import { messages } from 'utils/constants/messages';

export const explicitErrorHandler = (errorEvt: ErrorEvent) => {
   if (
      errorEvt.message === 'ResizeObserver loop completed with undelivered notifications.' ||
      errorEvt.message === 'ResizeObserver loop limit exceeded'
   ) {
      errorEvt.stopImmediatePropagation();
      errorEvt.stopPropagation();
   } else {
      // prompt user to confirm refresh
      /* if (/Loading chunk [\d]+ failed/.test(errorEvt.message)) {
         window.location.reload();
      } */

      if (
         errorEvt.message.includes('Loading chunk') ||
         errorEvt.message.includes('Request failed with status code') ||
         errorEvt.message.includes('Uncaught Error: The error you provided does not contain a stack trace')
      ) {
         window.location.reload();
      }
   }
};

// Error handling Sections
export const showApiErrorMessage = (error: any) => {
   return error && error.response && error.response.data && error.response.data.error_message
      ? error.response.data.error_message
      : error && error.response && error.response.data && error.response.data.error
      ? error.response.data.error
      : '';
};

export const getApiErrorMessage = (error: any) => {
   return error && error.response && error.response.data && error.response.data.error_message
      ? error.response.data.error_message
      : '';
};

export const getApiError = (error: any) => {
   return error && error.response && error.response.data && error.response.data.error
      ? error.response.data.error
      : messages.errorMessage;
};

export const getSagaApiErrorMessage = (response: any) => {
   return {
      error: response.data?.error_message || response.data?.error || messages.error,
   };
};
