import OncoPowerIcon from 'assets/askDoc/OncoBarTag.svg';

export const SeoTitle = {
   FEED: {
      title: 'Home',
      icon: OncoPowerIcon,
   },
   ASK_A_DOC: {
      title: 'Ask-a-Doc',
      icon: OncoPowerIcon,
   },
   SECOND_OPINION: {
      title: 'Second Opinion',
      icon: OncoPowerIcon,
   },
   DRUG_LOOKUP: {
      title: 'Drug Lookup',
      icon: OncoPowerIcon,
   },
   MEDICATION_SAVINGS: {
      title: 'Medication Savings',
      icon: OncoPowerIcon,
   },
   JOB_LISTING: {
      title: 'Job Listing',
      icon: OncoPowerIcon,
   },
   LOCUM_SERVICES: {
      title: 'Locum Service',
      icon: OncoPowerIcon,
   },
   ONCDRIVE: {
      title: 'OncDrive',
      icon: OncoPowerIcon,
   },
   ONCKLINIC: {
      title: 'Oncklinic',
      icon: OncoPowerIcon,
   },
   ADMIN: {
      title: 'Admin',
      icon: OncoPowerIcon,
   },
   WALLET: {
      title: 'Wallet',
      icon: OncoPowerIcon,
   },
   NOTIFICATION: {
      title: 'Notification',
      icon: OncoPowerIcon,
   },
   CONVERSATION: {
      title: 'Conversation',
      icon: OncoPowerIcon,
   },
   PROFILE: {
      title: 'My Profile',
      icon: OncoPowerIcon,
   },
   PROFILE_VIEW: {
      title: 'Profile View',
      icon: OncoPowerIcon,
   },
   EDIT_PROFILE: {
      title: 'Edit Profile',
      icon: OncoPowerIcon,
   },
   ACTIVITIES: {
      title: 'My Activities',
      icon: OncoPowerIcon,
   },
   HASHTAG_FEED: {
      title: 'Hashtag Feed',
      icon: OncoPowerIcon,
   },
   NETWORK: {
      title: 'Connections',
      icon: OncoPowerIcon,
   },
   MY_SAVED_POSTS: {
      title: 'My Saved Posts',
      icon: OncoPowerIcon,
   },
   CLINICAL_TRIALS: {
      title: 'Clinical Trials',
      icon: OncoPowerIcon,
   },
   MY_SERVICES: {
      title: 'My Services',
      icon: OncoPowerIcon,
   },
   EDUCATION_LIBRARY: {
      title: 'Education Library',
      icon: OncoPowerIcon,
   },
   ONCO_RELAX: {
      title: 'Meditation',
      icon: OncoPowerIcon,
   },
   NOTIFICATION_SETTINGS: {
      title: 'Notification Settings',
      icon: OncoPowerIcon,
   },
   SUPPORT_GROUPS: {
      title: 'Support Groups',
      icon: OncoPowerIcon,
   },
   PILL_REMAINDER: {
      title: 'Pill Remainder',
      icon: OncoPowerIcon,
   },
   TREATMENT_ESTIMATE: {
      title: 'Treatment Request',
      icon: OncoPowerIcon,
   },
   NOTIFICATION_FEED: {
      title: 'Notification Feed',
      icon: OncoPowerIcon,
   },
   ASK_A_DOC_FEED: {
      title: 'Ask-a-Doc Feed',
      icon: OncoPowerIcon,
   },
   CHECKLOGS: {
      title: 'Daily Check-In',
      icon: OncoPowerIcon,
   },
   RECRUITER: {
      title: 'Job Listing',
      icon: OncoPowerIcon,
   },
   GLOBAL_SEARCH: {
      title: 'Global Search',
      icon: OncoPowerIcon,
   },
   LOGIN: {
      title: 'Login',
      icon: OncoPowerIcon,
   },
   SIGNUP: {
      title: 'Register',
      icon: OncoPowerIcon,
   },
   EMAIL_VERIFICATION: {
      title: 'Email Verification',
      icon: OncoPowerIcon,
   },
   CONFIRM_JOIN: {
      title: 'Confirm Join',
      icon: OncoPowerIcon,
   },
   HIPPA_CONTENT: {
      title: 'Hippa Content',
      icon: OncoPowerIcon,
   },
   FORGOT_PASSWORD: {
      title: 'Forgot Password',
      icon: OncoPowerIcon,
   },
   MOBILE_VIEW: {
      title: 'Mobile View',
      icon: OncoPowerIcon,
   },
   SUBSCRIPTION: {
      title: 'Subscription',
      icon: OncoPowerIcon,
   },
   NO_INTERNET_CONNECTION: {
      title: 'No Internet Connection',
      icon: OncoPowerIcon,
   },
};
