import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MY_CLINIC_ADD_PATIENTS, MY_CLINIC_UPDATE_PATIENTS, GET_NURSE_CLINIC } from 'reduxToolKit/actions';
import { addPatientSlice, commonSlice } from 'reduxToolKit/reducers';
import { postShareSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   addMyPatientsRequest,
   addMyPatientsSuccess,
   addMyPatientsFailed,
   updateMyPatientsRequest,
   updateMyPatientsSuccess,
   updateMyPatientsFailed,
   getNurseClinicRequest,
   getNurseClinicSuccess,
   getNurseClinicFailed,
} = addPatientSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const addMyPatients = function* (payloadState: PayloadAction<postShareSagaTypes.IMyClinicAddPatient>) {
   yield put(addMyPatientsRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      let url = API.addPatients;
      if (data.addNursePatients) {
         url = API.addNursePatients;
      }
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...url });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: null,
         };
         yield put(addMyPatientsSuccess(resObj));
      } else {
         yield put(addMyPatientsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const updateMyPatients = function* (payloadState: any) {
   yield put(updateMyPatientsRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.updatePatientRequest });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message || messages.success,
            data: null,
         };
         yield put(updateMyPatientsSuccess(resObj));
      } else {
         yield put(updateMyPatientsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getNurseClinicSaga = function* (payloadState: any) {
   yield put(getNurseClinicRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.getNurseClinic });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message || messages.success,
            data: response.data.data,
         };
         yield put(getNurseClinicSuccess(resObj));
      } else {
         yield put(getNurseClinicFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAddPatients = function* () {
   yield takeEvery(MY_CLINIC_ADD_PATIENTS, addMyPatients);
   yield takeEvery(MY_CLINIC_UPDATE_PATIENTS, updateMyPatients);
   yield takeEvery(GET_NURSE_CLINIC, getNurseClinicSaga);
};
