import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_CANCER_STATUS, GET_MASTER_CANCER_STATUS } from 'reduxToolKit/actions';
import { commonSlice, cancerStatusSlice } from 'reduxToolKit/reducers';
import { myProfileSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   cancerStatusAddRequest,
   cancerStatusAddSuccess,
   cancerStatusAddFailed,
   getMasterCancerStatusRequest,
   getMasterCancerStatusSuccess,
   getMasterCancerStatusFailed,
} = cancerStatusSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const cancerStatusAddSaga = function* (cancerStatusAddPayload: any) {
   yield put(cancerStatusAddRequest(cancerStatusAddPayload.payload));
   try {
      const checkUser = false;
      const data = cancerStatusAddPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...API.cancerStatusAdd });
      if (response.status === 200) {
         const responseData = response.data;
         const cancerStatusAddData = {
            message: responseData?.message || '',
         };
         yield put(cancerStatusAddSuccess(cancerStatusAddData));
      } else {
         yield put(cancerStatusAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getMasterCancerStatusSaga = function* (
   getMasterCancerStatusPayload: PayloadAction<myProfileSagaTypes.IMasterRaceProps>
) {
   yield put(getMasterCancerStatusRequest(getMasterCancerStatusPayload.payload));
   try {
      const params = getMasterCancerStatusPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         params,
         ...API.getMasterCancerStatus,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const getMasterCancerStatusList = {
            list: responseData?.data?.data || [],
            total_records: responseData?.data?.total || 0,
            message: response?.data?.message || '',
         };
         yield put(getMasterCancerStatusSuccess(getMasterCancerStatusList));
      } else {
         yield put(getMasterCancerStatusFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeCancerStatus = function* () {
   yield takeEvery(ADD_CANCER_STATUS, cancerStatusAddSaga);
   yield takeEvery(GET_MASTER_CANCER_STATUS, getMasterCancerStatusSaga);
};
