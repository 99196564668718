import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ANNOUNCEMENT_LIST, ANNOUNCEMENT_RESPONSE } from 'reduxToolKit/actions';
import { announcementSlice, commonSlice } from 'reduxToolKit/reducers';
import { announcementPageTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   announcementListRequest,
   announcementListSuccess,
   announcementListFailed,
   announcementResponseRequest,
   announcementResponseSuccess,
   announcementResponseFailed,
} = announcementSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const announcementListSaga = function* () {
   yield put(announcementListRequest());
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const response: AxiosResponse = yield apiCall({ checkUser, peopleMayYouKnow, ...API.announcementList });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(announcementListSuccess(resObj));
      } else {
         yield put(announcementListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const announcementResponseSaga = function* (
   payloadState: PayloadAction<announcementPageTypes.IAnnouncementResponsePageTypes>
) {
   yield put(announcementResponseRequest(payloadState.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, peopleMayYouKnow, data, ...API.announcementResponse });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(announcementResponseSuccess(resObj));
      } else {
         yield put(announcementResponseFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAnnouncement = function* () {
   yield takeEvery(ANNOUNCEMENT_LIST, announcementListSaga);
   yield takeEvery(ANNOUNCEMENT_RESPONSE, announcementResponseSaga);
};
