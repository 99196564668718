import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pillRemainderReducerTypes } from 'types';

const initialState: pillRemainderReducerTypes.IPillRemainderNotificationGetOneReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const pillRemainderNotificationGetOneSlice = createSlice({
   name: 'pillRemainderNotificationGetOne',
   initialState: initialState,
   reducers: {
      pillRemainderNotificationGetOneView: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      pillRemainderNotificationGetOneSuccess: (
         state,
         action: PayloadAction<pillRemainderReducerTypes.IPillRemainderNotificationGetOneReducer>
      ) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      pillRemainderNotificationGetOneFailed: (state, action) => {
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      pillRemainderNotificationGetOneReset: () => {
         return initialState;
      },
   },
});
export { pillRemainderNotificationGetOneSlice };
