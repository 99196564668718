import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_UPDATE_LOCUM_SERVICE } from 'reduxToolKit/actions';
import { commonSlice, locumServiceAddUpdateSlice } from 'reduxToolKit/reducers';
import { locumSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   locumServiceAddUpdateRequest,
   locumServiceAddUpdateSuccess,
   locumServiceAddUpdateFailed,
} = locumServiceAddUpdateSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const locumServiceAddUpdateSaga = function* (
   locumServiceAddUpdate: PayloadAction<locumSagaTypes.IAddUpdateLocumServiceSagaParams>
) {
   yield put(locumServiceAddUpdateRequest(locumServiceAddUpdate.payload));
   try {
      let apiPath = API.addLocumService;
      if (locumServiceAddUpdate.payload.id) {
         apiPath = {
            apiPath: `${API.updateLocumService.apiPath}${locumServiceAddUpdate.payload.id}`,
            action: API.updateLocumService.action,
         };
      }
      const data = locumServiceAddUpdate.payload;
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...apiPath });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(locumServiceAddUpdateSuccess(resObj));
      } else {
         yield put(locumServiceAddUpdateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeLocumServiceAddUpdateRequest = function* () {
   yield takeEvery(ADD_UPDATE_LOCUM_SERVICE, locumServiceAddUpdateSaga);
};
