import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authReducerTypes, authSagaTypes } from 'types';

const initialState: authReducerTypes.IEmailVerificationReducerTypes = {
   message: '',
   error: '',
};
export const emailVerificationSlice = createSlice({
   name: 'emailVerification',
   initialState: initialState,
   reducers: {
      emailVerificationRequest: (state, action: PayloadAction<authSagaTypes.IEmailVerificationSagaPayload>) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      emailVerificationSuccess: (state, action: PayloadAction<authReducerTypes.IEmailVerificationSuccessPayload>) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      emailVerificationFailed: (state, action: PayloadAction<authReducerTypes.IEmailVerificationFailurePayload>) => {
         return {
            ...state,
            error: action.payload.error,
         };
      },
      emailVerificationReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
