import { createSlice } from '@reduxjs/toolkit';
import { myProfileReducerTypes } from 'types';

const initialState: myProfileReducerTypes.ICancerStatusReducer = {
   addCancerStatus: {
      isLoading: false,
      error: '',
      message: '',
   },
   getMasterCancerStatus: {
      isLoading: false,
      message: '',
      error: '',
      list: [],
   },
};

export const cancerStatusSlice = createSlice({
   name: 'cancerStatus',
   initialState: initialState,
   reducers: {
      // add cancer status
      cancerStatusAddRequest: (state, action) => {
         return {
            ...state,
            addCancerStatus: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      cancerStatusAddSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            addCancerStatus: {
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      cancerStatusAddFailed: (state, action: any) => {
         return {
            ...state,
            addCancerStatus: {
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      cancerStatusAddReset: (state) => {
         return {
            ...state,
            addCancerStatus: initialState.addCancerStatus,
         };
      },

      // get master cancer status
      getMasterCancerStatusRequest: (state, action) => {
         return {
            ...state,
            getMasterCancerStatus: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getMasterCancerStatusSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            getMasterCancerStatus: {
               list: payload.list,
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      getMasterCancerStatusFailed: (state, action: any) => {
         return {
            ...state,
            getMasterCancerStatus: {
               list: state.getMasterCancerStatus.list,
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      getMasterCancerStatusReset: (state) => {
         return {
            ...state,
            getMasterCancerStatus: initialState.getMasterCancerStatus,
         };
      },
   },
});
