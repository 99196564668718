import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { locumReducerTypes, locumSagaTypes } from 'types';

const initialState: locumReducerTypes.ILocumGetStateAndTravelListReducer = {
   isLoading: false,
   error: '',
   error_message: '',
   message: '',
   list: [],
   pagination: {
      current_page: null,
      last_page: null,
      first_page_url: '',
      last_page_url: '',
      next_page_url: '',
      prev_page_url: '',
      total: 0,
   },
};
export const settingLocumGetStateAndTravelListSlice = createSlice({
   name: 'settingLocumGetStateAndTravelList',
   initialState: initialState,
   reducers: {
      settingLocumGetStateAndTravelListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      settingLocumGetStateAndTravelListRequest: (
         state,
         action: PayloadAction<locumSagaTypes.IGetLocumStateAndTravelSagaParams>
      ) => {
         const stateAndTravelList = action.payload.page && action.payload.page === 1 ? [] : state.list;
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: stateAndTravelList,
         };
      },
      settingLocumGetStateAndTravelListSuccess: (
         state,
         action: PayloadAction<locumReducerTypes.ILocumGetStateAndTravelListReducer>
      ) => {
         const { payload } = action;
         const stateAndTravelList =
            payload.pagination.current_page === 1 ? payload.list : [...state.list, ...payload.list];
         return {
            ...state,
            pagination: { ...payload.pagination },
            list: stateAndTravelList,
            isLoading: false,
         };
      },
      settingLocumGetStateAndTravelListFailed: (
         state,
         action: PayloadAction<locumReducerTypes.ILocumGetStateAndTravelListReducer>
      ) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error_message,
         };
      },
      settingLocumGetStateAndTravelListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
