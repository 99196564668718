import { createSlice } from '@reduxjs/toolkit';
import { peopleNearMeReducerTypes } from 'types';

const initialState: peopleNearMeReducerTypes.IDrugSpecific = {
   isLoading: false,
   error: '',
   message: '',
   data: {},
};

export const notificationReadSlice = createSlice({
   name: 'notificationRead',
   initialState: initialState,
   reducers: {
      notificationReadRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            data: state.data,
         };
      },
      notificationReadSuccess: (state, action: any) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      notificationReadFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      notificationReadReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
