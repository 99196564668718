import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import {
   SUBSCRIPTION_CANCEL_PLAN,
   SUBSCRIPTION_CREATE_PLAN,
   SUBSCRIPTION_GET_PLAN,
   SUBSCRIPTION_PLANS_LIST,
   SUBSCRIPTION_PLAN_IS_ACTIVE,
   SUBSCRIPTION_CHECK_COUPON_CODE,
   SUBSCRIPTION_STRIPE_PORTAL,
   SUBSCRIPTION_CHECK_WALLET,
   SUBSCRIPTION_ONE_TIME_PURCHASE,
   SUBSCRIPTION_CALLBACK,
} from 'reduxToolKit/actions';
import { commonSlice, subscriptionDetailsSlice } from 'reduxToolKit/reducers';
import { subscriptionDetailsReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   checkPlanActiveDetailsRequest,
   checkPlanActiveDetailsSuccess,
   checkPlanActiveDetailsFailed,
   getSubscriptionPlansListRequest,
   getSubscriptionPlansListSuccess,
   getSubscriptionPlansListFailed,
   subscriptionGetPlanRequest,
   subscriptionGetPlanSuccess,
   subscriptionGetPlanFailed,
   subscriptionCancelPlanRequest,
   subscriptionCancelPlanSuccess,
   subscriptionCancelPlanFailed,
   subscriptionCreatePlanRequest,
   subscriptionCreatePlanSuccess,
   subscriptionCreatePlanFailed,
   checkCouponCodeRequest,
   checkCouponCodeSuccess,
   checkCouponCodeFailed,
   subscriptionStripePortalRequest,
   subscriptionStripePortalSuccess,
   subscriptionStripePortalFailed,
   checkCouponWalletRequest,
   checkCouponWalletSuccess,
   checkCouponWalletFailed,
   subscriptionOneTimePurchaseRequest,
   subscriptionOneTimePurchaseSuccess,
   subscriptionOneTimePurchaseFailed,
   subscriptionCallbackRequest,
   subscriptionCallbackSuccess,
   subscriptionCallbackFailed,
} = subscriptionDetailsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const checkPlanActiveSaga = function* (cancerTypePayload: any) {
   yield put(checkPlanActiveDetailsRequest(cancerTypePayload.payload));
   try {
      const response: AxiosResponse = yield apiCall({ ...API.checkPlanActive });
      if (response.status === 200) {
         const oncDriveList = {
            id: response.data?.data?.id,
            status: response.data?.data?.status || false,
            amount: response.data?.data?.amount,
            message: response.data?.message || messages.success,
         };
         yield put(checkPlanActiveDetailsSuccess(oncDriveList));
      } else {
         yield put(checkPlanActiveDetailsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getSubscriptionPlansList = function* () {
   yield put(getSubscriptionPlansListRequest());
   try {
      const response: AxiosResponse = yield apiCall({ ...API.getSubscriptionPlansList });
      if (response.status === 200) {
         const getSubscriptionPlansList = {
            data: response?.data.data,
            message: response?.data?.message || messages.success,
         };
         yield put(getSubscriptionPlansListSuccess(getSubscriptionPlansList));
      } else {
         yield put(getSubscriptionPlansListFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getActiveSubscriptionPlan = function* () {
   yield put(subscriptionGetPlanRequest());
   try {
      const response: AxiosResponse = yield apiCall({ ...API.subscriptionActivePlan });
      if (response.status === 200) {
         const getOneSubscriptionPlanData = {
            data: response?.data.data,
            message: response?.data?.message || messages.success,
         };
         yield put(subscriptionGetPlanSuccess(getOneSubscriptionPlanData));
      } else {
         yield put(subscriptionGetPlanFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const cancelSubscriptionPlan = function* () {
   yield put(subscriptionCancelPlanRequest());
   try {
      const response: AxiosResponse = yield apiCall({ ...API.subscriptionPlanCancel });
      if (response.status === 200) {
         const getOneSubscriptionPlanData = {
            data: response?.data?.data || null,
            message: response?.data?.message || messages.success,
         };
         yield put(subscriptionCancelPlanSuccess(getOneSubscriptionPlanData));
      } else {
         yield put(subscriptionCancelPlanFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const createSubscriptionPlan = function* (
   subscriptionPlanCreatePayload: PayloadAction<subscriptionDetailsReducerTypes.ISubscriptionCreatePlanRequest>
) {
   yield put(subscriptionCreatePlanRequest(subscriptionPlanCreatePayload.payload));
   try {
      const data = subscriptionPlanCreatePayload.payload;
      const response: AxiosResponse = yield apiCall({ data, ...API.subscriptionPlanCreate });
      if (response.status === 200) {
         const getOneSubscriptionPlanData = {
            data: response?.data.data,
            message: response?.data?.message || messages.success,
         };
         yield put(subscriptionCreatePlanSuccess(getOneSubscriptionPlanData));
      } else {
         yield put(subscriptionCreatePlanFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const subscriptionCheckCouponCode = function* (
   payloadState: PayloadAction<subscriptionDetailsReducerTypes.ISubscriptionCheckCouponCode>
) {
   yield put(checkCouponCodeRequest(payloadState.payload));
   try {
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ params, ...API.subscriptionCheckCouponCode });
      if (response.status === 200) {
         const returnResponse = {
            message: response.data?.message || messages.success,
            data: response.data?.data,
         };
         yield put(checkCouponCodeSuccess(returnResponse));
      } else {
         yield put(checkCouponCodeFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const subscriptionCheckWallet = function* (
   payloadState: PayloadAction<subscriptionDetailsReducerTypes.ISubscriptionCheckWalletParams>
) {
   yield put(checkCouponWalletRequest(payloadState.payload));
   try {
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ params, ...API.subscriptionCheckWallet });
      if (response.status === 200) {
         const returnResponse = {
            message: response.data?.message || messages.success,
            data: response.data?.data,
         };
         yield put(checkCouponWalletSuccess(returnResponse));
      } else {
         yield put(checkCouponWalletFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const subscriptionStripePortal = function* () {
   yield put(subscriptionStripePortalRequest());
   try {
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ checkUser, ...API.subscriptionStripePortal });
      if (response.status === 200) {
         const subscriptionStripePortal = {
            data: response?.data?.data || null,
            message: response?.data?.message || messages.success,
         };
         yield put(subscriptionStripePortalSuccess(subscriptionStripePortal));
      } else {
         yield put(subscriptionStripePortalFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const subscriptionOneTimePurchase = function* (
   subscriptionOneTimePurchaseReq: PayloadAction<subscriptionDetailsReducerTypes.ISubscriptionOneTimePurchaseParams>
) {
   yield put(subscriptionOneTimePurchaseRequest(subscriptionOneTimePurchaseReq.payload));
   try {
      const checkUser = false;
      const goUrl = false;
      const data = subscriptionOneTimePurchaseReq.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.subscriptionOneTimePurchase });
      if (response.status === 200) {
         const subscriptionOneTimePurchase = {
            data: response?.data?.data || null,
            message: response?.data?.message || messages.success,
         };
         yield put(subscriptionOneTimePurchaseSuccess(subscriptionOneTimePurchase));
      } else {
         yield put(subscriptionOneTimePurchaseFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const subscriptionCallback = function* (
   subscriptionCallbackReq: PayloadAction<subscriptionDetailsReducerTypes.ISubscriptionCallbackParams>
) {
   yield put(subscriptionCallbackRequest(subscriptionCallbackReq.payload));
   try {
      const checkUser = false;
      const goUrl = false;
      const data = subscriptionCallbackReq.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.subscriptionCallback });
      if (response.status === 200) {
         const subscriptionCallback = {
            data: response?.data?.data || null,
            message: response?.data?.message || messages.success,
         };
         yield put(subscriptionCallbackSuccess(subscriptionCallback));
      } else {
         yield put(subscriptionCallbackFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takecheckPlanActive = function* () {
   yield takeLatest(SUBSCRIPTION_PLAN_IS_ACTIVE, checkPlanActiveSaga);
};
export const takeGetSubscriptionPlansList = function* () {
   yield takeLatest(SUBSCRIPTION_PLANS_LIST, getSubscriptionPlansList);
   yield takeLatest(SUBSCRIPTION_GET_PLAN, getActiveSubscriptionPlan);
   yield takeLatest(SUBSCRIPTION_CANCEL_PLAN, cancelSubscriptionPlan);
   yield takeLatest(SUBSCRIPTION_CREATE_PLAN, createSubscriptionPlan);
   yield takeLatest(SUBSCRIPTION_STRIPE_PORTAL, subscriptionStripePortal);
   yield takeLatest(SUBSCRIPTION_ONE_TIME_PURCHASE, subscriptionOneTimePurchase);
   yield takeLatest(SUBSCRIPTION_CALLBACK, subscriptionCallback);
};
export const takeSubscriptionCheckCouponCode = function* () {
   yield takeLatest(SUBSCRIPTION_CHECK_COUPON_CODE, subscriptionCheckCouponCode);
   yield takeLatest(SUBSCRIPTION_CHECK_WALLET, subscriptionCheckWallet);
};
