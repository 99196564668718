import React, { useCallback, useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, Grid, IconButton, InputAdornment, Paper, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Add from 'assets/alert/pillAddIcon.svg';
import Delete from 'assets/alert/pillTimeDel.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import Loading from 'component/widget/Loading';
import NoData from 'component/widget/NoData';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { medicationListSagaAction } from 'reduxToolKit/actions';
import { medicationListSlice, pillRemainderMediStatusUpdateSlice } from 'reduxToolKit/reducers';
import { Toggle } from 'rsuite';
import { pillRemainderSagaTypes, storeTypes } from 'types';
import { PILL_REMAINDER_EVENTS } from 'utils/constants/eventTrack/pillRemainderEventConstants';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';
import { convertOnlyUTCTime } from 'utils/helpers/dateHelpers';
import { setEventTrack } from 'utils/helpers/eventTrack';

const SelectTime = (props: any) => {
   const dispatch = useDispatch();
   const [timeArray, setTimeArray] = useState<pillRemainderSagaTypes.IPillRemainderMedTime[]>(props?.timeArray);

   const {
      isLoading: isLoadingPillRemainderMediStatusLoading,
      message: pillRemainderMediStatusUpdateMessage,
      error: pillRemainderMediStatusUpdateErrorMessage,
   } = useSelector((state: storeTypes.IStore) => state.pillRemainderMediStatusUpdate);
   const { pillRemainderMediStatusUpdateReset } = pillRemainderMediStatusUpdateSlice.actions;

   const { medicationsListReset } = medicationListSlice.actions;

   useEffect(() => {
      setTimeArray(props?.timeArray);
   }, [props?.timeArray]);

   const changeTime = useCallback(
      (date: MaterialUiPickersDate, i: number) => {
         if (date) {
            const convertTime = convertOnlyUTCTime(date);
            const array = listParseAndStringify(timeArray);
            if (i !== -1) {
               const obj = { time: convertTime, dateTime: date };
               array.splice(i, 1, obj);
               setTimeArray(array);
               props.getTimesArray(array);
            }
         }
      },
      [timeArray]
   );

   const clickTimeAdd = () => {
      const array = listParseAndStringify(timeArray);
      array.push({ time: '', dateTime: null });
      setTimeArray(array);
      props.getTimesArray(array);
   };

   const clickTimeRemove = (i: number) => {
      const array = listParseAndStringify(timeArray);
      if (i !== -1) {
         array.splice(i, 1);
         setTimeArray(array);
         props.getTimesArray(array);
      }
   };

   /* const clickMedicationStatus = (medicationStatusBoolean: boolean) => {
      const pillRemainderMediStatusUpdateRequest = {
         id: props.pillItemMedication?.id || 0,
         status: medicationStatusBoolean,
      };
      dispatch(pillRemainderMedicationStatusUpdateSagaAction(pillRemainderMediStatusUpdateRequest));
   }; */

   const changeActiveToggle = (checked: boolean, event: any) => {
      props?.getActiveStatus(checked);
      if (checked) {
         setEventTrack(PILL_REMAINDER_EVENTS.RESUME_MEDICATION);
      } else {
         setEventTrack(PILL_REMAINDER_EVENTS.SUSPEND_MEDICATION);
      }
   };

   const hideAlert = (type: string) => {
      if (type === 'errorAlert') {
         dispatch(pillRemainderMediStatusUpdateReset());
      } else if (type === 'successAlert') {
         props.clearMedications();
         props.handleClose();
         dispatch(pillRemainderMediStatusUpdateReset());
         dispatch(medicationsListReset());
         const params: pillRemainderSagaTypes.IPillActiveAndInactiveMedicineList = {
            from_type: 'pill',
         };
         dispatch(medicationListSagaAction(params));
      }
   };

   return (
      <>
         {isLoadingPillRemainderMediStatusLoading && <Loading />}

         {pillRemainderMediStatusUpdateMessage && (
            <InfoTimerAlert
               success
               confirmBtnText={`Okay!`}
               confirmBtnBsStyle={`successBtn`}
               title={pillRemainderMediStatusUpdateMessage}
               onConfirm={() => hideAlert('successAlert')}
            />
         )}
         {pillRemainderMediStatusUpdateErrorMessage && (
            <SweetAlert
               error
               confirmBtnText={`Okay!`}
               confirmBtnBsStyle={`errorBtn`}
               title={pillRemainderMediStatusUpdateErrorMessage}
               onConfirm={() => hideAlert('errorAlert')}
            />
         )}
         {props.type === 'every_day' && (
            <Box mt={2}>
               <Paper variant="outlined" square>
                  <Grid container direction="row" justify="space-between">
                     <Grid item>
                        <Box p={2}>
                           <Typography className="pill-select-time-title">Selected time</Typography>
                        </Box>
                     </Grid>
                     <Grid item>
                        {!props.isView && (
                           <Box p={2}>
                              <Button className="pill-add-btn" onClick={clickTimeAdd}>
                                 <img src={Add} alt="img" />
                                 &nbsp; ADD
                              </Button>
                           </Box>
                        )}
                     </Grid>
                  </Grid>

                  <Box className="errorMsg ml-3">
                     {props.validation
                        ? timeArray?.length > 0 && timeArray.findIndex((x) => x.time === '') === -1
                           ? ''
                           : 'Dose time cannot be blank'
                        : null}
                  </Box>

                  {timeArray?.length > 0 && (
                     <Box ml={2} mt={1} width="90%">
                        <Grid container direction="column" spacing={1}>
                           {timeArray.length > 0 ? (
                              timeArray.map((time: pillRemainderSagaTypes.IPillRemainderMedTime, i: number) => {
                                 return (
                                    <Grid item key={i}>
                                       <Box>
                                          <Typography className="pill-per-day-time-text">{`Time-${i + 1}`}</Typography>
                                          <Box className="pill-container-time">
                                             <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <TimePicker
                                                   inputVariant="standard"
                                                   margin="normal"
                                                   id="time-picker"
                                                   label=""
                                                   disabled={props.isView}
                                                   placeholder="Select time"
                                                   className="pill"
                                                   value={time.dateTime}
                                                   onChange={(date: MaterialUiPickersDate) => {
                                                      changeTime(date, i);
                                                   }}
                                                   InputProps={{
                                                      startAdornment: (
                                                         <InputAdornment position="start">
                                                            <IconButton>
                                                               <AccessTimeIcon />
                                                            </IconButton>
                                                         </InputAdornment>
                                                      ),
                                                      // endAdornment: (
                                                      //    <InputAdornment position="end">
                                                      //       {!props.isView && (
                                                      //          <IconButton
                                                      //             className="del-endadorment"
                                                      //             onClick={() => {
                                                      //                clickTimeRemove(i);
                                                      //             }}
                                                      //          >
                                                      //             <img src={Delete} alt="img" />
                                                      //             {/* <AccessTimeIcon /> */}
                                                      //          </IconButton>
                                                      //       )}
                                                      //    </InputAdornment>
                                                      // ),
                                                   }}
                                                />
                                             </MuiPickersUtilsProvider>
                                             {!props.isView && (
                                                <IconButton
                                                   className="remove-icon-pill"
                                                   onClick={() => {
                                                      clickTimeRemove(i);
                                                   }}
                                                >
                                                   <img src={Delete} alt="img" />
                                                </IconButton>
                                             )}
                                          </Box>
                                       </Box>
                                    </Grid>
                                 );
                              })
                           ) : (
                              <NoData />
                           )}
                        </Grid>
                     </Box>
                  )}
               </Paper>
            </Box>
         )}
         {props?.isFromMyProfile ? null : (
            <Box mt={2} className="pill-drawer-active" style={{ marginBottom: '100px' }}>
               <Typography component={'span'} className="pill-active-text">
                  Active
               </Typography>
               <Toggle disabled={props.isView} checked={props?.activeStatus} onChange={changeActiveToggle} />
            </Box>
         )}
         {
            !props.isView ? (
               <Box className="submit-box">
                  <Button
                     variant="contained"
                     fullWidth
                     className="submit-btn"
                     onClick={() => {
                        props.clickSave();
                        setEventTrack(PILL_REMAINDER_EVENTS.EDIT_MEDICATION);
                     }}
                  >
                     Save
                  </Button>
               </Box>
            ) : null /* props.pillItemMedication?.status === 1 ? (
            <Box className="submit-box">
               <Button
                  variant="contained"
                  fullWidth
                  className="submit-btn"
                  onClick={() => {
                     clickMedicationStatus(false);
                     setEventTrack(PILL_REMAINDER_EVENTS.SUSPEND_MEDICATION);
                  }}
               >
                  Suspend
               </Button>
            </Box>
         ) : (
            <Box className="submit-box">
               <Button
                  variant="contained"
                  fullWidth
                  className="submit-btn"
                  onClick={() => {
                     clickMedicationStatus(true);
                     setEventTrack(PILL_REMAINDER_EVENTS.RESUME_MEDICATION);
                  }}
               >
                  Resume
               </Button>
            </Box>
         ) */
         }
      </>
   );
};

export { SelectTime };
