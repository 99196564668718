import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { NOTIFICATION_LIST } from 'reduxToolKit/actions';
import { commonSlice, notificationListSlice } from 'reduxToolKit/reducers';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   notificationListLoading,
   notificationListViewRequest,
   notificationListViewSuccess,
   notificationListFailed,
} = notificationListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const notificationListSaga = function* (cancerTypePayload: any) {
   yield put(notificationListLoading());
   yield put(notificationListViewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         peopleMayYouKnow,
         params,
         ...API.getNotificationList,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const notificationList = {
            list: responseData?.data?.list || [],
            total_records: responseData?.data?.total_records || 0,
            current_page: responseData?.data?.current_page || 0,
            message: response.data?.message || messages.success,
         };
         yield put(notificationListViewSuccess(notificationList));
      } else {
         yield put(notificationListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeNotificationList = function* () {
   yield takeEvery(NOTIFICATION_LIST, notificationListSaga);
};
