import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { globalSearchReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: globalSearchReducerTypes.IRecentSearchGetListReducer = {
   getRecentSearch: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   addRecentSearch: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
};

export const recentSearchSlice = createSlice({
   name: 'recentSearch',
   initialState: initialState,
   reducers: {
      recentSearchListRequest: (state) => {
         return {
            ...state,
            getRecentSearch: {
               ...state.getRecentSearch,
               isLoading: true,
            },
         };
      },
      recentSearchListSuccess: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            getRecentSearch: {
               ...state.getRecentSearch,
               message: action.payload?.message || messages.success,
               data: action.payload?.data,
               error: '',
               isLoading: false,
            },
         };
      },
      recentSearchListFailed: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            getRecentSearch: {
               ...state.getRecentSearch,
               error: action.payload?.error || messages.failed,
               message: '',
               data: null,
               isLoading: false,
            },
         };
      },
      recentSearchListReset: (state) => {
         return {
            ...state,
            getRecentSearch: {
               error: '',
               message: '',
               data: null,
               isLoading: false,
            },
         };
      },
      // recent search add
      recentSearchAddRequest: (
         state,
         action: PayloadAction<globalSearchReducerTypes.IAddRecentSearchRequestParams>
      ) => {
         return {
            ...state,
            addRecentSearch: {
               ...state.addRecentSearch,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      recentSearchAddSuccess: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            addRecentSearch: {
               ...state.addRecentSearch,
               message: action.payload?.message || messages.success,
               data: action.payload?.data,
               error: '',
               isLoading: false,
            },
         };
      },
      recentSearchAddFailed: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            addRecentSearch: {
               ...state.addRecentSearch,
               error: action.payload?.error || messages.failed,
               message: '',
               data: null,
               isLoading: false,
            },
         };
      },
      recentSearchAddReset: (state) => {
         return {
            ...state,
            addRecentSearch: {
               error: '',
               message: '',
               data: null,
               isLoading: false,
            },
         };
      },
   },
});
