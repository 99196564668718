import { createAction } from '@reduxjs/toolkit';
import { healthRecordsSagaTypes } from 'types';

export const HEALTH_RECORD_LIST = 'HEALTH_RECORD_LIST';
export const healthRecordListAction = createAction<healthRecordsSagaTypes.IHealthRecordList>(HEALTH_RECORD_LIST);

export const HEALTH_RECORD_ADD = 'HEALTH_RECORD_ADD';
export const healthRecordAddAction = createAction<healthRecordsSagaTypes.IHealthRecordAdd>(HEALTH_RECORD_ADD);

export const CUSTOM_NOTIFICATION_LIST = 'CUSTOM_NOTIFICATION_LIST';
export const customNotificationListAction = createAction<healthRecordsSagaTypes.INotificationList>(
   CUSTOM_NOTIFICATION_LIST
);

export const CUSTOM_NOTIFICATION_ADD = 'CUSTOM_NOTIFICATION_ADD';
export const customNotificationAddAction = createAction<healthRecordsSagaTypes.ICustomNotificationAdd>(
   CUSTOM_NOTIFICATION_ADD
);
