import { AxiosResponse } from 'axios';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { VIDEO_LIBRARY_LIST_SAGA, VIDEO_LIBRARY_LIST_SEARCH_SAGA } from 'reduxToolKit/actions';
import { commonSlice, videoLibraryListSlice } from 'reduxToolKit/reducers';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   videoLibraryListLoading,
   videoLibraryListViewRequest,
   videoLibraryListViewSuccess,
   videoLibraryListViewFailed,
} = videoLibraryListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const videoLibraryListSaga = function* (videoLibraryListPayload: any) {
   if (videoLibraryListPayload.payload.search_word !== '') {
      yield put(videoLibraryListLoading());
      yield delay(500);
   }
   yield put(videoLibraryListViewRequest(videoLibraryListPayload.payload));
   try {
      const params = videoLibraryListPayload.payload;
      const url = API.videoLibraryList;
      const response: AxiosResponse = yield apiCall({ params, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const oncDriveList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message || messages.success,
         };
         yield put(videoLibraryListViewSuccess(oncDriveList));
      } else {
         yield put(videoLibraryListViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeVideoLibraryListRequest = function* () {
   yield takeLatest(VIDEO_LIBRARY_LIST_SAGA, videoLibraryListSaga);
   yield takeLatest(VIDEO_LIBRARY_LIST_SEARCH_SAGA, videoLibraryListSaga);
};
