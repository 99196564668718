import { createAction } from '@reduxjs/toolkit';
import { oncKlinicSymptomSagaTypes, postShareSagaTypes } from 'types';

export const SYMPTOM_LIST = 'SYMPTOM_LIST';
export const symptomList = createAction<postShareSagaTypes.ISymptomList>(SYMPTOM_LIST);

export const REPORTABLE_SYMPTOM_LIST = 'REPORTABLE_SYMPTOM_LIST';
export const reportableSymptomList = createAction<postShareSagaTypes.IWallet>(REPORTABLE_SYMPTOM_LIST);

export const REPORTABLE_SYMPTOM_GO_LIST = 'REPORTABLE_SYMPTOM_GO_LIST';
export const reportableSymptomGoListAction = createAction<oncKlinicSymptomSagaTypes.IReportableSymptomParams>(
   REPORTABLE_SYMPTOM_GO_LIST
);

export const ADD_SYMPTOM = 'ADD_SYMPTOM';
export const addSymptom = createAction<postShareSagaTypes.IAddSymptom>(ADD_SYMPTOM);

export const DOCUMENTATION_LIST = 'DOCUMENTATION_LIST';
export const documentationList = createAction<postShareSagaTypes.IDocumentationList>(DOCUMENTATION_LIST);

export const ONCKLINIC_SYMPTOM_CLINIC_TIMING = 'ONCKLINIC_SYMPTOM_CLINIC_TIMING';
export const checkClinicTimingAction = createAction<postShareSagaTypes.IClinicWorkingHours>(
   ONCKLINIC_SYMPTOM_CLINIC_TIMING
);

export const UPDATE_SYMPTOM_MONITORING_CONSENT = 'UPDATE_SYMPTOM_MONITORING_CONSENT';
export const updateSymptomMonitoringConsent = createAction<oncKlinicSymptomSagaTypes.IUpdateSymptomMonitoringConsent>(
   UPDATE_SYMPTOM_MONITORING_CONSENT
);
