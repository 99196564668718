import { createAction } from '@reduxjs/toolkit';
import { authSagaTypes } from 'types';

export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const authForgotPassword = createAction<authSagaTypes.IForgetPasswordSagaPayload>(AUTH_FORGOT_PASSWORD);

export const AUTH_SET_PASSWORD = 'AUTH_SET_PASSWORD';
export const authSetPassword = createAction<authSagaTypes.ISetPasswordSagaPayload>(AUTH_SET_PASSWORD);

export const AUTH_EMAIL_VERIFICATION = 'AUTH_EMAIL_VERIFICATION';
export const authEmailVerification = createAction<authSagaTypes.IEmailVerificationSagaPayload>(AUTH_EMAIL_VERIFICATION);
