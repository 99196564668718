import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { supportGroupsReducerTypes, supportGroupsSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: supportGroupsReducerTypes.IGroupInfoReducerPayload = {
   getGroupInfo: {
      data: null,
      isLoading: false,
      error: '',
      message: '',
   },
   leaveGroup: {
      data: null,
      isLoading: false,
      error: '',
      message: '',
   },
   membersList: {
      data: null,
      isLoading: false,
      error: '',
      message: '',
   },
};

export const groupsInfoDetailsSlice = createSlice({
   name: 'groupsInfoDetails',
   initialState: initialState,
   reducers: {
      getGroupsInfoRequest: (state, action: PayloadAction<supportGroupsSagaTypes.IGroupInfoSagaTypes>) => {
         return {
            ...state,
            getGroupInfo: {
               ...state.getGroupInfo,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getGroupsInfoSuccess: (state, action: PayloadAction<supportGroupsReducerTypes.IGroupInfoSuccessPayload>) => {
         return {
            ...state,
            getGroupInfo: {
               ...action.payload,
               success: messages.success,
               error: '',
               isLoading: false,
            },
         };
      },
      getGroupsInfoFailed: (state, action: PayloadAction<supportGroupsReducerTypes.IGroupInfoFailurePayload>) => {
         return {
            ...state,
            getGroupInfo: {
               ...state.getGroupInfo,
               error: action.payload?.error,
               isLoading: false,
            },
         };
      },
      groupLeaveRequest: (state, action: PayloadAction<supportGroupsSagaTypes.IGroupInfoSagaTypes>) => {
         return {
            ...state,
            leaveGroup: {
               ...state.getGroupInfo,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      groupLeaveSuccess: (state, action: PayloadAction<supportGroupsReducerTypes.IGroupInfoSuccessPayload>) => {
         return {
            ...state,
            leaveGroup: {
               ...action.payload,
               error: '',
               isLoading: false,
            },
         };
      },
      groupLeaveFailed: (state, action: PayloadAction<supportGroupsReducerTypes.IGroupInfoFailurePayload>) => {
         return {
            ...state,
            leaveGroup: {
               ...state.leaveGroup,
               error: action.payload?.error,
               isLoading: false,
            },
         };
      },

      membersListRequest: (state, action: PayloadAction<supportGroupsSagaTypes.IGroupInfoSagaTypes>) => {
         return {
            ...state,
            membersList: {
               ...state.membersList,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      membersListSuccess: (state, action: PayloadAction<supportGroupsReducerTypes.IGroupInfoSuccessPayload>) => {
         return {
            ...state,
            membersList: {
               ...action.payload,
               success: messages.success,
               error: '',
               isLoading: false,
            },
         };
      },
      membersListFailed: (state, action: PayloadAction<supportGroupsReducerTypes.IGroupInfoFailurePayload>) => {
         return {
            ...state,
            membersList: {
               ...state.membersList,
               error: action.payload?.error,
               isLoading: false,
            },
         };
      },

      groupsInfoDetailsReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
