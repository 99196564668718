import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_JOIN_CALL } from 'reduxToolKit/actions';
import { commonSlice, joinCallAppointmentSlice } from 'reduxToolKit/reducers';
import { joinVideoCallSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getJoinCallList, getJoinCallSuccess, getJoinCallFailed } = joinCallAppointmentSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const joinAppointmentCall = function* (payload: joinVideoCallSagaTypes.IGetVideoAppointments) {
   yield put(getJoinCallList(payload));
   try {
      const response: AxiosResponse = yield apiCall({ ...API.joinCall });
      if (response.status === 200) {
         yield put(getJoinCallSuccess(response.data));
      } else {
         yield put(getJoinCallFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeJoinAppointmentCallRequest = function* () {
   yield takeEvery(GET_JOIN_CALL, joinAppointmentCall);
};
