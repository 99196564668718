import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { askDocReducerTypes, askDocSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: askDocReducerTypes.IAskADocPhyPanelListReducerPayload = {
   phyPanelList: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      pagination: {
         current_page: null,
         last_page: null,
         first_page_url: '',
         last_page_url: '',
         next_page_url: '',
         prev_page_url: '',
         total: 0,
      },
   },
   panelPhysicianList: {
      isLoading: false,
      message: '',
      list: [],
      error: '',
   },
};

export const askADocPhyPanelListSlice = createSlice({
   name: 'askADocPhyPanelList',
   initialState: initialState,
   reducers: {
      askADocPhyPanelListRequest: (state, action) => {
         return {
            ...state,
            phyPanelList: {
               ...state.phyPanelList,
               ...action.payload,
               isLoading: true,
               list: action.payload.page && action.payload.page === 1 ? [] : state.phyPanelList.list,
            },
         };
      },
      askADocPhyPanelListSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            phyPanelList: {
               ...state.phyPanelList,
               pagination: { ...payload.pagination },
               list:
                  payload.pagination.current_page === 1 ? payload.list : [...state.phyPanelList.list, ...payload.list],
               isLoading: false,
               message: payload.message || messages.success,
            },
         };
      },
      askADocPhyPanelListFailed: (state, action) => {
         return {
            ...state,
            phyPanelList: {
               ...state.phyPanelList,
               isLoading: false,
               error: action.payload.error,
            },
         };
      },
      askADocPhyPanelListReset: (state) => {
         return {
            ...state,
            phyPanelList: {
               ...initialState.phyPanelList,
            },
         };
      },

      askADocPanelPhysicianListRequest: (
         state,
         action: PayloadAction<askDocSagaTypes.IAskADocPanelPhysicianListSagaPayload>
      ) => {
         return {
            ...state,
            panelPhysicianList: {
               ...state.panelPhysicianList,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      askADocPanelPhysicianListSuccess: (
         state,
         action: PayloadAction<askDocReducerTypes.IAskADocPanelReqListSuccessPayload>
      ) => {
         return {
            ...state,
            panelPhysicianList: {
               ...state.panelPhysicianList,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      askADocPanelPhysicianListFailure: (
         state,
         action: PayloadAction<askDocReducerTypes.IAskADocPanelReqListFailurePayload>
      ) => {
         return {
            ...state,
            panelPhysicianList: {
               ...state.panelPhysicianList,
               ...action.payload,
               message: '',
               isLoading: false,
            },
         };
      },
      askADocPanelPhysicianListReset: (state) => {
         return {
            ...state,
            panelPhysicianList: {
               ...initialState.panelPhysicianList,
            },
         };
      },
   },
});
