import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { otpProcessReducerTypes } from 'types';

const initialState: otpProcessReducerTypes.IVerifyOtpReducerTypes = {
   isLoading: false,
   data: null,
   message: '',
   error: '',
};

export const otpProcessSlice = createSlice({
   name: 'verifyOtp',
   initialState: initialState,
   reducers: {
      verifyOtpRequest: (state, action: PayloadAction<otpProcessReducerTypes.IVerifyOTP>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      verifyOtpSuccess: (state, action: PayloadAction<otpProcessReducerTypes.verifyOtpSuccessPayload>) => {
         return {
            ...state,
            message: action.payload?.message,
            data: action.payload?.data,
            error: '',
         };
      },
      verifyOtpFailed: (state, action: PayloadAction<otpProcessReducerTypes.verifyOtpFailurePayload>) => {
         return {
            ...state,
            message: '',
            data: null,
            error: action.payload.error,
         };
      },
      resetVerifyOtp: () => {
         return {
            ...initialState,
         };
      },
   },
});
