import React, { useCallback, useEffect, useState } from 'react';

import StagingIcon from 'assets/fill-profile/staging_icon.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import { useDispatch, useSelector } from 'react-redux';
import { addStagingAction } from 'reduxToolKit/actions';
import { stagingSlice } from 'reduxToolKit/reducers';
import { SelectPicker } from 'rsuite';
import { myProfileSagaTypes, storeTypes } from 'types';
import { DONE, NEXT, SUBMIT } from 'utils/constants/profileConstants';

const StagingSection = (props: any) => {
   const dispatch = useDispatch();
   const [errorMessage, setErrorMessage] = useState<string>('');
   const [stagingValue, setStagingValue] = useState<number>(0);

   const { message: stagingMessage, error: stagingError } = useSelector((state: storeTypes.IStore) => state.staging);
   const { stagingReset } = stagingSlice.actions;

   const hideAlert = (type: string) => {
      if (type === 'stagingSuccess') {
         if (props?.isFromStaging) {
            props?.closeModelMyProfile();
            props.getUserData();
         }
         dispatch(stagingReset());
      } else if (type === 'stagingError') {
         dispatch(stagingReset());
         props?.setIsLoader(false);
      }
   };

   const changeValue = useCallback((value: number) => {
      setErrorMessage('');
      setStagingValue(value);
   }, []);

   const clickNext = useCallback(() => {
      if (!stagingValue) {
         setErrorMessage('Staging cannot be blank');
         return;
      }
      props?.setIsLoader(true);
      const data: myProfileSagaTypes.IStagingProps = {
         staging: stagingValue,
      };
      dispatch(addStagingAction(data));
   }, [stagingValue]);

   const gotoNavigation = useCallback(() => {
      if (props?.isFromClinicalTrials) {
         props.navigation(props?.nextSectionClinicalTrails);
      } else {
         props.navigation(props.nexSection);
      }
   }, []);

   useEffect(() => {
      if (props.isStagingValid) {
         gotoNavigation();
      }
      //edit
      setStagingValue(parseInt(props?.userDetail?.staging) || 0);
   }, []);

   useEffect(() => {
      if (stagingMessage && props?.isFromStaging) {
         const getAlert = () => (
            <InfoTimerAlert
               success
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={stagingMessage}
               onConfirm={() => hideAlert('stagingSuccess')}
            />
         );
         props?.setAlert(getAlert);
         props?.setIsLoader(false);
         props.currentModalClose();
      } else if (
         stagingMessage &&
         !props.isDisplay &&
         !props.isPrimary &&
         !props.isAddress &&
         !props.isDiagnosisDateValid &&
         !props?.isStagingValid &&
         !props?.isCancerStatusValid
      ) {
         props.setIsLoader(false);
         gotoNavigation();
      } else if (stagingMessage) {
         props.setIsLoader(false);
         gotoNavigation();
      }
   }, [stagingMessage]);

   useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
         document.body.style.overflow = 'scroll';
      };
   }, []);

   return (
      <div>
         {stagingError ? (
            <InfoTimerAlert
               error
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={stagingError}
               onConfirm={() => hideAlert('stagingError')}
            />
         ) : null}
         <div className="col-12">
            <img className="common-race-icon staging-icon mt--2" src={StagingIcon} alt="icon" />
         </div>
         <div className="col-12">
            <p className="common-race-title-text staging-title-text">Staging</p>
         </div>
         <div className="col-12">
            <p className="common-race-paragraph-text staging-paragraph-text  p-2">Please select your cancer stage</p>
         </div>
         <div className="col-12">
            <SelectPicker
               preventOverflow={true}
               cleanable={false}
               autoFocus={true}
               maxHeight={150}
               onChange={changeValue}
               value={stagingValue}
               data={[
                  {
                     label: '1',
                     value: 1,
                  },
                  {
                     label: '2',
                     value: 2,
                  },
                  {
                     label: '3',
                     value: 3,
                  },
                  {
                     label: '4',
                     value: 4,
                  },
               ]}
               placement="topStart"
               searchable={false}
               renderMenu={(menu: any) => {
                  return menu;
               }}
            />
         </div>
         {errorMessage ? <div className="error-msg ml-4 mt-3 mr-4"> {errorMessage || ''}</div> : null}
         <div className="button">
            <button type="submit" className="button-start" onClick={clickNext}>
               {props?.currentStateArray &&
               props?.currentStateArray[props?.currentStateArray?.length - 1] === props?.currentState
                  ? DONE
                  : props?.isFromStaging
                  ? SUBMIT
                  : NEXT}
            </button>
         </div>
      </div>
   );
};

export default StagingSection;
