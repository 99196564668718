import React, { memo } from 'react';

import { Box, Grid } from '@material-ui/core';
import DotLoader from 'component/loader/DotLoader';
import AdminNoData from 'component/widget/AdminNoData';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { clinicalTrialsInterestedListSagaAction } from 'reduxToolKit/actions';
// import { clinicalTrialsSlice } from 'reduxToolKit/reducers';
import { clinicalTrialsPageTypes, storeTypes } from 'types';
// import { listParseAndStringify } from 'utils/helpers/commonHelpers';

import ClinicalTrialsListItem from './ClinicTrialListItem';

const ClinicalTrialsInterestedCard = memo((props: any) => {
   const dispatch = useDispatch();

   const { list: clinicalTrialsInterestedList, isLoading: isLoadingInterestedList, pagination } = useSelector(
      (state: storeTypes.IStore) => state.clinicalTrials.clinicalTrialsInterestedList
   );
   const { current_page, last_page } = pagination;

   // const { clinicalTrialsInterestedListUpdate } = clinicalTrialsSlice.actions;

   const getClinicalTrialsInterestedList = (page: number, searchWord: string) => {
      const data: clinicalTrialsPageTypes.IClinicalTrialsInterestedListParams = {
         params: { page, limit: 15, search_word: searchWord },
      };
      dispatch(clinicalTrialsInterestedListSagaAction(data));
   };

   /* const clickViewMoreInterventions = (item: any, viewMore: boolean) => {
      const clinicalTrialsListArrayCopy = listParseAndStringify(clinicalTrialsInterestedList);
      const index = clinicalTrialsListArrayCopy.findIndex((x: any) => x.id === item.id);
      const interestDataLocal = listParseAndStringify(item);
      interestDataLocal.intervention_load_more = viewMore;
      clinicalTrialsListArrayCopy.splice(index, 1, interestDataLocal);
      dispatch(clinicalTrialsInterestedListUpdate(clinicalTrialsListArrayCopy));
   };

   const clickViewMoreConditions = (item: any, viewMore: boolean) => {
      const clinicalTrialsListArrayCopy = listParseAndStringify(clinicalTrialsInterestedList);
      const index = clinicalTrialsListArrayCopy.findIndex((x: any) => x.id === item.id);
      const interestDataLocal = listParseAndStringify(item);
      interestDataLocal.condition_load_more = viewMore;
      clinicalTrialsListArrayCopy.splice(index, 1, interestDataLocal);
      dispatch(clinicalTrialsInterestedListUpdate(clinicalTrialsListArrayCopy));
   }; */

   return (
      <Box className="cards-box style-7" id="clinicalTrialsScroll">
         <Box className="relative">
            <Box className="card-container" px={1} mb={2}>
               {isLoadingInterestedList && clinicalTrialsInterestedList?.length === 0 && <DotLoader />}
               {!isLoadingInterestedList && clinicalTrialsInterestedList?.length === 0 && (
                  <AdminNoData
                     title={'No clinical trials study has been marked as interested.'}
                     message={`Switch to all tab and mark your interest for the clinical trials study`}
                  />
               )}
               <InfiniteScroll
                  dataLength={clinicalTrialsInterestedList ? clinicalTrialsInterestedList.length : 0}
                  next={() => getClinicalTrialsInterestedList(current_page ? current_page + 1 : 1, props?.searchWord)}
                  hasMore={current_page && last_page && current_page < last_page ? true : false}
                  loader={<DotLoader />}
                  style={{
                     overflow: 'hidden',
                  }}
                  scrollableTarget="clinicalTrialsScroll"
               >
                  <Grid container direction="column" spacing={2}>
                     {clinicalTrialsInterestedList.map((item: any, i: number) => {
                        return (
                           <Grid item key={i}>
                              <ClinicalTrialsListItem
                                 i={i}
                                 item={item}
                                 /* clickViewMoreConditions={clickViewMoreConditions}
                                 clickViewMoreInterventions={clickViewMoreInterventions} */
                              />
                           </Grid>
                        );
                     })}
                  </Grid>
               </InfiniteScroll>
            </Box>
         </Box>
      </Box>
   );
});

export default ClinicalTrialsInterestedCard;
