import React, { useCallback, useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import MccpLogo from 'assets/medicationSavings/mccp_logo.svg';
import PillMedicationListIcon from 'assets/pill-remainder/pillMedicationListIcon.svg';
import PilMediDeleteIcon from 'assets/pill-remainder/pillMediDeleteIcon.svg';
import PillMediRightArrow from 'assets/pill-remainder/pillMediRightArrow.svg';
import PilMediEditIcon from 'assets/pill-remainder/pilMediEditIcon.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import Loading from 'component/widget/Loading';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { medicationListSagaAction, pillRemainderNonCancerDeleteSagaAction } from 'reduxToolKit/actions';
import { medicationListSlice, pillRemainderNonCancerDeleteSlice } from 'reduxToolKit/reducers';
import { pillRemainderSagaTypes, storeTypes } from 'types';
import { TIME_ONLY_ADD_DATE } from 'utils/constants/dateConstants';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';
import { convertOnly12HrsTime, convertOnlyAMPMTime, convertUTCtoLocalAndAddDays } from 'utils/helpers/dateHelpers';
import {
   getPillRemainderDays,
   getPillReminderTypes,
   getPillSpecificTypeCount,
} from 'utils/helpers/pillRemainderHelpers';

const PillMediListItem = (props: any) => {
   const dispatch = useDispatch();
   const [alert, setAlert] = useState<any | null>(null);
   const { activeInactiveMedi, clickEditActiveAndInActiveMedicine, clickActiveAndInActiveMedicine } = props;

   const {
      message: pillRemainderNonCancerDeleteMessage,
      error: pillRemainderNonCancerDeleteError,
      isLoading: pillRemainderNonCancerDeleteIsLoading,
   } = useSelector((state: storeTypes.IStore) => state.pillRemainderNonCancerDelete);
   const { pillRemainderNonCancerDeleteReset } = pillRemainderNonCancerDeleteSlice.actions;

   const { medicationsListReset } = medicationListSlice.actions;

   const confirmToClose = () => {
      const getAlert = () => (
         <SweetAlert
            info
            confirmBtnText="Yes"
            showCloseButton={false}
            showCancel
            cancelBtnBsStyle="cancelBtn"
            confirmBtnBsStyle="successBtn"
            cancelBtnText="No"
            closeOnClickOutside={false}
            focusCancelBtn
            allowEscape={false}
            onCancel={() => {
               setAlert(null);
            }}
            title="Are you sure"
            onConfirm={() => {
               setAlert(null);
               clickNext();
            }}
         >
            <div className="closed_content">{`You want to delete this medication?`}</div>
         </SweetAlert>
      );
      setAlert(getAlert);
   };

   const clickNext = useCallback(() => {
      const data: pillRemainderSagaTypes.IPillRemainderDeleteParams = {
         id: activeInactiveMedi?.id || 0,
      };
      dispatch(pillRemainderNonCancerDeleteSagaAction(data));
   }, [activeInactiveMedi]);

   const hideAlert = (type: string) => {
      if (type === 'pillRemainderNonCancerDeleteSuccess') {
         dispatch(pillRemainderNonCancerDeleteReset());
         // non cancer medication list
         dispatch(medicationsListReset());
         const params: pillRemainderSagaTypes.IPillActiveAndInactiveMedicineList = {
            from_type: 'non_cancer',
         };
         dispatch(medicationListSagaAction(params));
      } else if (type === 'pillRemainderNonCancerDeleteError') {
         dispatch(pillRemainderNonCancerDeleteReset());
      }
   };

   return (
      <Box className="pill-medications-list-item pill-medications-list-item-hover">
         {alert}
         {pillRemainderNonCancerDeleteIsLoading ? <Loading /> : null}
         {pillRemainderNonCancerDeleteMessage ? (
            <InfoTimerAlert
               success
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={pillRemainderNonCancerDeleteMessage}
               onConfirm={() => hideAlert('pillRemainderNonCancerDeleteSuccess')}
            />
         ) : null}
         {pillRemainderNonCancerDeleteError ? (
            <InfoTimerAlert
               error
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={pillRemainderNonCancerDeleteError}
               onConfirm={() => hideAlert('pillRemainderNonCancerDeleteError')}
            />
         ) : null}
         <Grid container direction="row" spacing={3}>
            <Grid item xs={2}>
               <img src={PillMedicationListIcon} alt="img" />
            </Grid>
            <Grid item xs={props?.isFromMyProfile ? 8 : props?.isFromHome ? 10 : 9}>
               <Box className={props?.isFromHome ? 'list-item-first-text-height' : ''}>
                  <Typography
                     className={`list-item-first-text ${
                        props?.isFromHome
                           ? (activeInactiveMedi?.name || '').length < 35
                              ? 'list-item-first-text-align'
                              : ''
                           : ''
                     }`}
                  >
                     {props?.isFromHome ? activeInactiveMedi?.name || '' : activeInactiveMedi?.name || ''}
                  </Typography>
               </Box>
               <Typography className="list-item-second-text">
                  {getPillReminderTypes(
                     activeInactiveMedi?.others_type || activeInactiveMedi?.type || '',
                     activeInactiveMedi
                  )}
               </Typography>
            </Grid>
            {props?.isFromMyProfile ? (
               <>
                  <Grid item xs={1} className="list-item-edit-grid">
                     <Box className="list-item-edit-div">
                        <img
                           src={PilMediEditIcon}
                           alt="img"
                           className="list-item-edit-div-img hand-i"
                           onClick={() => {
                              clickEditActiveAndInActiveMedicine(activeInactiveMedi);
                           }}
                        />
                     </Box>
                  </Grid>
                  <Grid item xs={1} className="list-item-edit-grid">
                     <Box className="list-item-edit-div">
                        <img
                           src={PilMediDeleteIcon}
                           alt="img"
                           className="list-item-edit-div-img hand-i"
                           onClick={() => {
                              confirmToClose();
                           }}
                        />
                     </Box>
                  </Grid>
               </>
            ) : props?.isFromHome ? null : (
               <Grid item xs={1} className="list-item-edit-grid">
                  <Box className="list-item-edit-div">
                     <img
                        src={PilMediEditIcon}
                        alt="img"
                        className="list-item-edit-div-img hand-i"
                        onClick={() => {
                           clickEditActiveAndInActiveMedicine(activeInactiveMedi);
                        }}
                     />
                  </Box>
               </Grid>
            )}
         </Grid>
         <Box className="list-time-root-div">
            {props?.isFromMyProfile || props?.isFromHome ? null : activeInactiveMedi?.medication_saving
                 ?.medication_saving_detail?.url ? (
               <Box
                  className="edit-div-logo-div hand-i"
                  onClick={() => {
                     if (activeInactiveMedi?.medication_saving?.medication_saving_detail?.url) {
                        window.open(activeInactiveMedi?.medication_saving?.medication_saving_detail?.url, '_blank');
                     }
                  }}
               >
                  <img src={MccpLogo} alt="img" className="list-item-edit-div-logo-img" />
                  <img src={PillMediRightArrow} alt="img" />
               </Box>
            ) : null}
            <Box className="list-time-div style-7">
               <Grid container direction="row">
                  {activeInactiveMedi?.first_dose_time ? (
                     <Grid item>
                        <Box className="list-item-time">
                           <Typography className="list-item-time-item">
                              {`${convertOnly12HrsTime(
                                 TIME_ONLY_ADD_DATE + activeInactiveMedi?.first_dose_time
                              )} ${convertOnlyAMPMTime(TIME_ONLY_ADD_DATE + activeInactiveMedi?.first_dose_time)}`}
                           </Typography>
                        </Box>
                     </Grid>
                  ) : null}
                  {activeInactiveMedi?.pill_rem_med_times?.length > 0
                     ? activeInactiveMedi?.pill_rem_med_times.map((time: any, k: number) => {
                          return (
                             <Grid item key={k}>
                                <Box className="list-item-time">
                                   <Typography className="list-item-time-item">{`${convertOnly12HrsTime(
                                      TIME_ONLY_ADD_DATE + time?.time
                                   )} ${convertOnlyAMPMTime(TIME_ONLY_ADD_DATE + time?.time)}`}</Typography>
                                </Box>
                             </Grid>
                          );
                       })
                     : null}
                  {activeInactiveMedi?.pill_rem_med_days?.length > 0
                     ? listParseAndStringify(activeInactiveMedi?.pill_rem_med_days)
                          .sort((a: any, b: any) => (a.day > b.day ? 1 : -1))
                          .map((day: any, k: number) => {
                             return (
                                <Grid item key={k}>
                                   <Box className="list-item-day">
                                      <Typography className="list-item-day-item">
                                         {getPillRemainderDays(String(day?.day))}
                                      </Typography>
                                   </Box>
                                </Grid>
                             );
                          })
                     : null}
                  {activeInactiveMedi?.start_date ? (
                     <>
                        <Grid item>
                           <Box className="list-item-day">
                              <Typography className="list-item-day-item">
                                 {`${convertUTCtoLocalAndAddDays(
                                    activeInactiveMedi?.start_date,
                                    getPillSpecificTypeCount(
                                       activeInactiveMedi?.others_type || activeInactiveMedi?.type,
                                       activeInactiveMedi
                                    )
                                 )}`}
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item>
                           <Box className="list-item-day">
                              <Typography className="list-item-day-item">
                                 {`${convertUTCtoLocalAndAddDays(
                                    activeInactiveMedi?.start_date,
                                    getPillSpecificTypeCount(
                                       activeInactiveMedi?.others_type || activeInactiveMedi?.type,
                                       activeInactiveMedi,
                                       true
                                    )
                                 )}`}
                              </Typography>
                           </Box>
                        </Grid>
                     </>
                  ) : null}
                  <Grid item>
                     <Box style={{ marginRight: '40px' }}></Box>
                  </Grid>
               </Grid>
            </Box>
            <Box className="pill-rem-time-list-more-gradient"></Box>
         </Box>
         {activeInactiveMedi?.dose_information ? (
            <Box className="list-item-third">
               <Typography className="list-item-third-text">{activeInactiveMedi?.dose_information || ''}</Typography>
               {props?.isFromHome ? (
                  40 <= (activeInactiveMedi?.dose_information || '').toUpperCase().length ? (
                     <Typography
                        className="list-item-third-text-more hand-i"
                        onClick={() => {
                           clickActiveAndInActiveMedicine(activeInactiveMedi);
                        }}
                     >
                        more
                     </Typography>
                  ) : null
               ) : 50 <= (activeInactiveMedi?.dose_information || '').toUpperCase().length ? (
                  <Typography
                     className="list-item-third-text-more hand-i"
                     onClick={() => {
                        clickActiveAndInActiveMedicine(activeInactiveMedi);
                     }}
                  >
                     more
                  </Typography>
               ) : null}
            </Box>
         ) : (
            <Box className="list-item-third">
               <Typography className="list-item-third-text list-item-third-no-text">{` `}</Typography>
            </Box>
         )}
      </Box>
   );
};

export { PillMediListItem };
