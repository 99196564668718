import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deviceSagaTypes, deviceReducesTypes } from 'types';

const initialState: deviceReducesTypes.IBloodPressureReducerTypes = {
   getBloodPressure: { isLoading: false, message: '', data: null, error: '' },
   getBloodPressureStatistics: { isLoading: false, message: '', data: null, error: '' },
};
export const deviceBloodPressureSlice = createSlice({
   name: 'deviceBloodPressure',
   initialState: initialState,
   reducers: {
      getBloodPressureRequest: (state, action: PayloadAction<deviceSagaTypes.IGetBloodPressure>) => {
         return {
            ...state,
            getBloodPressure: {
               ...state.getBloodPressure,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getBloodPressureSuccess: (state, action: any) => {
         return {
            ...state,
            getBloodPressure: {
               ...state.getBloodPressure,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      getBloodPressureFailed: (state, action) => {
         return {
            ...state,
            getBloodPressure: {
               ...state.getBloodPressure,
               isLoading: false,
               error: action.payload.error,
               message: '',
            },
         };
      },
      getBloodPressureReset: (state) => {
         return {
            ...state,
            getBloodPressure: initialState.getBloodPressure,
         };
      },

      getBloodPressureStatisticsRequest: (
         state,
         action: PayloadAction<deviceSagaTypes.IGetBloodPressureStatistics>
      ) => {
         return {
            ...state,
            getBloodPressureStatistics: {
               ...state.getBloodPressureStatistics,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getBloodPressureStatisticsSuccess: (state, action: any) => {
         return {
            ...state,
            getBloodPressureStatistics: {
               ...state.getBloodPressureStatistics,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      getBloodPressureStatisticsFailed: (state, action) => {
         return {
            ...state,
            getBloodPressureStatistics: {
               ...state.getBloodPressureStatistics,
               isLoading: false,
               error: action.payload.error,
               message: '',
            },
         };
      },
      getBloodPressureStatisticsReset: (state) => {
         return {
            ...state,
            getBloodPressureStatistics: initialState.getBloodPressureStatistics,
         };
      },
   },
});
