import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { PROFILE_VERIFY_OTP } from 'reduxToolKit/actions';
import { commonSlice, otpProcessSlice } from 'reduxToolKit/reducers';
import { otpProcessReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { verifyOtpRequest, verifyOtpSuccess, verifyOtpFailed } = otpProcessSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const verifyOtpSaga = function* (payloadState: PayloadAction<otpProcessReducerTypes.IVerifyOTP>) {
   yield put(verifyOtpRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.verifyOtp });
      if (response.status === 200) {
         const responseData = {
            data: response.data?.data,
            message: response.data?.message || messages.success,
         };
         yield put(verifyOtpSuccess(responseData));
      } else {
         yield put(verifyOtpFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeVerifyOtpSaga = function* () {
   yield takeEvery(PROFILE_VERIFY_OTP, verifyOtpSaga);
};
