import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ACCEPT_CONNECTION } from 'reduxToolKit/actions';
import { acceptConnectionPostSlice, commonSlice } from 'reduxToolKit/reducers';
import { postShareSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { acceptConnectionRequest, acceptConnectionSuccess, acceptConnectionFailed } = acceptConnectionPostSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const acceptConnectionSaga = function* (payloadState: PayloadAction<postShareSagaTypes.IAcceptConnection>) {
   yield put(acceptConnectionRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const payloadData = payloadState.payload;
      const data = {
         id: payloadData.id,
         from_user_id: payloadData.from_user_id,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.acceptConnection });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message || messages.success,
            data: null,
         };
         yield put(acceptConnectionSuccess(resObj));
      } else {
         yield put(acceptConnectionFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAcceptConnection = function* () {
   yield takeEvery(ACCEPT_CONNECTION, acceptConnectionSaga);
};
