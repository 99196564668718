import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { feedReducerTypes } from 'types';
import { CommentReplies, Feed, PollOptions, Post, Comment, User } from 'types/modelHelpers';
import { FeedReactions } from 'utils/constants/feedReactions';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';

const initialState: feedReducerTypes.IFeedPostListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   total_records: 0,
   page: 1,
   feedPostShareCountSuccess: false,
   feedReactionsTimeout: null,
};
export const feedPostListSlice = createSlice({
   name: 'feedList',
   initialState: initialState,
   reducers: {
      feedPostListRequest: (state: any, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      feedPostListSuccess: (state: any, action: PayloadAction<any>) => {
         const { payload } = action;
         const array = getFeedListArray(payload.list) || payload.list;
         return {
            ...state,
            list: state.page === 1 ? array : [...state.list, ...array],
            total_records: payload.total_records,
            message: payload.message,
            isLoading: false,
         };
      },
      feedCommentsAddUpdateData: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedCommentsAddUpdateData(state.list, payload.payload, payload.payloadRequest);
         return {
            ...state,
            list: array,
         };
      },
      feedDeletePostCommentMessage: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedDeletePostCommentMessage(state.list, payload);
         return {
            ...state,
            list: array,
         };
      },
      feedCommentsListMessage: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedCommentsListMessage(state.list, payload.payload, payload.commentsList);
         return {
            ...state,
            list: array,
         };
      },
      feedReplyCommentsListMessage: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedReplyCommentsListMessage(state.list, payload.payload, payload.commentsList);
         return {
            ...state,
            list: array,
         };
      },
      feedCommentReplyAdd: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedCommentReplyAdd(state.list, payload.payloadData, payload.requestData);
         return { ...state, list: array };
      },
      feedCommentReplyUpdate: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedCommentReplyUpdate(state.list, payload.payloadData, payload.requestData);
         return { ...state, list: array };
      },
      feedCommentReplyDelete: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedCommentReplyDelete(state.list, payload);
         return { ...state, list: array };
      },
      feedOnClickReactions: (state: any, action: any) => {
         const { payload } = action;
         const array = getOnClickReactions(state.list, payload.payloadPost, payload.payloadReactionType);
         return { ...state, list: array };
      },
      feedPostCommentCount: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedPostCommentCount(state.list, payload.payloadPost, payload.payloadPostCommentCount);
         return { ...state, list: array };
      },
      feedPostShareCount: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedPostShareCount(state.list, payload.payloadPost, payload.payloadPostShareCount);
         return { ...state, list: array, feedPostShareCountSuccess: true };
      },
      feedPostShareCountSuccessReset: (state: any) => {
         return { ...state, feedPostShareCountSuccess: false };
      },
      feedEditPostSuccess: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedEditPostSuccess(state.list, payload.payloadPost, payload.payloadEditPost);
         return { ...state, list: array };
      },
      feedDeletePostSuccess: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedDeletePostSuccess(state.list, payload);
         return { ...state, list: array };
      },
      feedReportPostSuccess: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedReportPostSuccess(state.list, payload);
         return { ...state, list: array };
      },
      feedReportPostCommentSuccess: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedReportPostCommentSuccess(state.list, payload);
         return { ...state, list: array };
      },
      feedReportPostCommentReplySuccess: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedReportPostCommentReplySuccess(state.list, payload);
         return { ...state, list: array };
      },
      feedSuccessAlertMyNetwork: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedSuccessAlertMyNetwork(
            state.list,
            payload.connectionSuccessPost,
            payload.connectionStatus
         );
         return { ...state, list: array };
      },
      feedPostCommentTagUsersListUpdate: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedPostCommentTagUsersListUpdate(
            state.list,
            payload.payloadPostId,
            payload.payloadTagUsersListArray
         );
         return { ...state, list: array };
      },
      feedPostCommentReplyTagUsersListUpdate: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedPostCommentReplyTagUsersListUpdate(
            state.list,
            payload.payloadPostId,
            payload.payloadCommentId,
            payload.payloadTagUsersListArray
         );
         return { ...state, list: array };
      },
      feedSavePostListUpdate: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedSavePostListUpdate(state.list, payload.postDataId, payload.isSaved, payload.mySavedPost);
         return { ...state, list: array };
      },
      feedClickPostPolling: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedClickPostPolling(
            state.list,
            payload.payloadPost,
            payload.pollOptionObj,
            payload.pollCount
         );
         return { ...state, list: array };
      },
      feedListLoad: (state: any, action: any) => {
         const { payload } = action;
         return { ...state, list: payload };
      },
      feedOnePostListLoad: (state: any, action: any) => {
         const { payload } = action;
         return { ...state, list: payload.payloadPostList, total_records: payload.payloadPostTotalRecords };
      },
      feedPollOptionError: (state: any, action: any) => {
         const { payload } = action;
         const array = getFeedPollOptionError(state.list, payload);
         return {
            ...state,
            list: array,
            isLoading: false,
         };
      },
      feedEduLibListMessage: (state: any, action: any) => {
         const { payload } = action;
         const array = feedEduLibraryListUpdate(state.list, payload.payload, payload.eduLibList);
         return {
            ...state,
            list: array,
         };
      },
      feedPostListFailed: (state: any, action: PayloadAction<feedReducerTypes.IFeedPostListReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      feedPostListResetMessage: (state: any) => {
         return {
            ...state,
            message: '',
            error: '',
            isLoading: false,
         };
      },
      setFeedReactionTimeout: (state: any, action: any) => {
         return {
            ...state,
            feedReactionsTimeout: action.payload,
         };
      },
      deleteFeedReactionTimeout: (state: any) => {
         return {
            ...state,
            feedReactionsTimeout: null,
         };
      },
      feedPostListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

// functions start
export function getPollTotalCount(pollOptionsArray: PollOptions[]) {
   let pollCount = 0;
   pollOptionsArray.forEach((pollOption: PollOptions) => {
      pollCount = pollCount + pollOption.voted_count;
   });
   return pollCount;
}

export function getFeedPollOptionError(postList: any[], payload: any) {
   const arrayCopy = listParseAndStringify(postList);
   const index = arrayCopy.findIndex((x: Feed) => x.post.id === payload?.post_id);
   const pollOptionsArray = listParseAndStringify(arrayCopy[index]?.post?.poll_options || []);
   let pollCount = getPollTotalCount(pollOptionsArray);
   const pollOptionsModArray = pollOptionsArray.map((pollOption: PollOptions) => {
      if (pollOption.id === payload?.id) {
         let voteCount = pollOption?.voted_count || 0;
         voteCount = voteCount - 1;
         pollCount = pollCount - 1;
         pollOption.voted_count = voteCount;
         pollOption.is_responded = false;
      } else {
         const voteCount = pollOption?.voted_count || 0;
         pollOption.voted_count = voteCount;
      }
      return pollOption;
   });
   if (index !== -1) {
      postList[index].post.poll_options = pollOptionsModArray;
      return postList;
   } else {
      return postList;
   }
}

export function getFeedListArray(postList: any[]) {
   const postsArray: any[] = [];
   const arrayCopy = listParseAndStringify(postList);
   arrayCopy.forEach((feed: any) => {
      if (feed && feed.post) {
         const commentsArray: any[] = [];
         feed?.post?.comments.forEach((comment: any) => {
            comment.comment_replys = [];
            commentsArray.push(comment);
         });
         feed.post.comments = commentsArray;
         postsArray.push(feed);
      }
   });
   return postsArray;
}

export function getFeedCommentsAddUpdateData(postList: any[], payload: any, payloadRequest: any) {
   const commentData = payload.data.comment_data;
   const commentCount = payload.data.comment_count;
   const postId = payloadRequest.post_id;
   const commentId = payloadRequest.id;
   const clickEditComment = payloadRequest.clickEditComment;

   const index = postList.findIndex((x: Feed) => x.post.id === postId);
   const arrayCopy = listParseAndStringify(postList);
   if (!clickEditComment) {
      arrayCopy[index].post.comments.unshift(commentData);
      arrayCopy[index].post.comment_count = commentCount;
      arrayCopy[index].post.total_comment_count += 1;
      const lastDisplayedCommentId = arrayCopy[index]?.post?.last_displayed_comment_id || [];
      lastDisplayedCommentId.push(commentData.id);
      arrayCopy[index].post.last_displayed_comment_id = lastDisplayedCommentId;
      arrayCopy[index].post.last_displayed_comment = true;
   } else {
      const commentArray = arrayCopy[index]?.post?.comments || [];
      const indexComment = commentArray.findIndex((x: Comment) => x.id === commentId);
      commentArray[indexComment] = commentData;
      arrayCopy[index].post.comments = commentArray;
   }
   return arrayCopy || [];
}

export function getFeedDeletePostCommentMessage(postList: any[], payload: any) {
   const postId = payload.post_id;
   const commentId = payload.id;

   const arrayCopy = listParseAndStringify(postList);
   const index = arrayCopy.findIndex((x: Feed) => x.post.id === postId);

   const commentArray = arrayCopy[index]?.post?.comments || [];
   const indexComment = commentArray.findIndex((x: Comment) => x.id === commentId);

   if (indexComment !== -1) {
      commentArray.splice(indexComment, 1);
      arrayCopy[index].post.comments = commentArray;
      arrayCopy[index].post.last_comment_id = commentArray[commentArray.length - 1]?.id || null;
      arrayCopy[index].post.total_comment_count -= 1;
      arrayCopy[index].post.comment_count -= 1;
      return arrayCopy || [];
   } else {
      return arrayCopy || [];
   }
}

export function getFeedCommentsListMessage(postList: any[], payload: any, commentsArray: any[]) {
   const commentsList = listParseAndStringify(commentsArray || []);
   const postId = payload.id;
   const last_displayed_comment_id = payload.last_displayed_comment_id;
   const last_comment_id = payload.last_comment_id;

   const arrayCopy = listParseAndStringify(postList);
   const index = arrayCopy.findIndex((x: Feed) => x.post.id === postId);

   let commentArray: Comment[] = [];
   if (last_comment_id && last_comment_id > 0) {
      commentArray = arrayCopy[index]?.post?.comments || [];
   } else {
      const postComments = arrayCopy[index]?.post?.comments || [];
      for (let i = 0; i < postComments.length; i++) {
         for (let j = 0; j < last_displayed_comment_id.length; j++) {
            if (postComments[i].id === last_displayed_comment_id[j]) {
               commentArray.push(postComments[i]);
            }
         }
      }
   }
   // TODO for future use we enable for list reverse
   // const commentArray = arrayCopy[index].post.comments;
   commentArray.push(...commentsList);
   arrayCopy[index].post.comments = commentArray;
   arrayCopy[index].post.last_comment_id = commentsList[commentsList.length - 1].id || null;
   arrayCopy[index].post.last_displayed_comment_id = last_displayed_comment_id;
   arrayCopy[index].post.last_displayed_comment = true;
   return arrayCopy || [];
}

export function getFeedReplyCommentsListMessage(postList: any[], payload: any, replyCommentsArray: any[]) {
   const commentReplyList = listParseAndStringify(replyCommentsArray);
   const postId = payload?.post_id;
   const commentId = payload?.comment_id;

   const arrayCopy = listParseAndStringify(postList);
   const index = arrayCopy.findIndex((x: Feed) => x.post.id === postId);

   const commentArray = arrayCopy[index]?.post?.comments || [];
   const commentReplyIndex = commentArray.findIndex((x: Comment) => x.id === commentId);
   const commentReplyArray = commentArray[commentReplyIndex]?.comment_replys || [];
   if (commentReplyList?.length > 0) commentReplyArray.unshift(...commentReplyList);
   commentArray[commentReplyIndex].comment_replys = commentReplyArray;
   commentArray[commentReplyIndex].last_comment_reply_id = commentReplyArray[0]?.id || null;
   arrayCopy[index].post.comments = commentArray;
   return arrayCopy || [];
}

export function getFeedCommentReplyAdd(postList: any[], payload: any, payloadRequest: any) {
   const commentData = payload.data;
   const postId = payloadRequest.post_id;
   const commentId = payloadRequest.comment_id;

   const arrayCopy = listParseAndStringify(postList);
   const index = arrayCopy.findIndex((x: Feed) => x.post.id === postId);

   const commentArray = arrayCopy[index]?.post?.comments || [];
   const commentReplyIndex = commentArray.findIndex((x: Comment) => x.id === commentId);
   const commentReplyArray = commentArray[commentReplyIndex]?.comment_replys || [];
   commentReplyArray.push(commentData);
   commentArray[commentReplyIndex].comment_replys = commentReplyArray;
   commentArray[commentReplyIndex].reply_count += 1;
   arrayCopy[index].post.comments = commentArray;
   arrayCopy[index].post.total_comment_count += 1;
   return arrayCopy || [];
}

export function getFeedCommentReplyUpdate(postList: any[], payload: any, payloadRequest: any) {
   const commentData = payload.data;
   const postId = payloadRequest.post_id;
   const commentId = payloadRequest.comment_id;
   const commentReplyId = payloadRequest.comment_reply_id;

   const arrayCopy = listParseAndStringify(postList);
   const index = arrayCopy.findIndex((x: Feed) => x.post.id === postId);

   const commentArray = arrayCopy[index]?.post?.comments || [];
   const commentReplyIndex = commentArray.findIndex((x: Comment) => x.id === commentId);
   const commentReplyArray = commentArray[commentReplyIndex]?.comment_replys || [];
   const indexCommentReply = commentReplyArray.findIndex((x: CommentReplies) => x.id === commentReplyId);
   commentReplyArray[indexCommentReply] = commentData;
   commentArray[commentReplyIndex].comment_replys = commentReplyArray;
   arrayCopy[index].post.comments = commentArray;
   return arrayCopy || [];
}

export function getFeedCommentReplyDelete(postList: any[], payload: any) {
   const postId = payload.post_id;
   const commentId = payload.comment_id;
   const commentReplyId = payload.comment_reply_id;

   const arrayCopy = listParseAndStringify(postList);
   const index = arrayCopy.findIndex((x: Feed) => x.post.id === postId);

   const commentArray = arrayCopy[index]?.post?.comments || [];
   const commentReplyIndex = commentArray.findIndex((x: Comment) => x.id === commentId);
   const commentReplyArray = commentArray[commentReplyIndex]?.comment_replys || [];
   const indexCommentReply = commentReplyArray.findIndex((x: CommentReplies) => x.id === commentReplyId);
   if (indexCommentReply !== -1) {
      commentReplyArray.splice(indexCommentReply, 1);
      commentArray[commentReplyIndex].comment_replys = commentReplyArray;
      commentArray[commentReplyIndex].last_comment_reply_id = commentReplyArray[0]?.id || null;
      commentArray[commentReplyIndex].reply_count -= 1;
      arrayCopy[index].post.comments = commentArray;
      arrayCopy[index].post.total_comment_count -= 1;
      return arrayCopy || [];
   } else {
      return arrayCopy || [];
   }
}

export const clickReactions = (arrayCopy: any, post: Post, reactionType: string, index: any) => {
   arrayCopy[index].post.reaction_type = reactionType;
   if (!post?.reaction_type) {
      if (reactionType) {
         arrayCopy[index].post.like_count += 1;
      }
      // reactionType
      if (reactionType === FeedReactions.Like) {
         arrayCopy[index].post.reaction_like += 1;
      } else if (reactionType === FeedReactions.FoldedHands) {
         arrayCopy[index].post.reaction_folded_hands += 1;
      } else if (reactionType === FeedReactions.Brainy) {
         arrayCopy[index].post.reaction_brainy += 1;
      } else if (reactionType === FeedReactions.Hugs) {
         arrayCopy[index].post.reaction_hugs += 1;
      }
   } else if (post?.reaction_type) {
      if (reactionType === post?.reaction_type) {
         arrayCopy[index].post.like_count -= 1;
      }
      // reactionType
      if (reactionType === FeedReactions.Like) {
         arrayCopy[index].post.reaction_like += 1;
      } else if (reactionType === FeedReactions.FoldedHands) {
         arrayCopy[index].post.reaction_folded_hands += 1;
      } else if (reactionType === FeedReactions.Brainy) {
         arrayCopy[index].post.reaction_brainy += 1;
      } else if (reactionType === FeedReactions.Hugs) {
         arrayCopy[index].post.reaction_hugs += 1;
      }
      // post?.reaction_type
      if (post?.reaction_type === FeedReactions.Like) {
         arrayCopy[index].post.reaction_like -= 1;
      } else if (post?.reaction_type === FeedReactions.FoldedHands) {
         arrayCopy[index].post.reaction_folded_hands -= 1;
      } else if (post?.reaction_type === FeedReactions.Brainy) {
         arrayCopy[index].post.reaction_brainy -= 1;
      } else if (post?.reaction_type === FeedReactions.Hugs) {
         arrayCopy[index].post.reaction_hugs -= 1;
      }
   }
   return arrayCopy || [];
};

export const clickReactionsElse = (arrayCopy: any, reactionType: string, index: any) => {
   if (arrayCopy[index].post.like_count > 0) {
      arrayCopy[index].post.reaction_type = '';
      arrayCopy[index].post.like_count -= 1;
      if (reactionType === FeedReactions.Like) {
         arrayCopy[index].post.reaction_like -= 1;
      } else if (reactionType === FeedReactions.FoldedHands) {
         arrayCopy[index].post.reaction_folded_hands -= 1;
      } else if (reactionType === FeedReactions.Brainy) {
         arrayCopy[index].post.reaction_brainy -= 1;
      } else if (reactionType === FeedReactions.Hugs) {
         arrayCopy[index].post.reaction_hugs -= 1;
      }
      return arrayCopy || [];
   } else {
      return arrayCopy || [];
   }
};

export function getOnClickReactions(postList: any[], post: Post, reactionType: string) {
   let arrayCopy = listParseAndStringify(postList);
   if (!post?.reaction_type || reactionType !== post?.reaction_type) {
      const index = arrayCopy.findIndex((x: Feed) => x.post.id === post.id);
      arrayCopy = clickReactions(arrayCopy, post, reactionType, index);
      return arrayCopy || [];
   } else if (post?.reaction_type) {
      const index = arrayCopy.findIndex((x: Feed) => x.post.id === post.id);
      arrayCopy = clickReactionsElse(arrayCopy, reactionType, index);
      return arrayCopy || [];
   }
}

export function getFeedPostCommentCount(postList: any[], post: Post, postCommentCount: number) {
   const arrayCopy = listParseAndStringify(postList);
   const index = arrayCopy.findIndex((x: Feed) => x.post.id === post.id);
   arrayCopy[index].post.comment_count =
      Number(postCommentCount) !== 0 ? Number(postCommentCount) : arrayCopy[index].post.comment_count;
   return arrayCopy || [];
}

export function getFeedPostShareCount(postList: any[], post: Post, postShareCount: number) {
   const arrayCopy = listParseAndStringify(postList);
   const index = arrayCopy.findIndex((x: Feed) => x.post.id === post?.id);
   if (arrayCopy.length > 0) {
      arrayCopy[index].post.share_count = postShareCount;
   }
   return arrayCopy || [];
}

export function getFeedEditPostSuccess(postList: any[], post: Post, editPost: Post) {
   const array = listParseAndStringify(postList);
   const index = array.findIndex((x: Feed) => x.post.id === editPost.id);
   if (index !== -1) {
      array.splice(index, 1, post);
      return array || [];
   } else {
      return array || [];
   }
}

export function getFeedDeletePostSuccess(postList: any[], post: Post) {
   const array = listParseAndStringify(postList);
   const deletePostList = array.filter((list: Feed) => list.post?.id !== post.id);
   return deletePostList;
}

export function getFeedReportPostSuccess(postList: any[], postId: number) {
   const array = listParseAndStringify(postList);
   const reportPostList = array.filter((list: Feed) => list.post?.id !== postId);
   return reportPostList;
}

export function getFeedReportPostCommentSuccess(postList: any[], payload: any) {
   const arrayCopy = listParseAndStringify(postList);
   const index = arrayCopy.findIndex((x: Feed) => x.post.id === payload?.reportPostId);

   const commentArray = arrayCopy[index]?.post?.comments || [];
   const indexComment = commentArray.findIndex((x: Comment) => x.id === payload?.reportCommentId);

   if (indexComment !== -1) {
      commentArray[indexComment].is_reported_comment = 1;
      arrayCopy[index].post.comments = commentArray;
      return arrayCopy || [];
   } else {
      return arrayCopy || [];
   }
}

export function getFeedReportPostCommentReplySuccess(postList: any[], payload: any) {
   const arrayCopy = listParseAndStringify(postList);
   const index = arrayCopy.findIndex((x: Feed) => x.post.id === payload?.reportPostId);

   const commentArray = arrayCopy[index]?.post?.comments || [];
   const commentIndex = commentArray.findIndex((x: Comment) => x.id === payload?.reportCommentId);
   const commentReplyArray = commentArray[commentIndex]?.comment_replys || [];
   const indexCommentReply = commentReplyArray.findIndex((x: CommentReplies) => x.id === payload?.reportCommentReplyId);
   if (indexCommentReply !== -1) {
      commentReplyArray[indexCommentReply].is_reported_comment_reply = 1;
      commentArray[commentIndex].comment_replys = commentReplyArray;
      arrayCopy[index].post.comments = commentArray;
      return arrayCopy || [];
   } else {
      return arrayCopy || [];
   }
}

export function getFeedSuccessAlertMyNetwork(postList: any[], connectionSuccessPost: Post, connectionStatus: number) {
   const array = listParseAndStringify(postList);
   const userPostArray: Feed[] = [];
   array.forEach((feed: Feed) => {
      const obj = feed;
      if (obj.post.user_id === connectionSuccessPost.user_id) {
         obj.post.user.connection_status = connectionStatus;
      }
      userPostArray.push(obj);
   });
   return userPostArray || [];
}
export function getFeedPostCommentTagUsersListUpdate(postList: any[], postId: any, tagUsersListArray: User[]) {
   const arrayCopy = listParseAndStringify(postList);
   const index = arrayCopy.findIndex((x: Feed) => x.post.id === postId);
   arrayCopy[index].post.tagUsersArray = tagUsersListArray;
   return arrayCopy || [];
}

export function getFeedPostCommentReplyTagUsersListUpdate(
   postList: any[],
   postId: any,
   commentId: any,
   tagUsersListArray: User[]
) {
   const arrayCopy = listParseAndStringify(postList);
   const index = arrayCopy.findIndex((x: Feed) => x.post.id === postId);

   const commentArray = arrayCopy[index]?.post?.comments || [];
   const commentReplyIndex = commentArray.findIndex((x: Comment) => x.id === commentId);
   commentArray[commentReplyIndex].tagUsersArray = tagUsersListArray;
   arrayCopy[index].post.comments = commentArray;
   return arrayCopy || [];
}

export function getFeedSavePostListUpdate(postList: any[], postDataId: any, is_saved: any, mySavedPost: any) {
   const array = listParseAndStringify(postList);
   const index = array.findIndex((x: Feed) => x.id === postDataId);
   if (index !== -1 && array[index]) {
      array[index].is_saved = is_saved;
      if (mySavedPost) {
         array.splice(index, 1);
      }
      return array || [];
   } else {
      return array || [];
   }
}

export function getFeedClickPostPolling(postList: any[], post: Post, pollOptionObj: PollOptions, pollCount: number) {
   const array = listParseAndStringify(postList);
   const index = array.findIndex((x: Feed) => x.post.id === post.id);
   const originalPostList = array.filter((x: Feed) => x?.post?.original_post?.id === post.id);
   const originalPostIds: number[] = [];
   originalPostList.forEach((feed: Feed) => {
      if (feed) {
         const originalPostIndex = array.findIndex((x: Feed) => x?.post?.id === feed?.post?.id);
         originalPostIds.push(originalPostIndex);
      }
   });
   const pollOptionsArray = listParseAndStringify(post?.poll_options || []);
   const pollOptionsModArray = pollOptionsArray.map((pollOption: PollOptions) => {
      if (pollOption.id === pollOptionObj.id) {
         let voteCount = pollOption?.voted_count || 0;
         voteCount = voteCount + 1;
         pollCount = pollCount + 1;
         pollOption.voted_count = voteCount;
         pollOption.is_responded = true;
      } else {
         const voteCount = pollOption?.voted_count || 0;
         pollOption.voted_count = voteCount;
      }
      return pollOption;
   });
   if (index !== -1) {
      array[index].post.poll_options = pollOptionsModArray;
      if (originalPostIds?.length > 0) {
         for (let j = 0; j < originalPostIds.length; j++) {
            const i = originalPostIds[j];
            if (i !== -1) {
               array[i].post.original_post.poll_options = pollOptionsModArray;
            }
         }
         return array || [];
      } else {
         return array || [];
      }
   } else if (originalPostIds?.length > 0) {
      for (let j = 0; j < originalPostIds.length; j++) {
         const i = originalPostIds[j];
         if (i !== -1) {
            array[i].post.original_post.poll_options = pollOptionsModArray;
         }
      }
      return array || [];
   } else {
      return array || [];
   }
}

export function feedEduLibraryListUpdate(postList: any[], payload: any, eduLibArray: any[]) {
   const commentReplyList = listParseAndStringify(eduLibArray);
   const postId = payload?.post_id;

   const arrayCopy = listParseAndStringify(postList);
   const index = arrayCopy.findIndex((x: Feed) => x.post.id === postId);
   arrayCopy[index].post.videoLibList = commentReplyList;
   return arrayCopy || [];
}
