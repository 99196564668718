import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CUSTOM_NOTIFICATION_ADD } from 'reduxToolKit/actions';
import { commonSlice, customNotificationAddSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   customNotificationAddLoading,
   customNotificationAddRequest,
   customNotificationAddSuccess,
   customNotificationAddFailed,
} = customNotificationAddSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const customNotificationAddSaga = function* (notificationPayload: any) {
   yield put(customNotificationAddLoading());
   yield put(customNotificationAddRequest(notificationPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = notificationPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.sendCustomNotification });
      if (response.status === 200) {
         const message = response.data.message;
         yield put(customNotificationAddSuccess(message));
      } else {
         yield put(customNotificationAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeCustomNotificationAddRequest = function* () {
   yield takeEvery(CUSTOM_NOTIFICATION_ADD, customNotificationAddSaga);
};
