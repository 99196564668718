import { createAction } from '@reduxjs/toolkit';
import { educationalVideoSagaTypes } from 'types';

export const ASSIGN_VIDEO = 'ASSIGN_VIDEO';
export const assignVideoAction = createAction<educationalVideoSagaTypes.IAssignVideoForm>(ASSIGN_VIDEO);

export const ASSIGNED_VIDEO = 'ASSIGNED_VIDEO';
export const assignedVideoAction = createAction<educationalVideoSagaTypes.IAssignedVideoForm>(ASSIGNED_VIDEO);

export const ASSIGN_CLINIC = 'ASSIGN_CLINIC';
export const assignClinicAction = createAction<educationalVideoSagaTypes.IAssignedVideoForm>(ASSIGN_CLINIC);
