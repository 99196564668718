import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MODAL_UPDATE_FAMILYHISTORIES_SAGA } from 'reduxToolKit/actions';
import { commonSlice, modalUpdateFamilyHistoriesSlice } from 'reduxToolKit/reducers';
import { modalUpdateFamilyHistoriesSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   modalUpdateFamilyHistoriesRequest,
   modalUpdateFamilyHistoriesSuccess,
   modalUpdateFamilyHistoriesFailed,
} = modalUpdateFamilyHistoriesSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const modalUpdateFamilyHistoriesSaga = function* (
   familyHistoryPayload: PayloadAction<modalUpdateFamilyHistoriesSagaTypes.IModalUpdateFamilyHistoriesSagaPayload>
) {
   yield put(modalUpdateFamilyHistoriesRequest(familyHistoryPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = familyHistoryPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.updateFamilyHistories });
      if (response.status === 200) {
         const responseData = response.data;
         const familyHistoryList = {
            message: responseData?.message || '',
         };
         yield put(modalUpdateFamilyHistoriesSuccess(familyHistoryList));
      } else {
         yield put(modalUpdateFamilyHistoriesFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takemodalUpdateFamilyHistories = function* () {
   yield takeEvery(MODAL_UPDATE_FAMILYHISTORIES_SAGA, modalUpdateFamilyHistoriesSaga);
};
