import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { UPDATE_SYMPTOM_MONITORING_CONSENT } from 'reduxToolKit/actions';
import { oncKlinicSymptomSlice, commonSlice } from 'reduxToolKit/reducers';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   updateSymptomMonitoringConsentRequest,
   updateSymptomMonitoringConsentSuccess,
   updateSymptomMonitoringConsentFailed,
} = oncKlinicSymptomSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const oncKlinicSymptomMonitoringSaga = function* (payloadState: any) {
   yield put(updateSymptomMonitoringConsentRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         goUrl,
         data,
         ...API.updateSymptomMonitoringConsent,
      });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message || messages.success,
            data: response.data?.data,
         };
         yield put(updateSymptomMonitoringConsentSuccess(resObj));
      } else {
         yield put(updateSymptomMonitoringConsentFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeOncKlinicSymptomMonitoringSaga = function* () {
   yield takeEvery(UPDATE_SYMPTOM_MONITORING_CONSENT, oncKlinicSymptomMonitoringSaga);
};
