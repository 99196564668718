import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { POST_ADDENDUM } from 'reduxToolKit/actions';
import { addendumPostSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { addendumRequest, addendumSuccess, addendumFailed } = addendumPostSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const addendumSaga = function* (payloadState: any) {
   yield put(addendumRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addAddendum });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: null,
         };
         yield put(addendumSuccess(resObj));
      } else {
         yield put(addendumFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAddendum = function* () {
   yield takeEvery(POST_ADDENDUM, addendumSaga);
};
