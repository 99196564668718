import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pillRemainderReducerTypes } from 'types';

const initialState: pillRemainderReducerTypes.IPillRemainderAddReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const pillRemainderMediStatusUpdateSlice = createSlice({
   name: 'pillRemainderMediStatusUpdate',
   initialState: initialState,
   reducers: {
      pillRemainderMediStatusUpdateRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      pillRemainderMediStatusUpdateSuccess: (
         state,
         action: PayloadAction<pillRemainderReducerTypes.IPillRemainderAddReducer>
      ) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      pillRemainderMediStatusUpdateFailed: (state, action) => {
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: action.payload.error_message ? action.payload.error_message : '',
         };
      },
      pillRemainderMediStatusUpdateReset: () => {
         return initialState;
      },
   },
});
export { pillRemainderMediStatusUpdateSlice };
