import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { DEVICE_GET_BLOOD_PRESSURE, DEVICE_GET_BLOOD_PRESSURE_STATISTICS } from 'reduxToolKit/actions';
import { deviceBloodPressureSlice, commonSlice } from 'reduxToolKit/reducers';
import { deviceSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getBloodPressureRequest,
   getBloodPressureSuccess,
   getBloodPressureFailed,
   getBloodPressureStatisticsRequest,
   getBloodPressureStatisticsSuccess,
   getBloodPressureStatisticsFailed,
} = deviceBloodPressureSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getBloodPressureSaga = function* (payloadState: PayloadAction<deviceSagaTypes.IGetBloodPressure>) {
   yield put(getBloodPressureRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getBloodPressure });
      if (response.status === 200) {
         const resObj = {
            message: response.data?.message || messages.success,
            data: response.data?.data,
         };
         yield put(getBloodPressureSuccess(resObj));
      } else {
         yield put(getBloodPressureFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getBloodPressureStatisticSaga = function* (payloadState: PayloadAction<deviceSagaTypes.IGetBloodPressure>) {
   yield put(getBloodPressureStatisticsRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getBloodPressureStatistics });
      if (response.status === 200) {
         const resObj = {
            message: response.data?.message || messages.success,
            data: response.data?.data,
         };
         yield put(getBloodPressureStatisticsSuccess(resObj));
      } else {
         yield put(getBloodPressureStatisticsFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGetBloodPressureSaga = function* () {
   yield takeEvery(DEVICE_GET_BLOOD_PRESSURE, getBloodPressureSaga);
   yield takeEvery(DEVICE_GET_BLOOD_PRESSURE_STATISTICS, getBloodPressureStatisticSaga);
};
