import { createAction } from '@reduxjs/toolkit';
import { myProfileSagaTypes } from 'types';

export const GET_BLOCK_UNBLOCK_USER = 'GET_BLOCK_UNBLOCK_USER';
export const getBlockUnblockUserAction = createAction<myProfileSagaTypes.IBlockUnblockUserRequest>(
   GET_BLOCK_UNBLOCK_USER
);

export const GET_BLOCK_USERS_LIST = 'GET_BLOCK_USERS_LIST';
export const getBlockedUsersListAction = createAction<myProfileSagaTypes.IBlockUsersListRequest>(GET_BLOCK_USERS_LIST);
