import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authReducerTypes, authSagaTypes } from 'types';

const initialState: authReducerTypes.ISetPasswordReducerTypes = {
   message: '',
   error: '',
};
export const setPasswordSlice = createSlice({
   name: 'setPassword',
   initialState: initialState,
   reducers: {
      setPasswordRequest: (state, action: PayloadAction<authSagaTypes.ISetPasswordSagaPayload>) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      setPasswordSuccess: (state, action: PayloadAction<authReducerTypes.ISetPasswordSuccessPayload>) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      setPasswordFailed: (state, action: PayloadAction<authReducerTypes.ISetPasswordFailurePayload>) => {
         return {
            ...state,
            error: action.payload.error,
         };
      },
      setPasswordReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
