import { createAction } from '@reduxjs/toolkit';
import { appointmentSchedulePageTypes } from 'types';

export const GET_PHYSICIAN_SCHEDULE = 'GET_PHYSICIAN_SCHEDULE';
export const getPhysicianSchedule = createAction<appointmentSchedulePageTypes.IAppointmentScheduleInput>(
   GET_PHYSICIAN_SCHEDULE
);

export const GET_SCHEDULE_PHYSICIAN = 'GET_SCHEDULE_PHYSICIAN';
export const getSchedulePhysician = createAction<appointmentSchedulePageTypes.IGetSChedulePhysicianInputProps>(
   GET_SCHEDULE_PHYSICIAN
);
