import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { associatedPhysicianReducerTypes, associatedPhysicianSagaTypes } from 'types';

const initialState: associatedPhysicianReducerTypes.IModalAssociatedPhysicianReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
   errors: [],
   add: {
      message: '',
      error: '',
      isLoading: false,
   },
};
//Modal - getAssociatedExceptTreatingPhysicians slice
export const modalGetAssociatedPhysicianSlice = createSlice({
   name: 'modalGetAssociatedPhysician',
   initialState: initialState,
   reducers: {
      getAssociatedPhysicianRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action?.payload?.page === 1 ? [] : state.list,
         };
      },
      getAssociatedPhysicianSuccess: (
         state,
         action: PayloadAction<associatedPhysicianReducerTypes.IModalAssociatedPhysicianSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            isLoading: false,
         };
      },
      getAssociatedPhysicianFailed: (
         state,
         action: PayloadAction<associatedPhysicianReducerTypes.IModalAssociatedPhysicianFailedPayload>
      ) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      getAssociatedPhysicianReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

// modal - AddAssociatedPhysician Slice
export const modalAddAssociatedPhysicianSlice = createSlice({
   name: 'modalAddAssociatedPhysician',
   initialState: initialState,
   reducers: {
      addAssociatedPhysicianRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            add: {
               isLoading: true,
            },
         };
      },
      addAssociatedPhysicianSuccess: (
         state,
         action: PayloadAction<associatedPhysicianReducerTypes.IModalAddAssociatedPhysicianSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      addAssociatedPhysicianFailed: (
         state,
         action: PayloadAction<associatedPhysicianReducerTypes.IModalAddAssociatedPhysicianFailedPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            message: '',
            error: payload.error,
         };
      },
      addAssociatedPhysicianReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

//Nurse -getAssociatedPhysician
export const nurseGetAssociatedPhysicianSlice = createSlice({
   name: 'nurseGetAssociatedPhysician',
   initialState: initialState,
   reducers: {
      nurseGetAssociatedPhysicianRequest: (
         state,
         action: PayloadAction<associatedPhysicianSagaTypes.INurseAssociatedPhysicianSagaPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action?.payload?.page === 1 ? [] : state.list,
         };
      },
      nurseGetAssociatedPhysicianSuccess: (
         state,
         action: PayloadAction<associatedPhysicianReducerTypes.INurseAssociatedPhysicianSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            isLoading: false,
         };
      },
      nurseGetAssociatedPhysicianFailed: (
         state,
         action: PayloadAction<associatedPhysicianReducerTypes.INurseAssociatedPhysicianFailedPayload>
      ) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      nurseGetAssociatedPhysicianReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
