import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { PHYSICIAN_INFO } from 'reduxToolKit/actions';
import { commonSlice, physicianInfoSlice } from 'reduxToolKit/reducers';
import { caseDetailsReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { physicianInfoRequest, physicianInfoSuccess, physicianInfoFailed } = physicianInfoSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const physicianInfo = function* (PayloadState: PayloadAction<caseDetailsReducerTypes.IGetOneAskADocId>) {
   yield put(physicianInfoRequest(PayloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = PayloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.physicianInfo });
      if (response.status === 200) {
         const viewAnswersData = {
            data: response?.data?.data,
            message: response?.data?.message || messages.success,
         };
         yield put(physicianInfoSuccess(viewAnswersData));
      } else {
         yield put(physicianInfoFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePhysicianInfo = function* () {
   yield takeLatest(PHYSICIAN_INFO, physicianInfo);
};
