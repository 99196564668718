import { createSlice } from '@reduxjs/toolkit';
import { feedReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: feedReducerTypes.ICommunityGuidelinesReducer = {
   isLoading: false,
   addCommunityGuidelines: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   createPostIsClinicUser: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
};

export const communityGuidelinesSlice = createSlice({
   name: 'communityGuidelines',
   initialState: initialState,
   reducers: {
      addCommunityGuidelinesRequest: (state) => {
         return {
            ...state,
            addCommunityGuidelines: {
               ...state.addCommunityGuidelines,
               isLoading: true,
            },
         };
      },
      addCommunityGuidelinesSuccess: (state, action: any) => {
         return {
            ...state,
            addCommunityGuidelines: {
               ...state.addCommunityGuidelines,
               message: action.payload?.message,
               error: '',
               isLoading: false,
            },
         };
      },
      addCommunityGuidelinesFailure: (state, action: any) => {
         return {
            ...state,
            addCommunityGuidelines: {
               ...state.addCommunityGuidelines,
               error: action.payload?.error,
               message: '',
               isLoading: false,
            },
         };
      },
      addCommunityGuidelinesReset: (state) => {
         return {
            ...state,
            addCommunityGuidelines: {
               ...initialState.addCommunityGuidelines,
            },
         };
      },
      // create post is clinic user
      createPostIsClinicUserRequest: (state) => {
         return {
            ...state,
            createPostIsClinicUser: {
               ...state.createPostIsClinicUser,
               isLoading: true,
            },
         };
      },
      createPostIsClinicUserSuccess: (state, action: any) => {
         return {
            ...state,
            createPostIsClinicUser: {
               ...state.createPostIsClinicUser,
               message: action.payload?.message,
               data: action.payload.data,
               error: '',
               isLoading: false,
            },
         };
      },
      createPostIsClinicUserFailure: (state, action: any) => {
         return {
            ...state,
            createPostIsClinicUser: {
               ...state.createPostIsClinicUser,
               error: action.payload?.error || messages.error,
               message: '',
               isLoading: false,
            },
         };
      },
      createPostIsClinicUserReset: (state) => {
         return {
            ...state,
            createPostIsClinicUser: {
               ...initialState.createPostIsClinicUser,
            },
         };
      },
   },
});
