import { createSlice } from '@reduxjs/toolkit';
import { IOrgUserEduLibReducer } from 'types/videoLibrary/videoLibraryReducerTypes';

const initialState: IOrgUserEduLibReducer = {
   myLibAddUpdate: {
      isLoading: false,
      data: null,
      message: '',
      error: '',
   },
   myLibDelete: {
      isLoading: false,
      data: null,
      message: '',
      error: '',
   },
   feedOrgList: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      pagination: {
         current_page: null,
         last_page: null,
         first_page_url: '',
         last_page_url: '',
         next_page_url: '',
         prev_page_url: '',
         total: 0,
      },
   },
};

export const orgUserEduLibSlice = createSlice({
   name: 'orgUserEduLib',
   initialState: initialState,
   reducers: {
      myLibAddUpdateRequest: (state, action) => {
         return {
            ...state,
            myLibAddUpdate: {
               ...state.myLibAddUpdate,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      myLibAddUpdateSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            myLibAddUpdate: {
               ...state.myLibAddUpdate,
               isLoading: false,
               data: payload.data,
               message: payload.message,
            },
         };
      },
      myLibAddUpdateFailed: (state, action) => {
         return {
            ...state,
            myLibAddUpdate: {
               ...state.myLibAddUpdate,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },
      myLibAddUpdateReset: (state) => {
         return {
            ...state,
            myLibAddUpdate: {
               ...initialState.myLibAddUpdate,
            },
         };
      },
      //delete
      myLibDeleteRequest: (state, action) => {
         return {
            ...state,
            myLibDelete: {
               ...state.myLibDelete,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      myLibDeleteSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            myLibDelete: {
               ...state.myLibDelete,
               isLoading: false,
               data: payload.data,
               message: payload.message,
            },
         };
      },
      myLibDeleteFailed: (state, action) => {
         return {
            ...state,
            myLibDelete: {
               ...state.myLibDelete,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },
      myLibDeleteReset: (state) => {
         return {
            ...state,
            myLibDelete: {
               ...initialState.myLibDelete,
            },
         };
      },
      // org list
      feedOrgListRequest: (state, action) => {
         return {
            ...state,
            feedOrgList: {
               ...action.payload,
               isLoading: true,
               list: action.payload.page && action.payload.page === 1 ? [] : state.feedOrgList.list,
            },
         };
      },
      feedOrgListSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            feedOrgList: {
               ...state.feedOrgList,
               pagination: { ...payload.pagination },
               list:
                  payload.pagination.current_page === 1 ? payload.list : [...state.feedOrgList.list, ...payload.list],
               isLoading: false,
            },
         };
      },
      feedOrgListFailed: (state, action) => {
         return {
            ...state,
            feedOrgList: {
               ...state.feedOrgList,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },
      feedOrgListReset: (state) => {
         return {
            ...state,
            feedOrgList: {
               ...initialState.feedOrgList,
            },
         };
      },
   },
});
