import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_BLOCK_UNBLOCK_USER, GET_BLOCK_USERS_LIST } from 'reduxToolKit/actions';
import { commonSlice, blockUnblockUserSlice } from 'reduxToolKit/reducers';
import { myProfileSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getBlockUnblockUserRequest,
   getBlockUnblockUserSuccess,
   getBlockUnblockUserFailed,
   getBlockedUsersListRequest,
   getBlockedUsersListSuccess,
   getBlockedUsersListFailed,
} = blockUnblockUserSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const blockUnblockUserSaga = function* (
   blockUnblockUserPayload: PayloadAction<myProfileSagaTypes.IBlockUnblockUserRequest>
) {
   yield put(getBlockUnblockUserRequest(blockUnblockUserPayload.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      let url = {
         apiPath: '',
         action: '',
      };
      let data = null;
      if (blockUnblockUserPayload.payload.type === 'block') {
         url = {
            apiPath: API.blockUser.apiPath,
            action: API.blockUser.action,
         };
         data = { block_user_id: blockUnblockUserPayload.payload.block_user_id };
      } else {
         url = {
            apiPath: API.unBlockUser.apiPath,
            action: API.unBlockUser.action,
         };
         data = { blocked_user_id: blockUnblockUserPayload.payload.blocked_user_id };
      }
      const response: AxiosResponse = yield apiCall({ checkUser, peopleMayYouKnow, data, ...url });
      if (response.status === 200) {
         const responseData = response.data;
         const blockUnblockUserData = {
            message: responseData?.message || messages.success,
         };
         yield put(getBlockUnblockUserSuccess(blockUnblockUserData));
      } else {
         yield put(getBlockUnblockUserFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const blockUsersListSaga = function* (payloadState: PayloadAction<myProfileSagaTypes.IBlockUsersListRequest>) {
   yield put(getBlockedUsersListRequest(payloadState.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         peopleMayYouKnow,
         params,
         ...API.blockedUsersList,
      });
      if (response.status === 200) {
         const responseData = response.data.data;
         const blockedUsersList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response?.data?.message || messages.success,
         };
         yield put(getBlockedUsersListSuccess(blockedUsersList));
      } else {
         yield put(getBlockedUsersListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGetBlockUnblockUserRequest = function* () {
   yield takeEvery(GET_BLOCK_UNBLOCK_USER, blockUnblockUserSaga);
   yield takeEvery(GET_BLOCK_USERS_LIST, blockUsersListSaga);
};
