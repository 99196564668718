import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { phyNpiNumberReducerTypes, phyNpiNumberSagaTypes } from 'types';

const initialState: phyNpiNumberReducerTypes.INpiNumberReducerTypes = {
   verifyNpiNumber: {
      status: false,
      message: '',
      error: '',
   },
   addNpiNumber: {
      message: '',
      error: '',
   },
};
export const phyNpiNumberVerifySlice = createSlice({
   name: 'phyNpiNumberVerify',
   initialState: initialState,
   reducers: {
      phyNpiNumberVerifyRequest: (state) => {
         return {
            ...state,
         };
      },
      phyNpiNumberVerifySuccess: (
         state,
         action: PayloadAction<phyNpiNumberReducerTypes.INpiNumberVerifySuccessPayload>
      ) => {
         return {
            ...state,
            verifyNpiNumber: {
               ...state.verifyNpiNumber,
               ...action.payload,
            },
         };
      },
      phyNpiNumberVerifyFailed: (state, action: PayloadAction<phyNpiNumberReducerTypes.INpiNumberFailurePayload>) => {
         return {
            ...state,
            verifyNpiNumber: {
               ...state.verifyNpiNumber,
               error: action.payload.error,
            },
         };
      },
      addNpiNumberRequest: (state, action: PayloadAction<phyNpiNumberSagaTypes.IAddNpiNumberSagaPayload>) => {
         return {
            ...state,
            addNpiNumber: {
               ...state.addNpiNumber,
               ...action.payload,
            },
         };
      },
      addNpiNumberSuccess: (state, action: PayloadAction<phyNpiNumberReducerTypes.IAddNpiNumberSuccessPayload>) => {
         return {
            ...state,
            addNpiNumber: {
               ...state.addNpiNumber,
               ...action.payload,
            },
         };
      },
      addNpiNumberFailed: (state, action: PayloadAction<phyNpiNumberReducerTypes.INpiNumberFailurePayload>) => {
         return {
            ...state,
            addNpiNumber: {
               ...state.addNpiNumber,
               error: action.payload.error,
            },
         };
      },
      phyNpiNumberVerifyReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
