import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_PHYSICIAN_SCHEDULE } from 'reduxToolKit/actions';
import { physicianScheduleSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getPhysicianSchedule,

   getPhysicianScheduleSuccess,
   getPhysicianScheduleFailed,
} = physicianScheduleSlice.actions;

const physicianSchedule = function* (payloadPhysicianSchedule: any) {
   yield put(getPhysicianSchedule(payloadPhysicianSchedule.payload));
   try {
      const params = {
         clinic_id: payloadPhysicianSchedule.payload.clinic_id,
         physician_id: payloadPhysicianSchedule.payload.physician_id,
         date: payloadPhysicianSchedule.payload.date,
      };
      const response: AxiosResponse = yield apiCall({ params, ...API.getPhysicianAppointmentSchedule });
      if (response.status === 200) {
         yield put(getPhysicianScheduleSuccess(response.data));
      } else {
         yield put(getPhysicianScheduleFailed(response.data));
      }
   } catch (error) {}
};
export { physicianSchedule };
export const takePhysicianScheduleRequest = function* () {
   yield takeEvery(GET_PHYSICIAN_SCHEDULE, physicianSchedule);
};
