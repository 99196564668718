import { createSlice } from '@reduxjs/toolkit';
import { myProfileReducerTypes } from 'types';

const initialState: myProfileReducerTypes.IRaceReducer = {
   addRace: {
      isLoading: false,
      error: '',
      message: '',
   },
   getMasterRace: {
      isLoading: false,
      message: '',
      error: '',
      list: [],
   },
};

export const raceSlice = createSlice({
   name: 'race',
   initialState: initialState,
   reducers: {
      // add race
      raceAddRequest: (state, action) => {
         return {
            ...state,
            addRace: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      raceAddSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            addRace: {
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      raceAddFailed: (state, action: any) => {
         return {
            ...state,
            addRace: {
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      raceAddReset: (state) => {
         return {
            ...state,
            addRace: initialState.addRace,
         };
      },

      // get master race
      getMasterRaceRequest: (state, action) => {
         return {
            ...state,
            getMasterRace: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getMasterRaceSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            getMasterRace: {
               list: payload.list,
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      getMasterRaceFailed: (state, action: any) => {
         return {
            ...state,
            getMasterRace: {
               list: state.getMasterRace.list,
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      getMasterRaceReset: (state) => {
         return {
            ...state,
            getMasterRace: initialState.getMasterRace,
         };
      },
   },
});
