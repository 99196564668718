import { createSlice } from '@reduxjs/toolkit';
import { editPrescriptionReducerTypes } from 'types';

const initialState: editPrescriptionReducerTypes.IGetMedicationReducer = {
   isLoading: false,
   list: [],
   error: '',
   message: '',
};

export const medicationSlice = createSlice({
   name: 'medicationList',
   initialState: initialState,
   reducers: {
      medicationListRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      medicationListSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload,
            isLoading: false,
            message: payload.message,
         };
      },
      medicationListFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            error: action.payload.error,
            message: '',
         };
      },
      medicationListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
