import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { TOP_CANCER_ACTION } from 'reduxToolKit/actions';
import { commonSlice, getTopCancerSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getTopCancerRequest, getTopCancerSuccess, getTopCancerFailed } = getTopCancerSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const topCancerSaga = function* () {
   yield put(getTopCancerRequest());
   try {
      const checkUser = true;
      const goUrl = true;

      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, ...API.topCancer });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            list: responseData.data,
            message: response.data?.message,
         };
         yield put(getTopCancerSuccess(oncDriveList));
      } else {
         yield put(getTopCancerFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeTopCancer = function* () {
   yield takeEvery(TOP_CANCER_ACTION, topCancerSaga);
};
