import {
   UPLOAD_CERTIFICATE_IMAGE,
   UPLOAD_CONVERSATION,
   UPLOAD_LICENSE_ATTACHMENT,
   UPLOAD_ADD_EDIT_DRAWER,
   UPLOAD_TELE_MEDICINE_MESSAGE,
   UPLOAD_ADD_CLINIC_VIDEO,
} from 'utils/constants/fileUploadTypes';
import {
   ACCEPTED_DOCUMENT_TYPES,
   ACCEPTED_IMAGE_AND_VIDEO_TYPES,
   ACCEPTED_PDF_ONLY,
   ACCEPTED_VIDEO_TYPES,
} from 'utils/constants/postTypes';

export const fileTypeAllowed = (mimeType: string, uploadType: string) => {
   let SUPPORTED_FORMATS: any[] = [];
   if (uploadType === UPLOAD_CONVERSATION) {
      const acceptedImageAndVideos = ACCEPTED_IMAGE_AND_VIDEO_TYPES.split(',');
      SUPPORTED_FORMATS = SUPPORTED_FORMATS.concat(acceptedImageAndVideos);
      const acceptedDoc = ACCEPTED_DOCUMENT_TYPES.split(',');
      SUPPORTED_FORMATS = SUPPORTED_FORMATS.concat(acceptedDoc);
   }
   if (
      uploadType === UPLOAD_ADD_EDIT_DRAWER ||
      uploadType === UPLOAD_TELE_MEDICINE_MESSAGE ||
      uploadType === UPLOAD_CERTIFICATE_IMAGE ||
      uploadType === UPLOAD_LICENSE_ATTACHMENT
   ) {
      const acceptedImageAndVideos = ACCEPTED_IMAGE_AND_VIDEO_TYPES.split(',');
      SUPPORTED_FORMATS = SUPPORTED_FORMATS.concat(acceptedImageAndVideos);
      SUPPORTED_FORMATS = SUPPORTED_FORMATS.concat(ACCEPTED_PDF_ONLY);
   }
   if (uploadType === UPLOAD_ADD_CLINIC_VIDEO) {
      const acceptedImageAndVideos = ACCEPTED_VIDEO_TYPES.split(',');
      SUPPORTED_FORMATS = SUPPORTED_FORMATS.concat(acceptedImageAndVideos);
   }
   return SUPPORTED_FORMATS.includes(mimeType);
};

export const removeFileObject = (payloadData: any) => {
   const { fileProgress, id } = payloadData;

   return Object.keys(fileProgress)
      .filter((key) => {
         return parseInt(key) !== id;
      })
      .reduce((obj: any, key: any) => {
         obj[key] = fileProgress[key];
         return obj;
      }, {});
};
