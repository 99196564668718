import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { caseDetailsReducerTypes, storeTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: caseDetailsReducerTypes.IPhysicianInfo = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};

export const physicianInfoSlice = createSlice({
   name: 'physicianInfo',
   initialState: initialState,
   reducers: {
      physicianInfoRequest: (state, action: PayloadAction<caseDetailsReducerTypes.IGetOneAskADocId>) => {
         return {
            ...state,
            isLoading: true,
            ...action.payload,
         };
      },
      physicianInfoSuccess: (state, action: PayloadAction<caseDetailsReducerTypes.IPhysicianInfoSuccessPayload>) => {
         const { payload } = action;
         return {
            ...state,
            data: payload?.data || null,
            message: messages.success,
            isLoading: false,
         };
      },
      physicianInfoFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: action.payload.error || '',
         };
      },
      physicianInfoReset: () => {
         return { ...initialState };
      },
   },
});
