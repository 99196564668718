import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { skillsReducerTypes, skillsSagaTypes } from 'types';

const initialState: skillsReducerTypes.IGetSkills = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
   errors: [],
   updateSkills: {
      message: '',
      error: '',
   },
};

//getSkills Slice
export const profileSkillsSlice = createSlice({
   name: 'profileSkills',
   initialState: initialState,
   reducers: {
      getSkillsRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action?.payload?.page === 1 ? [] : state.list,
         };
      },
      getSkillsSuccess: (state, action: PayloadAction<skillsReducerTypes.IGetSkillsSuccess>) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            isLoading: false,
         };
      },
      getSkillsFailed: (state, action: PayloadAction<skillsReducerTypes.IGetSkillsFailed>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      updateSkillsRequest: (state, action: PayloadAction<skillsSagaTypes.IUpdateUserSkills>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      updateSkillsSuccess: (state, action: PayloadAction<skillsReducerTypes.IUpdateUserSkillsSuccessPayload>) => {
         return {
            ...state,
            isLoading: false,
            updateSkills: {
               message: action.payload?.message,
               error: '',
            },
         };
      },
      updateSkillsFailed: (state, action: PayloadAction<skillsReducerTypes.IUpdateUserSkillsFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            updateSkills: {
               error: action.payload?.error,
               message: '',
            },
         };
      },

      userSkillsReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
