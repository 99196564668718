export const ASK_A_DOC_EVENTS = {
   //ask a doc
   HOME_ASK_A_DOC_SUBSCRIPTION: 'home_ask_a_doc_subscription',
   HOME_ASK_A_DOC_SUBSCRIPTION_BACK: 'home_ask_a_doc_subscription_back',
   CREATE_POST_ASK_A_DOC_SUBSCRIPTION: 'create_post_ask_a_doc_subscription',
   CREATE_POST_TRY_ASK_A_DOC_REQUEST: 'create_post_try_ask_a_doc_request',
   CREATE_POST_NO_THANKS_ASK_A_DOC: 'create_post_no_thanks_ask_a_doc',
   ASK_A_DOC_SUBSCRIPTION_MY_QUESTION_SCREEN_ASK_A_QUESTION: 'ask_a_doc_subscription_my_question_screen_ask_a_question',
   ASK_A_DOC_SUBSCRIPTION_ASK_A_QUESTION: 'ask_a_doc_subscription_ask_a_question',
   ASK_A_DOC_SUBSCRIPTION_REQUEST_SELECT_PANEL_BACK: 'ask_a_doc_subscription_request_select_panel_back',
   ASK_A_DOC_SUBSCRIPTION_REQUEST_ADD_QUESTION_BACK: 'ask_a_doc_subscription_request_add_question_back',
   ASK_A_DOC_SUBSCRIPTION_REQUEST_ADD_DOCUMENT_BACK: 'ask_a_doc_subscription_request_add_document_back',
   ASK_A_DOC_SUBSCRIPTION_REQUEST_SUBMIT: 'ask_a_doc_subscription_request_submit',
   ASK_A_DOC_SUBSCRIPTION_REQUEST_CANCEL: 'ask_a_doc_subscription_request_cancel',
   ASK_A_DOC_SUBSCRIPTION_PLAN_CANCEL_NOW: 'ask_a_doc_subscription_plan_cancel_now',
   ASK_A_DOC_SUBSCRIPTION_PLAN_DONOT_CANCEL: 'ask_a_doc_subscription_plan_donot_cancel',
   ASK_A_DOC_SUBSCRIPTION_PURCHASE: 'ask_a_doc_subscription_purchase',
   ASK_A_DOC_SUBSCRIPTION_PURCHASE_BACK: 'ask_a_doc_subscription_purchase_back',
   ASK_A_DOC_PAYMENT_OPTION_CANCEL: 'ask_a_doc_payment_option_cancel',
   ASK_A_DOC_SUBSCRIPTION_COUPON_APPLY: 'ask_a_doc_subscription_coupon_apply',
   ASK_A_DOC_SUBSCRIPTION_PROCEED_TO_PAY: 'ask_a_doc_subscription_proceed_to_pay',
   ASK_A_DOC_SUBSCRIPTION_STRIPE_PAYMENT_CANCEL: 'ask_a_doc_subscription_stripe_payment_cancel',
   ASK_A_DOC_SUBSCRIPTION_SEE_QUESTION: 'ask_a_doc_subscription_see_question',
   ASK_A_DOC_SUBSCRIPTION_BACK_FROM_SEE_QUESTION: 'ask_a_doc_subscription_back_from_see_question',
   ASK_A_DOC_SUBSCRIPTION_CONNECTION_REQUEST_FROM_CASE_DETAILS:
      'ask_a_doc_subscription_connection_request_from_case_details',
   ASK_A_DOC_SUBSCRIPTION_CANCEL_CONNECTION_REQUEST_FROM_CASE_DETAILS:
      'ask_a_doc_subscription_cancel_connection_request_from_case_details',
   ASK_A_DOC_SUBSCRIPTION_RESPOND_CONNECTION_REQUEST_FROM_CASE_DETAILS:
      'ask_a_doc_subscription_respond_connection_request_from_case_details',
   ASK_A_DOC_SUBSCRIPTION_DECLINE_CONNECTION_REQUEST_IN_CASE_DETAILS:
      'ask_a_doc_subscription_decline_connection_request_in_case_details',
   ASK_A_DOC_SUBSCRIPTION_ACCEPT_CONNECTION_REQUEST_IN_CASE_DETAILS:
      'ask_a_doc_subscription_accept_connection_request_in_case_details',
   ASK_A_DOC_SUBSCRIPTION_ONE_TO_ONE_CONVERSATION_FROM_CASE_DETAILS:
      'ask_a_doc_subscription_one_to_one_conversation_from_case_details',
   ASK_A_DOC_SUBSCRIPTION_VIEW_PROFILE_FROM_MY_QUESTIONS: 'ask_a_doc_subscription_view_profile_from_my_questions',
   ASK_A_DOC_SUBSCRIPTION_VIEW_PROFILE_FROM_DETAIL_PAGE: 'ask_a_doc_subscription_view_profile_from_detail_page',
   ASK_A_DOC_SUBSCRIPTION_VIEW_PROFILE_FROM_PANEL_REQUESTS: 'ask_a_doc_subscription_view_profile_from_panel_requests',
   ASK_A_DOC_SUBSCRIPTION_VIEW_PROFILE_FROM_YOUR_ANSWERS: 'ask_a_doc_subscription_view_profile_from_your_answers',
   ASK_A_DOC_SUBSCRIPTION_VIEW_PROFILE_FROM_PANEL_RESPONSE: 'ask_a_doc_subscription_view_profile_from_panel_response',
   ASK_A_DOC_SUBSCRIPTION_CONVERSATION: 'ask_a_doc_subscription_conversation',
   ASK_A_DOC_SUBSCRIPTION_CONVERSATION_MESSAGE_SEND: 'ask_a_doc_subscription_conversation_message_send',
   ASK_A_DOC_SUBSCRIPTION_CONVERSATION_CHAT_END: 'ask_a_doc_subscription_conversation_chat_end',
   ASK_A_DOC_SUBSCRIPTION_CONVERSATION_CHAT_END_CANCEL: 'ask_a_doc_subscription_conversation_chat_end_cancel',
   ASK_A_DOC_SUBSCRIPTION_REQUEST_CONNECTION_FROM_MY_QUESTION:
      'ask_a_doc_subscription_request_connection_from_my_question',
   ASK_A_DOC_SUBSCRIPTION_ONE_TO_ONE_CONVERSATION_FROM_MY_QUESTION:
      'ask_a_doc_subscription_one_to_one_conversation_from_my_question',
   ASK_A_DOC_SUBSCRIPTION_PICK_REQUEST_FROM_TOP: 'ask_a_doc_subscription_pick_request_from_top',
   ASK_A_DOC_SUBSCRIPTION_PICK_REQUEST_FROM_SCREEN: 'ask_a_doc_subscription_pick_request_from_screen',
   ASK_A_DOC_SUBSCRIPTION_SUBMIT_ANSWER: 'ask_a_doc_subscription_submit_answer',
   CANCEL_ASK_A_DOC_SUBSCRIPTION_SUBMIT_ANSWER: 'cancel_ask_a_doc_subscription_submit_answer',
   ASK_A_DOC_SUBSCRIPTION_SEE_QUESTION_FROM_PANEL_REQUESTS: 'ask_a_doc_subscription_see_question_from_panel_requests',
   ASK_A_DOC_SUBSCRIPTION_SEE_QUESTION_FROM_PANEL_REQUESTS_BACK:
      'ask_a_doc_subscription_see_question_from_panel_requests_back',
   ASK_A_DOC_SUBSCRIPTION_SEE_QUESTION_FROM_YOUR_ANSWERS: 'ask_a_doc_subscription_see_question_from_your_answers',
   ASK_A_DOC_SUBSCRIPTION_SEE_QUESTION_FROM_YOUR_ANSWERS_BACK:
      'ask_a_doc_subscription_see_question_from_your_answers_back',
   ASK_A_DOC_SUBSCRIPTION_SEE_QUESTION_FROM_PANEL_RESPONSES: 'ask_a_doc_subscription_see_question_from_panel_responses',
   ASK_A_DOC_SUBSCRIPTION_SEE_QUESTION_FROM_PANEL_RESPONSES_BACK:
      'ask_a_doc_subscription_see_question_from_panel_responses_back',
};
