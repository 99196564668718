import { createAction } from '@reduxjs/toolkit';
import { deviceSagaTypes } from 'types';

export const DEVICE_GET_MIN_AND_MAX = 'DEVICE_GET_MIN_AND_MAX';
export const getDeviceMinAndMaxAction = createAction<deviceSagaTypes.IGetDeviceMinAndMax>(DEVICE_GET_MIN_AND_MAX);

export const DEVICE_WEIGHT_READING_DIFFERENCE = 'DEVICE_WEIGHT_READING_DIFFERENCE';
export const getDeviceWeightReadingDifferenceAction = createAction<deviceSagaTypes.IGetDeviceWeightReadingDifference>(
   DEVICE_WEIGHT_READING_DIFFERENCE
);

export const DEVICE_GET_BLOOD_PRESSURE = 'DEVICE_GET_BLOOD_PRESSURE';
export const getBloodPressureAction = createAction<deviceSagaTypes.IGetBloodPressure>(DEVICE_GET_BLOOD_PRESSURE);

export const DEVICE_GET_BLOOD_PRESSURE_STATISTICS = 'DEVICE_GET_BLOOD_PRESSURE_STATISTICS';
export const getBloodPressureStatisticsAction = createAction<any>(DEVICE_GET_BLOOD_PRESSURE_STATISTICS);

export const DEVICE_ADD_DEVICES = 'DEVICE_ADD_DEVICES';
export const deviceAddDevicesAction = createAction<deviceSagaTypes.IDeviceAddDevices>(DEVICE_ADD_DEVICES);

export const DEVICE_GET_MY_SMART_WEIGHT = 'DEVICE_GET_MY_SMART_WEIGHT';
export const getMySmartWeightAction = createAction<deviceSagaTypes.IGetMySmartWeight>(DEVICE_GET_MY_SMART_WEIGHT);

export const DEVICE_GET_SMART_WEIGHT_STATISTICS = 'DEVICE_GET_SMART_WEIGHT_STATISTICS';
export const getSmartWeightStatisticsAction = createAction<deviceSagaTypes.IGetSmartWeightStatistics>(
   DEVICE_GET_SMART_WEIGHT_STATISTICS
);
