import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { HEALTH_RECORD_ADD } from 'reduxToolKit/actions';
import { commonSlice, healthRecordAddSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   healthRecordAddLoading,
   healthRecordAddRequest,
   healthRecordAddSuccess,
   healthRecordAddFailed,
} = healthRecordAddSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const healthRecordAddSaga = function* (healthTypePayload: any) {
   yield put(healthRecordAddLoading());
   yield put(healthRecordAddRequest(healthTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = healthTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addHealthRecord });
      if (response.status === 200) {
         const message = response.data.message;
         yield put(healthRecordAddSuccess(message));
      } else {
         yield put(healthRecordAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeHealthRecordAddRequest = function* () {
   yield takeEvery(HEALTH_RECORD_ADD, healthRecordAddSaga);
};
