import React, { useState } from 'react';

import { Dialog, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MobileStepper from '@material-ui/core/MobileStepper';
import PillModalImage from 'assets/pill-remainder/pillModalImage.png';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import { DialogContent } from 'component/shared/MuiWithStyle';
import Loading from 'component/widget/Loading';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import {
   medicationListSagaAction,
   pillRemainderAddSagaAction,
   pillRemainderListSagaAction,
} from 'reduxToolKit/actions';
import { medicationListSlice, pillRemainderAddSlice, pillRemainderListSlice } from 'reduxToolKit/reducers';
import { pillRemainderPageTypes, pillRemainderSagaTypes, storeTypes } from 'types';
import { PILL_REMAINDER_EVENTS } from 'utils/constants/eventTrack/pillRemainderEventConstants';
import { convertDateToTimezone, convertDateToTimezoneDate, getCurrentDate } from 'utils/helpers/dateHelpers';
import { setEventTrack } from 'utils/helpers/eventTrack';

import { PillChooseMultiDays } from './PillChooseMultiDays';
import { PillChooseOneDay } from './PillChooseOneDay';
import { PillFirstDose } from './PillFirstDose';
import { PillNameAdd } from './PillNameAdd';
import { PillOftenTake } from './PillOftenTake';
// import { PillAddSuccess } from './PillAddSuccess';
import { PillOthers } from './PillOthers';
import { PillOthersInterval } from './PillOthersInterval';
import { PillPerDay } from './PillPerDay';
import { PillStartDate } from './PillStartDate';

interface Props {
   handleClose: () => void;
   open: boolean;
   handleSubmit: () => void;
   selectedDate: pillRemainderPageTypes.IPillRemainderDate | undefined | null;
   isFromMyProfile?: boolean;
}

const PillRemainderModal = ({ handleClose, open, handleSubmit, selectedDate, isFromMyProfile }: Props) => {
   const dispatch = useDispatch();

   const [alert, setAlert] = useState<JSX.Element | null>(null);
   const [isSuccess] = useState(false);
   const [name, setName] = useState<pillRemainderPageTypes.dropDownName | null>(null);
   const [nameBoolean, setNameBoolean] = useState(true);
   const [type, setType] = useState('');
   const [typeBoolean, setTypeBoolean] = useState(false);

   const [modelType, setModelType] = useState('');

   const [timeArray, setTimeArray] = useState<pillRemainderSagaTypes.IPillRemainderMedTime[]>([
      { time: '', dateTime: null },
   ]);
   const [daysArray, setDaysArray] = useState<pillRemainderSagaTypes.IPillRemainderMedDay[]>([]);
   const [firstDoseTime, setFirstDoseTime] = useState('');
   const [firstDoseDateTime, setFirstDoseDateTime] = useState<Date | null>(null);
   const [pillStartDate, setPillStartDate] = useState('');
   const [pillStartDateTime, setPillStartDateTime] = useState<Date>();
   const [othersType, setOthersType] = useState('');
   const [intervalCount, setIntervalCount] = useState<number>(2);

   const [steps, setSteps] = useState(0);
   const [activeSteps, setActiveSteps] = useState(0);

   const [reminderDays, setReminderDays] = useState<number>(0);

   const {
      isLoading: isLoadingPillRemainder,
      message: pillRemainderMessage,
      error: pillRemainderErrorMessage,
   } = useSelector((state: storeTypes.IStore) => state.pillRemainderAdd);
   const { pillRemainderAddRemainderReset } = pillRemainderAddSlice.actions;

   const { pillRemainderListReset } = pillRemainderListSlice.actions;
   const { medicationsListReset } = medicationListSlice.actions;

   const getRemainderDays = (reminderDays: number) => {
      setReminderDays(reminderDays);
   };

   const pillRemainderName = (name: pillRemainderPageTypes.dropDownName) => {
      setName(name);
      if (nameBoolean) {
         setNameBoolean(false);
         setTypeBoolean(true);
      }
      // steps
      getSteps(type);
      getActiveSteps(type);
   };

   const pillRemainderNameBack = () => {
      setNameBoolean(true);
      setTypeBoolean(false);
      setSteps(0);
      setActiveSteps(0);
   };

   const setNameTypeFalse = () => {
      setNameBoolean(false);
      setTypeBoolean(false);
   };

   const pillRemainderType = (type: string) => {
      setType(type);
      setNameTypeFalse();
      if (type === 'every_day') {
         setModelType('time_array');
      } else if (
         type === 'every_two_days' ||
         type === 'every_fourteen_days' ||
         type === 'every_twenty_one_days' ||
         type === 'every_twenty_eight_days' ||
         type === 'once_a_week' ||
         type === 'two_days_a_week' ||
         type === 'three_days_a_week' ||
         type === 'five_days_a_week'
      ) {
         setModelType('first_dose_time');
      } else if (type === 'others') {
         setModelType('other');
      }
      // steps
      getSteps(type);
      getActiveSteps(type);
   };

   const pillRemainderTypeBack = (type: string) => {
      setNameBoolean(false);
      setTypeBoolean(true);
      // steps
      getSteps(type);
      getActiveStepsReverse(type);
      setModelType('');
   };

   const pillRemainderTimeArray = (timeArray: pillRemainderSagaTypes.IPillRemainderMedTime[]) => {
      setTimeArray(timeArray);
      setNameTypeFalse();
      if (type === 'every_day') {
         const data = {
            name: name?.name || '',
            drug_id: name?.drug_id || '',
            dose_information: name?.dose_information || '',
            unit: name?.unit || '',
            type: type,
            pill_rem_med_times: timeArray,
            from_type: isFromMyProfile ? 'non_cancer' : 'pill',
         };
         dispatch(pillRemainderAddSagaAction(data));
      } else if (type === 'every_two_days') {
      } else if (type === 'every_fourteen_days') {
      } else if (type === 'every_twenty_one_days') {
      } else if (type === 'every_twenty_eight_days') {
      } else if (type === 'once_a_week') {
      } else if (type === 'two_days_a_week') {
      } else if (type === 'three_days_a_week') {
      } else if (type === 'five_days_a_week') {
      } else if (type === 'others') {
      }
   };

   const pillRemainderTimeArrayBack = (timeArray: pillRemainderSagaTypes.IPillRemainderMedTime[]) => {
      setTimeArray(timeArray);
   };

   const pillRemainderChooseDays = (daysArray: pillRemainderSagaTypes.IPillRemainderMedDay[]) => {
      setDaysArray(daysArray);
      setNameTypeFalse();
      if (type === 'every_day') {
         setModelType('time_array');
         getActiveSteps(type);
      } else if (type === 'every_two_days') {
      } else if (type === 'every_fourteen_days') {
      } else if (type === 'every_twenty_one_days') {
      } else if (type === 'every_twenty_eight_days') {
      } else if (
         type === 'once_a_week' ||
         type === 'two_days_a_week' ||
         type === 'three_days_a_week' ||
         type === 'five_days_a_week'
      ) {
         const data = {
            name: name?.name || '',
            drug_id: name?.drug_id || '',
            dose_information: name?.dose_information || '',
            unit: name?.unit || '',
            type: type,
            first_does_time: firstDoseTime,
            first_dose_time: firstDoseTime,
            pill_rem_med_days: daysArray,
            from_type: isFromMyProfile ? 'non_cancer' : 'pill',
         };
         dispatch(pillRemainderAddSagaAction(data));
      } else if (type === 'others') {
         if (othersType === 'specific_days_of_the_week') {
            const data = {
               name: name?.name || '',
               drug_id: name?.drug_id || '',
               dose_information: name?.dose_information || '',
               unit: name?.unit || '',
               type: type,
               others_type: othersType,
               first_does_time: firstDoseTime,
               first_dose_time: firstDoseTime,
               pill_rem_med_days: daysArray,
               from_type: isFromMyProfile ? 'non_cancer' : 'pill',
            };
            dispatch(pillRemainderAddSagaAction(data));
         }
      }
   };

   const pillRemainderChooseDaysBack = (
      daysArray: pillRemainderSagaTypes.IPillRemainderMedDay[],
      type: string,
      modelType: string
   ) => {
      setDaysArray(daysArray);
      setNameTypeFalse();

      // steps
      getSteps(type);
      getActiveStepsReverse(type);
      setModelType(modelType);
   };

   const pillRemainderFirstDoseTime = (firstDoseTime: string) => {
      setFirstDoseTime(firstDoseTime);
      setNameTypeFalse();
      if (type === 'every_day') {
         setModelType('time_array');
      } else if (
         type === 'every_two_days' ||
         type === 'every_fourteen_days' ||
         type === 'every_twenty_one_days' ||
         type === 'every_twenty_eight_days'
      ) {
         setModelType('start_date');
      } else if (type === 'once_a_week') {
         setModelType('single_days_array');
      } else if (type === 'two_days_a_week' || type === 'three_days_a_week' || type === 'five_days_a_week') {
         setModelType('days_array');
      } else if (type === 'others') {
         if (othersType === 'specific_days_of_the_week') {
            setModelType('days_array');
         } else if (othersType === 'every_number_of_days') {
            setModelType('others_interval_days');
         }
      }
      // steps
      getActiveSteps(type);
   };

   const pillRemainderFirstDoseTimeBack = (firstDoseTime: string) => {
      setFirstDoseTime(firstDoseTime);
   };

   const pillRemainderFirstDoseTimeBackOthers = (type: string, modelType: string) => {
      setNameBoolean(false);
      setTypeBoolean(false);
      // steps
      getSteps(type);
      getActiveStepsReverse(type);
      setModelType(modelType);
   };

   const pillRemainderFirstDoseDateTime = (firstDoseDateTime: Date | null) => {
      setFirstDoseDateTime(firstDoseDateTime);
   };

   const pillRemainderStartDate = (pillStartDate: string) => {
      setPillStartDate(pillStartDate);
      setNameTypeFalse();
      if (type === 'every_day') {
      } else if (
         type === 'every_two_days' ||
         type === 'every_fourteen_days' ||
         type === 'every_twenty_one_days' ||
         type === 'every_twenty_eight_days'
      ) {
         const data = {
            name: name?.name || '',
            drug_id: name?.drug_id || '',
            dose_information: name?.dose_information || '',
            unit: name?.unit || '',
            type: type,
            first_does_time: firstDoseTime,
            first_dose_time: firstDoseTime,
            start_date: pillStartDate,
            from_type: isFromMyProfile ? 'non_cancer' : 'pill',
         };
         dispatch(pillRemainderAddSagaAction(data));
      } else if (type === 'once_a_week') {
      } else if (type === 'two_days_a_week') {
      } else if (type === 'three_days_a_week') {
      } else if (type === 'five_days_a_week') {
      } else if (type === 'others') {
      }
   };

   const pillRemainderStartDateTime = (pillStartDate: Date) => {
      setPillStartDateTime(pillStartDate);
   };

   const pillRemainderStartDateBack = (pillStartDate: string, type: string, modelType: string) => {
      setPillStartDate(pillStartDate);
      setNameBoolean(false);
      setTypeBoolean(false);
      // steps
      getSteps(type);
      getActiveStepsReverse(type);
      setModelType(modelType);
   };

   const pillRemainderOthersType = (othersType: string) => {
      setOthersType(othersType);
      if (type === 'others') {
         setModelType('first_dose_time');
         getActiveSteps(type);
      }
   };

   const pillRemainderOthersTypeBack = (othersType: string, type: string, modelType: string) => {
      setOthersType(othersType);
      setNameBoolean(false);
      setTypeBoolean(true);
      // steps
      getSteps(type);
      getActiveStepsReverse(type);
      setModelType('');
   };

   const pillRemainderOthersIntervalDays = (othersIntervalObj: pillRemainderSagaTypes.IPillRemainderIntervalDays) => {
      setIntervalCount(othersIntervalObj.intervalCount);
      setPillStartDate(othersIntervalObj.pillStartDate);
      if (type === 'others') {
         if (othersType === 'every_number_of_days') {
            const data = {
               name: name?.name || '',
               drug_id: name?.drug_id || '',
               dose_information: name?.dose_information || '',
               unit: name?.unit || '',
               type: type,
               others_type: othersType,
               first_does_time: firstDoseTime,
               first_dose_time: firstDoseTime,
               others_interval_days: othersIntervalObj.intervalCount,
               start_date: othersIntervalObj.pillStartDate,
               from_type: isFromMyProfile ? 'non_cancer' : 'pill',
            };
            dispatch(pillRemainderAddSagaAction(data));
         }
      }
   };

   const pillRemainderOthersIntervalDaysBack = (
      intervalCount: number,
      pillStartDate: string,
      type: string,
      modelType: string
   ) => {
      setIntervalCount(intervalCount);
      setPillStartDate(pillStartDate);

      setNameBoolean(false);
      setTypeBoolean(false);
      // steps
      getSteps(type);
      getActiveStepsReverse(type);
      setModelType(modelType);
   };

   const getSteps = (type: string) => {
      if (type === 'every_day') {
         setSteps(3);
      } else if (
         type === 'every_two_days' ||
         type === 'every_fourteen_days' ||
         type === 'every_twenty_one_days' ||
         type === 'every_twenty_eight_days' ||
         type === 'once_a_week' ||
         type === 'two_days_a_week' ||
         type === 'three_days_a_week' ||
         type === 'five_days_a_week'
      ) {
         setSteps(4);
      } else if (type === 'others') {
         setSteps(5);
      } else if (type === '') {
         setSteps(4);
      }
   };

   const getActiveSteps = (type: string) => {
      if (
         type === 'every_day' ||
         type === 'every_two_days' ||
         type === 'every_fourteen_days' ||
         type === 'every_twenty_one_days' ||
         type === 'every_twenty_eight_days' ||
         type === 'once_a_week' ||
         type === 'two_days_a_week' ||
         type === 'three_days_a_week' ||
         type === 'five_days_a_week' ||
         type === 'others' ||
         type === ''
      ) {
         setActiveSteps(activeSteps + 1);
      }
   };

   const getActiveStepsReverse = (type: string) => {
      if (
         type === 'every_day' ||
         type === 'every_two_days' ||
         type === 'every_fourteen_days' ||
         type === 'every_twenty_one_days' ||
         type === 'every_twenty_eight_days' ||
         type === 'once_a_week' ||
         type === 'two_days_a_week' ||
         type === 'three_days_a_week' ||
         type === 'five_days_a_week' ||
         type === 'others' ||
         type === ''
      ) {
         setActiveSteps(activeSteps - 1);
      }
   };

   const confirmToClose = () => {
      const getAlert = () => (
         <SweetAlert
            info
            confirmBtnText="Yes"
            showCloseButton={false}
            showCancel
            cancelBtnBsStyle="cancelBtn"
            confirmBtnBsStyle="successBtn"
            cancelBtnText="No"
            closeOnClickOutside={false}
            focusCancelBtn
            allowEscape={false}
            onCancel={() => {
               setAlert(null);
            }}
            title="Are you sure"
            onConfirm={() => {
               setAlert(null);
               handleClose();
               setEventTrack(PILL_REMAINDER_EVENTS.ADD_PILL_REMINDER_CANCEL);
            }}
         >
            <div className="closed_content">You want to cancel this add reminder?</div>
         </SweetAlert>
      );

      setAlert(getAlert());
      return false;
   };

   const pillRemainderListDispatch = (date: string, convertDateTimezone: string) => {
      const pillRemainderRequest = {
         date,
         time: convertDateTimezone,
      };
      dispatch(pillRemainderListSagaAction(pillRemainderRequest));
   };

   const hideAlert = (type: string) => {
      if (type === 'errorAlert') {
         dispatch(pillRemainderAddRemainderReset());
      } else if (type === 'successAlert') {
         handleClose();
         dispatch(pillRemainderAddRemainderReset());
         dispatch(pillRemainderListReset());
         if (isFromMyProfile) {
            dispatch(medicationsListReset());
            const params: pillRemainderSagaTypes.IPillActiveAndInactiveMedicineList = {
               from_type: 'non_cancer',
            };
            dispatch(medicationListSagaAction(params));
         } else {
            const currentDate = getCurrentDate();
            const convertDateTimezoneDate = convertDateToTimezoneDate(
               selectedDate?.dateFormat ? selectedDate.dateFormat : currentDate
            );
            const convertDateTimezone = convertDateToTimezone(
               selectedDate?.dateFormat ? selectedDate.dateFormat : currentDate
            );
            pillRemainderListDispatch(convertDateTimezoneDate, convertDateTimezone);

            dispatch(medicationsListReset());
            const params: pillRemainderSagaTypes.IPillActiveAndInactiveMedicineList = {
               from_type: 'pill',
            };
            dispatch(medicationListSagaAction(params));
         }
      }
   };

   return (
      <>
         <Dialog
            onClose={handleClose}
            disableBackdropClick={true}
            onBackdropClick={() => {
               confirmToClose();
            }}
            disableEscapeKeyDown={true}
            onEscapeKeyDown={() => {
               confirmToClose();
            }}
            aria-labelledby="customized-dialog-title"
            className="pill-remainder-modal"
            open={open}
            maxWidth="sm"
         >
            {alert}
            {isLoadingPillRemainder && <Loading />}
            {pillRemainderMessage && (
               <InfoTimerAlert
                  success
                  confirmBtnText={`Okay!`}
                  confirmBtnBsStyle={`successBtn`}
                  title={pillRemainderMessage}
                  onConfirm={() => hideAlert('successAlert')}
               />
            )}
            {pillRemainderErrorMessage && (
               <InfoTimerAlert
                  error
                  confirmBtnText={`Okay!`}
                  confirmBtnBsStyle={`errorBtn`}
                  title={pillRemainderErrorMessage}
                  onConfirm={() => hideAlert('errorAlert')}
               />
            )}
            {!isSuccess && (
               <MuiDialogTitle disableTypography>
                  <MobileStepper
                     variant="progress"
                     steps={steps}
                     position="static"
                     className="pill-remainder-stepper"
                     activeStep={activeSteps}
                     backButton=""
                     nextButton=""
                  />
               </MuiDialogTitle>
            )}

            <DialogContent className="style-7">
               {!isSuccess && (
                  <Typography align="center">
                     <img src={PillModalImage} alt="img" className="pill-headline-img" />
                  </Typography>
               )}
               {/* name */}
               {nameBoolean && (
                  <PillNameAdd
                     pillRemainderName={pillRemainderName}
                     getRemainderDays={getRemainderDays}
                     reminderDays={reminderDays}
                     confirmToClose={confirmToClose}
                     name={name}
                  />
               )}
               {/* select type */}
               {typeBoolean && (
                  <PillOftenTake
                     pillRemainderType={pillRemainderType}
                     reminderDays={reminderDays}
                     pillRemainderNameBack={pillRemainderNameBack}
                     type={type}
                  />
               )}

               {/* day time1 and time2 */}
               {modelType === 'time_array' && (
                  <PillPerDay
                     type={type}
                     timeArray={timeArray}
                     pillRemainderTimeArray={pillRemainderTimeArray}
                     pillRemainderTypeBack={pillRemainderTypeBack}
                     pillRemainderTimeArrayBack={pillRemainderTimeArrayBack}
                  />
               )}
               {/* sunday, monday, tuesday */}
               {modelType === 'single_days_array' && (
                  <PillChooseOneDay
                     type={type}
                     daysArray={daysArray}
                     pillRemainderChooseDays={pillRemainderChooseDays}
                     pillRemainderChooseDaysBack={pillRemainderChooseDaysBack}
                  />
               )}
               {/* first dose time */}
               {modelType === 'first_dose_time' && (
                  <PillFirstDose
                     type={type}
                     pillRemainderFirstDoseTime={pillRemainderFirstDoseTime}
                     pillRemainderFirstDoseDateTime={pillRemainderFirstDoseDateTime}
                     pillRemainderTypeBack={pillRemainderTypeBack}
                     pillRemainderFirstDoseTimeBack={pillRemainderFirstDoseTimeBack}
                     firstDoseTime={firstDoseTime}
                     firstDoseDateTime={firstDoseDateTime}
                     pillRemainderFirstDoseTimeBackOthers={pillRemainderFirstDoseTimeBackOthers}
                  />
               )}
               {/* set start date */}
               {modelType === 'start_date' && (
                  <PillStartDate
                     type={type}
                     pillStartDate={pillStartDate}
                     pillStartDateTime={pillStartDateTime}
                     pillRemainderStartDate={pillRemainderStartDate}
                     pillRemainderStartDateTime={pillRemainderStartDateTime}
                     pillRemainderStartDateBack={pillRemainderStartDateBack}
                  />
               )}
               {/* sunday, monday, tuesday checkbox */}
               {modelType === 'days_array' && (
                  <PillChooseMultiDays
                     type={type}
                     daysArray={daysArray}
                     othersType={othersType}
                     pillRemainderChooseDays={pillRemainderChooseDays}
                     pillRemainderChooseDaysBack={pillRemainderChooseDaysBack}
                  />
               )}
               {/* others choose type */}
               {modelType === 'other' && (
                  <PillOthers
                     type={type}
                     pillRemainderOthersType={pillRemainderOthersType}
                     reminderDays={reminderDays}
                     pillRemainderOthersTypeBack={pillRemainderOthersTypeBack}
                     othersType={othersType}
                  />
               )}
               {/* others interval type */}
               {modelType === 'others_interval_days' && (
                  <PillOthersInterval
                     type={type}
                     intervalCount={intervalCount}
                     pillStartDate={pillStartDate}
                     pillStartDateTime={pillStartDateTime}
                     pillRemainderOthersIntervalDays={pillRemainderOthersIntervalDays}
                     reminderDays={reminderDays}
                     othersType={othersType}
                     pillRemainderOthersIntervalDaysBack={pillRemainderOthersIntervalDaysBack}
                     pillRemainderStartDateTime={pillRemainderStartDateTime}
                  />
               )}
               {/* success */}
               {/* <PillAddSuccess /> */}
            </DialogContent>
         </Dialog>
      </>
   );
};

export { PillRemainderModal };
