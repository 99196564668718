import { createSlice } from '@reduxjs/toolkit';
import { viewAppointmentReducerTypes, viewAppointmentSagaTypes } from 'types';

const initialState: viewAppointmentReducerTypes.IAppointmentReducer = {
   viewAppointment: null,
   isLoading: false,
   error: '',
   message: '',
};
const viewAppointmentSlice = createSlice({
   name: 'viewAppointment',
   initialState: initialState,
   reducers: {
      getAppointmentView: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getAppointmentViewSuccess: (state, action: viewAppointmentSagaTypes.IGetAppointmentSuccess) => {
         return {
            ...state,
            viewAppointment: action.payload,
            isLoading: false,
         };
      },
      getAppointmentViewFailed: (state, action) => {
         return {
            ...state,
            viewAppointment: null,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
   },
});
export { viewAppointmentSlice };
