import { createSlice } from '@reduxjs/toolkit';
import { scheduleReducerTypes } from 'types';

const initialState: scheduleReducerTypes.IAddSchedule = {
   isLoading: false,
   error: '',
   message: '',
};

export const addScheduleSlice = createSlice({
   name: 'addSchedule',
   initialState: initialState,
   reducers: {
      addScheduleRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addScheduleSuccess: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      addScheduleFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      addScheduleResetMsg: (state) => {
         return {
            ...state,
            message: '',
            error: '',
         };
      },
   },
});
