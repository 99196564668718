import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { UPDATE_FAMILY_MEMBER } from 'reduxToolKit/actions';
import { commonSlice, updateFamilyMemberSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   updateFamilyMemberRequest,
   updateFamilyMemberSuccess,
   updateFamilyMemberFailed,
} = updateFamilyMemberSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const updateFamilyMemberSaga = function* (updateFamilyMemberPayload: any) {
   yield put(updateFamilyMemberRequest(updateFamilyMemberPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = updateFamilyMemberPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.updateFamilyMember });
      if (response.status === 200) {
         const responseData = response.data;
         const updateFamilyMemberList = {
            message: responseData?.message || '',
         };
         yield put(updateFamilyMemberSuccess(updateFamilyMemberList));
      } else {
         yield put(updateFamilyMemberFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeupdateFamilyMember = function* () {
   yield takeEvery(UPDATE_FAMILY_MEMBER, updateFamilyMemberSaga);
};
