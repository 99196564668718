import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CHECKAPPOINMENT_AVAILABILITY } from 'reduxToolKit/actions';
import { checkAppointmentAvailabilitySlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   checkAppointmentAvailabilityRequest,
   checkAppointmentAvailabilitySuccess,
   checkAppointmentAvailabilityFailed,
} = checkAppointmentAvailabilitySlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

export const checkAppointment = function* (checkAppointmentpayload: any) {
   yield put(checkAppointmentAvailabilityRequest(checkAppointmentpayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = checkAppointmentpayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.appointmentCheckAvailability });
      if (response.status === 200) {
         const responseData = response.data;
         const checkAppointmentList = {
            status: responseData.data.status,
         };
         yield put(checkAppointmentAvailabilitySuccess(checkAppointmentList));
      } else {
         yield put(checkAppointmentAvailabilityFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeCheckAppointmentRequest = function* () {
   yield takeEvery(CHECKAPPOINMENT_AVAILABILITY, checkAppointment);
};
