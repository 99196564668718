import * as HttpMethods from 'utils/constants/method';

export const apiRoutes = {
   //appointment
   AppointmentList: { apiPath: '/appointment', action: HttpMethods.GET },
   getPhysicianAppointmentSchedule: { apiPath: '/appointment-schedule-date', action: HttpMethods.GET },
   createAppointment: { apiPath: '/appointment', action: HttpMethods.POST },
   viewAppointment: { apiPath: '/appointment', action: HttpMethods.GET },
   appointmentUpdate: { apiPath: '/appointment', action: HttpMethods.PUT },
   getAppointmentComment: { apiPath: '/appointment-comment', action: HttpMethods.GET },
   addAppointmentComment: { apiPath: '/appointment-comment', action: HttpMethods.POST },
   editAppointmentComment: { apiPath: '/appointment-comment/', action: HttpMethods.PUT },
   viewAppointmentDocument: { apiPath: '/appointment-document', action: HttpMethods.GET },
   updateAppointmentDocument: { apiPath: '/appointment-document', action: HttpMethods.PUT },
   joinCall: { apiPath: '/video-conference-room', action: HttpMethods.GET },
   joinCallDisconnect: { apiPath: '/video-conference-room', action: HttpMethods.PUT },
   upComingMeeting: { apiPath: '/up-coming-appointments', action: HttpMethods.GET },
   videoConnection: { apiPath: '/video-conference', action: HttpMethods.GET },
   appointmentCheckAvailability: { apiPath: '/user/physician-appointment/check-availability', action: HttpMethods.GET },
   getScheduledPhysiciansList: {
      apiPath: '/user/patient-appointment/get-scheduled-physicians',
      action: HttpMethods.GET,
   },
   addAppointment: { apiPath: 'user/patient-appointment/add-request', action: HttpMethods.POST },
   rescheduleAppointment: { apiPath: '/user/patient-appointment/reschedule', action: HttpMethods.POST },
   getOneAppointment: { apiPath: '/user/get-one-appointment', action: HttpMethods.GET },
   updateAppointmentStatus: { apiPath: '/user/physician-appointment/update-request', action: HttpMethods.POST },
   updateAppointmentStatusPatient: { apiPath: '/user/patient-appointment/update-request', action: HttpMethods.POST },

   //states
   settingGetStateAndTravelList: { apiPath: '/setting', action: HttpMethods.GET },
   addSettingStateAndTravel: { apiPath: '/setting', action: HttpMethods.POST },
   getRPMDashboard: { apiPath: '/user/get-rpm-dashboard', action: HttpMethods.GET },
   getClinicRpmAllDevices: { apiPath: '/user/get-my-clinic-all-rpm-devices', action: HttpMethods.GET },
   getClinicRpmDevices: { apiPath: '/user/get-my-clinic-rpm-devices', action: HttpMethods.GET },

   //oncKlinic
   getMyPhysician: { apiPath: '/my-clinic-physicians', action: HttpMethods.GET },
   myClinicPatient: { apiPath: '/my-clinic-patients', action: HttpMethods.GET },
   clinicVideos: { apiPath: '/user/physician/get-clinic-videos', action: HttpMethods.GET },
   educationVideos: { apiPath: '/user/oncoklinic/get-my-assigned-videos', action: HttpMethods.GET },
   workingHours: { apiPath: '/user/oncoklinic/get-clinic-working_hours', action: HttpMethods.GET },
   symptomList: { apiPath: '/user/symptom-questionnaire/get-symptom-monitoring', action: HttpMethods.GET },
   clinicPhysicianAdmin: { apiPath: '/user/physician/get-physician-admin-clinics', action: HttpMethods.GET },
   clinicJoinRequests: { apiPath: '/user/physician/get-clinic-join-requests', action: HttpMethods.GET },
   clinicJoinRequestByPatient: {
      apiPath: '/user/physician/response/patient-join-clinic-request',
      action: HttpMethods.POST,
   },
   clinicJoinRequestByPhysician: {
      apiPath: '/user/physician/response/physician-join-clinic-request',
      action: HttpMethods.POST,
   },
   latestClinic: { apiPath: '/user/oncoklinic/get-latest-viewed-clinic', action: HttpMethods.GET },
   getMyClinic: { apiPath: '/user/oncoklinic/get-my-clinics', action: HttpMethods.GET },
   getMyAppointments: { apiPath: '/user/get-my-appointments', action: HttpMethods.GET },
   addLatestViewedClinic: { apiPath: '/user/oncoklinic/add-latest-viewed-clinic', action: HttpMethods.POST },
   myTreatingPhysician: { apiPath: '/user/oncoklinic/get-my-physicians', action: HttpMethods.GET },
   myClinicPhysician: { apiPath: '/user/oncoklinic/get-clinic-physicians', action: HttpMethods.GET },
   myClinicPhyCancelReq: { apiPath: '/user/oncoklinic/cancel-request', action: HttpMethods.POST },
   myClinicPhyReq: { apiPath: '/user/oncoklinic/add-patient-request', action: HttpMethods.POST },
   oncMyPatientsList: { apiPath: '/user/oncoklinic/get-my-patients', action: HttpMethods.GET },
   oncNursePatientsList: { apiPath: '/user/nurse/get-my-patients', action: HttpMethods.GET },
   getClinicAllPatients: { apiPath: '/user/get-clinic-patients', action: HttpMethods.GET },
   oncClinicMyPatientsList: { apiPath: '/user/oncoklinic/get-clinic-patients', action: HttpMethods.GET },
   addPatients: { apiPath: '/user/oncoklinic/add-physician-request', action: HttpMethods.POST },
   addNursePatients: { apiPath: '/user/oncoklinic/add-nurse-patients', action: HttpMethods.POST },
   updatePatientRequest: { apiPath: '/user/oncoklinic/update-patient-request', action: HttpMethods.POST },
   getNurseClinic: { apiPath: '/user/nurse/get-my-clinic', action: HttpMethods.GET },
   getClinicalNotesList: { apiPath: '/user/get-clinic-note', action: HttpMethods.GET },
   addClinicalNotes: { apiPath: '/user/add-clinic-note', action: HttpMethods.POST },
   assignClinicHistoryVideo: { apiPath: '/user/oncoklinic/assign-clinic-video', action: HttpMethods.POST },
   addClinicVideos: { apiPath: '/user/physician/add-clinic-video', action: HttpMethods.POST },
   clinicInvitePatientReq: { apiPath: '/user/clinic-admin/clinic-invite', action: HttpMethods.POST },
   clinicPatientBulkImport: { apiPath: '/user/patient-bulk-invite', action: HttpMethods.POST },
   existingClinicInviteReq: { apiPath: '/user/clinic-admin/is-exist-clinic-invite', action: HttpMethods.POST },
   deleteClinicVideos: { apiPath: '/user/physician/delete-clinic-video', action: HttpMethods.POST },
   deleteClinicStaff: { apiPath: '/user/physician/delete-clinic-nurse', action: HttpMethods.POST },
   addClinicStaff: { apiPath: '/user/physician/nurse-registration', action: HttpMethods.POST },
   clinicStaffList: { apiPath: '/user/physician/get-clinic-nurses', action: HttpMethods.GET },
   clinicAdmins: { apiPath: '/user/physician/clinic/get-admins', action: HttpMethods.GET },
   clinicInfo: { apiPath: '/user/get-clinic-basic-info', action: HttpMethods.GET },
   isPendingPrescription: { apiPath: '/user/lines-of-therapy/is-pending-prescription', action: HttpMethods.GET },
   prescriptionUpdateStatus: { apiPath: '/user/prescription/update-status', action: HttpMethods.POST },
   inviteClinicPhysicians: {
      apiPath: '/user/add-physician-by-practice-administrator-and-admin',
      action: HttpMethods.POST,
   },
   deleteClinicPhysicians: { apiPath: '/user/physician/delete-physician-clinic-member', action: HttpMethods.POST },
   deleteClinicPatients: { apiPath: '/user/physician/delete-patient-clinic-member', action: HttpMethods.POST },
   getClinicPhysician: { apiPath: '/user/physician/get-clinic-members', action: HttpMethods.GET },
   getClinicPhysicianInvite: { apiPath: '/user/get-physician-pending-clinic-invites', action: HttpMethods.GET },
   clinicPatients: { apiPath: '/user/clinic-admin/get-clinic-patients', action: HttpMethods.GET },
   clinicInvitePatients: { apiPath: '/user/clinic-admin/get-clinic-invites', action: HttpMethods.GET },
   clinicInfoEdit: { apiPath: '/user/physician/update-clinic', action: HttpMethods.POST },
   clinicPhyInviteIsExist: { apiPath: '/user/physician/check-exist-email', action: HttpMethods.POST },
   clinicDeleteAuxiliary: { apiPath: '/user/delete-clinic-auxiliary-staff', action: HttpMethods.POST },
   addPrescription: { apiPath: '/user/add-prescription', action: HttpMethods.POST },
   getPrescriptionList: { apiPath: '/user/get-prescription-list', action: HttpMethods.GET },
   getPatientAssignedVideos: { apiPath: '/user/oncoklinic/get-patient-assigned-videos', action: HttpMethods.GET },
   phyPractiseAdminList: { apiPath: '/user/physician/get-clinic-practice-administrators', action: HttpMethods.GET },
   auxiliaryList: { apiPath: '/user/get-clinic-auxiliary-staffs', action: HttpMethods.GET },
   joinGroup: { apiPath: '/user/join-group', action: HttpMethods.POST },
   phyPractiseAdminDelete: {
      apiPath: '/user/physician/delete-clinic-practice-administrator',
      action: HttpMethods.POST,
   },

   phyPractiseAdminAdd: {
      apiPath: '/user/physician/add-practice-administrator',
      action: HttpMethods.POST,
   },
   phyPractiseAuxiliaryStaffModelAdd: { apiPath: '/user/add-auxiliary-staff', action: HttpMethods.POST },
   getClinicNoteComment: { apiPath: '/user/get-clinic-note-comments', action: HttpMethods.GET },
   addClinicNoteComment: { apiPath: '/user/add-clinic-note-comment', action: HttpMethods.POST },
   editClinicNoteComment: { apiPath: '/user/edit-clinic-comment', action: HttpMethods.POST },
   clinicNoteCommentClose: { apiPath: '/user/clinic-comment-close', action: HttpMethods.POST },
   getTreatingPhysician: { apiPath: '/user/associated-physicians/get-treating-physicians', action: HttpMethods.GET },
   panelsList: { apiPath: '/user/patient/get-panels', action: HttpMethods.POST },
   subscriptionPanelsList: { apiPath: '/subscribe-panels', action: HttpMethods.GET },
   clinicWorkingHours: { apiPath: '/user/oncoklinic/clinic_working_hour', action: HttpMethods.POST },
   panelRequest: { apiPath: '/user/patient/ask-physician', action: HttpMethods.POST },
   createAskADocRequest: { apiPath: '/user/patient/ask-physician', action: HttpMethods.POST },
   panelReRequest: { apiPath: '/user/patient/ask-physician-rerequest', action: HttpMethods.POST },
   panelPhyList: { apiPath: '/user/patient/panel/get-physicians', action: HttpMethods.POST },
   deleteExperience: { apiPath: '/user/physician/delete-experience', action: HttpMethods.POST },
   diagnosisAddDate: { apiPath: '/initial-diagnosis', action: HttpMethods.POST },
   latinoAddOption: { apiPath: '/hispanic_or_latino', action: HttpMethods.POST },
   stagingAdd: { apiPath: '/staging', action: HttpMethods.POST },
   raceAdd: { apiPath: '/race', action: HttpMethods.POST },
   getMasterRace: { apiPath: '/get-races', action: HttpMethods.GET },
   serviceOfferedAdd: { apiPath: '/service-offered', action: HttpMethods.POST },
   getMasterServiceOffered: { apiPath: '/get-service-offered', action: HttpMethods.GET },
   getUserServiceOffered: { apiPath: '/user-service-offered', action: HttpMethods.GET },
   getUserPopulationServed: { apiPath: '/population-served', action: HttpMethods.GET },
   populationServedAdd: { apiPath: '/population-served', action: HttpMethods.POST },
   orgNameAdd: { apiPath: '/organization', action: HttpMethods.POST },
   geneticTestingAdd: { apiPath: '/genetic-test', action: HttpMethods.POST },
   getMasterGeneticTesting: { apiPath: '/get-genetic-tests', action: HttpMethods.GET },
   getGeneticTestingList: { apiPath: '/genetic-test-list', action: HttpMethods.GET },
   geneticTestingDelete: { apiPath: '/genetic-test/', action: HttpMethods.DELETE },
   medicalHistoryAdd: { apiPath: '/medical-history', action: HttpMethods.POST },
   getMasterMedicalHistory: { apiPath: '/get-medical-histories', action: HttpMethods.GET },
   getMedicalHistoryList: { apiPath: '/medical-history-list', action: HttpMethods.GET },
   ecogAdd: { apiPath: '/ecog', action: HttpMethods.POST },
   getMasterEcog: { apiPath: '/get-ecogs', action: HttpMethods.GET },
   cancerStatusAdd: { apiPath: '/cancer_status', action: HttpMethods.POST },
   getMasterCancerStatus: { apiPath: '/get-cancer-status', action: HttpMethods.GET },
   deleteEducation: { apiPath: '/user/physician/delete-education', action: HttpMethods.POST },
   getFamilyMembers: { apiPath: '/user/get-family-members', action: HttpMethods.GET },
   updateFamilyMember: { apiPath: '/user/patient/update-family-member', action: HttpMethods.POST },
   deleteFamilyMember: { apiPath: '/user/patient/delete-family-member', action: HttpMethods.DELETE },
   deleteClinicalTrial: { apiPath: '/user/physician/delete-clinical-trial', action: HttpMethods.POST },
   deleteCertificate: { apiPath: '/user/physician/delete-certificate', action: HttpMethods.POST },
   getDiseases: { apiPath: '/user/get-diseases', action: HttpMethods.GET },
   getFamilyMemberTypes: { apiPath: '/user/get-family-member-types', action: HttpMethods.GET },
   myServicesList: { apiPath: '/user/get-services', action: HttpMethods.GET },
   myServicesSpecilaityList: { apiPath: '/user/physician/get-services-specialities', action: HttpMethods.GET },
   myServiceUpdate: { apiPath: '/user/physician/update-my-services', action: HttpMethods.POST },
   addMyServicesSpecialties: { apiPath: '/user/physician/add-my-services-specialities', action: HttpMethods.POST },
   getHippaCompliance: { apiPath: '/user/exist-hipaa-compliance', action: HttpMethods.GET },
   addHippaCompliance: { apiPath: '/user/add-hipaa-compliance', action: HttpMethods.POST },
   getPatientState: { apiPath: '/user/patient/get-state', action: HttpMethods.GET },
   getPhysicianState: { apiPath: '/user/physician/get-state', action: HttpMethods.GET },
   getUserState: { apiPath: '/user/get-states', action: HttpMethods.GET },
   updatePhysicianState: { apiPath: '/user/physician/update-state', action: HttpMethods.POST },
   updatePatientState: { apiPath: '/user/patient/update-state', action: HttpMethods.POST },
   getDeviceMinMax: { apiPath: '/user/get-device-min-max', action: HttpMethods.GET },
   addPhysician: { apiPath: '/user/patient/add-physician', action: HttpMethods.POST },
   getSmartWeightReadingAndDifference: {
      apiPath: '/user/get-smart-weight-reading-and-difference',
      action: HttpMethods.GET,
   },
   getIsMyPatient: { apiPath: '/user/oncoklinic/is-my-patient', action: HttpMethods.GET },
   addSmartTemperature: { apiPath: '/user/add-smart-temperature', action: HttpMethods.POST },
   addSmartOxymeter: { apiPath: '/user/add-smart-oximeter', action: HttpMethods.POST },

   // Symptom
   checkClinicTiming: { apiPath: '/user/oncoklinic/check-clinic-timing', action: HttpMethods.GET },

   //oncdrive
   oncDriveListHomeDirectory: { apiPath: '/onco-drive', action: HttpMethods.GET },
   oncDriveListSubDirAndFiles: { apiPath: '/onco-drive/', action: HttpMethods.GET },
   oncDriveAddDirectory: { apiPath: '/onco-drive', action: HttpMethods.POST },
   oncDriveEditDirectory: { apiPath: '/onco-drive', action: HttpMethods.PUT },
   oncDriveDeleteFolderAndFiles: { apiPath: '/onco-drive/', action: HttpMethods.DELETE },
   oncDriveSharedWithMeList: { apiPath: '/onco-drive-analytic', action: HttpMethods.GET },
   oncDriveSharedUsersList: { apiPath: '/onco-drive-analytic', action: HttpMethods.GET },
   oncDriveShareAdd: { apiPath: '/onco-drive-analytic', action: HttpMethods.POST },
   oncDriveUnSharedUser: { apiPath: '/onco-drive-analytic/', action: HttpMethods.DELETE },

   //media
   uploadMedia: { apiPath: '/user/media', action: HttpMethods.POST }, // not use
   deleteS3Media: { apiPath: '/s3/delete', action: HttpMethods.DELETE }, // patient and physician
   getS3SignedURL: { apiPath: '/user/generate-pre-singed-s3-url', action: HttpMethods.GET },
   deleteS3FileKey: { apiPath: '/user/s3/file-delete', action: HttpMethods.DELETE },

   //medicalEnquiry
   medicalEnquiresList: { apiPath: '/medical-enquiries', action: HttpMethods.GET },
   medicalEnqMyClinicPhysiciansList: { apiPath: '/my-clinic-physicians', action: HttpMethods.GET },
   medicalEnqAdd: { apiPath: '/medical-enquiries', action: HttpMethods.POST },
   medicalEnqUpdate: { apiPath: '/medical-enquiries/', action: HttpMethods.PUT },
   medicalEnqGetOne: { apiPath: '/medical-enquiries/', action: HttpMethods.GET },
   medicalEnqAddComment: { apiPath: '/medical-enquiries-comment', action: HttpMethods.POST },
   medicalEnqGetComment: { apiPath: '/medical-enquiries-comment', action: HttpMethods.GET },
   medicalEnqEditComment: { apiPath: '/medical-enquiries-comment/', action: HttpMethods.PUT },
   medicalEnqDeleteComment: { apiPath: '/medical-enquiries-comment/', action: HttpMethods.DELETE },
   medicalEnqDocumentsList: { apiPath: '/medical-enquiry-documents', action: HttpMethods.GET },
   medicalEnqGetOneDocument: { apiPath: '/medical-enquiry-document/', action: HttpMethods.GET },
   medicalEnqUpdateDocument: { apiPath: '/medical-enquiry-document/', action: HttpMethods.PUT },
   getPatientMedications: { apiPath: '/user/get-patient-medications', action: HttpMethods.GET },

   //invite
   userInvite: { apiPath: '/api/user/invite', action: HttpMethods.POST },
   userInviteValidation: { apiPath: '/api/user/invite/validation', action: HttpMethods.POST },
   pendingRequests: { apiPath: '/user/get-pending-requests', action: HttpMethods.GET },
   inviteRequests: { apiPath: '/user/get-pending-invites', action: HttpMethods.GET },
   getMyConnections: { apiPath: '/user/get-my-connections', action: HttpMethods.GET },
   acceptConnection: { apiPath: '/user/connection-invite/accept', action: HttpMethods.POST },
   connectionRequest: { apiPath: '/user/connection-request', action: HttpMethods.POST },
   connectionRequestDelete: { apiPath: '/user/delete-connection-request', action: HttpMethods.DELETE },
   connectionInviteDelete: { apiPath: '/user/delete-invite-request', action: HttpMethods.DELETE },
   connectionMyDelete: { apiPath: '/user/delete-connection', action: HttpMethods.DELETE },

   //my-profile
   userDetails: { apiPath: '/user/my-profile', action: HttpMethods.GET },
   profileCompletion: { apiPath: '/user/sign-up-profile-update', action: HttpMethods.POST },
   myProfile: { apiPath: '/user/my-profile', action: HttpMethods.GET },
   verifyOtp: { apiPath: '/user/verify-phone-otp', action: HttpMethods.POST },
   resendOtp: { apiPath: '/user/send-phone-otp', action: HttpMethods.POST },
   getUserProfile: { apiPath: '/user/get-profile', action: HttpMethods.GET },
   updateProfile: { apiPath: '/user/update-profile', action: HttpMethods.POST },
   physicianInfo: { apiPath: '/user/get-physician-basic-info', action: HttpMethods.GET },
   profilePicture: { apiPath: '/user/update-profile-image', action: HttpMethods.POST },
   changePassword: { apiPath: '/user/change-password', action: HttpMethods.POST },
   userRegister: { apiPath: '/register', action: HttpMethods.POST },
   forgotPassword: { apiPath: '/forgot-password', action: HttpMethods.POST },
   resetPassword: { apiPath: '/reset-password-via-email', action: HttpMethods.POST },
   setPassword: { apiPath: '/set-password-via-email', action: HttpMethods.POST },
   resetViaPhone: { apiPath: '/reset-password-via-phone', action: HttpMethods.POST },
   emailVerification: { apiPath: '/email-verification', action: HttpMethods.POST },
   getCountries: { apiPath: '/user/get-countries', action: HttpMethods.GET },
   blockUser: { apiPath: '/block-user', action: HttpMethods.POST },
   unBlockUser: { apiPath: '/unblock-user', action: HttpMethods.POST },
   blockedUsersList: { apiPath: '/blocked-users', action: HttpMethods.GET },

   // Delete Account
   deleteAccount: { apiPath: '/delete-account', action: HttpMethods.DELETE },

   // video library
   videoLibraryList: { apiPath: '/video-library', action: HttpMethods.GET },
   videoLibraryCategoryList: { apiPath: '/video-category', action: HttpMethods.GET },
   newStateAdding: { apiPath: '/add-new-state', action: HttpMethods.POST },
   orgUserEduMyLibAdd: { apiPath: '/video-library', action: HttpMethods.POST },
   orgUserEduMyLibUpdate: { apiPath: '/video-library/', action: HttpMethods.PUT },
   orgUserEduMyLibDelete: { apiPath: '/video-library/', action: HttpMethods.DELETE },
   feedOrgList: { apiPath: '/organization', action: HttpMethods.GET },

   //feed
   feedReactionsAdd: { apiPath: '/api/post/', action: HttpMethods.POST },
   feedPollOption: { apiPath: '/api/poll-option/', action: HttpMethods.POST },
   showPatientFeed: { apiPath: '/update-patient-feed-status', action: HttpMethods.POST },
   publicPostShare: { apiPath: '/user/ask-a-doc-feed/share', action: HttpMethods.POST },
   postShare: { apiPath: '/user/share-post', action: HttpMethods.POST },
   getTagUsersList: { apiPath: '/api/user/tag', action: HttpMethods.POST },
   getHashTagsList: { apiPath: '/api/hashtag', action: HttpMethods.GET },
   deletePostComment: { apiPath: '/api/post/delete-comment', action: HttpMethods.POST },
   commentReplyAdd: { apiPath: '/comment-reply', action: HttpMethods.POST },
   commentReplyUpdate: { apiPath: '/comment-reply/', action: HttpMethods.PUT },
   commentReplyDelete: { apiPath: '/comment-reply/', action: HttpMethods.DELETE },
   getReplyComments: { apiPath: '/comment-reply', action: HttpMethods.GET },
   getAskADocFeedComments: { apiPath: '/user/ask-a-doc-feed/get-comments', action: HttpMethods.POST },
   getPostComments: { apiPath: '/user/post/get-comments', action: HttpMethods.POST },
   getOneFeed: { apiPath: '/user/get-one-post', action: HttpMethods.GET },
   commentsUpdateReactions: { apiPath: '/comment-reaction', action: HttpMethods.POST },
   askADocFeedPostReactions: { apiPath: '/ask-a-doc/like', action: HttpMethods.POST },
   feedAddPost: { apiPath: '/user/add-post', action: HttpMethods.POST },
   feedEditPost: { apiPath: '/user/edit-post', action: HttpMethods.POST },
   feedDeletePost: { apiPath: '/user/delete-post', action: HttpMethods.POST },
   feedPollUsersList: { apiPath: '/poll-option/list', action: HttpMethods.GET },
   feedMeditation: { apiPath: '/meditation-log', action: HttpMethods.POST },
   //supportGroup
   supportGroupUnreadCount: { apiPath: '/support-group/unread-count', action: HttpMethods.GET },
   supportGroupReadAll: { apiPath: '/support-group/read-all', action: HttpMethods.POST },

   //ask-a-doc
   getAskADocCreateRequest72Hrs: { apiPath: '/ask-physician/has-access', action: HttpMethods.GET },
   getAskPhysician: { apiPath: '/user/ask-physician-list', action: HttpMethods.GET },
   getAskOtherPhyAnsweredList: { apiPath: '/other-physician-list', action: HttpMethods.GET },
   getAskPhysicianCounts: { apiPath: '/user/ask-physician-statistics', action: HttpMethods.GET },
   askADocFeedCommentDelete: { apiPath: '/user/ask-a-doc-feed/delete-comment', action: HttpMethods.POST },
   askADocList: { apiPath: '/user/ask-a-doc-feed', action: HttpMethods.GET },
   askADocExampleQuesList: { apiPath: '/example-questions', action: HttpMethods.GET },
   askADocCaseDetails: { apiPath: '/user/ask-physician-get-one', action: HttpMethods.GET },
   rejectAskADocRequestCaseDetails: { apiPath: '/user/ask-physician/remove-request', action: HttpMethods.POST },
   pickAskADocRequest: { apiPath: '/ask-a-doc/request-picker/', action: HttpMethods.PUT },
   askADocViewAnswers: { apiPath: '/user/ask-physician-get-one', action: HttpMethods.GET },
   askADocCaseDetailsAnswers: { apiPath: '/user/ask-physician/add-answers', action: HttpMethods.POST },
   askADocFeedCommentEdit: { apiPath: '/user/ask-a-doc-feed/update-comment', action: HttpMethods.POST },
   askADocFeedComment: { apiPath: '/user/ask-a-doc-feed/add-comment', action: HttpMethods.POST },
   editComment: { apiPath: '/user/post/update-comment', action: HttpMethods.POST },
   addComment: { apiPath: '/user/post/add-comment', action: HttpMethods.POST },
   getAskADocOneFeed: { apiPath: '/user/get-one-ask-a-doc', action: HttpMethods.GET },
   askADocAddEstimate: { apiPath: '/ask-a-doc/estimate', action: HttpMethods.POST },
   askADocCancelEstimate: { apiPath: '/ask-a-doc-estimate/cancel/', action: HttpMethods.PUT },
   askADocAddEstimatePayment: { apiPath: '/ask-a-doc/payment', action: HttpMethods.POST },
   askADocEstimateEndChat: { apiPath: '/ask-a-doc/end-estimate-conversation/', action: HttpMethods.POST },
   askADocPhyPanelRequestList: { apiPath: '/panel-requests', action: HttpMethods.GET },
   askADocPhyPanelList: { apiPath: '/subscription/ask-a-doc/my-panels', action: HttpMethods.GET },
   askADocTreatmentEstimateEnabledPhyList: {
      apiPath: '/treatment-estimate/enable-physicians',
      action: HttpMethods.GET,
   },
   askADocPanelPhysicianList: { apiPath: '/my-panel_members', action: HttpMethods.GET },
   checkRequiredFields: { apiPath: '/user/physician/check-required-fields', action: HttpMethods.GET },
   askADocEndConversation: { apiPath: '/user/end-conversation', action: HttpMethods.POST },
   askADocConversationAddQuestion: { apiPath: '/user/conversation/add-question', action: HttpMethods.POST },
   askADocChangeClinic: { apiPath: '/subscription/change-panel', action: HttpMethods.POST },
   askADocPrePopulateCreateRequestData: { apiPath: '/ask-a-doc/prepopulate', action: HttpMethods.GET },
   askADocCaseHistory: { apiPath: '/ask-a-doc/history', action: HttpMethods.GET },

   // onco meditation
   getOncoMeditationList: { apiPath: '/meditation', action: HttpMethods.GET },

   //second opinion
   secondOpinionPhyList: { apiPath: '/user/patient/ask-a-doc/get-physicians-list', action: HttpMethods.POST },
   addShortListPhysician: { apiPath: '/user/shortlist/add-physician', action: HttpMethods.POST },
   deleteShortListPhysician: { apiPath: '/user/shortlist/delete-physician', action: HttpMethods.POST },
   secOpinionNearbyPhyList: { apiPath: '/user/patient/get-nearby-physicians', action: HttpMethods.POST },
   secondOpinionShortListPhysician: { apiPath: '/user/shortlist/get-physicians', action: HttpMethods.GET },
   getOneSecondOpinionSummery: { apiPath: '/user/get-one-ask-physician-summary/', action: HttpMethods.GET },

   // daily checklog
   dailyCheckLog: { apiPath: '/daily-checkin/logs', action: HttpMethods.GET },
   addCheckLog: { apiPath: '/daily-checkin/logs', action: HttpMethods.POST },
   addCheckNotes: { apiPath: '/daily-checkin-logs/', action: HttpMethods.POST },
   dailyCheckInsMasterList: { apiPath: '/daily-check-ins', action: HttpMethods.GET },
   dailyCheckInsList: { apiPath: '/check-in/graph', action: HttpMethods.POST },
   dailyCheckInsGetOne: { apiPath: '/check-in/', action: HttpMethods.GET },
   dailyCheckInsAdd: { apiPath: '/check-in', action: HttpMethods.POST },
   dailyCheckInsAddPost: { apiPath: '/check-in/', action: HttpMethods.PUT },
   dailyCheckInsUpdated: { apiPath: '/updated-check-ins', action: HttpMethods.GET },
   dailyCheckInsContinuous: { apiPath: '/continuous-check-in', action: HttpMethods.GET },
   dailyCheckInsStreaksMasterList: { apiPath: '/check-in-streaks', action: HttpMethods.GET },
   dailyCheckInsStreakUserList: { apiPath: '/check-in-user-streaks', action: HttpMethods.GET },
   dailyCheckInsStreakFeed: { apiPath: '/check-in-streak-feed', action: HttpMethods.POST },

   //pill remainder
   pillRemainderAdd: { apiPath: '/pill-reminder', action: HttpMethods.POST },
   pillRemainderList: { apiPath: '/pill-reminder', action: HttpMethods.GET },
   pillRemainderAllPillsList: { apiPath: '/pills', action: HttpMethods.GET },
   pillRemainderGetOne: { apiPath: '/pill-reminder/', action: HttpMethods.GET },

   // addendum and summary
   peopleNearMe: { apiPath: '/people/near-me', action: HttpMethods.GET },
   addAddendum: { apiPath: '/user/ask-physician-addendum', action: HttpMethods.POST },
   addSymptom: { apiPath: '/user/symptom-questionnaire/add-symptom-monitoring', action: HttpMethods.POST },
   addendumUpdate: { apiPath: '/user/ask-physician-addendum', action: HttpMethods.PUT },
   addSummary: { apiPath: '/user/ask-physician-summary', action: HttpMethods.POST },
   summaryUpdate: { apiPath: '/user/ask-physician-summary', action: HttpMethods.PUT },

   //cancer
   getCancerType: { apiPath: '/user/get-cancer-types', action: HttpMethods.GET },
   getSecondaryCancer: { apiPath: '/user/get-cancers', action: HttpMethods.GET },
   topState: { apiPath: '/user/physician/get-my-state-patient-count', action: HttpMethods.GET },
   topCancer: { apiPath: '/user/physician/get-my-state-cancer-type-patient-count', action: HttpMethods.GET },

   //pill-reminder
   pillRemainderDelete: { apiPath: '/pill-reminder/', action: HttpMethods.DELETE },
   pillRemainderNonCancerDelete: { apiPath: '/non-cancer-medication', action: HttpMethods.POST },
   pillRemainderEditComment: { apiPath: '/pill-reminder/', action: HttpMethods.PUT },
   pillMedicationList: { apiPath: '/pill-reminder/list', action: HttpMethods.GET },

   //symptom
   reportSymptomListOld: { apiPath: '/user/get-reportable-symptoms', action: HttpMethods.GET },
   reportSymptomList: { apiPath: '/get-reportable-symptoms', action: HttpMethods.GET },
   reportSymptomQus: { apiPath: '/user/get-symptom-questionnaire', action: HttpMethods.GET },
   getReportReason: { apiPath: '/user/get-report-reasons', action: HttpMethods.GET },
   addReportPost: { apiPath: '/user/add-report-post', action: HttpMethods.POST },
   getReportUserReason: { apiPath: '/user-report-reasons', action: HttpMethods.GET },
   addReportUserPost: { apiPath: '/report-user', action: HttpMethods.POST },
   addReportPostComment: { apiPath: '/report-comment', action: HttpMethods.POST },
   addReportPostCommentReply: { apiPath: '/report-comment-reply', action: HttpMethods.POST },
   getSymptomMonitoringComment: { apiPath: '/user/get-symptom-monitoring-comment/', action: HttpMethods.GET },
   symptomMonitoringCommentsClose: { apiPath: '/user/symptom-monitoring-comment-close', action: HttpMethods.POST },
   addSymptomMonitoringComments: { apiPath: '/user/add-symptom-monitoring-comment', action: HttpMethods.POST },
   editSymptomMonitoringComments: { apiPath: '/user/update-symptom-monitoring-comment', action: HttpMethods.PUT },
   getPatientSymptomMonitoringDocuments: {
      apiPath: '/user/get-patient-symptom-monitoring-documentations',
      action: HttpMethods.GET,
   },
   getPatientRPM: { apiPath: '/user/get-patient-rpm', action: HttpMethods.GET },
   getOneSymptomConsent: { apiPath: '/user/get-one-symptom-monitoring-consent/', action: HttpMethods.GET },

   // treatment method
   treatmentMethodAdd: { apiPath: '/treatment-method', action: HttpMethods.POST },
   treatmentMethodLogList: { apiPath: '/treatment-method', action: HttpMethods.GET },

   //conversations
   convoList: { apiPath: '/user/get-my-conversations', action: HttpMethods.GET },
   userGetAllUser: { apiPath: '/user/get-all-users', action: HttpMethods.GET }, // duplicate

   //wallet
   walletPatient: { apiPath: '/user/get-patient-wallet', action: HttpMethods.GET },
   walletPhysician: { apiPath: '/user/get-physician-wallet', action: HttpMethods.GET },
   walletOffers: { apiPath: '/user/get-patient-wallet-offers', action: HttpMethods.GET },
   physicianReward: { apiPath: '/user/join-to-physician-with-reward', action: HttpMethods.POST },
   getPatientWalletBalance: { apiPath: '/user/get-patient-wallet-balance', action: HttpMethods.GET },
   getPhysicianWalletBalance: { apiPath: '/user/get-physician-wallet-balance', action: HttpMethods.GET },

   //drugs
   getDrugs: { apiPath: '/user/get-drugs', action: HttpMethods.GET },
   getOneDrugs: { apiPath: '/user/physician/get-drug', action: HttpMethods.GET },
   getDrugCancerSpecific: { apiPath: '/user/physician/get-drug-cancer-specific', action: HttpMethods.GET },
   addReview: { apiPath: '/user/physician/drug-cancer-specific/add-review', action: HttpMethods.POST },

   // Drug LookUp
   getReview: { apiPath: '/user/physician/drug-cancer-specific/get-reviews', action: HttpMethods.GET },

   // medication saving
   getMedicationSavingList: { apiPath: '/medication-saving-list', action: HttpMethods.GET },
   getMedicationSavingDetail: { apiPath: '/medication-saving-detail', action: HttpMethods.GET },

   //notification
   notificationCount: { apiPath: '/notification/get-unread-count', action: HttpMethods.GET },
   notificationRead: { apiPath: '/user/notification/is-read', action: HttpMethods.POST },
   notificationAllRead: { apiPath: '/user/notification/read-all', action: HttpMethods.POST },
   getNotificationList: { apiPath: '/get-my-notifications', action: HttpMethods.GET },
   getNotificationSettingsList: { apiPath: '/user/notification/get-my-settings', action: HttpMethods.GET },
   updateNotificationSettings: { apiPath: '/user/notification/update-setting', action: HttpMethods.POST },
   pillRemainderNotificationStatusAdd: { apiPath: '/pill-rem-med-notifies', action: HttpMethods.POST },
   pillRemainderNotificationStatusUpdate: { apiPath: '/pill-rem-med-notifies/', action: HttpMethods.PUT },
   pillRemainderMedicationStatusUpdate: { apiPath: '/pill-reminder/status/', action: HttpMethods.PUT },
   pillRemainderNotificationGetOne: { apiPath: '/pill-rem-med-notifies/', action: HttpMethods.GET },
   getCustomNotification: { apiPath: '/user/get-custom-notifications', action: HttpMethods.GET },
   sendCustomNotification: { apiPath: '/user/custom-notification/send-patient', action: HttpMethods.POST },
   deleteDeviceToken: { apiPath: '/user/notification/delete-device-token', action: HttpMethods.DELETE },
   getOneNotification: { apiPath: '/user/get-one-notification', action: HttpMethods.GET },

   //paypal
   paypalIDUpdate: { apiPath: '/user/physician/update-paypal-id', action: HttpMethods.POST },
   patientPayPalIDUpdate: { apiPath: '/user/patient/update-paypal-id', action: HttpMethods.POST },

   //country and state
   getCountryList: { apiPath: '/get-countries', action: HttpMethods.GET },
   getStateList: { apiPath: '/get-states', action: HttpMethods.GET },

   //social login
   linkedInToken: { apiPath: '/linkedin-token', action: HttpMethods.POST },
   linkedInLogin: { apiPath: '/linkedin-login', action: HttpMethods.POST },
   userLogin: { apiPath: '/login', action: HttpMethods.POST },
   doximityToken: { apiPath: '/doximity-token', action: HttpMethods.POST },
   doximityLogin: { apiPath: '/doximity-login', action: HttpMethods.POST },
   authGoogleLogin: { apiPath: '/api/login', action: HttpMethods.POST },

   //health records
   getPatientHealthRecords: { apiPath: '/user/get-patient-health-records', action: HttpMethods.GET },
   getPhysicianHealthRecords: { apiPath: '/user/get-my-health-records', action: HttpMethods.GET },
   addHealthRecord: { apiPath: '/user/add-health-record', action: HttpMethods.POST },

   //locum service
   locumPractiseAdminList: { apiPath: '/locum-service/list', action: HttpMethods.GET },
   locumPractiseAdminPhySearch: { apiPath: '/locum-service/search', action: HttpMethods.POST },
   locumPractiseAdminPhyBookService: { apiPath: '/locum-service/booking', action: HttpMethods.PUT },
   getLocumServiceState: { apiPath: '/locum-service/state', action: HttpMethods.GET },
   addLocumService: { apiPath: '/locum-service', action: HttpMethods.POST },
   updateLocumService: { apiPath: '/locum-service/', action: HttpMethods.PUT },
   getLocumMonthWiseServiceList: { apiPath: '/locum-service', action: HttpMethods.GET },
   locumGetOneService: { apiPath: '/locum-service/', action: HttpMethods.GET },
   deleteLocumService: { apiPath: '/locum-service/', action: HttpMethods.DELETE },

   // schedule and pescription
   getSchedule: { apiPath: '/user/physician-appointment/get-my-schedule', action: HttpMethods.GET },
   checkScheduleBlock: { apiPath: '/user/physician-appointment/check-schedule-block', action: HttpMethods.POST },
   addSchedule: { apiPath: '/user/physician-appointment/add-schedule', action: HttpMethods.POST },
   getLineOfTherapy: { apiPath: '/user/get-lines-of-therapy', action: HttpMethods.GET },
   getCoMorbidities: { apiPath: '/user/get-morbidities', action: HttpMethods.GET },
   patientActiveRpmDevices: { apiPath: '/user/get-patient-active-rpm-devices', action: HttpMethods.GET },
   getMedications: { apiPath: '/user/get-medications', action: HttpMethods.GET },
   getPatientMorbidities: { apiPath: '/user/get-patient-morbidities', action: HttpMethods.GET },
   editPrescription: { apiPath: '/user/update-prescription', action: HttpMethods.POST },
   feedConnectionStatics: { apiPath: '/user/connection-statistics', action: HttpMethods.GET },
   getOnePrescription: { apiPath: '/user/get-one-prescription', action: HttpMethods.GET },
   getLinesofTherapyHistory: { apiPath: '/user/lines-of-therapy/get-history', action: HttpMethods.GET },
   getClinicPhysicianPrescription: { apiPath: '/user/prescription/get-clinic-physicians', action: HttpMethods.GET },
   getCaregivers: { apiPath: '/user/get-caregivers', action: HttpMethods.GET },
   updateCaregiver: { apiPath: '/user/patient/update-caregiver', action: HttpMethods.POST },
   deleteCaregiver: { apiPath: '/user/patient/delete-caregiver', action: HttpMethods.DELETE },
   getPrimaryDiseases: { apiPath: '/user/get-primary-diseases', action: HttpMethods.GET },
   getGeneticTestCancerMasterList: { apiPath: '/get-genetic-test-cancers', action: HttpMethods.GET },
   getDisclaimer: { apiPath: '/user/exist-disclaimer', action: HttpMethods.GET },
   addDisclaimer: { apiPath: '/user/add-disclaimer', action: HttpMethods.POST },
   myServicesPhyList: { apiPath: '/user/get/physician-services', action: HttpMethods.GET },
   getClinicGroups: { apiPath: '/user/get-clinic-groups', action: HttpMethods.GET },
   getClinicAdminInvites: { apiPath: '/user/get-my-clinic-invites', action: HttpMethods.GET },

   updateClinicAdminInviteStatus: { apiPath: '/user/clinic-invite/update-status', action: HttpMethods.POST },
   getAdvocates: { apiPath: '/user/get-advocates', action: HttpMethods.GET },

   //support groups
   supportGroupsTabList: { apiPath: '/user/get-groups', action: HttpMethods.GET },
   supportGroupsList: { apiPath: '/user/get-feed', action: HttpMethods.GET },
   getGroupInfo: { apiPath: '/user/group/get-basic-details', action: HttpMethods.GET },
   leaveGroup: { apiPath: '/user/leave-group', action: HttpMethods.POST },
   membersList: { apiPath: '/user/group/get-members', action: HttpMethods.GET },

   //devices
   getDevices: { apiPath: '/user/get-devices', action: HttpMethods.GET },
   getBloodPressure: { apiPath: '/user/get-blood-pressures', action: HttpMethods.GET },
   getBloodPressureStatistics: { apiPath: '/user/blood-pressure-statistics', action: HttpMethods.GET },
   getMySmartWeight: { apiPath: '/user/get-my-smart-weights', action: HttpMethods.GET },
   getSmartWeightStatistics: { apiPath: '/user/smart-weight-statistics', action: HttpMethods.GET },

   //Modal
   getCancers: { apiPath: '/user/get-cancers', action: HttpMethods.GET },
   patientDescriptionUpdate: { apiPath: '/user/patient/update-description', action: HttpMethods.POST },
   physicianDescriptionUpdate: { apiPath: '/user/physician/update-description', action: HttpMethods.POST },
   nurseDescriptionUpdate: { apiPath: '/user/nurse/update-description', action: HttpMethods.POST },
   updateSocialHistories: { apiPath: '/user/patient/update-social-histories', action: HttpMethods.POST },
   addFamilyMember: { apiPath: '/user/patient/add-family-member', action: HttpMethods.POST },
   updateCertificate: { apiPath: '/user/physician/update-certificate', action: HttpMethods.POST },
   addCaregiver: { apiPath: '/user/patient/add-caregiver', action: HttpMethods.POST },
   askADocCommentReplyAdd: { apiPath: '/ask-a-doc-comment-reply', action: HttpMethods.POST },
   askADocCommentReplyUpdate: { apiPath: '/ask-a-doc-comment-reply/', action: HttpMethods.PUT },
   askADocCommentReplyDelete: { apiPath: '/ask-a-doc-comment-reply/', action: HttpMethods.DELETE },
   askADocCommentReplyGet: { apiPath: '/ask-a-doc-comment-reply', action: HttpMethods.GET },
   updateClinicalTrial: { apiPath: '/user/physician/update-clinical-trial', action: HttpMethods.POST },
   addUpdateEducation: { apiPath: '/user/physician/update-education', action: HttpMethods.POST },
   updateCoMorbidities: { apiPath: '/user/patient/update-morbidities', action: HttpMethods.POST },
   updateFamilyHistories: { apiPath: '/user/patient/update-family-histories', action: HttpMethods.POST },
   updateExperience: { apiPath: '/user/physician/update-experience', action: HttpMethods.POST },
   inviteViaMail: { apiPath: '/user/invite-via-email', action: HttpMethods.POST },
   deviceAddClinicNote: { apiPath: '/user/add-clinic-note', action: HttpMethods.POST },
   deviceEditClinicNote: { apiPath: '/user/edit-device-clinic-note', action: HttpMethods.POST },
   getClinics: { apiPath: '/user/get-clinics', action: HttpMethods.GET },
   joinClinicPatient: { apiPath: '/user/patient/join-clinic', action: HttpMethods.POST },
   joinClinicPhysician: { apiPath: '/user/physician/join-clinic', action: HttpMethods.POST },
   updatePatientInsurance: { apiPath: '/user/patient/update-insurance', action: HttpMethods.POST },
   updateMedications: { apiPath: '/user/patient/update-medications', action: HttpMethods.POST },
   getAssociatedTreatingPhysicians: {
      apiPath: '/user/associated-physicians/get-treating-physicians',
      action: HttpMethods.GET,
   },
   leaveClinicPatient: { apiPath: '/user/patient/leave-clinic', action: HttpMethods.POST },
   leaveClinicPhysician: { apiPath: '/user/physician/leave-clinic', action: HttpMethods.POST },
   commentsReactionsList: { apiPath: '/comment-reaction', action: HttpMethods.GET },
   userGetAllUsers: { apiPath: '/user/get-all-users', action: HttpMethods.GET }, //duplicate
   updateAdvocate: { apiPath: '/user/patient/update-advocate', action: HttpMethods.POST },
   deletePatientAdvocate: { apiPath: '/user/patient/delete-advocate', action: HttpMethods.DELETE },
   addAdvocate: { apiPath: '/user/patient/add-advocate', action: HttpMethods.POST },
   getSocialHistories: { apiPath: '/user/get-social-histories', action: HttpMethods.GET },
   getSkills: { apiPath: '/user/get-skills', action: HttpMethods.GET },
   updateSkills: { apiPath: '/user/physician/update-skills', action: HttpMethods.POST },
   deletePublication: { apiPath: '/user/physician/delete-publication', action: HttpMethods.POST },
   updatePublication: { apiPath: '/user/physician/update-publication', action: HttpMethods.POST },
   updatePhysician: { apiPath: '/user/patient/update-physician', action: HttpMethods.POST },
   deletePhysician: { apiPath: '/user/patient/delete-physician', action: HttpMethods.DELETE },
   getAssociatedExceptTreatingPhysicians: {
      apiPath: '/user/associated-physicians/get-except-treating-physicians',
      action: HttpMethods.GET,
   },
   addAssociatedPhysicians: { apiPath: '/user/associated-physicians/add-physician', action: HttpMethods.POST },

   // Job Recruiter
   jobCreation: { apiPath: '/job-offer', action: HttpMethods.POST },
   adminJobsList: { apiPath: '/get-physician-applied-jobs', action: HttpMethods.GET },
   acceptRejectShortPhysician: { apiPath: '/job-applicants/status-and-shortlist/', action: HttpMethods.PUT },
   recruiterJobCreation: { apiPath: '/job-offer', action: HttpMethods.POST },
   recruiterJobEdit: { apiPath: '/job-offer', action: HttpMethods.PUT },
   getPhysicianJobOffersList: { apiPath: '/get-job-offers', action: HttpMethods.GET },
   getPhysicianAppliedJobsList: { apiPath: '/get-applied-job-offers', action: HttpMethods.GET },
   getPhysicianJobOffersListGetOne: { apiPath: '/job-offer/', action: HttpMethods.GET },
   physicianAppliedJobOffer: { apiPath: '/applied-job-offers/', action: HttpMethods.PUT },
   physicianAddJobFavourite: { apiPath: '/make-job-favorite/', action: HttpMethods.PUT },
   physicianSpecialities: { apiPath: '/user/get-specialities', action: HttpMethods.GET },
   recruiterJobsList: { apiPath: '/get-my-responsible-job-offers', action: HttpMethods.GET },
   recruiterJobDelete: { apiPath: '/job-offer/', action: HttpMethods.DELETE },

   // Chat
   getConversationUnreadCount: { apiPath: '/user/get-unread-conversation-count', action: HttpMethods.GET },
   getOneToOneConversation: { apiPath: '/user/get-one-to-one-conversation', action: HttpMethods.GET },
   askADocConversationAddMessage: { apiPath: '/user/add-message', action: HttpMethods.POST },
   askADocGetConversationMessage: { apiPath: '/user/get-conversation-message', action: HttpMethods.GET },
   getConversationCreateVerifiedUser: {
      apiPath: '/onco-verified-user/conversation-creation',
      action: HttpMethods.POST,
   },

   // Feed - Save Post
   saveUnsaveAPost: { apiPath: '/api/save-post/', action: HttpMethods.PUT },
   getMySavedPostList: { apiPath: '/user/get-feed', action: HttpMethods.GET },
   getMyPosts: { apiPath: '/user/get-my-posts', action: HttpMethods.GET },

   // notification device token
   notificationAddDeviceToken: { apiPath: '/user/notification/add-device-token', action: HttpMethods.POST },
   // home rpm interval remainder
   getHomeRpmIntervalReminders: { apiPath: '/user/home-rpm-interval-reminders', action: HttpMethods.GET },
   // global search
   getGlobalSearch: { apiPath: '/global-search', action: HttpMethods.GET },
   // recent search
   getRecentSearch: { apiPath: '/recent-search', action: HttpMethods.GET },
   addRecentSearch: { apiPath: '/recent-search', action: HttpMethods.POST },

   //admin notification
   adminNotification: { apiPath: '/admin/notification', action: HttpMethods.POST },

   //physician npi number
   phyNpiNumberVerify: { apiPath: '/user/physician/npi-number/check-is-exist', action: HttpMethods.GET },
   phyAddNpiNumber: { apiPath: '/user/physician/add-npi-number', action: HttpMethods.POST },
   // sub speciality
   getSubSpeciality: { apiPath: '/get-sub-specialities', action: HttpMethods.GET },
   //network
   networkAllUsersList: { apiPath: '/user/get-all-users', action: HttpMethods.GET }, // duplicate

   // Subscription Plan
   getSubscriptionPlansList: { apiPath: '/get-plans', action: HttpMethods.GET },
   checkPlanActive: { apiPath: '/subscription/check', action: HttpMethods.GET },
   getOneSubscriptionPlan: { apiPath: '/subscription/get-plan', action: HttpMethods.GET },
   subscriptionPlanCreate: { apiPath: '/subscription/create', action: HttpMethods.POST },
   subscriptionPlanCancel: { apiPath: '/subscription/cancel', action: HttpMethods.PUT },
   subscriptionActivePlan: { apiPath: '/subscription/active-plans', action: HttpMethods.GET },
   subscriptionCheckCouponCode: { apiPath: '/coupon/check', action: HttpMethods.GET },
   subscriptionStripePortal: { apiPath: '/stripe/portal', action: HttpMethods.GET },
   subscriptionCheckWallet: { apiPath: '/subscription/check-wallet', action: HttpMethods.GET },
   subscriptionOneTimePurchase: { apiPath: '/ask-a-doc/checkout', action: HttpMethods.POST },
   subscriptionCallback: { apiPath: '/subscription/callback', action: HttpMethods.POST },

   //vdocipher
   vdoCipher: { apiPath: '/vdo-cipher/generate-otp', action: HttpMethods.GET },

   // Symtom
   updateSymptomMonitoringConsent: { apiPath: '/user/Update-symptom-monitoring-consent', action: HttpMethods.PUT },

   // event track
   eventTrackLog: { apiPath: '/event-log', action: HttpMethods.POST },

   //feed share link
   feedShareLink: { apiPath: '/post-user-link', action: HttpMethods.POST },
   feedShareLinkUserWithoutAuth: { apiPath: '/post-user-link-user', action: HttpMethods.POST },
   feedShareLinkUserWithAuth: { apiPath: '/user/post-user-link-user', action: HttpMethods.POST },
   // announcement
   announcementList: { apiPath: '/announcement-list', action: HttpMethods.GET },
   announcementResponse: { apiPath: '/announcement-response', action: HttpMethods.POST },
   // clinical trials
   clinicalTrialsList: { apiPath: '/clinical-trial', action: HttpMethods.POST },
   clinicalTrialsInterestedList: { apiPath: '/interested-clinical-trials', action: HttpMethods.GET },
   clinicalTrialsInterest: { apiPath: '/clinical-trial-interest', action: HttpMethods.POST },
   clinicalTrialsStudyType: { apiPath: '/study-type', action: HttpMethods.GET },
   clinicalTrialsStudyPhase: { apiPath: '/study-phase', action: HttpMethods.GET },
   clinicalTrialsStates: { apiPath: '/clinical_trial-get-states', action: HttpMethods.POST },
   clinicalTrialsCancerSpecific: { apiPath: '/clinical_trial-cancers', action: HttpMethods.POST },
   clinicalTrialsListItemGetOne: { apiPath: '/clinical-trial-detail', action: HttpMethods.GET },
   // hide this post
   hidePost: { apiPath: '/hide-post', action: HttpMethods.POST },
   ctFilledPost: { apiPath: '/ct-field-popup', action: HttpMethods.POST },
   // community guidelines
   addCommunityGuidelines: { apiPath: '/community-guidelines', action: HttpMethods.PUT },
   // create post is clinic user
   createPostIsClinicUser: { apiPath: '/is-oncklinic-user', action: HttpMethods.GET },
};
