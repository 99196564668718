import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { WALLET, WALLET_BALANCE_GET } from 'reduxToolKit/actions';
import { commonSlice, walletSlice, walletBalanceGetSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';
import { getUser } from 'utils/helpers/authHeader';
import { isPCAUser, isPhysician } from 'utils/helpers/userHelpers/userTypeHelpers';

const { walletListLoading, walletListViewRequest, walletListViewSuccess, walletListFailed } = walletSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const walletSaga = function* (cancerTypePayload: any) {
   yield put(walletListLoading());
   yield put(walletListViewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.walletPatient });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            message: response.data?.message,
         };
         yield put(walletListViewSuccess(oncDriveList));
      } else {
         yield put(walletListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const { walletBalanceGetRequest, walletBalanceGetSuccess, walletBalanceGetFailed } = walletBalanceGetSlice.actions;

const getWalletBalance = function* (walletBalanceGet: any) {
   yield put(walletBalanceGetRequest(walletBalanceGet.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = walletBalanceGet.payload;
      let url: { apiPath: string; action: string } = { apiPath: '', action: '' };
      if (isPCAUser(getUser())) {
         url = API.getPatientWalletBalance;
      } else if (isPhysician(getUser())) {
         url = API.getPhysicianWalletBalance;
      }
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...url });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(walletBalanceGetSuccess(responseData));
      } else {
         yield put(walletBalanceGetFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeWallet = function* () {
   yield takeEvery(WALLET, walletSaga);
   yield takeEvery(WALLET_BALANCE_GET, getWalletBalance);
};
