export const jsUcfirst = (string: string) => {
   if (string) {
      const convertString = string.charAt(0).toUpperCase() + string.slice(1);
      return convertString.trim();
   }
   return '';
};

export const jsUcFirstCaps = (string: string) => {
   if (string) {
      const convertString = string.charAt(0).toUpperCase();
      return convertString.trim();
   }
   return '';
};

export const convertStringToLink = (content: any, start: number, end: number) => {
   const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
   const description = jsUcfirst(content.replace(reg, "<a target='_blank' href='$1$2'>$1$2</a>"));
   return description.substring(start, end);
};

export const convertStringToLinkLength = (content: any) => {
   const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
   const description = jsUcfirst(content.replace(reg, '<a target="_blank" href="$1$2">$1$2</a>'));
   return description.length;
};

export const stringReplaceNewLine = (newLineString: any) => {
   if (newLineString) {
      return newLineString.split('\n').join('<br/>');
   } else {
      return newLineString;
   }
};

export const toUpperCase = (string: string) => {
   if (string) {
      return string.toUpperCase();
   }
};

export const toConvertLowerCase = (string: string) => {
   if (string) {
      return string?.toString()?.toLowerCase() || string;
   }
   return '';
};

export const textOverFlow = (string: string, limit: number) => {
   return string ? (string.length < limit ? string : string.substring(0, limit) + '...') : '';
};

export const stringLastCharRemove = (word: string) => {
   return word.slice(0, -1);
};

export const letterSpaceWithNumber = (event: any) => {
   const inputValue = event.charCode;
   //&& (inputValue != 46)  DOt
   if (
      !(inputValue >= 48 && inputValue <= 57) &&
      !(inputValue >= 65 && inputValue <= 90) &&
      !(inputValue >= 97 && inputValue <= 122) &&
      inputValue !== 32 &&
      inputValue !== 0 &&
      inputValue !== 46 &&
      inputValue !== 95 &&
      inputValue !== 45
   ) {
      event.preventDefault();
   }
};

export const letterSpace = (event: any) => {
   const inputValue = event.charCode;
   //&& (inputValue != 46)  DOt
   if (
      !(inputValue >= 65 && inputValue <= 90) &&
      !(inputValue >= 97 && inputValue <= 122) &&
      inputValue !== 32 &&
      inputValue !== 0
   ) {
      event.preventDefault();
   }
};

export const keyExtensionFromString = (str: string) => {
   return str.split('.').pop();
};

export const urlPatternValidation = (URL: string) => {
   const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
   return regex.test(URL);
};
