import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { PILL_REMAINDER_MEDICATION_STATUS_UPDATE_SAGA } from 'reduxToolKit/actions';
import { commonSlice, pillRemainderMediStatusUpdateSlice } from 'reduxToolKit/reducers';
import { pillRemainderSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   pillRemainderMediStatusUpdateRequest,
   pillRemainderMediStatusUpdateSuccess,
   pillRemainderMediStatusUpdateFailed,
} = pillRemainderMediStatusUpdateSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const pillRemainderMediUpdate = function* (
   pillRemainderMediUpdate: PayloadAction<pillRemainderSagaTypes.IPillRemainderMedicationStatusUpdateParams>
) {
   yield put(pillRemainderMediStatusUpdateRequest(pillRemainderMediUpdate.payload));
   try {
      const data = pillRemainderMediUpdate.payload;
      const apiPath = {
         apiPath: `${API.pillRemainderMedicationStatusUpdate.apiPath}${pillRemainderMediUpdate.payload.id}`,
         action: API.pillRemainderMedicationStatusUpdate.action,
      };
      const response: AxiosResponse = yield apiCall({ data, ...apiPath });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(pillRemainderMediStatusUpdateSuccess(resObj));
      } else {
         yield put(pillRemainderMediStatusUpdateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takePillRemainderMediUpdateRequest = function* () {
   yield takeEvery(PILL_REMAINDER_MEDICATION_STATUS_UPDATE_SAGA, pillRemainderMediUpdate);
};
