import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { GET_USER_STATE_LISTS, UPDATE_USER_STATE } from 'reduxToolKit/actions';
import { commonSlice, userStateSlice } from 'reduxToolKit/reducers';
import { userStateSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getUserStatesRequest,
   getUserStatesSuccess,
   getUserStatesFailed,
   updateUserStateRequest,
   updateUserStateSuccess,
   updateUserStateFailed,
} = userStateSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

export const getStateListSaga = function* (payloadState: PayloadAction<userStateSagaTypes.IGetStateLists>) {
   yield put(getUserStatesRequest(payloadState.payload));
   if (payloadState.payload.search_word) {
      yield delay(500);
   }
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getUserState });
      if (response.status === 200) {
         const stateLists = {
            list: response.data?.data?.list || [],
            total_records: response.data?.data?.total_records || 0,
            message: response.data?.message || messages.success,
         };
         yield put(getUserStatesSuccess(stateLists));
      } else {
         yield put(getUserStatesFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const updateUserStateSaga = function* (payloadState: PayloadAction<userStateSagaTypes.IUpdateUserState>) {
   yield put(updateUserStateRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;

      const requestData = payloadState.payload;
      let data;
      let customUrl;
      if (requestData?.isPhysician) {
         data = {
            state_ids: requestData.state_id,
         };
         customUrl = API.updatePhysicianState;
      } else {
         data = {
            state_id: requestData.state_id,
         };
         customUrl = API.updatePatientState;
      }

      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...customUrl });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(updateUserStateSuccess(responseData));
      } else {
         yield put(updateUserStateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeUserStateSaga = function* () {
   yield takeLatest(GET_USER_STATE_LISTS, getStateListSaga);
   yield takeEvery(UPDATE_USER_STATE, updateUserStateSaga);
};
