import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   GET_APPLICANTS_LIST,
   ACCEPT_REJECT_SHORT_PHYSICIAN,
   RECRUITER_JOB_CREATE_ACTION_SAGA,
   RECRUITER_JOB_DETAIL_SAGA,
   RECRUITER_JOBS_LIST_SAGA,
   RECRUITER_JOB_DELETE_SAGA,
} from 'reduxToolKit/actions';
import { recruiterAdminJobSlice, commonSlice } from 'reduxToolKit/reducers';
import { adminJobSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   recruiterJobDetailRequest,
   recruiterJobDetailSuccess,
   recruiterJobDetailFailed,
   recruiterJobCreationRequest,
   recruiterJobCreationSuccess,
   recruiterJobCreationFailed,
   adminApplicantsListRequest,
   adminApplicantsListSuccess,
   adminApplicantsListError,
   acceptRejectShortPhysicianRequest,
   acceptRejectShortPhysicianSuccess,
   acceptRejectShortPhysicianError,
   recruiterJobShortlistAction,
   recruiterJobsListFailed,
   recruiterJobsListSuccess,
   recruiterJobsListRequest,
} = recruiterAdminJobSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const recruiterJobCreation = function* (payloadState: PayloadAction<adminJobSagaTypes.IRecruiterJobCreateSagaPayload>) {
   yield put(recruiterJobCreationRequest(payloadState.payload));
   try {
      let apiPath = API.recruiterJobCreation;
      if (payloadState.payload?.id) {
         apiPath = {
            apiPath: `${API.recruiterJobEdit.apiPath}/${payloadState.payload?.id}`,
            action: API.recruiterJobEdit.action,
         };
      }
      const checkUser = false;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...apiPath });
      if (response.status === 200) {
         const resObj = {
            data: null,
            message: response?.data?.message,
         };
         yield put(recruiterJobCreationSuccess(resObj));
      } else {
         yield put(recruiterJobCreationFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
const recruiterJobsList = function* (payloadState: PayloadAction<adminJobSagaTypes.IRecruiterJobsListSagaPayload>) {
   yield put(recruiterJobsListRequest(payloadState.payload));
   try {
      const url = API.recruiterJobsList;
      const checkUser = false;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, params, ...url });
      if (response.status === 200) {
         const responseData = response?.data?.data;
         const resObj = {
            data: responseData?.data,
            message: response?.data?.message,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
         };
         yield put(recruiterJobsListSuccess(resObj));
      } else {
         yield put(recruiterJobsListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const adminJobApplicantsListSaga = function* (payloadState: PayloadAction<adminJobSagaTypes.IAdminJobApplicantsList>) {
   yield put(adminApplicantsListRequest(payloadState.payload));
   try {
      const checkUser = false;
      const params = {
         id: payloadState.payload?.id,
         is_short_list: payloadState.payload?.isShortList,
         page: payloadState.payload?.pageNo,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, params, ...API.adminJobsList });
      if (response.status === 200) {
         const responseData = response.data?.data;
         const resObj = {
            data: responseData,
            message: response.data?.message || messages.success,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
         };
         yield put(adminApplicantsListSuccess(resObj));
      } else {
         yield put(adminApplicantsListError(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const acceptRejectShortPhysicianSaga = function* (
   payloadState: PayloadAction<adminJobSagaTypes.IAcceptRejectShortPhysician>
) {
   yield put(acceptRejectShortPhysicianRequest(payloadState.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const apiPath = API.acceptRejectShortPhysician?.apiPath + payloadState.payload?.applicantId;
      const action = API.acceptRejectShortPhysician?.action;
      const data = {
         type: payloadState.payload?.type,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, peopleMayYouKnow, data, ...{ apiPath, action } });
      if (response.status === 200) {
         const resObj = {
            type: payloadState.payload?.type,
            data: response?.data?.data,
            message: response?.data?.message || messages.success,
         };
         yield put(acceptRejectShortPhysicianSuccess(resObj));
         if (payloadState.payload?.type === 'shortlist') {
            const countObj = {
               applicantId: response.data?.data?.id,
               isShortlisted: response.data?.data?.is_shortlisted,
               type: payloadState.payload?.type,
            };
            yield put(recruiterJobShortlistAction(countObj));
         }
      } else {
         const returnError = {
            error: response.data?.error_message || response.data?.error || messages.errorMessage,
            type: payloadState.payload?.type,
         };
         yield put(acceptRejectShortPhysicianError(returnError));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const recruiterJobDetail = function* (payloadGetOne: PayloadAction<adminJobSagaTypes.IRecruiterJobDetailSagaPayload>) {
   yield put(recruiterJobDetailRequest(payloadGetOne.payload));
   try {
      const params = payloadGetOne.payload;
      const checkUser = true;
      const peopleMayYouKnow = true;
      const apiPath = {
         apiPath: `${API.getPhysicianJobOffersListGetOne.apiPath}${params.jobId}`,
         action: API.getPhysicianJobOffersListGetOne.action,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, peopleMayYouKnow, ...apiPath });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
            data: response.data,
         };
         yield put(recruiterJobDetailSuccess(responseData));
      } else {
         yield put(recruiterJobDetailFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   recruiterJobDeleteRequest,
   recruiterJobDeleteSuccess,
   recruiterJobDeleteFailed,
} = recruiterAdminJobSlice.actions;

const recruiterJobDelete = function* (payloadGetOne: PayloadAction<adminJobSagaTypes.IRecruiterJobDeleteSagaPayload>) {
   yield put(recruiterJobDeleteRequest(payloadGetOne.payload));
   try {
      const params = payloadGetOne.payload;
      const checkUser = false;
      const apiPath = {
         apiPath: `${API.recruiterJobDelete.apiPath}${params.jobId}`,
         action: API.recruiterJobDelete.action,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, ...apiPath });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
            data: response.data,
         };
         yield put(recruiterJobDeleteSuccess(responseData));
      } else {
         yield put(recruiterJobDeleteFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeRecruiterJobAdmin = function* () {
   yield takeEvery(RECRUITER_JOB_CREATE_ACTION_SAGA, recruiterJobCreation);
   yield takeEvery(RECRUITER_JOB_DETAIL_SAGA, recruiterJobDetail);
   yield takeEvery(RECRUITER_JOBS_LIST_SAGA, recruiterJobsList);
};

export const takeAdminJobApplicantsListSaga = function* () {
   yield takeEvery(GET_APPLICANTS_LIST, adminJobApplicantsListSaga);
};

export const takeAcceptRejectShortPhysicianSaga = function* () {
   yield takeEvery(ACCEPT_REJECT_SHORT_PHYSICIAN, acceptRejectShortPhysicianSaga);
   yield takeEvery(RECRUITER_JOB_DELETE_SAGA, recruiterJobDelete);
};
