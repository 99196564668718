import { createAction } from '@reduxjs/toolkit';
import { clinicalTrialsPageTypes } from 'types';

export const CLINICAL_TRIALS_LIST = 'CLINICAL_TRIALS_LIST';
export const clinicalTrialsListSagaAction = createAction<clinicalTrialsPageTypes.IClinicalTrialsListParamsAndData>(
   CLINICAL_TRIALS_LIST
);

export const CLINICAL_TRIALS_LIST_COUNT = 'CLINICAL_TRIALS_LIST_COUNT';
export const clinicalTrialsListCountSagaAction = createAction<clinicalTrialsPageTypes.IClinicalTrialsListCountParams>(
   CLINICAL_TRIALS_LIST_COUNT
);

export const CLINICAL_TRIALS_INTERESTED_LIST = 'CLINICAL_TRIALS_INTERESTED_LIST';
export const clinicalTrialsInterestedListSagaAction = createAction<clinicalTrialsPageTypes.IClinicalTrialsInterestedListParams>(
   CLINICAL_TRIALS_INTERESTED_LIST
);

export const CLINICAL_TRIALS_INTEREST = 'CLINICAL_TRIALS_INTEREST';
export const clinicalTrialsInterestSagaAction = createAction<clinicalTrialsPageTypes.IClinicalTrialsInterestParams>(
   CLINICAL_TRIALS_INTEREST
);

export const CLINICAL_TRIALS_STUDY_TYPE = 'CLINICAL_TRIALS_STUDY_TYPE';
export const clinicalTrialsStudyTypeSagaAction = createAction<clinicalTrialsPageTypes.IClinicalTrailsStudyTypeParams>(
   CLINICAL_TRIALS_STUDY_TYPE
);

export const CLINICAL_TRIALS_STUDY_PHASE = 'CLINICAL_TRIALS_STUDY_PHASE';
export const clinicalTrialsStudyPhaseSagaAction = createAction<clinicalTrialsPageTypes.IClinicalTrailsStudyPhaseParams>(
   CLINICAL_TRIALS_STUDY_PHASE
);

export const CLINICAL_TRIALS_STATES = 'CLINICAL_TRIALS_STATES';
export const clinicalTrialsStatesSagaAction = createAction<clinicalTrialsPageTypes.IClinicalTrailsStatesParamsAndPostData>(
   CLINICAL_TRIALS_STATES
);

export const CLINICAL_TRIALS_CANCER_SPECIFIC = 'CLINICAL_TRIALS_CANCER_SPECIFIC';
export const clinicalTrialsCancerSpecificSagaAction = createAction<clinicalTrialsPageTypes.IClinicalTrailsCancerSpecificParamsAndPostData>(
   CLINICAL_TRIALS_CANCER_SPECIFIC
);

export const CLINICAL_TRIALS_LIST_ITEM_GET_ONE = 'CLINICAL_TRIALS_LIST_ITEM_GET_ONE';
export const clinicalTrialsListItemGetOneSagaAction = createAction<clinicalTrialsPageTypes.IClinicalTrialsListItemGetOne>(
   CLINICAL_TRIALS_LIST_ITEM_GET_ONE
);
