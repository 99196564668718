import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { socialHistoryReducerTypes } from 'types';

const initialState: socialHistoryReducerTypes.ISocialHistoriesReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
   errors: [],
};

export const profileSocialHistoriesSlice = createSlice({
   name: 'getSocialHistories',
   initialState: initialState,
   reducers: {
      getSocialHistoriesRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action?.payload?.page === 1 ? [] : state.list,
         };
      },
      getSocialHistoriesSuccess: (
         state,
         action: PayloadAction<socialHistoryReducerTypes.ISocialHistoriesSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            isLoading: false,
         };
      },
      getSocialHistoriesFailed: (
         state,
         action: PayloadAction<socialHistoryReducerTypes.ISocialHistoriesFailedPayload>
      ) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      getSocialHistoriesReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
