import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { modalExperienceReducerTypes, modalExperienceSagaTypes } from 'types';

const initialState: modalExperienceReducerTypes.IModalExperienceReducer = {
   error: '',
   message: '',
   isLoading: false,
};

export const modalUpdateExperienceSlice = createSlice({
   name: 'modalUpdateExperience',
   initialState: initialState,
   reducers: {
      modalUpdateExperienceRequest: (
         state,
         action: PayloadAction<modalExperienceSagaTypes.IModalExperienceSagaPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      modalUpdateExperienceSuccess: (
         state,
         action: PayloadAction<modalExperienceReducerTypes.IModalExperienceSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      modalUpdateExperienceFailed: (
         state,
         action: PayloadAction<modalExperienceReducerTypes.IModalExperienceFailedPayload>
      ) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      modalUpdateExperienceReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
