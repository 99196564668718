import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINICAL_TRIALS_CANCER_SPECIFIC } from 'reduxToolKit/actions';
import { clinicalTrialsCancerSpecificSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   clinicalTrialsCancerSpecificRequest,
   clinicalTrialsCancerSpecificSuccess,
   clinicalTrialsCancerSpecificFailed,
} = clinicalTrialsCancerSpecificSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const clinicalTrialsCancerSpecificSaga = function* (clinicalTrialsCancerSpecificPayload: any) {
   yield put(clinicalTrialsCancerSpecificRequest(clinicalTrialsCancerSpecificPayload.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const params = clinicalTrialsCancerSpecificPayload.payload.params;
      const data = clinicalTrialsCancerSpecificPayload.payload.data;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         params,
         data,
         peopleMayYouKnow,
         ...API.clinicalTrialsCancerSpecific,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const clinicalTrialsCancerSpecificList = {
            list: responseData.list,
            message: response.data?.message,
         };
         yield put(clinicalTrialsCancerSpecificSuccess(clinicalTrialsCancerSpecificList));
      } else {
         yield put(clinicalTrialsCancerSpecificFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeClinicalTrialsCancerSpecificRequest = function* () {
   yield takeEvery(CLINICAL_TRIALS_CANCER_SPECIFIC, clinicalTrialsCancerSpecificSaga);
};
