import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_CLINIC_PHYSICIANS } from 'reduxToolKit/actions';
import { commonSlice, getClinicPhysicianSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getClinicPhysicianRequest,
   getClinicPhysicianSuccess,
   getClinicPhysicianFailed,
} = getClinicPhysicianSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;
const getClinicPhysicianSaga = function* (clinicPhyTypePayload: any) {
   yield put(getClinicPhysicianRequest(clinicPhyTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = clinicPhyTypePayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         goUrl,
         params,
         ...API.getClinicPhysicianPrescription,
      });
      if (response.status === 200) {
         const responseData = response?.data?.data;
         yield put(getClinicPhysicianSuccess(responseData));
      } else {
         yield put(getClinicPhysicianFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takegetClinicPhysician = function* () {
   yield takeEvery(GET_CLINIC_PHYSICIANS, getClinicPhysicianSaga);
};
