import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clinicAdminReducerTypes, clinicAdminSagaTypes, storeTypes } from 'types';

const initialState: clinicAdminReducerTypes.IClinicAdminsReducer = {
   isLoading: false,
   clinicAdminInfo: {
      data: null,
      error: '',
   },
   clinicAdminList: {
      list: [],
      error: '',
   },
};

export const clinicAdminsSlice = createSlice({
   name: 'getClinicAdmins',
   initialState: initialState,
   reducers: {
      clinicAdminsRequest: (state, action: PayloadAction<clinicAdminSagaTypes.IClinicAdminsListRequestSaga>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      clinicAdminsListSuccess: (
         state,
         action: PayloadAction<clinicAdminReducerTypes.IClinicAdminListSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            clinicAdminList: {
               list: payload.list,
            },
            isLoading: false,
         };
      },
      clinicAdminsListFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            clinicAdminList: {
               error: action.payload?.error,
               list: [],
            },
         };
      },
      clinicAdminsInfoRequest: (state, action: PayloadAction<clinicAdminSagaTypes.IClinicAdminsInfoRequestSaga>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      clinicAdminInfoSuccess: (
         state,
         action: PayloadAction<clinicAdminReducerTypes.IClinicAdminInfoSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            clinicAdminInfo: {
               data: payload.data,
               error: '',
            },
            isLoading: false,
         };
      },
      clinicAdminsInfoFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            clinicAdminInfo: {
               error: action.payload?.error,
               data: null,
            },
         };
      },

      clinicAdminsReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
