import React, { useEffect, useState } from 'react';

import { Avatar, Box, Container, Divider, Grid, Paper, Typography } from '@material-ui/core';
import PillAvatar from 'assets/pill-remainder/pillAvatar.svg';
import GreenPillTimer from 'assets/pill-remainder/pillTimer.png';
import RedPillTimer from 'assets/pill-remainder/pillTimerAvoid.png';
import DotLoader from 'component/loader/DotLoader';
import { PillRemainderDrawer } from 'component/pillRemainder/shared/prDrawer/index';
import { PillRescheduleModal } from 'component/pillRemainder/shared/prModal/pillRescheduleModal';
import { PillTakeModal } from 'component/pillRemainder/shared/prModal/PillTakeModal';
import NoData from 'component/widget/NoData';
import QueryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { pillRemainderListSagaAction } from 'reduxToolKit/actions';
import { pillRemainderListSlice } from 'reduxToolKit/reducers';
import { pillRemainderPageTypes, storeTypes } from 'types';
import {
   convertDateToTimezone,
   convertDateToTimezoneDate,
   convertOnly12HrsTime,
   convertOnlyAMPMTime,
   getCurrentDate,
   getLocalTime12Hrs,
   isBeforeSameDate,
} from 'utils/helpers/dateHelpers';

const PillRemainderList = (props: pillRemainderPageTypes.IPillListModelProps) => {
   const dispatch = useDispatch();
   const location = useLocation();
   const [editModal, setEditModal] = useState(false);
   const [editDrawer, setEditDrawer] = useState(false);
   const [enableNotificationDialog, setEnableNotificationDialog] = useState(false);
   const [notificationPillId, setNotificationPillId] = useState<number>(0);
   const [pillItem, setPillItem] = useState<pillRemainderPageTypes.IPillRemainderPillsModifyItem | null>(null);
   const [pillItemHeader, setPillItemHeader] = useState<pillRemainderPageTypes.IPillRemainderPillItemHeader | null>(
      null
   );

   const { pillRemainderListReset } = pillRemainderListSlice.actions;
   const { list: pillRemainderList, isLoading: isLoadingList } = useSelector(
      (state: storeTypes.IStore) => state.pillRemainderList
   );

   const handleReschedule = () => {
      setEditModal(!editModal);
   };
   const handleEditDrawer = () => {
      setEditModal(!editModal);
      setEditDrawer(!editDrawer);
   };
   const handleEditDrawerOnly = () => {
      setEditDrawer(!editDrawer);
   };
   const handleCloseDrawer = () => {
      setEditDrawer(!editDrawer);
   };

   const setPillEdit = (pillItemObj: any) => {
      setPillItem(pillItemObj);
   };

   const setMedicationEdit = (isEdit: boolean) => {};

   const clearMedications = () => {};

   const pillRemainderListDispatch = () => {
      const currentDate = getCurrentDate();
      const convertDateTimezone = convertDateToTimezone(currentDate);
      const convertDateTimezoneDate = convertDateToTimezoneDate(currentDate);
      const pillRemainderRequest = {
         date: convertDateTimezoneDate,
         time: convertDateTimezone,
      };
      dispatch(pillRemainderListSagaAction(pillRemainderRequest));
   };

   const handleNotificationPillRemainder = () => {
      setEnableNotificationDialog(!enableNotificationDialog);
   };

   useEffect(() => {
      const queryString: any = QueryString.parse(location.search);
      const pillRemainderId = queryString.pillRemainderId;
      const pillId = pillRemainderId ? parseInt(pillRemainderId) : 0;
      setNotificationPillId(pillId);
      if (pillId > 0) setEnableNotificationDialog(!enableNotificationDialog);

      return () => {};
   }, []);

   useEffect(() => {
      pillRemainderListDispatch();
      return () => {
         dispatch(pillRemainderListReset());
      };
   }, []);

   return (
      <Box>
         <Container maxWidth={'lg'}>
            <Box p={3}>
               <Box p={3} bgcolor="#FAFAFA">
                  <Grid container direction="column" spacing={2}>
                     {isLoadingList && pillRemainderList?.length === 0 && <DotLoader />}
                     {!isLoadingList && pillRemainderList?.length === 0 && <NoData />}
                     {pillRemainderList && pillRemainderList.length > 0
                        ? pillRemainderList.map(
                             (pillRemainderListItem: pillRemainderPageTypes.IPillRemainderListItem, i: number) => {
                                const amPm = convertOnlyAMPMTime(pillRemainderListItem.pills[0].pills_date_time);
                                const time = convertOnly12HrsTime(pillRemainderListItem.pills[0].pills_date_time);
                                return (
                                   <Grid item key={i}>
                                      <Paper variant="outlined" className="pill-remainder-list">
                                         <Grid container direction="column">
                                            <Grid item>
                                               <Box>
                                                  <Grid container direction="row" spacing={10}>
                                                     <Grid item>
                                                        <Box pl={4}>
                                                           <Typography className="pill-remainder-time">
                                                              {time}{' '}
                                                              <Typography
                                                                 component="span"
                                                                 className="pill-remainder-period"
                                                              >
                                                                 {amPm}
                                                              </Typography>
                                                           </Typography>
                                                        </Box>
                                                     </Grid>
                                                     {/* <Grid item>
                                                        <Box p={1} pt={2}>
                                                           <Typography className="pill-remainder-list-schedule-time">
                                                              Time-{`${i + 1}`}
                                                           </Typography>
                                                        </Box>
                                                     </Grid> */}
                                                  </Grid>
                                                  <Box pt={1}>
                                                     <Divider />
                                                  </Box>
                                               </Box>
                                            </Grid>
                                            <Grid item>
                                               <Box p={2}>
                                                  <Grid container direction="column" spacing={2}>
                                                     {pillRemainderListItem?.pills ? (
                                                        pillRemainderListItem?.pills.map(
                                                           (
                                                              pillRemainderPillsItem: pillRemainderPageTypes.IPillRemainderPillsModifyItem,
                                                              j: number
                                                           ) => {
                                                              return (
                                                                 <Grid
                                                                    item
                                                                    key={j}
                                                                    onClick={() => {
                                                                       if (
                                                                          isBeforeSameDate(
                                                                             props.selectedDate?.dateFormat
                                                                          )
                                                                       ) {
                                                                          handleReschedule();
                                                                          setPillItem(pillRemainderPillsItem);
                                                                          const timeObj = {
                                                                             timeCountText: `Time-${i + 1}`,
                                                                             amPm,
                                                                             time,
                                                                          };
                                                                          setPillItemHeader(timeObj);
                                                                       }
                                                                    }}
                                                                    className={
                                                                       isBeforeSameDate(props.selectedDate?.dateFormat)
                                                                          ? `hand-i`
                                                                          : ''
                                                                    }
                                                                 >
                                                                    <Box pl={2}>
                                                                       <Grid container direction="row" spacing={2}>
                                                                          <Grid item>
                                                                             <Avatar src={PillAvatar} />
                                                                          </Grid>
                                                                          <Grid item>
                                                                             <Typography
                                                                                className={
                                                                                   pillRemainderPillsItem?.status ===
                                                                                      'in_progress' ||
                                                                                   pillRemainderPillsItem?.status ===
                                                                                      null
                                                                                      ? 'pill-name mt-2 pt-1'
                                                                                      : 'pill-name'
                                                                                }
                                                                             >
                                                                                {pillRemainderPillsItem.name || ''}
                                                                             </Typography>
                                                                             {pillRemainderPillsItem?.status ===
                                                                             'taken' ? (
                                                                                <Typography className="pill-taken-green">
                                                                                   <img src={GreenPillTimer} alt="" />
                                                                                   &nbsp;{' '}
                                                                                   {`Taken at ${getLocalTime12Hrs(
                                                                                      pillRemainderPillsItem?.pills_action_time ||
                                                                                         ''
                                                                                   )}`}
                                                                                </Typography>
                                                                             ) : pillRemainderPillsItem?.status ===
                                                                               'skipped' ? (
                                                                                <Typography className="pill-taken-red">
                                                                                   <img src={RedPillTimer} alt="" />
                                                                                   &nbsp;{' '}
                                                                                   {`Skipped at ${getLocalTime12Hrs(
                                                                                      pillRemainderPillsItem?.pills_action_time ||
                                                                                         ''
                                                                                   )}`}
                                                                                </Typography>
                                                                             ) : null}
                                                                          </Grid>
                                                                       </Grid>
                                                                       {j + 1 ===
                                                                       pillRemainderListItem?.pills?.length ? null : (
                                                                          <Box pt={1.5}>
                                                                             <Divider />
                                                                          </Box>
                                                                       )}
                                                                    </Box>
                                                                 </Grid>
                                                              );
                                                           }
                                                        )
                                                     ) : (
                                                        <NoData />
                                                     )}
                                                  </Grid>
                                               </Box>
                                            </Grid>
                                         </Grid>
                                      </Paper>
                                   </Grid>
                                );
                             }
                          )
                        : isLoadingList
                        ? null
                        : null}
                  </Grid>

                  <Paper variant="outlined" className="pill-remainder-list hide">
                     <Grid container direction="column">
                        <Grid item>
                           <Box>
                              <Grid container direction="row" spacing={10}>
                                 <Grid item>
                                    <Box pl={4}>
                                       <Typography className="pill-remainder-time">
                                          8:30{' '}
                                          <Typography component="span" className="pill-remainder-period">
                                             PM
                                          </Typography>
                                       </Typography>
                                    </Box>
                                 </Grid>
                                 <Grid item>
                                    <Box p={1} pt={2}>
                                       <Typography className="pill-remainder-list-schedule-time">Time-1</Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                              <Box pt={1}>
                                 <Divider />
                              </Box>
                           </Box>
                        </Grid>
                        <Grid item>
                           <Box p={2}>
                              {[1].map((_, i: number) => {
                                 return (
                                    <Box pl={2} key={i}>
                                       <Grid container direction="row" spacing={2}>
                                          <Grid item>
                                             <Avatar src={PillAvatar} />
                                          </Grid>
                                          <Grid item>
                                             <Typography className="pill-name">Abacavir</Typography>
                                             <Typography className="pill-taken-red">
                                                <img src={RedPillTimer} alt="" />
                                                &nbsp; Skipped at 8:32 PM
                                             </Typography>
                                          </Grid>
                                       </Grid>
                                       <Box pt={1.5}>
                                          <Divider />
                                       </Box>
                                    </Box>
                                 );
                              })}
                           </Box>
                        </Grid>
                     </Grid>
                  </Paper>
               </Box>
            </Box>
         </Container>
         {editModal && (
            <PillRescheduleModal
               handleClose={handleReschedule}
               handleEdit={handleEditDrawer}
               handleSubmit={handleReschedule}
               open={editModal}
               pillItem={pillItem}
               pillItemHeader={pillItemHeader}
               selectedDate={props.selectedDate}
            />
         )}
         {editDrawer && (
            <PillRemainderDrawer
               handleClose={handleCloseDrawer}
               pillItem={pillItem}
               pillItemMedication={null}
               type={''}
               isView={false}
               setMedicationEdit={setMedicationEdit}
               selectedDate={props.selectedDate}
               clearMedications={clearMedications}
               isFromHome={true}
            />
         )}
         {enableNotificationDialog && (
            <PillTakeModal
               handleClose={handleNotificationPillRemainder}
               handleSubmit={handleNotificationPillRemainder}
               open={enableNotificationDialog}
               notificationPillId={notificationPillId}
               selectedDate={props.selectedDate}
               handleEdit={handleEditDrawerOnly}
               setPillEdit={setPillEdit}
            />
         )}
      </Box>
   );
};

export { PillRemainderList };
