import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clinicalNotesReducerTypes, clinicalNotesSagaTypes } from 'types';

const initialState: clinicalNotesReducerTypes.IClinicNotes = {
   isLoading: false,
   progressLoadMore: false,
   error: '',
   message: '',
   list: [],
   current_page: 1,
   limit: 0,
   total_records: 0,
   page: 1,
};

export const clinicalNotesListSlice = createSlice({
   name: 'clinicalNotesList',
   initialState: initialState,
   reducers: {
      clinicalNotesListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
            progressLoadMore: true,
         };
      },
      clinicalNotesListViewRequest: (state, action: PayloadAction<clinicalNotesSagaTypes.IClinicalNotesListForm>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            progressLoadMore: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      clinicalNotesListViewSuccess: (state, action: PayloadAction<clinicalNotesReducerTypes.IClinicNotesResponse>) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.page === 1 ? payload.list : [...state.list, ...payload.list],
            total_records: payload.total_records,
            isLoading: false,
            progressLoadMore: false,
         };
      },
      clinicalNotesListFailed: (state, action: PayloadAction<clinicalNotesReducerTypes.IClinicNotesFailed>) => {
         return {
            ...state,
            isLoading: false,
            progressLoadMore: false,
            message: '',
            error: action.payload.error,
         };
      },

      clinicalNotesListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
