import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_ORG_IMAGE, ADD_ORG_NAME } from 'reduxToolKit/actions';
import { commonSlice, orgNameSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   addOrgNameRequest,
   addOrgNameSuccess,
   addOrgNameFailed,
   addOrgImageRequest,
   addOrgImageSuccess,
   addOrgImageFailed,
} = orgNameSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const orgNameAddSaga = function* (orgNameAddPayload: any) {
   yield put(addOrgNameRequest(orgNameAddPayload.payload));
   try {
      const data = orgNameAddPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: false,
         data,
         ...API.orgNameAdd,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const orgNameAddData = {
            message: responseData?.message || '',
         };
         yield put(addOrgNameSuccess(orgNameAddData));
      } else {
         yield put(addOrgNameFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const orgImageAddSaga = function* (orgImageAddPayload: any) {
   yield put(addOrgImageRequest(orgImageAddPayload.payload));
   try {
      const data = orgImageAddPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: false,
         data,
         ...API.orgNameAdd,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const orgNameAddData = {
            message: responseData?.message || '',
         };
         yield put(addOrgImageSuccess(orgNameAddData));
      } else {
         yield put(addOrgImageFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeOrgName = function* () {
   yield takeEvery(ADD_ORG_NAME, orgNameAddSaga);
   yield takeEvery(ADD_ORG_IMAGE, orgImageAddSaga);
};
