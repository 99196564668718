import { createAction } from '@reduxjs/toolkit';
import { physicianJobsReducerTypes, physicianJobsSagaTypes } from 'types';

export const PHYSICIAN_JOBS_LIST = 'PHYSICIAN_JOBS_LIST';
export const getPhysicianJobsList = createAction<physicianJobsSagaTypes.IPhysicianJobsListTypes>(PHYSICIAN_JOBS_LIST);

export const PHYSICIAN_JOBS_LIST_GET_ONE = 'PHYSICIAN_JOBS_LIST_GET_ONE';
export const getPhysicianJobsListGetOneSagaAction = createAction<physicianJobsSagaTypes.IPhysicianJobsListGetOneTypes>(
   PHYSICIAN_JOBS_LIST_GET_ONE
);

export const PHYSICIAN_APPLIED_JOB_OFFER = 'PHYSICIAN_APPLIED_JOB_OFFER';
export const physicianAppliedJobOfferSagaAction = createAction<physicianJobsReducerTypes.IPhysicianAppliedJobOfferTypes>(
   PHYSICIAN_APPLIED_JOB_OFFER
);

export const PHYSICIAN_JOB_ADD_RM_FAVOURITE = 'PHYSICIAN_JOB_ADD_RM_FAVOURITE';
export const physicianJobAdRmFavourite = createAction<physicianJobsSagaTypes.IPhysicianJobsAddRmFavourite>(
   PHYSICIAN_JOB_ADD_RM_FAVOURITE
);
