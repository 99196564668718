import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { conversationsReducersTypes, conversationsSagatypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: conversationsReducersTypes.IConversationAddGetMessageReducerTypes = {
   addMessage: {
      isLoading: false,
      responseMessage: '',
      error: '',
      data: null,
   },
   getMessages: {
      isLoading: false,
      message: '',
      error: '',
      data: null,
   },
};

export const conversationMessageAddGetSlice = createSlice({
   name: 'conversationMessageAddGet',
   initialState: initialState,
   reducers: {
      conversationAddMesssageRequest: (
         state,
         action: PayloadAction<conversationsSagatypes.IAskADocAddConversationMessage>
      ) => {
         return {
            ...state,
            addMessage: {
               ...state.addMessage,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      conversationAddMesssageSuccess: (
         state,
         action: PayloadAction<conversationsReducersTypes.IConversationsAddMessageSuccessPayload>
      ) => {
         return {
            ...state,
            addMessage: {
               ...state.addMessage,
               isLoading: false,
               responseMessage: action.payload.responseMessage || messages.success,
               data: action.payload.data,
               error: '',
            },
         };
      },
      conversationAddMesssageFailed: (
         state,
         action: PayloadAction<conversationsReducersTypes.IConversationsUnreadCountGetFailed>
      ) => {
         return {
            ...state,
            addMessage: {
               ...state.addMessage,
               isLoading: false,
               error: action.payload.error,
               message: '',
               data: null,
            },
         };
      },
      conversationAddMesssageReset: (state) => {
         return {
            ...state,
            addMessage: {
               ...state.addMessage,
               ...initialState.addMessage,
            },
         };
      },

      conversationGetMesssageRequest: (
         state,
         action: PayloadAction<conversationsSagatypes.IaskADocGetConversationMessage>
      ) => {
         return {
            ...state,
            getMessages: {
               ...state.getMessages,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      conversationGetMesssageSuccess: (
         state,
         action: PayloadAction<conversationsReducersTypes.IConversationsUnreadCountGetSuccess>
      ) => {
         return {
            ...state,
            getMessages: {
               ...state.getMessages,
               isLoading: false,
               message: action.payload.message || messages.success,
               data: action.payload.data,
               error: '',
            },
         };
      },
      conversationGetMesssageFailed: (
         state,
         action: PayloadAction<conversationsReducersTypes.IConversationsUnreadCountGetFailed>
      ) => {
         return {
            ...state,
            getMessages: {
               ...state.getMessages,
               isLoading: false,
               error: action.payload.error,
               data: null,
            },
         };
      },

      conversationGetMesssageReset: (state) => {
         return {
            ...state,
            getMessages: initialState.getMessages,
         };
      },
   },
});
