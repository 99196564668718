import { createSlice } from '@reduxjs/toolkit';
import { IAddDailyCheckLog } from 'types/dailyCheckLog/checkLogReducerTypes';

const initialState: IAddDailyCheckLog = {
   isLoading: false,
   error: '',
   message: '',
   addDailyCheckLog: null,
};
const addDailyCheckLogSlice = createSlice({
   name: 'addDailyCheckLog',
   initialState: initialState,
   reducers: {
      addDailyCheckLog: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addDailyCheckLogSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            addDailyCheckLog: payload?.data,
            message: payload.message,
            isLoading: false,
         };
      },
      addDailyCheckLogFailed: (state, action) => {
         return {
            ...state,
            addDailyCheckLog: null,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      addDailyCheckLogReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
export { addDailyCheckLogSlice };
