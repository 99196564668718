import { createSlice } from '@reduxjs/toolkit';
import { appointmentScheduleReducerTypes, appointmentScheduleSagaTypes } from 'types';

const initialState: appointmentScheduleReducerTypes.IAppointmentReducer = {
   physicianSchedule: null,
   isLoading: false,
   error: '',
};
const physicianScheduleSlice = createSlice({
   name: 'physicianAppointmentSchedule',
   initialState: initialState,
   reducers: {
      getPhysicianSchedule: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getPhysicianScheduleSuccess: (state, action: appointmentScheduleSagaTypes.IPhysicianScheduleSuccess) => {
         return {
            ...state,
            physicianSchedule: action.payload,
            isLoading: false,
         };
      },
      getPhysicianScheduleFailed: (state, action) => {
         return {
            ...state,
            physicianSchedule: null,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      getPhysicianScheduleReset: (state, action) => {
         return {
            ...state,
            physicianSchedule: null,
            isLoading: false,
            message: '',
            error: '',
         };
      },
   },
});
export { physicianScheduleSlice };
