import { createAction } from '@reduxjs/toolkit';
import { secondOpinionSagaTypes } from 'types';

export const SECOND_OPINION_PHYSICIAN_LIST = 'SECOND_OPINION_PHYSICIAN_LIST';
export const secondOpinionPhysicianList = createAction<secondOpinionSagaTypes.ISecOpinionPhyListSagaPayload>(
   SECOND_OPINION_PHYSICIAN_LIST
);
export const SECOND_OPINION_SHORT_LIST_PHYSICIAN = 'SECOND_OPINION_SHORT_LIST_PHYSICIAN';
export const secondOpinionShortList = createAction<secondOpinionSagaTypes.ISecOpinionShortListPhySagaPayload>(
   SECOND_OPINION_SHORT_LIST_PHYSICIAN
);
export const SEC_OPINION_ADD_N_DELETE_SHORT_LIST = 'SEC_OPINION_ADD_N_DELETE_SHORT_LIST';
export const secOpinionAddNDeleteShortList = createAction<secondOpinionSagaTypes.ISecOpinionAddNDeleteShortListSagaPayload>(
   SEC_OPINION_ADD_N_DELETE_SHORT_LIST
);

export const SEC_OPINION_NEARBY_PHYSICIAN = 'SEC_OPINION_NEARBY_PHYSICIAN';
export const secOpinionNearbyPhysicianList = createAction<secondOpinionSagaTypes.ISecOpinionNearbyPhysicianListSagaPayload>(
   SEC_OPINION_NEARBY_PHYSICIAN
);
