import { createSlice } from '@reduxjs/toolkit';
import { fillProfileReducerTypes, peopleNearMeReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: peopleNearMeReducerTypes.ICancerTypeListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
};

const initialStateGetWalletBalance: fillProfileReducerTypes.IPhoneSectionReducerType = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};

export const walletSlice = createSlice({
   name: 'walletList',
   initialState: initialState,
   reducers: {
      walletListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      walletListViewRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      walletListViewSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            isLoading: false,
            message: payload.message || messages.success,
         };
      },
      walletListFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      walletListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

export const walletBalanceGetSlice = createSlice({
   name: 'walletBalanceGet',
   initialState: initialStateGetWalletBalance,
   reducers: {
      walletBalanceGetRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      walletBalanceGetSuccess: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            ...action.payload,
         };
      },
      walletBalanceGetFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      walletBalanceGetReset: () => {
         return {
            ...initialStateGetWalletBalance,
         };
      },
   },
});
