import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { ASK_A_DOC_PHY_PANEL_REQUESTS_LIST } from 'reduxToolKit/actions';
import { commonSlice, askADocPhyPanelRequestSlice } from 'reduxToolKit/reducers';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   askADocPhyPanelReqListRequest,
   askADocPhyPanelReqListSuccess,
   askADocPhyPanelReqListFailed,
} = askADocPhyPanelRequestSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const askADocPhyPanelReqList = function* (payloadState: any) {
   yield put(askADocPhyPanelReqListRequest(payloadState.payload));
   try {
      const params = payloadState.payload;
      const url = API.askADocPhyPanelRequestList;
      const response: AxiosResponse = yield apiCall({ params, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const panelReqList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response?.data?.message || messages.success,
         };
         yield put(askADocPhyPanelReqListSuccess(panelReqList));
      } else {
         yield put(askADocPhyPanelReqListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAskDocPanelPhyPanelRequest = function* () {
   yield takeLatest(ASK_A_DOC_PHY_PANEL_REQUESTS_LIST, askADocPhyPanelReqList);
};
