import React, { Component, lazy, Suspense } from 'react';

import ClinicalTrials from 'component/clinicalTrials';
import ClinicalTrialsDetail from 'component/clinicalTrials/ClinicalTrialsDetail';
import DotLoader from 'component/loader/DotLoader';
// TODO: PillRemainder files imported directly because lazy load issue.
import PillRemainder from 'component/pillRemainder/prList/index';
import PillRemainderMedications from 'component/pillRemainder/prMedications/index';
import Constant from 'config/Constant';
import IdleTimer from 'react-idle-timer';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { notificationDeleteDeviceToken } from 'reduxToolKit/actions';
import {
   commonSlice,
   conversationSlice,
   notificationSettingsListSlice,
   searchSlice,
   userDetailsSlice,
} from 'reduxToolKit/reducers';
import { commonApiTypes, notificationSagaTypes, storeTypes } from 'types';
import { SET_TIME_OUT } from 'utils/constants/common';
import { ROUTE_LOGIN } from 'utils/constants/routerConstants';
import { SeoTitle } from 'utils/constants/seoTitle';
import { isValidUser, setIdleTimeOut, getDeviceNotificationToken, logout, getUser } from 'utils/helpers/authHeader';
import { getCurrentDate } from 'utils/helpers/dateHelpers';
import { retryLoading } from 'utils/helpers/lazyLoadRetry';
// TODO: Validation control code hided temporarily.
import { validateRouter } from 'utils/helpers/routerHelpers';

const PublicRoute = lazy(() => import('layouts/PublicLayout'));
const PrivateRoute = lazy(() => import('layouts/PrivateLayout'));

// Login
const Login = lazy(() => import('component/pages/Login'));
const Register = lazy(() => import('component/pages/Register'));
const EmailVerify = lazy(() => import('component/pages/EmailVerify'));
const Home = lazy(() => import('component/pages/Home'));

// Other Login Forms
const DoximityLogin = lazy(() => import('component/pages/DoximityLogin'));
const AppleLogin = lazy(() => import('component/pages/AppleLogin'));

// Password Setup
const ForgotPassword = lazy(() => import('component/pages/ForgotPassword'));
const ForgotPasswordOtp = lazy(() => import('component/pages/ForgotPasswordOtp'));
const SetPassword = lazy(() => import('component/pages/SetPassword'));

// Admin component
const Admin = lazy(() => retryLoading(() => import('component/pages/admin')));

// Locum Service
/* const LocumPracticeAdminPage = lazy(() =>
   retryLoading(() => import('component/pages/locumServices/LocumPracticeAdminPage'))
);
const BookServicePage = lazy(() => retryLoading(() => import('component/pages/locumServices/BookServicePage'))); */
/* const LocumPhysicianPage = lazy(() =>
   retryLoading(() => import('component/pages/locumServices/LocumServicePhysicianPage'))
); */

// Ask-a-Doc
const CaseListsPage = lazy(() => retryLoading(() => import('component/pages/askADoc/CaseListsPage')));
const CaseListsProPage = lazy(() => retryLoading(() => import('component/pages/askADoc/CaseListsProPage')));
const CaseListsDetailsPage = lazy(() => retryLoading(() => import('component/pages/askADoc/CaseListsDetailsPage')));
const AskADocChatPage = lazy(() => retryLoading(() => import('component/pages/askADoc/physician/AskADocChatPage')));

// Ask-a-Doc Physician
const AskADocPhysicianCaseListPage = lazy(() =>
   retryLoading(() => import('component/pages/askADoc/physician/AskADocPhysicianCaseListPage'))
);
const AskADocPhysicianCaseListProPage = lazy(() =>
   retryLoading(() => import('component/pages/askADoc/physician/AskADocPhysicianCaseListProPage'))
);
const ConversationsPage = lazy(() => retryLoading(() => import('component/pages/conversations/ConvesationsPage')));
const SelectPhysicianFreePage = lazy(() =>
   retryLoading(() => import('component/pages/askADoc/patient/SelectPhysicianFreePage'))
);
const CreateAskADocPage = lazy(() => retryLoading(() => import('component/pages/askADoc/patient/CreateAskADocPage')));

//subscription plan
const AskADocSubscriptionPlanPage = lazy(() =>
   retryLoading(() => import('component/pages/askADoc/patient/AskADocSubscriptionPage'))
);

// Drug Lookup
const DrugDetailViewPage = lazy(() => retryLoading(() => import('component/pages/drug/DrugDetailViewPage')));
const DrugFullDetailsPage = lazy(() => retryLoading(() => import('component/pages/drug/DrugFullDetailsPage')));
const DrugSeeAllReviewsPage = lazy(() => retryLoading(() => import('component/pages/drug/DrugSeeAllReviewsPage')));
const DrugLookupPhysicianViewPage = lazy(() =>
   retryLoading(() => import('component/pages/drug/DrugLookupPhysicianViewPage'))
);

// Medication Savings
const MedicationSavingsHomePage = lazy(() =>
   retryLoading(() => import('component/pages/medicationSavings/MedicationSavingsHomePage'))
);
const MedicationSavingsDetailPage = lazy(() =>
   retryLoading(() => import('component/pages/medicationSavings/MedicationSavingsDetailPage'))
);

// Profile
const ProfileViewPage = lazy(() => retryLoading(() => import('component/pages/profile/ProfileViewPage')));
const EditProfilePage = lazy(() => retryLoading(() => import('component/pages/profile/EditProfilePage')));
const MyProfilePage = lazy(() => retryLoading(() => import('component/pages/profile/MyProfilePage')));

// Notification
const Notification = lazy(() => import('component/notification/Notification'));
const NotificationSettings = lazy(() => import('component/notification/NotificationSettings'));

// My Networks
const MyNetworkPage = lazy(() => retryLoading(() => import('component/pages/MyNetworkPage')));

// My Services
const MyServicesPage = lazy(() => retryLoading(() => import('component/pages/MyServicesPage')));

// Support Groups
const SupportGroupPage = lazy(() => retryLoading(() => import('component/pages/supportGroups/SupportGroupPage')));
const SupportGroupDetailPage = lazy(() =>
   retryLoading(() => import('component/pages/supportGroups/SupportGroupDetailPage'))
);

// Feed
const NotificationFeedPage = lazy(() => retryLoading(() => import('component/pages/feed/NotificationFeedPage')));
const AskADocOneFeedPage = lazy(() => retryLoading(() => import('component/pages/feed/AskADocOneFeedPage')));
const FeedPage = lazy(() => retryLoading(() => import('component/pages/feed/FeedPage')));
const MyFeedPage = lazy(() => retryLoading(() => import('component/pages/feed/MyFeedPage')));
const HashtagFeedPage = lazy(() => retryLoading(() => import('component/pages/feed/HashtagFeedPage')));
const MySavedFeedPostPage = lazy(() => retryLoading(() => import('component/pages/feed/MySavedFeedPostPage')));

const CheckoutFormElem = lazy(() => import('component/stripe/CheckoutFormElem'));

const Subscription = lazy(() => import('component/subscription/Subscription'));
const MyWallet = lazy(() => import('component/wallet/MyWallet'));
const Time = lazy(() => import('component/widget/Time'));
const HippaContent = lazy(() => import('HippaContent'));

// Onc Drive
const oncDrivePage = lazy(() => retryLoading(() => import('component/pages/oncDrive/oncDrivePage')));
const OncDriveFilesPage = lazy(() => retryLoading(() => import('component/pages/oncDrive/OncDriveFilesPage')));

// Mobile View
const MobileResponsiveView = lazy(() => import('component/shared/MobileResponsiveView'));

// Video Library
const VideoLibrary = lazy(() => retryLoading(() => import('component/videoLibrary/list')));
const VideoLibraryDetail = lazy(() => retryLoading(() => import('component/videoLibrary/detail')));
const VideoLibraryOrgDetail = lazy(() => retryLoading(() => import('component/videoLibrary/detailOrg')));
const VideoOrgMyLibraryList = lazy(() => retryLoading(() => import('component/videoLibrary/myLibrary/list')));
const VideoOrgMyLibraryUpload = lazy(() => retryLoading(() => import('component/videoLibrary/myLibrary/upload')));

// Meditation
const OncoMeditation = lazy(() => retryLoading(() => import('component/oncoMeditation/list')));

// ONCKLINIC SECTION
const OncKlinicPage = lazy(() => retryLoading(() => import('component/pages/oncKlinic/OncKlinicPage')));
const RPMDashboardPage = lazy(() => retryLoading(() => import('component/pages/oncKlinic/physician/RPMDashboardPage')));
const NotificationPage = lazy(() => retryLoading(() => import('component/oncoKlinic/Notification')));

//Health Records
const HealthReportsPage = lazy(() => retryLoading(() => import('component/pages/oncKlinic/patient/HealthReportsPage')));
const PrescriptionPage = lazy(() => retryLoading(() => import('component/pages/oncKlinic/patient/PrescriptionPage')));

// My Treatig Physician
const MyTreatingPhysiciansPage = lazy(() =>
   retryLoading(() => import('component/pages/oncKlinic/patient/MyTreatingPhysiciansPage'))
);
const AddPhysicianPage = lazy(() => retryLoading(() => import('component/pages/oncKlinic/patient/AddPhysicianPage')));

// Consent
const DeviceConsent = lazy(() => retryLoading(() => import('component/pages/oncKlinic/patient/DeviceConsentPage')));

// My Patients
const MyPatientsPage = lazy(() => retryLoading(() => import('component/pages/oncKlinic/physician/MyPatientsPage')));
const AddPatientsPage = lazy(() => retryLoading(() => import('component/pages/oncKlinic/physician/AddPatientsPage')));
const ClinicalHistoryPage = lazy(() =>
   retryLoading(() => import('component/pages/oncKlinic/physician/ClinicalHistoryPage'))
);

// Medical Enquiry
const MedicalEnquiryPage = lazy(() => retryLoading(() => import('component/pages/oncKlinic/MedicalEnquiryPage')));
const MedicalEnquiryDocumentsPage = lazy(() =>
   retryLoading(() => import('component/pages/oncKlinic/physician/MedicalEnquiryDocumentsPage'))
);

// oncoKlinic Tele Medicine
const TeleMedicinePage = lazy(() => retryLoading(() => import('component/pages/oncKlinic/physician/TeleMedicinePage')));
const Meeting = lazy(() => retryLoading(() => import('component/oncoKlinic/Meeting')));
const VideoCallPage = lazy(() => retryLoading(() => import('component/pages/oncKlinic/VideoCallPage')));
const TeleMedicineViewPage = lazy(() => retryLoading(() => import('component/pages/oncKlinic/TeleMedicineViewPage')));
const TeleMedicineDocumentsPage = lazy(() =>
   retryLoading(() => import('component/pages/oncKlinic/physician/TeleMedicineDocumentsPage'))
);
const VideoAssignmentPage = lazy(() =>
   retryLoading(() => import('component/pages/oncKlinic/patient/VideoAssignmentPage'))
);
const MyApponitments = lazy(() => retryLoading(() => import('component/apponitments/MyApponitments')));

// Schedule
const SchedulePage = lazy(() => retryLoading(() => import('component/pages/oncKlinic/physician/SchedulePage')));

// OncoKlinic Symptom
const ReportSymptomPage = lazy(() => retryLoading(() => import('component/pages/oncKlinic/patient/ReportSymptomPage')));
const SymptomPage = lazy(() => retryLoading(() => import('component/pages/oncKlinic/patient/SymptomPage')));

// OncoKlinic Devices
const BloodPressurePage = lazy(() =>
   retryLoading(() => import('component/pages/oncKlinic/patient/devices/BloodPressurePage'))
);
const SmartWeightPage = lazy(() => retryLoading(() => import('component/oncoKlinic/smartWeight/SmartWeight')));
const ThermometerPage = lazy(() =>
   retryLoading(() => import('component/pages/oncKlinic/patient/devices/ThermometerPage'))
);
const OxyMeterPage = lazy(() => retryLoading(() => import('component/pages/oncKlinic/patient/devices/OxyMeterPage')));

const DailyCheckLogs = lazy(() => retryLoading(() => import('component/dailyCheckLogs/index')));
const DailyCheckLogEntry = lazy(() => retryLoading(() => import('component/dailyCheckIns/logEntry')));

// oncKlinic Billing Document
const BillingInfo99453Page = lazy(() =>
   retryLoading(() => import('component/pages/oncKlinic/physician/documents/BillingInfo99453Page'))
);
const BillingInfo99454Page = lazy(() =>
   retryLoading(() => import('component/pages/oncKlinic/physician/documents/BillingInfo99454Page'))
);
const BillingInfo99457 = lazy(() =>
   retryLoading(() => import('component/pages/oncKlinic/physician/documents/BillingInfo99457Page'))
);
const BillingInfo99458 = lazy(() =>
   retryLoading(() => import('component/pages/oncKlinic/physician/documents/BillingInfo99458Page'))
);
const BillingInfo99091 = lazy(() =>
   retryLoading(() => import('component/pages/oncKlinic/physician/documents/BillingInfo99091Page'))
);
const BillingInfo99421 = lazy(() =>
   retryLoading(() => import('component/pages/oncKlinic/physician/documents/BillingInfo99421Page'))
);

// Recruiter
/* const PhysicianJobsListPage = lazy(() =>
   retryLoading(() => import('component/pages/recruiter/physician/PhysicianJobsListPage'))
);
const PhysicianJobDetailPage = lazy(() =>
   retryLoading(() => import('component/pages/recruiter/physician/PhysicianJobDetailPage'))
); */
const PAJobsListPage = lazy(() => retryLoading(() => import('component/pages/recruiter/practiseAdmin/PAJobsListPage')));
const JobRecruiterDetailPage = lazy(() =>
   retryLoading(() => import('component/pages/recruiter/practiseAdmin/PAJobDetailsPage'))
);

//global search
const GlobalSearch = lazy(() => retryLoading(() => import('component/pages/globalSearch/GlobalSearchPage')));

// Page Not Found
const PageNotFound = lazy(() => import('component/shared/PageNotFound'));

// Internet Connection Check
const InternetConnectionCheck = lazy(() => import('component/shared/InternetConnectionCheck'));

const PhyAccessDenied = lazy(() => import('component/shared/PhyAccessDenied'));

const timeOut = Constant.TIME_OUT_MIN;

class Routes extends Component<any, any> {
   idleTimer: IdleTimer | null;
   timeout: number;
   constructor(props: any) {
      super(props);
      this.timeout = 1000 * 60 * timeOut;
      this.idleTimer = null;
      this.state = {
         remaining: this.timeout,
         isIdle: false,
         isLogOut: false,
         lastActive: getCurrentDate(),
      };
   }
   handleOnActive = () => {
      this.setState({ isIdle: false });
   };

   handleOnAction = () => {
      this.setState({ isIdle: false });
   };

   handleOnIdle = () => {
      this.setState(
         {
            isIdle: true,
            isLogOut: true,
         },
         () => {
            if (this.props.isApiLoading === false) {
               if (getUser()?.is_associated_clinic) {
                  this.logout();
               }
            } else if (this.props.isApiLoading === true) {
               this.idleTimer?.reset();
            }
         }
      );
   };

   timeOutHide = () => {
      this.setState((state: any) => {
         return {
            isLogOut: !state.isLogOut,
         };
      });
   };

   logout = () => {
      this.props.resetAllSearchDispatch();
      this.setState({
         isLoading: true,
      });
      const fcmToken = getDeviceNotificationToken();
      setTimeout(() => {
         this.props.unreadConversationsCountReset();
         this.props.conversationCount(0);
      }, SET_TIME_OUT.MIN);
      if (fcmToken && fcmToken !== '') {
         const data: notificationSagaTypes.INotificationDeleteDeviceTokenSaga = {
            device_token: String(fcmToken),
         };
         this.props.notificationDeleteDeviceTokenDispatch(data);
      } else {
         this.setState(
            {
               isLoading: false,
            },
            () => {
               logout();
               setIdleTimeOut(1);
               window.location.reload();
            }
         );
      }
   };
   componentDidUpdate(nextProps: commonApiTypes.ICommon) {
      const { isApiLoading } = nextProps;
      if (isApiLoading) {
         this.idleTimer?.reset();
      }
   }
   render():
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | string
      | number
      | React.ReactNodeArray
      | React.ReactPortal
      | boolean
      | null
      | undefined {
      const oncklinic = Constant.OncoKlinic;
      const urlSecondOpinion = Constant.urlSecondOpinion;
      return (
         <>
            {isValidUser() &&
            window.location.origin !== 'http://localhost:3000' &&
            window.location.origin !== 'https://dev.oncopower.org' ? (
               <IdleTimer
                  ref={(ref) => {
                     this.idleTimer = ref;
                  }}
                  onActive={this.handleOnActive}
                  onIdle={this.handleOnIdle}
                  onAction={this.handleOnAction}
                  timeout={this.timeout}
                  passive={true}
                  capture={true}
               />
            ) : null}
            <Router>
               <Suspense
                  fallback={
                     <div className="feed-spinner-lazy-load">
                        <DotLoader />
                     </div>
                  }
               >
                  <Switch>
                     {/* Login */}
                     <PublicRoute exact path={'/login'} component={Login} seo={SeoTitle.LOGIN} />
                     <PublicRoute exact path={'/'} component={Login} seo={SeoTitle.LOGIN} />
                     <PublicRoute exact path={'/register/:id?'} component={Register} seo={SeoTitle.SIGNUP} />
                     <PublicRoute
                        exact
                        path={'/email-verification'}
                        component={EmailVerify}
                        seo={SeoTitle.EMAIL_VERIFICATION}
                     />
                     <PublicRoute exact path={'/confirm-join'} component={SetPassword} seo={SeoTitle.CONFIRM_JOIN} />
                     <PublicRoute exact path={'/home'} component={Home} seo={SeoTitle.SIGNUP} />
                     <PublicRoute
                        exact
                        path={'/mobile-view'}
                        seo={SeoTitle.MOBILE_VIEW}
                        component={MobileResponsiveView}
                     />
                     <PublicRoute exact path={'/hippa-content'} component={HippaContent} seo={SeoTitle.HIPPA_CONTENT} />
                     {/* Password Setup */}
                     <PublicRoute
                        exact
                        path={'/forgot-password'}
                        component={ForgotPassword}
                        seo={SeoTitle.FORGOT_PASSWORD}
                     />
                     <PublicRoute
                        exact
                        path={'/password-verify-otp/:phone'}
                        component={ForgotPasswordOtp}
                        seo={SeoTitle.FORGOT_PASSWORD}
                     />
                     <PublicRoute exact path={'/set-password'} component={SetPassword} seo={SeoTitle.FORGOT_PASSWORD} />
                     {/* Login user details */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/connections'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.PHYSICIAN,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        component={MyNetworkPage}
                        seo={SeoTitle.NETWORK}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/my-profile'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.PHYSICIAN,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        component={MyProfilePage}
                        seo={SeoTitle.PROFILE}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/edit-profile'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.PHYSICIAN,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        component={EditProfilePage}
                        seo={SeoTitle.EDIT_PROFILE}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/connections/profile-view/:type/:id/:isFrom?'}
                        /* TODO: This URL has used to all user. So, it is granted without url restriction
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.PHYSICIAN,
                           Constant.NURSE,
                           Constant.PRACTISE_ADMIN,
                           Constant.AUXILIARY_STAFF, Constant.CAREGIVER, Constant.PATIENT_ADVOCATE
                        ])} */
                        isURLEncrypted={true}
                        component={ProfileViewPage}
                        seo={SeoTitle.PROFILE_VIEW}
                     />
                     {/* Physician services */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/my-services'}
                        isAuthenticated={validateRouter([])} // Constant.PHYSICIAN - Temporary hide
                        component={MyServicesPage}
                        seo={SeoTitle.MY_SERVICES}
                     />
                     {/* Admin component */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/admin'}
                        isAuthenticated={validateRouter([
                           Constant.PHYSICIAN,
                           Constant.NURSE,
                           Constant.PRACTISE_ADMIN,
                           Constant.AUXILIARY_STAFF,
                        ])}
                        component={Admin}
                        seo={SeoTitle.ADMIN}
                     />
                     {/* Feed */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/feed'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.PHYSICIAN,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        component={FeedPage}
                        seo={SeoTitle.FEED}
                     />
                     {/* Support Group */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/support-group'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        component={SupportGroupPage}
                        seo={SeoTitle.SUPPORT_GROUPS}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/support-group/detail/:id'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        isURLEncrypted={true}
                        component={SupportGroupDetailPage}
                        seo={SeoTitle.SUPPORT_GROUPS}
                     />
                     {/* Locum Service */}
                     {/* donot delete temp hide */}
                     {/* <PrivateRoute
                        header={true}
                        exact
                        path={'/locum-service/p-a'}
                        isURLEncrypted={true}
                        component={LocumPracticeAdminPage}
                        isAuthenticated={validateRouter([Constant.PRACTISE_ADMIN])}
                        seo={SeoTitle.LOCUM_SERVICES}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/locum-service/p-a/book-service'}
                        component={BookServicePage}
                        isAuthenticated={validateRouter([Constant.PRACTISE_ADMIN])}
                        seo={SeoTitle.LOCUM_SERVICES}
                     /> */}
                     {/* <PrivateRoute
                        header={true}
                        exact
                        path={'/locum-service/physician'}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN, Constant.PRACTISE_ADMIN])}
                        isURLEncrypted={true}
                        component={LocumPhysicianPage}
                        seo={SeoTitle.LOCUM_SERVICES}
                     /> */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/my-activities'}
                        component={MyFeedPage}
                        seo={SeoTitle.ACTIVITIES}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/hashtag-feed'}
                        component={HashtagFeedPage}
                        seo={SeoTitle.HASHTAG_FEED}
                     />
                     {/* Drug Lookup */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/drug-lookup'}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN, Constant.ORGANIZATION])}
                        component={DrugLookupPhysicianViewPage}
                        seo={SeoTitle.DRUG_LOOKUP}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/drug-lookup/detail/:id'}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN, Constant.ORGANIZATION])}
                        isURLEncrypted={true}
                        component={DrugDetailViewPage}
                        seo={SeoTitle.DRUG_LOOKUP}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/drug-lookup/specific/:id'}
                        component={DrugFullDetailsPage}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN, Constant.ORGANIZATION])}
                        seo={SeoTitle.DRUG_LOOKUP}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/drug-lookup/reviews/:id'}
                        component={DrugSeeAllReviewsPage}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN, Constant.ORGANIZATION])}
                        seo={SeoTitle.DRUG_LOOKUP}
                     />
                     {/* Medication Savings */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/medication-savings'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        component={MedicationSavingsHomePage}
                        seo={SeoTitle.MEDICATION_SAVINGS}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/medication-savings/detail/:medication_saving_id/:medication_saving_brand_id'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        isURLEncrypted={true}
                        component={MedicationSavingsDetailPage}
                        seo={SeoTitle.MEDICATION_SAVINGS}
                     />
                     {/* Ask-a-Doc */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/ask-a-doc/patient'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                        ])}
                        isURLEncrypted={true}
                        component={CaseListsPage}
                        seo={SeoTitle.ASK_A_DOC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/patient/treatment-estimate'}
                        isAuthenticated={validateRouter([])} // Constant.PATIENT, Constant.CAREGIVER, Constant.PATIENT_ADVOCATE - Temporary hide
                        component={CaseListsPage}
                        seo={SeoTitle.TREATMENT_ESTIMATE}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + urlSecondOpinion}
                        isAuthenticated={validateRouter([])} // Constant.PATIENT, Constant.CAREGIVER, Constant.PATIENT_ADVOCATE - Temporary hide
                        component={CaseListsProPage}
                        seo={SeoTitle.SECOND_OPINION}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/ask-a-doc/detail/:requestId'}
                        isAuthenticated={validateRouter([
                           Constant.PHYSICIAN,
                           Constant.PATIENT,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                        ])}
                        isURLEncrypted={true}
                        component={CaseListsDetailsPage}
                        seo={SeoTitle.ASK_A_DOC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/treatment-estimate/case-list/:requestId'}
                        isAuthenticated={validateRouter([])} // Constant.PATIENT, Constant.PHYSICIAN, Constant.CAREGIVER, Constant.PATIENT_ADVOCATE - Temporary hide
                        isURLEncrypted={true}
                        component={CaseListsDetailsPage}
                        seo={SeoTitle.TREATMENT_ESTIMATE}
                     />
                     {/* Ask-a-Doc select physician free */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/ask-a-doc/select-physician/:type/:id/:rejectedId?/:networkPhyId?'}
                        component={SelectPhysicianFreePage}
                        seo={SeoTitle.ASK_A_DOC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/treatment-estimate/select-physician/:type/:id/:rejectedId?/:networkPhyId?'}
                        component={SelectPhysicianFreePage}
                        seo={SeoTitle.TREATMENT_ESTIMATE}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + urlSecondOpinion + '/select-physician/:type/:id/:rejectedId?'}
                        component={SelectPhysicianFreePage}
                        seo={SeoTitle.SECOND_OPINION}
                        isAuthenticated={validateRouter([])} // Constant.PATIENT, Constant.CAREGIVER, Constant.PATIENT_ADVOCATE - Temporary hide
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/ask-a-doc/create-askADoc/:planType?'}
                        component={CreateAskADocPage}
                        seo={SeoTitle.ASK_A_DOC}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                        ])}
                     />
                     {/*First two , having all*/}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + urlSecondOpinion + '/chat'}
                        component={AskADocChatPage}
                        isAuthenticated={validateRouter([])} // Constant.PATIENT, Constant.CAREGIVER, Constant.PATIENT_ADVOCATE - Temporary hide
                        seo={SeoTitle.ASK_A_DOC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/conversations'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.PHYSICIAN,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        isURLEncrypted={true}
                        component={ConversationsPage}
                        seo={SeoTitle.CONVERSATION}
                     />
                     {/* Ask-a-Doc physician */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/ask-a-doc/physician'}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN])}
                        isURLEncrypted={true}
                        component={AskADocPhysicianCaseListPage}
                        seo={SeoTitle.ASK_A_DOC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/physician/treatment-estimate'}
                        component={AskADocPhysicianCaseListPage}
                        isAuthenticated={validateRouter([])} // Constant.PHYSICIAN - Temporary hide
                        seo={SeoTitle.TREATMENT_ESTIMATE}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + urlSecondOpinion + '/case-list'}
                        component={AskADocPhysicianCaseListProPage}
                        isAuthenticated={validateRouter([])} // Constant.PHYSICIAN - Temporary hide
                        seo={SeoTitle.SECOND_OPINION}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + urlSecondOpinion + '/case-list/chat'}
                        component={AskADocChatPage}
                        isAuthenticated={validateRouter([])} // Constant.PHYSICIAN - Temporary hide
                        seo={SeoTitle.SECOND_OPINION}
                     />
                     <PrivateRoute path={'/subscription'} component={Subscription} seo={SeoTitle.SUBSCRIPTION} />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/ask-a-doc/subscription-plan'} // This URL used in same WEB/MOB so don't change that
                        component={AskADocSubscriptionPlanPage}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                        ])}
                        seo={SeoTitle.ASK_A_DOC}
                     />
                     {/* ONCKLINIC SECTION */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.PHYSICIAN,
                           Constant.PRACTISE_ADMIN,
                           Constant.AUXILIARY_STAFF,
                        ])}
                        component={OncKlinicPage}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/schedule/:type?'}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN])}
                        isURLEncrypted={true}
                        component={SchedulePage}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/my-patients'}
                        component={MyPatientsPage}
                        isAuthenticated={validateRouter([
                           Constant.PHYSICIAN,
                           Constant.NURSE,
                           Constant.PRACTISE_ADMIN,
                           Constant.AUXILIARY_STAFF,
                        ])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/my-patients/add-patient'}
                        component={AddPatientsPage}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN, Constant.NURSE])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/rpm-dashboard'}
                        component={RPMDashboardPage}
                        isAuthenticated={validateRouter([
                           Constant.PHYSICIAN,
                           Constant.PRACTISE_ADMIN,
                           Constant.NURSE,
                           Constant.PRACTISE_ADMIN,
                           Constant.AUXILIARY_STAFF,
                        ])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={false}
                        exact
                        path={'/' + oncklinic + '/notification'}
                        isAuthenticated={validateRouter([Constant.PATIENT, Constant.PHYSICIAN])}
                        component={NotificationPage}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/meetings'}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN])}
                        component={Meeting}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/my-treating-physicians'}
                        component={MyTreatingPhysiciansPage}
                        isAuthenticated={validateRouter([Constant.PATIENT])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/my-treating-physicians/add-physician'}
                        component={AddPhysicianPage}
                        isAuthenticated={validateRouter([Constant.PATIENT])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/education-videos'}
                        component={VideoAssignmentPage}
                        isAuthenticated={validateRouter([Constant.PATIENT])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/my-appointments'}
                        component={MyApponitments}
                        isAuthenticated={validateRouter([Constant.PATIENT, Constant.PHYSICIAN])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/device-consent'}
                        isAuthenticated={validateRouter([Constant.PATIENT])}
                        component={DeviceConsent}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/health-records'}
                        component={HealthReportsPage}
                        isAuthenticated={validateRouter([Constant.PATIENT])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/patient/prescription'}
                        component={PrescriptionPage}
                        isAuthenticated={validateRouter([Constant.PATIENT])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/clinical-history'}
                        isAuthenticated={validateRouter([
                           Constant.PHYSICIAN,
                           Constant.PRACTISE_ADMIN,
                           Constant.NURSE,
                           Constant.PRACTISE_ADMIN,
                           Constant.AUXILIARY_STAFF,
                        ])}
                        isURLEncrypted={true}
                        component={ClinicalHistoryPage}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/clinical-history/billing-info-99453'}
                        component={BillingInfo99453Page}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/clinical-history/billing-info-99454'}
                        component={BillingInfo99454Page}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/clinical-history/billing-info-99457'}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN, Constant.NURSE, Constant.PRACTISE_ADMIN])}
                        component={BillingInfo99457}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/clinical-history/billing-info-99458'}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN, Constant.NURSE, Constant.PRACTISE_ADMIN])}
                        component={BillingInfo99458}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/clinical-history/billing-info-99091'}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN, Constant.NURSE])}
                        component={BillingInfo99091}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/clinical-history/billing-info-99421'}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN, Constant.NURSE])}
                        component={BillingInfo99421}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     {/* symptom */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/symptom'}
                        isAuthenticated={validateRouter([Constant.PATIENT])}
                        isURLEncrypted={true}
                        component={SymptomPage}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/report-symptom'}
                        component={ReportSymptomPage}
                        isAuthenticated={validateRouter([Constant.PATIENT])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     {/* OncoKlinic Devices */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/blood-pressure'}
                        isAuthenticated={validateRouter([Constant.PATIENT])}
                        isURLEncrypted={true}
                        component={BloodPressurePage}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/smart-weight'}
                        isAuthenticated={validateRouter([Constant.PATIENT])}
                        isURLEncrypted={true}
                        component={SmartWeightPage}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/thermometer'}
                        isAuthenticated={validateRouter([Constant.PATIENT])}
                        isURLEncrypted={true}
                        component={ThermometerPage}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/oximeter'}
                        isAuthenticated={validateRouter([Constant.PATIENT])}
                        isURLEncrypted={true}
                        component={OxyMeterPage}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/tele-medicine'}
                        component={TeleMedicinePage}
                        isAuthenticated={validateRouter([Constant.PATIENT, Constant.PHYSICIAN])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     {/* video-call */}
                     <PrivateRoute
                        header={false}
                        exact
                        path={'/' + oncklinic + '/video-call/:id'}
                        component={VideoCallPage}
                        isAuthenticated={validateRouter([Constant.PATIENT, Constant.PHYSICIAN])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/image-video-consult'}
                        isAuthenticated={validateRouter([Constant.PATIENT, Constant.PHYSICIAN, Constant.NURSE])}
                        isURLEncrypted={true}
                        component={MedicalEnquiryPage}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/image-video-consult/document/:id/:code'}
                        component={MedicalEnquiryDocumentsPage}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN, Constant.NURSE])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/tele-medicine/:id'}
                        component={TeleMedicineViewPage}
                        isAuthenticated={validateRouter([Constant.PATIENT, Constant.PHYSICIAN])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/' + oncklinic + '/tele-medicine/document/:id/:code'}
                        component={TeleMedicineDocumentsPage}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN])}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/pill-reminder-list/patient'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                        ])}
                        component={PillRemainder}
                        seo={SeoTitle.PILL_REMAINDER}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/pill-reminder-medications'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                        ])}
                        component={PillRemainderMedications}
                        seo={SeoTitle.PILL_REMAINDER}
                     />
                     {/* Notification */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/notification-settings'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.PHYSICIAN,
                           Constant.PRACTISE_ADMIN,
                           Constant.NURSE,
                           Constant.AUXILIARY_STAFF,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        component={NotificationSettings}
                        seo={SeoTitle.NOTIFICATION_SETTINGS}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/notification'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.PHYSICIAN,
                           Constant.PRACTISE_ADMIN,
                           Constant.NURSE,
                           Constant.AUXILIARY_STAFF,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        component={Notification}
                        seo={SeoTitle.NOTIFICATION}
                     />
                     {/* Health Records */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/health-records'}
                        component={HealthReportsPage}
                        seo={SeoTitle.ONCKLINIC}
                     />
                     {/* Notification feed route */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/notification-feed/:id'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.PHYSICIAN,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        isURLEncrypted={true}
                        component={NotificationFeedPage}
                        seo={SeoTitle.NOTIFICATION_FEED}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/ask-a-doc-feed/:id'}
                        component={AskADocOneFeedPage}
                        seo={SeoTitle.ASK_A_DOC_FEED}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/my-wallet'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.PHYSICIAN,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                        ])}
                        component={MyWallet}
                        seo={SeoTitle.WALLET}
                     />
                     {/*oncDrive Route*/}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/onc-drive'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.PHYSICIAN,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                        ])}
                        component={oncDrivePage}
                        seo={SeoTitle.ONCDRIVE}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/education-library'}
                        isURLEncrypted={true}
                        component={VideoLibrary}
                        seo={SeoTitle.EDUCATION_LIBRARY}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/education-library/organization'}
                        isURLEncrypted={true}
                        component={VideoLibrary}
                        seo={SeoTitle.EDUCATION_LIBRARY}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/education-library/organization/my-library'}
                        isURLEncrypted={true}
                        component={VideoOrgMyLibraryList}
                        seo={SeoTitle.EDUCATION_LIBRARY}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/education-library/organization/my-library/upload'}
                        isURLEncrypted={true}
                        component={VideoOrgMyLibraryUpload}
                        seo={SeoTitle.EDUCATION_LIBRARY}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/education-library/:id'}
                        isURLEncrypted={true}
                        component={VideoLibraryDetail}
                        seo={SeoTitle.EDUCATION_LIBRARY}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/education-library/organization/detail/:orgId?'}
                        isURLEncrypted={true}
                        component={VideoLibraryOrgDetail}
                        seo={SeoTitle.EDUCATION_LIBRARY}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/meditation/:id?'}
                        isURLEncrypted={true}
                        component={OncoMeditation}
                        seo={SeoTitle.ONCO_RELAX}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/onc-drive/:id/:share_id?'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.PHYSICIAN,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                        ])}
                        component={OncDriveFilesPage}
                        seo={SeoTitle.ONCDRIVE}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/daily-checklogs'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        component={DailyCheckLogs}
                        seo={SeoTitle.CHECKLOGS}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/daily-checklog-add'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        component={DailyCheckLogEntry}
                        seo={SeoTitle.CHECKLOGS}
                     />
                     {/*Job Recruiter*/}
                     {/* donot delete temp hide */}
                     {/* <PrivateRoute
                        header={true}
                        exact
                        path={'/job-recruiter/physician'}
                        component={PhysicianJobsListPage}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN])}
                        seo={SeoTitle.RECRUITER}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/job-recruiter/physician/detail/:id'}
                        component={PhysicianJobDetailPage}
                        isAuthenticated={validateRouter([Constant.PHYSICIAN])}
                        seo={SeoTitle.RECRUITER}
                     /> */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/job-recruiter/p-a-and-recruiter/detail/:id'}
                        isAuthenticated={validateRouter([Constant.PRACTISE_ADMIN, Constant.RECRUITER])}
                        component={JobRecruiterDetailPage}
                        seo={SeoTitle.RECRUITER}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/job-recruiter/p-a-and-recruiter'}
                        isAuthenticated={validateRouter([Constant.PRACTISE_ADMIN, Constant.RECRUITER])}
                        component={PAJobsListPage}
                        seo={SeoTitle.RECRUITER}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/global-search'}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.PHYSICIAN,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        component={GlobalSearch}
                        seo={SeoTitle.GLOBAL_SEARCH}
                     />
                     {/*Job Recruiter*/}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/my-saved-post'}
                        component={MySavedFeedPostPage}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.PHYSICIAN,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        seo={SeoTitle.MY_SAVED_POSTS}
                     />
                     {/* clinical trails */}
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/clinical-trials/:id?'}
                        component={ClinicalTrials}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        seo={SeoTitle.CLINICAL_TRIALS}
                     />
                     <PrivateRoute
                        header={true}
                        exact
                        path={'/clinical-trials-detail/:id?'}
                        component={ClinicalTrialsDetail}
                        isAuthenticated={validateRouter([
                           Constant.PATIENT,
                           Constant.CAREGIVER,
                           Constant.PATIENT_ADVOCATE,
                           Constant.ORGANIZATION,
                        ])}
                        seo={SeoTitle.CLINICAL_TRIALS}
                     />
                     {/* Other Login Forms */}
                     <Route exact path={'/linkedin'} component={LinkedInPopUp} />
                     <Route exact path={'/auth/doximity/callback'} component={DoximityLogin} />
                     <Route exact path={'/auth/apple/callback'} component={AppleLogin} />
                     <Route exact path={'/time'} component={Time} />
                     <Route exact path={'/stripe'} component={CheckoutFormElem} />
                     {/* Internet Connection Check */}
                     <Route exact path={'/no-internet-connection'} component={InternetConnectionCheck} />\
                     {/* Physician access denied */}
                     <Route exact path={'/phy-access-denied'} component={PhyAccessDenied} />
                     {/* Page Not Found */}
                     <Route component={PageNotFound} />
                  </Switch>
               </Suspense>
            </Router>
         </>
      );
   }
   componentWillUpdate(nextProps: any) {
      if (nextProps?.deleteDeviceToken?.message || nextProps?.deleteDeviceToken?.error) {
         this.setState(
            {
               isLoading: false,
            },
            () => {
               this.props.getUserResetDispatch();
               logout();
               setIdleTimeOut(1);
               window.location.href = ROUTE_LOGIN;
            }
         );
         this.props.deleteDeviceTokenResetDispatch();
      }
   }
}
const mapStateToProps = (state: storeTypes.IStore) => ({
   isApiLoading: state.common.isApiLoading,
   deleteDeviceToken: state.notificationSettingsList.deleteDeviceToken,
});

const mapDispatchToProps = (dispatch: any) => {
   const { updateApiLoadingState } = commonSlice.actions;
   const { deleteDeviceTokenReset } = notificationSettingsListSlice.actions;
   const { resetAllSearch } = searchSlice.actions;
   const { conversationCountAction } = commonSlice.actions;
   const { conversationsCountReset } = conversationSlice.actions;
   const { getUserReset } = userDetailsSlice.actions;

   return {
      resetAllSearchDispatch: () => dispatch(resetAllSearch()),
      updateApiLoadingState: (status: boolean) => dispatch(updateApiLoadingState(status)),
      notificationDeleteDeviceTokenDispatch: (data: notificationSagaTypes.INotificationDeleteDeviceTokenSaga) =>
         dispatch(notificationDeleteDeviceToken(data)),
      deleteDeviceTokenResetDispatch: () => dispatch(deleteDeviceTokenReset()),
      conversationCount: (count: number) => dispatch(conversationCountAction(count)),
      unreadConversationsCountReset: () => dispatch(conversationsCountReset()),
      getUserResetDispatch: () => dispatch(getUserReset()),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
