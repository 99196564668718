import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ONCDRIVE_ADD_SAGA, ONCDRIVE_EDIT_SAGA } from 'reduxToolKit/actions';
import { commonSlice, oncDriveAddFolderSlice } from 'reduxToolKit/reducers';
import { oncDriveSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { oncDriveAddFolderRequest, addOncDriveSuccess, addOncDriveFailed } = oncDriveAddFolderSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const addOncDrive = function* (oncDriveFolder: oncDriveSagaTypes.IAddToDrivePayload) {
   yield put(oncDriveAddFolderRequest(oncDriveFolder.payload));
   try {
      const data = {
         name: oncDriveFolder.payload?.name,
         parent_id: oncDriveFolder.payload?.parent_id,
         type: oncDriveFolder.payload?.type,
         media: oncDriveFolder.payload?.media,
         description: oncDriveFolder.payload?.description,
         issue_date: oncDriveFolder.payload?.issue_date,
      };
      const response: AxiosResponse = yield apiCall({ data, ...API.oncDriveAddDirectory });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: null,
         };
         yield put(addOncDriveSuccess(resObj));
      } else {
         yield put(addOncDriveFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
const oncDriveEdit = function* (oncDriveFolder: oncDriveSagaTypes.IAddToDrivePayload) {
   yield put(oncDriveAddFolderRequest(oncDriveFolder.payload));
   try {
      const apiPath = {
         apiPath: `${API.oncDriveEditDirectory.apiPath}${'/'}${oncDriveFolder.payload.id}`,
         action: API.oncDriveEditDirectory.action,
      };
      const data = {
         name: oncDriveFolder.payload?.name,
         parent_id: oncDriveFolder.payload?.parent_id,
         type: oncDriveFolder.payload?.type,
         media: oncDriveFolder.payload?.media,
         description: oncDriveFolder.payload?.description,
         issue_date: oncDriveFolder.payload?.issue_date,
         relative_path: oncDriveFolder.payload?.relative_path,
      };

      const response: AxiosResponse = yield apiCall({ data, ...apiPath });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: null,
         };
         yield put(addOncDriveSuccess(resObj));
      } else {
         yield put(addOncDriveFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeAddOncDriveRequest = function* () {
   yield takeEvery(ONCDRIVE_ADD_SAGA, addOncDrive);
};
export const takeEditOncDriveRequest = function* () {
   yield takeEvery(ONCDRIVE_EDIT_SAGA, oncDriveEdit);
};
