import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
   IAuthGoogleLoginFailurePayload,
   IAuthGoogleLoginPayload,
   IAuthLinkedInLoginFailurePayload,
   IAuthLinkedInLoginPayload,
   IAuthUserRegisterPayload,
   IGoogleLoginActionProps,
   ILinkedInLoginActionProps,
   IUserRegisterSagaProps,
} from 'types/auth/login';

const loginSlice: any = createSlice({
   name: 'login',
   initialState: {
      user: null,
      accountDeActivate: null,
   },
   reducers: {
      loginSuccess: (state, action) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      logOutSuccess: (state, action) => {
         return {
            ...state,
         };
      },
      userAccountDeActivated: (state, action) => {
         return {
            ...state,
            accountDeActivate: action.payload.account,
            msg: action.payload.msg,
         };
      },
   },
});

const initialState: IAuthGoogleLoginPayload = {
   isLoading: false,
   message: '',
   data: null,
   error: '',
};

const googleLoginSlice = createSlice({
   name: 'googleLogin',
   initialState: initialState,
   reducers: {
      googleLoginAddRequest: (state, action: PayloadAction<IGoogleLoginActionProps>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      googleLoginAddSuccess: (state, action: PayloadAction<IAuthGoogleLoginPayload>) => {
         const { payload } = action;
         return {
            ...state,
            data: payload,
            isLoading: false,
            message: payload.message,
         };
      },
      googleLoginAddFailed: (state, action: PayloadAction<IAuthGoogleLoginFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error_message: action.payload.error_message || '',
            errors: action.payload.errors,
         };
      },

      googleLoginAddReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

const initialStateLInkedIn: IAuthLinkedInLoginPayload = {
   isLoading: false,
   message: '',
   data: null,
   error: '',
};

const linkedInLoginSlice = createSlice({
   name: 'linkedInLogin',
   initialState: initialStateLInkedIn,
   reducers: {
      linkedInLoginAddRequest: (state, action: PayloadAction<ILinkedInLoginActionProps>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      linkedInLoginAddSuccess: (state, action: PayloadAction<IAuthLinkedInLoginPayload>) => {
         const { payload } = action;
         return {
            ...state,
            data: payload,
            isLoading: false,
            message: payload.message,
         };
      },
      linkedInLoginAddFailed: (state, action: PayloadAction<IAuthLinkedInLoginFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error_message: action.payload.error_message || '',
            errors: action.payload.errors,
         };
      },

      linkedInLoginAddReset: () => {
         return {
            ...initialStateLInkedIn,
         };
      },
   },
});

const initialStateUserRegister: IAuthUserRegisterPayload = {
   isLoading: false,
   message: '',
   error: '',
};

const userRegisterSlice = createSlice({
   name: 'userRegister',
   initialState: initialStateUserRegister,
   reducers: {
      userRegisterRequest: (state, action: PayloadAction<IUserRegisterSagaProps>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      userRegisterSuccess: (state, action: PayloadAction<IAuthUserRegisterPayload>) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            message: payload.message,
         };
      },
      userRegisterFailed: (state, action: PayloadAction<IAuthLinkedInLoginFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error_message: action.payload.error_message || '',
            errors: action.payload.errors,
         };
      },

      userRegisterReset: () => {
         return {
            ...initialStateUserRegister,
         };
      },
   },
});

export { googleLoginSlice, linkedInLoginSlice, loginSlice, userRegisterSlice };
