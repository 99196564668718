import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { eventTrackAction, FEED_CREATE_AND_EDIT, FEED_POST_DELETE } from 'reduxToolKit/actions';
import { commonSlice, postCreateAndEditSlice } from 'reduxToolKit/reducers';
import { eventTrackTypes, feedSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   postCreateAndEditFailure,
   postCreateAndEditRequest,
   postCreateAndEditSuccess,
   postDeleteFailure,
   postDeleteRequest,
   postDeleteSuccess,
} = postCreateAndEditSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const createAndEditPost = function* (payloadState: PayloadAction<feedSagaTypes.ICreateAndEditPostEventSagaPayload>) {
   yield put(postCreateAndEditRequest(payloadState.payload));
   try {
      let url;
      if (payloadState.payload.postType === 'add') {
         url = API.feedAddPost;
      } else {
         url = API.feedEditPost;
      }

      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload.post;
      const response: AxiosResponse = yield apiCall({ goUrl, data, checkUser, ...url });
      if (response.status === 200) {
         const responseData = {
            message: response?.data?.message || messages.success,
         };
         yield put(postCreateAndEditSuccess(responseData));
         if (payloadState.payload.event) {
            const eventData: eventTrackTypes.IEventTrack = {
               event: {
                  name: payloadState.payload.event.name,
               },
            };
            yield put(eventTrackAction(eventData));
         }
      } else {
         yield put(postCreateAndEditFailure(response.data));
      }
   } catch (err) {
      yield put(exceptionHandlerRequest(err));
   }
};

const deletePost = function* (payloadState: PayloadAction<feedSagaTypes.IDeletePostSagaPayload>) {
   yield put(postDeleteRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ goUrl, data, checkUser, ...API.feedDeletePost });
      if (response.status === 200) {
         const responseData = {
            message: response?.data?.message || messages.success,
         };
         yield put(postDeleteSuccess(responseData));
      } else {
         yield put(postDeleteFailure(response.data));
      }
   } catch (err) {
      yield put(exceptionHandlerRequest(err));
   }
};

export const takeCreateAndEditPost = function* () {
   yield takeLatest(FEED_CREATE_AND_EDIT, createAndEditPost);
};
export const takeDeletePost = function* () {
   yield takeLatest(FEED_POST_DELETE, deletePost);
};
