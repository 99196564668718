import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { secondOpinionReducerTypes, secondOpinionSagaTypes } from 'types';

const initialState: secondOpinionReducerTypes.ISecondOpinionFilterPhysicianListReducerPayload = {
   data: null,
   message: '',
   error: '',
   isLoading: false,
};

export const secondOpinionPhyListSlice = createSlice({
   name: 'secondOpinionPhyList',
   initialState: initialState,
   reducers: {
      secondOpinionPhyListRequest: (
         state,
         action: PayloadAction<secondOpinionSagaTypes.ISecOpinionPhyListSagaPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      secondOpinionPhyListSuccess: (
         state,
         action: PayloadAction<secondOpinionReducerTypes.ISecOpinionPhyListSuccessPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      secondOpinionPhyListFailure: (
         state,
         action: PayloadAction<secondOpinionReducerTypes.ISecOpinionPhyListFailurePayload>
      ) => {
         return {
            ...state,
            error: action.payload.error,
            isLoading: false,
         };
      },

      secondOpinionPhyListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
