import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { SUPPORT_GROUP_LIST } from 'reduxToolKit/actions';
import { commonSlice, supportGroupsSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   supportGroupsLoading,
   supportGroupsViewRequest,
   supportGroupsViewSuccess,
   supportGroupsFailed,
} = supportGroupsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const supportGroupsSaga = function* (cancerTypePayload: any) {
   yield put(supportGroupsLoading());
   yield put(supportGroupsViewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.supportGroupsList });
      if (response.status === 200) {
         const responseData = response?.data;
         const oncDriveList = {
            list: responseData?.data?.list,
            total_records: responseData?.data?.total_records,
            message: response?.data?.message,
         };
         yield put(supportGroupsViewSuccess(oncDriveList));
      } else {
         yield put(supportGroupsFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeSupportGroups = function* () {
   yield takeEvery(SUPPORT_GROUP_LIST, supportGroupsSaga);
};
