import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { RPM_DASHBOARD_LIST, GET_CLINIC_RPM_ALL_DEVICES, GET_CLINIC_RPM_DEVICES } from 'reduxToolKit/actions';
import { commonSlice, rpmDashboardSlice } from 'reduxToolKit/reducers';
import { rpmDashBoardSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   rpmDashboardLoading,
   rpmDashboardRequest,
   rpmDashboardSuccess,
   rpmDashboardFailed,
   clinicAllRpmDevicesRequest,
   clinicAllRpmDevicesSuccess,
   clinicAllRpmDevicesFailed,
} = rpmDashboardSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const rpmDashboardSaga = function* (rpmTypePayload: any) {
   yield put(rpmDashboardLoading());
   yield put(rpmDashboardRequest(rpmTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = rpmTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getRPMDashboard });
      if (response.status === 200) {
         const responseData = response.data;
         const rpmDashboardList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            message: response.data?.message,
         };
         yield put(rpmDashboardSuccess(rpmDashboardList));
      } else {
         yield put(rpmDashboardFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getClinicRpmAllDevicesSaga = function* (payloadState: PayloadAction<rpmDashBoardSagaTypes.IClinicRpmAllDevices>) {
   yield put(clinicAllRpmDevicesRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getClinicRpmAllDevices });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
            data: response.data?.data,
         };
         yield put(clinicAllRpmDevicesSuccess(responseData));
      } else {
         yield put(clinicAllRpmDevicesFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getClinicRpmDevicesSaga = function* (payloadState: PayloadAction<rpmDashBoardSagaTypes.IClinicRpmAllDevices>) {
   yield put(clinicAllRpmDevicesRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getClinicRpmDevices });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
            data: response.data?.data,
         };
         yield put(clinicAllRpmDevicesSuccess(responseData));
      } else {
         yield put(clinicAllRpmDevicesFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeRPMDashboardRequest = function* () {
   yield takeEvery(RPM_DASHBOARD_LIST, rpmDashboardSaga);
   yield takeEvery(GET_CLINIC_RPM_DEVICES, getClinicRpmDevicesSaga);
};

export const takeClinicRpmAllDevicesSaga = function* () {
   yield takeEvery(GET_CLINIC_RPM_ALL_DEVICES, getClinicRpmAllDevicesSaga);
};
