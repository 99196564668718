import { createSlice } from '@reduxjs/toolkit';
import { joinVideoCallReducerTypes, joinVideoCallSagaTypes } from 'types';

const initialState: joinVideoCallReducerTypes.IAppointmentReducer = {
   joinCallDetails: null,
   isLoading: false,
   error: '',
   message: '',
};
const joinCallAppointmentSlice = createSlice({
   name: 'joinCallAppointment',
   initialState: initialState,
   reducers: {
      getJoinCallList: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getJoinCallSuccess: (state, action: joinVideoCallSagaTypes.IGetVideoAppointments) => {
         return {
            ...state,
            joinCallDetails: action.payload,
            isLoading: false,
         };
      },
      getJoinCallFailed: (state, action) => {
         return {
            ...state,
            joinCallDetails: null,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
   },
});
export { joinCallAppointmentSlice };
