import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINICAL_TRIALS_STUDY_PHASE } from 'reduxToolKit/actions';
import { clinicalTrialsStudyPhaseSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   clinicalTrialsStudyPhaseRequest,
   clinicalTrialsStudyPhaseSuccess,
   clinicalTrialsStudyPhaseFailed,
} = clinicalTrialsStudyPhaseSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const clinicalTrialsStudyPhaseSaga = function* (clinicalTrialsStudyPhasePayload: any) {
   yield put(clinicalTrialsStudyPhaseRequest(clinicalTrialsStudyPhasePayload.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const params = clinicalTrialsStudyPhasePayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         params,
         peopleMayYouKnow,
         ...API.clinicalTrialsStudyPhase,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const clinicalTrialsStudyPhaseList = {
            list: responseData.data.data,
            message: response.data?.message,
         };
         yield put(clinicalTrialsStudyPhaseSuccess(clinicalTrialsStudyPhaseList));
      } else {
         yield put(clinicalTrialsStudyPhaseFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeClinicalTrialsStudyPhaseRequest = function* () {
   yield takeEvery(CLINICAL_TRIALS_STUDY_PHASE, clinicalTrialsStudyPhaseSaga);
};
