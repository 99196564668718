import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { locumReducerTypes, locumSagaTypes } from 'types';

const initialState: locumReducerTypes.ILocumMonthWiseServiceListReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};

export const locumMonthWiseServiceListSlice = createSlice({
   name: 'locumMonthWiseServiceList',
   initialState: initialState,
   reducers: {
      locumMonthWiseServiceListRequest: (
         state,
         action: PayloadAction<locumSagaTypes.ILocumMonthWiseServiceListSagaParams>
      ) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: true,
         };
      },
      locumMonthWiseServiceListSuccess: (
         state,
         action: PayloadAction<locumReducerTypes.ILocumMonthWiseServiceListReducer>
      ) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      locumMonthWiseServiceListFailed: (
         state,
         action: PayloadAction<locumReducerTypes.ILocumMonthWiseServiceListReducer>
      ) => {
         const { payload } = action;
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: payload.error,
         };
      },

      locumMonthWiseServiceListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
