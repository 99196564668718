import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { DELETE_EXPERIENCE } from 'reduxToolKit/actions';
import { commonSlice, deleteExperienceSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { deleteExperienceRequest, deleteExperienceSuccess, deleteExperienceFailed } = deleteExperienceSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const deleteExperienceSaga = function* (deleteExperiencePayload: any) {
   yield put(deleteExperienceRequest(deleteExperiencePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = deleteExperiencePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.deleteExperience });
      if (response.status === 200) {
         const responseData = response.data;
         const deleteExperienceList = {
            message: responseData?.message || '',
         };
         yield put(deleteExperienceSuccess(deleteExperienceList));
      } else {
         yield put(deleteExperienceFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takedeleteExperience = function* () {
   yield takeEvery(DELETE_EXPERIENCE, deleteExperienceSaga);
};
