import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { REPORTABLE_SYMPTOM_GO_LIST, REPORTABLE_SYMPTOM_LIST } from 'reduxToolKit/actions';
import { commonSlice, reportableSymptomSlice, reportableSymptomGoListSlice } from 'reduxToolKit/reducers';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   reportableSymptomLoading,
   reportableSymptomRequest,
   reportableSymptomSuccess,
   reportableSymptomFailed,
} = reportableSymptomSlice.actions;
const {
   reportableSymptomGoLoading,
   reportableSymptomGoRequest,
   reportableSymptomGoSuccess,
   reportableSymptomGoFailed,
} = reportableSymptomGoListSlice.actions;

const { exceptionHandlerRequest } = commonSlice.actions;

const reportableSymptomListSaga = function* (cancerTypePayload: any) {
   yield put(reportableSymptomLoading());
   yield put(reportableSymptomRequest(cancerTypePayload.payload));
   try {
      const checkUser = false;
      const goUrl = false;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.reportSymptomList });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            list: responseData.data.paginate.list,
            total_records: responseData.data.paginate.total_records,
            message: response.data?.message || messages.success,
            is_load_more: responseData.data.is_load_more,
         };
         yield put(reportableSymptomSuccess(oncDriveList));
      } else {
         yield put(reportableSymptomFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const reportableSymptomGoListSaga = function* (cancerTypePayload: any) {
   yield put(reportableSymptomGoLoading());
   yield put(reportableSymptomGoRequest(cancerTypePayload.payload));
   try {
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         goUrl: true,
         params,
         ...API.reportSymptomListOld,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            message: messages.success,
         };
         yield put(reportableSymptomGoSuccess(oncDriveList));
      } else {
         yield put(reportableSymptomGoFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeReportableSymptomList = function* () {
   yield takeEvery(REPORTABLE_SYMPTOM_LIST, reportableSymptomListSaga);
   yield takeEvery(REPORTABLE_SYMPTOM_GO_LIST, reportableSymptomGoListSaga);
};
