import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_POPULATION_SERVED, GET_USER_POPULATION_SERVED } from 'reduxToolKit/actions';
import { commonSlice, populationServedSlice } from 'reduxToolKit/reducers';
import { myProfileReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   populationServedAddRequest,
   populationServedAddSuccess,
   populationServedAddFailed,
   getUserPopulationServedRequest,
   getUserPopulationServedSuccess,
   getUserPopulationServedFailed,
} = populationServedSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const populationServedAddSaga = function* (populationServedAddPayload: any) {
   yield put(populationServedAddRequest(populationServedAddPayload.payload));
   try {
      const data = populationServedAddPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: false,
         data,
         ...API.populationServedAdd,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const populationServedAddData = {
            message: responseData?.message || '',
         };
         yield put(populationServedAddSuccess(populationServedAddData));
      } else {
         yield put(populationServedAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getUserPopulationServedSaga = function* (
   getUserPopulationServedPayload: PayloadAction<myProfileReducerTypes.IRaceReducer>
) {
   yield put(getUserPopulationServedRequest(getUserPopulationServedPayload.payload));
   try {
      const params = getUserPopulationServedPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         params,
         ...API.getUserPopulationServed,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const getUserPopulationServedList = {
            data: responseData?.data || null,
            total_records: 0,
            message: messages.success,
         };
         yield put(getUserPopulationServedSuccess(getUserPopulationServedList));
      } else {
         yield put(getUserPopulationServedFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePopulationServed = function* () {
   yield takeEvery(ADD_POPULATION_SERVED, populationServedAddSaga);
   yield takeEvery(GET_USER_POPULATION_SERVED, getUserPopulationServedSaga);
};
