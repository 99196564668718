import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { SECOND_OPINION_SHORT_LIST_PHYSICIAN, SEC_OPINION_ADD_N_DELETE_SHORT_LIST } from 'reduxToolKit/actions';
import { commonSlice, secOpinionShortPhyListSlice } from 'reduxToolKit/reducers';
import { secondOpinionSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   secOpinionShortPhyListRequest,
   secOpinionShortPhyListFailure,
   secOpinionShortPhyListSuccess,
   secOpinionAddNDeleteShortPhyFailure,
   secOpinionAddNDeleteShortPhyRequest,
   secOpinionAddNDeleteShortPhySuccess,
} = secOpinionShortPhyListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

//list
const secondOpinionShortListPhy = function* (
   payloadState: PayloadAction<secondOpinionSagaTypes.ISecOpinionShortListPhySagaPayload>
) {
   yield put(secOpinionShortPhyListRequest(payloadState.payload));
   try {
      const params = payloadState.payload;
      const url = API.secondOpinionShortListPhysician;
      const checkUser = true;
      const goUrl = true;
      const response: AxiosResponse = yield apiCall({ params, checkUser, goUrl, ...url });
      if (response.status === 200) {
         const responseData = response?.data?.data;
         const dataObj = {
            data: responseData,
            message: response?.data?.message || messages.success,
         };
         yield put(secOpinionShortPhyListSuccess(dataObj));
      } else {
         yield put(secOpinionShortPhyListFailure(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

//add n delete
const secondOpinionShortListAddNDelete = function* (
   payloadState: PayloadAction<secondOpinionSagaTypes.ISecOpinionAddNDeleteShortListSagaPayload>
) {
   yield put(secOpinionAddNDeleteShortPhyRequest(payloadState.payload));
   try {
      const data = payloadState.payload.data;
      let url = API.deleteShortListPhysician;
      if (payloadState.payload.phyType === 'add') {
         url = API.addShortListPhysician;
      }
      const checkUser = true;
      const goUrl = true;
      const response: AxiosResponse = yield apiCall({ data, checkUser, goUrl, ...url });
      if (response.status === 200) {
         const dataObj = {
            data: null,
            message: response?.data?.message || messages.success,
         };
         yield put(secOpinionAddNDeleteShortPhySuccess(dataObj));
      } else {
         yield put(secOpinionAddNDeleteShortPhyFailure(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeSecondOpinionShortListPhysician = function* () {
   yield takeLatest(SECOND_OPINION_SHORT_LIST_PHYSICIAN, secondOpinionShortListPhy);
};
export const takeSecondOpinionShortListAddNDelete = function* () {
   yield takeLatest(SEC_OPINION_ADD_N_DELETE_SHORT_LIST, secondOpinionShortListAddNDelete);
};
