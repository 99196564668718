import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { VDOCIPHER_ACTION, VDOCIPHER_GET_VIDEO_QUESTIONS } from 'reduxToolKit/actions';
import { vdocipherSlice, commonSlice } from 'reduxToolKit/reducers';
import { vdocipherSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   vdocipherFailed,
   vdocipherRequest,
   vdocipherSuccess,
   vdocipherQuestionsFailed,
   vdocipherQuestionsRequest,
   vdocipherQuestionsSuccess,
} = vdocipherSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const vdocipherSaga = function* (payloadState: PayloadAction<vdocipherSagaTypes.IPostVideoCipherSagaPayload>) {
   yield put(vdocipherRequest(payloadState.payload));
   try {
      const peopleMayYouKnow = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ peopleMayYouKnow, params, ...API.vdoCipher });
      if (response.status === 200) {
         const resObj = {
            message: response?.data?.message,
            data: response?.data?.data,
         };
         yield put(vdocipherSuccess(resObj));
      } else {
         yield put(vdocipherFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
const vdocipherQuestionsSaga = function* (
   payloadState: PayloadAction<vdocipherSagaTypes.IVdoCipherGetQuestionSagaPayload>
) {
   yield put(vdocipherQuestionsRequest(payloadState.payload));
   try {
      const peopleMayYouKnow = true;
      const url = {
         apiPath: `${API.videoLibraryList.apiPath}/${payloadState.payload.id}`,
         action: API.videoLibraryList.action,
      };

      const response: AxiosResponse = yield apiCall({ peopleMayYouKnow, ...url });
      if (response.status === 200) {
         const resObj = {
            message: response?.data?.message || messages.success,
            data: response?.data?.data,
         };
         yield put(vdocipherQuestionsSuccess(resObj));
      } else {
         yield put(vdocipherQuestionsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeVdocipher = function* () {
   yield takeLatest(VDOCIPHER_ACTION, vdocipherSaga);
};
export const takeVdocipherQuestions = function* () {
   yield takeLatest(VDOCIPHER_GET_VIDEO_QUESTIONS, vdocipherQuestionsSaga);
};
