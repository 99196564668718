import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { feedReducerTypes } from 'types';
import { feedSagaTypes } from 'types/feed';
import { messages } from 'utils/constants/messages';

const initialState: feedReducerTypes.IFeedReactionsAddReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const feedShareLinkUserSlice = createSlice({
   name: 'feedShareLinkUser',
   initialState: initialState,
   reducers: {
      feedShareLinkUserRequest: (state, action: PayloadAction<feedSagaTypes.IFeedPostUserLinkUserParams>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      feedShareLinkUserSuccess: (state, action: PayloadAction<feedReducerTypes.IFeedReactionsAddReducer>) => {
         return {
            ...state,
            isLoading: false,
            data: action.payload?.data,
            message: action.payload?.message || messages.success,
         };
      },
      feedShareLinkUserFailed: (state, action: PayloadAction<feedReducerTypes.IFeedReactionsAddReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error || messages.failed,
         };
      },
      feedShareLinkUserReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
export { feedShareLinkUserSlice };
