import { createAction } from '@reduxjs/toolkit';
import { subscriptionDetailsReducerTypes } from 'types';

export const SUBSCRIPTION_PLAN_IS_ACTIVE = 'SUBSCRIPTION_PLAN_IS_ACTIVE';
export const subscriptionPlanIsActive = createAction(SUBSCRIPTION_PLAN_IS_ACTIVE);

export const SUBSCRIPTION_PLANS_LIST = 'SUBSCRIPTION_PLANS_LIST';
export const subscriptionPlansList = createAction(SUBSCRIPTION_PLANS_LIST);

export const SUBSCRIPTION_GET_PLAN = 'SUBSCRIPTION_GET_PLAN';
export const subscriptionGetPlan = createAction(SUBSCRIPTION_GET_PLAN);

export const SUBSCRIPTION_CANCEL_PLAN = 'SUBSCRIPTION_CANCEL_PLAN';
export const subscriptionCancelPlan = createAction(SUBSCRIPTION_CANCEL_PLAN);

export const SUBSCRIPTION_CREATE_PLAN = 'SUBSCRIPTION_CREATE_PLAN';
export const subscriptionCreatePlan = createAction<subscriptionDetailsReducerTypes.ISubscriptionCreatePlanRequest>(
   SUBSCRIPTION_CREATE_PLAN
);

export const SUBSCRIPTION_CHECK_COUPON_CODE = 'SUBSCRIPTION_CHECK_COUPON_CODE';
export const subscriptionCheckCouponCode = createAction<subscriptionDetailsReducerTypes.ISubscriptionCheckCouponCode>(
   SUBSCRIPTION_CHECK_COUPON_CODE
);

export const SUBSCRIPTION_STRIPE_PORTAL = 'SUBSCRIPTION_STRIPE_PORTAL';
export const subscriptionStripePortalSagaAction = createAction(SUBSCRIPTION_STRIPE_PORTAL);

export const SUBSCRIPTION_CHECK_WALLET = 'SUBSCRIPTION_CHECK_WALLET';
export const subscriptionCheckWalletSagaAction = createAction<subscriptionDetailsReducerTypes.ISubscriptionCheckWalletParams>(
   SUBSCRIPTION_CHECK_WALLET
);

export const SUBSCRIPTION_ONE_TIME_PURCHASE = 'SUBSCRIPTION_ONE_TIME_PURCHASE';
export const subscriptionOneTimePurchaseSagaAction = createAction<subscriptionDetailsReducerTypes.ISubscriptionOneTimePurchaseParams>(
   SUBSCRIPTION_ONE_TIME_PURCHASE
);

export const SUBSCRIPTION_CALLBACK = 'SUBSCRIPTION_CALLBACK';
export const subscriptionCallbackSagaAction = createAction<subscriptionDetailsReducerTypes.ISubscriptionCallbackParams>(
   SUBSCRIPTION_CALLBACK
);
