import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { adminJobReducerTypes, adminJobSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';

const initialState: adminJobReducerTypes.IRecruiterJobReducersPayload = {
   createJob: {
      isLoading: false,
      data: null,
      message: '',
      error: '',
      errors: null,
   },
   data: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   jobLists: {
      isLoading: false,
      data: [],
      message: '',
      error: '',
      pagination: {
         current_page: null,
         last_page: null,
         first_page_url: '',
         last_page_url: '',
         next_page_url: '',
         prev_page_url: '',
         total: 0,
      },
   },
   applicantsList: {
      isLoading: false,
      data: [],
      message: '',
      error: '',
      isShortList: 0,
      pagination: {
         current_page: null,
         last_page: null,
         first_page_url: '',
         last_page_url: '',
         next_page_url: '',
         prev_page_url: '',
         total: 0,
      },
   },
   acceptRejectShort: {
      type: '',
      isLoading: false,
      data: null,
      message: '',
   },
   deleteData: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
};

export const recruiterAdminJobSlice = createSlice({
   name: 'recruiterAdminJob',
   initialState: initialState,
   reducers: {
      recruiterJobCreationRequest: (state, action: PayloadAction<adminJobSagaTypes.IRecruiterJobCreateSagaPayload>) => {
         return {
            ...state,
            createJob: {
               ...state.createJob,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      recruiterJobCreationSuccess: (state, action: PayloadAction<adminJobReducerTypes.IRecruiterJobSuccessPayload>) => {
         return {
            ...state,
            createJob: {
               ...action.payload,
               error: '',
               isLoading: false,
            },
         };
      },
      recruiterJobCreationFailed: (state, action: PayloadAction<adminJobReducerTypes.IRecruiterJobsFailurePayload>) => {
         return {
            ...state,
            createJob: {
               error: action.payload?.error,
               errors: action.payload?.errors,
               message: '',
               isLoading: false,
            },
         };
      },

      recruiterJobCreationReset: (state) => {
         return {
            ...state,
            createJob: {
               isLoading: false,
               data: null,
               message: '',
               error: '',
            },
         };
      },

      adminApplicantsListRequest: (state, action: PayloadAction<adminJobSagaTypes.IAdminJobApplicantsList>) => {
         return {
            ...state,
            applicantsList: {
               ...state.applicantsList,
               ...action.payload,
               isLoading: true,
               data: action.payload.pageNo && action.payload.pageNo === 1 ? [] : state.applicantsList.data,
            },
         };
      },
      adminApplicantsListSuccess: (
         state,
         action: PayloadAction<adminJobReducerTypes.IRecruiterJobListSuccessPayload>
      ) => {
         return {
            ...state,
            applicantsList: {
               ...state.applicantsList,
               message: action.payload?.message,
               pagination: { ...action.payload?.pagination },
               data:
                  action.payload?.pagination.current_page === 1
                     ? action.payload?.data.data
                     : [...state.applicantsList?.data, ...action.payload?.data.data],
               isLoading: false,
               error: '',
            },
         };
      },
      adminApplicantsListError: (state, action: PayloadAction<adminJobReducerTypes.IRecruiterJobsFailurePayload>) => {
         return {
            ...state,
            applicantsList: {
               error: action.payload?.error,
               message: '',
               isLoading: false,
               isShortList: state.applicantsList?.isShortList | 0,
               pagination: {
                  current_page: null,
                  last_page: null,
                  first_page_url: '',
                  last_page_url: '',
                  next_page_url: '',
                  prev_page_url: '',
                  total: 0,
               },
            },
         };
      },
      recruiterJobShortlistAction: (state, action: PayloadAction<adminJobReducerTypes.IJobShortListActionType>) => {
         const applicantsList = state.applicantsList;
         const list = listParseAndStringify(applicantsList.data);
         const index = list.findIndex((x: any) => x.id === action.payload?.applicantId);
         if (index !== -1) {
            list[index].is_shortlisted = action.payload?.isShortlisted ? 1 : 0;
            if (action.payload?.type === 'shortlist' && applicantsList.isShortList === 1) {
               list.splice(index, 1);
            }
            return {
               ...state,
               applicantsList: {
                  ...state.applicantsList,
                  data: list,
               },
            };
         } else {
            return {
               ...state,
            };
         }
      },

      acceptRejectShortPhysicianRequest: (
         state,
         action: PayloadAction<adminJobSagaTypes.IAcceptRejectShortPhysician>
      ) => {
         return {
            ...state,
            acceptRejectShort: {
               ...state.acceptRejectShort,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      acceptRejectShortPhysicianSuccess: (
         state,
         action: PayloadAction<adminJobReducerTypes.IPhysicianAcceptRejectShortSuccessPayload>
      ) => {
         return {
            ...state,
            acceptRejectShort: {
               ...action.payload,
               error: '',
               isLoading: false,
            },
         };
      },
      acceptRejectShortPhysicianError: (
         state,
         action: PayloadAction<adminJobReducerTypes.IPhysicianAcceptRejectShortFailurePayload>
      ) => {
         return {
            ...state,
            acceptRejectShort: {
               ...action.payload,
               message: '',
               data: null,
               isLoading: false,
            },
         };
      },
      acceptRejectShortPhysicianReset: (state) => {
         return {
            ...state,
            acceptRejectShort: {
               ...state.acceptRejectShort,
               type: '',
               isLoading: false,
               data: null,
               message: '',
               error: '',
            },
         };
      },

      recruiterJobDetailRequest: (state, action: PayloadAction<adminJobSagaTypes.IRecruiterJobDetailSagaPayload>) => {
         return {
            ...state,
            data: {
               isLoading: true,
               message: state.data?.message || '',
               data: state.data?.data || null,
               error: state.data?.error || '',
            },
         };
      },
      recruiterJobDetailSuccess: (state, action: PayloadAction<adminJobReducerTypes.IRecruiterJobSuccessPayload>) => {
         return {
            ...state,
            data: {
               isLoading: false,
               message: action.payload?.message,
               data: action.payload?.data,
               error: '',
            },
         };
      },
      recruiterJobDetailFailed: (state, action: PayloadAction<adminJobReducerTypes.IRecruiterJobsFailurePayload>) => {
         return {
            ...state,
            data: {
               isLoading: false,
               error: action.payload?.error,
               message: '',
               data: null,
            },
         };
      },

      recruiterJobDetailReset: (state) => {
         return {
            ...state,
            data: {
               isLoading: false,
               error: '',
               message: '',
               data: null,
            },
         };
      },

      recruiterJobsListRequest: (state, action: PayloadAction<adminJobSagaTypes.IRecruiterJobsListSagaPayload>) => {
         return {
            ...state,
            jobLists: {
               ...state.jobLists,
               ...action.payload,
               isLoading: true,
               data: action.payload.page && action.payload.page === 1 ? [] : state.jobLists.data,
            },
         };
      },
      recruiterJobsListSuccess: (
         state,
         action: PayloadAction<adminJobReducerTypes.IRecruiterJobListSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            jobLists: {
               ...state.jobLists,
               pagination: { ...payload.pagination },
               data:
                  payload.pagination.current_page === 1
                     ? listParseAndStringify(payload.data)
                     : [...state.jobLists.data, ...listParseAndStringify(payload.data)],
               message: messages.success,
               error: '',
               isLoading: false,
            },
         };
      },
      recruiterJobsListFailed: (state, action: PayloadAction<adminJobReducerTypes.IRecruiterJobsFailurePayload>) => {
         return {
            ...state,
            jobLists: {
               ...initialState.jobLists,
               error: action.payload?.error,
            },
         };
      },

      recruiterJobDeleteRequest: (state, action: PayloadAction<adminJobSagaTypes.IRecruiterJobDeleteSagaPayload>) => {
         return {
            ...state,
            isLoading: false,
            deleteData: {
               isLoading: true,
               message: '',
               data: null,
               error: '',
            },
         };
      },
      recruiterJobDeleteSuccess: (state, action: PayloadAction<adminJobReducerTypes.IRecruiterJobSuccessPayload>) => {
         return {
            ...state,
            deleteData: {
               isLoading: false,
               message: action.payload?.message,
               data: action.payload?.data,
               error: '',
            },
         };
      },
      recruiterJobDeleteFailed: (state, action: PayloadAction<adminJobReducerTypes.IRecruiterJobsFailurePayload>) => {
         return {
            ...state,
            deleteData: {
               isLoading: false,
               error: action.payload?.error,
               message: '',
               data: null,
            },
         };
      },

      recruiterJobDeleteReset: (state) => {
         return {
            ...state,
            deleteData: {
               isLoading: false,
               error: '',
               message: '',
               data: null,
            },
         };
      },

      // recruiterJobCreationReset: () => {
      //    return {
      //       ...initialState,
      //    };
      // },

      recruiterJobAdminReset: (state) => {
         return {
            ...state,
            applicantsList: {
               ...state.applicantsList,
               isLoading: false,
               data: [],
               message: '',
               error: '',
               pagination: {
                  current_page: null,
                  last_page: null,
                  first_page_url: '',
                  last_page_url: '',
                  next_page_url: '',
                  prev_page_url: '',
                  total: 0,
               },
            },
         };
      },
   },
});
