import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { RECENT_SEARCH_ADD_SAGA, RECENT_SEARCH_LIST_SAGA } from 'reduxToolKit/actions';
import { recentSearchSlice, commonSlice } from 'reduxToolKit/reducers';
import { globalSearchReducerTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   recentSearchListRequest,
   recentSearchListSuccess,
   recentSearchListFailed,
   recentSearchAddRequest,
   recentSearchAddSuccess,
   recentSearchAddFailed,
} = recentSearchSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const recentSearchList = function* () {
   yield put(recentSearchListRequest());
   try {
      const url = API.getRecentSearch;
      const checkUser = true;
      const peopleMayYouKnow = true;
      const response: AxiosResponse = yield apiCall({ checkUser, peopleMayYouKnow, ...url });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(recentSearchListSuccess(resObj));
      } else {
         yield put(recentSearchListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const recentSearchAdd = function* (
   payloadAddRecentSearch: PayloadAction<globalSearchReducerTypes.IAddRecentSearchRequestParams>
) {
   yield put(recentSearchAddRequest(payloadAddRecentSearch.payload));
   yield delay(500);
   try {
      const url = API.addRecentSearch;
      const checkUser = true;
      const peopleMayYouKnow = true;
      const data = payloadAddRecentSearch.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, peopleMayYouKnow, data, ...url });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(recentSearchAddSuccess(resObj));
      } else {
         yield put(recentSearchAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeRecentSearch = function* () {
   yield takeEvery(RECENT_SEARCH_LIST_SAGA, recentSearchList);
   yield takeLatest(RECENT_SEARCH_ADD_SAGA, recentSearchAdd);
};
