import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
   PHYSICIAN_JOBS_LIST,
   PHYSICIAN_JOBS_LIST_GET_ONE,
   PHYSICIAN_APPLIED_JOB_OFFER,
   PHYSICIAN_JOB_ADD_RM_FAVOURITE,
} from 'reduxToolKit/actions';
import { commonSlice, physicianJobsSlice, physicianJobFavouriteSlice } from 'reduxToolKit/reducers';
import { physicianJobsReducerTypes, physicianJobsSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getPhysicianJobsListRequest,
   getPhysicianJobsListSuccess,
   getPhysicianJobsListFailed,
} = physicianJobsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const physicianJobsListSaga = function* (payloadState: PayloadAction<physicianJobsSagaTypes.IPhysicianJobsListTypes>) {
   yield put(getPhysicianJobsListRequest(payloadState.payload));
   try {
      const customUrl =
         payloadState.payload?.pageType === 'all' ? API.getPhysicianJobOffersList : API.getPhysicianAppliedJobsList;
      let params: any = {
         page: payloadState.payload?.pageNo,
         is_favorite: payloadState.payload?.is_favorite,
      };
      if (payloadState.payload?.searchTerm) {
         params = {
            ...params,
            search_word: payloadState.payload?.searchTerm,
         };
      }
      const response: AxiosResponse = yield apiCall({ params, ...customUrl });
      if (response.status === 200) {
         const responseData = response.data?.data;
         const returnData = {
            message: response.data?.message || messages.success,
            data: responseData,
            pageType: payloadState.payload?.pageType,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
         };
         yield put(getPhysicianJobsListSuccess(returnData));
      } else {
         yield put(getPhysicianJobsListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   getPhysicianJobsListGetOneRequest,
   getPhysicianJobsListGetOneSuccess,
   getPhysicianJobsListGetOneFailed,
} = physicianJobsSlice.actions;

const physicianJobsListGetOneSaga = function* (
   payloadGetOne: PayloadAction<physicianJobsSagaTypes.IPhysicianJobsListGetOneTypes>
) {
   yield put(getPhysicianJobsListGetOneRequest(payloadGetOne.payload));
   try {
      const params = payloadGetOne.payload;
      const checkUser = true;
      const peopleMayYouKnow = true;
      const apiPath = {
         apiPath: `${API.getPhysicianJobOffersListGetOne.apiPath}${params.jobId}`,
         action: API.getPhysicianJobOffersListGetOne.action,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, peopleMayYouKnow, ...apiPath });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
            data: response.data,
         };
         yield put(getPhysicianJobsListGetOneSuccess(responseData));
      } else {
         yield put(getPhysicianJobsListGetOneFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const { appliedJobOfferRequest, appliedJobOfferSuccess, appliedJobOfferFailed } = physicianJobsSlice.actions;

const physicianAppliedJobOfferSaga = function* (
   payloadGetOne: PayloadAction<physicianJobsReducerTypes.IPhysicianAppliedJobOfferTypes>
) {
   yield put(appliedJobOfferRequest());
   try {
      const data = payloadGetOne.payload;
      const checkUser = false;
      const apiPath = {
         apiPath: `${API.physicianAppliedJobOffer.apiPath}${data.jobId}`,
         action: API.physicianAppliedJobOffer.action,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...apiPath });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
            data: response.data,
         };
         yield put(appliedJobOfferSuccess(responseData));
      } else {
         yield put(appliedJobOfferFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   physicianFavouriteJobsRequest,
   physicianFavouriteJobsSuccess,
   physicianFavouriteJobsFailed,
} = physicianJobFavouriteSlice.actions;
const { physicianJobDetailsFavouriteUpdateAction, physicianJobListFavouriteUpdateAction } = physicianJobsSlice.actions;
const physicianJobAddRmFavourite = function* (
   payloadState: PayloadAction<physicianJobsSagaTypes.IPhysicianJobsAddRmFavourite>
) {
   yield put(physicianFavouriteJobsRequest(payloadState.payload));
   try {
      const data = payloadState.payload;
      if (data?.activeTab === 'job-detail') {
         yield put(physicianJobDetailsFavouriteUpdateAction());
      } else {
         yield put(physicianJobListFavouriteUpdateAction(data));
      }

      const checkUser = false;
      const apiPath = {
         apiPath: `${API.physicianAddJobFavourite.apiPath}${data.jobId}`,
         action: API.physicianAddJobFavourite.action,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, ...apiPath });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(physicianFavouriteJobsSuccess(responseData));
      } else {
         yield put(physicianFavouriteJobsFailed(response.data));

         const countObj = {
            jobId: data?.jobId,
            activeTab: data?.activeTab,
         };
         if (data?.activeTab === 'job-detail') {
            yield put(physicianJobDetailsFavouriteUpdateAction());
         } else {
            yield put(physicianJobListFavouriteUpdateAction(countObj));
         }
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePhysicianJobsSaga = function* () {
   yield takeLatest(PHYSICIAN_JOBS_LIST, physicianJobsListSaga);
   yield takeEvery(PHYSICIAN_JOBS_LIST_GET_ONE, physicianJobsListGetOneSaga);
   yield takeEvery(PHYSICIAN_APPLIED_JOB_OFFER, physicianAppliedJobOfferSaga);
   yield takeEvery(PHYSICIAN_JOB_ADD_RM_FAVOURITE, physicianJobAddRmFavourite);
};
