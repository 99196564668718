import { createSlice } from '@reduxjs/toolkit';
import { oncDriveReducerTypes } from 'types';

const initialState: oncDriveReducerTypes.IOncDriveViewFileReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const oncDriveViewFileSlice = createSlice({
   name: 'oncDriveViewFile',
   initialState: initialState,
   reducers: {
      oncDriveViewFileRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            data: null,
            isLoading: true,
         };
      },
      oncDriveViewFileSuccess: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            ...payload,
         };
      },
      oncDriveViewFileFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error_message,
         };
      },
      oncDriveViewFileReset: () => {
         return initialState;
      },
   },
});
export { oncDriveViewFileSlice };
