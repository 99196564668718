import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   NOTIFICATION_GET_SETTINGS_LIST,
   NOTIFICATION_UPDATE_SETTINGS,
   NOTIFICATION_DELETE_DEVICE_TOKEN,
   NOTIFICATION_GET_ONE,
} from 'reduxToolKit/actions';
import { commonSlice, notificationSettingsListSlice } from 'reduxToolKit/reducers';
import { notificationSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   notificationSettingsListViewRequest,
   notificationSettingsListViewSuccess,
   notificationSettingsListFailed,
   updateNotificationSettingsRequest,
   updateNotificationSettingsFailed,
   updateNotificationSettingsSuccess,
   deleteDeviceTokenRequest,
   deleteDeviceTokenFailed,
   deleteDeviceTokenSuccess,
   notificationGetOneRequest,
   notificationGetOneSuccess,
   notificationGetOneFailed,
} = notificationSettingsListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const notificationSettingsListSaga = function* (
   payloadState: PayloadAction<notificationSagaTypes.INotificationSettingsSagaPayload>
) {
   yield put(notificationSettingsListViewRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getNotificationSettingsList });
      if (response.status === 200) {
         const notificatioSettingsList = {
            data: response?.data?.data,
            message: response.data?.message || messages.success,
         };
         yield put(notificationSettingsListViewSuccess(notificatioSettingsList));
      } else {
         yield put(notificationSettingsListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
const updateNotificationSettingsSaga = function* (
   payloadState: PayloadAction<notificationSagaTypes.INotificationSettingsUpdateSagaPayload>
) {
   yield put(updateNotificationSettingsRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.updateNotificationSettings });
      if (response.status === 200) {
         const notificationSettingsList = {
            message: response.data?.message || messages.success,
         };
         yield put(updateNotificationSettingsSuccess(notificationSettingsList));
      } else {
         yield put(updateNotificationSettingsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const notificationDeleteDeviceTokenSaga = function* (
   payloadState: PayloadAction<notificationSagaTypes.INotificationDeleteDeviceTokenSaga>
) {
   yield put(deleteDeviceTokenRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.deleteDeviceToken });
      if (response.status === 200) {
         const notificationSettingsList = {
            message: response.data?.message || messages.success,
         };
         yield put(deleteDeviceTokenSuccess(notificationSettingsList));
      } else {
         yield put(deleteDeviceTokenFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const notificationGetOne = function* (payloadState: PayloadAction<notificationSagaTypes.INotificationGetOneSaga>) {
   yield put(notificationGetOneRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getOneNotification });
      if (response.status === 200) {
         const notificationGetOne = response.data;
         yield put(notificationGetOneSuccess(notificationGetOne));
      } else {
         yield put(notificationGetOneFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeNotificationSettingsList = function* () {
   yield takeEvery(NOTIFICATION_GET_SETTINGS_LIST, notificationSettingsListSaga);
};
export const takeUpdateNotificationSettings = function* () {
   yield takeEvery(NOTIFICATION_UPDATE_SETTINGS, updateNotificationSettingsSaga);
};
export const takeNotificationDeleteDeviceToken = function* () {
   yield takeEvery(NOTIFICATION_DELETE_DEVICE_TOKEN, notificationDeleteDeviceTokenSaga);
};
export const takeNotificationGetOne = function* () {
   yield takeEvery(NOTIFICATION_GET_ONE, notificationGetOne);
};
