import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MODAL_UPDATE_COMORBIDITIES_SAGA } from 'reduxToolKit/actions';
import { commonSlice, modalUpdateCoMorbiditiesSlice } from 'reduxToolKit/reducers';
import { modalUpdateCoMorbiditiesSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   modalUpdateCoMorbiditiesRequest,
   modalUpdateCoMorbiditiesSuccess,
   modalUpdateCoMorbiditiesFailed,
} = modalUpdateCoMorbiditiesSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const modalUpdateCoMorbiditiesSaga = function* (
   coMorbiditiesPayload: PayloadAction<modalUpdateCoMorbiditiesSagaTypes.IModalCoMorbiditiesUpdateSagaPayload>
) {
   yield put(modalUpdateCoMorbiditiesRequest(coMorbiditiesPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = coMorbiditiesPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.updateCoMorbidities });
      if (response.status === 200) {
         const responseData = response.data;
         const coMorbiditiesList = {
            message: responseData?.message || '',
         };
         yield put(modalUpdateCoMorbiditiesSuccess(coMorbiditiesList));
      } else {
         yield put(modalUpdateCoMorbiditiesFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeUpdateCoMorbidities = function* () {
   yield takeEvery(MODAL_UPDATE_COMORBIDITIES_SAGA, modalUpdateCoMorbiditiesSaga);
};
