import { createAction } from '@reduxjs/toolkit';
import { appointmentScheduleSubmitPageTypes } from 'types';

export const APPOINTMENT_HANDLE = 'APPOINTMENT_HANDLE';
export const appointmentHandle = createAction<appointmentScheduleSubmitPageTypes.IMyPhysicianInput>(APPOINTMENT_HANDLE);

export const APPOINTMENT_ADD = 'APPOINTMENT_ADD';
export const appointmentAddSaga = createAction<appointmentScheduleSubmitPageTypes.IAddAppointmentInput>(
   APPOINTMENT_ADD
);

export const GET_ONE_APPOINTMENT = 'GET_ONE_APPOINTMENT';
export const getOneAppointmentSaga = createAction<appointmentScheduleSubmitPageTypes.IGetOneAppointmentInput>(
   GET_ONE_APPOINTMENT
);

export const UPDATE_APPOINTMENT_STATUS = 'UPDATE_APPOINTMENT_STATUS';
export const updateAppointmentStatusSaga = createAction<appointmentScheduleSubmitPageTypes.IUpdateAppointmentStatusInput>(
   UPDATE_APPOINTMENT_STATUS
);

export const MY_APPOINtMENT_MEETING = 'MY_APPOINtMENT_MEETING';
export const myAppointmentMeetingSaga = createAction<appointmentScheduleSubmitPageTypes.IMyAppointmentMeetingInput>(
   MY_APPOINtMENT_MEETING
);
