import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { FEED_POLL_USERS_LIST } from 'reduxToolKit/actions';
import { commonSlice, feedPollUsersListSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   feedPollUsersListLoading,
   feedPollUsersListRequest,
   feedPollUsersListSuccess,
   feedPollUsersListFailed,
} = feedPollUsersListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const feedPollUsersListSaga = function* (
   feedPollUsersListPayload: PayloadAction<feedSagaTypes.IFeedPollUserListSagaPayload>
) {
   if (feedPollUsersListPayload.payload.search_word !== '') {
      yield put(feedPollUsersListLoading());
      yield delay(500);
   }
   yield put(feedPollUsersListRequest(feedPollUsersListPayload.payload));
   try {
      const params = feedPollUsersListPayload.payload;
      const url = API.feedPollUsersList;
      const peopleMayYouKnow = true;
      const checkUser = true;
      const response: AxiosResponse = yield apiCall({ params, peopleMayYouKnow, checkUser, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const feedPollUsersList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message || messages.success,
         };
         yield put(feedPollUsersListSuccess(feedPollUsersList));
      } else {
         yield put(feedPollUsersListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeFeedPollUsersListRequest = function* () {
   yield takeLatest(FEED_POLL_USERS_LIST, feedPollUsersListSaga);
};
