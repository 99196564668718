import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_CLINIC_PHYSICIAN_INVITE } from 'reduxToolKit/actions';
import { commonSlice, getPhysicianInviteSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getPhysicianInviteLoading,
   getPhysicianInviteViewRequest,
   getPhysicianInviteViewSuccess,
   getPhysicianInviteFailed,
} = getPhysicianInviteSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const clinicPhysicianInvite = function* (cancerTypePayload: any) {
   yield put(getPhysicianInviteLoading());
   yield put(getPhysicianInviteViewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getClinicPhysicianInvite });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            data: responseData.data,
         };
         yield put(getPhysicianInviteViewSuccess(oncDriveList));
      } else {
         yield put(getPhysicianInviteFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeClinicPhysicianInvite = function* () {
   yield takeEvery(GET_CLINIC_PHYSICIAN_INVITE, clinicPhysicianInvite);
};
