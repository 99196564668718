import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { PILL_REMAINDER_ALL_PILLS } from 'reduxToolKit/actions';
import { commonSlice, pillRemainderAllPillsListSlice } from 'reduxToolKit/reducers';
import { pillRemainderSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   pillRemainderAllPillsListRequest,
   pillRemainderAllPillsListSuccess,
   pillRemainderAllPillsListFailed,
} = pillRemainderAllPillsListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const pillRemainderAllPillsListSaga = function* (
   pillRemainderAllPillsListPayload: PayloadAction<pillRemainderSagaTypes.IPillRemainderAllPillsParams>
) {
   yield put(pillRemainderAllPillsListRequest(pillRemainderAllPillsListPayload.payload));
   if (pillRemainderAllPillsListPayload.payload.search_word) {
      yield delay(500);
   }
   try {
      const params = pillRemainderAllPillsListPayload.payload;
      const url = API.pillRemainderAllPillsList;
      const response: AxiosResponse = yield apiCall({ params, checkUser: false, ...url });
      if (response.status === 200) {
         const responseData = response.data.data.data;
         const pillRemainderAllPillsList = {
            list: responseData,
            message: response.data?.message,
         };
         yield put(pillRemainderAllPillsListSuccess(pillRemainderAllPillsList));
      } else {
         yield put(pillRemainderAllPillsListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePillRemainderAllPillsListRequest = function* () {
   yield takeLatest(PILL_REMAINDER_ALL_PILLS, pillRemainderAllPillsListSaga);
};
