import React, { useCallback, useEffect, useState } from 'react';

import OrganizationNameIcon from 'assets/fill-profile/organization_name_icon.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import { useDispatch, useSelector } from 'react-redux';
import { addOrgNameAction } from 'reduxToolKit/actions';
import { orgNameSlice } from 'reduxToolKit/reducers';
import { myProfileSagaTypes, storeTypes } from 'types';
import { DONE, NEXT, SUBMIT } from 'utils/constants/profileConstants';

const OrganizationNameSection = (props: any) => {
   const dispatch = useDispatch();
   const [orgName, setOrgName] = useState<string>('');
   const [errorMessage, setErrorMessage] = useState<string>('');

   const { message: addOrgNameMessage, error: addOrgNameError } = useSelector(
      (state: storeTypes.IStore) => state.orgName.addOrgName
   );
   const { addOrgNameReset } = orgNameSlice.actions;

   const changeOrgName = useCallback((e: any) => {
      setErrorMessage('');
      setOrgName(e.target.value);
   }, []);

   useEffect(() => {
      if (addOrgNameMessage && props?.isFromOrgName) {
         const getAlert = () => (
            <InfoTimerAlert
               success
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={addOrgNameMessage}
               onConfirm={() => hideAlert('addOrgNameMessage')}
            />
         );
         props?.setAlert(getAlert);
         props?.setIsLoader(false);
         props.currentModalClose();
      } else if (addOrgNameMessage) {
         props.setIsLoader(false);
         gotoNavigation();
      }
   }, [addOrgNameMessage]);

   const clickNext = useCallback(() => {
      if (!orgName) {
         setErrorMessage('Name cannot be empty');
         return;
      }
      props?.setIsLoader(true);
      const data: myProfileSagaTypes.IAddOrgName = {
         name: orgName,
      };
      dispatch(addOrgNameAction(data));
   }, [orgName]);

   const hideAlert = (type: string) => {
      if (type === 'addOrgNameSuccess') {
         if (props?.isFromRace) {
            props?.closeModelMyProfile();
            props.getUserData();
         }
         dispatch(addOrgNameReset());
         props?.setIsLoader(false);
      } else if (type === 'addOrgNameError') {
         dispatch(addOrgNameReset());
         props?.setIsLoader(false);
      }
   };

   const gotoNavigation = useCallback(() => {
      props.navigation(props.nexSectionOrg);
   }, []);

   useEffect(() => {
      if (!props?.isOrgNameValid) {
         props.setIsLoader(false);
         gotoNavigation();
      }
   }, []);

   return (
      <div>
         {addOrgNameError ? (
            <InfoTimerAlert
               error
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={addOrgNameError}
               onConfirm={() => hideAlert('addOrgNameError')}
            />
         ) : null}
         <div className="col-12">
            <img className="common-race-icon race-icon mt--2" src={OrganizationNameIcon} alt="icon" />
         </div>
         <div className="col-12">
            <p className="common-race-title-text race-title-text">Organization name</p>
         </div>
         <div className="col-12">
            <p className="common-race-paragraph-text organization-paragraph-text  p-2">
               Welcome to OncoPower! Type in your name as you want it to appear for the community.
            </p>
         </div>
         <div className="col-12 mb-3">
            <input
               className="address-input"
               autoFocus
               placeholder="Enter organization name"
               value={orgName}
               onChange={changeOrgName}
               autoComplete="off"
               maxLength={30}
            />
         </div>
         {errorMessage ? <div className="error-msg ml-4 mt-3 mr-4"> {errorMessage}</div> : null}
         <div className="button">
            <button
               type="submit"
               className="button-start"
               id="orgNameSectionNext"
               onClick={() => {
                  clickNext();
               }}
            >
               {props?.currentStateArray &&
               props?.currentStateArray[props?.currentStateArray?.length - 1] === props?.currentState
                  ? DONE
                  : props?.isFromRace
                  ? SUBMIT
                  : NEXT}
            </button>
         </div>
      </div>
   );
};

export default OrganizationNameSection;
