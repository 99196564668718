import Constant from 'config/Constant';
import dayjs, { OpUnitType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import {
   CONVERT_DATE_AND_TIME,
   DIAGNOSIS_DATE_AND_TIME,
   TIME_ONLY_ADD_DATE,
   TIME_SINCE_A_DAY,
   TIME_SINCE_A_HOUR,
   TIME_SINCE_A_MIN,
   TIME_SINCE_A_YEAR,
} from 'utils/constants/dateConstants';

const DATE_FORMAT = Constant.DATE_FORMAT;
dayjs.extend(utc);

export const convertYearMonths = (duration: number, type: string) => {
   let Inc = '';
   if (duration > 1) {
      Inc = 's';
   }
   if (type === 'y') {
      return 'Year' + Inc;
   } else if (type === 'm') {
      return 'Month' + Inc;
   }
};

export const getDay = (dayNumber: number, fullDay: boolean) => {
   let day = null;
   if (dayNumber === 0) {
      day = fullDay ? 'Sunday' : 'Sun';
   } else if (dayNumber === 1) {
      day = fullDay ? 'Monday' : 'Mon';
   } else if (dayNumber === 2) {
      day = fullDay ? 'Tuesday' : 'Tue';
   } else if (dayNumber === 3) {
      day = fullDay ? 'Wednesday' : 'Wed';
   } else if (dayNumber === 4) {
      day = fullDay ? 'Thursday' : 'Thu';
   } else if (dayNumber === 5) {
      day = fullDay ? 'Friday' : 'Fri';
   } else if (dayNumber === 6) {
      day = fullDay ? 'Saturday' : 'Sat';
   }
   return day;
};

const isValidDate = (date: Date | string | number | null | undefined) => {
   if (date !== null && date !== undefined) {
      return dayjs(date).isValid();
   }
};

export const convertUTCtoLocal = (date: Date | string, dateFormat?: string) => {
   if (!isValidDate(date)) {
      return 'invalid-date';
   }
   return dayjs
      .utc(dayjs(date))
      .local()
      .format(dateFormat || DATE_FORMAT);
};

export const convertUTCtoLocalAndAddDays = (date: Date | string, count: number, dateFormat?: string) => {
   if (!isValidDate(date)) {
      return 'invalid-date';
   }
   return dayjs
      .utc(dayjs(date))
      .local()
      .add(count, 'days')
      .format(dateFormat || DATE_FORMAT);
};

export const getDatesInRange = (startDate: Date, endDate: Date) => {
   const date = new Date(startDate.getTime());
   date.setDate(date.getDate() + 1);
   const dates = [];

   while (date < endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
   }
   return dates;
};

export const getDiffBetweenDaytoToday = (date: Date | string) => {
   const returnObj = {
      count: 0,
      dayType: 'days',
   };
   if (!date) {
      returnObj.dayType = 'error';
      return returnObj;
   }

   const convertToUtc = convertUTCtoLocal(date);
   const lastSubscribedDate = dayjs(convertToUtc);
   const today = dayjs();
   const differenceOfDays = lastSubscribedDate.diff(today, 'day');
   returnObj.count = differenceOfDays;
   returnObj.dayType = differenceOfDays === 1 ? 'day' : 'days';
   if (differenceOfDays === 0) {
      const differenceOfHours = lastSubscribedDate.diff(today, 'hour');
      returnObj.count = differenceOfHours;
      returnObj.dayType = differenceOfHours === 1 ? 'hour' : 'hours';
      return returnObj;
   }
   return returnObj;
};

export const addDaysToToday = (periodType: string, daysCount: number, dateFormat: string, limitDays?: number) => {
   let totalDaysCount = daysCount;
   if (periodType === 'month' && limitDays) {
      totalDaysCount = daysCount * limitDays;
   }
   const convertedDate = dayjs().add(totalDaysCount, 'days');
   return convertedDate.format(dateFormat);
};

export const changeDateFormatToISO = (date: Date) => {
   if (!isValidDate(date)) {
      return 'invalid-date';
   }
   return dayjs(date).toISOString();
};

// get date from day count
export const getParticularDate = (dayCount: number) => {
   const date = new Date();

   if (date && dayCount > 0) {
      const exactDate = dayjs().add(dayCount, 'day').utc().format('YYYY-MM-DD') + 'T23:59:59Z';
      return exactDate;
   } else {
      const exactDate = dayjs().utc().format('YYYY-MM-DD') + 'T23:59:59Z';
      return exactDate;
   }
};

// get date from day count
export const getParticularDateLocal = (dayCount: number) => {
   const date = new Date();

   if (date && dayCount > 0) {
      const exactDate = dayjs().add(dayCount, 'day').local().format('YYYY-MM-DD') + 'T23:59:59Z';
      return exactDate;
   } else {
      const exactDate = dayjs().local().format('YYYY-MM-DD') + 'T23:59:59Z';
      return exactDate;
   }
};

// get date from day count
export const getTwoDateDifference = (createDate: any, expireDate: any) => {
   const date1 = dayjs(createDate);
   const date2 = dayjs(expireDate);
   const diff = date2.diff(date1, 'day');
   const days = Math.floor(diff);
   return days;
};

export const changeDateFormatToUTC = (date: Date) => {
   if (date) {
      return dayjs(date).utc().format('YYYY-MM-DD') + 'T23:59:59Z';
   }
};

/*Change date iso to ist*/
export const changeDateFormatToIST = (date: Date | string) => {
   if (!isValidDate(date)) {
      return 'invalid-date';
   }

   return dayjs(date).utc().local().format('MM/DD/YYYY');
};

export const changeDateToDB = (date: Date | string) => {
   if (!isValidDate(date)) {
      return 'invalid-date';
   }
   return dayjs(dayjs(date)).format('YYYY-MM-DD') + 'T00:00:00Z';
};

export const changeDateToDBLastDateUTC = (date: Date | string) => {
   if (!isValidDate(date)) {
      return 'invalid-date';
   }
   return dayjs(dayjs(date)).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
};

export const changeDateFormat = (date: Date | string) => {
   if (!isValidDate(date)) {
      return 'invalid-date';
   }
   return dayjs(date);
};

export const changeDateTime = (date: Date | string) => {
   if (!isValidDate(date)) {
      return 'invalid-date';
   }
   return dayjs(date).format('YYYY-MM-DD') + 'T00:00:00Z';
};

export const convertStartEndDate = (date: Date | string | undefined) => {
   if (!date) {
      return '';
   }
   return dayjs(date).format('YYYY-MM-DD');
};

export const convertStartOfCheckInsDate = (date: Date | string | undefined) => {
   if (!date) {
      return '';
   }
   return dayjs(date).startOf('day').utc().format('YYYY-MM-DD HH:mm').toString() + ':00';
};

export const convertEndOfCheckInsDate = (date: Date | string | undefined) => {
   if (!date) {
      return '';
   }
   return dayjs(date).endOf('day').utc().format('YYYY-MM-DD HH:mm').toString() + ':59';
};

export const convertTime = (dateTime: Date | string | undefined, dateFormat: string | undefined = 'hh:mm A') => {
   dayjs.extend(customParseFormat);
   return dayjs(dateTime, ['HH.mm']).format(dateFormat);
};

export const convertTimeFormat = (timeString: any, originalFormat: string, convertFormat?: string | undefined) => {
   dayjs.extend(customParseFormat);
   if (!convertFormat) {
      return dayjs(timeString, originalFormat);
   }
   return dayjs(timeString, originalFormat).format(convertFormat);
};

export const timeSubtract = (timeString: any, duration: number | string, durationType: OpUnitType | undefined) => {
   if (!isValidDate(timeString)) {
      return 'invalid-date';
   }
   dayjs.extend(customParseFormat);
   if (typeof duration === 'string') {
      duration = parseInt(duration);
   }
   const subtractTimeString = dayjs(timeString).subtract(duration, durationType).format();
   return convertTime(subtractTimeString, 'HH:mm');
};

export const getTimeSlots = (startConvertTime: string, endConvertTime: string, duration: number) => {
   dayjs.extend(customParseFormat);
   let startTime = dayjs(startConvertTime, 'HH:mm');
   const endTime = dayjs(endConvertTime, 'HH:mm');
   const timeStops = [];
   while (startTime <= endTime) {
      timeStops.push({
         is_break: false,
         start_time: dayjs(startTime).subtract(duration, 'minutes').format('HH:mm'),
         end_time: dayjs(startTime).format('HH:mm'),
      });
      startTime = startTime.add(duration, 'minutes');
   }
   timeStops.shift();

   return timeStops;
};

export const changeDateFormatToLocal = (date: Date | string | number, dateFormat?: string) => {
   if (!isValidDate(date)) {
      return 'invalid-date';
   }
   const dateISO = new Date(date).toISOString();
   return dayjs(dateISO).format(dateFormat || 'YYYY-MM-DD');
};

export const getCurrentDateWithMomentStartOf = () => {
   return dayjs().startOf('day').toDate();
};

export const getCurrentDateWithMomentEndOf = () => {
   return dayjs().endOf('day').toDate();
};

export const getToTimeStringStartOf = () => {
   return dayjs().startOf('day').toDate().toTimeString();
};

export const getToTimeStringEndOf = () => {
   return dayjs().endOf('day').toDate().toTimeString();
};

export const get24Hrs = () => {
   return dayjs(new Date().setHours(new Date().getHours())).startOf('day').toDate();
};

export const get48Hrs = () => {
   return dayjs(new Date().setHours(new Date().getHours() - 24))
      .startOf('day')
      .toDate();
};

export const getLastMonth = () => {
   return dayjs(new Date().setMonth(new Date().getMonth() - 1))
      .startOf('day')
      .toDate();
};

export const getLastWeek = () => {
   return dayjs(new Date().setDate(new Date().getDate() - 6))
      .startOf('day')
      .toDate();
};

export const changeTimeFormat = (time: any) => {
   dayjs.extend(customParseFormat);
   return dayjs(time, 'HH:mm:ss').format('hh:mm A');
};

export const checkPastTime = (dateTime: any, appointmentStartTime: any) => {
   const date = dayjs.utc(dayjs(dateTime)).local().format('DD/MM/YYYY');
   const nowTime = dayjs.utc(dayjs(new Date())).local().format('DD/MM/YYYY HH:mm');
   const convertStartTime = date + ' ' + dayjs(appointmentStartTime, ['HH.mm']).format('HH:mm');

   return nowTime > convertStartTime;
};

export const checkPastTime1 = (dateTime: Date | string) => {
   if (dateTime) {
      const startTime = dayjs.utc(dayjs(dateTime)).local();
      const currentTime = dayjs.utc(dayjs(new Date())).local();

      if (startTime.isBefore(currentTime)) {
         return true;
      } else {
         return false;
      }
   }
};

export const checkDateAndCurrentDateEquals = (dateTime: Date | string, currentDateTime: Date | string) => {
   if (dateTime) {
      const startTime = dayjs.utc(dayjs(dateTime)).local();
      const currentTime = dayjs.utc(dayjs(currentDateTime)).local();

      if (startTime.isSame(currentTime)) {
         return true;
      } else {
         return false;
      }
   }
};

export const convertDateAndTime = (dateTime: Date | string) => {
   return dayjs.utc(dayjs(dateTime)).local().format(CONVERT_DATE_AND_TIME);
};

export const convertDate = (time: string) => {
   return new Date(time);
};

export const convertDiagnosisDateToUTCDateTime = (date: Date) => {
   return dayjs(date).utc().format(DIAGNOSIS_DATE_AND_TIME) + 'Z';
};

export const convertDiagnosisToDateTime = (date: Date) => {
   return dayjs(date).format(DIAGNOSIS_DATE_AND_TIME) + 'Z';
};

export const convertOnlyUTCTime = (date: Date) => {
   return dayjs(date).utc().format('HH:mm') + ':00';
};

export const getUnixMilliSeconds = () => {
   return dayjs.utc(new Date()).unix();
};

export const getUTCToTimeAppendDate = (time: string) => {
   const localFormat = dayjs.utc(time).local().format('HH:mm');
   return new Date('2014-08-18T' + localFormat);
};

export const changeDateFormatToUser = (date: Date | string | null | undefined) => {
   if (date !== null && date !== undefined) {
      return dayjs.utc(dayjs(date)).local().format(DATE_FORMAT);
   }
};

export const changeDateFormatToUserToLocal = (date: Date | string | null | undefined) => {
   if (date !== null && date !== undefined) {
      return dayjs.utc(date).local().format(DATE_FORMAT);
   }
};

export const changeDateFormatToLocalNoFormat = (date: Date | string | null | undefined) => {
   if (date !== null && date !== undefined) {
      return dayjs.utc(date).local().format();
   }
};

export const changeDateAndTimeFormatToLocal = (date: Date | string) => {
   if (date !== null) {
      return dayjs.utc(dayjs(date)).local().format('MM/DD/YYYY ( h:mm A ) ');
   }
};

export const changeDateFormatToMonth = (date: Date | string) => {
   if (date !== null) {
      const dateISO = new Date(date).toString();
      return dayjs(dateISO).format('MMMM');
   }
};

export const changeDateFormatTohhmm = (date: Date | string | undefined, timeFormat: string | undefined = 'hh:mm a') => {
   if (date) {
      const dateISO = new Date(date).toString();
      return dayjs(dateISO).format(timeFormat);
   }
};

export const getAgeHelper = (date: any) => {
   if (date) {
      const years = dayjs().diff(date, 'years', true);
      const age = Math.round(years * 10) / 10;
      return age.toFixed(0) + ' Years';
   } else {
      return '';
   }
};

export const getAgeNumberHelper = (date: any) => {
   if (date) {
      const years = dayjs().diff(date, 'years', true);
      const age = Math.round(years * 10) / 10;
      return age.toFixed(0);
   } else {
      return '0';
   }
};

export const getAgeByYear = (date: any) => {
   if (date) {
      const years = dayjs().diff(date, 'years', true);
      const age = Math.round(years * 10) / 10;
      return age.toFixed(0);
   } else {
      return null;
   }
};

export const getDays = (date: any) => {
   if (date) {
      const years = dayjs().diff(date, 'days', true);
      if (Math.round(years * 10) / 10 < 1) {
         return 'Today';
      } else if (Math.round(years * 10) / 10 < 2) {
         return 'Yesterday';
      } else {
         return changeDateFormatToUser(date);
      }
   } else {
      return '';
   }
};

export const getTimeAgo = (date: any) => {
   dayjs.extend(relativeTime);
   return dayjs(date).fromNow();
};

export const convertMeetingTime = (dateTime: any, startTime: any) => {
   const date = dayjs.utc(dayjs(dateTime)).format(DATE_FORMAT);
   return date + ' ' + dayjs(startTime, ['HH.mm']).format('( hh:mm A )');
};

export const meetingOver = (dateTime: any, appointmentStartTime: any) => {
   dayjs.extend(customParseFormat);
   const dateISO = new Date(dateTime).toString();
   const date = dayjs(dateISO).format('DD/MM/YYYY');

   const convertTime = date + ' ' + dayjs(appointmentStartTime, ['HH.mm']).format('hh:mm');
   const nowTime = dayjs(new Date()).format('DD/MM/YYYY hh:mm');
   const startTime = dayjs(convertTime, 'DD/MM/YYYY hh:mm');
   const endTime = dayjs(nowTime, 'DD/MM/YYYY hh:mm');

   return startTime <= endTime;
};

export const localDateConversion = (date: Date, format: string) => {
   dayjs.extend(customParseFormat);
   if (!isValidDate(date)) return null;
   return dayjs(date).format(format);
};

export const acceptStatus = (dateTime: any, appointmentStartTime: any, appointmentEndTime: any) => {
   dayjs.extend(customParseFormat);
   const dateISO = new Date(dateTime).toString();
   const date = dayjs(dateISO).format('DD/MM/YYYY');
   const nowTime = dayjs(new Date()).format('DD/MM/YYYY HH:mm');
   const convertStartTime = date + ' ' + dayjs(appointmentStartTime, ['HH.mm']).format('HH:mm');
   const convertEndTime = date + ' ' + dayjs(appointmentEndTime, ['HH.mm']).format('HH:mm');
   const currentTime = dayjs(nowTime, 'DD/MM/YYYY HH:mm');
   const startTime = dayjs(convertStartTime, 'DD/MM/YYYY HH:mm');
   const endTime = dayjs(convertEndTime, 'DD/MM/YYYY HH:mm');

   if (currentTime < startTime) {
      return 'Meeting yet to be started';
   } else if (startTime <= currentTime && endTime > currentTime) {
      return 'Meeting end at ' + dayjs(appointmentEndTime, ['HH.mm']).format('hh:mm A');
   } else if (endTime <= currentTime) {
      return 'Meeting completed';
   }
};

export const meetingStart = (dateTime: any, appointmentStartTime: any) => {
   dayjs.extend(customParseFormat);
   const dateISO = new Date(dateTime).toString();
   const date = dayjs(dateISO).format('DD/MM/YYYY');
   const nowTime = dayjs(new Date()).format('DD/MM/YYYY HH:mm');
   const convertStartTime = date + ' ' + dayjs(appointmentStartTime, ['HH.mm']).format('HH:mm');
   const currentTime = dayjs(nowTime, 'DD/MM/YYYY HH:mm');
   const startTime = dayjs(convertStartTime, 'DD/MM/YYYY HH:mm');
   return currentTime < startTime;
};

export const checkAppointmentEndTime = (dateTime: any, appointmentEndTime: any) => {
   dayjs.extend(customParseFormat);
   const dateISO = new Date(dateTime).toString();
   const date = dayjs(dateISO).format('DD/MM/YYYY');
   const nowTime = dayjs(new Date()).format('DD/MM/YYYY HH:mm');
   const convertStartTime = date + ' ' + dayjs(appointmentEndTime, ['HH.mm']).format('HH:mm');
   const currentTime = dayjs(nowTime, 'DD/MM/YYYY HH:mm');
   const startTime = dayjs(convertStartTime, 'DD/MM/YYYY HH:mm');

   return currentTime > startTime;
};

export const getUTCTime = (time: string) => {
   dayjs.extend(customParseFormat);
   return dayjs(time, 'HH:mm').format('hh:mm a');
};

export const getStartEndTime = (time: string) => {
   dayjs.extend(customParseFormat);
   return dayjs(time, ['HH:mm']).format('HH:mm');
};

export const getUTCTimeAppendDate = (time: string) => {
   const localFormat = dayjs.utc(dayjs(time, 'HH:mm')).format('HH:mm');
   return new Date('2014-08-18T' + localFormat);
};

export const getUTCConvertTime = (time: string) => {
   return dayjs.utc(dayjs(time)).format('HH:mm');
};

export const getLocalTime = (time: string) => {
   return dayjs.utc(time).local().format('HH:mm');
};

export const getLocalTime12Hrs = (time: string) => {
   return dayjs.utc(time).local().format('hh:mm A');
};

export const isBeforeFilterDate = (date: Date | string | undefined) => {
   return dayjs(date).isBefore(new Date());
};

export const isBefore100YearsFilterDate = (date: any) => {
   dayjs.extend(isSameOrBefore);
   const isSameOrBeforeBoolean = dayjs(date).isSameOrBefore(getSubtract100Years());
   const isAfterBoolean = isAfterFilterDate(date);
   return isSameOrBeforeBoolean || isAfterBoolean ? true : false;
};

export const isAfterFilterDate = (date: Date | string | undefined) => {
   return dayjs(date).isAfter(new Date());
};

export const getYear = () => {
   return dayjs().year();
};

export const getSubtract30Days = () => {
   return new Date(dayjs().subtract(30, 'days').format());
};

export const getSubtract30DaysStartOf = () => {
   return dayjs().subtract(30, 'days').startOf('day').toDate();
};

export const getSubtractDaysStartOf = (noOfDays: number) => {
   return dayjs().subtract(noOfDays, 'days').startOf('day').toDate();
};

export const getSubtract365Days = () => {
   return new Date(dayjs().subtract(365, 'days').format());
};

export const getSubtract100Years = () => {
   return new Date(dayjs().subtract(100, 'years').format());
};

export const getWorkingHourDay = (clinicWorkingHour: any) => {
   dayjs.extend(isoWeek);

   const currentDayNumber = dayjs().isoWeekday();
   const index = clinicWorkingHour.findIndex((x: any) => parseInt(x.day) === currentDayNumber);
   if (index !== -1) {
      const localStartTime = TIME_ONLY_ADD_DATE + clinicWorkingHour[index].start_time;
      const localEndTime = TIME_ONLY_ADD_DATE + clinicWorkingHour[index].end_time;
      return (
         dayjs.utc(localStartTime).local().format('hh:mm a') +
         ' to ' +
         dayjs.utc(localEndTime).local().format('hh:mm a')
      );
   }
   return null;
};

export const getUTCtoNormalTime = (time: string) => {
   const localStartTime = TIME_ONLY_ADD_DATE + time;
   return dayjs.utc(localStartTime).local().format('hh:mm a');
};

export const isBeforeYesterday = (date: any) => {
   return dayjs(date).isBefore(dayjs().subtract(1, 'days'));
};

export const isBeforeSameDate = (date: any) => {
   dayjs.extend(isSameOrBefore);
   return dayjs(date).isSameOrBefore(new Date());
};

export const convertStartEndDateToUTC = (date: any, dateFormat?: string) => {
   if (!date) {
      return '';
   }
   return dayjs(date)
      .utc()
      .format(dateFormat || 'YYYY-MM-DD');
};

export const convertStartEndDateToUTCTwoMonths = (date: any) => {
   if (!date) {
      return '';
   }
   return dayjs(date).add(2, 'months').utc().format('YYYY-MM-DD');
};

export const convertStartEndDateToYearMonthUTC = (date: any) => {
   if (!date) {
      return '';
   }
   return dayjs(date).utc().format('YYYY-MM');
};

export const convertDateToTimezone = (date: any) => {
   if (!date) {
      return '';
   }
   return dayjs(date).startOf('day').utc().format('HH:mm').toString() + ':00';
};

export const convertDateToTimezoneDate = (date: any) => {
   if (!date) {
      return '';
   }
   return dayjs(date).startOf('day').utc().format('YYYY-MM-DD').toString();
};

export const convertOnly12HrsTime = (dateTime: any) => {
   return dayjs.utc(dateTime).local().format('hh:mm');
};

export const convertOnlyAMPMTime = (dateTime: any) => {
   return dayjs.utc(dateTime).local().format('A');
};

export const changeNormalDateFormat = (date: Date | string | number) => {
   return new Date(date);
};

export const getDate = (year: number, month: number, date?: number | undefined) => {
   return new Date(year, month, date).getDate();
};

export const getDateOfNormalDate = (year: number, month: number, date?: number | undefined) => {
   return new Date(year, month, date);
};

export const getCurrentDate = () => {
   return new Date();
};

export const getToTimeString = () => {
   return new Date().toTimeString();
};

export const convertToDate = (date: any) => {
   return new Date(date);
};

export const getCurrentMonth = () => {
   return dayjs().toDate();
};

export const getMonthNameAndYear = (date: any) => {
   return dayjs(date).format('MMMM YYYY');
};

export const getMonthName = (date: any) => {
   return dayjs(date).format('dddd');
};

export const getNextMonthDate = (date: any) => {
   return dayjs(date).utc().format('YYYY-MM');
};

export const getPreMonthDate = (date: any) => {
   return dayjs(date).utc().format('YYYY-MM');
};

export const getNextMonth = (date: any) => {
   return dayjs(date).add(1, 'months');
};

export const getPrevMonth = (date: any) => {
   return dayjs(date).subtract(1, 'months');
};

export const compareTwoDate = (fromDate: any, toDate: any, startDateFormat: string, endDateFormat: string) => {
   const start = dayjs(fromDate, startDateFormat);
   const end = dayjs(toDate, endDateFormat);

   if (start <= end) {
      return true;
   } else {
      return false;
   }
};

export const changeOneDateFormatToAnother = (date: any) => {
   const dateFormat = dayjs(date).format(DATE_FORMAT);
   return dateFormat;
};

export const changeOneDateFormatToAnotherApi = (date: any) => {
   const dateFormat = dayjs(date).format(CONVERT_DATE_AND_TIME);
   return dateFormat;
};

export const isDiscontinueDate = (dateTime: string) => {
   if (dateTime !== null) {
      const dateTime1 = new Date().getTime();
      const dateTime2 = new Date(dateTime).getTime();
      const diff = dateTime2 - dateTime1;
      if (diff > 0) {
         return false;
      } else {
         return true;
      }
   } else {
      return false;
   }
};

export const convertOnlyTime = (date: Date) => {
   return dayjs(date).format('HH:mm:ss');
};

export const getCurrentDayNumber = () => {
   return dayjs().day();
};

export const isRevokedDate = (dateTime: string) => {
   if (dateTime !== null) {
      const dateTime1 = new Date().getTime();
      const dateTime2 = new Date(dateTime).getTime();
      const diff = dateTime2 - dateTime1;
      if (diff < 0) {
         return false;
      } else {
         return true;
      }
   } else {
      return false;
   }
};

export const getClosingDay = (date: string) => {
   const today = changeDateFormatToUser(new Date());
   const givenDate = changeDateFormatToUser(date);
   const same = today === givenDate;
   if (same) {
      return ' today';
   } else {
      const tomorrowInc = new Date();
      tomorrowInc.setDate(tomorrowInc.getDate() + 1);
      const tomorrow = changeDateFormatToUser(tomorrowInc);
      const same1 = tomorrow === givenDate;
      if (same1) {
         return ' tomorrow';
      } else {
         return ' on ' + changeDateFormatToUser(date);
      }
   }
};

export const getDayText = (dayCount: number) => {
   let dayText = dayCount + ' day';
   if (dayCount > 1) {
      dayText = dayText + 's';
   }
   return dayText;
};

export const convertSecondsToMinutes = (time: number) => {
   // Hours, minutes and seconds
   const hrs = ~~(time / 3600);
   const mins = ~~((time % 3600) / 60);
   const secs = ~~time % 60;
   let convertedTime = '';
   if (hrs > 0) {
      convertedTime += (mins < 10 ? '0' : '') + hrs + ':';
   }
   convertedTime += (mins < 10 ? '0' : '') + mins + ':' + (secs < 10 ? '0' : '');
   convertedTime += '' + secs;
   return convertedTime;
};

export const getMinDate = () => {
   return new Date(new Date().getTime() + 86400000 * 90);
};

export const previousTimeDisableFromNow = (time: string, date: Date) => {
   const today = getCurrentDate();
   dayjs.extend(isSameOrAfter);
   if (today >= date) {
      const currentTime = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      const now = dayjs();
      const scheduleTime = dayjs(time, 'HH:mm:ss');
      const todayTime = dayjs(currentTime, 'HH:mm:ss');
      const difference = now.isSameOrAfter(todayTime) <= now.isSameOrAfter(scheduleTime) ? true : false;
      return difference;
   }
   return false;
};

export const pollEndDate = (date: string | null) => {
   if (date) {
      const localFormat = dayjs.utc(date).local().format();
      if (new Date() <= new Date(localFormat)) {
         return timeSince(new Date(localFormat));
      }
      return {
         isExpired: true,
         value: 'Poll Expired',
      };
   }
   return {
      isExpired: false,
      value: null,
   };
};

export const timeSince = (date: any) => {
   let seconds = Math.floor((Date.now() - date) / 1000);
   let unit = 'second';
   if (seconds < 0) {
      seconds = -seconds;
   }
   let value = seconds;
   if (seconds >= TIME_SINCE_A_YEAR) {
      value = Math.floor(seconds / TIME_SINCE_A_YEAR);
      unit = 'year';
   } else if (seconds >= TIME_SINCE_A_DAY) {
      value = Math.floor(seconds / TIME_SINCE_A_DAY);
      unit = 'day';
   } else if (seconds >= TIME_SINCE_A_HOUR) {
      value = Math.floor(seconds / TIME_SINCE_A_HOUR);
      unit = 'hour';
   } else if (seconds >= TIME_SINCE_A_MIN) {
      value = Math.floor(seconds / TIME_SINCE_A_MIN);
      unit = 'minute';
   }
   if (value !== 1) unit += 's';

   return {
      isExpired: false,
      value: `Poll ends in ${value} ${unit}`,
   };
};
