export const INFO_ALERT_ERROR_TITLE = 'Sorry try again';
export const DESCRIPTION_ERROR_MSG = 'Description field is required';
export const TITLE_ERROR_MSG = 'Title field is required';
export const CLINIC_NAME_ERROR_MSG = 'Clinic name field is required';
export const SPECIALIZATIONS_ERROR_MSG = 'Specializations field is required';
export const JOB_TYPE_ERROR_MSG = 'Job type field is required';
export const EMPLOYMENT_TYPE_ERROR_MSG = 'Employment type field is required';
export const LOCATION_ERROR_MSG = 'Location field is required';
export const DURATION_ERROR_MSG = 'Contract duration field is required';

export const messages = {
   common: {
      emptyState: {
         noData: 'No data found',
         errorMessage: 'Sorry try again',
      },
   },
   askADoc: {
      emptyState: {
         panelRequests: 'There are no requests from patients yet',
      },
   },
   linkedIn: {
      success: {
         title:
            'Thanks for signing up with OncoPower. Your account is pending to be verified by admin. Kindly contact OncoPower support if not verified within 24 hours.',
      },
   },
   admin: {
      emptyState: {
         clinicWorkingHours: 'Add your clinic working hours. (Need "Add Hours" button below content)',
         clinicVideos: 'Please add your clinic videos. (Need "Add video" button below content)',
         clinicMembers: `You don't have any physicians yet. Start adding them. (Need "Invite Physicians" button below content)`,
         invitedPhysicians:
            'This list is empty. Start inviting your clinic physicians. (Need "Invite Physicians" button below content)',
         clinicPatients: `You don't have any patients yet. Start inviting them. (Need "Invite Patients" button below content)`,
         invitedMembers:
            'This list is empty. Start inviting your clinic patients. (Need "Invite Patients" button below content)',
         clinicStaff: `You don't have any clinical staff yet. Start inviting them. (Need "Invite Nurse" button below content)`,
         practiceAdministration: `You don't have any practice administrators yet. Start inviting them. (Need "Invite PA" button below content)`,
      },
   },
   clinicPendingReq: {
      emptyState: {
         result: 'No request found',
      },
   },
   thermometer: {
      emptyState: {
         selectedPeriod: 'No data available for selected period',
      },
   },
   assosiatedPhysician: {
      emptyState: {
         noPhysician: 'No physicians found',
      },
   },
   upComingMeeting: {
      emptyState: {
         noMeetings: 'No upcoming appointments',
      },
   },
   chat: {
      emptyState: {
         emptyChat: 'No chats found',
      },
   },
   viewSummary: {
      emptyState: {
         noPatientLink: 'No link for patient found',
         noPhysicianLink: 'No links for treating physician found',
      },
   },
   recentChatHistory: {
      emptyState: {
         conversations: 'No conversations found',
      },
   },
   editPrescription: {
      emptyState: {
         noMarbidity: 'No morbidity found',
         noDrugs: 'No drugs',
         reportSymptom: 'No report symptoms',
         reportSymptomEvery: 'No report symptoms every',
         videoUrl: 'Not available',
         devices: 'no devices',
         reportDevice: 'No report device readings every',
         medicalNecessity: 'No medical necessity',
         videoLink: 'No video links',
         noClinic: 'No clinic videos',
      },
   },
   familyHistory: {
      emptyState: {
         noFamily: 'No family history found',
      },
   },
   socialHistory: {
      emptyState: {
         socialHistory: 'No family history found',
      },
   },
   addCommentErrorMessage: 'Comments cannot be empty or media cannot be empty',
   addCommentTimeSpentErrorMessage: 'Time spent cannot be empty',

   success: 'success',
   failed: 'error',
   errorMessage: 'Sorry try again',
   error: {
      error: 'Sorry try again',
   },
   internal_error: 'Something went wrong. Try again.',
   urlValidationError: 'Invalid URL Detection',
};
