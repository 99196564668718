import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { networkReducerTypes, networkSagaTypes } from 'types';

const initialState: networkReducerTypes.ISearchNetworkAllUsersReducersPayload = {
   data: null,
   message: '',
   error: '',
};

export const searchNetworkAllUsersSlice = createSlice({
   name: 'searchNetworkAllUsers',
   initialState: initialState,
   reducers: {
      searchNetworkAllUsersRequest: (
         state,
         action: PayloadAction<networkSagaTypes.ISearchNetworkAllUsersSagaPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      searchNetworkAllUsersSuccess: (
         state,
         action: PayloadAction<networkReducerTypes.ISearchNetworkAllUsersSuccessPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      searchNetworkAllUsersFailed: (
         state,
         action: PayloadAction<networkReducerTypes.ISearchNetworkAllUsersFailurePayload>
      ) => {
         return {
            ...state,
            error: action.payload.error,
         };
      },
      searchNetworkAllUsersReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
