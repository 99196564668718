import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { PENDING_REQUEST } from 'reduxToolKit/actions';
import { commonSlice, pendingRequestListSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   pendingRequestListLoading,
   pendingRequestListViewRequest,
   pendingRequestListViewSuccess,
   pendingRequestListFailed,
} = pendingRequestListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const pendingListSaga = function* (cancerTypePayload: any) {
   yield put(pendingRequestListLoading());
   yield put(pendingRequestListViewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.pendingRequests });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            message: response.data?.message,
         };
         yield put(pendingRequestListViewSuccess(oncDriveList));
      } else {
         yield put(pendingRequestListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePendingListRequest = function* () {
   yield takeEvery(PENDING_REQUEST, pendingListSaga);
};
