import React, { useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import PopulationServedIcon from 'assets/fill-profile/population_served_icon.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import { useDispatch, useSelector } from 'react-redux';
import {
   addPopulationServedAction,
   // cancerSecondaryTypeSagaAction,
   cancerTypeSagaAction,
   clinicalTrialsCancerSpecificSagaAction,
} from 'reduxToolKit/actions';
import { cancerTypeListSlice, populationServedSlice, secondaryCancerTypeListSlice } from 'reduxToolKit/reducers';
import { clinicalTrialsPageTypes, myProfileSagaTypes, storeTypes } from 'types';
import { CommonConstants } from 'utils/constants/common';
import { DONE, NEXT, POPULATION_SERVED_AGE_GROUP, SUBMIT } from 'utils/constants/profileConstants';
import { MenuProps } from 'utils/constants/uiConstants';

const useStyles = makeStyles((theme: any) => ({
   formControl: {
      width: '100%',
      backgroundColor: '#FCFCFC',
   },
   chips: {
      display: 'flex',
      flexWrap: 'nowrap',
      height: 30,
   },
   chip: {
      margin: 2,
      backgroundColor: '#F0F0F0',
      color: '#7A7A8F',
   },
}));

const PopulationServedSection = (props: any) => {
   const classes = useStyles();
   const dispatch = useDispatch();

   const [primaryCancerIds, setPrimaryCancerIds] = useState<number[]>([]);
   const [primaryCancerChange, setPrimaryCancerChange] = useState<boolean>(false);
   const [specificCancerIds, setSpecificCancerIds] = useState<number[]>([]);
   const [ageGroup, setAgeGroup] = useState<string[]>([]);

   const [cancerTypes, setCancerTypes] = useState<any[]>([]);
   const [cancers, setCancers] = useState<any[]>([]);

   const [errorMessagePrimary, setErrorMessagePrimary] = useState<string>('');
   // const [errorMessageSpecific, setErrorMessageSpecific] = useState<string>('');
   // const [errorMessageAgeGroup, setErrorMessageAgeGroup] = useState<string>('');

   const { list, isLoading: cancerLoading } = useSelector((state: storeTypes.IStore) => state.cancerType);
   const { list: SecondaryList, isLoading: secondaryLoading } = useSelector(
      (state: storeTypes.IStore) => state.clinicalTrialsCancerSpecific
   );
   const { cancerListReset } = cancerTypeListSlice.actions;
   const { secondaryCancerListReset } = secondaryCancerTypeListSlice.actions;

   const { data: getUserPopulationServedData } = useSelector(
      (state: storeTypes.IStore) => state.populationServed.getUserPopulationServed
   );
   const { message: addPopulationServedMessage, error: addPopulationServedError } = useSelector(
      (state: storeTypes.IStore) => state.populationServed.addPopulationServed
   );
   const { populationServedAddReset } = populationServedSlice.actions;

   useEffect(() => {
      if (getUserPopulationServedData) {
         const selectedPrimaryIds = getUserPopulationServedData?.primary_cancers?.map((item: any) => {
            return item.cancer_type_id;
         });
         setPrimaryCancerIds(selectedPrimaryIds);
         getSpecificCancer(selectedPrimaryIds, '');
         const selectedSpecificIds = getUserPopulationServedData?.specific_cancers?.map((item: any) => {
            return item.cancer_id;
         });
         setSpecificCancerIds(selectedSpecificIds);
         const selectedAges = getUserPopulationServedData?.age_groups?.map((item: any) => {
            return item.group;
         });
         setAgeGroup(selectedAges);
      } else {
         setPrimaryCancerIds([]);
         setSpecificCancerIds([]);
         setAgeGroup([]);
      }
   }, [getUserPopulationServedData]);

   const getCancerTypes = (searchWord: string) => {
      setCancerTypes([]);
      const params = {
         page: 1,
         limit: CommonConstants.DropDownLimit,
         search_word: searchWord,
      };
      dispatch(cancerTypeSagaAction(params));
   };

   useEffect(() => {
      if (list.length > 0) {
         const cancerList: any[] = list.map((cancerList: any) => {
            const cancers = {
               label: cancerList?.name,
               value: cancerList?.id,
            };
            return cancers;
         });
         setCancerTypes(cancerList);
         dispatch(cancerListReset());
      }
   }, [list]);

   const getSpecificCancer = (cancerTypeIds: number[], searchWord: string) => {
      setCancers([]);
      //
      const cancerSpecificParamsAndData: clinicalTrialsPageTypes.IClinicalTrailsCancerSpecificParamsAndPostData = {
         params: { page: 1, limit: CommonConstants.DropDownLimit, search_word: searchWord },
         data: {
            cancer_type_ids: cancerTypeIds,
         },
      };
      dispatch(clinicalTrialsCancerSpecificSagaAction(cancerSpecificParamsAndData));
   };

   useEffect(() => {
      if (SecondaryList.length > 0) {
         const secondCancers: any[] = SecondaryList.map((cancerList: any) => {
            const cancersObj = {
               label: cancerList?.name,
               value: cancerList?.id,
            };
            return cancersObj;
         });
         setCancers(secondCancers);
         dispatch(secondaryCancerListReset());
      }
   }, [SecondaryList]);

   const changeCancerType = (event: React.ChangeEvent<{ value: unknown }>) => {
      const cancerTypeIds = event.target.value as number[];
      setPrimaryCancerIds(cancerTypeIds);
      setPrimaryCancerChange(true);
   };

   const onCloseCancerType = () => {
      if (primaryCancerChange) {
         getSpecificCancer(primaryCancerIds, '');
      }
   };

   const changeCancerSpecific = (event: React.ChangeEvent<{ value: unknown }>) => {
      const secondaryCancerTypeIds = event.target.value as number[];
      setSpecificCancerIds(secondaryCancerTypeIds);
   };

   const changeAgeGroup = (event: React.ChangeEvent<{ value: unknown }>) => {
      const ageGroup = event.target.value as string[];
      setAgeGroup(ageGroup);
   };

   useEffect(() => {
      if (addPopulationServedMessage && props?.isFromPopulationServed) {
         const getAlert = () => (
            <InfoTimerAlert
               success
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={addPopulationServedMessage}
               onConfirm={() => hideAlert('addPopulationServedMessage')}
            />
         );
         props?.setAlert(getAlert);
         props?.setIsLoader(false);
         props.currentModalClose();
      } else if (addPopulationServedMessage) {
         props.setIsLoader(false);
         gotoNavigation();
      }
   }, [addPopulationServedMessage]);

   const hideAlert = (type: string) => {
      if (type === 'addPopulationServedMessage') {
         if (props?.isFromPopulationServed) {
            props?.closeModelMyProfile();
            props.getUserData();
         }
         dispatch(populationServedAddReset());
         props?.setIsLoader(false);
         props?.populationServedList();
      } else if (type === 'addPopulationServedError') {
         dispatch(populationServedAddReset());
         props?.setIsLoader(false);
      }
   };

   const clickNext = useCallback(() => {
      if (primaryCancerIds?.length === 0) {
         setErrorMessagePrimary('Primary cancer cannot be blank');
         return;
      }
      /* if (specificCancerIds?.length === 0) {
         setErrorMessageSpecific('Specific cancer cannot be blank');
         return;
      }
      if (ageGroup?.length === 0) {
         setErrorMessageAgeGroup('Age group cannot be blank');
         return;
      } */
      props?.setIsLoader(true);
      const data: myProfileSagaTypes.IAddPopulationServedProps = {
         cancer_type_ids: primaryCancerIds,
         cancer_ids: specificCancerIds,
         age_groups: ageGroup,
      };
      dispatch(addPopulationServedAction(data));
   }, [primaryCancerIds, specificCancerIds, ageGroup]);

   const gotoNavigation = useCallback(() => {
      props?.finalMessage();
      props?.currentModalClose();
      props?.setIsLoader(false);
   }, []);

   useEffect(() => {
      if (props?.isPopulationServedValid) {
         props.setIsLoader(false);
         gotoNavigation();
      } else {
         getCancerTypes('');
         // getSpecificCancer(1, '');
      }
   }, []);

   return (
      <div>
         {addPopulationServedError ? (
            <InfoTimerAlert
               error
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={addPopulationServedError}
               onConfirm={() => hideAlert('addPopulationServedError')}
            />
         ) : null}
         <div className="col-12">
            <img className="common-race-icon race-icon mt--2" src={PopulationServedIcon} alt="icon" />
         </div>
         <div className="col-12">
            <p className="common-race-title-text race-title-text">Population served</p>
         </div>
         <div className="col-12">
            <p className="common-race-paragraph-text organization-paragraph-text  p-2">
               Please select the types of patients that you serve.
            </p>
         </div>
         <div className="col-12 mb-3">
            <FormControl className={classes.formControl}>
               <Box className="service-offered-div">
                  {primaryCancerIds?.length === 0 ? (
                     <Typography className="service-offered-text">Primary Cancer</Typography>
                  ) : null}
                  <Select
                     labelId="demo-mutiple-checkbox-label"
                     label={'Primary Cancer'}
                     input={<OutlinedInput />}
                     id="demo-mutiple-checkbox"
                     multiple
                     value={primaryCancerIds}
                     onChange={changeCancerType}
                     onClose={onCloseCancerType}
                     renderValue={(selected: any) => (
                        <div className={classes.chips}>
                           {cancerLoading
                              ? null
                              : selected.map((value: any) => (
                                   <Chip
                                      key={value}
                                      label={cancerTypes?.find((item: any) => item?.value === value)?.label || ''}
                                      className={classes.chip}
                                   />
                                ))}
                        </div>
                     )}
                     MenuProps={MenuProps}
                     variant="outlined"
                     style={{ width: '100%' }}
                     className={`services-offered-dropdown-bg-color ${
                        primaryCancerIds?.length > 0 ? 'population-served-select' : ''
                     }`}
                  >
                     {cancerTypes?.length > 0
                        ? cancerTypes.map((geneticTestingItem: any) => (
                             <MenuItem
                                key={geneticTestingItem?.value || 0}
                                value={geneticTestingItem?.value || ''}
                                className={`genetic-testing-checkbox-color ${
                                   primaryCancerIds.findIndex((obj: any) => obj === geneticTestingItem.value) > -1
                                      ? 'services-offered-checkbox-bg-color'
                                      : ''
                                }`}
                             >
                                <Checkbox
                                   checked={
                                      primaryCancerIds.findIndex((obj: any) => obj === geneticTestingItem.value) > -1
                                   }
                                   inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                <ListItemText primary={geneticTestingItem?.label || ''} />
                             </MenuItem>
                          ))
                        : null}
                  </Select>
               </Box>
            </FormControl>
         </div>
         {errorMessagePrimary ? <div className="error-msg ml-4 mt-3 mr-4"> {errorMessagePrimary}</div> : null}
         <div className="col-12 mb-3">
            <FormControl className={classes.formControl}>
               <Box className="service-offered-div">
                  {specificCancerIds?.length === 0 ? (
                     <Typography className="service-offered-text">Specific Cancer</Typography>
                  ) : null}
                  <Select
                     labelId="demo-mutiple-checkbox-label"
                     label={'Specific Cancer'}
                     input={<OutlinedInput />}
                     id="demo-mutiple-checkbox"
                     multiple
                     value={specificCancerIds}
                     onChange={changeCancerSpecific}
                     renderValue={(selected: any) => (
                        <div className={classes.chips}>
                           {secondaryLoading
                              ? null
                              : selected.map((value: any) => (
                                   <Chip
                                      key={value}
                                      label={cancers?.find((item: any) => item?.value === value)?.label || ''}
                                      className={classes.chip}
                                   />
                                ))}
                        </div>
                     )}
                     MenuProps={MenuProps}
                     variant="outlined"
                     style={{ width: '100%' }}
                     className={`services-offered-dropdown-bg-color ${
                        specificCancerIds?.length > 0 ? 'population-served-select' : ''
                     }`}
                  >
                     {cancers?.length > 0
                        ? cancers.map((geneticTestingItem: any) => (
                             <MenuItem
                                key={geneticTestingItem?.value || 0}
                                value={geneticTestingItem?.value || ''}
                                className={`genetic-testing-checkbox-color ${
                                   specificCancerIds?.findIndex((obj: any) => obj === geneticTestingItem.value) > -1
                                      ? 'services-offered-checkbox-bg-color'
                                      : ''
                                }`}
                             >
                                <Checkbox
                                   checked={
                                      specificCancerIds?.findIndex((obj: any) => obj === geneticTestingItem.value) > -1
                                   }
                                   inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                <ListItemText primary={geneticTestingItem?.label || ''} />
                             </MenuItem>
                          ))
                        : null}
                  </Select>
               </Box>
            </FormControl>
         </div>
         {/* {errorMessageSpecific ? <div className="error-msg ml-4 mt-3 mr-4"> {errorMessageSpecific}</div> : null} */}
         <div className="col-12 mb-3">
            <FormControl className={classes.formControl}>
               <Box className="service-offered-div">
                  {ageGroup?.length === 0 ? <Typography className="service-offered-text">Age Group</Typography> : null}
                  <Select
                     labelId="demo-mutiple-checkbox-label"
                     label={'Age Group'}
                     input={<OutlinedInput />}
                     id="demo-mutiple-checkbox"
                     multiple
                     value={ageGroup}
                     onChange={changeAgeGroup}
                     renderValue={(selected: any) => (
                        <div className={classes.chips}>
                           {selected.map((value: any) => (
                              <Chip
                                 key={value}
                                 label={
                                    POPULATION_SERVED_AGE_GROUP.find((item: any) => item?.value === value)?.label || ''
                                 }
                                 className={classes.chip}
                              />
                           ))}
                        </div>
                     )}
                     MenuProps={MenuProps}
                     variant="outlined"
                     style={{ width: '100%' }}
                     className={`services-offered-dropdown-bg-color ${
                        ageGroup?.length > 0 ? 'population-served-select' : ''
                     }`}
                  >
                     {POPULATION_SERVED_AGE_GROUP?.length > 0
                        ? POPULATION_SERVED_AGE_GROUP.map((geneticTestingItem: any) => (
                             <MenuItem
                                key={geneticTestingItem?.value || 0}
                                value={geneticTestingItem?.value || ''}
                                className={`genetic-testing-checkbox-color ${
                                   ageGroup?.findIndex((obj: any) => obj === geneticTestingItem.value) > -1
                                      ? 'services-offered-checkbox-bg-color'
                                      : ''
                                }`}
                             >
                                <Checkbox
                                   checked={ageGroup?.findIndex((obj: any) => obj === geneticTestingItem.value) > -1}
                                   inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                <ListItemText primary={geneticTestingItem?.label || ''} />
                             </MenuItem>
                          ))
                        : null}
                  </Select>
               </Box>
            </FormControl>
         </div>
         {/* {errorMessageAgeGroup ? <div className="error-msg ml-4 mt-3 mr-4"> {errorMessageAgeGroup}</div> : null} */}
         <div className="button">
            <button
               type="submit"
               className="button-start"
               id="populationServedSectionNext"
               onClick={() => {
                  clickNext();
               }}
            >
               {props?.currentStateArray &&
               props?.currentStateArray[props?.currentStateArray?.length - 1] === props?.currentState
                  ? DONE
                  : props?.isFromPopulationServed
                  ? SUBMIT
                  : NEXT}
            </button>
         </div>
      </div>
   );
};

export default PopulationServedSection;
