import { AxiosResponse } from 'axios';
import Constant from 'config/Constant';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINIC_DELETE_PHYSICIAN } from 'reduxToolKit/actions';
import { commonSlice, deleteClinicPhysiciansSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   deleteClinicPhysiciansRequest,
   deleteClinicPhysiciansSuccess,
   deleteClinicPhysiciansFailed,
} = deleteClinicPhysiciansSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const deleteClinicPhysiciansSaga = function* (payloadState: any) {
   yield put(deleteClinicPhysiciansRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = { id: payloadState.payload.id };
      let url = '' as any;
      if (payloadState.payload.type === Constant.PHYSICIAN) {
         url = API.deleteClinicPhysicians;
      } else if (
         payloadState.payload.type === Constant.PATIENT ||
         payloadState.payload.type === Constant.CAREGIVER ||
         payloadState.payload.type === Constant.PATIENT_ADVOCATE
      ) {
         url = API.deleteClinicPatients;
      }
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...url });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
         };
         yield put(deleteClinicPhysiciansSuccess(resObj));
      } else {
         yield put(deleteClinicPhysiciansFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeDeleteClinicPhysicians = function* () {
   yield takeEvery(CLINIC_DELETE_PHYSICIAN, deleteClinicPhysiciansSaga);
};
