import React from 'react';

import NoDataFound from 'assets/onco-clinic/no-data-found.svg';

const NoData = (props: any) => {
   return (
      <div className="my-patients mt-0">
         <div className="col-12">
            <div className="row p-5">
               <div className="col-lg-12 text-center pt-2 pb-3">
                  <img src={NoDataFound} alt="no-patients" />
               </div>
               {props.title && (
                  <div className="col-lg-12 text-center pt-3 pb-3">
                     <span className="no-patient-title" style={{ fontSize: '22px' }}>
                        {props.title}
                     </span>
                  </div>
               )}

               <div className="col-lg-12 text-center pl-5 pr-5 pb-0">
                  {props.message && (
                     <div
                        className="no-patient-content"
                        style={{ fontSize: '16px', fontStyle: 'normal', color: '#1c1c1c' }}
                     >
                        {props.message}
                     </div>
                  )}
                  {props.sub_message && (
                     <div
                        className="no-patient-content"
                        style={{ fontSize: '16px', fontStyle: 'normal', color: '#1c1c1c' }}
                     >
                        {props.sub_message}
                     </div>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

export default NoData;
