import { createAction } from '@reduxjs/toolkit';
import { feedSagaTypes } from 'types/feed';

export const FEED_REACTIONS_ADD_SAGA = 'FEED_REACTIONS_ADD_SAGA';
export const feedReactionsAddSagaAction = createAction<feedSagaTypes.IFeedAddReactionsActionProps>(
   FEED_REACTIONS_ADD_SAGA
);
export const SINGLE_FEED_POST = 'SINGLE_FEED_POST';
export const singlePostFeed = createAction<feedSagaTypes.IDeletePostCommentActionProps>(SINGLE_FEED_POST);

export const ASK_DOC_SINGLE_FEED_POST = 'ASK_DOC_SINGLE_FEED_POST';
export const askADocSinglePostFeed = createAction<feedSagaTypes.IAskADocSingleFeed>(ASK_DOC_SINGLE_FEED_POST);

export const DELETE_POST_COMMENT = 'DELETE_POST_COMMENT';
export const deletePostCommentSagaAction = createAction<feedSagaTypes.IDeletePostCommentActionProps>(
   DELETE_POST_COMMENT
);

export const COMMENT_REPLY_ADD = 'COMMENT_REPLY_ADD';
export const commentReplyAddSagaAction = createAction<feedSagaTypes.ICommentReplyAddSagaRequest>(COMMENT_REPLY_ADD);

export const COMMENT_REPLY_UPDATE = 'COMMENT_REPLY_UPDATE';
export const commentReplyUpdateSagaAction = createAction<feedSagaTypes.ICommentReplyUpdateSagaRequest>(
   COMMENT_REPLY_UPDATE
);

export const COMMENT_REPLY_DELETE = 'COMMENT_REPLY_DELETE';
export const commentReplyDeleteSagaAction = createAction<feedSagaTypes.ICommentReplyDeleteSagaRequest>(
   COMMENT_REPLY_DELETE
);

export const GET_COMMENTS = 'GET_COMMENTS';
export const getCommentsSagaAction = createAction<feedSagaTypes.IGetCommentsSagaRequest>(GET_COMMENTS);

export const COMMENT_ADD_UPDATE = 'COMMENT_ADD_UPDATE';
export const commentAddUpdateSagaAction = createAction<feedSagaTypes.ICommentAddUpdateSagaRequest>(COMMENT_ADD_UPDATE);

export const COMMENTS_UPDATE_REACTIONS = 'COMMENTS_UPDATE_REACTIONS';
export const commentsUpdateReaction = createAction<feedSagaTypes.ICommentsUpdateReactions>(COMMENTS_UPDATE_REACTIONS);

export const FEED_POST_REACTIONS = 'FEED_POST_REACTIONS';
export const feedPostReaction = createAction<feedSagaTypes.IFeedReactions>(FEED_POST_REACTIONS);

export const COMMENTS_REACTIONS_LIST = 'COMMENTS_REACTIONS_LIST';
export const commentsReactionList = createAction<feedSagaTypes.ICommentsReactionsList>(COMMENTS_REACTIONS_LIST);

export const GET_REPLY_COMMENTS = 'GET_REPLY_COMMENTS';
export const getReplyCommentsSagaAction = createAction<feedSagaTypes.IGetReplyCommentsSagaRequest>(GET_REPLY_COMMENTS);

export const FEED_CREATE_AND_EDIT = 'FEED_CREATE_AND_EDIT ';
export const feedCreateAndEdit = createAction<feedSagaTypes.ICreateAndEditPostEventSagaPayload>(FEED_CREATE_AND_EDIT);

export const FEED_POST_DELETE = 'FEED_POST_DELETE ';
export const feedPostDelete = createAction<feedSagaTypes.IDeletePostSagaPayload>(FEED_POST_DELETE);

export const FEED_POLL_OPTION = 'FEED_POLL_OPTION ';
export const feedPostOption = createAction<feedSagaTypes.IPostPollOptionSagaPayload>(FEED_POLL_OPTION);

export const FEED_POLL_USERS_LIST = 'FEED_POLL_USERS_LIST';
export const feedPollUsersList = createAction<feedSagaTypes.IFeedPollUserListSagaPayload>(FEED_POLL_USERS_LIST);

export const FEED_POST_LIST = 'FEED_POST_LIST';
export const getFeedPostList = createAction<feedSagaTypes.IMySavedFeetPostList>(FEED_POST_LIST);

export const FEED_MEDITATION = 'FEED_MEDITATION';
export const getFeedMeditation = createAction<feedSagaTypes.IFeedMeditationParams>(FEED_MEDITATION);

export const FEED_POST_USER_LINK = 'FEED_POST_USER_LINK';
export const getFeedPostUserLink = createAction<feedSagaTypes.IFeedPostUserLinkParams>(FEED_POST_USER_LINK);

export const FEED_POST_USER_LINK_USER_WITHOUT_AUTH = 'FEED_POST_USER_LINK_USER_WITHOUT_AUTH';
export const getFeedPostUserLinkUserWithoutAuth = createAction<feedSagaTypes.IFeedPostUserLinkUserParams>(
   FEED_POST_USER_LINK_USER_WITHOUT_AUTH
);

export const FEED_POST_USER_LINK_USER_WITH = 'FEED_POST_USER_LINK_USER_WITH';
export const getFeedPostUserLinkUserWithAuth = createAction<feedSagaTypes.IFeedPostUserLinkUserParams>(
   FEED_POST_USER_LINK_USER_WITH
);

export const HIDE_THIS_POST = 'HIDE_THIS_POST';
export const getHideThisPost = createAction<feedSagaTypes.IFeedHideThisPost>(HIDE_THIS_POST);

export const ADD_COMMUNITY_GUIDELINES = 'ADD_COMMUNITY_GUIDELINES';
export const addCommunityGuidelines = createAction(ADD_COMMUNITY_GUIDELINES);

export const CREATE_POST_IS_CLINIC_USER = 'CREATE_POST_IS_CLINIC_USER';
export const createPostIsClinicUser = createAction(CREATE_POST_IS_CLINIC_USER);

export const CT_FILLED_POPUP = 'CT_FILLED_POPUP';
export const ctFilledPopupAction = createAction<feedSagaTypes.ICtFilledPopUp>(CT_FILLED_POPUP);
