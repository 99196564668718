import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { HASH_TAG_LIST } from 'reduxToolKit/actions';
import { commonSlice, hashTagListSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { hashTagListRequest, hashTagListSuccess, hashTagListFailed } = hashTagListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const hashTagListSaga = function* (hashTagListParams: PayloadAction<feedSagaTypes.ITagUsersListSagaRequest>) {
   yield put(hashTagListRequest());
   try {
      const params = hashTagListParams.payload;
      const checkUser = true;
      const response: AxiosResponse = yield apiCall({ checkUser, params, ...API.getHashTagsList });
      if (response.status === 200) {
         const responseData = response.data.data;
         const hashTagList = {
            list: responseData.data,
            message: response.data?.message,
         };
         yield put(hashTagListSuccess(hashTagList));
      } else {
         yield put(hashTagListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeHashTagList = function* () {
   yield takeEvery(HASH_TAG_LIST, hashTagListSaga);
};
