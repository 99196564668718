import environment, { Environment } from './base';

const baseUrl = 'https://api-v2.oncopower.org';
const imageBaseUrl = 'https://cdn.oncopower.org/';
const baseUrlNew = 'https://api-v3.oncopower.org';
const env = environment();

const oncoProd: Environment = {
   ...env,
   // override anything that gets added from base.
   baseUrl: baseUrl,
   baseUrlNew: baseUrlNew,
   isKovidPower: false,
   imageBaseUrl: imageBaseUrl,
};

export default oncoProd;
