import { createSlice } from '@reduxjs/toolkit';
import { myProfileReducerTypes } from 'types';

const initialState: myProfileReducerTypes.IGeneticTestingReducer = {
   addGeneticTesting: {
      isLoading: false,
      error: '',
      message: '',
   },
   getMasterGeneticTesting: {
      isLoading: false,
      message: '',
      error: '',
      list: [],
   },
   getGeneticTestingList: {
      isLoading: false,
      message: '',
      error: '',
      list: [],
   },
   getGeneticTestingMasterList: {
      isLoading: false,
      message: '',
      error: '',
      list: [],
   },
   deleteGeneticTesting: {
      isLoading: false,
      error: '',
      message: '',
   },
};

export const geneticTestingSlice = createSlice({
   name: 'geneticTesting',
   initialState: initialState,
   reducers: {
      // add genetic Testing
      geneticTestingAddRequest: (state, action) => {
         return {
            ...state,
            addGeneticTesting: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      geneticTestingAddSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            addGeneticTesting: {
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      geneticTestingAddFailed: (state, action: any) => {
         return {
            ...state,
            addGeneticTesting: {
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      geneticTestingAddReset: (state) => {
         return {
            ...state,
            addGeneticTesting: initialState.addGeneticTesting,
         };
      },

      // get master genetic testing
      getMasterGeneticTestingRequest: (state, action) => {
         return {
            ...state,
            getMasterGeneticTesting: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getMasterGeneticTestingSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            getMasterGeneticTesting: {
               list: payload.list,
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      getMasterGeneticTestingFailed: (state, action: any) => {
         return {
            ...state,
            getMasterGeneticTesting: {
               list: state.getMasterGeneticTesting.list,
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      getMasterGeneticTestingReset: (state) => {
         return {
            ...state,
            getMasterGeneticTesting: initialState.getMasterGeneticTesting,
         };
      },
      // get genetic testing list
      getGeneticTestingListRequest: (state, action) => {
         return {
            ...state,
            getGeneticTestingList: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getGeneticTestingListSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            getGeneticTestingList: {
               list: payload.list,
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      getGeneticTestingListFailed: (state, action: any) => {
         return {
            ...state,
            getGeneticTestingList: {
               list: state.getGeneticTestingList.list,
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      getGeneticTestingListReset: (state) => {
         return {
            ...state,
            getGeneticTestingList: initialState.getGeneticTestingList,
         };
      },
      // get genetic testing master list
      getGeneticTestingMasterListRequest: (state, action) => {
         return {
            ...state,
            getGeneticTestingMasterList: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getGeneticTestingMasterListSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            getGeneticTestingMasterList: {
               list: payload.list,
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      getGeneticTestingMasterListFailed: (state, action: any) => {
         return {
            ...state,
            getGeneticTestingMasterList: {
               list: state.getGeneticTestingMasterList.list,
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      getGeneticTestingMasterListReset: (state) => {
         return {
            ...state,
            getGeneticTestingMasterList: initialState.getGeneticTestingMasterList,
         };
      },
      // delete genetic Testing
      geneticTestingDeleteRequest: (state, action) => {
         return {
            ...state,
            deleteGeneticTesting: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      geneticTestingDeleteSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            deleteGeneticTesting: {
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      geneticTestingDeleteFailed: (state, action: any) => {
         return {
            ...state,
            deleteGeneticTesting: {
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      geneticTestingDeleteReset: (state) => {
         return {
            ...state,
            deleteGeneticTesting: initialState.deleteGeneticTesting,
         };
      },
   },
});
