import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { toastControllerSlice } from 'reduxToolKit/reducers';
import { storeTypes } from 'types';
import 'react-toastify/dist/ReactToastify.css';
import { CommonConstants } from 'utils/constants/common';
import { messages } from 'utils/constants/messages';

export const ToastController = () => {
   const dispatch = useDispatch();

   const toastProps: any = useSelector((state: storeTypes.IStore) => state.toastController);
   const { toastControllerReset } = toastControllerSlice.actions;

   useEffect(() => {
      if (toastProps.message) {
         const options: any = {};
         for (const key in toastProps) {
            if (key !== 'messageType' && key !== 'message' && toastProps.hasOwnProperty(key)) {
               options[key] = toastProps[key];
            }
         }
         switch (toastProps.messageType) {
            case CommonConstants.Toast.types.SUCCESS: {
               toast.success(toastProps.message, options);
               break;
            }
            case CommonConstants.Toast.types.ERROR: {
               toast.error(toastProps.message, options);
               break;
            }
            case CommonConstants.Toast.types.WARN: {
               toast.warn(toastProps.message, options);
               break;
            }
            case CommonConstants.Toast.types.INFO: {
               toast.info(toastProps.message, options);
               break;
            }
            default: {
               toast(toastProps.message || messages.errorMessage);
            }
         }
         dispatch(toastControllerReset());
      }
   }, [toastProps.message]);

   return (
      <div>
         <ToastContainer />
      </div>
   );
};
