import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pillRemainderReducerTypes } from 'types';

const initialState: pillRemainderReducerTypes.IPillRemainderMedicationsReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const medicationListSlice = createSlice({
   name: 'pillRemainderGetOne',
   initialState: initialState,
   reducers: {
      medicationsListRequest: (state, action: any) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      medicationsListSuccess: (
         state,
         action: PayloadAction<pillRemainderReducerTypes.IPillRemainderMedicationsReducer>
      ) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      medicationsListFailed: (state, action) => {
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      medicationsListReset: () => {
         return initialState;
      },
   },
});
export { medicationListSlice };
