import { AxiosResponse } from 'axios';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { ASK_A_DOC_GET_PANEL_PHY_LIST } from 'reduxToolKit/actions';
import { commonSlice, panelsPhyListSlice } from 'reduxToolKit/reducers';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   panelsPhyListLoading,
   panelsPhyListViewRequest,
   panelsPhyListViewSuccess,
   panelsPhyListFailed,
} = panelsPhyListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const panelsPhyListSaga = function* (cancerTypePayload: any) {
   yield put(panelsPhyListLoading());
   yield put(panelsPhyListViewRequest(cancerTypePayload.payload));
   yield delay(500);
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload.params;
      const data = cancerTypePayload.payload.postData;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, params, ...API.panelPhyList });
      if (response.status === 200) {
         const responseData = response?.data;
         const oncDriveList = {
            list: responseData?.data?.list,
            total_records: responseData?.data?.total_records,
            message: responseData?.message || messages.success,
         };
         yield put(panelsPhyListViewSuccess(oncDriveList));
      } else {
         yield put(panelsPhyListFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePanelsPhyListRequest = function* () {
   yield takeLatest(ASK_A_DOC_GET_PANEL_PHY_LIST, panelsPhyListSaga);
};
