import { AxiosResponse } from 'axios';
import { delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
   MEDICAL_ENQUIRES_MY_CLINIC_PHYSICIANS_LIST_SAGA,
   MEDICAL_ENQUIRES_MY_CLINIC_PHYSICIANS_SEARCH_SAGA,
} from 'reduxToolKit/actions';
import { commonSlice, medicalEnqMyClinicPhysListSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   oncDriveMyClinicPhysListViewLoading,
   medicalEnqMyClinicPhysListViewRequest,
   medicalEnqMyClinicPhysListtViewSuccess,
   medicalEnqMyClinicPhysListViewFailed,
} = medicalEnqMyClinicPhysListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const medicalEnqMyClinicPhysListSaga = function* (medicalEnqMyClinicPhysiciansListPayload: any) {
   if (medicalEnqMyClinicPhysiciansListPayload.payload.search_word !== '') {
      yield put(oncDriveMyClinicPhysListViewLoading());
      yield delay(500);
   }
   yield put(medicalEnqMyClinicPhysListViewRequest(medicalEnqMyClinicPhysiciansListPayload.payload));
   try {
      const params = medicalEnqMyClinicPhysiciansListPayload.payload;
      const url = API.medicalEnqMyClinicPhysiciansList;
      const response: AxiosResponse = yield apiCall({ params, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const responseObject = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message,
         };
         yield put(medicalEnqMyClinicPhysListtViewSuccess(responseObject));
      } else {
         yield put(medicalEnqMyClinicPhysListViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeMedicalEnqMyClinicPhysListRequest = function* () {
   yield takeEvery(MEDICAL_ENQUIRES_MY_CLINIC_PHYSICIANS_LIST_SAGA, medicalEnqMyClinicPhysListSaga);
};
export const takeMedicalEnqMyClinicPhysSearchRequest = function* () {
   yield takeLatest(MEDICAL_ENQUIRES_MY_CLINIC_PHYSICIANS_SEARCH_SAGA, medicalEnqMyClinicPhysListSaga);
};
