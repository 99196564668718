export const PILL_REMAINDER_EVENTS = {
   // pill remainder
   PILL_REMINDER_WATCH_LATER: 'pill_reminder_watch_later',
   PILL_REMINDER_DEMO_PLAY: 'pill_reminder_demo_play',
   ADD_PILL_REMINDER: 'add_pill_reminder',
   ADD_PILL_REMINDER_CANCEL: 'add_pill_reminder_cancel',
   VIEW_MEDICATIONS: 'view_medications',
   SUSPEND_MEDICATION: 'suspend_medication',
   RESUME_MEDICATION: 'resume_medication',
   EDIT_MEDICATION: 'edit_medication',
   EDIT_MEDICATION_CANCEL: 'edit_medication_cancel',
   PILL_SKIP: 'pill_skip',
   PILL_RESCHEDULE: 'pill_reschedule',
   PILL_UNTAKE: 'pill_untake',
   PILL_TAKE: 'pill_take',
   PILL_REMINDER_NOTIFICATION_DIALOGUE_CLOSE: 'pill_reminder_notification_dialogue_close',
};
