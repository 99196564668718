import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { APPOINTMENT_DOCUMENT_UPDATE_SAGA, APPOINTMENT_DOCUMENT_VIEW_SAGA } from 'reduxToolKit/actions';
import { commonSlice, updateAppointmentDocumentSlice, viewAppointmentDocumentSlice } from 'reduxToolKit/reducers';
import { appointmentDocumentSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   viewAppointmentDocStart,
   viewAppointmentDocFailed,
   viewAppointmentDocSuccess,
} = viewAppointmentDocumentSlice.actions;

const {
   updateAppointmentDocFailed,
   updateAppointmentDocStart,
   updateAppointmentDocSuccess,
} = updateAppointmentDocumentSlice.actions;

const { exceptionHandlerRequest } = commonSlice.actions;

const viewAppointmentDocumentSaga = function* (
   payloadId: appointmentDocumentSagaTypes.IAppointmentDocumentDataPayload
) {
   yield put(viewAppointmentDocStart(payloadId.payload));
   try {
      const apiPath = {
         apiPath: `${API.viewAppointmentDocument.apiPath}${'/'}${payloadId.payload}`,
         action: API.viewAppointmentDocument.action,
      };
      const response: AxiosResponse = yield apiCall({ ...apiPath });
      if (response.status === 200) {
         yield put(viewAppointmentDocSuccess(response.data.data));
      } else {
         yield put(viewAppointmentDocFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const updateAppointmentDocumentSaga = function* (
   appointmentUpdatePayload: appointmentDocumentSagaTypes.IAppointmentDocumentUpdatePayload
) {
   yield put(updateAppointmentDocStart(appointmentUpdatePayload));
   try {
      const data = {
         claim_form: appointmentUpdatePayload.payload.claim_form,
      };
      const apiPath = {
         apiPath: `${API.updateAppointmentDocument.apiPath}${'/'}${appointmentUpdatePayload.payload.id}`,
         action: API.updateAppointmentDocument.action,
      };
      const response: AxiosResponse = yield apiCall({ ...apiPath, data });
      if (response.status === 200) {
         yield put(updateAppointmentDocSuccess(response.data));
      } else {
         yield put(updateAppointmentDocFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeViewAppointmentDocumentRequest = function* () {
   yield takeEvery(APPOINTMENT_DOCUMENT_VIEW_SAGA, viewAppointmentDocumentSaga);
   yield takeEvery(APPOINTMENT_DOCUMENT_UPDATE_SAGA, updateAppointmentDocumentSaga);
};
