import { createSlice } from '@reduxjs/toolkit';
import { scheduleReducerTypes } from 'types';

const initialState: scheduleReducerTypes.IGetSchedule = {
   isLoading: false,
   error: '',
   message: '',
   progressLoadMore: false,
   list: [],
};

export const getScheduleSlice = createSlice({
   name: 'getSchedule',
   initialState: initialState,
   reducers: {
      getScheduleRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getScheduleSuccess: (state, action) => {
         return {
            ...state,
            list: action.payload,
            isLoading: false,
         };
      },
      getScheduleFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error_message,
         };
      },
      getScheduleResetMsg: (state, action) => {
         return {
            ...state,
            message: '',
         };
      },
      getScheduleResetErr: (state, action) => {
         return {
            ...state,
            message: '',
            error: '',
         };
      },
   },
});
