import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deviceSagaTypes, deviceReducesTypes } from 'types';

const initialState: deviceReducesTypes.ISmartWeightReducerTypes = {
   mySmartWeight: { isLoading: false, message: '', data: null, error: '' },
   smartWeightStatistics: { isLoading: false, message: '', data: null, error: '' },
};
export const deviceSmartWeightSlice = createSlice({
   name: 'deviceSmartWeight',
   initialState: initialState,
   reducers: {
      /**
       * TODO: For manage get smart weight api call in all devices
       */
      mySmartWeightRequest: (state, action: PayloadAction<deviceSagaTypes.IGetBloodPressure>) => {
         return {
            ...state,
            mySmartWeight: {
               ...state.mySmartWeight,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      mySmartWeightSuccess: (state, action: PayloadAction<deviceReducesTypes.IDeviceCommonSuccessPayload>) => {
         return {
            ...state,
            mySmartWeight: {
               ...state.mySmartWeight,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      mySmartWeightFailed: (state, action: PayloadAction<deviceReducesTypes.IDeviceCommonFailurePayload>) => {
         return {
            ...state,
            mySmartWeight: {
               ...state.mySmartWeight,
               isLoading: false,
               error: action.payload.error,
               message: '',
            },
         };
      },
      mySmartWeightReset: (state) => {
         return {
            ...state,
            mySmartWeight: initialState.mySmartWeight,
         };
      },

      /**
       * TODO: For manage get smart weight statistics api call in all devices
       */
      mySmartWeightStatisticsRequest: (state, action: PayloadAction<deviceSagaTypes.IGetBloodPressure>) => {
         return {
            ...state,
            smartWeightStatistics: {
               ...state.smartWeightStatistics,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      mySmartWeightStatisticsSuccess: (
         state,
         action: PayloadAction<deviceReducesTypes.IDeviceCommonSuccessPayload>
      ) => {
         return {
            ...state,
            smartWeightStatistics: {
               ...state.smartWeightStatistics,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      mySmartWeightStatisticsFailed: (state, action: PayloadAction<deviceReducesTypes.IDeviceCommonFailurePayload>) => {
         return {
            ...state,
            smartWeightStatistics: {
               ...state.smartWeightStatistics,
               isLoading: false,
               error: action.payload.error,
               message: '',
            },
         };
      },
      mySmartWeightStatisticsReset: (state) => {
         return {
            ...state,
            smartWeightStatistics: initialState.smartWeightStatistics,
         };
      },
   },
});
