import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clinicAdminReducerTypes, clinicAdminSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: clinicAdminReducerTypes.IClinicInfo = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const clinicDeleteAuxiliarySlice = createSlice({
   name: 'clinicDeleteAuxiliary',
   initialState: initialState,
   reducers: {
      clinicDeleteAuxiliaryRequest: (state, action: PayloadAction<clinicAdminSagaTypes.IClinicDeleteAuxiliarySaga>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      clinicDeleteAuxiliarySuccess: (state, action: PayloadAction<clinicAdminReducerTypes.IClinicInfo>) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            message: payload.message || messages.success,
            isLoading: false,
         };
      },
      clinicDeleteAuxiliaryFailed: (state, action) => {
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: action.payload.error_message ? action.payload.error_message : '',
            ...action.payload,
         };
      },
      clinicDeleteAuxiliaryReset: () => {
         return initialState;
      },
   },
});

export { clinicDeleteAuxiliarySlice };
