export const simpleEncode = (str: string) => {
   return typeof str === 'string' ? btoa(str) : str;
};

export const simpleDecode = (encryptStr: string | null | string[]) => {
   return typeof encryptStr === 'string' && isBase64Str(encryptStr) ? atob(encryptStr) : String(encryptStr);
};

export const isBase64Str = (value: string | null | string[]) => {
   const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
   return typeof value === 'string' && base64regex.test(value);
};

export const isBase64 = (str: string) => {
   try {
      return btoa(atob(str)) === str;
   } catch (err) {
      return false;
   }
};
