import { createAction } from '@reduxjs/toolkit';
import { clinicAdminSagaTypes } from 'types';

export const CLINIC_INFORMATION_EDIT_SAGA = 'CLINIC_INFORMATION_EDIT_SAGA';
export const clinicInformationEditSagaAction = createAction<clinicAdminSagaTypes.IClinicInformationEditSaga>(
   CLINIC_INFORMATION_EDIT_SAGA
);

export const CLINIC_PHY_INVITE_IS_EXIST_SAGA = 'CLINIC_PHY_INVITE_IS_EXIST_SAGA';
export const clinicPhyInviteIsExistSagaAction = createAction<clinicAdminSagaTypes.IClinicPhyInviteIsExistSaga>(
   CLINIC_PHY_INVITE_IS_EXIST_SAGA
);

export const CLINIC_DELETE_AUXILIARY_SAGA = 'CLINIC_DELETE_AUXILIARY_SAGA';
export const clinicDeleteAuxiliarySagaAction = createAction<clinicAdminSagaTypes.IClinicDeleteAuxiliarySaga>(
   CLINIC_DELETE_AUXILIARY_SAGA
);

export const CLINIC_PATIENT_BULK_IMPORT_SAGA = 'CLINIC_PATIENT_BULK_IMPORT_SAGA';
export const clinicPatientBulkImportSagaAction = createAction<clinicAdminSagaTypes.IClinicPatientBulkImport>(
   CLINIC_PATIENT_BULK_IMPORT_SAGA
);
