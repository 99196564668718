import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { UPDATE_PAYPAL_AND_ZELLE_ID } from 'reduxToolKit/actions';
import { commonSlice, paypalAndZelleUpdateSlice } from 'reduxToolKit/reducers';
import { paypalAndZelleSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   paypalAndZelleUpdateRequest,
   paypalAndZelleUpdateSuccess,
   paypalAndZelleUpdateFailed,
} = paypalAndZelleUpdateSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const paypalAndZelleUpdate = function* (
   medicalEnqAdd: PayloadAction<paypalAndZelleSagaTypes.IPaypalAndZelleSagaParams>
) {
   yield put(paypalAndZelleUpdateRequest(medicalEnqAdd.payload));
   try {
      const data = medicalEnqAdd.payload;
      let url = API.paypalIDUpdate;
      if (data.isPatient) {
         url = API.patientPayPalIDUpdate;
      }
      const checkUser = true;
      const goUrl = true;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...url });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(paypalAndZelleUpdateSuccess(resObj));
      } else {
         yield put(paypalAndZelleUpdateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takePaypalAndZelleUpdate = function* () {
   yield takeEvery(UPDATE_PAYPAL_AND_ZELLE_ID, paypalAndZelleUpdate);
};
