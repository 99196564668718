import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ONCDRIVE_VIEW_SAGA } from 'reduxToolKit/actions';
import { commonSlice, oncDriveViewFileSlice } from 'reduxToolKit/reducers';
import { oncDriveSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { oncDriveViewFileRequest, oncDriveViewFileSuccess, oncDriveViewFileFailed } = oncDriveViewFileSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const oncDriveView = function* (oncDriveFolder: oncDriveSagaTypes.IAddToDrivePayload) {
   yield put(oncDriveViewFileRequest(oncDriveFolder.payload));
   try {
      const apiPath = {
         apiPath: `${API.oncDriveListSubDirAndFiles.apiPath}${oncDriveFolder.payload.id}`,
         action: API.oncDriveListSubDirAndFiles.action,
      };
      const params = {
         onco_drive_analytic_id: oncDriveFolder.payload.onco_drive_analytic_id,
      };
      const response: AxiosResponse = yield apiCall({ params, ...apiPath });
      if (response.status === 200) {
         yield put(oncDriveViewFileSuccess(response.data));
      } else {
         yield put(oncDriveViewFileFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeViewOncDriveRequest = function* () {
   yield takeEvery(ONCDRIVE_VIEW_SAGA, oncDriveView);
};
