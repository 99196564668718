import { userInviteEmailPageTypes } from 'types/userInvite';

export const USR_PRF_RENDER_VALID: userInviteEmailPageTypes.IUserDetails = {
   isFrom: '',
};

export const NEXT = 'Next';
export const DONE = 'Done';
export const SUBMIT = 'Submit';

export const POPULATION_SERVED_AGE_GROUP: { label: string; value: string }[] = [
   {
      label: 'Child (Birth-17)',
      value: 'child',
   },
   {
      label: 'Adult (18-64)',
      value: 'adult',
   },
   {
      label: 'Older Adult (65+)',
      value: 'older_adult',
   },
];
