import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_DISCLAIMER, GET_DISCLAIMER } from 'reduxToolKit/actions';
import { askADocDisclaimerSlice, commonSlice } from 'reduxToolKit/reducers';
import { askADocDisclaimerReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   addDisclaimerRequest,
   addDisclaimerSuccess,
   addDisclaimerFailed,
   getDisclaimerRequest,
   getDisclaimerSuccess,
   getDisclaimerFailed,
} = askADocDisclaimerSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const addDisclaimerSaga = function* (payloadState: PayloadAction<askADocDisclaimerReducerTypes.IGetDisclaimer>) {
   yield put(addDisclaimerRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addDisclaimer });
      if (response.status === 200) {
         const responseData = {
            data: response.data?.data || null,
            message: response.data?.message || messages.success,
         };
         yield put(addDisclaimerSuccess(responseData));
      } else {
         yield put(addDisclaimerFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getDisclaimerSaga = function* (payloadState: PayloadAction<askADocDisclaimerReducerTypes.IGetDisclaimer>) {
   yield put(getDisclaimerRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getDisclaimer });
      if (response.status === 200) {
         const responseData = {
            data: response.data?.data || null,
            message: response.data?.message || messages.success,
         };
         yield put(getDisclaimerSuccess(responseData));
      } else {
         yield put(getDisclaimerFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeDisclaimerSaga = function* () {
   yield takeEvery(ADD_DISCLAIMER, addDisclaimerSaga);
   yield takeEvery(GET_DISCLAIMER, getDisclaimerSaga);
};
