import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ONCDRIVE_DELETE_FOLDER_AND_FILES_SAGA } from 'reduxToolKit/actions';
import { commonSlice, oncDriveDeleteFolderAndFilesSlice } from 'reduxToolKit/reducers';
import { oncDriveSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   oncDriveDeleteFolderAndFilesRequest,
   oncDriveDeleteFolderAndFilesSuccess,
   oncDriveDeleteFolderAndFilesFailed,
} = oncDriveDeleteFolderAndFilesSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const oncDirveDeleteFolderAndFiles = function* (
   oncDriveFolder: oncDriveSagaTypes.IOncDriveDeleteFolderAndFilesPayload
) {
   yield put(oncDriveDeleteFolderAndFilesRequest(oncDriveFolder.payload));
   try {
      const url = {
         apiPath: `${API.oncDriveDeleteFolderAndFiles.apiPath}${oncDriveFolder.payload.id}`,
         action: API.oncDriveDeleteFolderAndFiles.action,
      };
      const response: AxiosResponse = yield apiCall({ ...url });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(oncDriveDeleteFolderAndFilesSuccess(responseData));
      } else {
         yield put(oncDriveDeleteFolderAndFilesFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeDeleteFolderAndFilesOncDriveRequest = function* () {
   yield takeEvery(ONCDRIVE_DELETE_FOLDER_AND_FILES_SAGA, oncDirveDeleteFolderAndFiles);
};
