import React, { useEffect, useState } from 'react';

import MobileStepper from '@material-ui/core/MobileStepper';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { latinoSlice, postShareSlice } from 'reduxToolKit/reducers';
import { storeTypes } from 'types';
import {
   ADDRESS_SECTION,
   CANCER_STATUS_SECTION,
   CANCER_TYPE_SECTION,
   CLINICAL_TRAILS_ADDRESS_SECTION,
   CLINICAL_TRAILS_CANCER_TYPE_SECTION,
   CLINICAL_TRAILS_DISPLAY_NAME_SECTION,
   CLINICAL_TRIALS_CANCER_STATUS_SECTION,
   CLINICAL_TRIALS_DIAGNOSIS_SECTION,
   CLINICAL_TRIALS_DOB_SECTION,
   CLINICAL_TRIALS_HOME_SECTION,
   CLINICAL_TRIALS_LATINO_SECTION,
   CLINICAL_TRIALS_RACE_SECTION,
   CLINICAL_TRIALS_SERVICE_OFFERED_SECTION,
   CLINICAL_TRIALS_STAGING_SECTION,
   DIAGNOSIS_SECTION,
   DISPLAY_NAME_SECTION,
   DOB_SECTION,
   HOME_SECTION,
   HOME_SECTION_EXISTING_USER,
   INITIAL_ADDRESS_SECTION,
   INITIAL_CANCER_TYPE_SECTION,
   INITIAL_DISPLAY_NAME_SECTION,
   LATINO_SECTION,
   ORG_USER_NAME,
   ORG_USER_POPULATION_SERVED,
   ORG_USER_SERVICES_OFFERED,
   RACE_SECTION,
   SERVICE_OFFERED_SECTION,
   STAGING_SECTION,
} from 'utils/constants/auth';
import { FILL_PROFILE_SKIP_ARRAY } from 'utils/constants/feed';
import { getUser } from 'utils/helpers/authHeader';
import {
   clinicalTrailsFillProfile,
   clinicalTrailsFillProfileArray,
   clinicalTrailsRemainingFillProfile,
   clinicalTrailsRemainingFillProfileArray,
   fillProfile,
   fillProfileArray,
   fillProfileInitial,
   fillProfileInitialArray,
   fillProfileOrgInitial,
   fillProfileOrgInitialArray,
} from 'utils/helpers/myProfileHelpers';

import Loading from '../widget/Loading';
import AddressSection from './AddressSection';
import CancerStatusSection from './CancerStatusSection';
import { CancerTypeSection } from './CancerTypeSection';
import ClinicalTrailsHomeSection from './ClinicalTrailsHomeSection';
import DiagnosisSection from './DiagnosisSection';
import { DisplayNameSection } from './DisplayNameSection';
import DobSection from './DobSection';
import HomeSection from './HomeSection';
import LatinoSection from './LatinoSection';
import OrganizationNameSection from './OrganizationNameSection';
import PopulationServedSection from './PopulationServedSection';
import RaceSection from './RaceSection';
import ServicesOfferedSection from './ServicesOfferedSection';
import StagingSection from './StagingSection';
// TODO
// incase will use future
// import OtpSection from './otpSection';
// import PhoneSection from './phoneSection';

const customStyles = {
   content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      marginTop: 0,
      width: '25%',
   },
};

const ProfileLayout = (props: any) => {
   const dispatch = useDispatch();
   const [modalIsOpen, setModalIsOpen] = useState(false);
   const [isLoader, setIsLoader] = useState(false);
   const [currentState, setCurrentState] = useState(-1);
   const [currentStateInitial, setCurrentStateInitial] = useState(-1);
   const [currentStateClinicalTrailsRemaining, setCurrentStateClinicalTrailsRemaining] = useState(-1);
   const [currentStateClinicalTrails, setCurrentStateClinicalTrails] = useState(-1);
   const [currentStateOrg, setCurrentStateOrg] = useState(-1);
   const [alert, setAlert] = useState<any>(null);

   const [currentStateArray, setCurrentStateArray] = useState<number[]>([]);
   const { postShareReset } = postShareSlice.actions;
   const { userDetail } = useSelector((state: storeTypes.IStore) => state.userDetail);
   const { message: messageUserServiceOffered } = useSelector(
      (state: storeTypes.IStore) => state.serviceOffered.getUserServiceOffered
   );
   const { message: messagePopulationServed } = useSelector(
      (state: storeTypes.IStore) => state.populationServed.getUserPopulationServed
   );

   const nextDisplayStatus = props?.dobValid ? true : false;

   const { latinoReset } = latinoSlice.actions;

   const skipModal = (refresh?: boolean) => {
      props.closeModal(false, getUser(), refresh);
   };

   const UpdateCurrentState = (navigate: any) => {
      if (props?.isFromOrg) {
         setCurrentStateOrg(navigate);
      } else if (props?.isFromClinicalTrialsList) {
         setCurrentStateClinicalTrailsRemaining(navigate);
      } else if (props?.isFromClinicalTrials) {
         setCurrentStateClinicalTrails(navigate);
      } else if (props?.isFromFeedInitial) {
         setCurrentStateInitial(navigate);
      } else {
         setCurrentState(navigate);
      }
      dispatch(postShareReset());
   };

   const closeModelMyProfile = () => {
      props.closeMyProfileModal();
   };

   const currentModalClose = () => {
      setModalIsOpen(false);
   };

   const hideAlert = (type: string) => {
      if (type === 'fillProfileUpdateSuccess') {
         dispatch(latinoReset());
         skipModal(true);
      }
   };

   const finalMessage = () => {
      const getAlert = () => (
         <InfoTimerAlert
            success
            confirmBtnText="Okay!"
            confirmBtnBsStyle="successBtn"
            title={'Profile updated successfully'}
            onConfirm={() => hideAlert('fillProfileUpdateSuccess')}
         />
      );
      setAlert(getAlert);
   };

   // TODO
   // incase will use future
   // const postData = (postPhoneData: any) => {
   //    setResendOtp(postPhoneData);
   // };

   useEffect(() => {
      if (userDetail || messageUserServiceOffered || messagePopulationServed) {
         const { user: userData } = userDetail;
         if (!userData) {
            setModalIsOpen(false);
            return;
         }
         setModalIsOpen(true);
         if (!props?.isFromFeed) {
            return;
         }
         let currentStateList: number[] = [];

         if (props?.isFromOrg) {
            setCurrentStateOrg(
               fillProfileOrgInitial(userData, props?.isServiceOfferedValid, props?.isPopulationServedValid)
            );
            currentStateList = fillProfileOrgInitialArray(
               userData,
               props?.isServiceOfferedValid,
               props?.isPopulationServedValid
            );
         } else if (props?.isFromClinicalTrialsList) {
            setCurrentStateClinicalTrailsRemaining(
               clinicalTrailsRemainingFillProfile(
                  userData,
                  props?.isFromClinicalTrialsList,
                  props?.isServiceOfferedValid
               )
            );
            currentStateList = clinicalTrailsRemainingFillProfileArray(
               userData,
               props?.isFromClinicalTrialsList,
               props?.isServiceOfferedValid
            );
         } else if (props?.isFromClinicalTrials) {
            setCurrentStateClinicalTrails(clinicalTrailsFillProfile(userData));
            currentStateList = clinicalTrailsFillProfileArray(userData);
         } else if (props?.isFirst || props?.isFromFeedRemaining) {
            setCurrentState(fillProfile(userData, props?.isFirst, props?.isServiceOfferedValid));
            currentStateList = fillProfileArray(userData, props?.isFirst, props?.isServiceOfferedValid);
         } else if (props?.isFromFeedInitial) {
            const staticFillProfileInitial: number = fillProfileInitial(userData);
            if (staticFillProfileInitial === undefined) {
               setCurrentState(fillProfile(userData, props?.isFirst, props?.isServiceOfferedValid));
               currentStateList = fillProfileArray(userData, props?.isFirst, props?.isServiceOfferedValid);
               setCurrentStateInitial(-1);
            } else {
               setCurrentStateInitial(staticFillProfileInitial);
               currentStateList = fillProfileInitialArray(userData);
            }
         }
         setCurrentStateArray(currentStateList);
      }
   }, [userDetail, messageUserServiceOffered, messagePopulationServed]);

   useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
         document.body.style.overflow = 'scroll';
      };
   }, []);

   return (
      <div>
         {isLoader ? <Loading /> : null}
         {alert}
         <Modal
            isOpen={modalIsOpen}
            onRequestClose={currentModalClose}
            style={customStyles}
            contentLabel="AboutMe"
            className="ModalSection fill-profile"
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
         >
            <div className="row">
               <div className="modal-content">
                  <div className="modal-body">
                     {!props?.isFromFeed ? (
                        <div className="col-12 model-close-icon">
                           <div className="close-div" onClick={props?.closeMyProfileModal}>
                              <i className="material-icons">close</i>
                           </div>
                        </div>
                     ) : null}
                     {currentState === HOME_SECTION && props?.isFirst && (
                        <HomeSection
                           nexSection={DISPLAY_NAME_SECTION}
                           navigation={UpdateCurrentState}
                           isDisplay={props?.displayNameValid}
                           prevSection={!props?.displayNameValid ? DISPLAY_NAME_SECTION : CANCER_TYPE_SECTION}
                        />
                     )}
                     {currentState === HOME_SECTION_EXISTING_USER && !props?.isFirst && (
                        <ClinicalTrailsHomeSection
                           nexSection={DISPLAY_NAME_SECTION}
                           navigation={UpdateCurrentState}
                           setIsLoader={setIsLoader}
                           currentModalClose={currentModalClose}
                           skipModal={skipModal}
                           isFromClinicalTrialsList={props?.isFromClinicalTrialsList}
                        />
                     )}
                     {currentStateClinicalTrailsRemaining === CLINICAL_TRIALS_HOME_SECTION ? (
                        <ClinicalTrailsHomeSection
                           nexSection={
                              props?.isFromClinicalTrialsList
                                 ? CLINICAL_TRIALS_DIAGNOSIS_SECTION
                                 : CLINICAL_TRAILS_DISPLAY_NAME_SECTION
                           }
                           navigation={UpdateCurrentState}
                           isFromClinicalTrials={props?.isFromClinicalTrials}
                           setIsLoader={setIsLoader}
                           currentModalClose={currentModalClose}
                           skipModal={skipModal}
                           isFromClinicalTrialsList={props?.isFromClinicalTrialsList}
                           fillProfileModalClose={props?.fillProfileModalClose}
                        />
                     ) : null}
                     {(currentState === DISPLAY_NAME_SECTION ||
                        currentStateClinicalTrails === CLINICAL_TRAILS_DISPLAY_NAME_SECTION ||
                        currentStateInitial === INITIAL_DISPLAY_NAME_SECTION) && (
                        <DisplayNameSection
                           nexSection={CANCER_TYPE_SECTION}
                           nextSectionClinicalTrails={CLINICAL_TRAILS_CANCER_TYPE_SECTION}
                           nextSectionFillProfileInitial={INITIAL_CANCER_TYPE_SECTION}
                           setIsLoader={setIsLoader}
                           skipModal={skipModal}
                           setAlert={setAlert}
                           navigation={UpdateCurrentState}
                           currentModalClose={currentModalClose}
                           isDisplay={props?.displayNameValid}
                           isRaceValid={props?.isRaceValid}
                           isStagingValid={props?.isStagingValid}
                           isDiagnosisDateValid={props?.isDiagnosisDateValid}
                           isLatinoValid={props?.isLatinoValid}
                           userDetail={props?.userDetail}
                           isFromNotification={props?.isFromNotification}
                           isFromClinicalTrials={props?.isFromClinicalTrials}
                           isFromFeedInitial={props?.isFromFeedInitial}
                           currentStateArray={currentStateArray}
                           currentState={
                              currentState > -1
                                 ? currentState
                                 : currentStateClinicalTrails > -1
                                 ? currentStateClinicalTrails
                                 : currentStateInitial
                           }
                        />
                     )}
                     {currentState === CANCER_TYPE_SECTION ||
                     currentStateClinicalTrails === CLINICAL_TRAILS_CANCER_TYPE_SECTION ||
                     currentStateInitial === INITIAL_CANCER_TYPE_SECTION ? (
                        <CancerTypeSection
                           nexSection={DIAGNOSIS_SECTION}
                           nextSectionClinicalTrails={CLINICAL_TRAILS_ADDRESS_SECTION}
                           nextSectionFillProfileInitial={INITIAL_ADDRESS_SECTION}
                           navigation={UpdateCurrentState}
                           currentModalClose={currentModalClose}
                           setIsLoader={setIsLoader}
                           skipModal={skipModal}
                           setAlert={setAlert}
                           isDisplay={props?.displayNameValid}
                           isPrimary={props?.primaryCancerValid}
                           displayStatus={nextDisplayStatus}
                           isAddress={props?.addressValid}
                           isRaceValid={props?.isRaceValid}
                           isStagingValid={props?.isStagingValid}
                           isDiagnosisDateValid={props?.isDiagnosisDateValid}
                           isCancerStatusValid={props?.isCancerStatusValid}
                           userDetail={props?.userDetail}
                           isFromNotification={props?.isFromNotification}
                           isFromClinicalTrials={props?.isFromClinicalTrials}
                           isFromFeedInitial={props?.isFromFeedInitial}
                           currentStateArray={currentStateArray}
                           currentState={
                              currentState > -1
                                 ? currentState
                                 : currentStateClinicalTrails > -1
                                 ? currentStateClinicalTrails
                                 : currentStateInitial
                           }
                        />
                     ) : null}
                     {/* TODO
                      incase will use future */}
                     {/* {currentState === PHONE_SECTION && (
                        <PhoneSection
                           nexSection={OTP_SECTION}
                           setIsLoader={setIsLoader}
                           navigation={UpdateCurrentState}
                           postData={postData}
                        />
                     )}

                     {currentState === OTP_SECTION && (
                        <OtpSection
                           dobValid={props?.dobValid}
                           addressValid={props?.addressValid}
                           skipModal={skipModal}
                           currentModalClose={currentModalClose}
                           // setAlert={setAlert}
                           resendOtp={resendOtp}
                           nexSection={DOB_SECTION}
                           prevSection={PHONE_SECTION}
                           setIsLoader={setIsLoader}
                           navigation={UpdateCurrentState}
                        />
                     )} */}
                     {currentState === DIAGNOSIS_SECTION ||
                     currentStateClinicalTrailsRemaining === CLINICAL_TRIALS_DIAGNOSIS_SECTION ||
                     props?.isFromDiagnosis ? (
                        <DiagnosisSection
                           closeModelMyProfile={closeModelMyProfile}
                           isFromDiagnosis={props?.isFromDiagnosis}
                           userDetail={props?.userDetail}
                           navigation={UpdateCurrentState}
                           nexSection={STAGING_SECTION}
                           nextSectionClinicalTrails={CLINICAL_TRIALS_STAGING_SECTION}
                           setAlert={setAlert}
                           setIsLoader={setIsLoader}
                           skipModal={skipModal}
                           currentModalClose={currentModalClose}
                           isDisplay={props?.displayNameValid}
                           isPrimary={props?.primaryCancerValid}
                           isAddress={props?.addressValid}
                           isStagingValid={props?.isStagingValid}
                           isDiagnosisDateValid={props?.isDiagnosisDateValid}
                           isCancerStatusValid={props?.isCancerStatusValid}
                           isFromNotification={props?.isFromNotification}
                           isFromClinicalTrials={props?.isFromClinicalTrials}
                           getUserData={props.getUserData}
                           currentStateArray={currentStateArray}
                           currentState={currentState > -1 ? currentState : currentStateClinicalTrailsRemaining}
                        />
                     ) : null}
                     {currentState === STAGING_SECTION ||
                     currentStateClinicalTrailsRemaining === CLINICAL_TRIALS_STAGING_SECTION ||
                     props?.isFromStaging ? (
                        <StagingSection
                           closeModelMyProfile={closeModelMyProfile}
                           isFromStaging={props?.isFromStaging}
                           userDetail={props?.userDetail}
                           navigation={UpdateCurrentState}
                           nexSection={CANCER_STATUS_SECTION}
                           nextSectionClinicalTrails={CLINICAL_TRIALS_CANCER_STATUS_SECTION}
                           setAlert={setAlert}
                           setIsLoader={setIsLoader}
                           skipModal={skipModal}
                           currentModalClose={currentModalClose}
                           isDisplay={props?.displayNameValid}
                           isPrimary={props?.primaryCancerValid}
                           isAddress={props?.addressValid}
                           isStagingValid={props?.isStagingValid}
                           isDiagnosisDateValid={props?.isDiagnosisDateValid}
                           isCancerStatusValid={props?.isCancerStatusValid}
                           isFromNotification={props?.isFromNotification}
                           isFromClinicalTrials={props?.isFromClinicalTrials}
                           getUserData={props.getUserData}
                           currentStateArray={currentStateArray}
                           currentState={currentState > -1 ? currentState : currentStateClinicalTrailsRemaining}
                        />
                     ) : null}
                     {currentState === CANCER_STATUS_SECTION ||
                     currentStateClinicalTrailsRemaining === CLINICAL_TRIALS_CANCER_STATUS_SECTION ||
                     props?.isFromCancerStatus ? (
                        <CancerStatusSection
                           closeModelMyProfile={closeModelMyProfile}
                           isFromCancerStatus={props?.isFromCancerStatus}
                           userDetail={props?.userDetail}
                           navigation={UpdateCurrentState}
                           nexSection={ADDRESS_SECTION}
                           nextSectionClinicalTrails={CLINICAL_TRIALS_SERVICE_OFFERED_SECTION}
                           setAlert={setAlert}
                           setIsLoader={setIsLoader}
                           skipModal={skipModal}
                           currentModalClose={currentModalClose}
                           isDisplay={props?.displayNameValid}
                           isPrimary={props?.primaryCancerValid}
                           isAddress={props?.addressValid}
                           isStagingValid={props?.isStagingValid}
                           isDiagnosisDateValid={props?.isDiagnosisDateValid}
                           isLatinoValid={props?.isLatinoValid}
                           isCancerStatusValid={props?.isCancerStatusValid}
                           isFromNotification={props?.isFromNotification}
                           isFromClinicalTrials={props?.isFromClinicalTrials}
                           getUserData={props.getUserData}
                           currentStateArray={currentStateArray}
                           currentState={currentState > -1 ? currentState : currentStateClinicalTrailsRemaining}
                        />
                     ) : null}
                     {currentState === ADDRESS_SECTION ||
                     currentStateClinicalTrails === CLINICAL_TRAILS_ADDRESS_SECTION ||
                     currentStateInitial === INITIAL_ADDRESS_SECTION ? (
                        <AddressSection
                           setIsLoader={setIsLoader}
                           skipModal={skipModal}
                           navigation={UpdateCurrentState}
                           setAlert={setAlert}
                           currentModalClose={currentModalClose}
                           userDetail={props?.userDetail}
                           nexSection={DOB_SECTION}
                           nextSectionClinicalTrails={CLINICAL_TRIALS_DOB_SECTION}
                           isAddress={props?.addressValid}
                           isFromClinicalTrials={props?.isFromClinicalTrials}
                           isFromFeedInitial={props?.isFromFeedInitial}
                           finalMessage={finalMessage}
                           currentStateArray={currentStateArray}
                           currentState={
                              currentState > -1
                                 ? currentState
                                 : currentStateClinicalTrails > -1
                                 ? currentStateClinicalTrails
                                 : currentStateInitial
                           }
                        />
                     ) : null}
                     {currentState === DOB_SECTION || currentStateClinicalTrails === CLINICAL_TRIALS_DOB_SECTION ? (
                        <DobSection
                           nexSection={SERVICE_OFFERED_SECTION}
                           dobValid={props?.dobValid}
                           navigation={UpdateCurrentState}
                           setIsLoader={setIsLoader}
                           skipModal={skipModal}
                           userDetail={props?.userDetail}
                           setAlert={setAlert}
                           currentModalClose={currentModalClose}
                           isFromNotification={props?.isFromNotification}
                           isFromClinicalTrials={props?.isFromClinicalTrials}
                           isFromClinicalTrialsHome={props?.isFromClinicalTrialsHome}
                           finalMessage={finalMessage}
                           currentStateArray={currentStateArray}
                           currentState={currentState > -1 ? currentState : currentStateClinicalTrails}
                        />
                     ) : null}
                     {currentState === RACE_SECTION ||
                     currentStateClinicalTrailsRemaining === CLINICAL_TRIALS_RACE_SECTION ||
                     props?.isFromRace ? (
                        <RaceSection
                           closeModelMyProfile={closeModelMyProfile}
                           isFromRace={props?.isFromRace}
                           userDetail={props?.userDetail}
                           navigation={UpdateCurrentState}
                           nexSection={LATINO_SECTION}
                           nextSectionClinicalTrails={CLINICAL_TRIALS_LATINO_SECTION}
                           setAlert={setAlert}
                           setIsLoader={setIsLoader}
                           skipModal={skipModal}
                           currentModalClose={currentModalClose}
                           isRaceValid={props?.isRaceValid}
                           isFromClinicalTrials={props?.isFromClinicalTrials}
                           getUserData={props.getUserData}
                           currentStateArray={currentStateArray}
                           currentState={currentState > -1 ? currentState : currentStateClinicalTrailsRemaining}
                        />
                     ) : null}
                     {currentState === LATINO_SECTION ||
                     currentStateClinicalTrailsRemaining === CLINICAL_TRIALS_LATINO_SECTION ||
                     props?.isFromLatino ? (
                        <LatinoSection
                           closeModelMyProfile={closeModelMyProfile}
                           isFromLatino={props?.isFromLatino}
                           userDetail={props?.userDetail}
                           navigation={UpdateCurrentState}
                           // nexSection={CANCER_TYPE_SECTION}
                           setAlert={setAlert}
                           finalMessage={finalMessage}
                           setIsLoader={setIsLoader}
                           skipModal={skipModal}
                           currentModalClose={currentModalClose}
                           isLatinoValid={props?.isLatinoValid}
                           isFromNotification={props?.isFromNotification}
                           isFromClinicalTrials={props?.isFromClinicalTrials}
                           getUserData={props.getUserData}
                           currentStateArray={currentStateArray}
                           currentState={currentState > -1 ? currentState : currentStateClinicalTrailsRemaining}
                        />
                     ) : null}

                     {currentStateOrg === ORG_USER_NAME ? (
                        <OrganizationNameSection
                           userDetail={props?.userDetail}
                           navigation={UpdateCurrentState}
                           nexSectionOrg={ORG_USER_SERVICES_OFFERED}
                           currentStateArray={currentStateArray}
                           currentState={currentState > -1 ? currentState : currentStateOrg}
                           setIsLoader={setIsLoader}
                           isOrgNameValid={props?.isOrgNameValid}
                        />
                     ) : null}
                     {currentStateOrg === ORG_USER_SERVICES_OFFERED ||
                     currentState === SERVICE_OFFERED_SECTION ||
                     currentStateClinicalTrailsRemaining === CLINICAL_TRIALS_SERVICE_OFFERED_SECTION ||
                     props?.isFromServiceOffered ? (
                        <ServicesOfferedSection
                           userDetail={props?.userDetail}
                           getUserData={props.getUserData}
                           navigation={UpdateCurrentState}
                           isFromServiceOffered={props?.isFromServiceOffered}
                           nexSectionOrg={ORG_USER_POPULATION_SERVED}
                           nexSection={RACE_SECTION}
                           nextSectionClinicalTrails={CLINICAL_TRIALS_RACE_SECTION}
                           currentStateArray={currentStateArray}
                           currentState={currentState > -1 ? currentState : currentStateOrg}
                           setIsLoader={setIsLoader}
                           isServiceOfferedValid={props?.isServiceOfferedValid}
                           isFromClinicalTrials={props?.isFromClinicalTrials}
                           isFromOrg={props?.isFromOrg}
                           setAlert={setAlert}
                           currentModalClose={currentModalClose}
                           closeModelMyProfile={closeModelMyProfile}
                           getUserServiceOfferedFun={props?.getUserServiceOfferedFun}
                        />
                     ) : null}
                     {currentStateOrg === ORG_USER_POPULATION_SERVED || props?.isFromPopulationServed ? (
                        <PopulationServedSection
                           userDetail={props?.userDetail}
                           getUserData={props.getUserData}
                           navigation={UpdateCurrentState}
                           currentStateArray={currentStateArray}
                           finalMessage={finalMessage}
                           setIsLoader={setIsLoader}
                           currentState={currentState > -1 ? currentState : currentStateOrg}
                           isFromPopulationServed={props?.isFromPopulationServed}
                           setAlert={setAlert}
                           currentModalClose={currentModalClose}
                           closeModelMyProfile={closeModelMyProfile}
                           populationServedList={props?.populationServedList}
                           isPopulationServedValid={props?.isPopulationServedValid}
                        />
                     ) : null}
                  </div>
                  {!props?.isFromFeed ? null : (
                     <div className="footer">
                        <MobileStepper
                           variant="dots"
                           steps={currentStateArray?.length || 0}
                           position="static"
                           activeStep={
                              currentStateArray?.length > 0
                                 ? props?.isFromOrg
                                    ? currentStateArray.findIndex((obj: number) => obj === currentStateOrg)
                                    : props?.isFromClinicalTrialsList
                                    ? currentStateArray.findIndex(
                                         (obj: number) => obj === currentStateClinicalTrailsRemaining
                                      )
                                    : props?.isFromClinicalTrials
                                    ? currentStateArray.findIndex((obj: number) => obj === currentStateClinicalTrails)
                                    : props?.isFromFeedInitial
                                    ? currentStateArray.findIndex((obj: number) => obj === currentStateInitial)
                                    : currentStateArray.findIndex((obj: number) => obj === currentState)
                                 : 0
                           }
                           className="stepper"
                           backButton={null}
                           nextButton={null}
                        />
                        {props?.isFromClinicalTrials || props?.isFromFeed ? null : (
                           <div className="skip-css">
                              {FILL_PROFILE_SKIP_ARRAY.includes(currentState) ? (
                                 <label
                                    htmlFor="title"
                                    className="skip hand-i"
                                    aria-hidden="true"
                                    onClick={() => {
                                       if (currentState === DOB_SECTION) {
                                          setCurrentState(RACE_SECTION);
                                       } else if (currentState === RACE_SECTION) {
                                          setCurrentState(LATINO_SECTION);
                                       } else if (currentState === LATINO_SECTION) {
                                          skipModal();
                                       }
                                    }}
                                 >
                                    <span className="skip-text">Skip</span>&nbsp;
                                    <i className="fa fa-chevron-right" aria-hidden="true" />
                                    <i className="fa fa-chevron-right" aria-hidden="true" />
                                 </label>
                              ) : null}
                           </div>
                        )}
                     </div>
                  )}
               </div>
            </div>
         </Modal>
      </div>
   );
};

export default ProfileLayout;
