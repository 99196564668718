import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ONCLICK_ALL_READ } from 'reduxToolKit/actions';
import { commonSlice, notificationReadAllSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   notificationReadAllRequest,
   notificationReadAllSuccess,
   notificationReadAllFailed,
} = notificationReadAllSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const notificationSaga = function* () {
   yield put(notificationReadAllRequest());
   try {
      const checkUser = true;
      const goUrl = true;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, ...API.notificationAllRead });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: null,
         };
         yield put(notificationReadAllSuccess(resObj));
      } else {
         yield put(notificationReadAllFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeNotificationAllRead = function* () {
   yield takeEvery(ONCLICK_ALL_READ, notificationSaga);
};
