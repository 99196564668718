import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   ADD_EDIT_SYMPTOM_MONITORING_COMMENTS,
   GET_SYMPTOM_MONITORING_COMMENT,
   SYMPTOM_MONITORING_COMMENTS_CLOSE,
} from 'reduxToolKit/actions';
import { commonSlice, symptomMonitoringCommentsSlice } from 'reduxToolKit/reducers';
import { symptomMonitoringCommentReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getSymptomMonitoringCommentsRequest,
   getSymptomMonitoringCommentsSuccess,
   getSymptomMonitoringCommentsFailed,
   symptomMonitoringCommentCloseRequest,
   symptomMonitoringCommentCloseSuccess,
   symptomMonitoringCommentCloseFailed,
   addEditSymptomMonitoringCommentRequest,
   addEditSymptomMonitoringCommentSuccess,
   addEditSymptomMonitoringCommentFailed,
} = symptomMonitoringCommentsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getSymptomMonitoringCommentSaga = function* (
   payloadState: PayloadAction<symptomMonitoringCommentReducerTypes.ISymptomMonitoringComment>
) {
   yield put(getSymptomMonitoringCommentsRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const payloadData = payloadState.payload;
      const params = {
         page: payloadData.page,
         limit: payloadData.limit,
      };
      const apiPath = {
         apiPath: `${API.getSymptomMonitoringComment.apiPath}${String(payloadData.symptom_monitoring_id)}`,
         action: API.getSymptomMonitoringComment.action,
      };

      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...apiPath });
      if (response.status === 200) {
         const responseData = {
            list: response.data?.data?.list,
            message: response.data?.message || messages.success,
            total_records: response.data?.data?.total_records,
         };
         yield put(getSymptomMonitoringCommentsSuccess(responseData));
      } else {
         yield put(getSymptomMonitoringCommentsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const symptomMonitoringCommentsCloseSaga = function* (
   payloadState: PayloadAction<symptomMonitoringCommentReducerTypes.ISymptomMonitoringComment>
) {
   yield put(symptomMonitoringCommentCloseRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         goUrl,
         data,
         ...API.symptomMonitoringCommentsClose,
      });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(symptomMonitoringCommentCloseSuccess(responseData));
      } else {
         yield put(symptomMonitoringCommentCloseFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const addEditSymptomMonitoringComments = function* (
   payloadState: PayloadAction<symptomMonitoringCommentReducerTypes.IAddEditSymptomMonitoringComments>
) {
   yield put(addEditSymptomMonitoringCommentRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const requestData = payloadState.payload;
      let data;
      let customApiUrl;

      if (requestData.isEditComment) {
         data = {
            id: requestData.id || 0,
            comment: requestData.comment,
            minutes: requestData.minutes,
            is_audio_video_selected: requestData.is_audio_video_selected,
            medias: requestData.medias,
            media_ids: requestData.media_ids,
         };
         customApiUrl = API.editSymptomMonitoringComments;
      } else {
         data = {
            symptom_monitoring_id: requestData.symptom_monitoring_id,
            comment: requestData.comment,
            minutes: requestData.minutes,
            is_audio_video_selected: requestData.is_audio_video_selected,
            medias: requestData.medias,
         };
         customApiUrl = API.addSymptomMonitoringComments;
      }

      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...customApiUrl });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(addEditSymptomMonitoringCommentSuccess(responseData));
      } else {
         yield put(addEditSymptomMonitoringCommentFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeSymptomMonitoringCommentSaga = function* () {
   yield takeEvery(GET_SYMPTOM_MONITORING_COMMENT, getSymptomMonitoringCommentSaga);
   yield takeEvery(SYMPTOM_MONITORING_COMMENTS_CLOSE, symptomMonitoringCommentsCloseSaga);
   yield takeEvery(ADD_EDIT_SYMPTOM_MONITORING_COMMENTS, addEditSymptomMonitoringComments);
};
