import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { USER_INVITE_EMAIL, USER_INVITE_EMAIL_VALIDATION } from 'reduxToolKit/actions';
import { commonSlice, userInviteEmailSlice, userInviteEmailValidationSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';
//import { viewAppointmentSagaTypes } from 'types';

const { userInviteEmailView, userInviteEmailSuccess, userInviteEmailFailed } = userInviteEmailSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const userInviteEmail = function* (payloadId: any) {
   yield put(userInviteEmailView(payloadId.payload));
   try {
      const data = { invite_users: payloadId.payload };
      const checkUser = true;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...API.userInvite });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(userInviteEmailSuccess(responseData));
      } else {
         yield put(userInviteEmailFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   userInviteEmailValidationView,
   userInviteEmailValidationSuccess,
   userInviteEmailValidationFailed,
} = userInviteEmailValidationSlice.actions;

const userInviteEmailValidation = function* (payloadId: any) {
   yield put(userInviteEmailValidationView(payloadId.payload));
   try {
      const data = payloadId.payload;
      const checkUser = true;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...API.userInviteValidation });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(userInviteEmailValidationSuccess(responseData));
      } else {
         yield put(userInviteEmailValidationFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeInviteUserEmailRequest = function* () {
   yield takeEvery(USER_INVITE_EMAIL, userInviteEmail);
   yield takeEvery(USER_INVITE_EMAIL_VALIDATION, userInviteEmailValidation);
};
