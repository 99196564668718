import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';

const PillOthers = (props: any) => {
   const [othersType, setOthersType] = useState('');
   const [errorMessage, setErrorMessage] = useState('');

   const clickOthersType = (othersType: string) => {
      setOthersType(othersType);
      setErrorMessage('');
   };

   const clickNext = useCallback(() => {
      if (othersType) {
         props.pillRemainderOthersType(othersType);
      } else {
         setErrorMessage('Please select any one others type');
      }
   }, [othersType]);

   useEffect(() => {
      if (props.reminderDays > 0) {
         if (props.reminderDays === 28 || props.reminderDays === 1 || props.reminderDays === 2) {
            setOthersType('');
         } else if (props.reminderDays > 2 && props.reminderDays <= 27) {
            setOthersType('every_number_of_days');
         }
      } else {
         setOthersType('');
      }
   }, [props.reminderDays]);

   useEffect(() => {
      setOthersType(props?.othersType);
   }, []);

   return (
      <Box>
         <Typography align="center" className="pill-add-text">
            How often do you take it?
         </Typography>
         <Box width="71%" ml={8} mt={2}>
            <Grid container direction="column" spacing={2}>
               <Grid item>
                  <Button
                     variant={othersType === 'specific_days_of_the_week' ? 'contained' : 'outlined'}
                     fullWidth
                     className="pill-remainder-others-btn"
                     onClick={() => {
                        clickOthersType('specific_days_of_the_week');
                     }}
                  >
                     Specific days of the week
                  </Button>
               </Grid>
               <Grid item>
                  <Button
                     variant={othersType === 'every_number_of_days' ? 'contained' : 'outlined'}
                     fullWidth
                     className="pill-remainder-others-btn"
                     onClick={() => {
                        clickOthersType('every_number_of_days');
                     }}
                  >
                     Every number of days
                  </Button>
               </Grid>
            </Grid>
         </Box>
         {errorMessage && <Box className="errorMsg errorMsgTextAlign">{errorMessage}</Box>}
         <Box mb={4} mx="auto" mt={5}>
            <Grid container direction="row" spacing={3} justify="center">
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="pill-add-back-button"
                     fullWidth
                     onClick={() => {
                        if (props?.type === 'others') {
                           props?.pillRemainderOthersTypeBack(othersType, props?.type, 'first_dose_time');
                        }
                     }}
                  >
                     Back
                  </Button>
               </Grid>
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="telemedicine__modal--btn"
                     fullWidth
                     onClick={clickNext}
                  >
                     Next
                  </Button>
               </Grid>
            </Grid>
         </Box>
      </Box>
   );
};

export { PillOthers };
