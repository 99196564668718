import { createSlice } from '@reduxjs/toolkit';
import { IVideoLibraryListReducer } from 'types/videoLibrary/videoLibraryReducerTypes';
import { messages } from 'utils/constants/messages';

const initialState: IVideoLibraryListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   pagination: {
      current_page: null,
      last_page: null,
      first_page_url: '',
      last_page_url: '',
      next_page_url: '',
      prev_page_url: '',
      total: 0,
   },
};

export const videoLibraryListSlice = createSlice({
   name: 'videoLibraryList',
   initialState: initialState,
   reducers: {
      videoLibraryListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
            list: state.list,
            pagination: state.pagination,
         };
      },
      videoLibraryListViewRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      videoLibraryListViewSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            pagination: { ...payload.pagination },
            list: payload.pagination.current_page === 1 ? payload.list : [...state.list, ...payload.list],
            message: payload.message,
            isLoading: false,
         };
      },
      videoLibraryListViewFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error || messages.error,
         };
      },
      videoLibraryListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
