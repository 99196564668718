import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { locumReducerTypes, locumSagaTypes } from 'types';

const initialState: locumReducerTypes.IGetStateAndTravelReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};

export const settingAddStateAndTravelSlice = createSlice({
   name: 'settingAddStateAndTravel',
   initialState: initialState,
   reducers: {
      settingAddStateAndTravelRequest: (state, action: PayloadAction<locumSagaTypes.AddStateAndTravelSagaParams>) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: true,
         };
      },
      settingAddStateAndTravelSuccess: (state, action: PayloadAction<locumReducerTypes.IGetStateAndTravelReducer>) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      settingAddStateAndTravelFailed: (state, action: PayloadAction<locumReducerTypes.IGetStateAndTravelReducer>) => {
         const { payload } = action;
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: payload.error,
         };
      },
      settingAddStateAndTravelReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
