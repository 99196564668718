import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { DEVICE_GET_MY_SMART_WEIGHT, DEVICE_GET_SMART_WEIGHT_STATISTICS } from 'reduxToolKit/actions';
import { deviceSmartWeightSlice, commonSlice } from 'reduxToolKit/reducers';
import { deviceSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   mySmartWeightRequest,
   mySmartWeightSuccess,
   mySmartWeightFailed,
   mySmartWeightStatisticsRequest,
   mySmartWeightStatisticsSuccess,
   mySmartWeightStatisticsFailed,
} = deviceSmartWeightSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const smartWeightSaga = function* (payloadState: PayloadAction<deviceSagaTypes.IGetBloodPressure>) {
   yield put(mySmartWeightRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getMySmartWeight });
      if (response.status === 200) {
         const resObj = {
            message: response.data?.message || messages.success,
            data: response.data?.data,
         };
         yield put(mySmartWeightSuccess(resObj));
      } else {
         yield put(mySmartWeightFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const smartWeightStatisticsSaga = function* (payloadState: PayloadAction<deviceSagaTypes.IGetBloodPressure>) {
   yield put(mySmartWeightStatisticsRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getSmartWeightStatistics });
      if (response.status === 200) {
         const resObj = {
            message: response.data?.message || messages.success,
            data: response.data?.data,
         };
         yield put(mySmartWeightStatisticsSuccess(resObj));
      } else {
         yield put(mySmartWeightStatisticsFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeSmartWeightSagaSaga = function* () {
   yield takeEvery(DEVICE_GET_MY_SMART_WEIGHT, smartWeightSaga);
   yield takeEvery(DEVICE_GET_SMART_WEIGHT_STATISTICS, smartWeightStatisticsSaga);
};
