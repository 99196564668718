import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { TAG_USERS_LIST } from 'reduxToolKit/actions';
import { commonSlice, tagUsersListSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { tagUsersListRequest, tagUsersListSuccess, tagUsersListFailed } = tagUsersListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const tagUsersListSaga = function* (tagUsersListParams: PayloadAction<feedSagaTypes.ITagUsersListSagaRequest>) {
   yield put(tagUsersListRequest());
   try {
      const data = tagUsersListParams.payload;
      const checkUser = true;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...API.getTagUsersList });
      if (response.status === 200) {
         const responseData = response.data.data;
         const tagUsersList = {
            list: responseData.data,
            message: response.data?.message,
            requestData: data,
         };
         yield put(tagUsersListSuccess(tagUsersList));
      } else {
         yield put(tagUsersListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeTagUsersList = function* () {
   yield takeEvery(TAG_USERS_LIST, tagUsersListSaga);
};
