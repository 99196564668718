import { createAction } from '@reduxjs/toolkit';
import { scheduleSagaTypes } from 'types';

export const CHECKAPPOINMENT_AVAILABILITY = 'CHECKAPPOINMENT_AVAILABILITY';
export const checkAppointmentAvailabilityAction = createAction<scheduleSagaTypes.IScheduleForms>(
   CHECKAPPOINMENT_AVAILABILITY
);

export const GET_SCHEDULE = 'GET_SCHEDULE';
export const getScheduleAction = createAction<scheduleSagaTypes.IScheduleForms>(GET_SCHEDULE);

export const CHECKSCHEDULE_BLOCK = 'CHECKSCHEDULE_BLOCK';
export const CheckScheduleBlockAction = createAction<scheduleSagaTypes.ICheckScheduleBlock>(CHECKSCHEDULE_BLOCK);

export const ADD_SCHEDULE = 'ADD_SCHEDULE';
export const addScheduleAction = createAction<scheduleSagaTypes.IAddSchedule>(ADD_SCHEDULE);
