import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deviceClinicNotesReducerTypes, deviceClinicNotesSagaTypes } from 'types';

const initialState: deviceClinicNotesReducerTypes.IDeviceClinicNoteCommentsReducerTypes = {
   isLoading: false,
   clinicNoteComments: {
      message: '',
      list: [],
      total_records: 0,
      error: '',
   },
   addEditclinicNoteComment: {
      message: '',
      error: '',
   },
   clinicNoteCommentClose: {
      message: '',
      error: '',
   },
};

export const deviceClinicNotesCommentsSlice = createSlice({
   name: 'deviceClinicNoteComments',
   initialState: initialState,
   reducers: {
      modalDeviceAddClinicNoteRequest: (
         state,
         action: PayloadAction<deviceClinicNotesSagaTypes.IDeviceClinicAddNoteSagaPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },

      modalDeviceEditClinicNoteRequest: (
         state,
         action: PayloadAction<deviceClinicNotesSagaTypes.IDeviceClinicEditNoteSagaPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },

      getClinicNoteCommentsRequest: (
         state,
         action: PayloadAction<deviceClinicNotesSagaTypes.IDeviceClinicNoteGetComments>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getClinicNoteCommentsSuccess: (
         state,
         action: PayloadAction<deviceClinicNotesReducerTypes.IDeviceClinicNoteCommentsGetSuccessPayload>
      ) => {
         return {
            ...state,
            isLoading: true,
            clinicNoteComments: {
               message: action.payload?.message,
               list: action.payload?.list,
               total_records: action.payload?.total_records,
               error: '',
            },
         };
      },
      getClinicNoteCommentsFailed: (
         state,
         action: PayloadAction<deviceClinicNotesReducerTypes.IDeviceClinicNoteCommentsFailurePayload>
      ) => {
         return {
            ...state,
            isLoading: true,
            clinicNoteComments: {
               error: action.payload?.error,
               message: '',
               list: [],
               total_records: 0,
            },
         };
      },

      addEditclinicNotecommentRequest: (
         state,
         action: PayloadAction<deviceClinicNotesSagaTypes.IDeviceClinicNoteAddEditComment>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addEditclinicNotecommentSuccess: (
         state,
         action: PayloadAction<deviceClinicNotesReducerTypes.IDeviceClinicNoteCommentSuccessPayload>
      ) => {
         return {
            ...state,
            isLoading: true,
            addEditclinicNoteComment: {
               message: action.payload?.message,
               error: '',
            },
         };
      },
      addEditclinicNotecommentFailed: (
         state,
         action: PayloadAction<deviceClinicNotesReducerTypes.IDeviceClinicNoteCommentsFailurePayload>
      ) => {
         return {
            ...state,
            isLoading: true,
            addEditclinicNoteComment: {
               error: action.payload?.error,
               message: '',
            },
         };
      },

      clinicNoteCommentCloseRequest: (
         state,
         action: PayloadAction<deviceClinicNotesSagaTypes.IDeviceClinicNoteEndSession>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      clinicNoteCommentCloseSuccess: (
         state,
         action: PayloadAction<deviceClinicNotesReducerTypes.IDeviceClinicNoteCommentSuccessPayload>
      ) => {
         return {
            ...state,
            isLoading: true,
            clinicNoteCommentClose: {
               message: action.payload?.message,
               error: '',
            },
         };
      },
      clinicNoteCommentCloseFailed: (
         state,
         action: PayloadAction<deviceClinicNotesReducerTypes.IDeviceClinicNoteCommentsFailurePayload>
      ) => {
         return {
            ...state,
            isLoading: true,
            clinicNoteCommentClose: {
               error: action.payload?.error,
               message: '',
            },
         };
      },

      deviceClinicNotesCommentsReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
