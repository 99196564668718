import { createSlice } from '@reduxjs/toolkit';
import { medicalEnqReducerTypes } from 'types';

const initialState: medicalEnqReducerTypes.IMedicalEnqDocumentsListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   pagination: {
      current_page: null,
      last_page: null,
      first_page_url: '',
      last_page_url: '',
      next_page_url: '',
      prev_page_url: '',
      total: 0,
   },
};
const medicalEnqDocumentsListSlice = createSlice({
   name: 'medicalEnquiresDocumentsList',
   initialState: initialState,
   reducers: {
      medicalEnqDocumentsListViewRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      medicalEnqDocumentsListViewSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            pagination: { ...payload.pagination },
            list: payload.pagination.current_page === 1 ? payload.list : [...state.list, ...payload.list],
            isLoading: false,
         };
      },
      medicalEnqDocumentsListViewFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload,
         };
      },
      medicalEnqDocumentsListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

const initialStateView: medicalEnqReducerTypes.IMedicalEnqViewDocumentReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};

const medicalEnqViewDocumentSlice = createSlice({
   name: 'medicalEnqViewDocument',
   initialState: initialStateView,
   reducers: {
      medicalEnqViewDocumentView: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      medicalEnqViewDocumentViewSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            data: payload,
            isLoading: false,
         };
      },
      medicalEnqViewDocumentViewFailed: (state, action) => {
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: action.payload,
         };
      },
      medicalEnqViewDocumentReset: () => {
         return {
            ...initialStateView,
         };
      },
   },
});

const initialStateUpdate: medicalEnqReducerTypes.IMedicalEnqUpdateDocumentReducer = {
   isLoading: false,
   error: '',
   message: '',
   id: '',
   data: null,
   isMedicalEnqtDocUpdate: null,
};

const medicalEnqUpdateDocumentSlice = createSlice({
   name: 'medicalEnqUpdateDocument',
   initialState: initialStateUpdate,
   reducers: {
      medicalEnqUpdateDocumentView: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            isMedicalEnqtDocUpdate: null,
         };
      },

      medicalEnqUpdateDocumentSuccess: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
            isMedicalEnqtDocUpdate: true,
         };
      },
      medicalEnqUpdateDocumentFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: 'Something went wrong',
            error: action.payload,
            isMedicalEnqtDocUpdate: false,
         };
      },
      medicalEnqUpdateDocumentReset: () => {
         return {
            ...initialStateUpdate,
         };
      },
   },
});

export { medicalEnqDocumentsListSlice, medicalEnqUpdateDocumentSlice, medicalEnqViewDocumentSlice };
