import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINIC_INVITE_PATIENT } from 'reduxToolKit/actions';
import { clinicInvitePatientsSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   clinicInvitePatientsLoading,
   clinicInvitePatientsViewRequest,
   clinicInvitePatientsViewSuccess,
   clinicInvitePatientsFailed,
} = clinicInvitePatientsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const clinicInvitePatientsSaga = function* (payloadState: any) {
   yield put(clinicInvitePatientsLoading());
   yield put(clinicInvitePatientsViewRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.clinicInvitePatients });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            message: response.data?.message,
         };
         yield put(clinicInvitePatientsViewSuccess(oncDriveList));
      } else {
         yield put(clinicInvitePatientsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeClinicInvitePatients = function* () {
   yield takeEvery(CLINIC_INVITE_PATIENT, clinicInvitePatientsSaga);
};
