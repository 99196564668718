import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { VIDEO_CONNECTION } from 'reduxToolKit/actions';
import { commonSlice, videoCallConnectionSlice } from 'reduxToolKit/reducers';
import { joinVideoCallSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { videoCallConnection, videoCallConnectionSuccess, videoCallConnectionFailed } = videoCallConnectionSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const videoCallConnections = function* (payload: joinVideoCallSagaTypes.IGetVideoConnection) {
   yield put(videoCallConnection(payload));
   try {
      const apiPath = {
         apiPath: `${API.videoConnection.apiPath}${'/'}${payload.payload}${'/connection'}`,
         action: API.videoConnection.action,
      };
      const response: AxiosResponse = yield apiCall({ ...apiPath });
      if (response.status === 200) {
         yield put(videoCallConnectionSuccess(response.data));
      } else {
         yield put(videoCallConnectionFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeVideoCallConnectionRequest = function* () {
   yield takeEvery(VIDEO_CONNECTION, videoCallConnections);
};
