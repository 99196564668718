import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { ADMIN_NOTIFICATION_COUNT } from 'reduxToolKit/actions';
import { commonSlice, adminNotificationSlice } from 'reduxToolKit/reducers';
import { notificationSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { adminNotificationRequest, adminNotificationSuccess, adminNotificationFailed } = adminNotificationSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const adminNotification = function* (
   adminNotificationPayload: PayloadAction<notificationSagaTypes.IAdminNotificationSagaTypes>
) {
   yield put(adminNotificationRequest(adminNotificationPayload.payload));
   try {
      const peopleMayYouKnow = true;
      const data = adminNotificationPayload.payload;
      const response: AxiosResponse = yield apiCall({ peopleMayYouKnow, data, ...API.adminNotification });
      if (response.status === 200) {
         yield put(adminNotificationSuccess());
      } else {
         yield put(adminNotificationFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAdminNotification = function* () {
   yield takeLatest(ADMIN_NOTIFICATION_COUNT, adminNotification);
};
