import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { physicianJobsReducerTypes, physicianJobsSagaTypes } from 'types';

const initialState: physicianJobsReducerTypes.IPhysicianJobFavouriteReducerType = {
   isLoading: false,
   message: '',
   error: '',
};

export const physicianJobFavouriteSlice = createSlice({
   name: 'physicianJobFavourite',
   initialState: initialState,
   reducers: {
      physicianFavouriteJobsRequest: (
         state,
         action: PayloadAction<physicianJobsSagaTypes.IPhysicianJobsAddRmFavourite>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      physicianFavouriteJobsSuccess: (
         state,
         action: PayloadAction<physicianJobsReducerTypes.IPhysicianJobsActionsPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      physicianFavouriteJobsFailed: (
         state,
         action: PayloadAction<physicianJobsReducerTypes.IPhysicianJobAddRmFavouriteFailurePayload>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      physicianFavouriteJobsReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
