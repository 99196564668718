import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notificationReducerTypes, notificationSagaTypes } from 'types';

const initialState: notificationReducerTypes.INotificationSettingsReducerTypes = {
   list: {
      data: null,
      message: '',
      error: '',
   },
   updateSettings: {
      isLoading: false,
      message: '',
      error: '',
   },
   deleteDeviceToken: {
      isLoading: false,
      message: '',
      error: '',
   },
   getOne: {
      isLoading: false,
      data: null,
      message: '',
      error: '',
   },
};

export const notificationSettingsListSlice = createSlice({
   name: 'notificationSettingsList',
   initialState: initialState,
   reducers: {
      notificationSettingsListViewRequest: (
         state,
         action: PayloadAction<notificationSagaTypes.INotificationSettingsSagaPayload>
      ) => {
         return {
            ...state,
            list: {
               ...state.list,
               ...action.payload,
            },
         };
      },
      notificationSettingsListViewSuccess: (
         state,
         action: PayloadAction<notificationReducerTypes.INotificationSettingsSuccessPayload>
      ) => {
         return {
            ...state,
            list: {
               ...state.list,
               ...action.payload,
            },
         };
      },
      notificationSettingsListFailed: (
         state,
         action: PayloadAction<notificationReducerTypes.INotificationSetingsFailurePayload>
      ) => {
         return {
            ...state,
            list: {
               ...state.list,
               error: action.payload.error,
            },
         };
      },

      notificationSettingsListReset: (state) => {
         return {
            ...state,
            list: {
               ...initialState.list,
            },
         };
      },
      //update notification list
      updateNotificationSettingsRequest: (
         state,
         action: PayloadAction<notificationSagaTypes.INotificationSettingsUpdateSagaPayload>
      ) => {
         return {
            ...state,
            updateSettings: {
               ...state.updateSettings,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      updateNotificationSettingsSuccess: (
         state,
         action: PayloadAction<notificationReducerTypes.IUpdateNotificationSettingsSuccessPayload>
      ) => {
         return {
            ...state,
            updateSettings: {
               ...state.updateSettings,
               ...action.payload,
            },
         };
      },
      updateNotificationSettingsFailed: (
         state,
         action: PayloadAction<notificationReducerTypes.IUpdateNotificationSetingsFailurePayload>
      ) => {
         return {
            ...state,
            updateSettings: {
               ...state.updateSettings,
               error: action.payload.error,
            },
         };
      },

      updateNotificationSettingsReset: (state) => {
         return {
            ...state,
            updateSettings: {
               ...initialState.updateSettings,
            },
         };
      },
      // notification delete device token
      deleteDeviceTokenRequest: (
         state,
         action: PayloadAction<notificationSagaTypes.INotificationDeleteDeviceTokenSaga>
      ) => {
         return {
            ...state,
            deleteDeviceToken: {
               ...state.deleteDeviceToken,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      deleteDeviceTokenSuccess: (
         state,
         action: PayloadAction<notificationReducerTypes.IUpdateNotificationSettingsSuccessPayload>
      ) => {
         return {
            ...state,
            deleteDeviceToken: {
               ...state.deleteDeviceToken,
               ...action.payload,
            },
         };
      },
      deleteDeviceTokenFailed: (
         state,
         action: PayloadAction<notificationReducerTypes.IUpdateNotificationSetingsFailurePayload>
      ) => {
         return {
            ...state,
            deleteDeviceToken: {
               ...state.deleteDeviceToken,
               error: action.payload.error,
            },
         };
      },

      deleteDeviceTokenReset: (state) => {
         return {
            ...state,
            deleteDeviceToken: {
               ...initialState.deleteDeviceToken,
            },
         };
      },
      // notification get one
      notificationGetOneRequest: (state, action: PayloadAction<notificationSagaTypes.INotificationGetOneSaga>) => {
         return {
            ...state,
            getOne: {
               ...state.getOne,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      notificationGetOneSuccess: (
         state,
         action: PayloadAction<notificationReducerTypes.IUpdateNotificationSettingsSuccessPayload>
      ) => {
         return {
            ...state,
            getOne: {
               ...state.getOne,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      notificationGetOneFailed: (
         state,
         action: PayloadAction<notificationReducerTypes.IUpdateNotificationSetingsFailurePayload>
      ) => {
         return {
            ...state,
            getOne: {
               ...state.getOne,
               error: action.payload.error,
               isLoading: false,
            },
         };
      },

      notificationGetOneResetSuccess: (state) => {
         return {
            ...state,
            getOne: {
               ...initialState.getOne,
               isLoading: true,
            },
         };
      },

      notificationGetOneReset: (state) => {
         return {
            ...state,
            getOne: {
               ...initialState.getOne,
            },
         };
      },
   },
});
