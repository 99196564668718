import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { physicianDetailsTypes } from 'types';

const initialState: physicianDetailsTypes.IPhysicianReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
};

//MyProfile getPhysician reducer
export const getPhysiciansSlice = createSlice({
   name: 'getPhysicians',
   initialState: initialState,
   reducers: {
      getPhysiciansRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action?.payload?.page === 1 ? [] : state.list,
         };
      },
      getPhysiciansSuccess: (state, action: PayloadAction<physicianDetailsTypes.IGetPhysicianSuccess>) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            isLoading: false,
         };
      },
      getPhysiciansFailed: (state, action: PayloadAction<physicianDetailsTypes.IPhysicianFailed>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      getPhysiciansReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

//MyProfile updatePhysician reducer
export const updatePhysicianSlice = createSlice({
   name: 'updatePhysician',
   initialState: initialState,
   reducers: {
      updatePhysicianRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      updatePhysicianSuccess: (state, action: PayloadAction<physicianDetailsTypes.IUpdatePhysicianSuccess>) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      updatePhysicianFailed: (state, action: PayloadAction<physicianDetailsTypes.IPhysicianFailed>) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      updatePhysicianReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

//MyProfile deletePhysician reducer
export const deletePhysicianSlice = createSlice({
   name: 'deletePhysician',
   initialState: initialState,
   reducers: {
      deletePhysicianRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      deletePhysicianSuccess: (state, action: PayloadAction<physicianDetailsTypes.IDeletePhysicianSuccess>) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      deletePhysicianFailed: (state, action: PayloadAction<physicianDetailsTypes.IPhysicianFailed>) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            message: '',
            error: payload.error,
         };
      },
      deletePhysicianReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
