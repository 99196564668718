import { createSlice } from '@reduxjs/toolkit';
import { askDocReducerTypes } from 'types';

const initialState: askDocReducerTypes.IAskADocPhyPanelReqReducerPayload = {
   panelReqList: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      pagination: {
         current_page: null,
         last_page: null,
         first_page_url: '',
         last_page_url: '',
         next_page_url: '',
         prev_page_url: '',
         total: 0,
      },
   },
};

export const askADocPhyPanelRequestSlice = createSlice({
   name: 'askADocPhyPanel',
   initialState: initialState,
   reducers: {
      askADocPhyPanelReqListRequest: (state, action) => {
         return {
            ...state,
            panelReqList: {
               ...state.panelReqList,
               ...action.payload,
               isLoading: true,
               list: action.payload.page && action.payload.page === 1 ? [] : state.panelReqList.list,
            },
         };
      },
      askADocPhyPanelReqListSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            panelReqList: {
               ...state.panelReqList,
               pagination: payload.pagination,
               list:
                  payload.pagination.current_page === 1 ? payload.list : [...state.panelReqList.list, ...payload.list],
               isLoading: false,
            },
         };
      },
      askADocPhyPanelReqListFailed: (state, action) => {
         return {
            ...state,
            panelReqList: {
               ...state.panelReqList,
               isLoading: false,
               error: action.payload.error,
            },
         };
      },
      askADocPhyPanelReqListReset: (state) => {
         return {
            ...state,
            panelReqList: {
               ...initialState.panelReqList,
            },
         };
      },
   },
});
