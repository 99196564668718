import { createAction } from '@reduxjs/toolkit';
import { clinicAdminSagaTypes, postShareSagaTypes } from 'types';

export const GET_PHYSICIAN_ADMIN = 'GET_PHYSICIAN_ADMIN';
export const getPhysicianAdmin = createAction<postShareSagaTypes.IGetPhysicianAdmin>(GET_PHYSICIAN_ADMIN);

export const PHYSICIAN_JOIN_REQUESTS = 'PHYSICIAN_JOIN_REQUESTS';
export const physicianJoinClinicRequest = createAction(PHYSICIAN_JOIN_REQUESTS);

export const PHYSICIAN_ACCEPT_REQUEST = 'PHYSICIAN_ACCEPT_REQUEST';
export const physicianAcceptRequest = createAction<postShareSagaTypes.IJoinClinicAccept>(PHYSICIAN_ACCEPT_REQUEST);

export const PATIENT_ACCEPT_REQUEST = 'PATIENT_ACCEPT_REQUEST';
export const patientAcceptRequest = createAction<postShareSagaTypes.IJoinClinicAccept>(PATIENT_ACCEPT_REQUEST);

export const WORKING_HOURS = 'WORKING_HOURS';
export const workingHours = createAction<postShareSagaTypes.IClinicWorkingHours>(WORKING_HOURS);

export const CLINIC_VIDEOS = 'CLINIC_VIDEOS';
export const clinicVideos = createAction<postShareSagaTypes.IEducationVideos>(CLINIC_VIDEOS);

export const CLINIC_STAFF_LIST = 'CLINIC_STAFF_LIST';
export const clinicStaffList = createAction<postShareSagaTypes.IEducationVideos>(CLINIC_STAFF_LIST);

export const CLINIC_STAFF_ADD = 'CLINIC_STAFF_ADD';
export const addClinicStaff = createAction<postShareSagaTypes.IAddClinicStaff>(CLINIC_STAFF_ADD);

export const CLINIC_ADD_VIDEOS = 'CLINIC_ADD_VIDEOS';
export const clinicAddVideos = createAction<postShareSagaTypes.IClinicAddVideos>(CLINIC_ADD_VIDEOS);

export const CLINIC_DELETE_STAFF = 'CLINIC_DELETE_STAFF';
export const clinicDeleteStaff = createAction<postShareSagaTypes.IMyClinicPhysicianCancelReq>(CLINIC_DELETE_STAFF);

export const CLINIC_DELETE_VIDEOS = 'CLINIC_DELETE_VIDEOS';
export const clinicDeleteVideos = createAction<postShareSagaTypes.IMyClinicPhysicianCancelReq>(CLINIC_DELETE_VIDEOS);

export const CLINIC_PHYSICIANS = 'CLINIC_PHYSICIANS';
export const clinicPhysicians = createAction<postShareSagaTypes.IClinicPhysician>(CLINIC_PHYSICIANS);

export const GET_CLINIC_PHYSICIAN_INVITE = 'GET_CLINIC_PHYSICIAN_INVITE';
export const getClinicPhysician = createAction<postShareSagaTypes.IEducationVideos>(GET_CLINIC_PHYSICIAN_INVITE);

export const CLINIC_DELETE_PHYSICIAN = 'CLINIC_DELETE_PHYSICIAN';
export const clinicDeletePhysicians = createAction<postShareSagaTypes.IMyClinicDeletePhysicians>(
   CLINIC_DELETE_PHYSICIAN
);

export const CLINIC_INVITE_PHYSICIAN = 'CLINIC_INVITE_PHYSICIAN';
export const clinicInvitePhysician = createAction<postShareSagaTypes.IPhysicianInvite>(CLINIC_INVITE_PHYSICIAN);

export const CLINIC_PATIENT = 'CLINIC_PATIENT';
export const clinicPatient = createAction<postShareSagaTypes.IEducationVideos>(CLINIC_PATIENT);

export const CLINIC_INVITE_PATIENT = 'CLINIC_INVITE_PATIENT';
export const clinicInvitePatient = createAction<postShareSagaTypes.IEducationVideos>(CLINIC_INVITE_PATIENT);

export const CLINIC_INVITE_PATIENT_REQ = 'CLINIC_INVITE_PATIENT_REQ';
export const clinicInvitePatientReq = createAction<postShareSagaTypes.IPatientInvite>(CLINIC_INVITE_PATIENT_REQ);

export const CLINIC_PRACTISE_ADMIN_LIST = 'CLINIC_PRACTISE_ADMIN_LIST';
export const clinicPractiseAdminList = createAction<postShareSagaTypes.IEducationVideos>(CLINIC_PRACTISE_ADMIN_LIST);

export const CLINIC_DELETE_ADMIN = 'CLINIC_DELETE_ADMIN';
export const clinicDeleteAdmin = createAction<postShareSagaTypes.IMyClinicPhysicianCancelReq>(CLINIC_DELETE_ADMIN);

export const CLINIC_ADD_ADMIN = 'CLINIC_ADD_ADMIN';
export const clinicAddAdmin = createAction<postShareSagaTypes.IAddClinicAdmin>(CLINIC_ADD_ADMIN);

export const ADD_AUXILIARY_STAFF_MODEL = 'ADD_AUXILIARY_STAFF_MODEL';
export const add_AuxiliaryStaffModel = createAction<postShareSagaTypes.IAddAuxiliaryStaff>(ADD_AUXILIARY_STAFF_MODEL);

export const CLINIC_ADMINS_LIST_SAGA = 'CLINIC_ADMINS_LIST_SAGA';
export const clinicAdminsListSaga = createAction<clinicAdminSagaTypes.IClinicAdminsListRequestSaga>(
   CLINIC_ADMINS_LIST_SAGA
);

export const CLINIC_ADMINS_INFO_SAGA = 'CLINIC_ADMINS_INFO_SAGA';
export const clinicAdminsInfoSaga = createAction<clinicAdminSagaTypes.IClinicAdminsInfoRequestSaga>(
   CLINIC_ADMINS_INFO_SAGA
);

export const ONC_CLINIC_PRC_ADMIN_IS_EXISTING_CLINIC_INVITE = 'ONC_CLINIC_PRC_ADMIN_IS_EXISTING_CLINIC_INVITE';
export const isExistingClinicInvite = createAction<clinicAdminSagaTypes.IExistingClinicInviteSaga>(
   ONC_CLINIC_PRC_ADMIN_IS_EXISTING_CLINIC_INVITE
);
export const CLINIC_WORKING_HOURS_SAGA = 'CLINIC_WORKING_HOURS_SAGA';
export const clinicWorkingHoursSaga = createAction<clinicAdminSagaTypes.IClinicWorkingHoursSaga>(
   CLINIC_WORKING_HOURS_SAGA
);

export const CLINIC_AUXILIARY_LIST = 'CLINIC_AUXILIARY_LIST';
export const clinicAuxiliaryList = createAction<clinicAdminSagaTypes.IGetAuxiliarySagaPayload>(CLINIC_AUXILIARY_LIST);
