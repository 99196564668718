import { createSlice } from '@reduxjs/toolkit';
import Constant from 'config/Constant';
import { userReducerTypes } from 'types';

const initialState: userReducerTypes.IMyclinicPatientsReducerTypes = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
   isMyPatient: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
};

export const myClinicPatientsListSlice = createSlice({
   name: 'myClinicPatientsList',
   initialState: initialState,
   reducers: {
      myClinicPatientsListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      myClinicPatientsListViewRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      myClinicPatientsListViewSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            isLoading: false,
            message: Constant.SUCCESS,
         };
      },
      myClinicPatientsListFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      myClinicPatientsListReset: () => {
         return {
            ...initialState,
         };
      },

      checkIsMyPatientRequest: (state, action) => {
         return {
            ...state,
            isMyPatient: {
               ...state.isMyPatient,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      checkIsMyPatientSuccess: (state, action: any) => {
         return {
            ...state,
            isMyPatient: {
               ...state.isMyPatient,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      checkIsMyPatientFailed: (state, action: any) => {
         return {
            ...state,
            isMyPatient: {
               ...state.isMyPatient,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },
   },
});
