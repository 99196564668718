import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ONCKLINIC_SYMPTOM_CLINIC_TIMING } from 'reduxToolKit/actions';
import { oncKlinicSymptomClinicSlice, commonSlice } from 'reduxToolKit/reducers';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   checkClinicTimingRequest,
   checkClinicTimingSuccess,
   checkClinicTimingFailed,
} = oncKlinicSymptomClinicSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const checkClinicTimingSaga = function* (payloadState: any) {
   yield put(checkClinicTimingRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.checkClinicTiming });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message || messages.success,
            data: response.data?.data,
         };
         yield put(checkClinicTimingSuccess(resObj));
      } else {
         yield put(checkClinicTimingFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeCheckClinicTimingSaga = function* () {
   yield takeEvery(ONCKLINIC_SYMPTOM_CLINIC_TIMING, checkClinicTimingSaga);
};
