import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import Constant from 'config/Constant';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINIC_LEAVE_CLINIC_SAGA } from 'reduxToolKit/actions';
import { commonSlice, leaveClinicSlice } from 'reduxToolKit/reducers';
import { leaveClinicTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { leaveClinicRequest, leaveClinicSuccess, leaveClinicFailed } = leaveClinicSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

//both patient and physician
const leaveClinicSaga = function* (leaveClinicPayload: PayloadAction<leaveClinicTypes.ILeaveClinicForm>) {
   yield put(leaveClinicRequest(leaveClinicPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;

      const data = {
         clinic_id: leaveClinicPayload.payload.clinic_id,
      };
      let url: any = null;
      if (
         leaveClinicPayload.payload.type === Constant.PATIENT ||
         leaveClinicPayload.payload.type === Constant.CAREGIVER ||
         leaveClinicPayload.payload.type === Constant.PATIENT_ADVOCATE
      ) {
         url = { checkUser, goUrl, data, ...API.leaveClinicPatient };
      } else if (leaveClinicPayload.payload.type === Constant.PHYSICIAN) {
         url = { checkUser, goUrl, data, ...API.leaveClinicPhysician };
      }
      const response: AxiosResponse = yield apiCall(url);
      if (response.status === 200) {
         const responseData = response.data;
         const leaveClinicList = {
            message: responseData?.message || '',
         };
         yield put(leaveClinicSuccess(leaveClinicList));
      } else {
         yield put(leaveClinicFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeleaveClinicRequest = function* () {
   yield takeEvery(CLINIC_LEAVE_CLINIC_SAGA, leaveClinicSaga);
};
