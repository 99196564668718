import { AxiosResponse } from 'axios';
import { delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
   CLINICAL_TRIALS_LIST,
   CLINICAL_TRIALS_INTERESTED_LIST,
   CLINICAL_TRIALS_INTEREST,
   CLINICAL_TRIALS_LIST_ITEM_GET_ONE,
   CLINICAL_TRIALS_LIST_COUNT,
} from 'reduxToolKit/actions';
import { clinicalTrialsSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   clinicalTrialsListRequest,
   clinicalTrialsListSuccess,
   clinicalTrialsListFailed,
   clinicalTrialsListCountRequest,
   clinicalTrialsListCountSuccess,
   clinicalTrialsListCountFailed,
   clinicalTrialsInterestedListRequest,
   clinicalTrialsInterestedListSuccess,
   clinicalTrialsInterestedListFailed,
   clinicalTrialsInterestRequest,
   clinicalTrialsInterestSuccess,
   clinicalTrialsInterestFailed,
} = clinicalTrialsSlice.actions;

const { exceptionHandlerRequest } = commonSlice.actions;

const clinicalTrialsList = function* (clinicalTrialsPayload: any) {
   yield put(clinicalTrialsListRequest(clinicalTrialsPayload.payload));
   if (clinicalTrialsPayload.payload.data.search_word) {
      yield delay(500);
   }
   try {
      const params = clinicalTrialsPayload.payload.params;
      const data = clinicalTrialsPayload.payload.data;
      const url = API.clinicalTrialsList;
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ params, data, checkUser, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const responseDataSecond = responseData.data;
         const clinicalTrialsList = {
            list: responseDataSecond.data,
            pagination: {
               current_page: responseDataSecond.current_page,
               last_page: responseDataSecond.last_page,
               first_page_url: responseDataSecond.first_page_url,
               last_page_url: responseDataSecond.last_page_url,
               next_page_url: responseDataSecond.next_page_url,
               prev_page_url: responseDataSecond.prev_page_url,
               total: responseData.total_count,
            },
            message: response.data?.message,
         };
         yield put(clinicalTrialsListSuccess(clinicalTrialsList));
      } else {
         yield put(clinicalTrialsListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const clinicalTrialsListCount = function* (clinicalTrialsPayload: any) {
   yield put(clinicalTrialsListCountRequest(clinicalTrialsPayload.payload));
   if (clinicalTrialsPayload.payload.data.search_word) {
      yield delay(500);
   }
   try {
      const params = clinicalTrialsPayload.payload.params;
      const data = clinicalTrialsPayload.payload.data;
      const url = API.clinicalTrialsList;
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ params, data, checkUser, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const clinicalTrialsListCount = {
            data: responseData,
            message: response.data?.message,
         };
         yield put(clinicalTrialsListCountSuccess(clinicalTrialsListCount));
      } else {
         yield put(clinicalTrialsListCountFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const clinicalTrialsInterestedList = function* (clinicalTrialsPayload: any) {
   yield put(clinicalTrialsInterestedListRequest(clinicalTrialsPayload.payload));
   if (clinicalTrialsPayload.payload.params.search_word) {
      yield delay(500);
   }
   try {
      const params = clinicalTrialsPayload.payload.params;
      const url = API.clinicalTrialsInterestedList;
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ params, checkUser, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const clinicalTrialsInterestedList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message,
         };
         yield put(clinicalTrialsInterestedListSuccess(clinicalTrialsInterestedList));
      } else {
         yield put(clinicalTrialsInterestedListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const clinicalTrialsInterest = function* (clinicalTrialsPayload: any) {
   yield put(clinicalTrialsInterestRequest(clinicalTrialsPayload.payload));
   try {
      const data = clinicalTrialsPayload.payload;
      const url = API.clinicalTrialsInterest;
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ data, checkUser, ...url });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(clinicalTrialsInterestSuccess(responseData));
      } else {
         yield put(clinicalTrialsInterestFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

// clinical trails list item get one
const clinicalTrialsListItemGetOne = function* (clinicalTrialsPayload: any) {
   yield put(clinicalTrialsListRequest(clinicalTrialsPayload.payload));
   try {
      const url = {
         apiPath: `${API.clinicalTrialsListItemGetOne.apiPath}/${clinicalTrialsPayload.payload.clinical_trails_id}`,
         action: API.clinicalTrialsListItemGetOne.action,
      };
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ checkUser, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const clinicalTrialsList = {
            list: [responseData],
            pagination: {
               current_page: 1,
               last_page: 1,
               first_page_url: '',
               last_page_url: '',
               next_page_url: '',
               prev_page_url: '',
               total: 1,
            },
            message: response.data?.message,
         };
         yield put(clinicalTrialsListSuccess(clinicalTrialsList));
      } else {
         yield put(clinicalTrialsListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeClinicalTrialsRequest = function* () {
   yield takeLatest(CLINICAL_TRIALS_LIST, clinicalTrialsList);
   yield takeLatest(CLINICAL_TRIALS_LIST_COUNT, clinicalTrialsListCount);
   yield takeLatest(CLINICAL_TRIALS_INTERESTED_LIST, clinicalTrialsInterestedList);
   yield takeEvery(CLINICAL_TRIALS_INTEREST, clinicalTrialsInterest);
   yield takeEvery(CLINICAL_TRIALS_LIST_ITEM_GET_ONE, clinicalTrialsListItemGetOne);
};
