import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { postShareReducerTypes } from 'types';

const initialState: postShareReducerTypes.IAskADocCreateRequest72HrsReducer = {
   isLoading: false,
   error: '',
   errorMessage: '',
   message: '',
   data: null,
};
export const askADocCreateRequest72HrsSlice = createSlice({
   name: 'askADocCreateRequest72Hrs',
   initialState: initialState,
   reducers: {
      askADocCreateRequest72HrsRequest: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      askADocCreateRequest72HrsSuccess: (
         state,
         action: PayloadAction<postShareReducerTypes.IAskADocCreateRequest72HrsReducer>
      ) => {
         return {
            ...state,
            isLoading: false,
            message: action?.payload?.message || 'success',
         };
      },
      askADocCreateRequest72HrsFailed: (
         state,
         action: PayloadAction<postShareReducerTypes.IAskADocCreateRequest72HrsReducer>
      ) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.errors?.error || '',
            errorMessage: action.payload.error_message,
         };
      },
      askADocCreateRequest72HrsReset: () => {
         return { ...initialState };
      },
   },
});
