import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_MY_PHYSICIAN } from 'reduxToolKit/actions';
import { myPhysicianSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getMyPhysicianList, getMyPhysicianSuccess, getMyPhysicianFailed } = myPhysicianSlice.actions;

const listPhysician = function* (payloadMyPhysician: any) {
   yield put(getMyPhysicianList(payloadMyPhysician.payload));
   try {
      const params = {
         clinic_id: payloadMyPhysician.payload.clinic_id,
         page: payloadMyPhysician.payload.page,
         limit: payloadMyPhysician.payload.limit,
      };
      const response: AxiosResponse = yield apiCall({ params, ...API.getMyPhysician });
      if (response.status === 200) {
         yield put(getMyPhysicianSuccess(response.data));
      } else {
         yield put(getMyPhysicianFailed(response.data));
      }
   } catch (error) {}
};
export { listPhysician };
export const takePhysicianListRequest = function* () {
   yield takeEvery(GET_MY_PHYSICIAN, listPhysician);
};
