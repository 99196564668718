import { createSlice } from '@reduxjs/toolkit';
import { pillRemainderReducerTypes } from 'types';

const initialState: pillRemainderReducerTypes.IPillRemainderListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   pagination: {
      current_page: null,
      last_page: null,
      first_page_url: '',
      last_page_url: '',
      next_page_url: '',
      prev_page_url: '',
      total: 0,
   },
};

export const pillRemainderListSlice = createSlice({
   name: 'pillRemainderList',
   initialState: initialState,
   reducers: {
      pillRemainderListViewRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      pillRemainderListViewSuccess: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            isLoading: false,
         };
      },
      pillRemainderListViewFailed: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            message: '',
            error: payload.error,
         };
      },
      pillRemainderListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
