import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MODAL_INVITE_VIA_MAIL_SAGA } from 'reduxToolKit/actions';
import { commonSlice, modalInviteViaMailSlice } from 'reduxToolKit/reducers';
import { modalConnectionInviteSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   modalInviteViaMailRequest,
   modalInviteViaMailSuccess,
   modalInviteViaMailFailed,
} = modalInviteViaMailSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const modalInviteViaMailSaga = function* (
   mailPayload: PayloadAction<modalConnectionInviteSagaTypes.IModalInviteViaMailSagaPayload>
) {
   yield put(modalInviteViaMailRequest(mailPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = mailPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.inviteViaMail });
      if (response.status === 200) {
         const responseData = response.data;
         const mailList = {
            message: responseData?.message || '',
         };
         yield put(modalInviteViaMailSuccess(mailList));
      } else {
         yield put(modalInviteViaMailFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeInviteViaMailRequest = function* () {
   yield takeEvery(MODAL_INVITE_VIA_MAIL_SAGA, modalInviteViaMailSaga);
};
