import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notificationSagaTypes, notificationReducerTypes } from 'types';

const initialState: notificationReducerTypes.IAdminNotificationReducerPayload = {
   error: '',
};

export const adminNotificationSlice = createSlice({
   name: 'adminNotification',
   initialState: initialState,
   reducers: {
      adminNotificationRequest: (state, action: PayloadAction<notificationSagaTypes.IAdminNotificationSagaTypes>) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      adminNotificationSuccess: (state) => {
         return {
            ...state,
         };
      },
      adminNotificationFailed: (
         state,
         action: PayloadAction<notificationReducerTypes.IAdminNotificationReducerPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
         };
      },

      adminNotificationReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
