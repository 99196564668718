import { createAction } from '@reduxjs/toolkit';
import { IGoogleLoginActionProps, ILinkedInLoginActionProps, IUserRegisterSagaProps } from 'types/auth/login';

export const LOGIN_SAGA = 'LOGIN_SAGA';
export const loginSaga = (login: any) => {
   return {
      type: LOGIN_SAGA,
      payload: login,
   };
};

export const LOGIN_GOOGLE_SAGA = 'LOGIN_GOOGLE_SAGA';
export const getGoogleLoginSagaAction = createAction<IGoogleLoginActionProps>(LOGIN_GOOGLE_SAGA);

export const LOGIN_LINKEDIN_SAGA = 'LOGIN_LINKEDIN_SAGA';
export const getLinkedInLoginSagaAction = createAction<ILinkedInLoginActionProps>(LOGIN_LINKEDIN_SAGA);

export const LOGIN_USER_REGISTER = 'LOGIN_USER_REGISTER';
export const userRegisterSagaAction = createAction<IUserRegisterSagaProps>(LOGIN_USER_REGISTER);
