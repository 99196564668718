import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { feedSagaTypes, peopleNearMeReducerTypes } from 'types';

const initialState: peopleNearMeReducerTypes.ICancerTypeListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
};

export const commentsReactionsListSlice = createSlice({
   name: 'commentsReactionsList',
   initialState: initialState,
   reducers: {
      commentsReactionsListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      commentsReactionsListViewRequest: (state, action: PayloadAction<feedSagaTypes.ICommentsReactionsList>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            message: '',
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      commentsReactionsListViewSuccess: (
         state,
         action: PayloadAction<peopleNearMeReducerTypes.ICancerTypeListReducer>
      ) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            message: 'success',
            isLoading: false,
         };
      },
      commentsReactionsListFailed: (state, action: PayloadAction<peopleNearMeReducerTypes.ICancerTypeListReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      commentsReactionsListResetMessage: (state) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: '',
         };
      },

      commentsReactionsListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
