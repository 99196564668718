import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   UNREAD_CONVERSATIONS_COUNT,
   GET_ONE_TO_ONE_CONVERSATION,
   GET_CONVERSATION_CREATION_VERIFIED_USER,
} from 'reduxToolKit/actions';
import { conversationSlice, commonSlice } from 'reduxToolKit/reducers';
import { conversationsReducersTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   conversationsUnreadCountRequest,
   conversationsUnreadCountSuccess,
   conversationsUnreadCountFailed,
   getOneToOneConversationRequest,
   getOneToOneConversationSuccess,
   getOneToOneConversationFailed,
   getConversationCreationVerifiedUserRequest,
   getConversationCreationVerifiedUserSuccess,
   getConversationCreationVerifiedUserFailed,
} = conversationSlice.actions;
const { conversationCountAction, exceptionHandlerRequest } = commonSlice.actions;

const conversationsSaga = function* () {
   yield put(conversationsUnreadCountRequest());
   try {
      const checkUser = true;
      const goUrl = true;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, ...API.getConversationUnreadCount });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message || messages.success,
            data: response.data?.data || 0,
         };
         yield put(conversationsUnreadCountSuccess(resObj));
         yield put(conversationCountAction(response.data?.data || 0));
      } else {
         yield put(conversationsUnreadCountFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const oneToOneConversationSaga = function* (
   oneToOneConversationPayload: PayloadAction<conversationsReducersTypes.IGetOneToOneConversationProps>
) {
   yield put(getOneToOneConversationRequest(oneToOneConversationPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = {
         ask_a_doc_id: oneToOneConversationPayload.payload.ask_a_doc_id,
         user_id: oneToOneConversationPayload.payload.user_id,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getOneToOneConversation });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(getOneToOneConversationSuccess(resObj));
      } else {
         yield put(getOneToOneConversationFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const conversationCreateVerifiedUser = function* (
   oneToOneConversationPayload: PayloadAction<conversationsReducersTypes.IConversationCreateVerifiedUser>
) {
   yield put(getConversationCreationVerifiedUserRequest(oneToOneConversationPayload.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const data = { connecting_user_id: oneToOneConversationPayload.payload.connecting_user_id };
      const response: AxiosResponse = yield apiCall({
         checkUser,
         peopleMayYouKnow,
         data,
         ...API.getConversationCreateVerifiedUser,
      });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(getConversationCreationVerifiedUserSuccess(resObj));
      } else {
         yield put(getConversationCreationVerifiedUserFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeConversationsActions = function* () {
   yield takeEvery(UNREAD_CONVERSATIONS_COUNT, conversationsSaga);
   yield takeEvery(GET_ONE_TO_ONE_CONVERSATION, oneToOneConversationSaga);
   yield takeEvery(GET_CONVERSATION_CREATION_VERIFIED_USER, conversationCreateVerifiedUser);
};
