import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { DELETE_LOCUM_SERVICE } from 'reduxToolKit/actions';
import { commonSlice, locumServiceDeleteSlice } from 'reduxToolKit/reducers';
import { locumSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   locumServiceDeleteRequest,
   locumServiceDeleteSuccess,
   locumServiceDeleteFailed,
} = locumServiceDeleteSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const locumServiceDeleteSaga = function* (
   locumServiceDelete: PayloadAction<locumSagaTypes.IGetOneLocumServiceSagaParams>
) {
   yield put(locumServiceDeleteRequest(locumServiceDelete.payload));
   try {
      const apiPath = {
         apiPath: `${API.deleteLocumService.apiPath}${locumServiceDelete.payload.id}`,
         action: API.deleteLocumService.action,
      };
      const data = locumServiceDelete.payload;
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...apiPath });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(locumServiceDeleteSuccess(resObj));
      } else {
         yield put(locumServiceDeleteFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeLocumServiceDeleteRequest = function* () {
   yield takeEvery(DELETE_LOCUM_SERVICE, locumServiceDeleteSaga);
};
