import { createAction } from '@reduxjs/toolkit';
import { feedSagaTypes } from 'types/feed';

export const SHOW_PATIENT_FEED_SAGA = 'SHOW_PATIENT_FEED_SAGA';
export const showPatientFeedSagaAction = createAction<feedSagaTypes.IShowPatientFeedActionProps>(
   SHOW_PATIENT_FEED_SAGA
);

export const TOP_STATE_ACTION = 'TOP_STATE_ACTION';
export const topStateFeedAction = createAction(TOP_STATE_ACTION);

export const TOP_CANCER_ACTION = 'TOP_CANCER_ACTION';
export const topCancerFeedAction = createAction(TOP_CANCER_ACTION);

export const HOME_RPM_INTERVAL_REMAINDER_SAGA = 'HOME_RPM_INTERVAL_REMAINDER_SAGA';
export const homeRpmIntervalRemainderSagaAction = createAction(HOME_RPM_INTERVAL_REMAINDER_SAGA);
