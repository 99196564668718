import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { PILL_REMAINDER_LIST_SAGA } from 'reduxToolKit/actions';
import { commonSlice, pillRemainderListSlice } from 'reduxToolKit/reducers';
import { pillRemainderSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   pillRemainderListViewRequest,
   pillRemainderListViewSuccess,
   pillRemainderListViewFailed,
} = pillRemainderListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const pillRemainderListSaga = function* (
   pillRemainderListPayload: PayloadAction<pillRemainderSagaTypes.IPillRemainderListSagaParams>
) {
   yield put(pillRemainderListViewRequest(pillRemainderListPayload.payload));
   try {
      const params = pillRemainderListPayload.payload;
      const url = API.pillRemainderList;
      const response: AxiosResponse = yield apiCall({ params, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const oncDriveList = {
            list: responseData,
            message: response.data?.message,
         };
         yield put(pillRemainderListViewSuccess(oncDriveList));
      } else {
         yield put(pillRemainderListViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePillRemainderListRequest = function* () {
   yield takeEvery(PILL_REMAINDER_LIST_SAGA, pillRemainderListSaga);
};
