import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonApiTypes, postShareSagaTypes } from 'types';

const initialState: commonApiTypes.ICommonPostsService = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};

export const addMyServicesSpecialtiesSlice = createSlice({
   name: 'addMyServicesSpecialties',
   initialState: initialState,
   reducers: {
      addMyServicesSpecialtiesRequest: (state, action: PayloadAction<postShareSagaTypes.IAddMyServiceSpecialties>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addMyServicesSpecialtiesSuccess: (state, action: PayloadAction<commonApiTypes.ICommonPostsService>) => {
         return {
            ...state,
            ...action.payload,
            message: action.payload?.message,
            error: '',
         };
      },
      addMyServicesSpecialtiesFailed: (state, action: PayloadAction<commonApiTypes.ICommonPostsService>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload?.error,
         };
      },
      addMyServicesSpecialtiesReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
