import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MY_CLINIC_PHYSICIANS_CANCEL_REQ } from 'reduxToolKit/actions';
import { commonSlice, myClinicPhyCancelReqSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   myClinicPhyCancelReqRequest,
   myClinicPhyCancelReqSuccess,
   myClinicPhyCancelReqFailed,
} = myClinicPhyCancelReqSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const phyCancelReqSaga = function* (payloadState: any) {
   yield put(myClinicPhyCancelReqRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.myClinicPhyCancelReq });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: null,
         };
         yield put(myClinicPhyCancelReqSuccess(resObj));
      } else {
         yield put(myClinicPhyCancelReqFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeMyClinicPhyCancelReq = function* () {
   yield takeEvery(MY_CLINIC_PHYSICIANS_CANCEL_REQ, phyCancelReqSaga);
};
