import { createSlice } from '@reduxjs/toolkit';
import { rpmDashBoardReducerTypes } from 'types';

const initialState: rpmDashBoardReducerTypes.IGetClinicPhyReducer = {
   isLoading: false,
   list: [],
   error: '',
   message: '',
};

export const getClinicPhysicianSlice = createSlice({
   name: 'getClinicPhysician',
   initialState: initialState,
   reducers: {
      getClinicPhysicianRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getClinicPhysicianSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            isLoading: false,
            message: payload.message,
         };
      },
      getClinicPhysicianFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            error: action.payload.error,
            message: '',
         };
      },
      getClinicPhysicianReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
