import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CT_FILLED_POPUP, HIDE_THIS_POST } from 'reduxToolKit/actions';
import { commonSlice, hidePostSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   hidePostRequest,
   hidePostSuccess,
   hidePostFailure,
   ctFilledPostRequest,
   ctFilledPostSuccess,
   ctFilledPostFailure,
} = hidePostSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const hidePostSaga = function* (payloadState: PayloadAction<feedSagaTypes.IFeedHideThisPost>) {
   yield put(hidePostRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = false;
      const peopleMayYouKnow = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ goUrl, checkUser, peopleMayYouKnow, data, ...API.hidePost });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
            data: response.data?.data || null,
         };
         yield put(hidePostSuccess(responseData));
      } else {
         yield put(hidePostFailure(response.data));
      }
   } catch (err) {
      yield put(exceptionHandlerRequest(err));
   }
};

const ctFilledPopup = function* (payloadState: PayloadAction<feedSagaTypes.ICtFilledPopUp>) {
   yield put(ctFilledPostRequest(payloadState.payload));
   try {
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser: false, data, ...API.ctFilledPost });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
            data: response.data?.data || null,
         };
         yield put(ctFilledPostSuccess(responseData));
      } else {
         yield put(ctFilledPostFailure(response.data));
      }
   } catch (err) {
      yield put(exceptionHandlerRequest(err));
   }
};

export const takeHideThisPostSaga = function* () {
   yield takeEvery(HIDE_THIS_POST, hidePostSaga);
   yield takeEvery(CT_FILLED_POPUP, ctFilledPopup);
};
