import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonApiTypes, postShareSagaTypes, storeTypes } from 'types';

const initialState: commonApiTypes.ICommonPostsService = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};

export const addAuxiliaryStaffSlice = createSlice({
   name: 'addAuxiliaryStaff',
   initialState: initialState,
   reducers: {
      addAuxiliaryStaffRequest: (state, action: PayloadAction<postShareSagaTypes.IAddAuxiliaryStaff>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addAuxiliaryStaffSuccess: (state, action: PayloadAction<commonApiTypes.ICommonPostsService>) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      addAuxiliaryStaffFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      addAuxiliaryStaffReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
