import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { feedReducerTypes, feedSagaTypes } from 'types';

const initialState: feedReducerTypes.IPostRelatedReducer = {
   isLoading: false,
   saveAPost: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
};

export const savedPostSlice = createSlice({
   name: 'savedPostObject',
   initialState: initialState,
   reducers: {
      saveUnsaveAPostRequest: (state, action: PayloadAction<feedSagaTypes.ISaveUnSaveAPost>) => {
         return {
            ...state,
            saveAPost: {
               ...state.saveAPost,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      saveUnsavePostSuccess: (state, action: PayloadAction<feedReducerTypes.ICommonSavePostSuccessPayload>) => {
         return {
            ...state,
            saveAPost: {
               ...state.saveAPost,
               message: action.payload?.message,
               data: action.payload?.data,
               error: '',
               isLoading: false,
            },
         };
      },
      saveUnsavePostFailure: (state, action: PayloadAction<feedReducerTypes.ICommonSaveFailurePayload>) => {
         return {
            ...state,
            saveAPost: {
               ...state.saveAPost,
               error: action.payload?.error,
               message: '',
               data: null,
               isLoading: false,
            },
         };
      },
      saveUnsaveAPostReset: (state) => {
         return {
            ...state,
            saveAPost: {
               ...state.saveAPost,
               isLoading: false,
               message: '',
               data: null,
               error: '',
            },
         };
      },
   },
});
