import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { askADocPageTypes, peopleNearMeReducerTypes } from 'types';

const initialState: peopleNearMeReducerTypes.ICancerTypeListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
};

export const panelsListSlice = createSlice({
   name: 'panelsList',
   initialState: initialState,
   reducers: {
      panelsListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      panelsListViewRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: state.list,
         };
      },
      panelsListViewSuccess: (state, action: PayloadAction<peopleNearMeReducerTypes.ICancerTypeListReducer>) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            list: payload.list,
            total_records: payload.total_records,
            isLoading: false,
         };
      },
      panelsListFailed: (state, action: PayloadAction<peopleNearMeReducerTypes.ICancerTypeListReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      subscriptionPanelsListViewRequest: (state, action: PayloadAction<askADocPageTypes.IAskADocClinicRequest>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: state.list,
         };
      },
      subscriptionPanelsListViewSuccess: (
         state,
         action: PayloadAction<peopleNearMeReducerTypes.ICancerTypeListReducer>
      ) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            list: payload.list,
            total_records: payload.total_records,
            isLoading: false,
         };
      },
      subscriptionPanelsListFailed: (state, action: PayloadAction<peopleNearMeReducerTypes.ICancerTypeListReducer>) => {
         return {
            ...state,
            error: action.payload.error,
            isLoading: false,
            message: '',
         };
      },

      panelsListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
