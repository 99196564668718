import React, { memo, useEffect } from 'react';

import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Button,
   Checkbox,
   Divider,
   FormControlLabel,
   Grid,
   IconButton,
   InputAdornment,
   Popover,
   TextField,
   Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from 'assets/askDoc/searchIconHeader.svg';
import NoData from 'component/widget/NoData';
import { useSelector } from 'react-redux';
import { storeTypes } from 'types';
import { SET_TIME_OUT } from 'utils/constants/common';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';
import { toConvertLowerCase } from 'utils/helpers/stringHelpers';

const ClinicFilter = memo((props: any) => {
   const [anchorElCancerType, setAnchorElCancerType] = React.useState(null);
   const [anchorElCancerSpecific, setAnchorElCancerSpecific] = React.useState(null);
   const [anchorElStudyType, setAnchorElStudyType] = React.useState(null);
   const [anchorElStudyPhase, setAnchorElStudyPhase] = React.useState(null);
   const [anchorElState, setAnchorElState] = React.useState(null);
   const [anchorElCountries, setAnchorElCountries] = React.useState(null);

   const [cancerTypeListMain, setCancerTypeListMain] = React.useState<any[]>([]);
   const [cancerTypeListDialog, setCancerTypeListDialog] = React.useState<any[]>([]);
   const [cancerSpecificListMain, setCancerSpecificListMain] = React.useState<any[]>([]);
   const [cancerSpecificListDialog, setCancerSpecificListDialog] = React.useState<any[]>([]);
   const [studyTypeListMain, setStudyTypeListMain] = React.useState<any[]>([]);
   const [studyTypeListDialog, setStudyTypeListDialog] = React.useState<any[]>([]);
   const [studyPhaseListMain, setStudyPhaseListMain] = React.useState<any[]>([]);
   const [studyPhaseListDialog, setStudyPhaseListDialog] = React.useState<any[]>([]);
   const [stateListMain, setStateListMain] = React.useState<any[]>([]);
   const [stateListDialog, setStateListDialog] = React.useState<any[]>([]);
   const [countriesListMain, setCountriesListMain] = React.useState<any[]>([]);
   const [countriesListDialog, setCountriesListDialog] = React.useState<any[]>([]);

   const [dataApplyFilter, setDataApplyFilter] = React.useState<any>(null);

   const [searchWordListDialogCancerType, setSearchWordListDialogCancerType] = React.useState<string>('');
   const [searchWordListDialogCancerSpecific, setSearchWordListDialogCancerSpecific] = React.useState<string>('');
   const [searchWordListDialogStudyType, setSearchWordListDialogStudyType] = React.useState<string>('');
   const [searchWordListDialogStudyPhase, setSearchWordListDialogStudyPhase] = React.useState<string>('');
   const [searchWordListDialogState, setSearchWordListDialogState] = React.useState<string>('');
   const [searchWordListDialogCountries, setSearchWordListDialogCountries] = React.useState<string>('');

   const cancerType = useSelector((state: storeTypes.IStore) => state.cancerType);
   const cancerSpecific = useSelector((state: storeTypes.IStore) => state.clinicalTrialsCancerSpecific);
   const studyType = useSelector((state: storeTypes.IStore) => state.clinicalTrialsStudyType);
   const studyPhase = useSelector((state: storeTypes.IStore) => state.clinicalTrialsStudyPhase);
   const state = useSelector((state: storeTypes.IStore) => state.clinicalTrialsStates);
   const countries = useSelector((state: storeTypes.IStore) => state.userGetCountries);

   const { errors: errorsList } = useSelector((state: storeTypes.IStore) => state.clinicalTrials.clinicalTrialsList);

   const handleClickCancerType = (event: any) => {
      setAnchorElCancerType(event.currentTarget);
   };
   const handleCloseCancerType = (finalData: any) => {
      setAnchorElCancerType(null);
      setSearchWordListDialogCancerType('');
      setTimeout(() => {
         const cancerList = listParseAndStringify(cancerType?.list || []);
         const dataCancerType = listParseAndStringify(finalData);
         let finalListCancerType: any[] = [];
         if (dataCancerType?.cancer_type?.length > 0) {
            dataCancerType.cancer_type
               ?.slice()
               .sort()
               .forEach((item: any, i: number) => {
                  const index = cancerList?.findIndex((x: any) => x.name === item);
                  finalListCancerType.push(cancerList[index]);
                  cancerList.splice(index, 1);
                  if (dataCancerType.cancer_type?.length === i + 1) {
                     finalListCancerType = finalListCancerType.concat(cancerList);
                     setCancerTypeListMain(finalListCancerType);
                  }
               });
            setCancerTypeListDialog(cancerType?.list || []);
         } else {
            setCancerTypeListMain(cancerType?.list || []);
            setCancerTypeListDialog(cancerType?.list || []);
         }
      }, SET_TIME_OUT.MIN);
   };

   const handleClickCancerSpecific = (event: any) => {
      setAnchorElCancerSpecific(event.currentTarget);
   };
   const handleCloseCancerSpecific = (finalData: any) => {
      setAnchorElCancerSpecific(null);
      setSearchWordListDialogCancerSpecific('');
      setTimeout(() => {
         const cancerSpecificList = listParseAndStringify(cancerSpecific?.list || []);
         const dataCancerSpecific = listParseAndStringify(finalData);
         let finalListCancerSpecific: any[] = [];
         if (dataCancerSpecific?.cancer_specific?.length > 0) {
            dataCancerSpecific.cancer_specific
               ?.slice()
               .sort((a: any, b: any) => (a.cancer_specific_name > b.cancer_specific_name ? -1 : 1))
               .forEach((item: any, i: number) => {
                  const index = cancerSpecificList?.findIndex((x: any) => x.name === item.cancer_specific_name);
                  finalListCancerSpecific.push(cancerSpecificList[index]);
                  cancerSpecificList.splice(index, 1);
                  if (dataCancerSpecific.cancer_specific?.length === i + 1) {
                     finalListCancerSpecific = finalListCancerSpecific.concat(cancerSpecificList);
                     setCancerSpecificListMain(finalListCancerSpecific);
                  }
               });
            setCancerSpecificListDialog(cancerSpecific?.list || []);
         } else {
            setCancerSpecificListMain(cancerSpecific?.list || []);
            setCancerSpecificListDialog(cancerSpecific?.list || []);
         }
      }, SET_TIME_OUT.MIN);
   };

   const handleClickStudyType = (event: any) => {
      setAnchorElStudyType(event.currentTarget);
   };
   const handleCloseStudyType = (finalData: any) => {
      setAnchorElStudyType(null);
      setSearchWordListDialogStudyType('');
      setTimeout(() => {
         const studyTypeList = listParseAndStringify(studyType?.list || []);
         const dataStudyType = listParseAndStringify(finalData);
         let finalListStudyType: any[] = [];
         if (dataStudyType?.study_type?.length > 0) {
            dataStudyType.study_type
               ?.slice()
               .sort()
               .forEach((item: any, i: number) => {
                  const index = studyTypeList?.findIndex((x: any) => x.name === item);
                  finalListStudyType.push(studyTypeList[index]);
                  studyTypeList.splice(index, 1);
                  if (dataStudyType.study_type?.length === i + 1) {
                     finalListStudyType = finalListStudyType.concat(studyTypeList);
                     setStudyTypeListMain(finalListStudyType);
                  }
               });
            setStudyTypeListDialog(studyType?.list || []);
         } else {
            setStudyTypeListMain(studyType?.list || []);
            setStudyTypeListDialog(studyType?.list || []);
         }
      }, SET_TIME_OUT.MIN);
   };

   const handleClickStudyPhase = (event: any) => {
      setAnchorElStudyPhase(event.currentTarget);
   };
   const handleCloseStudyPhase = (finalData: any) => {
      setAnchorElStudyPhase(null);
      setSearchWordListDialogStudyPhase('');
      setTimeout(() => {
         const studyPhaseList = listParseAndStringify(studyPhase?.list || []);
         const dataStudyPhase = listParseAndStringify(finalData);
         let finalListStudyPhase: any[] = [];
         if (dataStudyPhase?.study_phase?.length > 0) {
            dataStudyPhase.study_phase
               ?.slice()
               .sort()
               .forEach((item: any, i: number) => {
                  const index = studyPhaseList?.findIndex((x: any) => x.name === item);
                  finalListStudyPhase.push(studyPhaseList[index]);
                  studyPhaseList.splice(index, 1);
                  if (dataStudyPhase?.study_phase?.length === i + 1) {
                     finalListStudyPhase = finalListStudyPhase.concat(studyPhaseList);
                     setStudyPhaseListMain(finalListStudyPhase);
                  }
               });
            setStudyPhaseListDialog(studyPhase?.list || []);
         } else {
            setStudyPhaseListMain(studyPhase?.list || []);
            setStudyPhaseListDialog(studyPhase?.list || []);
         }
      }, SET_TIME_OUT.MIN);
   };

   const handleClickCountries = (event: any) => {
      setAnchorElCountries(event.currentTarget);
   };

   const handleCloseCountries = (finalData: any) => {
      setAnchorElCountries(null);
      setSearchWordListDialogCountries('');
      setTimeout(() => {
         const countriesList = listParseAndStringify(countries?.list || []);
         const dataCountries = listParseAndStringify(finalData);
         let finalListCountries: any[] = [];
         if (dataCountries?.countries?.length > 0) {
            dataCountries.countries
               ?.slice()
               .sort()
               .forEach((item: any, i: number) => {
                  const index = countriesList?.findIndex((x: any) => x.name === item);
                  finalListCountries.push(countriesList[index]);
                  countriesList.splice(index, 1);
                  if (dataCountries?.countries?.length === i + 1) {
                     finalListCountries = finalListCountries.concat(countriesList);
                     setCountriesListMain(finalListCountries);
                  }
               });
            setCountriesListDialog(countries?.list || []);
         } else {
            setCountriesListMain(countries?.list || []);
            setCountriesListDialog(countries?.list || []);
         }
      }, SET_TIME_OUT.MIN);
   };

   const handleClickState = (event: any) => {
      setAnchorElState(event.currentTarget);
   };

   const handleCloseState = (finalData: any) => {
      setAnchorElState(null);
      setSearchWordListDialogState('');
      setTimeout(() => {
         const stateList = listParseAndStringify(state?.list || []);
         const dataState = listParseAndStringify(finalData);
         let finalListState: any[] = [];
         if (dataState?.state?.length > 0) {
            dataState.state
               ?.slice()
               .sort((a: any, b: any) => (a.state_name > b.state_name ? -1 : 1))
               .forEach((item: any, i: number) => {
                  const index = stateList?.findIndex((x: any) => x.name === item?.state_name);
                  finalListState.push(stateList[index]);
                  stateList.splice(index, 1);
                  if (dataState?.state?.length === i + 1) {
                     finalListState = finalListState.concat(stateList);
                     setStateListMain(finalListState);
                  }
               });
            setStateListDialog(state?.list || []);
         } else {
            setStateListMain(state?.list || []);
            setStateListDialog(state?.list || []);
         }
      }, SET_TIME_OUT.MIN);
   };

   const changeListDialogCancerType = (e: any) => {
      const value = e?.target?.value || '';
      setSearchWordListDialogCancerType(value);
      if (value) {
         const cancerTypeArray = listParseAndStringify(cancerType?.list || []);
         const filteredList = cancerTypeArray.filter((obj: any) =>
            toConvertLowerCase(obj.name).includes(toConvertLowerCase(value))
         );
         setCancerTypeListDialog(filteredList);
         setCancerTypeListMain(filteredList);
      } else {
         setCancerTypeListDialog(cancerType?.list || []);
         const cancerList = listParseAndStringify(cancerType?.list || []);
         const dataCancerType = listParseAndStringify(props?.finalData);
         let finalListCancerType: any[] = [];
         if (dataCancerType?.cancer_type?.length > 0) {
            dataCancerType.cancer_type
               ?.slice()
               .sort()
               .forEach((item: any, i: number) => {
                  const index = cancerList?.findIndex((x: any) => x.name === item);
                  finalListCancerType.push(cancerList[index]);
                  cancerList.splice(index, 1);
                  if (dataCancerType?.cancer_type?.length === i + 1) {
                     finalListCancerType = finalListCancerType.concat(cancerList);
                     setCancerTypeListMain(finalListCancerType);
                  }
               });
         } else {
            setCancerTypeListMain(cancerType?.list || []);
         }
      }
   };

   const changeListDialogCancerSpecific = (e: any) => {
      const value = e?.target?.value || '';
      setSearchWordListDialogCancerSpecific(value);
      if (value) {
         const cancerSpecificArray = listParseAndStringify(cancerSpecific?.list || []);
         const filteredList = cancerSpecificArray.filter((obj: any) =>
            toConvertLowerCase(obj.name).includes(toConvertLowerCase(value))
         );
         setCancerSpecificListDialog(filteredList);
         setCancerSpecificListMain(filteredList);
      } else {
         setCancerSpecificListDialog(cancerSpecific?.list || []);
         const cancerSpecificList = listParseAndStringify(cancerSpecific?.list || []);
         const dataCancerSpecific = listParseAndStringify(props?.finalData);
         let finalListCancerSpecific: any[] = [];
         if (dataCancerSpecific?.cancer_specific?.length > 0) {
            dataCancerSpecific.cancer_specific
               ?.slice()
               .sort((a: any, b: any) => (a.cancer_specific_name > b.cancer_specific_name ? -1 : 1))
               .forEach((item: any, i: number) => {
                  const index = cancerSpecificList?.findIndex((x: any) => x.name === item.cancer_specific_name);
                  finalListCancerSpecific.push(cancerSpecificList[index]);
                  cancerSpecificList.splice(index, 1);
                  if (dataCancerSpecific?.cancer_specific?.length === i + 1) {
                     finalListCancerSpecific = finalListCancerSpecific.concat(cancerSpecificList);
                     setCancerSpecificListMain(finalListCancerSpecific);
                  }
               });
         } else {
            setCancerSpecificListMain(cancerSpecific?.list || []);
         }
      }
   };

   const changeListDialogStudyType = (e: any) => {
      const value = e?.target?.value || '';
      setSearchWordListDialogStudyType(value);
      if (value) {
         const studyTypeArray = listParseAndStringify(studyType?.list || []);
         const filteredList = studyTypeArray.filter((obj: any) =>
            toConvertLowerCase(obj.name).includes(toConvertLowerCase(value))
         );
         setStudyTypeListDialog(filteredList);
         setStudyTypeListMain(filteredList);
      } else {
         setStudyTypeListDialog(studyType?.list || []);
         const studyTypeList = listParseAndStringify(studyType?.list || []);
         const dataStudyType = listParseAndStringify(props?.finalData);
         let finalListStudyType: any[] = [];
         if (dataStudyType?.study_type?.length > 0) {
            dataStudyType.study_type
               ?.slice()
               .sort()
               .forEach((item: any, i: number) => {
                  const index = studyTypeList?.findIndex((x: any) => x.name === item);
                  finalListStudyType.push(studyTypeList[index]);
                  studyTypeList.splice(index, 1);
                  if (dataStudyType?.study_type?.length === i + 1) {
                     finalListStudyType = finalListStudyType.concat(studyTypeList);
                     setStudyTypeListMain(finalListStudyType);
                  }
               });
         } else {
            setStudyTypeListMain(studyType?.list || []);
         }
      }
   };

   const changeListDialogStudyPhase = (e: any) => {
      const value = e?.target?.value || '';
      setSearchWordListDialogStudyPhase(value);
      if (value) {
         const studyPhaseArray = listParseAndStringify(studyPhase?.list || []);
         const filteredList = studyPhaseArray.filter((obj: any) =>
            toConvertLowerCase(obj.name).includes(toConvertLowerCase(value))
         );
         setStudyPhaseListDialog(filteredList);
         setStudyPhaseListMain(filteredList);
      } else {
         setStudyPhaseListDialog(studyPhase?.list || []);
         const studyPhaseList = listParseAndStringify(studyPhase?.list || []);
         const dataStudyPhase = listParseAndStringify(props?.finalData);
         let finalListStudyPhase: any[] = [];
         if (dataStudyPhase?.study_phase?.length > 0) {
            dataStudyPhase.study_phase
               ?.slice()
               .sort()
               .forEach((item: any, i: number) => {
                  const index = studyPhaseList?.findIndex((x: any) => x.name === item);
                  finalListStudyPhase.push(studyPhaseList[index]);
                  studyPhaseList.splice(index, 1);
                  if (dataStudyPhase?.study_phase?.length === i + 1) {
                     finalListStudyPhase = finalListStudyPhase.concat(studyPhaseList);
                     setStudyPhaseListMain(finalListStudyPhase);
                  }
               });
         } else {
            setStudyPhaseListMain(studyPhase?.list || []);
         }
      }
   };

   const changeListDialogCountries = (e: any) => {
      const value = e?.target?.value || '';
      setSearchWordListDialogCountries(value);

      if (value) {
         const countriesArray = listParseAndStringify(countries?.list || []);
         const filteredList = countriesArray.filter((obj: any) =>
            toConvertLowerCase(obj.name).includes(toConvertLowerCase(value))
         );
         setCountriesListDialog(filteredList);
         setCountriesListMain(filteredList);
      } else {
         setCountriesListDialog(countries?.list || []);
         const countriesList = listParseAndStringify(countries?.list || []);
         const dataCountries = listParseAndStringify(props?.finalData);
         let finalListCountries: any[] = [];
         if (dataCountries?.countries?.length > 0) {
            dataCountries.countries
               ?.slice()
               .sort()
               .forEach((item: any, i: number) => {
                  const index = countriesList?.findIndex((x: any) => x.name === item);
                  finalListCountries.push(countriesList[index]);
                  countriesList.splice(index, 1);
                  if (dataCountries?.countries?.length === i + 1) {
                     finalListCountries = finalListCountries.concat(countriesList);
                     setCountriesListMain(finalListCountries);
                  }
               });
         } else {
            setCountriesListMain(countries?.list || []);
         }
      }
   };

   const changeListDialogState = (e: any) => {
      const value = e?.target?.value || '';
      setSearchWordListDialogState(value);
      if (value) {
         const stateArray = listParseAndStringify(state?.list || []);
         const filteredList = stateArray.filter((obj: any) =>
            toConvertLowerCase(obj.name).includes(toConvertLowerCase(value))
         );
         setStateListDialog(filteredList);
         setStateListMain(filteredList);
      } else {
         setStateListDialog(state?.list || []);
         const stateList = listParseAndStringify(state?.list || []);
         const dataState = listParseAndStringify(props?.finalData);
         let finalListState: any[] = [];
         if (dataState?.state?.length > 0) {
            dataState.state
               ?.slice()
               .sort((a: any, b: any) => (a.state_name > b.state_name ? -1 : 1))
               .forEach((item: any, i: number) => {
                  const index = stateList?.findIndex((x: any) => x.name === item?.state_name);
                  finalListState.push(stateList[index]);
                  stateList.splice(index, 1);
                  if (dataState?.state?.length === i + 1) {
                     finalListState = finalListState.concat(stateList);
                     setStateListMain(finalListState);
                  }
               });
         } else {
            setStateListMain(state?.list || []);
         }
      }
   };

   const changeStatus = (event: any) => {
      const name = event.target.name;
      const data = listParseAndStringify(props?.finalData);
      const index = data?.status?.findIndex((x: any) => x === name);
      if (index === -1) {
         data.status.push(name);
      } else {
         data.status.splice(index, 1);
      }
      props?.clinicalTrialsFinalObj(data);
   };

   const changeAge = (event: any) => {
      const name = event.target.name;
      const data = listParseAndStringify(props?.finalData);
      const index = data?.age?.findIndex((x: any) => x === name);
      if (index === -1) {
         data.age.push(name);
      } else {
         data.age.splice(index, 1);
      }
      props?.clinicalTrialsFinalObj(data);
   };

   const changeGender = (event: any) => {
      const name = event.target.name;
      const data = listParseAndStringify(props?.finalData);
      const index = data?.gender?.findIndex((x: any) => x === name);
      if (index === -1) {
         data.gender.push(name);
      } else {
         data.gender.splice(index, 1);
      }
      props?.clinicalTrialsFinalObj(data);
   };

   /* TODO: changeGender1 function can be used select all checkbox */
   /* const changeGender1 = (event: any) => {
      const name = event.target.name;
      const data = listParseAndStringify(props?.finalData);

      const selectAllLength = data.gender.length;
      const index = data.gender.findIndex((x: any) => x === name);

      if (selectAllLength !== 3) {
         if (index === -1) {
            if (name === 'SelectAll') {
               data.gender = ['All', 'Male', 'Female'];
            } else {
               data.gender.push(name);
            }
         } else {
            data.gender.splice(index, 1);
         }
      } else if (selectAllLength === 3) {
         if (name === 'SelectAll') {
            data.gender = [];
         } else {
            data.gender.splice(index, 1);
         }
      }

      props?.clinicalTrialsFinalObj(data);
   }; */

   const changeCancerTypeMain = (itemValue: any) => {
      const name = itemValue?.name;
      const data = listParseAndStringify(props?.finalData);
      const index = data?.cancer_type?.findIndex((x: any) => x === name);
      if (index === -1) {
         data.cancer_type.push(name);
      } else {
         data.cancer_type.splice(index, 1);
         const cancerSpecificType = data.cancer_specific.filter((y: any) => y.cancer_type_id === itemValue?.id);
         cancerSpecificType.forEach((w: any) => {
            const index = data.cancer_specific.findIndex((z: any) => z.cancer_type_id === w?.cancer_type_id);
            if (index !== -1) {
               data.cancer_specific.splice(index, 1);
            }
         });
      }
      props?.clinicalTrialsFinalObj(data);

      const cancerTypeIdsLocal = listParseAndStringify(props?.cancerTypeIds);
      const indexCancerType = cancerTypeIdsLocal.findIndex((x: any) => x === itemValue?.id);
      if (indexCancerType === -1) {
         cancerTypeIdsLocal.push(itemValue?.id);
      } else {
         cancerTypeIdsLocal.splice(indexCancerType, 1);
      }
      props?.clinicalTrialsCancerTypeIdChange(cancerTypeIdsLocal);

      setTimeout(() => {
         const cancerList = listParseAndStringify(cancerType?.list || []);
         let finalList: any[] = [];
         if (data.cancer_type.length > 0) {
            data.cancer_type
               .slice()
               .sort()
               .forEach((item: any, i: number) => {
                  const index = cancerList?.findIndex((x: any) => x.name === item);
                  finalList.push(cancerList[index]);
                  cancerList.splice(index, 1);
                  if (data.cancer_type?.length === i + 1) {
                     finalList = finalList.concat(cancerList);
                     setCancerTypeListMain(finalList);
                  }
               });
         } else {
            setCancerTypeListMain(cancerType?.list || []);
         }
      }, SET_TIME_OUT.MIN);
   };

   const changeCancerType = (itemValue: any) => {
      const name = itemValue?.name;
      const data = listParseAndStringify(dataApplyFilter);
      const index = data?.cancer_type?.findIndex((x: any) => x === name);
      if (index === -1) {
         data.cancer_type.push(name);
      } else {
         data.cancer_type.splice(index, 1);
         const cancerSpecificType = data.cancer_specific.filter((y: any) => y.cancer_type_id === itemValue?.id);
         cancerSpecificType.forEach((w: any) => {
            const index = data.cancer_specific.findIndex((z: any) => z.cancer_type_id === w?.cancer_type_id);
            if (index !== -1) {
               data.cancer_specific.splice(index, 1);
            }
         });
      }
      setDataApplyFilter(data);

      const cancerTypeIdsLocal = listParseAndStringify(props?.cancerTypeIds);
      const indexCancerType = cancerTypeIdsLocal.findIndex((x: any) => x === itemValue?.id);
      if (indexCancerType === -1) {
         cancerTypeIdsLocal.push(itemValue?.id);
      } else {
         cancerTypeIdsLocal.splice(indexCancerType, 1);
      }
      props?.clinicalTrialsCancerTypeIdChange(cancerTypeIdsLocal);
   };

   const changeCancerSpecificMain = (itemValue: any) => {
      const name = itemValue.name;
      const data = listParseAndStringify(props?.finalData);
      const index = data?.cancer_specific?.findIndex((x: any) => x.cancer_specific_name === name);
      if (index === -1) {
         data.cancer_specific.push({ cancer_specific_name: name, cancer_type_id: itemValue?.cancer_type_id });
      } else {
         data.cancer_specific.splice(index, 1);
      }
      props?.clinicalTrialsFinalObj(data);

      setTimeout(() => {
         const cancerSpecificList = listParseAndStringify(cancerSpecific?.list || []);
         let finalList: any[] = [];
         if (data?.cancer_specific?.length > 0) {
            data.cancer_specific
               .slice()
               .sort((a: any, b: any) => (a.cancer_specific_name > b.cancer_specific_name ? -1 : 1))
               .forEach((item: any, i: number) => {
                  const index = cancerSpecificList?.findIndex((x: any) => x.name === item.cancer_specific_name);
                  finalList.push(cancerSpecificList[index]);
                  cancerSpecificList.splice(index, 1);
                  if (data.cancer_specific?.length === i + 1) {
                     finalList = finalList.concat(cancerSpecificList);
                     setCancerSpecificListMain(finalList);
                  }
               });
         } else {
            setCancerSpecificListMain(cancerSpecific?.list || []);
         }
      }, SET_TIME_OUT.MIN);
   };

   const changeCancerSpecific = (itemValue: any) => {
      const name = itemValue?.name;
      const data = listParseAndStringify(dataApplyFilter);
      const index = data?.cancer_specific?.findIndex((x: any) => x.cancer_specific_name === name);
      if (index === -1) {
         data.cancer_specific.push({ cancer_specific_name: name, cancer_type_id: itemValue?.cancer_type_id });
      } else {
         data.cancer_specific.splice(index, 1);
      }
      setDataApplyFilter(data);
   };

   const changeStudyTypeMain = (event: any) => {
      const name = event.target.name;
      const data = listParseAndStringify(props?.finalData);
      const index = data.study_type.findIndex((x: any) => x === name);
      if (index === -1) {
         data.study_type.push(name);
      } else {
         data.study_type.splice(index, 1);
      }
      props?.clinicalTrialsFinalObj(data);

      setTimeout(() => {
         const studyTypeList = listParseAndStringify(studyType?.list || []);
         let finalList: any[] = [];
         if (data?.study_type?.length > 0) {
            data.study_type
               .slice()
               .sort()
               .forEach((item: any, i: number) => {
                  const index = studyTypeList?.findIndex((x: any) => x.name === item);
                  if (index !== -1) {
                     finalList.push(studyTypeList[index]);
                     studyTypeList.splice(index, 1);
                     if (data.study_type?.length === i + 1) {
                        finalList = finalList.concat(studyTypeList);
                        setStudyTypeListMain(finalList);
                     }
                  } else {
                     setStudyTypeListMain(studyType?.list || []);
                  }
               });
         } else {
            setStudyTypeListMain(studyType?.list || []);
         }
      }, SET_TIME_OUT.MIN);
   };

   const changeStudyType = (event: any) => {
      const name = event.target.name;
      const data = listParseAndStringify(dataApplyFilter);
      const index = data.study_type.findIndex((x: any) => x === name);
      if (index === -1) {
         data.study_type.push(name);
      } else {
         data.study_type.splice(index, 1);
      }
      setDataApplyFilter(data);
   };

   const changeStudyPhaseMain = (event: any) => {
      const name = event.target.name;
      const data = listParseAndStringify(props?.finalData);
      const index = data?.study_phase?.findIndex((x: any) => x === name);
      if (index === -1) {
         data.study_phase.push(name);
      } else {
         data.study_phase.splice(index, 1);
      }
      props?.clinicalTrialsFinalObj(data);

      setTimeout(() => {
         const studyPhaseList = listParseAndStringify(studyPhase?.list || []);
         let finalList: any[] = [];
         if (data.study_phase.length > 0) {
            data.study_phase
               .slice()
               .sort()
               .forEach((item: any, i: number) => {
                  const index = studyPhaseList?.findIndex((x: any) => x.name === item);
                  finalList.push(studyPhaseList[index]);
                  studyPhaseList.splice(index, 1);
                  if (data.study_phase?.length === i + 1) {
                     finalList = finalList.concat(studyPhaseList);
                     setStudyPhaseListMain(finalList);
                  }
               });
         } else {
            setStudyPhaseListMain(studyPhase?.list || []);
         }
      }, SET_TIME_OUT.MIN);
   };

   const changeStudyPhase = (event: any) => {
      const name = event.target.name;
      const data = listParseAndStringify(dataApplyFilter);
      const index = data?.study_phase?.findIndex((x: any) => x === name);
      if (index === -1) {
         data.study_phase.push(name);
      } else {
         data.study_phase.splice(index, 1);
      }
      setDataApplyFilter(data);
   };

   const changeCountriesMain = (itemValue: any) => {
      const name = itemValue?.name || '';
      const data = listParseAndStringify(props?.finalData);
      const index = data?.countries?.findIndex((x: any) => x === name);
      if (index === -1) {
         data.countries.push(name);
      } else {
         data.countries.splice(index, 1);
         const stateCountrys = data.state.filter((y: any) => y.country_id === itemValue?.id);
         stateCountrys.forEach((w: any) => {
            const index = data.state.findIndex((z: any) => z.country_id === w?.country_id);
            if (index !== -1) {
               data.state.splice(index, 1);
            }
         });
      }
      props?.clinicalTrialsFinalObj(data);

      const countryIdsLocal = listParseAndStringify(props?.countriesIds);
      const indexCountry = countryIdsLocal.findIndex((x: any) => x === itemValue?.id);
      if (indexCountry === -1) {
         countryIdsLocal.push(itemValue?.id);
      } else {
         countryIdsLocal.splice(indexCountry, 1);
      }
      props?.clinicalTrialsCountryIdChange(countryIdsLocal);

      setTimeout(() => {
         const countriesList = listParseAndStringify(countries?.list || []);
         let finalList: any[] = [];
         if (data?.countries?.length > 0) {
            data.countries
               .slice()
               .sort()
               .forEach((item: any, i: number) => {
                  const index = countriesList?.findIndex((x: any) => x.name === item);
                  finalList.push(countriesList[index]);
                  countriesList.splice(index, 1);
                  if (data.countries?.length === i + 1) {
                     finalList = finalList.concat(countriesList);
                     setCountriesListMain(finalList);
                  }
               });
         } else {
            setCountriesListMain(countries?.list || []);
         }
      }, SET_TIME_OUT.MIN);
   };

   const changeStateMain = (stateValue: any) => {
      const name = stateValue?.name || '';
      const data = listParseAndStringify(props?.finalData);
      const index = data?.state?.findIndex((x: any) => x.state_name === name);
      if (index === -1) {
         data.state.push({ state_name: name, country_id: stateValue?.country_id });
      } else {
         data.state.splice(index, 1);
      }
      props?.clinicalTrialsFinalObj(data);

      setTimeout(() => {
         const stateList = listParseAndStringify(state?.list || []);
         let finalList: any[] = [];
         if (data?.state?.length > 0) {
            data.state
               .slice()
               .sort((a: any, b: any) => (a.state_name > b.state_name ? -1 : 1))
               .forEach((item: any, i: number) => {
                  const index = stateList?.findIndex((x: any) => x.name === item.state_name);
                  finalList.push(stateList[index]);
                  stateList.splice(index, 1);
                  if (data.state?.length === i + 1) {
                     finalList = finalList.concat(stateList);
                     setStateListMain(finalList);
                  }
               });
         } else {
            setStateListMain(state?.list || []);
         }
      }, SET_TIME_OUT.MIN);
   };

   const changeCountries = (itemValue: any) => {
      const name = itemValue?.name || '';
      const data = listParseAndStringify(dataApplyFilter);
      const index = data?.countries?.findIndex((x: any) => x === name);
      if (index === -1) {
         data.countries.push(name);
      } else {
         data.countries.splice(index, 1);
         const stateCountrys = data?.state?.filter((y: any) => y.country_id === itemValue?.id);
         stateCountrys.forEach((w: any) => {
            const index = data?.state?.findIndex((z: any) => z.country_id === w?.country_id);
            if (index !== -1) {
               data.state.splice(index, 1);
            }
         });
      }
      setDataApplyFilter(data);

      const countryIdsLocal = listParseAndStringify(props?.countriesIds);
      const indexCountry = countryIdsLocal.findIndex((x: any) => x === itemValue?.id);
      if (indexCountry === -1) {
         countryIdsLocal.push(itemValue?.id);
      } else {
         countryIdsLocal.splice(indexCountry, 1);
      }
      props?.clinicalTrialsCountryIdChange(countryIdsLocal);
   };

   const changeState = (stateValue: any) => {
      const name = stateValue.name;
      const data = listParseAndStringify(dataApplyFilter);
      const index = data?.state?.findIndex((x: any) => x.state_name === name);
      if (index === -1) {
         data.state.push({ state_name: name, country_id: stateValue?.country_id });
      } else {
         data.state.splice(index, 1);
      }
      setDataApplyFilter(data);
   };

   const clearAll = (type: string, finalData: any) => {
      const data = listParseAndStringify(finalData);
      data[type] = [];
      setDataApplyFilter(data);
   };

   useEffect(() => {
      // cancer type start
      setCancerTypeListDialog(cancerType?.list || []);
      const cancerList = listParseAndStringify(cancerType?.list || []);
      const dataCancerType = listParseAndStringify(props?.finalData);
      let finalListCancerType: any[] = [];
      if (dataCancerType?.cancer_type?.length > 0) {
         dataCancerType.cancer_type
            .slice()
            .sort()
            .forEach((item: any, i: number) => {
               const index = cancerList?.findIndex((x: any) => x.name === item);
               if (index !== -1) {
                  finalListCancerType.push(cancerList[index]);
                  cancerList.splice(index, 1);
               }
               if (dataCancerType.cancer_type?.length === i + 1) {
                  finalListCancerType = finalListCancerType.concat(cancerList);
                  setCancerTypeListMain(finalListCancerType);
               }
            });
      } else {
         setCancerTypeListMain(cancerType?.list || []);
      }
      // cancer type end
   }, [cancerType]);

   useEffect(() => {
      // cancer specific start
      setCancerSpecificListDialog(cancerSpecific?.list || []);
      const cancerSpecificList = listParseAndStringify(cancerSpecific?.list || []);
      const dataCancerSpecific = listParseAndStringify(props?.finalData);
      let finalListCancerSpecific: any[] = [];
      if (dataCancerSpecific?.cancer_specific?.length > 0) {
         dataCancerSpecific.cancer_specific
            .slice()
            .sort((a: any, b: any) => (a.cancer_specific_name > b.cancer_specific_name ? -1 : 1))
            .forEach((item: any, i: number) => {
               const index = cancerSpecificList?.findIndex((x: any) => x.name === item.cancer_specific_name);
               if (index !== -1) {
                  finalListCancerSpecific.push(cancerSpecificList[index]);
                  cancerSpecificList.splice(index, 1);
               }
               if (dataCancerSpecific?.cancer_specific?.length === i + 1) {
                  finalListCancerSpecific = finalListCancerSpecific.concat(cancerSpecificList);
                  setCancerSpecificListMain(finalListCancerSpecific);
               }
            });
      } else {
         setCancerSpecificListMain(cancerSpecific?.list || []);
      }
      //cancer specific end
   }, [cancerSpecific]);

   useEffect(() => {
      // study type start
      setStudyTypeListDialog(studyType?.list || []);
      const studyTypeList = listParseAndStringify(studyType?.list || []);
      const dataStudyType = listParseAndStringify(props?.finalData);
      let finalListStudyType: any[] = [];
      if (dataStudyType?.study_type?.length > 0) {
         dataStudyType.study_type
            .slice()
            .sort()
            .forEach((item: any, i: number) => {
               const index = studyTypeList?.findIndex((x: any) => x.name === item);
               if (index !== -1) {
                  finalListStudyType.push(studyTypeList[index]);
                  studyTypeList.splice(index, 1);
               }
               if (dataStudyType.study_type?.length === i + 1) {
                  finalListStudyType = finalListStudyType.concat(studyTypeList);
                  setStudyTypeListMain(finalListStudyType);
               }
            });
      } else {
         setStudyTypeListMain(studyType?.list || []);
      }
      //study type end
   }, [studyType]);

   useEffect(() => {
      // study phase start
      setStudyPhaseListDialog(studyPhase?.list || []);
      const studyPhaseList = listParseAndStringify(studyPhase?.list || []);
      const dataStudyPhase = listParseAndStringify(props?.finalData);
      let finalListStudyPhase: any[] = [];
      if (dataStudyPhase.study_phase?.length > 0) {
         dataStudyPhase.study_phase
            .slice()
            .sort()
            .forEach((item: any, i: number) => {
               const index = studyPhaseList?.findIndex((x: any) => x.name === item);
               if (index !== -1) {
                  finalListStudyPhase.push(studyPhaseList[index]);
                  studyPhaseList.splice(index, 1);
               }
               if (dataStudyPhase.study_phase?.length === i + 1) {
                  finalListStudyPhase = finalListStudyPhase.concat(studyPhaseList);
                  setStudyPhaseListMain(finalListStudyPhase);
               }
            });
      } else {
         setStudyPhaseListMain(studyPhase?.list || []);
      }
      // study phase end
   }, [studyPhase]);

   useEffect(() => {
      // countries start
      setCountriesListDialog(countries?.list || []);
      const countriesList = listParseAndStringify(countries?.list || []);
      const dataCountries = listParseAndStringify(props?.finalData);
      let finalListCountries: any[] = [];
      if (dataCountries.countries?.length > 0) {
         dataCountries.countries
            .slice()
            .sort()
            .forEach((item: any, i: number) => {
               const index = countriesList?.findIndex((x: any) => x.name === item);
               if (index !== -1) {
                  finalListCountries.push(countriesList[index]);
                  countriesList.splice(index, 1);
               }
               if (dataCountries.countries?.length === i + 1) {
                  finalListCountries = finalListCountries.concat(countriesList);
                  setCountriesListMain(finalListCountries);
               }
            });
      } else {
         setCountriesListMain(countries?.list || []);
      }
      // countries end
   }, [countries]);

   useEffect(() => {
      // state start
      setStateListDialog(state?.list || []);
      const stateList = listParseAndStringify(state?.list || []);
      const dataState = listParseAndStringify(props?.finalData);

      let finalListState: any[] = [];
      if (dataState.state?.length > 0) {
         dataState.state
            .slice()
            .sort((a: any, b: any) => (a.state_name > b.state_name ? -1 : 1))
            .forEach((item: any, i: number) => {
               const index = stateList?.findIndex((x: any) => x.name === item?.state_name);
               if (index !== -1) {
                  finalListState.push(stateList[index]);
                  stateList.splice(index, 1);
               }
               if (dataState.state?.length === i + 1) {
                  finalListState = finalListState.concat(stateList);
                  setStateListMain(finalListState);
               }
            });
      } else {
         setStateListMain(state?.list || []);
      }
      // state end
   }, [state]);

   const renderBottomButtons = (type: string) => {
      return (
         <Box className="filter-bottom-card " px={4}>
            <Button
               className="clear-all"
               onClick={() => {
                  clearAll(type, dataApplyFilter);
               }}
            >
               Clear All
            </Button>
            <Button
               className="ml-4 apply-filter"
               onClick={() => {
                  props?.clinicalTrialsFinalObj(dataApplyFilter);
                  if (type === 'cancer_type') {
                     handleCloseCancerType(dataApplyFilter);
                  } else if (type === 'cancer_specific') {
                     handleCloseCancerSpecific(dataApplyFilter);
                  } else if (type === 'study_type') {
                     handleCloseStudyType(dataApplyFilter);
                  } else if (type === 'study_phase') {
                     handleCloseStudyPhase(dataApplyFilter);
                  } else if (type === 'countries') {
                     handleCloseCountries(dataApplyFilter);
                  } else if (type === 'state') {
                     handleCloseState(dataApplyFilter);
                  }
                  setTimeout(() => {
                     setDataApplyFilter(null);
                  }, SET_TIME_OUT.MIN);
               }}
            >
               Apply Filter
            </Button>
         </Box>
      );
   };

   return (
      <Box>
         <Box className="trials-filter">
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Grid container direction="row" alignItems="center">
                     <Grid item>
                        <Typography className="filter-name">Status</Typography>
                     </Grid>
                     <Grid item>
                        <Box className="filtered-text-div">
                           <Grid container direction="row">
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text-bold">
                                    {`${props.finalData?.status?.length || 0}`}
                                 </Typography>
                              </Grid>
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text">
                                    Filtered
                                 </Typography>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                  </Grid>
               </AccordionSummary>
               <AccordionDetails className="details">
                  <Grid container direction="column">
                     <Grid item>
                        <FormControlLabel
                           value="end"
                           control={
                              <Checkbox
                                 color="primary"
                                 name="Recruiting"
                                 checked={props.finalData?.status?.findIndex((x: any) => x === 'Recruiting') !== -1}
                                 onChange={changeStatus}
                              />
                           }
                           label="Recruiting"
                           labelPlacement="end"
                        />
                     </Grid>
                     <Grid item>
                        <FormControlLabel
                           value="end"
                           control={
                              <Checkbox
                                 color="primary"
                                 name="Not yet recruiting"
                                 checked={
                                    props.finalData?.status?.findIndex((x: any) => x === 'Not yet recruiting') !== -1
                                 }
                                 onChange={changeStatus}
                              />
                           }
                           label="Not yet recruiting"
                           labelPlacement="end"
                        />
                     </Grid>
                     <Grid item>
                        <FormControlLabel
                           value="end"
                           control={
                              <Checkbox
                                 color="primary"
                                 name="Enrolling by invitation"
                                 checked={
                                    props.finalData?.status?.findIndex((x: any) => x === 'Enrolling by invitation') !==
                                    -1
                                 }
                                 onChange={changeStatus}
                              />
                           }
                           label="Enrolling by invitation"
                           labelPlacement="end"
                        />
                     </Grid>
                     {errorsList?.status ? (
                        <Typography className="filter-error-name">{errorsList?.status || ''}</Typography>
                     ) : null}
                  </Grid>
               </AccordionDetails>
            </Accordion>
            <Divider />
         </Box>
         <Box className="trials-filter">
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Grid container direction="row" alignItems="center">
                     <Grid item>
                        <Typography className="filter-name">Eligibility Criteria</Typography>
                     </Grid>
                     <Grid item>
                        <Box className="filtered-text-div">
                           <Grid container direction="row">
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text-bold">
                                    {`${(props.finalData?.age?.length || 0) + (props.finalData?.gender?.length || 0)}`}
                                 </Typography>
                              </Grid>
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text">
                                    Filtered
                                 </Typography>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                  </Grid>
               </AccordionSummary>
               <AccordionDetails className="details">
                  <Grid container direction="column">
                     <Grid item>
                        <Box py={1}>
                           <Typography className="details-title">Age</Typography>
                        </Box>
                     </Grid>
                     <Grid item>
                        <FormControlLabel
                           value="end"
                           control={
                              <Checkbox
                                 color="primary"
                                 name="Child"
                                 checked={props.finalData?.age?.findIndex((x: any) => x === 'Child') !== -1}
                                 onChange={changeAge}
                              />
                           }
                           label="Child (Birth–17)"
                           labelPlacement="end"
                        />
                     </Grid>
                     <Grid item>
                        <FormControlLabel
                           value="end"
                           control={
                              <Checkbox
                                 color="primary"
                                 name="Adult"
                                 checked={props.finalData?.age?.findIndex((x: any) => x === 'Adult') !== -1}
                                 onChange={changeAge}
                              />
                           }
                           label="Adult (18–64)"
                           labelPlacement="end"
                        />
                     </Grid>
                     <Grid item>
                        <FormControlLabel
                           value="end"
                           control={
                              <Checkbox
                                 color="primary"
                                 name="Older Adult"
                                 checked={props.finalData?.age?.findIndex((x: any) => x === 'Older Adult') !== -1}
                                 onChange={changeAge}
                              />
                           }
                           label="Older Adult (65+)"
                           labelPlacement="end"
                        />
                     </Grid>
                     {errorsList?.age ? (
                        <Typography className="filter-error-name">{errorsList?.age || ''}</Typography>
                     ) : null}
                     <Grid item>
                        <Box py={1}>
                           <Typography className="details-title">Sex</Typography>
                        </Box>
                     </Grid>
                     {/* <Grid item>
                        <FormControlLabel
                           value="end"
                           control={
                              <Checkbox
                                 color="primary"
                                 name="SelectAll"
                                 checked={props.finalData?.gender?.length === 3}
                                 onChange={changeGender}
                              />
                           }
                           label="Select all"
                           labelPlacement="end"
                        />
                     </Grid> */}
                     <Grid item>
                        <FormControlLabel
                           value="end"
                           control={
                              <Checkbox
                                 color="primary"
                                 name="All"
                                 checked={
                                    /* props.finalData?.gender?.length === 3 || */
                                    props.finalData?.gender?.findIndex((x: any) => x === 'All') !== -1
                                 }
                                 onChange={changeGender}
                              />
                           }
                           label="All"
                           labelPlacement="end"
                        />
                     </Grid>
                     <Grid item>
                        <FormControlLabel
                           value="end"
                           control={
                              <Checkbox
                                 color="primary"
                                 name="Male"
                                 checked={
                                    /* props.finalData?.gender?.length === 3 || */
                                    props.finalData?.gender?.findIndex((x: any) => x === 'Male') !== -1
                                 }
                                 onChange={changeGender}
                              />
                           }
                           label="Male"
                           labelPlacement="end"
                        />
                     </Grid>
                     <Grid item>
                        <FormControlLabel
                           value="end"
                           control={
                              <Checkbox
                                 color="primary"
                                 name="Female"
                                 checked={
                                    /* props.finalData?.gender?.length === 3 || */
                                    props.finalData?.gender?.findIndex((x: any) => x === 'Female') !== -1
                                 }
                                 onChange={changeGender}
                              />
                           }
                           label="Female"
                           labelPlacement="end"
                        />
                     </Grid>
                     {errorsList?.gender ? (
                        <Typography className="filter-error-name">{errorsList?.gender || ''}</Typography>
                     ) : null}
                  </Grid>
               </AccordionDetails>
            </Accordion>
            <Divider />
         </Box>
         <Box className="trials-filter">
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Grid container direction="row" alignItems="center">
                     <Grid item>
                        <Typography className="filter-name">Cancer Type</Typography>
                     </Grid>
                     <Grid item>
                        <Box className="filtered-text-div">
                           <Grid container direction="row">
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text-bold">
                                    {`${props.finalData?.cancer_type?.length || 0}`}
                                 </Typography>
                              </Grid>
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text">
                                    Filtered
                                 </Typography>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                  </Grid>
               </AccordionSummary>
               <AccordionDetails className="details">
                  <Grid container direction="column">
                     {/* <Grid item>
                        <Box className="global-search" py={1}>
                           <Box className="clinical-trials">
                              <TextField
                                 autoComplete="off"
                                 id="outlined-basic"
                                 placeholder="Search cancer type"
                                 fullWidth
                                 autoFocus
                                 variant="outlined"
                                 InputProps={{
                                    startAdornment: (
                                       <InputAdornment position="start">
                                          <img src={SearchIcon} alt={'img'} />
                                       </InputAdornment>
                                    ),
                                 }}
                                 style={{ width: '100%' }}
                                 value={searchWordListDialogCancerType}
                                 onChange={changeListDialogCancerType}
                              />
                           </Box>
                        </Box>
                     </Grid> */}
                     {cancerTypeListMain?.length > 0 ? (
                        cancerTypeListMain.map((item: any, i: number) => {
                           if (i < 5) {
                              return (
                                 <Grid item key={i}>
                                    <FormControlLabel
                                       value="end"
                                       control={
                                          <Checkbox
                                             color="primary"
                                             name={item?.name || ''}
                                             checked={
                                                props.finalData?.cancer_type?.findIndex(
                                                   (x: any) => x === item?.name
                                                ) !== -1
                                             }
                                             onChange={() => {
                                                changeCancerTypeMain(item);
                                             }}
                                          />
                                       }
                                       label={item?.name || ''}
                                       labelPlacement="end"
                                    />
                                 </Grid>
                              );
                           } else {
                              return null;
                           }
                        })
                     ) : (
                        <NoData />
                     )}

                     <Box
                        py={0.2}
                        onClick={(e: any) => {
                           if (cancerTypeListMain?.length >= 5) {
                              handleClickCancerType(e);
                              setDataApplyFilter(props?.finalData);
                           }
                        }}
                        className={cancerTypeListMain?.length >= 5 ? 'hand-i' : ''}
                     >
                        {cancerTypeListMain?.length >= 5 ? (
                           <Typography className="details-more">+ More</Typography>
                        ) : null}
                     </Box>
                     {errorsList?.cancer_type ? (
                        <Typography className="filter-error-name">{errorsList?.cancer_type || ''}</Typography>
                     ) : null}
                  </Grid>
               </AccordionDetails>
            </Accordion>
            <Divider />
         </Box>
         <Box className="trials-filter">
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Grid container direction="row" alignItems="center">
                     <Grid item>
                        <Typography className="filter-name">Cancer Specific</Typography>
                     </Grid>
                     <Grid item>
                        <Box className="filtered-text-div">
                           <Grid container direction="row">
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text-bold">
                                    {`${props.finalData?.cancer_specific?.length || 0}`}
                                 </Typography>
                              </Grid>
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text">
                                    Filtered
                                 </Typography>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                  </Grid>
               </AccordionSummary>
               <AccordionDetails className="details">
                  <Grid container direction="column">
                     {/* <Grid item>
                        <Box className="global-search" py={1} pb={2}>
                           <Box className="clinical-trials">
                              <TextField
                                 autoComplete="off"
                                 id="outlined-basic"
                                 placeholder="Search cancer specific"
                                 fullWidth
                                 autoFocus
                                 variant="outlined"
                                 InputProps={{
                                    startAdornment: (
                                       <InputAdornment position="start">
                                          <img src={SearchIcon} alt={'img'} />
                                       </InputAdornment>
                                    ),
                                 }}
                                 style={{ width: '100%' }}
                                 value={searchWordListDialogCancerSpecific}
                                 onChange={changeListDialogCancerSpecific}
                              />
                           </Box>
                        </Box>
                     </Grid> */}
                     {cancerSpecificListMain?.length > 0 ? (
                        cancerSpecificListMain.map((item: any, i: number) => {
                           if (i < 5) {
                              return (
                                 <Grid item key={i}>
                                    <FormControlLabel
                                       value="end"
                                       control={
                                          <Checkbox
                                             color="primary"
                                             name={item?.name || ''}
                                             checked={
                                                props.finalData?.cancer_specific?.findIndex(
                                                   (x: any) => x.cancer_specific_name === item?.name
                                                ) !== -1
                                             }
                                             onChange={() => {
                                                changeCancerSpecificMain(item);
                                             }}
                                          />
                                       }
                                       label={item?.name || ''}
                                       labelPlacement="end"
                                    />
                                 </Grid>
                              );
                           } else {
                              return null;
                           }
                        })
                     ) : (
                        <NoData />
                     )}

                     <Box
                        py={0.2}
                        onClick={(e: any) => {
                           if (cancerSpecificListMain?.length >= 5) {
                              handleClickCancerSpecific(e);
                              setDataApplyFilter(props?.finalData);
                           }
                        }}
                        className={cancerSpecificListMain?.length >= 5 ? 'hand-i' : ''}
                     >
                        {cancerSpecificListMain?.length >= 5 ? (
                           <Typography className="details-more">+ More</Typography>
                        ) : null}
                     </Box>
                     {errorsList?.cancer_specific ? (
                        <Typography className="filter-error-name">{errorsList?.cancer_specific || ''}</Typography>
                     ) : null}
                  </Grid>
               </AccordionDetails>
            </Accordion>
            <Divider />
         </Box>
         <Box className="trials-filter">
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Grid container direction="row" alignItems="center">
                     <Grid item>
                        <Typography className="filter-name">Study Type</Typography>
                     </Grid>
                     <Grid item>
                        <Box className="filtered-text-div">
                           <Grid container direction="row">
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text-bold">
                                    {`${props.finalData?.study_type?.length || 0}`}
                                 </Typography>
                              </Grid>
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text">
                                    Filtered
                                 </Typography>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                  </Grid>
               </AccordionSummary>
               <AccordionDetails className="details">
                  <Grid container direction="column">
                     {/* <Grid item>
                        <FormControlLabel
                           value="end"
                           control={
                              <Checkbox
                                 color="primary"
                                 name="All"
                                 checked={props.finalData?.study_type?.findIndex((x: any) => x === 'All') !== -1}
                                 onChange={changeStudyTypeMain}
                              />
                           }
                           label="All"
                           labelPlacement="end"
                        />
                     </Grid> */}
                     {studyTypeListMain?.length > 0 ? (
                        studyTypeListMain?.map((item: any, i: number) => {
                           if (i < 5) {
                              return (
                                 <Grid item key={i}>
                                    <FormControlLabel
                                       value="end"
                                       control={
                                          <Checkbox
                                             color="primary"
                                             name={item?.name || ''}
                                             checked={
                                                props.finalData?.study_type?.findIndex((x: any) => x === 'All') !==
                                                   -1 ||
                                                props.finalData?.study_type?.findIndex((x: any) => x === item?.name) !==
                                                   -1
                                             }
                                             onChange={changeStudyTypeMain}
                                          />
                                       }
                                       label={item?.name || ''}
                                       labelPlacement="end"
                                    />
                                 </Grid>
                              );
                           } else {
                              return null;
                           }
                        })
                     ) : (
                        <NoData />
                     )}

                     <Box
                        py={0.2}
                        onClick={(e: any) => {
                           if (studyType?.list?.length >= 5) {
                              handleClickStudyType(e);
                              setDataApplyFilter(props?.finalData);
                           }
                        }}
                        className={studyType?.list?.length >= 5 ? 'hand-i' : ''}
                     >
                        {studyType?.list?.length >= 5 ? <Typography className="details-more">+ More</Typography> : null}
                     </Box>
                     {errorsList?.study_type ? (
                        <Typography className="filter-error-name">{errorsList?.study_type || ''}</Typography>
                     ) : null}
                  </Grid>
               </AccordionDetails>
            </Accordion>
            <Divider />
         </Box>
         <Box className="trials-filter">
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Grid container direction="row" alignItems="center">
                     <Grid item>
                        <Typography className="filter-name">Study Phase</Typography>
                     </Grid>
                     <Grid item>
                        <Box className="filtered-text-div">
                           <Grid container direction="row">
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text-bold">
                                    {`${props.finalData?.study_phase?.length || 0}`}
                                 </Typography>
                              </Grid>
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text">
                                    Filtered
                                 </Typography>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                  </Grid>
               </AccordionSummary>
               <AccordionDetails className="details">
                  <Grid container direction="column">
                     {studyPhaseListMain?.length > 0 ? (
                        studyPhaseListMain?.map((item: any, i: number) => {
                           if (i < 5) {
                              return (
                                 <Grid item key={i}>
                                    <FormControlLabel
                                       value="end"
                                       control={
                                          <Checkbox
                                             color="primary"
                                             name={item?.name || ''}
                                             checked={
                                                props.finalData?.study_phase?.findIndex(
                                                   (x: any) => x === item?.name
                                                ) !== -1
                                             }
                                             onChange={changeStudyPhaseMain}
                                          />
                                       }
                                       label={item?.name || ''}
                                       labelPlacement="end"
                                    />
                                 </Grid>
                              );
                           } else {
                              return null;
                           }
                        })
                     ) : (
                        <NoData />
                     )}

                     <Box
                        py={0.2}
                        onClick={(e: any) => {
                           if (studyPhase?.list?.length >= 5) {
                              handleClickStudyPhase(e);
                              setDataApplyFilter(props?.finalData);
                           }
                        }}
                        className={studyPhase?.list?.length >= 5 ? 'hand-i' : ''}
                     >
                        {studyPhase?.list?.length >= 5 ? (
                           <Typography className="details-more">+ More</Typography>
                        ) : null}
                     </Box>
                     {errorsList?.study_phase ? (
                        <Typography className="filter-error-name">{errorsList?.study_phase || ''}</Typography>
                     ) : null}
                  </Grid>
               </AccordionDetails>
            </Accordion>
            <Divider />
         </Box>
         <Box className="trials-filter">
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Grid container direction="row" alignItems="center">
                     <Grid item>
                        <Typography className="filter-name">Country</Typography>
                     </Grid>
                     <Grid item>
                        <Box className="filtered-text-div">
                           <Grid container direction="row">
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text-bold">
                                    {`${props.finalData?.countries?.length || 0}`}
                                 </Typography>
                              </Grid>
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text">
                                    Filtered
                                 </Typography>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                  </Grid>
               </AccordionSummary>
               <AccordionDetails className="details">
                  <Grid container direction="column">
                     {/* <Grid item>
                        <Box className="global-search" py={1} pb={2}>
                           <Box className="clinical-trials">
                              <TextField
                                 autoComplete="off"
                                 id="outlined-basic"
                                 placeholder="Search state"
                                 fullWidth
                                 autoFocus
                                 variant="outlined"
                                 InputProps={{
                                    startAdornment: (
                                       <InputAdornment position="start">
                                          <img src={SearchIcon} alt={'img'} />
                                       </InputAdornment>
                                    ),
                                 }}
                                 style={{ width: '100%' }}
                                 value={searchWordListDialogState}
                                 onChange={changeListDialogState}
                              />
                           </Box>
                        </Box>
                     </Grid> */}
                     {countriesListMain?.length > 0 ? (
                        countriesListMain.map((item: any, i: number) => {
                           if (i < 5) {
                              return (
                                 <Grid item key={i}>
                                    <FormControlLabel
                                       value="end"
                                       control={
                                          <Checkbox
                                             color="primary"
                                             name={item?.name || ''}
                                             checked={
                                                props.finalData?.countries?.findIndex((x: any) => x === item?.name) !==
                                                -1
                                             }
                                             onChange={() => {
                                                changeCountriesMain(item);
                                             }}
                                          />
                                       }
                                       label={item?.name || ''}
                                       labelPlacement="end"
                                    />
                                 </Grid>
                              );
                           } else {
                              return null;
                           }
                        })
                     ) : (
                        <NoData />
                     )}

                     <Box
                        py={0.2}
                        onClick={(e: any) => {
                           if (countriesListMain?.length >= 5) {
                              handleClickCountries(e);
                              setDataApplyFilter(props?.finalData);
                           }
                        }}
                        className={countriesListMain?.length >= 5 ? 'hand-i' : ''}
                     >
                        {countriesListMain?.length >= 5 ? (
                           <Typography className="details-more">+ More</Typography>
                        ) : null}
                     </Box>
                     {errorsList?.countries ? (
                        <Typography className="filter-error-name">{errorsList?.countries || ''}</Typography>
                     ) : null}
                  </Grid>
               </AccordionDetails>
            </Accordion>
            <Divider />
         </Box>
         <Box className="trials-filter">
            <Accordion>
               <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Grid container direction="row" alignItems="center">
                     <Grid item>
                        <Typography className="filter-name">State / Territory</Typography>
                     </Grid>
                     <Grid item>
                        <Box className="filtered-text-div">
                           <Grid container direction="row">
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text-bold">
                                    {`${props.finalData?.state?.length || 0}`}
                                 </Typography>
                              </Grid>
                              <Grid item>
                                 <Typography component={'span'} className="filtered-text">
                                    Filtered
                                 </Typography>
                              </Grid>
                           </Grid>
                        </Box>
                     </Grid>
                  </Grid>
               </AccordionSummary>
               <AccordionDetails className="details">
                  <Grid container direction="column">
                     {/* <Grid item>
                        <Box className="global-search" py={1} pb={2}>
                           <Box className="clinical-trials">
                              <TextField
                                 autoComplete="off"
                                 id="outlined-basic"
                                 placeholder="Search state"
                                 fullWidth
                                 autoFocus
                                 variant="outlined"
                                 InputProps={{
                                    startAdornment: (
                                       <InputAdornment position="start">
                                          <img src={SearchIcon} alt={'img'} />
                                       </InputAdornment>
                                    ),
                                 }}
                                 style={{ width: '100%' }}
                                 value={searchWordListDialogState}
                                 onChange={changeListDialogState}
                              />
                           </Box>
                        </Box>
                     </Grid> */}
                     {stateListMain?.length > 0 ? (
                        stateListMain.map((item: any, i: number) => {
                           if (i < 5) {
                              return (
                                 <Grid item key={i}>
                                    <FormControlLabel
                                       value="end"
                                       control={
                                          <Checkbox
                                             color="primary"
                                             name={item?.name || ''}
                                             checked={
                                                props.finalData?.state?.findIndex(
                                                   (x: any) => x.state_name === item?.name
                                                ) !== -1
                                             }
                                             onChange={() => {
                                                changeStateMain(item);
                                             }}
                                          />
                                       }
                                       label={item?.name || ''}
                                       labelPlacement="end"
                                    />
                                 </Grid>
                              );
                           } else {
                              return null;
                           }
                        })
                     ) : (
                        <NoData />
                     )}

                     <Box
                        py={0.2}
                        onClick={(e: any) => {
                           if (stateListMain?.length >= 5) {
                              handleClickState(e);
                              setDataApplyFilter(props?.finalData);
                           }
                        }}
                        className={stateListMain?.length >= 5 ? 'hand-i' : ''}
                     >
                        {stateListMain?.length >= 5 ? <Typography className="details-more">+ More</Typography> : null}
                     </Box>
                     {errorsList?.state ? (
                        <Typography className="filter-error-name">{errorsList?.state || ''}</Typography>
                     ) : null}
                  </Grid>
               </AccordionDetails>
            </Accordion>
            <Divider />
         </Box>

         <Box className="more-popover">
            {/* Cancer type */}
            <Popover
               id={Boolean(anchorElCancerType) ? 'simple-popover' : undefined}
               open={Boolean(anchorElCancerType)}
               anchorEl={anchorElCancerType}
               onClose={() => {
                  handleCloseCancerType(props?.finalData);
               }}
               anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
               }}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
               }}
               className="more-popover"
               disableBackdropClick
               disableEscapeKeyDown
            >
               <Box className="more-box style-7" py={2}>
                  <Box className="global-search" py={1}>
                     <Box className="clinical-trials-more" display="flex" justifyContent="space-between">
                        <Grid container alignItems="center">
                           <Grid item>
                              <TextField
                                 autoComplete="off"
                                 id="outlined-basic"
                                 placeholder="Search cancer type"
                                 fullWidth
                                 autoFocus
                                 variant="outlined"
                                 InputProps={{
                                    startAdornment: (
                                       <InputAdornment position="start">
                                          <img src={SearchIcon} alt={'img'} />
                                       </InputAdornment>
                                    ),
                                 }}
                                 style={{ width: '231px' }}
                                 value={searchWordListDialogCancerType}
                                 onChange={changeListDialogCancerType}
                              />
                           </Grid>
                        </Grid>
                        <Box>
                           <IconButton
                              onClick={() => {
                                 handleCloseCancerType(props?.finalData);
                              }}
                           >
                              <CloseIcon />
                           </IconButton>
                        </Box>
                     </Box>
                  </Box>
                  <Box className="item-div style-7" px={1}>
                     <Grid container direction="row" spacing={1}>
                        {cancerTypeListDialog?.length > 0 ? (
                           cancerTypeListDialog.map((item: any, i: number) => {
                              return (
                                 <Grid item key={i} xs={4}>
                                    <FormControlLabel
                                       value="end"
                                       control={
                                          <Checkbox
                                             color="primary"
                                             name={item?.name || ''}
                                             checked={
                                                dataApplyFilter?.cancer_type?.findIndex(
                                                   (x: any) => x === item?.name
                                                ) !== -1
                                             }
                                             onChange={() => {
                                                changeCancerType(item);
                                             }}
                                          />
                                       }
                                       label={item?.name || ''}
                                       labelPlacement="end"
                                    />
                                 </Grid>
                              );
                           })
                        ) : (
                           <NoData />
                        )}
                     </Grid>
                  </Box>
                  {renderBottomButtons('cancer_type')}
                  {errorsList?.cancer_type ? (
                     <Typography className="filter-error-name">{errorsList?.cancer_type || ''}</Typography>
                  ) : null}
               </Box>
            </Popover>

            {/* cancer specific */}
            <Popover
               id={Boolean(anchorElCancerSpecific) ? 'simple-popover' : undefined}
               open={Boolean(anchorElCancerSpecific)}
               anchorEl={anchorElCancerSpecific}
               onClose={() => {
                  handleCloseCancerSpecific(props?.finalData);
               }}
               anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
               }}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
               }}
               className="more-popover"
               disableBackdropClick
               disableEscapeKeyDown
            >
               <Box className="more-box style-7" py={2}>
                  <Box className="global-search" py={1}>
                     <Box className="clinical-trials-more" display="flex" justifyContent="space-between">
                        <Grid container alignItems="center">
                           <Grid item>
                              <TextField
                                 autoComplete="off"
                                 id="outlined-basic"
                                 placeholder="Search cancer specific"
                                 fullWidth
                                 autoFocus
                                 variant="outlined"
                                 InputProps={{
                                    startAdornment: (
                                       <InputAdornment position="start">
                                          <img src={SearchIcon} alt={'img'} />
                                       </InputAdornment>
                                    ),
                                 }}
                                 style={{ width: '231px' }}
                                 value={searchWordListDialogCancerSpecific}
                                 onChange={changeListDialogCancerSpecific}
                              />
                           </Grid>
                        </Grid>
                        <Box>
                           <IconButton
                              onClick={() => {
                                 handleCloseCancerSpecific(props?.finalData);
                              }}
                           >
                              <CloseIcon />
                           </IconButton>
                        </Box>
                     </Box>
                  </Box>
                  <Box className="item-div style-7" px={2}>
                     <Grid container direction="row" spacing={1}>
                        {cancerSpecificListDialog?.length > 0 ? (
                           cancerSpecificListDialog.map((item: any, i: number) => {
                              return (
                                 <Grid item key={i} xs={4}>
                                    <FormControlLabel
                                       value="end"
                                       control={
                                          <Checkbox
                                             color="primary"
                                             name={item?.name || ''}
                                             checked={
                                                dataApplyFilter?.cancer_specific?.findIndex(
                                                   (x: any) => x.cancer_specific_name === item?.name
                                                ) !== -1
                                             }
                                             onChange={() => {
                                                changeCancerSpecific(item);
                                             }}
                                          />
                                       }
                                       label={item?.name || ''}
                                       labelPlacement="end"
                                    />
                                 </Grid>
                              );
                           })
                        ) : (
                           <NoData />
                        )}
                     </Grid>
                  </Box>
                  {renderBottomButtons('cancer_specific')}
                  {errorsList?.cancer_specific ? (
                     <Typography className="filter-error-name">{errorsList?.cancer_specific || ''}</Typography>
                  ) : null}
               </Box>
            </Popover>

            {/* study type */}
            <Popover
               id={Boolean(anchorElStudyType) ? 'simple-popover' : undefined}
               open={Boolean(anchorElStudyType)}
               anchorEl={anchorElStudyType}
               onClose={() => {
                  handleCloseStudyType(props?.finalData);
               }}
               anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
               }}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
               }}
               className="more-popover"
               disableBackdropClick
               disableEscapeKeyDown
            >
               <Box className="more-box style-7" py={2}>
                  <Box className="global-search" py={1}>
                     <Box className="clinical-trials-more" display="flex" justifyContent="space-between">
                        <Grid container alignItems="center">
                           <Grid item>
                              <TextField
                                 autoComplete="off"
                                 id="outlined-basic"
                                 placeholder="Search study type"
                                 fullWidth
                                 autoFocus
                                 variant="outlined"
                                 InputProps={{
                                    startAdornment: (
                                       <InputAdornment position="start">
                                          <img src={SearchIcon} alt={'img'} />
                                       </InputAdornment>
                                    ),
                                 }}
                                 style={{ width: '231px' }}
                                 value={searchWordListDialogStudyType}
                                 onChange={changeListDialogStudyType}
                              />
                           </Grid>
                        </Grid>
                        <Box>
                           <IconButton
                              onClick={() => {
                                 handleCloseStudyType(props?.finalData);
                              }}
                           >
                              <CloseIcon />
                           </IconButton>
                        </Box>
                     </Box>
                  </Box>
                  <Box className="item-div style-7" px={1}>
                     <Grid container direction="row" spacing={1}>
                        <Grid item xs={4}>
                           <FormControlLabel
                              value="end"
                              control={
                                 <Checkbox
                                    color="primary"
                                    name={'All'}
                                    checked={props.finalData?.study_type?.findIndex((x: any) => x === 'All') !== -1}
                                    onChange={changeStudyType}
                                 />
                              }
                              label={'All'}
                              labelPlacement="end"
                           />
                        </Grid>
                        {studyTypeListDialog?.length > 0 ? (
                           studyTypeListDialog.map((item: any, i: number) => {
                              return (
                                 <Grid item key={i} xs={4}>
                                    <FormControlLabel
                                       value="end"
                                       control={
                                          <Checkbox
                                             color="primary"
                                             name={item?.name || ''}
                                             checked={
                                                dataApplyFilter?.study_type?.findIndex((x: any) => x === 'All') !==
                                                   -1 ||
                                                dataApplyFilter?.study_type?.findIndex((x: any) => x === item?.name) !==
                                                   -1
                                             }
                                             onChange={changeStudyType}
                                          />
                                       }
                                       label={item?.name || ''}
                                       labelPlacement="end"
                                    />
                                 </Grid>
                              );
                           })
                        ) : (
                           <NoData />
                        )}
                     </Grid>
                  </Box>
               </Box>
               {renderBottomButtons('study_type')}
               {errorsList?.study_type ? (
                  <Typography className="filter-error-name">{errorsList?.study_type || ''}</Typography>
               ) : null}
            </Popover>

            {/* study phase */}
            <Popover
               id={Boolean(anchorElStudyPhase) ? 'simple-popover' : undefined}
               open={Boolean(anchorElStudyPhase)}
               anchorEl={anchorElStudyPhase}
               onClose={() => {
                  handleCloseStudyPhase(props?.finalData);
               }}
               anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
               }}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
               }}
               className="more-popover"
               disableBackdropClick
               disableEscapeKeyDown
            >
               <Box className="more-box style-7" py={2}>
                  <Box className="global-search" py={1}>
                     <Box className="clinical-trials-more" display="flex" justifyContent="space-between">
                        <Grid container alignItems="center">
                           <Grid item>
                              <TextField
                                 autoComplete="off"
                                 id="outlined-basic"
                                 placeholder="Search study phase"
                                 fullWidth
                                 autoFocus
                                 variant="outlined"
                                 InputProps={{
                                    startAdornment: (
                                       <InputAdornment position="start">
                                          <img src={SearchIcon} alt={'img'} />
                                       </InputAdornment>
                                    ),
                                 }}
                                 style={{ width: '231px' }}
                                 value={searchWordListDialogStudyPhase}
                                 onChange={changeListDialogStudyPhase}
                              />
                           </Grid>
                        </Grid>
                        <Box>
                           <IconButton
                              onClick={() => {
                                 handleCloseStudyPhase(props?.finalData);
                              }}
                           >
                              <CloseIcon />
                           </IconButton>
                        </Box>
                     </Box>
                  </Box>
                  <Box className="item-div style-7" px={1}>
                     <Grid container direction="row" spacing={1}>
                        {studyPhaseListDialog?.length > 0 ? (
                           studyPhaseListDialog.map((item: any, i: number) => {
                              return (
                                 <Grid item key={i} xs={4}>
                                    <FormControlLabel
                                       value="end"
                                       control={
                                          <Checkbox
                                             color="primary"
                                             name={item?.name || ''}
                                             checked={
                                                dataApplyFilter?.study_phase?.findIndex(
                                                   (x: any) => x === item?.name
                                                ) !== -1
                                             }
                                             onChange={changeStudyPhase}
                                          />
                                       }
                                       label={item?.name || ''}
                                       labelPlacement="end"
                                    />
                                 </Grid>
                              );
                           })
                        ) : (
                           <NoData />
                        )}
                     </Grid>
                  </Box>
                  {renderBottomButtons('study_phase')}
                  {errorsList?.study_phase ? (
                     <Typography className="filter-error-name">{errorsList?.study_phase || ''}</Typography>
                  ) : null}
               </Box>
            </Popover>

            {/* countries */}
            <Popover
               id={Boolean(anchorElCountries) ? 'simple-popover' : undefined}
               open={Boolean(anchorElCountries)}
               anchorEl={anchorElCountries}
               onClose={() => {
                  handleCloseCountries(props?.finalData);
               }}
               anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
               }}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
               }}
               className="more-popover"
               disableBackdropClick
               disableEscapeKeyDown
            >
               <Box className="more-box style-7" py={2}>
                  <Box className="global-search" py={1}>
                     <Box className="clinical-trials-more" display="flex" justifyContent="space-between">
                        <Grid container direction="row" alignItems="center">
                           <Grid item>
                              <TextField
                                 autoComplete="off"
                                 id="outlined-basic"
                                 placeholder="Search countries"
                                 fullWidth
                                 autoFocus
                                 variant="outlined"
                                 InputProps={{
                                    startAdornment: (
                                       <InputAdornment position="start">
                                          <img src={SearchIcon} alt={'img'} />
                                       </InputAdornment>
                                    ),
                                 }}
                                 style={{ width: '231px' }}
                                 value={searchWordListDialogCountries}
                                 onChange={changeListDialogCountries}
                              />
                           </Grid>
                        </Grid>
                        <Box>
                           <IconButton
                              onClick={() => {
                                 handleCloseCountries(props?.finalData);
                              }}
                           >
                              <CloseIcon />
                           </IconButton>
                        </Box>
                     </Box>
                  </Box>
                  <Box className="item-div style-7" px={1}>
                     <Grid container direction="row" spacing={1}>
                        {countriesListDialog?.length > 0 ? (
                           countriesListDialog.map((item: any, i: number) => {
                              return (
                                 <Grid item key={i} xs={4}>
                                    <FormControlLabel
                                       value="end"
                                       control={
                                          <Checkbox
                                             color="primary"
                                             name={item?.name || ''}
                                             checked={
                                                dataApplyFilter?.countries?.findIndex((x: any) => x === item?.name) !==
                                                -1
                                             }
                                             onChange={() => {
                                                changeCountries(item);
                                             }}
                                          />
                                       }
                                       label={item?.name || ''}
                                       labelPlacement="end"
                                    />
                                 </Grid>
                              );
                           })
                        ) : (
                           <NoData />
                        )}
                     </Grid>
                  </Box>
                  {renderBottomButtons('countries')}
                  {errorsList?.countries ? (
                     <Typography className="filter-error-name">{errorsList?.countries || ''}</Typography>
                  ) : null}
               </Box>
            </Popover>

            {/* state */}
            <Popover
               id={Boolean(anchorElState) ? 'simple-popover' : undefined}
               open={Boolean(anchorElState)}
               anchorEl={anchorElState}
               onClose={() => {
                  handleCloseState(props?.finalData);
               }}
               anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
               }}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
               }}
               className="more-popover"
               disableBackdropClick
               disableEscapeKeyDown
            >
               <Box className="more-box style-7" py={2}>
                  <Box className="global-search" py={1}>
                     <Box className="clinical-trials-more" display="flex" justifyContent="space-between">
                        <Grid container direction="row" alignItems="center">
                           <Grid item>
                              <TextField
                                 autoComplete="off"
                                 id="outlined-basic"
                                 placeholder="Search state"
                                 fullWidth
                                 autoFocus
                                 variant="outlined"
                                 InputProps={{
                                    startAdornment: (
                                       <InputAdornment position="start">
                                          <img src={SearchIcon} alt={'img'} />
                                       </InputAdornment>
                                    ),
                                 }}
                                 style={{ width: '231px' }}
                                 value={searchWordListDialogState}
                                 onChange={changeListDialogState}
                              />
                           </Grid>
                        </Grid>
                        <Box>
                           <IconButton
                              onClick={() => {
                                 handleCloseState(props?.finalData);
                              }}
                           >
                              <CloseIcon />
                           </IconButton>
                        </Box>
                     </Box>
                  </Box>
                  <Box className="item-div style-7" px={1}>
                     <Grid container direction="row" spacing={1}>
                        {stateListDialog?.length > 0 ? (
                           stateListDialog.map((item: any, i: number) => {
                              return (
                                 <Grid item key={i} xs={4}>
                                    <FormControlLabel
                                       value="end"
                                       control={
                                          <Checkbox
                                             color="primary"
                                             name={item?.name || ''}
                                             checked={
                                                dataApplyFilter?.state?.findIndex(
                                                   (x: any) => x.state_name === item?.name
                                                ) !== -1
                                             }
                                             onChange={() => {
                                                changeState(item);
                                             }}
                                          />
                                       }
                                       label={item?.name || ''}
                                       labelPlacement="end"
                                    />
                                 </Grid>
                              );
                           })
                        ) : (
                           <NoData />
                        )}
                     </Grid>
                  </Box>
                  {renderBottomButtons('state')}
                  {errorsList?.state ? (
                     <Typography className="filter-error-name">{errorsList?.state || ''}</Typography>
                  ) : null}
               </Box>
            </Popover>
         </Box>
      </Box>
   );
});

export default ClinicFilter;
