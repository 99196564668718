import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_SECONDARY_CANCER_TYPES_SAGA } from 'reduxToolKit/actions';
import { commonSlice, secondaryCancerTypeListSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   secondaryCancerListLoading,
   secondaryCancerListViewRequest,
   secondaryCancerListViewSuccess,
   secondaryCancerListFailed,
} = secondaryCancerTypeListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const secondaryCancerTypeListSaga = function* (cancerTypePayload: any) {
   yield put(secondaryCancerListLoading());
   yield put(secondaryCancerListViewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getSecondaryCancer });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            list: responseData.data.list,
            message: response.data?.message,
         };
         yield put(secondaryCancerListViewSuccess(oncDriveList));
      } else {
         yield put(secondaryCancerListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeSecondaryCancerListRequest = function* () {
   yield takeEvery(GET_SECONDARY_CANCER_TYPES_SAGA, secondaryCancerTypeListSaga);
};
