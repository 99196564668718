import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, ButtonGroup, Grid, Typography } from '@material-ui/core';
import Constant from 'config/Constant';
import { DatePicker } from 'rsuite';
import { convertStartEndDateToUTC, isBeforeYesterday } from 'utils/helpers/dateHelpers';

const PillOthersInterval = (props: any) => {
   const [intervalCount, setIntervalCount] = useState<number>(2);
   const [pillStartDate, setPillStartDate] = useState('');
   const [pillStartDateTime, setPillStartDateTime] = useState<Date>();
   const [errorMessage, setErrorMessage] = useState('');
   const [countErrorMessage, setCountErrorMessage] = useState('');

   const clickIncrement = useCallback(() => {
      const count = intervalCount + 1;
      setIntervalCount(count);
      setCountErrorMessage('');
   }, [intervalCount]);

   const clickDecrement = useCallback(() => {
      if (intervalCount > 2) {
         const count = intervalCount - 1;
         setIntervalCount(count);
         setCountErrorMessage('');
      }
   }, [intervalCount]);

   const changeDate = useCallback((date: Date) => {
      if (date) {
         setPillStartDateTime(date);
         const convertDate = convertStartEndDateToUTC(date);
         setPillStartDate(convertDate);
         setErrorMessage('');
      }
   }, []);

   const setActionText = () => {
      let action = 'Next';
      if (props.type === 'others') {
         action = 'Submit';
      }
      return action;
   };

   const clickNext = useCallback(() => {
      if (!pillStartDate) {
         setErrorMessage('Please select date');
      }
      if (!intervalCount) {
         setCountErrorMessage('Interval days cannot be empty');
      }
      if (intervalCount && pillStartDate) {
         const othersIntervalObj = { intervalCount, pillStartDate };
         props.pillRemainderOthersIntervalDays(othersIntervalObj);
      }
   }, [intervalCount, pillStartDate]);

   useEffect(() => {
      if (props.reminderDays > 0) {
         if (props.reminderDays === 28 || props.reminderDays === 1 || props.reminderDays === 2) {
            setIntervalCount(2);
         } else if (props.reminderDays > 2 && props.reminderDays <= 27) {
            setIntervalCount(props.reminderDays);
         }
      } else {
         setIntervalCount(2);
      }
   }, [props.reminderDays]);

   useEffect(() => {
      setPillStartDateTime(props?.pillStartDateTime);
      setPillStartDate(props?.pillStartDate);
      setIntervalCount(props?.intervalCount);
   }, []);

   return (
      <Box>
         <Typography align="center" className="pill-add-text">
            Set every days interval
         </Typography>
         <Box display="flex" justifyContent="center" mt={2}>
            <ButtonGroup variant="contained" className="btn-grp-schedule">
               <Button
                  style={{ textIndent: '8px' }}
                  className={intervalCount === 2 ? 'btn-schedule-disable' : 'btn-schedule'}
                  onClick={clickDecrement}
                  disabled={intervalCount === 2 ? true : false}
               >
                  -
               </Button>
               <Box className="btn-schedule-duration">
                  <Button className="btn-schedule-time" disabled>
                     {intervalCount}
                  </Button>
               </Box>
               <Button className={'btn-schedule'} onClick={clickIncrement}>
                  +
               </Button>
            </ButtonGroup>
         </Box>
         {countErrorMessage && <Box className="errorMsg">{countErrorMessage}</Box>}
         <Box width="85%" ml={4} mt={2}>
            <DatePicker
               size="lg"
               placeholder="Select Date"
               placement="topEnd"
               style={{ width: 280, display: 'block', marginBottom: 10 }}
               onChange={changeDate}
               value={pillStartDateTime}
               format={Constant.DATE_FORMAT}
               disabledDate={(date) => isBeforeYesterday(date)}
            />
         </Box>
         {errorMessage && <Box className="errorMsg ml-5">{errorMessage}</Box>}
         <Box mb={4} mx="auto" mt={5}>
            <Grid container direction="row" spacing={3} justify="center">
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="pill-add-back-button"
                     fullWidth
                     onClick={() => {
                        if (props?.type === 'others') {
                           if (props?.othersType === 'every_number_of_days') {
                              props?.pillRemainderOthersIntervalDaysBack(
                                 intervalCount,
                                 pillStartDate,
                                 props?.type,
                                 'first_dose_time'
                              );
                              props?.pillRemainderStartDateTime(pillStartDateTime);
                           }
                        }
                     }}
                  >
                     Back
                  </Button>
               </Grid>
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="telemedicine__modal--btn"
                     fullWidth
                     onClick={clickNext}
                  >
                     {setActionText()}
                  </Button>
               </Grid>
            </Grid>
         </Box>
      </Box>
   );
};

export { PillOthersInterval };
