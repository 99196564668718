import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_ECOG, GET_MASTER_ECOG } from 'reduxToolKit/actions';
import { commonSlice, ecogSlice } from 'reduxToolKit/reducers';
import { myProfileReducerTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   ecogAddRequest,
   ecogAddSuccess,
   ecogAddFailed,
   getMasterEcogRequest,
   getMasterEcogSuccess,
   getMasterEcogFailed,
} = ecogSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const ecogAddSaga = function* (ecogAddPayload: any) {
   yield put(ecogAddRequest(ecogAddPayload.payload));
   try {
      const checkUser = false;
      const data = ecogAddPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...API.ecogAdd });
      if (response.status === 200) {
         const responseData = response.data;
         const ecogAddData = {
            message: responseData?.message || '',
         };
         yield put(ecogAddSuccess(ecogAddData));
      } else {
         yield put(ecogAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getMasterEcogSaga = function* (getMasterEcogPayload: PayloadAction<myProfileReducerTypes.IEcogReducer>) {
   yield put(getMasterEcogRequest(getMasterEcogPayload.payload));
   try {
      const params = getMasterEcogPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         params,
         ...API.getMasterEcog,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const getMasterEcogList = {
            list: responseData?.data?.data || [],
            total_records: responseData?.data?.total || 0,
            message: response?.data?.message || '',
         };
         yield put(getMasterEcogSuccess(getMasterEcogList));
      } else {
         yield put(getMasterEcogFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeEcog = function* () {
   yield takeEvery(ADD_ECOG, ecogAddSaga);
   yield takeEvery(GET_MASTER_ECOG, getMasterEcogSaga);
};
