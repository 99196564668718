export const getCountryFromGeoLocation = (resultsCountry: any[]) => {
   const country = {
      longName: null,
      shortName: null,
   };
   if (resultsCountry?.length > 0) {
      resultsCountry.forEach((result) => {
         const geoTypeChecksCountry = result.types.includes('country');
         if (geoTypeChecksCountry) {
            const addressComponents = result.address_components ? result.address_components : [];
            const countryArray = addressComponents.filter(
               (addressComponent: any) => addressComponent.types && addressComponent.types.includes('country')
            );
            if (countryArray.length) {
               country.longName = countryArray[0].long_name;
               country.shortName = countryArray[0].short_name;
            }
         }
      });
   }
   return country;
};
export const getCountryFromAddress = (resultsCountry: any[]) => {
   const country = {
      longName: null,
      shortName: null,
   };
   if (resultsCountry?.length > 0) {
      resultsCountry.forEach((result) => {
         let geoTypeChecksCountry = result.types.includes('point_of_interest');
         geoTypeChecksCountry = geoTypeChecksCountry ? geoTypeChecksCountry : result.types.includes('locality');
         geoTypeChecksCountry = geoTypeChecksCountry ? geoTypeChecksCountry : result.types.includes('political');

         if (geoTypeChecksCountry) {
            const addressComponents = result.address_components ? result.address_components : [];
            const countryArray = addressComponents.filter(
               (addressComponent: any) => addressComponent.types && addressComponent.types.includes('country')
            );
            if (countryArray.length) {
               country.longName = countryArray[0].long_name;
               country.shortName = countryArray[0].short_name;
            }
         }
      });
   }
   return country;
};
export const getStateFromGeoLocation = (resultsState: any[]) => {
   const state = {
      longName: null,
      shortName: null,
   };
   if (resultsState?.length > 0) {
      resultsState.forEach((result) => {
         const geoTypeChecksState = result.types.includes('administrative_area_level_1');
         if (geoTypeChecksState) {
            const addressComponents = result.address_components ? result.address_components : [];
            const countryArray = addressComponents.filter(
               (addressComponent: any) =>
                  addressComponent.types && addressComponent.types.includes('administrative_area_level_1')
            );
            if (countryArray.length) {
               state.longName = countryArray[0].long_name;
               state.shortName = countryArray[0].short_name;
            }
         }
      });
   }
   return state;
};
export const getStateFromAddress = (resultsState: any[]) => {
   const state = {
      longName: null,
      shortName: null,
   };
   if (resultsState?.length > 0) {
      resultsState.forEach((result) => {
         let geoTypeChecksState = result.types.includes('point_of_interest');
         geoTypeChecksState = geoTypeChecksState ? geoTypeChecksState : result.types.includes('sublocality');
         geoTypeChecksState = geoTypeChecksState ? geoTypeChecksState : result.types.includes('sublocality_level_2');
         geoTypeChecksState = geoTypeChecksState ? geoTypeChecksState : result.types.includes('political');
         if (geoTypeChecksState) {
            const addressComponents = result.address_components ? result.address_components : [];
            const countryArray = addressComponents.filter(
               (addressComponent: any) =>
                  addressComponent.types && addressComponent.types.includes('administrative_area_level_1')
            );
            if (countryArray.length) {
               state.longName = countryArray[0].long_name;
               state.shortName = countryArray[0].short_name;
            }
         }
      });
   }
   return state;
};
