import { createSlice } from '@reduxjs/toolkit';
import { oncDriveReducerTypes, oncDriveSagaTypes } from 'types';

const initialState: oncDriveReducerTypes.IOncDriveShareReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};

const initialStateUnShared: oncDriveReducerTypes.IOncDriveShareReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};

export const oncDriveShareSlice = createSlice({
   name: 'oncDriveShare',
   initialState: initialState,
   reducers: {
      oncDriveShareRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      shareOncDriveSuccess: (state, action: oncDriveSagaTypes.IShareToDriveSuccess) => {
         return {
            ...state,
            isLoading: false,
            ...action.payload,
         };
      },
      shareOncDriveFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      oncDriveShareReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

export const oncDriveUnShareSlice = createSlice({
   name: 'oncDriveUnShare',
   initialState: initialStateUnShared,
   reducers: {
      oncDriveUnShareRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      oncDriveUnShareSuccess: (state, action: oncDriveSagaTypes.IShareToDriveSuccess) => {
         return {
            ...state,
            isLoading: false,
            ...action.payload,
         };
      },
      oncDriveUnShareFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      oncDriveUnShareReset: () => {
         return {
            ...initialStateUnShared,
         };
      },
   },
});
