import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_PHYSICIAN_ADMIN } from 'reduxToolKit/actions';
import { commonSlice, getPhysicianAdminSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getPhysicianAdminLoading,
   getPhysicianAdminRequest,
   getPhysicianAdminSuccess,
   getPhysicianAdminFailed,
} = getPhysicianAdminSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const adminClinicSaga = function* (cancerTypePayload: any) {
   yield put(getPhysicianAdminLoading());
   yield put(getPhysicianAdminRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.clinicPhysicianAdmin });
      if (response.status === 200) {
         const responseData = response.data;
         const clinicPhySicianList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            message: response.data?.message,
         };
         yield put(getPhysicianAdminSuccess(clinicPhySicianList));
      } else {
         yield put(getPhysicianAdminFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePhysicianClinic = function* () {
   yield takeEvery(GET_PHYSICIAN_ADMIN, adminClinicSaga);
};
