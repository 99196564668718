import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { modalCertificateReducerTypes } from 'types';

const initialState: modalCertificateReducerTypes.IModalUpdateCertificateReducer = {
   error: '',
   message: '',
   isLoading: false,
};

export const modalUpdateCertificateSlice = createSlice({
   name: 'modalUpdateCertificate',
   initialState: initialState,
   reducers: {
      modalCertificateUpdateRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      modalCertificateUpdateSuccess: (
         state,
         action: PayloadAction<modalCertificateReducerTypes.IModalUpdateCertificateSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      modalCertificateUpdateFailed: (
         state,
         action: PayloadAction<modalCertificateReducerTypes.IModalUpdateCertificateFailedPayload>
      ) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      certificateUpdateReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
