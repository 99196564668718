import { createSlice } from '@reduxjs/toolkit';
import { myProfileReducerTypes } from 'types';

const initialState: myProfileReducerTypes.IMedicalHistoryReducer = {
   addMedicalHistory: {
      isLoading: false,
      error: '',
      message: '',
   },
   getMasterMedicalHistory: {
      isLoading: false,
      message: '',
      error: '',
      list: [],
   },
   getMedicalHistoryList: {
      isLoading: false,
      message: '',
      error: '',
      list: [],
   },
};

export const medicalHistorySlice = createSlice({
   name: 'medicalHistory',
   initialState: initialState,
   reducers: {
      // add race
      medicalHistoryAddRequest: (state, action) => {
         return {
            ...state,
            addMedicalHistory: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      medicalHistoryAddSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            addMedicalHistory: {
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      medicalHistoryAddFailed: (state, action: any) => {
         return {
            ...state,
            addMedicalHistory: {
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      medicalHistoryAddReset: (state) => {
         return {
            ...state,
            addMedicalHistory: initialState.addMedicalHistory,
         };
      },

      // get master medical history
      getMasterMedicalHistoryRequest: (state, action) => {
         return {
            ...state,
            getMasterMedicalHistory: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getMasterMedicalHistorySuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            getMasterMedicalHistory: {
               list: payload.list,
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      getMasterMedicalHistoryFailed: (state, action: any) => {
         return {
            ...state,
            getMasterMedicalHistory: {
               list: state.getMasterMedicalHistory.list,
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      getMasterMedicalHistoryReset: (state) => {
         return {
            ...state,
            getMasterMedicalHistory: initialState.getMasterMedicalHistory,
         };
      },
      // get medical history list
      getMedicalHistoryListRequest: (state, action) => {
         return {
            ...state,
            getMedicalHistoryList: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getMedicalHistoryListSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            getMedicalHistoryList: {
               list: payload.list,
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      getMedicalHistoryListFailed: (state, action: any) => {
         return {
            ...state,
            getMedicalHistoryList: {
               list: state.getMedicalHistoryList.list,
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      getMedicalHistoryListReset: (state) => {
         return {
            ...state,
            getMedicalHistoryList: initialState.getMedicalHistoryList,
         };
      },
   },
});
