import { createAction } from '@reduxjs/toolkit';
import { globalSearchReducerTypes } from 'types';

export const GLOBAL_SEARCH_LIST_SAGA = 'GLOBAL_SEARCH_LIST_SAGA';
export const globalSearchListSagaAction = createAction<globalSearchReducerTypes.IGlobalSearchSagaProps>(
   GLOBAL_SEARCH_LIST_SAGA
);

export const RECENT_SEARCH_LIST_SAGA = 'RECENT_SEARCH_LIST_SAGA';
export const recentSearchListSagaAction = createAction(RECENT_SEARCH_LIST_SAGA);

export const RECENT_SEARCH_ADD_SAGA = 'RECENT_SEARCH_ADD_SAGA';
export const recentSearchAddSagaAction = createAction<globalSearchReducerTypes.IAddRecentSearchRequestParams>(
   RECENT_SEARCH_ADD_SAGA
);
