import {
   ADDRESS_SECTION,
   CANCER_STATUS_SECTION,
   CANCER_TYPE_SECTION,
   CLINICAL_TRAILS_ADDRESS_SECTION,
   CLINICAL_TRAILS_CANCER_TYPE_SECTION,
   CLINICAL_TRAILS_DISPLAY_NAME_SECTION,
   CLINICAL_TRIALS_CANCER_STATUS_SECTION,
   CLINICAL_TRIALS_DIAGNOSIS_SECTION,
   CLINICAL_TRIALS_DOB_SECTION,
   CLINICAL_TRIALS_HOME_SECTION,
   CLINICAL_TRIALS_LATINO_SECTION,
   CLINICAL_TRIALS_RACE_SECTION,
   CLINICAL_TRIALS_SERVICE_OFFERED_SECTION,
   CLINICAL_TRIALS_STAGING_SECTION,
   DIAGNOSIS_SECTION,
   DISPLAY_NAME_SECTION,
   DOB_SECTION,
   HOME_SECTION,
   HOME_SECTION_EXISTING_USER,
   INITIAL_ADDRESS_SECTION,
   INITIAL_CANCER_TYPE_SECTION,
   INITIAL_DISPLAY_NAME_SECTION,
   LATINO_SECTION,
   ORG_USER_NAME,
   ORG_USER_POPULATION_SERVED,
   ORG_USER_SERVICES_OFFERED,
   RACE_SECTION,
   SERVICE_OFFERED_SECTION,
   STAGING_SECTION,
} from 'utils/constants/auth';

export const calculateDuration = (type: string, value: number) => {
   switch (type) {
      case 'y':
         return value === 1 ? `${value} Year` : `${value} Years`;
      case 'm':
         return value === 1 ? `${value} Month` : `${value} Months`;
      case 'w':
         return value === 1 ? `${value} Week` : `${value} Weeks`;
      case 'd':
         return value === 1 ? `${value} Day` : `${value} Days`;
      case 'ml':
         return value === 1 ? `${value} Milliliter` : `${value} Milliliters`;
      case 'l':
         return value === 1 ? `${value} Liter` : `${value} Liters`;
      case 'mg':
         return value === 1 ? `${value} Milligram` : `${value} Milligrams`;
      case 'g':
         return value === 1 ? `${value} Gram` : `${value} Grams`;
      default:
         return `${value}`;
   }
};

// initial clinical trials ask for first 4 fill profile mandatory items
/**
 * The function checks if certain user data is missing and returns the corresponding section for
 * filling out the profile for clinical trials.
 * @param {any} userData - an object containing user data, including display name, cancer type,
 * address, country, patient state, date of birth, and gender.
 * @returns The function `clinicalTrailsFillProfile` takes in a `userData` object as input and returns
 * a string value based on the conditions met in the if-else statements. The value returned depends on
 * which section of the clinical trials profile is incomplete. If the `display_name` property is
 * missing, it returns `CLINICAL_TRAILS_DISPLAY_NAME_SECTION`. If the `cancer_type`
 */
export const clinicalTrailsFillProfile = (userData: any): any => {
   if (!userData?.display_name) {
      return CLINICAL_TRAILS_DISPLAY_NAME_SECTION;
   } else if (!userData?.cancer_patient?.cancer_type?.id) {
      return CLINICAL_TRAILS_CANCER_TYPE_SECTION;
   } else if (!userData?.address || userData?.country === null || userData?.patient_state === null) {
      return CLINICAL_TRAILS_ADDRESS_SECTION;
   } else if (!userData?.date_of_birth || !userData?.gender) {
      return CLINICAL_TRIALS_DOB_SECTION;
   }
};

// initial clinical trials ask for first 4 fill profile mandatory available items for showing dots
/**
 * The function takes in user data and returns an array of sections that are incomplete for filling out
 * a clinical trial profile.
 * @param {any} userData - an object containing user data, including display name, cancer type,
 * address, date of birth, and gender.
 * @returns an array of numbers representing the sections of a clinical trial profile that are
 * incomplete based on the user data passed as an argument.
 */
export const clinicalTrailsFillProfileArray = (userData: any): any => {
   const clinicalTrailsArray: number[] = [];
   if (!userData?.display_name) {
      clinicalTrailsArray.push(CLINICAL_TRAILS_DISPLAY_NAME_SECTION);
   }
   if (!userData?.cancer_patient?.cancer_type?.id) {
      clinicalTrailsArray.push(CLINICAL_TRAILS_CANCER_TYPE_SECTION);
   }
   if (!userData?.address || userData?.country === null || userData?.patient_state === null) {
      clinicalTrailsArray.push(CLINICAL_TRAILS_ADDRESS_SECTION);
   }
   if (!userData?.date_of_birth || !userData?.gender) {
      clinicalTrailsArray.push(CLINICAL_TRIALS_DOB_SECTION);
   }
   return clinicalTrailsArray;
};

// clinical trails interested click remaining fill profile item get function
/**
 * This function returns a section of a clinical trials profile that is incomplete based on the user's
 * data and whether they are accessing it from a clinical trials list.
 * @param {any} userData - an object containing user data, such as initial diagnosis date, staging,
 * cancer status, race, and Hispanic/Latino status.
 * @param {boolean} isFromClinicalTrialsList - A boolean value indicating whether the user is coming
 * from the clinical trials list page or not.
 * @returns a section identifier based on the user data and a boolean flag indicating whether the
 * function is called from the clinical trials list. The section identifier returned depends on the
 * user data provided and the order of the conditions in the function. If the user data is missing any
 * of the required fields, the function returns the corresponding section identifier. If the function
 * is called from the clinical trials list, it returns
 */
export const clinicalTrailsRemainingFillProfile = (
   userData: any,
   isFromClinicalTrialsList: boolean,
   isServiceOfferedValid: boolean
): any => {
   if (isFromClinicalTrialsList) {
      return CLINICAL_TRIALS_HOME_SECTION;
   } else if (!userData?.initial_diagnosis_date) {
      return CLINICAL_TRIALS_DIAGNOSIS_SECTION;
   } else if (!userData?.staging) {
      return CLINICAL_TRIALS_STAGING_SECTION;
   } else if (!userData?.cancer_status) {
      return CLINICAL_TRIALS_CANCER_STATUS_SECTION;
   } else if (!userData?.race) {
      return CLINICAL_TRIALS_RACE_SECTION;
   } else if (!userData?.hispanic_or_latino) {
      return CLINICAL_TRIALS_LATINO_SECTION;
   } else if (!isServiceOfferedValid) {
      return CLINICAL_TRIALS_SERVICE_OFFERED_SECTION;
   }
};

// clinical trails interested click remaining fill profile available item get function to showing dots
/**
 * This function takes in user data and a boolean value and returns an array of numbers representing
 * the remaining sections of a clinical trial profile that the user needs to fill out.
 * @param {any} userData - an object containing user data such as initial diagnosis date, staging,
 * cancer status, race, and Hispanic/Latino status.
 * @param {boolean} isFromClinicalTrialsList - A boolean value indicating whether the user is coming
 * from the clinical trials list page or not.
 * @returns an array of numbers representing the sections that are remaining to be filled in the user's
 * clinical trial profile. The sections are determined based on the user's data and whether the user is
 * coming from the clinical trials list.
 */
export const clinicalTrailsRemainingFillProfileArray = (
   userData: any,
   isFromClinicalTrialsList: boolean,
   isServiceOfferedValid: boolean
): any => {
   const clinicalTrailsRemainingArray: number[] = [];
   if (isFromClinicalTrialsList) {
      clinicalTrailsRemainingArray.push(CLINICAL_TRIALS_HOME_SECTION);
   }
   if (!userData?.initial_diagnosis_date) {
      clinicalTrailsRemainingArray.push(CLINICAL_TRIALS_DIAGNOSIS_SECTION);
   }
   if (!userData?.staging) {
      clinicalTrailsRemainingArray.push(CLINICAL_TRIALS_STAGING_SECTION);
   }
   if (!userData?.cancer_status) {
      clinicalTrailsRemainingArray.push(CLINICAL_TRIALS_CANCER_STATUS_SECTION);
   }
   if (!userData?.race) {
      clinicalTrailsRemainingArray.push(CLINICAL_TRIALS_RACE_SECTION);
   }
   if (!userData?.hispanic_or_latino) {
      clinicalTrailsRemainingArray.push(CLINICAL_TRIALS_LATINO_SECTION);
   }
   if (!isServiceOfferedValid) {
      clinicalTrailsRemainingArray.push(CLINICAL_TRIALS_SERVICE_OFFERED_SECTION);
   }
   return clinicalTrailsRemainingArray;
};

// feed page existing user fill profile mandatory fields get function
/**
 * The function checks if certain user data is missing and returns an initial section based on what is
 * missing.
 * @param {any} userData - The userData parameter is an object that contains information about a user's
 * profile. It may include properties such as display_name, cancer_patient, address, country, and
 * patient_state.
 * @returns The function `fillProfileInitial` returns an object that represents the initial section to
 * be displayed based on the user data provided. The specific object returned depends on the user data
 * provided and the logic of the function.
 */
export const fillProfileInitial = (userData: any): any => {
   if (!userData?.display_name) {
      return INITIAL_DISPLAY_NAME_SECTION;
   } else if (!userData?.cancer_patient?.cancer_type?.id) {
      return INITIAL_CANCER_TYPE_SECTION;
   } else if (!userData?.address || userData?.country === null || userData?.patient_state === null) {
      return INITIAL_ADDRESS_SECTION;
   }
};

// feed page existing user fill profile mandatory available fields get function showing dots
/**
 * The function takes user data and returns an array of sections that need to be filled in their
 * profile.
 * @param {any} userData - an object containing user data, including display name, cancer patient
 * information, and address information.
 * @returns The function `fillProfileInitialArray` returns an array of numbers representing the
 * sections of a user's profile that are incomplete and need to be filled out. The specific sections
 * that are checked for completeness are based on the properties of the `userData` object passed as an
 * argument to the function.
 */
export const fillProfileInitialArray = (userData: any): any => {
   const fillProfileInitialList: number[] = [];
   if (!userData?.display_name) {
      fillProfileInitialList.push(INITIAL_DISPLAY_NAME_SECTION);
   }
   if (!userData?.cancer_patient?.cancer_type?.id) {
      fillProfileInitialList.push(INITIAL_CANCER_TYPE_SECTION);
   }
   if (!userData?.address || userData?.country === null || userData?.patient_state === null) {
      fillProfileInitialList.push(INITIAL_ADDRESS_SECTION);
   }
   return fillProfileInitialList;
};

// feed page first user initial time get all fill profile function
/**
 * The function takes in user data and a boolean value indicating if it's the user's first time filling
 * out their profile, and returns the appropriate section of the profile to be filled out next based on
 * the user's current progress.
 * @param {any} userData - an object containing user data
 * @param {boolean} isFirst - A boolean value indicating whether the user is a new user or an existing
 * user.
 * @returns The function `fillProfile` returns a section of a user's profile based on the provided
 * `userData` and `isFirst` boolean flag. The section returned depends on which data is missing from
 * the user's profile.
 */

export const fillProfile = (userData: any, isFirst: boolean, isServiceOfferedValid: boolean): any => {
   if (isFirst) {
      return HOME_SECTION;
   } else if (!isFirst) {
      return HOME_SECTION_EXISTING_USER;
   } else if (!userData?.display_name) {
      return DISPLAY_NAME_SECTION;
   } else if (!userData?.cancer_patient?.cancer_type?.id) {
      return CANCER_TYPE_SECTION;
   } else if (!userData?.initial_diagnosis_date) {
      return DIAGNOSIS_SECTION;
   } else if (!userData?.staging) {
      return STAGING_SECTION;
   } else if (!userData?.cancer_status) {
      return CANCER_STATUS_SECTION;
   } else if (!userData?.address || userData?.country === null || userData?.patient_state === null) {
      return ADDRESS_SECTION;
   } else if (!userData?.date_of_birth || !userData?.gender) {
      return DOB_SECTION;
   } else if (!isServiceOfferedValid) {
      return SERVICE_OFFERED_SECTION;
   } else if (!userData?.race) {
      return RACE_SECTION;
   } else if (!userData?.hispanic_or_latino) {
      return LATINO_SECTION;
   }
};

/**
 * The function takes in user data and a boolean value, and returns an array of numbers representing
 * the sections of a user's profile that need to be filled out.
 * @param {any} userData - an object containing user data
 * @param {boolean} isFirst - A boolean value indicating whether the user is a new user or an existing
 * user.
 * @returns The function `fillProfileArray` returns an array of numbers representing the sections of a
 * user's profile that are incomplete or missing.
 */
export const fillProfileArray = (userData: any, isFirst: boolean, isServiceOfferedValid: boolean): any => {
   const fillProfileList: number[] = [];
   if (isFirst) {
      fillProfileList.push(HOME_SECTION);
   }
   if (!isFirst) {
      fillProfileList.push(HOME_SECTION_EXISTING_USER);
   }
   if (!userData?.display_name) {
      fillProfileList.push(DISPLAY_NAME_SECTION);
   }
   if (!userData?.cancer_patient?.cancer_type?.id) {
      fillProfileList.push(CANCER_TYPE_SECTION);
   }
   if (!userData?.initial_diagnosis_date) {
      fillProfileList.push(DIAGNOSIS_SECTION);
   }
   if (!userData?.staging) {
      fillProfileList.push(STAGING_SECTION);
   }
   if (!userData?.cancer_status) {
      fillProfileList.push(CANCER_STATUS_SECTION);
   }
   if (!userData?.address || userData?.country === null || userData?.patient_state === null) {
      fillProfileList.push(ADDRESS_SECTION);
   }
   if (!userData?.date_of_birth || !userData?.gender) {
      fillProfileList.push(DOB_SECTION);
   }
   if (!isServiceOfferedValid) {
      fillProfileList.push(SERVICE_OFFERED_SECTION);
   }
   if (!userData?.race) {
      fillProfileList.push(RACE_SECTION);
   }
   if (!userData?.hispanic_or_latino) {
      fillProfileList.push(LATINO_SECTION);
   }

   return fillProfileList;
};

export const fillProfileOrgInitial = (
   userData: any,
   isServiceOfferedValid: boolean,
   isPopulationServed: boolean
): any => {
   if (!userData?.organization?.name) {
      return ORG_USER_NAME;
   } else if (!isServiceOfferedValid) {
      return ORG_USER_SERVICES_OFFERED;
   } else if (!isPopulationServed) {
      return ORG_USER_POPULATION_SERVED;
   }
};

export const fillProfileOrgInitialArray = (
   userData: any,
   isServiceOfferedValid: boolean,
   isPopulationServed: boolean
): any => {
   const fillProfileOrgInitialList: number[] = [];
   if (!userData?.organization?.name) {
      fillProfileOrgInitialList.push(ORG_USER_NAME);
   }
   if (!isServiceOfferedValid) {
      fillProfileOrgInitialList.push(ORG_USER_SERVICES_OFFERED);
   }
   if (!isPopulationServed) {
      fillProfileOrgInitialList.push(ORG_USER_POPULATION_SERVED);
   }
   return fillProfileOrgInitialList;
};
