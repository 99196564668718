import { createSlice } from '@reduxjs/toolkit';
import { myProfileReducerTypes } from 'types';

const initialState: myProfileReducerTypes.IPopulationServedReducer = {
   addPopulationServed: {
      isLoading: false,
      error: '',
      message: '',
   },
   getUserPopulationServed: {
      isLoading: false,
      message: '',
      error: '',
      data: null,
   },
   testAll: {
      isLoading: false,
      message: '',
      error: '',
      data: null,
   },
};

export const populationServedSlice = createSlice({
   name: 'populationServed',
   initialState: initialState,
   reducers: {
      // add population served
      populationServedAddRequest: (state, action) => {
         return {
            ...state,
            addPopulationServed: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      populationServedAddSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            addPopulationServed: {
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      populationServedAddFailed: (state, action: any) => {
         return {
            ...state,
            addPopulationServed: {
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      populationServedAddReset: (state) => {
         return {
            ...state,
            addPopulationServed: initialState.addPopulationServed,
         };
      },

      // get user service offered
      getUserPopulationServedRequest: (state, action) => {
         return {
            ...state,
            getUserPopulationServed: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getUserPopulationServedSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            getUserPopulationServed: {
               data: payload.data,
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      getUserPopulationServedFailed: (state, action: any) => {
         return {
            ...state,
            getUserPopulationServed: {
               data: state.getUserPopulationServed.data,
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      getUserPopulationServedMessageReset: (state) => {
         return {
            ...state,
            getUserPopulationServed: {
               ...state.getUserPopulationServed,
               message: '',
               isLoading: false,
            },
         };
      },
      getUserPopulationServedReset: (state) => {
         return {
            ...state,
            getUserPopulationServed: initialState.getUserPopulationServed,
         };
      },
   },
});
