// import Typography from '@material-ui/core/Typography';
import React from 'react';

import { Typography } from '@material-ui/core';
import EmptyUpcomingMeeting from 'assets/alert/upcomingMeetings.svg';
import { messages } from 'utils/constants/messages';

const NoData = (props: any) => {
   return (
      <div className="p-4 pt-0 pb-0 ">
         <Typography align="center">
            {props.isFromUpcomingAppointment && <img src={EmptyUpcomingMeeting} alt={'img'} />}
         </Typography>
         <div className="no-data">
            <Typography align={'center'} className="no-data-text">
               {props.errorMessage ? props.errorMessage : messages.common.emptyState.noData}
            </Typography>
         </div>
      </div>
   );
};

export default NoData;
