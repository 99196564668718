import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { SYMPTOM_LIST } from 'reduxToolKit/actions';
import { commonSlice, symptomListSlice } from 'reduxToolKit/reducers';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { symptomListLoading, symptomListRequest, symptomListSuccess, symptomListFailed } = symptomListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

let searchPhysician: CancelTokenSource | null = null;

const symptomListSaga = function* (cancerTypePayload: any) {
   yield put(symptomListLoading());
   yield delay(500);
   yield put(symptomListRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;
      if (searchPhysician) {
         searchPhysician.cancel();
      }
      const CancelToken = axios.CancelToken;
      searchPhysician = CancelToken.source();
      const cancelToken = searchPhysician.token;

      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, cancelToken, ...API.symptomList });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            message: response.data?.message || messages.success,
         };
         yield put(symptomListSuccess(oncDriveList));
      } else {
         yield put(symptomListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeSymptomList = function* () {
   yield takeLatest(SYMPTOM_LIST, symptomListSaga);
};
