import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_LOCUM_MONTH_WISE_SERVICE_LIST_SAGA } from 'reduxToolKit/actions';
import { commonSlice, locumMonthWiseServiceListSlice } from 'reduxToolKit/reducers';
import { locumSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   locumMonthWiseServiceListRequest,
   locumMonthWiseServiceListSuccess,
   locumMonthWiseServiceListFailed,
} = locumMonthWiseServiceListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const locumMonthWiseServiceListSaga = function* (
   locumMonthWiseServiceList: PayloadAction<locumSagaTypes.ILocumMonthWiseServiceListSagaParams>
) {
   yield put(locumMonthWiseServiceListRequest(locumMonthWiseServiceList.payload));
   try {
      const url = API.getLocumMonthWiseServiceList;
      const params = locumMonthWiseServiceList.payload;
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ checkUser, params, ...url });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(locumMonthWiseServiceListSuccess(resObj));
      } else {
         yield put(locumMonthWiseServiceListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeLocumMonthWiseServiceListRequest = function* () {
   yield takeEvery(GET_LOCUM_MONTH_WISE_SERVICE_LIST_SAGA, locumMonthWiseServiceListSaga);
};
