import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { hippaComplianceReducerTypes, storeTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: hippaComplianceReducerTypes.IHippaComplianceReducer = {
   isLoading: false,
   addHippaDocument: {
      message: '',
      data: null,
      error: '',
   },
   getHippaDocument: {
      message: '',
      data: null,
      error: '',
   },
};

export const hippaComplianceSlice = createSlice({
   name: 'getHippaCompliance',
   initialState: initialState,
   reducers: {
      getHippaComplianceRequest: (state, action: PayloadAction<hippaComplianceReducerTypes.IGetHippaCompliance>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getHippaComplianceSuccess: (
         state,
         action: PayloadAction<hippaComplianceReducerTypes.IHippaComplianceSuccess>
      ) => {
         return {
            ...state,
            isLoading: false,
            getHippaDocument: {
               data: action.payload?.data || null,
               message: action.payload?.message || messages.errorMessage,
               error: '',
            },
         };
      },
      getHippaComplianceFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            getHippaDocument: {
               error: action.payload?.error,
               data: null,
               message: '',
            },
         };
      },

      addHippaComplianceRequest: (state, action: PayloadAction<hippaComplianceReducerTypes.IAddHippaCompliance>) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      addHippaComplianceSuccess: (
         state,
         action: PayloadAction<hippaComplianceReducerTypes.IHippaComplianceSuccess>
      ) => {
         return {
            ...state,
            isLoading: false,
            addHippaDocument: {
               message: action.payload?.message,
               data: action.payload?.data || null,
               error: '',
            },
         };
      },
      addHippaComplianceFailed: (state, action: PayloadAction<hippaComplianceReducerTypes.IHippaComplianceFailed>) => {
         return {
            ...state,
            isLoading: false,
            addHippaDocument: {
               error: action.payload?.error,
               message: '',
               data: null,
            },
         };
      },

      hippaComplianceReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
