import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MODAL_DESCRIPTION_UPDATE_SAGA } from 'reduxToolKit/actions';
import { commonSlice, modalDescriptionUpdateSlice } from 'reduxToolKit/reducers';
import { aboutMeSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';
import * as UserType from 'utils/helpers/userHelpers/userTypeHelpers';

const {
   descriptionUpdateRequest,
   descriptionUpdateSuccess,
   descriptionUpdateFailed,
} = modalDescriptionUpdateSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

//AboutMe - patientDescriptionUpdate, physicianDescriptionUpdate, nurseDescriptionUpdate Saga
const modalDescriptionUpdateSaga = function* (
   descriptionUpdatePayload: PayloadAction<aboutMeSagaTypes.IModalDescriptionUpdateSagaPayload>
) {
   yield put(descriptionUpdateRequest(descriptionUpdatePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;

      const data = {
         primary_disease_id: descriptionUpdatePayload.payload.primary_disease_id,
         cancer_id: descriptionUpdatePayload.payload.cancer_id,
         cancer_type_id: descriptionUpdatePayload.payload.cancer_type_id,
         description: descriptionUpdatePayload.payload.description,
      };
      let url: any = null;
      if (UserType.isPhysician(descriptionUpdatePayload.payload.userData)) {
         url = { checkUser, goUrl, data, ...API.physicianDescriptionUpdate };
      } else if (UserType.isNurse(descriptionUpdatePayload.payload.userData)) {
         url = { checkUser, goUrl, data, ...API.nurseDescriptionUpdate };
      } else {
         url = { checkUser, goUrl, data, ...API.patientDescriptionUpdate };
      }
      const response: AxiosResponse = yield apiCall(url);
      if (response.status === 200) {
         const responseData = response.data;
         const descriptionUpdateList = {
            message: responseData?.message || '',
         };
         yield put(descriptionUpdateSuccess(descriptionUpdateList));
      } else {
         yield put(descriptionUpdateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeDescriptionUpdateRequest = function* () {
   yield takeEvery(MODAL_DESCRIPTION_UPDATE_SAGA, modalDescriptionUpdateSaga);
};
