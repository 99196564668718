import { createSlice } from '@reduxjs/toolkit';
import { paypalAndZelleReducerTypes } from 'types';

const initialState: paypalAndZelleReducerTypes.IPaypalAndZelleUpdateReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const paypalAndZelleUpdateSlice = createSlice({
   name: 'paypalAndZelleUpdate',
   initialState: initialState,
   reducers: {
      paypalAndZelleUpdateRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      paypalAndZelleUpdateSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      paypalAndZelleUpdateFailed: (state, action) => {
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: action.payload,
         };
      },
      paypalAndZelleUpdateReset: () => {
         return initialState;
      },
   },
});
export { paypalAndZelleUpdateSlice };
