import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   ADD_REPORT_POST,
   ADD_REPORT_POST_COMMENT,
   ADD_REPORT_POST_COMMENT_REPLY,
   ADD_REPORT_USER_POST,
   GET_REPORT_REASON,
   GET_REPORT_USER_REASON,
} from 'reduxToolKit/actions';
import { commonSlice, reportPostSlice } from 'reduxToolKit/reducers';
import { reportPostReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getReportReasonRequest,
   getReportReasonSuccess,
   getReportReasonFailed,
   addReportPostRequest,
   addReportPostSuccess,
   addReportPostFailed,
   addReportUserPostRequest,
   addReportUserPostSuccess,
   addReportUserPostFailed,
   addReportPostCommentRequest,
   addReportPostCommentSuccess,
   addReportPostCommentFailed,
   addReportPostCommentReplyRequest,
   addReportPostCommentReplySuccess,
   addReportPostCommentReplyFailed,
} = reportPostSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

//Modal - getReportReason Saga
const getReportReasonSaga = function* (payloadState: PayloadAction<reportPostReducerTypes.IGetReportPost>) {
   yield put(getReportReasonRequest());
   try {
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser: true, params, goUrl: true, ...API.getReportReason });
      if (response.status === 200) {
         const gerReportReasonList = {
            list: response.data?.data?.list || [],
            message: response?.data?.message || messages.success,
            total_records: response.data?.data?.total_records || 0,
         };
         yield put(getReportReasonSuccess(gerReportReasonList));
      } else {
         yield put(getReportReasonFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const addReportPostsSaga = function* (payloadState: PayloadAction<reportPostReducerTypes.IAddReportPost>) {
   yield put(addReportPostRequest(payloadState.payload));
   try {
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser: true, goUrl: true, data, ...API.addReportPost });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(addReportPostSuccess(responseData));
      } else {
         yield put(addReportPostFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

//Modal - getReportUserReason Saga
const getReportUserReasonSaga = function* () {
   yield put(getReportReasonRequest());
   try {
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         ...API.getReportUserReason,
      });
      if (response.status === 200) {
         const responseData = response?.data;
         const gerReportReasonList = {
            list: responseData?.data?.data || [],
            message: responseData?.message || messages.success,
            total_records: responseData?.data?.total || 0,
         };
         yield put(getReportReasonSuccess(gerReportReasonList));
      } else {
         yield put(getReportReasonFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const addReportUserPostsSaga = function* (payloadState: PayloadAction<reportPostReducerTypes.IAddReportPost>) {
   yield put(addReportUserPostRequest(payloadState.payload));
   try {
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         data,
         ...API.addReportUserPost,
      });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(addReportUserPostSuccess(responseData));
      } else {
         yield put(addReportUserPostFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const addReportPostsCommentSaga = function* (payloadState: PayloadAction<reportPostReducerTypes.IAddReportPost>) {
   yield put(addReportPostCommentRequest(payloadState.payload));
   try {
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         data,
         ...API.addReportPostComment,
      });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(addReportPostCommentSuccess(responseData));
      } else {
         yield put(addReportPostCommentFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const addReportPostsCommentReplySaga = function* (payloadState: PayloadAction<reportPostReducerTypes.IAddReportPost>) {
   yield put(addReportPostCommentReplyRequest(payloadState.payload));
   try {
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         data,
         ...API.addReportPostCommentReply,
      });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(addReportPostCommentReplySuccess(responseData));
      } else {
         yield put(addReportPostCommentReplyFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeReportPost = function* () {
   yield takeEvery(GET_REPORT_REASON, getReportReasonSaga);
   yield takeEvery(ADD_REPORT_POST, addReportPostsSaga);
   yield takeEvery(GET_REPORT_USER_REASON, getReportUserReasonSaga);
   yield takeEvery(ADD_REPORT_USER_POST, addReportUserPostsSaga);
   yield takeEvery(ADD_REPORT_POST_COMMENT, addReportPostsCommentSaga);
   yield takeEvery(ADD_REPORT_POST_COMMENT_REPLY, addReportPostsCommentReplySaga);
};
