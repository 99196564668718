import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CASE_DETAILS_ADD_ANSWERS, GET_VIEW_ANSWERS } from 'reduxToolKit/actions';
import { askADocViewAnswersSlice, commonSlice } from 'reduxToolKit/reducers';
import { askADocViewAnswersReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   askADocViewAnswersRequest,
   askADocViewAnswersSuccess,
   askADocViewAnswersFailed,
   askADocAddAnswersRequest,
   askADocAddAnswersSuccess,
   askADocAddAnswersFailed,
} = askADocViewAnswersSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const askADocViewAnswerSaga = function* (
   PayloadState: PayloadAction<askADocViewAnswersReducerTypes.IViewAnswerPhysicianID>
) {
   yield put(askADocViewAnswersRequest(PayloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = PayloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.askADocViewAnswers });
      if (response.status === 200) {
         const viewAnswersData = {
            data: response.data?.data,
            message: response.data?.message || messages.success,
         };
         yield put(askADocViewAnswersSuccess(viewAnswersData));
      } else {
         yield put(askADocViewAnswersFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const askADocAddAnswerSaga = function* (PayloadState: PayloadAction<askADocViewAnswersReducerTypes.IAddAnswerPayload>) {
   yield put(askADocAddAnswersRequest(PayloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = PayloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.askADocCaseDetailsAnswers });
      if (response.status === 200) {
         const responseData = {
            data: response.data?.data,
            message: response.data?.message || messages.success,
         };
         yield put(askADocAddAnswersSuccess(responseData));
      } else {
         yield put(askADocAddAnswersFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAskADocViewAnswersRequest = function* () {
   yield takeEvery(GET_VIEW_ANSWERS, askADocViewAnswerSaga);
   yield takeEvery(CASE_DETAILS_ADD_ANSWERS, askADocAddAnswerSaga);
};
