import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { DOCUMENTATION_LIST } from 'reduxToolKit/actions';
import { commonSlice, documentListSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { documentListLoading, documentListRequest, documentListSuccess, documentListFailed } = documentListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const documentListSaga = function* (documentTypePayload: any) {
   yield put(documentListLoading());
   yield put(documentListRequest(documentTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = documentTypePayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         goUrl,
         params,
         ...API.getPatientSymptomMonitoringDocuments,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const documentList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            message: response.data?.message,
         };
         yield put(documentListSuccess(documentList));
      } else {
         yield put(documentListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeDocumentListRequest = function* () {
   yield takeEvery(DOCUMENTATION_LIST, documentListSaga);
};
