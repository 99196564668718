import { getUser } from 'utils/helpers/authHeader';

import { toConvertLowerCase } from '../stringHelpers';

export const validateRouter = (rolesList: string[]) => {
   let isValidRoute = false;
   if (getUser() && rolesList.includes(getUser().user_type)) {
      isValidRoute = true;
   }
   return isValidRoute;
};

export const isOncKlinicUrl = (routePath: string) => {
   const routesArray = routePath.split('/');
   const index = routesArray.findIndex((item: string) => toConvertLowerCase(item) === 'oncklinic');
   return index > -1;
};
