import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_ONE_PRESCRIPTION } from 'reduxToolKit/actions';
import { commonSlice, getOnePrescriptionSlice } from 'reduxToolKit/reducers';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getOnePrescriptionRequest,
   getOnePrescriptionSuccess,
   getOnePrescriptionFailed,
} = getOnePrescriptionSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getOnePrescriptionSaga = function* (onePrescriptionTypePayload: any) {
   yield put(getOnePrescriptionRequest(onePrescriptionTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = onePrescriptionTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getOnePrescription });
      if (response.status === 200) {
         const responseData = response.data;
         const onePrescriptionList = {
            prescription_drugs: responseData.data?.prescription_drugs,
            prescription_morbidity: responseData.data?.prescription_morbidity,
            prescription_medication: responseData.data?.prescription_medication,
            symptom_submit_reminder: responseData.data?.symptom_submit_reminder,
            report_symptom_submit_reminder: responseData.data?.report_symptom_submit_reminder,
            description: responseData.data?.description,
            prescription_devices: responseData.data?.prescription_devices,
            prescription_video_reference: responseData.data?.prescription_video_reference,
            clinic_video_prescription: responseData.data?.clinic_video_prescription,
            line_of_therapy: responseData.data?.line_of_therapy,
            physician: responseData.data?.physician,
            physician_id: responseData.data?.physician_id,
            line_of_therapy_id: responseData.data?.line_of_therapy_id,
            message: response.data?.message || messages.success,
         };
         yield put(getOnePrescriptionSuccess(onePrescriptionList));
      } else {
         yield put(getOnePrescriptionFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeOnePrescriptionRequest = function* () {
   yield takeEvery(GET_ONE_PRESCRIPTION, getOnePrescriptionSaga);
};
