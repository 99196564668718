import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINIC_DELETE_STAFF } from 'reduxToolKit/actions';
import { commonSlice, deleteClinicStaffSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { deleteClinicStaffRequest, deleteClinicStaffSuccess, deleteClinicStaffFailed } = deleteClinicStaffSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const deleteClinicStaffSaga = function* (payloadState: any) {
   yield put(deleteClinicStaffRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.deleteClinicStaff });
      if (response.status === 200) {
         const resObj = {
            message: response?.data?.message,
         };
         yield put(deleteClinicStaffSuccess(resObj));
      } else {
         yield put(deleteClinicStaffFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeDeleteClinicStaff = function* () {
   yield takeEvery(CLINIC_DELETE_STAFF, deleteClinicStaffSaga);
};
