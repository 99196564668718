import React, { useCallback, useEffect, useState } from 'react';

import AddressIcon from 'assets/fill-profile/map.png';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import {
   addressSectionSagaAction,
   getPatientState,
   userAddNewState,
   userGetCountriesAction,
   getStateList,
} from 'reduxToolKit/actions';
import {
   addressSectionPostSlice,
   getPatientStateSlice,
   newStateAddingSlice,
   userGetCountriesSlice,
   userStateSlice,
} from 'reduxToolKit/reducers';
import { Icon, SelectPicker } from 'rsuite';
import { fillProfilePageTypes, fillProfileSagaTypes, myProfileSagaTypes, storeTypes } from 'types';
import { countryList, SelectPickerModel, State } from 'types/modelHelpers';
import { CommonConstants } from 'utils/constants/common';
import { DONE, NEXT } from 'utils/constants/profileConstants';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';
import {
   getCountryFromAddress,
   getCountryFromGeoLocation,
   getStateFromAddress,
   getStateFromGeoLocation,
} from 'utils/helpers/geoLocationHelpers';

export default function PhoneSection(props: any) {
   const [address, setAddress] = useState<string | null>(null);
   const [countryValue, setCountryValue] = useState<number | null>(null);
   const [isSearchState, setIsSearchState] = useState<boolean>(false);
   const [stateId, setStateId] = useState<number | null>(null);
   const [countryList, setCountryList] = useState<countryList[]>([]);
   const [countryLoading, setCountryLoading] = useState<boolean>(false);
   const [stateLists, setStateLists] = useState<fillProfilePageTypes.IStateList[]>([]);
   const [latitude, setLatitude] = useState<number | null>(null);
   const [longitude, setLongitude] = useState<number | null>(null);
   const [validation, setValidation] = useState(false);
   const [addressError, setAddressError] = useState('');
   const [stateObject, setStateObject] = useState(null as any);
   const [countryId, setCountryId] = useState(null as any);

   const dispatch = useDispatch();
   const { newStateAdded, error } = useSelector((state: storeTypes.IStore) => state.newStateAdded);
   const { newStateDetailReset } = newStateAddingSlice.actions;
   const { message, errorMessage } = useSelector((state: storeTypes.IStore) => state.profileAddressSection);
   const { addressSectionReset } = addressSectionPostSlice.actions;

   const { message: patientStateMessage, data: patientStateData, error: patientStateError } = useSelector(
      (state: storeTypes.IStore) => state.getPatientStateData
   );
   const { getPatientStateReset } = getPatientStateSlice.actions;

   const { message: userGetCountriesMessage, list: userGetCountriesList, error: userGetCountriesError } = useSelector(
      (state: storeTypes.IStore) => state.userGetCountries
   );
   const userStateData = useSelector((state: storeTypes.IStore) => state.userStateData);
   const { userGetCountriesResetMessage, userGetCountriesReset } = userGetCountriesSlice.actions;
   const { userStateReset } = userStateSlice.actions;

   const {
      setIsLoader,
      isFromClinicalTrials,
      navigation,
      nexSection,
      currentModalClose,
      isAddress,
      currentStateArray,
      currentState,
      nextSectionClinicalTrails,
      userDetail,
      finalMessage,
      isFromFeedInitial,
   } = props;

   useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
         document.body.style.overflow = 'scroll';
      };
   }, []);

   useEffect(() => {
      if (userGetCountriesMessage) {
         setCountryLoading(false);
         const list: countryList[] = [];
         const countriesList = listParseAndStringify(userGetCountriesList || []);
         const countryObj = countriesList.find((country: any) => country.id === 236);
         countriesList.forEach((obj: countryList) => {
            if (countryObj.id !== obj.id) {
               obj.label = obj.name;
               obj.value = obj.id;
               list.push(obj);
            }
         });
         countryObj.label = countryObj.name;
         countryObj.value = countryObj.id;
         list.unshift(countryObj);
         setCountryList(list);
         if (userDetail?.user?.country) {
            setCountryValue(userDetail?.user?.country.id);
            setStateId(userDetail?.user?.patient_state?.state?.id);
            getStates(1, '', userDetail?.user?.country.id);
         }
         dispatch(userGetCountriesResetMessage());
      } else if (userGetCountriesError) {
         setCountryLoading(false);
         setCountryList([]);
         dispatch(userGetCountriesReset());
      }
   }, [userGetCountriesMessage, userGetCountriesList, userGetCountriesError]);

   useEffect(() => {
      if (userStateData?.userStates?.message) {
         setIsLoader(false);
         setIsSearchState(true);
         const stateLists: SelectPickerModel[] = [];
         userStateData?.userStates?.list.forEach((state: State) => {
            const selectPickerModel = { label: state.name, value: state.id };
            stateLists.push(selectPickerModel);
         });
         setStateLists(stateLists);
         setIsSearchState(false);

         const stateFiltered = stateLists.filter(
            (state: fillProfilePageTypes.IStateList) => state.label === stateObject?.longName
         );
         if (stateFiltered.length > 0) {
            setStateId(stateFiltered[0].value ? stateFiltered[0].value : 0);
            setIsLoader(false);
         } else if (countryId && stateObject?.longName) {
            newStateAdding(countryId || 0, stateObject?.longName);
         }
         dispatch(userStateReset());
      } else if (userStateData?.userStates?.error) {
         setIsSearchState(true);
         setIsLoader(false);
         setStateLists([]);
         setIsSearchState(false);
      }
   }, [userStateData]);

   const handleSubmit = () => {
      if (address !== null && countryValue !== null && stateId !== null) {
         setValidation(false);
         setIsLoader(true);
         const postAddressData: fillProfileSagaTypes.IAddressSectionType = {
            input_type: 'address',
            address: address,
            latitude: latitude ? String(latitude) : null,
            longitude: longitude ? String(longitude) : null,
            country_id: countryValue,
            state_id: stateId,
         };
         dispatch(addressSectionSagaAction(postAddressData));
      } else {
         setIsLoader(false);
         setValidation(true);
         return;
      }
   };

   const gotoNavigation = useCallback(() => {
      if (isFromFeedInitial) {
         currentModalClose();
         setIsLoader(false);
         finalMessage();
      } else if (isFromClinicalTrials) {
         navigation(nextSectionClinicalTrails);
      } else {
         navigation(nexSection);
      }
   }, []);

   useEffect(() => {
      if (message) {
         if (isFromFeedInitial) {
            currentModalClose();
            setIsLoader(false);
            finalMessage();
         } else {
            gotoNavigation();
         }
         setIsLoader(false);
         dispatch(addressSectionReset());
      }
      if (errorMessage) {
         setAddressError(errorMessage);
         setIsLoader(false);
         dispatch(addressSectionReset());
      }
      if (patientStateMessage) {
         setIsSearchState(false);
         if (patientStateData !== null) {
            const stateId = patientStateData?.state_id || 0;
            localStorage.setItem('stateId', stateId);
            setStateId(stateId);
         } else {
            localStorage.setItem('stateId', '');
         }
         dispatch(getPatientStateReset());
      } else if (patientStateError) {
         setIsSearchState(false);
         dispatch(getPatientStateReset());
      }
   }, [message, errorMessage, patientStateError, patientStateData, patientStateMessage]);

   const handleChange = (address: string) => {
      setAddress(address ? address : null);
      setLongitude(null);
      setLatitude(null);
   };

   const getCurrentLocation = () => {
      if (navigator?.geolocation) {
         setIsLoader(true);
         navigator.geolocation.getCurrentPosition(
            (position) => {
               const geocoder = new google.maps.Geocoder();
               const latlng = { lat: position.coords.latitude, lng: position.coords.longitude };
               geocoder.geocode({ location: latlng }, function (results) {
                  if (results.length > 0 && results[0]) {
                     setLatitude(position.coords.latitude);
                     setLongitude(position.coords.longitude);
                     setAddress(results[0].formatted_address);
                     const countryObj = getCountryFromGeoLocation(results);
                     const stateObj = getStateFromGeoLocation(results);

                     const countryFiltered = countryList.filter(
                        (country: countryList) => country.name === countryObj.longName
                     );
                     if (countryFiltered.length) {
                        setIsLoader(false);
                        setCountryValue(countryFiltered[0].id);
                        changeCountry(countryFiltered[0].id, stateObj);
                     } else {
                        setIsLoader(false);
                     }
                  }
               });
            },
            (error) => {
               setIsLoader(false);
               setLatitude(null);
               setLongitude(null);
            },
            { enableHighAccuracy: true }
         );
      }
   };

   const newStateAdding = (countryId: number, state: string | null) => {
      setIsLoader(true);
      const payload = {
         state_name: state,
         country_id: countryId,
      };
      dispatch(userAddNewState(payload));
   };

   const getCountries = (search: string) => {
      setCountryLoading(true);
      const params: myProfileSagaTypes.IGetCountriesRequest = {
         limit: CommonConstants.DropDownLimit,
         search_word: search,
      };
      dispatch(userGetCountriesAction(params));
   };

   const changeCountry = (data: number, stateObj?: any) => {
      if (data !== countryValue) {
         setCountryValue(data);
         setStateId(null);
         getStates(1, '', data, stateObj);
      } else {
         setIsLoader(false);
      }
   };
   const handleSelect = (address: string) => {
      geocodeByAddress(address)
         .then((results) => {
            setAddress(address);
            const countryObj = getCountryFromAddress(results);
            const stateObj = getStateFromAddress(results);

            const countryFiltered = countryList.filter((country: countryList) => country.name === countryObj.longName);
            if (countryFiltered.length) {
               setCountryValue(countryFiltered[0].id);
               changeCountry(countryFiltered[0].id, stateObj);
            }
            return getLatLng(results[0]);
         })
         .then(({ lat, lng }) => {
            setLatitude(lat);
            setLongitude(lng);
         })
         .catch(() => {
            setLatitude(null);
            setLongitude(null);
         });
   };

   const getStates = (page: number, searchWord: string, country_id: number | null, stateObj?: any) => {
      setIsSearchState(true);
      setStateObject(stateObj);
      setCountryId(country_id);

      const params = {
         page: page,
         // limit: this.state.fetchRecords,
         search_word: searchWord,
         country_id: country_id,
      };
      dispatch(getStateList(params));
   };

   const onChangeState = (value: any) => {
      setStateId(value);
   };

   const onSearchState = (value: string) => {
      setTimeout(() => {
         setIsSearchState(true);
         setStateLists([]);
         getStates(1, value, countryValue);
      }, 1000);
   };

   const getPatientStateFunction = () => {
      setIsSearchState(true);
      dispatch(getPatientState({}));
   };

   useEffect(() => {
      if (newStateAdded) {
         const selectPickerModel: fillProfilePageTypes.IStateList = {
            label: newStateAdded?.name,
            value: newStateAdded?.id,
         };
         setStateId(newStateAdded?.id);
         setStateLists((previousState) => [...previousState, selectPickerModel]);
         setIsLoader(false);
         dispatch(newStateDetailReset());
      } else if (error) {
         setIsLoader(false);
         dispatch(newStateDetailReset());
      }
   }, [newStateAdded, error]);

   useEffect(() => {
      if (isAddress) {
         gotoNavigation();
      } else {
         getCountries('');
         getPatientStateFunction();
      }
   }, []);

   useEffect(() => {
      if (userDetail) {
         if (userDetail?.user?.address) {
            setAddress(userDetail?.user?.address);
         }
         if (userDetail?.user?.latitude) {
            setLatitude(userDetail?.user?.latitude);
         }
         if (userDetail?.user?.longitude) {
            setLongitude(userDetail?.user?.longitude);
         }
      }
   }, [userDetail]);

   return (
      <React.Fragment>
         <div className="col-12 ">
            <img className="phone-icon" src={AddressIcon} alt="icon" />
         </div>
         <div>
            <p className="address-text p-3">
               Great, please provide your location to enter the app. This helps us give you insight to local care and
               resources.
            </p>
         </div>
         <div className="form-row ">
            <div className="col-12">
               <label htmlFor="title" aria-hidden="true" className="dob-header ml-3">
                  Address
               </label>
               <span
                  onClick={() => {
                     if (!countryLoading) {
                        getCurrentLocation();
                     }
                  }}
                  className="getLocation pull-right mb-2"
               >
                  Locate Me
               </span>

               <PlacesAutocomplete value={address ? address : ''} onSelect={handleSelect} onChange={handleChange}>
                  {({ getInputProps, suggestions, getSuggestionItemProps }: any) => (
                     <div style={{ marginLeft: '8px' }}>
                        <input
                           disabled={countryLoading}
                           className="address-input"
                           value={address ? address : ''}
                           {...getInputProps({
                              placeholder: 'Search Address ...',
                           })}
                        />
                        <div
                           className={`addressAutocomplete ${
                              suggestions?.length > 0 ? 'addressAutocompleteScroll style-7' : ''
                           }`}
                        >
                           {suggestions.map((suggestion: any, i: number) => {
                              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';

                              // inline style for demonstration purpose
                              const style = suggestion.active
                                 ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                 : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                 <div key={i} {...getSuggestionItemProps(suggestion, { className, style })}>
                                    <span>
                                       <i className="fa fa-map-marker" /> {suggestion.description}
                                    </span>
                                 </div>
                              );
                           })}
                        </div>
                     </div>
                  )}
               </PlacesAutocomplete>
               {address === null && validation ? <div className="error-msg-address">Search your address</div> : null}

               {/* {address !== null && (latitude === null || longitude === null) && validation ? (
                  <div className="error-msg-address">Can't get latitude and longitude</div>
               ) : null} */}

               <div className="error-msg">{addressError || ''}</div>
            </div>
         </div>
         <div className="form-row ml-1 mt-2 ">
            <div className="col-12">
               <label htmlFor="title" aria-hidden="true" className="dob-header">
                  Country
               </label>
               <SelectPicker
                  preventOverflow={true}
                  cleanable={false}
                  maxHeight={150}
                  placement="topStart"
                  onChange={(data) => {
                     changeCountry(data);
                  }}
                  value={countryValue}
                  data={countryList}
                  renderMenu={(menu) => {
                     if (countryList.length === 0) {
                        return (
                           <p
                              style={{
                                 padding: 4,
                                 color: '#999',
                                 textAlign: 'center',
                              }}
                           >
                              <Icon icon="spinner" spin /> Loading
                           </p>
                        );
                     }
                     return menu;
                  }}
               />
               {countryValue === null && validation ? (
                  <div className="error-msg-address">Country Field is required</div>
               ) : null}
            </div>
         </div>
         <div className="form-row ml-1 mt-2">
            <div className="col-12">
               <label htmlFor="title" aria-hidden="true" className="dob-header">
                  State
               </label>
               <SelectPicker
                  placeholder="Select"
                  cleanable={false}
                  maxHeight={150}
                  onChange={onChangeState}
                  placement="topStart"
                  preventOverflow={true}
                  value={stateId}
                  data={stateLists}
                  onSearch={onSearchState}
                  renderMenu={(menu) => {
                     if (stateLists.length === 0 && isSearchState) {
                        return (
                           <p
                              style={{
                                 padding: 4,
                                 color: '#999',
                                 textAlign: 'center',
                              }}
                           >
                              <Icon icon="spinner" spin /> Loading
                           </p>
                        );
                     } else if (stateLists.length === 0 && !isSearchState) {
                        return (
                           <p
                              style={{
                                 padding: 4,
                                 color: '#999',
                                 textAlign: 'center',
                              }}
                           >
                              No Results Found
                           </p>
                        );
                     }
                     return menu;
                  }}
               />
               {!stateId && validation ? <div className="error-msg-address">State Field is required</div> : null}
            </div>
         </div>
         <div className="button">
            <button type="button" className="button-start" id="addressSectionSubmit" onClick={handleSubmit}>
               {currentStateArray && currentStateArray[currentStateArray?.length - 1] === currentState ? DONE : NEXT}
            </button>
         </div>
      </React.Fragment>
   );
}
