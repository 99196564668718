import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authReducerTypes, authSagaTypes } from 'types';

const initialState: authReducerTypes.IForgetPasswordReducerTypes = {
   message: '',
   verificationType: '',
   error: '',
};
export const forgetPasswordSlice = createSlice({
   name: 'changePassword',
   initialState: initialState,
   reducers: {
      forgetPasswordRequest: (state, action: PayloadAction<authSagaTypes.IForgetPasswordSagaPayload>) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      forgetPasswordSuccess: (state, action: PayloadAction<authReducerTypes.IForgetPasswordSuccessPayload>) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      forgetPasswordFailed: (state, action: PayloadAction<authReducerTypes.IForgetPasswordFailurePayload>) => {
         return {
            ...state,
            error: action.payload.error,
         };
      },
      forgetPasswordReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
