import React, { useCallback, useEffect, useState } from 'react';

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import LatinoIcon from 'assets/fill-profile/latino_icon.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import { useSelector, useDispatch } from 'react-redux';
import { addLatinoOptionAction } from 'reduxToolKit/actions';
import { latinoSlice } from 'reduxToolKit/reducers';
import { myProfileSagaTypes, storeTypes } from 'types';
import { DONE, NEXT, SUBMIT } from 'utils/constants/profileConstants';

const LatinoSection = (props: any) => {
   const dispatch = useDispatch();
   const [errorMessage, setErrorMessage] = useState<string>('');
   const [value, setValue] = useState('');

   const handleChange = useCallback((event) => {
      setErrorMessage('');
      setValue(event.target.value);
   }, []);

   const { message: latinoMessage, error: latinoError } = useSelector((state: storeTypes.IStore) => state.latino);
   const { latinoReset } = latinoSlice.actions;

   const hideAlert = (type: string) => {
      if (type === 'fillProfileUpdateSuccess') {
         if (props.isFromNotification) {
         } else if (props.isFromClinicalTrials) {
         } else {
            window.location.href = '/feed';
         }
         dispatch(latinoReset());
      } else if (type === 'diagnosisSuccess') {
         if (props?.isFromLatino) {
            props?.closeModelMyProfile();
            props.getUserData();
         }
         dispatch(latinoReset());
      } else if (type === 'diagnosisError') {
         dispatch(latinoReset());
         props?.setIsLoader(false);
      }
   };

   const clickNext = useCallback(() => {
      if (!value) {
         setErrorMessage('Hispanic or Latino cannot be blank');
         return;
      }
      props?.setIsLoader(true);
      const data: myProfileSagaTypes.ILatinoProps = {
         hispanic_or_latino: value,
      };
      dispatch(addLatinoOptionAction(data));
   }, [value]);

   useEffect(() => {
      if (props.isLatinoValid) {
         props?.currentModalClose();
         props.setIsLoader(false);
         props?.finalMessage();
      }
      // edit
      setValue(props?.userDetail?.hispanic_or_latino || '');
   }, []);

   useEffect(() => {
      if (latinoMessage && props?.isFromLatino) {
         const getAlert = () => (
            <InfoTimerAlert
               success
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={latinoMessage}
               onConfirm={() => hideAlert('diagnosisSuccess')}
            />
         );
         props?.setAlert(getAlert);
         props?.setIsLoader(false);
         props.currentModalClose();
      } else if (latinoMessage) {
         props?.finalMessage();
         props?.currentModalClose();
         props?.setIsLoader(false);
      }
   }, [latinoMessage]);

   return (
      <div>
         {latinoError ? (
            <InfoTimerAlert
               error
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={latinoError}
               onConfirm={() => hideAlert('diagnosisError')}
            />
         ) : null}
         <div className="col-12">
            <img className="common-race-icon latino-icon mt--2" src={LatinoIcon} alt="icon" />
         </div>
         <div className="col-12">
            <p className="common-race-title-text latino-title-text">Are you Hispanic or Latino?</p>
         </div>
         <div className="col-12">
            <p className="common-race-paragraph-text latino-paragraph-text  p-2">Please choose your origin</p>
         </div>
         <div className="col-12">
            <FormControl className="latino-form-control">
               <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="latino-group-div"
                  value={value}
                  onChange={handleChange}
               >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
               </RadioGroup>
            </FormControl>
         </div>
         {errorMessage ? (
            <div className="error-msg ml-4 mt-3 mb-3 mr-4" style={{ textAlign: 'center' }}>
               {errorMessage || ''}
            </div>
         ) : null}
         <div className="button">
            <button type="submit" className="button-start" onClick={clickNext}>
               {props?.currentStateArray &&
               props?.currentStateArray[props?.currentStateArray?.length - 1] === props?.currentState
                  ? DONE
                  : props?.isFromLatino
                  ? SUBMIT
                  : NEXT}
            </button>
         </div>
      </div>
   );
};

export default LatinoSection;
