import { createSlice } from '@reduxjs/toolkit';
import { feedReducerTypes } from 'types';

const initialState: feedReducerTypes.IShowPatientFeedReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const showPatientFeedSlice = createSlice({
   name: 'showPatientFeedSlice',
   initialState: initialState,
   reducers: {
      showPatientRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      showPatientSuccess: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            data: action.payload,
         };
      },
      showPatientFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      showPatientReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
export { showPatientFeedSlice };
