import { createAction } from '@reduxjs/toolkit';
import { phyNpiNumberSagaTypes } from 'types';

export const PHYSICIAN_NPI_NUMBER_VERIFICATION = 'PHYSICIAN_NPI_NUMBER_VERIFICATION';
export const physicianNpiNumberVerification = createAction(PHYSICIAN_NPI_NUMBER_VERIFICATION);

export const ADD_PHYSICIAN_NPI_NUMBER = 'ADD_PHYSICIAN_NPI_NUMBER';
export const addPhysicianNpiNumber = createAction<phyNpiNumberSagaTypes.IAddNpiNumberSagaPayload>(
   ADD_PHYSICIAN_NPI_NUMBER
);
