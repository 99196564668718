import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { modalUpdateFamilyHistoriesReducerTypes, modalUpdateFamilyHistoriesSagaTypes } from 'types';

const initialState: modalUpdateFamilyHistoriesReducerTypes.IModalUpdateFamilyHistoriesReducer = {
   error: '',
   message: '',
   isLoading: false,
};

export const modalUpdateFamilyHistoriesSlice = createSlice({
   name: 'modalUpdateFamilyHistories',
   initialState: initialState,
   reducers: {
      modalUpdateFamilyHistoriesRequest: (
         state,
         action: PayloadAction<modalUpdateFamilyHistoriesSagaTypes.IModalUpdateFamilyHistoriesSagaPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      modalUpdateFamilyHistoriesSuccess: (
         state,
         action: PayloadAction<modalUpdateFamilyHistoriesReducerTypes.IModalUpdateFamilyHistoriesSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      modalUpdateFamilyHistoriesFailed: (
         state,
         action: PayloadAction<modalUpdateFamilyHistoriesReducerTypes.IModalUpdateFamilyHistoriesFailedPayload>
      ) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      modalUpdateFamilyHistoriesReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
