import { put, takeEvery } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ADD_MY_SERVICE_SPECIALTES } from 'reduxToolKit/actions';
import { addMyServicesSpecialtiesSlice, commonSlice } from 'reduxToolKit/reducers';
import { postShareSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   addMyServicesSpecialtiesRequest,
   addMyServicesSpecialtiesSuccess,
   addMyServicesSpecialtiesFailed,
} = addMyServicesSpecialtiesSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const addMyServicesSpecialtiesSaga = function* (
   payloadState: PayloadAction<postShareSagaTypes.IAddMyServiceSpecialties>
) {
   yield put(addMyServicesSpecialtiesRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addMyServicesSpecialties });
      if (response.status === 200) {
         yield put(addMyServicesSpecialtiesSuccess(response?.data || messages.success));
      } else {
         yield put(addMyServicesSpecialtiesFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAddMyServicesSpecialties = function* () {
   yield takeEvery(ADD_MY_SERVICE_SPECIALTES, addMyServicesSpecialtiesSaga);
};
