import { createSlice } from '@reduxjs/toolkit';
import { myPhysicianReducerTypes, myPhysicianSagaTypes } from 'types';

const initialState: myPhysicianReducerTypes.IAppointmentReducer = {
   myPhysician: null,
   isLoading: false,
   error: '',
};
const myPhysicianSlice = createSlice({
   name: 'getMyPhysician',
   initialState: initialState,
   reducers: {
      getMyPhysicianList: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getMyPhysicianSuccess: (state, action: myPhysicianSagaTypes.IMyPhysicianSuccess) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      getMyPhysicianFailed: (state, action) => {
         return {
            ...state,
            myPhysician: null,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
   },
});
export { myPhysicianSlice };
