import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINIC_INVITE_PATIENT_REQ } from 'reduxToolKit/actions';
import { clinicInvitePatientReqSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   clinicInvitePatientReqRequest,
   clinicInvitePatientReqSuccess,
   clinicInvitePatientReqFailed,
} = clinicInvitePatientReqSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const clinicInvitePatientReqSaga = function* (payloadState: any) {
   yield put(clinicInvitePatientReqRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.clinicInvitePatientReq });
      if (response.status === 200) {
         const resObj = {
            message: response?.data?.message,
         };
         yield put(clinicInvitePatientReqSuccess(resObj));
      } else {
         yield put(clinicInvitePatientReqFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeclinicInvitePatientReq = function* () {
   yield takeEvery(CLINIC_INVITE_PATIENT_REQ, clinicInvitePatientReqSaga);
};
