import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { feedReducerTypes, feedSagaTypes } from 'types';

const initialState: feedReducerTypes.IFeedReactionsAddReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
export const askADocFeedReactionSlice = createSlice({
   name: 'askADocFeedReactionSlice',
   initialState: initialState,
   reducers: {
      askADocFeedReactionsRequest: (state, action: PayloadAction<feedSagaTypes.IFeedReactions>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      askADocFeedReactionsSuccess: (state, action: PayloadAction<feedReducerTypes.IFeedReactionsAddReducer>) => {
         return {
            ...state,
            isLoading: false,
            ...action.payload,
         };
      },
      askADocFeedReactionsFailed: (state, action: PayloadAction<feedReducerTypes.IFeedReactionsAddReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      askADocFeedReactionsReset: (state) => {
         return {
            ...initialState,
         };
      },
   },
});
