import { createSlice } from '@reduxjs/toolkit';
import { healthRecordsReducerTypes } from 'types';

const initialState: healthRecordsReducerTypes.IHealthRecordTypeListReducer = {
   isLoading: false,
   progressLoadMore: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
};

export const healthRecordSlice = createSlice({
   name: 'healthRecords',
   initialState: initialState,
   reducers: {
      healthRecordLoading: (state) => {
         return {
            ...state,
            isLoading: true,
            progressLoadMore: true,
         };
      },
      healthRecordRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            progressLoadMore: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      healthRecordSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.page === 1 ? payload.list : [...state.list, ...payload.list],
            total_records: payload.total_records,
            isLoading: false,
            progressLoadMore: false,
         };
      },
      healthRecordFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            progressLoadMore: false,
            message: '',
            error: action.payload.error_message,
         };
      },

      healthRecordReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
