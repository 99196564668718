import { createSlice } from '@reduxjs/toolkit';
import { myProfileReducerTypes } from 'types';

const initialState: myProfileReducerTypes.IDiagnosisReducer = {
   error: '',
   message: '',
   isLoading: false,
};

export const diagnosisSlice = createSlice({
   name: 'diagnosis',
   initialState: initialState,
   reducers: {
      diagnosisRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      diagnosisSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      diagnosisFailed: (state, action: any) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      diagnosisReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
