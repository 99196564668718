import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { SUPPORT_GRP_LEAVE_GROUP, SUPPORT_GROUPS_INFO, SUPPORT_GRP_MEMBERS_LIST } from 'reduxToolKit/actions';
import { commonSlice, groupsInfoDetailsSlice } from 'reduxToolKit/reducers';
import { supportGroupsSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getGroupsInfoRequest,
   getGroupsInfoSuccess,
   getGroupsInfoFailed,
   groupLeaveRequest,
   groupLeaveSuccess,
   groupLeaveFailed,
   membersListRequest,
   membersListSuccess,
   membersListFailed,
} = groupsInfoDetailsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getGroupInfoSaga = function* (payloadState: PayloadAction<supportGroupsSagaTypes.IGroupInfoSagaTypes>) {
   yield put(getGroupsInfoRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getGroupInfo });
      if (response.status === 200) {
         const responseData = response?.data?.data;
         const returnData = {
            data: responseData,
            message: messages.success,
         };

         yield put(getGroupsInfoSuccess(returnData));
      } else {
         yield put(getGroupsInfoFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
const getMembersListSaga = function* (payloadState: PayloadAction<supportGroupsSagaTypes.IMembersList>) {
   yield put(membersListRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.membersList });
      if (response.status === 200) {
         const responseData = response?.data?.data;
         const returnData = {
            data: responseData,
            message: messages.success,
         };

         yield put(membersListSuccess(returnData));
      } else {
         yield put(membersListFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const leaveGroupSaga = function* (payloadState: PayloadAction<supportGroupsSagaTypes.IGroupInfoSagaTypes>) {
   yield put(groupLeaveRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.leaveGroup });
      if (response.status === 200) {
         const responseData = {
            message: response?.data?.message || messages.success,
            data: response?.data,
         };
         yield put(groupLeaveSuccess(responseData));
      } else {
         yield put(groupLeaveFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeSupportGroupInfo = function* () {
   yield takeLatest(SUPPORT_GROUPS_INFO, getGroupInfoSaga);
};
export const takeLeaveSupportGroup = function* () {
   yield takeLatest(SUPPORT_GRP_LEAVE_GROUP, leaveGroupSaga);
};
export const takeMembersList = function* () {
   yield takeLatest(SUPPORT_GRP_MEMBERS_LIST, getMembersListSaga);
};
