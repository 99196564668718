import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_SERVICES } from 'reduxToolKit/actions';
import { commonSlice, myServiceListSlice } from 'reduxToolKit/reducers';
import { postShareSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   myServiceListLoading,
   myServiceListViewRequest,
   myServiceListViewSuccess,
   myServiceListFailed,
} = myServiceListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const ListSaga = function* (servicePayload: PayloadAction<postShareSagaTypes.IWallet>) {
   yield put(myServiceListLoading());
   yield put(myServiceListViewRequest(servicePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = servicePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.myServicesList });
      if (response?.status === 200) {
         const responseData = response?.data;
         const oncDriveList = {
            list: responseData?.data?.list,
            total_records: responseData?.data?.total_records,
         };
         yield put(myServiceListViewSuccess(oncDriveList));
      } else {
         yield put(myServiceListFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeMyServiceListRequest = function* () {
   yield takeEvery(GET_SERVICES, ListSaga);
};
