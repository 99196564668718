export default {
   /*Common Routes Start*/
   //not completed
   userLogin: '/login',
   // not yet completed
   myProfile: '/user/my-profile',
   //not completed
   updateDisplayNameStatus: '/user/update-display-name',
   //not completed
   updateProfileImage: '/user/update-profile-image',
   forgotPassword: '/forgot-password',
   // not completed
   emailVerification: '/email-verification',
   resetPasswordViaEmail: '/reset-password-via-email',
   resetPasswordViaPhone: '/reset-password-via-phone',
   setPasswordViaEmail: '/set-password-via-email',
   // not completed
   getMyNotifications: '/user/get-my-notifications',
   // not completed
   getPhysicianInfo: '/user/get-physician-basic-info',
   // not completed
   getS3SignedURL: '/user/generate-pre-singed-s3-url',
   /*Connections*/
   // not completed
   userConnectionRequest: 'user/connection-request',
   // not completed
   userGetPendingRequest: 'user/get-pending-requests',
   // not completed
   userGetPendingInvites: 'user/get-pending-invites',
   //not completed
   acceptConnection: 'user/connection-invite/accept',
   // not completed
   deleteConnectionRequest: 'user/delete-connection-request',
   // not completed
   deleteInviteRequest: 'user/delete-invite-request',
   // not completed
   deleteAccount: '/user/delete-account',

   /*Post*/
   // not completed
   addPostMedia: '/user/add-post-media',
   // not completed
   postLike: '/user/post-like',
   // not completed
   addComment: '/user/post/add-comment',
   // not completed
   getPostComments: '/user/post/get-comments',
   // not completed
   getPostLikes: '/user/post/get-likes',
   // not completed
   getPostShares: '/user/post/get-shares',
   // not completed
   editComment: '/user/post/update-comment',

   //Prescription
   // not completed
   getTherapyMedications: '/user/get-therapy-medications',
   // not completed
   getCountries: '/user/get-countries',
   /*Common Routes End*/

   /*Patient Routes Start*/
   // not completed
   getPhysician: 'user/get-physicians',
   /* ask a doc */
   getAskPhysicians: '/user/ask-physician-list', // Not used
   getNearByClinics: '/user/patient/get-nearby-clinics', // Not used
   getNearByPanels: '/user/patient/get-nearby-panels', // Not used
   askPhysicianMedia: '/user/add-ask-physician-media', // Not used
   getPatientClinics: '/user/patient/get-clinics', // Temporarily not used
   getPatientClinicPhysicians: '/user/patient/clinic/get-physicians', // Temporarily not used
   getWithoutPhysicians: '/user/patient/ask-a-doc/get-physicians-list', // Temporarily not used
   updateRequestStatus: '/user/ask-physician/update-request-status', // Temporarily not used
   // not completed
   getAskADocFeed: '/user/ask-a-doc-feed',
   // not completed
   askADocFeedLike: '/user/ask-a-doc-feed/add-like',
   // not completed
   getAskADocFeedLikes: '/user/ask-a-doc-feed/get-likes',
   // not completed
   askADocFeedComment: '/user/ask-a-doc-feed/add-comment',
   // not completed
   getAskADocFeedComments: '/user/ask-a-doc-feed/get-comments',
   // not completed
   askADocFeedCommentEdit: '/user/ask-a-doc-feed/update-comment',
   // not completed
   askADocFeedCommentDelete: '/user/ask-a-doc-feed/delete-comment',
   getMyConversations: '/user/get-my-conversations', // Not used
   // not completed
   getOneSecondOpinionSummery: '/user/get-one-ask-physician-summary/',

   /* Book appointment */
   // not completed
   getTimeSlots: 'user/patient-appointment/get-physician-schedule',

   /* Temprature */
   // not completed
   getSymptoms: '/user/get-symptoms', // Not used
   addTemperature: '/user/add-temperature', // Not used
   getMyTemperatureHistories: '/user/get-my-temperature-histories', // Not used

   /* Symptoms */
   // not completed
   getSymptomsStatistics: '/user/symptom-statistics', // Not used

   /* Temperature or thermometer */
   addSmartTemperature: '/user/add-smart-temperature', // Not used
   // not completed
   getSmartTemperatures: '/user/get-my-smart-temperatures',
   // not completed
   smartTemperatureStatistics: '/user/smart-temperature-statistics',

   /* Oxymeter */
   // not completed
   addSmartOxymeter: '/user/add-smart-oximeter',
   // not completed
   getSmartOxymeters: '/user/get-my-smart-oximeters',
   // not completed
   smartOxymeterStatistics: '/user/smart-oximeter-statistics',

   /*Patient Routes End*/

   /*Physician Routes Start*/

   getTimeZone: 'user/get-timezones',
   // not completed
   updateMyServices: 'user/physician/update-my-services', // Temporarily Not used
   getMyServices: '/user/get/physician-services', // Temporarily Not used
   getSubSpecialties: '/user/get-sub-specialities',
   // not completed
   getOnePhysician: '/user/ask-physician-get-one',
   // not completed
   addAnswersOfQuestions: '/user/ask-physician/add-answers',
   // not completed
   rejectPhysicianCase: 'user/ask-physician/remove-request',
   // not completed
   getServiceSpecialties: '/user/get-service-specialities',
   // not completed
   checkRequiredFields: '/user/physician/check-required-fields',

   //admin
   addClinicMedia: '/user/physician/add-clinic-media',
   // not completed
   addMediaForClinicVideo: '/user/physician/clinic-video/add-media',

   /*Schedules*/
   // not completed
   getSchedule: 'user/physician-appointment/get-my-schedule',
   // not completed
   getPhysicianSchudule: '/user/physician-appointment/get-physician-schedule',

   /* OncoKlinic */
   getLatestViewedClinc: '/user/oncoklinic/get-latest-viewed-clinic', // Not used
   getPatientRequests: '/user/oncoklinic/get-patient-requests', // Not used
   getAllPatients: '/user/get-all-patients', // Not used
   getIsMyPatient: '/user/oncoklinic/is-my-patient', // completed

   /* Smart Weight */
   // not completed
   addSmartWeight: '/user/add-smart-weight',
   //not completed
   getMySmartWeight: '/user/get-my-smart-weights',
   //not completed
   getSmartWeightStatistics: '/user/smart-weight-statistics',

   /* Blood Pressure */
   // not completed
   getBloodPressureCondition: 'user/get-blood-pressure-conditions',
   // not completed
   addBloodPressure: '/user/add-blood-pressure',

   /* Documentation */
   // not completed
   getPatientRpmBills: '/user/get-patient-rpm-bills',
   // not completed
   getPatientRpmBillById: '/user/get-rpm-bill-by-id',
   // not completed
   updateRpmDocument99453: '/user/update-rpm-document-99453',
   // not completed
   updateRpmDocument99454: '/user/update-rpm-document-99454',
   // not completed
   updateRpmDocument99457: '/user/update-rpm-document-99457',
   // not completed
   updateRpmDocument99458: '/user/update-rpm-document-99458',
   // not completed
   updateRpmDocument99091: '/user/update-rpm-document-99091',

   /* Symptoms */
   // not completed
   getSymptomQuestionnaire: '/user/get-symptom-questionnaire',
   // not completed
   getRecentSymptoms: '/user/symptom-questionnaire/get-recent-symptoms', // not used
   addQuestionariesAnswer: '/user/symptom-questionnaire/add-answers',
   getMyAnswer: '/user/symptom-questionnaire/get-my-answers',
   // not completed
   getSymptomView: '/user/symptom-questionnaire/get-one-answer',
   // not completed
   getPatientSymptoms: '/user/symptom/get-patient-symptoms', // not used

   /* Health Records */
   addHealthRecordMedia: '/user/add-health-record-media',

   //local recommended
   // not completed
   getLocalRecommendedPhysiciansList: '/user/get-local-recommended-physicians-list',

   /*Physician Routes End*/

   /*RPM Routes Start*/
   // not completed
   getPatientRPM: '/user/get-patient-rpm',
   // not completed
   updateRpmSignedFile: '/user/update-rpm-signed-file',
   // not completed
   updateRpmConsentAgreement: '/user/patient-rpm-consent-agreement',
   //not completed
   updateRpmSignedFileRequest: '/user/update-patient-rpm-signed-file-request',
   // not completed
   updateRevoke: '/user/update-revoke',
   // not completed
   updatePatientRevokeRequest: '/user/update-patient-revoke-request',
   // not completed
   updateRevokeRequestPatient: '/user/patient-rpm-revoke-request',
   // not completed
   getClinicRpmAllDevices: '/user/get-my-clinic-all-rpm-devices',
   // not completed
   cancelPatientRpm: '/user/cancel-patient-rpm',
   /*RPM Routes End*/

   //symptom consent
   // not completed
   updateSymptomMonitoringConsent: '/user/Update-symptom-monitoring-consent',
   // not completed
   getOnePatientSymptomMonitoringDocument: '/user/get-symptom-monitoring-documentation-by-id',
   // not completed
   updateSymptomMonitoringDocumentation: '/user/update-symptom-monitoring-documentation',
};
