import { createAction } from '@reduxjs/toolkit';
import { feedSagaTypes, notificationSagaTypes } from 'types';

export const GET_NOTIFICATION_COUNT = 'GET_NOTIFICATION_COUNT';
export const notificationCount = createAction<feedSagaTypes.INotification>(GET_NOTIFICATION_COUNT);

export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
export const notificationList = createAction<feedSagaTypes.INotificationList>(NOTIFICATION_LIST);

export const ONCLICK_READ = 'ONCLICK_READ';
export const onClickReadAction = createAction<feedSagaTypes.IGetOneDrugs>(ONCLICK_READ);

export const ONCLICK_ALL_READ = 'ONCLICK_ALL_READ';
export const onClickReadAllAction = createAction(ONCLICK_ALL_READ);

export const NOTIFICATION_ADD_DEVICE_TOKEN = 'NOTIFICATION_ADD_DEVICE_TOKEN';
export const notificationAddDeviceTokenSagaAction = createAction<feedSagaTypes.INotificationAddDeviceTokenProps>(
   NOTIFICATION_ADD_DEVICE_TOKEN
);

export const ADMIN_NOTIFICATION_COUNT = 'ADMIN_NOTIFICATION_COUNT';
export const adminNotificationCount = createAction<notificationSagaTypes.IAdminNotificationSagaTypes>(
   ADMIN_NOTIFICATION_COUNT
);

export const NOTIFICATION_DELETE_DEVICE_TOKEN = 'NOTIFICATION_DELETE_DEVICE_TOKEN';
export const notificationDeleteDeviceToken = createAction<notificationSagaTypes.INotificationDeleteDeviceTokenSaga>(
   NOTIFICATION_DELETE_DEVICE_TOKEN
);

export const NOTIFICATION_GET_ONE = 'NOTIFICATION_GET_ONE';
export const notificationGetOne = createAction<notificationSagaTypes.INotificationGetOneSaga>(NOTIFICATION_GET_ONE);
