import { createAction } from '@reduxjs/toolkit';
import { myProfileSagaTypes } from 'types';

export const ADD_GENETIC_TESTING = 'ADD_GENETIC_TESTING';
export const addGeneticTestingAction = createAction<myProfileSagaTypes.IGeneticTestingProps>(ADD_GENETIC_TESTING);

export const GET_MASTER_GENETIC_TESTING = 'GET_MASTER_GENETIC_TESTING';
export const getMasterGeneticTestingAction = createAction<myProfileSagaTypes.IMasterGeneticTestingProps>(
   GET_MASTER_GENETIC_TESTING
);

export const GET_GENETIC_TESTING_LIST = 'GET_GENETIC_TESTING_LIST';
export const getGeneticTestingListAction = createAction<myProfileSagaTypes.IGeneticTestingListProps>(
   GET_GENETIC_TESTING_LIST
);

export const GENETIC_TEST_CANCER_LIST = 'GENETIC_TEST_CANCER_LIST';
export const getGeneticTestCancerMasterListAction = createAction<myProfileSagaTypes.IGetPrimaryDisease>(
   GENETIC_TEST_CANCER_LIST
);

export const DELETE_GENETIC_TESTING = 'DELETE_GENETIC_TESTING';
export const deleteGeneticTestingAction = createAction<myProfileSagaTypes.IDeleteGeneticTestingProps>(
   DELETE_GENETIC_TESTING
);
