import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ONCLICK_READ } from 'reduxToolKit/actions';
import { commonSlice, notificationReadSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { notificationReadRequest, notificationReadSuccess, notificationReadFailed } = notificationReadSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const notificationRead = function* (cancerTypePayload: any) {
   yield put(notificationReadRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.notificationRead });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            data: responseData.data,
            message: response.data?.message,
         };
         yield put(notificationReadSuccess(oncDriveList));
      } else {
         yield put(notificationReadFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeNotificationRead = function* () {
   yield takeEvery(ONCLICK_READ, notificationRead);
};
