import { createAction } from '@reduxjs/toolkit';
import { postShareSagaTypes, networkSagaTypes } from 'types';

export const PENDING_REQUEST = 'PENDING_REQUEST';
export const pendingRequestListAction = createAction<postShareSagaTypes.IAskADocList>(PENDING_REQUEST);

export const INVITE_REQUEST = 'INVITE_REQUEST';
export const inviteRequestListAction = createAction<postShareSagaTypes.IAskADocList>(INVITE_REQUEST);

export const ACCEPT_CONNECTION = 'ACCEPT_CONNECTION';
export const acceptConnectionAction = createAction<postShareSagaTypes.IAcceptConnection>(ACCEPT_CONNECTION);

export const CONNECTION_REQUEST = 'CONNECTION_REQUEST';
export const connectionRequestAction = createAction<postShareSagaTypes.IConnectionRequest>(CONNECTION_REQUEST);

export const DELETE_MY_CONNECTION = 'DELETE_MY_CONNECTION';
export const deleteMyConnectionAction = createAction<networkSagaTypes.IDeleteMyConnectionsSagaPayload>(
   DELETE_MY_CONNECTION
);

export const DELETE_CONNECTION_REQUEST = 'DELETE_CONNECTION_REQUEST';
export const deleteConnectionRequest = createAction<networkSagaTypes.IDeleteMyConnectionRequestSagaPayload>(
   DELETE_CONNECTION_REQUEST
);

export const DELETE_CONNECTION_INVITE = 'DELETE_CONNECTION_INVITE';
export const deleteConnectionInvite = createAction<networkSagaTypes.IDeleteInviteRequestSagaPayload>(
   DELETE_CONNECTION_INVITE
);

export const GET_ALL_CONNECTIONS = 'GET_ALL_CONNECTIONS';
export const getAllConnections = createAction<networkSagaTypes.IGetMyConnectionsSagaPayload>(GET_ALL_CONNECTIONS);

export const SEARCH_NETWORK_ALL_USERS = 'SEARCH_NETWORK_ALL_USERS';
export const searchNetworkAllUsers = createAction<networkSagaTypes.ISearchNetworkAllUsersSagaPayload>(
   SEARCH_NETWORK_ALL_USERS
);
