import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { oncoKlinicReducerTypes, oncoKlinicSagatypes } from 'types';

const initialState: oncoKlinicReducerTypes.IOncKlinicReducer = {
   latestClinic: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   myClinicList: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   myAppointments: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   latestViewedClinic: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
};

export const oncoKlinicSlice = createSlice({
   name: 'oncoKlinic',
   initialState: initialState,
   reducers: {
      latestClinicRequest: (state) => {
         return {
            ...state,
            isLoading: true,
            latestClinic: {
               ...state.latestClinic,
               data: state.latestClinic?.data,
            },
         };
      },
      latestClinicSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            latestClinic: {
               ...state.latestClinic,
               data: payload.data,
               isLoading: false,
            },
         };
      },
      latestClinicFailed: (state, action: any) => {
         return {
            ...state,
            latestClinic: {
               ...state.latestClinic,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },

      latestClinicReset: (state) => {
         return {
            ...state,
            latestClinic: {
               isLoading: false,
               message: '',
               data: null,
               error: '',
            },
         };
      },

      myClinicRequest: (state, action: PayloadAction<oncoKlinicSagatypes.IMyClinicList>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      myClinicSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            myClinicList: {
               ...state.myClinicList,
               isLoading: false,
               message: payload.message,
               data: payload.data,
            },
         };
      },
      myClinicFailed: (state, action: any) => {
         return {
            ...state,
            myClinicList: {
               ...state.myClinicList,
               isLoading: false,
               error: action.payload.error,
            },
         };
      },

      myClinicReset: (state) => {
         return {
            ...state,
            myClinicList: {
               isLoading: false,
               message: '',
               data: null,
               error: '',
            },
         };
      },

      myAppointmentRequest: (state, action: PayloadAction<oncoKlinicSagatypes.IMyClinicList>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      myAppointmentSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            myAppointments: {
               ...state.myAppointments,
               isLoading: false,
               message: payload.message,
               data: payload.data,
            },
         };
      },
      myAppointmentFailed: (state, action: any) => {
         return {
            ...state,
            myAppointments: {
               ...state.myAppointments,
               isLoading: false,
               error: action.payload.error,
            },
         };
      },

      myAppointmentReset: (state) => {
         return {
            ...state,
            myAppointments: {
               isLoading: false,
               message: '',
               data: null,
               error: '',
            },
         };
      },

      latestViewedClinicRequest: (state, action: PayloadAction<oncoKlinicSagatypes.IAddLatestViewedClinic>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      latestViewedClinicSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            latestViewedClinic: {
               ...state.latestViewedClinic,
               isLoading: false,
               message: payload.message,
               data: payload.data,
            },
         };
      },
      latestViewedClinicFailed: (state, action: any) => {
         return {
            ...state,
            latestViewedClinic: {
               ...state.latestViewedClinic,
               isLoading: false,
               error: action.payload.error,
            },
         };
      },

      latestViewedClinicReset: (state) => {
         return {
            ...state,
            latestViewedClinic: {
               isLoading: false,
               message: '',
               data: null,
               error: '',
            },
         };
      },
   },
});
