import { AxiosResponse } from 'axios';
import { delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ONCDRIVE_LIST_SAGA, ONCDRIVE_SHARED_LIST_SAGA, ONCDRIVE_SHARED_USERS_LIST_SAGA } from 'reduxToolKit/actions';
import {
   commonSlice,
   oncDriveListSlice,
   oncDriveSharedListSlice,
   oncDriveSharedUserListSlice,
} from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { oncDriveListViewRequest, oncDriveListtViewSuccess, oncDriveListViewFailed } = oncDriveListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const oncDriveListSaga = function* (oncDriveListPayload: any) {
   yield put(oncDriveListViewRequest(oncDriveListPayload.payload));
   try {
      const params = oncDriveListPayload.payload;
      let url = API.oncDriveListHomeDirectory;
      if (params.parent_id) {
         url = {
            apiPath: `${API.oncDriveListSubDirAndFiles.apiPath}${params.parent_id}`,
            action: API.oncDriveListSubDirAndFiles.action,
         };
      }
      const response: AxiosResponse = yield apiCall({ params, ...url });
      if (response.status === 200) {
         let responseData = response.data.data;
         let breadcrumbList = [];
         if (params.parent_id) {
            breadcrumbList = responseData.breadcrumb;
            // reverse the breadcrumb list
            breadcrumbList = breadcrumbList.reverse();
            responseData = responseData.sub_directory_and_files;
         }
         const oncDriveList = {
            list: responseData.data,
            breadcrumbList,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message,
         };
         yield put(oncDriveListtViewSuccess(oncDriveList));
      } else {
         yield put(oncDriveListViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   oncDriveSharedListViewRequest,
   oncDriveSharedListtViewSuccess,
   oncDriveSharedListViewFailed,
} = oncDriveSharedListSlice.actions;

const oncDriveSharedListSaga = function* (oncDriveListPayload: any) {
   yield put(oncDriveSharedListViewRequest(oncDriveListPayload.payload));
   try {
      const params = oncDriveListPayload.payload;
      const url = API.oncDriveSharedWithMeList;
      const response: AxiosResponse = yield apiCall({ params, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const sharedWithMeList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message,
         };
         yield put(oncDriveSharedListtViewSuccess(sharedWithMeList));
      } else {
         yield put(oncDriveSharedListViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   oncDriveSharedUserListViewLoading,
   oncDriveSharedUserListViewRequest,
   oncDriveSharedUserListViewSuccess,
   oncDriveSharedUserListViewFailed,
} = oncDriveSharedUserListSlice.actions;

const oncDriveSharedUsersListSaga = function* (oncDriveSharedUsersListPayload: any) {
   if (oncDriveSharedUsersListPayload.payload.search_word !== '') {
      yield put(oncDriveSharedUserListViewLoading());
      yield delay(500);
   }
   yield put(oncDriveSharedUserListViewRequest(oncDriveSharedUsersListPayload.payload));
   try {
      const params = oncDriveSharedUsersListPayload.payload;
      const url = API.oncDriveSharedUsersList;
      const response: AxiosResponse = yield apiCall({ params, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const sharedUsersList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message,
         };
         yield put(oncDriveSharedUserListViewSuccess(sharedUsersList));
      } else {
         yield put(oncDriveSharedUserListViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeOncDriveListRequest = function* () {
   yield takeEvery(ONCDRIVE_LIST_SAGA, oncDriveListSaga);
   yield takeEvery(ONCDRIVE_SHARED_LIST_SAGA, oncDriveSharedListSaga);
   yield takeLatest(ONCDRIVE_SHARED_USERS_LIST_SAGA, oncDriveSharedUsersListSaga);
};
