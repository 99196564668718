import { createSlice } from '@reduxjs/toolkit';
import { joinVideoCallReducerTypes, joinVideoCallSagaTypes } from 'types';

const initialState: joinVideoCallReducerTypes.IVideoCallReducer = {
   videoCallUpdate: null,
   isLoading: false,
   error: '',
   message: '',
};
const videoCallUpdateSlice = createSlice({
   name: 'videoCallUpdate',
   initialState: initialState,
   reducers: {
      updateVideoCall: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      updateVideoCallSuccess: (state, action: joinVideoCallSagaTypes.IGetVideoAppointments) => {
         return {
            ...state,
            videoCallUpdate: action.payload,
            isLoading: false,
         };
      },
      updateVideoCallFailed: (state, action) => {
         return {
            ...state,
            videoCallUpdate: null,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
   },
});
export { videoCallUpdateSlice };
