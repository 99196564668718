import { createSlice } from '@reduxjs/toolkit';
import { medicationSavingReducerTypes } from 'types';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';

const initialState: medicationSavingReducerTypes.IMedicationSavingReducer = {
   medicationSavingList: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      current_page: 1,
      total_records: 0,
   },
   medicationSavingDetail: {
      isLoading: false,
      error: '',
      message: '',
      data: null,
   },
};
const medicationSavingSlice = createSlice({
   name: 'medicationSaving',
   initialState: initialState,
   reducers: {
      medicationSavingListLoading: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            medicationSavingList: {
               isLoading: true,
               message: '',
               error: '',
               list: payload?.page === 1 ? [] : listParseAndStringify(state.medicationSavingList.list),
               current_page: payload?.page === 1 ? 1 : listParseAndStringify(state.medicationSavingList.current_page),
               total_records: payload?.page === 1 ? 0 : listParseAndStringify(state.medicationSavingList.total_records),
            },
         };
      },
      medicationSavingListRequest: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            ...action.payload,
            medicationSavingList: {
               isLoading: true,
               message: '',
               error: '',
               list: payload?.page === 1 ? [] : listParseAndStringify(state.medicationSavingList.list),
               current_page: payload?.page === 1 ? 1 : listParseAndStringify(state.medicationSavingList.current_page),
               total_records: payload?.page === 1 ? 0 : listParseAndStringify(state.medicationSavingList.total_records),
            },
         };
      },
      medicationSavingListSuccess: (state, action: any) => {
         const { payload } = action;
         const stateList = listParseAndStringify(state.medicationSavingList.list);
         const payloadList = listParseAndStringify(payload.list);
         return {
            ...state,
            medicationSavingList: {
               isLoading: false,
               message: payload.message,
               error: '',
               list:
                  payload?.current_page === 1
                     ? listParseAndStringify(payloadList)
                     : listParseAndStringify([...stateList, ...payloadList]),
               current_page: payload.current_page,
               total_records: payload.total_records,
            },
         };
      },
      medicationSavingListFailed: (state, action) => {
         return {
            ...state,
            medicationSavingList: {
               list: [],
               isLoading: false,
               message: '',
               error: action.payload,
               current_page: 1,
               total_records: 0,
            },
         };
      },
      medicationSavingListReset: (state) => {
         return { ...state, medicationSavingList: initialState.medicationSavingList };
      },
      medicationSavingDetailRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            medicationSavingDetail: {
               isLoading: true,
               message: '',
               error: '',
               data: [],
            },
         };
      },
      medicationSavingDetailSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            medicationSavingDetail: {
               isLoading: false,
               message: payload.message,
               error: '',
               data: payload.data,
            },
         };
      },
      medicationSavingDetailFailed: (state, action) => {
         return {
            ...state,
            medicationSavingDetail: {
               data: [],
               isLoading: false,
               message: '',
               error: action.payload,
            },
         };
      },
      medicationSavingDetailReset: (state) => {
         return { ...state, medicationSavingDetail: initialState.medicationSavingDetail };
      },
   },
});
export { medicationSavingSlice };
