import { createSlice } from '@reduxjs/toolkit';
import { globalSearchReducerTypes } from 'types';

const initPagination = {
   current_page: null,
   last_page: null,
   first_page_url: '',
   last_page_url: '',
   next_page_url: '',
   prev_page_url: '',
   total: 0,
};

const initialStateGlobalSearchList: globalSearchReducerTypes.IGlobalSearchListReducer = {
   isLoading: false,
   users: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      pagination: initPagination,
   },
   patient: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      pagination: initPagination,
   },
   physician: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      pagination: initPagination,
   },
   patientAdvocate: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      pagination: initPagination,
   },
   patientCaregiver: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      pagination: initPagination,
   },
   organization: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      pagination: initPagination,
   },
   drugLookup: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      pagination: initPagination,
   },
   posts: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      pagination: initPagination,
   },
   videoLibrary: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      pagination: initPagination,
   },
};

export const globalSearchListSlice = createSlice({
   name: 'globalSearchList',
   initialState: initialStateGlobalSearchList,
   reducers: {
      globalSearchListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
            users: {
               ...state.users,
               isLoading: true,
            },
            patient: {
               ...state.patient,
               isLoading: true,
            },
            physician: {
               ...state.physician,
               isLoading: true,
            },
            patientAdvocate: {
               ...state.patientAdvocate,
               isLoading: true,
            },
            patientCaregiver: {
               ...state.patientCaregiver,
               isLoading: true,
            },
            organization: {
               ...state.organization,
               isLoading: true,
            },
            drugLookup: {
               ...state.drugLookup,
               isLoading: true,
            },
            posts: {
               ...state.posts,
               isLoading: true,
            },
            videoLibrary: {
               ...state.videoLibrary,
               isLoading: true,
            },
         };
      },
      globalSearchListRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            users: {
               isLoading: true,
               pagination: state.users.pagination,
               list: action?.payload?.page === 1 ? [] : state.users.list,
            },
            patient: {
               isLoading: true,
               pagination: state.patient.pagination,
               list: action?.payload?.page === 1 ? [] : state.patient.list,
            },
            physician: {
               isLoading: true,
               pagination: state.physician.pagination,
               list: action?.payload?.page === 1 ? [] : state.physician.list,
            },
            patientAdvocate: {
               isLoading: true,
               pagination: state.patientAdvocate.pagination,
               list: action?.payload?.page === 1 ? [] : state.patientAdvocate.list,
            },
            patientCaregiver: {
               isLoading: true,
               pagination: state.patientCaregiver.pagination,
               list: action?.payload?.page === 1 ? [] : state.patientCaregiver.list,
            },
            organization: {
               isLoading: true,
               pagination: state.organization.pagination,
               list: action?.payload?.page === 1 ? [] : state.organization.list,
            },
            drugLookup: {
               isLoading: true,
               pagination: state.drugLookup.pagination,
               list: action?.payload?.page === 1 ? [] : state.drugLookup.list,
            },
            posts: {
               isLoading: true,
               pagination: state.posts.pagination,
               list: action?.payload?.page === 1 ? [] : state.posts.list,
            },
            videoLibrary: {
               isLoading: true,
               pagination: state.videoLibrary.pagination,
               list: action?.payload?.page === 1 ? [] : state.videoLibrary.list,
            },
         };
      },
      globalSearchListSuccess: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            users: {
               isLoading: false,
               pagination: { ...payload.users.pagination },
               list:
                  payload.users.pagination.current_page === 1
                     ? payload.users.list
                     : [...state.users.list, ...payload.users.list],
            },
            patient: {
               isLoading: false,
               pagination: { ...payload.patient.pagination },
               list:
                  payload.patient.pagination.current_page === 1
                     ? payload.patient.list
                     : [...state.patient.list, ...payload.patient.list],
            },
            physician: {
               isLoading: false,
               pagination: { ...payload.physician.pagination },
               list:
                  payload.physician.pagination.current_page === 1
                     ? payload.physician.list
                     : [...state.physician.list, ...payload.physician.list],
            },
            patientAdvocate: {
               isLoading: false,
               pagination: { ...payload.patientAdvocate.pagination },
               list:
                  payload.patientAdvocate.pagination.current_page === 1
                     ? payload.patientAdvocate.list
                     : [...state.patientAdvocate.list, ...payload.patientAdvocate.list],
            },
            patientCaregiver: {
               isLoading: false,
               pagination: { ...payload.patientCaregiver.pagination },
               list:
                  payload.patientCaregiver.pagination.current_page === 1
                     ? payload.patientCaregiver.list
                     : [...state.patientCaregiver.list, ...payload.patientCaregiver.list],
            },
            organization: {
               isLoading: false,
               pagination: { ...payload.organization.pagination },
               list:
                  payload.organization.pagination.current_page === 1
                     ? payload.organization.list
                     : [...state.organization.list, ...payload.organization.list],
            },
            drugLookup: {
               isLoading: false,
               pagination: { ...payload.drugLookup.pagination },
               list:
                  payload.drugLookup.pagination.current_page === 1
                     ? payload.drugLookup.list
                     : [...state.drugLookup.list, ...payload.drugLookup.list],
            },
            posts: {
               isLoading: false,
               pagination: { ...payload.posts.pagination },
               list:
                  payload.posts.pagination.current_page === 1
                     ? payload.posts.list
                     : [...state.posts.list, ...payload.posts.list],
            },
            videoLibrary: {
               isLoading: false,
               pagination: { ...payload.videoLibrary.pagination },
               list:
                  payload.videoLibrary.pagination.current_page === 1
                     ? payload.videoLibrary.list
                     : [...state.videoLibrary.list, ...payload.videoLibrary.list],
            },
         };
      },
      globalSearchListFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            users: {
               ...state.users,
               isLoading: false,
            },
            patient: {
               ...state.patient,
               isLoading: false,
            },
            physician: {
               ...state.physician,
               isLoading: false,
            },
            patientAdvocate: {
               ...state.patientAdvocate,
               isLoading: false,
            },
            patientCaregiver: {
               ...state.patientCaregiver,
               isLoading: false,
            },
            organization: {
               ...state.organization,
               isLoading: false,
            },
            drugLookup: {
               ...state.drugLookup,
               isLoading: false,
            },
            posts: {
               ...state.posts,
               isLoading: false,
            },
            videoLibrary: {
               ...state.videoLibrary,
               isLoading: false,
            },
            message: '',
            error: action.payload.error,
         };
      },
      globalSearchListReset: () => {
         return {
            ...initialStateGlobalSearchList,
         };
      },
   },
});
