import { createAction } from '@reduxjs/toolkit';
import { caseDetailsReducerTypes } from 'types';

export const GET_ONE_ASK_DOC_FEED_REQUEST = 'GET_ONE_ASK_DOC_FEED_REQUEST';
export const getAskADocCaseDetailsSagaAction = createAction<caseDetailsReducerTypes.IGetOneAskADocId>(
   GET_ONE_ASK_DOC_FEED_REQUEST
);

export const ASK_A_DOC_ADD_ESTIMATE = 'ASK_A_DOC_ADD_ESTIMATE';
export const askADocAddEstimateSagaAction = createAction<caseDetailsReducerTypes.ICaseDetailsAddEstimate>(
   ASK_A_DOC_ADD_ESTIMATE
);

export const ASK_A_DOC_CANCEL_ESTIMATE = 'ASK_A_DOC_CANCEL_ESTIMATE';
export const askADocCancelEstimateSagaAction = createAction<caseDetailsReducerTypes.ICaseDetailsCancelEstimate>(
   ASK_A_DOC_CANCEL_ESTIMATE
);

export const REJECT_ASKADOC_REQUEST_CASE_DETAILS = 'REJECT_ASKADOC_REQUEST_CASE_DETAILS';
export const rejectAskADocRequestCaseDetails = createAction<caseDetailsReducerTypes.IGetOneAskADocId>(
   REJECT_ASKADOC_REQUEST_CASE_DETAILS
);

export const ASK_A_DOC_ADD_ESTIMATE_PAYMENT = 'ASK_A_DOC_ADD_ESTIMATE_PAYMENT';
export const askADocAddEstimatePaymentSagaAction = createAction<caseDetailsReducerTypes.ICaseDetailsAddEstimatePayment>(
   ASK_A_DOC_ADD_ESTIMATE_PAYMENT
);

export const ASK_A_DOC_ESTIMATE_END_CHAT = 'ASK_A_DOC_ESTIMATE_END_CHAT';
export const askADocEstimateEndChatSagaAction = createAction<caseDetailsReducerTypes.ICaseDetailsCancelEstimate>(
   ASK_A_DOC_ESTIMATE_END_CHAT
);

export const PHYSICIAN_INFO = 'PHYSICIAN_INFO';
export const physicianInfoAction = createAction<caseDetailsReducerTypes.IGetOneAskADocId>(PHYSICIAN_INFO);

export const PICK_ASK_A_DOC_REQUEST = 'PICK_ASK_A_DOC_REQUEST';
export const pickAskADocRequest = createAction<caseDetailsReducerTypes.IGetOneAskADocId>(PICK_ASK_A_DOC_REQUEST);

export const ASK_A_DOC_END_CONVERSATION = 'ASK_A_DOC_END_CONVERSATION';
export const askADocEndConversationRequest = createAction<caseDetailsReducerTypes.IAskADocEndConversation>(
   ASK_A_DOC_END_CONVERSATION
);

export const ASK_A_DOC_CONVERSATION_ADD_QUESTION = 'ASK_A_DOC_CONVERSATION_ADD_QUESTION';
export const askADocConversationAddQuestion = createAction<caseDetailsReducerTypes.IAskADocConversationAddQuestion>(
   ASK_A_DOC_CONVERSATION_ADD_QUESTION
);

export const ASK_A_DOC_CHANGE_CLINIC = 'ASK_A_DOC_CHANGE_CLINIC';
export const askADocChangeClinicSagaAction = createAction<caseDetailsReducerTypes.IAskADocChangeClinicSagaRequest>(
   ASK_A_DOC_CHANGE_CLINIC
);

export const ASK_A_DOC_CASE_HISTORY = 'ASK_A_DOC_CASE_HISTORY';
export const askADocCaseHistorySagaAction = createAction<caseDetailsReducerTypes.IAskADocCaseHistorySagaRequest>(
   ASK_A_DOC_CASE_HISTORY
);
