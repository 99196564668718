import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_SCHEDULE_PHYSICIAN } from 'reduxToolKit/actions';
import { commonSlice, getScheduledPhysicianSlice } from 'reduxToolKit/reducers';
import { appointmentSchedulePageTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getScheduledPhysicianRequest,
   getScheduledPhysicianSuccess,
   getScheduledPhysicianFailed,
} = getScheduledPhysicianSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getScheduledPhysicianList = function* (
   comment: PayloadAction<appointmentSchedulePageTypes.IGetSChedulePhysicianInputProps>
) {
   yield put(getScheduledPhysicianRequest(comment.payload));
   try {
      const params = comment.payload;
      const response: AxiosResponse = yield apiCall({ params, ...API.getScheduledPhysiciansList });
      if (response.status === 200) {
         const responseData = response.data.data;
         const scheduledPhysicianList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message || messages.success,
         };
         yield put(getScheduledPhysicianSuccess(scheduledPhysicianList));
      } else {
         yield put(getScheduledPhysicianFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeGetSchedulePhysiciansList = function* () {
   yield takeEvery(GET_SCHEDULE_PHYSICIAN, getScheduledPhysicianList);
};
