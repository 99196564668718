import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { UPDATE_CAREGIVER } from 'reduxToolKit/actions';
import { commonSlice, updateCaregiverSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { updateCaregiverRequest, updateCaregiverSuccess, updateCaregiverFailed } = updateCaregiverSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const updateCaregiverSaga = function* (updateCaregiverPayload: any) {
   yield put(updateCaregiverRequest(updateCaregiverPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = updateCaregiverPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.updateCaregiver });
      if (response.status === 200) {
         const responseData = response.data;
         const updateCaregiverList = {
            message: responseData?.message || '',
         };
         yield put(updateCaregiverSuccess(updateCaregiverList));
      } else {
         yield put(updateCaregiverFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeupdateCaregiverRequest = function* () {
   yield takeEvery(UPDATE_CAREGIVER, updateCaregiverSaga);
};
