import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { EDIT_PRESCRIPTION } from 'reduxToolKit/actions';
import { commonSlice, rpmPrescriptionEditSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   rpmPrescriptionEditRequest,
   rpmPrescriptionEditSuccess,
   rpmPrescriptionEditFailed,
} = rpmPrescriptionEditSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const rpmPrescriptionEditSaga = function* (prescriptionEditTypePayload: any) {
   yield put(rpmPrescriptionEditRequest(prescriptionEditTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = prescriptionEditTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.editPrescription });
      if (response.status === 200) {
         const responseData = response.data;
         const prescriptionEditList = {
            message: responseData?.message || '',
         };
         yield put(rpmPrescriptionEditSuccess(prescriptionEditList));
      } else {
         yield put(rpmPrescriptionEditFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takePrescriptionEditRequest = function* () {
   yield takeEvery(EDIT_PRESCRIPTION, rpmPrescriptionEditSaga);
};
