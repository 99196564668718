import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clinicAdminReducerTypes, clinicAdminSagaTypes } from 'types';

const initialState: clinicAdminReducerTypes.IExistingClinicInviteReducerPayload = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
export const isExistingClinicInviteSlice = createSlice({
   name: 'isExistingClinicInvite',
   initialState: initialState,
   reducers: {
      isExistingClinicInviteRequest: (state, action: PayloadAction<clinicAdminSagaTypes.IExistingClinicInviteSaga>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      isExistingClinicInviteSuccess: (
         state,
         action: PayloadAction<clinicAdminReducerTypes.IExistingClinicInviteSuccessPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      isExistingClinicInviteFailed: (
         state,
         action: PayloadAction<clinicAdminReducerTypes.IExistingClinicInviteFailurePayload>
      ) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      isExistingClinicInviteReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
