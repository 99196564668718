import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import React, { useEffect, useState, useCallback } from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import BirthDayIcon from 'assets/fill-profile/birthday.png';
import Constant from 'config/Constant';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { dobSectionSagaAction } from 'reduxToolKit/actions';
import { dobSectionPostSlice } from 'reduxToolKit/reducers';
import { DatePicker } from 'rsuite';
import { storeTypes } from 'types';
import { DONE, NEXT } from 'utils/constants/profileConstants';
import { changeDateToDB, isBefore100YearsFilterDate } from 'utils/helpers/dateHelpers';
import * as Yup from 'yup';

const schema = Yup.object().shape({
   dateOfBirth: Yup.date().nullable().required('Date of birth is required'),
   gender: Yup.string().required('Gender is required'),
});

export default function DobSection(props: any) {
   const dispatch = useDispatch();
   const { message, errorMessage } = useSelector((state: storeTypes.IStore) => state.profileDobSection);
   const { dobSectionReset } = dobSectionPostSlice.actions;
   const [error, setError] = useState('');

   const {
      setIsLoader,
      currentModalClose,
      finalMessage,
      isFromClinicalTrialsHome,
      isFromClinicalTrials,
      navigation,
      nexSection,
      dobValid,
      isFromClinicalTrialsList,
      currentStateArray,
      currentState,
   } = props;

   useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
         document.body.style.overflow = 'scroll';
      };
   }, []);

   const handleSubmit = (values: any) => {
      setIsLoader(true);
      const postDobData = {
         input_type: 'others',
         date_of_birth: changeDateToDB(values.dateOfBirth),
         gender: values.gender,
      };
      dispatch(dobSectionSagaAction(postDobData));
   };

   useEffect(() => {
      if (message) {
         setIsLoader(false);

         if (isFromClinicalTrialsHome) {
            currentModalClose();
            setIsLoader(false);
            finalMessage();
         } else {
            gotoNavigation();
         }
         setIsLoader(false);
         dispatch(dobSectionReset());
      } else if (errorMessage) {
         setError(errorMessage);
         setIsLoader(false);
         dispatch(dobSectionReset());
      }
   }, [message, errorMessage]);

   const currentModelCloseAndFinal = () => {
      currentModalClose();
      setIsLoader(false);
      finalMessage();
   };

   const gotoNavigation = useCallback(() => {
      if (isFromClinicalTrials) {
         currentModelCloseAndFinal();
      } else if (!isFromClinicalTrials) {
         navigation(nexSection);
      }
   }, []);

   useEffect(() => {
      if (dobValid) {
         if (isFromClinicalTrialsList) {
            currentModelCloseAndFinal();
         } else {
            gotoNavigation();
         }
      }
   }, []);

   return (
      <Formik
         initialValues={{ dateOfBirth: null as any, gender: '' }}
         onSubmit={(values) => {
            handleSubmit(values);
         }}
         validationSchema={schema}
      >
         {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => {
            return (
               <form onSubmit={handleSubmit}>
                  <div>
                     {isFromClinicalTrials ? (
                        <div className="col-12">
                           <p className="paragragh-text  p-2 pb-5">
                              Let's get started with your date of birth and gender so we can give you a list of matching
                              clinical studies
                           </p>
                        </div>
                     ) : (
                        <>
                           <div className="col-12">
                              <img className="birthday-icon mt--2" src={BirthDayIcon} alt="icon" />
                           </div>
                           <div className="col-12">
                              <p className="paragragh-text  p-2">Terrific, a few last details that help us help you.</p>
                           </div>
                        </>
                     )}
                     <div>
                        <div className="col-12 dob-css ">
                           <label htmlFor="title" className="dob-header">
                              Birth Date{' '}
                           </label>
                           <div className="semantic-ui-calender-pick today-btn-clear">
                              <DatePicker
                                 preventOverflow={true}
                                 ranges={[]}
                                 disabledDate={(date) => isBefore100YearsFilterDate(date)}
                                 block={true}
                                 size="lg"
                                 placement={'top'}
                                 format={Constant.DATE_FORMAT}
                                 placeholder={Constant.DATE_FORMAT}
                                 value={values.dateOfBirth}
                                 limitEndYear={0}
                                 oneTap={true}
                                 onChange={(date: Date) => {
                                    setFieldValue('dateOfBirth', date);
                                 }}
                              />
                              {errors.dateOfBirth && touched.dateOfBirth && (
                                 <div className="error-msg">{errors.dateOfBirth}</div>
                              )}
                           </div>
                        </div>
                     </div>
                     <div className="col-12 ">
                        <label htmlFor="title" className="gender-header">
                           Sex
                        </label>
                        <RadioGroup
                           aria-label="gender"
                           name="gender"
                           value={values.gender}
                           className="gender-group"
                           onChange={handleChange}
                        >
                           <FormControlLabel value="m" control={<Radio />} label="Male" />
                           <FormControlLabel value="f" control={<Radio />} label="Female" />
                           <FormControlLabel value="t" control={<Radio />} label="Prefer not to specify" />
                        </RadioGroup>
                        {errors.gender && touched.gender && <div className="error-msg">{errors.gender}</div>}
                     </div>
                     <span className="error-msg"> {error ? error : ''}</span>
                     <div className="button">
                        <button type="submit" className="button-start">
                           {currentStateArray && currentStateArray[currentStateArray?.length - 1] === currentState
                              ? DONE
                              : NEXT}
                        </button>
                     </div>
                  </div>
               </form>
            );
         }}
      </Formik>
   );
}
