import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { modalEducationReducerTypes, modalEducationTrainingSagaTypes } from 'types';

const initialState: modalEducationReducerTypes.IModalEducationTrainingReducer = {
   error: '',
   message: '',
   isLoading: false,
};

export const modalUpdateEducationTrainingSlice = createSlice({
   name: 'updateEducationTraining',
   initialState: initialState,
   reducers: {
      updateEducationTrainingRequest: (
         state,
         action: PayloadAction<modalEducationTrainingSagaTypes.IModalEducationTrainingSagaPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      updateEducationTrainingSuccess: (
         state,
         action: PayloadAction<modalEducationReducerTypes.IModalEducationTrainingSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      updateEducationTrainingFailed: (
         state,
         action: PayloadAction<modalEducationReducerTypes.IModalEducationTrainingFailedPayload>
      ) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      updateEducationTrainingReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
