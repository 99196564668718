import { createAction } from '@reduxjs/toolkit';
import {
   askADocDisclaimerReducerTypes,
   askADocPatientReducerTypes,
   askADocUserProfileReducerTypes,
   hippaComplianceReducerTypes,
   postShareSagaTypes,
   askDocSagaTypes,
   askADocPageTypes,
} from 'types';

export const ASK_A_DOC_LIST = 'ASK_A_DOC_LIST';
export const askADocListAction = createAction<postShareSagaTypes.IAskADocList>(ASK_A_DOC_LIST);

export const ASK_A_DOC_GET_PANELS = 'ASK_A_DOC_GET_PANELS';
export const getPanelsList = createAction<postShareSagaTypes.IGetPanels>(ASK_A_DOC_GET_PANELS);

export const ASK_A_DOC_GET_SUBSCRIPTIONS_PANELS = 'ASK_A_DOC_GET_SUBSCRIPTIONS_PANELS';
export const getAskADocSubscriptionPanelsList = createAction<askADocPageTypes.IAskADocClinicRequest>(
   ASK_A_DOC_GET_SUBSCRIPTIONS_PANELS
);

export const ASK_A_DOC_GET_PANELS_REQ = 'ASK_A_DOC_GET_PANELS_REQ';
export const getPanelsReq = createAction<postShareSagaTypes.IAskPhyReq>(ASK_A_DOC_GET_PANELS_REQ);

export const ASK_A_DOC_CREATE_REQUEST = 'ASK_A_DOC_CREATE_REQUEST';
export const createAskADocRequest = createAction<postShareSagaTypes.IAskADocRequestCreationType>(
   ASK_A_DOC_CREATE_REQUEST
);

export const ASK_A_DOC_GET_PANEL_PHY_LIST = 'ASK_A_DOC_GET_PANEL_PHY_LIST';
export const getPanelPhyList = createAction<postShareSagaTypes.IGetPanelsPhysicians>(ASK_A_DOC_GET_PANEL_PHY_LIST);

export const ASK_PHYSICIANS_LIST = 'ASK_PHYSICIANS_LIST';
export const askPhysiciansList = createAction<postShareSagaTypes.IAskPhyList>(ASK_PHYSICIANS_LIST);

export const ASK_PHYSICIANS_COUNT = 'ASK_PHYSICIANS_COUNT';
export const askPhysiciansCount = createAction<postShareSagaTypes.IPhyCount>(ASK_PHYSICIANS_COUNT);

export const ASK_A_DOC_FEED_CONNECTION_STATISTICS = 'ASK_A_DOC_FEED_CONNECTION_STATISTICS';
export const feedConnctionStatistics = createAction(ASK_A_DOC_FEED_CONNECTION_STATISTICS);

export const ASK_A_DOC_CREATE_REQUEST_72_HRS = 'ASK_A_DOC_CREATE_REQUEST_72_HRS';
export const askADocCreateRequest72HrsAction = createAction(ASK_A_DOC_CREATE_REQUEST_72_HRS);

export const ASK_A_DOC_GET_USER_PROFILE = 'ASK_A_DOC_GET_USER_PROFILE';
export const getUserProfileDispatch = createAction<askADocUserProfileReducerTypes.IAskADocGetUserProfile>(
   ASK_A_DOC_GET_USER_PROFILE
);

export const ASK_A_DOC_EXAMPLE_QUESTIONS = 'ASK_A_DOC_EXAMPLE_QUESTIONS';
export const getAskADocExampleQuestions = createAction(ASK_A_DOC_EXAMPLE_QUESTIONS);

export const ASK_A_DOC_PHY_PANEL_REQUESTS_LIST = 'ASK_A_DOC_PHY_PANEL_REQUESTS_LIST';
export const askADocPhyPanelRequestsList = createAction<askDocSagaTypes.IAskADocPhyPanelListRequestsSagaPayload>(
   ASK_A_DOC_PHY_PANEL_REQUESTS_LIST
);

// Hippa & Disclaimer Actions

export const ADD_HIPPA_COMPLIANCE = 'ADD_HIPPA_COMPLIANCE';
export const addHippaCompliance = createAction<hippaComplianceReducerTypes.IAddHippaCompliance>(ADD_HIPPA_COMPLIANCE);

export const GET_HIPPA_COMPLIANCE = 'GET_HIPPA_COMPLIANCE';
export const getHippaCompliance = createAction<hippaComplianceReducerTypes.IGetHippaCompliance>(GET_HIPPA_COMPLIANCE);

export const GET_PATIENT_STATE = 'GET_PATIENT_STATE';
export const getPatientState = createAction<askADocPatientReducerTypes.IAskADocPatientStateProps>(GET_PATIENT_STATE);

export const GET_PHYSICIAN_STATE = 'GET_PHYSICIAN_STATE';
export const getPhysicianState = createAction<askADocPatientReducerTypes.IAskADocPhysicianStateProps>(
   GET_PHYSICIAN_STATE
);

export const GET_DISCLAIMER = 'GET_DISCLAIMER';
export const getDisclaimerDispatch = createAction<askADocDisclaimerReducerTypes.IGetDisclaimer>(GET_DISCLAIMER);

export const ADD_DISCLAIMER = 'ADD_DISCLAIMER';
export const addDisclaimerDispatch = createAction<askADocDisclaimerReducerTypes.IGetDisclaimer>(ADD_DISCLAIMER);

export const ASK_A_DOC_PHY_PANEL_LIST = 'ASK_A_DOC_PHY_PANEL_LIST';
export const askADocPhyPanelList = createAction<askDocSagaTypes.IAskADocPhyPanelListRequestsSagaPayload>(
   ASK_A_DOC_PHY_PANEL_LIST
);

export const ASK_A_DOC_PANEL_PHYSICIAN_LIST = 'ASK_A_DOC_PANEL_PHYSICIAN_LIST';
export const askADocPanelPhysicianList = createAction<askDocSagaTypes.IAskADocPanelPhysicianListSagaPayload>(
   ASK_A_DOC_PANEL_PHYSICIAN_LIST
);

export const ASK_A_DOC_CHECK_REQUIRED_FIELDS = 'ASK_A_DOC_CHECK_REQUIRED_FIELDS';
export const askADocCaseListCheckRequiredFields = createAction<askDocSagaTypes.IAskADocCheckRequiredFields>(
   ASK_A_DOC_CHECK_REQUIRED_FIELDS
);

export const ASK_A_DOC_PRE_POPULATE_CREATE_REQUEST = 'ASK_A_DOC_PRE_POPULATE_CREATE_REQUEST';
export const askADocPrePopulateCreateRequestAction = createAction(ASK_A_DOC_PRE_POPULATE_CREATE_REQUEST);
