import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   APPOINTMENT_HANDLE,
   APPOINTMENT_ADD,
   GET_ONE_APPOINTMENT,
   UPDATE_APPOINTMENT_STATUS,
   MY_APPOINtMENT_MEETING,
} from 'reduxToolKit/actions';
import { appointmentScheduleSlice, commonSlice } from 'reduxToolKit/reducers';
import { appointmentScheduleSubmitSagaTypes, appointmentScheduleSubmitPageTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   PostAppointmentSchedule,
   PostAppointmentScheduleSuccess,
   PostAppointmentScheduleFailed,
   addAppointmentRequest,
   addAppointmentSuccess,
   addAppointmentFailed,
   getOneAppointmentRequest,
   getOneAppointmentSuccess,
   getOneAppointmentFailed,
   updateAppointmentStatusRequest,
   updateAppointmentStatusSuccess,
   updateAppointmentStatusFailed,
   myAppointmentsMeetingRequest,
   myAppointmentsMeetingSuccess,
   myAppointmentsMeetingFailed,
} = appointmentScheduleSlice.actions;

const { exceptionHandlerRequest } = commonSlice.actions;

const appointmentSchedule = function* (
   payloadAppointment: appointmentScheduleSubmitSagaTypes.IAppointmentScheduleSuccess
) {
   yield put(PostAppointmentSchedule(payloadAppointment.payload));
   try {
      const data = payloadAppointment.payload;
      const response: AxiosResponse = yield apiCall({ data, ...API.createAppointment });
      if (response.status === 200) {
         yield put(PostAppointmentScheduleSuccess(response.data));
      } else {
         yield put(PostAppointmentScheduleFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export { appointmentSchedule };

const appointmentAdd = function* (
   payloadAppointment: PayloadAction<appointmentScheduleSubmitPageTypes.IAddAppointmentInput>
) {
   yield put(addAppointmentRequest(payloadAppointment.payload));
   try {
      const goUrl = true;
      const data = payloadAppointment.payload;
      let url = API.addAppointment;
      if (data.type === 'reschedule') {
         url = API.rescheduleAppointment;
      }
      const response: AxiosResponse = yield apiCall({ data, goUrl, ...url });
      if (response.status === 200) {
         yield put(addAppointmentSuccess(response.data));
      } else {
         yield put(addAppointmentFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getOneAppointment = function* (
   payloadAppointment: PayloadAction<appointmentScheduleSubmitPageTypes.IGetOneAppointmentInput>
) {
   yield put(getOneAppointmentRequest(payloadAppointment.payload));
   try {
      const goUrl = true;
      const data = payloadAppointment.payload;
      const url = API.getOneAppointment;
      const response: AxiosResponse = yield apiCall({ data, goUrl, ...url });
      if (response.status === 200) {
         yield put(getOneAppointmentSuccess(response.data));
      } else {
         yield put(getOneAppointmentFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const updateAppointmentStatus = function* (
   payloadAppointment: PayloadAction<appointmentScheduleSubmitPageTypes.IUpdateAppointmentStatusInput>
) {
   yield put(updateAppointmentStatusRequest(payloadAppointment.payload));
   try {
      const goUrl = true;
      const data = payloadAppointment.payload;
      let url = API.updateAppointmentStatus;
      if (data.status === 3) {
         url = API.updateAppointmentStatusPatient;
      } else if (data.status === 2) {
         url = API.updateAppointmentStatus;
      }
      const response: AxiosResponse = yield apiCall({ data, goUrl, ...url });
      if (response.status === 200) {
         yield put(updateAppointmentStatusSuccess(response.data));
      } else {
         yield put(updateAppointmentStatusFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const myAppointmentsMeeting = function* (
   payloadAppointment: PayloadAction<appointmentScheduleSubmitPageTypes.IMyAppointmentMeetingInput>
) {
   yield put(myAppointmentsMeetingRequest(payloadAppointment.payload));
   try {
      const goUrl = true;
      const params = payloadAppointment.payload;
      const url = API.getMyAppointments;
      const response: AxiosResponse = yield apiCall({ params, goUrl, ...url });
      if (response.status === 200) {
         yield put(myAppointmentsMeetingSuccess(response.data));
      } else {
         yield put(myAppointmentsMeetingFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAppointmentScheduleRequest = function* () {
   yield takeEvery(APPOINTMENT_HANDLE, appointmentSchedule);
   yield takeEvery(APPOINTMENT_ADD, appointmentAdd);
   yield takeEvery(GET_ONE_APPOINTMENT, getOneAppointment);
   yield takeEvery(UPDATE_APPOINTMENT_STATUS, updateAppointmentStatus);
   yield takeEvery(MY_APPOINtMENT_MEETING, myAppointmentsMeeting);
};
