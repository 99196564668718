import Constant from 'config/Constant';

export default {
   ASK_DOC_PATIENT_DISCLAIMER:
      '* The responses provided on ' +
      Constant.AskADoc +
      ' are for informational purposes only. These interactions do not constitute a patient-physician relationship and are not to be taken as specific treatment advice.',
   ASK_DOC_PHYSICIAN_DISCLAIMER:
      '* Your responses will not constitute a formal patient-physician relationship. Patients will have acknowledged that the responses shared on ' +
      Constant.AskADoc +
      ' are not to be taken as specific medical advice. Your ' +
      Constant.AskADoc +
      ' interactions are covered under the Witty Health malpractice insurance coverage.',
   ASK_DOC_AGREE:
      'Please note ' +
      Constant.AskADoc +
      ' is a free service to benefit several patients fighting cancer. Often, patients learn from other patients questions. All responses under ' +
      Constant.AskADoc +
      ' are visible to other patients unless the physician chooses to send it to you privately. To protect your privacy, you may choose not to disclose any identifying information like your real name, date of birth etc while asking a question. You can also select a display name in your profile.',
   ONCOKLINIC_SYMPTOM_HIDE: 'Please approve the consent agreement to enable Symptoms Monitoring.',
   ONCOKLINIC_TELE_MEDICINE_HIDE: 'Please approve the consent agreement to enable Telemedicine.',
   ONCOKLINIC_IMAGE_VIDEO_CONSENT_HIDE: 'Please approve the consent agreement to enable Image/Video Consult.',
   DISPLAY_DETAILS:
      'This will be used instead of your real name in the public areas of OncoPower. Some content in the Networking, News Feed, and Ask-a-Doc features are visible to other members of OncoPower. Therefore, it is common for members to use a nickname instead of their real name for privacy reasons. Your real name will only be used in the OncKlinic and Second Opinion sections, since they are a private and direct interaction with a provider.',
   ASK_A_DOC_CREATE_CONFIRMATION: 'You want to submit this request?',
};
