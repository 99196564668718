import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { ONC_CLINIC_PRC_ADMIN_IS_EXISTING_CLINIC_INVITE } from 'reduxToolKit/actions';
import { isExistingClinicInviteSlice, commonSlice } from 'reduxToolKit/reducers';
import { clinicAdminSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   isExistingClinicInviteRequest,
   isExistingClinicInviteSuccess,
   isExistingClinicInviteFailed,
} = isExistingClinicInviteSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const existingClinicInvite = function* (payloadState: PayloadAction<clinicAdminSagaTypes.IExistingClinicInviteSaga>) {
   yield put(isExistingClinicInviteRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.existingClinicInviteReq });
      if (response.status === 200) {
         const resObj = {
            data: response?.data?.data,
            message: messages.success,
         };
         yield put(isExistingClinicInviteSuccess(resObj));
      } else {
         yield put(isExistingClinicInviteFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeExistingClinicInviteSaga = function* () {
   yield takeLatest(ONC_CLINIC_PRC_ADMIN_IS_EXISTING_CLINIC_INVITE, existingClinicInvite);
};
