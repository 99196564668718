import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { ASK_PHYSICIANS_LIST } from 'reduxToolKit/actions';
import { askPhyListSlice, commonSlice } from 'reduxToolKit/reducers';
import { postShareSagaTypes } from 'types';
import { OTHER_PHY_ANSWERED_REQUEST } from 'utils/constants/askADoc';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { askPhyListLoading, askPhyListViewRequest, askPhyListViewSuccess, askPhyListFailed } = askPhyListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const askPhyListSaga = function* (payloadState: PayloadAction<postShareSagaTypes.IAskPhyList>) {
   yield put(askPhyListLoading());
   yield delay(500);
   yield put(askPhyListViewRequest(payloadState.payload));
   try {
      const params = payloadState.payload;
      let checkUser = true;
      let goUrl = true;
      let apiUrl = API.getAskPhysician;
      if (payloadState.payload.ask_a_doc_type === OTHER_PHY_ANSWERED_REQUEST) {
         checkUser = false;
         goUrl = false;
         apiUrl = API.getAskOtherPhyAnsweredList;
      }
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...apiUrl });
      if (response.status === 200) {
         const responseData = response?.data;
         let oncDriveList = {};
         if (payloadState.payload.ask_a_doc_type === OTHER_PHY_ANSWERED_REQUEST) {
            oncDriveList = {
               list: responseData?.data?.data,
               total_records: responseData?.data?.total,
               message: responseData?.data?.message || messages.success,
            };
         } else {
            oncDriveList = {
               list: responseData?.data?.list,
               total_records: responseData?.data?.total_records,
               message: responseData?.data?.message || messages.success,
            };
         }
         yield put(askPhyListViewSuccess(oncDriveList));
      } else {
         yield put(askPhyListFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAskPhyList = function* () {
   yield takeLatest(ASK_PHYSICIANS_LIST, askPhyListSaga);
};
