import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MODAL_DEVICE_ADD_CLINIC_NOTE_SAGA, MODAL_DEVICE_EDIT_CLINIC_NOTE_SAGA } from 'reduxToolKit/actions';
import { commonSlice, deviceClinicNotesCommentsSlice } from 'reduxToolKit/reducers';
import { deviceClinicNotesSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   modalDeviceAddClinicNoteRequest,
   modalDeviceEditClinicNoteRequest,
   addEditclinicNotecommentSuccess,
   addEditclinicNotecommentFailed,
} = deviceClinicNotesCommentsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const modalDeviceEditClinicNoteSaga = function* (
   deviceEditPayload: PayloadAction<deviceClinicNotesSagaTypes.IDeviceClinicEditNoteSagaPayload>
) {
   yield put(modalDeviceEditClinicNoteRequest(deviceEditPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = deviceEditPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.deviceEditClinicNote });
      if (response.status === 200) {
         const responseData = response.data;
         const deviceEditList = {
            message: responseData?.message || messages.success,
         };
         yield put(addEditclinicNotecommentSuccess(deviceEditList));
      } else {
         yield put(addEditclinicNotecommentFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const modalDeviceAddClinicNoteSaga = function* (
   deviceAddPayload: PayloadAction<deviceClinicNotesSagaTypes.IDeviceClinicAddNoteSagaPayload>
) {
   yield put(modalDeviceAddClinicNoteRequest(deviceAddPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = deviceAddPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.deviceAddClinicNote });
      if (response.status === 200) {
         const responseData = response.data;
         const deviceAddList = {
            message: responseData?.message || messages.success,
         };
         yield put(addEditclinicNotecommentSuccess(deviceAddList));
      } else {
         yield put(addEditclinicNotecommentFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeDeviceClinicNoteRequest = function* () {
   yield takeEvery(MODAL_DEVICE_ADD_CLINIC_NOTE_SAGA, modalDeviceAddClinicNoteSaga);
   yield takeEvery(MODAL_DEVICE_EDIT_CLINIC_NOTE_SAGA, modalDeviceEditClinicNoteSaga);
};
