import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { TREATMENT_METHOD_ADD, TREATMENT_METHOD_LOG_LIST } from 'reduxToolKit/actions';
import { commonSlice, treatmentMethodSlice } from 'reduxToolKit/reducers';
import { ITreatmentMethodLogListRequestParams, ITreatmentMethodRequestParams } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   treatmentMethodAddRequest,
   treatmentMethodAddSuccess,
   treatmentMethodAddFailed,
   treatmentMethodLogListRequest,
   treatmentMethodLogListSuccess,
   treatmentMethodLogListFailed,
} = treatmentMethodSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const treatmentMethodAddSaga = function* (consentTypePayload: PayloadAction<ITreatmentMethodRequestParams>) {
   yield put(treatmentMethodAddRequest(consentTypePayload.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const goUrl = false;
      const data = consentTypePayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         peopleMayYouKnow,
         goUrl,
         data,
         ...API.treatmentMethodAdd,
      });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(treatmentMethodAddSuccess(responseData));
      } else {
         yield put(treatmentMethodAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const treatmentMethodLogListSaga = function* (consentTypePayload: PayloadAction<ITreatmentMethodLogListRequestParams>) {
   yield put(treatmentMethodLogListRequest(consentTypePayload.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const goUrl = false;
      const params = consentTypePayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         goUrl,
         peopleMayYouKnow,
         params,
         ...API.treatmentMethodLogList,
      });
      if (response.status === 200) {
         const responseData = response.data.data;
         yield put(treatmentMethodLogListSuccess(responseData));
      } else {
         yield put(treatmentMethodLogListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeTreatmentMethod = function* () {
   yield takeEvery(TREATMENT_METHOD_ADD, treatmentMethodAddSaga);
   yield takeEvery(TREATMENT_METHOD_LOG_LIST, treatmentMethodLogListSaga);
};
