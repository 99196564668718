import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ASK_A_DOC_GET_USER_PROFILE } from 'reduxToolKit/actions';
import { commonSlice, getUserProfileSlice } from 'reduxToolKit/reducers';
import { askADocUserProfileReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getUserProfileRequest, getUserProfileSuccess, getUserProfileFailed } = getUserProfileSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getUserProfileSaga = function* (
   payloadState: PayloadAction<askADocUserProfileReducerTypes.IAskADocGetUserProfile>
) {
   yield put(getUserProfileRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getUserProfile });
      if (response.status === 200) {
         const responseData = {
            data: response.data?.data || null,
            message: response.data?.message || messages.success,
         };
         yield put(getUserProfileSuccess(responseData));
      } else {
         yield put(getUserProfileFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGetUserProfile = function* () {
   yield takeEvery(ASK_A_DOC_GET_USER_PROFILE, getUserProfileSaga);
};
