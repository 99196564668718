import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import size from 'lodash.size';
// import { store } from 'react-notifications-component';
// import { store } from 'reduxToolKit';
import { s3UploadReducerTypesFileUpload } from 'types/fileUpload';
import { removeFileObject, fileTypeAllowed } from 'utils/helpers/fileFuncHelpers';

const s3FileUploadState: s3UploadReducerTypesFileUpload.IFileProgress = {
   fileProgress: {},
};

const s3MediaUploadSlice = createSlice({
   name: 's3MediaUpload',
   initialState: s3FileUploadState,
   reducers: {
      s3FileUploadStart: (state, action: PayloadAction<s3UploadReducerTypesFileUpload.IFileProgressPayload>) => {
         return {
            ...state,
            fileProgress: {
               ...state.fileProgress,
               ...modifyFiles(state.fileProgress, action.payload),
            },
         };
      },
      s3FileUploadInProgress: (state, action) => {
         return {
            ...state,
            fileProgress: {
               ...state.fileProgress,
               [action.payload.id]: {
                  ...state.fileProgress[action.payload.id],
                  progress: action.payload.progress,
               },
            },
         };
      },
      s3FileUploadSignedURLStarted: (state, action) => {
         return {
            ...state,
            fileProgress: {
               ...state.fileProgress,
               [action.payload]: {
                  ...state.fileProgress[action.payload],
                  isPreSignedURLStarted: true,
               },
            },
         };
      },
      s3FileUploadSignedURL: (state, action) => {
         let s3SignedResponseObject: any = {};
         action.payload.forEach((fileObj: any) => {
            s3SignedResponseObject = {
               ...s3SignedResponseObject,
               [fileObj.fileId]: {
                  ...state.fileProgress[fileObj.fileId],
                  isPreSignedURLGenerated: fileObj.isPreSignedURLGenerated,
               },
            };
         });

         return {
            ...state,
            fileProgress: {
               ...state.fileProgress,
               ...s3SignedResponseObject,
            },
         };
      },
      s3FileUploadSuccess: (state, action) => {
         let s3SuccessResponseObject: any = {};
         action.payload.forEach((fileObj: any) => {
            s3SuccessResponseObject = {
               ...s3SuccessResponseObject,
               [fileObj.fileId]: {
                  ...state.fileProgress[fileObj.fileId],
                  key: fileObj.fileKey,
                  isPreSignedURLGenerated: fileObj.isPreSignedURLGenerated,
                  isLoading: false,
                  isUploaded: true,
               },
            };
         });
         return {
            ...state,
            fileProgress: {
               ...state.fileProgress,
               ...s3SuccessResponseObject,
            },
         };
      },
      s3FileUploadFailed: (state, action) => {
         return {
            ...state,
            fileProgress: {
               ...state.fileProgress,
               [action.payload]: {
                  ...state.fileProgress[action.payload],
                  isLoading: true,
                  isUploaded: false,
                  progress: 0,
                  key: '',
               },
            },
         };
      },
      s3FileUploadRemove: (state, action) => {
         const fileProgressData = removeFileObject(action.payload);
         return {
            ...state,
            fileProgress: fileProgressData,
         };
      },

      s3FileUploadReset: () => {
         return {
            ...s3FileUploadState,
         };
      },
   },
});

export { s3MediaUploadSlice };

const modifyFiles = (existingFiles: any, payloadData: s3UploadReducerTypesFileUpload.IFileProgressPayload) => {
   let fileToUpload = {};
   const files = payloadData.uploadingFiles;
   // eslint-disable-next-line no-loops/no-loops,no-plusplus
   for (let i = 0; i < files.length; i++) {
      const isFileTypeAllowed = fileTypeAllowed(files[i].type, payloadData.type);
      if (isFileTypeAllowed) {
         // const id = size(existingFiles) + i + 1;
         const id = Object.keys(existingFiles).length + i + 1;
         fileToUpload = {
            ...fileToUpload,
            [id]: {
               id,
               file: files[i],
               isLoading: true,
               isUploaded: false,
               progress: 0,
               type: payloadData.type,
               isPreSignedURLGenerated: false,
               isPreSignedURLStarted: false,
               key: '',
            },
         };
      } else {
         // store.addNotification(getToaster(`${APP_NAME} File Upload`, 'File Format Not Supported', DANGER));
      }
   }

   return fileToUpload;
};
