import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { askADocViewAnswersReducerTypes, storeTypes } from 'types';

const initialState: askADocViewAnswersReducerTypes.IViewAnswersReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};

export const askADocViewAnswersSlice = createSlice({
   name: 'askADocViewAnswers',
   initialState: initialState,
   reducers: {
      askADocViewAnswersRequest: (state, action) => {
         return {
            ...state,
            isLoading: true,
            ...action.payload,
         };
      },
      askADocViewAnswersSuccess: (
         state,
         action: PayloadAction<askADocViewAnswersReducerTypes.IViewAnswerSuccesspayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            data: payload?.data || null,
            message: payload?.message || 'success',
            error: '',
            isLoading: false,
         };
      },
      askADocViewAnswersFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: action.payload?.error,
         };
      },

      askADocAddAnswersRequest: (state, action: PayloadAction<askADocViewAnswersReducerTypes.IAddAnswerPayload>) => {
         return {
            ...state,
            isLoading: true,
            ...action.payload,
         };
      },
      askADocAddAnswersSuccess: (
         state,
         action: PayloadAction<askADocViewAnswersReducerTypes.IViewAnswerSuccesspayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            data: payload?.data || null,
            message: payload?.message,
            error: '',
            isLoading: false,
         };
      },
      askADocAddAnswersFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: action.payload?.error,
         };
      },

      askADocViewAnswersReset: (state) => {
         return { ...initialState };
      },
   },
});
