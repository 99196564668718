import React, { useCallback, useEffect } from 'react';

import ClinicalTrailsHomeIcon from 'assets/fill-profile/clinical_trials_home.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ctFilledPopupAction } from 'reduxToolKit/actions';
import { hidePostSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes, storeTypes } from 'types';

const ClinicalTrailsHomeSection = (props: any) => {
   const dispatch = useDispatch();

   const { isLoading, message, isFrom } = useSelector((state: storeTypes.IStore) => state.hidePost.ctFilledPost);
   const { ctFilledPostReset } = hidePostSlice.actions;

   const { currentModalClose, setIsLoader, skipModal } = props;

   const handleSubmit = () => {
      props.navigation(props.nexSection);
   };

   useEffect(() => {
      setIsLoader(isLoading);
      if (message) {
         if (isFrom === 'submit') {
            handleSubmit();
         } else {
            currentModalClose();
            setIsLoader(false);
            skipModal();
         }
         dispatch(ctFilledPostReset());
      }
   }, [message, isLoading]);

   const clickHomeCancel = useCallback((isFrom: string) => {
      const data: feedSagaTypes.ICtFilledPopUp = {
         is_popped_up: 1,
         isFrom: isFrom,
      };
      dispatch(ctFilledPopupAction(data));
   }, []);

   return (
      <>
         <div className="col-12 fill-home">
            <div
               className="fill-home-close hand-i"
               id="clinicalTrialsHomeCancel"
               onClick={() => {
                  if (props?.isFromClinicalTrialsList) {
                     props?.fillProfileModalClose();
                  } else {
                     clickHomeCancel('cancel');
                  }
               }}
            >
               <i className="material-icons">close</i>
            </div>
         </div>
         <div className="col-12">
            <img className="onco-icon" src={ClinicalTrailsHomeIcon} alt="icon" />
         </div>
         <div>
            <p className="clinical-trails-paragraph-text p-2">
               {props?.isFromClinicalTrials
                  ? `Thank you for being a valued member of our community! Please complete your patient profile so that we can provide more personalized cancer support.`
                  : `Thank you for being a valued member of our community! Please complete your patient profile so that we can provide more personalized cancer support.`}
            </p>
         </div>
         <div className="button">
            <button
               type="button"
               className="clinical-trails-submit"
               id="clinicalTrialsSubmitSubmitCancel"
               onClick={() => {
                  if (props?.isFromClinicalTrials) {
                     handleSubmit();
                  } else {
                     clickHomeCancel('submit');
                  }
               }}
            >
               Continue
            </button>
         </div>
      </>
   );
};

export default ClinicalTrailsHomeSection;
