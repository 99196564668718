import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { TELE_MEDICINE_LIST } from 'reduxToolKit/actions';
import { teleMedicineListSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getAllTeleMedicineListReq,

   getAllTeleMedicineSuccess,
   getAllTeleMedicineFailed,
} = teleMedicineListSlice.actions;

const listTeleMedicine = function* (getClinicIdPayload: any) {
   yield put(getAllTeleMedicineListReq());
   try {
      const params = getClinicIdPayload.payload;
      const response: AxiosResponse = yield apiCall({ params, ...API.AppointmentList });
      if (response.status === 200) {
         const responseData = response.data.data;
         const appointmentList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message,
         };
         yield put(getAllTeleMedicineSuccess(appointmentList));
      } else {
         yield put(getAllTeleMedicineFailed(response.data));
      }
   } catch (error) {}
};
export const takeListMedicineRequest = function* () {
   yield takeEvery(TELE_MEDICINE_LIST, listTeleMedicine);
};
