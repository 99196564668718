import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { UPDATE_PROFILE_ACTION, USER_GET_COUNTRIES, DELETE_ACCOUNT } from 'reduxToolKit/actions';
import { commonSlice, getUserProfileSlice, userGetCountriesSlice } from 'reduxToolKit/reducers';
import { myProfileSagaTypes } from 'types';
import { IEditProfile } from 'types/modelHelpers';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { userProfileUpdateRequest, userProfileUpdateSuccess, userProfileUpdateFailed } = getUserProfileSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const userProfileUpdateSaga = function* (profileUpdatePayload: PayloadAction<IEditProfile>) {
   yield put(userProfileUpdateRequest(profileUpdatePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = profileUpdatePayload.payload;
      const customUrl = API.updateProfile;
      customUrl.apiPath = customUrl.apiPath + profileUpdatePayload.payload?.paramsType;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...customUrl });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(userProfileUpdateSuccess(responseData));
      } else {
         yield put(userProfileUpdateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   userGetCountriesLoading,
   userGetCountriesRequest,
   userGetCountriesSuccess,
   userGetCountriesFailed,
} = userGetCountriesSlice.actions;

const userGetCountriesSaga = function* (getCountriesPayload: PayloadAction<myProfileSagaTypes.IGetCountriesRequest>) {
   const payload = getCountriesPayload.payload;
   yield put(userGetCountriesLoading());
   if (payload.search_word) {
      yield delay(1000);
   }
   yield put(userGetCountriesRequest(payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getCountries });
      if (response.status === 200) {
         const responseData = response.data.data;
         yield put(userGetCountriesSuccess(responseData));
      } else {
         yield put(userGetCountriesFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const { deleteAccountRequest, deleteAccountSuccess, deleteAccountFailed } = getUserProfileSlice.actions;
const deleteAccountSaga = function* (profileUpdatePayload: PayloadAction<IEditProfile>) {
   yield put(deleteAccountRequest(profileUpdatePayload.payload));
   try {
      const checkUser = false;
      const goUrl = false;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, ...API.deleteAccount });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(deleteAccountSuccess(responseData));
      } else {
         yield put(deleteAccountFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeUserProfileUpdateSaga = function* () {
   yield takeEvery(UPDATE_PROFILE_ACTION, userProfileUpdateSaga);
   yield takeLatest(USER_GET_COUNTRIES, userGetCountriesSaga);
   yield takeLatest(DELETE_ACCOUNT, deleteAccountSaga);
};
