export const isAO = (value: any) => value && (value instanceof Array || value instanceof Object);

export const arrayCheckIdxExists = (data: any[], idx?: number): boolean => {
   if (data && Array.isArray(data)) {
      if (idx === undefined) {
         return true;
      }
      if (data[idx]) {
         return true;
      }
      return false;
   }
   return false;
};

export const arrayCheckLength = (data: any[]): boolean => {
   if (data && Array.isArray(data) && data.length > 0) {
      return true;
   }
   return false;
};
