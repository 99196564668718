import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_COMMUNITY_GUIDELINES, CREATE_POST_IS_CLINIC_USER } from 'reduxToolKit/actions';
import { commonSlice, communityGuidelinesSlice } from 'reduxToolKit/reducers';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   addCommunityGuidelinesRequest,
   addCommunityGuidelinesSuccess,
   addCommunityGuidelinesFailure,
   createPostIsClinicUserRequest,
   createPostIsClinicUserSuccess,
   createPostIsClinicUserFailure,
} = communityGuidelinesSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const communityGuidelinesSaga = function* () {
   yield put(addCommunityGuidelinesRequest());
   try {
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ checkUser, ...API.addCommunityGuidelines });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(addCommunityGuidelinesSuccess(responseData));
      } else {
         yield put(addCommunityGuidelinesFailure(response.data));
      }
   } catch (err) {
      yield put(exceptionHandlerRequest(err));
   }
};

const createPostIsClinicUserSaga = function* () {
   yield put(createPostIsClinicUserRequest());
   try {
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         ...API.createPostIsClinicUser,
      });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
            data: response.data?.data || false,
         };
         yield put(createPostIsClinicUserSuccess(responseData));
      } else {
         yield put(createPostIsClinicUserFailure(response.data));
      }
   } catch (err) {
      yield put(exceptionHandlerRequest(err));
   }
};

export const takeCommunityGuidelinesSaga = function* () {
   yield takeEvery(ADD_COMMUNITY_GUIDELINES, communityGuidelinesSaga);
   yield takeEvery(CREATE_POST_IS_CLINIC_USER, createPostIsClinicUserSaga);
};
