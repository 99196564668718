import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { PRACTISE_ADMIN_LOCUM_LIST } from 'reduxToolKit/actions';
import { commonSlice, practiseAdminLocumListSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   practiseAdminLocumListRequest,
   practiseAdminLocumListSuccess,
   practiseAdminLocumListFailed,
} = practiseAdminLocumListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const practiseAdminLocumList = function* (locumListParams: any) {
   yield put(practiseAdminLocumListRequest(locumListParams.payload));
   try {
      const url = API.locumPractiseAdminList;
      const checkUser = false;
      const params = locumListParams.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, params, ...url });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(practiseAdminLocumListSuccess(resObj));
      } else {
         yield put(practiseAdminLocumListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePractiseAdminLocumList = function* () {
   yield takeLatest(PRACTISE_ADMIN_LOCUM_LIST, practiseAdminLocumList);
};
