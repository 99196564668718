import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';
import Constant from 'config/Constant';
import { DatePicker } from 'rsuite';
import { convertStartEndDateToUTC, isBeforeYesterday } from 'utils/helpers/dateHelpers';

const PillStartDate = (props: any) => {
   const [pillStartDate, setPillStartDate] = useState('');
   const [pillStartDateTime, setPillStartDateTime] = useState<Date>();
   const [errorMessage, setErrorMessage] = useState('');

   const changeDate = useCallback((date: Date) => {
      if (date) {
         setPillStartDateTime(date);
         const convertDate = convertStartEndDateToUTC(date);
         setPillStartDate(convertDate);
         setErrorMessage('');
      }
   }, []);

   const setActionText = () => {
      let action = 'Next';
      if (
         props.type === 'every_two_days' ||
         props.type === 'every_fourteen_days' ||
         props.type === 'every_twenty_one_days' ||
         props.type === 'every_twenty_eight_days'
      ) {
         action = 'Submit';
      }
      return action;
   };

   const clickNext = useCallback(() => {
      if (pillStartDate) {
         props.pillRemainderStartDate(pillStartDate);
         props.pillRemainderStartDateTime(pillStartDateTime);
      } else {
         setErrorMessage('Please select date');
      }
   }, [pillStartDate]);

   useEffect(() => {
      setPillStartDateTime(props?.pillStartDateTime);
      setPillStartDate(props?.pillStartDate);
   }, []);

   return (
      <Box>
         <Typography align="center" className="pill-add-text">
            Set start date
         </Typography>
         <Box width="85%" ml={4} mt={2}>
            <DatePicker
               size="lg"
               placeholder="Select Date"
               placement="topEnd"
               style={{ width: 280, display: 'block', marginBottom: 10 }}
               onChange={changeDate}
               value={pillStartDateTime}
               format={Constant.DATE_FORMAT}
               disabledDate={(date) => isBeforeYesterday(date)}
            />
         </Box>
         {errorMessage && <Box className="errorMsg ml-5">{errorMessage}</Box>}
         <Box mb={4} mx="auto" mt={5}>
            <Grid container direction="row" spacing={3} justify="center">
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="pill-add-back-button"
                     fullWidth
                     onClick={() => {
                        if (
                           props?.type === 'every_two_days' ||
                           props?.type === 'every_fourteen_days' ||
                           props?.type === 'every_twenty_one_days' ||
                           props?.type === 'every_twenty_eight_days'
                        ) {
                           props?.pillRemainderStartDateBack(pillStartDate, props?.type, 'first_dose_time');
                           props?.pillRemainderStartDateTime(pillStartDateTime);
                        }
                     }}
                  >
                     Back
                  </Button>
               </Grid>
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="telemedicine__modal--btn"
                     fullWidth
                     onClick={clickNext}
                  >
                     {setActionText()}
                  </Button>
               </Grid>
            </Grid>
         </Box>
      </Box>
   );
};

export { PillStartDate };
