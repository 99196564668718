import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { DEVICE_GET_MIN_AND_MAX, DEVICE_WEIGHT_READING_DIFFERENCE, DEVICE_ADD_DEVICES } from 'reduxToolKit/actions';
import { commonSlice, deviceGetMinAndMaxSlice } from 'reduxToolKit/reducers';
import { deviceSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   deviceGetMinAndMaxLoading,
   deviceGetMinAndMaxRequest,
   deviceGetMinAndMaxSuccess,
   deviceGetMinAndMaxFailed,
   deviceGetWeightReadingDiffRequest,
   deviceGetWeightReadingDiffSuccess,
   deviceGetWeightReadingDiffFailed,
   addDevicesRequest,
   addDevicesSuccess,
   addDevicesFailed,
} = deviceGetMinAndMaxSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const deviceMinAndMax = function* (deviceMinAndMaxPayload: any) {
   yield put(deviceGetMinAndMaxLoading());
   yield put(deviceGetMinAndMaxRequest(deviceMinAndMaxPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = deviceMinAndMaxPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getDeviceMinMax });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(deviceGetMinAndMaxSuccess(responseData));
      } else {
         yield put(deviceGetMinAndMaxFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const deviceGetWeightReadingDiff = function* (deviceGetWeightReadingDiffPayload: any) {
   yield put(deviceGetWeightReadingDiffRequest(deviceGetWeightReadingDiffPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = deviceGetWeightReadingDiffPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         goUrl,
         params,
         ...API.getSmartWeightReadingAndDifference,
      });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(deviceGetWeightReadingDiffSuccess(responseData));
      } else {
         yield put(deviceGetWeightReadingDiffFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const deviceAddDevices = function* (deviceAddDevicesPayload: PayloadAction<deviceSagaTypes.IDeviceAddDevices>) {
   yield put(addDevicesRequest(deviceAddDevicesPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = deviceAddDevicesPayload.payload;
      let api = API.addSmartTemperature;
      if (data.oxygen_level) {
         api = API.addSmartOxymeter;
      }
      const response: AxiosResponse = yield apiCall({
         checkUser,
         goUrl,
         data,
         ...api,
      });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(addDevicesSuccess(responseData));
      } else {
         yield put(addDevicesFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeDevice = function* () {
   yield takeEvery(DEVICE_GET_MIN_AND_MAX, deviceMinAndMax);
   yield takeEvery(DEVICE_WEIGHT_READING_DIFFERENCE, deviceGetWeightReadingDiff);
   yield takeEvery(DEVICE_ADD_DEVICES, deviceAddDevices);
};
