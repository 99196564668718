import { createAction } from '@reduxjs/toolkit';
import {
   ITreatmentMethodLogListRequestParams,
   ITreatmentMethodRequestParams,
} from 'types/oncKlinic/treatmentMethodTypes';

export const TREATMENT_METHOD_ADD = 'TREATMENT_METHOD_ADD';
export const treatmentMethodAdd = createAction<ITreatmentMethodRequestParams>(TREATMENT_METHOD_ADD);

export const TREATMENT_METHOD_LOG_LIST = 'TREATMENT_METHOD_LOG_LIST';
export const treatmentMethodLogList = createAction<ITreatmentMethodLogListRequestParams>(TREATMENT_METHOD_LOG_LIST);
