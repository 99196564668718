import React, { useCallback, useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, ButtonGroup, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import Drawer from '@material-ui/core/Drawer';
import { Cancel, Edit } from '@material-ui/icons';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Add from 'assets/alert/pillAddIcon.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import Loading from 'component/widget/Loading';
import Constant from 'config/Constant';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import Select, { InputActionMeta, ValueType } from 'react-select';
import {
   medicationListSagaAction,
   pillRemainderAllPillsSagaAction,
   pillRemainderEditSagaAction,
   pillRemainderGetOneSagaAction,
   pillRemainderListSagaAction,
   pillRemainderMedicationStatusUpdateSagaAction,
} from 'reduxToolKit/actions';
import {
   getDrugsListSlice,
   medicationListSlice,
   pillRemainderEditSlice,
   pillRemainderGetOneSlice,
   pillRemainderListSlice,
} from 'reduxToolKit/reducers';
import { DatePicker } from 'rsuite';
import { pillRemainderPageTypes, pillRemainderSagaTypes, storeTypes } from 'types';
import { CommonConstants } from 'utils/constants/common';
import { TIME_ONLY_ADD_DATE } from 'utils/constants/dateConstants';
import { PILL_REMAINDER_EVENTS } from 'utils/constants/eventTrack/pillRemainderEventConstants';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';
import {
   changeNormalDateFormat,
   convertDateToTimezone,
   convertDateToTimezoneDate,
   convertOnlyUTCTime,
   convertStartEndDateToUTC,
   getCurrentDate,
   getUTCToTimeAppendDate,
   isBeforeYesterday,
} from 'utils/helpers/dateHelpers';
import { setEventTrack } from 'utils/helpers/eventTrack';

import { SelectTime } from './SelectTime';

const types = [
   { title: 'Every day', value: 'every_day' },
   { title: 'Once a week', value: 'once_a_week' },
   { title: 'Every 2 days', value: 'every_two_days' },
   { title: '2 Days a week', value: 'two_days_a_week' },
   { title: '3 Days a week', value: 'three_days_a_week' },
   { title: 'Every 14 days', value: 'every_fourteen_days' },
   { title: 'Every 21 days', value: 'every_twenty_one_days' },
   { title: 'Every 28 days', value: 'every_twenty_eight_days' },
   { title: '5 Days a week', value: 'five_days_a_week' },
   { title: 'Others', value: 'others' },
];
const days = [
   { title: 'Sunday', value: '0', day: '0', id: 0 },
   { title: 'Monday', value: '1', day: '1', id: 0 },
   { title: 'Tuesday', value: '2', day: '2', id: 0 },
   { title: 'Wednesday', value: '3', day: '3', id: 0 },
   { title: 'Thursday', value: '4', day: '4', id: 0 },
   { title: 'Friday', value: '5', day: '5', id: 0 },
   { title: 'Saturday', value: '6', day: '6', id: 0 },
];
const Others = [
   { title: 'Specific days of the week', value: 'specific_days_of_the_week' },
   { title: 'Every number of days', value: 'every_number_of_days' },
];

const PillRemainderDrawer = (props: pillRemainderPageTypes.IPillRemainderDrawerProps) => {
   const [alert, setAlert] = useState<JSX.Element | null>(null);
   const rightAnchor = 'right';
   const [disableBackdropClick, setDisableBackdropClick] = useState(false);
   const [disableEscapeKeyDown, setDisableEscapeKeyDown] = useState(false);

   const [name, setName] = useState<pillRemainderPageTypes.dropDownName | null>(null);
   const [changedDoseInformation, setChangedDoseInformation] = useState<string>('');
   const [type, setType] = useState('');
   const [typeDropdown, setTypeDropdown] = useState<pillRemainderPageTypes.dropDownProps | null>(null);
   const [timeArray, setTimeArray] = useState<pillRemainderSagaTypes.IPillRemainderMedTime[]>([]);
   const [daysArray, setDaysArray] = useState<pillRemainderSagaTypes.IPillRemainderMedDay[]>([]);
   const [daySingleDropdown, setDaySingleDropdown] = useState<pillRemainderPageTypes.dropDownProps | null | undefined>(
      null
   );
   const [dayMultipleDropdown, setDayMultipleDropdown] = useState<pillRemainderPageTypes.dropDownProps[] | undefined>(
      undefined
   );
   const [firstDoseTime, setFirstDoseTime] = useState('');
   const [firstDoseDateTime, setFirstDoseDateTime] = useState<Date | null>(null);
   const [pillStartDate, setPillStartDate] = useState('');
   const [pillStartDateTime, setPillStartDateTime] = useState<Date | undefined>(undefined);
   const [othersType, setOthersType] = useState<pillRemainderPageTypes.dropDownProps | null>(null);
   const [intervalCount, setIntervalCount] = useState<number>(2);

   const [validation, setValidation] = useState<boolean>(false);
   const [multipleDaysErrorMessage, setMultipleDaysErrorMessage] = useState<string>('');

   const [searchWord, setSearchWord] = useState('');
   const [addSearchWord, setAddSearchWord] = useState('');
   const [drugListDropdown, setDrugListDropdown] = useState<pillRemainderPageTypes.dropDownPropsName[]>([]);
   const [drugValue, setDrugValue] = useState<pillRemainderPageTypes.dropDownPropsName | null>(null);

   const [activeStatus, setActiveStatus] = useState<boolean>(false);

   const dispatch = useDispatch();

   const { isLoading: isLoadingPillRemainder, data: pillItemData, error: pillRemainderErrorMessage } = useSelector(
      (state: storeTypes.IStore) => state.pillRemainderGetOne
   );
   const { pillRemainderGetOneReset } = pillRemainderGetOneSlice.actions;

   const { pillRemainderListReset } = pillRemainderListSlice.actions;

   const { medicationsListReset } = medicationListSlice.actions;

   const {
      isLoading: isLoadingEditPillRemainder,
      message: pillRemainderEditSuccessMessage,
      error: pillRemainderEditErrorMessage,
      statusActive: pillRemainderActiveStatus,
      data: pillRemainderResponseData,
   } = useSelector((state: storeTypes.IStore) => state.pillRemainderEdit);
   const { pillRemainderEditRemainderReset } = pillRemainderEditSlice.actions;

   const { list: drugList, error: drugsError, isLoading: isLoadingDrugList } = useSelector(
      (state: storeTypes.IStore) => state.pillRemainderAllPillsList
   );
   const { getDrugsListReset } = getDrugsListSlice.actions;

   const getActiveStatus = (status: boolean) => {
      setActiveStatus(status);
   };

   const clickMedicationStatus = (medicationId: number, medicationStatusBoolean: boolean) => {
      const pillRemainderMediStatusUpdateRequest = {
         id: medicationId,
         status: medicationStatusBoolean,
      };
      dispatch(pillRemainderMedicationStatusUpdateSagaAction(pillRemainderMediStatusUpdateRequest));
   };

   useEffect(() => {
      if (pillRemainderResponseData) {
         if (pillRemainderActiveStatus === pillRemainderResponseData?.status) {
            if (pillRemainderEditSuccessMessage) {
               const getAlert = () => (
                  <InfoTimerAlert
                     success
                     confirmBtnText={`Okay!`}
                     confirmBtnBsStyle={`successBtn`}
                     title={pillRemainderEditSuccessMessage}
                     onConfirm={() => hideAlert('successAlertPillEdit')}
                  />
               );
               setAlert(getAlert());
            } else if (pillRemainderEditErrorMessage) {
               const getAlert = () => (
                  <SweetAlert
                     error
                     confirmBtnText={`Okay!`}
                     confirmBtnBsStyle={`errorBtn`}
                     title={pillRemainderEditErrorMessage}
                     onConfirm={() => hideAlert('errorAlertEdit')}
                  />
               );
               setAlert(getAlert());
            }
         } else {
            const id = pillRemainderResponseData?.id || 0;
            const activeStatus = pillRemainderActiveStatus === 1 ? true : false;
            clickMedicationStatus(id, activeStatus);
            dispatch(pillRemainderEditRemainderReset());
         }
      } else if (pillRemainderEditErrorMessage) {
         const getAlert = () => (
            <SweetAlert
               error
               confirmBtnText={`Okay!`}
               confirmBtnBsStyle={`errorBtn`}
               title={pillRemainderEditErrorMessage}
               onConfirm={() => hideAlert('errorAlertEdit')}
            />
         );
         setAlert(getAlert());
      }
   }, [
      pillRemainderResponseData,
      pillRemainderActiveStatus,
      pillRemainderEditSuccessMessage,
      pillRemainderEditErrorMessage,
   ]);

   const pillRemainderList = () => {
      if (props.pillItemMedication && !props.isFromHome && props.isFromMyProfile) {
         dispatch(medicationsListReset());
         const params: pillRemainderSagaTypes.IPillActiveAndInactiveMedicineList = {
            from_type: 'non_cancer',
         };
         dispatch(medicationListSagaAction(params));
      } else if (props.pillItemMedication && !props.isFromHome) {
         dispatch(medicationsListReset());
         const params: pillRemainderSagaTypes.IPillActiveAndInactiveMedicineList = {
            from_type: 'pill',
         };
         dispatch(medicationListSagaAction(params));
      } else {
         const currentDate = getCurrentDate();
         const convertDateTimezoneDate = convertDateToTimezoneDate(
            props.selectedDate?.dateFormat ? props.selectedDate.dateFormat : currentDate
         );
         const convertDateTimezone = convertDateToTimezone(
            props.selectedDate?.dateFormat ? props.selectedDate.dateFormat : currentDate
         );
         dispatch(pillRemainderListReset());
         pillRemainderListDispatch(convertDateTimezoneDate, convertDateTimezone);

         dispatch(medicationsListReset());
         const params: pillRemainderSagaTypes.IPillActiveAndInactiveMedicineList = {
            from_type: 'pill',
         };
         dispatch(medicationListSagaAction(params));
      }
   };

   const pillRemainderListDispatch = (date: string, convertDateTimezone: string) => {
      const pillRemainderRequest = {
         date,
         time: convertDateTimezone,
      };
      dispatch(pillRemainderListSagaAction(pillRemainderRequest));
   };

   const hideAlert = (type: string) => {
      if (type === 'errorAlert') {
         dispatch(pillRemainderGetOneReset());
      } else if (type === 'errorAlertEdit') {
         setAlert(null);
         dispatch(pillRemainderEditRemainderReset());
      } else if (type === 'errorAlertDrug') {
         dispatch(getDrugsListReset());
      } else if (type === 'successAlertPillEdit') {
         pillRemainderList();
         props.handleClose();
         dispatch(pillRemainderEditRemainderReset());
      }
   };

   const searchName = (value: string, actionMeta: InputActionMeta) => {
      if (actionMeta?.action === 'input-change') {
         setSearchWord(value);
         setAddSearchWord('');
         setDrugValue(null);
         setName(null);
         setChangedDoseInformation('');
         pillRemainderAllPillsDispatch(1, value);
      }
   };

   const clickAdd = () => {
      if (searchWord) {
         setAddSearchWord(searchWord);
         setName({
            name: searchWord || '',
            drug_id: '',
            dose_information: '',
            unit: '',
         });
      } else {
         setAddSearchWord('');
         setName(null);
         setDrugValue(null);
      }
   };

   useEffect(() => {
      const drugListTemp: pillRemainderPageTypes.dropDownPropsName[] = [];
      const array = listParseAndStringify(drugList);
      if (array) {
         for (let i = 0; i < array.length; i++) {
            const obj = array[i];
            drugListTemp.push({
               title: obj?.brand_name || obj?.generic_name || '',
               label: obj?.brand_name || obj?.generic_name || '',
               value: String(obj?.id) || '0',
               dose_information: obj.dose_information || '',
               unit: obj.unit || '',
            });
         }
      }
      setDrugListDropdown(drugListTemp);
   }, [drugList]);

   const pillRemainderAllPillsDispatch = (page: number, searchWord: string) => {
      const drugs = {
         search_word: searchWord,
         page: page,
         limit: CommonConstants.DropDownLimit,
      };
      dispatch(pillRemainderAllPillsSagaAction(drugs));
   };

   useEffect(() => {
      if (!props.isView) {
         pillRemainderAllPillsDispatch(1, searchWord);
         return () => {
            dispatch(getDrugsListReset());
         };
      }
   }, [props.isView]);

   useEffect(() => {
      if (pillItemData) {
         if (pillItemData.name) {
            setName({
               name: pillItemData?.name || '',
               drug_id: pillItemData?.drug_id ? String(pillItemData.drug_id) : '',
               dose_information: pillItemData?.dose_information || '',
               unit: pillItemData?.unit || '',
            });
            setDrugValue({
               title: pillItemData?.name || '',
               label: pillItemData?.name || '',
               value: String(pillItemData?.drug_id) || '',
               dose_information: pillItemData?.dose_information || '',
               unit: pillItemData?.unit || '',
            });
            setChangedDoseInformation(pillItemData?.dose_information || '');
         }
         if (pillItemData.type) {
            setType(pillItemData.type);
            const obj = types.find((y: pillRemainderPageTypes.dropDownProps) => y.value === pillItemData.type);
            setTypeDropdown({ title: obj?.title || '', value: pillItemData.type });
         }
         if (pillItemData.pill_rem_med_times) {
            const timeArray: pillRemainderSagaTypes.IPillRemainderMedTime[] = [];
            const array = listParseAndStringify(pillItemData.pill_rem_med_times);
            for (let i = 0; i < array.length; i++) {
               const obj = array[i];
               const localStartTime = TIME_ONLY_ADD_DATE + obj.time;
               obj.dateTime = getUTCToTimeAppendDate(localStartTime);
               timeArray.push(obj);
            }
            setTimeArray(timeArray);
         }
         if (pillItemData.pill_rem_med_days) {
            setDaysArray(pillItemData.pill_rem_med_days);
            if (pillItemData.type === 'once_a_week') {
               const obj = days.find(
                  (y: pillRemainderPageTypes.dropDownProps) => y.value === String(pillItemData.pill_rem_med_days[0].day)
               );
               setDaySingleDropdown({
                  title: obj?.title || '',
                  value: String(pillItemData.pill_rem_med_days[0].day) || '',
                  day: String(pillItemData.pill_rem_med_days[0].day) || '',
                  id: pillItemData.pill_rem_med_days[0].id || 0,
               });
            } else {
               const multipleArray: pillRemainderPageTypes.dropDownProps[] = [];
               const array = listParseAndStringify(pillItemData.pill_rem_med_days);
               for (let i = 0; i < array.length; i++) {
                  const obj = days.find((y: pillRemainderPageTypes.dropDownProps) => y.value === String(array[i].day));
                  multipleArray.push({
                     title: obj?.title || '',
                     value: String(array[i].day) || '',
                     day: String(array[i].day) || '',
                     id: array[i].id || 0,
                  });
               }
               setDayMultipleDropdown(multipleArray);
            }
         }
         if (pillItemData.first_dose_time) {
            setFirstDoseTime(pillItemData.first_dose_time);
            const localStartTime = TIME_ONLY_ADD_DATE + pillItemData.first_dose_time;
            const date = getUTCToTimeAppendDate(localStartTime);
            setFirstDoseDateTime(date);
         }
         if (pillItemData.start_date) {
            setPillStartDate(pillItemData.start_date);
            setPillStartDateTime(changeNormalDateFormat(pillItemData.start_date));
         }
         if (pillItemData.others_type) {
            if (pillItemData.others_type === 'specific_days_of_the_week') {
               setOthersType({ title: 'Specific days of the week', value: 'specific_days_of_the_week' });
            } else if (pillItemData.others_type === 'every_number_of_days') {
               setOthersType({ title: 'Every number of days', value: 'every_number_of_days' });
            }
         }
         if (pillItemData.others_interval_days) {
            setIntervalCount(pillItemData.others_interval_days);
         }
         setActiveStatus(pillItemData?.status === 1 || false);
      }
   }, [pillItemData, days, types]);

   const changeType = (value: pillRemainderPageTypes.dropDownProps | null) => {
      setTypeDropdown(value);
      setType(value?.value || '');
      // all remove
      setTimeArray([]);
      setDaysArray([]);
      setDaySingleDropdown(null);
      setDayMultipleDropdown(undefined);
      setFirstDoseTime('');
      setFirstDoseDateTime(null);
      setPillStartDate('');
      setPillStartDateTime(undefined);
      setOthersType(null);
      setIntervalCount(2);
      setMultipleDaysErrorMessage('');
   };

   // const changeIntervalCount = (event: any) => {
   //    setIntervalCount(event.target.value);
   // };

   const changeSingleDay = (value: pillRemainderPageTypes.dropDownProps | null) => {
      setMultipleDaysErrorMessage('');
      if (type === 'once_a_week') {
         setDaySingleDropdown(value);
         // setDaysArray([{ day: value?.value || '' }]);
      } else if (type === 'two_days_a_week') {
         const dayMultipleArray = dayMultipleDropdown ? listParseAndStringify(dayMultipleDropdown) : [];
         if (dayMultipleArray && dayMultipleArray?.length < 2) {
            if (value) {
               const index = dayMultipleArray.findIndex(
                  (y: pillRemainderPageTypes.dropDownProps) => y.value === value.value
               );
               if (index === -1) {
                  dayMultipleArray.push(value);
               }
            }
         }
         setDayMultipleDropdown(dayMultipleArray);
      } else if (type === 'three_days_a_week') {
         const dayMultipleArray = dayMultipleDropdown ? listParseAndStringify(dayMultipleDropdown) : [];
         if (dayMultipleArray && dayMultipleArray?.length < 3) {
            if (value) {
               const index = dayMultipleArray.findIndex(
                  (y: pillRemainderPageTypes.dropDownProps) => y.value === value.value
               );
               if (index === -1) {
                  dayMultipleArray.push(value);
               }
            }
         }
         setDayMultipleDropdown(dayMultipleArray);
      } else if (type === 'five_days_a_week') {
         const dayMultipleArray = dayMultipleDropdown ? listParseAndStringify(dayMultipleDropdown) : [];
         if (dayMultipleArray && dayMultipleArray?.length < 5) {
            if (value) {
               const index = dayMultipleArray.findIndex(
                  (y: pillRemainderPageTypes.dropDownProps) => y.value === value.value
               );
               if (index === -1) {
                  dayMultipleArray.push(value);
               }
            }
         }
         setDayMultipleDropdown(dayMultipleArray);
      } else if (othersType?.value === 'specific_days_of_the_week') {
         const dayMultipleArray = dayMultipleDropdown ? listParseAndStringify(dayMultipleDropdown) : [];
         if (dayMultipleArray && dayMultipleArray?.length < 7) {
            if (value) {
               const index = dayMultipleArray.findIndex(
                  (y: pillRemainderPageTypes.dropDownProps) => y.value === value.value
               );
               if (index === -1) {
                  dayMultipleArray.push(value);
               }
            }
         }
         setDayMultipleDropdown(dayMultipleArray);
      }
   };

   const clickRemoveMultipleDays = (data: pillRemainderPageTypes.dropDownProps, index: number) => {
      if (type === 'once_a_week') {
         setDaySingleDropdown(null);
      } else {
         const dayMultipleArray = dayMultipleDropdown ? listParseAndStringify(dayMultipleDropdown) : [];
         if (dayMultipleArray && dayMultipleArray?.length > 0) {
            if (index !== -1) {
               dayMultipleArray.splice(index, 1);
               setDayMultipleDropdown(dayMultipleArray);
            }
         }
         if (type === 'two_days_a_week') {
            if (dayMultipleArray && dayMultipleArray.length <= 2) {
               setMultipleDaysErrorMessage('Minimum 2 days required');
            }
         } else if (type === 'three_days_a_week') {
            if (dayMultipleArray && dayMultipleArray.length <= 3) {
               setMultipleDaysErrorMessage('Minimum 3 days required');
            }
         } else if (type === 'five_days_a_week') {
            if (dayMultipleArray && dayMultipleArray.length <= 5) {
               setMultipleDaysErrorMessage('Minimum 5 days required');
            }
         } else if (othersType?.value === 'specific_days_of_the_week') {
            if (dayMultipleArray && dayMultipleArray.length < 1) {
               setMultipleDaysErrorMessage('Minimum 1 day required');
            }
         }
      }
   };

   const changeDate = (value: Date) => {
      setPillStartDateTime(value);
      const convertDate = convertStartEndDateToUTC(value);
      setPillStartDate(convertDate);
   };

   const changeTime = useCallback((date: MaterialUiPickersDate) => {
      if (date) {
         setFirstDoseDateTime(date);
         const convertTime = convertOnlyUTCTime(date);
         setFirstDoseTime(convertTime);
      }
   }, []);

   const clickIncrement = useCallback(() => {
      const count = intervalCount + 1;
      setIntervalCount(count);
   }, [intervalCount]);

   const clickDecrement = useCallback(() => {
      if (intervalCount > 2) {
         const count = intervalCount - 1;
         setIntervalCount(count);
      }
   }, [intervalCount]);

   const changeOthersType = (value: pillRemainderPageTypes.dropDownProps | null) => {
      setOthersType(value);
   };

   const getTimesArray = (timeArray: pillRemainderSagaTypes.IPillRemainderMedTime[]) => {
      setTimeArray(timeArray);
   };

   const clickSave = useCallback(() => {
      setValidation(false);
      setMultipleDaysErrorMessage('');
      if (type === '') {
         setValidation(true);
         return;
      }
      if (type === 'every_day') {
         if (name && type && timeArray.length > 0 && timeArray.findIndex((x) => x.time === '') === -1) {
            saveFunction();
         } else {
            setValidation(true);
         }
      } else if (
         type === 'every_two_days' ||
         type === 'every_fourteen_days' ||
         type === 'every_twenty_one_days' ||
         type === 'every_twenty_eight_days'
      ) {
         if (name && type && firstDoseTime && pillStartDate) {
            saveFunction();
         } else {
            setValidation(true);
         }
      } else if (type === 'once_a_week') {
         if (name && type && firstDoseTime && daySingleDropdown) {
            saveFunction();
         } else {
            setValidation(true);
         }
      } else if (type === 'two_days_a_week') {
         if (name && type && firstDoseTime && dayMultipleDropdown && dayMultipleDropdown.length === 2) {
            saveFunction();
         } else {
            setValidation(true);
            if (!dayMultipleDropdown) {
               setMultipleDaysErrorMessage('Minimum 2 days required');
            }
            if (dayMultipleDropdown && dayMultipleDropdown.length < 2) {
               setMultipleDaysErrorMessage('Minimum 2 days required');
            }
         }
      } else if (type === 'three_days_a_week') {
         if (name && type && firstDoseTime && dayMultipleDropdown && dayMultipleDropdown.length === 3) {
            saveFunction();
         } else {
            setValidation(true);
            if (!dayMultipleDropdown) {
               setMultipleDaysErrorMessage('Minimum 3 days required');
            }
            if (dayMultipleDropdown && dayMultipleDropdown.length < 3) {
               setMultipleDaysErrorMessage('Minimum 3 days required');
            }
         }
      } else if (type === 'five_days_a_week') {
         if (name && type && firstDoseTime && dayMultipleDropdown && dayMultipleDropdown.length === 5) {
            saveFunction();
         } else {
            setValidation(true);
            if (!dayMultipleDropdown) {
               setMultipleDaysErrorMessage('Minimum 5 days required');
            }
            if (dayMultipleDropdown && dayMultipleDropdown.length < 5) {
               setMultipleDaysErrorMessage('Minimum 5 days required');
            }
         }
      } else if (type === 'others') {
         if (othersType) {
         } else {
            setValidation(true);
            return;
         }
         if (othersType?.value === 'specific_days_of_the_week') {
            if (
               name &&
               type &&
               firstDoseTime &&
               othersType &&
               dayMultipleDropdown &&
               dayMultipleDropdown.length >= 1 &&
               dayMultipleDropdown.length <= 7
            ) {
               saveFunction();
            } else {
               setValidation(true);
               if (!dayMultipleDropdown) {
                  setMultipleDaysErrorMessage('Minimum 1 day required');
               }
               if (dayMultipleDropdown && dayMultipleDropdown.length < 1) {
                  setMultipleDaysErrorMessage('Minimum 1 day required');
               }
            }
         } else if (othersType?.value === 'every_number_of_days') {
            if (name && type && othersType && firstDoseTime && intervalCount && pillStartDate) {
               saveFunction();
            } else {
               setValidation(true);
            }
         }
      }
   }, [
      name,
      changedDoseInformation,
      type,
      timeArray,
      firstDoseTime,
      pillStartDate,
      daysArray,
      othersType,
      intervalCount,
      dayMultipleDropdown,
      daySingleDropdown,
      activeStatus,
   ]);

   const saveFunction = useCallback(() => {
      const pillRemainderEditRequest = {
         id: props.pillItem?.pill_rem_medicine_id || props.pillItemMedication?.id || 0,
         name: name?.name || '',
         drug_id: name?.drug_id || '',
         dose_information: changedDoseInformation ? changedDoseInformation : '',
         unit: name?.unit || '',
         type: type,
         pill_rem_med_times: timeArray,
         first_dose_time: firstDoseTime,
         start_date: pillStartDate,
         pill_rem_med_days:
            type === 'once_a_week' ? (daySingleDropdown ? [daySingleDropdown] : null) : dayMultipleDropdown,
         others_type: othersType?.value,
         others_interval_days: intervalCount ? intervalCount : 0,
         statusActive: activeStatus === true ? 1 : 0,
         from_type: props?.isFromMyProfile ? 'non_cancer' : 'pill',
      };
      dispatch(pillRemainderEditSagaAction(pillRemainderEditRequest));
   }, [
      name,
      changedDoseInformation,
      type,
      timeArray,
      firstDoseTime,
      pillStartDate,
      daysArray,
      othersType,
      intervalCount,
      dayMultipleDropdown,
      daySingleDropdown,
      activeStatus,
   ]);

   useEffect(() => {
      const getOneRequest = {
         id: props?.pillItem?.pill_rem_medicine_id || props.pillItemMedication?.id || 0,
      };
      dispatch(pillRemainderGetOneSagaAction(getOneRequest));
      setActiveStatus(props.pillItemMedication?.status === 1 || false);
      return () => {
         dispatch(pillRemainderGetOneReset());
      };
   }, []);

   useEffect(() => {
      if (props?.isView) {
         setDisableBackdropClick(true);
         setDisableEscapeKeyDown(true);
      }
   }, []);

   const confirmToClose = () => {
      const getAlert = () => (
         <SweetAlert
            info
            confirmBtnText="Yes"
            showCloseButton={false}
            showCancel
            cancelBtnBsStyle="cancelBtn"
            confirmBtnBsStyle="successBtn"
            cancelBtnText="No"
            closeOnClickOutside={false}
            focusCancelBtn
            allowEscape={false}
            onCancel={() => {
               setAlert(null);
            }}
            title="Are you sure"
            onConfirm={() => {
               setAlert(null);
               setEventTrack(PILL_REMAINDER_EVENTS.EDIT_MEDICATION_CANCEL);
               props.handleClose();
            }}
         >
            <div className="closed_content">{`You want to cancel this edit dose?`}</div>
         </SweetAlert>
      );
      setAlert(getAlert);
   };

   const drawerToClose = (anchor: any, open: any) => (event: any) => {
      if (open === false) {
         if (props.isView) {
            setEventTrack(PILL_REMAINDER_EVENTS.EDIT_MEDICATION_CANCEL);
            return props.handleClose();
         } else {
            return confirmToClose();
         }
      }
      if (
         event.type === 'keydown' &&
         ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
      ) {
      }
   };

   return (
      <>
         {alert}
         {(isLoadingPillRemainder || isLoadingEditPillRemainder) && <Loading />}
         {pillRemainderErrorMessage && (
            <InfoTimerAlert
               error
               confirmBtnText={`Okay!`}
               confirmBtnBsStyle={`errorBtn`}
               title={pillRemainderErrorMessage}
               onConfirm={() => hideAlert('errorAlert')}
            />
         )}
         {/* {pillRemainderEditErrorMessage && (
            <InfoTimerAlert
               error
               confirmBtnText={`Okay!`}
               confirmBtnBsStyle={`errorBtn`}
               title={pillRemainderEditErrorMessage}
               onConfirm={() => hideAlert('errorAlertEdit')}
            />
         )}
         {pillRemainderEditSuccessMessage && (
            <InfoTimerAlert
               success
               confirmBtnText={`Okay!`}
               confirmBtnBsStyle={`successBtn`}
               title={pillRemainderEditSuccessMessage}
               onConfirm={() => hideAlert('successAlertPillEdit')}
            />
         )} */}
         {drugsError && (
            <InfoTimerAlert
               error
               confirmBtnText={`Okay!`}
               confirmBtnBsStyle={`errorBtn`}
               title={drugsError}
               onConfirm={() => hideAlert('errorAlertDrug')}
            />
         )}
         <Drawer
            anchor={rightAnchor}
            open={true}
            onClose={drawerToClose(rightAnchor, false)}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}
            className="onco__schedule style-7"
         >
            <Box component="div" role="presentation" className="style-7">
               <CardHeader
                  style={{ backgroundColor: '#007EDD', color: 'white' }}
                  avatar={<span></span>}
                  action={
                     <>
                        {props.isView && (
                           <IconButton
                              aria-label="settings"
                              onClick={() => {
                                 props.setMedicationEdit(false);
                              }}
                           >
                              <Edit
                                 style={{ color: 'rgba(255, 255, 255, 0.24)', top: '8px', position: 'relative' }}
                                 fontSize="large"
                              />
                           </IconButton>
                        )}
                        <IconButton aria-label="settings" onClick={drawerToClose(rightAnchor, false)}>
                           <Cancel
                              style={{ color: 'rgba(255, 255, 255, 0.24)', top: '8px', position: 'relative' }}
                              fontSize="large"
                           />
                        </IconButton>
                     </>
                  }
                  title={
                     <Typography variant="h5" className="med-modal-title">
                        {props.isView ? `View Dose` : `Edit Dose`}
                     </Typography>
                  }
               />
               <Box p={3} mb={6}>
                  <Typography className="pill-med-name">Medicine name :</Typography>
                  <Box className="pill-input-box col-12" mt={2}>
                     {/* <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className="pill-add-input"
                        fullWidth
                        value={name}
                        onChange={changeName}
                        placeholder="Type here"
                     /> */}
                     <Select
                        placeholder=""
                        inputValue={searchWord}
                        isDisabled={props.isView}
                        className="col-10"
                        isLoading={isLoadingDrugList}
                        loadingMessage={(obj: { inputValue: string }) => 'Loading...'}
                        options={drugListDropdown}
                        value={drugValue}
                        onChange={(value: ValueType<pillRemainderPageTypes.dropDownPropsName, false>) => {
                           setDrugValue({
                              title: value?.title || '',
                              label: value?.label || '',
                              value: String(value?.value) || '',
                              dose_information: value?.dose_information || '',
                              unit: value?.unit || '',
                           });
                           setName({
                              name: value?.label || '',
                              drug_id: value?.value ? String(value?.value) : '',
                              dose_information: value?.dose_information || '',
                              unit: value?.unit || '',
                           });
                           setChangedDoseInformation(value?.dose_information || '');
                           setSearchWord('');
                           setAddSearchWord('');
                        }}
                        onInputChange={(value: string, actionMeta: InputActionMeta) => {
                           searchName(value, actionMeta);
                        }}
                     />
                     {!props.isView &&
                        (searchWord ? (
                           <Button
                              className="pill-add-btn col-2"
                              onClick={() => {
                                 clickAdd();
                              }}
                           >
                              <img src={Add} alt="img" />
                              &nbsp; ADD
                           </Button>
                        ) : null)}
                  </Box>

                  {addSearchWord && (
                     <div className="col-12 mt-2 p-0 line-auto-add">
                        <div className="mt-2 pill-name-drawer-search">
                           <div>
                              <label className="pl-2 pr-2 mb-1 mt-1 ">{addSearchWord}</label>
                           </div>
                           <div>
                              <i
                                 className="fa fa-close iconRound ml-2 mr-2"
                                 onClick={() => {
                                    setSearchWord('');
                                    setAddSearchWord('');
                                    setDrugValue(null);
                                    setName(null);
                                 }}
                              />
                           </div>
                        </div>
                     </div>
                  )}
                  {<Box className="errorMsg mt-2">{validation ? (name ? '' : 'Drug cannot be blank') : null}</Box>}

                  <Typography className="pill-med-name mt-3">Dose info :</Typography>
                  <Box mt={2} className="dose-info-textarea">
                     <TextField
                        id="outlined-basic"
                        disabled={props.isView}
                        // className="pill-input-box"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        defaultValue="Default Value"
                        value={changedDoseInformation}
                        onChange={(e: any) => {
                           setChangedDoseInformation(e.target.value);
                        }}
                     />
                  </Box>

                  <Box mt={2}>
                     <Typography className="pill-med-name">Reminder times :</Typography>

                     <Box className={`pill-auto-complete ${props.isView ? 'hand-i-default' : ''}`}>
                        <Autocomplete
                           id="auto-complete"
                           disabled={props.isView}
                           autoComplete
                           includeInputInList
                           options={types}
                           value={typeDropdown}
                           onChange={(_, value: pillRemainderPageTypes.dropDownProps | null) => {
                              changeType(value);
                           }}
                           noOptionsText={''}
                           getOptionLabel={(option: any) => option.title}
                           renderOption={(option) => (
                              <React.Fragment>
                                 <Grid
                                    container
                                    spacing={2}
                                    className={`doctor__list ${props.isView ? 'hand-i-default' : ''}`}
                                 >
                                    <Grid item xs={12} sm container>
                                       <Grid item xs container direction="column" spacing={2}>
                                          <Grid item xs>
                                             <Typography
                                                gutterBottom
                                                variant="subtitle1"
                                                className="doctor-namenormaltext"
                                             >
                                                {option.title}
                                             </Typography>
                                             {/* <Box className="auto-complete-divider">
                                                <Divider />
                                             </Box> */}
                                          </Grid>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                              </React.Fragment>
                           )}
                           renderInput={(params) => (
                              <TextField
                                 {...params}
                                 disabled={true}
                                 placeholder="Select Type"
                                 margin="normal"
                                 variant="outlined"
                                 className="doctor-namenormaltext"
                              />
                           )}
                        />
                     </Box>
                     {<Box className="errorMsg">{validation ? (type ? '' : 'Type cannot be blank') : null}</Box>}

                     {type === 'others' && (
                        <>
                           <Box className={`pill-auto-complete ${props.isView ? 'hand-i-default' : ''}`}>
                              <Autocomplete
                                 id="auto-complete"
                                 disabled={props.isView}
                                 autoComplete
                                 includeInputInList
                                 options={Others}
                                 noOptionsText={''}
                                 value={othersType}
                                 onChange={(_, value: pillRemainderPageTypes.dropDownProps | null) => {
                                    changeOthersType(value);
                                 }}
                                 getOptionLabel={(option: any) => option.title}
                                 renderOption={(option) => (
                                    <React.Fragment>
                                       <Grid container spacing={2} className="doctor__list">
                                          <Grid item xs={12} sm container>
                                             <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                   <Typography
                                                      gutterBottom
                                                      variant="subtitle1"
                                                      className="doctor-namenormaltext"
                                                   >
                                                      {option.title}
                                                   </Typography>
                                                   {/* <Divider /> */}
                                                </Grid>
                                             </Grid>
                                          </Grid>
                                       </Grid>
                                    </React.Fragment>
                                 )}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       disabled={true}
                                       placeholder="Select Others"
                                       margin="normal"
                                       variant="outlined"
                                       className="doctor-namenormaltext"
                                    />
                                 )}
                              />
                           </Box>
                           <Box className="errorMsg">
                              {validation ? (othersType ? '' : 'Others type cannot be blank') : null}
                           </Box>
                        </>
                     )}
                     {type === 'once_a_week' ||
                     type === 'two_days_a_week' ||
                     type === 'three_days_a_week' ||
                     type === 'five_days_a_week' ||
                     othersType?.value === 'specific_days_of_the_week' ? (
                        <>
                           <Box>
                              <Autocomplete
                                 id="auto-complete"
                                 disabled={props.isView}
                                 autoComplete
                                 includeInputInList
                                 options={days}
                                 value={type === 'once_a_week' ? daySingleDropdown : undefined}
                                 onChange={(_, value: pillRemainderPageTypes.dropDownProps | null) => {
                                    changeSingleDay(value);
                                 }}
                                 noOptionsText={''}
                                 getOptionLabel={(option: any) => option.title}
                                 renderOption={(option) => (
                                    <React.Fragment>
                                       <Grid container spacing={2} className="doctor__list">
                                          <Grid item xs={12} sm container>
                                             <Grid item xs container direction="column" spacing={2}>
                                                <Grid item xs>
                                                   <Typography
                                                      gutterBottom
                                                      variant="subtitle1"
                                                      className="doctor-namenormaltext"
                                                   >
                                                      {option.title}
                                                   </Typography>
                                                   {/* <Divider /> */}
                                                </Grid>
                                             </Grid>
                                          </Grid>
                                       </Grid>
                                    </React.Fragment>
                                 )}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       disabled={true}
                                       placeholder="Select Day"
                                       margin="normal"
                                       variant="outlined"
                                       className="doctor-namenormaltext"
                                    />
                                 )}
                              />
                           </Box>
                           <Box className="errorMsg">
                              {validation
                                 ? type === 'once_a_week'
                                    ? daySingleDropdown
                                       ? ''
                                       : 'Day cannot be blank'
                                    : null
                                 : null}
                           </Box>
                           {<Box className="errorMsg">{validation ? multipleDaysErrorMessage : null}</Box>}

                           <div className="col-12 p-0 line-auto-add">
                              <div className="row">
                                 <div className="col-12">
                                    <ul>
                                       {dayMultipleDropdown &&
                                          dayMultipleDropdown.map(
                                             (data: pillRemainderPageTypes.dropDownProps, i: number) => {
                                                return (
                                                   <li key={i}>
                                                      <label className="pl-2 pr-2 mb-1 mt-1">{data.title}</label>
                                                      {!props.isView && (
                                                         <i
                                                            onClick={() => {
                                                               clickRemoveMultipleDays(data, i);
                                                            }}
                                                            className="fa fa-close iconRound ml-2 mr-2"
                                                         />
                                                      )}
                                                   </li>
                                                );
                                             }
                                          )}
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </>
                     ) : null}

                     {type === 'every_two_days' ||
                     type === 'every_fourteen_days' ||
                     type === 'every_twenty_one_days' ||
                     type === 'every_twenty_eight_days' ||
                     othersType?.value === 'every_number_of_days' ? (
                        <>
                           <Box>
                              <Box width="100%" mt={2} className="edit-datepicker">
                                 <DatePicker
                                    size="lg"
                                    disabled={props.isView}
                                    placeholder="Select Date"
                                    placement="top"
                                    value={pillStartDateTime}
                                    onChange={(value: Date) => {
                                       changeDate(value);
                                    }}
                                    format={Constant.DATE_FORMAT}
                                    disabledDate={(date) => isBeforeYesterday(date)}
                                 />
                              </Box>
                           </Box>
                           <Box className="errorMsg">
                              {validation ? (pillStartDate ? '' : 'Start date cannot be blank') : null}
                           </Box>
                        </>
                     ) : null}

                     {type === 'every_two_days' ||
                     type === 'every_fourteen_days' ||
                     type === 'every_twenty_one_days' ||
                     type === 'every_twenty_eight_days' ||
                     type === 'once_a_week' ||
                     type === 'two_days_a_week' ||
                     type === 'three_days_a_week' ||
                     type === 'five_days_a_week' ||
                     othersType?.value === 'specific_days_of_the_week' ||
                     othersType?.value === 'every_number_of_days' ? (
                        <>
                           <Box>
                              <Box width="100%">
                                 <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimePicker
                                       inputVariant="standard"
                                       margin="normal"
                                       id="time-picker"
                                       className="time-pick form-control"
                                       label=""
                                       disabled={props.isView}
                                       placeholder="Select time"
                                       value={firstDoseDateTime}
                                       onChange={(date: MaterialUiPickersDate) => {
                                          changeTime(date);
                                       }}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <IconButton>
                                                   <AccessTimeIcon />
                                                </IconButton>
                                             </InputAdornment>
                                          ),
                                       }}
                                    />
                                 </MuiPickersUtilsProvider>
                              </Box>
                           </Box>
                           <Box className="errorMsg mt-3 pt-2">
                              {validation ? (firstDoseTime ? '' : 'Dose time cannot be blank') : null}
                           </Box>
                        </>
                     ) : null}
                     {othersType?.value === 'every_number_of_days' ? (
                        <>
                           <Typography className="pill-med-name mt-5">Other interval days :</Typography>
                           {/* <Box className="pill-input-box" mt={2}>
                              <TextField
                                 id="outlined-basic"
                                 variant="outlined"
                                 className="pill-add-input"
                                 fullWidth
                                 disabled={props.isView}
                                 value={intervalCount}
                                 onChange={changeIntervalCount}
                                 onKeyPress={isNumber}
                                 placeholder="Type here"
                              />
                           </Box> */}

                           <Box display="flex" justifyContent="center" mt={2} mb={5}>
                              <ButtonGroup variant="contained" className="btn-grp-schedule">
                                 <Button
                                    style={{ textIndent: '8px' }}
                                    className={intervalCount === 2 ? 'btn-schedule-disable' : 'btn-schedule'}
                                    onClick={clickDecrement}
                                    disabled={props.isView ? true : intervalCount === 2 ? true : false}
                                 >
                                    -
                                 </Button>
                                 <Box className="btn-schedule-duration">
                                    <Button className="btn-schedule-time" disabled>
                                       {intervalCount}
                                    </Button>
                                 </Box>
                                 <Button className={'btn-schedule'} onClick={clickIncrement} disabled={props.isView}>
                                    +
                                 </Button>
                              </ButtonGroup>
                           </Box>
                           <Box className="errorMsg">
                              {validation ? (intervalCount ? '' : 'Interval count cannot be blank') : null}
                           </Box>
                        </>
                     ) : null}
                  </Box>
                  <SelectTime
                     type={type}
                     timeArray={timeArray}
                     validation={validation}
                     getTimesArray={getTimesArray}
                     clickSave={clickSave}
                     isView={props.isView}
                     pillItemMedication={props.pillItemMedication}
                     handleClose={props.handleClose}
                     clearMedications={props.clearMedications}
                     activeStatus={activeStatus}
                     getActiveStatus={getActiveStatus}
                     isFromMyProfile={props?.isFromMyProfile}
                  />
               </Box>
            </Box>
         </Drawer>
      </>
   );
};

export { PillRemainderDrawer };
