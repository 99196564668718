import { createSlice } from '@reduxjs/toolkit';
import { eventTrackTypes } from 'types';

const initialState: eventTrackTypes.IEventTrackReducer = {
   data: null,
};

export const eventTrackSlice = createSlice({
   name: 'eventTrack',
   initialState: initialState,
   reducers: {
      eventTrackRequest: (state) => {
         return {
            ...state,
         };
      },
      eventTrackSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            data: payload.data,
         };
      },
      eventTrackFailed: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            data: payload.data,
         };
      },
   },
});
