import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   FEED_POST_USER_LINK,
   FEED_POST_USER_LINK_USER_WITHOUT_AUTH,
   FEED_POST_USER_LINK_USER_WITH,
} from 'reduxToolKit/actions';
import { commonSlice, feedShareLinkSlice, feedShareLinkUserSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { feedShareLinkRequest, feedShareLinkSuccess, feedShareLinkFailed } = feedShareLinkSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const feedPostShareLinkSaga = function* (
   feedPostShareLinkPayload: PayloadAction<feedSagaTypes.IFeedPostUserLinkParams>
) {
   yield put(feedShareLinkRequest(feedPostShareLinkPayload.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const data = feedPostShareLinkPayload.payload.data;
      const url = API.feedShareLink;
      const response: AxiosResponse = yield apiCall({ data, peopleMayYouKnow, checkUser, ...url });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(feedShareLinkSuccess(responseData));
      } else {
         yield put(feedShareLinkFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const { feedShareLinkUserRequest, feedShareLinkUserSuccess, feedShareLinkUserFailed } = feedShareLinkUserSlice.actions;

const feedPostShareLinkUserWithoutAuthSaga = function* (
   feedPostShareLinkUserPayload: PayloadAction<feedSagaTypes.IFeedPostUserLinkUserParams>
) {
   yield put(feedShareLinkUserRequest(feedPostShareLinkUserPayload.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const data = feedPostShareLinkUserPayload.payload;
      const url = API.feedShareLinkUserWithoutAuth;
      const response: AxiosResponse = yield apiCall({ data, peopleMayYouKnow, checkUser, ...url });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(feedShareLinkUserSuccess(responseData));
      } else {
         yield put(feedShareLinkUserFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const feedPostShareLinkUserWithAuthSaga = function* (
   feedPostShareLinkUserPayload: PayloadAction<feedSagaTypes.IFeedPostUserLinkUserParams>
) {
   yield put(feedShareLinkUserRequest(feedPostShareLinkUserPayload.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const data = feedPostShareLinkUserPayload.payload;
      const url = API.feedShareLinkUserWithAuth;
      const response: AxiosResponse = yield apiCall({ data, peopleMayYouKnow, checkUser, ...url });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(feedShareLinkUserSuccess(responseData));
      } else {
         yield put(feedShareLinkUserFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeFeedPostShareLinkRequest = function* () {
   yield takeEvery(FEED_POST_USER_LINK, feedPostShareLinkSaga);
   yield takeEvery(FEED_POST_USER_LINK_USER_WITHOUT_AUTH, feedPostShareLinkUserWithoutAuthSaga);
   yield takeEvery(FEED_POST_USER_LINK_USER_WITH, feedPostShareLinkUserWithAuthSaga);
};
