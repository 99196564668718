import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MODAL_UPDATE_CLINIC_TRIAL_SAGA } from 'reduxToolKit/actions';
import { commonSlice, modalUpdateClinicTrialSlice } from 'reduxToolKit/reducers';
import { modalClinicTrialSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   modalUpdateClinicTrialRequest,
   modalUpdateClinicTrialSuccess,
   modalUpdateClinicTrialFailed,
} = modalUpdateClinicTrialSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const modalUpdateClinicTrialSaga = function* (
   clinicTrialPayload: PayloadAction<modalClinicTrialSagaTypes.IModalClinicTrialSagaPayload>
) {
   yield put(modalUpdateClinicTrialRequest(clinicTrialPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = clinicTrialPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.updateClinicalTrial });
      if (response.status === 200) {
         const responseData = response.data;
         const clinicTrialList = {
            message: responseData?.message || '',
         };
         yield put(modalUpdateClinicTrialSuccess(clinicTrialList));
      } else {
         yield put(modalUpdateClinicTrialFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeUpdateClinicTrialRequest = function* () {
   yield takeEvery(MODAL_UPDATE_CLINIC_TRIAL_SAGA, modalUpdateClinicTrialSaga);
};
