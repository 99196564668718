import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_LINES_OF_THERAPY_HISTORY } from 'reduxToolKit/actions';
import { commonSlice, getLineOfTherapyHistorySlice } from 'reduxToolKit/reducers';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getLineOfTherapyHistoryRequest,
   getLineOfTherapyHistorySuccess,
   getLineOfTherapyHistoryFailed,
} = getLineOfTherapyHistorySlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;
const getLineOfTherapyHistorySaga = function* (prescriptionTypePayload: any) {
   yield put(getLineOfTherapyHistoryRequest(prescriptionTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = prescriptionTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getLinesofTherapyHistory });
      if (response.status === 200) {
         const responseData = response.data;
         const getLineOfTherapyList = {
            list: responseData?.data || [],
            message: responseData?.message || messages.success,
         };
         yield put(getLineOfTherapyHistorySuccess(getLineOfTherapyList));
      } else {
         yield put(getLineOfTherapyHistoryFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takegetLineOfTherapyHistory = function* () {
   yield takeEvery(GET_LINES_OF_THERAPY_HISTORY, getLineOfTherapyHistorySaga);
};
