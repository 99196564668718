import { createSlice } from '@reduxjs/toolkit';
import { oncDriveReducerTypes } from 'types';

const initialState: oncDriveReducerTypes.IOncDriveDeleteFolderAndFilesReducer = {
   isLoading: false,
   error: '',
   message: '',
};
export const oncDriveDeleteFolderAndFilesSlice = createSlice({
   name: 'oncDriveDeleteFolderAndFiles',
   initialState: initialState,
   reducers: {
      oncDriveDeleteFolderAndFilesRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      oncDriveDeleteFolderAndFilesSuccess: (state, action: any) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      oncDriveDeleteFolderAndFilesFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      oncDriveDeleteFolderAndFilesReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
