import ViewEstimateIcon from 'assets/askDoc/viewEstimateIcon.svg';
import Addendum from 'assets/icons/addendum-icon.svg';
import AnswerIcon from 'assets/icons/answer-icon.svg';
import UserIcon from 'assets/icons/case-details-user.svg';
import CaseHistoryIcon from 'assets/icons/case-history-icon.svg';
import ConversationIcon from 'assets/icons/conversation-icon.svg';
import GrayArrow from 'assets/icons/gray-arrow-icon.svg';

import { ASK_A_DOC_EVENTS } from './eventTrack/askADocEventConstants';

export const menuNameList = {
   CASE_DETAILS: { menuName: 'Case Details', componentName: 'caseDetails' },
   CONVERSATION: { menuName: 'Conversation', componentName: 'conversation' },
   VIEW_ANSWER: { menuName: 'View Answer', componentName: 'viewAnswer' },
   ADDENDUM: { menuName: 'Add Addendum', componentName: 'addendum' },
   VIEW_ADDENDUM: { menuName: 'View Addendum', componentName: 'addendum' },
   VIEW_ESTIMATE: { menuName: 'View Estimate', componentName: 'viewEstimate' },
   CASE_HISTORY: { menuName: 'Case History', componentName: 'caseHistory' },
};

export const askADocRequestMenuList = [
   {
      id: 1,
      imgIcon: UserIcon,
      text: menuNameList.CASE_DETAILS.menuName,
      name: menuNameList.CASE_DETAILS.componentName,
      navArrow: GrayArrow,
   },
   {
      id: 2,
      imgIcon: ViewEstimateIcon,
      text: menuNameList.VIEW_ESTIMATE.menuName,
      name: menuNameList.VIEW_ESTIMATE.componentName,
      navArrow: GrayArrow,
   },
   {
      id: 3,
      imgIcon: ConversationIcon,
      text: menuNameList.CONVERSATION.menuName,
      name: menuNameList.CONVERSATION.componentName,
      navArrow: GrayArrow,
   },
   {
      id: 4,
      imgIcon: AnswerIcon,
      text: menuNameList.VIEW_ANSWER.menuName,
      name: menuNameList.VIEW_ANSWER.componentName,
      navArrow: GrayArrow,
   },
   {
      id: 5,
      imgIcon: Addendum,
      text: menuNameList.ADDENDUM.menuName,
      name: menuNameList.ADDENDUM.componentName,
      navArrow: GrayArrow,
   },
   {
      id: 6,
      imgIcon: Addendum,
      text: menuNameList.VIEW_ADDENDUM.menuName,
      name: menuNameList.VIEW_ADDENDUM.componentName,
      navArrow: GrayArrow,
   },
   {
      id: 7,
      imgIcon: CaseHistoryIcon,
      text: menuNameList.CASE_HISTORY.menuName,
      name: menuNameList.CASE_HISTORY.componentName,
      navArrow: GrayArrow,
   },
];

export const statusObjClassName: any = {
   0: 'estimate',
   1: 'complete',
   2: 'reject',
   3: 'inprogress',
   4: 'await',
   5: 'cancel',
};

export const connectionObject: any = {
   0: 'req-connection',
   1: 'req-connection',
   2: 'cancel-connection',
   3: 'respond-connection',
};

export const statusObjName: any = {
   0: 'Pending',
   1: 'Completed',
   2: 'Rejected',
   3: 'In Progress',
   4: 'Awaiting Payment',
   5: 'Canceled',
};

export const CASE_DETAILS_STATUS: any = {
   PENDING: 0,
   COMPLETED: 1,
   REJECTED: 2,
   IN_PROGRESS: 3,
   AWAITING_PAYMENT: 4,
   CANCELED: 5,
};

export const connectionObjName: any = {
   0: 'Request Connection',
   1: 'Send Message',
   2: 'Cancel Request',
   3: 'Respond Request',
   4: 'Go to chat',
};

export const EXAMPLE_QUESTIONS = 'example-questions';
export const ASK_A_DOC_SUBSCRIPTION = 'subscription';
export const ASK_A_DOC_ESTIMATE = 'estimate';
export const SUBSCRIPTION_ONE_PLAN = 'one_plan';
export const SUBSCRIPTION_ADVANCE_PLAN = 'advance_plan';

export const ASK_A_DOC_QUESTION_LIMIT = 1;
export const ASK_A_DOC_ONE_TIME_PURCHASE_QUESTION_LIMIT = 3;
export const ASK_A_DOC_VIEW_PANELS_LIMIT = 11;
export const ASK_A_DOC_PATIENT_EMPTY_PAGE_TITLE = 'Care at any time, from anywhere.';
export const ASK_DOC_PATIENT_EMPTY_PAGE_CONTENT = `The Ask-a-Doc team is able to provide personalized advice on current treatment, medications, side effects, genetic testing, nutrition care, emotional care, or try to assist with navigating the financial challenges of cancer care.

   We're here to augment and not replace your in-person care. You'll have the opportunity to upload relevant reports/documents and ask clarifying questions from the provider.`;
export const ASK_A_DOC_PHYSICIAN_EMPTY_PAGE_TITLE = 'No Request found!';
export const ASK_DOC_PHYSICIAN_EMPTY_PAGE_CONTENT =
   'You are not a member of any of our cancer clinics. Please contact <a href="mailto:support@oncopower.org">support@oncopower.org</a> to join a clinic and start receiving consults from patients.';
export const SUBSCRIPTION_STRIPE_PAYMENT_SUCCESS = 'Payment was successful.';
export const ASK_A_DOC_PLAN_ERROR_MESSAGE = 'The coupon code cannot be blank';
export const OTHER_PHY_ANSWERED_REQUEST = 'OTHER_PHYSICIAN_ANSWERED';

export const createAskDocSteps: any = {
   1: ASK_A_DOC_EVENTS.ASK_A_DOC_SUBSCRIPTION_REQUEST_ADD_QUESTION_BACK,
   2: ASK_A_DOC_EVENTS.ASK_A_DOC_SUBSCRIPTION_REQUEST_ADD_DOCUMENT_BACK,
   0: ASK_A_DOC_EVENTS.ASK_A_DOC_SUBSCRIPTION_REQUEST_SELECT_PANEL_BACK,
};

export const ASK_A_DOC_CREATE_REQUEST_SUCCESS_TITLE = 'Ask-a-Doc request successfully submitted';
export const ASK_A_DOC_CREATE_REQUEST_SUCCESS_TEXT =
   'Thank you and we received your request. Please contact 911 (or local emergency number in your country) if this is a medical emergency. We appreciate your patience as our oncologists may take up to 72 hours to respond to your request. It may take longer, if it is a weekend or your question requires research from our oncologists.';
export const ASK_A_DOC_SUBSCRIPTION_PAYMENT_ERROR_MESSAGE =
   'If your wallet funds were debited, they will be refunded within 1-2 hours.';
export const ASK_A_DOC_SUBSCRIPTION_PAYMENT_FAILED = 'Payment failed';
export const ASK_A_DOC_SUBSCRIPTION_PAYMENT_WAS_FAILED = 'Payment was failed';
export const PLAN_AMOUNT = 'Plan amount';
export const PLAN_AMOUNT_PER_MONTH = 'Plan amount per month';
export const ASK_A_DOC_FEED_DIALOG_TITLE_TEXT =
   'Welcome to OncoPower! As a welcome gift for joining our community, we have credited you with a complimentary one month OncoPower Premium membership (value $99.99). This includes on demand consultations with a panel of top US cancer specialists, and wellness experts.  Easy cancellation anytime.';
export const ASK_A_DOC_FEED_DIALOG_TITLE_TEXT_YES =
   'I want access to hundreds of oncologists and other exclusive features';
export const ASK_A_DOC_FEED_DIALOG_TITLE_TEXT_NO = 'I do not want enhanced cancer care now, but will consider later';
