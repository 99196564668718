import React from 'react';

const Loading = () => {
   return (
      <div className="showLoadings ring">
         <div className="spinner" />
         <div>Please Wait..</div>
      </div>
   );
};

export default Loading;
