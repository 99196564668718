import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { ONCO_MEDITATION_LIST } from 'reduxToolKit/actions';
import { commonSlice, oncoMeditationSlice } from 'reduxToolKit/reducers';
import { oncoMeditationPageTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { oncoMeditationListRequest, oncoMeditationListSuccess, oncoMeditationListFailed } = oncoMeditationSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const oncoMeditationListSaga = function* (
   oncoMeditationListPayload: PayloadAction<oncoMeditationPageTypes.IOncoMeditationRequestProps>
) {
   yield put(oncoMeditationListRequest(oncoMeditationListPayload.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const params = oncoMeditationListPayload.payload;

      const response: AxiosResponse = yield apiCall({
         checkUser,
         peopleMayYouKnow,
         params,
         ...API.getOncoMeditationList,
      });
      if (response.status === 200) {
         const responseLastCreatedDate = response.data.data?.last_created;
         const responseData = response.data.data?.meditation;
         const usersList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response?.data?.message || messages.success,
            lastCreatedDate: responseLastCreatedDate,
         };
         yield put(oncoMeditationListSuccess(usersList));
      } else {
         yield put(oncoMeditationListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeOncoMeditationRequest = function* () {
   yield takeLatest(ONCO_MEDITATION_LIST, oncoMeditationListSaga);
};
