import { createAction } from '@reduxjs/toolkit';
import { checkLogPageTypes } from 'types';

export const ADD_DAILY_CHECK = 'ADD_DAILY_CHECK';
export const addDailyCheck = createAction<checkLogPageTypes.IAddCheckLogType>(ADD_DAILY_CHECK);

export const DAILY_CHECK_LIST = 'DAILY_CHECK_List';
export const dailyCheckList = createAction<checkLogPageTypes.ICheckLogType>(DAILY_CHECK_LIST);

export const DAILY_CHECK_INS_MASTER_LIST = 'DAILY_CHECK_INS_MASTER_LIST';
export const dailyCheckInsMasterList = createAction(DAILY_CHECK_INS_MASTER_LIST);

export const DAILY_CHECK_INS_LIST = 'DAILY_CHECK_INS_LIST';
export const dailyCheckInsList = createAction<checkLogPageTypes.IDailyCheckInsListParams>(DAILY_CHECK_INS_LIST);

export const DAILY_CHECK_INS_GET_ONE = 'DAILY_CHECK_INS_GET_ONE';
export const dailyCheckInsGetOne = createAction<checkLogPageTypes.IDailyCheckInsGetOneParam>(DAILY_CHECK_INS_GET_ONE);

export const DAILY_CHECK_INS_ADD = 'DAILY_CHECK_INS_ADD';
export const dailyCheckInsAdd = createAction<checkLogPageTypes.IDailyCheckInsAddParam>(DAILY_CHECK_INS_ADD);

export const DAILY_CHECK_INS_ADD_POST = 'DAILY_CHECK_INS_ADD_POST';
export const dailyCheckInsAddPost = createAction<checkLogPageTypes.IDailyCheckInsAddPostParam>(
   DAILY_CHECK_INS_ADD_POST
);

export const UPDATED_CHECK_INS = 'UPDATED_CHECK_INS';
export const updatedCheckIns = createAction(UPDATED_CHECK_INS);

export const CONTINUOUS_CHECK_INS = 'CONTINUOUS_CHECK_INS';
export const continuousCheckIns = createAction(CONTINUOUS_CHECK_INS);

// badges

export const CHECK_IN_STREAKS_MASTER_LIST = 'CHECK_IN_STREAKS_MASTER_LIST';
export const checkInStreaksMasterList = createAction<checkLogPageTypes.ICheckInsStreaksMasterList>(
   CHECK_IN_STREAKS_MASTER_LIST
);

export const CHECK_IN_STREAKS_USER_LIST = 'CHECK_IN_STREAKS_USER_LIST';
export const checkInStreaksUserList = createAction<checkLogPageTypes.ICheckInsStreaksMasterList>(
   CHECK_IN_STREAKS_USER_LIST
);

export const CHECK_IN_STREAK_FEED = 'CHECK_IN_STREAK_FEED';
export const checkInStreakFeed = createAction<checkLogPageTypes.ICheckInsStreakFeed>(CHECK_IN_STREAK_FEED);
