import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_APPOINTMENT_COMMENTS } from 'reduxToolKit/actions';
import { commonSlice, getAppointmentCommentSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';
//import { viewAppointmentSagaTypes } from 'types';

const {
   getAppointmentCommentView,
   getAppointmentCommentViewSuccess,
   getAppointmentCommentViewFailed,
} = getAppointmentCommentSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getAppointmentComment = function* (comment: any) {
   yield put(getAppointmentCommentView(comment.payload));
   try {
      const params = comment.payload;
      const response: AxiosResponse = yield apiCall({ params, ...API.getAppointmentComment });
      if (response.status === 200) {
         const responseData = response.data.data;
         const appointmentMessagesList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message,
         };
         yield put(getAppointmentCommentViewSuccess(appointmentMessagesList));
      } else {
         yield put(getAppointmentCommentViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeGetAppointmentCommentRequest = function* () {
   yield takeEvery(GET_APPOINTMENT_COMMENTS, getAppointmentComment);
};
