import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { myProfileReducerTypes, myProfileSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: myProfileReducerTypes.IGetCountriesReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
};

export const userGetCountriesSlice = createSlice({
   name: 'userGetCountries',
   initialState: initialState,
   reducers: {
      userGetCountriesLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      userGetCountriesRequest: (state, action: PayloadAction<myProfileSagaTypes.IGetCountriesRequest>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: [],
         };
      },
      userGetCountriesSuccess: (state, action: PayloadAction<myProfileReducerTypes.IGetCountriesReducer>) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            isLoading: false,
            message: payload.message || messages.success,
         };
      },
      userGetCountriesFailed: (state, action: PayloadAction<myProfileReducerTypes.IGetCountriesReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      userGetCountriesResetMessage: (state) => {
         return {
            ...state,
            isLoading: false,
            message: '',
         };
      },
      userGetCountriesReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
