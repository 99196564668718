import { createAction } from '@reduxjs/toolkit';
import { oncDriveSagaTypes } from 'types';

export const ONCDRIVE_LIST_SAGA = 'ONCDRIVE_LIST_SAGA';
export const oncDriveListSagaAction = createAction<oncDriveSagaTypes.IOncDriveListSaga>(ONCDRIVE_LIST_SAGA);

export const ONCDRIVE_ADD_SAGA = 'ONCDRIVE_ADD_SAGA';
export const oncDriveAddSagaAction = createAction<oncDriveSagaTypes.IOncDriveAddSaga>(ONCDRIVE_ADD_SAGA);

export const ONCDRIVE_EDIT_SAGA = 'ONCDRIVE_EDIT_SAGA';
export const oncDriveEditSagaAction = createAction<oncDriveSagaTypes.IOncDriveAddSaga>(ONCDRIVE_EDIT_SAGA);

export const ONCDRIVE_VIEW_SAGA = 'ONCDRIVE_VIEW_SAGA';
export const oncDriveViewSagaAction = createAction<oncDriveSagaTypes.IOncDriveViewSaga>(ONCDRIVE_VIEW_SAGA);

export const ONCDRIVE_DELETE_FOLDER_AND_FILES_SAGA = 'ONCDRIVE_DELETE_FOLDER_AND_FILES_SAGA';
export const oncDriveDeleteFolderAndFilesSagaAction = createAction<oncDriveSagaTypes.IOncDriveDeleteFolderAndFilesSaga>(
   ONCDRIVE_DELETE_FOLDER_AND_FILES_SAGA
);
// Shared  OncDrive
export const ONCDRIVE_SHARE_ADD_SAGA = 'ONCDRIVE_SHARE_ADD_SAGA';
export const oncDriveShareAddSagaAction = createAction<oncDriveSagaTypes.IOncDriveShareAddSaga>(
   ONCDRIVE_SHARE_ADD_SAGA
);
// Shared List OncDrive
export const ONCDRIVE_SHARED_LIST_SAGA = 'ONCDRIVE_SHARED_LIST_SAGA';
export const oncDriveSharedListSagaAction = createAction<oncDriveSagaTypes.IOncDriveSharedListSaga>(
   ONCDRIVE_SHARED_LIST_SAGA
);
/**
 * OncDrive Shared user list
 */
export const ONCDRIVE_SHARED_USERS_LIST_SAGA = 'ONCDRIVE_SHARED_USERS_LIST_SAGA';
export const oncDriveSharedUsersListSagaAction = createAction<oncDriveSagaTypes.IOncDriveSharedUsersListSaga>(
   ONCDRIVE_SHARED_USERS_LIST_SAGA
);

/**
 * clinic patient for oncDrive Share
 */
export const ONCDRIVE_CLINIC_PATIENT_SAGA = 'ONCDRIVE_CLINIC_PATIENT_SAGA';
export const oncDriveClinicPatientSagaAction = createAction<oncDriveSagaTypes.IOncDriveClinicPatientSaga>(
   ONCDRIVE_CLINIC_PATIENT_SAGA
);

/**
 * clinic patient for oncDrive Share search
 */
export const ONCDRIVE_CLINIC_PATIENT_SEARCH_SAGA = 'ONCDRIVE_CLINIC_PATIENT_SEARCH_SAGA';
export const oncDriveClinicPatientSearchSagaAction = createAction<oncDriveSagaTypes.IOncDriveClinicPatientSaga>(
   ONCDRIVE_CLINIC_PATIENT_SEARCH_SAGA
);

/**
 * onc drive removed user
 */
export const ONCDRIVE_UNSHARED_USER_SAGA = 'ONCDRIVE_UNSHARED_USER_SAGA';
export const oncDriveUnSharedUserSagaAction = createAction<oncDriveSagaTypes.IOncDriveUnSharedUserSaga>(
   ONCDRIVE_UNSHARED_USER_SAGA
);
