// import axios, {AxiosRequestConfig} from 'axios';
import axios from 'axios';
import { loginSlice } from 'reduxToolKit/reducers';
import * as myHelper from 'utils/helpers/authHeader';
import { getUnixMilliSeconds } from 'utils/helpers/dateHelpers';

import ApiUrlConfig from './config/ApiUrlConfig';
import UrlConfig from './config/UrlConfig';
import { store } from './reduxToolKit';

// Set config defaults when creating the instance
const instance = axios.create({
   baseURL: UrlConfig.baseURL,
   //baseURL: environment.baseUrl,
});

// Alter defaults after instance has been created
instance.defaults.headers.common['Authorization'] = 'Bearer ' + myHelper.getAuth().authToken;
instance.defaults.headers.common['device-type'] = 'web';

let numberRefreshToken = 0;

// Add a request interceptor
// let request: AxiosRequestConfig;
instance.interceptors.request.use(
   (config) => {
      const token = myHelper.getAuth().authToken;
      if (token) {
         config.headers['Authorization'] = 'Bearer ' + token;
         config.headers['device-type'] = 'web';
      }
      return config;
   },
   (error) => {
      return Promise.reject(error);
   }
);

instance.interceptors.response.use(
   (response) => {
      return response;
   },
   (error) => {
      const originalRequest = error.config;
      const requestURL = originalRequest.url;
      if (error.response.status === 401) {
         if (
            requestURL === ApiUrlConfig.userLogin ||
            requestURL === ApiUrlConfig.forgotPassword ||
            requestURL === ApiUrlConfig.setPasswordViaEmail ||
            requestURL === ApiUrlConfig.emailVerification ||
            requestURL === ApiUrlConfig.resetPasswordViaEmail ||
            requestURL === ApiUrlConfig.resetPasswordViaPhone
         ) {
         } else if (
            error.response.data.error ===
            'Your account is deactivated. Contact OncoPower support at support@oncopower.org'
         ) {
            const { userAccountDeActivated } = loginSlice.actions;
            store.dispatch(userAccountDeActivated({ account: true, msg: error.response.data.error }));
         } else if (getUnixMilliSeconds() >= parseInt(myHelper.getAuth()?.tokenExp || '0')) {
            if (myHelper.getAuth().authToken === null || myHelper.getAuth().refreshToken === null) {
               myHelper.logout();
               window.location.href = '/';
               return false;
            }
            const refreshToken = myHelper.getAuth().refreshToken;
            return axios
               .post(
                  UrlConfig.baseURL + '/refresh-token',
                  {},
                  {
                     headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${refreshToken}`,
                        'device-type': 'web',
                     },
                  }
               )
               .then((res) => {
                  if (res.status === 200) {
                     if (numberRefreshToken === 0) {
                        myHelper.removeToken();
                     }
                     return res;
                  }
               })
               .then((res) => {
                  if (res && res.status === 200) {
                     if (numberRefreshToken === 0) {
                        numberRefreshToken = 1;
                        myHelper.setAuthRefreshToken(res.data.data);
                     }
                     return res;
                  }
               })
               .then((res) => {
                  if (res && res.status === 200) {
                     // instance.defaults.headers.common['Authorization'] = 'Bearer ' + myHelper.getAuth().authToken;
                     instance.defaults.headers.common['Authorization'] = 'Bearer ' + res?.data?.data?.token || '';
                     instance.defaults.headers.common['device-type'] = 'web';
                     originalRequest.headers['Authorization'] = 'Bearer ' + res?.data?.data?.token || '';
                     originalRequest.headers['device-type'] = 'web';
                     return instance(originalRequest);
                  }
               })
               .catch(() => {
                  myHelper.logout();
                  window.location.href = '/';
               });
         } else {
            //console.log('network error');
         }
      }

      return Promise.reject(error);
   }
);
export default instance;
