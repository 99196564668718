import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   ADD_ADVOCATE_SAGA,
   DELETE_PATIENT_ADVOCATE_SAGA,
   GET_ADVOCATES_SAGA,
   UPDATE_ADVOCATES_SAGA,
} from 'reduxToolKit/actions';
import { commonSlice, patientAdvocateSlice } from 'reduxToolKit/reducers';
import { patientAdvocateTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getAdvocatesRequest,
   getAdvocatesSuccess,
   patientAdvocateFailed,
   updateAdvocateRequest,
   updateAdvocateSuccess,
   updateAdvocateFailed,
   deletePatientAdvocateRequest,
   deletePatientAdvocateSuccess,
   deletePatientAdvocateFailed,
   addAdvocateRequest,
   addAdvocateSuccess,
   addAdvocateFailed,
} = patientAdvocateSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getAdvocatesSaga = function* (advocatesPayload: PayloadAction<patientAdvocateTypes.IPatientAdvocateForm>) {
   yield put(getAdvocatesRequest(advocatesPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = advocatesPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getAdvocates });
      if (response.status === 200) {
         const responseData = response.data;
         const getAdvocatesList = {
            list: responseData?.data?.list || [],
            total_records: responseData?.data?.total_records || 0,
            message: response.data?.message || messages.success,
         };
         yield put(getAdvocatesSuccess(getAdvocatesList));
      } else {
         yield put(patientAdvocateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const updateAdvocateSaga = function* (updateAdvocatePayload: PayloadAction<patientAdvocateTypes.IUpdateAdvocateForm>) {
   yield put(updateAdvocateRequest(updateAdvocatePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = updateAdvocatePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.updateAdvocate });
      if (response.status === 200) {
         const responseData = response.data;
         const updateAdvocateList = {
            message: responseData?.message || messages.success,
         };
         yield put(updateAdvocateSuccess(updateAdvocateList));
      } else {
         yield put(updateAdvocateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const deletePatientAdvocateSaga = function* (
   payloadState: PayloadAction<patientAdvocateTypes.IDeletePatientAdvocateForm>
) {
   yield put(deletePatientAdvocateRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.deletePatientAdvocate });
      if (response.status === 200) {
         const resObj = {
            message: response.data?.message || messages.success,
         };
         yield put(deletePatientAdvocateSuccess(resObj));
      } else {
         yield put(deletePatientAdvocateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const addAdvocateSaga = function* (payloadState: PayloadAction<patientAdvocateTypes.IAddAdvocateForm>) {
   yield put(addAdvocateRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addAdvocate });
      if (response.status === 200) {
         const resObj = {
            message: response.data?.message || messages.success,
         };
         yield put(addAdvocateSuccess(resObj));
      } else {
         yield put(addAdvocateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePatientAdvocatesRequest = function* () {
   yield takeEvery(GET_ADVOCATES_SAGA, getAdvocatesSaga);
   yield takeEvery(UPDATE_ADVOCATES_SAGA, updateAdvocateSaga);
   yield takeEvery(DELETE_PATIENT_ADVOCATE_SAGA, deletePatientAdvocateSaga);
   yield takeEvery(ADD_ADVOCATE_SAGA, addAdvocateSaga);
};
