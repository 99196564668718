import { createSlice } from '@reduxjs/toolkit';
import { oncDriveReducerTypes } from 'types';

const initialState: oncDriveReducerTypes.IOncDriveMyClinicPatientListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   pagination: {
      current_page: null,
      last_page: null,
      first_page_url: '',
      last_page_url: '',
      next_page_url: '',
      prev_page_url: '',
      total: 0,
   },
};
export const oncDriveMyClinicPatientListSlice = createSlice({
   name: 'oncDriveMyClinicPatientList',
   initialState: initialState,
   reducers: {
      oncDriveMyClinicPatientListViewLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      oncDriveMyClinicPatientListViewRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      oncDriveMyClinicPatientListViewSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            pagination: { ...payload.pagination },
            list: payload.pagination.current_page === 1 ? payload.list : [...state.list, ...payload.list],
            isLoading: false,
         };
      },
      oncDriveMyClinicPatientListViewFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      oncDriveMyClinicPatientListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
