import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { ASK_DOC_SINGLE_FEED_POST } from 'reduxToolKit/actions';
import { commonSlice, singleAskADocFeedListSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   singleAskADocFeedListRequest,
   singleAskADocFeedListSuccess,
   singleAskADocFeedListFailed,
} = singleAskADocFeedListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const singleFeed = function* (locumListParams: any) {
   yield put(singleAskADocFeedListRequest(locumListParams.payload));
   try {
      const url = API.getAskADocOneFeed;
      const checkUser = true;
      const goUrl = true;
      const params = locumListParams.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...url });
      if (response.status === 200) {
         const resObj = response?.data;
         yield put(singleAskADocFeedListSuccess(resObj));
      } else {
         yield put(singleAskADocFeedListFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAskSingleFeedList = function* () {
   yield takeLatest(ASK_DOC_SINGLE_FEED_POST, singleFeed);
};
