import { createSlice } from '@reduxjs/toolkit';
import { healthRecordsReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: healthRecordsReducerTypes.IHealthRecordTypeListReducer = {
   isLoading: false,
   progressLoadMore: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
};
const initialPendingState: healthRecordsReducerTypes.IPendingPrescriptionReducer = {
   isLoading: false,
   error: '',
   isPendingPrescription: false,
   isPendingPrescriptionMessage: '',
};
const initialUpdateState: healthRecordsReducerTypes.IPrescriptionUpdate = {
   error: '',
   message: '',
   isLoading: false,
};
export const rpmPrescriptionSlice = createSlice({
   name: 'rpmPrescriptionList',
   initialState: initialState,
   reducers: {
      rpmPrescriptionLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      rpmPrescriptionRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      rpmPrescriptionSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.page === 1 ? payload.list : [...state.list, ...payload.list],
            total_records: payload.total_records,
            isLoading: false,
            message: payload.message || messages.success,
         };
      },
      rpmPrescriptionFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      rpmPrescriptionMessageReset: (state) => {
         return {
            ...state,
            isLoading: false,
            message: '',
         };
      },

      rpmPrescriptionReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
export const rpmPendingPrescriptionSlice = createSlice({
   name: 'rpmPendingPrescriptionList',
   initialState: initialPendingState,
   reducers: {
      rpmPendingPrescriptionLoading: (state) => {
         return {
            ...state,
            isLoading: false,
         };
      },
      rpmPendingPrescriptionRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      rpmPendingPrescriptionSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            isPendingPrescription: payload.isPendingPrescription,
            isPendingPrescriptionMessage: payload.isPendingPrescriptionMessage,
         };
      },
      rpmPendingPrescriptionFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            error: action.payload.error,
         };
      },

      rpmPendingPrescriptionReset: () => {
         return {
            ...initialPendingState,
         };
      },
   },
});
export const rpmPrescriptionUpdateSlice = createSlice({
   name: 'rpmPrescriptionUpdate',
   initialState: initialUpdateState,
   reducers: {
      rpmPrescriptionUpdateLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      rpmPrescriptionUpdateRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      rpmPrescriptionUpdateSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      rpmPrescriptionUpdateFailed: (state, action: any) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      rpmPrescriptionUpdateReset: () => {
         return {
            ...initialUpdateState,
         };
      },
   },
});
export const rpmPrescriptionAddSlice = createSlice({
   name: 'rpmPrescriptionAdd',
   initialState: initialUpdateState,
   reducers: {
      rpmPrescriptionAddLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      rpmPrescriptionAddRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      rpmPrescriptionAddSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message || messages.success,
            error: '',
            isLoading: false,
         };
      },
      rpmPrescriptionAddFailed: (state, action: any) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      rpmPrescriptionAddReset: () => {
         return {
            ...initialUpdateState,
         };
      },
   },
});
