import React, { useCallback, useEffect } from 'react';

import { Box, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import ServicesOfferedIcon from 'assets/fill-profile/services_offered_icon.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import { useDispatch, useSelector } from 'react-redux';
import { addServiceOfferedAction, getMasterServiceOfferedAction } from 'reduxToolKit/actions';
import { serviceOfferedSlice } from 'reduxToolKit/reducers';
import {
   addServiceOffered,
   getMasterServiceOffered,
   getUserServiceOffered,
} from 'reduxToolKit/selectors/myProfile/serviceOfferedSelectors';
import { myProfileSagaTypes } from 'types';
import { CommonConstants } from 'utils/constants/common';
import { DONE, NEXT, SUBMIT } from 'utils/constants/profileConstants';
import { MenuProps } from 'utils/constants/uiConstants';

const useStyles = makeStyles((theme: any) => ({
   formControl: {
      width: '100%',
      backgroundColor: '#FCFCFC',
   },
   chips: {
      display: 'flex',
      flexWrap: 'nowrap',
   },
   chip: {
      margin: 2,
      backgroundColor: '#F0F0F0',
      color: '#7A7A8F',
      borderRadius: 10,
   },
}));

const ServicesOfferedSection = (props: any) => {
   const dispatch = useDispatch();
   const classes = useStyles();

   const [selectedServiceOffered, setSelectedServiceOffered] = React.useState<number[]>([]);
   const [errorMessage, setErrorMessage] = React.useState<string>('');

   const { list: listUserServiceOffered } = useSelector(getUserServiceOffered);
   const {
      list: listMasterServiceOffered,
      error: masterServiceOfferedError,
      isLoading: masterServiceLoading,
   } = useSelector(getMasterServiceOffered);
   const { message: addServiceOfferedMessage, error: addServiceOfferedError } = useSelector(addServiceOffered);
   const { serviceOfferedAddReset, getMasterServiceOfferedReset } = serviceOfferedSlice.actions;

   useEffect(() => {
      if (listUserServiceOffered?.length > 0) {
         const selectedIds = listUserServiceOffered.map((item: any) => {
            return item.service_offered_id;
         });
         setSelectedServiceOffered(selectedIds);
      } else {
         setSelectedServiceOffered([]);
      }
   }, [listUserServiceOffered]);

   useEffect(() => {
      if (addServiceOfferedMessage && props?.isFromServiceOffered) {
         const getAlert = () => (
            <InfoTimerAlert
               success
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={addServiceOfferedMessage}
               onConfirm={() => hideAlert('addServiceOfferedMessage')}
            />
         );
         props?.setAlert(getAlert);
         props?.setIsLoader(false);
         props.currentModalClose();
      } else if (addServiceOfferedMessage) {
         props.setIsLoader(false);
         gotoNavigation();
      }
   }, [addServiceOfferedMessage]);

   const hideAlert = (type: string) => {
      if (type === 'addServiceOfferedMessage') {
         if (props?.isFromServiceOffered) {
            props?.closeModelMyProfile();
            props.getUserData();
         }
         dispatch(serviceOfferedAddReset());
         props?.setIsLoader(false);
         props?.getUserServiceOfferedFun();
      } else if (type === 'addServiceOfferedError') {
         dispatch(serviceOfferedAddReset());
         props?.setIsLoader(false);
      } else if (type === 'masterServiceOfferedError') {
         dispatch(getMasterServiceOfferedReset());
         props?.setIsLoader(true);
      }
   };

   const clickNext = useCallback(() => {
      if (selectedServiceOffered?.length === 0) {
         setErrorMessage('Organization focus cannot be blank');
         return;
      }
      props?.setIsLoader(true);
      const data: myProfileSagaTypes.IAddServiceOfferedProps = {
         ids: selectedServiceOffered,
      };
      dispatch(addServiceOfferedAction(data));
   }, [selectedServiceOffered]);

   const gotoNavigation = useCallback(() => {
      if (props?.isFromOrg) {
         props.navigation(props.nexSectionOrg);
      } else if (props?.isFromClinicalTrials) {
         props.navigation(props.nextSectionClinicalTrails);
      } else {
         props.navigation(props.nexSection);
      }
   }, []);

   const handleChange = (event: any) => {
      setSelectedServiceOffered(event.target.value);
      setErrorMessage('');
   };

   useEffect(() => {
      if (props?.isServiceOfferedValid) {
         props.setIsLoader(false);
         gotoNavigation();
      } else {
         const data: myProfileSagaTypes.IMasterServiceOfferedProps = {
            page: 1,
            limit: CommonConstants.DropDownLimit,
         };
         dispatch(getMasterServiceOfferedAction(data));
      }
   }, []);

   return (
      <div>
         {addServiceOfferedError ? (
            <InfoTimerAlert
               error
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={addServiceOfferedError}
               onConfirm={() => hideAlert('addServiceOfferedError')}
            />
         ) : null}
         {masterServiceOfferedError ? (
            <InfoTimerAlert
               error
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={masterServiceOfferedError}
               onConfirm={() => hideAlert('masterServiceOfferedError')}
            />
         ) : null}
         <div className="col-12">
            <img className="common-race-icon race-icon mt--2" src={ServicesOfferedIcon} alt="icon" />
         </div>
         <div className="col-12">
            <p className="common-race-title-text race-title-text">Organization Focus</p>
         </div>
         <div className="col-12">
            <p className="common-race-paragraph-text organization-paragraph-text  p-2">
               Please select areas where you support patients and their families.
            </p>
         </div>
         <div className="col-12 mb-3">
            <FormControl className={classes.formControl}>
               <Box className="service-offered-div">
                  {selectedServiceOffered?.length === 0 ? (
                     <Typography className="service-offered-text">Choose Services</Typography>
                  ) : null}
                  <Select
                     labelId="demo-mutiple-checkbox-label"
                     label={''}
                     input={<OutlinedInput />}
                     id="demo-mutiple-checkbox"
                     multiple
                     value={selectedServiceOffered}
                     onChange={handleChange}
                     renderValue={(selected: any) => (
                        <div className={classes.chips}>
                           {masterServiceLoading
                              ? null
                              : selected.map((value: any) => (
                                   <Chip
                                      key={value}
                                      label={
                                         listMasterServiceOffered?.find((item: any) => item?.id === value)?.name || ''
                                      }
                                      className={classes.chip}
                                   />
                                ))}
                        </div>
                     )}
                     MenuProps={MenuProps}
                     variant="outlined"
                     style={{ width: '100%' }}
                     className="services-offered-dropdown-bg-color"
                  >
                     {listMasterServiceOffered?.length > 0
                        ? listMasterServiceOffered.map((geneticTestingItem: any, i: number) => (
                             <MenuItem
                                key={geneticTestingItem.id || i}
                                value={geneticTestingItem.id || 0}
                                className={`genetic-testing-checkbox-color ${
                                   selectedServiceOffered.findIndex((obj: any) => obj === geneticTestingItem.id) > -1
                                      ? 'services-offered-checkbox-bg-color'
                                      : ''
                                }`}
                             >
                                <Checkbox
                                   checked={
                                      selectedServiceOffered.findIndex((obj: any) => obj === geneticTestingItem?.id) >
                                      -1
                                   }
                                   inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                <ListItemText primary={geneticTestingItem?.name || ''} />
                             </MenuItem>
                          ))
                        : null}
                  </Select>
               </Box>
            </FormControl>
         </div>
         {errorMessage ? <div className="error-msg ml-4 mt-3 mr-4"> {errorMessage}</div> : null}
         <div className="button">
            <button
               type="submit"
               className="button-start"
               id="servicesOfferedSectionNext"
               onClick={() => {
                  clickNext();
               }}
            >
               {props?.currentStateArray &&
               props?.currentStateArray[props?.currentStateArray?.length - 1] === props?.currentState
                  ? DONE
                  : props?.isFromServiceOffered
                  ? SUBMIT
                  : NEXT}
            </button>
         </div>
      </div>
   );
};

export default ServicesOfferedSection;
