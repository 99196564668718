import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { patientAdvocateTypes, storeTypes } from 'types';

const initialState: patientAdvocateTypes.IPatientAdvocateReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
   updatePatientAdvocate: {
      message: '',
      error: '',
   },
   deletePatientAdvocate: {
      message: '',
      error: '',
   },
   addPatientAdvocate: {
      message: '',
      error: '',
   },
};

export const patientAdvocateSlice = createSlice({
   name: 'patientAdvocate',
   initialState: initialState,
   reducers: {
      getAdvocatesRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action?.payload?.page === 1 ? [] : state.list,
         };
      },
      getAdvocatesSuccess: (state, action: PayloadAction<patientAdvocateTypes.IPatientAdvocateSuccess>) => {
         const { payload } = action;
         return {
            ...state,
            message: payload?.message,
            list: payload?.list,
            total_records: payload?.total_records,
            isLoading: false,
         };
      },
      patientAdvocateFailed: (state, action: PayloadAction<patientAdvocateTypes.IPatientAdvocateFailed>) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            message: '',
            error: payload?.error,
         };
      },

      updateAdvocateRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      updateAdvocateSuccess: (state, action: PayloadAction<patientAdvocateTypes.IPatientAdvocateSuccessPayload>) => {
         const { payload } = action;
         return {
            ...state,
            updatePatientAdvocate: {
               message: payload?.message,
               error: '',
            },
            isLoading: false,
         };
      },
      updateAdvocateFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         const { payload } = action;
         return {
            ...state,
            updatePatientAdvocate: {
               message: '',
               error: payload?.error,
            },
         };
      },

      deletePatientAdvocateRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      deletePatientAdvocateSuccess: (
         state,
         action: PayloadAction<patientAdvocateTypes.IPatientAdvocateSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            deletePatientAdvocate: {
               message: payload?.message,
               error: '',
            },
         };
      },
      deletePatientAdvocateFailed: (
         state,
         action: PayloadAction<patientAdvocateTypes.IDeletePatientAdvocateFailedPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            deletePatientAdvocate: {
               message: '',
               error: payload?.error_message,
            },
         };
      },

      addAdvocateRequest: (state, action: PayloadAction<patientAdvocateTypes.IAddAdvocateForm>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addAdvocateSuccess: (state, action: PayloadAction<patientAdvocateTypes.IPatientAdvocateSuccessPayload>) => {
         return {
            ...state,
            isLoading: false,
            addPatientAdvocate: {
               message: action.payload?.message,
               error: '',
            },
         };
      },
      addAdvocateFailed: (state, action: PayloadAction<patientAdvocateTypes.IAddPatientAdvocateFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            addPatientAdvocate: {
               error: action.payload?.errors,
               message: '',
            },
         };
      },

      patientAdvocateReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
