import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { LOGIN_GOOGLE_SAGA, LOGIN_LINKEDIN_SAGA, LOGIN_USER_REGISTER } from 'reduxToolKit/actions';
import { commonSlice, googleLoginSlice, linkedInLoginSlice, userRegisterSlice } from 'reduxToolKit/reducers';
import { IGoogleLoginActionProps, ILinkedInLoginActionProps, IUserRegisterSagaProps } from 'types/auth/login';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

import { LOGIN_SAGA } from '../../actions';
import { loginSlice } from '../../reducers';

const { loginStart } = loginSlice.actions;
const loginSaga = function* (loginPayload: any) {
   yield put(loginStart(loginPayload.payload));
};

const { googleLoginAddRequest, googleLoginAddSuccess, googleLoginAddFailed } = googleLoginSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const googleLoginAddSaga = function* (googleLoginTypePayload: PayloadAction<IGoogleLoginActionProps>) {
   yield put(googleLoginAddRequest(googleLoginTypePayload.payload));
   try {
      const checkUser = true;
      const data = googleLoginTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...API.authGoogleLogin });
      if (response.status === 200) {
         const responseData = response.data.data;
         yield put(googleLoginAddSuccess(responseData));
      } else {
         yield put(googleLoginAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const { linkedInLoginAddRequest, linkedInLoginAddSuccess, linkedInLoginAddFailed } = linkedInLoginSlice.actions;

const linkedInLoginAddSaga = function* (linkedInLoginTypePayload: PayloadAction<ILinkedInLoginActionProps>) {
   yield put(linkedInLoginAddRequest(linkedInLoginTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const unAuthorization = true;
      const data = {
         token: linkedInLoginTypePayload.payload.token,
         email: linkedInLoginTypePayload.payload.email,
         user_type: linkedInLoginTypePayload.payload.user_type,
         source: linkedInLoginTypePayload.payload.source,
      };
      const params = { device_type: linkedInLoginTypePayload.payload.device_type };
      const response: AxiosResponse = yield apiCall({
         goUrl,
         unAuthorization,
         checkUser,
         params,
         data,
         ...API.linkedInLogin,
      });
      if (response.status === 200) {
         const responseData = response.data.data;
         yield put(linkedInLoginAddSuccess(responseData));
      } else {
         yield put(linkedInLoginAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const { userRegisterRequest, userRegisterSuccess, userRegisterFailed } = userRegisterSlice.actions;

const userRegisterSaga = function* (userRegisterPayload: PayloadAction<IUserRegisterSagaProps>) {
   yield put(userRegisterRequest(userRegisterPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const unAuthorization = true;
      const data = userRegisterPayload.payload;
      const params = { source: userRegisterPayload.payload.src };
      const response: AxiosResponse = yield apiCall({
         goUrl,
         unAuthorization,
         checkUser,
         params,
         data,
         ...API.userRegister,
      });
      if (response.status === 200 || response.status === 201) {
         const responseData = { message: response.data.message || messages.success };
         yield put(userRegisterSuccess(responseData));
      } else {
         yield put(userRegisterFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeLinkedInLoginAddRequest = function* () {
   yield takeEvery(LOGIN_LINKEDIN_SAGA, linkedInLoginAddSaga);
};

export const takeGoogleLoginAddRequest = function* () {
   yield takeEvery(LOGIN_GOOGLE_SAGA, googleLoginAddSaga);
};

export const takeUserRegisterRequest = function* () {
   yield takeEvery(LOGIN_USER_REGISTER, userRegisterSaga);
};

export const takeLoginRequest = function* () {
   yield takeEvery(LOGIN_SAGA, loginSaga);
};
