import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   ADD_EDIT_DEVICE_CLINIC_NOTE_COMMENT,
   DEVICE_CLINIC_NOTE_COMMENTS_CLOSE,
   GET_DEVICE_CLINIC_NOTE_COMMENTS,
} from 'reduxToolKit/actions';
import { commonSlice, deviceClinicNotesCommentsSlice } from 'reduxToolKit/reducers';
import { deviceClinicNotesSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getClinicNoteCommentsRequest,
   getClinicNoteCommentsSuccess,
   getClinicNoteCommentsFailed,
   addEditclinicNotecommentRequest,
   addEditclinicNotecommentSuccess,
   addEditclinicNotecommentFailed,
   clinicNoteCommentCloseRequest,
   clinicNoteCommentCloseSuccess,
   clinicNoteCommentCloseFailed,
} = deviceClinicNotesCommentsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getDeviceClinicNotesCommentsSaga = function* (
   payloadState: PayloadAction<deviceClinicNotesSagaTypes.IDeviceClinicNoteGetComments>
) {
   yield put(getClinicNoteCommentsRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getClinicNoteComment });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
            list: response.data?.data?.list || [],
            total_records: response.data?.data?.total_records || 0,
         };
         yield put(getClinicNoteCommentsSuccess(responseData));
      } else {
         yield put(getClinicNoteCommentsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const AddEditDeviceClinicNotesCommentSaga = function* (
   payloadState: PayloadAction<deviceClinicNotesSagaTypes.IDeviceClinicNoteAddEditComment>
) {
   yield put(addEditclinicNotecommentRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const requestData = payloadState.payload;
      let customUrl, data;

      if (requestData.isEditComment) {
         data = {
            id: requestData.id,
            comment: requestData.comment,
            minutes: requestData.minutes,
            is_audio_video_selected: requestData.is_audio_video_selected,
            medias: requestData.medias,
            media_ids: requestData.media_ids,
         };

         customUrl = API.editClinicNoteComment;
      } else {
         data = {
            clinic_note_id: requestData.clinic_note_id,
            comment: requestData.comment,
            minutes: requestData.minutes,
            is_comment_closed: requestData.is_comment_closed,
            is_audio_video_selected: requestData.is_audio_video_selected,
            medias: requestData.medias,
         };

         customUrl = API.addClinicNoteComment;
      }

      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...customUrl });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(addEditclinicNotecommentSuccess(responseData));
      } else {
         yield put(addEditclinicNotecommentFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const deviceClinicNotesCommentscloseSaga = function* (
   payloadState: PayloadAction<deviceClinicNotesSagaTypes.IDeviceClinicNoteEndSession>
) {
   yield put(clinicNoteCommentCloseRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.clinicNoteCommentClose });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(clinicNoteCommentCloseSuccess(responseData));
      } else {
         yield put(clinicNoteCommentCloseFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeDeviceNotesCommentsSaga = function* () {
   yield takeEvery(GET_DEVICE_CLINIC_NOTE_COMMENTS, getDeviceClinicNotesCommentsSaga);
   yield takeEvery(ADD_EDIT_DEVICE_CLINIC_NOTE_COMMENT, AddEditDeviceClinicNotesCommentSaga);
   yield takeEvery(DEVICE_CLINIC_NOTE_COMMENTS_CLOSE, deviceClinicNotesCommentscloseSaga);
};
