import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ASK_A_DOC_GET_PANELS, ASK_A_DOC_GET_SUBSCRIPTIONS_PANELS } from 'reduxToolKit/actions';
import { commonSlice, panelsListSlice } from 'reduxToolKit/reducers';
import { askADocPageTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   panelsListLoading,
   panelsListViewRequest,
   panelsListViewSuccess,
   panelsListFailed,

   subscriptionPanelsListViewRequest,
   subscriptionPanelsListViewSuccess,
   subscriptionPanelsListFailed,
} = panelsListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const panelsListSaga = function* (cancerTypePayload: any) {
   yield put(panelsListLoading());
   yield put(panelsListViewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload.params;
      const data = cancerTypePayload.payload.postData;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, params, ...API.panelsList });
      if (response.status === 200) {
         const responseData = response?.data;
         const oncDriveList = {
            list: responseData?.data?.list,
            total_records: responseData?.data?.total_records,
            message: response?.data?.message || messages.success,
         };
         yield put(panelsListViewSuccess(oncDriveList));
      } else {
         yield put(panelsListFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const SubscriptionPanelsListSaga = function* (payloadPanels: PayloadAction<askADocPageTypes.IAskADocClinicRequest>) {
   yield put(subscriptionPanelsListViewRequest(payloadPanels.payload));
   yield delay(500);
   try {
      const checkUser = false;
      const goUrl = false;
      const params = payloadPanels.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.subscriptionPanelsList });
      if (response.status === 200) {
         const responseData = response?.data;
         const oncDriveList = {
            message: response?.data?.message || messages.success,
            list: responseData?.data || [],
         };
         yield put(subscriptionPanelsListViewSuccess(oncDriveList));
      } else {
         yield put(subscriptionPanelsListFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePanelsListRequest = function* () {
   yield takeEvery(ASK_A_DOC_GET_PANELS, panelsListSaga);
   yield takeLatest(ASK_A_DOC_GET_SUBSCRIPTIONS_PANELS, SubscriptionPanelsListSaga);
};
