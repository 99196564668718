import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINIC_ADD_VIDEOS } from 'reduxToolKit/actions';
import { addClinicVideosSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { addClinicVideosRequest, addClinicVideosSuccess, addClinicVideosFailed } = addClinicVideosSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const addClinicVideosSaga = function* (payloadState: any) {
   yield put(addClinicVideosRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addClinicVideos });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
         };
         yield put(addClinicVideosSuccess(resObj));
      } else {
         yield put(addClinicVideosFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAddClinicVideos = function* () {
   yield takeEvery(CLINIC_ADD_VIDEOS, addClinicVideosSaga);
};
