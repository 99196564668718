import { createSlice } from '@reduxjs/toolkit';
import { postShareReducerTypes } from 'types';

const initialState: postShareReducerTypes.IProfileCompletionSuccessPayload = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const postShareSlice = createSlice({
   name: 'publicPostShare',
   initialState: initialState,
   reducers: {
      postShareRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      postShareSuccess: (state, action) => {
         return {
            ...state,
            isLoading: false,
            ...action.payload,
         };
      },
      postShareFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      postShareReset: (state) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: '',
         };
      },
   },
});
export { postShareSlice };
