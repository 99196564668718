import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { s3UploadReducerTypes } from 'types';

const initialState: s3UploadReducerTypes.IGetS3SignedURLReducerTypes = {
   isLoading: false,
   data: null,
   message: '',
   error: '',
   deleteFileKey: {
      isLoading: false,
      data: null,
      message: '',
      error: '',
   },
};

export const s3FileUploadSlice = createSlice({
   name: 'getS3SignedURL',
   initialState: initialState,
   reducers: {
      getS3SignedURLRequest: (state, action: PayloadAction<s3UploadReducerTypes.IGetS3SignedURL>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getS3SignedURLSuccess: (state, action: PayloadAction<s3UploadReducerTypes.IS3SignedURLSuccessPayload>) => {
         return {
            ...state,
            data: action.payload?.data,
            message: action.payload?.message,
            error: '',
            isLoading: false,
         };
      },
      getS3SignedURLFailed: (state, action: PayloadAction<s3UploadReducerTypes.IS3SignedURLFailurePayload>) => {
         return {
            ...state,
            error: action.payload?.error,
            message: '',
            isLoading: false,
         };
      },

      s3DeleteFileKeyRequest: (state, action: PayloadAction<s3UploadReducerTypes.IS3DeleteFileKey>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      s3DeleteFileKeySuccess: (state, action: PayloadAction<s3UploadReducerTypes.IS3SignedURLSuccessPayload>) => {
         return {
            ...state,
            deleteFileKey: {
               ...state.deleteFileKey,
               ...action.payload,
               error: '',
               isLoading: false,
            },
         };
      },
      s3DeleteFileKeyFailed: (state, action: PayloadAction<s3UploadReducerTypes.IS3SignedURLFailurePayload>) => {
         return {
            ...state,
            deleteFileKey: {
               ...state.deleteFileKey,
               ...action.payload,
               message: '',
               data: null,
               isLoading: false,
            },
            isLoading: false,
         };
      },

      getS3SignedURLReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
