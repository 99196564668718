import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_SCHEDULE } from 'reduxToolKit/actions';
import { addScheduleSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { addScheduleRequest, addScheduleSuccess, addScheduleFailed } = addScheduleSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const addSchedule = function* (addSchedulePayload: any) {
   yield put(addScheduleRequest(addSchedulePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = addSchedulePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addSchedule });
      if (response.status === 200) {
         const addScheduleList = {
            message: response.data?.message || '',
         };
         yield put(addScheduleSuccess(addScheduleList));
      } else {
         yield put(addScheduleFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAddScheduleRequest = function* () {
   yield takeEvery(ADD_SCHEDULE, addSchedule);
};
