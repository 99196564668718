import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_CLINIC_ADMIN_INVITES, GET_CLINIC_GROUPS } from 'reduxToolKit/actions';
import { commonSlice, getClinicAdminInvitesSlice, getClinicGroupsSlice } from 'reduxToolKit/reducers';
import { myClinicSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getClinicGroupsRequest, getClinicGroupsSuccess, getClinicGroupsFailed } = getClinicGroupsSlice.actions;
const {
   getClinicAdminInvitesRequest,
   getClinicAdminInvitesSuccess,
   getClinicAdminInvitesFailed,
} = getClinicAdminInvitesSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getClinicGroupsSaga = function* (clinicGroupsPayload: PayloadAction<myClinicSagaTypes.IGetClinicGroups>) {
   yield put(getClinicGroupsRequest(clinicGroupsPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = clinicGroupsPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getClinicGroups });
      if (response.status === 200) {
         const responseData = response.data;
         const clinicGroupsList = {
            list: responseData?.data?.list || [],
            total_records: responseData?.data?.total_records || 0,
            message: response?.data?.message || messages.success,
         };
         yield put(getClinicGroupsSuccess(clinicGroupsList));
      } else {
         yield put(getClinicGroupsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeClinicGroupsRequest = function* () {
   yield takeEvery(GET_CLINIC_GROUPS, getClinicGroupsSaga);
};

const getClinicAdminInvitesSaga = function* (clinicAdminPayload: PayloadAction<myClinicSagaTypes.IGetClinicGroups>) {
   yield put(getClinicAdminInvitesRequest(clinicAdminPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = clinicAdminPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getClinicAdminInvites });
      if (response.status === 200) {
         const responseData = response.data;
         const clinicAdminList = {
            list: responseData?.data?.list || [],
            total_records: responseData?.data?.total_records || 0,
            message: response?.data?.message || messages.success,
         };
         yield put(getClinicAdminInvitesSuccess(clinicAdminList));
      } else {
         yield put(getClinicAdminInvitesFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeClinicAdminInvites = function* () {
   yield takeEvery(GET_CLINIC_ADMIN_INVITES, getClinicAdminInvitesSaga);
};
