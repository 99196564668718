import React, { useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { Box, Container, Dialog, Divider, Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import OncoImage from 'assets/pill-remainder/oncoVector.png';
import PillExitIcon from 'assets/pill-remainder/pillMedExit.png';
import PillRescheduleIcon from 'assets/pill-remainder/pillReSchedule.svg';
import PillNameIcon from 'assets/pill-remainder/pillResNameIcon.png';
// import PillTakeIcon from 'assets/pill-remainder/pillTakeIcon.png';
import PillSkipIcon from 'assets/pill-remainder/pillSkip.svg';
import PillTakeIcon from 'assets/pill-remainder/pillTake.svg';
import PillUntakeIcon from 'assets/pill-remainder/pillUnTake.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import { DialogContent } from 'component/shared/MuiWithStyle';
import Loading from 'component/widget/Loading';
import Constant from 'config/Constant';
import { useDispatch, useSelector } from 'react-redux';
import {
   pillRemainderListSagaAction,
   pillRemainderNotificationGetOneSagaAction,
   pillRemainderNotificationStatusEditSagaAction,
} from 'reduxToolKit/actions';
import {
   pillRemainderListSlice,
   pillRemainderNotificationGetOneSlice,
   pillRemainderNotifyStatusEditSlice,
} from 'reduxToolKit/reducers';
import { DatePicker } from 'rsuite';
import { pillRemainderPageTypes, storeTypes } from 'types';
import { PILL_REMAINDER_EVENTS } from 'utils/constants/eventTrack/pillRemainderEventConstants';
import {
   convertDateToTimezone,
   convertDateToTimezoneDate,
   convertOnly12HrsTime,
   convertOnlyAMPMTime,
   convertOnlyUTCTime,
   convertStartEndDateToUTC,
   getCurrentDate,
   isBeforeYesterday,
} from 'utils/helpers/dateHelpers';
import { setEventTrack } from 'utils/helpers/eventTrack';

const PillTakeModal = ({
   handleClose,
   open,
   handleSubmit,
   notificationPillId,
   selectedDate,
   handleEdit,
   setPillEdit,
}: pillRemainderPageTypes.IPillTakeModelProps) => {
   const dispatch = useDispatch();

   const [openRescheduleTooltip, setOpenRescheduleTooltip] = useState<boolean>(false);
   const [anchorEl, setAnchorEl] = useState(null);
   const [pillRescheduleDate, setPillRescheduleDate] = useState<Date | undefined>(getCurrentDate());
   const [pillRescheduleConvertDate, setPillRescheduleConvertDate] = useState<string>(
      convertStartEndDateToUTC(getCurrentDate())
   );
   const [pillRescheduleTime, setPillRescheduleTime] = useState<Date | null>(getCurrentDate());

   const [clickForNowDate, setClickForNowDate] = useState<boolean>(false);
   const [clickForNowTime, setClickForNowTime] = useState<boolean>(false);

   const { isLoading: isLoadingPillRemainder, data: pillItemData, error: pillRemainderErrorMessage } = useSelector(
      (state: storeTypes.IStore) => state.pillRemainderNotificationGetOne
   );
   const { pillRemainderNotificationGetOneReset } = pillRemainderNotificationGetOneSlice.actions;

   const {
      isLoading: isLoadingPillRemainderNotifyStatusEdit,
      // data: pillRemainderNotifyStatusEdit,
      message: pillRemainderNotifyStatusEditMessage,
      error: pillRemainderNotifyStatusEditErrorMessage,
   } = useSelector((state: storeTypes.IStore) => state.pillRemainderNotifyStatusEdit);
   const {
      pillRemainderNotifyStatusEditLoading,
      pillRemainderNotifyStatusEditReset,
   } = pillRemainderNotifyStatusEditSlice.actions;

   const { pillRemainderListReset } = pillRemainderListSlice.actions;

   const pillRemainderList = () => {
      const currentDate = getCurrentDate();
      const convertDateTimezoneDate = convertDateToTimezoneDate(
         selectedDate?.dateFormat ? selectedDate.dateFormat : currentDate
      );
      const convertDateTimezone = convertDateToTimezone(
         selectedDate?.dateFormat ? selectedDate.dateFormat : currentDate
      );
      dispatch(pillRemainderListReset());
      pillRemainderListDispatch(convertDateTimezoneDate, convertDateTimezone);
   };

   const pillRemainderListDispatch = (date: string, convertDateTimezone: string) => {
      const pillRemainderRequest = {
         date,
         time: convertDateTimezone,
      };
      dispatch(pillRemainderListSagaAction(pillRemainderRequest));
   };

   const hideAlert = (type: string) => {
      if (type === 'errorAlert') {
         dispatch(pillRemainderNotificationGetOneReset());
      } else if (type === 'successAlertNotifyStatusEdit') {
         dispatch(pillRemainderNotifyStatusEditReset());
         pillRemainderList();
         handleClose();
      } else if (type === 'errorAlertNotifyStatusEdit') {
         dispatch(pillRemainderNotifyStatusEditReset());
      }
   };

   const updateStatus = (status: string, convertTime?: any) => {
      const updateStatusEditRequest = {
         id: pillItemData?.id || 0,
         status,
         pills_date_time:
            status === 'reschedule'
               ? `${pillRescheduleConvertDate} ${convertTime}` || pillItemData?.pills_date_time || ''
               : pillItemData?.pills_date_time || '',
      };
      dispatch(pillRemainderNotificationStatusEditSagaAction(updateStatusEditRequest));
   };

   const clickSkip = () => {
      updateStatus('skipped');
      setEventTrack(PILL_REMAINDER_EVENTS.PILL_SKIP);
   };

   const clickTake = () => {
      updateStatus('taken');
      setEventTrack(PILL_REMAINDER_EVENTS.PILL_TAKE);
   };

   const clickReschedule = (convertTime: any) => {
      updateStatus('reschedule', convertTime);
      setEventTrack(PILL_REMAINDER_EVENTS.PILL_RESCHEDULE);
   };

   const clickUnTake = () => {
      updateStatus('in_progress');
      setEventTrack(PILL_REMAINDER_EVENTS.PILL_UNTAKE);
   };

   const openReschedule = (e: any) => {
      setOpenRescheduleTooltip(true);
      setAnchorEl(e.currentTarget);
   };

   const closeReschedule = () => {
      setOpenRescheduleTooltip(false);
      setAnchorEl(null);

      setClickForNowDate(false);
      setClickForNowTime(false);
   };

   const handleForNow = () => {
      setClickForNowDate(true);
   };

   const changeDate = (value: Date) => {
      setPillRescheduleDate(value);
      const convertDate = convertStartEndDateToUTC(value);
      setPillRescheduleConvertDate(convertDate);
   };

   const changeTime = (date: MaterialUiPickersDate) => {
      if (date) {
         setPillRescheduleTime(date);
         const convertTime = convertOnlyUTCTime(date);

         dispatch(pillRemainderNotifyStatusEditLoading());
         setTimeout(() => {
            clickReschedule(convertTime);
         }, 10000);
      }
   };

   useEffect(() => {
      const getOneRequest = {
         id: notificationPillId || 0,
      };
      dispatch(pillRemainderNotificationGetOneSagaAction(getOneRequest));
      return () => {
         dispatch(pillRemainderNotificationGetOneReset());
      };
   }, []);

   return (
      <>
         <Dialog
            onClose={() => {
               handleClose();
               setEventTrack(PILL_REMAINDER_EVENTS.PILL_REMINDER_NOTIFICATION_DIALOGUE_CLOSE);
            }}
            aria-labelledby="customized-dialog-title"
            className="pill-remainder-modal"
            open={open}
            maxWidth="sm"
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
         >
            {(isLoadingPillRemainder || isLoadingPillRemainderNotifyStatusEdit) && <Loading />}
            {pillRemainderErrorMessage && (
               <InfoTimerAlert
                  error
                  confirmBtnText={`Okay!`}
                  confirmBtnBsStyle={`errorBtn`}
                  title={pillRemainderErrorMessage}
                  onConfirm={() => hideAlert('errorAlert')}
               />
            )}
            {pillRemainderNotifyStatusEditMessage && (
               <InfoTimerAlert
                  success
                  confirmBtnText={`Okay!`}
                  confirmBtnBsStyle={`successBtn`}
                  title={pillRemainderNotifyStatusEditMessage}
                  onConfirm={() => hideAlert('successAlertNotifyStatusEdit')}
               />
            )}
            {pillRemainderNotifyStatusEditErrorMessage && (
               <InfoTimerAlert
                  error
                  confirmBtnText={`Okay!`}
                  confirmBtnBsStyle={`errorBtn`}
                  title={pillRemainderNotifyStatusEditErrorMessage}
                  onConfirm={() => hideAlert('errorAlertNotifyStatusEdit')}
               />
            )}
            <DialogContent>
               <Box mt={2}>
                  <Grid container direction="row" justify="flex-end">
                     <Grid
                        item
                        onClick={() => {
                           handleClose();
                           setEventTrack(PILL_REMAINDER_EVENTS.PILL_REMINDER_NOTIFICATION_DIALOGUE_CLOSE);
                        }}
                     >
                        <img src={PillExitIcon} alt="img" className="pill-reschedule-time-img-edit" />
                     </Grid>
                  </Grid>
                  <Grid container direction="column" spacing={2}>
                     <Grid item>
                        <Typography align="center">
                           <img src={OncoImage} alt="img" />
                        </Typography>
                     </Grid>
                     <Grid item>
                        <Typography align="center" className="pill-remainder-text">
                           {`Hello, it’s time to take your ${convertOnly12HrsTime(
                              pillItemData?.pills_date_time
                           )}  ${convertOnlyAMPMTime(pillItemData?.pills_date_time)} medicine`}
                        </Typography>
                     </Grid>
                     <Grid item>
                        <Box width="70%" mx="auto">
                           <Divider />
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
               <Box mt={2}>
                  <Container maxWidth="lg">
                     <Grid container direction="row" justify="space-between">
                        <Grid item>
                           <Box>
                              <Typography className="pill-remainder-time">
                                 {convertOnly12HrsTime(pillItemData?.pills_date_time)}{' '}
                                 <Typography component="span" className="pill-remainder-period">
                                    {convertOnlyAMPMTime(pillItemData?.pills_date_time)}
                                 </Typography>
                              </Typography>
                           </Box>
                        </Grid>
                        {/* <Grid item>
                           <Typography className="pill-reschedule-time-text">Time-2</Typography>
                        </Grid> */}
                     </Grid>
                  </Container>
               </Box>
            </DialogContent>
            <DialogContent className="pill-med-box">
               <Box pl={3}>
                  <Grid container direction="row" spacing={3} alignItems="center" style={{ flexWrap: 'nowrap' }}>
                     <Grid item>
                        <img src={PillNameIcon} alt="img" />
                     </Grid>
                     <Grid item>
                        <Box>
                           <Typography className="pill-med-name">
                              {pillItemData?.pill_rem_medicine?.name || ''}
                           </Typography>
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
            </DialogContent>
            <DialogContent>
               <Box mt={5} mb={5}>
                  <Grid container direction="row" justify="space-evenly">
                     {pillItemData?.status === null || pillItemData?.status === 'in_progress' ? (
                        <Grid item className="hand-i" onClick={clickSkip}>
                           <img src={PillSkipIcon} alt="exit-img" className="modal-progress-img" />
                           <Box mt={2}>
                              <Typography className="pill-skip">Skip</Typography>
                           </Box>
                        </Grid>
                     ) : null}
                     {pillItemData?.status === null ||
                     pillItemData?.status === 'in_progress' ||
                     pillItemData?.status === 'skipped' ? (
                        <>
                           <Grid item className="hand-i" onClick={openReschedule}>
                              <Box style={{ textAlign: 'center' }}>
                                 <img src={PillRescheduleIcon} alt="reschedule-img" className="modal-progress-img" />

                                 <Box mt={2}>
                                    <Typography className="pill-reschedule">Re-Schedule</Typography>
                                 </Box>
                              </Box>
                           </Grid>

                           <Popover
                              id="simple-popover"
                              open={openRescheduleTooltip}
                              anchorEl={anchorEl}
                              onClose={closeReschedule}
                              anchorOrigin={{
                                 vertical: 'bottom',
                                 horizontal: 'center',
                              }}
                              transformOrigin={{
                                 vertical: 'top',
                                 horizontal: 'center',
                              }}
                              className="hand-i"
                              style={{ zIndex: 1600 }}
                           >
                              <Box>
                                 <Box className="pill-reschedule-dialog-div">
                                    <Typography
                                       onClick={() => {
                                          handleEdit();
                                          setPillEdit(pillItemData);
                                          closeReschedule();
                                          handleClose();
                                          setEventTrack(
                                             PILL_REMAINDER_EVENTS.PILL_REMINDER_NOTIFICATION_DIALOGUE_CLOSE
                                          );
                                       }}
                                       className="edit-medication-text"
                                    >
                                       Edit medication
                                    </Typography>
                                    {pillItemData?.pill_rem_med_notify_id ? (
                                       <Typography
                                          onClick={() => {
                                             handleForNow();
                                          }}
                                          className="for-now-text"
                                       >
                                          For now
                                       </Typography>
                                    ) : null}
                                 </Box>
                                 {clickForNowDate ? (
                                    <Box style={{ visibility: 'hidden', height: '1px' }}>
                                       <DatePicker
                                          onOk={() => {
                                             setClickForNowDate(false);
                                             setClickForNowTime(true);
                                          }}
                                          size="lg"
                                          open={clickForNowDate}
                                          placeholder="Select Date"
                                          placement="top"
                                          value={pillRescheduleDate}
                                          onChange={(value: Date) => {
                                             changeDate(value);
                                          }}
                                          format={Constant.DATE_FORMAT}
                                          disabledDate={(date) => isBeforeYesterday(date)}
                                       />
                                    </Box>
                                 ) : null}
                                 {clickForNowTime ? (
                                    <Box style={{ visibility: 'hidden', height: '1px' }}>
                                       <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                          <TimePicker
                                             open={clickForNowTime}
                                             inputVariant="standard"
                                             margin="normal"
                                             id="time-picker"
                                             className="time-pick form-control"
                                             label=""
                                             placeholder="Select time"
                                             value={pillRescheduleTime}
                                             onChange={(date: MaterialUiPickersDate) => {
                                                changeTime(date);
                                             }}
                                             InputProps={{
                                                startAdornment: (
                                                   <InputAdornment position="start">
                                                      <IconButton>
                                                         <AccessTimeIcon />
                                                      </IconButton>
                                                   </InputAdornment>
                                                ),
                                             }}
                                             onClose={() => {
                                                closeReschedule();
                                             }}
                                          />
                                       </MuiPickersUtilsProvider>
                                    </Box>
                                 ) : null}
                              </Box>
                           </Popover>
                        </>
                     ) : null}
                     {pillItemData?.status === 'taken' || pillItemData?.status === 'skipped' ? (
                        <Grid item className="hand-i" onClick={clickUnTake}>
                           <Box style={{ textAlign: 'center' }}>
                              <img src={PillUntakeIcon} alt="untake-img" className="modal-progress-img" />

                              <Box mt={2}>
                                 <Typography className="pill-untake">unTake</Typography>
                              </Box>
                           </Box>
                        </Grid>
                     ) : null}
                     {pillItemData?.status === null ||
                     pillItemData?.status === 'in_progress' ||
                     pillItemData?.status === 'skipped' ? (
                        <Grid item className="hand-i" onClick={clickTake}>
                           <img src={PillTakeIcon} alt="untake-img" className="modal-progress-img" />
                           <Box mt={2}>
                              <Typography className="pill-take">Take</Typography>
                           </Box>
                        </Grid>
                     ) : null}
                  </Grid>
               </Box>
            </DialogContent>
         </Dialog>
      </>
   );
};

export { PillTakeModal };
