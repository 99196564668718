import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ASK_A_DOC_CREATE_REQUEST_72_HRS } from 'reduxToolKit/actions';
import { askADocCreateRequest72HrsSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   askADocCreateRequest72HrsRequest,
   askADocCreateRequest72HrsSuccess,
   askADocCreateRequest72HrsFailed,
} = askADocCreateRequest72HrsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const askADocCreateRequest72HrsSaga = function* () {
   yield put(askADocCreateRequest72HrsRequest());
   try {
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ checkUser, ...API.getAskADocCreateRequest72Hrs });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: null,
         };
         yield put(askADocCreateRequest72HrsSuccess(resObj));
      } else {
         yield put(askADocCreateRequest72HrsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAskADocCreateRequest72Hrs = function* () {
   yield takeEvery(ASK_A_DOC_CREATE_REQUEST_72_HRS, askADocCreateRequest72HrsSaga);
};
