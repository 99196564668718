import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rateWriteReducerTypes } from 'types';

const initialState: rateWriteReducerTypes.IRateWriteReducerTypes = {
   isLoading: false,
   message: '',
   error: '',
};

export const rateWriteSlice = createSlice({
   name: 'rateWrite',
   initialState: initialState,
   reducers: {
      rateWriteRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      rateWriteSuccess: (state, action: PayloadAction<rateWriteReducerTypes.IRateWriteSuccessPayload>) => {
         return {
            ...state,
            message: action.payload?.message,
            error: '',
            isLoading: false,
         };
      },
      rateWriteFailed: (state, action: PayloadAction<rateWriteReducerTypes.IRateWriteFailurePayload>) => {
         return {
            ...state,
            error: action.payload?.error,
            message: '',
            isLoading: false,
         };
      },
      rateWriteReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
