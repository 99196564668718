import React, { useCallback, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DisplayName from 'assets/fill-profile/displayName.svg';
import InfoIcon from 'assets/image/info_icon.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import { useDispatch, useSelector } from 'react-redux';
import { profileCompletionSagaAction } from 'reduxToolKit/actions';
import { postShareSlice } from 'reduxToolKit/reducers';
import { storeTypes } from 'types';
import { DisplayTypeProps } from 'types/modelHelpers';
import { CANCER_TYPE_SECTION } from 'utils/constants/auth';
import { DONE, NEXT } from 'utils/constants/profileConstants';
import DisplayDetails from 'utils/helpers/messageHelpers';

const useStylesBootstrap = makeStyles((theme) => ({
   arrow: {
      color: '#007edd',
   },
   tooltip: {
      backgroundColor: '#007edd',
      fontSize: theme.typography.pxToRem(14),
   },
}));

function BootstrapTooltip(props: any) {
   const classes = useStylesBootstrap();
   return <Tooltip placement="top" arrow classes={classes} {...props} />;
}

const DisplayNameSection = (props: DisplayTypeProps) => {
   const [displayName, setDisplayName] = useState<string | null>(null);
   const [validation, setValidation] = useState(false);
   const [patterenValidation, setPatterenValidation] = useState(false);
   const [addressError, setAddressError] = useState('');
   const dispatch = useDispatch();
   const { message, isLoading, error } = useSelector((state: storeTypes.IStore) => state.publicPostShare);

   const { postShareReset } = postShareSlice.actions;

   useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
         document.body.style.overflow = 'scroll';
      };
   }, []);

   const handleSubmit = () => {
      if (displayName !== null) {
         const pattern = new RegExp(/^[a-zA-Z0-9_.-]+$/);
         const result = pattern.test(displayName);
         if (result) {
            setValidation(false);
            setPatterenValidation(false);
            props.setIsLoader(true);
            const postAddressData = {
               input_type: 'display_name',
               display_name: displayName,
            };
            dispatch(profileCompletionSagaAction(postAddressData));
         } else {
            setPatterenValidation(true);
         }
      } else {
         props.setIsLoader(false);
         setValidation(true);
         return;
      }
   };
   const onChangeDisplayName = (e: any) => {
      const value = e.target.value;
      setDisplayName(value);
      if (value) {
         const pattern = new RegExp(/^[a-zA-Z0-9_.-]+$/);
         const result = pattern.test(value);
         if (result) {
            setPatterenValidation(false);
         } else {
            setPatterenValidation(true);
         }
      } else {
         setPatterenValidation(false);
      }
   };

   const hideAlert = (type: string) => {
      if (type === 'userAlert') {
         props.skipModal();
         if (props.isFromNotification) {
         } else if (props.isFromClinicalTrials) {
         } else {
            window.location.href = '/feed';
         }
      }
   };

   const gotoNavigation = useCallback(() => {
      if (props?.isFromFeedInitial) {
         props.navigation(props?.nextSectionFillProfileInitial);
      } else if (props?.isFromClinicalTrials) {
         props.navigation(props?.nextSectionClinicalTrails);
      } else {
         props.navigation(props.nexSection);
      }
   }, []);

   useEffect(() => {
      if (
         message !== '' &&
         !props.isDisplay &&
         !props.isPrimary &&
         !props.isAddress &&
         !props.isDiagnosisDateValid &&
         !props?.isStagingValid &&
         !props?.isCancerStatusValid
      ) {
         props.setIsLoader(isLoading);
         gotoNavigation();
      } /* else if (
         message &&
         !props.isDisplay &&
         props.isPrimary &&
         !props.isAddress &&
         !props.isDiagnosisDateValid &&
         !props?.isStagingValid &&
         !props?.isCancerStatusValid
      ) {
         props.setIsLoader(isLoading);
         props.navigation(ADDRESS_SECTION);
      } */ else if (
         message &&
         !props.isDisplay &&
         !props.isPrimary &&
         props.isAddress &&
         !props.isDiagnosisDateValid &&
         !props?.isStagingValid &&
         !props?.isCancerStatusValid
      ) {
         props.setIsLoader(isLoading);
         props.navigation(CANCER_TYPE_SECTION);
      } else if (
         message &&
         !props.isDisplay &&
         props.isPrimary &&
         props.isAddress &&
         props.isDiagnosisDateValid &&
         props?.isStagingValid &&
         props?.isCancerStatusValid
      ) {
         const getAlert = () => (
            <InfoTimerAlert
               success
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={message}
               onConfirm={() => hideAlert('userAlert')}
            />
         );

         props.setAlert(getAlert);
         props.setIsLoader(isLoading);
         props.currentModalClose();
      } else if (message) {
         props.setIsLoader(isLoading);
         gotoNavigation();
      } else if (error) {
         setAddressError(error);
         props.setIsLoader(isLoading);
         dispatch(postShareReset());
      }
   }, [message, error]);

   useEffect(() => {
      if (props.isDisplay) {
         gotoNavigation();
      }
   }, []);

   return (
      <>
         <div className="col-12">
            <img className="onco-icon" src={DisplayName} alt="icon" />
         </div>

         <div>
            <p className="paragragh-text p-2">
               OncoPower requires you to set a Display Name. Other users on OncoPower can search you by your Display
               Name.
            </p>
         </div>
         <div>
            <label htmlFor="title" aria-hidden="true" className="dob-header">
               Display Name
               <BootstrapTooltip title={DisplayDetails.DISPLAY_DETAILS}>
                  <img
                     style={{
                        cursor: 'not-allowed',
                     }}
                     className="hand-i"
                     src={InfoIcon}
                     alt="info"
                  />
               </BootstrapTooltip>
            </label>
            <input
               className="address-input"
               autoFocus
               placeholder="Type here..."
               onChange={onChangeDisplayName}
               autoComplete="off"
            />
         </div>
         {displayName === null && validation ? <div className="error-msg-address">Display name is required</div> : null}
         {patterenValidation ? (
            <div className="error-msg-address">Display Name can contain a-z or A-Z or 0-9 or _ or .or -</div>
         ) : null}
         <div className="error-msg">{addressError || null}</div>
         <div className="button">
            <button type="button" className="button-start" onClick={handleSubmit}>
               {props?.currentStateArray &&
               props?.currentStateArray[props?.currentStateArray?.length - 1] === props?.currentState
                  ? DONE
                  : NEXT}
            </button>
         </div>
      </>
   );
};

export { DisplayNameSection };
