import React, { useCallback, useEffect, useState } from 'react';

import CancerStatusIcon from 'assets/fill-profile/cancer_status_icon.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { addCancerStatusAction, getMasterCancerStatusAction } from 'reduxToolKit/actions';
import { cancerStatusSlice } from 'reduxToolKit/reducers';
// import { Icon, SelectPicker } from 'rsuite';
import { myProfileSagaTypes, storeTypes } from 'types';
import { DONE, NEXT, SUBMIT } from 'utils/constants/profileConstants';
import { CustomStyleoption } from 'utils/constants/uiConstants';

const CancerStatusSection = (props: any) => {
   const dispatch = useDispatch();
   const [errorMessage, setErrorMessage] = useState<string>('');
   const [cancerStatusList, setCancerStatusList] = useState<any[]>([]);
   const [cancerStatusId, setCancerStatusId] = useState<any>(null);

   const {
      setIsLoader,
      isFromClinicalTrials,
      navigation,
      nexSection,
      getUserData,
      setAlert,
      currentModalClose,
      isDisplay,
      isPrimary,
      isAddress,
      isDiagnosisDateValid,
      currentStateArray,
      currentState,
      isFromCancerStatus,
      closeModelMyProfile,
      nextSectionClinicalTrails,
      userDetail,
      isCancerStatusValid,
      isStagingValid,
   } = props;

   const { message: addCancerStatusMessage, error: addCancerStatusError } = useSelector(
      (state: storeTypes.IStore) => state.cancerStatus.addCancerStatus
   );

   const {
      list: getMasterCancerStatusList,
      error: getMasterCancerStatusError,
      isLoading: getMasterCancerStatusIsLoading,
   } = useSelector((state: storeTypes.IStore) => state.cancerStatus.getMasterCancerStatus);
   const { cancerStatusAddReset, getMasterCancerStatusReset } = cancerStatusSlice.actions;

   useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
         document.body.style.overflow = 'scroll';
      };
   }, []);

   useEffect(() => {
      if (getMasterCancerStatusList?.length > 0) {
         const cancerStatusArray: any[] = [];
         getMasterCancerStatusList.forEach((item: any) => {
            const listData = {
               label: item?.name || '',
               value: item?.id || 0,
            };
            cancerStatusArray.push(listData);
         });
         setCancerStatusList(cancerStatusArray);
         dispatch(getMasterCancerStatusReset());
      }
   }, [getMasterCancerStatusList]);

   const changeCancerStatusId = useCallback((data: any) => {
      setErrorMessage('');
      setCancerStatusId(data);
   }, []);

   const clickNext = useCallback(() => {
      if (!cancerStatusId?.value) {
         setErrorMessage('Cancer status cannot be blank');
         return;
      }
      setIsLoader(true);
      const data: myProfileSagaTypes.ICancerStatusProps = {
         cancer_status_id: cancerStatusId?.value || 0,
      };
      dispatch(addCancerStatusAction(data));
   }, [cancerStatusId]);

   const hideAlert = (type: string) => {
      if (type === 'addCancerStatusSuccess') {
         if (isFromCancerStatus) {
            closeModelMyProfile();
            getUserData();
         }
         dispatch(cancerStatusAddReset());
      } else if (type === 'addCancerStatusError') {
         dispatch(cancerStatusAddReset());
         setIsLoader(false);
      } else if (type === 'getMasterCancerStatusError') {
         dispatch(getMasterCancerStatusReset());
      }
   };

   const gotoNavigation = useCallback(() => {
      if (isFromClinicalTrials) {
         navigation(nextSectionClinicalTrails);
      } else {
         navigation(nexSection);
      }
   }, []);

   useEffect(() => {
      if (isCancerStatusValid) {
         gotoNavigation();
      } else {
         const params: myProfileSagaTypes.IMasterRaceProps = {
            page: 1,
            limit: 1000,
         };
         dispatch(getMasterCancerStatusAction(params));
      }
      // edit
      setCancerStatusId({ label: userDetail?.cancer_status?.name, value: userDetail?.cancer_status?.id || 0 });
   }, []);

   useEffect(() => {
      if (addCancerStatusMessage && isFromCancerStatus) {
         const getAlert = () => (
            <InfoTimerAlert
               success
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={addCancerStatusMessage}
               onConfirm={() => hideAlert('addCancerStatusSuccess')}
            />
         );
         setAlert(getAlert);
         setIsLoader(false);
         currentModalClose();
      } else if (
         addCancerStatusMessage &&
         !isDisplay &&
         !isPrimary &&
         !isAddress &&
         !isDiagnosisDateValid &&
         !isStagingValid &&
         !isCancerStatusValid
      ) {
         setIsLoader(false);
         gotoNavigation();
      } else if (addCancerStatusMessage) {
         setIsLoader(false);
         gotoNavigation();
      }
   }, [addCancerStatusMessage]);

   return (
      <div>
         {addCancerStatusError ? (
            <InfoTimerAlert
               error
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={addCancerStatusError}
               onConfirm={() => hideAlert('addCancerStatusError')}
            />
         ) : null}
         {getMasterCancerStatusError ? (
            <InfoTimerAlert
               error
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={getMasterCancerStatusError}
               onConfirm={() => hideAlert('getMasterCancerStatusError')}
            />
         ) : null}
         <div className="col-12">
            <img className="common-race-icon cancer-status-icon mt--2" src={CancerStatusIcon} alt="icon" />
         </div>
         <div className="col-12">
            <p className="common-race-title-text cancer-status-title-text">Cancer Status</p>
         </div>
         <div className="col-12">
            <p className="common-race-paragraph-text cancer-status-paragraph-text  p-2">
               Please select the status of your cancer
            </p>
         </div>
         <div className="col-12">
            <ReactSelect
               value={cancerStatusId}
               options={cancerStatusList}
               onChange={changeCancerStatusId}
               styles={{ ...CustomStyleoption, ...{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) } }}
               className="selectbox mb-3"
               maxMenuHeight={150}
               menuPlacement="auto"
               placeholder={'Select cancer status'}
               isLoading={getMasterCancerStatusIsLoading}
               noOptionsMessage={() => 'No Results Found'}
               menuPortalTarget={document.body}
            />
            {/* <SelectPicker
               preventOverflow={true}
               cleanable={false}
               autoFocus={true}
               maxHeight={150}
               onChange={changeCancerStatusId}
               value={cancerStatusId}
               data={cancerStatusList}
               renderMenu={(menu: any) => {
                  if (getMasterCancerStatusIsLoading) {
                     return (
                        <p
                           style={{
                              padding: 4,
                              color: '#999',
                              textAlign: 'center',
                           }}
                        >
                           <Icon icon="spinner" spin /> Loading
                        </p>
                     );
                  } else if (cancerStatusList?.length === 0) {
                     return (
                        <p
                           style={{
                              padding: 4,
                              color: '#999',
                              textAlign: 'center',
                           }}
                        >
                           No Results Found
                        </p>
                     );
                  }
                  return menu;
               }}
            /> */}
         </div>
         {errorMessage ? <div className="error-msg ml-4 mt-3 mr-4"> {errorMessage || ''}</div> : null}
         <div className="button">
            <button type="submit" className="button-start" id="cancerStatusSectionNext" onClick={clickNext}>
               {currentStateArray && currentStateArray[currentStateArray?.length - 1] === currentState
                  ? DONE
                  : isFromCancerStatus
                  ? SUBMIT
                  : NEXT}
            </button>
         </div>
      </div>
   );
};

export default CancerStatusSection;
