import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { aboutMeReducerTypes } from 'types';

const initialState: aboutMeReducerTypes.IModalDescriptionUpdateReducer = {
   error: '',
   message: '',
   isLoading: false,
   errors: [],
};

export const modalDescriptionUpdateSlice = createSlice({
   name: 'modalDescriptionUpdate',
   initialState: initialState,
   reducers: {
      descriptionUpdateRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      descriptionUpdateSuccess: (
         state,
         action: PayloadAction<aboutMeReducerTypes.IModalDescriptionUpdateSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      descriptionUpdateFailed: (
         state,
         action: PayloadAction<aboutMeReducerTypes.IModalDescriptionUpdateFailedPayload>
      ) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      descriptionUpdateReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
