import { AxiosResponse } from 'axios';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { ASK_A_DOC_FEED_CONNECTION_STATISTICS } from 'reduxToolKit/actions';
import { commonSlice, feedConnectionStatisticsSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   feedConnectionStatisticsRequest,
   feedConnectionStatisticsSuccess,
   feedConnectionStatisticsFailed,
} = feedConnectionStatisticsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const feedConnectionStatistics = function* () {
   yield put(feedConnectionStatisticsRequest());
   yield delay(500);
   try {
      const url = API.feedConnectionStatics;
      const checkUser = true;
      const goUrl = true;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, ...url });
      if (response.status === 200) {
         const resObj = { data: response?.data?.data || null };
         yield put(feedConnectionStatisticsSuccess(resObj));
      } else {
         yield put(feedConnectionStatisticsFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeFeedConnectionStatistics = function* () {
   yield takeLatest(ASK_A_DOC_FEED_CONNECTION_STATISTICS, feedConnectionStatistics);
};
