import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_LOCUM_SERVICE_STATE_AND_TRAVEL_SAGA } from 'reduxToolKit/actions';
import { commonSlice, settingLocumGetStateAndTravelListSlice } from 'reduxToolKit/reducers';
import { locumSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   settingLocumGetStateAndTravelListRequest,
   settingLocumGetStateAndTravelListSuccess,
   settingLocumGetStateAndTravelListFailed,
} = settingLocumGetStateAndTravelListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const settingLocumGetStateAndTravelListSaga = function* (
   medicalEnquiresListPayload: PayloadAction<locumSagaTypes.IGetLocumStateAndTravelSagaParams>
) {
   yield put(settingLocumGetStateAndTravelListRequest(medicalEnquiresListPayload.payload));
   try {
      const params = medicalEnquiresListPayload.payload;
      const url = API.getLocumServiceState;
      const response: AxiosResponse = yield apiCall({ params, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const settingLocumGetStateAndTravelList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message,
         };
         yield put(settingLocumGetStateAndTravelListSuccess(settingLocumGetStateAndTravelList));
      } else {
         yield put(settingLocumGetStateAndTravelListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeLocumGetStateAndTravelListRequest = function* () {
   yield takeEvery(GET_LOCUM_SERVICE_STATE_AND_TRAVEL_SAGA, settingLocumGetStateAndTravelListSaga);
};
