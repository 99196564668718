import { createAction } from '@reduxjs/toolkit';
import { caregiverDetailsSagaTypes } from 'types';

export const GET_CAREGIVER_DETAILS = 'GET_CAREGIVER_DETAILS';
export const getCaregiverDetailsAction = createAction<caregiverDetailsSagaTypes.IGetCaregiverDetails>(
   GET_CAREGIVER_DETAILS
);

export const UPDATE_CAREGIVER = 'UPDATE_CAREGIVER';
export const updateCaregiverAction = createAction<caregiverDetailsSagaTypes.IUpdateCaregiver>(UPDATE_CAREGIVER);

export const DELETE_CAREGIVER = 'DELETE_CAREGIVER';
export const deleteCaregiverSagaAction = createAction<caregiverDetailsSagaTypes.IDeleteCaregiver>(DELETE_CAREGIVER);
