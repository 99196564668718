import { createAction } from '@reduxjs/toolkit';
import { rpmDashBoardSagaTypes } from 'types';

export const RPM_DASHBOARD_LIST = 'RPM_DASHBOARD_LIST';
export const rpmDashBoardListAction = createAction<rpmDashBoardSagaTypes.IRpmDashboardList>(RPM_DASHBOARD_LIST);

export const RPM_PRESCRIPTION_LIST = 'RPM_PRESCRIPTION_LIST';
export const rpmPrescriptionListAction = createAction<rpmDashBoardSagaTypes.IRpmPrescription>(RPM_PRESCRIPTION_LIST);

export const RPM_PENDING_PRESCRIPTION = 'RPM_PENDING_PRESCRIPTION';
export const rpmPendingPrescriptionAction = createAction<rpmDashBoardSagaTypes.IRpmPendingPrescription>(
   RPM_PENDING_PRESCRIPTION
);

export const RPM_PRESCRIPTION_UPDATE = 'RPM_PRESCRIPTION_UPDATE';
export const rpmPrescriptionUpdateAction = createAction<rpmDashBoardSagaTypes.IRpmPrescriptionUpdate>(
   RPM_PRESCRIPTION_UPDATE
);

export const ADD_PRESCRIPTION = 'ADD_PRESCRIPTION';
export const prescriptionAddAction = createAction<rpmDashBoardSagaTypes.IRpmPrescriptionAdd>(ADD_PRESCRIPTION);

export const GET_PATIENT_MEDICATION = 'GET_PATIENT_MEDICATION';
export const getPatientMedicationAction = createAction<rpmDashBoardSagaTypes.IPatientMedications>(
   GET_PATIENT_MEDICATION
);

export const GET_LINE_THERAPY = 'GET_LINE_THERAPY';
export const getLineTherapyAction = createAction<rpmDashBoardSagaTypes.ILineOfTherapy>(GET_LINE_THERAPY);

export const GET_CO_MORBIDITIES = 'GET_CO_MORBIDITIES';
export const getCoMorbiditiesAction = createAction<rpmDashBoardSagaTypes.ILineOfTherapy>(GET_CO_MORBIDITIES);

export const GET_PATIENT_ACTIVE_RPM_DEVICE = 'GET_PATIENT_ACTIVE_RPM_DEVICE';
export const getPatientActiveRpmDeviceAction = createAction<rpmDashBoardSagaTypes.ILineOfTherapy>(
   GET_PATIENT_ACTIVE_RPM_DEVICE
);

export const GET_MEDITATION = 'GET_MEDITATION';
export const getMeditationAction = createAction<rpmDashBoardSagaTypes.ILineOfTherapy>(GET_MEDITATION);

export const GET_DEVICE = 'GET_DEVICE';
export const getDeviceAction = createAction(GET_DEVICE);

export const GET_PATIENT_MORBIDITIES = 'GET_PATIENT_MORBIDITIES';
export const getPatientMorbiditiesAction = createAction<rpmDashBoardSagaTypes.IPatientMedications>(
   GET_PATIENT_MORBIDITIES
);

export const GET_CLINIC_PHYSICIANS = 'GET_CLINIC_PHYSICIANS';
export const getClinicPhysicianAction = createAction<rpmDashBoardSagaTypes.IGetClinicPhy>(GET_CLINIC_PHYSICIANS);

export const GET_CLINIC_RPM_ALL_DEVICES = 'GET_CLINIC_RPM_ALL_DEVICES';
export const getClinicRpmAllDevicesAction = createAction<rpmDashBoardSagaTypes.IClinicRpmAllDevices>(
   GET_CLINIC_RPM_ALL_DEVICES
);

export const GET_CLINIC_RPM_DEVICES = 'GET_CLINIC_RPM_DEVICES';
export const getClinicRpmDevicesAction = createAction<rpmDashBoardSagaTypes.IClinicRpmAllDevices>(
   GET_CLINIC_RPM_DEVICES
);
