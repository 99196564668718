import { createSlice } from '@reduxjs/toolkit';
import { myClinicReducerTypes } from 'types';

const initialState: myClinicReducerTypes.IUpdateClinicAdminInviteStatusReducer = {
   error: '',
   message: '',
   isLoading: false,
};

export const updateClinicAdminInviteStatusSlice = createSlice({
   name: 'updateClinicAdminInviteStatus',
   initialState: initialState,
   reducers: {
      updateClinicAdminInviteStatusRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      updateClinicAdminInviteStatusSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      updateClinicAdminInviteStatusFailed: (state, action: any) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      updateClinicAdminInviteStatusReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
