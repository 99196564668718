import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ONCDRIVE_SHARE_ADD_SAGA, ONCDRIVE_UNSHARED_USER_SAGA } from 'reduxToolKit/actions';
import { commonSlice, oncDriveShareSlice, oncDriveUnShareSlice } from 'reduxToolKit/reducers';
import { oncDriveSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { oncDriveShareRequest, shareOncDriveSuccess, shareOncDriveFailed } = oncDriveShareSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const shareOncDrive = function* (oncDriveShare: oncDriveSagaTypes.IShareToDrivePayload) {
   yield put(oncDriveShareRequest(oncDriveShare.payload));
   try {
      const data = {
         onco_drive_id: oncDriveShare.payload?.onco_drive_id,
         user_ids: oncDriveShare.payload?.user_ids,
      };
      const response: AxiosResponse = yield apiCall({ data, ...API.oncDriveShareAdd });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: null,
         };
         yield put(shareOncDriveSuccess(resObj));
      } else {
         yield put(shareOncDriveFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const { oncDriveUnShareRequest, oncDriveUnShareSuccess, oncDriveUnShareFailed } = oncDriveUnShareSlice.actions;

const oncDriveUnShared = function* (oncDriveUnShare: oncDriveSagaTypes.IOncDriveUnSharedUserPayload) {
   yield put(oncDriveUnShareRequest(oncDriveUnShare.payload));
   try {
      const url = {
         apiPath: `${API.oncDriveUnSharedUser.apiPath}${oncDriveUnShare.payload.onc_drive_analytic_id}`,
         action: API.oncDriveUnSharedUser.action,
      };
      const response: AxiosResponse = yield apiCall({ ...url });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: null,
         };
         yield put(oncDriveUnShareSuccess(resObj));
      } else {
         yield put(oncDriveUnShareFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeShareOncDriveRequest = function* () {
   yield takeEvery(ONCDRIVE_SHARE_ADD_SAGA, shareOncDrive);
   yield takeEvery(ONCDRIVE_UNSHARED_USER_SAGA, oncDriveUnShared);
};
