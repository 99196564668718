import { createAction } from '@reduxjs/toolkit';
import { notificationSagaTypes } from 'types';

export const NOTIFICATION_GET_SETTINGS_LIST = 'NOTIFICATION_GET_SETTINGS_LIST';
export const notificationSettingsListAction = createAction<notificationSagaTypes.INotificationSettingsSagaPayload>(
   NOTIFICATION_GET_SETTINGS_LIST
);
export const NOTIFICATION_UPDATE_SETTINGS = 'NOTIFICATION_UPDATE_SETTINGS';
export const notificationUpdateSettingsAction = createAction<notificationSagaTypes.INotificationSettingsUpdateSagaPayload>(
   NOTIFICATION_UPDATE_SETTINGS
);
