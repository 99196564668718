import { createAction } from '@reduxjs/toolkit';
import { DrugLookUpSagaTypes } from 'types';
import { feedSagaTypes } from 'types/feed';

export const GET_DRUGS = 'GET_DRUGS';
export const getDrugsAction = createAction<feedSagaTypes.IGetDrugs>(GET_DRUGS);

export const GET_ONE_DRUGS = 'GET_ONE_DRUGS';
export const getOneDrugsAction = createAction<feedSagaTypes.IGetOneDrugs>(GET_ONE_DRUGS);

export const GET_ONE_DRUGS_CANCER_SPECIFIC = 'GET_ONE_DRUGS_CANCER_SPECIFIC';
export const getOneCancerSpecificAction = createAction<feedSagaTypes.IGetOneDrugs>(GET_ONE_DRUGS_CANCER_SPECIFIC);

export const GET_ONE_DRUG_REVIEW = 'GET_ONE_DRUG_REVIEW';
export const getReviewAction = createAction<DrugLookUpSagaTypes.IOneDrugGetReviews>(GET_ONE_DRUG_REVIEW);
