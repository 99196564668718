import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_STAGING } from 'reduxToolKit/actions';
import { commonSlice, stagingSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { stagingRequest, stagingSuccess, stagingFailed } = stagingSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const stagingSaga = function* (stagingPayload: any) {
   yield put(stagingRequest(stagingPayload.payload));
   try {
      const checkUser = false;
      const data = stagingPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...API.stagingAdd });
      if (response.status === 200) {
         const responseData = response.data;
         const stagingData = {
            message: responseData?.message || '',
         };
         yield put(stagingSuccess(stagingData));
      } else {
         yield put(stagingFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeStaging = function* () {
   yield takeEvery(ADD_STAGING, stagingSaga);
};
