import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { modalMyClinicsReducerTypes, modalMyClinicsSagaTypes } from 'types';

const initialState: modalMyClinicsReducerTypes.IModalGetMyClinicsReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
   joinClinics: {
      isLoading: false,
      message: '',
      error: '',
   },
};

export const modalGetClinicsSlice = createSlice({
   name: 'modalGetClinics',
   initialState: initialState,
   reducers: {
      modalGetClinicsRequest: (state, action: PayloadAction<modalMyClinicsSagaTypes.IModalGetMyClinicsSagaPayload>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action?.payload?.page === 1 ? [] : state.list,
         };
      },
      modalGetClinicsSuccess: (
         state,
         action: PayloadAction<modalMyClinicsReducerTypes.IModalGetMyClinicsSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            isLoading: false,
         };
      },
      modalGetClinicsFailed: (
         state,
         action: PayloadAction<modalMyClinicsReducerTypes.IModalGetMyClinicsFailedPayload>
      ) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      modalGetClinicsReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

export const modalJoinClinicsSlice = createSlice({
   name: 'modalJoinClinics',
   initialState: initialState,
   reducers: {
      modalJoinClinicsRequest: (state, action: PayloadAction<modalMyClinicsSagaTypes.IModalJoinClinicSagaPayload>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      modalJoinClinicsSuccess: (
         state,
         action: PayloadAction<modalMyClinicsReducerTypes.IModalJoinClinicSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            joinClinics: {
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      modalJoinClinicsFailed: (
         state,
         action: PayloadAction<modalMyClinicsReducerTypes.IModalJoinClinicFailedPayload>
      ) => {
         return {
            ...state,
            joinClinics: {
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      modalJoinClinicsReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
