import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINIC_PATIENT_BULK_IMPORT_SAGA } from 'reduxToolKit/actions';
import { commonSlice, clinicPatientBulkImportSlice } from 'reduxToolKit/reducers';
import { clinicAdminSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   clinicPatientBulkImportRequest,
   clinicPatientBulkImportSuccess,
   clinicPatientBulkImportFailed,
} = clinicPatientBulkImportSlice.actions;

const { exceptionHandlerRequest } = commonSlice.actions;

const clinicPatientBulkImport = function* (
   clinicPatientBulkImport: PayloadAction<clinicAdminSagaTypes.IClinicPatientBulkImport>
) {
   yield put(clinicPatientBulkImportRequest(clinicPatientBulkImport.payload));
   try {
      const data = clinicPatientBulkImport.payload;
      const goUrl = true;
      const checkUser = true;
      const response: AxiosResponse = yield apiCall({ data, goUrl, checkUser, ...API.clinicPatientBulkImport });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message || messages.success,
         };
         yield put(clinicPatientBulkImportSuccess(resObj));
      } else {
         yield put(clinicPatientBulkImportFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeClinicPatientBulkImportRequest = function* () {
   yield takeEvery(CLINIC_PATIENT_BULK_IMPORT_SAGA, clinicPatientBulkImport);
};
