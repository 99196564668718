import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_DAILY_CHECK } from 'reduxToolKit/actions';
import { addDailyCheckLogSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';
//import { viewAppointmentSagaTypes } from 'types';

const { addDailyCheckLog, addDailyCheckLogSuccess, addDailyCheckLogFailed } = addDailyCheckLogSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const addDailyCheckLogSaga = function* (payloadId: any) {
   yield put(addDailyCheckLog(payloadId.payload));
   try {
      let data = payloadId.payload;
      let url = { apiPath: `${API.addCheckLog.apiPath}`, action: API.addCheckLog.action };
      if (payloadId.payload?.id > 0) {
         data = {
            notes: payloadId.payload.notes,
         };
         url = {
            apiPath: `${API.addCheckNotes.apiPath}${payloadId.payload.id}${'/update'}`,
            action: API.addCheckNotes.action,
         };
      }

      const response: AxiosResponse = yield apiCall({ data, ...url });

      if (response.status === 200) {
         const responseData = response.data;
         yield put(addDailyCheckLogSuccess(responseData));
      } else {
         yield put(addDailyCheckLogFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeAddCheckLogRequest = function* () {
   yield takeEvery(ADD_DAILY_CHECK, addDailyCheckLogSaga);
};
