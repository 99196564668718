import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { MEDICATION_SAVING_DETAIL, MEDICATION_SAVING_LIST } from 'reduxToolKit/actions';
import { commonSlice, medicationSavingSlice } from 'reduxToolKit/reducers';
import { medicationSavingSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   medicationSavingListLoading,
   medicationSavingListRequest,
   medicationSavingListSuccess,
   medicationSavingListFailed,
   medicationSavingDetailRequest,
   medicationSavingDetailSuccess,
   medicationSavingDetailFailed,
} = medicationSavingSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getMedicationSavingListSaga = function* (
   medicationSharingPayload: PayloadAction<medicationSavingSagaTypes.IMedicationSavingParams>
) {
   yield put(medicationSavingListLoading(medicationSharingPayload.payload));
   if (medicationSharingPayload.payload.search_word) {
      yield delay(1000);
   }
   yield put(medicationSavingListRequest(medicationSharingPayload.payload));

   try {
      const params = medicationSharingPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         params,
         ...API.getMedicationSavingList,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const medicationSavingList = {
            list: responseData.data.data,
            total_records: responseData.data.total,
            message: response.data?.message || messages.success,
            current_page: responseData.data.current_page,
         };
         yield put(medicationSavingListSuccess(medicationSavingList));
      } else {
         yield put(medicationSavingListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getMedicationSavingDetailSaga = function* (
   medicationSharingDetailPayload: PayloadAction<medicationSavingSagaTypes.IMedicationSavingParams>
) {
   yield put(medicationSavingDetailRequest(medicationSharingDetailPayload.payload));

   try {
      const params = medicationSharingDetailPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         params,
         ...API.getMedicationSavingDetail,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const medicationSavingDetail = {
            data: responseData.data,
            message: response.data?.message || messages.success,
         };
         yield put(medicationSavingDetailSuccess(medicationSavingDetail));
      } else {
         yield put(medicationSavingDetailFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGetMedicationSaving = function* () {
   yield takeLatest(MEDICATION_SAVING_LIST, getMedicationSavingListSaga);
   yield takeEvery(MEDICATION_SAVING_DETAIL, getMedicationSavingDetailSaga);
};
