import React, { useCallback, useEffect, useState } from 'react';

import RaceIcon from 'assets/fill-profile/race_icon.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { addRaceAction, getMasterRaceAction } from 'reduxToolKit/actions';
import { raceSlice } from 'reduxToolKit/reducers';
// import { Icon, SelectPicker } from 'rsuite';
import { myProfileSagaTypes, storeTypes } from 'types';
import { DONE, NEXT, SUBMIT } from 'utils/constants/profileConstants';
import { CustomStyleoption } from 'utils/constants/uiConstants';

const RaceSection = (props: any) => {
   const dispatch = useDispatch();
   const [errorMessage, setErrorMessage] = useState<string>('');
   const [raceList, setRaceList] = useState<any[]>([]);
   const [raceId, setRaceId] = useState<any>(null);

   const { message: addRaceMessage, error: addRaceError } = useSelector(
      (state: storeTypes.IStore) => state.race.addRace
   );

   const { list: getMasterRaceList, error: getMasterRaceError, isLoading: getMasterRaceIsLoading } = useSelector(
      (state: storeTypes.IStore) => state.race.getMasterRace
   );
   const { raceAddReset, getMasterRaceReset } = raceSlice.actions;

   useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
         document.body.style.overflow = 'scroll';
      };
   }, []);

   useEffect(() => {
      if (getMasterRaceList?.length > 0) {
         const raceArray: any[] = [];
         getMasterRaceList.forEach((item: any) => {
            const listData = {
               label: item?.name || '',
               value: item?.id || 0,
            };
            raceArray.push(listData);
         });
         setRaceList(raceArray);
         dispatch(getMasterRaceReset());
      }
   }, [getMasterRaceList]);

   const changeRaceId = useCallback((data: any) => {
      setErrorMessage('');
      setRaceId(data);
   }, []);

   const clickNext = useCallback(() => {
      if (!raceId.value) {
         setErrorMessage('Race cannot be blank');
         return;
      }
      props?.setIsLoader(true);
      const data: myProfileSagaTypes.IRaceProps = {
         race_id: raceId?.value || 0,
      };
      dispatch(addRaceAction(data));
   }, [raceId]);

   const hideAlert = (type: string) => {
      if (type === 'addRaceSuccess') {
         if (props?.isFromRace) {
            props?.closeModelMyProfile();
            props.getUserData();
         }
         dispatch(raceAddReset());
         props?.setIsLoader(false);
      } else if (type === 'addRaceError') {
         dispatch(raceAddReset());
         props?.setIsLoader(false);
      } else if (type === 'getMasterRaceError') {
         dispatch(getMasterRaceReset());
      }
   };

   const gotoNavigation = useCallback(() => {
      if (props?.isFromClinicalTrials) {
         props.navigation(props?.nextSectionClinicalTrails);
      } else {
         props.navigation(props.nexSection);
      }
   }, []);

   useEffect(() => {
      if (props.isRaceValid) {
         gotoNavigation();
      } else {
         const params: myProfileSagaTypes.IMasterRaceProps = {
            page: 1,
            limit: 1000,
         };
         dispatch(getMasterRaceAction(params));
      }
      // edit
      setRaceId({ label: props?.userDetail?.race?.name, value: props?.userDetail?.race?.id || 0 });
   }, []);

   useEffect(() => {
      if (addRaceMessage && props?.isFromRace) {
         const getAlert = () => (
            <InfoTimerAlert
               success
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={addRaceMessage}
               onConfirm={() => hideAlert('addRaceSuccess')}
            />
         );
         props?.setAlert(getAlert);
         props?.setIsLoader(false);
         props.currentModalClose();
      } else if (addRaceMessage) {
         props.setIsLoader(false);
         gotoNavigation();
      }
   }, [addRaceMessage]);

   return (
      <div>
         {addRaceError ? (
            <InfoTimerAlert
               error
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={addRaceError}
               onConfirm={() => hideAlert('addRaceError')}
            />
         ) : null}
         {getMasterRaceError ? (
            <InfoTimerAlert
               error
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={getMasterRaceError}
               onConfirm={() => hideAlert('getMasterRaceError')}
            />
         ) : null}
         <div className="col-12">
            <img className="common-race-icon race-icon mt--2" src={RaceIcon} alt="icon" />
         </div>
         <div className="col-12">
            <p className="common-race-title-text race-title-text">Race</p>
         </div>
         <div className="col-12">
            <p className="common-race-paragraph-text race-paragraph-text  p-2">Please select your race</p>
         </div>
         <div className="col-12">
            <ReactSelect
               value={raceId}
               options={raceList}
               onChange={changeRaceId}
               styles={{ ...CustomStyleoption, ...{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) } }}
               className="selectbox mb-3"
               maxMenuHeight={150}
               menuPlacement="auto"
               placeholder={'Select race'}
               isLoading={getMasterRaceIsLoading}
               noOptionsMessage={() => 'No Results Found'}
               menuPortalTarget={document.body}
            />
         </div>
         {errorMessage ? <div className="error-msg ml-4 mt-3 mr-4"> {errorMessage || ''}</div> : null}
         <div className="button">
            <button type="submit" className="button-start" id="raceSectionNext" onClick={clickNext}>
               {props?.currentStateArray &&
               props?.currentStateArray[props?.currentStateArray?.length - 1] === props?.currentState
                  ? DONE
                  : props?.isFromRace
                  ? SUBMIT
                  : NEXT}
            </button>
         </div>
      </div>
   );
};

export default RaceSection;
