import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_ONE_DRUGS, GET_ONE_DRUG_REVIEW } from 'reduxToolKit/actions';
import { commonSlice, getOneDrugsListSlice } from 'reduxToolKit/reducers';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getOneDrugsListViewRequest, getOneDrugsListViewSuccess, getOneDrugsListFailed } = getOneDrugsListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getOneDrugs = function* (cancerTypePayload: any) {
   yield put(getOneDrugsListViewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getOneDrugs });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            name: responseData.data.name,
            drug_url: responseData.data.drug_url,
            list: responseData.data.drug_cancer_specific,
            message: response.data?.message,
         };
         yield put(getOneDrugsListViewSuccess(oncDriveList));
      } else {
         yield put(getOneDrugsListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const { getOneDrugsReviewRequest, getOneDrugsReviewSuccess, getOneDrugsReviewFailed } = getOneDrugsListSlice.actions;
const getOneDrugReviewsSaga = function* (cancerTypePayload: any) {
   yield put(getOneDrugsReviewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getReview });
      if (response.status === 200) {
         const responseData = {
            message: response.data.message || messages.success,
            data: response.data.data,
         };
         yield put(getOneDrugsReviewSuccess(responseData));
      } else {
         yield put(getOneDrugsReviewFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGetOneDrugs = function* () {
   yield takeEvery(GET_ONE_DRUGS, getOneDrugs);
   yield takeEvery(GET_ONE_DRUG_REVIEW, getOneDrugReviewsSaga);
};
