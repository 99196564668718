import { createSlice } from '@reduxjs/toolkit';
import { peopleNearMeReducerTypes } from 'types';

const initialState: peopleNearMeReducerTypes.IDrugSpecific = {
   isLoading: false,
   error: '',
   message: '',
   data: {},
};

export const drugSpecificListSlice = createSlice({
   name: 'drugSpecificList',
   initialState: initialState,
   reducers: {
      drugSpecificListViewRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: state.data,
         };
      },
      drugSpecificListViewSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            data: payload.data,
         };
      },
      drugSpecificListFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      drugSpecificListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
