import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { FEED_POST_LIST } from 'reduxToolKit/actions';
import { commonSlice, feedPostListSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { feedPostListRequest, feedPostListSuccess, feedPostListFailed } = feedPostListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const feedPostListSaga = function* (feedPostListPayload: PayloadAction<feedSagaTypes.IMySavedFeetPostList>) {
   yield put(feedPostListRequest(feedPostListPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = feedPostListPayload.payload;
      let url = API.getMySavedPostList;
      if (params.isGetMyPosts) {
         url = API.getMyPosts;
      }
      const response: AxiosResponse = yield apiCall({ params, goUrl, checkUser, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const feedPostList = {
            list: responseData.list,
            total_records: responseData.total_records,
            message: responseData?.message || messages.success,
         };
         yield put(feedPostListSuccess(feedPostList));
      } else {
         yield put(feedPostListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeFeedPostListRequest = function* () {
   yield takeLatest(FEED_POST_LIST, feedPostListSaga);
};
