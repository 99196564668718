import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { USER_DETAILS } from 'reduxToolKit/actions';
import { commonSlice, userDetailsSlice } from 'reduxToolKit/reducers';
import { userInviteEmailPageTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getUserDetails, getUserDetailSuccess, getUserDetailFailed } = userDetailsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const UserDetails = function* (payloadUser: PayloadAction<userInviteEmailPageTypes.IUserDetails>) {
   yield put(getUserDetails(payloadUser?.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, ...API.userDetails });
      if (response.status === 200) {
         yield put(getUserDetailSuccess(response.data));
      } else {
         yield put(getUserDetailFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeUserDetailsRequest = function* () {
   yield takeEvery(USER_DETAILS, UserDetails);
};
