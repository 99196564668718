import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   ASK_A_DOC_ADD_ESTIMATE,
   ASK_A_DOC_ADD_ESTIMATE_PAYMENT,
   ASK_A_DOC_CANCEL_ESTIMATE,
   ASK_A_DOC_ESTIMATE_END_CHAT,
} from 'reduxToolKit/actions';
import { askADocEstimateSlice, commonSlice } from 'reduxToolKit/reducers';
import { caseDetailsReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   askADocEstimateRequest,
   askADocEstimateCancelRequest,
   askADocEstimatePaymentRequest,
   askADocEstimateEndChatRequest,
   askADocEstimateSuccess,
   askADocEstimateFailed,
} = askADocEstimateSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const askADocAddEstimateSaga = function* (
   payloadState: PayloadAction<caseDetailsReducerTypes.ICaseDetailsAddEstimate>
) {
   yield put(askADocEstimateRequest(payloadState.payload));
   try {
      const checkUser = false;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...API.askADocAddEstimate });
      if (response.status === 200) {
         const estimateResponseData = {
            data: response.data?.data,
            message: response.data?.message || messages.success,
            requestData: data,
         };
         yield put(askADocEstimateSuccess(estimateResponseData));
      } else {
         yield put(askADocEstimateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const askADocCancelEstimateSaga = function* (
   payloadState: PayloadAction<caseDetailsReducerTypes.ICaseDetailsCancelEstimate>
) {
   yield put(askADocEstimateCancelRequest(payloadState.payload));
   try {
      const checkUser = false;
      const data = payloadState.payload;
      const apiPath = {
         apiPath: `${API.askADocCancelEstimate.apiPath}${data.ask_physician_id}`,
         action: API.askADocCancelEstimate.action,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...apiPath });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(askADocEstimateSuccess(responseData));
      } else {
         yield put(askADocEstimateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const askADocAddEstimatePaymentSaga = function* (
   payloadState: PayloadAction<caseDetailsReducerTypes.ICaseDetailsAddEstimatePayment>
) {
   yield put(askADocEstimatePaymentRequest(payloadState.payload));
   try {
      const checkUser = false;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...API.askADocAddEstimatePayment });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(askADocEstimateSuccess(responseData));
      } else {
         yield put(askADocEstimateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const askADocAddEstimateEndChatSaga = function* (
   payloadState: PayloadAction<caseDetailsReducerTypes.ICaseDetailsCancelEstimate>
) {
   yield put(askADocEstimateEndChatRequest(payloadState.payload));
   try {
      const checkUser = false;
      const data = payloadState.payload;
      const apiPath = {
         apiPath: `${API.askADocEstimateEndChat.apiPath}${data.ask_physician_id}`,
         action: API.askADocEstimateEndChat.action,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...apiPath });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(askADocEstimateSuccess(responseData));
      } else {
         yield put(askADocEstimateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAskADocEstimateRequest = function* () {
   yield takeEvery(ASK_A_DOC_ADD_ESTIMATE, askADocAddEstimateSaga);
   yield takeEvery(ASK_A_DOC_CANCEL_ESTIMATE, askADocCancelEstimateSaga);
   yield takeEvery(ASK_A_DOC_ADD_ESTIMATE_PAYMENT, askADocAddEstimatePaymentSaga);
   yield takeEvery(ASK_A_DOC_ESTIMATE_END_CHAT, askADocAddEstimateEndChatSaga);
};
