import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_SCHEDULE } from 'reduxToolKit/actions';
import { getScheduleSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getScheduleRequest, getScheduleSuccess, getScheduleFailed } = getScheduleSlice.actions;

export const getSchedule = function* (checkAppointmentSlicepayload: any) {
   yield put(getScheduleRequest(checkAppointmentSlicepayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = checkAppointmentSlicepayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getSchedule });
      if (response.status === 200) {
         const responseData = response.data.data;
         yield put(getScheduleSuccess(responseData));
      } else {
         yield put(getScheduleFailed(response.data));
      }
   } catch (error) {}
};

export const takeGetScheduleRequest = function* () {
   yield takeEvery(GET_SCHEDULE, getSchedule);
};
