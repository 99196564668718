// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const retryLoading = async (importFn: any, retries = 2, interval = 1000) => {
   return await importFn();

   // TODO: check duplicate loading after component loading
   /* try {
      return await importFn();
   } catch (error) {
      if (retries) {
         await wait(interval);
         return retryLoading(importFn, retries - 1, interval);
      } else {
         window.location.reload();
      }
   } */
};
/* const wait = (ms: any) => {
   return new Promise((resolve) => setTimeout(resolve, ms));
}; */
