import { createAction } from '@reduxjs/toolkit';
import { postShareSagaTypes } from 'types';

export const GET_SERVICES = 'GET_SERVICES';
export const getService = createAction<postShareSagaTypes.IWallet>(GET_SERVICES);

export const GET_SERVICE_SPECIALITY = 'GET_SERVICE_SPECIALITY';
export const getServiceSpeciality = createAction<postShareSagaTypes.IWallet>(GET_SERVICE_SPECIALITY);

export const GET_SERVICE_PHY_SPECIALITY = 'GET_SERVICE_PHY_SPECIALITY';
export const getServicePhySpeciality = createAction<postShareSagaTypes.IMyServicePhysicians>(
   GET_SERVICE_PHY_SPECIALITY
);

export const UPDATE_MY_SERVICE = 'UPDATE_MY_SERVICE';
export const updateMyServices = createAction<postShareSagaTypes.IMyServiceUpdateDataPayload>(UPDATE_MY_SERVICE);

export const ADD_MY_SERVICE_SPECIALTES = 'ADD_MY_SERVICE_SPECIALTES';
export const addMyServicesSpecialties = createAction<postShareSagaTypes.IAddMyServiceSpecialties>(
   ADD_MY_SERVICE_SPECIALTES
);
