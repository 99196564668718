import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { PROFILE_DELETE_PUBLICATION_SAGA, UPDATE_PROFILE_PUBLICATION } from 'reduxToolKit/actions';
import { commonSlice, profilePublicationSlice } from 'reduxToolKit/reducers';
import { publicationSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   deletePublicationRequest,
   deletePublicationSuccess,
   deletePublicationFailed,
   updatePublicationRequest,
   updatePublicationSuccess,
   updatePublicationFailed,
} = profilePublicationSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

//deletePublication Saga
const deletePublicationSaga = function* (payloadState: PayloadAction<publicationSagaTypes.IProfilePublicationForm>) {
   yield put(deletePublicationRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.deletePublication });
      if (response.status === 200) {
         const resObj = {
            message: response.data?.message || messages.success,
         };
         yield put(deletePublicationSuccess(resObj));
      } else {
         yield put(deletePublicationFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

// Update Publications
const updatePublicationSaga = function* (payloadState: PayloadAction<publicationSagaTypes.IUpdatePublication>) {
   yield put(updatePublicationRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.updatePublication });
      if (response.status === 200) {
         const resObj = {
            message: response.data?.message || messages.success,
         };
         yield put(updatePublicationSuccess(resObj));
      } else {
         yield put(updatePublicationFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePublicationManage = function* () {
   yield takeEvery(PROFILE_DELETE_PUBLICATION_SAGA, deletePublicationSaga);
   yield takeEvery(UPDATE_PROFILE_PUBLICATION, updatePublicationSaga);
};
