import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { askDocSagaTypes, caseDetailsPageTypes, caseDetailsReducerTypes, storeTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: caseDetailsReducerTypes.ICaseDetailsReducer = {
   isLoading: false,
   error: '',
   errorMessage: '',
   message: '',
   data: null,
   rejectCaseDetailRequest: {
      message: '',
      error: '',
   },
   pickAskADocRequest: {
      message: '',
      error: '',
   },
   askADocEndConversation: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   askADocConversationAddQuestion: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   askADocChangeClinic: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   askADocCaseHistory: {
      isLoading: false,
      message: '',
      error: '',
      list: [],
      pagination: {
         current_page: null,
         last_page: null,
         first_page_url: '',
         last_page_url: '',
         next_page_url: '',
         prev_page_url: '',
         total: 0,
      },
   },
   requestData: null,
};

export const getAskADocCaseDetailsSlice = createSlice({
   name: 'getAskADocCaseDetails',
   initialState: initialState,
   reducers: {
      getAskADocCaseDetailsRequest: (state, action) => {
         return {
            ...state,
            isLoading: true,
            ...action.payload,
         };
      },
      getAskADocCaseDetailsSuccess: (
         state,
         action: PayloadAction<caseDetailsReducerTypes.ICaseDetailsSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            data: payload?.data || null,
            message: payload?.message || messages.success,
            error: '',
            isLoading: false,
         };
      },
      updateAskADocCaseDetailsStatus: (state, action: PayloadAction<caseDetailsPageTypes.IOneRequestCaseDetails>) => {
         return {
            ...state,
            data: action?.payload,
         };
      },
      getAskADocCaseDetailsFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: action.payload.error || '',
         };
      },
      getAskADocCaseDetailsReset: () => {
         return { ...initialState };
      },

      rejectAskADocCaseDetailsRequest: (state, action: PayloadAction<caseDetailsReducerTypes.IGetOneAskADocId>) => {
         return {
            ...state,
            isLoading: true,
            ...action.payload,
         };
      },
      rejectAskADocCaseDetailsSuccess: (
         state,
         action: PayloadAction<caseDetailsReducerTypes.ICaseDetailsSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            rejectCaseDetailRequest: {
               message: payload?.message,
               error: '',
            },
            isLoading: false,
         };
      },
      rejectAskADocCaseDetailsFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            rejectCaseDetailRequest: {
               message: '',
               error: action.payload.error,
            },
         };
      },
      rejectAskADocCaseDetailsReset: (state) => {
         return {
            ...state,
            rejectCaseDetailRequest: {
               message: '',
               error: '',
            },
         };
      },

      askADocRequestPickRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      askADocRequestPickSuccess: (state, action: PayloadAction<caseDetailsReducerTypes.ICaseDetailsSuccessPayload>) => {
         return {
            ...state,
            pickAskADocRequest: {
               message: action.payload?.message,
               error: '',
            },
         };
      },
      askADocRequestPickFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            pickAskADocRequest: {
               error: action.payload?.error,
               message: '',
            },
         };
      },
      askADocRequestPickReset: (state) => {
         return {
            ...state,
            pickAskADocRequest: {
               message: '',
               error: '',
            },
         };
      },
      // ask a doc end conversation
      askADocEndConversationRequest: (
         state,
         action: PayloadAction<caseDetailsReducerTypes.IAskADocEndConversation>
      ) => {
         return {
            ...state,
            askADocEndConversation: {
               ...state.askADocEndConversation,
               isLoading: true,
               ...action.payload,
            },
         };
      },
      askADocEndConversationSuccess: (
         state,
         action: PayloadAction<caseDetailsReducerTypes.ICaseDetailsSuccessPayload>
      ) => {
         return {
            ...state,
            askADocEndConversation: {
               isLoading: false,
               data: action.payload.data,
               message: action.payload?.message || messages.success,
               error: '',
            },
         };
      },
      askADocEndConversationFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            askADocEndConversation: {
               isLoading: false,
               error: action.payload?.error,
               message: '',
            },
         };
      },
      askADocEndConversationReset: (state) => {
         return {
            ...state,
            askADocEndConversation: { ...initialState.askADocEndConversation },
         };
      },
      // ask a doc conversation add question
      askADocConversationAddQuestionRequest: (
         state,
         action: PayloadAction<caseDetailsReducerTypes.IAskADocConversationAddQuestion>
      ) => {
         return {
            ...state,
            askADocConversationAddQuestion: {
               ...state.askADocConversationAddQuestion,
               isLoading: true,
               ...action.payload,
            },
         };
      },
      askADocConversationAddQuestionSuccess: (
         state,
         action: PayloadAction<caseDetailsReducerTypes.ICaseDetailsSuccessPayload>
      ) => {
         return {
            ...state,
            askADocConversationAddQuestion: {
               isLoading: false,
               data: action.payload.data,
               message: action.payload?.message || messages.success,
               error: '',
            },
         };
      },
      askADocConversationAddQuestionFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            askADocConversationAddQuestion: {
               isLoading: false,
               error: action.payload?.error,
               message: '',
            },
         };
      },
      askADocConversationAddQuestionReset: (state) => {
         return {
            ...state,
            askADocConversationAddQuestion: { ...initialState.askADocConversationAddQuestion },
         };
      },
      // ask a doc change clinic
      askADocChangeClinicRequest: (
         state,
         action: PayloadAction<caseDetailsReducerTypes.IAskADocChangeClinicSagaRequest>
      ) => {
         return {
            ...state,
            askADocChangeClinic: {
               ...state.askADocChangeClinic,
               isLoading: true,
               ...action.payload,
            },
         };
      },
      askADocChangeClinicSuccess: (
         state,
         action: PayloadAction<caseDetailsReducerTypes.ICaseDetailsSuccessPayload>
      ) => {
         return {
            ...state,
            askADocChangeClinic: {
               isLoading: false,
               data: action.payload.data,
               message: action.payload?.message || messages.success,
               error: '',
            },
         };
      },
      askADocChangeClinicFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            askADocChangeClinic: {
               isLoading: false,
               error: action.payload?.error || messages.failed,
               message: '',
            },
         };
      },
      askADocChangeClinicReset: (state) => {
         return {
            ...state,
            askADocChangeClinic: { ...initialState.askADocChangeClinic },
         };
      },
      // ask a doc case history
      askADocCaseHistoryRequest: (
         state,
         action: PayloadAction<caseDetailsReducerTypes.IAskADocCaseHistorySagaRequest>
      ) => {
         return {
            ...state,
            askADocCaseHistory: {
               ...state.askADocCaseHistory,
               ...action.payload,
               isLoading: true,
               list: action.payload.page && action.payload.page === 1 ? [] : state.askADocCaseHistory?.list || [],
            },
         };
      },
      askADocCaseHistorySuccess: (state, action: PayloadAction<any>) => {
         const { payload } = action;
         const stateList = state.askADocCaseHistory?.list || [];
         return {
            ...state,
            askADocCaseHistory: {
               isLoading: false,
               pagination: payload.pagination,
               list: payload.pagination.current_page === 1 ? payload.list : [...stateList, ...payload.list],
               message: action.payload?.message || messages.success,
               error: '',
            },
         };
      },
      askADocCaseHistoryFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            askADocCaseHistory: {
               isLoading: false,
               error: action.payload?.error || messages.failed,
               message: '',
            },
         };
      },
      askADocCaseHistoryReset: (state) => {
         return {
            ...state,
            askADocCaseHistory: { ...initialState.askADocCaseHistory },
         };
      },
   },
});

const checkRequiredFieldInitialState: caseDetailsReducerTypes.IAskADocCaseDetailsCheckRequiredFields = {
   isLoading: false,
   reponseMessage: '',
   data: null,
   error: '',
};
export const getAskADocCaseDetailsCheckRequiredFieldsSlice = createSlice({
   name: 'askADocCheckRequiredFields',
   initialState: checkRequiredFieldInitialState,
   reducers: {
      askADocRequiredFieldsRequest: (state, action: PayloadAction<askDocSagaTypes.IAskADocCheckRequiredFields>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      askADocRequiredFieldsSuccess: (
         state,
         action: PayloadAction<caseDetailsReducerTypes.ICaseDetailsSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            ...action.payload,
            isLoading: false,
            reponseMessage: payload?.message || messages.success,
            data: payload?.data || null,
            error: '',
         };
      },
      askADocRequiredFieldsFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            error: action.payload.error || '',
            message: '',
            data: null,
         };
      },
      askADocRequiredFieldsReset: () => {
         return { ...checkRequiredFieldInitialState };
      },
   },
});
