import { createAction } from '@reduxjs/toolkit';
import { postShareSagaTypes } from 'types';

export const WALLET = 'WALLET';
export const walletAction = createAction<postShareSagaTypes.IWallet>(WALLET);

export const WALLET_PHYSICIAN = 'WALLET_PHYSICIAN';
export const walletPhysicianAction = createAction<postShareSagaTypes.IWallet>(WALLET_PHYSICIAN);

export const WALLET_OFFERS = 'WALLET_OFFERS';
export const walletOffersAction = createAction<postShareSagaTypes.IWallet>(WALLET_OFFERS);

export const PHYSICIAN_REWARD = 'PHYSICIAN_REWARD';
export const physicianRewardAction = createAction<postShareSagaTypes.IWalletReward>(PHYSICIAN_REWARD);

export const WALLET_BALANCE_GET = 'WALLET_BALANCE_GET';
export const walletBalanceGetAction = createAction(WALLET_BALANCE_GET);
