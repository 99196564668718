import { createSlice } from '@reduxjs/toolkit';
import { IInviteUserEmailReducer } from 'types/userInvite/userInviteEmailReducerTypes';

const initialState: IInviteUserEmailReducer = {
   isLoading: false,
   error: '',
   message: '',
   userInvite: null,
};

const initialStateValidation: IInviteUserEmailReducer = {
   isLoading: false,
   error: '',
   message: '',
   userInvite: null,
};

export const userInviteEmailSlice = createSlice({
   name: 'userInviteEmail',
   initialState: initialState,
   reducers: {
      userInviteEmailView: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      userInviteEmailSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            userInvite: payload,
            isLoading: false,
         };
      },
      userInviteEmailFailed: (state, action) => {
         return {
            ...state,
            userInvite: null,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      userInviteEmailReset: (state, action) => {
         return {
            ...state,
            userInvite: null,
            isLoading: false,
            message: '',
            error: '',
         };
      },
   },
});

export const userInviteEmailValidationSlice = createSlice({
   name: 'userInviteEmailValidation',
   initialState: initialStateValidation,
   reducers: {
      userInviteEmailValidationView: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      userInviteEmailValidationSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      userInviteEmailValidationFailed: (state, action) => {
         return {
            ...state,
            userInvite: null,
            isLoading: false,
            message: '',
            error: action.payload.error_message,
         };
      },
      userInviteEmailValidationReset: () => {
         return { ...initialStateValidation };
      },
   },
});
