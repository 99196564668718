import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { DELETE_CLINICAL_TRAIL } from 'reduxToolKit/actions';
import { commonSlice, deleteClinicalTrialSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   deleteClinicalTrialRequest,
   deleteClinicalTrialSuccess,
   deleteClinicalTrialFailed,
} = deleteClinicalTrialSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const deleteClinicalTrialSaga = function* (deleteClinicalTrialPayload: any) {
   yield put(deleteClinicalTrialRequest(deleteClinicalTrialPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = deleteClinicalTrialPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.deleteClinicalTrial });
      if (response.status === 200) {
         const responseData = response.data;
         const deleteClinicalTrialList = {
            message: responseData?.message || '',
         };
         yield put(deleteClinicalTrialSuccess(deleteClinicalTrialList));
      } else {
         yield put(deleteClinicalTrialFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takedeleteClinicalTrial = function* () {
   yield takeEvery(DELETE_CLINICAL_TRAIL, deleteClinicalTrialSaga);
};
