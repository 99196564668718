import { AxiosResponse } from 'axios';
import { delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ONCDRIVE_CLINIC_PATIENT_SAGA, ONCDRIVE_CLINIC_PATIENT_SEARCH_SAGA } from 'reduxToolKit/actions';
import { commonSlice, oncDriveMyClinicPatientListSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   oncDriveMyClinicPatientListViewLoading,
   oncDriveMyClinicPatientListViewRequest,
   oncDriveMyClinicPatientListViewSuccess,
   oncDriveMyClinicPatientListViewFailed,
} = oncDriveMyClinicPatientListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const oncDriveMyClinicPatientListSaga = function* (oncDriveMyClinicPatientListPayload: any) {
   if (oncDriveMyClinicPatientListPayload.payload.search_word !== '') {
      yield put(oncDriveMyClinicPatientListViewLoading());
      yield delay(500);
   }
   yield put(oncDriveMyClinicPatientListViewRequest(oncDriveMyClinicPatientListPayload.payload));
   try {
      const params = oncDriveMyClinicPatientListPayload.payload;
      const url = API.myClinicPatient;
      const response: AxiosResponse = yield apiCall({ params, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const responseObject = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message,
         };
         yield put(oncDriveMyClinicPatientListViewSuccess(responseObject));
      } else {
         yield put(oncDriveMyClinicPatientListViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeOncDriveMyClinicPatientListRequest = function* () {
   yield takeEvery(ONCDRIVE_CLINIC_PATIENT_SAGA, oncDriveMyClinicPatientListSaga);
};
export const takeOncDriveMyClinicPatientSearchRequest = function* () {
   yield takeLatest(ONCDRIVE_CLINIC_PATIENT_SEARCH_SAGA, oncDriveMyClinicPatientListSaga);
};
