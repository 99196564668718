import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_AUXILIARY_STAFF_MODEL } from 'reduxToolKit/actions';
import { addAuxiliaryStaffSlice, commonSlice } from 'reduxToolKit/reducers';
import { postShareSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { addAuxiliaryStaffRequest, addAuxiliaryStaffSuccess, addAuxiliaryStaffFailed } = addAuxiliaryStaffSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const addAuxiliaryStaffSaga = function* (payloadState: PayloadAction<postShareSagaTypes.IAddAuxiliaryStaff>) {
   yield put(addAuxiliaryStaffRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         goUrl,
         data,
         ...API.phyPractiseAuxiliaryStaffModelAdd,
      });
      if (response.status === 200) {
         const resObj = { message: response?.data?.message };
         yield put(addAuxiliaryStaffSuccess(resObj));
      } else {
         yield put(addAuxiliaryStaffFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAddAuxiliaryStaff = function* () {
   yield takeEvery(ADD_AUXILIARY_STAFF_MODEL, addAuxiliaryStaffSaga);
};
