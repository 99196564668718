import { getAgeNumberHelper } from '../dateHelpers';

export const getClinicalTrialsAge = (userData: any) => {
   if (userData) {
      const age = Number(getAgeNumberHelper(userData.date_of_birth));
      if (age >= 0 && age <= 17) {
         return ['Child'];
      } else if (age >= 18 && age <= 64) {
         return ['Adult'];
      } else if (age >= 65) {
         return ['Older Adult'];
      } else {
         return [];
      }
   }
   return [];
};

export const getClinicalTrialsGender = (userData: any) => {
   if (userData) {
      const gender = userData.gender;
      if (gender === 'm') {
         return ['Male'];
      } else if (gender === 'f') {
         return ['Female'];
      } else if (gender === 't') {
         return ['All'];
      } else {
         return [];
      }
   }
   return [];
};

export const getClinicalTrialsCancerType = (userData: any) => {
   if (userData) {
      const cancerPatient = userData.cancer_patient;
      if (cancerPatient?.cancer_type?.name) {
         return [cancerPatient.cancer_type.name];
      } else {
         return [];
      }
   }
   return [];
};

export const getClinicalTrialsCancerTypeIds = (userData: any) => {
   if (userData) {
      const cancerPatient = userData.cancer_patient;
      if (cancerPatient?.cancer_type?.id) {
         return [cancerPatient.cancer_type.id];
      } else {
         return [];
      }
   }
   return [];
};

export const getClinicalTrialsCancerSpecific = (userData: any) => {
   if (userData) {
      const cancerPatient = userData.cancer_patient;
      if (cancerPatient?.cancer?.name) {
         return [{ cancer_specific_name: cancerPatient.cancer.name, cancer_type_id: cancerPatient?.cancer_type?.id }];
      } else {
         return [];
      }
   }
   return [];
};

export const getClinicalTrailsDefaultStudyPhase = () => {
   return ['Phase 2', 'Phase 3'];
};

export const getClinicalTrialsCountries = (userData: any) => {
   if (userData) {
      const countryPatient = userData.country;
      if (countryPatient?.name) {
         return [countryPatient?.name];
      } else {
         return [];
      }
   }
   return [];
};

export const getClinicalTrialsCountriesIds = (userData: any) => {
   if (userData) {
      const countryPatient = userData.country;
      if (countryPatient?.id) {
         return [countryPatient?.id];
      } else {
         return [];
      }
   }
   return [];
};

export const getClinicalTrialsState = (userData: any) => {
   if (userData) {
      const statePatient = userData.patient_state;
      if (statePatient?.state?.name) {
         return [{ state_name: statePatient?.state?.name, country_id: statePatient?.state?.country_id }];
      } else {
         return [];
      }
   }
   return [];
};

export const getStatusClassName = (status: string) => {
   switch (status) {
      case 'Recruiting':
      case 'Enrolling by invitation': {
         return 'card-sts-green';
      }
      case 'Not yet recruiting': {
         return 'card-sts-red';
      }
      default: {
         return 'card-sts-green';
      }
   }
};

export const getAgeGroup = (age: string) => {
   switch (age) {
      case 'Child': {
         return '1-17';
      }
      case 'Adult': {
         return '18-64';
      }
      case 'Older Adult': {
         return '65+';
      }
      default: {
         return '-';
      }
   }
};
