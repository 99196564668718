import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { DELETE_EDUCATION } from 'reduxToolKit/actions';
import { commonSlice, deleteEducationSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { deleteEducationRequest, deleteEducationSuccess, deleteEducationFailed } = deleteEducationSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const deleteEducationSaga = function* (deleteEducationPayload: any) {
   yield put(deleteEducationRequest(deleteEducationPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = deleteEducationPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.deleteEducation });
      if (response.status === 200) {
         const responseData = response.data;
         const deleteEducationList = {
            message: responseData?.message || '',
         };
         yield put(deleteEducationSuccess(deleteEducationList));
      } else {
         yield put(deleteEducationFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeDeleteEducationRequest = function* () {
   yield takeEvery(DELETE_EDUCATION, deleteEducationSaga);
};
