import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MODAL_GET_CLINICS_SAGA, MODAL_JOIN_CLINIC_SAGA } from 'reduxToolKit/actions';
import { commonSlice, modalGetClinicsSlice, modalJoinClinicsSlice } from 'reduxToolKit/reducers';
import { modalMyClinicsSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';
import * as UserType from 'utils/helpers/userHelpers/userTypeHelpers';
import { isPCAUser } from 'utils/helpers/userHelpers/userTypeHelpers';

const { modalGetClinicsRequest, modalGetClinicsSuccess, modalGetClinicsFailed } = modalGetClinicsSlice.actions;
const { modalJoinClinicsRequest, modalJoinClinicsSuccess, modalJoinClinicsFailed } = modalJoinClinicsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const modalGetClinicsSaga = function* (
   getClinicsTypePayload: PayloadAction<modalMyClinicsSagaTypes.IModalGetMyClinicsSagaPayload>
) {
   yield put(modalGetClinicsRequest(getClinicsTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = getClinicsTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getClinics });
      if (response.status === 200) {
         const responseData = response.data;
         const getClinicsList = {
            list: responseData?.data?.list || [],
            total_records: responseData?.data?.total_records || 0,
            message: responseData?.message || '',
         };
         yield put(modalGetClinicsSuccess(getClinicsList));
      } else {
         yield put(modalGetClinicsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const modalJoinClinicsSaga = function* (
   joinClinicPayload: PayloadAction<modalMyClinicsSagaTypes.IModalJoinClinicSagaPayload>
) {
   yield put(modalJoinClinicsRequest(joinClinicPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = {
         clinic_id: joinClinicPayload.payload.clinic_id,
      };
      let url: any = null;
      if (isPCAUser(joinClinicPayload.payload.userData)) {
         url = { checkUser, goUrl, data, ...API.joinClinicPatient };
      } else if (UserType.isPhysician(joinClinicPayload.payload.userData)) {
         url = { checkUser, goUrl, data, ...API.joinClinicPhysician };
      }
      const response: AxiosResponse = yield apiCall(url);
      if (response.data) {
         const responseData = response.data;
         const joinClinicList = {
            message: responseData?.message || '',
         };
         yield put(modalJoinClinicsSuccess(joinClinicList));
      } else {
         yield put(modalJoinClinicsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGetClinicsRequest = function* () {
   yield takeEvery(MODAL_GET_CLINICS_SAGA, modalGetClinicsSaga);
   yield takeEvery(MODAL_JOIN_CLINIC_SAGA, modalJoinClinicsSaga);
};

// export const takeJoinClinicsRequest = function* () {};
