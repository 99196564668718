import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, Card, Container, Grid, Typography } from '@material-ui/core';
import Back from 'assets/askDoc/backIconBlue.svg';
import ProfileLayoutModal from 'component/fillProfile/ProfileLayout';
import DotLoader from 'component/loader/DotLoader';
import AddCTHippoCompliance from 'component/modal/AddCTHippoCompliance';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import NoData from 'component/widget/AdminNoData';
import Loading from 'component/widget/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
   clinicalTrialsInterestSagaAction,
   clinicalTrialsListItemGetOneSagaAction,
   fillProfileAction,
} from 'reduxToolKit/actions';
import {
   clinicalTrialsSlice,
   populationServedSlice,
   serviceOfferedSlice,
   userDetailsSlice,
} from 'reduxToolKit/reducers';
import { clinicalTrialsPageTypes, myProfileSagaTypes, storeTypes, userInviteEmailPageTypes } from 'types';
import { CommonConstants, SET_TIME_OUT } from 'utils/constants/common';
import { addUserObject, getUser } from 'utils/helpers/authHeader';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';
import { changeOneDateFormatToAnotherApi, getCurrentDate } from 'utils/helpers/dateHelpers';
import { simpleDecode } from 'utils/helpers/encryptHelpers';
import { isOrganization, isPCAUser } from 'utils/helpers/userHelpers/userTypeHelpers';
import {
   isAddValidation,
   isCancerStatusValidation,
   isDiagnosisDateValidation,
   isDisplayName,
   isDobValidation,
   isLatinoValidation,
   isOrgNameValidation,
   isPhoneValidation,
   isPrimaryCancer,
   isRaceValidation,
   isStagingValidation,
} from 'utils/helpers/validationHelpers';

import ClinicalTrialsListItem from './ClinicTrialListItem';

const ClinicalTrialsDetail = () => {
   const dispatch = useDispatch();
   const history = useHistory();

   const [clinicalTrialId, setClinicalTrialId] = useState<number | string>(0);
   const [openCTHippoCompliance, setOpenCTHippoCompliance] = useState<boolean>(false);

   const [isProfileLayoutModal, setIsProfileLayoutModal] = useState<boolean>(false);
   const [isProfileLayoutRemainingModal, setIsProfileLayoutRemainingModal] = useState<boolean>(false);
   const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
   const [isDobValid, setIsDobValid] = useState<boolean>(false);
   const [isAddressValid, setIsAddressValid] = useState<boolean>(false);
   const [isDisplayNameValid, setIsDisplayNameValid] = useState<boolean>(false);
   const [isPrimaryCancerValid, setIsPrimaryCancerValid] = useState<boolean>(false);
   const [isRaceValid, setIsRaceValid] = useState<boolean>(false);
   const [isStagingValid, setIsStagingValid] = useState<boolean>(false);
   const [isDiagnosisDateValid, setIsDiagnosisDateValid] = useState<boolean>(false);
   const [isLatinoValid, setIsLatinoValid] = useState<boolean>(false);
   const [isCancerStatusValid, setIsCancerStatusValid] = useState<boolean>(false);
   const [isServiceOfferedValid, setIsServiceOfferedValid] = useState(false);
   const [isOrgNameValid, setIsOrgNameValid] = useState(false);
   const [isPopulationServedValid, setIsPopulationServedValid] = useState(false);

   const {
      isLoading: interestIsLoading,
      message: interestMessage,
      error: interestError,
      data: interestData,
      requestData: interestRequestData,
   } = useSelector((state: storeTypes.IStore) => state.clinicalTrials.clinicalTrialsInterest);

   const { list: clinicalTrialsList, isLoading: isLoadingList } = useSelector(
      (state: storeTypes.IStore) => state.clinicalTrials.clinicalTrialsList
   );

   const {
      clinicalTrialsInterestRequestLoading,
      clinicalTrialsListUpdate,
      clinicalTrialsListReset,
      clinicalTrialsInterestReset,
   } = clinicalTrialsSlice.actions;

   const { isFrom, userDetail } = useSelector((state: storeTypes.IStore) => state.userDetail);
   const { getUserReset } = userDetailsSlice.actions;

   const {
      list: listUserServiceOffered,
      message: messageUserServiceOffered,
      // isLoading: serviceOfferdLoading,
   } = useSelector((state: storeTypes.IStore) => state.serviceOffered.getUserServiceOffered);
   const {
      data: getUserPopulationServedData,
      message: messagePopulationServed,
      // isLoading: populationServedLoading,
   } = useSelector((state: storeTypes.IStore) => state.populationServed.getUserPopulationServed);

   const { getUserServiceOfferedMessageReset } = serviceOfferedSlice.actions;
   const { getUserPopulationServedMessageReset } = populationServedSlice.actions;

   useEffect(() => {
      if (messageUserServiceOffered || messagePopulationServed) {
         if (listUserServiceOffered) {
            setIsServiceOfferedValid(listUserServiceOffered?.length > 0);
            dispatch(getUserServiceOfferedMessageReset());
         }

         if (getUserPopulationServedData) {
            const { primary_cancers } = getUserPopulationServedData;
            setIsPopulationServedValid(primary_cancers?.length > 0);
            dispatch(getUserPopulationServedMessageReset());
         }
      }
   }, [listUserServiceOffered, messageUserServiceOffered, messagePopulationServed, getUserPopulationServedData]);

   useEffect(() => {
      if (isOrgNameValid && isServiceOfferedValid && isPopulationServedValid) {
         setIsProfileLayoutModal(false);
      } else {
         setIsProfileLayoutModal(true);
      }
   }, [isOrgNameValid, isServiceOfferedValid, isPopulationServedValid]);

   const pathParams: {
      id: string;
   } = useParams();
   const { id: clinicalTrialsIdString }: any = pathParams;

   useEffect(() => {
      if (interestError) {
         dispatch(clinicalTrialsInterestReset());
         setOpenCTHippoCompliance(true);
      }
   }, [interestError]);

   const hideAlert = (type: string) => {
      if (type === 'successAlertInterest') {
         const clinicalTrialsListArrayCopy = listParseAndStringify(clinicalTrialsList);
         const index = clinicalTrialsListArrayCopy.findIndex(
            (x: any) => x.id === interestRequestData.clinical_trial_id
         );
         const interestDataLocal = listParseAndStringify(interestData);
         interestDataLocal.clinical_trial.is_interested = 1;
         clinicalTrialsListArrayCopy.splice(index, 1, interestDataLocal?.clinical_trial);
         dispatch(clinicalTrialsListUpdate(clinicalTrialsListArrayCopy));

         dispatch(clinicalTrialsInterestReset());
      }
   };

   const openHippoCompliance = () => {
      setOpenCTHippoCompliance(!openCTHippoCompliance);
   };

   const addCTHippaCompliance = (data: any) => {
      clickInterested(clinicalTrialId, data);
   };

   const clickClinicalTrialsHome = useCallback(() => {
      history?.push('/clinical-trials');
   }, []);

   const clickInterested = useCallback(
      (clinicalTrialId: number | string, dataObj?: any) => {
         if (!userDetail || !dataObj) {
            return;
         }
         if (!userDetail?.user) {
            return;
         }
         setClinicalTrialId(clinicalTrialId);
         if (
            !isDiagnosisDateValidation(userDetail?.user) ||
            !isStagingValidation(userDetail?.user) ||
            !isCancerStatusValidation(userDetail?.user) ||
            !isRaceValidation(userDetail?.user) ||
            !isLatinoValidation(userDetail?.user)
         ) {
            setIsProfileLayoutRemainingModal(true);
            return;
         }
         const data: clinicalTrialsPageTypes.IClinicalTrialsInterestParams = {
            clinical_trial_id: clinicalTrialId,
            user_name: dataObj.user_name || null,
            signature: dataObj.signature || null,
            hipaa_sign_date: changeOneDateFormatToAnotherApi(getCurrentDate()),
         };
         dispatch(clinicalTrialsInterestSagaAction(data));
      },
      [userDetail]
   );

   const updatedUser = (user: any) => {
      if (user) {
         setIsPrimaryCancerValid(isPrimaryCancer(user));
         setIsDisplayNameValid(isDisplayName(user));
         setIsAddressValid(isAddValidation(user));
         setIsDobValid(isDobValidation(user));
         setIsPhoneValid(isPhoneValidation(user));
         setIsRaceValid(isRaceValidation(user));
         setIsStagingValid(isStagingValidation(user));
         setIsDiagnosisDateValid(isDiagnosisDateValidation(user));
         setIsLatinoValid(isLatinoValidation(user));
         setIsCancerStatusValid(isCancerStatusValidation(user));
         setIsOrgNameValid(isOrgNameValidation(user));

         if (isPCAUser(user)) {
            if (!isDisplayName() || !isPrimaryCancer() || !isAddValidation() || !isDobValidation()) {
               setIsProfileLayoutModal(true);
            }
         }
         // TODO: checking purpose hided this
         /* else {
            if (isOrgNameValid && isServiceOfferedValid && isPopulationServedValid) {
               setIsProfileLayoutModal(false);
            } else {
               setIsProfileLayoutModal(true);
            }
         } */
      }
   };

   useEffect(() => {
      if (userDetail && isFrom === 'clinicalTrailsCard') {
         addUserObject(userDetail);
         setTimeout(() => {
            updatedUser(userDetail?.user);
            if (clinicalTrialId && isFrom === 'clinicalTrailsCard') {
               clickInterested(clinicalTrialId);
            }
         }, SET_TIME_OUT.MIN);
      }
   }, [userDetail, isFrom, isOrgNameValid, isServiceOfferedValid, isPopulationServedValid]);

   const fillProfileModal = () => {
      setIsProfileLayoutModal(false);
      fillProfileApi('clinicalTrailsHome');
   };

   const fillProfileApi = (fromValue: string) => {
      const userData: userInviteEmailPageTypes.IUserDetails = {
         isFrom: fromValue,
      };
      const params: myProfileSagaTypes.IFillProfileProps = {
         params: { page: 1, limit: CommonConstants.DropDownLimit },
         data: userData,
      };
      dispatch(fillProfileAction(params));
   };

   const fillProfileModalRemaining = useCallback(() => {
      setIsProfileLayoutRemainingModal(false);
      dispatch(getUserReset());
      dispatch(clinicalTrialsInterestRequestLoading());
      setTimeout(() => {
         fillProfileApi('clinicalTrailsCard');
      }, SET_TIME_OUT.MIN);
   }, []);

   const fillProfileModalClose = useCallback(() => {
      setIsProfileLayoutRemainingModal(false);
   }, []);

   useEffect(() => {
      dispatch(getUserReset());
      const clinicalTrailsIdsObj = JSON.parse(simpleDecode(clinicalTrialsIdString));
      const trialId = clinicalTrailsIdsObj.clinical_trial_id || 0;

      const clinicalTrialId = parseInt(trialId) || 0;
      if (clinicalTrialId > 0) {
         dispatch(clinicalTrialsListReset());
         const params = {
            clinical_trails_id: clinicalTrialId,
         };
         dispatch(clinicalTrialsListItemGetOneSagaAction(params));
      }
      fillProfileApi('clinicalTrailsHome');
      /* populationServedList();
      getUserServiceOfferedFun(); */
      return () => {
         dispatch(getUserReset());
      };
   }, []);

   return (
      <Box mt={16}>
         <Container maxWidth="lg">
            <Card className="trials_card">
               <Box>
                  {isProfileLayoutModal ? (
                     isPCAUser(getUser()) || isOrganization(getUser()) ? (
                        <ProfileLayoutModal
                           closeModal={fillProfileModal}
                           isFirst={false}
                           phoneValid={isPhoneValid}
                           dobValid={isDobValid}
                           addressValid={isAddressValid}
                           displayNameValid={isDisplayNameValid}
                           primaryCancerValid={isPrimaryCancerValid}
                           isRaceValid={isRaceValid}
                           isStagingValid={isStagingValid}
                           isDiagnosisDateValid={isDiagnosisDateValid}
                           isLatinoValid={isLatinoValid}
                           isCancerStatusValid={isCancerStatusValid}
                           isServiceOfferedValid={isServiceOfferedValid}
                           isOrgNameValid={isOrgNameValid}
                           isPopulationServedValid={isPopulationServedValid}
                           userDetail={getUser()}
                           isFromClinicalTrials={true}
                           isFromClinicalTrialsHome={true}
                           isFromFeed={true}
                           isFromOrg={isOrganization(getUser())}
                        />
                     ) : null
                  ) : null}
                  {isProfileLayoutRemainingModal ? (
                     isPCAUser(getUser()) || isOrganization(getUser()) ? (
                        <ProfileLayoutModal
                           closeModal={fillProfileModalRemaining}
                           isFirst={false}
                           phoneValid={isPhoneValid}
                           dobValid={isDobValid}
                           addressValid={isAddressValid}
                           displayNameValid={isDisplayNameValid}
                           primaryCancerValid={isPrimaryCancerValid}
                           isRaceValid={isRaceValid}
                           isStagingValid={isStagingValid}
                           isDiagnosisDateValid={isDiagnosisDateValid}
                           isLatinoValid={isLatinoValid}
                           isCancerStatusValid={isCancerStatusValid}
                           isServiceOfferedValid={isServiceOfferedValid}
                           isOrgNameValid={isOrgNameValid}
                           isPopulationServedValid={isPopulationServedValid}
                           userDetail={getUser()}
                           isFromClinicalTrials={true}
                           isFromClinicalTrialsList={true}
                           isFromFeed={true}
                           fillProfileModalClose={fillProfileModalClose}
                           isFromOrg={isOrganization(getUser())}
                        />
                     ) : null
                  ) : null}
                  {interestIsLoading ? <Loading /> : null}
                  {interestMessage ? (
                     <InfoTimerAlert
                        setManualTimer={true}
                        manualTimer={5}
                        success
                        confirmBtnText={`Okay!`}
                        confirmBtnBsStyle={`successBtn`}
                        title=""
                        onConfirm={() => hideAlert('successAlertInterest')}
                     >
                        {interestMessage}
                     </InfoTimerAlert>
                  ) : null}
                  {openCTHippoCompliance && (
                     <AddCTHippoCompliance
                        userData={getUser()}
                        openHippoCompliance={openHippoCompliance}
                        addCTHippaCompliance={addCTHippaCompliance}
                     />
                  )}
                  <Typography className="trials_title trials_title-center">Clinical Trials Detail</Typography>
                  {isLoadingList && clinicalTrialsList?.length === 0 ? <DotLoader /> : null}
                  {!isLoadingList && clinicalTrialsList?.length === 0 ? (
                     <Box>
                        <NoData message="No data found" />
                        <Box className="trials-no-data-home-box">
                           <Button
                              variant="contained"
                              color="primary"
                              startIcon={<img src={Back} alt="Back" />}
                              onClick={clickClinicalTrialsHome}
                           >
                              Clinical Trials
                           </Button>
                        </Box>
                     </Box>
                  ) : null}
                  {!isLoadingList && clinicalTrialsList?.length > 0 ? (
                     <Box>
                        <Box className="trials-container">
                           <Grid container direction="column" spacing={2} alignItems="center">
                              <Grid item xs={9} className="mb-3">
                                 <ClinicalTrialsListItem
                                    i={1}
                                    item={clinicalTrialsList[0]}
                                    clickInterested={clickInterested}
                                 />
                              </Grid>
                           </Grid>
                        </Box>
                     </Box>
                  ) : null}
               </Box>
            </Card>
         </Container>
      </Box>
   );
};

export default ClinicalTrialsDetail;
