import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { secondOpinionReducerTypes, secondOpinionSagaTypes } from 'types';

const initialState: secondOpinionReducerTypes.ISecOpinionPhyListReducerPayload = {
   list: {
      data: null,
      message: '',
      error: '',
      isLoading: false,
   },
   addNDelete: {
      message: '',
      error: '',
      isLoading: false,
   },
};

export const secOpinionShortPhyListSlice = createSlice({
   name: 'secOpinionShortPhyList',
   initialState: initialState,
   reducers: {
      //list
      secOpinionShortPhyListRequest: (
         state,
         action: PayloadAction<secondOpinionSagaTypes.ISecOpinionShortListPhySagaPayload>
      ) => {
         return {
            ...state,
            list: {
               ...state.list,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      secOpinionShortPhyListSuccess: (
         state,
         action: PayloadAction<secondOpinionReducerTypes.ISecOpinionPhyListSuccessPayload>
      ) => {
         return {
            ...state,
            list: {
               ...state.list,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      secOpinionShortPhyListFailure: (
         state,
         action: PayloadAction<secondOpinionReducerTypes.ISecOpinionPhyListFailurePayload>
      ) => {
         return {
            ...state,
            list: {
               ...state.list,
               error: action.payload.error,
               isLoading: false,
            },
         };
      },

      secOpinionShortPhyListReset: (state) => {
         return {
            ...state,
            list: {
               ...initialState.list,
            },
         };
      },
      //add n delete
      secOpinionAddNDeleteShortPhyRequest: (
         state,
         action: PayloadAction<secondOpinionSagaTypes.ISecOpinionAddNDeleteShortListSagaPayload>
      ) => {
         return {
            ...state,
            addNDelete: {
               ...state.addNDelete,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      secOpinionAddNDeleteShortPhySuccess: (
         state,
         action: PayloadAction<secondOpinionReducerTypes.ISecOpinionAddNDeleteSucessPayload>
      ) => {
         return {
            ...state,
            addNDelete: {
               ...state.addNDelete,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      secOpinionAddNDeleteShortPhyFailure: (
         state,
         action: PayloadAction<secondOpinionReducerTypes.ISecOpinionPhyListFailurePayload>
      ) => {
         return {
            ...state,
            addNDelete: {
               ...state.addNDelete,
               error: action.payload.error,
               isLoading: false,
            },
         };
      },

      secOpinionAddNDeleteShortPhyReset: (state) => {
         return {
            ...state,
            addNDelete: {
               ...initialState.addNDelete,
            },
         };
      },
   },
});
