import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { SUPPORT_MY_GROUP_LIST } from 'reduxToolKit/actions';
import { commonSlice, supportMyGroupsSlice } from 'reduxToolKit/reducers';
import { postShareSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   supportMyGroupsLoading,
   supportMyGroupsViewRequest,
   supportMyGroupsViewSuccess,
   supportMyGroupsFailed,
} = supportMyGroupsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const supportMyGroupsSaga = function* (cancerTypePayload: PayloadAction<postShareSagaTypes.ISupportMyGroupsList>) {
   if (cancerTypePayload.payload.search_word !== '') {
      yield put(supportMyGroupsLoading());
      yield delay(500);
   }
   yield put(supportMyGroupsViewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.supportGroupsTabList });
      if (response.status === 200) {
         const responseData = response?.data;
         const oncDriveList = {
            list: responseData?.data?.list,
            total_records: responseData?.data?.total_records,
            message: response?.data?.message,
         };
         yield put(supportMyGroupsViewSuccess(oncDriveList));
      } else {
         yield put(supportMyGroupsFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeSupportMyGroups = function* () {
   yield takeLatest(SUPPORT_MY_GROUP_LIST, supportMyGroupsSaga);
};
