import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addFamilyMemberReducerTypes } from 'types';

const initialState: addFamilyMemberReducerTypes.IModalAddFamilyMemberReducer = {
   error: '',
   message: '',
   isLoading: false,
};

export const modalAddFamilyMemberSlice = createSlice({
   name: 'modalAddFamilyMember',
   initialState: initialState,
   reducers: {
      addFamilyMemberRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addFamilyMemberSuccess: (
         state,
         action: PayloadAction<addFamilyMemberReducerTypes.IModalAddFamilyMemberSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      addFamilyMemberFailed: (
         state,
         action: PayloadAction<addFamilyMemberReducerTypes.IModalAddFamilyMemberFailedPayload>
      ) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      addFamilyMemberReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
