import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { DOB_SECTION_PROFILE_SAGA } from 'reduxToolKit/actions';
import { commonSlice, dobSectionPostSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { dobSectionRequest, dobSectionSuccess, dobSectionFailed } = dobSectionPostSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const dobSectionSaga = function* (payloadState: any) {
   yield put(dobSectionRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.profileCompletion });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: null,
         };
         yield put(dobSectionSuccess(resObj));
      } else {
         yield put(dobSectionFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeProfileDobSection = function* () {
   yield takeEvery(DOB_SECTION_PROFILE_SAGA, dobSectionSaga);
};
