import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   LATEST_VIEWED_CLINIC,
   GET_MY_CLINIC_LIST,
   GET_MY_APPOINTMENTS,
   ADD_LATEST_VIEWED_CLINIC,
} from 'reduxToolKit/actions';
import { commonSlice, oncoKlinicSlice } from 'reduxToolKit/reducers';
import { oncoKlinicSagatypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   latestClinicRequest,
   latestClinicSuccess,
   latestClinicFailed,
   myClinicRequest,
   myClinicSuccess,
   myClinicFailed,
   myAppointmentRequest,
   myAppointmentSuccess,
   myAppointmentFailed,
   latestViewedClinicRequest,
   latestViewedClinicSuccess,
   latestViewedClinicFailed,
} = oncoKlinicSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const latestViewdClinicSaga = function* () {
   yield put(latestClinicRequest());
   try {
      const checkUser = true;
      const goUrl = true;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, ...API.latestClinic });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            data: responseData.data,
         };
         yield put(latestClinicSuccess(oncDriveList));
      } else {
         yield put(latestClinicFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getMyClinicListSaga = function* (payloadState: PayloadAction<oncoKlinicSagatypes.IMyClinicList>) {
   yield put(myClinicRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, ...API.getMyClinic });
      if (response.status === 200) {
         const responseData = {
            message: response.data.message || messages.success,
            data: response.data.data,
         };
         yield put(myClinicSuccess(responseData));
      } else {
         yield put(myClinicFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getMyAppointmentsSaga = function* (payloadState: PayloadAction<oncoKlinicSagatypes.IGetMyAppointments>) {
   yield put(myAppointmentRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, ...API.getMyClinic });
      if (response.status === 200) {
         const responseData = {
            message: response.data.message || messages.success,
            data: response.data.data,
         };
         yield put(myAppointmentSuccess(responseData));
      } else {
         yield put(myAppointmentFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const addLatestViewedClinicSaga = function* (payloadState: PayloadAction<oncoKlinicSagatypes.IAddLatestViewedClinic>) {
   yield put(latestViewedClinicRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addLatestViewedClinic });
      if (response.status === 200) {
         const responseData = {
            message: response.data.message || messages.success,
            data: response.data.data,
         };
         yield put(latestViewedClinicSuccess(responseData));
      } else {
         yield put(latestViewedClinicFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeLatestClinic = function* () {
   yield takeEvery(LATEST_VIEWED_CLINIC, latestViewdClinicSaga);
   yield takeEvery(GET_MY_CLINIC_LIST, getMyClinicListSaga);
   yield takeEvery(GET_MY_APPOINTMENTS, getMyAppointmentsSaga);
   yield takeEvery(ADD_LATEST_VIEWED_CLINIC, addLatestViewedClinicSaga);
};
