import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clinicalNotesReducerTypes, clinicalNotesSagaTypes } from 'types';

const initialState: clinicalNotesReducerTypes.IClinicalNotesAddReducer = {
   isLoading: false,
   error: '',
   message: '',
};
export const clinicalNotesAddSlice = createSlice({
   name: 'clinicalNotesAdd',
   initialState: initialState,
   reducers: {
      clinicalNotesAddLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      clinicalNotesAddRequest: (state, action: PayloadAction<clinicalNotesSagaTypes.IClinicalNotesAddForm>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      clinicalNotesAddSuccess: (state, action: PayloadAction<clinicalNotesReducerTypes.IClinicalNotesAddResponse>) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            message: payload.message,
         };
      },
      clinicalNotesAddFailed: (state, action: PayloadAction<clinicalNotesReducerTypes.IClinicNotesFailed>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      clinicalNotesAddReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
