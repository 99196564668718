import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { askADocPatientReducerTypes, storeTypes } from 'types';

const initialState: askADocPatientReducerTypes.IAskADocPatientStateReducerTypes = {
   isLoading: false,
   data: null,
   error: '',
   message: '',
};

export const getPatientStateSlice = createSlice({
   name: 'getPatientState',
   initialState: initialState,
   reducers: {
      getPatientStateRequest: (state, action: PayloadAction<askADocPatientReducerTypes.IAskADocPatientStateProps>) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      getPatientStateSuccess: (
         state,
         action: PayloadAction<askADocPatientReducerTypes.IAskADocPatientStateReducerTypes>
      ) => {
         return {
            ...state,
            data: action.payload?.data,
            message: action.payload?.message,
            error: '',
            isLoading: false,
         };
      },
      getPatientStateFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            data: null,
            message: '',
            error: action.payload?.error,
            isLoading: false,
         };
      },
      getPatientStateReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
