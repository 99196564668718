import { createAction } from '@reduxjs/toolkit';
import {
   myProfileSagaTypes,
   publicationSagaTypes,
   skillsSagaTypes,
   socialHistoryReducerTypes,
   socialHistorySagaTypes,
} from 'types';
import { IEditProfile } from 'types/modelHelpers';

export const GET_PRIMARY_DISEASE = 'GET_PRIMARY_DISEASE';
export const getPrimaryDiseaseAction = createAction<myProfileSagaTypes.IGetPrimaryDisease>(GET_PRIMARY_DISEASE);

export const DELETE_CLINICAL_TRAIL = 'DELETE_CLINICAL_TRAIL';
export const deleteClinicalTrialAction = createAction<myProfileSagaTypes.IDeleteClinicalTrial>(DELETE_CLINICAL_TRAIL);

export const DELETE_CERTIFICATE = 'DELETE_CERTIFICATE';
export const deleteCertificateAction = createAction<myProfileSagaTypes.IDeleteCertificate>(DELETE_CERTIFICATE);

export const DELETE_EXPERIENCE = 'DELETE_EXPERIENCE';
export const deleteExperienceAction = createAction<myProfileSagaTypes.IDeleteCertificate>(DELETE_EXPERIENCE);

export const DELETE_EDUCATION = 'DELETE_EDUCATION';
export const deleteEducationAction = createAction<myProfileSagaTypes.IDeleteEducationTraining>(DELETE_EDUCATION);

export const GET_FAMILY_MEMBERS = 'GET_FAMILY_MEMBERS';
export const getFamilyMembersAction = createAction<myProfileSagaTypes.IGetFamilyMembers>(GET_FAMILY_MEMBERS);

export const UPDATE_FAMILY_MEMBER = 'UPDATE_FAMILY_MEMBER';
export const updateFamilyMemberAction = createAction<myProfileSagaTypes.IUpdateFamilyMembers>(UPDATE_FAMILY_MEMBER);

export const DELETE_FAMILY_MEMBER = 'DELETE_FAMILY_MEMBER';
export const deleteFamilyMemberAction = createAction<myProfileSagaTypes.IDeleteFamilyMembers>(DELETE_FAMILY_MEMBER);

export const GET_DISEASES = 'GET_DISEASES';
export const getDiseasesAction = createAction<myProfileSagaTypes.IGetPrimaryDisease>(GET_DISEASES);

export const GET_FAMILY_MEMBER_TYPES = 'GET_FAMILY_MEMBER_TYPES';
export const getFamilyMemberTypesAction = createAction<myProfileSagaTypes.IGetPrimaryDisease>(GET_FAMILY_MEMBER_TYPES);

//getSocial Histories Action
export const PROFILE_GET_SOCIAL_HISTORIES_SAGA = 'PROFILE_GET_SOCIAL_HISTORIES_SAGA';
export const profileGetSocialHistoriesAction = createAction<socialHistorySagaTypes.IGetSocialHistoriesForm>(
   PROFILE_GET_SOCIAL_HISTORIES_SAGA
);
//getSkills
export const PROFILE_GET_SKILLS_SAGA = 'PROFILE_GET_SKILLS_SAGA';
export const profileGetSkillsAction = createAction<skillsSagaTypes.IGetSkillsForm>(PROFILE_GET_SKILLS_SAGA);

// updateSkills
export const UPDATE_USER_SKILLS = 'UPDATE_USER_SKILLS';
export const updateSkills = createAction<skillsSagaTypes.IUpdateUserSkills>(UPDATE_USER_SKILLS);

//deletePublication
export const PROFILE_DELETE_PUBLICATION_SAGA = 'PROFILE_DELETE_PUBLICATION_SAGA';
export const profileDeletePublicationAction = createAction<publicationSagaTypes.IProfilePublicationForm>(
   PROFILE_DELETE_PUBLICATION_SAGA
);

// Update publications
export const UPDATE_PROFILE_PUBLICATION = 'UPDATE_PROFILE_PUBLICATION';
export const updatePublication = createAction<publicationSagaTypes.IUpdatePublication>(UPDATE_PROFILE_PUBLICATION);

//Update Social History
export const USR_PROFILE_UPDATE_SOCIAL_HISTORIES = 'USR_PROFILE_UPDATE_SOCIAL_HISTORIES';
export const updateSocialHistories = createAction<socialHistoryReducerTypes.IUpdateSocialHistoryData>(
   USR_PROFILE_UPDATE_SOCIAL_HISTORIES
);

// Update user profile Actions
export const UPDATE_PROFILE_ACTION = 'UPDATE_PROFILE_ACTION';
export const updateProfileAction = createAction<IEditProfile>(UPDATE_PROFILE_ACTION);

// get countries list
export const USER_GET_COUNTRIES = 'USER_GET_COUNTRIES';
export const userGetCountriesAction = createAction<myProfileSagaTypes.IGetCountriesRequest>(USER_GET_COUNTRIES);

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const deleteAccountAction = createAction(DELETE_ACCOUNT);
