import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { modalMedicationReducerTypes, modalMedicationSagaTypes } from 'types';

const initialState: modalMedicationReducerTypes.IModalUpdateMedicationReducer = {
   isLoading: false,
   error: '',
   message: '',
};

export const modalUpdateMedicationSlice = createSlice({
   name: 'modalUpdateMedication',
   initialState: initialState,
   reducers: {
      modalUpdateMedicationRequest: (
         state,
         action: PayloadAction<modalMedicationSagaTypes.IModalUpdateMedicationSagaPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      modalUpdateMedicationSuccess: (
         state,
         action: PayloadAction<modalMedicationReducerTypes.IModalUpdateMedicationSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      modalUpdateMedicationFailed: (
         state,
         action: PayloadAction<modalMedicationReducerTypes.IModalUpdateMedicationFailedPayload>
      ) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      modalUpdateMedicationReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
