import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { COMMENTS_UPDATE_REACTIONS, eventTrackAction } from 'reduxToolKit/actions';
import { commentsReactionSlice, commonSlice } from 'reduxToolKit/reducers';
import { eventTrackTypes, feedSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { commentsReactionsRequest, commentsReactionsSuccess, commentsReactionsFailed } = commentsReactionSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const commentsReactions = function* (payloadState: PayloadAction<feedSagaTypes.ICommentsUpdateReactions>) {
   yield put(commentsReactionsRequest(payloadState.payload));
   try {
      const peopleMayYouKnow = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ peopleMayYouKnow, data, ...API.commentsUpdateReactions });
      if (response.status === 200) {
         const resObj = {
            message: response?.data?.message,
            data: null,
         };
         yield put(commentsReactionsSuccess(resObj));
         if (payloadState.payload.event) {
            const eventData: eventTrackTypes.IEventTrack = {
               event: {
                  name: payloadState.payload.event.name,
               },
            };
            yield put(eventTrackAction(eventData));
         }
      } else {
         yield put(commentsReactionsFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeCommentsReactions = function* () {
   yield takeEvery(COMMENTS_UPDATE_REACTIONS, commentsReactions);
};
