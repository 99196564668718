import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ASK_A_DOC_GET_PANELS_REQ, ASK_A_DOC_CREATE_REQUEST } from 'reduxToolKit/actions';
import { commonSlice, panelRequestSlice } from 'reduxToolKit/reducers';
import { postShareSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { panelRequestRequest, panelRequestSuccess, panelRequestFailed } = panelRequestSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const panelsReqSaga = function* (payloadState: PayloadAction<postShareSagaTypes.IAskPhyReq>) {
   yield put(panelRequestRequest(payloadState.payload.postData));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload.postData;
      let url;
      if (payloadState.payload.rejectedId) {
         url = API.panelReRequest;
      } else {
         url = API.panelRequest;
      }
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...url });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message || messages.success,
            data: response?.data?.data || null,
         };
         yield put(panelRequestSuccess(resObj));
      } else {
         yield put(panelRequestFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const createAskADocRequestSaga = function* (
   payloadState: PayloadAction<postShareSagaTypes.IAskADocRequestCreationType>
) {
   yield put(panelRequestRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.createAskADocRequest });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message || messages.success,
            data: response?.data?.data || null,
         };
         yield put(panelRequestSuccess(resObj));
      } else {
         yield put(panelRequestFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePanelsReq = function* () {
   yield takeEvery(ASK_A_DOC_GET_PANELS_REQ, panelsReqSaga);
};

export const takeCreateAskADocRequest = function* () {
   yield takeEvery(ASK_A_DOC_CREATE_REQUEST, createAskADocRequestSaga);
};
