import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { APPOINTMENT_UPDATE } from 'reduxToolKit/actions';
import { commonSlice, updateAppointmentSlice } from 'reduxToolKit/reducers';
import { updateAppointmentSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   updateAppointmentView,

   updateAppointmentViewSuccess,
   updateAppointmentViewFailed,
} = updateAppointmentSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;
const updateAppointment = function* (updatePayload: updateAppointmentSagaTypes.IUpdateAppointments) {
   yield put(updateAppointmentView(updatePayload.payload));
   const params = {
      status: updatePayload.payload.status,
      notes: updatePayload.payload.notes ? updatePayload.payload.notes : null,
      minutes: updatePayload.payload.minutes ? updatePayload.payload.minutes : null,
      reject_reason: updatePayload.payload.reject_reason ? updatePayload.payload.reject_reason : null,
   };
   try {
      if (updatePayload.payload.appointmentId) {
         const apiPath = {
            apiPath: `${API.appointmentUpdate.apiPath}${'/'}${updatePayload.payload.appointmentId}`,
            action: API.appointmentUpdate.action,
         };
         const response: AxiosResponse = yield apiCall({ params, ...apiPath });
         if (response.status === 200) {
            yield put(updateAppointmentViewSuccess(response.data));
         } else {
            yield put(updateAppointmentViewFailed(response.data));
         }
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeUpdateAppointmentRequest = function* () {
   yield takeEvery(APPOINTMENT_UPDATE, updateAppointment);
};
