import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   GET_CO_MORBIDITIES,
   GET_DEVICE,
   GET_LINE_THERAPY,
   GET_MEDITATION,
   GET_PATIENT_ACTIVE_RPM_DEVICE,
   GET_PATIENT_MEDICATION,
   GET_PATIENT_MORBIDITIES,
} from 'reduxToolKit/actions';
import {
   commonSlice,
   coMorbiditiesSlice,
   getDeviceSlice,
   lineOfTherapySlice,
   meditationSlice,
   morbiditiesSlice,
   patientMedicationsSlice,
   patientMeditationRpmDeviceSlice,
} from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   patientMedicationLoading,
   patientMedicationRequest,
   patientMedicationSuccess,
   patientMedicationFailed,
} = patientMedicationsSlice.actions;
const { devicesListLoading, devicesListRequest, devicesListSuccess, devicesListFailed } = getDeviceSlice.actions;
const {
   morbiditiesListLoading,
   morbiditiesListRequest,
   morbiditiesListSuccess,
   morbiditiesListFailed,
} = morbiditiesSlice.actions;
const {
   lineOfTherapyLoading,
   lineOfTherapyRequest,
   lineOfTherapySuccess,
   lineOfTherapyFailed,
} = lineOfTherapySlice.actions;
const {
   coMorbiditiesLoading,
   coMorbiditiesRequest,
   coMorbiditiesSuccess,
   coMorbiditiesFailed,
} = coMorbiditiesSlice.actions;
const {
   meditationRpmDeviceLoading,
   meditationRpmDeviceRequest,
   meditationRpmDeviceSuccess,
   meditationRpmDeviceFailed,
} = patientMeditationRpmDeviceSlice.actions;
const {
   meditationListLoading,
   meditationListRequest,
   meditationListSuccess,
   meditationListFailed,
} = meditationSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getPatientMedicationSaga = function* (prescriptionTypePayload: any) {
   yield put(patientMedicationLoading());
   yield put(patientMedicationRequest(prescriptionTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = prescriptionTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getPatientMedications });
      if (response.status === 200) {
         const responseData = response.data.data;
         yield put(patientMedicationSuccess(responseData));
      } else {
         yield put(patientMedicationFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
const getLineOfTherapySaga = function* (prescriptionTypePayload: any) {
   yield put(lineOfTherapyLoading());
   yield put(lineOfTherapyRequest(prescriptionTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = prescriptionTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getLineOfTherapy });
      if (response.status === 200) {
         const responseData = response?.data?.data?.list;
         yield put(lineOfTherapySuccess(responseData));
      } else {
         yield put(lineOfTherapyFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
const getCoMorbiditiesSaga = function* (prescriptionTypePayload: any) {
   yield put(coMorbiditiesLoading());
   yield put(coMorbiditiesRequest(prescriptionTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = prescriptionTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getCoMorbidities });
      if (response.status === 200) {
         const responseData = response?.data?.data?.list;
         yield put(coMorbiditiesSuccess(responseData));
      } else {
         yield put(coMorbiditiesFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
const getActiveRpmDeviceSaga = function* (prescriptionTypePayload: any) {
   yield put(meditationRpmDeviceLoading());
   yield put(meditationRpmDeviceRequest(prescriptionTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = prescriptionTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.patientActiveRpmDevices });
      if (response.status === 200) {
         const responseData = response?.data?.data?.list;
         yield put(meditationRpmDeviceSuccess(responseData));
      } else {
         yield put(meditationRpmDeviceFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
const getMeditationSaga = function* (prescriptionTypePayload: any) {
   yield put(meditationListLoading());
   yield put(meditationListRequest(prescriptionTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = prescriptionTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getMedications });
      if (response.status === 200) {
         const responseData = response?.data?.data?.list;
         yield put(meditationListSuccess(responseData));
      } else {
         yield put(meditationListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
const getMorbiditiesSaga = function* (prescriptionTypePayload: any) {
   yield put(morbiditiesListLoading());
   yield put(morbiditiesListRequest(prescriptionTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = prescriptionTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getPatientMorbidities });
      if (response.status === 200) {
         const responseData = response?.data?.data;
         yield put(morbiditiesListSuccess(responseData));
      } else {
         yield put(morbiditiesListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
const getDeviceSaga = function* (prescriptionTypePayload: any) {
   yield put(devicesListLoading());
   yield put(devicesListRequest(prescriptionTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = prescriptionTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getDevices });
      if (response.status === 200) {
         const responseData = response?.data?.data?.list;
         yield put(devicesListSuccess(responseData));
      } else {
         yield put(devicesListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takePatientMedicationRequest = function* () {
   yield takeEvery(GET_PATIENT_MEDICATION, getPatientMedicationSaga);
   yield takeEvery(GET_LINE_THERAPY, getLineOfTherapySaga);
   yield takeEvery(GET_CO_MORBIDITIES, getCoMorbiditiesSaga);
   yield takeEvery(GET_PATIENT_ACTIVE_RPM_DEVICE, getActiveRpmDeviceSaga);
   yield takeEvery(GET_MEDITATION, getMeditationSaga);
   yield takeEvery(GET_PATIENT_MORBIDITIES, getMorbiditiesSaga);
   yield takeEvery(GET_DEVICE, getDeviceSaga);
};
