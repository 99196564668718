import { createSlice } from '@reduxjs/toolkit';
import { feedReducerTypes } from 'types';

const initialState: feedReducerTypes.IHideThisPostReducer = {
   isLoading: false,
   hidePost: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   ctFilledPost: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
};

export const hidePostSlice = createSlice({
   name: 'hidePost',
   initialState: initialState,
   reducers: {
      hidePostRequest: (state, action: any) => {
         return {
            ...state,
            hidePost: {
               ...state.hidePost,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      hidePostSuccess: (state, action: any) => {
         return {
            ...state,
            hidePost: {
               ...state.hidePost,
               message: action.payload?.message,
               data: action.payload?.data,
               error: '',
               isLoading: false,
            },
         };
      },
      hidePostFailure: (state, action: any) => {
         return {
            ...state,
            hidePost: {
               ...state.hidePost,
               error: action.payload?.error,
               message: '',
               data: null,
               isLoading: false,
            },
         };
      },
      hidePostReset: (state) => {
         return {
            ...state,
            hidePost: {
               ...state.hidePost,
               isLoading: false,
               message: '',
               data: null,
               error: '',
            },
         };
      },
      ctFilledPostRequest: (state, action: any) => {
         return {
            ...state,
            ctFilledPost: {
               ...state.ctFilledPost,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      ctFilledPostSuccess: (state, action: any) => {
         return {
            ...state,
            ctFilledPost: {
               ...state.ctFilledPost,
               message: action.payload?.message,
               data: action.payload?.data,
               error: '',
               isLoading: false,
            },
         };
      },
      ctFilledPostFailure: (state, action: any) => {
         return {
            ...state,
            ctFilledPost: {
               ...state.ctFilledPost,
               error: action.payload?.error,
               message: '',
               data: null,
               isLoading: false,
            },
         };
      },
      ctFilledPostReset: (state) => {
         return {
            ...state,
            ctFilledPost: {
               ...state.ctFilledPost,
               isLoading: false,
               message: '',
               data: null,
               error: '',
            },
         };
      },
   },
});
