import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   GET_ONE_ASK_DOC_FEED_REQUEST,
   REJECT_ASKADOC_REQUEST_CASE_DETAILS,
   PICK_ASK_A_DOC_REQUEST,
   ASK_A_DOC_END_CONVERSATION,
   ASK_A_DOC_CONVERSATION_ADD_QUESTION,
   ASK_A_DOC_CHANGE_CLINIC,
   ASK_A_DOC_CASE_HISTORY,
} from 'reduxToolKit/actions';
import { commonSlice, getAskADocCaseDetailsSlice } from 'reduxToolKit/reducers';
import { caseDetailsReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getAskADocCaseDetailsRequest,
   getAskADocCaseDetailsSuccess,
   getAskADocCaseDetailsFailed,
   rejectAskADocCaseDetailsRequest,
   rejectAskADocCaseDetailsSuccess,
   rejectAskADocCaseDetailsFailed,
   askADocEndConversationRequest,
   askADocEndConversationSuccess,
   askADocEndConversationFailed,
   askADocConversationAddQuestionRequest,
   askADocConversationAddQuestionSuccess,
   askADocConversationAddQuestionFailed,

   askADocRequestPickRequest,
   askADocRequestPickSuccess,
   askADocRequestPickFailed,

   askADocChangeClinicRequest,
   askADocChangeClinicSuccess,
   askADocChangeClinicFailed,

   askADocCaseHistoryRequest,
   askADocCaseHistorySuccess,
   askADocCaseHistoryFailed,
} = getAskADocCaseDetailsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getAskADocCaseDetailsSaga = function* (payloadState: PayloadAction<caseDetailsReducerTypes.IGetOneAskADocId>) {
   yield put(getAskADocCaseDetailsRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.askADocCaseDetails });
      if (response.status === 200) {
         const onCaseDetailsList = {
            data: response.data?.data,
            message: response.data?.message || messages.success,
            requestData: params,
         };
         yield put(getAskADocCaseDetailsSuccess(onCaseDetailsList));
      } else {
         yield put(getAskADocCaseDetailsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const rejectADocAddAnswerSaga = function* (PayloadState: PayloadAction<caseDetailsReducerTypes.IGetOneAskADocId>) {
   yield put(rejectAskADocCaseDetailsRequest(PayloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = PayloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.rejectAskADocRequestCaseDetails });
      if (response.status === 200) {
         const responseData = {
            data: response.data?.data,
            message: response.data?.message || messages.success,
         };
         yield put(rejectAskADocCaseDetailsSuccess(responseData));
      } else {
         yield put(rejectAskADocCaseDetailsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getAskADocEndConversationSaga = function* (
   payloadState: PayloadAction<caseDetailsReducerTypes.IAskADocEndConversation>
) {
   yield put(askADocEndConversationRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const data = {};
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, data, ...API.askADocEndConversation });
      if (response.status === 200) {
         const requestData = response.data;
         yield put(askADocEndConversationSuccess(requestData));
      } else {
         yield put(askADocEndConversationFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getAskADocConversationAddQuesSaga = function* (
   payloadState: PayloadAction<caseDetailsReducerTypes.IAskADocConversationAddQuestion>
) {
   yield put(askADocConversationAddQuestionRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const payloadData = payloadState.payload;
      const params = {
         ask_a_doc_id: payloadData.ask_a_doc_id,
      };
      const data = { patient_questions: payloadData.patient_questions };
      const response: AxiosResponse = yield apiCall({
         checkUser,
         goUrl,
         params,
         data,
         ...API.askADocConversationAddQuestion,
      });
      if (response.status === 200) {
         const requestData = response.data;
         yield put(askADocConversationAddQuestionSuccess(requestData));
      } else {
         yield put(askADocConversationAddQuestionFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const pickAskADocRequestSaga = function* (PayloadState: PayloadAction<caseDetailsReducerTypes.IGetOneAskADocId>) {
   yield put(askADocRequestPickRequest(PayloadState.payload));
   try {
      const apiPath = `${API.pickAskADocRequest.apiPath}${PayloadState.payload?.id}`;
      const action = API.pickAskADocRequest.action;
      const response: AxiosResponse = yield apiCall({ ...{ apiPath, action } });
      if (response.status === 200) {
         const responseData = {
            data: response.data?.data,
            message: response.data?.message || messages.success,
         };
         yield put(askADocRequestPickSuccess(responseData));
      } else {
         yield put(askADocRequestPickFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getAskADocChangeClinicSaga = function* (
   payloadState: PayloadAction<caseDetailsReducerTypes.IAskADocChangeClinicSagaRequest>
) {
   yield put(askADocChangeClinicRequest(payloadState.payload));
   try {
      const checkUser = false;
      const goUrl = false;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         goUrl,
         data,
         ...API.askADocChangeClinic,
      });
      if (response.status === 200) {
         const requestData = response.data;
         yield put(askADocChangeClinicSuccess(requestData));
      } else {
         yield put(askADocChangeClinicFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getAskADocCaseHistorySaga = function* (
   payloadState: PayloadAction<caseDetailsReducerTypes.IAskADocCaseHistorySagaRequest>
) {
   yield put(askADocCaseHistoryRequest(payloadState.payload));
   try {
      const checkUser = false;
      const goUrl = false;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         goUrl,
         params,
         ...API.askADocCaseHistory,
      });
      if (response.status === 200) {
         const responseData = response.data.data;
         const caseHistoryList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response?.data?.message || messages.success,
         };
         yield put(askADocCaseHistorySuccess(caseHistoryList));
      } else {
         yield put(askADocCaseHistoryFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAskADocCaseDetailsRequest = function* () {
   yield takeEvery(REJECT_ASKADOC_REQUEST_CASE_DETAILS, rejectADocAddAnswerSaga);
   yield takeEvery(GET_ONE_ASK_DOC_FEED_REQUEST, getAskADocCaseDetailsSaga);
   yield takeEvery(ASK_A_DOC_END_CONVERSATION, getAskADocEndConversationSaga);
   yield takeEvery(ASK_A_DOC_CONVERSATION_ADD_QUESTION, getAskADocConversationAddQuesSaga);
   yield takeEvery(PICK_ASK_A_DOC_REQUEST, pickAskADocRequestSaga);
   yield takeEvery(ASK_A_DOC_CHANGE_CLINIC, getAskADocChangeClinicSaga);
   yield takeEvery(ASK_A_DOC_CASE_HISTORY, getAskADocCaseHistorySaga);
};
