import { createSlice } from '@reduxjs/toolkit';
import { updateAppointmentReducerTypes, updateAppointmentSagaTypes } from 'types';

const initialState: updateAppointmentReducerTypes.IAppointmentReducer = {
   updateAppointment: null,
   isLoading: false,
   error: '',
   message: '',
};
const updateAppointmentSlice = createSlice({
   name: 'updateAppointment',
   initialState: initialState,
   reducers: {
      updateAppointmentView: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      updateAppointmentViewSuccess: (state, action: updateAppointmentSagaTypes.IUpdateAppointmentSuccess) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      updateAppointmentViewFailed: (state, action) => {
         return {
            ...state,
            updateAppointment: null,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      updateAppointmentResetErr: (state, action) => {
         return {
            ...state,
            message: '',
            error: '',
         };
      },
   },
});
export { updateAppointmentSlice };
