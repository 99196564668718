import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { oncoMeditationReducerTypes, vdocipherReducerTypes, vdocipherSagaTypes } from 'types';
import { IUpdateAudioPlayerInvoke } from 'types/oncoMeditation/oncoMeditationPageTypes';

const initialState: vdocipherReducerTypes.IPostVideoCipherReducerPayload = {
   vdocipherPost: {
      data: null,
      message: '',
      error: '',
      isLoading: false,
   },
   vdocipherQuestions: {
      data: null,
      message: '',
      error: '',
      isLoading: false,
   },
   eduLibAudioPlayer: {
      selectedIndex: -1,
      oncoItem: null,
      isAudioPlaying: false,
      isAudioPaused: false,
      onAudioPlayingTime: 0,
      isAudioPlayerInvoke: false,
      audioPlayingUrl: null,
   },
};
export const vdocipherSlice = createSlice({
   name: 'vdocipherSlice',
   initialState: initialState,
   reducers: {
      //vdocipher otp generation
      vdocipherRequest: (state, action: PayloadAction<vdocipherSagaTypes.IPostVideoCipherSagaPayload>) => {
         return {
            ...state,
            vdocipherPost: {
               ...state.vdocipherPost,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      vdocipherSuccess: (state, action: PayloadAction<vdocipherReducerTypes.IPostVideoCipherSuccessPayload>) => {
         return {
            ...state,
            vdocipherPost: {
               ...state.vdocipherPost,
               isLoading: false,
               ...action.payload,
            },
         };
      },
      vdocipherFailed: (state, action: PayloadAction<vdocipherReducerTypes.IPostVideoCipherFailurePayload>) => {
         return {
            ...state,
            vdocipherPost: {
               ...state.vdocipherPost,
               isLoading: false,
               error: action.payload.error,
            },
         };
      },
      vdocipherReset: (state) => {
         return {
            ...state,
            vdocipherPost: {
               ...initialState.vdocipherPost,
            },
         };
      },
      //get vdocipher questions
      vdocipherQuestionsRequest: (
         state,
         action: PayloadAction<vdocipherSagaTypes.IVdoCipherGetQuestionSagaPayload>
      ) => {
         return {
            ...state,
            vdocipherQuestions: {
               ...state.vdocipherQuestions,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      vdocipherQuestionsSuccess: (
         state,
         action: PayloadAction<vdocipherReducerTypes.IGetVideoCipherQuestionsSuccessPayload>
      ) => {
         return {
            ...state,
            vdocipherQuestions: {
               ...state.vdocipherQuestions,
               isLoading: false,
               ...action.payload,
            },
         };
      },
      vdocipherQuestionsFailed: (
         state,
         action: PayloadAction<vdocipherReducerTypes.IPostVideoCipherFailurePayload>
      ) => {
         return {
            ...state,
            vdocipherQuestions: {
               ...state.vdocipherQuestions,
               isLoading: false,
               error: action.payload.error,
            },
         };
      },
      vdocipherQuestionsReset: (state) => {
         return {
            ...state,
            vdocipherQuestions: {
               ...initialState.vdocipherQuestions,
            },
         };
      },
      eduLibAudioPlayerStates: (
         state,
         action: PayloadAction<oncoMeditationReducerTypes.IOncoMeditationAudioPlayer>
      ) => {
         return {
            ...state,
            eduLibAudioPlayer: {
               ...state.eduLibAudioPlayer,
               selectedIndex:
                  action.payload?.selectedIndex || action.payload?.selectedIndex === 0
                     ? action.payload?.selectedIndex
                     : state.eduLibAudioPlayer.selectedIndex,
               oncoItem: action.payload?.oncoItem ? action.payload?.oncoItem : state.eduLibAudioPlayer.oncoItem,
               isAudioPlaying:
                  action.payload?.isAudioPlaying === undefined
                     ? state.eduLibAudioPlayer.isAudioPlaying
                     : action.payload?.isAudioPlaying,
               isAudioPaused:
                  action.payload?.isAudioPaused === undefined
                     ? state.eduLibAudioPlayer.isAudioPaused
                     : action.payload?.isAudioPaused,
               isAudioPlayerInvoke:
                  action.payload?.isAudioPlayerInvoke === undefined
                     ? state.eduLibAudioPlayer.isAudioPlayerInvoke
                     : action.payload?.isAudioPlayerInvoke,
               onAudioPlayingTime: action.payload?.onAudioPlayingTime || state.eduLibAudioPlayer.onAudioPlayingTime,
               audioPlayingUrl: action.payload?.audioPlayingUrl
                  ? action.payload?.audioPlayingUrl
                  : state.eduLibAudioPlayer.audioPlayingUrl,
            },
         };
      },

      eduLibAudioPlayerInvoke: (state, action: PayloadAction<IUpdateAudioPlayerInvoke>) => {
         return {
            ...state,
            eduLibAudioPlayer: {
               ...state.eduLibAudioPlayer,
               isAudioPlayerInvoke: action.payload?.isAudioPlayerInvoke,
               isAudioPaused:
                  action.payload?.isAudioPaused === undefined
                     ? state.eduLibAudioPlayer.isAudioPaused
                     : action.payload?.isAudioPaused,
            },
         };
      },

      eduLibAudioPlayerStateReset: (state) => {
         return {
            ...state,
            eduLibAudioPlayer: initialState.eduLibAudioPlayer,
         };
      },
   },
});
