import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { peopleNearMeReducerTypes, postShareSagaTypes } from 'types';

const initialState: peopleNearMeReducerTypes.ICancerTypeListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
};

export const myServiceListSlice = createSlice({
   name: 'myServiceList',
   initialState: initialState,
   reducers: {
      myServiceListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      myServiceListViewRequest: (state, action: PayloadAction<postShareSagaTypes.IWallet>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: state.list,
         };
      },
      myServiceListViewSuccess: (state, action: PayloadAction<peopleNearMeReducerTypes.ICancerTypeListReducer>) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            isLoading: false,
            message: 'success',
         };
      },
      myServiceListFailed: (state, action: PayloadAction<peopleNearMeReducerTypes.ICancerTypeListReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      myServiceListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
