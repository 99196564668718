import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { PILL_REMAINDER_DELETE_SAGA, PILL_REMAINDER_NON_CANCER_DELETE_SAGA } from 'reduxToolKit/actions';
import { commonSlice, pillRemainderDeleteSlice, pillRemainderNonCancerDeleteSlice } from 'reduxToolKit/reducers';
import { pillRemainderSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   pillRemainderDeleteRemainderRequest,
   pillRemainderDeleteRemainderSuccess,
   pillRemainderDeleteRemainderFailed,
} = pillRemainderDeleteSlice.actions;

const {
   pillRemainderNonCancerDeleteRequest,
   pillRemainderNonCancerDeleteSuccess,
   pillRemainderNonCancerDeleteFailed,
} = pillRemainderNonCancerDeleteSlice.actions;

const { exceptionHandlerRequest } = commonSlice.actions;

const pillRemainderDelete = function* (
   pillRemainderDelete: PayloadAction<pillRemainderSagaTypes.IPillRemainderDeleteParams>
) {
   yield put(pillRemainderDeleteRemainderRequest(pillRemainderDelete.payload));
   try {
      const payload = pillRemainderDelete.payload;
      const url = {
         apiPath: `${API.pillRemainderDelete.apiPath}${payload.id}`,
         action: API.pillRemainderDelete.action,
      };
      const response: AxiosResponse = yield apiCall({ ...url });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(pillRemainderDeleteRemainderSuccess(resObj));
      } else {
         yield put(pillRemainderDeleteRemainderFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const pillRemainderNonCancerDelete = function* (
   pillRemainderDelete: PayloadAction<pillRemainderSagaTypes.IPillRemainderDeleteParams>
) {
   yield put(pillRemainderNonCancerDeleteRequest(pillRemainderDelete.payload));
   try {
      const data = pillRemainderDelete.payload;
      const response: AxiosResponse = yield apiCall({ checkUser: false, data, ...API.pillRemainderNonCancerDelete });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(pillRemainderNonCancerDeleteSuccess(resObj));
      } else {
         yield put(pillRemainderNonCancerDeleteFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePillRemainderDeleteRequest = function* () {
   yield takeEvery(PILL_REMAINDER_DELETE_SAGA, pillRemainderDelete);
   yield takeEvery(PILL_REMAINDER_NON_CANCER_DELETE_SAGA, pillRemainderNonCancerDelete);
};
