import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_PHYSICIAN_STATE } from 'reduxToolKit/actions';
import { commonSlice, getPhysicianStateSlice } from 'reduxToolKit/reducers';
import { askADocPatientReducerTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getPhysicianStateRequest, getPhysicianStateSuccess, getPhysicianStateFailed } = getPhysicianStateSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getPhysicianStateSaga = function* (
   physicianStatePayload: PayloadAction<askADocPatientReducerTypes.IAskADocPhysicianStateProps>
) {
   yield put(getPhysicianStateRequest(physicianStatePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = physicianStatePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getPhysicianState });
      if (response.status === 200) {
         const responseData = response.data.data;
         yield put(getPhysicianStateSuccess(responseData));
      } else {
         yield put(getPhysicianStateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGetPhysicianStateSaga = function* () {
   yield takeEvery(GET_PHYSICIAN_STATE, getPhysicianStateSaga);
};
