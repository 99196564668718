import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { eventTrackAction, FEED_REACTIONS_ADD_SAGA } from 'reduxToolKit/actions';
import { commonSlice, feedReactionsAddSlice } from 'reduxToolKit/reducers';
import { eventTrackTypes, feedSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { feedReactionsAddRequest, feedReactionsAddSuccess, feedReactionsAddFailed } = feedReactionsAddSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const feedReactionsAdd = function* (oncDriveFolder: PayloadAction<feedSagaTypes.IFeedAddReactionsActionProps>) {
   yield put(feedReactionsAddRequest(oncDriveFolder.payload));
   try {
      const apiPath = {
         apiPath: `${API.feedReactionsAdd.apiPath}${oncDriveFolder.payload.id}${`/like`}`,
         action: API.feedReactionsAdd.action,
      };
      const data = {
         reaction_type: oncDriveFolder.payload.reaction_type,
      };
      const checkUser = true;

      const response: AxiosResponse = yield apiCall({ checkUser, data, ...apiPath });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: null,
         };
         yield put(feedReactionsAddSuccess(resObj));
         if (oncDriveFolder.payload.event) {
            const eventData: eventTrackTypes.IEventTrack = {
               event: {
                  name: oncDriveFolder.payload.event.name,
               },
            };
            yield put(eventTrackAction(eventData));
         }
      } else {
         yield put(feedReactionsAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeFeedReactionsAddRequest = function* () {
   yield takeEvery(FEED_REACTIONS_ADD_SAGA, feedReactionsAdd);
};
