import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { feedPageTypes, feedReducerTypes } from 'types';

const initialState: feedReducerTypes.IHashTagListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
};

const getHashTagDataArray = (hashTagsList: any[]) => {
   const hashTagData: feedPageTypes.ICommentHashTagObj[] = [];

   if (hashTagsList?.length > 0) {
      hashTagsList.forEach((hashTag: any) => {
         const hashTagObject: feedPageTypes.ICommentHashTagObj = {
            id: hashTag.id,
            display: hashTag.name || '',
            type: 'hashTag',
            postCount: hashTag.post_count || 0,
         };
         hashTagData.push(hashTagObject);
      });
   }
   return hashTagData;
};

export const hashTagListSlice = createSlice({
   name: 'hashTagList',
   initialState: initialState,
   reducers: {
      hashTagListRequest: (state) => {
         return {
            ...state,
            isLoading: true,
            list: [],
         };
      },
      hashTagListSuccess: (state, action: PayloadAction<feedReducerTypes.IHashTagListReducer>) => {
         const { payload } = action;
         const list = payload?.list || [];
         const userMentionData: feedPageTypes.ICommentHashTagObj[] = getHashTagDataArray(list);
         return {
            ...state,
            list: userMentionData,
            message: 'success',
            isLoading: false,
         };
      },
      hashTagListFailed: (state, action: PayloadAction<feedReducerTypes.IHashTagListReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: 'failed',
            error: action.payload.error,
         };
      },
      hashTagListResetMessage: (state) => {
         return {
            ...state,
            message: '',
         };
      },
      hashTagListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
