import React from 'react';

import { Box, Button, Dialog, Typography } from '@material-ui/core';
import videoPlay from 'assets/alert/videoplay.png';
import NurseTutorialThumb from 'assets/image/nurse_tutorial_thumb.jpg';
import { DialogTitle, DialogContent } from 'component/shared/MuiWithStyle';
import Constant from 'config/Constant';
import UrlConfig from 'config/UrlConfig';
import { videoTutorialModalTypes } from 'types';
import { ONCO_DRIVE_EVENTS } from 'utils/constants/eventTrack/oncoDriveEventConstants';
import { PILL_REMAINDER_EVENTS } from 'utils/constants/eventTrack/pillRemainderEventConstants';
import { setEventTrack } from 'utils/helpers/eventTrack';

const VideoTutorialModal = ({
   handleClose,
   open,
   isFromAskADoc,
   isFromAskADocPhy,
   isFromOncoklinic,
   isFromNurse,
   isFromOncDrive,
   isFromSecondOpinionPhy,
   isFromSecondOpinionPat,
   isFromPillReminder,
   openImageslider,
}: videoTutorialModalTypes.IVideoTutorialModalProps) => {
   return (
      <Box component="div">
         <Dialog
            onClose={() => {
               if (isFromOncDrive) {
                  setEventTrack(ONCO_DRIVE_EVENTS.ONCO_DRIVE_DEMO_WATCH_LATER);
               } else if (isFromPillReminder) {
                  setEventTrack(PILL_REMAINDER_EVENTS.PILL_REMINDER_WATCH_LATER);
               }
               handleClose();
            }}
            aria-labelledby="customized-dialog-title"
            className="video__tutorial__modal"
            open={open}
         >
            <DialogTitle
               id="customized-dialog-title"
               onClose={() => {
                  if (isFromOncDrive) {
                     setEventTrack(ONCO_DRIVE_EVENTS.ONCO_DRIVE_DEMO_WATCH_LATER);
                  } else if (isFromPillReminder) {
                     setEventTrack(PILL_REMAINDER_EVENTS.PILL_REMINDER_WATCH_LATER);
                  }
                  handleClose();
               }}
            >
               <Typography></Typography>
            </DialogTitle>
            <DialogContent>
               <Box>
                  <img
                     src={
                        isFromOncDrive
                           ? `${UrlConfig.imageBaseUrl}${Constant.ONC_DRIVE_PATIENT_VIEW_TUTORIAL_VIDEO_THUMBNAIL}`
                           : isFromAskADoc
                           ? `${UrlConfig.imageBaseUrl}${Constant.ASK_A_DOC_VIEW_TUTORIAL_VIDEO_THUMBNAIL}`
                           : isFromAskADocPhy
                           ? `${UrlConfig.imageBaseUrl}${Constant.ASK_A_DOC_PHYSICIAN_VIEW_TUTORIAL_VIDEO_THUMBNAIL}`
                           : isFromOncoklinic
                           ? `${UrlConfig.imageBaseUrl}${Constant.VIEW_TUTORIAL_VIDEO_THUMBNAIL}`
                           : isFromNurse
                           ? NurseTutorialThumb
                           : isFromSecondOpinionPhy
                           ? `${UrlConfig.imageBaseUrl}${Constant.SECOND_OPINION_VIEW_TUTORIAL_VIDEO_THUMBNAIL}`
                           : isFromSecondOpinionPat
                           ? `${UrlConfig.imageBaseUrl}${Constant.SECOND_OPINION_PATIENT_VIEW_TUTORIAL_VIDEO_THUMBNAIL}`
                           : isFromPillReminder
                           ? `${UrlConfig.imageBaseUrl}${Constant.PILL_REMINDER_PATIENT_VIEW_TUTORIAL_VIDEO_THUMBNAIL}`
                           : undefined
                     }
                     alt={'tutorialVideoImage'}
                     className="video__image"
                  />
               </Box>
               <Box className="plus-number-chat">
                  <img
                     onClick={() => {
                        openImageslider(1);
                        if (isFromOncDrive) {
                           setEventTrack(ONCO_DRIVE_EVENTS.ONCO_DRIVE_DEMO_PLAY);
                        } else if (isFromPillReminder) {
                           setEventTrack(PILL_REMAINDER_EVENTS.PILL_REMINDER_DEMO_PLAY);
                        }
                     }}
                     src={videoPlay}
                     alt={'tutorialVideoPlayIcon'}
                     style={{ textAlign: 'center' }}
                  />
               </Box>
               <Box pl={6} ml={3} width="75%" display="flex" justifyContent={'center'}>
                  <Typography align={'center'} style={{ color: 'black' }}>
                     {isFromOncDrive
                        ? Constant.ONC_DRIVE_VIEW_TUTORIAL_VIDEO_TEXT
                        : isFromAskADoc
                        ? Constant.ASK_A_DOC_VIEW_TUTORIAL_VIDEO_TEXT
                        : isFromAskADocPhy
                        ? Constant.ASK_A_DOC_PHYSICIAN_VIEW_TUTORIAL_VIDEO_TEXT
                        : isFromOncoklinic
                        ? Constant.ONCOKLINIC_VIEW_TUTORIAL_VIDEO_TEXT
                        : isFromNurse
                        ? Constant.VIEW_TUTORIAL_VIDEO_NURSE_TEXT
                        : isFromSecondOpinionPhy
                        ? Constant.SECOND_OPINION_PHYSICIAN_VIEW_TUTORIAL_VIDEO_TEXT
                        : isFromSecondOpinionPat
                        ? Constant.SECOND_OPINION_VIEW_TUTORIAL_VIDEO_TEXT
                        : isFromPillReminder
                        ? Constant.PILL_REMINDER_VIEW_TUTORIAL_VIDEO_TEXT
                        : ''}
                  </Typography>
               </Box>
            </DialogContent>
            <Box mb={4} width="75%" mx="auto">
               <Button
                  onClick={() => {
                     if (isFromOncDrive) {
                        setEventTrack(ONCO_DRIVE_EVENTS.ONCO_DRIVE_DEMO_WATCH_LATER);
                     } else if (isFromPillReminder) {
                        setEventTrack(PILL_REMAINDER_EVENTS.PILL_REMINDER_WATCH_LATER);
                     }
                     handleClose();
                  }}
                  variant="contained"
                  className="video__tutorial__modal--btn"
                  fullWidth
               >
                  WATCH LATER
               </Button>
            </Box>
         </Dialog>
      </Box>
   );
};

export default VideoTutorialModal;
