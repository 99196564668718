import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MEDICAL_ENQUIRES_UPDATE_SAGA } from 'reduxToolKit/actions';
import { commonSlice, medicalEnqUpdateSlice } from 'reduxToolKit/reducers';
import { medicalEnqSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { medicalEnqUpdateView, medicalEnqUpdateViewSuccess, medicalEnqUpdateViewFailed } = medicalEnqUpdateSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const medicalEnqUpdate = function* (medicalEnqUpdate: medicalEnqSagaTypes.IMedicalEnqAddAnsPayload) {
   yield put(medicalEnqUpdateView(medicalEnqUpdate.payload));
   try {
      const payload = medicalEnqUpdate.payload;
      let data: any = {
         clinic_id: payload.clinic_id,
         answer: payload.answer,
         time_spent: payload.time_spent,
         answer_medias: payload.answer_medias,
      };
      if (payload.is_session_end) {
         data = {
            clinic_id: payload.clinic_id,
            is_session_end: payload.is_session_end,
         };
      }
      const url = {
         apiPath: `${API.medicalEnqUpdate.apiPath}${payload.id}`,
         action: API.medicalEnqUpdate.action,
      };
      const response: AxiosResponse = yield apiCall({ data, ...url });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(medicalEnqUpdateViewSuccess(resObj));
      } else {
         yield put(medicalEnqUpdateViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeMedicalEnqUpdateRequest = function* () {
   yield takeEvery(MEDICAL_ENQUIRES_UPDATE_SAGA, medicalEnqUpdate);
};
