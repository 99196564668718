import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userReducerTypes, userSagaTypes } from 'types';

const initialState: userReducerTypes.IOncKlinicUpCommingMeeting = {
   upCommingMeeting: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
};

export const oncKlinicMeetingSlice = createSlice({
   name: 'oncKlinicMeeting',
   initialState: initialState,
   reducers: {
      upCommingMeetingRequest: (state, action: PayloadAction<userSagaTypes.IUpcommingMeeting>) => {
         return {
            ...state,
            upCommingMeeting: {
               ...state.upCommingMeeting,
               isLoading: false,
            },
         };
      },
      upCommingMeetingSuccess: (
         state,
         action: PayloadAction<userReducerTypes.IOncKlinicUpCommingMeetingSuccessPayload>
      ) => {
         return {
            ...state,
            upCommingMeeting: {
               ...state.upCommingMeeting,
               ...action.payload,
               isLoading: false,
               error: '',
            },
         };
      },
      upCommingMeetingFailed: (state, action: PayloadAction<userReducerTypes.IOncKlinicUpCommingMeetingFailure>) => {
         return {
            ...state,
            upCommingMeeting: {
               ...state.upCommingMeeting,
               error: action.payload?.error,
               isLoading: false,
               message: '',
               data: null,
            },
         };
      },
      upCommingMeetingReset: (state) => {
         return {
            ...state,
            upCommingMeeting: {
               ...initialState.upCommingMeeting,
            },
         };
      },
   },
});
