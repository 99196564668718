import { createAction } from '@reduxjs/toolkit';
import { physicianDetailsTypes } from 'types';

export const PROFILE_GET_PHYSICIAN_SAGA = 'PROFILE_GET_PHYSICIAN_SAGA';
export const profileGetPhysiciansAction = createAction<physicianDetailsTypes.IGetPhysicianForm>(
   PROFILE_GET_PHYSICIAN_SAGA
);

export const PROFILE_UPDATE_PHYSICIAN_SAGA = 'PROFILE_UPDATE_PHYSICIAN_SAGA';
export const profileUpdatePhysicianAction = createAction<physicianDetailsTypes.IUpdatePhysicianForm>(
   PROFILE_UPDATE_PHYSICIAN_SAGA
);

export const PROFILE_DELETE_PHYSICIAN_SAGA = 'PROFILE_DELETE_PHYSICIAN_SAGA';
export const profileDeletePhysicianAction = createAction<physicianDetailsTypes.IDeletePhysicianForm>(
   PROFILE_DELETE_PHYSICIAN_SAGA
);
