import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { feedPageTypes, feedReducerTypes } from 'types';

const initialState: feedReducerTypes.IFeedReactionsAddReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const feedReactionsAddSlice = createSlice({
   name: 'feedReactionsAddSlice',
   initialState: initialState,
   reducers: {
      feedReactionsAddRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      feedReactionsAddSuccess: (state, action: PayloadAction<feedPageTypes.IFeedReactionsAddResponse>) => {
         return {
            ...state,
            isLoading: false,
            ...action.payload,
         };
      },
      feedReactionsAddFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      feedReactionsAddReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
export { feedReactionsAddSlice };
