import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { FEED_MEDITATION } from 'reduxToolKit/actions';
import { commonSlice, feedMeditationSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { feedMeditationRequest, feedMeditationSuccess, feedMeditationFailed } = feedMeditationSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const feedMeditation = function* (feedMeditation: PayloadAction<feedSagaTypes.IFeedMeditationParams>) {
   yield put(feedMeditationRequest(feedMeditation.payload));
   try {
      const url = API.feedMeditation;
      const checkUser = false;
      const peopleMayYouKnow = true;
      const params = feedMeditation.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, peopleMayYouKnow, params, ...url });
      if (response.status === 200) {
         const resObj = response?.data;
         yield put(feedMeditationSuccess(resObj));
      } else {
         yield put(feedMeditationFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeFeedMeditation = function* () {
   yield takeEvery(FEED_MEDITATION, feedMeditation);
};
