import { createAction } from '@reduxjs/toolkit';
import { leaveClinicTypes, myClinicSagaTypes } from 'types';

export const GET_CLINIC_GROUPS = 'GET_CLINIC_GROUPS';
export const getClinicGroupsAction = createAction<myClinicSagaTypes.IGetClinicGroups>(GET_CLINIC_GROUPS);

export const GET_CLINIC_ADMIN_INVITES = 'GET_CLINIC_ADMIN_INVITES';
export const getClinicAdminInvitesAction = createAction<myClinicSagaTypes.IGetClinicGroups>(GET_CLINIC_ADMIN_INVITES);

export const UPDATE_CLINIC_ADMIN_INVITES_STATUS = 'UPDATE_CLINIC_ADMIN_INVITES_STATUS';
export const updateClinicAdminInviteStatusAction = createAction<myClinicSagaTypes.IUpdateAdminStatus>(
   UPDATE_CLINIC_ADMIN_INVITES_STATUS
);

export const CLINIC_LEAVE_CLINIC_SAGA = 'CLINIC_LEAVE_CLINIC_SAGA';
export const leaveClinicAction = createAction<leaveClinicTypes.ILeaveClinicForm>(CLINIC_LEAVE_CLINIC_SAGA); //both patient and physician
