import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { myClinicReducerTypes } from 'types';

const initialState: myClinicReducerTypes.IGetClinicGroupsReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
};

export const getClinicGroupsSlice = createSlice({
   name: 'getClinicGroups',
   initialState: initialState,
   reducers: {
      getClinicGroupsRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action?.payload?.page === 1 ? [] : state.list,
         };
      },
      getClinicGroupsSuccess: (state, action: PayloadAction<myClinicReducerTypes.IClinicGroupsSuccess>) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            message: payload.message,
            isLoading: false,
         };
      },
      getClinicGroupsFailed: (state, action: PayloadAction<myClinicReducerTypes.IClinicGroupsFailed>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      getClinicGroupsReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

export const getClinicAdminInvitesSlice = createSlice({
   name: 'getClinicAdminInvites',
   initialState: initialState,
   reducers: {
      getClinicAdminInvitesRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action?.payload?.page === 1 ? [] : state.list,
         };
      },
      getClinicAdminInvitesSuccess: (state, action: PayloadAction<myClinicReducerTypes.IClinicGroupsSuccess>) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            message: payload.message,
            isLoading: false,
         };
      },
      getClinicAdminInvitesFailed: (state, action: PayloadAction<myClinicReducerTypes.IClinicGroupsFailed>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      getClinicAdminInvitesReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
