import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { feedReducerTypes, feedSagaTypes } from 'types';

const initialState: feedReducerTypes.ICommentsReplyAddReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const commentsReplyUpdateSlice = createSlice({
   name: 'commentsReplyUpdate',
   initialState: initialState,
   reducers: {
      commentsReplyUpdateRequest: (state, action: PayloadAction<feedSagaTypes.ICommentReplyUpdateSagaRequest>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      commentsReplyUpdateSuccess: (state, action: PayloadAction<feedReducerTypes.ICommentsReplyAddReducer>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      commentsReplyUpdateFailed: (state, action: PayloadAction<feedReducerTypes.ICommentsReplyAddReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error_message,
         };
      },
      commentsReplyUpdateReset: () => {
         return {
            ...initialState,
         };
      },
      commentsReplyUpdateResetMessage: (state) => {
         return {
            ...state,
            message: '',
         };
      },
   },
});
export { commentsReplyUpdateSlice };
