import { createSlice } from '@reduxjs/toolkit';
import { userReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: userReducerTypes.IUserReducer = {
   isLoading: false,
   error: '',
   message: '',
   addPhysician: {
      isLoading: false,
      message: '',
      error: '',
      errors: null,
      data: null,
   },
};

export const userOncoklinicSlice = createSlice({
   name: 'userOncoklinic',
   initialState: initialState,
   reducers: {
      addPhysicianLoading: (state) => {
         return {
            ...state,
            isLoading: true,
            addPhysician: {
               isLoading: true,
            },
         };
      },
      addPhysicianRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            addPhysician: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      addPhysicianSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            addPhysician: {
               isLoading: false,
               data: payload.data,
               message: payload.message || messages.success,
            },
         };
      },
      addPhysicianFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            addPhysician: {
               isLoading: false,
               message: '',
               error: action.payload.error,
               errors: action.payload.errors,
            },
         };
      },

      addPhysicianReset: (state) => {
         return {
            ...state,
            addPhysician: {
               ...initialState.addPhysician,
            },
         };
      },
   },
});
