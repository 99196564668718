import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { FEED_ORG_LIST, ORG_USER_EDU_MY_LIB_ADD_UPDATE, ORG_USER_EDU_MY_LIB_DELETE } from 'reduxToolKit/actions';
import { orgUserEduLibSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   myLibAddUpdateRequest,
   myLibAddUpdateSuccess,
   myLibAddUpdateFailed,
   myLibDeleteRequest,
   myLibDeleteSuccess,
   myLibDeleteFailed,
   feedOrgListRequest,
   feedOrgListSuccess,
   feedOrgListFailed,
} = orgUserEduLibSlice.actions;

const { exceptionHandlerRequest } = commonSlice.actions;

const orgUserEduLibAddUpdate = function* (eduAddUpdatePayload: any) {
   yield put(myLibAddUpdateRequest(eduAddUpdatePayload.payload));
   try {
      const data = eduAddUpdatePayload.payload;
      let url = API.orgUserEduMyLibAdd;
      if (data.id) {
         url = {
            apiPath: `${API.orgUserEduMyLibUpdate.apiPath}${data.id}`,
            action: API.orgUserEduMyLibUpdate.action,
         };
      }
      const response: AxiosResponse = yield apiCall({ data, ...url });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(myLibAddUpdateSuccess(responseData));
      } else {
         yield put(myLibAddUpdateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const orgUserEduLibDelete = function* (eduDeletePayload: any) {
   yield put(myLibDeleteRequest(eduDeletePayload.payload));
   try {
      const data = eduDeletePayload.payload;
      const url = {
         apiPath: `${API.orgUserEduMyLibDelete.apiPath}${data.id}`,
         action: API.orgUserEduMyLibDelete.action,
      };
      const response: AxiosResponse = yield apiCall({ data, ...url });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(myLibDeleteSuccess(responseData));
      } else {
         yield put(myLibDeleteFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const feedOrgListAction = function* (orgListPayload: PayloadAction<any>) {
   yield put(feedOrgListRequest(orgListPayload.payload));
   try {
      const params = orgListPayload.payload;
      const url = API.feedOrgList;
      const response: AxiosResponse = yield apiCall({ params, peopleMayYouKnow: true, checkUser: true, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const oncDriveList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message,
         };
         yield put(feedOrgListSuccess(oncDriveList));
      } else {
         yield put(feedOrgListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeOrgUserEduLib = function* () {
   yield takeEvery(ORG_USER_EDU_MY_LIB_ADD_UPDATE, orgUserEduLibAddUpdate);
   yield takeEvery(ORG_USER_EDU_MY_LIB_DELETE, orgUserEduLibDelete);
   yield takeEvery(FEED_ORG_LIST, feedOrgListAction);
};
