import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MEDICATIONS_LIST_SAGA, OTHER_MEDICATIONS_LIST_SAGA } from 'reduxToolKit/actions';
import { commonSlice, medicationListSlice } from 'reduxToolKit/reducers';
import { pillRemainderSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { medicationsListRequest, medicationsListSuccess, medicationsListFailed } = medicationListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const medicationsListSaga = function* (
   pillMEditListPayload: PayloadAction<pillRemainderSagaTypes.IPillActiveAndInactiveMedicineList>
) {
   yield put(medicationsListRequest(pillMEditListPayload.payload));
   try {
      const params = pillMEditListPayload.payload;
      const response: AxiosResponse = yield apiCall({ params, ...API.pillMedicationList });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(medicationsListSuccess(responseData));
      } else {
         yield put(medicationsListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const otherMedicationsListSaga = function* (
   pillMEditListPayload: PayloadAction<pillRemainderSagaTypes.IPillActiveAndInactiveMedicineList>
) {
   yield put(medicationsListRequest(pillMEditListPayload.payload));
   try {
      const params = pillMEditListPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         params,
         ...API.pillMedicationList,
      });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(medicationsListSuccess(responseData));
      } else {
         yield put(medicationsListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeMedicationList = function* () {
   yield takeEvery(MEDICATIONS_LIST_SAGA, medicationsListSaga);
   yield takeEvery(OTHER_MEDICATIONS_LIST_SAGA, otherMedicationsListSaga);
};
