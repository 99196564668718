import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CONNECTION_REQUEST } from 'reduxToolKit/actions';
import { commonSlice, connectionRequestPostSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { connectionRequest, connectionRequestSuccess, connectionRequestFailed } = connectionRequestPostSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const connectionAcceptSaga = function* (payloadState: any) {
   yield put(connectionRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = {
         to_user_id: payloadState.payload.to_user_id,
         isFrom: payloadState.payload.isFrom,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.connectionRequest });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: null,
            requestPayloadIsFrom: payloadState.payload.isFrom,
         };
         yield put(connectionRequestSuccess(resObj));
      } else {
         yield put(connectionRequestFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeConnectionRequest = function* () {
   yield takeEvery(CONNECTION_REQUEST, connectionAcceptSaga);
};
