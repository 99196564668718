import { createAction } from '@reduxjs/toolkit';
import { myProfileSagaTypes } from 'types';

export const ADD_SERVICE_OFFERED = 'ADD_SERVICE_OFFERED';
export const addServiceOfferedAction = createAction<myProfileSagaTypes.IAddServiceOfferedProps>(ADD_SERVICE_OFFERED);

export const GET_MASTER_SERVICE_OFFERED = 'GET_MASTER_SERVICE_OFFERED';
export const getMasterServiceOfferedAction = createAction<myProfileSagaTypes.IMasterServiceOfferedProps>(
   GET_MASTER_SERVICE_OFFERED
);

export const GET_USER_SERVICE_OFFERED = 'GET_USER_SERVICE_OFFERED';
export const getUserServiceOfferedAction = createAction<myProfileSagaTypes.IUserServiceOfferedProps>(
   GET_USER_SERVICE_OFFERED
);
