import { createSlice } from '@reduxjs/toolkit';
import { fillProfileReducerTypes } from 'types';

const initialState: fillProfileReducerTypes.IPhoneSectionReducerType = {
   isLoading: false,
   error: '',
   errorMessage: '',
   message: '',
   data: null,
};
export const addressSectionPostSlice = createSlice({
   name: 'addressSectionSlice',
   initialState: initialState,
   reducers: {
      addressSectionRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addressSectionSuccess: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            ...action.payload,
         };
      },
      addressSectionFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
            errorMessage: action.payload.error,
         };
      },
      addressSectionReset: (state) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            errorMessage: '',
         };
      },
   },
});
