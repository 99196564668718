export const CommonConstants = {
   InfoTimer: {
      INFO_TIMER_INTERVAL: 1000,
      INFO_TIMER_ALERT: 2,
   },
   DropDownLimit: 1000,
   AddWorkingHoursArray: [0, 1, 2, 3, 4, 5, 6],
   API_PAGE_LIMIT: 15,
   Toast: {
      TOAST_TIMER_ALERT: 3000,
      types: {
         LOADING: 'loading',
         SUCCESS: 'success',
         ERROR: 'error',
         WARN: 'warn',
         INFO: 'info',
         WARNING: 'warning',
         DARK: 'dark',
      },
   },
   US_COUNTRY_ID: 236,
};

export const common = {
   SET_TIMEOUT: 1000,
   YOUTUBE_WIDTH: 1920,
   YOUTUBE_HEIGHT: 1080,
};

export const SET_TIME_OUT = {
   MAX: 2000,
   MIN: 1000,
   MEDIUM: 1500,
   MICRO: 500,
   NANO: 100,
};
