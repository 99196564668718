import { createSlice } from '@reduxjs/toolkit';
import { editPrescriptionReducerTypes } from 'types';

const initialState: editPrescriptionReducerTypes.IOnePrescription = {
   change_in_weight: 0,
   clinic_id: 0,
   clinic_video_prescription: [],
   comparison_based_on: '',
   created_at: '',
   description: '',
   discontinue_date: '',
   id: 0,
   last_interval_checked: '',
   line_of_therapy: [],
   line_of_therapy_id: 0,
   no_of_days_for_previous_comparison: 0,
   patient: [],
   patient_id: 0,
   physician: [],
   physician_id: 0,
   prescription_devices: [],
   prescription_drugs: [],
   prescription_medication: [],
   prescription_morbidity: [],
   prescription_video_reference: [],
   source: '',
   status: 0,
   symptom_submit_reminder: 0,
   report_symptom_submit_reminder: '',
   updated_at: '',
   user: [],
   user_id: 0,
   isLoading: false,
   error: '',
   message: '',
};

export const getOnePrescriptionSlice = createSlice({
   name: 'getOnePrescription',
   initialState: initialState,
   reducers: {
      getOnePrescriptionRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getOnePrescriptionSuccess: (state, action: any) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      getOnePrescriptionFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload?.error || action.payload?.error_message,
         };
      },
      getOnePrescriptionReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
