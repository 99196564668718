import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from 'assets/image/info_icon.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import { DialogActions } from 'component/shared/MuiWithStyle';
import Constant from 'config/Constant';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { profileCompletionSagaAction } from 'reduxToolKit/actions';
import { postShareSlice } from 'reduxToolKit/reducers';
import { storeTypes } from 'types';
import DisplayDetails from 'utils/helpers/messageHelpers';

const styles = (theme: Theme) =>
   createStyles({
      root: {
         margin: 0,
         padding: theme.spacing(2),
      },
      closeButton: {
         position: 'absolute',
         right: theme.spacing(1),
         top: theme.spacing(1),
         color: 'white',
      },
   });
const useStylesBootstrap = makeStyles((theme) => ({
   arrow: {
      color: '#007edd',
   },
   tooltip: {
      backgroundColor: '#007edd',
      fontSize: theme.typography.pxToRem(14),
   },
}));

function BootstrapTooltip(props: any) {
   const classes = useStylesBootstrap();
   return <Tooltip placement="top" arrow classes={classes} {...props} />;
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
   id: string;
   children: React.ReactNode;
   onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: any) => {
   const { children, classes, onClose, ...other } = props;
   return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
         <Typography variant="h6">{children}</Typography>
         {/* {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
               <CloseIcon />
            </IconButton>
         ) : null} */}
      </MuiDialogTitle>
   );
});

class DisplayName extends React.Component<any, any> {
   constructor(props: any) {
      super(props);

      this.state = {
         error: null,
         displayName: null,
         modalIsOpen: true,
         displayType: 'display',
      };

      this.handleClose = this.handleClose.bind(this);
   }

   handleClose = (reason?: any) => {
      if (reason !== 'backdropClick') {
         this.setState({ modalIsOpen: false });
         if (this.props.openDisplayName) this.props.openDisplayName();
         if (this.props.isRedirectToAskADocHome) {
            if (this.props.type === 'free') {
               window.location.href = '/ask-a-doc/patient';
            } else {
               window.location.href = '/' + Constant.urlSecondOpinion;
            }
         }
      }
   };

   clickDisplayNameType = (type: string) => {
      this.setState(
         {
            displayType: type,
         },
         () => {
            if (this.state.displayType === 'actual') {
               this.setState({
                  displayName: null,
                  error: '',
               });
            }
         }
      );
   };

   changeDisplayNameType = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState(
         {
            displayType: e.target.value,
         },
         () => {
            if (this.state.displayType === 'actual') {
               this.setState({
                  displayName: null,
                  error: '',
               });
            }
         }
      );
   };

   capitalizeFirstLetter = (str: string) => {
      if (str !== null && str.length >= 1) {
         return str.charAt(0).toUpperCase() + str.slice(1);
      } else {
         return str;
      }
   };

   onChangeDisplayName = (e: React.ChangeEvent<HTMLInputElement>) => {
      const displayName = this.capitalizeFirstLetter(e.target.value);
      this.setState(
         {
            displayName: displayName ? displayName : null,
         },
         () => {
            this.setState({
               error: this.state.displayName ? '' : 'Display name field is required',
            });
         }
      );
   };

   saveDisplayName = () => {
      if (this.state.displayType === 'actual') {
         this.setState(
            {
               displayName: null,
            },
            () => {
               this.updateDisplayNameStatus(false, '');
            }
         );
      } else if (this.state.displayType === 'display') {
         if (this.state.displayName === null) {
            this.setState({
               error: 'Display name field is required',
            });
            return;
         } else {
            this.setState({
               error: '',
            });
            this.updateDisplayNameStatus(true, this.state.displayName);
         }
      }
   };

   updateDisplayNameStatus = (status: boolean, displayName: string) => {
      this.props.showLoading('show');
      const postAddressData = {
         input_type: 'display_name',
         display_name: displayName,
      };
      this.props.displayNameDispatch(postAddressData);
   };
   displaySuccess = (message: string) => {
      const getAlert = () => (
         <InfoTimerAlert
            success
            confirmBtnText="Okay!"
            confirmBtnBsStyle="successBtn"
            title={message}
            onConfirm={() => this.hideAlert('userAlert')}
         ></InfoTimerAlert>
      );
      this.props.showLoading('hide');
      if (this.props.openDisplayName) this.props.openDisplayName();
      this.setState({ modalIsOpen: false, alert: getAlert() });
      this.props.resetDisplayName();
   };
   displayError = (error: string) => {
      const getAlert = () => (
         <InfoTimerAlert
            error
            confirmBtnText="Okay!"
            confirmBtnBsStyle="errorBtn"
            title={error}
            onConfirm={() => this.hideAlert('userAlert')}
         ></InfoTimerAlert>
      );
      this.props.showLoading('hide');
      this.setState({ modalIsOpen: false, alert: getAlert() });
      this.props.resetDisplayName();
   };

   hideAlert = (type: string) => {
      if (type === 'userAlert') {
         this.props.updateDisplayNameStatus(true);
      }
   };
   render() {
      return (
         <div className="pull-right" id="list">
            {this.state.alert}
            <Dialog
               className="feed ask-doc__hippa"
               onClose={(e: any, reason: any) => {
                  this.handleClose(reason);
               }}
               aria-labelledby="customized-dialog-title"
               open={this.state.modalIsOpen}
               fullWidth={true}
               maxWidth={'xs'}
            >
               {this.props.isBasicProfile ? null : (
                  <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                     Display Name
                  </DialogTitle>
               )}
               <DialogActions>
                  <Grid container spacing={3} className="m-2">
                     {this.props.isBasicProfile ? (
                        <div className="col-12 stage add-rating-request">
                           <div className="confrm-modal-close">
                              <CloseIcon className="hand-i" onClick={this.handleClose} />
                           </div>
                           <div className="col-12 mb-4 clear-both">
                              <label htmlFor="title" className="revoke-checked text-center" style={{ padding: '0' }}>
                                 <span className="revoke-consent-content pl-1" style={{ fontWeight: 700 }}>
                                    {Constant.COMPLETE_PROFILE}
                                 </span>
                              </label>
                           </div>
                           <div className="col-12 mt-2 mb-2">
                              <label htmlFor="title" className="revoke-checked text-center" style={{ padding: '0' }}>
                                 <span className="revoke-consent-content pl-1" style={{ fontWeight: 600 }}>
                                    {this.props.myService ? Constant.CHOOSE_COUNTRY : Constant.CHOOSE_DISPLAY_NAME}
                                 </span>
                              </label>
                           </div>
                           <div className="col-12 mt-4 mb-2">
                              <div className="book__appointment text-center">
                                 <Link
                                    to={
                                       this.props.myService
                                          ? '/edit-profile?source=' + Constant.FROM_MY_SERVICE
                                          : '/edit-profile?source=' + Constant.FROM_SECOND_OPINION
                                    }
                                 >
                                    <Button variant="contained" color="primary" className="m-save-btn m-btn-p">
                                       {'Go to Profile'}
                                    </Button>
                                 </Link>
                              </div>
                           </div>
                        </div>
                     ) : (
                        <div className="col-12 stage add-rating-request">
                           {/* <div className="col-12 pr-0 mb-4">
                              <label htmlFor="title" className="revoke-checked text-center flex mb-0">
                                 <input
                                    type="radio"
                                    name="visit_type"
                                    value="actual"
                                    onChange={this.changeDisplayNameType}
                                    checked={this.state.displayType === 'actual'}
                                 />
                                 <span
                                    className="revoke-consent-content pl-1"
                                    onClick={() => {
                                       this.clickDisplayNameType('actual');
                                    }}
                                    style={{ fontWeight: 600 }}
                                 >
                                    Do you want to continue with Actual Name ?
                                 </span>
                                 <span
                                    className="checked-radio"
                                    onClick={() => {
                                       this.clickDisplayNameType('actual');
                                    }}
                                 />
                              </label>
                           </div>
                           <div className="col-12 pr-0 mt-2 mb-2">
                              <label htmlFor="title" className="revoke-checked text-center flex mb-0">
                                 <input
                                    type="radio"
                                    name="visit_type"
                                    value="display"
                                    onChange={this.changeDisplayNameType}
                                    checked={this.state.displayType === 'display'}
                                 />
                                 <span
                                    className="revoke-consent-content pl-1"
                                    onClick={() => {
                                       this.clickDisplayNameType('display');
                                    }}
                                    style={{ fontWeight: 600 }}
                                 >
                                    Do you want to continue with Display Name ?
                                 </span>
                                 <span
                                    className="checked-radio"
                                    onClick={() => {
                                       this.clickDisplayNameType('display');
                                    }}
                                 />
                              </label>
                           </div> */}
                           <div className="col-12 text-center">
                              <div className="ocp-form">
                                 <form className="form">
                                    <div className="form-row mb-0">
                                       <div className="col-12 pr-0 mt-2 mb-2">
                                          <label htmlFor="title" className="revoke-checked text-center flex mb-0 pl-0">
                                             <span className="revoke-consent-content pl-1" style={{ fontWeight: 600 }}>
                                                Display Name
                                             </span>
                                             <BootstrapTooltip title={DisplayDetails.DISPLAY_DETAILS}>
                                                <img
                                                   style={{
                                                      cursor: 'not-allowed',
                                                   }}
                                                   className="hand-i"
                                                   src={InfoIcon}
                                                   alt="info"
                                                />
                                             </BootstrapTooltip>
                                          </label>
                                       </div>
                                       <div className="col-12">
                                          <input
                                             type="text"
                                             name="name"
                                             // readOnly={this.state.displayType === 'actual'}
                                             value={this.state.displayName ? this.state.displayName : ''}
                                             onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.onChangeDisplayName(e);
                                             }}
                                             // onKeyPress={(e) => isAlphaNumeric(e)}
                                             className="form-control input-filed"
                                             placeholder="Type Name"
                                             autoComplete="off"
                                          />
                                       </div>
                                       <div className="errorMsg mb-2">
                                          {this.state.error ? this.state.error : ''}
                                          {this.state.errors ? this.state.errors.display_name : ''}
                                       </div>
                                    </div>
                                 </form>
                              </div>
                           </div>
                           <div className="col-12 mt-4 mb-2">
                              <div className="book__appointment text-right">
                                 <Button
                                    onClick={this.saveDisplayName}
                                    variant="contained"
                                    color="primary"
                                    className="m-save-btn m-btn-p"
                                 >
                                    Submit
                                 </Button>
                              </div>
                           </div>
                        </div>
                     )}
                  </Grid>
               </DialogActions>
            </Dialog>
         </div>
      );
   }

   componentDidMount(): void {
      this.setState({
         error: this.props.error,
      });
   }
   componentWillUpdate(nextProps: any) {
      if (nextProps.message !== '') {
         this.displaySuccess(nextProps.message);
      } else if (nextProps.error !== '') {
         this.displayError(nextProps.error);
      }
   }
}
const mapDispatchToProps = (dispatch: any) => {
   const { postShareReset } = postShareSlice.actions;
   return {
      displayNameDispatch: (params: any) => dispatch(profileCompletionSagaAction(params)),
      resetDisplayName: () => dispatch(postShareReset()),
   };
};
const mapStateToProps = (state: storeTypes.IStore) => ({
   message: state.publicPostShare.message,
   isLoading: state.publicPostShare.isLoading,
   error: state.publicPostShare.error,
});
export default connect(mapStateToProps, mapDispatchToProps)(DisplayName);
