import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';
import { pillRemainderSagaTypes } from 'types';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';

const PillChooseMultiDays = (props: any) => {
   const [daysArray, setDaysArray] = useState<pillRemainderSagaTypes.IPillRemainderMedDay[]>([]);
   const [errorMessage, setErrorMessage] = useState('');

   const changeDays = useCallback(
      (event: React.ChangeEvent<any>, checked: boolean) => {
         const day = event.target.value;
         if (checked) {
            const daysObjArray = listParseAndStringify(daysArray);
            if (props.type === 'two_days_a_week') {
               if (daysObjArray.length < 2) {
                  daysObjArray.push({ day: event.target.value });
               }
            } else if (props.type === 'three_days_a_week') {
               if (daysObjArray.length < 3) {
                  daysObjArray.push({ day: event.target.value });
               }
            } else if (props.type === 'five_days_a_week') {
               if (daysObjArray.length < 5) {
                  daysObjArray.push({ day: event.target.value });
               }
            } else if (props.type === 'others') {
               daysObjArray.push({ day: event.target.value });
            }

            setDaysArray(daysObjArray);
         } else {
            const daysObjArray = listParseAndStringify(daysArray);
            const index = daysArray.findIndex((x: pillRemainderSagaTypes.IPillRemainderMedDay) => x.day === day);
            if (index !== -1) {
               daysObjArray.splice(index, 1);
               setDaysArray(daysObjArray);
            }
         }
      },
      [daysArray]
   );

   const setActionText = () => {
      let action = 'Next';
      if (
         props.type === 'two_days_a_week' ||
         props.type === 'three_days_a_week' ||
         props.type === 'five_days_a_week' ||
         props.type === 'others'
      ) {
         action = 'Submit';
      }
      return action;
   };

   const clickNext = useCallback(() => {
      if (props.type === 'two_days_a_week') {
         if (daysArray.length === 2) {
         } else {
            setErrorMessage('Please select 2 days');
            return;
         }
      } else if (props.type === 'three_days_a_week') {
         if (daysArray.length === 3) {
         } else {
            setErrorMessage('Please select 3 days');
            return;
         }
      } else if (props.type === 'five_days_a_week') {
         if (daysArray.length === 5) {
         } else {
            setErrorMessage('Please select 5 days');
            return;
         }
      } else if (props.type === 'others') {
         if (props.othersType === 'specific_days_of_the_week') {
            if (daysArray.length >= 1 && daysArray.length <= 7) {
            } else {
               setErrorMessage('Please select specific days');
               return;
            }
         }
      }
      if (daysArray.length > 0) {
         props.pillRemainderChooseDays(daysArray);
      } else {
         setErrorMessage('Please select one or more days');
      }
   }, [daysArray]);

   useEffect(() => {
      setDaysArray(props?.daysArray);
   }, []);

   return (
      <Box>
         <Box pl={3}>
            <Typography align="center" className="pill-add-text">
               On which day(s) do you need to take the medicine
            </Typography>
         </Box>
         <Box pl={3} width="80%" ml={7} mt={3}>
            <Grid container direction="row" spacing={10}>
               <Grid item>
                  <FormControl component="fieldset">
                     <FormGroup>
                        <FormControlLabel
                           onChange={(event: React.ChangeEvent<any>, checked: boolean) => {
                              changeDays(event, checked);
                           }}
                           value={'0'}
                           control={
                              <Checkbox
                                 checked={
                                    daysArray.findIndex(
                                       (x: pillRemainderSagaTypes.IPillRemainderMedDay) => x.day === '0'
                                    ) !== -1
                                       ? true
                                       : false
                                 }
                                 name="gilad"
                              />
                           }
                           label="Sunday"
                        />
                        <FormControlLabel
                           onChange={(event: React.ChangeEvent<any>, checked: boolean) => {
                              changeDays(event, checked);
                           }}
                           value={'2'}
                           control={
                              <Checkbox
                                 checked={
                                    daysArray.findIndex(
                                       (x: pillRemainderSagaTypes.IPillRemainderMedDay) => x.day === '2'
                                    ) !== -1
                                       ? true
                                       : false
                                 }
                                 name="jason"
                              />
                           }
                           label="Tuesday"
                        />
                        <FormControlLabel
                           onChange={(event: React.ChangeEvent<any>, checked: boolean) => {
                              changeDays(event, checked);
                           }}
                           value={'4'}
                           control={
                              <Checkbox
                                 checked={
                                    daysArray.findIndex(
                                       (x: pillRemainderSagaTypes.IPillRemainderMedDay) => x.day === '4'
                                    ) !== -1
                                       ? true
                                       : false
                                 }
                                 name="antoine"
                              />
                           }
                           label="Thursday"
                        />
                        <FormControlLabel
                           onChange={(event: React.ChangeEvent<any>, checked: boolean) => {
                              changeDays(event, checked);
                           }}
                           value={'6'}
                           control={
                              <Checkbox
                                 checked={
                                    daysArray.findIndex(
                                       (x: pillRemainderSagaTypes.IPillRemainderMedDay) => x.day === '6'
                                    ) !== -1
                                       ? true
                                       : false
                                 }
                                 name="antoine"
                              />
                           }
                           label="Saturday"
                        />
                     </FormGroup>
                  </FormControl>
               </Grid>
               <Grid item>
                  <FormControl component="fieldset">
                     <FormGroup>
                        <FormControlLabel
                           onChange={(event: React.ChangeEvent<any>, checked: boolean) => {
                              changeDays(event, checked);
                           }}
                           value={'1'}
                           control={
                              <Checkbox
                                 checked={
                                    daysArray.findIndex(
                                       (x: pillRemainderSagaTypes.IPillRemainderMedDay) => x.day === '1'
                                    ) !== -1
                                       ? true
                                       : false
                                 }
                                 name="gilad"
                              />
                           }
                           label="Monday"
                        />
                        <FormControlLabel
                           onChange={(event: React.ChangeEvent<any>, checked: boolean) => {
                              changeDays(event, checked);
                           }}
                           value={'3'}
                           control={
                              <Checkbox
                                 checked={
                                    daysArray.findIndex(
                                       (x: pillRemainderSagaTypes.IPillRemainderMedDay) => x.day === '3'
                                    ) !== -1
                                       ? true
                                       : false
                                 }
                                 name="jason"
                              />
                           }
                           label="Wednesday"
                        />
                        <FormControlLabel
                           onChange={(event: React.ChangeEvent<any>, checked: boolean) => {
                              changeDays(event, checked);
                           }}
                           value={'5'}
                           control={
                              <Checkbox
                                 checked={
                                    daysArray.findIndex(
                                       (x: pillRemainderSagaTypes.IPillRemainderMedDay) => x.day === '5'
                                    ) !== -1
                                       ? true
                                       : false
                                 }
                                 name="antoine"
                              />
                           }
                           label="Friday"
                        />
                     </FormGroup>
                  </FormControl>
               </Grid>
            </Grid>
         </Box>
         {errorMessage && <Box className="errorMsg pill-error">{errorMessage}</Box>}
         <Box mb={4} mx="auto" mt={5}>
            <Grid container direction="row" spacing={3} justify="center">
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="pill-add-back-button"
                     fullWidth
                     onClick={() => {
                        if (
                           props?.type === 'two_days_a_week' ||
                           props?.type === 'three_days_a_week' ||
                           props?.type === 'five_days_a_week'
                        ) {
                           props?.pillRemainderChooseDaysBack(daysArray, props?.type, 'first_dose_time');
                        } else if (props?.type === 'others') {
                           if (props?.othersType === 'specific_days_of_the_week') {
                              props?.pillRemainderChooseDaysBack(daysArray, props?.type, 'first_dose_time');
                           }
                        }
                     }}
                  >
                     Back
                  </Button>
               </Grid>
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="telemedicine__modal--btn"
                     fullWidth
                     onClick={clickNext}
                  >
                     {setActionText()}
                  </Button>
               </Grid>
            </Grid>
         </Box>
      </Box>
   );
};

export { PillChooseMultiDays };
