import { createSlice } from '@reduxjs/toolkit';
import { educationalVideoReducerTypes } from 'types';

const initialState: educationalVideoReducerTypes.IEducationalVideo = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
};

export const assignVideoSlice = createSlice({
   name: 'assignVideo',
   initialState: initialState,
   reducers: {
      assignVideoLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      assignVideoRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: state.list,
         };
      },
      assignVideoSuccess: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            isLoading: false,
         };
      },
      assignVideoFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      assignVideoReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

export const assignedVideoSlice = createSlice({
   name: 'assignedVideo',
   initialState: initialState,
   reducers: {
      assignedVideoLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      assignedVideoRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: state.list,
         };
      },
      assignedVideoSuccess: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            isLoading: false,
         };
      },
      assignedVideoFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      assignedVideoReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

export const assignClinicVideoSlice = createSlice({
   name: 'assignClinicVideo',
   initialState: initialState,
   reducers: {
      assignClinicVideoLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      assignClinicVideoRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: state.list,
         };
      },
      assignClinicVideoSuccess: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
         };
      },
      assignClinicVideoFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      assignClinicVideoReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
