import { createSlice } from '@reduxjs/toolkit';
import { editPrescriptionReducerTypes } from 'types';

const initialState: editPrescriptionReducerTypes.IGetLinesOfTherapyReducer = {
   isLoading: false,
   list: [],
   error: '',
   message: '',
};

export const getLineOfTherapyHistorySlice = createSlice({
   name: 'getLineOfTherapyHistory',
   initialState: initialState,
   reducers: {
      getLineOfTherapyHistoryRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getLineOfTherapyHistorySuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload,
            isLoading: false,
            message: payload.message,
         };
      },
      getLineOfTherapyHistoryFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            error: action.payload.error,
            message: '',
         };
      },
      getLineOfTherapyHistoryResetMessage: (state) => {
         return {
            ...state,
            isLoading: false,
            message: '',
         };
      },
      getLineOfTherapyHistoryReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
