import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import {
   ASK_A_DOC_PHY_PANEL_LIST,
   ASK_A_DOC_PANEL_PHYSICIAN_LIST,
   ASK_A_DOC_CHECK_REQUIRED_FIELDS,
} from 'reduxToolKit/actions';
import {
   commonSlice,
   askADocPhyPanelListSlice,
   getAskADocCaseDetailsCheckRequiredFieldsSlice,
} from 'reduxToolKit/reducers';
import { askDocSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   askADocPhyPanelListRequest,
   askADocPhyPanelListSuccess,
   askADocPhyPanelListFailed,
   askADocPanelPhysicianListRequest,
   askADocPanelPhysicianListSuccess,
   askADocPanelPhysicianListFailure,
} = askADocPhyPanelListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const askADocPhyPanelList = function* (payloadState: any) {
   yield put(askADocPhyPanelListRequest(payloadState.payload));
   try {
      const params = payloadState.payload;
      const url = API.askADocPhyPanelList;
      const response: AxiosResponse = yield apiCall({ params, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const panelReqList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response?.data?.message || messages.success,
         };
         yield put(askADocPhyPanelListSuccess(panelReqList));
      } else {
         yield put(askADocPhyPanelListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const askADocPanelPhysicianList = function* (
   payloadState: PayloadAction<askDocSagaTypes.IAskADocPanelPhysicianListSagaPayload>
) {
   yield put(askADocPanelPhysicianListRequest(payloadState.payload));
   try {
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ params, ...API.askADocPanelPhysicianList });
      if (response.status === 200) {
         const responseData = response.data.data;
         const panelPhysicianList = {
            data: responseData.data,
            message: response?.data?.message || messages.success,
         };
         yield put(askADocPanelPhysicianListSuccess(panelPhysicianList));
      } else {
         yield put(askADocPanelPhysicianListFailure(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   askADocRequiredFieldsRequest,
   askADocRequiredFieldsSuccess,
   askADocRequiredFieldsFailed,
} = getAskADocCaseDetailsCheckRequiredFieldsSlice.actions;
const askADocCaseDetailsCheckRequiredFields = function* (
   payloadState: PayloadAction<askDocSagaTypes.IAskADocCheckRequiredFields>
) {
   yield put(askADocRequiredFieldsRequest(payloadState.payload));
   try {
      const goUrl = true;
      const checkUser = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ goUrl, checkUser, params, ...API.checkRequiredFields });
      if (response.status === 200) {
         const panelPhysicianList = {
            data: response.data.data,
            message: response?.data?.message,
            reponseMessage: messages.success,
         };
         yield put(askADocRequiredFieldsSuccess(panelPhysicianList));
      } else {
         yield put(askADocRequiredFieldsFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAskDocPhyPanelList = function* () {
   yield takeLatest(ASK_A_DOC_PHY_PANEL_LIST, askADocPhyPanelList);
   yield takeLatest(ASK_A_DOC_PANEL_PHYSICIAN_LIST, askADocPanelPhysicianList);
};

export const takeAskADocCaseDetailsCheckRequiredFields = function* () {
   yield takeLatest(ASK_A_DOC_CHECK_REQUIRED_FIELDS, askADocCaseDetailsCheckRequiredFields);
};
