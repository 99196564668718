import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_RACE, GET_MASTER_RACE } from 'reduxToolKit/actions';
import { commonSlice, raceSlice } from 'reduxToolKit/reducers';
import { myProfileReducerTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   raceAddRequest,
   raceAddSuccess,
   raceAddFailed,
   getMasterRaceRequest,
   getMasterRaceSuccess,
   getMasterRaceFailed,
} = raceSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const raceAddSaga = function* (raceAddPayload: any) {
   yield put(raceAddRequest(raceAddPayload.payload));
   try {
      const checkUser = false;
      const data = raceAddPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...API.raceAdd });
      if (response.status === 200) {
         const responseData = response.data;
         const raceAddData = {
            message: responseData?.message || '',
         };
         yield put(raceAddSuccess(raceAddData));
      } else {
         yield put(raceAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getMasterRaceSaga = function* (getMasterRacePayload: PayloadAction<myProfileReducerTypes.IRaceReducer>) {
   yield put(getMasterRaceRequest(getMasterRacePayload.payload));
   try {
      const params = getMasterRacePayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         params,
         ...API.getMasterRace,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const getMasterRaceList = {
            list: responseData?.data?.data || [],
            total_records: responseData?.data?.total || 0,
            message: response?.data?.message || '',
         };
         yield put(getMasterRaceSuccess(getMasterRaceList));
      } else {
         yield put(getMasterRaceFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeRace = function* () {
   yield takeEvery(ADD_RACE, raceAddSaga);
   yield takeEvery(GET_MASTER_RACE, getMasterRaceSaga);
};
