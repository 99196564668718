import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clinicAdminReducerTypes, clinicAdminSagaTypes } from 'types';

const initialState: clinicAdminReducerTypes.IClinicInfo = {
   isLoading: false,
   error: '',
   message: '',
};
const clinicPatientBulkImportSlice = createSlice({
   name: 'clinicPatientBulkImport',
   initialState: initialState,
   reducers: {
      clinicPatientBulkImportRequest: (state, action: PayloadAction<clinicAdminSagaTypes.IClinicPatientBulkImport>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      clinicPatientBulkImportSuccess: (state, action: PayloadAction<clinicAdminReducerTypes.IClinicInfo>) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      clinicPatientBulkImportFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error_message ? action.payload.error_message : '',
            ...action.payload,
         };
      },
      clinicPatientBulkImportReset: () => {
         return initialState;
      },
   },
});

export { clinicPatientBulkImportSlice };
