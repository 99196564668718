export const FeedReactions = {
   Like: 'like',
   FoldedHands: 'folded_hands',
   Brainy: 'brainy',
   Hugs: 'hugs',
};
export const Reactions = {
   Like: 'reaction_like',
   FoldedHands: 'reaction_folded_hands',
   Brainy: 'reaction_brainy',
   Hugs: 'reaction_hugs',
};
