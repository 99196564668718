import { createAction } from '@reduxjs/toolkit';
import { askADocPageTypes } from 'types';

export const POST_SUMMARY = 'POST_SUMMARY';
export const postSummarySagaAction = createAction<askADocPageTypes.ISummary>(POST_SUMMARY);

export const UPDATE_SUMMARY = 'UPDATE_SUMMARY';
export const updateSummarySagaAction = createAction<askADocPageTypes.ISummary>(UPDATE_SUMMARY);

export const SECOND_OPINION_GET_ONE_SUMMARY = 'SECOND_OPINION_GET_ONE_SUMMARY';
export const secondOpinionGetOneSummarySagaAction = createAction<askADocPageTypes.IGetOneSummery>(
   SECOND_OPINION_GET_ONE_SUMMARY
);
