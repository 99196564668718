import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userStateReducerTypes, userStateSagaTypes } from 'types';

const initialState: userStateReducerTypes.IUserStateReducerTypes = {
   isLoading: false,
   userStates: {
      list: [],
      message: '',
      total_records: 0,
      error: '',
   },
   updateUserState: {
      message: '',
      error: '',
   },
};

export const userStateSlice = createSlice({
   name: 'userState',
   initialState: initialState,
   reducers: {
      getUserStatesRequest: (state, action: PayloadAction<userStateSagaTypes.IGetStateLists>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getUserStatesSuccess: (state, action: PayloadAction<userStateReducerTypes.IGetUserStatesSuccessPayload>) => {
         return {
            ...state,
            userStates: {
               message: action.payload?.message,
               list: action.payload?.list,
               total_records: action.payload?.total_records,
               error: '',
            },
         };
      },
      getUserStatesFailed: (state, action: PayloadAction<userStateReducerTypes.IUserStateFailurePayload>) => {
         return {
            ...state,
            userStates: {
               error: action.payload?.error,
               message: '',
               list: [],
               total_records: 0,
            },
         };
      },

      updateUserStateRequest: (state, action: PayloadAction<userStateSagaTypes.IUpdateUserState>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      updateUserStateSuccess: (state, action: PayloadAction<userStateReducerTypes.IUpdateUserStateSuccessPayload>) => {
         return {
            ...state,
            isLoading: false,
            updateUserState: {
               message: action.payload?.message,
               error: '',
            },
         };
      },
      updateUserStateFailed: (state, action: PayloadAction<userStateReducerTypes.IUserStateFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            updateUserState: {
               error: action.payload?.error,
               message: '',
            },
         };
      },

      userStateReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
