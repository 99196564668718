import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';

const PillChooseOneDay = (props: any) => {
   const [chooseOneDayValue, setChooseOneDayValue] = useState('');
   const [errorMessage, setErrorMessage] = useState('');

   const changeRadioGroupType = useCallback((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setChooseOneDayValue(value);
      setErrorMessage('');
   }, []);

   const setActionText = () => {
      let action = 'Next';
      if (props.type === 'once_a_week') {
         action = 'Submit';
      }
      return action;
   };

   const clickNext = useCallback(() => {
      if (chooseOneDayValue) {
         props.pillRemainderChooseDays([{ day: chooseOneDayValue }]);
      } else {
         setErrorMessage('Please select any one type');
      }
   }, [chooseOneDayValue]);

   useEffect(() => {
      setChooseOneDayValue(props?.daysArray.length > 0 ? props?.daysArray[0].day : '');
   }, []);

   return (
      <Box>
         <Box pl={3}>
            <Typography align="center" className="pill-add-text">
               On which day(s) do you need to take the medicine
            </Typography>
         </Box>
         <Box pl={3} width="80%" ml={7} mt={3}>
            <Grid container direction="row" spacing={10}>
               <Grid item>
                  <FormControl component="fieldset">
                     <RadioGroup
                        aria-label="pillScheduleDays"
                        name="pillScheduleDays"
                        value={chooseOneDayValue}
                        onChange={changeRadioGroupType}
                     >
                        <FormControlLabel value="0" control={<Radio />} label="Sunday" />
                        <FormControlLabel value="2" control={<Radio />} label="Tuesday" />
                        <FormControlLabel value="4" control={<Radio />} label="Thursday" />
                        <FormControlLabel value="6" control={<Radio />} label="Saturday" />
                     </RadioGroup>
                  </FormControl>
               </Grid>
               <Grid item>
                  <FormControl component="fieldset">
                     <RadioGroup
                        aria-label="pillScheduleDays"
                        name="pillScheduleDays"
                        value={chooseOneDayValue}
                        onChange={changeRadioGroupType}
                     >
                        <FormControlLabel value="1" control={<Radio />} label="Monday" />
                        <FormControlLabel value="3" control={<Radio />} label="Wednesday" />
                        <FormControlLabel value="5" control={<Radio />} label="Friday" />
                     </RadioGroup>
                  </FormControl>
               </Grid>
            </Grid>
         </Box>
         {errorMessage && <Box className="errorMsg pill-error">{errorMessage}</Box>}
         <Box mb={4} mx="auto" mt={5}>
            <Grid container direction="row" spacing={3} justify="center">
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="pill-add-back-button"
                     fullWidth
                     onClick={() => {
                        if (props?.type === 'once_a_week') {
                           props?.pillRemainderChooseDaysBack(
                              [{ day: chooseOneDayValue }],
                              props?.type,
                              'first_dose_time'
                           );
                        }
                     }}
                  >
                     Back
                  </Button>
               </Grid>
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="telemedicine__modal--btn"
                     fullWidth
                     onClick={clickNext}
                  >
                     {setActionText()}
                  </Button>
               </Grid>
            </Grid>
         </Box>
      </Box>
   );
};

export { PillChooseOneDay };
