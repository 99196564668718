import { createAction } from '@reduxjs/toolkit';
import { postShareSagaTypes } from 'types';

export const SUPPORT_GROUP_LIST = 'SUPPORT_GROUP_LIST';
export const supportGroupList = createAction<postShareSagaTypes.ISupportGroupsList>(SUPPORT_GROUP_LIST);

export const SUPPORT_MY_GROUP_LIST = 'SUPPORT_MY_GROUP_LIST';
export const supportMyGroupList = createAction<postShareSagaTypes.ISupportMyGroupsList>(SUPPORT_MY_GROUP_LIST);

export const SUPPORT_JOIN_GROUP = 'SUPPORT_JOIN_GROUP';
export const supportJoinGroup = createAction<postShareSagaTypes.IJoinGroup>(SUPPORT_JOIN_GROUP);
