import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { myProfileReducerTypes, myProfileSagaTypes } from 'types';

const initialState: myProfileReducerTypes.IChangePasswordReducerTypes = {
   message: '',
   error: '',
   is_enable_logout: false,
};
export const changePasswordSlice = createSlice({
   name: 'changePassword',
   initialState: initialState,
   reducers: {
      changePasswordRequest: (state, action: PayloadAction<myProfileSagaTypes.IChangePassword>) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      changePasswordSuccess: (state, action: PayloadAction<myProfileReducerTypes.IChangePasswordSuccessPayload>) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      changePasswordFailed: (state, action: PayloadAction<myProfileReducerTypes.IChangePasswordFailurePayload>) => {
         return {
            ...state,
            error: action.payload.error,
         };
      },
      changePasswordReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
