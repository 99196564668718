import React from 'react';

import OncoPowerIcon from 'assets/fill-profile/onco.png';

export default function HomeSection(props: any) {
   const handleSubmit = () => {
      if (!props.isDisplay) {
         props.navigation(props.nexSection);
      } else {
         props.navigation(props.prevSection);
      }
   };

   return (
      <>
         <div className="col-12">
            <img className="onco-icon" src={OncoPowerIcon} alt="icon" />
         </div>
         <div>
            <p className="paragragh-text p-2">
               Team OncoPower is here to support you and your medical team in managing your care.
            </p>
         </div>
         <div className="button">
            <button type="button" className="button-start" onClick={handleSubmit}>
               Start
            </button>
         </div>
      </>
   );
}
