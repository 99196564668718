import { createAction } from '@reduxjs/toolkit';
import { userInviteEmailPageTypes } from 'types';

export const USER_INVITE_EMAIL = 'USER_INVITE_EMAIL';
export const userInviteEmail = createAction<userInviteEmailPageTypes.IInviteUserEmail>(USER_INVITE_EMAIL);

export const USER_DETAILS = 'USER_DETAILS';
export const userDetails = createAction<userInviteEmailPageTypes.IUserDetails>(USER_DETAILS);

export const USER_INVITE_EMAIL_VALIDATION = 'USER_INVITE_EMAIL_VALIDATION';
export const userInviteEmailValidation = createAction<userInviteEmailPageTypes.IInviteUserEmail>(
   USER_INVITE_EMAIL_VALIDATION
);
export const USER_ADD_NEW_STATE = 'USER_ADD_NEW_STATE';
export const userAddNewState = createAction<userInviteEmailPageTypes.INewStateAdding>(USER_ADD_NEW_STATE);
