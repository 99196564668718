import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { specialityReducerTypes, specialitySagaTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: specialityReducerTypes.IGetSpecialityReducerPayload = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};

export const getSpecialitiesSlice = createSlice({
   name: 'specialitiesList',
   initialState: initialState,
   reducers: {
      getSpecialitiesRequest: (state, action: PayloadAction<specialitySagaTypes.IGetSpecialitySagaPayload>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            data: state.data,
         };
      },
      getSpecialitiesSuccess: (state, action: PayloadAction<specialityReducerTypes.IGetSpecialitySagaSuccess>) => {
         const { payload } = action;
         return {
            ...state,
            data: payload.data,
            message: payload.message || messages.success,
            isLoading: false,
         };
      },
      getSpecialitiesFailed: (state, action: PayloadAction<specialityReducerTypes.IGetSpecialityFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      getSpecialitiesReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
