import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { toastTypes } from 'types';
import { CommonConstants } from 'utils/constants/common';

const initialState: toastTypes.ITriggerToast = {
   messageType: '',
   message: '',

   pauseOnHover: true,
   pauseOnFocusLoss: true,
   closeOnClick: true,
   autoClose: CommonConstants.Toast.TOAST_TIMER_ALERT,
   position: toast.POSITION.BOTTOM_RIGHT,
   closeButton: true,
   progressClassName: undefined,
   progressStyle: undefined,
   bodyClassName: undefined,
   bodyStyle: undefined,
   hideProgressBar: false,
   transition: undefined,
   draggable: true,
   draggablePercent: 80,
   draggableDirection: 'x',
   role: 'alert',
   onClick: undefined,
   rtl: false,
   icon: true,
   theme: 'light',
};

export const toastControllerSlice = createSlice({
   name: 'toastController',
   initialState: initialState,
   reducers: {
      triggerToastAction: (state, action: PayloadAction<toastTypes.ITriggerToast>) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      toastControllerReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
