export const SUBSCRIPTION_ONE_MONTH_DAYS_LIMIT = 30;
export const COUPON_CHECK_END_DATE_FORMAT = 'MMMM D, YYYY';
export const NOTIFICATION_DATE_FORMAT = 'YYYY-MM-DD';

export const TIME_SINCE_A_YEAR = 31536000;
export const TIME_SINCE_A_DAY = 86400;
export const TIME_SINCE_A_HOUR = 3600;
export const TIME_SINCE_A_MIN = 60;

export const TIME_ONLY_ADD_DATE = '2016-02-02 ';
export const DIAGNOSIS_DATE_AND_TIME = 'YYYY-MM-DDTHH:mm:ss';
export const CONVERT_DATE_AND_TIME = 'MM/DD/YYYY hh:mm A ';
