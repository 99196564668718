import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINIC_STAFF_LIST } from 'reduxToolKit/actions';
import { clinicStaffSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   clinicStaffLoading,
   clinicStaffViewRequest,
   clinicStaffViewSuccess,
   clinicStaffFailed,
} = clinicStaffSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const clinicStaffSaga = function* (cancerTypePayload: any) {
   yield put(clinicStaffLoading());
   yield put(clinicStaffViewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.clinicStaffList });
      if (response.status === 200) {
         const responseData = response?.data;
         const oncDriveList = {
            list: responseData?.data?.list,
            message: response?.data?.message,
         };
         yield put(clinicStaffViewSuccess(oncDriveList));
      } else {
         yield put(clinicStaffFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeClinicStaff = function* () {
   yield takeEvery(CLINIC_STAFF_LIST, clinicStaffSaga);
};
