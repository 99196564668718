import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { FEED_POST_REACTIONS } from 'reduxToolKit/actions';
import { askADocFeedReactionSlice, commonSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   askADocFeedReactionsRequest,
   askADocFeedReactionsSuccess,
   askADocFeedReactionsFailed,
} = askADocFeedReactionSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const askADocFeedReactions = function* (payloadState: PayloadAction<feedSagaTypes.IFeedReactions>) {
   yield put(askADocFeedReactionsRequest(payloadState.payload));
   try {
      const peopleMayYouKnow = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ peopleMayYouKnow, data, ...API.askADocFeedPostReactions });
      if (response.status === 200) {
         const resObj = {
            message: response?.data?.message,
            data: null,
         };
         yield put(askADocFeedReactionsSuccess(resObj));
      } else {
         yield put(askADocFeedReactionsFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAskADocFeedReactions = function* () {
   yield takeEvery(FEED_POST_REACTIONS, askADocFeedReactions);
};
