import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_DIAGNOSIS_DATE } from 'reduxToolKit/actions';
import { commonSlice, diagnosisSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { diagnosisRequest, diagnosisSuccess, diagnosisFailed } = diagnosisSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const diagnosisSaga = function* (diagnosisPayload: any) {
   yield put(diagnosisRequest(diagnosisPayload.payload));
   try {
      const checkUser = false;
      const data = diagnosisPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...API.diagnosisAddDate });
      if (response.status === 200) {
         const responseData = response.data;
         const diagnosisData = {
            message: responseData?.message || '',
         };
         yield put(diagnosisSuccess(diagnosisData));
      } else {
         yield put(diagnosisFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeDiagnosis = function* () {
   yield takeEvery(ADD_DIAGNOSIS_DATE, diagnosisSaga);
};
