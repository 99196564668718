import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { PEOPLE_NEAR_ME_LIST_SAGA } from 'reduxToolKit/actions';
import { commonSlice, peopleNearMeListSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   peopleNearMeListLoading,
   peopleNearMeListViewRequest,
   peopleNearMeListViewSuccess,
   peopleNearMeListFailed,
} = peopleNearMeListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const peopleNearMeListSaga = function* (peopleNearMePayload: any) {
   yield put(peopleNearMeListLoading());
   yield put(peopleNearMeListViewRequest(peopleNearMePayload.payload));
   try {
      const params = peopleNearMePayload.payload;
      const url = API.peopleNearMe;
      const peopleMayYouKnow = true;
      const response: AxiosResponse = yield apiCall({ peopleMayYouKnow, params, ...url });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message,
         };
         yield put(peopleNearMeListViewSuccess(oncDriveList));
      } else {
         yield put(peopleNearMeListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePeopleNearMeListRequest = function* () {
   yield takeEvery(PEOPLE_NEAR_ME_LIST_SAGA, peopleNearMeListSaga);
};
