import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { AUTH_FORGOT_PASSWORD } from 'reduxToolKit/actions';
import { forgetPasswordSlice, commonSlice } from 'reduxToolKit/reducers';
import { authSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { forgetPasswordRequest, forgetPasswordSuccess, forgetPasswordFailed } = forgetPasswordSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const forgetPasswordSaga = function* (payloadState: PayloadAction<authSagaTypes.IForgetPasswordSagaPayload>) {
   yield put(forgetPasswordRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.forgotPassword });
      if (response.status === 200) {
         const resObj = {
            message: response?.data?.message || messages.success,
            verificationType: response?.data?.data?.verification_mode,
         };
         yield put(forgetPasswordSuccess(resObj));
      } else {
         yield put(forgetPasswordFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeForgotPassword = function* () {
   yield takeLatest(AUTH_FORGOT_PASSWORD, forgetPasswordSaga);
};
