import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { leaveClinicTypes } from 'types';

const initialState: leaveClinicTypes.ILeaveClinicReducer = {
   isLoading: false,
   error: '',
   message: '',
};

//both patient and physician
export const leaveClinicSlice = createSlice({
   name: 'leaveClinic',
   initialState: initialState,
   reducers: {
      leaveClinicRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      leaveClinicSuccess: (state, action: PayloadAction<leaveClinicTypes.ILeaveClinicSuccess>) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      leaveClinicFailed: (state, action: PayloadAction<leaveClinicTypes.ILeaveClinicFailed>) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            message: '',
            error: payload.error,
         };
      },
      leaveClinicReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
