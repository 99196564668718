//default values

export default function baseEnv() {
   return {
      isKovidPower: false,
      baseUrl: 'https://dev-api-v2.oncopower.org',
      baseUrlNew: 'https://dev-api-v3.oncopower.org',
      imageBaseUrl: 'https://cdn-dev.oncopower.org/',
   };
}

export type Environment = ReturnType<typeof baseEnv>;
