import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { PHYSICIAN_NPI_NUMBER_VERIFICATION, ADD_PHYSICIAN_NPI_NUMBER } from 'reduxToolKit/actions';
import { phyNpiNumberVerifySlice, commonSlice } from 'reduxToolKit/reducers';
import { phyNpiNumberSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   phyNpiNumberVerifyRequest,
   phyNpiNumberVerifySuccess,
   phyNpiNumberVerifyFailed,
   addNpiNumberRequest,
   addNpiNumberSuccess,
   addNpiNumberFailed,
} = phyNpiNumberVerifySlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const npiNumberVerifySaga = function* () {
   yield put(phyNpiNumberVerifyRequest());
   try {
      const checkUser = true;
      const goUrl = true;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, ...API.phyNpiNumberVerify });
      if (response.status === 200) {
         const resObj = {
            status: response?.data?.data?.status,
            message: messages.success,
         };
         yield put(phyNpiNumberVerifySuccess(resObj));
      } else {
         yield put(phyNpiNumberVerifyFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
const addNpiNumberSaga = function* (payloadState: PayloadAction<phyNpiNumberSagaTypes.IAddNpiNumberSagaPayload>) {
   yield put(addNpiNumberRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.phyAddNpiNumber });
      if (response.status === 200) {
         const resObj = {
            message: response?.data?.message || messages.success,
         };
         yield put(addNpiNumberSuccess(resObj));
      } else {
         yield put(addNpiNumberFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePhyNpiNumberVerify = function* () {
   yield takeLatest(PHYSICIAN_NPI_NUMBER_VERIFICATION, npiNumberVerifySaga);
};
export const takeAddPhyNpiNumber = function* () {
   yield takeLatest(ADD_PHYSICIAN_NPI_NUMBER, addNpiNumberSaga);
};
