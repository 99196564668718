import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINICAL_TRIALS_STUDY_TYPE } from 'reduxToolKit/actions';
import { clinicalTrialsStudyTypeSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   clinicalTrialsStudyTypeRequest,
   clinicalTrialsStudyTypeSuccess,
   clinicalTrialsStudyTypeFailed,
} = clinicalTrialsStudyTypeSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const clinicalTrialsStudyTypeSaga = function* (clinicalTrialsStudyTypePayload: any) {
   yield put(clinicalTrialsStudyTypeRequest(clinicalTrialsStudyTypePayload.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const params = clinicalTrialsStudyTypePayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         params,
         peopleMayYouKnow,
         ...API.clinicalTrialsStudyType,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const clinicalTrialsStudyTypeList = {
            list: responseData.data.data,
            message: response.data?.message,
         };
         yield put(clinicalTrialsStudyTypeSuccess(clinicalTrialsStudyTypeList));
      } else {
         yield put(clinicalTrialsStudyTypeFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeClinicalTrialsStudyTypeRequest = function* () {
   yield takeEvery(CLINICAL_TRIALS_STUDY_TYPE, clinicalTrialsStudyTypeSaga);
};
