import { createAction } from '@reduxjs/toolkit';
import { editPrescriptionSagaTypes } from 'types';

export const GET_MEDICATION = 'GET_MEDICATION';
export const getMedicationAction = createAction<editPrescriptionSagaTypes.IGetMedications>(GET_MEDICATION);

export const EDIT_PRESCRIPTION = 'EDIT_PRESCRIPTION';
export const editPrescriptionAction = createAction<editPrescriptionSagaTypes.IRpmPrescriptionEdit>(EDIT_PRESCRIPTION);

export const GET_TREATING_PHYSICIAN = 'GET_TREATING_PHYSICIAN';
export const getTreatingPhysicianAction = createAction<editPrescriptionSagaTypes.IGetTreatingPhysician>(
   GET_TREATING_PHYSICIAN
);

export const GET_ONE_PRESCRIPTION = 'GET_ONE_PRESCRIPTION';
export const getOnePrescriptionAction = createAction<editPrescriptionSagaTypes.IGetOnePrescription>(
   GET_ONE_PRESCRIPTION
);

export const GET_LINES_OF_THERAPY_HISTORY = 'GET_LINES_OF_THERAPY_HISTORY';
export const getLinesOfTherapyHistoryAction = createAction<editPrescriptionSagaTypes.IGetLinesOfTherapyHistory>(
   GET_LINES_OF_THERAPY_HISTORY
);
