import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MODAL_UPDATE_MEDICATION_SAGA } from 'reduxToolKit/actions';
import { commonSlice, modalUpdateMedicationSlice } from 'reduxToolKit/reducers';
import { modalMedicationSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   modalUpdateMedicationRequest,
   modalUpdateMedicationSuccess,
   modalUpdateMedicationFailed,
} = modalUpdateMedicationSlice.actions;

const { exceptionHandlerRequest } = commonSlice.actions;

const modalUpdateMedicationSaga = function* (
   medicationUpdatePayload: PayloadAction<modalMedicationSagaTypes.IModalUpdateMedicationSagaPayload>
) {
   yield put(modalUpdateMedicationRequest(medicationUpdatePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = medicationUpdatePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.updateMedications });
      if (response.status === 200) {
         const responseData = response.data;
         const medicationUpdateList = {
            message: responseData?.message || '',
         };
         yield put(modalUpdateMedicationSuccess(medicationUpdateList));
      } else {
         yield put(modalUpdateMedicationFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeModalMedicationRequest = function* () {
   yield takeEvery(MODAL_UPDATE_MEDICATION_SAGA, modalUpdateMedicationSaga);
};
