import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { feedReducerTypes } from 'types';
import { feedSagaTypes } from 'types/feed';
import { messages } from 'utils/constants/messages';

const initialState: feedReducerTypes.IFeedReactionsAddReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const feedShareLinkSlice = createSlice({
   name: 'feedShareLink',
   initialState: initialState,
   reducers: {
      feedShareLinkRequest: (state, action: PayloadAction<feedSagaTypes.IFeedPostUserLinkParams>) => {
         return {
            ...state,
            ...action.payload.data,
            ...action.payload.tempData,
            isLoading: true,
         };
      },
      feedShareLinkSuccess: (state, action: PayloadAction<feedReducerTypes.IFeedReactionsAddReducer>) => {
         return {
            ...state,
            isLoading: false,
            data: action.payload?.data,
            message: action.payload?.message || messages.success,
         };
      },
      feedShareLinkFailed: (state, action: PayloadAction<feedReducerTypes.IFeedReactionsAddReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error || messages.failed,
         };
      },
      feedShareLinkReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
export { feedShareLinkSlice };
