import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_PRIMARY_DISEASE } from 'reduxToolKit/actions';
import { commonSlice, getPrimaryDiseaseSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getPrimaryDiseaseRequest, getPrimaryDiseaseSuccess, getPrimaryDiseaseFailed } = getPrimaryDiseaseSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getPrimaryDiseaseSaga = function* (primaryDiseaseTypePayload: any) {
   yield put(getPrimaryDiseaseRequest(primaryDiseaseTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = primaryDiseaseTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getPrimaryDiseases });
      if (response.status === 200) {
         const responseData = response.data;
         const primaryDiseaseList = {
            list: responseData?.data?.list || [],
            total_records: responseData?.data?.total_records || 0,
            message: response?.data?.message || '',
         };
         yield put(getPrimaryDiseaseSuccess(primaryDiseaseList));
      } else {
         yield put(getPrimaryDiseaseFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePrimaryDiseaseRequest = function* () {
   yield takeEvery(GET_PRIMARY_DISEASE, getPrimaryDiseaseSaga);
};
