import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   ASK_A_DOC_LIST,
   ASK_A_DOC_EXAMPLE_QUESTIONS,
   ASK_A_DOC_PRE_POPULATE_CREATE_REQUEST,
} from 'reduxToolKit/actions';
import {
   askADocListSlice,
   askADocExampleQusListSlice,
   commonSlice,
   askADocPrePopulateCreateRequestDataSlice,
} from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   askADocListLoading,
   askADocListViewRequest,
   askADocListViewSuccess,
   askADocListFailed,
} = askADocListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const askADocListSaga = function* (cancerTypePayload: any) {
   yield put(askADocListLoading());
   yield put(askADocListViewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.askADocList });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            list: responseData.data?.list,
            total_records: responseData.data?.total_records,
            message: responseData.data?.message,
         };
         yield put(askADocListViewSuccess(oncDriveList));
      } else {
         yield put(askADocListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   askADocExampleQusListRequest,
   askADocExampleQusListSuccess,
   askADocExampleQusListFailed,
} = askADocExampleQusListSlice.actions;

const askADocExampleQusListSaga = function* (cancerTypePayload: any) {
   yield put(askADocExampleQusListRequest(cancerTypePayload.payload));
   try {
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ checkUser, ...API.askADocExampleQuesList });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(askADocExampleQusListSuccess(responseData));
      } else {
         yield put(askADocExampleQusListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   askADocPrePopulateCreateRequestDataRequest,
   askADocPrePopulateCreateRequestDataSuccess,
   askADocPrePopulateCreateRequestDataFailed,
} = askADocPrePopulateCreateRequestDataSlice.actions;

const askADocPrePopulateCreateRequestDataSaga = function* () {
   yield put(askADocPrePopulateCreateRequestDataRequest());
   try {
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ checkUser, ...API.askADocPrePopulateCreateRequestData });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(askADocPrePopulateCreateRequestDataSuccess(responseData));
      } else {
         yield put(askADocPrePopulateCreateRequestDataFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAskADocListRequest = function* () {
   yield takeEvery(ASK_A_DOC_LIST, askADocListSaga);
   yield takeEvery(ASK_A_DOC_EXAMPLE_QUESTIONS, askADocExampleQusListSaga);
   yield takeEvery(ASK_A_DOC_PRE_POPULATE_CREATE_REQUEST, askADocPrePopulateCreateRequestDataSaga);
};
