import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { SYMPTOM_CONSENT_LIST } from 'reduxToolKit/actions';
import { commonSlice, symptomConsentListSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   symptomConsentLoading,
   symptomConsentRequest,
   symptomConsentSuccess,
   symptomConsentFailed,
} = symptomConsentListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const symptomConsentListSaga = function* (consentTypePayload: any) {
   yield put(symptomConsentLoading());
   yield put(symptomConsentRequest(consentTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = consentTypePayload.payload;
      const url = {
         apiPath: `${API.getOneSymptomConsent.apiPath}${params.clinicID}${'/'}${params.patientId}`,
         action: API.getOneSymptomConsent.action,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...url });
      if (response.status === 200) {
         const responseData = response.data;
         const consentList = {
            list: responseData.data,
         };
         yield put(symptomConsentSuccess(consentList));
      } else {
         yield put(symptomConsentFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeSymptomConsentList = function* () {
   yield takeEvery(SYMPTOM_CONSENT_LIST, symptomConsentListSaga);
};
