import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { PRACTISE_ADMIN_LOCUM_PHY_BOOK } from 'reduxToolKit/actions';
import { commonSlice, practiseAdminLocumPhySearchSlice } from 'reduxToolKit/reducers';
import { locumSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   practiseAdminLocumPhySearchLoading,
   practiseAdminLocumPhySearchRequest,
   practiseAdminLocumPhySearchSuccess,
   practiseAdminLocumPhySearchFailed,
} = practiseAdminLocumPhySearchSlice.actions;

const { exceptionHandlerRequest } = commonSlice.actions;

const practiseAdminLocumPhySearch = function* (
   payloadState: PayloadAction<locumSagaTypes.IBookPhysicianListSagaParams>
) {
   if (payloadState.payload.search_word !== '') {
      yield put(practiseAdminLocumPhySearchLoading());
      yield delay(500);
   }
   yield put(practiseAdminLocumPhySearchRequest(payloadState.payload));
   try {
      const url = API.locumPractiseAdminPhySearch;
      const checkUser = false;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...url });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(practiseAdminLocumPhySearchSuccess(resObj));
      } else {
         yield put(practiseAdminLocumPhySearchFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePractiseAdminLocumPhySearch = function* () {
   yield takeLatest(PRACTISE_ADMIN_LOCUM_PHY_BOOK, practiseAdminLocumPhySearch);
};
