import { all, fork } from 'redux-saga/effects';

import {
   takeAcceptConnection,
   takeAcceptJoinClinic,
   takeAcceptJoinClinicPat,
   takeAddAuxiliaryStaff,
   takeAddCaregiverRequest,
   takeAddCheckLogRequest,
   takeAddClinicAdmin,
   takeAddClinicStaff,
   takeAddClinicVideos,
   takeAddEditAppointmentCommentRequest,
   takeAddendum,
   takeAddendumUpdate,
   takeAddFamilyMember,
   takeAddMyServicesSpecialties,
   takeAddOncDriveRequest,
   takeAddPatients,
   takeAddScheduleRequest,
   takeaddSymptom,
   takeAllMyDeleteConnectionRequest,
   takeAllUsersListRequest,
   takeOncoMeditationRequest,
   takeSaveUnsaveAPostSaga,
   takeFeedPostListRequest,
   takeAppointmentScheduleRequest,
   takeAskADocCaseDetailsRequest,
   takeAskADocCreateRequest72Hrs,
   takeAskADocEstimateRequest,
   takeAskADocFeedReactions,
   takeAskADocHippaCompliance,
   takeAskADocListRequest,
   takeAskADocViewAnswersRequest,
   takeAskPhyCount,
   takeAskPhyList,
   takeAskSingleFeedList,
   takeAssignClinicVideo,
   takeAssignedVideo,
   takeAssignVideo,
   takeAssociatedPhysicianRequest,
   takeBookService,
   takeCancerListRequest,
   takeCancerSpecific,
   takeCaregiverDeleteRequest,
   takeCaregiverRequest,
   takeCertificateUpdateRequest,
   takeCheckAppointmentRequest,
   takeCheckScheduleRequest,
   takeClinicAdmin,
   takeClinicAdminInvites,
   takeClinicAdmins,
   takeClinicalNotesAddRequest,
   takeClinicalNotesListRequest,
   takeClinicAuxiliary,
   takeClinicGroupsRequest,
   takeclinicInvitePatientReq,
   takeClinicInvitePatients,
   takeClinicPatients,
   takeClinicPhysicianInvite,
   takeClinicPhysicianList,
   takeClinicStaff,
   takeClinicVideos,
   takeCommentAddUpdateRequest,
   takeCommentReplyAddRequest,
   takeCommentReplyDeleteRequest,
   takeCommentReplyUpdateRequest,
   takeCommentsReactions,
   takecommentsReactionsListRequest,
   takeConnectionRequest,
   takeConsentList,
   takeConvoListRequest,
   takeConversationsActions,
   takeConversationAddGetActions,
   takeCustomNotificationAddRequest,
   takeCustomNotificationRequest,
   takeDailyCheckLogListRequest,
   takeDailyCheckInsRequest,
   takedeleteCertificateRequest,
   takeDeleteClinicAdmin,
   takedeleteClinicalTrial,
   takeDeleteClinicPhysicians,
   takeDeleteClinicStaff,
   takeDeleteClinicVideos,
   takeDeleteConnectionInvite,
   takeDeleteConnectionRequest,
   takeDeleteEducationRequest,
   takedeleteExperience,
   takeDeleteFamilyMember,
   takeDeleteFolderAndFilesOncDriveRequest,
   takeDeletePostCommentRequest,
   takeDescriptionUpdateRequest,
   takeDeviceClinicNoteRequest,
   takeDeviceNotesCommentsSaga,
   takeDisclaimerSaga,
   takeDocumentListRequest,
   takeEditOncDriveRequest,
   takeEducationVideos,
   takeFeedConnectionStatistics,
   takeFeedReactionsAddRequest,
   takeFeedPollOptionRequest,
   takeFeedPollUsersListRequest,
   takegetAllConnections,
   takeGetAppointmentCommentRequest,
   takeGetSchedulePhysiciansList,
   takeGetCancersRequest,
   takegetClinicPhysician,
   takeGetClinicsRequest,
   takeGetCommentsList,
   takegetDiseases,
   takeGetDrugs,
   takeGetFamilyMembers,
   takegetFamilyMemberTypes,
   takegetLineOfTherapyHistory,
   takeGetOneDrugs,
   takeGetPatientStateSaga,
   takeGetPhysicianStateSaga,
   takeGetReplyCommentsList,
   takeGetS3SignedURLSaga,
   takeGetScheduleRequest,
   takeGetSkillsRequest,
   takeGetSocialHistoriesRequest,
   takeGetUserProfile,
   takeHealthRecordAddRequest,
   takeHealthRecordPhysicianRequest,
   takeinviteClinicPhysicians,
   takeInviteListRequest,
   takeInviteUserEmailRequest,
   takeInviteViaMailRequest,
   takeJoinAppointmentCallRequest,
   takeJoinClinicRequest,
   takejoinGroup,
   takeLatestClinic,
   takeleaveClinicRequest,
   takeListMedicineRequest,
   takeLocumGetOneServiceListRequest,
   takeLocumGetStateAndTravelListRequest,
   takeLocumMonthWiseServiceListRequest,
   takeLocumServiceAddUpdateRequest,
   takeLocumServiceDeleteRequest,
   takeMedicalEnqAddRequest,
   takeMedicalEnqAddUpdateCommentRequest,
   takeMedicalEnqCommentsListRequest,
   takeMedicalEnqDocumentRequest,
   takeMedicalEnqGetOneRequest,
   takeMedicalEnqMyClinicPhysListRequest,
   takeMedicalEnqMyClinicPhysSearchRequest,
   takeMedicalEnquiresListRequest,
   takeMedicalEnquiresListSearchRequest,
   takeMedicalEnqUpdateRequest,
   takeMedicationList,
   takeMedicationRequest,
   takeModalMedicationRequest,
   takemodalUpdateFamilyHistories,
   takeMyClinicPatientsRequest,
   takeMyClinicPhyCancelReq,
   takeMyClinicPhyReq,
   takeMyClinicPhysician,
   takeMyPatientsRequest,
   takeMyServiceListRequest,
   takeMyServicePhyListRequest,
   takeMyServiceSpecialityListRequest,
   takeMyTreatingPhysician,
   takeNewStateRequest,
   takeNotificationAllRead,
   takeNotificationCount,
   takeNotificationList,
   takeNotificationRead,
   takeNotificationSettingsList,
   takeUpdateNotificationSettings,
   takeNotificationDeleteDeviceToken,
   takeNotificationGetOne,
   takeOncDriveListRequest,
   takeOncDriveMyClinicPatientListRequest,
   takeOncDriveMyClinicPatientSearchRequest,
   takeOnePrescriptionRequest,
   takePanelsListRequest,
   takePanelsPhyListRequest,
   takePanelsReq,
   takeCreateAskADocRequest,
   takePatientAdvocatesRequest,
   takePatientMedicationRequest,
   takePaypalAndZelleUpdate,
   takePendingListRequest,
   takePeopleNearMeListRequest,
   takePhysicianClinic,
   takePhysicianDetails,
   takePhysicianInfo,
   takePhysicianListRequest,
   takePhysicianReward,
   takePhysicianScheduleRequest,
   takePillRemainderAddRequest,
   takePillRemainderDeleteRequest,
   takePillRemainderEditRequest,
   takePillRemainderGetOneRequest,
   takePillRemainderListRequest,
   takePillRemainderMediUpdateRequest,
   takePillRemainderNotificationGetOneRequest,
   takePillRemainderNotificationStatusAddRequest,
   takePillRemainderNotificationStatusEditRequest,
   takePillRemainderAllPillsListRequest,
   takePractiseAdminLocumList,
   takePractiseAdminLocumPhySearch,
   takePrescriptionEditRequest,
   takePrimaryDiseaseRequest,
   takeProfileAddressSection,
   takeProfileCompletionRequest,
   takeProfileDobSection,
   takeProfileOtpSection,
   takeProfilePhoneSection,
   takeProfileResendOtpSection,
   takeFillProfileSubSpeciality,
   takePublicationManage,
   takePublicPostRequest,
   takeRateWriteSaga,
   takeReportableSymptomList,
   takeReportPost,
   takeRPMDashboardRequest,
   takeRPMPrescriptionRequest,
   takeSecondaryCancerListRequest,
   takeSettingAddStateAndTravelRequest,
   takeSettingGetStateAndTravelRequest,
   takeShareOncDriveRequest,
   takeShowPatientFeed,
   takeSingleFeedList,
   takeSummary,
   takeSummaryUpdate,
   takeSupportGroups,
   takeSupportMyGroups,
   takeSymptomConsentList,
   takeSymptomList,
   takeSymptomMonitoringCommentSaga,
   takeTagUsersList,
   takeTopCancer,
   takeTopState,
   takeTreatingPhysicianRequest,
   takeUpdateAppointmentRequest,
   takeupdateCaregiverRequest,
   takeUpdateClinicAdminInviteStatus,
   takeUpdateClinicTrialRequest,
   takeUpdateCoMorbidities,
   takeUpdateEducationTraining,
   takeUpdateExperienceRequest,
   takeupdateFamilyMember,
   takeUpdateMyService,
   takeUpdatePatientInsurance,
   takeUpdateSocialHistories,
   takeUserDetailsRequest,
   takeUserProfileUpdateSaga,
   takeUserStateSaga,
   takeVerifyOtpSaga,
   takeVideoCallConnectionRequest,
   takeVideoCallUpdateRequest,
   takeVideoLibraryCategoryListRequest,
   takeVideoLibraryListRequest,
   takeViewAppointmentDocumentRequest,
   takeViewAppointmentRequest,
   takeViewOncDriveRequest,
   takeWallet,
   takeWalletOffers,
   takeWalletPhysician,
   takeWorkingHours,
   takeAdminJobApplicantsListSaga,
   takeAcceptRejectShortPhysicianSaga,
   // Job recruiter
   takeRecruiterJobAdmin,
   takePhysicianJobsSaga,
   takeGetSpecialities,
   // google login
   takeGoogleLoginAddRequest,
   // linked in
   takeLinkedInLoginAddRequest,

   //support group
   takeSupportGroupInfo,
   takeLeaveSupportGroup,
   takeMembersList,
   takeSecondOpinionShortListAddNDelete,
   takeSupportGroup,

   //notification add device tokens
   takeNotificationAddDeviceToken,
   // home rpm interval remainder
   takeRpmIntervalRemainder,
   // global search
   takeGlobalSearchListRequest,
   // recent search
   takeRecentSearch,
   //admin notification
   takeAdminNotification,
   //profile
   takeProfilePictureSaga,
   takeChangePassword,
   takeGetBlockUnblockUserRequest,
   // user login
   takeUserRegisterRequest,
   takeForgotPassword,
   takeSetPassword,
   takeEmailVerification,
   //clinic admin
   takeExistingClinicInviteSaga,
   takeClinicWorkingHoursSaga,
   takeClinicInfoEditRequest,
   takeClinicPhyInviteIsExistRequest,
   takeClinicDeleteAuxiliaryRequest,
   takeClinicPatientBulkImportRequest,
   //npi number
   takePhyNpiNumberVerify,
   takeAddPhyNpiNumber,
   //network
   takeSearchNetworkAllUsers,

   // Subscription Details
   takecheckPlanActive,
   takeGetSubscriptionPlansList,
   takeAskDocPanelPhyPanelRequest,
   takeAskDocPhyPanelList,
   takeAskADocCaseDetailsCheckRequiredFields,
   takeSubscriptionCheckCouponCode,
   //feed
   takeCreateAndEditPost,
   takeDeletePost,
   takeFeedMeditation,
   //vdocipher
   takeVdocipher,
   takeVdocipherQuestions,

   // device
   takeDevice,
   takeGetBloodPressureSaga,
   takeUser,
   takeClinicRpmAllDevicesSaga,
   takeSmartWeightSagaSaga,

   //second opinion
   takeSecondOpinionPhysicianList,
   takeSecondOpinionShortListPhysician,
   takeSecondOpinionNearbyPhysicianList,
   takeoncKlinicUpCommingMeetingSaga,

   // Symptom
   takeCheckClinicTimingSaga,
   takeOncKlinicSymptomMonitoringSaga,
   // event track
   takeEventTrack,
   // treatment method
   takeTreatmentMethod,
   // feed share link
   takeFeedPostShareLinkRequest,
   takeHideThisPostSaga,
   // announcement
   takeAnnouncement,
   // clinical trials
   takeClinicalTrialsRequest,
   takeClinicalTrialsStudyTypeRequest,
   takeClinicalTrialsStudyPhaseRequest,
   takeClinicalTrialsStatesRequest,
   takeClinicalTrialsCancerSpecificRequest,
   // hash tag
   takeHashTagList,
   // community guidelines
   takeCommunityGuidelinesSaga,
   //medicaiton saving
   takeGetMedicationSaving,
   takeDiagnosis,
   takeLatino,
   takeStaging,
   takeRace,
   takeCancerStatus,
   takeEcog,
   takeMedicalHistory,
   takeGeneticTesting,
   takeServiceOffered,
   takePopulationServed,
   takeOrgName,
   takeOrgUserEduLib,
} from './sagas';
import { takeFillProfile } from './sagas/myProfile/fillProfileSaga';

const rootSaga = function* () {
   yield all([
      // Rpm Prescription
      fork(takegetClinicPhysician),
      fork(takeRpmIntervalRemainder),

      // AskADoc
      fork(takeAddendum),
      fork(takeAddendumUpdate),
      fork(takeAskADocCaseDetailsRequest),
      fork(takeAskADocCreateRequest72Hrs),
      fork(takeDisclaimerSaga),
      fork(takeAskADocFeedReactions),
      fork(takeAskADocHippaCompliance),
      fork(takeAskADocListRequest),
      fork(takeAskADocViewAnswersRequest),
      fork(takeAskPhyCount),
      fork(takeAskPhyList),
      fork(takeFeedConnectionStatistics),
      fork(takeAllUsersListRequest),
      fork(takeGetPatientStateSaga),
      fork(takeGetPhysicianStateSaga),
      fork(takeGetUserProfile),
      fork(takePanelsReq),
      fork(takeCreateAskADocRequest),
      fork(takePanelsListRequest),
      fork(takePanelsPhyListRequest),
      fork(takeProfileCompletionRequest),
      fork(takePublicPostRequest),
      fork(takeSummary),
      fork(takeSummaryUpdate),
      fork(takeAskDocPanelPhyPanelRequest),
      fork(takeAskDocPhyPanelList),
      fork(takeAskADocCaseDetailsCheckRequiredFields),

      // onco meditation
      fork(takeOncoMeditationRequest),

      // Chat
      fork(takeConvoListRequest),
      fork(takeConversationsActions),
      fork(takeConversationAddGetActions),

      // Clinical Notes
      fork(takeClinicalNotesAddRequest),
      fork(takeClinicalNotesListRequest),

      // Custom Notifincation
      fork(takeCustomNotificationAddRequest),
      fork(takeCustomNotificationRequest),

      // Daily Check Log
      fork(takeAddCheckLogRequest),
      fork(takeDailyCheckLogListRequest),
      fork(takeDailyCheckInsRequest),

      // Drug Lookup
      fork(takeCancerSpecific),
      fork(takeGetDrugs),
      fork(takeGetOneDrugs),

      // Edit Prescription
      fork(takePrescriptionEditRequest),
      fork(takegetLineOfTherapyHistory),
      fork(takeMedicationRequest),
      fork(takeOnePrescriptionRequest),
      fork(takeTreatingPhysicianRequest),

      // Educational Video
      fork(takeAssignVideo),
      fork(takeAssignedVideo),
      fork(takeAssignClinicVideo),

      // Feed
      fork(takeCommentAddUpdateRequest),
      fork(takeCommentReplyAddRequest),
      fork(takeCommentReplyUpdateRequest),
      fork(takeCommentReplyDeleteRequest),
      fork(takeGetReplyCommentsList),
      fork(takeCommentsReactions),
      fork(takecommentsReactionsListRequest),
      fork(takeDeletePostCommentRequest),
      fork(takeFeedReactionsAddRequest),
      fork(takeFeedPollOptionRequest),
      fork(takeFeedPollUsersListRequest),
      fork(takeGetCommentsList),
      fork(takeShowPatientFeed),
      fork(takeAskSingleFeedList),
      fork(takeSingleFeedList),
      fork(takeTagUsersList),
      fork(takeTopCancer),
      fork(takeTopState),
      fork(takeCreateAndEditPost),
      fork(takeDeletePost),
      fork(takeFeedPostListRequest),
      fork(takeFeedMeditation),

      // Fill Profile
      fork(takeProfileAddressSection),
      fork(takeCancerListRequest),
      fork(takeProfileDobSection),
      fork(takeProfileOtpSection),
      fork(takeProfilePhoneSection),
      fork(takeProfileResendOtpSection),
      fork(takeSecondaryCancerListRequest),
      fork(takeFillProfileSubSpeciality),

      // Health Records
      fork(takeHealthRecordAddRequest),
      fork(takeHealthRecordPhysicianRequest),

      // Locum
      fork(takeBookService),
      fork(takeLocumGetOneServiceListRequest),
      fork(takeLocumMonthWiseServiceListRequest),
      fork(takeLocumServiceAddUpdateRequest),
      fork(takeLocumServiceDeleteRequest),
      fork(takePractiseAdminLocumList),
      fork(takePractiseAdminLocumPhySearch),
      fork(takeSettingAddStateAndTravelRequest),
      fork(takeSettingGetStateAndTravelRequest),
      fork(takeLocumGetStateAndTravelListRequest),

      // Medical Enquiry
      fork(takeMedicalEnqAddRequest),
      fork(takeMedicalEnqAddUpdateCommentRequest),
      fork(takeMedicalEnqCommentsListRequest),
      fork(takeMedicalEnqDocumentRequest),
      fork(takeMedicalEnqGetOneRequest),
      fork(takeMedicalEnqMyClinicPhysListRequest),
      fork(takeMedicalEnqMyClinicPhysSearchRequest),
      fork(takeMedicalEnquiresListRequest),
      fork(takeMedicalEnquiresListSearchRequest),
      fork(takeMedicalEnqUpdateRequest),

      // Modal
      fork(takeAddCaregiverRequest),
      fork(takeAddFamilyMember),
      fork(takeAssociatedPhysicianRequest),
      fork(takeGetCancersRequest),
      fork(takeDescriptionUpdateRequest),
      fork(takeDeviceClinicNoteRequest),
      fork(takeModalMedicationRequest),
      fork(takeInviteViaMailRequest),
      fork(takeCertificateUpdateRequest),
      fork(takeUpdateClinicTrialRequest),
      fork(takeUpdateCoMorbidities),
      fork(takeUpdateEducationTraining),
      fork(takeUpdateExperienceRequest),
      fork(takemodalUpdateFamilyHistories),
      fork(takeUpdatePatientInsurance),
      fork(takeRateWriteSaga),
      fork(takeReportPost),
      fork(takeSymptomMonitoringCommentSaga),
      fork(takeUserStateSaga),

      // My Profile
      // -- Care Giver
      fork(takeCaregiverDeleteRequest),
      fork(takeCaregiverRequest),
      fork(takeupdateCaregiverRequest),
      // -- Family Details
      fork(takeDeleteFamilyMember),
      fork(takeGetFamilyMembers),
      fork(takeupdateFamilyMember),
      // -- My Clinic
      fork(takeClinicGroupsRequest),
      fork(takeClinicAdminInvites),
      fork(takeleaveClinicRequest),
      fork(takeUpdateClinicAdminInviteStatus),
      // -- Verify OTP
      fork(takeVerifyOtpSaga),
      // -- Patient Advocate
      fork(takePatientAdvocatesRequest),
      // -- Paypall and Zelle
      fork(takePaypalAndZelleUpdate),
      // -- Physician Details
      fork(takePhysicianDetails),
      // -- Social History
      fork(takeUpdateSocialHistories),
      // -- Others
      fork(takedeleteCertificateRequest),
      fork(takedeleteClinicalTrial),
      fork(takeDeleteEducationRequest),
      fork(takedeleteExperience),
      fork(takegetDiseases),
      fork(takegetFamilyMemberTypes),
      fork(takePrimaryDiseaseRequest),
      fork(takePublicationManage),
      fork(takeGetSkillsRequest),
      fork(takeGetSocialHistoriesRequest),
      fork(takeUserProfileUpdateSaga),
      fork(takeChangePassword),

      // My Service
      fork(takeAddMyServicesSpecialties),
      fork(takeMyServiceListRequest),
      fork(takeMyServicePhyListRequest),
      fork(takeMyServiceSpecialityListRequest),
      fork(takeUpdateMyService),

      // Network
      fork(takeAcceptConnection),
      fork(takeDeleteConnectionInvite),
      fork(takeDeleteConnectionRequest),
      fork(takeConnectionRequest),
      fork(takeAllMyDeleteConnectionRequest),
      fork(takegetAllConnections),
      fork(takeInviteListRequest),
      fork(takejoinGroup),
      fork(takePendingListRequest),
      fork(takeSupportGroups),
      fork(takeSupportMyGroups),
      fork(takeSearchNetworkAllUsers),

      // Notifications
      fork(takeNotificationAllRead),
      fork(takeNotificationCount),
      fork(takeNotificationList),
      fork(takeNotificationRead),
      fork(takeNotificationSettingsList),
      fork(takeUpdateNotificationSettings),
      fork(takeNotificationAddDeviceToken),
      fork(takeNotificationDeleteDeviceToken),
      fork(takeNotificationGetOne),

      // Onc Drive
      fork(takeAddOncDriveRequest),
      fork(takeEditOncDriveRequest),
      fork(takeDeleteFolderAndFilesOncDriveRequest),
      fork(takeOncDriveListRequest),
      fork(takeOncDriveMyClinicPatientListRequest),
      fork(takeOncDriveMyClinicPatientSearchRequest),
      fork(takeShareOncDriveRequest),
      fork(takeViewOncDriveRequest),

      // Onc Klinic
      // -- Device Clinic Notes
      fork(takeDeviceNotesCommentsSaga),
      fork(takeGetClinicsRequest),
      fork(takeAskADocEstimateRequest),
      fork(takePhysicianInfo),
      // -- Practice Admin
      fork(takeAcceptJoinClinic),
      fork(takeAcceptJoinClinicPat),
      fork(takeAddClinicAdmin),
      fork(takeAddAuxiliaryStaff),
      fork(takeAddClinicVideos),
      fork(takeAddClinicStaff),
      fork(takeClinicAdmin),
      fork(takeClinicAuxiliary),
      fork(takeClinicAdmins),
      fork(takeDeleteClinicPhysicians),
      fork(takeClinicInvitePatients),
      fork(takeJoinClinicRequest),
      fork(takeClinicPatients),
      fork(takeclinicInvitePatientReq),
      fork(takeClinicVideos),
      fork(takeDeleteClinicAdmin),
      fork(takeDeleteClinicVideos),
      fork(takeDeleteClinicStaff),
      fork(takePhysicianClinic),
      fork(takeClinicPhysicianList),
      fork(takeClinicPhysicianInvite),
      fork(takeinviteClinicPhysicians),
      fork(takeClinicStaff),
      fork(takeWorkingHours),
      fork(takeExistingClinicInviteSaga),
      fork(takeClinicWorkingHoursSaga),
      fork(takeClinicInfoEditRequest),
      fork(takeClinicPhyInviteIsExistRequest),
      fork(takeClinicDeleteAuxiliaryRequest),
      fork(takeClinicPatientBulkImportRequest),
      fork(takeUser),
      fork(takeoncKlinicUpCommingMeetingSaga),

      // Devices
      fork(takeDevice),
      fork(takeGetBloodPressureSaga),
      fork(takeClinicRpmAllDevicesSaga),
      fork(takeSmartWeightSagaSaga),

      // -- Others
      fork(takeAddPatients),
      fork(takeaddSymptom),
      fork(takeConsentList),
      fork(takeDocumentListRequest),
      fork(takeEducationVideos),
      fork(takeLatestClinic),
      fork(takeMyClinicPatientsRequest),
      fork(takeMyClinicPhyCancelReq),
      fork(takeMyClinicPhyReq),
      fork(takeMyClinicPhysician),
      fork(takeMyPatientsRequest),
      fork(takeMyTreatingPhysician),
      fork(takePatientMedicationRequest),
      fork(takeReportableSymptomList),
      fork(takeRPMDashboardRequest),
      fork(takeRPMPrescriptionRequest),
      fork(takeSymptomConsentList),
      fork(takeSymptomList),

      // People may Near Me
      fork(takePeopleNearMeListRequest),

      // Pill Remainder
      fork(takeMedicationList),
      fork(takePillRemainderAddRequest),
      fork(takePillRemainderDeleteRequest),
      fork(takePillRemainderEditRequest),
      fork(takePillRemainderGetOneRequest),
      fork(takePillRemainderListRequest),
      fork(takePillRemainderMediUpdateRequest),
      fork(takePillRemainderNotificationGetOneRequest),
      fork(takePillRemainderNotificationStatusAddRequest),
      fork(takePillRemainderNotificationStatusEditRequest),
      fork(takePillRemainderAllPillsListRequest),
      // Recruiter
      // -- Physician
      fork(takePhysicianJobsSaga),
      // -- Practice Admin
      fork(takeRecruiterJobAdmin),
      fork(takeAdminJobApplicantsListSaga),
      fork(takeAcceptRejectShortPhysicianSaga),

      // S3Upload
      fork(takeGetS3SignedURLSaga),

      // Schedule
      fork(takeAddScheduleRequest),
      fork(takeCheckAppointmentRequest),
      fork(takeCheckScheduleRequest),
      fork(takeGetScheduleRequest),

      // Shared
      // -- Speciality
      fork(takeGetSpecialities),
      // Save Post
      fork(takeSaveUnsaveAPostSaga),
      // google login
      fork(takeGoogleLoginAddRequest),
      // linked in
      fork(takeLinkedInLoginAddRequest),

      // Tele Medicine
      fork(takeAddEditAppointmentCommentRequest),
      fork(takeViewAppointmentDocumentRequest),
      fork(takeAppointmentScheduleRequest),
      fork(takeViewAppointmentRequest),
      fork(takeGetAppointmentCommentRequest),
      fork(takeGetSchedulePhysiciansList),
      fork(takeJoinAppointmentCallRequest),
      fork(takeListMedicineRequest),
      fork(takePhysicianListRequest),
      fork(takePhysicianScheduleRequest),
      fork(takeUpdateAppointmentRequest),
      fork(takeVideoCallConnectionRequest),
      fork(takeVideoCallUpdateRequest),

      // User Invite
      fork(takeNewStateRequest),
      fork(takeUserDetailsRequest),
      fork(takeInviteUserEmailRequest),

      // Video Library
      fork(takeVideoLibraryCategoryListRequest),
      fork(takeVideoLibraryListRequest),
      fork(takeOrgUserEduLib),

      // Wallet
      fork(takeWalletPhysician),
      fork(takeWalletOffers),
      fork(takeWallet),
      fork(takePhysicianReward),

      //support groups
      fork(takeLeaveSupportGroup),
      fork(takeSupportGroupInfo),
      fork(takeMembersList),
      // global search
      fork(takeGlobalSearchListRequest),
      // recent search
      fork(takeRecentSearch),
      // admin notification
      fork(takeAdminNotification),
      //profile picture
      fork(takeProfilePictureSaga),
      fork(takeGetBlockUnblockUserRequest),
      //npi number
      fork(takePhyNpiNumberVerify),
      fork(takeAddPhyNpiNumber),

      //user login
      fork(takeUserRegisterRequest),
      fork(takeForgotPassword),
      fork(takeSetPassword),
      fork(takeEmailVerification),

      // Subscription Details
      fork(takecheckPlanActive),
      fork(takeGetSubscriptionPlansList),
      fork(takeSubscriptionCheckCouponCode),
      //vdocipher
      fork(takeVdocipher),
      fork(takeVdocipherQuestions),

      //second opinion
      fork(takeSecondOpinionPhysicianList),
      fork(takeSecondOpinionShortListPhysician),
      fork(takeSecondOpinionShortListAddNDelete),
      fork(takeSecondOpinionNearbyPhysicianList),
      fork(takeSupportGroup),

      // Symptoms
      fork(takeCheckClinicTimingSaga),
      fork(takeOncKlinicSymptomMonitoringSaga),

      // event track
      fork(takeEventTrack),
      // treatment method
      fork(takeTreatmentMethod),
      // feed share link
      fork(takeFeedPostShareLinkRequest),
      fork(takeHideThisPostSaga),
      // announcement
      fork(takeAnnouncement),
      // clinical trials
      fork(takeClinicalTrialsRequest),
      fork(takeClinicalTrialsStudyTypeRequest),
      fork(takeClinicalTrialsStudyPhaseRequest),
      fork(takeClinicalTrialsStatesRequest),
      fork(takeClinicalTrialsCancerSpecificRequest),
      // hash tag
      fork(takeHashTagList),
      // community guidelines
      fork(takeCommunityGuidelinesSaga),
      // medication saving
      fork(takeGetMedicationSaving),
      fork(takeDiagnosis),
      fork(takeLatino),
      fork(takeStaging),
      fork(takeRace),
      fork(takeServiceOffered),
      fork(takePopulationServed),
      fork(takeOrgName),
      fork(takeCancerStatus),
      fork(takeEcog),
      fork(takeMedicalHistory),
      fork(takeGeneticTesting),
      fork(takeFillProfile),
   ]);
};

export { rootSaga };
