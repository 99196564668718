import { createAction } from '@reduxjs/toolkit';
import { conversationsReducersTypes, postShareSagaTypes, conversationsSagatypes } from 'types';

export const GET_MY_CONVERSATIONS = 'GET_MY_CONVERSATIONS';
export const getMyConversationsAction = createAction<postShareSagaTypes.IChat>(GET_MY_CONVERSATIONS);

export const UNREAD_CONVERSATIONS_COUNT = 'UNREAD_CONVERSATIONS_COUNT';
export const unreadConversationsCount = createAction(UNREAD_CONVERSATIONS_COUNT);

export const GET_ONE_TO_ONE_CONVERSATION = 'GET_ONE_TO_ONE_CONVERSATION';
export const getOneToOneConversationAction = createAction<conversationsReducersTypes.IGetOneToOneConversationProps>(
   GET_ONE_TO_ONE_CONVERSATION
);

export const GET_CONVERSATIONS_MESSAGES = 'GET_CONVERSATIONS_MESSAGES';
export const askADocGetConversationMessageAction = createAction<conversationsSagatypes.IaskADocGetConversationMessage>(
   GET_CONVERSATIONS_MESSAGES
);

export const ADD_CONVERSATIONS_MESSAGES = 'ADD_CONVERSATIONS_MESSAGES';
export const askADocConversationAddMessageAction = createAction<conversationsSagatypes.IAskADocAddConversationMessage>(
   ADD_CONVERSATIONS_MESSAGES
);

export const GET_CONVERSATION_CREATION_VERIFIED_USER = 'GET_CONVERSATION_CREATION_VERIFIED_USER';
export const getConversationCreationVerifiedUserAction = createAction<conversationsReducersTypes.IConversationCreateVerifiedUser>(
   GET_CONVERSATION_CREATION_VERIFIED_USER
);
