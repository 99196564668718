import React, { memo, useCallback, useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import ProfileLayoutModal from 'component/fillProfile/ProfileLayout';
import DotLoader from 'component/loader/DotLoader';
import AddCTHippoCompliance from 'component/modal/AddCTHippoCompliance';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import AdminNoData from 'component/widget/AdminNoData';
import Loading from 'component/widget/Loading';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import {
   clinicalTrialsInterestedListSagaAction,
   clinicalTrialsInterestSagaAction,
   clinicalTrialsListSagaAction,
   fillProfileAction,
} from 'reduxToolKit/actions';
import {
   clinicalTrialsSlice,
   populationServedSlice,
   serviceOfferedSlice,
   userDetailsSlice,
} from 'reduxToolKit/reducers';
import { clinicalTrialsPageTypes, myProfileSagaTypes, storeTypes, userInviteEmailPageTypes } from 'types';
import { CommonConstants, SET_TIME_OUT } from 'utils/constants/common';
import { addUserObject, getUser } from 'utils/helpers/authHeader';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';
import { changeOneDateFormatToAnotherApi, getCurrentDate } from 'utils/helpers/dateHelpers';
import { isOrganization, isPCAUser } from 'utils/helpers/userHelpers/userTypeHelpers';
import {
   isAddValidation,
   isCancerStatusValidation,
   isDiagnosisDateValidation,
   isDisplayName,
   isDobValidation,
   isLatinoValidation,
   isOrgNameValidation,
   isPhoneValidation,
   isPrimaryCancer,
   isRaceValidation,
   isStagingValidation,
} from 'utils/helpers/validationHelpers';

import ClinicalTrialsListItem from './ClinicTrialListItem';

const ClinicalTrialsCard = memo((props: any) => {
   const dispatch = useDispatch();

   const [clinicalTrialId, setClinicalTrialId] = useState<number | string>(0);
   const [openCTHippoCompliance, setOpenCTHippoCompliance] = useState<boolean>(false);
   const [isProfileLayoutRemainingModal, setIsProfileLayoutRemainingModal] = useState<boolean>(false);
   const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
   const [isDobValid, setIsDobValid] = useState<boolean>(false);
   const [isAddressValid, setIsAddressValid] = useState<boolean>(false);
   const [isDisplayNameValid, setIsDisplayNameValid] = useState<boolean>(false);
   const [isPrimaryCancerValid, setIsPrimaryCancerValid] = useState<boolean>(false);
   const [isRaceValid, setIsRaceValid] = useState<boolean>(false);
   const [isStagingValid, setIsStagingValid] = useState<boolean>(false);
   const [isDiagnosisDateValid, setIsDiagnosisDateValid] = useState<boolean>(false);
   const [isLatinoValid, setIsLatinoValid] = useState<boolean>(false);
   const [isCancerStatusValid, setIsCancerStatusValid] = useState<boolean>(false);
   const [isServiceOfferedValid, setIsServiceOfferedValid] = useState(false);
   const [isOrgNameValid, setIsOrgNameValid] = useState(false);
   const [isPopulationServedValid, setIsPopulationServedValid] = useState(false);

   const { list: clinicalTrialsList, isLoading: isLoadingList, pagination } = useSelector(
      (state: storeTypes.IStore) => state.clinicalTrials.clinicalTrialsList
   );
   const { current_page, total } = pagination;

   const {
      isLoading: interestIsLoading,
      message: interestMessage,
      error: interestError,
      data: interestData,
      requestData: interestRequestData,
   } = useSelector((state: storeTypes.IStore) => state.clinicalTrials.clinicalTrialsInterest);
   const {
      clinicalTrialsInterestRequestLoading,
      clinicalTrialsListUpdate,
      clinicalTrialsInterestReset,
      clinicalTrialsInterestedListReset,
   } = clinicalTrialsSlice.actions;

   const { isFrom, userDetail } = useSelector((state: storeTypes.IStore) => state.userDetail);
   const { getUserReset } = userDetailsSlice.actions;

   const { list: listUserServiceOffered, message: messageUserServiceOffered } = useSelector(
      (state: storeTypes.IStore) => state.serviceOffered.getUserServiceOffered
   );
   const { data: getUserPopulationServedData, message: messagePopulationServed } = useSelector(
      (state: storeTypes.IStore) => state.populationServed.getUserPopulationServed
   );

   const { getUserServiceOfferedMessageReset } = serviceOfferedSlice.actions;
   const { getUserPopulationServedMessageReset } = populationServedSlice.actions;

   useEffect(() => {
      if (messageUserServiceOffered || messagePopulationServed) {
         if (listUserServiceOffered) {
            setIsServiceOfferedValid(listUserServiceOffered?.length > 0);
            dispatch(getUserServiceOfferedMessageReset());
         }

         if (getUserPopulationServedData) {
            const { primary_cancers } = getUserPopulationServedData;
            setIsPopulationServedValid(primary_cancers?.length > 0);
            dispatch(getUserPopulationServedMessageReset());
         }
      }
   }, [listUserServiceOffered, messageUserServiceOffered, messagePopulationServed, getUserPopulationServedData]);

   useEffect(() => {
      if (interestError) {
         dispatch(clinicalTrialsInterestReset());
         setOpenCTHippoCompliance(true);
      }
   }, [interestError]);

   const getClinicalTrialsList = (page: number) => {
      const apiData: any = {
         status: props.finalData.status,
         age: props.finalData.age,
         gender: props.finalData.gender,
         cancer_type: props.finalData.cancer_type,
         cancer_specific: props.finalData?.cancer_specific?.map((q: any) => {
            return q.cancer_specific_name;
         }),
         search_word: props.finalData.search_word,
         study_type: props.finalData.study_type,
         study_phase: props.finalData.study_phase,
         countries: props.finalData.countries,
         state: props.finalData.state.map((q: any) => {
            return q.state_name;
         }),
      };
      const data: clinicalTrialsPageTypes.IClinicalTrialsListParamsAndData = {
         params: { page, limit: 15 },
         data: apiData,
      };
      dispatch(clinicalTrialsListSagaAction(data));
   };

   const getClinicalTrialsInterestedList = (page: number, searchWord: string) => {
      const data: clinicalTrialsPageTypes.IClinicalTrialsInterestedListParams = {
         params: { page, limit: 15, search_word: searchWord },
      };
      dispatch(clinicalTrialsInterestedListSagaAction(data));
   };

   const hideAlert = (type: string) => {
      if (type === 'successAlertInterest') {
         const clinicalTrialsListArrayCopy = listParseAndStringify(clinicalTrialsList);
         const index = clinicalTrialsListArrayCopy.findIndex(
            (x: any) => x.id === interestRequestData.clinical_trial_id
         );
         const interestDataLocal = listParseAndStringify(interestData);
         interestDataLocal.clinical_trial.is_interested = 1;
         clinicalTrialsListArrayCopy.splice(index, 1, interestDataLocal?.clinical_trial);
         dispatch(clinicalTrialsListUpdate(clinicalTrialsListArrayCopy));

         dispatch(clinicalTrialsInterestedListReset());
         getClinicalTrialsInterestedList(1, props?.searchWord);

         dispatch(clinicalTrialsInterestReset());
      }
   };

   /* TODO - for this will have used for future purpose */
   /* const clickViewMoreInterventions = (item: any, viewMore: boolean) => {
      const clinicalTrialsListArrayCopy = listParseAndStringify(clinicalTrialsList);
      const index = clinicalTrialsListArrayCopy.findIndex((x: any) => x.id === item.id);
      const interestDataLocal = listParseAndStringify(item);
      interestDataLocal.intervention_load_more = viewMore;
      clinicalTrialsListArrayCopy.splice(index, 1, interestDataLocal);
      dispatch(clinicalTrialsListUpdate(clinicalTrialsListArrayCopy));
   };

   const clickViewMoreConditions = (item: any, viewMore: boolean) => {
      const clinicalTrialsListArrayCopy = listParseAndStringify(clinicalTrialsList);
      const index = clinicalTrialsListArrayCopy.findIndex((x: any) => x.id === item.id);
      const interestDataLocal = listParseAndStringify(item);
      interestDataLocal.condition_load_more = viewMore;
      clinicalTrialsListArrayCopy.splice(index, 1, interestDataLocal);
      dispatch(clinicalTrialsListUpdate(clinicalTrialsListArrayCopy));
   }; */

   const openHippoCompliance = () => {
      setOpenCTHippoCompliance(!openCTHippoCompliance);
   };

   const addCTHippaCompliance = (data: any) => {
      clickInterested(clinicalTrialId, data);
   };

   const clickInterested = useCallback(
      (clinicalTrialId: number | string, dataObj?: any) => {
         setClinicalTrialId(clinicalTrialId);
         if (
            isPCAUser(userDetail?.user) &&
            (!isDiagnosisDateValidation(userDetail?.user) ||
               !isStagingValidation(userDetail?.user) ||
               !isCancerStatusValidation(userDetail?.user) ||
               !isRaceValidation(userDetail?.user) ||
               !isLatinoValidation(userDetail?.user))
         ) {
            setIsProfileLayoutRemainingModal(true);
         } else if (
            isOrganization(userDetail?.user) &&
            (!isOrgNameValid || !isServiceOfferedValid || !isPopulationServedValid)
         ) {
            setIsProfileLayoutRemainingModal(true);
         } else {
            const data: clinicalTrialsPageTypes.IClinicalTrialsInterestParams = {
               clinical_trial_id: clinicalTrialId,
               user_name: dataObj?.user_name || null,
               signature: dataObj?.signature || null,
               hipaa_sign_date: changeOneDateFormatToAnotherApi(getCurrentDate()),
            };
            dispatch(clinicalTrialsInterestSagaAction(data));
         }
      },
      [userDetail, isOrgNameValid, isServiceOfferedValid, isPopulationServedValid]
   );

   useEffect(() => {
      if (userDetail) {
         addUserObject(userDetail);
         setTimeout(() => {
            updatedUser(userDetail?.user);
            if (isFrom === 'clinicalTrailsCard' && clinicalTrialId) {
               clickInterested(clinicalTrialId);
            }
         }, SET_TIME_OUT.MIN);
      }
   }, [userDetail, isFrom]);

   const fillProfileApi = () => {
      const userData: userInviteEmailPageTypes.IUserDetails = {
         isFrom: 'clinicalTrailsCard',
      };
      const params: myProfileSagaTypes.IFillProfileProps = {
         params: { page: 1, limit: CommonConstants.DropDownLimit },
         data: userData,
      };
      dispatch(fillProfileAction(params));
   };

   const fillProfileModal = useCallback(() => {
      setIsProfileLayoutRemainingModal(false);
      dispatch(getUserReset());
      dispatch(clinicalTrialsInterestRequestLoading());
      setTimeout(() => {
         fillProfileApi();
      }, SET_TIME_OUT.MIN);
   }, []);

   const fillProfileModalClose = useCallback(() => {
      setIsProfileLayoutRemainingModal(false);
   }, []);

   const updatedUser = useCallback((user: any) => {
      if (user) {
         setIsPrimaryCancerValid(isPrimaryCancer(user));
         setIsDisplayNameValid(isDisplayName(user));
         setIsAddressValid(isAddValidation(user));
         setIsDobValid(isDobValidation(user));
         setIsPhoneValid(isPhoneValidation(user));
         setIsRaceValid(isRaceValidation(user));
         setIsStagingValid(isStagingValidation(user));
         setIsDiagnosisDateValid(isDiagnosisDateValidation(user));
         setIsLatinoValid(isLatinoValidation(user));
         setIsCancerStatusValid(isCancerStatusValidation(user));
         setIsOrgNameValid(isOrgNameValidation(user));
      }
   }, []);

   useEffect(() => {
      fillProfileApi();
   }, []);

   return (
      <Box className="cards-box style-7" id="clinicalTrialsScroll">
         <Box className="relative">
            {isProfileLayoutRemainingModal ? (
               isPCAUser(getUser()) || isOrganization(getUser()) ? (
                  <ProfileLayoutModal
                     closeModal={fillProfileModal}
                     isFirst={false}
                     phoneValid={isPhoneValid}
                     dobValid={isDobValid}
                     addressValid={isAddressValid}
                     displayNameValid={isDisplayNameValid}
                     primaryCancerValid={isPrimaryCancerValid}
                     isRaceValid={isRaceValid}
                     isStagingValid={isStagingValid}
                     isDiagnosisDateValid={isDiagnosisDateValid}
                     isLatinoValid={isLatinoValid}
                     isCancerStatusValid={isCancerStatusValid}
                     isServiceOfferedValid={isServiceOfferedValid}
                     isOrgNameValid={isOrgNameValid}
                     isPopulationServedValid={isPopulationServedValid}
                     userDetail={getUser()}
                     isFromClinicalTrials={true}
                     isFromClinicalTrialsList={true}
                     isFromFeed={true}
                     fillProfileModalClose={fillProfileModalClose}
                     isFromOrg={isOrganization(getUser())}
                  />
               ) : null
            ) : null}
            {interestIsLoading ? <Loading /> : null}
            {interestMessage && (
               <InfoTimerAlert
                  setManualTimer={true}
                  manualTimer={5}
                  success
                  confirmBtnText={`Okay!`}
                  confirmBtnBsStyle={`successBtn`}
                  title=""
                  onConfirm={() => hideAlert('successAlertInterest')}
               >
                  {interestMessage}
               </InfoTimerAlert>
            )}
            {openCTHippoCompliance && (
               <AddCTHippoCompliance
                  userData={getUser()}
                  openHippoCompliance={openHippoCompliance}
                  addCTHippaCompliance={addCTHippaCompliance}
               />
            )}
            <Box className="card-container" px={1} mb={2}>
               {isLoadingList && clinicalTrialsList?.length === 0 && <DotLoader />}
               {!isLoadingList && clinicalTrialsList?.length === 0 && (
                  <AdminNoData
                     title={'No result for the selected filter'}
                     message={`Currently, No active study available for the selected filters.
               Try searching with other filters`}
                  />
               )}
               <InfiniteScroll
                  dataLength={clinicalTrialsList ? clinicalTrialsList.length : 0}
                  next={() => getClinicalTrialsList(current_page ? current_page + 1 : 1)}
                  hasMore={total > clinicalTrialsList.length ? true : false}
                  loader={clinicalTrialsList.length > 0 && <DotLoader />}
                  style={{
                     overflow: 'hidden',
                  }}
                  scrollableTarget="clinicalTrialsScroll"
               >
                  <Grid container direction="column" spacing={2}>
                     {clinicalTrialsList.map((item: any, i: number) => {
                        return (
                           <Grid item key={i}>
                              <ClinicalTrialsListItem
                                 i={i}
                                 item={item}
                                 clickInterested={clickInterested}
                                 /* clickViewMoreConditions={clickViewMoreConditions}
                                 clickViewMoreInterventions={clickViewMoreInterventions} */
                              />
                           </Grid>
                        );
                     })}
                  </Grid>
               </InfiniteScroll>
            </Box>
         </Box>
      </Box>
   );
});

export default ClinicalTrialsCard;
