import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { physicianJobsReducerTypes, physicianJobsSagaTypes } from 'types';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';

const initialState: physicianJobsReducerTypes.IPhysicianJobsReducerTypes = {
   isLoading: false,
   error: '',
   list: {
      isLoading: false,
      message: '',
      data: [],
      pageType: '',
      error: '',
      pagination: {
         current_page: null,
         last_page: null,
         first_page_url: '',
         last_page_url: '',
         next_page_url: '',
         prev_page_url: '',
         total: 0,
      },
   },
   data: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   appliedJobOfferData: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
};

export const physicianJobsSlice = createSlice({
   name: 'physicianJobs',
   initialState: initialState,
   reducers: {
      getPhysicianJobsListRequest: (state, action: PayloadAction<physicianJobsSagaTypes.IPhysicianJobsListTypes>) => {
         return {
            ...state,
            list: {
               ...state.list,
               ...action.payload,
               isLoading: true,
               data: action.payload.pageNo && action.payload.pageNo === 1 ? [] : state.list.data,
            },
         };
      },
      getPhysicianJobsListSuccess: (
         state,
         action: PayloadAction<physicianJobsReducerTypes.IPhysicianJobsListSuccessPayload>
      ) => {
         return {
            ...state,
            list: {
               ...state.list,
               pageType: action.payload?.pageType,
               pagination: { ...action.payload?.pagination },
               isLoading: false,
               message: action.payload?.message,
               data:
                  action.payload?.pagination.current_page === 1
                     ? action.payload?.data.data
                     : [...state.list?.data, ...action.payload?.data.data],
               error: '',
            },
         };
      },
      getPhysicianJobsListFailed: (
         state,
         action: PayloadAction<physicianJobsReducerTypes.IPhysicianJobsListFailurePayload>
      ) => {
         return {
            ...state,
            list: {
               ...state.list,
               error: action.payload?.error,
               isLoading: false,
               message: '',
               pagination: {
                  current_page: null,
                  last_page: null,
                  first_page_url: '',
                  last_page_url: '',
                  next_page_url: '',
                  prev_page_url: '',
                  total: 0,
               },
            },
         };
      },
      physicianJobListFavouriteUpdateAction: (
         state,
         action: PayloadAction<physicianJobsSagaTypes.IPhysicianJobsAddRmFavourite>
      ) => {
         const jobList = state.list;
         const list: any[] = listParseAndStringify(jobList.data);

         if (action.payload?.activeTab === 'all') {
            const index = list.findIndex((x: any) => x.id === action.payload?.jobId);
            if (index > -1) {
               list[index].is_favorite = !list[index].is_favorite;
            }
         } else if (action.payload?.activeTab === 'applied') {
            const index = list.findIndex((x: any) => x.job_id === action.payload?.jobId);
            if (index > -1) {
               list[index].job_offers[0].is_favorite = !list[index].job_offers[0].is_favorite;
            }
         } else if (action.payload?.activeTab === 'saved') {
            const index = list.findIndex((x: any) => x.job_id === action.payload?.jobId);
            if (index > -1) {
               list.splice(index, 1);
            }
         }

         return {
            ...state,
            list: {
               ...state.list,
               data: list,
            },
         };
      },

      getPhysicianJobsListGetOneRequest: (
         state,
         action: PayloadAction<physicianJobsSagaTypes.IPhysicianJobsListGetOneTypes>
      ) => {
         return {
            ...state,
            isLoading: false,
            data: {
               isLoading: true,
               message: '',
               data: null,
               error: '',
            },
         };
      },
      getPhysicianJobsListGetOneSuccess: (
         state,
         action: PayloadAction<physicianJobsReducerTypes.IPhysicianJobsActionsPayload>
      ) => {
         return {
            ...state,
            data: {
               isLoading: false,
               message: action.payload?.message,
               data: action.payload?.data,
               error: '',
            },
         };
      },
      getPhysicianJobsListGetOneFailed: (
         state,
         action: PayloadAction<physicianJobsReducerTypes.IPhysicianJobsListFailurePayload>
      ) => {
         return {
            ...state,
            data: {
               isLoading: false,
               error: action.payload?.error,
               message: '',
               data: null,
            },
         };
      },

      physicianJobDetailsFavouriteUpdateAction: (state) => {
         const jobObjCopy = listParseAndStringify(state.data);
         const jobDetails = jobObjCopy?.data;
         if (jobDetails.data.id) {
            jobDetails.data.is_favorite = !jobDetails.data.is_favorite;
         }

         return {
            ...state,
            data: {
               isLoading: false,
               message: '',
               data: jobDetails,
               error: '',
            },
         };
      },

      appliedJobOfferRequest: (state) => {
         return {
            ...state,
            isLoading: false,
            appliedJobOfferData: {
               isLoading: true,
               message: '',
               data: null,
               error: '',
            },
         };
      },
      appliedJobOfferSuccess: (
         state,
         action: PayloadAction<physicianJobsReducerTypes.IPhysicianJobsActionsPayload>
      ) => {
         return {
            ...state,
            appliedJobOfferData: {
               isLoading: false,
               message: action.payload?.message,
               data: action.payload?.data,
               error: '',
            },
         };
      },
      appliedJobOfferFailed: (
         state,
         action: PayloadAction<physicianJobsReducerTypes.IPhysicianJobsListFailurePayload>
      ) => {
         return {
            ...state,
            appliedJobOfferData: {
               isLoading: false,
               error: action.payload?.error,
               message: '',
               data: null,
            },
         };
      },

      physicianJobsReset: () => {
         return {
            ...initialState,
         };
      },

      physicianGetOneJobReset: (state) => {
         return {
            ...state,
            data: {
               isLoading: false,
               error: '',
               message: '',
               data: null,
            },
         };
      },

      physicianAppliedJobOfferReset: (state) => {
         return {
            ...state,
            appliedJobOfferData: {
               isLoading: false,
               error: '',
               message: '',
               data: null,
            },
         };
      },
   },
});
