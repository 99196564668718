import { createAction } from '@reduxjs/toolkit';
import { medicalEnqSagaTypes } from 'types';

export const MEDICAL_ENQUIRES_ADD_SAGA = 'MEDICAL_ENQUIRES_ADD_SAGA';
export const medicalEnqAddSagaAction = createAction<medicalEnqSagaTypes.IMedicalEnqAddSaga>(MEDICAL_ENQUIRES_ADD_SAGA);

export const MEDICAL_ENQUIRES_MY_CLINIC_PHYSICIANS_LIST_SAGA = 'MEDICAL_ENQUIRES_MY_CLINIC_PHYSICIANS_LIST_SAGA';
export const medicalEnqMyClinicPhysListSagaAction = createAction<medicalEnqSagaTypes.IOncDriveMyClinicPhysiciansListSaga>(
   MEDICAL_ENQUIRES_MY_CLINIC_PHYSICIANS_LIST_SAGA
);
export const MEDICAL_ENQUIRES_MY_CLINIC_PHYSICIANS_SEARCH_SAGA = 'MEDICAL_ENQUIRES_MY_CLINIC_PHYSICIANS_SEARCH_SAGA';
export const medicalEnqMyClinicPhysSearchSagaAction = createAction<medicalEnqSagaTypes.IOncDriveMyClinicPhysiciansListSaga>(
   MEDICAL_ENQUIRES_MY_CLINIC_PHYSICIANS_SEARCH_SAGA
);

export const MEDICAL_ENQUIRES_LIST_SAGA = 'MEDICAL_ENQUIRES_LIST_SAGA';
export const medicalEnquiresListSagaAction = createAction<medicalEnqSagaTypes.IOncDriveListSaga>(
   MEDICAL_ENQUIRES_LIST_SAGA
);

export const MEDICAL_ENQUIRES_LIST_SEARCH_SAGA = 'MEDICAL_ENQUIRES_LIST_SEARCH_SAGA';
export const medicalEnquiresListSearchSagaAction = createAction<medicalEnqSagaTypes.IOncDriveListSaga>(
   MEDICAL_ENQUIRES_LIST_SEARCH_SAGA
);

export const MEDICAL_ENQUIRES_UPDATE_SAGA = 'MEDICAL_ENQUIRES_UPDATE_SAGA';
export const medicalEnqUpdateSagaAction = createAction<medicalEnqSagaTypes.IMedicalEnqUpdateSaga>(
   MEDICAL_ENQUIRES_UPDATE_SAGA
);

export const MEDICAL_ENQUIRES_GET_ONE_SAGA = 'MEDICAL_ENQUIRES_GET_ONE_SAGA';
export const medicalEnqGetOneSagaAction = createAction<medicalEnqSagaTypes.IMedicalEnqGetOneSaga>(
   MEDICAL_ENQUIRES_GET_ONE_SAGA
);

export const MEDICAL_ENQUIRES_ADD_COMMENT_SAGA = 'MEDICAL_ENQUIRES_ADD_COMMENT_SAGA';
export const medicalEnqAddCommentSagaAction = createAction<medicalEnqSagaTypes.IMedicalEnqAddCommentSaga>(
   MEDICAL_ENQUIRES_ADD_COMMENT_SAGA
);

export const MEDICAL_ENQUIRES_COMMENT_LIST_SAGA = 'MEDICAL_ENQUIRES_COMMENT_LIST_SAGA';
export const medicalEnqCommentListSagaAction = createAction<medicalEnqSagaTypes.IMedicalEnqCommentListSaga>(
   MEDICAL_ENQUIRES_COMMENT_LIST_SAGA
);

export const MEDICAL_ENQUIRES_UPDATE_COMMENT_SAGA = 'MEDICAL_ENQUIRES_UPDATE_COMMENT_SAGA';
export const medicalEnqUpdateCommentSagaAction = createAction<medicalEnqSagaTypes.IMedicalEnqUpdateCommentSaga>(
   MEDICAL_ENQUIRES_UPDATE_COMMENT_SAGA
);

export const MEDICAL_ENQUIRES_DOCUMENT_LIST_SAGA = 'MEDICAL_ENQUIRES_DOCUMENT_LIST_SAGA';
export const medicalEnqDocumentListSagaAction = createAction<medicalEnqSagaTypes.IMedicalEnqDocumentListSaga>(
   MEDICAL_ENQUIRES_DOCUMENT_LIST_SAGA
);

export const MEDICAL_ENQUIRES_DOCUMENT_VIEW_SAGA = 'MEDICAL_ENQUIRES_DOCUMENT_VIEW_SAGA';
export const medicalEnqDocumentViewSaga = createAction<number>(MEDICAL_ENQUIRES_DOCUMENT_VIEW_SAGA);

export const MEDICAL_ENQUIRES_DOCUMENT_UPDATE_SAGA = 'MEDICAL_ENQUIRES_DOCUMENT_UPDATE_SAGA';
export const medicalEnqDocumentUpdateSaga = createAction<medicalEnqSagaTypes.IMedicalEnqDocumentUpdateForm>(
   MEDICAL_ENQUIRES_DOCUMENT_UPDATE_SAGA
);
