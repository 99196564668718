import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { peopleNearMeReducerTypes } from 'types';

const initialState: peopleNearMeReducerTypes.ICancerTypeListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
};

export const clinicAuxiliarySlice = createSlice({
   name: 'clinicAuxiliary',
   initialState: initialState,
   reducers: {
      clinicAuxiliaryLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      clinicAuxiliaryViewRequest: (state, action: PayloadAction<peopleNearMeReducerTypes.ICancerTypeListReducer>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: state.list,
         };
      },
      clinicAuxiliaryViewSuccess: (state, action: PayloadAction<peopleNearMeReducerTypes.ICancerTypeListReducer>) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            message: payload.message,
            isLoading: false,
         };
      },
      clinicAuxiliaryFailed: (state, action: PayloadAction<peopleNearMeReducerTypes.ICancerTypeListReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      clinicAuxiliaryReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
