import { createSlice } from '@reduxjs/toolkit';
import { peopleNearMeReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: peopleNearMeReducerTypes.ITopCancerState = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
};

export const getTopStateSlice = createSlice({
   name: 'getTopState',
   initialState: initialState,
   reducers: {
      getTopStateRequest: (state) => {
         return {
            ...state,

            isLoading: true,
            list: state.list,
         };
      },
      getTopStateSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            isLoading: false,
            message: payload.message || messages.success,
         };
      },
      getTopStateFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      getTopStateReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
