import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINIC_STAFF_ADD } from 'reduxToolKit/actions';
import { addClinicStaffSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { addClinicStaffRequest, addClinicStaffSuccess, addClinicStaffFailed } = addClinicStaffSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const addClinicStaffSaga = function* (payloadState: any) {
   yield put(addClinicStaffRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addClinicStaff });
      if (response.status === 200) {
         const resObj = {
            message: response?.data?.message,
         };
         yield put(addClinicStaffSuccess(resObj));
      } else {
         yield put(addClinicStaffFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAddClinicStaff = function* () {
   yield takeEvery(CLINIC_STAFF_ADD, addClinicStaffSaga);
};
