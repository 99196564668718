import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { HOME_RPM_INTERVAL_REMAINDER_SAGA } from 'reduxToolKit/actions';
import { commonSlice, rpmIntervalRemainderSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   rpmIntervalRemainderRequest,
   rpmIntervalRemainderSuccess,
   rpmIntervalRemainderFailed,
} = rpmIntervalRemainderSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const rpmIntervalRemainderSaga = function* () {
   yield put(rpmIntervalRemainderRequest());
   try {
      const checkUser = true;
      const goUrl = true;

      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, ...API.getHomeRpmIntervalReminders });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            list: responseData.data,
            message: response.data.message,
         };
         yield put(rpmIntervalRemainderSuccess(oncDriveList));
      } else {
         yield put(rpmIntervalRemainderFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeRpmIntervalRemainder = function* () {
   yield takeEvery(HOME_RPM_INTERVAL_REMAINDER_SAGA, rpmIntervalRemainderSaga);
};
