export const ONCO_DRIVE_EVENTS = {
   // onco drive
   ONCO_DRIVE_DEMO_WATCH_LATER: 'onco_drive_demo_watch_later',
   ONCO_DRIVE_DEMO_PLAY: 'onco_drive_demo_play',
   ONCO_DRIVE_ADD_FOLDER: 'onco_drive_add_folder',
   ONCO_DRIVE_RENAME_FOLDER: 'onco_drive_rename_folder',
   ONCO_DRIVE_SHARE_FOLDER: 'onco_drive_share_folder',
   ONCO_DRIVE_DELETE_FOLDER: 'onco_drive_delete_folder',
   ONCO_DRIVE_ADD_FILES: 'onco_drive_add_files',
   ONCO_DRIVE_ADD_FILE_CANCEL: 'onco_drive_add_file_cancel',
   ONCO_DRIVE_DELETE_FILES: 'onco_drive_delete_files',
   ONCO_DRIVE_EDIT_FILES: 'onco_drive_delete_files',
   ONCO_DRIVE_SHARE_FILES: 'onco_drive_share_files',
   ONCO_DRIVE_CANCEL_SHARE: 'onco_drive_cancel_share',
   SHARED_WITH_ME_FILE_VIEW_INFO: 'shared_with_me_file_view_info',
};
