import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_NOTIFICATION_COUNT } from 'reduxToolKit/actions';
import { commonSlice, notificationCountSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { notificationCountRequest, notificationCountSuccess, notificationCountFailed } = notificationCountSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const notificationCount = function* (cancerTypePayload: PayloadAction<feedSagaTypes.INotification>) {
   yield put(notificationCountRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, peopleMayYouKnow, params, ...API.notificationCount });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            data: responseData.data,
            message: response.data?.message || messages.success,
         };
         yield put(notificationCountSuccess(oncDriveList));
      } else {
         yield put(notificationCountFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeNotificationCount = function* () {
   yield takeEvery(GET_NOTIFICATION_COUNT, notificationCount);
};
