import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_ALL_USERS, TREATMENT_ESTIMATE_ENABLED_PHYS } from 'reduxToolKit/actions';
import { commonSlice, getAllUsersListSlice } from 'reduxToolKit/reducers';
import { askADocPageTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   allUsersListLoading,
   allUsersListViewRequest,
   allUsersListViewSuccess,
   allUsersListFailed,
   treatmentEstimateEnabledPhysListRequest,
   treatmentEstimateEnabledPhysListSuccess,
   treatmentEstimateEnabledPhysListFailed,
} = getAllUsersListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const allUsersListSaga = function* () {
   yield put(allUsersListLoading());
   yield put(allUsersListViewRequest());
   try {
      const checkUser = true;
      const goUrl = true;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, ...API.userGetAllUser });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            message: response.data.message,
         };
         yield put(allUsersListViewSuccess(oncDriveList));
      } else {
         yield put(allUsersListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const treatmentEstimateEnabledPhysListSaga = function* (
   treatmentEstimateEnabledPhysListPayload: PayloadAction<askADocPageTypes.ITreatmentEstimateEnabledPhysSagaProps>
) {
   yield put(treatmentEstimateEnabledPhysListRequest(treatmentEstimateEnabledPhysListPayload.payload));
   try {
      const checkUser = false;
      const params = treatmentEstimateEnabledPhysListPayload.payload;

      const response: AxiosResponse = yield apiCall({
         checkUser,
         params,
         ...API.askADocTreatmentEstimateEnabledPhyList,
      });
      if (response.status === 200) {
         const responseData = response.data.data;
         const usersList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response?.data?.message || messages.success,
         };
         yield put(treatmentEstimateEnabledPhysListSuccess(usersList));
      } else {
         yield put(treatmentEstimateEnabledPhysListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAllUsersListRequest = function* () {
   yield takeEvery(GET_ALL_USERS, allUsersListSaga);
   yield takeEvery(TREATMENT_ESTIMATE_ENABLED_PHYS, treatmentEstimateEnabledPhysListSaga);
};
