import { createSlice } from '@reduxjs/toolkit';
import { INewStateDetailsReducer } from 'types/userInvite/userInviteEmailReducerTypes';

const initialState: INewStateDetailsReducer = {
   isLoading: false,
   error: '',
   message: '',
   newStateAdded: null,
};
const newStateAddingSlice = createSlice({
   name: 'newStateAdded',
   initialState: initialState,
   reducers: {
      newStateDetails: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      newStateDetailSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            newStateAdded: payload?.data,
            isLoading: false,
         };
      },
      newStateDetailFailed: (state, action) => {
         return {
            ...state,
            newStateAdded: null,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      newStateDetailReset: () => {
         return { ...initialState };
      },
   },
});
export { newStateAddingSlice };
