import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CHECKSCHEDULE_BLOCK } from 'reduxToolKit/actions';
import { checkScheduleBlockSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   checkScheduleBlockRequest,
   checkScheduleBlockSuccess,
   checkScheduleBlockFailed,
} = checkScheduleBlockSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

export const checkScheduleBlock = function* (checkSchedulepayload: any) {
   yield put(checkScheduleBlockRequest());
   try {
      const checkUser = true;
      const goUrl = true;
      const data = checkSchedulepayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.checkScheduleBlock });
      if (response.status === 200) {
         const responseData = response.data;
         const checkScheduleList = {
            status: responseData.data.status,
            schedules: responseData.data.schedules,
            message: responseData.message,
         };
         yield put(checkScheduleBlockSuccess(checkScheduleList));
      } else {
         yield put(checkScheduleBlockFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeCheckScheduleRequest = function* () {
   yield takeEvery(CHECKSCHEDULE_BLOCK, checkScheduleBlock);
};
