import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { POST_ADD_REVIEW } from 'reduxToolKit/actions';
import { commonSlice, rateWriteSlice } from 'reduxToolKit/reducers';
import { rateWriteSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { rateWriteRequest, rateWriteSuccess, rateWriteFailed } = rateWriteSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const rateWriteSaga = function* (payloadState: PayloadAction<rateWriteSagaTypes.IAddReview>) {
   yield put(rateWriteRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addReview });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(rateWriteSuccess(responseData));
      } else {
         yield put(rateWriteFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeRateWriteSaga = function* () {
   yield takeEvery(POST_ADD_REVIEW, rateWriteSaga);
};
