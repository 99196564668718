import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { SINGLE_FEED_POST } from 'reduxToolKit/actions';
import { commonSlice, singleFeedListSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { singleFeedListRequest, singleFeedListSuccess, singleFeedListFailed } = singleFeedListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const singleFeed = function* (locumListParams: PayloadAction<feedSagaTypes.IDeletePostCommentActionProps>) {
   yield put(singleFeedListRequest(locumListParams.payload));
   try {
      const url = API.getOneFeed;
      const checkUser = true;
      const goUrl = true;
      const params = locumListParams.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...url });
      if (response.status === 200) {
         const resObj = response?.data;
         yield put(singleFeedListSuccess(resObj));
      } else {
         yield put(singleFeedListFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeSingleFeedList = function* () {
   yield takeLatest(SINGLE_FEED_POST, singleFeed);
};
