import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { GET_SERVICE_SPECIALITY } from 'reduxToolKit/actions';
import { commonSlice, myServiceSpecialityListSlice } from 'reduxToolKit/reducers';
import { postShareSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   myServiceSpecialityListLoading,
   myServiceSpecialityListViewRequest,
   myServiceSpecialityListViewSuccess,
   myServiceSpecialityListFailed,
} = myServiceSpecialityListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const ListSaga = function* (servicePayload: PayloadAction<postShareSagaTypes.IWallet>) {
   yield put(myServiceSpecialityListLoading());
   yield put(myServiceSpecialityListViewRequest(servicePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = servicePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.myServicesSpecilaityList });
      if (response?.status === 200) {
         const responseData = response?.data;
         const oncDriveList = {
            list: responseData?.data?.list,
            total_records: responseData?.data?.total_records,
         };
         yield put(myServiceSpecialityListViewSuccess(oncDriveList));
      } else {
         yield put(myServiceSpecialityListFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeMyServiceSpecialityListRequest = function* () {
   yield takeLatest(GET_SERVICE_SPECIALITY, ListSaga);
};
