import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { oncKlinicSymptomReducerTypes, oncKlinicSymptomSagaTypes } from 'types';

const initialState: oncKlinicSymptomReducerTypes.ISymptomMonitoringConsent = {
   symptomMonitoringConsent: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
};

export const oncKlinicSymptomSlice = createSlice({
   name: 'oncKlinicSymptom',
   initialState: initialState,
   reducers: {
      updateSymptomMonitoringConsentRequest: (
         state,
         action: PayloadAction<oncKlinicSymptomSagaTypes.IUpdateSymptomMonitoringConsent>
      ) => {
         return {
            ...state,
            symptomMonitoringConsent: {
               ...state.symptomMonitoringConsent,
               isLoading: true,
            },
         };
      },
      updateSymptomMonitoringConsentSuccess: (
         state,
         action: PayloadAction<oncKlinicSymptomReducerTypes.ISymptomMonitoringConsentSuccessPayload>
      ) => {
         return {
            ...state,
            symptomMonitoringConsent: {
               ...state.symptomMonitoringConsent,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      updateSymptomMonitoringConsentFailed: (
         state,
         action: PayloadAction<oncKlinicSymptomReducerTypes.ISymptomMonitoringConsentFailurePayload>
      ) => {
         return {
            ...state,
            symptomMonitoringConsent: {
               ...state.symptomMonitoringConsent,
               error: action.payload?.error,
               isLoading: false,
               message: '',
               data: null,
            },
         };
      },
      updateSymptomMonitoringConsentReset: (state) => {
         return {
            ...state,
            symptomMonitoringConsent: initialState.symptomMonitoringConsent,
         };
      },
   },
});
