import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { askADocDisclaimerReducerTypes, storeTypes } from 'types';

const initialState: askADocDisclaimerReducerTypes.IAskADocDisclaimerReducerTypes = {
   isLoading: false,
   addDisclaimer: {
      message: '',
      data: null,
      error: '',
   },
   getDisclaimer: {
      message: '',
      data: null,
      error: '',
   },
};

export const askADocDisclaimerSlice = createSlice({
   name: 'askADocDisclaimer',
   initialState: initialState,
   reducers: {
      getDisclaimerRequest: (state, action: PayloadAction<askADocDisclaimerReducerTypes.IGetDisclaimer>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getDisclaimerSuccess: (state, action: PayloadAction<askADocDisclaimerReducerTypes.IDisclaimerSuccess>) => {
         return {
            ...state,
            getDisclaimer: {
               data: action.payload?.data,
               message: action.payload?.message,
               error: '',
            },
            isLoading: false,
         };
      },
      getDisclaimerFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            getDisclaimer: {
               error: action.payload?.error,
               message: '',
               data: null,
            },
            isLoading: false,
         };
      },

      addDisclaimerRequest: (state, action: PayloadAction<askADocDisclaimerReducerTypes.IGetDisclaimer>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addDisclaimerSuccess: (state, action: PayloadAction<askADocDisclaimerReducerTypes.IDisclaimerSuccess>) => {
         return {
            ...state,
            addDisclaimer: {
               data: action.payload?.data,
               message: action.payload?.message,
               error: '',
            },
            isLoading: false,
         };
      },

      addDisclaimerFailed: (state, action: PayloadAction<askADocDisclaimerReducerTypes.IDisclaimerFailure>) => {
         return {
            ...state,
            addDisclaimer: {
               error: action.payload?.error,
               message: '',
               data: null,
            },
            isLoading: false,
         };
      },
      disclaimerReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
