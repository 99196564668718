import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_MEDICAL_HISTORY, GET_MASTER_MEDICAL_HISTORY, GET_MEDICAL_HISTORY_LIST } from 'reduxToolKit/actions';
import { commonSlice, medicalHistorySlice } from 'reduxToolKit/reducers';
import { myProfileReducerTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   medicalHistoryAddRequest,
   medicalHistoryAddSuccess,
   medicalHistoryAddFailed,
   getMasterMedicalHistoryRequest,
   getMasterMedicalHistorySuccess,
   getMasterMedicalHistoryFailed,
   getMedicalHistoryListRequest,
   getMedicalHistoryListSuccess,
   getMedicalHistoryListFailed,
} = medicalHistorySlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const medicalHistoryAddSaga = function* (medicalHistoryAddPayload: any) {
   yield put(medicalHistoryAddRequest(medicalHistoryAddPayload.payload));
   try {
      const checkUser = false;
      const data = medicalHistoryAddPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...API.medicalHistoryAdd });
      if (response.status === 200) {
         const responseData = response.data;
         const medicalHistoryAddData = {
            message: responseData?.message || '',
         };
         yield put(medicalHistoryAddSuccess(medicalHistoryAddData));
      } else {
         yield put(medicalHistoryAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getMasterMedicalHistorySaga = function* (
   getMasterMedicalHistoryPayload: PayloadAction<myProfileReducerTypes.IRaceReducer>
) {
   yield put(getMasterMedicalHistoryRequest(getMasterMedicalHistoryPayload.payload));
   try {
      const params = getMasterMedicalHistoryPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         params,
         ...API.getMasterMedicalHistory,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const getMasterMedicalHistoryList = {
            list: responseData?.data?.data || [],
            total_records: responseData?.data?.total || 0,
            message: response?.data?.message || '',
         };
         yield put(getMasterMedicalHistorySuccess(getMasterMedicalHistoryList));
      } else {
         yield put(getMasterMedicalHistoryFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getMedicalHistoryListSaga = function* (
   getMedicalHistoryListPayload: PayloadAction<myProfileReducerTypes.IMedicalHistoryReducer>
) {
   yield put(getMedicalHistoryListRequest(getMedicalHistoryListPayload.payload));
   try {
      const params = getMedicalHistoryListPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         params,
         ...API.getMedicalHistoryList,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const getMedicalHistoryList = {
            list: responseData?.data?.data || [],
            total_records: responseData?.data?.total || 0,
            message: response?.data?.message || '',
         };
         yield put(getMedicalHistoryListSuccess(getMedicalHistoryList));
      } else {
         yield put(getMedicalHistoryListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeMedicalHistory = function* () {
   yield takeEvery(ADD_MEDICAL_HISTORY, medicalHistoryAddSaga);
   yield takeEvery(GET_MASTER_MEDICAL_HISTORY, getMasterMedicalHistorySaga);
   yield takeEvery(GET_MEDICAL_HISTORY_LIST, getMedicalHistoryListSaga);
};
