import { createSlice } from '@reduxjs/toolkit';
import { oncDriveReducerTypes } from 'types';

const initialState: oncDriveReducerTypes.IOncDriveListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   breadcrumbList: [],
   pagination: {
      current_page: null,
      last_page: null,
      first_page_url: '',
      last_page_url: '',
      next_page_url: '',
      prev_page_url: '',
      total: 0,
   },
};

const initialStateSharedList: oncDriveReducerTypes.IOncDriveSharedListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   pagination: {
      current_page: null,
      last_page: null,
      first_page_url: '',
      last_page_url: '',
      next_page_url: '',
      prev_page_url: '',
      total: 0,
   },
};

const initialStateSharedUsersList: oncDriveReducerTypes.IOncDriveSharedUsersListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   pagination: {
      current_page: null,
      last_page: null,
      first_page_url: '',
      last_page_url: '',
      next_page_url: '',
      prev_page_url: '',
      total: 0,
   },
};
export const oncDriveListSlice = createSlice({
   name: 'oncDriveList',
   initialState: initialState,
   reducers: {
      oncDriveListViewRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      oncDriveListtViewSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            pagination: { ...payload.pagination },
            list: payload.pagination.current_page === 1 ? payload.list : [...state.list, ...payload.list],
            breadcrumbList: payload.breadcrumbList,
            isLoading: false,
         };
      },
      oncDriveListViewFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      oncDriveListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

export const oncDriveSharedListSlice = createSlice({
   name: 'oncDriveSharedList',
   initialState: initialStateSharedList,
   reducers: {
      oncDriveSharedListViewRequest: (state, action) => {
         return {
            ...state,

            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      oncDriveSharedListtViewSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            pagination: { ...payload.pagination },
            list: payload.pagination.current_page === 1 ? payload.list : [...state.list, ...payload.list],
            isLoading: false,
         };
      },
      oncDriveSharedListViewFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      oncDriveSharedListReset: () => {
         return {
            ...initialStateSharedList,
         };
      },
   },
});

export const oncDriveSharedUserListSlice = createSlice({
   name: 'oncDriveSharedUserList',
   initialState: initialStateSharedUsersList,
   reducers: {
      oncDriveSharedUserListViewLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      oncDriveSharedUserListViewRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      oncDriveSharedUserListViewSuccess: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            pagination: { ...payload.pagination },
            list: payload.pagination.current_page === 1 ? payload.list : [...state.list, ...payload.list],
            breadcrumbList: payload.breadcrumbList,
            isLoading: false,
         };
      },
      oncDriveSharedUserListViewFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload,
         };
      },
      oncDriveSharedUserListReset: () => {
         return {
            ...initialStateSharedUsersList,
         };
      },
   },
});
