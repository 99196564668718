import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { socialHistoryReducerTypes } from 'types';

const initialState: socialHistoryReducerTypes.IUpdateSocialHistories = {
   isLoading: false,
   message: '',
   error: '',
   errors: [],
};

export const socialHistoriesSlice = createSlice({
   name: 'socialHistories',
   initialState: initialState,
   reducers: {
      updateSocialHistoriesRequest: (
         state,
         action: PayloadAction<socialHistoryReducerTypes.IUpdateSocialHistoryData>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      updateSocialHistoriesSuccess: (
         state,
         action: PayloadAction<socialHistoryReducerTypes.IUpdateSocialHistorySuccessPayload>
      ) => {
         return {
            ...state,
            message: action.payload?.message,
            error: '',
            isLoading: false,
         };
      },
      updateSocialHistoriesFailed: (
         state,
         action: PayloadAction<socialHistoryReducerTypes.ISocialHistoriesFailedPayload>
      ) => {
         return {
            ...state,
            error: action.payload?.error,
            message: '',
            isLoading: false,
         };
      },
      updateSocialHistoriesReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
