import React from 'react';

import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme: Theme) =>
   createStyles({
      root: {
         margin: 0,
         padding: theme.spacing(2),
      },
      closeButton: {
         position: 'absolute',
         right: theme.spacing(1),
         top: theme.spacing(1),
         color: theme.palette.grey[500],
      },
      formControl: {
         margin: theme.spacing(3),
      },
      radio: {
         '&$checked': {
            color: '#4B8DF8',
         },
         span: {
            color: 'black',
         },
      },
   });

export interface DialogTitleProps extends WithStyles<typeof styles> {
   id: string;
   children: React.ReactNode;
   onClose?: () => void;
}
export const DialogContent = withStyles((theme: Theme) => ({
   root: {
      padding: theme.spacing(2),
   },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
   root: {
      margin: 0,
      padding: theme.spacing(1),
   },
}))(MuiDialogActions);

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
   const { children, classes, onClose, ...other } = props;
   return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
         <Typography variant="h6" style={{ paddingLeft: 0 }}>
            {children}
         </Typography>
         {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
               <CloseIcon />
            </IconButton>
         ) : null}
      </MuiDialogTitle>
   );
});
