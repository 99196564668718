import { createAction } from '@reduxjs/toolkit';
import { myProfileSagaTypes } from 'types';

export const ADD_MEDICAL_HISTORY = 'ADD_MEDICAL_HISTORY';
export const addMedicalHistoryAction = createAction<myProfileSagaTypes.IMedicalHistoryProps>(ADD_MEDICAL_HISTORY);

export const GET_MASTER_MEDICAL_HISTORY = 'GET_MASTER_MEDICAL_HISTORY';
export const getMasterMedicalHistoryAction = createAction<myProfileSagaTypes.IMasterRaceProps>(
   GET_MASTER_MEDICAL_HISTORY
);

export const GET_MEDICAL_HISTORY_LIST = 'GET_MEDICAL_HISTORY_LIST';
export const getMedicalHistoryListAction = createAction<myProfileSagaTypes.IGeneticTestingListProps>(
   GET_MEDICAL_HISTORY_LIST
);
