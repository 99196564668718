import { createSlice } from '@reduxjs/toolkit';
import { myProfileReducerTypes } from 'types';

const initialState: myProfileReducerTypes.IDeleteClinicalTrialReducer = {
   error: '',
   message: '',
   isLoading: false,
};

export const deleteClinicalTrialSlice = createSlice({
   name: 'deleteClinicalTrial',
   initialState: initialState,
   reducers: {
      deleteClinicalTrialRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      deleteClinicalTrialSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            isLoading: false,
         };
      },
      deleteClinicalTrialFailed: (state, action: any) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      deleteClinicalTrialReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
