import { configureStore } from '@reduxjs/toolkit';
// import { Middleware } from 'redux';
// import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from 'reduxToolKit/rootReducers';
import { rootSaga } from 'reduxToolKit/rootSaga';
// import { isLocalOnly } from 'utils/helpers/validationHelpers';

const persistConfig = {
   key: 'reduxPersistState',
   timeout: 0,
   storage: storage,
   whitelist: ['login', 'joinCallAppointment', 'userDetail', 'search', 'common', 'oncoMeditation'],
   transforms: [
      createWhitelistFilter('common', [
         'isInvalidURLDetect',
         'isDrawerOpen',
         'unreadableNotifyCount',
         'getWalletBalance',
         'conversationStatus',
         'conversationCount',
         'isApiLoading',
         'selectedPlan',
      ]),
   ],
};
const pReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const middleware: Array<any> = [sagaMiddleware];
/* if (isLocalOnly()) {
   const logger: Middleware = createLogger();
   middleware.push(logger);
} */

const store = configureStore({
   reducer: pReducer,
   middleware,
});

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { persistor, store };
