import { createSlice } from '@reduxjs/toolkit';
import { fillProfileReducerTypes } from 'types';

const initialState: fillProfileReducerTypes.IPhoneSectionReducerType = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
export const physicianRewardSlice = createSlice({
   name: 'physicianRewardSlice',
   initialState: initialState,
   reducers: {
      physicianRewardRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      physicianRewardSuccess: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            ...action.payload,
         };
      },
      physicianRewardFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      physicianRewardReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
