export const getPillReminderTypes = (type: string, pillObj: any) => {
   const pillTypesArray = [
      { title: 'Every day', value: 'every_day' },
      { title: 'Once a week', value: 'once_a_week' },
      { title: 'Every 2 days', value: 'every_two_days' },
      { title: '2 Days a week', value: 'two_days_a_week' },
      { title: '3 Days a week', value: 'three_days_a_week' },
      { title: 'Every 14 days', value: 'every_fourteen_days' },
      { title: 'Every 21 days', value: 'every_twenty_one_days' },
      { title: 'Every 28 days', value: 'every_twenty_eight_days' },
      { title: '5 Days a week', value: 'five_days_a_week' },
      { title: 'Others', value: 'others' },
      { title: 'Specific days of the week', value: 'specific_days_of_the_week' },
      {
         title: `Every ${
            (pillObj?.others_interval_days || 0) === 1
               ? `${pillObj?.others_interval_days || 0} day`
               : `${pillObj?.others_interval_days || 0} days`
         }`,
         value: 'every_number_of_days',
      },
   ];
   const pillTypeObj = pillTypesArray.find((obj: any) => obj.value === type);
   return pillTypeObj?.title || '';
};

export const getPillRemainderDays = (day: string) => {
   const daysArray = [
      { title: 'Sunday', value: '0', day: '0', id: 0 },
      { title: 'Monday', value: '1', day: '1', id: 0 },
      { title: 'Tuesday', value: '2', day: '2', id: 0 },
      { title: 'Wednesday', value: '3', day: '3', id: 0 },
      { title: 'Thursday', value: '4', day: '4', id: 0 },
      { title: 'Friday', value: '5', day: '5', id: 0 },
      { title: 'Saturday', value: '6', day: '6', id: 0 },
   ];
   const pillDayObj = daysArray.find((obj: any) => obj.value === day);
   return pillDayObj?.title || '';
};

export const getPillSpecificTypeCount = (type: string, pillObj: any, multiply?: boolean) => {
   const pillTypesArray = [
      { title: 'Every day', value: 'every_day', count: 0 },
      { title: 'Once a week', value: 'once_a_week', count: 0 },
      { title: 'Every 2 days', value: 'every_two_days', count: 2 },
      { title: '2 Days a week', value: 'two_days_a_week', count: 0 },
      { title: '3 Days a week', value: 'three_days_a_week', count: 0 },
      { title: 'Every 14 days', value: 'every_fourteen_days', count: 14 },
      { title: 'Every 21 days', value: 'every_twenty_one_days', count: 21 },
      { title: 'Every 28 days', value: 'every_twenty_eight_days', count: 28 },
      { title: '5 Days a week', value: 'five_days_a_week', count: 0 },
      { title: 'Others', value: 'others', count: 0 },
      { title: 'Specific days of the week', value: 'specific_days_of_the_week', count: 0 },
      { title: 'Every number of days', value: 'every_number_of_days', count: pillObj?.others_interval_days || 0 },
   ];
   const pillTypeObj = pillTypesArray.find((obj: any) => obj.value === type);
   let count = pillTypeObj?.count || 0;
   if (multiply) {
      count = count * 2;
   }
   return count;
};
