import { createSlice } from '@reduxjs/toolkit';
import { getAppointmentReducerTypes } from 'types';

const initialState: getAppointmentReducerTypes.IAppointmentListReducer = {
   list: [],
   isCallingScreenMount: false,
   pagination: {
      current_page: null,
      last_page: null,
      next_page_url: '',
      prev_page_url: '',
      first_page_url: '',
      last_page_url: '',
      total: 0,
   },
   isLoading: false,
   error: '',
};
const teleMedicineListSlice = createSlice({
   name: 'listTeleMedicine',
   initialState: initialState,
   reducers: {
      getAllTeleMedicineListReq: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      getAllTeleMedicineSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            pagination: { ...payload.pagination },
            list: payload.pagination.current_page === 1 ? payload.list : [...state.list, ...payload.list],
            isLoading: false,
         };
      },
      getAllTeleMedicineFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      teleMedicineCallingScreenMountAction: (state) => {
         return {
            ...state,
            isCallingScreenMount: true,
         };
      },
      getAllTeleMedicineReset: () => {
         return initialState;
      },
   },
});
export { teleMedicineListSlice };
