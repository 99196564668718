import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_FAMILY_MEMBERS } from 'reduxToolKit/actions';
import { commonSlice, getFamilyMembersSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getFamilyMembersRequest, getFamilyMembersSuccess, getFamilyMembersFailed } = getFamilyMembersSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getFamilyMembersSaga = function* (familyMembersPayload: any) {
   yield put(getFamilyMembersRequest(familyMembersPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = familyMembersPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getFamilyMembers });
      if (response.status === 200) {
         const responseData = response.data;
         const familyMembersList = {
            list: responseData?.data?.list || [],
            total_records: responseData?.data?.total_records || 0,
            message: response?.data?.message || '',
         };
         yield put(getFamilyMembersSuccess(familyMembersList));
      } else {
         yield put(getFamilyMembersFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGetFamilyMembers = function* () {
   yield takeEvery(GET_FAMILY_MEMBERS, getFamilyMembersSaga);
};
