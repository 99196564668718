import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_MEDICATION } from 'reduxToolKit/actions';
import { commonSlice, medicationSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { medicationListRequest, medicationListSuccess, medicationListFailed } = medicationSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;
const getMedicationSaga = function* (prescriptionTypePayload: any) {
   yield put(medicationListRequest(prescriptionTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = prescriptionTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getMedications });
      if (response.status === 200) {
         const responseData = response?.data?.data?.list;
         yield put(medicationListSuccess(responseData));
      } else {
         yield put(medicationListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeMedicationRequest = function* () {
   yield takeEvery(GET_MEDICATION, getMedicationSaga);
};
