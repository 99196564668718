import React, { useEffect, useState } from 'react';

import { Box, Button, Card, Container, Divider, Grid, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import PillMediListIcon from 'assets/pill-remainder/pillMediListIcon.png';
import DotLoader from 'component/loader/DotLoader';
import { PillRemainderDrawer } from 'component/pillRemainder/shared/prDrawer/index';
import NoData from 'component/widget/NoData';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { medicationListSagaAction } from 'reduxToolKit/actions';
import { medicationListSlice } from 'reduxToolKit/reducers';
import { pillRemainderPageTypes, pillRemainderSagaTypes, storeTypes } from 'types';
import { getUser } from 'utils/helpers/authHeader';
import { isPCAUser, isPhysician } from 'utils/helpers/userHelpers/userTypeHelpers';

import { PillMediListItem } from './PillMediListItem';

const PillRemainderMedications = () => {
   const [isView, setIsView] = useState<boolean>(true);
   const [activeMedications, setActiveMedications] = useState<pillRemainderPageTypes.IPillRemainderPillsItem[]>([]);
   const [inActiveMedications, setInActiveMedications] = useState<pillRemainderPageTypes.IPillRemainderPillsItem[]>([]);
   const [editDrawer, setEditDrawer] = useState(false);
   const [pillItemMedication, setPillItemMedication] = useState<pillRemainderPageTypes.IPillRemainderPillsItem | null>(
      null
   );
   const [activeTab, setActiveTab] = useState<string>('active');

   const history = useHistory();
   const dispatch = useDispatch();
   const { data: medicationsList, isLoading } = useSelector((state: storeTypes.IStore) => state.medicationsList);
   const { medicationsListReset } = medicationListSlice.actions;

   useEffect(() => {
      if (medicationsList) {
         setActiveMedications(medicationsList.active);
         setInActiveMedications(medicationsList.in_active);
      }
      if (isLoading) {
         clearMedications();
      }
   }, [medicationsList, isLoading]);

   const handleCloseDrawer = () => {
      setEditDrawer(!editDrawer);
   };

   const setMedicationEdit = (isEdit: boolean) => {
      setIsView(isEdit);
   };

   const clearMedications = () => {
      setActiveMedications([]);
      setInActiveMedications([]);
   };

   const clickActiveAndInActiveMedicine = (activeMedi: pillRemainderPageTypes.IPillRemainderPillsItem) => {
      setPillItemMedication(activeMedi);
      setIsView(true);
      handleCloseDrawer();
   };

   const clickEditActiveAndInActiveMedicine = (activeMedi: pillRemainderPageTypes.IPillRemainderPillsItem) => {
      setPillItemMedication(activeMedi);
      setIsView(false);
      handleCloseDrawer();
   };

   useEffect(() => {
      const checkUserBoolean = isPCAUser(getUser()) || isPhysician(getUser());
      if (!checkUserBoolean) {
         history.push('/login');
      }
   }, []);

   useEffect(() => {
      const params: pillRemainderSagaTypes.IPillActiveAndInactiveMedicineList = {
         from_type: 'pill',
      };
      dispatch(medicationListSagaAction(params));
      return () => {
         dispatch(medicationsListReset());
      };
   }, []);

   return (
      <Box mt={15} className="pill-remainder">
         <Container className="container-maxWidth" style={{ marginBottom: '10px' }}>
            <Card>
               <Grid container xs={12} direction="column">
                  <Grid item xs={12}>
                     <Container maxWidth={'lg'}>
                        <Box p={3}>
                           <Grid container direction="row" justify="space-between">
                              <Grid item>
                                 <Typography className="pill-title">Medications</Typography>
                              </Grid>
                              <Grid item>
                                 <Grid container spacing={3}>
                                    <Grid item>
                                       <Link to="/pill-reminder-list/patient">
                                          <Button className="medications-back-btn">
                                             {' '}
                                             <ArrowBackIosIcon fontSize="small" />
                                             Back
                                          </Button>
                                       </Link>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Box>
                     </Container>
                     <Divider />
                  </Grid>
                  <Grid item xs={12}>
                     <Box className="trials-container">
                        <Box className="global-search pill-medi-tad-padding" py={2} px={2}>
                           <Box className="clinical-trials-search pl-4 pr-4">
                              <Grid container xs={12} spacing={2} direction="row">
                                 <Grid item xs={6} className="grid-first">
                                    <Grid container spacing={2} direction="row">
                                       <Grid item>
                                          <Typography
                                             onClick={() => {
                                                setActiveTab('active');
                                             }}
                                             component="span"
                                             className={`tab-text ${activeTab === 'active' ? `tab-active` : ''}`}
                                          >
                                             {`Active`}
                                          </Typography>
                                       </Grid>
                                       <Grid item>
                                          <Typography
                                             onClick={() => {
                                                setActiveTab('inactive');
                                             }}
                                             component="span"
                                             className={`tab-text ${activeTab === 'inactive' ? `tab-active` : ''}`}
                                          >
                                             {`Inactive`}
                                          </Typography>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Box>
                        <Box>
                           {activeTab === 'active' ? (
                              <Box className="pill-medications-list">
                                 <Grid container xs={12} direction="row" spacing={3}>
                                    {isLoading && <DotLoader />}
                                    {!isLoading && activeMedications.length === 0 && (
                                       <div className="text-center col-12">
                                          <NoData />
                                       </div>
                                    )}
                                    {activeMedications.length > 0 &&
                                       activeMedications.map(
                                          (activeMedi: pillRemainderPageTypes.IPillRemainderPillsItem, j: number) => {
                                             return (
                                                <Grid item xs={6} key={j}>
                                                   <PillMediListItem
                                                      activeInactiveMedi={activeMedi}
                                                      clickEditActiveAndInActiveMedicine={
                                                         clickEditActiveAndInActiveMedicine
                                                      }
                                                      clickActiveAndInActiveMedicine={clickActiveAndInActiveMedicine}
                                                   />
                                                </Grid>
                                             );
                                          }
                                       )}
                                 </Grid>
                              </Box>
                           ) : (
                              <Box className="pill-medications-list">
                                 <Grid container xs={12} direction="row" spacing={3}>
                                    {isLoading && <DotLoader />}
                                    {!isLoading && inActiveMedications.length === 0 && (
                                       <div className="text-center col-12">
                                          <NoData />
                                       </div>
                                    )}
                                    {inActiveMedications.length > 0 &&
                                       inActiveMedications.map(
                                          (inActiveMedi: pillRemainderPageTypes.IPillRemainderPillsItem, j: number) => {
                                             return (
                                                <Grid item xs={6} key={j}>
                                                   <PillMediListItem
                                                      activeInactiveMedi={inActiveMedi}
                                                      clickEditActiveAndInActiveMedicine={
                                                         clickEditActiveAndInActiveMedicine
                                                      }
                                                      clickActiveAndInActiveMedicine={clickActiveAndInActiveMedicine}
                                                   />
                                                </Grid>
                                             );
                                          }
                                       )}
                                 </Grid>
                              </Box>
                           )}
                        </Box>
                     </Box>
                  </Grid>
                  {/* <>
                     <Grid item>
                        <Box className="pill-active-medicine" mb={2}>
                           <Button disabled className="pill-active-medicine-title">
                              Active medicine
                           </Button>
                        </Box>
                     </Grid>
                     {isLoading && <DotLoader />}
                     {!isLoading && activeMedications.length === 0 && <NoData />}
                     <Grid item>
                        {activeMedications.length > 0 &&
                           activeMedications.map(
                              (activeMedi: pillRemainderPageTypes.IPillRemainderPillsItem, i: number) => {
                                 return (
                                    <Box key={i}>
                                       <Box
                                          p={3}
                                          ml={7}
                                          width="90%"
                                          className="pill-medi-list"
                                          onClick={() => {
                                             clickActiveAndInActiveMedicine(activeMedi);
                                          }}
                                       >
                                          <Container maxWidth="lg">
                                             <Grid container direction="row" spacing={2}>
                                                <Grid item>
                                                   <img src={PillMediListIcon} alt="img" />
                                                </Grid>
                                                <Grid item>
                                                   <Divider orientation="vertical" />
                                                </Grid>
                                                <Grid item>
                                                   <Typography className="pill-name">{activeMedi.name}</Typography>
                                                </Grid>
                                             </Grid>
                                          </Container>
                                       </Box>
                                       <Box width="90%" ml={7}>
                                          <Divider />
                                       </Box>
                                    </Box>
                                 );
                              }
                           )}
                     </Grid>
                  </>
                  <>
                     <Grid item>
                        <Box className="pill-active-medicine" mb={2}>
                           <Button disabled className="pill-active-medicine-title">
                              Inactive medicine
                           </Button>
                        </Box>
                     </Grid>
                     {isLoading && <DotLoader />}
                     {!isLoading && inActiveMedications.length === 0 && <NoData />}
                     <Grid item>
                        {inActiveMedications?.length > 0 &&
                           inActiveMedications.map(
                              (inActiveMedi: pillRemainderPageTypes.IPillRemainderPillsItem, j: number) => {
                                 return (
                                    <Box key={j}>
                                       <Box
                                          p={3}
                                          ml={7}
                                          width="90%"
                                          className="pill-medi-list"
                                          onClick={() => {
                                             clickActiveAndInActiveMedicine(inActiveMedi);
                                          }}
                                       >
                                          <Container maxWidth="lg">
                                             <Grid container direction="row" spacing={2}>
                                                <Grid item>
                                                   <img src={PillMediListIcon} alt="img" />
                                                </Grid>
                                                <Grid item>
                                                   <Divider orientation="vertical" />
                                                </Grid>
                                                <Grid item>
                                                   <Typography className="pill-name">{inActiveMedi.name}</Typography>
                                                </Grid>
                                             </Grid>
                                          </Container>
                                       </Box>
                                       <Box width="90%" ml={7}>
                                          <Divider />
                                       </Box>
                                    </Box>
                                 );
                              }
                           )}
                     </Grid>
                  </> */}
               </Grid>
            </Card>
         </Container>
         {editDrawer && (
            <PillRemainderDrawer
               handleClose={handleCloseDrawer}
               pillItem={null}
               pillItemMedication={pillItemMedication}
               type={''}
               isView={isView}
               setMedicationEdit={setMedicationEdit}
               selectedDate={null}
               clearMedications={clearMedications}
               isFromHome={false}
            />
         )}
      </Box>
   );
};

export default PillRemainderMedications;
