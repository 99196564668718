import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { PILL_REMAINDER_ADD_SAGA } from 'reduxToolKit/actions';
import { commonSlice, pillRemainderAddSlice } from 'reduxToolKit/reducers';
import { pillRemainderSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   pillRemainderAddRemainderRequest,
   pillRemainderAddRemainderSuccess,
   pillRemainderAddRemainderFailed,
} = pillRemainderAddSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const pillRemainderAdd = function* (
   pillRemainderAdd: PayloadAction<pillRemainderSagaTypes.IPillRemainderAddSagaParams>
) {
   yield put(pillRemainderAddRemainderRequest(pillRemainderAdd.payload));
   try {
      const data = pillRemainderAdd.payload;
      const response: AxiosResponse = yield apiCall({ data, ...API.pillRemainderAdd });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(pillRemainderAddRemainderSuccess(resObj));
      } else {
         yield put(pillRemainderAddRemainderFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takePillRemainderAddRequest = function* () {
   yield takeEvery(PILL_REMAINDER_ADD_SAGA, pillRemainderAdd);
};
