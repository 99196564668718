import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { feedSagaTypes, peopleNearMeReducerTypes } from 'types';
import { setDeviceNotificationToken, setDeviceToken } from 'utils/helpers/authHeader';

const initialState: peopleNearMeReducerTypes.IDrugSpecific = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};

export const notificationAddDeviceTokenSlice = createSlice({
   name: 'notificationAddDeviceToken',
   initialState: initialState,
   reducers: {
      notificationAddDeviceTokenLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      notificationAddDeviceTokenRequest: (
         state,
         action: PayloadAction<feedSagaTypes.INotificationAddDeviceTokenProps>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      notificationAddDeviceTokenSuccess: (state, action: any) => {
         setDeviceToken('true');
         setDeviceNotificationToken(String(action.payload.device_token));
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      notificationAddDeviceTokenFailed: (state, action: PayloadAction<peopleNearMeReducerTypes.IDrugSpecific>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      notificationAddDeviceTokenReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
