import { createAction } from '@reduxjs/toolkit';
import { clinicAdminSagaTypes, oncoKlinicSagatypes, postShareSagaTypes, userSagaTypes } from 'types';

export const MY_PATIENTS_LIST = 'MY_PATIENTS_LIST';
export const myPatientsAction = createAction<clinicAdminSagaTypes.IClinicNursePatients>(MY_PATIENTS_LIST);

export const GET_CLINICAL_ALL_PATIENTS = 'GET_CLINICAL_ALL_PATIENTS';
export const getClinicalAllPatientsAction = createAction<clinicAdminSagaTypes.IGetClinicalAllPatients>(
   GET_CLINICAL_ALL_PATIENTS
);

export const MY_CLINIC_PATIENTS_LIST = 'MY_CLINIC_PATIENTS_LIST';
export const myClinicPatientsAction = createAction<postShareSagaTypes.IAskADocList>(MY_CLINIC_PATIENTS_LIST);

export const MY_CLINIC_ADD_PATIENTS = 'MY_CLINIC_ADD_PATIENTS';
export const myClinicAddPatientAction = createAction<postShareSagaTypes.IMyClinicAddPatient>(MY_CLINIC_ADD_PATIENTS);

export const MY_CLINIC_UPDATE_PATIENTS = 'MY_CLINIC_UPDATE_PATIENTS';
export const updatePatientAction = createAction<userSagaTypes.IUpdatePatientAction>(MY_CLINIC_UPDATE_PATIENTS);

export const EDUCATIONS_VIDEOS = 'EDUCATIONS_VIDEOS';
export const educationVideos = createAction<postShareSagaTypes.IEducationVideos>(EDUCATIONS_VIDEOS);

export const LATEST_VIEWED_CLINIC = 'LATEST_VIEWED_CLINIC';
export const latestViewedClinic = createAction(LATEST_VIEWED_CLINIC);

export const MY_TREATING_PHYSICIANS = 'MY_TREATING_PHYSICIANS';
export const myTreatingPhysicians = createAction<postShareSagaTypes.IMyTreatingPhysicians>(MY_TREATING_PHYSICIANS);

export const MY_CLINIC_PHYSICIANS = 'MY_CLINIC_PHYSICIANS';
export const myClinicPhysicians = createAction<postShareSagaTypes.IEducationVideos>(MY_CLINIC_PHYSICIANS);

export const MY_CLINIC_PHYSICIANS_REQ = 'MY_CLINIC_PHYSICIANS_REQ';
export const myClinicPhysiciansReq = createAction<postShareSagaTypes.IMyClinicPhysiciansReq>(MY_CLINIC_PHYSICIANS_REQ);

export const MY_CLINIC_PHYSICIANS_CANCEL_REQ = 'MY_CLINIC_PHYSICIANS_CANCEL_REQ';
export const myClinicPhysicianCancelReq = createAction<postShareSagaTypes.IMyClinicPhysicianCancelReq>(
   MY_CLINIC_PHYSICIANS_CANCEL_REQ
);

export const GET_NURSE_CLINIC = 'GET_NURSE_CLINIC';
export const getNurseClinicAction = createAction(GET_NURSE_CLINIC);

export const GET_MY_CLINIC_LIST = 'GET_MY_CLINIC_LIST';
export const getMyClinicList = createAction<oncoKlinicSagatypes.IMyClinicList>(GET_MY_CLINIC_LIST);

export const GET_MY_APPOINTMENTS = 'GET_MY_APPOINTMENTS';
export const getMyAppointments = createAction<oncoKlinicSagatypes.IGetMyAppointments>(GET_MY_APPOINTMENTS);

export const ADD_LATEST_VIEWED_CLINIC = 'ADD_LATEST_VIEWED_CLINIC';
export const addLatestViewedClinicAction = createAction<oncoKlinicSagatypes.IAddLatestViewedClinic>(
   ADD_LATEST_VIEWED_CLINIC
);

export const CHECK_IS_MY_PATIENT_ACTION = 'CHECK_IS_MY_PATIENT_ACTION';
export const checkIsMyPatientAction = createAction<userSagaTypes.IPatientId>(CHECK_IS_MY_PATIENT_ACTION);

export const ONCKLINIC_UPCOMMING_MEETING = 'ONCKLINIC_UPCOMMING_MEETING';
export const upComingMeetingAction = createAction<userSagaTypes.IUpcommingMeeting>(ONCKLINIC_UPCOMMING_MEETING);
