import { createSlice } from '@reduxjs/toolkit';
import { IInviteUserDetailsReducer } from 'types/userInvite/userInviteEmailReducerTypes';
import { messages } from 'utils/constants/messages';

const initialState: IInviteUserDetailsReducer = {
   isLoading: false,
   error: '',
   message: '',
   userDetail: null,
   userDetailPersist: null,
};
const userDetailsSlice = createSlice({
   name: 'userDetail',
   initialState: initialState,
   reducers: {
      getUserDetails: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getUserDetailSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            userDetail: payload?.data,
            userDetailPersist: payload?.data,
            message: messages.success,
            isLoading: false,
         };
      },
      getUserDetailFailed: (state, action) => {
         return {
            ...state,
            userDetail: null,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      getUserReset: (state) => {
         return {
            ...state,
            userDetail: null,
            isLoading: false,
            message: '',
            error: '',
         };
      },
      getUserMessageReset: (state) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: '',
         };
      },
   },
});
export { userDetailsSlice };
