import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MEDICAL_ENQUIRES_ADD_SAGA } from 'reduxToolKit/actions';
import { commonSlice, medicalEnqAddSlice } from 'reduxToolKit/reducers';
import { medicalEnqSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { medicalEnqAddView, medicalEnqAddViewSuccess, medicalEnqAddViewFailed } = medicalEnqAddSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const medicalEnqAdd = function* (medicalEnqAdd: medicalEnqSagaTypes.IMedicalEnqAddPayload) {
   yield put(medicalEnqAddView(medicalEnqAdd.payload));
   try {
      const data = medicalEnqAdd.payload;
      const response: AxiosResponse = yield apiCall({ data, ...API.medicalEnqAdd });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(medicalEnqAddViewSuccess(resObj));
      } else {
         yield put(medicalEnqAddViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeMedicalEnqAddRequest = function* () {
   yield takeEvery(MEDICAL_ENQUIRES_ADD_SAGA, medicalEnqAdd);
};
