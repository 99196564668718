import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_ONE_DRUGS_CANCER_SPECIFIC } from 'reduxToolKit/actions';
import { commonSlice, drugSpecificListSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   drugSpecificListViewRequest,
   drugSpecificListViewSuccess,
   drugSpecificListFailed,
} = drugSpecificListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const cancerSpecificSaga = function* (cancerTypePayload: any) {
   yield put(drugSpecificListViewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getDrugCancerSpecific });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            data: responseData.data,
            message: response.data?.message,
         };
         yield put(drugSpecificListViewSuccess(oncDriveList));
      } else {
         yield put(drugSpecificListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeCancerSpecific = function* () {
   yield takeEvery(GET_ONE_DRUGS_CANCER_SPECIFIC, cancerSpecificSaga);
};
