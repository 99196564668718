import React, { useCallback, useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, ButtonGroup, Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { pillRemainderSagaTypes } from 'types';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';
import { convertOnlyUTCTime } from 'utils/helpers/dateHelpers';

const PillPerDay = (props: any) => {
   const [timeCount, setTimeCount] = useState<number>(1);
   const [timeArray, setTimeArray] = useState<pillRemainderSagaTypes.IPillRemainderMedTime[]>([
      { time: '', dateTime: null },
   ]);
   const [validation, setValidation] = useState(false);

   const clickIncrement = useCallback(() => {
      const count = timeCount + 1;
      setTimeCount(count);
      const array = timeArray;
      array.push({ time: '', dateTime: null });
      setTimeArray(array);
   }, [timeCount, timeArray]);

   const clickDecrement = useCallback(() => {
      if (timeCount > 1) {
         const count = timeCount - 1;
         setTimeCount(count);
         const array = timeArray;
         array.pop();
         setTimeArray(array);
      }
   }, [timeCount, timeArray]);

   const changeTime = useCallback(
      (date: MaterialUiPickersDate, i: number) => {
         if (date) {
            const convertOnlyUTCTime1 = convertOnlyUTCTime(date);
            const array = listParseAndStringify(timeArray);
            array[i].time = convertOnlyUTCTime1;
            array[i].dateTime = date;
            setTimeArray(array);
         }
      },
      [timeArray]
   );

   const setActionText = () => {
      let action = 'Next';
      if (props.type === 'every_day') {
         action = 'Submit';
      }
      return action;
   };

   const clickNext = useCallback(() => {
      if (timeArray.length > 0) {
         const index = timeArray.findIndex((x: pillRemainderSagaTypes.IPillRemainderMedTime) => x.time.trim() === '');
         if (index !== -1) {
            setValidation(true);
            return;
         }
         props.pillRemainderTimeArray(timeArray);
      }
   }, [timeCount, timeArray]);

   useEffect(() => {
      setTimeCount(props?.timeArray?.length || 1);
      setTimeArray(props?.timeArray);
   }, []);

   return (
      <Box>
         <Typography align="center" className="pill-add-text">
            How many times per day
         </Typography>
         <Box display="flex" justifyContent="center" mt={2}>
            <ButtonGroup variant="contained" className="btn-grp-schedule">
               <Button
                  style={{ textIndent: '8px' }}
                  className={timeCount === 1 ? 'btn-schedule-disable' : 'btn-schedule'}
                  onClick={clickDecrement}
                  disabled={timeCount === 1 ? true : false}
               >
                  -
               </Button>
               <Box className="btn-schedule-duration">
                  <Button className="btn-schedule-time" disabled>
                     {timeCount}
                  </Button>
               </Box>
               <Button className={'btn-schedule'} onClick={clickIncrement}>
                  +
               </Button>
            </ButtonGroup>
         </Box>
         <Box ml={5} mt={2}>
            <Grid container direction="row" spacing={3}>
               {timeArray.length > 0 &&
                  timeArray.map((time: pillRemainderSagaTypes.IPillRemainderMedTime, i: number) => {
                     return (
                        <Grid item key={i}>
                           <Box ml={1.5}>
                              <Typography className="pill-per-day-time-text">{`Time-${i + 1}`}</Typography>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                 <TimePicker
                                    inputVariant="standard"
                                    margin="normal"
                                    id="time-picker"
                                    label=""
                                    placeholder="Select time"
                                    className="time-pick form-control"
                                    value={time.dateTime}
                                    onChange={(date: MaterialUiPickersDate) => {
                                       changeTime(date, i);
                                    }}
                                    InputProps={{
                                       startAdornment: (
                                          <InputAdornment position="start">
                                             <IconButton>
                                                <AccessTimeIcon />
                                             </IconButton>
                                          </InputAdornment>
                                       ),
                                    }}
                                 />
                              </MuiPickersUtilsProvider>
                           </Box>
                           {validation && time.time.trim() === '' && (
                              <Box className="errorMsg ml-3 mt-4 pt-2">{'Please select time'}</Box>
                           )}
                        </Grid>
                     );
                  })}
            </Grid>
         </Box>
         <Box mb={4} mx="auto" mt={5}>
            <Grid container direction="row" spacing={3} justify="center">
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="pill-add-back-button"
                     fullWidth
                     onClick={() => {
                        if (props.type === 'every_day') {
                           props?.pillRemainderTypeBack(props?.type);
                           props?.pillRemainderTimeArrayBack(timeArray);
                        }
                     }}
                  >
                     Back
                  </Button>
               </Grid>
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="telemedicine__modal--btn"
                     fullWidth
                     onClick={clickNext}
                  >
                     {setActionText()}
                  </Button>
               </Grid>
            </Grid>
         </Box>
      </Box>
   );
};

export { PillPerDay };
