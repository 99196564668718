import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_EDIT_APPOINTMENT_COMMENTS } from 'reduxToolKit/actions';
import { addEditAppointmentCommentSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   addEditAppointmentCommentView,
   addEditAppointmentCommentViewSuccess,
   addEditAppointmentCommentViewFailed,
} = addEditAppointmentCommentSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const addEditAppointmentComment = function* (payloadId: any) {
   yield put(addEditAppointmentCommentView(payloadId.payload));
   try {
      const data = payloadId.payload;
      let url = API.addAppointmentComment;
      if (data.commentId) {
         url = {
            apiPath: `${API.editAppointmentComment.apiPath}${data.commentId}`,
            action: API.editAppointmentComment.action,
         };
      }
      const response: AxiosResponse = yield apiCall({ data, ...url });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(addEditAppointmentCommentViewSuccess(responseData));
      } else {
         yield put(addEditAppointmentCommentViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeAddEditAppointmentCommentRequest = function* () {
   yield takeEvery(ADD_EDIT_APPOINTMENT_COMMENTS, addEditAppointmentComment);
};
