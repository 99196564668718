import { createSlice } from '@reduxjs/toolkit';
import { videoLibraryPageTypes } from 'types';
import { IVideoLibraryCategoryListReducer } from 'types/videoLibrary/videoLibraryReducerTypes';

const initialState: IVideoLibraryCategoryListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   pagination: {
      current_page: null,
      last_page: null,
      first_page_url: '',
      last_page_url: '',
      next_page_url: '',
      prev_page_url: '',
      total: 0,
   },
};

export const videoLibraryCategoryListSlice = createSlice({
   name: 'videoLibraryCategoryList',
   initialState: initialState,
   reducers: {
      videoLibraryCategoryListViewRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      videoLibraryCategoryListViewSuccess: (state, action: any) => {
         const { payload } = action;
         const payloadList = getCategoryListOrderChange(payload.list);
         return {
            ...state,
            pagination: { ...payload.pagination },
            list: payload.pagination.current_page === 1 ? payloadList : [...state.list, ...payloadList],
            isLoading: false,
         };
      },
      videoLibraryCategoryListViewFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      videoLibraryCategoryListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

//get category list order
export const getCategoryListOrderChange = (list: videoLibraryPageTypes.IVideoCategory[]) => {
   const categoryArray: videoLibraryPageTypes.IVideoCategory[] = [];
   const sortedArray = list.sort((a: videoLibraryPageTypes.IVideoCategory, b: videoLibraryPageTypes.IVideoCategory) => {
      const aName = a.name?.toLowerCase() || '';
      const bName = b.name?.toLowerCase() || '';
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
   });
   sortedArray.forEach((category: videoLibraryPageTypes.IVideoCategory) => {
      if (category?.name === 'Cancer Care Plan') {
         categoryArray[0] = category;
      } else if (category?.name === 'Symptom Management') {
         categoryArray[1] = category;
      } else if (category?.name === 'Clinical Trials') {
         categoryArray[2] = category;
      }
   });
   const convertArray: videoLibraryPageTypes.IVideoCategory[] = [];
   sortedArray.forEach((categoryObj: videoLibraryPageTypes.IVideoCategory) => {
      const index = categoryArray.findIndex((c: videoLibraryPageTypes.IVideoCategory) => c.name === categoryObj.name);
      if (index === -1) {
         convertArray.push(categoryObj);
      }
   });
   return [...categoryArray, ...convertArray];
};
