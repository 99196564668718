import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { modalPatientInsuranceReducerTypes, modalPatientInsuranceSagaTypes } from 'types';

const initialState: modalPatientInsuranceReducerTypes.IModalPatientInsuranceUpdateReducer = {
   error: '',
   message: '',
   isLoading: false,
};

export const modalUpdatePatientInsuranceSlice = createSlice({
   name: 'modalUpdatePatientInsurance',
   initialState: initialState,
   reducers: {
      modalUpdatePatientInsuranceRequest: (
         state,
         action: PayloadAction<modalPatientInsuranceSagaTypes.IModalPatientInsuranceUpdateSagaPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      modalUpdatePatientInsuranceSuccess: (
         state,
         action: PayloadAction<modalPatientInsuranceReducerTypes.IModalPatientInsuranceUpdateSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      modalUpdatePatientInsuranceFailed: (
         state,
         action: PayloadAction<modalPatientInsuranceReducerTypes.IModalPatientInsuranceUpdateFailedPayload>
      ) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      modalUpdatePatientInsuranceReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
