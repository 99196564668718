import { createAction } from '@reduxjs/toolkit';
import { adminJobSagaTypes } from 'types';

export const CREATE_JOB_ACTION = 'CREATE_JOB_ACTION';
export const createJobAction = createAction<adminJobSagaTypes.IRecruiterJobCreateSagaPayload>(CREATE_JOB_ACTION);

export const GET_APPLICANTS_LIST = 'GET_APPLICANTS_LIST';
export const getApplicantsList = createAction<adminJobSagaTypes.IAdminJobApplicantsList>(GET_APPLICANTS_LIST);

export const ACCEPT_REJECT_SHORT_PHYSICIAN = 'ACCEPT_REJECT_SHORT_PHYSICIAN';
export const acceptRejectShortPhysician = createAction<adminJobSagaTypes.IAcceptRejectShortPhysician>(
   ACCEPT_REJECT_SHORT_PHYSICIAN
);

export const RECRUITER_JOB_CREATE_ACTION_SAGA = 'RECRUITER_JOB_CREATE_ACTION_SAGA';
export const recruiterJobCreateAction = createAction<adminJobSagaTypes.IRecruiterJobCreateSagaPayload>(
   RECRUITER_JOB_CREATE_ACTION_SAGA
);

export const RECRUITER_JOB_DETAIL_SAGA = 'RECRUITER_JOB_DETAIL_SAGA';
export const getRecruiterJobDetailSagaAction = createAction<adminJobSagaTypes.IRecruiterJobDetailSagaPayload>(
   RECRUITER_JOB_DETAIL_SAGA
);

export const RECRUITER_JOBS_LIST_SAGA = 'RECRUITER_JOBS_LIST_SAGA';
export const recruiterJobsListAction = createAction<adminJobSagaTypes.IRecruiterJobsListSagaPayload>(
   RECRUITER_JOBS_LIST_SAGA
);

export const RECRUITER_JOB_DELETE_SAGA = 'RECRUITER_JOB_DELETE_SAGA';
export const recruiterJobDeleteAction = createAction<adminJobSagaTypes.IRecruiterJobDeleteSagaPayload>(
   RECRUITER_JOB_DELETE_SAGA
);
