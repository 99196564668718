import { createSlice } from '@reduxjs/toolkit';
import { fillProfileReducerTypes } from 'types';

const initialState: fillProfileReducerTypes.IPhoneSectionReducerType = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
export const connectionReqDeleteSlice = createSlice({
   name: 'connectionReqDeleteSlice',
   initialState: initialState,
   reducers: {
      connectionReqDeleteRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      connectionReqDeleteSuccess: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            ...action.payload,
         };
      },
      connectionReqDeleteFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      connectionReqDeleteReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
