import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { modalConnectionInviteReducerTypes, modalConnectionInviteSagaTypes } from 'types';

const initialState: modalConnectionInviteReducerTypes.IModalInviteViaMailReducer = {
   error: '',
   message: '',
   isLoading: false,
};

export const modalInviteViaMailSlice = createSlice({
   name: 'modalConnectionInvite',
   initialState: initialState,
   reducers: {
      modalInviteViaMailRequest: (
         state,
         action: PayloadAction<modalConnectionInviteSagaTypes.IModalInviteViaMailSagaPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      modalInviteViaMailSuccess: (
         state,
         action: PayloadAction<modalConnectionInviteReducerTypes.IModalInviteViaMailSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      modalInviteViaMailFailed: (
         state,
         action: PayloadAction<modalConnectionInviteReducerTypes.IModalInviteViaMailFailedPayload>
      ) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      modalInviteViaMailReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
