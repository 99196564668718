import { AxiosResponse } from 'axios';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { NOTIFICATION_ADD_DEVICE_TOKEN } from 'reduxToolKit/actions';
import { commonSlice, notificationAddDeviceTokenSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   notificationAddDeviceTokenLoading,
   notificationAddDeviceTokenRequest,
   notificationAddDeviceTokenSuccess,
   notificationAddDeviceTokenFailed,
} = notificationAddDeviceTokenSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const notificationAddDeviceToken = function* (cancerTypePayload: any) {
   yield put(notificationAddDeviceTokenLoading());
   yield delay(500);
   yield put(notificationAddDeviceTokenRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.notificationAddDeviceToken });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(notificationAddDeviceTokenSuccess(responseData));
      } else {
         yield put(notificationAddDeviceTokenFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeNotificationAddDeviceToken = function* () {
   yield takeLatest(NOTIFICATION_ADD_DEVICE_TOKEN, notificationAddDeviceToken);
};
