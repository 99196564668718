import React, { useCallback, useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { convertOnlyUTCTime } from 'utils/helpers/dateHelpers';

const PillFirstDose = (props: any) => {
   const [firstDoseTime, setFirstDoseTime] = useState('');
   const [firstDoseDateTime, setFirstDoseDateTime] = useState<Date | null>(null);
   const [errorMessage, setErrorMessage] = useState('');

   const changeTime = useCallback((date: MaterialUiPickersDate) => {
      if (date) {
         setFirstDoseDateTime(date);
         const convertTime = convertOnlyUTCTime(date);
         setFirstDoseTime(convertTime);
         setErrorMessage('');
      }
   }, []);

   const clickNext = useCallback(() => {
      if (firstDoseTime) {
         props.pillRemainderFirstDoseTime(firstDoseTime);
         props.pillRemainderFirstDoseDateTime(firstDoseDateTime);
      } else {
         setErrorMessage('Please select time');
      }
   }, [firstDoseTime]);

   useEffect(() => {
      setFirstDoseTime(props?.firstDoseTime);
      setFirstDoseDateTime(props?.firstDoseDateTime);
   }, []);

   return (
      <Box>
         <Typography align="center" className="pill-add-text">
            When do you to take the first dose?
         </Typography>
         <Box width="80%" mx="auto">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
               <TimePicker
                  inputVariant="standard"
                  margin="normal"
                  id="time-picker"
                  label=""
                  placeholder="Select time"
                  className="time-pick form-control"
                  value={firstDoseDateTime}
                  onChange={(date: MaterialUiPickersDate) => {
                     changeTime(date);
                  }}
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position="start">
                           <IconButton>
                              <AccessTimeIcon />
                           </IconButton>
                        </InputAdornment>
                     ),
                  }}
               />
            </MuiPickersUtilsProvider>
         </Box>
         {errorMessage && <Box className="errorMsg pill-error pt-3">{errorMessage}</Box>}
         <Box mb={4} mx="auto" mt={5}>
            <Grid container direction="row" spacing={3} justify="center">
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="pill-add-back-button"
                     fullWidth
                     onClick={() => {
                        props?.pillRemainderFirstDoseTimeBack(firstDoseTime);
                        props?.pillRemainderFirstDoseDateTime(firstDoseDateTime);
                        if (
                           props.type === 'every_two_days' ||
                           props.type === 'every_fourteen_days' ||
                           props.type === 'every_twenty_one_days' ||
                           props.type === 'every_twenty_eight_days' ||
                           props.type === 'once_a_week' ||
                           props.type === 'two_days_a_week' ||
                           props.type === 'three_days_a_week' ||
                           props.type === 'five_days_a_week'
                        ) {
                           props?.pillRemainderTypeBack(props?.type);
                        } else if (props?.type === 'others') {
                           props?.pillRemainderFirstDoseTimeBackOthers(props?.type, 'other');
                        }
                     }}
                  >
                     Back
                  </Button>
               </Grid>
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="telemedicine__modal--btn"
                     fullWidth
                     onClick={clickNext}
                  >
                     Next
                  </Button>
               </Grid>
            </Grid>
         </Box>
      </Box>
   );
};

export { PillFirstDose };
