import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { audioPlayerPageTypes, fsLightBoxTypes } from 'types';
import { ICommon } from 'types/common/api';

const initialState: ICommon = {
   exception: {
      error: null,
   },
   isInvalidURLDetect: false,
   isDrawerOpen: false,
   unreadableNotifyCount: 0,
   getWalletBalance: 0,
   conversationStatus: false,
   conversationCount: 0,
   isApiLoading: false,
   selectedPlan: null,
   currentAudioPlayingId: null,
   isFslightBoxOpen: false,
};

const commonSlice = createSlice({
   name: 'common',
   initialState: initialState,
   reducers: {
      exceptionHandlerRequest: (state, action) => {
         return {
            ...state,
            exception: {
               error: action.payload,
            },
         };
      },
      invalidURLDetect: (state, action) => {
         return {
            ...state,
            isInvalidURLDetect: action.payload,
         };
      },
      drawerStatusUpdates: (state, action: PayloadAction<boolean>) => {
         document.body.style.overflow = 'hidden !important';
         if (action.payload === true) {
            document.body.style.overflow = 'hidden !important';
         }
         return {
            ...state,
            isDrawerOpen: action.payload,
         };
      },
      drawerStatusReset: (state) => {
         return {
            ...state,
            isDrawerOpen: false,
         };
      },
      unreadableNotifyCountAction: (state, action: PayloadAction<number>) => {
         return {
            ...state,
            unreadableNotifyCount: action.payload,
         };
      },
      getWalletBalanceAction: (state, action: PayloadAction<number>) => {
         return {
            ...state,
            getWalletBalance: action.payload,
         };
      },
      conversationStatus: (state, action: PayloadAction<boolean>) => {
         return {
            ...state,
            conversationStatus: action.payload,
         };
      },
      conversationCountAction: (state, action: PayloadAction<number>) => {
         return {
            ...state,
            conversationCount: action.payload,
         };
      },
      updateApiLoadingState: (state, action: PayloadAction<boolean>) => {
         return {
            ...state,
            isApiLoading: action.payload,
         };
      },
      setSelectedSubscriptionPlan: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            selectedPlan: action.payload,
         };
      },
      setAudioPlayerState: (state, action: PayloadAction<audioPlayerPageTypes.IOnAudioPlayingId>) => {
         return {
            ...state,
            currentAudioPlayingId: action.payload?.currentAudioPlayingId,
         };
      },
      updateFslightBoxOpen: (state, action: PayloadAction<fsLightBoxTypes.IUpdateFslightBoxOpen>) => {
         return {
            ...state,
            isFslightBoxOpen: action.payload?.isFslightBoxOpen,
         };
      },
   },
});

export { commonSlice };
