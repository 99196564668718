import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subscriptionDetailsReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: subscriptionDetailsReducerTypes.ISubscriptionDetailsReducerTypes = {
   isLoading: false,
   subscriptionPlanActiveDetails: {
      status: null,
      message: '',
      id: 0,
      amount: 0,
      error: '',
   },
   subscriptionPlansList: {
      data: null,
      isLoading: false,
      message: '',
      error: '',
   },
   subscriptionGetPlan: {
      data: null,
      isLoading: false,
      message: '',
      error: '',
   },
   subscriptionCancelPlan: {
      data: null,
      isLoading: false,
      message: '',
      error: '',
   },
   subscriptionCreatePlan: {
      data: null,
      isLoading: false,
      message: '',
      error: '',
   },
   couponCodeDetails: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   subscriptionStripePortal: {
      data: null,
      isLoading: false,
      message: '',
      error: '',
   },
   checkCouponWallet: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   subscriptionOneTimePurchase: {
      data: null,
      isLoading: false,
      message: '',
      error: '',
   },
   subscriptionCallback: {
      data: null,
      isLoading: false,
      message: '',
      error: '',
   },
};

export const subscriptionDetailsSlice = createSlice({
   name: 'subscriptionDetails',
   initialState: initialState,
   reducers: {
      checkPlanActiveDetailsRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      checkPlanActiveDetailsSuccess: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.ISubscriptionDetailsSuccessPayload>
      ) => {
         return {
            ...state,
            isLoading: false,
            subscriptionPlanActiveDetails: {
               status: action.payload?.status,
               message: action.payload?.message,
               id: action.payload?.id,
               amount: action.payload?.amount,
               error: '',
            },
         };
      },
      checkPlanActiveDetailsFailed: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IPhysicianJobsListFailurePayload>
      ) => {
         return {
            ...state,
            isLoading: false,
            subscriptionPlanActiveDetails: {
               error: action.payload?.error,
               status: false,
               id: 0,
               amount: 0,
               message: '',
            },
         };
      },
      checkPlanActiveDetailsMessageReset: (state) => {
         return {
            ...state,
            subscriptionPlanActiveDetails: {
               ...state.subscriptionPlanActiveDetails,
               message: '',
               error: '',
            },
         };
      },
      checkPlanActiveDetailsReset: (state) => {
         return {
            ...state,
            subscriptionPlanActiveDetails: {
               ...initialState.subscriptionPlanActiveDetails,
            },
         };
      },
      //getSubscriptionPlansList
      getSubscriptionPlansListRequest: (state) => {
         return {
            ...state,
            subscriptionPlansList: {
               ...state.subscriptionPlansList,
               isLoading: true,
            },
         };
      },
      getSubscriptionPlansListSuccess: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListSuccessPayload>
      ) => {
         return {
            ...state,
            subscriptionPlansList: {
               ...state.subscriptionPlansList,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      getSubscriptionPlansListFailed: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListFailurePayload>
      ) => {
         return {
            ...state,
            subscriptionPlansList: {
               ...state.subscriptionPlansList,
               error: action.payload.error,
               isLoading: false,
            },
         };
      },

      getSubscriptionPlansListReset: (state) => {
         return {
            ...state,
            subscriptionPlansList: {
               ...initialState.subscriptionPlansList,
            },
         };
      },
      //subscriptionGetPlan
      subscriptionGetPlanRequest: (state) => {
         return {
            ...state,
            subscriptionGetPlan: {
               ...state.subscriptionGetPlan,
               isLoading: true,
            },
         };
      },
      subscriptionGetPlanSuccess: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListSuccessPayload>
      ) => {
         return {
            ...state,
            subscriptionGetPlan: {
               ...state.subscriptionGetPlan,
               ...action.payload,
               message: action.payload.message || messages.success,
               isLoading: false,
            },
         };
      },
      subscriptionGetPlanFailed: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListFailurePayload>
      ) => {
         return {
            ...state,
            subscriptionGetPlan: {
               ...state.subscriptionGetPlan,
               error: action.payload.error,
               isLoading: false,
            },
         };
      },

      subscriptionGetPlanResetMessage: (state) => {
         return {
            ...state,
            subscriptionGetPlan: {
               ...state.subscriptionGetPlan,
               message: '',
               error: '',
               isLoading: false,
            },
         };
      },

      subscriptionGetPlanReset: (state) => {
         return {
            ...state,
            subscriptionGetPlan: {
               ...initialState.subscriptionGetPlan,
            },
         };
      },
      //subscriptionCancelPlan
      subscriptionCancelPlanRequest: (state) => {
         return {
            ...state,
            subscriptionCancelPlan: {
               ...state.subscriptionCancelPlan,
               isLoading: true,
            },
         };
      },
      subscriptionCancelPlanSuccess: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListSuccessPayload>
      ) => {
         return {
            ...state,
            subscriptionCancelPlan: {
               ...state.subscriptionCancelPlan,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      subscriptionCancelPlanFailed: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListFailurePayload>
      ) => {
         return {
            ...state,
            subscriptionCancelPlan: {
               ...state.subscriptionCancelPlan,
               error: action.payload.error,
               isLoading: false,
            },
         };
      },

      subscriptionCancelPlanReset: (state) => {
         return {
            ...state,
            subscriptionCancelPlan: {
               ...initialState.subscriptionCancelPlan,
            },
         };
      },
      //subscriptionCreatePlan
      subscriptionCreatePlanRequest: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.ISubscriptionCreatePlanRequest>
      ) => {
         return {
            ...state,
            subscriptionCreatePlan: {
               ...state.subscriptionCreatePlan,
               isLoading: true,
            },
         };
      },
      subscriptionCreatePlanSuccess: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListSuccessPayload>
      ) => {
         return {
            ...state,
            subscriptionCreatePlan: {
               ...state.subscriptionCreatePlan,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      subscriptionCreatePlanFailed: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListFailurePayload>
      ) => {
         return {
            ...state,
            subscriptionCreatePlan: {
               ...state.subscriptionCreatePlan,
               error: action.payload.error_message || messages.failed,
               isLoading: false,
            },
         };
      },

      subscriptionCreatePlanResetLoading: (state) => {
         return {
            ...state,
            subscriptionCreatePlan: {
               ...state.subscriptionCreatePlan,
               isLoading: false,
            },
         };
      },

      subscriptionCreatePlanReset: (state) => {
         return {
            ...state,
            subscriptionCreatePlan: {
               data: null,
               isLoading: false,
               message: '',
               error: '',
            },
         };
      },

      checkCouponCodeRequest: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.ISubscriptionCheckCouponCode>
      ) => {
         return {
            ...state,
            couponCodeDetails: {
               ...state.couponCodeDetails,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      checkCouponCodeSuccess: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListSuccessPayload>
      ) => {
         return {
            ...state,
            couponCodeDetails: {
               ...state.couponCodeDetails,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      checkCouponCodeFailed: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListSuccessPayload>
      ) => {
         return {
            ...state,
            couponCodeDetails: {
               ...state.couponCodeDetails,
               ...action.payload,
               message: '',
               data: null,
               isLoading: false,
            },
         };
      },
      checkCouponCodeMessageReset: (state) => {
         return {
            ...state,
            couponCodeDetails: {
               ...state.couponCodeDetails,
               isLoading: false,
               message: '',
            },
         };
      },
      checkCouponCodeReset: (state) => {
         return {
            ...state,
            couponCodeDetails: {
               ...initialState.couponCodeDetails,
            },
         };
      },
      //subscriptionStripePortal
      subscriptionStripePortalRequest: (state) => {
         return {
            ...state,
            subscriptionStripePortal: {
               ...state.subscriptionStripePortal,
               isLoading: true,
            },
         };
      },
      subscriptionStripePortalSuccess: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListSuccessPayload>
      ) => {
         return {
            ...state,
            subscriptionStripePortal: {
               ...state.subscriptionStripePortal,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      subscriptionStripePortalFailed: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListFailurePayload>
      ) => {
         return {
            ...state,
            subscriptionStripePortal: {
               ...state.subscriptionStripePortal,
               error: action.payload.error,
               isLoading: false,
            },
         };
      },

      subscriptionStripePortalReset: (state) => {
         return {
            ...state,
            subscriptionStripePortal: {
               ...initialState.subscriptionStripePortal,
            },
         };
      },
      // subscription check wallet amount
      checkCouponWalletRequest: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.ISubscriptionCheckWalletParams>
      ) => {
         return {
            ...state,
            checkCouponWallet: {
               ...state.checkCouponWallet,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      checkCouponWalletSuccess: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListSuccessPayload>
      ) => {
         return {
            ...state,
            checkCouponWallet: {
               ...state.checkCouponWallet,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      checkCouponWalletFailed: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListSuccessPayload>
      ) => {
         return {
            ...state,
            checkCouponWallet: {
               ...state.checkCouponWallet,
               ...action.payload,
               message: '',
               data: null,
               isLoading: false,
            },
         };
      },
      checkCouponWalletMessageReset: (state) => {
         return {
            ...state,
            checkCouponWallet: {
               ...state.checkCouponWallet,
               isLoading: false,
               message: '',
            },
         };
      },
      checkCouponWalletReset: (state) => {
         return {
            ...state,
            checkCouponWallet: {
               ...initialState.checkCouponWallet,
            },
         };
      },
      //subscriptionOneTimePurchase
      subscriptionOneTimePurchaseRequest: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.ISubscriptionOneTimePurchaseParams>
      ) => {
         return {
            ...state,
            subscriptionOneTimePurchase: {
               ...state.subscriptionOneTimePurchase,
               isLoading: true,
            },
         };
      },
      subscriptionOneTimePurchaseSuccess: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListSuccessPayload>
      ) => {
         return {
            ...state,
            subscriptionOneTimePurchase: {
               ...state.subscriptionOneTimePurchase,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      subscriptionOneTimePurchaseFailed: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListFailurePayload>
      ) => {
         return {
            ...state,
            subscriptionOneTimePurchase: {
               ...state.subscriptionOneTimePurchase,
               error: action.payload.error,
               isLoading: false,
            },
         };
      },

      subscriptionOneTimePurchaseResetLoading: (state) => {
         return {
            ...state,
            subscriptionOneTimePurchase: {
               ...state.subscriptionOneTimePurchase,
               isLoading: false,
            },
         };
      },

      subscriptionOneTimePurchaseReset: (state) => {
         return {
            ...state,
            subscriptionOneTimePurchase: {
               ...initialState.subscriptionOneTimePurchase,
            },
         };
      },

      //subscriptionCallback
      subscriptionCallbackRequest: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.ISubscriptionCallbackParams>
      ) => {
         return {
            ...state,
            subscriptionCallback: {
               ...state.subscriptionCallback,
               isLoading: true,
            },
         };
      },
      subscriptionCallbackSuccess: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListSuccessPayload>
      ) => {
         return {
            ...state,
            subscriptionCallback: {
               ...state.subscriptionCallback,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      subscriptionCallbackFailed: (
         state,
         action: PayloadAction<subscriptionDetailsReducerTypes.IGetSubscriptionPlansListFailurePayload>
      ) => {
         return {
            ...state,
            subscriptionCallback: {
               ...state.subscriptionCallback,
               error: action.payload.error || messages.errorMessage,
               isLoading: false,
            },
         };
      },

      subscriptionCallbackResetLoading: (state) => {
         return {
            ...state,
            subscriptionCallback: {
               ...state.subscriptionCallback,
               isLoading: false,
            },
         };
      },

      subscriptionCallbackReset: (state) => {
         return {
            ...state,
            subscriptionCallback: {
               ...initialState.subscriptionCallback,
            },
         };
      },
   },
});
