import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { askADocUserProfileReducerTypes, myProfileReducerTypes, storeTypes } from 'types';
import { IEditProfile } from 'types/modelHelpers';

const initialState: askADocUserProfileReducerTypes.IAskADocUserProfileReducerPayload = {
   isLoading: false,
   data: null,
   message: '',
   error: '',
   deleteAccount: {
      isLoading: false,
      data: null,
      message: '',
      error: '',
   },
};

export const getUserProfileSlice = createSlice({
   name: 'getUserProfile',
   initialState: initialState,
   reducers: {
      getUserProfileRequest: (state, action: PayloadAction<askADocUserProfileReducerTypes.IAskADocGetUserProfile>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getUserProfileSuccess: (
         state,
         action: PayloadAction<askADocUserProfileReducerTypes.IMyProfileSuccessPayload>
      ) => {
         return {
            ...state,
            data: action.payload?.data,
            message: action.payload?.message,
            error: '',
            isLoading: false,
         };
      },
      getUserProfileFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            error: action.payload?.error,
            data: null,
            message: '',
            isLoading: false,
         };
      },

      userProfileUpdateRequest: (state, action: PayloadAction<IEditProfile>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      userProfileUpdateSuccess: (state, action: PayloadAction<myProfileReducerTypes.IUserProfileUpdateSuccess>) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            message: payload?.message,
         };
      },
      userProfileUpdateFailed: (state, action: PayloadAction<myProfileReducerTypes.IUserProfileUpdateFailure>) => {
         return {
            ...state,
            isLoading: false,
            ...action.payload,
            message: '',
         };
      },

      deleteAccountRequest: (state, action: PayloadAction<IEditProfile>) => {
         return {
            ...state,
            deleteAccount: {
               ...state.deleteAccount,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      deleteAccountSuccess: (state, action: PayloadAction<myProfileReducerTypes.IUserProfileUpdateSuccess>) => {
         const { payload } = action;
         return {
            ...state,
            deleteAccount: {
               ...state.deleteAccount,
               isLoading: false,
               message: payload?.message,
            },
         };
      },
      deleteAccountFailed: (state, action: PayloadAction<myProfileReducerTypes.IUserProfileUpdateFailure>) => {
         return {
            ...state,
            deleteAccount: {
               ...state.deleteAccount,
               ...action.payload,
               isLoading: false,
               message: '',
            },
         };
      },

      userProfileReset: () => {
         return {
            ...initialState,
         };
      },

      deleteAccountReset: (state) => {
         return {
            ...state,
            deleteAccount: {
               ...state.deleteAccount,
               ...initialState.deleteAccount,
            },
         };
      },
   },
});
