import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { askADocPageTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: askADocPageTypes.IGetAllUsersReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
   getTreatmentEstimateEnabledPhysList: {
      isLoading: false,
      message: '',
      error: '',
      list: [],
      pagination: {
         current_page: null,
         last_page: null,
         first_page_url: '',
         last_page_url: '',
         next_page_url: '',
         prev_page_url: '',
         total: 0,
      },
   },
};

export const getAllUsersListSlice = createSlice({
   name: 'getAllUsersList',
   initialState: initialState,
   reducers: {
      allUsersListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      allUsersListViewRequest: (state) => {
         return {
            ...state,
            isLoading: true,
            list: state.list,
         };
      },
      allUsersListViewSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            message: payload.message || messages.success,
            isLoading: false,
         };
      },
      allUsersListFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      allUsersListReset: (state) => {
         return {
            ...state,
            isLoading: false,
            error: '',
            message: '',
            list: [],
            current_page: 0,
            limit: 0,
            total_records: 0,
         };
      },
      // get treatment estimate enabled physicians
      treatmentEstimateEnabledPhysListRequest: (
         state,
         action: PayloadAction<askADocPageTypes.ITreatmentEstimateEnabledPhysSagaProps>
      ) => {
         return {
            ...state,
            getTreatmentEstimateEnabledPhysList: {
               ...state.getTreatmentEstimateEnabledPhysList,
               ...action.payload,
               isLoading: true,
               list:
                  action.payload.page && action.payload.page === 1
                     ? []
                     : state.getTreatmentEstimateEnabledPhysList.list,
            },
         };
      },
      treatmentEstimateEnabledPhysListSuccess: (
         state,
         action: PayloadAction<askADocPageTypes.GetTreatmentEstimateEnabledPhysList>
      ) => {
         const { payload } = action;
         return {
            ...state,
            getTreatmentEstimateEnabledPhysList: {
               pagination: payload.pagination,
               list:
                  payload.pagination.current_page === 1
                     ? payload.list
                     : [...state.getTreatmentEstimateEnabledPhysList.list, ...payload.list],
               isLoading: false,
               message: payload.message,
            },
         };
      },
      treatmentEstimateEnabledPhysListFailed: (
         state,
         action: PayloadAction<askADocPageTypes.GetTreatmentEstimateEnabledPhysList>
      ) => {
         return {
            ...state,
            getTreatmentEstimateEnabledPhysList: {
               ...state.getTreatmentEstimateEnabledPhysList,
               isLoading: false,
               error: action.payload.error,
               message: '',
            },
         };
      },
      treatmentEstimateEnabledPhysListResetMessage: (state) => {
         return {
            ...state,
            getTreatmentEstimateEnabledPhysList: {
               ...state.getTreatmentEstimateEnabledPhysList,
               message: '',
               isLoading: false,
            },
         };
      },
      treatmentEstimateEnabledPhysListReset: (state) => {
         return {
            ...state,
            getTreatmentEstimateEnabledPhysList: {
               ...initialState.getTreatmentEstimateEnabledPhysList,
            },
         };
      },
   },
});
