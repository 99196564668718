import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { eventTrackAction, GLOBAL_SEARCH_LIST_SAGA } from 'reduxToolKit/actions';
import { commonSlice, globalSearchListSlice } from 'reduxToolKit/reducers';
import { eventTrackTypes, globalSearchReducerTypes } from 'types';
import { GLOBAL_SEARCH_EVENTS } from 'utils/constants/eventTrack/globalSearchEventConstants';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   globalSearchListLoading,
   globalSearchListRequest,
   globalSearchListSuccess,
   globalSearchListFailed,
} = globalSearchListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getGlobalSearchTableData = (response: any, tabType: string) => {
   const responseData = response.data.data;
   return {
      list: responseData[tabType]?.data || [],
      pagination: {
         current_page: responseData[tabType]?.current_page || 0,
         last_page: responseData[tabType]?.last_page || 0,
         first_page_url: responseData[tabType]?.first_page_url || '',
         last_page_url: responseData[tabType]?.last_page_url || '',
         next_page_url: responseData[tabType]?.next_page_url || '',
         prev_page_url: responseData[tabType]?.prev_page_url || '',
         total: responseData[tabType]?.total || 0,
      },
      message: response.data?.message || messages.success,
   };
};

const globalSearchListSaga = function* (
   globalSearchListPayload: PayloadAction<globalSearchReducerTypes.IGlobalSearchSagaProps>
) {
   if (globalSearchListPayload.payload?.search_word !== '') {
      yield put(globalSearchListLoading());
      yield delay(500);
   }
   yield put(globalSearchListRequest(globalSearchListPayload.payload));
   try {
      const params = globalSearchListPayload.payload;
      const url = API.getGlobalSearch;
      const peopleMayYouKnow = true;
      const response: AxiosResponse = yield apiCall({ params, peopleMayYouKnow, ...url });
      if (response.status === 200) {
         const globalSearchData = {
            users: getGlobalSearchTableData(response, 'users'),
            patient: getGlobalSearchTableData(response, 'users'),
            physician: getGlobalSearchTableData(response, 'users'),
            patientAdvocate: getGlobalSearchTableData(response, 'users'),
            patientCaregiver: getGlobalSearchTableData(response, 'users'),
            organization: getGlobalSearchTableData(response, 'users'),
            drugLookup: getGlobalSearchTableData(response, 'drugs'),
            posts: getGlobalSearchTableData(response, 'posts'),
            videoLibrary: getGlobalSearchTableData(response, 'video_cipher_seeking'),
         };
         yield put(globalSearchListSuccess(globalSearchData));
         const eventData: eventTrackTypes.IEventTrack = {
            event: {
               name: GLOBAL_SEARCH_EVENTS.GLOBAL_SEARCH,
            },
         };
         yield put(eventTrackAction(eventData));
      } else {
         yield put(globalSearchListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGlobalSearchListRequest = function* () {
   yield takeLatest(GLOBAL_SEARCH_LIST_SAGA, globalSearchListSaga);
};
