import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { BOOK_SERVICE } from 'reduxToolKit/actions';
import { bookServiceSlice, commonSlice } from 'reduxToolKit/reducers';
import { locumSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { bookServiceRequest, bookServiceSuccess, bookServiceFailed } = bookServiceSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const bookService = function* (payloadState: PayloadAction<locumSagaTypes.IBookService>) {
   yield put(bookServiceRequest());
   try {
      const url = API.locumPractiseAdminPhyBookService;
      const checkUser = false;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...url });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(bookServiceSuccess(resObj));
      } else {
         yield put(bookServiceFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeBookService = function* () {
   yield takeEvery(BOOK_SERVICE, bookService);
};
