import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { conversationsReducersTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: conversationsReducersTypes.IConversationsReducerTypes = {
   isLoading: false,
   error: '',
   message: '',
   data: 0,
   getOneToOneConversation: {
      isLoading: false,
      message: '',
      error: '',
      data: null,
   },
   getConversationCreationVerifiedUser: {
      isLoading: false,
      message: '',
      error: '',
      data: null,
   },
};

export const conversationSlice = createSlice({
   name: 'conversations',
   initialState: initialState,
   reducers: {
      conversationsUnreadCountRequest: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      conversationsUnreadCountSuccess: (
         state,
         action: PayloadAction<conversationsReducersTypes.IConversationsUnreadCountGetSuccess>
      ) => {
         return {
            ...state,
            message: action.payload.message || messages.success,
            data: action.payload.data,
            isLoading: false,
            error: '',
         };
      },
      conversationsUnreadCountFailed: (
         state,
         action: PayloadAction<conversationsReducersTypes.IConversationsUnreadCountGetFailed>
      ) => {
         return {
            ...state,
            error: action.payload.error,
            isLoading: false,
            data: 0,
         };
      },

      conversationsCountReset: (state) => {
         return {
            ...state,
            message: '',
            data: 0,
         };
      },
      // get one to one conversation
      getOneToOneConversationRequest: (
         state,
         action: PayloadAction<conversationsReducersTypes.IGetOneToOneConversationProps>
      ) => {
         return {
            ...state,
            getOneToOneConversation: {
               ...state.getOneToOneConversation,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getOneToOneConversationSuccess: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            getOneToOneConversation: {
               ...state.getOneToOneConversation,
               message: action.payload.message,
               data: action.payload.data,
               isLoading: false,
               error: '',
            },
         };
      },
      getOneToOneConversationFailed: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            getOneToOneConversation: {
               ...state.getOneToOneConversation,
               error: action.payload.error,
               isLoading: false,
            },
         };
      },

      getOneToOneConversationReset: (state) => {
         return {
            ...state,
            getOneToOneConversation: {
               ...initialState.getOneToOneConversation,
            },
         };
      },
      // get conversation create verified user
      getConversationCreationVerifiedUserRequest: (
         state,
         action: PayloadAction<conversationsReducersTypes.IConversationCreateVerifiedUser>
      ) => {
         return {
            ...state,
            getConversationCreationVerifiedUser: {
               ...state.getConversationCreationVerifiedUser,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getConversationCreationVerifiedUserSuccess: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            getConversationCreationVerifiedUser: {
               ...state.getConversationCreationVerifiedUser,
               message: action.payload.message,
               data: action.payload.data,
               isLoading: false,
               error: '',
            },
         };
      },
      getConversationCreationVerifiedUserFailed: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            getConversationCreationVerifiedUser: {
               ...state.getConversationCreationVerifiedUser,
               error: action.payload.error,
               isLoading: false,
            },
         };
      },

      getConversationCreationVerifiedUserReset: (state) => {
         return {
            ...state,
            getConversationCreationVerifiedUser: {
               ...initialState.getConversationCreationVerifiedUser,
            },
         };
      },
   },
});
