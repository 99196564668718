import { createSlice } from '@reduxjs/toolkit';
import { myProfileReducerTypes } from 'types';

const initialState: myProfileReducerTypes.IEcogReducer = {
   addEcog: {
      isLoading: false,
      error: '',
      message: '',
   },
   getMasterEcog: {
      isLoading: false,
      message: '',
      error: '',
      list: [],
   },
};

export const ecogSlice = createSlice({
   name: 'ecog',
   initialState: initialState,
   reducers: {
      // add ecog
      ecogAddRequest: (state, action) => {
         return {
            ...state,
            addEcog: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      ecogAddSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            addEcog: {
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      ecogAddFailed: (state, action: any) => {
         return {
            ...state,
            addEcog: {
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      ecogAddReset: (state) => {
         return {
            ...state,
            addEcog: initialState.addEcog,
         };
      },

      // get master ecog
      getMasterEcogRequest: (state, action) => {
         return {
            ...state,
            getMasterEcog: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getMasterEcogSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            getMasterEcog: {
               list: payload.list,
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      getMasterEcogFailed: (state, action: any) => {
         return {
            ...state,
            getMasterEcog: {
               list: state.getMasterEcog.list,
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      getMasterEcogReset: (state) => {
         return {
            ...state,
            getMasterEcog: initialState.getMasterEcog,
         };
      },
   },
});
