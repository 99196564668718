import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { CLINIC_AUXILIARY_LIST } from 'reduxToolKit/actions';
import { clinicAuxiliarySlice, commonSlice } from 'reduxToolKit/reducers';
import { clinicAdminSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   clinicAuxiliaryLoading,
   clinicAuxiliaryViewRequest,
   clinicAuxiliaryViewSuccess,
   clinicAuxiliaryFailed,
} = clinicAuxiliarySlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const clinicAuxiliarySaga = function* (payloadState: PayloadAction<clinicAdminSagaTypes.IGetAuxiliarySagaPayload>) {
   yield put(clinicAuxiliaryLoading());
   yield put(clinicAuxiliaryViewRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.auxiliaryList });
      if (response.status === 200) {
         const responseData = response?.data;
         const oncDriveList = {
            list: responseData?.data?.list || [],
            message: response?.data?.message || messages.success,
         };
         yield put(clinicAuxiliaryViewSuccess(oncDriveList));
      } else {
         yield put(clinicAuxiliaryFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeClinicAuxiliary = function* () {
   yield takeLatest(CLINIC_AUXILIARY_LIST, clinicAuxiliarySaga);
};
