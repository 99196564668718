import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { VIDEO_CALL_UPDATE } from 'reduxToolKit/actions';
import { commonSlice, videoCallUpdateSlice } from 'reduxToolKit/reducers';
import { joinVideoCallSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { updateVideoCall, updateVideoCallSuccess, updateVideoCallFailed } = videoCallUpdateSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const videoCallUpdate = function* (payload: joinVideoCallSagaTypes.IGetVideoAppointments) {
   yield put(updateVideoCall(payload));
   try {
      const apiPath = {
         apiPath: `${API.joinCallDisconnect.apiPath}${'/'}${payload.payload}`,
         action: API.joinCallDisconnect.action,
      };
      const response: AxiosResponse = yield apiCall({ ...apiPath });
      if (response.status === 200) {
         yield put(updateVideoCallSuccess(response.data));
      } else {
         yield put(updateVideoCallFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeVideoCallUpdateRequest = function* () {
   yield takeEvery(VIDEO_CALL_UPDATE, videoCallUpdate);
};
