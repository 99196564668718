import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MEDICAL_ENQUIRES_ADD_COMMENT_SAGA, MEDICAL_ENQUIRES_UPDATE_COMMENT_SAGA } from 'reduxToolKit/actions';
import { commonSlice, medicalEnqAddCommentSlice, medicalEnqUpdateCommentSlice } from 'reduxToolKit/reducers';
import { medicalEnqSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   medicalEnqAddCommentView,
   medicalEnqAddCommentViewSuccess,
   medicalEnqAddCommentViewFailed,
} = medicalEnqAddCommentSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const medicalEnqAddComment = function* (medicalEnqAddComment: medicalEnqSagaTypes.IMedicalEnqAddCommentPayload) {
   yield put(medicalEnqAddCommentView(medicalEnqAddComment.payload));
   try {
      const data = medicalEnqAddComment.payload;
      const response: AxiosResponse = yield apiCall({ data, ...API.medicalEnqAddComment });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(medicalEnqAddCommentViewSuccess(resObj));
      } else {
         yield put(medicalEnqAddCommentViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   medicalEnqUpdateCommentView,
   medicalEnqUpdateCommentViewSuccess,
   medicalEnqUpdateCommentViewFailed,
} = medicalEnqUpdateCommentSlice.actions;

const medicalEnqUpdateComment = function* (
   medicalEnqUpdateComment: medicalEnqSagaTypes.IMedicalEnqUpdateCommentPayload
) {
   yield put(medicalEnqUpdateCommentView(medicalEnqUpdateComment.payload));
   try {
      const payload = medicalEnqUpdateComment.payload;
      const data: any = {
         comment: payload.comment,
         medical_enquiry_comment_medias: payload.medical_enquiry_comment_medias,
         media_ids: payload.media_ids,
      };
      if (payload.time_spent) {
         data.time_spent = payload.time_spent;
      }
      const url = {
         apiPath: `${API.medicalEnqEditComment.apiPath}${payload.id}`,
         action: API.medicalEnqEditComment.action,
      };
      const response: AxiosResponse = yield apiCall({ data, ...url });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(medicalEnqUpdateCommentViewSuccess(resObj));
      } else {
         yield put(medicalEnqUpdateCommentViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeMedicalEnqAddUpdateCommentRequest = function* () {
   yield takeEvery(MEDICAL_ENQUIRES_ADD_COMMENT_SAGA, medicalEnqAddComment);
   yield takeEvery(MEDICAL_ENQUIRES_UPDATE_COMMENT_SAGA, medicalEnqUpdateComment);
};
