import { createSlice } from '@reduxjs/toolkit';
import { IAddAppointmentCommentReducer } from 'types/teleMedicine/addAppointmentComments/addAppointmentCommentReducerTypes';

const initialState: IAddAppointmentCommentReducer = {
   isLoading: false,
   error: '',
   message: '',
   addEditAppointmentComment: null,
};
const addEditAppointmentCommentSlice = createSlice({
   name: 'addEditAppointmentComment',
   initialState: initialState,
   reducers: {
      addEditAppointmentCommentView: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addEditAppointmentCommentViewSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            addEditAppointmentComment: payload,
            isLoading: false,
         };
      },
      addEditAppointmentCommentViewFailed: (state, action) => {
         return {
            ...state,
            addEditAppointmentComment: null,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      addAppointmentCommentReset: (state) => {
         return {
            ...state,
            addEditAppointmentComment: null,
            isLoading: false,
            message: '',
            error: '',
         };
      },
   },
});
export { addEditAppointmentCommentSlice };
