import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { locumReducerTypes } from 'types';

const initialState: locumReducerTypes.IPAPhyBookServiceReducer = {
   isLoading: false,
   errors: null,
   error_message: '',
   message: '',
   data: null,
};

export const bookServiceSlice = createSlice({
   name: 'bookService',
   initialState: initialState,
   reducers: {
      bookServiceRequest: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      bookServiceSuccess: (state, action: PayloadAction<locumReducerTypes.IPAPhyBookServiceReducer>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      bookServiceFailed: (state, action: PayloadAction<locumReducerTypes.IPAPhyBookServiceReducer>) => {
         const { payload } = action;
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error_message: payload.error_message,
            errors: payload.errors || null,
         };
      },
      bookServiceReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
