import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clinicAdminReducerTypes, clinicAdminSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: clinicAdminReducerTypes.IClinicInfo = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const clinicPhyInviteIsExistSlice = createSlice({
   name: 'clinicPhyInviteIsExist',
   initialState: initialState,
   reducers: {
      clinicPhyInviteIsExistRequest: (
         state,
         action: PayloadAction<clinicAdminSagaTypes.IClinicPhyInviteIsExistSaga>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      clinicPhyInviteIsExistSuccess: (state, action: PayloadAction<clinicAdminReducerTypes.IClinicInfo>) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            message: payload.message || messages.success,
            isLoading: false,
         };
      },
      clinicPhyInviteIsExistFailed: (state, action) => {
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: action.payload.error_message ? action.payload.error_message : '',
            ...action.payload,
         };
      },
      clinicPhyInviteIsExistReset: () => {
         return initialState;
      },
   },
});

export { clinicPhyInviteIsExistSlice };
