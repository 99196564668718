import { createSlice } from '@reduxjs/toolkit';
import { peopleNearMeReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: peopleNearMeReducerTypes.ICancerTypeListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
};

export const getDrugsListSlice = createSlice({
   name: 'getDrugsList',
   initialState: initialState,
   reducers: {
      getDrugsListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      getDrugsListViewRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      getDrugsListViewSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            isLoading: false,
            message: payload.message || messages.success,
         };
      },
      getDrugsListFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error_message,
         };
      },

      getDrugsListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
