import { createSlice } from '@reduxjs/toolkit';
import { feedReducerTypes } from 'types';

const initialState: feedReducerTypes.ISupportGroupReducer = {
   supportGroupUnreadCount: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   supportGroupReadAll: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
};

export const supportGroupSlice = createSlice({
   name: 'supportGroup',
   initialState: initialState,
   reducers: {
      // Support Group Unread Count
      supportGroupUnreadCountRequest: (state) => {
         return {
            ...state,
            supportGroupUnreadCount: {
               ...state.supportGroupUnreadCount,
               isLoading: true,
            },
         };
      },
      supportGroupUnreadCountSuccess: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            supportGroupUnreadCount: {
               ...state.supportGroupUnreadCount,
               isLoading: false,
               ...payload,
            },
         };
      },
      supportGroupUnreadCountFailed: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            supportGroupUnreadCount: {
               ...state.supportGroupUnreadCount,
               isLoading: false,
               message: '',
               error: payload.error,
            },
         };
      },
      supportGroupUnreadCountReset: (state) => {
         return {
            ...state,
            supportGroupUnreadCount: {
               ...initialState.supportGroupUnreadCount,
            },
         };
      },
      // Support Group Read All
      supportGroupReadAllRequest: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            supportGroupReadAll: {
               ...state.supportGroupReadAll,
               ...payload,
               isLoading: true,
            },
         };
      },
      supportGroupReadAllSuccess: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            supportGroupReadAll: {
               ...state.supportGroupReadAll,
               isLoading: false,
               ...payload,
            },
         };
      },
      supportGroupReadAllFailed: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            supportGroupReadAll: {
               ...state.supportGroupReadAll,
               isLoading: false,
               message: '',
               error: payload.error,
            },
         };
      },
      supportGroupReadAllReset: (state) => {
         return {
            ...state,
            supportGroupReadAll: {
               ...initialState.supportGroupReadAll,
            },
         };
      },
   },
});
