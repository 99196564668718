import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { PILL_REMAINDER_NOTIFICATION_STATUS_EDIT_SAGA } from 'reduxToolKit/actions';
import { commonSlice, pillRemainderNotifyStatusEditSlice } from 'reduxToolKit/reducers';
import { pillRemainderSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   pillRemainderNotifyStatusEditRequest,
   pillRemainderNotifyStatusEditSuccess,
   pillRemainderNotifyStatusEditFailed,
} = pillRemainderNotifyStatusEditSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const pillRemainderNotificationStatusEdit = function* (
   pillRemainderNotificationStatusEdit: PayloadAction<pillRemainderSagaTypes.IPillRemainderNotificationStatusEditParams>
) {
   yield put(pillRemainderNotifyStatusEditRequest(pillRemainderNotificationStatusEdit.payload));
   try {
      const data = pillRemainderNotificationStatusEdit.payload;
      const apiPath = {
         apiPath: `${API.pillRemainderNotificationStatusUpdate.apiPath}${pillRemainderNotificationStatusEdit.payload.id}`,
         action: API.pillRemainderNotificationStatusUpdate.action,
      };
      const response: AxiosResponse = yield apiCall({ data, ...apiPath });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(pillRemainderNotifyStatusEditSuccess(resObj));
      } else {
         yield put(pillRemainderNotifyStatusEditFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takePillRemainderNotificationStatusEditRequest = function* () {
   yield takeEvery(PILL_REMAINDER_NOTIFICATION_STATUS_EDIT_SAGA, pillRemainderNotificationStatusEdit);
};
