import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MODAL_ADD_CAREGIVER_SAGA } from 'reduxToolKit/actions';
import { commonSlice, modalAddCaregiverSlice } from 'reduxToolKit/reducers';
import { addCaregiverSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { addCaregiverRequest, addCaregiverSuccess, addCaregiverFailed } = modalAddCaregiverSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const modalAddCaregiverSaga = function* (
   cargiverPayload: PayloadAction<addCaregiverSagaTypes.IModalAddCaregiverSagaPayload>
) {
   yield put(addCaregiverRequest(cargiverPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = cargiverPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addCaregiver });
      if (response.status === 200) {
         const responseData = response.data;
         const addCaregiverList = {
            message: responseData?.message || '',
         };
         yield put(addCaregiverSuccess(addCaregiverList));
      } else {
         yield put(addCaregiverFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeAddCaregiverRequest = function* () {
   yield takeEvery(MODAL_ADD_CAREGIVER_SAGA, modalAddCaregiverSaga);
};
