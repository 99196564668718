import React, { useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { Box, Dialog, Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';
// import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
// import PillEditIcon from 'assets/pill-remainder/PillDeleteIcon.png';
import PillExitIcon from 'assets/pill-remainder/pillMedExit.png';
import PillRescheduleIcon from 'assets/pill-remainder/pillReSchedule.svg';
import PillNameIcon from 'assets/pill-remainder/pillResNameIcon.png';
import PillSkipIcon from 'assets/pill-remainder/pillSkip.svg';
import PillTakeIcon from 'assets/pill-remainder/pillTake.svg';
import PillUntakeIcon from 'assets/pill-remainder/pillUnTake.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import { DialogContent } from 'component/shared/MuiWithStyle';
import Loading from 'component/widget/Loading';
import Constant from 'config/Constant';
import { useDispatch, useSelector } from 'react-redux';
import {
   pillRemainderListSagaAction,
   pillRemainderNotificationStatusAddSagaAction,
   pillRemainderNotificationStatusEditSagaAction,
} from 'reduxToolKit/actions';
import {
   pillRemainderGetOneSlice,
   pillRemainderListSlice,
   pillRemainderNotifyStatusAddSlice,
   pillRemainderNotifyStatusEditSlice,
} from 'reduxToolKit/reducers';
import { DatePicker } from 'rsuite';
import { pillRemainderPageTypes, storeTypes } from 'types';
import { PILL_REMAINDER_EVENTS } from 'utils/constants/eventTrack/pillRemainderEventConstants';
import {
   convertDateToTimezone,
   convertDateToTimezoneDate,
   convertOnlyUTCTime,
   convertStartEndDateToUTC,
   getCurrentDate,
   isBeforeYesterday,
} from 'utils/helpers/dateHelpers';
import { setEventTrack } from 'utils/helpers/eventTrack';

const PillRescheduleModal = ({
   handleClose,
   open,
   handleSubmit,
   handleEdit,
   pillItem,
   pillItemHeader,
   selectedDate,
}: pillRemainderPageTypes.IPillRescheduleModelProps) => {
   const dispatch = useDispatch();

   const [openRescheduleTooltip, setOpenRescheduleTooltip] = useState<boolean>(false);
   const [anchorEl, setAnchorEl] = useState(null);
   const [pillRescheduleDate, setPillRescheduleDate] = useState<Date | undefined>(getCurrentDate());
   const [pillRescheduleConvertDate, setPillRescheduleConvertDate] = useState<string>(
      convertStartEndDateToUTC(getCurrentDate())
   );
   const [pillRescheduleTime, setPillRescheduleTime] = useState<Date | null>(getCurrentDate());

   const [clickForNowDate, setClickForNowDate] = useState<boolean>(false);
   const [clickForNowTime, setClickForNowTime] = useState<boolean>(false);

   const {
      isLoading: isLoadingPillRemainder /* , data: pillItemData */,
      error: pillRemainderErrorMessage,
   } = useSelector((state: storeTypes.IStore) => state.pillRemainderGetOne);
   const { pillRemainderGetOneReset } = pillRemainderGetOneSlice.actions;

   const {
      isLoading: isLoadingPillRemainderNotifyStatusAdd,
      // data: pillRemainderNotifyStatusAdd,
      message: pillRemainderNotifyStatusAddMessage,
      error: pillRemainderNotifyStatusAddErrorMessage,
   } = useSelector((state: storeTypes.IStore) => state.pillRemainderNotifyStatusAdd);
   const { pillRemainderNotifyStatusAddReset } = pillRemainderNotifyStatusAddSlice.actions;

   const {
      isLoading: isLoadingPillRemainderNotifyStatusEdit,
      // data: pillRemainderNotifyStatusEdit,
      message: pillRemainderNotifyStatusEditMessage,
      error: pillRemainderNotifyStatusEditErrorMessage,
   } = useSelector((state: storeTypes.IStore) => state.pillRemainderNotifyStatusEdit);
   const {
      pillRemainderNotifyStatusEditLoading,
      pillRemainderNotifyStatusEditReset,
   } = pillRemainderNotifyStatusEditSlice.actions;

   const { pillRemainderListReset } = pillRemainderListSlice.actions;

   const pillRemainderList = () => {
      const currentDate = getCurrentDate();
      const convertDateTimezoneDate = convertDateToTimezoneDate(
         selectedDate?.dateFormat ? selectedDate.dateFormat : currentDate
      );
      const convertDateTimezone = convertDateToTimezone(
         selectedDate?.dateFormat ? selectedDate.dateFormat : currentDate
      );
      dispatch(pillRemainderListReset());
      pillRemainderListDispatch(convertDateTimezoneDate, convertDateTimezone);
   };

   const pillRemainderListDispatch = (date: string, convertDateTimezone: string) => {
      const pillRemainderRequest = {
         date,
         time: convertDateTimezone,
      };
      dispatch(pillRemainderListSagaAction(pillRemainderRequest));
   };

   const hideAlert = (type: string) => {
      if (type === 'errorAlert') {
         dispatch(pillRemainderGetOneReset());
      } else if (type === 'errorAlertNotifyStatusAdd') {
         dispatch(pillRemainderNotifyStatusAddReset());
      } else if (type === 'errorAlertNotifyStatusEdit') {
         dispatch(pillRemainderNotifyStatusEditReset());
      } else if (type === 'successAlertNotifyStatusAdd') {
         dispatch(pillRemainderNotifyStatusAddReset());
         pillRemainderList();
         handleClose();
      } else if (type === 'successAlertNotifyStatusEdit') {
         dispatch(pillRemainderNotifyStatusEditReset());
         pillRemainderList();
         handleClose();
      }
   };

   const changeDate = (value: Date) => {
      setPillRescheduleDate(value);
      const convertDate = convertStartEndDateToUTC(value);
      setPillRescheduleConvertDate(convertDate);
   };

   const changeTime = (date: MaterialUiPickersDate) => {
      if (date) {
         setPillRescheduleTime(date);
         const convertTime = convertOnlyUTCTime(date);

         dispatch(pillRemainderNotifyStatusEditLoading());
         setTimeout(() => {
            clickReschedule(convertTime);
         }, 10000);
      }
   };

   const handleForNow = () => {
      setClickForNowDate(true);
   };

   const updateStatus = (status: string, convertTime?: any) => {
      if (pillItem?.pill_rem_med_notify_id) {
         const updateStatusEditRequest = {
            id: pillItem.pill_rem_med_notify_id,
            status,
            pills_date_time:
               status === 'reschedule'
                  ? `${pillRescheduleConvertDate} ${convertTime}` || pillItem?.pills_date_time || ''
                  : pillItem?.pills_date_time || '',
         };
         dispatch(pillRemainderNotificationStatusEditSagaAction(updateStatusEditRequest));
      } else {
         const updateStatusRequest = {
            pill_rem_medicine_id: pillItem?.pill_rem_medicine_id || 0,
            status,
            pills_date_time:
               status === 'reschedule'
                  ? `${pillRescheduleConvertDate} ${convertTime}` || pillItem?.pills_date_time || ''
                  : pillItem?.pills_date_time || '',
            pill_rem_med_day_id: pillItem?.pill_rem_med_day_id || null,
            pill_rem_med_time_id: pillItem?.pill_rem_med_time_id || null,
         };
         dispatch(pillRemainderNotificationStatusAddSagaAction(updateStatusRequest));
      }
   };

   const clickSkip = () => {
      updateStatus('skipped');
      setEventTrack(PILL_REMAINDER_EVENTS.PILL_SKIP);
   };

   const clickReschedule = (convertTime: any) => {
      updateStatus('reschedule', convertTime);
      setEventTrack(PILL_REMAINDER_EVENTS.PILL_RESCHEDULE);
   };

   const openReschedule = (e: any) => {
      setOpenRescheduleTooltip(true);
      setAnchorEl(e.currentTarget);
   };

   const closeReschedule = () => {
      setOpenRescheduleTooltip(false);
      setAnchorEl(null);

      setClickForNowDate(false);
      setClickForNowTime(false);
   };

   const clickUnTake = () => {
      updateStatus('in_progress');
      setEventTrack(PILL_REMAINDER_EVENTS.PILL_UNTAKE);
   };

   const clickTake = () => {
      updateStatus('taken');
      setEventTrack(PILL_REMAINDER_EVENTS.PILL_TAKE);
   };

   /* useEffect(() => {
      const getOneRequest = {
         id: pillItem?.pill_rem_medicine_id || 0,
      };
      dispatch(pillRemainderGetOneSagaAction(getOneRequest));
      return () => {
         dispatch(pillRemainderGetOneReset());
      };
   }, []); */

   return (
      <>
         <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            className="pill-remainder-modal"
            open={open}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            maxWidth="sm"
         >
            <DialogContent>
               {(isLoadingPillRemainder ||
                  isLoadingPillRemainderNotifyStatusAdd ||
                  isLoadingPillRemainderNotifyStatusEdit) && <Loading />}

               {pillRemainderNotifyStatusAddMessage && (
                  <InfoTimerAlert
                     success
                     confirmBtnText={`Okay!`}
                     confirmBtnBsStyle={`successBtn`}
                     title={pillRemainderNotifyStatusAddMessage}
                     onConfirm={() => hideAlert('successAlertNotifyStatusAdd')}
                  />
               )}
               {pillRemainderNotifyStatusEditMessage && (
                  <InfoTimerAlert
                     success
                     confirmBtnText={`Okay!`}
                     confirmBtnBsStyle={`successBtn`}
                     title={pillRemainderNotifyStatusEditMessage}
                     onConfirm={() => hideAlert('successAlertNotifyStatusEdit')}
                  />
               )}
               {pillRemainderErrorMessage && (
                  <InfoTimerAlert
                     error
                     confirmBtnText={`Okay!`}
                     confirmBtnBsStyle={`errorBtn`}
                     title={pillRemainderErrorMessage}
                     onConfirm={() => hideAlert('errorAlert')}
                  />
               )}
               {pillRemainderNotifyStatusAddErrorMessage && (
                  <InfoTimerAlert
                     error
                     confirmBtnText={`Okay!`}
                     confirmBtnBsStyle={`errorBtn`}
                     title={pillRemainderNotifyStatusAddErrorMessage}
                     onConfirm={() => hideAlert('errorAlertNotifyStatusAdd')}
                  />
               )}
               {pillRemainderNotifyStatusEditErrorMessage && (
                  <InfoTimerAlert
                     error
                     confirmBtnText={`Okay!`}
                     confirmBtnBsStyle={`errorBtn`}
                     title={pillRemainderNotifyStatusEditErrorMessage}
                     onConfirm={() => hideAlert('errorAlertNotifyStatusEdit')}
                  />
               )}
               <Box mt={2}>
                  <Grid container direction="row" justify="space-between">
                     <Grid item>
                        <Grid container direction="row" spacing={4}>
                           <Grid item>
                              <Box>
                                 <Typography className="pill-remainder-time ml-4 pl-2">
                                    {pillItemHeader?.time}{' '}
                                    <Typography component="span" className="pill-remainder-period">
                                       {pillItemHeader?.amPm}
                                    </Typography>
                                 </Typography>
                              </Box>
                           </Grid>
                           {/* <Grid item onClick={handleEdit}>
                              <Box ml={2}>
                                 <img src={PillEditIcon} alt="img" className="pill-reschedule-time-img" />
                              </Box>
                           </Grid> */}
                        </Grid>
                     </Grid>

                     <Grid item onClick={handleClose}>
                        <img src={PillExitIcon} alt="img" className="pill-reschedule-time-img-edit" />
                     </Grid>
                  </Grid>
               </Box>
            </DialogContent>
            <DialogContent className="pill-med-box">
               <Box pl={3}>
                  <Grid container direction="row" spacing={3} alignItems="center" style={{ flexWrap: 'nowrap' }}>
                     <Grid item>
                        <img src={PillNameIcon} alt="img" />
                     </Grid>
                     <Grid item>
                        <Box>
                           <Typography className="pill-med-name">{pillItem?.name || ''}</Typography>
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
            </DialogContent>
            <DialogContent>
               <Box mt={5} mb={5}>
                  <Grid container direction="row" justify="space-around" alignItems="center">
                     {pillItem?.status === null || pillItem?.status === 'in_progress' ? (
                        <Grid item className="hand-i" onClick={clickSkip}>
                           <Box style={{ textAlign: 'center' }}>
                              <img src={PillSkipIcon} alt="exit-img" className="modal-progress-img" />
                              <Box mt={2}>
                                 <Typography className="pill-skip">Skip</Typography>
                              </Box>
                           </Box>
                        </Grid>
                     ) : null}
                     {pillItem?.status === null ||
                     pillItem?.status === 'in_progress' ||
                     pillItem?.status === 'skipped' ? (
                        <>
                           <Grid item className="hand-i" onClick={openReschedule}>
                              <Box style={{ textAlign: 'center' }}>
                                 <img src={PillRescheduleIcon} alt="reschedule-img" className="modal-progress-img" />

                                 <Box mt={2}>
                                    <Typography className="pill-reschedule">Re-Schedule</Typography>
                                 </Box>
                              </Box>
                           </Grid>

                           <Popover
                              id="simple-popover"
                              open={openRescheduleTooltip}
                              anchorEl={anchorEl}
                              onClose={closeReschedule}
                              anchorOrigin={{
                                 vertical: 'bottom',
                                 horizontal: 'center',
                              }}
                              transformOrigin={{
                                 vertical: 'top',
                                 horizontal: 'center',
                              }}
                              className="hand-i"
                              style={{ zIndex: 1600 }}
                           >
                              <Box>
                                 <Box className="pill-reschedule-dialog-div">
                                    <Typography onClick={handleEdit} className="edit-medication-text">
                                       Edit medication
                                    </Typography>
                                    {pillItem?.pill_rem_med_notify_id ? (
                                       <Typography
                                          onClick={() => {
                                             handleForNow();
                                          }}
                                          className="for-now-text"
                                       >
                                          For now
                                       </Typography>
                                    ) : null}
                                 </Box>
                                 {clickForNowDate ? (
                                    <Box style={{ visibility: 'hidden', height: '1px' }}>
                                       <DatePicker
                                          onOk={() => {
                                             setClickForNowDate(false);
                                             setClickForNowTime(true);
                                          }}
                                          size="lg"
                                          open={clickForNowDate}
                                          placeholder="Select Date"
                                          placement="top"
                                          value={pillRescheduleDate}
                                          onChange={(value: Date) => {
                                             changeDate(value);
                                          }}
                                          format={Constant.DATE_FORMAT}
                                          disabledDate={(date) => isBeforeYesterday(date)}
                                       />
                                    </Box>
                                 ) : null}
                                 {clickForNowTime ? (
                                    <Box style={{ visibility: 'hidden', height: '1px' }}>
                                       <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                          <TimePicker
                                             open={clickForNowTime}
                                             inputVariant="standard"
                                             margin="normal"
                                             id="time-picker"
                                             className="time-pick form-control"
                                             label=""
                                             placeholder="Select time"
                                             value={pillRescheduleTime}
                                             onChange={(date: MaterialUiPickersDate) => {
                                                changeTime(date);
                                             }}
                                             InputProps={{
                                                startAdornment: (
                                                   <InputAdornment position="start">
                                                      <IconButton>
                                                         <AccessTimeIcon />
                                                      </IconButton>
                                                   </InputAdornment>
                                                ),
                                             }}
                                             onClose={() => {
                                                closeReschedule();
                                             }}
                                          />
                                       </MuiPickersUtilsProvider>
                                    </Box>
                                 ) : null}
                              </Box>
                           </Popover>
                        </>
                     ) : null}
                     {pillItem?.status === 'taken' || pillItem?.status === 'skipped' ? (
                        <Grid item className="hand-i" onClick={clickUnTake}>
                           <Box style={{ textAlign: 'center' }}>
                              <img src={PillUntakeIcon} alt="untake-img" className="modal-progress-img" />

                              <Box mt={2}>
                                 <Typography className="pill-untake">unTake</Typography>
                              </Box>
                           </Box>
                        </Grid>
                     ) : null}
                     {pillItem?.status === null ||
                     pillItem?.status === 'in_progress' ||
                     pillItem?.status === 'skipped' ? (
                        <Grid item className="hand-i" onClick={clickTake}>
                           <Box style={{ textAlign: 'center' }}>
                              <img src={PillTakeIcon} alt="take-img" className="modal-progress-img" />
                              <Box mt={2}>
                                 <Typography className="pill-take">Take</Typography>
                              </Box>
                           </Box>
                        </Grid>
                     ) : null}
                  </Grid>
               </Box>
            </DialogContent>
         </Dialog>
      </>
   );
};

export { PillRescheduleModal };
