import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';

const PillOftenTake = (props: any) => {
   const [firstRadioGroupValue, setFirstRadioGroupValue] = useState('');
   const [errorMessage, setErrorMessage] = useState('');

   const changeRadioGroupType = useCallback((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setFirstRadioGroupValue(value);
      setErrorMessage('');
   }, []);

   const clickNext = useCallback(() => {
      if (firstRadioGroupValue) {
         props.pillRemainderType(firstRadioGroupValue);
      } else {
         setErrorMessage('Please select any one type');
      }
   }, [firstRadioGroupValue]);

   useEffect(() => {
      if (props.reminderDays > 0) {
         if (props.reminderDays === 14) {
            setFirstRadioGroupValue('every_fourteen_days');
         } else if (props.reminderDays === 21) {
            setFirstRadioGroupValue('every_twenty_one_days');
         } else if (props.reminderDays === 28) {
            setFirstRadioGroupValue('every_twenty_eight_days');
         } else if (props.reminderDays === 1) {
            setFirstRadioGroupValue('every_day');
         } else if (props.reminderDays === 2) {
            setFirstRadioGroupValue('every_two_days');
         } else if (props.reminderDays > 2 && props.reminderDays <= 27) {
            setFirstRadioGroupValue('others');
         }
      } else {
         setFirstRadioGroupValue('');
      }
   }, [props.reminderDays]);

   useEffect(() => {
      setFirstRadioGroupValue(props?.type);
   }, []);

   return (
      <Box>
         <Typography align="center" className="pill-add-text">
            How often do you take it ?
         </Typography>
         <Box pl={3} width="80%" ml={10} mt={3}>
            <Grid container direction="row" spacing={1} className="">
               <Grid item>
                  <FormControl component="fieldset">
                     <RadioGroup
                        aria-label="pillSchedule"
                        name="pillSchedule"
                        value={firstRadioGroupValue}
                        onChange={changeRadioGroupType}
                     >
                        <FormControlLabel value="every_day" control={<Radio />} label="Every day" />
                     </RadioGroup>
                  </FormControl>
               </Grid>
               <Grid item>
                  <FormControl component="fieldset">
                     <RadioGroup
                        aria-label="pillSchedule"
                        name="pillSchedule"
                        value={firstRadioGroupValue}
                        onChange={changeRadioGroupType}
                     ></RadioGroup>
                  </FormControl>
               </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mt-2">
               <Grid item>
                  <FormControl component="fieldset">
                     <RadioGroup
                        aria-label="pillSchedule"
                        name="pillSchedule"
                        value={firstRadioGroupValue}
                        onChange={changeRadioGroupType}
                     >
                        <FormControlLabel value="once_a_week" control={<Radio />} label="Once a week" />
                        <FormControlLabel value="three_days_a_week" control={<Radio />} label="3 Days a week" />
                     </RadioGroup>
                  </FormControl>
               </Grid>
               <Grid item>
                  <FormControl component="fieldset">
                     <RadioGroup
                        aria-label="pillSchedule"
                        name="pillSchedule"
                        value={firstRadioGroupValue}
                        onChange={changeRadioGroupType}
                     >
                        <FormControlLabel value="two_days_a_week" control={<Radio />} label="2 Days a week" />
                        <FormControlLabel value="five_days_a_week" control={<Radio />} label="5 Days a week" />
                     </RadioGroup>
                  </FormControl>
               </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mt-2">
               <Grid item>
                  <FormControl component="fieldset">
                     <RadioGroup
                        aria-label="pillSchedule"
                        name="pillSchedule"
                        value={firstRadioGroupValue}
                        onChange={changeRadioGroupType}
                     >
                        <FormControlLabel value="every_two_days" control={<Radio />} label="Every 2 days" />
                        <FormControlLabel value="every_twenty_one_days" control={<Radio />} label="Every 21 days" />
                     </RadioGroup>
                  </FormControl>
               </Grid>
               <Grid item>
                  <FormControl component="fieldset">
                     <RadioGroup
                        aria-label="pillSchedule"
                        name="pillSchedule"
                        value={firstRadioGroupValue}
                        onChange={changeRadioGroupType}
                     >
                        <FormControlLabel value="every_fourteen_days" control={<Radio />} label="Every 14 days" />
                        <FormControlLabel value="every_twenty_eight_days" control={<Radio />} label="Every 28 days" />
                     </RadioGroup>
                  </FormControl>
               </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mt-2">
               <Grid item>
                  <FormControl component="fieldset">
                     <RadioGroup
                        aria-label="pillSchedule"
                        name="pillSchedule"
                        value={firstRadioGroupValue}
                        onChange={changeRadioGroupType}
                     >
                        <FormControlLabel value="others" control={<Radio />} label="Others" />
                     </RadioGroup>
                  </FormControl>
               </Grid>
            </Grid>
         </Box>
         {errorMessage && <Box className="errorMsg pill-error">{errorMessage}</Box>}
         {/* <Box mb={4} width="65%" mx="auto" mt={5}>
            <Button autoFocus variant="contained" className="telemedicine__modal--btn" fullWidth onClick={clickNext}>
               Next
            </Button>
         </Box> */}
         <Box mb={4} mx="auto" mt={5}>
            <Grid container direction="row" spacing={3} justify="center">
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="pill-add-back-button"
                     fullWidth
                     onClick={() => {
                        props?.pillRemainderNameBack();
                     }}
                  >
                     Back
                  </Button>
               </Grid>
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="telemedicine__modal--btn"
                     fullWidth
                     onClick={clickNext}
                  >
                     Next
                  </Button>
               </Grid>
            </Grid>
         </Box>
      </Box>
   );
};

export { PillOftenTake };
