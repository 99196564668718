import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { SHOW_PATIENT_FEED_SAGA } from 'reduxToolKit/actions';
import { commonSlice, showPatientFeedSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { showPatientRequest, showPatientSuccess, showPatientFailed } = showPatientFeedSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const showPatientFeedSaga = function* (patientFeed: PayloadAction<feedSagaTypes.IShowPatientFeedActionProps>) {
   yield put(showPatientRequest(patientFeed.payload));
   try {
      const data = {
         show_patient_feed: patientFeed.payload.show_patient_feed,
      };

      const url = API.showPatientFeed;
      const response: AxiosResponse = yield apiCall({ data, ...url });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: response.data,
         };
         yield put(showPatientSuccess(resObj));
      } else {
         yield put(showPatientFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeShowPatientFeed = function* () {
   yield takeEvery(SHOW_PATIENT_FEED_SAGA, showPatientFeedSaga);
};
