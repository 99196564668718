import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { oncKlinicSymptomReducerTypes } from 'types';

const initialState: oncKlinicSymptomReducerTypes.IOncKlinicSymptomClinicReducerTypes = {
   clinicTiming: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
};
export const oncKlinicSymptomClinicSlice = createSlice({
   name: 'oncKlinicSymptomClinic',
   initialState: initialState,
   reducers: {
      checkClinicTimingRequest: (state, action) => {
         return {
            ...state,
            clinicTiming: {
               ...state.clinicTiming,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      checkClinicTimingSuccess: (
         state,
         action: PayloadAction<oncKlinicSymptomReducerTypes.ICheckClinicTimingSuccessPayload>
      ) => {
         return {
            ...state,
            clinicTiming: {
               ...state.clinicTiming,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      checkClinicTimingFailed: (
         state,
         action: PayloadAction<oncKlinicSymptomReducerTypes.ICheckClinicTimingFailurePayload>
      ) => {
         return {
            ...state,
            clinicTiming: {
               ...state.clinicTiming,
               error: action.payload.error,
               isLoading: false,
               message: '',
            },
         };
      },

      checkClinicTimingReset: (state) => {
         return {
            ...state,
            clinicTiming: initialState.clinicTiming,
         };
      },
   },
});
