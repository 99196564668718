import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { feedReducerTypes, feedSagaTypes } from 'types';

const initialState: feedReducerTypes.ICreateAndEditPostReducerPayload = {
   postCreateAndUpdate: {
      message: '',
      errorMessage: '',
   },
   postDelete: {
      message: '',
      errorMessage: '',
   },
};

export const postCreateAndEditSlice = createSlice({
   name: 'postCreateAndEdit',
   initialState: initialState,
   reducers: {
      postCreateAndEditRequest: (state, action: PayloadAction<feedSagaTypes.ICreateAndEditPostSagaPayload>) => {
         return {
            ...state,
            postCreateAndUpdate: {
               ...state.postCreateAndUpdate,
               ...action.payload,
            },
         };
      },
      postCreateAndEditSuccess: (state, action: PayloadAction<feedReducerTypes.ICreateAndEditPostSuccessPayload>) => {
         return {
            ...state,
            postCreateAndUpdate: {
               ...state.postCreateAndUpdate,
               ...action.payload,
            },
         };
      },
      postCreateAndEditFailure: (state, action: PayloadAction<feedReducerTypes.ICommonSaveFailurePayload>) => {
         return {
            ...state,
            postCreateAndUpdate: {
               ...state.postCreateAndUpdate,
               errorMessage: action.payload.error,
            },
         };
      },

      //delete
      postDeleteRequest: (state, action: PayloadAction<feedSagaTypes.IDeletePostSagaPayload>) => {
         return {
            ...state,
            postDelete: {
               ...state.postDelete,
               ...action.payload,
            },
         };
      },
      postDeleteSuccess: (state, action: PayloadAction<feedReducerTypes.ICreateAndEditPostSuccessPayload>) => {
         return {
            ...state,
            postDelete: {
               ...state.postDelete,
               ...action.payload,
            },
         };
      },
      postDeleteFailure: (state, action: PayloadAction<feedReducerTypes.ICommonSaveFailurePayload>) => {
         return {
            ...state,
            postDelete: {
               ...state.postDelete,
               errorMessage: action.payload.error,
            },
         };
      },
      postCreateAndEditReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
