import { createSlice } from '@reduxjs/toolkit';
import { ITreatmentMethodReducer } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: ITreatmentMethodReducer = {
   treatmentMethodAdd: {
      isLoading: false,
      error: '',
      message: '',
      data: null,
   },
   treatmentMethodLogList: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      total: 0,
      current_page: 1,
   },
};

export const treatmentMethodSlice = createSlice({
   name: 'treatmentMethod',
   initialState: initialState,
   reducers: {
      treatmentMethodAddRequest: (state, action) => {
         return {
            ...state,
            treatmentMethodAdd: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      treatmentMethodAddSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            treatmentMethodAdd: {
               message: payload.message || messages.success,
               isLoading: false,
               data: payload.data,
            },
         };
      },
      treatmentMethodAddFailed: (state, action: any) => {
         return {
            ...state,
            treatmentMethodAdd: {
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },

      treatmentMethodAddReset: (state) => {
         return {
            ...state,
            treatmentMethodAdd: {
               ...initialState.treatmentMethodAdd,
            },
         };
      },
      // treatment method lod list
      treatmentMethodLogListRequest: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            treatmentMethodLogList: {
               ...payload,
               isLoading: true,
               list: payload?.page === 1 ? [] : state.treatmentMethodLogList.list,
               total: 0,
               current_page: 0,
            },
         };
      },
      treatmentMethodLogListSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            treatmentMethodLogList: {
               message: payload.message || messages.success,
               isLoading: false,
               list:
                  payload?.current_page === 1 ? payload.data : state.treatmentMethodLogList.list.concat(payload.data),
               total: payload.total,
               current_page: payload.current_page,
            },
         };
      },
      treatmentMethodLogListFailed: (state, action: any) => {
         return {
            ...state,
            treatmentMethodLogList: {
               isLoading: false,
               message: '',
               error: action.payload.error,
               list: [],
               total: 0,
               current_page: 0,
            },
         };
      },

      treatmentMethodLogListReset: (state) => {
         return {
            ...state,
            treatmentMethodLogList: {
               ...initialState.treatmentMethodLogList,
            },
         };
      },
   },
});
