import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MODAL_UPDATE_EDUCATION_TRAINING_SAGA } from 'reduxToolKit/actions';
import { commonSlice, modalUpdateEducationTrainingSlice } from 'reduxToolKit/reducers';
import { modalEducationTrainingSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   updateEducationTrainingRequest,
   updateEducationTrainingSuccess,
   updateEducationTrainingFailed,
} = modalUpdateEducationTrainingSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const modalUpdateEducationTrainingSaga = function* (
   educationPayload: PayloadAction<modalEducationTrainingSagaTypes.IModalEducationTrainingSagaPayload>
) {
   yield put(updateEducationTrainingRequest(educationPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = educationPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addUpdateEducation });
      if (response.status === 200) {
         const responseData = response.data;
         const educationTrainingList = {
            message: responseData?.message || '',
         };
         yield put(updateEducationTrainingSuccess(educationTrainingList));
      } else {
         yield put(updateEducationTrainingFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeUpdateEducationTraining = function* () {
   yield takeEvery(MODAL_UPDATE_EDUCATION_TRAINING_SAGA, modalUpdateEducationTrainingSaga);
};
