import { createSlice } from '@reduxjs/toolkit';
import { myProfileReducerTypes } from 'types';

const initialState: myProfileReducerTypes.IDeleteFamilyMembersReducer = {
   isLoading: false,
   error: '',
   message: '',
};

export const deleteFamilyMemberSlice = createSlice({
   name: 'deleteFamilyMember',
   initialState: initialState,
   reducers: {
      deleteFamilyMemberRequest: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: true,
         };
      },
      deleteFamilyMemberSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      deleteFamilyMemberFailed: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            message: '',
            error: payload.error_message,
         };
      },
      deleteFamilyMemberReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
