import React, { useEffect, useState } from 'react';

import SweetAlert from 'react-bootstrap-sweetalert';
import { CommonConstants } from 'utils/constants/common';

export const InfoTimerAlert = (props: any) => {
   const [timer, setTimer] = useState<number>(
      props?.setManualTimer ? parseInt(props?.manualTimer) : CommonConstants.InfoTimer.INFO_TIMER_ALERT
   );
   const [btnTxtWithTimer, setBtnWithTimer] = useState(`${props.confirmBtnText} (${timer})`);

   useEffect(() => {
      const interval = setInterval(() => {
         setBtnWithTimer(`${props.confirmBtnText} (${timer})`);
         if (timer > 0) {
            setTimer((timer) => timer - 1);
         } else if (timer === 0) {
            props.onConfirm();
            clearInterval(interval);
         }
      }, CommonConstants.InfoTimer.INFO_TIMER_INTERVAL);
      return () => {
         clearInterval(interval);
      };
   }, [timer]);
   return <SweetAlert {...props} confirmBtnText={btnTxtWithTimer} />;
};
