import Api from 'Api';
import { AxiosResponse } from 'axios';
import ApiUrlConfig from 'config/ApiUrlConfig';
import Constant from 'config/Constant';
import { store } from 'reduxToolKit';
import { toastControllerSlice } from 'reduxToolKit/reducers';
import { DummyUser, User } from 'types/modelHelpers';
import { IGetPatient, IGetPhysician } from 'types/teleMedicine/appointmentList/getAppointmentSagaTypes';
import { CommonConstants } from 'utils/constants/common';
import { getUser, getUserId } from 'utils/helpers/authHeader';
import { simpleEncode } from 'utils/helpers/encryptHelpers';
import { jsUcfirst } from 'utils/helpers/stringHelpers';
import {
   isAdmin,
   isAuxiliaryStaff,
   isCaregiver,
   isNurse,
   isOrganization,
   isPCAUser,
   isPatient,
   isPatientAdvocate,
   isPhysician,
   isPhysicianAdmin,
   isPractiseAdmin,
   isRecruiter,
} from 'utils/helpers/userHelpers/userTypeHelpers';

const { triggerToastAction } = toastControllerSlice.actions;

export const showUserType = (userObject: any) => {
   if (isPhysician(userObject)) {
      return 'Physician';
   } else if (isPatient(userObject)) {
      return 'Patient';
   } else if (isCaregiver(userObject)) {
      return 'Caregiver';
   } else if (isPatientAdvocate(userObject)) {
      return 'Patient Advocate';
   } else if (isNurse(userObject)) {
      return 'Nurse';
   } else if (isPractiseAdmin(userObject)) {
      return 'Practice Administrator';
   } else if (isAuxiliaryStaff(userObject)) {
      return 'Auxiliary Staff';
   } else if (isOrganization(userObject)) {
      return 'Organization';
   } else if (isAdmin(userObject)) {
      return 'admin';
   }
};

export const showDisplayName = (userObject: any, hippa?: boolean, organization?: any) => {
   const userObjectData = userObject;
   let userName = '';
   if (isPhysician(userObject)) {
      //Dr. removed add MD say by gowtham // 04-07-2020
      if (userObject.physician_suffix) {
         userName =
            jsUcfirst(userObjectData.first_name) +
            ' ' +
            jsUcfirst(userObjectData.last_name) +
            ', ' +
            userObject.physician_suffix;
      } else {
         userName = jsUcfirst(userObjectData.first_name) + ' ' + jsUcfirst(userObjectData.last_name) + ', MD';
      }
   } else if (isPCAUser(userObject)) {
      if (hippa) {
         userName = jsUcfirst(userObjectData.first_name) + ' ' + jsUcfirst(userObjectData.last_name);
      } else {
         if (userObjectData.display_name) {
            userName = userObjectData.display_name;
         } else {
            userName = jsUcfirst(userObjectData.first_name) + ' ' + jsUcfirst(userObjectData.last_name);
         }
      }
   } else if (
      isNurse(userObject) ||
      isPractiseAdmin(userObject) ||
      isAuxiliaryStaff(userObject) ||
      isAdmin(userObject)
   ) {
      userName = jsUcfirst(userObjectData.first_name) + ' ' + jsUcfirst(userObjectData.last_name);
   } else if (isOrganization(userObject)) {
      userName = userObject?.organization?.name
         ? jsUcfirst(userObject?.organization?.name)
         : organization?.name
         ? jsUcfirst(organization?.name)
         : userObjectData.first_name || userObjectData.last_name
         ? jsUcfirst(userObjectData.first_name) + ' ' + jsUcfirst(userObjectData.last_name)
         : '';
   }
   return userName;
};

export const gotoProfileHelper = (
   userObject: User | IGetPhysician | IGetPatient | DummyUser | undefined | null,
   isFrom?: string
) => {
   if (
      isPCAUser(getUser()) ||
      isPhysician(getUser()) ||
      isPhysicianAdmin(getUser()) ||
      isPractiseAdmin(getUser()) ||
      isRecruiter(getUser()) ||
      isNurse(getUser()) ||
      isAuxiliaryStaff(getUser()) ||
      isOrganization(getUser())
   ) {
      if (isPCAUser(userObject) || isPhysician(userObject) || isOrganization(userObject)) {
         const userId = getUserId();
         if (userObject) {
            if (userObject.id === userId) {
               const url = '/my-profile';
               window.open(url, '_self');
            } else {
               if (
                  isPhysician(getUser()) &&
                  !getUser().oncopower_verified &&
                  (userObject.user_type === Constant.PATIENT ||
                     userObject.user_type === Constant.CAREGIVER ||
                     userObject.user_type === Constant.PATIENT_ADVOCATE) &&
                  isFrom !== 'feed_view_profile'
               ) {
                  checkIsMyPatient(userObject, isFrom);
               } else {
                  let url = `/connections/profile-view/${simpleEncode(userObject.user_type)}/${simpleEncode(
                     String(userObject.id)
                  )}`;
                  if (isPractiseAdmin(getUser()) || isAuxiliaryStaff(getUser())) {
                     url = `${url}/${simpleEncode('paApplicantList')}`;
                  } else {
                     if (isFrom) {
                        url = `${url}/${simpleEncode(isFrom)}`;
                     }
                  }
                  window.open(url, '_blank');
               }
            }
         }
      }
   }
};

const checkIsMyPatient = (userObject: User | IGetPhysician | IGetPatient | DummyUser | undefined, isFrom?: string) => {
   Api.get(ApiUrlConfig.getIsMyPatient, {
      params: {
         patient_id: userObject?.id,
         is_connection_check: 1,
      },
   })
      .then((response: AxiosResponse) => {
         if (response.data.data === true) {
            let url = `/connections/profile-view/${simpleEncode(userObject?.user_type || '')}/${simpleEncode(
               String(userObject?.id)
            )}`;
            if (isPractiseAdmin(getUser())) {
               url = `${url}/${simpleEncode('paApplicantList')}`;
            } else {
               if (isFrom) {
                  url = `${url}/${simpleEncode(isFrom)}`;
               }
            }
            window.open(url, '_blank');
         } else if (response.data.data === false) {
            const toastData = {
               messageType: CommonConstants.Toast.types.ERROR,
               message: 'Access denied',
            };
            store.dispatch(triggerToastAction(toastData));
         }
      })
      .catch(() => {});
};
