import { createSlice } from '@reduxjs/toolkit';
import { healthRecordsReducerTypes } from 'types';

const initialState: healthRecordsReducerTypes.IHealthRecordAddReducer = {
   isLoading: false,
   error: '',
   message: '',
};
export const healthRecordAddSlice = createSlice({
   name: 'healthRecordAdd',
   initialState: initialState,
   reducers: {
      healthRecordAddLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      healthRecordAddRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      healthRecordAddSuccess: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            message: payload,
         };
      },
      healthRecordAddFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      healthRecordAddReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
