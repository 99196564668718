import { createAction } from '@reduxjs/toolkit';
import { fillProfileSagaTypes } from 'types';

export const PHONE_SECTION_PROFILE_SAGA = 'PHONE_SECTION_PROFILE_SAGA';
export const phoneSectionSagaAction = createAction<fillProfileSagaTypes.IPhoneSectionType>(PHONE_SECTION_PROFILE_SAGA);

export const OTP_SECTION_PROFILE_SAGA = 'OTP_SECTION_PROFILE_SAGA';
export const otpSectionSagaAction = createAction<fillProfileSagaTypes.IOtpSectionType>(OTP_SECTION_PROFILE_SAGA);

export const RESEND_OTP_SECTION_PROFILE_SAGA = 'RESEND_OTP_SECTION_PROFILE_SAGA';
export const resendOtpSectionSagaAction = createAction<fillProfileSagaTypes.IResendOtpSectionType>(
   RESEND_OTP_SECTION_PROFILE_SAGA
);

export const DOB_SECTION_PROFILE_SAGA = 'DOB_SECTION_PROFILE_SAGA';
export const dobSectionSagaAction = createAction<fillProfileSagaTypes.IDobSectionType>(DOB_SECTION_PROFILE_SAGA);

export const ADDRESS_SECTION_PROFILE_SAGA = 'ADDRESS_SECTION_PROFILE_SAGA';
export const addressSectionSagaAction = createAction<fillProfileSagaTypes.IAddressSectionType>(
   ADDRESS_SECTION_PROFILE_SAGA
);

export const GET_CANCER_TYPES_SAGA = 'GET_CANCER_TYPES_SAGA';
export const cancerTypeSagaAction = createAction<fillProfileSagaTypes.ICancerType>(GET_CANCER_TYPES_SAGA);

export const GET_SECONDARY_CANCER_TYPES_SAGA = 'GET_SECONDARY_CANCER_TYPES_SAGA';
export const cancerSecondaryTypeSagaAction = createAction<fillProfileSagaTypes.ISecondaryCancerType>(
   GET_SECONDARY_CANCER_TYPES_SAGA
);

export const GET_SUB_SPECIALITY = 'GET_SUB_SPECIALITY';
export const getSubSpecialitySagaAction = createAction<fillProfileSagaTypes.ISubSpecialitySagaProps>(
   GET_SUB_SPECIALITY
);
