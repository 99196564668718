import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { EVENT_TRACK } from 'reduxToolKit/actions';
import { commonSlice, eventTrackSlice } from 'reduxToolKit/reducers';
import { eventTrackTypes } from 'types/eventTrack';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { eventTrackRequest, eventTrackSuccess, eventTrackFailed } = eventTrackSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const eventTrackSaga = function* (eventTrackPayload: PayloadAction<eventTrackTypes.IEventTrack>) {
   yield put(eventTrackRequest());
   try {
      const checkUser = true;
      const goUrl = false;
      const peopleMayYouKnow = true;
      const params = eventTrackPayload.payload.event;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         peopleMayYouKnow,
         goUrl,
         params,
         ...API.eventTrackLog,
      });
      if (response.status === 200) {
         const responseData = response.data.data;
         yield put(eventTrackSuccess(responseData));
      } else {
         yield put(eventTrackFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeEventTrack = function* () {
   yield takeEvery(EVENT_TRACK, eventTrackSaga);
};
