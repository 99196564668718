export const HOME_SECTION = 0;
export const HOME_SECTION_EXISTING_USER = 0;
export const DISPLAY_NAME_SECTION = 1;
export const CANCER_TYPE_SECTION = 2;
export const DIAGNOSIS_SECTION = 3;
export const STAGING_SECTION = 4;
export const CANCER_STATUS_SECTION = 5;
export const ADDRESS_SECTION = 6;
export const DOB_SECTION = 7;
export const SERVICE_OFFERED_SECTION = 8;
export const RACE_SECTION = 9;
export const LATINO_SECTION = 10;

export const INITIAL_DISPLAY_NAME_SECTION = 0;
export const INITIAL_CANCER_TYPE_SECTION = 1;
export const INITIAL_ADDRESS_SECTION = 2;

export const CLINICAL_TRAILS_DISPLAY_NAME_SECTION = 0;
export const CLINICAL_TRAILS_CANCER_TYPE_SECTION = 1;
export const CLINICAL_TRAILS_ADDRESS_SECTION = 2;
export const CLINICAL_TRIALS_DOB_SECTION = 3;

export const CLINICAL_TRIALS_HOME_SECTION = 0;
export const CLINICAL_TRIALS_DIAGNOSIS_SECTION = 1;
export const CLINICAL_TRIALS_STAGING_SECTION = 2;
export const CLINICAL_TRIALS_CANCER_STATUS_SECTION = 3;
export const CLINICAL_TRIALS_SERVICE_OFFERED_SECTION = 4;
export const CLINICAL_TRIALS_RACE_SECTION = 5;
export const CLINICAL_TRIALS_LATINO_SECTION = 6;

export const ORG_USER_NAME = 0;
export const ORG_USER_SERVICES_OFFERED = 1;
export const ORG_USER_POPULATION_SERVED = 2;

export const ACCESS_DENIED = 'Access Denied';
export const ACCESS_DENIED_TEXT = 'You do not have permission to access the requested feature or menu';
export const ACCESS_DENIED_GO_TO_HOME = 'Go to Home';
export const SIGNED = 'signed';
export const UPLOAD = 'upload';
export const TOKEN_CANCELLED = 'Token cancelled';
export const UPLOAD_CANCELLED = 'Upload cancelled';
