import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_DISEASES, GET_FAMILY_MEMBER_TYPES } from 'reduxToolKit/actions';
import { commonSlice, getDiseasesSlice, getFamilyMemberTypesSlice } from 'reduxToolKit/reducers';
import { myProfileSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getDiseasesRequest, getDiseasesSuccess, getDiseasesFailed } = getDiseasesSlice.actions;
const {
   getFamilyMemberTypesRequest,
   getFamilyMemberTypesSuccess,
   getFamilyMemberTypesFailed,
} = getFamilyMemberTypesSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getDiseasesSaga = function* (diseasesTypePayload: PayloadAction<myProfileSagaTypes.IGetPrimaryDisease>) {
   yield put(getDiseasesRequest(diseasesTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = diseasesTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getDiseases });
      if (response.status === 200) {
         const responseData = response.data;
         const diseaseList = {
            list: responseData?.data?.list || [],
            total_records: responseData?.data?.total_records || 0,
            message: response?.data?.message || '',
         };
         yield put(getDiseasesSuccess(diseaseList));
      } else {
         yield put(getDiseasesFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getFamilyMemberTypesSaga = function* (
   familyMemberTypePayload: PayloadAction<myProfileSagaTypes.IGetPrimaryDisease>
) {
   yield put(getFamilyMemberTypesRequest(familyMemberTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = familyMemberTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getFamilyMemberTypes });
      if (response.status === 200) {
         const responseData = response.data;
         const familyMemberList = {
            list: responseData?.data?.list || [],
            total_records: responseData?.data?.total_records || 0,
            message: response?.data?.message || '',
         };
         yield put(getFamilyMemberTypesSuccess(familyMemberList));
      } else {
         yield put(getFamilyMemberTypesFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takegetDiseases = function* () {
   yield takeEvery(GET_DISEASES, getDiseasesSaga);
};

export const takegetFamilyMemberTypes = function* () {
   yield takeEvery(GET_FAMILY_MEMBER_TYPES, getFamilyMemberTypesSaga);
};
