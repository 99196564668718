import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_TREATING_PHYSICIAN } from 'reduxToolKit/actions';
import { commonSlice, getTreatingPhysicianSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getTreatingPhysicianRequest,
   getTreatingPhysicianSuccess,
   getTreatingPhysicianFailed,
} = getTreatingPhysicianSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getTreatingPhysicianSaga = function* (treatingPhysicianTypePayload: any) {
   yield put(getTreatingPhysicianRequest(treatingPhysicianTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = treatingPhysicianTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getTreatingPhysician });
      if (response.status === 200) {
         const responseData = response.data;
         const treatingPhysicianList = {
            list: responseData?.data?.list || [],
            total_records: responseData?.data?.total_records || 0,
            message: response?.data?.message || '',
         };
         yield put(getTreatingPhysicianSuccess(treatingPhysicianList));
      } else {
         yield put(getTreatingPhysicianFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeTreatingPhysicianRequest = function* () {
   yield takeEvery(GET_TREATING_PHYSICIAN, getTreatingPhysicianSaga);
};
