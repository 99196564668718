import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { symptomMonitoringCommentReducerTypes } from 'types';

const initialState: symptomMonitoringCommentReducerTypes.ISymptomMonitoringCommentReducerTypes = {
   isLoading: false,
   symptom_monitoring_id: 0,
   symptomMonitoringComments: {
      list: [],
      message: '',
      error: '',
      total_records: 0,
   },
   symptomMonitoringCommentsClose: {
      message: '',
      error: '',
   },
   addEditSymptomMonitoringComments: {
      data: {
         symptom_monitoring_id: 0,
         comment: '',
         minutes: 0,
         is_audio_video_selected: false,
         medias: [],
         isEditComment: false,
         id: 0,
         media_ids: [],
      },
      message: '',
      error: '',
   },
};

export const symptomMonitoringCommentsSlice = createSlice({
   name: 'symptomMonitoringComment',
   initialState: initialState,
   reducers: {
      getSymptomMonitoringCommentsRequest: (
         state,
         action: PayloadAction<symptomMonitoringCommentReducerTypes.ISymptomMonitoringComment>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getSymptomMonitoringCommentsSuccess: (
         state,
         action: PayloadAction<symptomMonitoringCommentReducerTypes.ISymptomMonitoringCommentSuccessPayload>
      ) => {
         return {
            ...state,
            isLoading: false,
            symptomMonitoringComments: {
               list: action.payload?.list,
               message: action.payload?.message,
               total_records: action.payload?.total_records,
               error: '',
            },
         };
      },
      getSymptomMonitoringCommentsFailed: (
         state,
         action: PayloadAction<symptomMonitoringCommentReducerTypes.ISymptomMonitoringCommentFailurePayload>
      ) => {
         return {
            ...state,
            isLoading: false,
            symptomMonitoringComments: {
               list: [],
               message: '',
               error: action.payload?.error,
               total_records: 0,
            },
         };
      },

      symptomMonitoringCommentCloseRequest: (
         state,
         action: PayloadAction<symptomMonitoringCommentReducerTypes.ISymptomMonitoringCommentsClose>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      symptomMonitoringCommentCloseSuccess: (
         state,
         action: PayloadAction<symptomMonitoringCommentReducerTypes.ISymptomMonitoringCommentsSuccessPayload>
      ) => {
         return {
            ...state,
            isLoading: false,
            symptomMonitoringCommentsClose: {
               message: action.payload?.message,
               error: '',
            },
         };
      },
      symptomMonitoringCommentCloseFailed: (
         state,
         action: PayloadAction<symptomMonitoringCommentReducerTypes.ISymptomMonitoringCommentFailurePayload>
      ) => {
         return {
            ...state,
            isLoading: false,
            symptomMonitoringCommentsClose: {
               error: action.payload.error,
               message: '',
            },
         };
      },

      addEditSymptomMonitoringCommentRequest: (
         state,
         action: PayloadAction<symptomMonitoringCommentReducerTypes.IAddEditSymptomMonitoringComments>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },

      addEditSymptomMonitoringCommentSuccess: (
         state,
         action: PayloadAction<symptomMonitoringCommentReducerTypes.ISymptomMonitoringCommentsSuccessPayload>
      ) => {
         return {
            ...state,
            isLoading: false,
            addEditSymptomMonitoringComments: {
               message: action.payload?.message,
               error: '',
            },
         };
      },

      addEditSymptomMonitoringCommentFailed: (
         state,
         action: PayloadAction<symptomMonitoringCommentReducerTypes.ISymptomMonitoringCommentFailurePayload>
      ) => {
         return {
            ...state,
            isLoading: false,
            addEditSymptomMonitoringComments: {
               error: action.payload?.error,
               message: '',
            },
         };
      },

      symptomMonitoringCommentsReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
