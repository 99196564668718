import { createSlice } from '@reduxjs/toolkit';
import { userReducerTypes } from 'types';

const initialState: userReducerTypes.IAddUpdatePatientReducerType = {
   isLoading: false,
   error: '',
   errorMessage: '',
   message: '',
   data: null,
   updatePatient: {
      isLoading: false,
      error: '',
      message: '',
      data: null,
   },
   nurseClinic: {
      isLoading: false,
      error: '',
      message: '',
      data: null,
   },
};
export const addPatientSlice = createSlice({
   name: 'addPatient',
   initialState: initialState,
   reducers: {
      addMyPatientsRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addMyPatientsSuccess: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            ...action.payload,
         };
      },
      addMyPatientsFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
            errorMessage: action.payload.error,
         };
      },

      updateMyPatientsRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      updateMyPatientsSuccess: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            updatePatient: {
               ...state.updatePatient,
               ...action.payload,
            },
         };
      },
      updateMyPatientsFailed: (state, action) => {
         return {
            ...state,
            updatePatient: {
               ...state.updatePatient,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },

      getNurseClinicRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getNurseClinicSuccess: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            nurseClinic: {
               ...state.nurseClinic,
               ...action.payload,
            },
         };
      },
      getNurseClinicFailed: (state, action) => {
         return {
            ...state,
            nurseClinic: {
               ...state.nurseClinic,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },

      addMyPatientsReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
