import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { POST_SUMMARY, SECOND_OPINION_GET_ONE_SUMMARY } from 'reduxToolKit/actions';
import { commonSlice, getOneSummarySlice, summaryPostSlice } from 'reduxToolKit/reducers';
import { askADocPageTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { summaryRequest, summarySuccess, summaryFailed } = summaryPostSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const summarySaga = function* (payloadState: any) {
   yield put(summaryRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addSummary });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: null,
         };
         yield put(summarySuccess(resObj));
      } else {
         yield put(summaryFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const { getOneSummaryRequest, getOneSummarySuccess, getOneSummaryFailed } = getOneSummarySlice.actions;

const getOneSummarySaga = function* (payloadState: PayloadAction<askADocPageTypes.IGetOneSummery>) {
   yield put(getOneSummaryRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const apiPath = {
         apiPath: `${API.getOneSecondOpinionSummery.apiPath}${data.id}`,
         action: API.getOneSecondOpinionSummery.action,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...apiPath });
      if (response.status === 200) {
         const resObj = response.data.data;
         yield put(getOneSummarySuccess(resObj));
      } else {
         yield put(getOneSummaryFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeSummary = function* () {
   yield takeEvery(POST_SUMMARY, summarySaga);
   yield takeEvery(SECOND_OPINION_GET_ONE_SUMMARY, getOneSummarySaga);
};
