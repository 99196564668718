import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINIC_INFORMATION_EDIT_SAGA } from 'reduxToolKit/actions';
import { commonSlice, clinicInfoEditSlice } from 'reduxToolKit/reducers';
import { clinicAdminSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { clinicInfoEditRequest, clinicInfoEditSuccess, clinicInfoEditFailed } = clinicInfoEditSlice.actions;

const { exceptionHandlerRequest } = commonSlice.actions;

const clinicInfoEdit = function* (clinicInfoEdit: PayloadAction<clinicAdminSagaTypes.IClinicInformationEditSaga>) {
   yield put(clinicInfoEditRequest(clinicInfoEdit.payload));
   try {
      const data = clinicInfoEdit.payload;
      const goUrl = true;
      const checkUser = true;
      const response: AxiosResponse = yield apiCall({ data, goUrl, checkUser, ...API.clinicInfoEdit });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(clinicInfoEditSuccess(resObj));
      } else {
         yield put(clinicInfoEditFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeClinicInfoEditRequest = function* () {
   yield takeEvery(CLINIC_INFORMATION_EDIT_SAGA, clinicInfoEdit);
};
