import './App.css';
import 'rsuite/dist/styles/rsuite-default.css';
import 'semantic-ui-css/semantic.min.css';

import React, { Component } from 'react';

import FingerPrint from 'component/fingerprint/Fingerprint';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import ExceptionHandlerModal from 'component/widget/ExceptionHandlerModal';
import { ToastController } from 'component/widget/toast';
import Constant from 'config/Constant';
import { isMobile } from 'react-device-detect';
//facebook pixel
import ReactPixel from 'react-facebook-pixel';
// TODO: ReactGA unused because flow implemented with alternate of window.gtag
// import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'reduxToolKit';
import { loginSlice } from 'reduxToolKit/reducers';
import Routes from 'routes';
import { getUser, logout } from 'utils/helpers/authHeader';
import { explicitErrorHandler } from 'utils/helpers/errorHelpers';

class App extends Component<any, any> {
   alert: null;
   constructor(props: any) {
      super(props);
      this.alert = null;
      this.state = {
         elapsed: 0,
         alert: null,
      };
      store.subscribe(() => {
         const { accountDeActivate, msg } = store.getState().login;
         this.setState({
            accountDeActivate,
            msg,
         });
      });
   }

   hideAlert = (type: string) => {
      if (type === 'successAlert') {
         const { userAccountDeActivated } = loginSlice.actions;
         store.dispatch(userAccountDeActivated({ account: null, msg: '' }));
         logout();
         window.location.href = '/';
      }
   };

   render():
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | string
      | number
      | React.ReactNodeArray
      | React.ReactPortal
      | boolean
      | null
      | undefined {
      return (
         <div>
            {this.state.accountDeActivate && this.state.msg !== null ? (
               <InfoTimerAlert
                  success
                  confirmBtnText="Okay!"
                  confirmBtnBsStyle="successBtn"
                  title={this.state.msg}
                  onConfirm={() => this.hideAlert('successAlert')}
               ></InfoTimerAlert>
            ) : null}
            <Provider store={store}>
               <PersistGate persistor={persistor}>
                  {getUser() && false ? <FingerPrint /> : null}
                  <Routes />
                  <ToastController />
                  <ExceptionHandlerModal />
               </PersistGate>
            </Provider>
         </div>
      );
   }

   componentDidMount() {
      /* TODO: internet connection disconnect temprarly hided */
      /* window.addEventListener('offline', () => {
         window.location.href = '/no-internet-connection';
      }); */
      window.addEventListener('error', (errorEvt: ErrorEvent) => explicitErrorHandler(errorEvt));
      window.addEventListener('unhandledrejection', (errorEvt) => {
         errorEvt.stopImmediatePropagation();
         errorEvt.stopPropagation();
      });
      const pathName = window.location.pathname;
      const params = pathName.split('/');
      const paramsId = params[2];
      // TODO: ReactGA unused because flow implemented with alternate of window.gtag
      // ReactGA.initialize('G-JNBJLE3GZF');
      // ReactGA.send(pathName);
      const Url = [
         '/',
         '/mobile-view',
         '/register',
         '/home',
         '/forgot-password',
         '/set-password',
         '/confirm-join',
         '/email-verification',
         '/password-verify-otp/' + paramsId,
      ];
      const mobileResponsive = Url.includes(pathName);
      if (isMobile && !mobileResponsive) {
         window.location.href = '/mobile-view';
      }
   }

   componentWillUnmount() {
      window.removeEventListener('error', (errorEvt: ErrorEvent) => explicitErrorHandler(errorEvt));
      /* TODO: internet connection disconnect temprarly hided */
      /* window.removeEventListener('offline', () => {}); */
      window.removeEventListener('unhandledrejection', (errorEvt) => {
         errorEvt.stopImmediatePropagation();
         errorEvt.stopPropagation();
      });
   }
}
const advancedMatching = {};
const options = {
   autoConfig: true,
   debug: false,
};

const pixelId = Constant.facebookPixelId;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
ReactPixel.init(pixelId, advancedMatching, options);
const appWindow: any = window;
if (appWindow?.fbq) {
   appWindow.fbq.disablePushState = true;
}
export default App;
