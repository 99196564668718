import { createAction } from '@reduxjs/toolkit';
import { locumSagaTypes } from 'types';

export const GET_STATE_AND_TRAVEL_SAGA = 'GET_STATE_AND_TRAVEL_SAGA';
export const getStateAndTravelSagaAction = createAction(GET_STATE_AND_TRAVEL_SAGA);

export const ADD_STATE_AND_TRAVEL_SAGA = 'ADD_STATE_AND_TRAVEL_SAGA';
export const addStateAndTravelSagaAction = createAction<locumSagaTypes.AddStateAndTravelSagaParams>(
   ADD_STATE_AND_TRAVEL_SAGA
);

export const PRACTISE_ADMIN_LOCUM_LIST = 'PRACTISE_ADMIN_LOCUM_LIST';
export const practiseAdminLocumList = createAction<locumSagaTypes.IPhysicianListSagaParams>(PRACTISE_ADMIN_LOCUM_LIST);

export const PRACTISE_ADMIN_LOCUM_PHY_BOOK = 'PRACTISE_ADMIN_LOCUM_PHY_BOOK';
export const practiseAdminLocumBookPhy = createAction<locumSagaTypes.IBookPhysicianListSagaParams>(
   PRACTISE_ADMIN_LOCUM_PHY_BOOK
);
export const GET_LOCUM_SERVICE_STATE_AND_TRAVEL_SAGA = 'GET_LOCUM_SERVICE_STATE_AND_TRAVEL_SAGA';
export const getLocumStateAndTravelSagaAction = createAction<locumSagaTypes.IGetLocumStateAndTravelSagaParams>(
   GET_LOCUM_SERVICE_STATE_AND_TRAVEL_SAGA
);

export const ADD_UPDATE_LOCUM_SERVICE = 'ADD_UPDATE_LOCUM_SERVICE';
export const addUpdateLocumServiceSagaAction = createAction<locumSagaTypes.IAddUpdateLocumServiceSagaParams>(
   ADD_UPDATE_LOCUM_SERVICE
);

export const BOOK_SERVICE = 'BOOK_SERVICE';
export const bookService = createAction<locumSagaTypes.IBookService>(BOOK_SERVICE);

export const GET_LOCUM_MONTH_WISE_SERVICE_LIST_SAGA = 'GET_LOCUM_MONTH_WISE_SERVICE_LIST_SAGA';
export const getLocumMonthWiseServiceListSagaAction = createAction<locumSagaTypes.ILocumMonthWiseServiceListSagaParams>(
   GET_LOCUM_MONTH_WISE_SERVICE_LIST_SAGA
);

export const GET_ONE_LOCUM_SERVICE_SAGA = 'GET_ONE_LOCUM_SERVICE_SAGA';
export const getOneLocumServiceSagaAction = createAction<locumSagaTypes.IGetOneLocumServiceSagaParams>(
   GET_ONE_LOCUM_SERVICE_SAGA
);

export const DELETE_LOCUM_SERVICE = 'DELETE_LOCUM_SERVICE';
export const deleteLocumServiceSagaAction = createAction<locumSagaTypes.IGetOneLocumServiceSagaParams>(
   DELETE_LOCUM_SERVICE
);
