import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   DAILY_CHECK_INS_MASTER_LIST,
   DAILY_CHECK_INS_LIST,
   DAILY_CHECK_INS_GET_ONE,
   DAILY_CHECK_INS_ADD,
   DAILY_CHECK_INS_ADD_POST,
   UPDATED_CHECK_INS,
   CONTINUOUS_CHECK_INS,
   CHECK_IN_STREAKS_MASTER_LIST,
   CHECK_IN_STREAKS_USER_LIST,
   CHECK_IN_STREAK_FEED,
} from 'reduxToolKit/actions';
import { dailyCheckInsSlice } from 'reduxToolKit/reducers';
import { checkLogPageTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   dailyCheckInsMasterListRequest,
   dailyCheckInsMasterListSuccess,
   dailyCheckInsMasterListFailed,
   dailyCheckInsListRequest,
   dailyCheckInsListSuccess,
   dailyCheckInsListFailed,
   dailyCheckInsGetOneRequest,
   dailyCheckInsGetOneSuccess,
   dailyCheckInsGetOneFailed,
   dailyCheckInsAddRequest,
   dailyCheckInsAddSuccess,
   dailyCheckInsAddFailed,
   dailyCheckInsAddPostRequest,
   dailyCheckInsAddPostSuccess,
   dailyCheckInsAddPostFailed,
   dailyCheckInsUpdatedRequest,
   dailyCheckInsUpdatedSuccess,
   dailyCheckInsUpdatedFailed,
   dailyCheckInsContinuousRequest,
   dailyCheckInsContinuousSuccess,
   dailyCheckInsContinuousFailed,
   dailyCheckInsStreakMasterListRequest,
   dailyCheckInsStreakMasterListSuccess,
   dailyCheckInsStreakMasterListFailed,
   dailyCheckInsStreakUserListRequest,
   dailyCheckInsStreakUserListSuccess,
   dailyCheckInsStreakUserListFailed,
   dailyCheckInsStreakFeedRequest,
   dailyCheckInsStreakFeedSuccess,
   dailyCheckInsStreakFeedFailed,
} = dailyCheckInsSlice.actions;

const dailyCheckInsMasterList = function* () {
   yield put(dailyCheckInsMasterListRequest());
   try {
      const checkUser = true;
      const peopleMayYouKnow = true;
      const response: AxiosResponse = yield apiCall({ peopleMayYouKnow, checkUser, ...API.dailyCheckInsMasterList });
      if (response.status === 200) {
         const responseData = response.data;
         const checkInsMasterList = {
            list: responseData.data,
            message: response.data?.message || messages.success,
         };
         yield put(dailyCheckInsMasterListSuccess(checkInsMasterList));
      } else {
         yield put(dailyCheckInsMasterListFailed(response.data));
      }
   } catch (error) {}
};

const dailyCheckInsList = function* (dailyCheckInsListPayload: any) {
   yield put(dailyCheckInsListRequest(dailyCheckInsListPayload.payload));
   try {
      const params = dailyCheckInsListPayload.payload;
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ params, checkUser, ...API.dailyCheckInsList });
      if (response.status === 200) {
         const responseData = response.data.data;
         const checkLogList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message || messages.success,
         };
         yield put(dailyCheckInsListSuccess(checkLogList));
      } else {
         yield put(dailyCheckInsListFailed(response.data));
      }
   } catch (error) {}
};

const dailyCheckInsGetOne = function* (dailyCheckInsGetOnePayload: any) {
   yield put(dailyCheckInsGetOneRequest(dailyCheckInsGetOnePayload.payload));
   try {
      const params = dailyCheckInsGetOnePayload.payload;
      const checkUser = false;

      const url = { apiPath: `${API.dailyCheckInsGetOne.apiPath}${params.id}`, action: API.dailyCheckInsGetOne.action };
      const response: AxiosResponse = yield apiCall({ checkUser, ...url });
      if (response.status === 200) {
         const responseData = response.data;
         const checkInsGetOne = {
            data: responseData.data,
            message: response.data?.message || messages.success,
         };
         yield put(dailyCheckInsGetOneSuccess(checkInsGetOne));
      } else {
         yield put(dailyCheckInsGetOneFailed(response.data));
      }
   } catch (error) {}
};

const dailyCheckInsAdd = function* (dailyCheckInsAddPayload: any) {
   yield put(dailyCheckInsAddRequest(dailyCheckInsAddPayload.payload));
   try {
      const data = { check_in_reaction_detail_id: dailyCheckInsAddPayload.payload.check_in_reaction_detail_id };
      const checkUser = false;

      const response: AxiosResponse = yield apiCall({ checkUser, data, ...API.dailyCheckInsAdd });
      if (response.status === 200) {
         const responseData = response.data;
         const dailyCheckInsAdd = {
            data: responseData.data,
            message: response.data?.message || messages.success,
         };
         yield put(dailyCheckInsAddSuccess(dailyCheckInsAdd));
      } else {
         yield put(dailyCheckInsAddFailed(response.data));
      }
   } catch (error) {}
};

const dailyCheckInsAddPost = function* (dailyCheckInsAddPostPayload: any) {
   yield put(dailyCheckInsAddPostRequest(dailyCheckInsAddPostPayload.payload));
   try {
      const data = dailyCheckInsAddPostPayload.payload.data;
      const params = dailyCheckInsAddPostPayload.payload.params;
      const checkUser = false;

      const url = {
         apiPath: `${API.dailyCheckInsAddPost.apiPath}${params.id}`,
         action: API.dailyCheckInsAddPost.action,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...url });
      if (response.status === 200) {
         const responseData = response.data;
         const dailyCheckInsAddPost = {
            data: responseData.data,
            message: response.data?.message || messages.success,
         };
         yield put(dailyCheckInsAddPostSuccess(dailyCheckInsAddPost));
      } else {
         yield put(dailyCheckInsAddPostFailed(response.data));
      }
   } catch (error) {}
};

const updatedCheckIns = function* () {
   yield put(dailyCheckInsUpdatedRequest());
   try {
      const checkUser = false;

      const response: AxiosResponse = yield apiCall({ checkUser, ...API.dailyCheckInsUpdated });
      if (response.status === 200) {
         const responseData = response.data;
         const dailyCheckInsUpdated = {
            data: responseData.data,
            message: response.data?.message || messages.success,
         };
         yield put(dailyCheckInsUpdatedSuccess(dailyCheckInsUpdated));
      } else {
         yield put(dailyCheckInsUpdatedFailed(response.data));
      }
   } catch (error) {}
};

const continuousCheckIns = function* () {
   yield put(dailyCheckInsContinuousRequest());
   try {
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ checkUser, ...API.dailyCheckInsContinuous });
      if (response.status === 200) {
         const responseData = response.data;
         const dailyCheckInsContinuous = {
            data: responseData.data,
            message: response.data?.message || messages.success,
         };
         yield put(dailyCheckInsContinuousSuccess(dailyCheckInsContinuous));
      } else {
         yield put(dailyCheckInsContinuousFailed(response.data));
      }
   } catch (error) {}
};

const dailyCheckInsStreakMasterList = function* (
   payloadParams: PayloadAction<checkLogPageTypes.ICheckInsStreaksMasterList>
) {
   yield put(dailyCheckInsStreakMasterListRequest(payloadParams.payload));
   try {
      const response: AxiosResponse = yield apiCall({
         checkUser: false,
         params: payloadParams.payload,
         ...API.dailyCheckInsStreaksMasterList,
      });
      if (response.status === 200) {
         const responseData = response.data.data;
         const checkInsMasterList = {
            list: responseData.data,
            message: response.data?.message || messages.success,
         };
         yield put(dailyCheckInsStreakMasterListSuccess(checkInsMasterList));
      } else {
         yield put(dailyCheckInsStreakMasterListFailed(response.data));
      }
   } catch (error) {}
};

const dailyCheckInsStreakUserList = function* (
   payloadParams: PayloadAction<checkLogPageTypes.ICheckInsStreaksMasterList>
) {
   yield put(dailyCheckInsStreakUserListRequest(payloadParams.payload));
   try {
      const response: AxiosResponse = yield apiCall({
         checkUser: false,
         params: payloadParams.payload,
         ...API.dailyCheckInsStreakUserList,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const checkInsMasterList = {
            list: responseData.data,
            message: response.data?.message || messages.success,
         };
         yield put(dailyCheckInsStreakUserListSuccess(checkInsMasterList));
      } else {
         yield put(dailyCheckInsStreakUserListFailed(response.data));
      }
   } catch (error) {}
};

const dailyCheckInsStreakFeed = function* (payloadData: PayloadAction<checkLogPageTypes.ICheckInsStreakFeed>) {
   yield put(dailyCheckInsStreakFeedRequest(payloadData.payload));
   try {
      const response: AxiosResponse = yield apiCall({
         checkUser: false,
         ...API.dailyCheckInsStreakFeed,
         data: payloadData.payload,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const checkInsMasterList = {
            data: responseData.data,
            message: response.data?.message || messages.success,
         };
         yield put(dailyCheckInsStreakFeedSuccess(checkInsMasterList));
      } else {
         yield put(dailyCheckInsStreakFeedFailed(response.data));
      }
   } catch (error) {}
};

export const takeDailyCheckInsRequest = function* () {
   yield takeEvery(DAILY_CHECK_INS_MASTER_LIST, dailyCheckInsMasterList);
   yield takeEvery(DAILY_CHECK_INS_LIST, dailyCheckInsList);
   yield takeEvery(DAILY_CHECK_INS_GET_ONE, dailyCheckInsGetOne);
   yield takeEvery(DAILY_CHECK_INS_ADD, dailyCheckInsAdd);
   yield takeEvery(DAILY_CHECK_INS_ADD_POST, dailyCheckInsAddPost);
   yield takeEvery(UPDATED_CHECK_INS, updatedCheckIns);
   yield takeEvery(CONTINUOUS_CHECK_INS, continuousCheckIns);
   yield takeEvery(CHECK_IN_STREAKS_MASTER_LIST, dailyCheckInsStreakMasterList);
   yield takeEvery(CHECK_IN_STREAKS_USER_LIST, dailyCheckInsStreakUserList);
   yield takeEvery(CHECK_IN_STREAK_FEED, dailyCheckInsStreakFeed);
};
