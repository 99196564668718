import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   COMMENT_REPLY_ADD,
   COMMENT_REPLY_DELETE,
   COMMENT_REPLY_UPDATE,
   eventTrackAction,
   GET_REPLY_COMMENTS,
} from 'reduxToolKit/actions';
import {
   commentsReplyAddSlice,
   commentsReplyDeleteSlice,
   commentsReplyUpdateSlice,
   commonSlice,
   getReplyCommentsListSlice,
} from 'reduxToolKit/reducers';
import { eventTrackTypes, feedSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { commentsReplyAddRequest, commentsReplyAddSuccess, commentsReplyAddFailed } = commentsReplyAddSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const commentReplyAdd = function* (commentsReplyAdd: PayloadAction<feedSagaTypes.ICommentReplyAddSagaRequest>) {
   yield put(commentsReplyAddRequest(commentsReplyAdd.payload));
   try {
      const data = commentsReplyAdd.payload;
      const peopleMayYouKnow = true;
      let apiUrl = API.commentReplyAdd;
      if (data.isAskADoc) {
         apiUrl = API.askADocCommentReplyAdd;
      }

      const response: AxiosResponse = yield apiCall({ peopleMayYouKnow, data, ...apiUrl });
      if (response.status === 200) {
         const resObj = { data: response.data, requestData: data };
         yield put(commentsReplyAddSuccess(resObj));
         if (commentsReplyAdd.payload.event) {
            const eventData: eventTrackTypes.IEventTrack = {
               event: {
                  name: commentsReplyAdd.payload.event.name,
               },
            };
            yield put(eventTrackAction(eventData));
         }
      } else {
         yield put(commentsReplyAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   commentsReplyUpdateRequest,
   commentsReplyUpdateSuccess,
   commentsReplyUpdateFailed,
} = commentsReplyUpdateSlice.actions;

const commentsReplyUpdate = function* (
   commentsReplyUpdate: PayloadAction<feedSagaTypes.ICommentReplyUpdateSagaRequest>
) {
   yield put(commentsReplyUpdateRequest(commentsReplyUpdate.payload));
   try {
      const data = commentsReplyUpdate.payload;
      let apiPath = {
         apiPath: `${API.commentReplyUpdate.apiPath}${commentsReplyUpdate.payload.comment_reply_id}`,
         action: API.commentReplyUpdate.action,
      };
      if (data.isAskADoc) {
         apiPath = {
            apiPath: `${API.askADocCommentReplyUpdate.apiPath}${commentsReplyUpdate.payload.comment_reply_id}`,
            action: API.askADocCommentReplyUpdate.action,
         };
      }
      const peopleMayYouKnow = true;

      const response: AxiosResponse = yield apiCall({ peopleMayYouKnow, data, ...apiPath });
      if (response.status === 200) {
         const resObj = { data: response.data, requestData: data };
         yield put(commentsReplyUpdateSuccess(resObj));
         if (commentsReplyUpdate.payload.event) {
            const eventData: eventTrackTypes.IEventTrack = {
               event: {
                  name: commentsReplyUpdate.payload.event.name,
               },
            };
            yield put(eventTrackAction(eventData));
         }
      } else {
         yield put(commentsReplyUpdateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   commentsReplyDeleteRequest,
   commentsReplyDeleteSuccess,
   commentsReplyDeleteFailed,
} = commentsReplyDeleteSlice.actions;

const commentsReplyDelete = function* (
   commentsReplyDelete: PayloadAction<feedSagaTypes.ICommentReplyDeleteSagaRequest>
) {
   yield put(commentsReplyDeleteRequest(commentsReplyDelete.payload));
   try {
      const data = commentsReplyDelete.payload;
      let apiPath = {
         apiPath: `${API.commentReplyDelete.apiPath}${commentsReplyDelete.payload.comment_reply_id}`,
         action: API.commentReplyDelete.action,
      };
      if (data.isAskADoc) {
         apiPath = {
            apiPath: `${API.askADocCommentReplyDelete.apiPath}${commentsReplyDelete.payload.comment_reply_id}`,
            action: API.askADocCommentReplyDelete.action,
         };
      }
      const peopleMayYouKnow = true;

      const response: AxiosResponse = yield apiCall({ peopleMayYouKnow, data, ...apiPath });
      if (response.status === 200) {
         const resObj = { data: response.data, requestData: data };
         yield put(commentsReplyDeleteSuccess(resObj));
         if (commentsReplyDelete.payload.event) {
            const eventData: eventTrackTypes.IEventTrack = {
               event: {
                  name: commentsReplyDelete.payload.event.name,
               },
            };
            yield put(eventTrackAction(eventData));
         }
      } else {
         yield put(commentsReplyDeleteFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   getReplyCommentsListRequest,
   getReplyCommentsListSuccess,
   getReplyCommentsListFailed,
} = getReplyCommentsListSlice.actions;

const getReplyCommentsListSaga = function* (
   getReplyCommentsListParams: PayloadAction<feedSagaTypes.IGetReplyCommentsSagaRequest>
) {
   yield put(getReplyCommentsListRequest(getReplyCommentsListParams.payload));
   try {
      const requestPayload = getReplyCommentsListParams.payload;
      let params: feedSagaTypes.IGetReplyCommentsSagaRequest = {
         comment_id: requestPayload.comment_id,
         page: requestPayload.page,
         limit: requestPayload.limit,
         isAskADoc: requestPayload.isAskADoc,
         last_comment_reply_id: requestPayload.last_comment_reply_id,
      };
      if (requestPayload.isAskADoc) {
         params = {
            ask_a_doc_feed_comment_id: requestPayload.comment_id,
            page: requestPayload.page,
            limit: requestPayload.limit,
            isAskADoc: requestPayload.isAskADoc,
            last_comment_reply_id: requestPayload.last_comment_reply_id,
         };
      }
      let apiPath = API.getReplyComments;
      if (requestPayload.isAskADoc) {
         apiPath = API.askADocCommentReplyGet;
      }
      const checkUser = true;
      const peopleMayYouKnow = true;
      const response: AxiosResponse = yield apiCall({ checkUser, peopleMayYouKnow, params, ...apiPath });
      if (response.status === 200) {
         const responseData = response.data.data;
         const tagUsersList = {
            list: responseData,
            message: response.data?.message,
            requestData: requestPayload,
         };
         yield put(getReplyCommentsListSuccess(tagUsersList));
      } else {
         yield put(getReplyCommentsListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeCommentReplyAddRequest = function* () {
   yield takeEvery(COMMENT_REPLY_ADD, commentReplyAdd);
};

export const takeCommentReplyUpdateRequest = function* () {
   yield takeEvery(COMMENT_REPLY_UPDATE, commentsReplyUpdate);
};

export const takeCommentReplyDeleteRequest = function* () {
   yield takeEvery(COMMENT_REPLY_DELETE, commentsReplyDelete);
};

export const takeGetReplyCommentsList = function* () {
   yield takeEvery(GET_REPLY_COMMENTS, getReplyCommentsListSaga);
};
