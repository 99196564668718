import { store } from 'reduxToolKit';
import { eventTrackAction } from 'reduxToolKit/actions';

export const setEventTrack = (eventType: string) => {
   const eventData = {
      event: {
         name: eventType,
      },
   };
   store.dispatch(eventTrackAction(eventData));
};
