import { put, takeEvery } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { UPDATE_MY_SERVICE } from 'reduxToolKit/actions';
import { commonSlice, myServiceUpdateSlice } from 'reduxToolKit/reducers';
import { postShareSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { myServiceUpdateRequest, myServiceUpdateSuccess, myServiceUpdateFailed } = myServiceUpdateSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const updateMyServiceSaga = function* (payloadState: PayloadAction<postShareSagaTypes.IMyServiceUpdateDataPayload>) {
   yield put(myServiceUpdateRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.myServiceUpdate });
      if (response.status === 200) {
         yield put(myServiceUpdateSuccess(response?.data || messages.success));
      } else {
         yield put(myServiceUpdateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeUpdateMyService = function* () {
   yield takeEvery(UPDATE_MY_SERVICE, updateMyServiceSaga);
};
