export const routerConstants = {
   PublicRoutesList: [
      '/login',
      '/register',
      '/forgot-password',
      '/set-password',
      '/confirm-join',
      '/home',
      '/mobile-view',
      '/hippa-content',
      '/email-verification',
      '/password-verify-otp',
      '/auth/doximity/callback',
      '/auth/apple/callback',
      '/linkedin',
   ],
   HeaderRoutesList: [
      '/feed',
      '/ask-a-doc/patient',
      '/second-opinion/case-list',
      '/second-opinion',
      '/ask-a-doc/physician',
      '/connections',
      '/oncklinic',
      '/drug-lookup',
      '/admin',
      '/locum-service/physician',
      '/my-profile',
      '/my-activities',
      '/my-saved-post',
      '/my-wallet',
      '/my-services',
      '/education-library',
      '/onc-drive',
      '/job-recruiter/physician',
      '/physician/treatment-estimate',
      '/support-group',
      '/pill-reminder-list/patient',
      '/patient/treatment-estimate',
      '/notification-feed/',
   ],
   googleAnalyticsExcludesRoutesList: [
      '/oncklinic',
      '/oncklinic/my-patients',
      '/oncklinic/patient/prescription',
      '/oncklinic/device-consent',
      '/oncklinic/symptom',
      '/oncklinic/report-symptom',
      '/oncklinic/tele-medicine',
      '/oncklinic/tele-medicine/:id',
      '/oncklinic/tele-medicine/document/:id/:code',
      '/oncklinic/video-call/:id',
      '/oncklinic/health-records',
      '/oncklinic/my-treating-physicians',
      '/oncklinic/my-treating-physicians/add-physician',
      '/oncklinic/image-video-consult',
      '/oncklinic/image-video-consult/document/:id/:code',
      '/oncklinic/schedule/:type?',
      '/oncklinic/my-patients/add-patient',
      '/oncklinic/rpm-dashboard',
      '/oncklinic/blood-pressure',
      '/oncklinic/smart-weight',
      '/oncklinic/thermometer',
      '/oncklinic/oximeter',
      '/oncklinic/notification',
      '/oncklinic/education-videos',
      '/oncklinic/my-appointments',
      '/oncklinic/clinical-history',
      '/oncklinic/clinical-history/billing-info-99453',
      '/oncklinic/clinical-history/billing-info-99454',
      '/oncklinic/clinical-history/billing-info-99457',
      '/oncklinic/clinical-history/billing-info-99458',
      '/oncklinic/clinical-history/billing-info-99091',
      '/oncklinic/clinical-history/billing-info-99421',
   ],
};

export const ROUTE_LOGIN = '/login';
export const ROTE_NOTIFICATION_FEED = '/notification-feed/';
