import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { VIDEO_LIBRARY_CATEGORY_LIST_SAGA } from 'reduxToolKit/actions';
import { commonSlice, videoLibraryCategoryListSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   videoLibraryCategoryListViewRequest,
   videoLibraryCategoryListViewSuccess,
   videoLibraryCategoryListViewFailed,
} = videoLibraryCategoryListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const videoLibraryCategoryListSaga = function* (videoLibraryCategoryListPayload: any) {
   yield put(videoLibraryCategoryListViewRequest(videoLibraryCategoryListPayload.payload));
   try {
      const params = videoLibraryCategoryListPayload.payload;
      const url = API.videoLibraryCategoryList;
      const response: AxiosResponse = yield apiCall({ params, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const oncDriveList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message,
         };
         yield put(videoLibraryCategoryListViewSuccess(oncDriveList));
      } else {
         yield put(videoLibraryCategoryListViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeVideoLibraryCategoryListRequest = function* () {
   yield takeEvery(VIDEO_LIBRARY_CATEGORY_LIST_SAGA, videoLibraryCategoryListSaga);
};
