import React from 'react';

import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { commonSlice } from 'reduxToolKit/reducers';
import { storeTypes } from 'types';

const ExceptionHandlerModal = () => {
   const dispatch = useDispatch();

   const exception = useSelector((state: storeTypes.IStore) => state.common.exception);

   const { exceptionHandlerRequest } = commonSlice.actions;

   const handleAlert = (message: string, popupType: any) => {
      return (
         <SweetAlert
            type={popupType}
            confirmBtnText="Reload"
            confirmBtnBsStyle={popupType === 'success' ? 'successBtn' : 'errorBtn'}
            title={message}
            onConfirm={() => {
               dispatch(exceptionHandlerRequest(''));
               window.location.reload();
            }}
            allowEscape={false}
            closeOnClickOutside={false}
         />
      );
   };

   return <>{exception?.error && typeof exception?.error !== 'object' && handleAlert(exception?.error, 'error')}</>;
};

export default ExceptionHandlerModal;
