import { createAction } from '@reduxjs/toolkit';
import { vdocipherSagaTypes } from 'types';

export const VDOCIPHER_ACTION = 'VDOCIPHER_ACTION';
export const vdocipherAction = createAction<vdocipherSagaTypes.IPostVideoCipherSagaPayload>(VDOCIPHER_ACTION);

export const VDOCIPHER_GET_VIDEO_QUESTIONS = 'VDOCIPHER_GET_VIDEO_QUESTIONS';
export const vdocipherGetVideoQuestion = createAction<vdocipherSagaTypes.IVdoCipherGetQuestionSagaPayload>(
   VDOCIPHER_GET_VIDEO_QUESTIONS
);
