import { createSlice } from '@reduxjs/toolkit';
import { peopleNearMeReducerTypes } from 'types';

const initialState: peopleNearMeReducerTypes.ICancerTypeListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
};

export const consentListSlice = createSlice({
   name: 'consentList',
   initialState: initialState,
   reducers: {
      consentListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      consentListRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      consentListSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.page === 1 ? payload.list : [...state.list, ...payload.list],
            total_records: payload.total_records,
            isLoading: false,
         };
      },
      consentListFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      consentListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
