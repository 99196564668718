import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { askADocPageTypes } from 'types';

const initialState: askADocPageTypes.IGetOneSummaryReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
export const getOneSummarySlice = createSlice({
   name: 'getOneSummary',
   initialState: initialState,
   reducers: {
      getOneSummaryRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getOneSummarySuccess: (state, action: PayloadAction<askADocPageTypes.IGetOneSummaryReducer>) => {
         return {
            ...state,
            isLoading: false,
            data: action.payload.data,
         };
      },
      getOneSummaryFailed: (state, action: PayloadAction<askADocPageTypes.IGetOneSummaryReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      getOneSummaryReset: (state) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            errorMessage: '',
         };
      },
   },
});
