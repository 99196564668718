import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { SUPPORT_GROUP_READ_ALL, SUPPORT_GROUP_UNREAD_COUNT } from 'reduxToolKit/actions';
import { commonSlice, supportGroupSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   supportGroupUnreadCountRequest,
   supportGroupUnreadCountSuccess,
   supportGroupUnreadCountFailed,
   supportGroupReadAllRequest,
   supportGroupReadAllSuccess,
   supportGroupReadAllFailed,
} = supportGroupSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const supportGroupUnreadCountSaga = function* () {
   yield put(supportGroupUnreadCountRequest());
   try {
      const response: AxiosResponse = yield apiCall({ checkUser: false, ...API.supportGroupUnreadCount });
      if (response.status === 200) {
         const responseData = response.data;
         const unreadCountData = {
            message: responseData.message || messages.success,
            data: responseData.data,
         };
         yield put(supportGroupUnreadCountSuccess(unreadCountData));
      } else {
         yield put(supportGroupUnreadCountFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const supportGroupReadAllSaga = function* (params: PayloadAction<feedSagaTypes.ICreatePostProps>) {
   yield put(supportGroupReadAllRequest(params.payload));
   try {
      const response: AxiosResponse = yield apiCall({ checkUser: false, ...API.supportGroupReadAll });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(supportGroupReadAllSuccess(responseData));
      } else {
         yield put(supportGroupReadAllFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeSupportGroup = function* () {
   yield takeEvery(SUPPORT_GROUP_UNREAD_COUNT, supportGroupUnreadCountSaga);
   yield takeEvery(SUPPORT_GROUP_READ_ALL, supportGroupReadAllSaga);
};
