import { PayloadAction } from '@reduxjs/toolkit';
import { AllEffect, CallEffect, all, call, put, takeEvery } from 'redux-saga/effects';
import { FILL_PROFILE } from 'reduxToolKit/actions';
import { commonSlice, populationServedSlice, userDetailsSlice, serviceOfferedSlice } from 'reduxToolKit/reducers';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';

const {
   getUserServiceOfferedRequest,
   getUserServiceOfferedSuccess,
   getUserServiceOfferedFailed,
} = serviceOfferedSlice.actions;

const {
   getUserPopulationServedRequest,
   getUserPopulationServedSuccess,
   getUserPopulationServedFailed,
} = populationServedSlice.actions;
const { getUserDetails, getUserDetailSuccess, getUserDetailFailed } = userDetailsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

function* testAllSaga(fillProfilePayload: PayloadAction<any>) {
   const params = fillProfilePayload.payload.params;
   const dataParams = fillProfilePayload.payload.data;
   yield put(getUserServiceOfferedRequest(params));
   yield put(getUserPopulationServedRequest(params));
   yield put(getUserDetails(dataParams));
   try {
      const dataArray: AllEffect<CallEffect<any>>[][] = yield all([
         call(apiCall, {
            checkUser: true,
            goUrl: true,
            ...API.userDetails,
         }),
         call(apiCall, {
            checkUser: true,
            peopleMayYouKnow: true,
            params,
            ...API.getUserServiceOffered,
         }),
         call(apiCall, {
            checkUser: true,
            peopleMayYouKnow: true,
            params,
            ...API.getUserPopulationServed,
         }),
      ]);
      const response: any[] = listParseAndStringify(dataArray);

      if (response[0].status === 200) {
         yield put(getUserDetailSuccess(response[0].data));
      } else {
         yield put(getUserDetailFailed(response[0].data));
      }

      if (response[1].status === 200) {
         const responseData = response[1].data;
         const getUserServiceOfferedList = {
            list: responseData?.data?.data || [],
            total_records: responseData?.data?.total || 0,
            message: messages.success,
         };
         yield put(getUserServiceOfferedSuccess(getUserServiceOfferedList));
      } else {
         yield put(getUserServiceOfferedFailed(response[1].data));
      }

      if (response[2].status === 200) {
         const responseData = response[2].data;
         const getUserPopulationServedList = {
            data: responseData?.data || null,
            total_records: 0,
            message: messages.success,
         };
         yield put(getUserPopulationServedSuccess(getUserPopulationServedList));
      } else {
         yield put(getUserPopulationServedFailed(response[2].data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
}

export const takeFillProfile = function* () {
   yield takeEvery(FILL_PROFILE, testAllSaga);
};
