import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { publicationReducerTypes, publicationSagaTypes } from 'types';

const initialState: publicationReducerTypes.IProfilePublication = {
   isLoading: false,
   deletePublication: {
      error: '',
      message: '',
   },
   updatePublications: {
      error: '',
      message: '',
   },
};

//deletePublication Slice
export const profilePublicationSlice = createSlice({
   name: 'profilePublication',
   initialState: initialState,
   reducers: {
      deletePublicationRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      deletePublicationSuccess: (
         state,
         action: PayloadAction<publicationReducerTypes.IProfilePublicationSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            deletePublication: {
               message: payload?.message,
               error: '',
            },
         };
      },
      deletePublicationFailed: (
         state,
         action: PayloadAction<publicationReducerTypes.IProfilePublicationFailedPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            deletePublication: {
               error: payload.error,
               message: '',
            },
         };
      },
      deletePublicationReset: (state) => {
         return {
            ...initialState,
         };
      },

      updatePublicationRequest: (state, action: PayloadAction<publicationSagaTypes.IUpdatePublication>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      updatePublicationSuccess: (
         state,
         action: PayloadAction<publicationReducerTypes.IProfilePublicationSuccessPayload>
      ) => {
         return {
            ...state,
            isLoading: false,
            updatePublications: {
               message: action.payload?.message,
               error: '',
            },
         };
      },
      updatePublicationFailed: (
         state,
         action: PayloadAction<publicationReducerTypes.IProfilePublicationFailedPayload>
      ) => {
         return {
            ...state,
            isLoading: false,
            updatePublications: {
               error: action.payload?.error,
               message: '',
            },
         };
      },

      updatePublicationReset: (state) => {
         return {
            ...initialState,
         };
      },
   },
});
