import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { MY_CLINIC_PATIENTS_LIST, CHECK_IS_MY_PATIENT_ACTION } from 'reduxToolKit/actions';
import { commonSlice, myClinicPatientsListSlice } from 'reduxToolKit/reducers';
import { userSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   myClinicPatientsListLoading,
   myClinicPatientsListViewRequest,
   myClinicPatientsListViewSuccess,
   myClinicPatientsListFailed,
   checkIsMyPatientRequest,
   checkIsMyPatientSuccess,
   checkIsMyPatientFailed,
} = myClinicPatientsListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const myClinicPatientsSaga = function* (cancerTypePayload: any) {
   yield put(myClinicPatientsListLoading());
   yield put(myClinicPatientsListViewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.oncClinicMyPatientsList });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            message: response.data?.message,
         };
         yield put(myClinicPatientsListViewSuccess(oncDriveList));
      } else {
         yield put(myClinicPatientsListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const checkIsMyPatientSaga = function* (payloadState: PayloadAction<userSagaTypes.IPatientId>) {
   yield put(checkIsMyPatientRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = {
         patient_id: payloadState.payload?.patient_id,
         is_connection_check: payloadState.payload?.is_connection_check || 0,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getIsMyPatient });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
            data: response.data?.data,
         };
         yield put(checkIsMyPatientSuccess(responseData));
      } else {
         yield put(checkIsMyPatientFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeMyClinicPatientsRequest = function* () {
   yield takeLatest(MY_CLINIC_PATIENTS_LIST, myClinicPatientsSaga);
   yield takeEvery(CHECK_IS_MY_PATIENT_ACTION, checkIsMyPatientSaga);
};
