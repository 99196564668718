import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { AUTH_SET_PASSWORD } from 'reduxToolKit/actions';
import { setPasswordSlice, commonSlice } from 'reduxToolKit/reducers';
import { authSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { setPasswordRequest, setPasswordSuccess, setPasswordFailed } = setPasswordSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const setPasswordSaga = function* (payloadState: PayloadAction<authSagaTypes.ISetPasswordSagaPayload>) {
   yield put(setPasswordRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload.setPasswordPayload;
      let url;
      if (
         payloadState.payload.type === 'nurse' ||
         payloadState.payload.type === 'practice_administrator' ||
         payloadState.payload.type === 'set_password' ||
         payloadState.payload.type === 'physician' ||
         payloadState.payload.type === 'auxiliary_staff'
      ) {
         url = API.setPassword;
      } else if (payloadState.payload.type === 'phone') {
         url = API.resetViaPhone;
      } else {
         url = API.resetPassword;
      }
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...url });
      if (response.status === 200) {
         const resObj = {
            message: response?.data?.message || messages.success,
         };
         yield put(setPasswordSuccess(resObj));
      } else {
         yield put(setPasswordFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeSetPassword = function* () {
   yield takeLatest(AUTH_SET_PASSWORD, setPasswordSaga);
};
