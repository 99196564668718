import { AxiosResponse } from 'axios';
import { delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
   ADD_PRESCRIPTION,
   RPM_PENDING_PRESCRIPTION,
   RPM_PRESCRIPTION_LIST,
   RPM_PRESCRIPTION_UPDATE,
} from 'reduxToolKit/actions';
import {
   commonSlice,
   rpmPendingPrescriptionSlice,
   rpmPrescriptionAddSlice,
   rpmPrescriptionSlice,
   rpmPrescriptionUpdateSlice,
} from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   rpmPrescriptionLoading,
   rpmPrescriptionRequest,
   rpmPrescriptionSuccess,
   rpmPrescriptionFailed,
} = rpmPrescriptionSlice.actions;
const {
   rpmPendingPrescriptionLoading,
   rpmPendingPrescriptionRequest,
   rpmPendingPrescriptionSuccess,
   rpmPendingPrescriptionFailed,
} = rpmPendingPrescriptionSlice.actions;
const {
   rpmPrescriptionUpdateLoading,
   rpmPrescriptionUpdateRequest,
   rpmPrescriptionUpdateSuccess,
   rpmPrescriptionUpdateFailed,
} = rpmPrescriptionUpdateSlice.actions;
const {
   rpmPrescriptionAddLoading,
   rpmPrescriptionAddRequest,
   rpmPrescriptionAddSuccess,
   rpmPrescriptionAddFailed,
} = rpmPrescriptionAddSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const rpmPrescriptionSaga = function* (prescriptionTypePayload: any) {
   yield put(rpmPrescriptionLoading());
   if (prescriptionTypePayload.payload.search_word) {
      yield delay(1000);
   }
   yield put(rpmPrescriptionRequest(prescriptionTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = prescriptionTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getPrescriptionList });
      if (response.status === 200) {
         const responseData = response.data;
         const prescriptionList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            message: responseData.message,
         };
         yield put(rpmPrescriptionSuccess(prescriptionList));
      } else {
         yield put(rpmPrescriptionFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
const rpmPendingPrescriptionSaga = function* (prescriptionTypePayload: any) {
   yield put(rpmPendingPrescriptionLoading());
   yield put(rpmPendingPrescriptionRequest(prescriptionTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = prescriptionTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.isPendingPrescription });
      if (response.status === 200) {
         const responseData = response?.data;
         const pendingPrescription = {
            isPendingPrescription: responseData?.data?.is_pending_prescription,
            isPendingPrescriptionMessage: responseData?.data?.message,
         };
         yield put(rpmPendingPrescriptionSuccess(pendingPrescription));
      } else {
         yield put(rpmPendingPrescriptionFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
const rpmPrescriptionUpdateSaga = function* (prescriptionTypePayload: any) {
   yield put(rpmPrescriptionUpdateLoading());
   yield put(rpmPrescriptionUpdateRequest(prescriptionTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = prescriptionTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.prescriptionUpdateStatus });
      if (response.status === 200) {
         const responseData = response.data;
         const prescriptionList = {
            message: responseData.message,
         };
         yield put(rpmPrescriptionUpdateSuccess(prescriptionList));
      } else {
         yield put(rpmPrescriptionUpdateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
const rpmPrescriptionAddSaga = function* (prescriptionTypePayload: any) {
   yield put(rpmPrescriptionAddLoading());
   yield put(rpmPrescriptionAddRequest(prescriptionTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = prescriptionTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addPrescription });
      if (response.status === 200) {
         const responseData = response.data;
         const prescriptionList = {
            message: responseData.message,
         };
         yield put(rpmPrescriptionAddSuccess(prescriptionList));
      } else {
         yield put(rpmPrescriptionAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeRPMPrescriptionRequest = function* () {
   yield takeLatest(RPM_PRESCRIPTION_LIST, rpmPrescriptionSaga);
   yield takeEvery(RPM_PENDING_PRESCRIPTION, rpmPendingPrescriptionSaga);
   yield takeEvery(RPM_PRESCRIPTION_UPDATE, rpmPrescriptionUpdateSaga);
   yield takeEvery(ADD_PRESCRIPTION, rpmPrescriptionAddSaga);
};
