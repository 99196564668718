import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { UPDATE_CLINIC_ADMIN_INVITES_STATUS } from 'reduxToolKit/actions';
import { commonSlice, updateClinicAdminInviteStatusSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   updateClinicAdminInviteStatusRequest,
   updateClinicAdminInviteStatusSuccess,
   updateClinicAdminInviteStatusFailed,
} = updateClinicAdminInviteStatusSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const updateClinicAdminInviteStatusSaga = function* (updateClinicAdminPayload: any) {
   yield put(updateClinicAdminInviteStatusRequest(updateClinicAdminPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = updateClinicAdminPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.updateClinicAdminInviteStatus });
      if (response.status === 200) {
         const responseData = response.data;
         const updateClinicAdminList = {
            message: responseData?.message || '',
         };
         yield put(updateClinicAdminInviteStatusSuccess(updateClinicAdminList));
      } else {
         yield put(updateClinicAdminInviteStatusFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeUpdateClinicAdminInviteStatus = function* () {
   yield takeEvery(UPDATE_CLINIC_ADMIN_INVITES_STATUS, updateClinicAdminInviteStatusSaga);
};
