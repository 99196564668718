/* eslint-disable */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './index.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/style.scss';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Constant from './config/Constant';
import { CacheLocation, FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';

// Enable Hard Refresh in S3
const history = createBrowserHistory();
const path = (/#!(\/.*)$/.exec(history.location.hash) || [])[1];
if (path) {
    history.replace(path);
}
if ('serviceWorker' in navigator) {
   navigator.serviceWorker
      .register('./firebase-messaging-sw.js')
      .then((registration) => {})
      .catch((err) => {});
}
if (Constant.BUGSNAG_ENABLED_DOMAINS.includes(window.location.host)) {
   Bugsnag.start({
      apiKey: Constant.BUGSNAG_API_KEY,
      plugins: [new BugsnagPluginReact()],
      releaseStage: 'dev',
   });

   // @ts-ignore
   const ErrorBoundary: any = Bugsnag.getPlugin('react').createErrorBoundary(React);

   ReactDOM.render(
      <ErrorBoundary>
         <FpjsProvider
            cacheLocation={CacheLocation.SessionStorage}
            loadOptions={{ apiKey: Constant.FINGER_PRINT_API_KEY, endpoint: Constant.FINGER_PRINT_END_POINT }}
         >
            <App />
         </FpjsProvider>
      </ErrorBoundary>,

      document.getElementById('root')
   );
} else {
   ReactDOM.render(
   <FpjsProvider
      cacheLocation={CacheLocation.SessionStorage}
      loadOptions={{ apiKey: Constant.FINGER_PRINT_API_KEY, endpoint: Constant.FINGER_PRINT_END_POINT }}
  >
      <App />
   </FpjsProvider>, document.getElementById('root'));
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
