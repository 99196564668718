import { createSlice } from '@reduxjs/toolkit';
import { medicalEnqReducerTypes } from 'types';

const initialState: medicalEnqReducerTypes.IAddMedicalEnqReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const medicalEnqAddSlice = createSlice({
   name: 'addMedicalEnq',
   initialState: initialState,
   reducers: {
      medicalEnqAddView: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      medicalEnqAddViewSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      medicalEnqAddViewFailed: (state, action) => {
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      medicalEnqAddReset: () => {
         return initialState;
      },
   },
});
export { medicalEnqAddSlice };
