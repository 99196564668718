import { createAction } from '@reduxjs/toolkit';
import { askADocPageTypes } from 'types';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const getAllUsersSagaAction = createAction(GET_ALL_USERS);

export const TREATMENT_ESTIMATE_ENABLED_PHYS = 'TREATMENT_ESTIMATE_ENABLED_PHYS';
export const getTreatmentEstimateEnabledPhysSagaAction = createAction<askADocPageTypes.ITreatmentEstimateEnabledPhysSagaProps>(
   TREATMENT_ESTIMATE_ENABLED_PHYS
);
