import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { askADocPatientReducerTypes, peopleNearMeReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: peopleNearMeReducerTypes.ICancerTypeListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
};

const initialStateExampleQuestions: askADocPatientReducerTypes.IAskADocExampleQuestions = {
   isLoading: false,
   error: '',
   message: '',
   data: [],
};

const initialStatePrePopulateCreateRequest: askADocPatientReducerTypes.IAskADocPrePopulateCrateRequest = {
   isLoading: false,
   error: '',
   message: '',
   data: [],
};

export const askADocListSlice = createSlice({
   name: 'askADocList',
   initialState: initialState,
   reducers: {
      askADocListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      askADocListViewRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      askADocListViewSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            message: payload.message || messages.success,
            isLoading: false,
         };
      },
      askADocListFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      askADocListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});

export const askADocExampleQusListSlice = createSlice({
   name: 'askADocExampleQusList',
   initialState: initialStateExampleQuestions,
   reducers: {
      askADocExampleQusListRequest: (state, action) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      askADocExampleQusListSuccess: (
         state,
         action: PayloadAction<askADocPatientReducerTypes.IAskADocExampleQuestions>
      ) => {
         const { payload } = action;
         return {
            ...state,
            data: payload.data,
            message: payload.message || messages.success,
            isLoading: false,
         };
      },
      askADocExampleQusListFailed: (
         state,
         action: PayloadAction<askADocPatientReducerTypes.IAskADocExampleQuestions>
      ) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      askADocExampleQusListReset: () => {
         return {
            ...initialStateExampleQuestions,
         };
      },
   },
});

export const askADocPrePopulateCreateRequestDataSlice = createSlice({
   name: 'askADocPrePopulateCreateRequestData',
   initialState: initialStatePrePopulateCreateRequest,
   reducers: {
      askADocPrePopulateCreateRequestDataRequest: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      askADocPrePopulateCreateRequestDataSuccess: (
         state,
         action: PayloadAction<askADocPatientReducerTypes.IAskADocPrePopulateCrateRequest>
      ) => {
         const { payload } = action;
         return {
            ...state,
            data: payload.data,
            message: payload.message || messages.success,
            isLoading: false,
         };
      },
      askADocPrePopulateCreateRequestDataFailed: (
         state,
         action: PayloadAction<askADocPatientReducerTypes.IAskADocPrePopulateCrateRequest>
      ) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      askADocPrePopulateCreateRequestDataReset: () => {
         return {
            ...initialStateExampleQuestions,
         };
      },
   },
});
