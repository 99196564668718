import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { SEC_OPINION_NEARBY_PHYSICIAN } from 'reduxToolKit/actions';
import { commonSlice, secondOpinionNearbyPhyListSlice } from 'reduxToolKit/reducers';
import { secondOpinionSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   secondOpinionNearbyPhyListFailure,
   secondOpinionNearbyPhyListRequest,
   secondOpinionNearbyPhyListSuccess,
} = secondOpinionNearbyPhyListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const secondOpinionPhyList = function* (
   payloadState: PayloadAction<secondOpinionSagaTypes.ISecOpinionNearbyPhysicianListSagaPayload>
) {
   yield put(secondOpinionNearbyPhyListRequest(payloadState.payload));
   try {
      const data = payloadState.payload.data;
      const params = payloadState.payload.params;
      const url = API.secOpinionNearbyPhyList;
      const checkUser = true;
      const goUrl = true;
      const response: AxiosResponse = yield apiCall({ params, data, checkUser, goUrl, ...url });
      if (response.status === 200) {
         const responseData = response?.data?.data;
         const dataObj = {
            data: responseData,
            message: response?.data?.message || messages.success,
         };
         yield put(secondOpinionNearbyPhyListSuccess(dataObj));
      } else {
         yield put(secondOpinionNearbyPhyListFailure(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeSecondOpinionNearbyPhysicianList = function* () {
   yield takeLatest(SEC_OPINION_NEARBY_PHYSICIAN, secondOpinionPhyList);
};
