import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reportPostReducerTypes } from 'types';

const initialState: reportPostReducerTypes.IReportPostReducerTypes = {
   isLoading: false,
   addReportPost: {
      message: '',
      error: '',
   },
   reportReasons: {
      message: '',
      list: [],
      total_records: 0,
      error: '',
   },
   addReportUserPost: {
      message: '',
      error: '',
   },
   addReportPostComment: {
      message: '',
      error: '',
   },
   addReportPostCommentReply: {
      message: '',
      error: '',
   },
   reportUserReasons: {
      message: '',
      list: [],
      total_records: 0,
      error: '',
   },
};

export const reportPostSlice = createSlice({
   name: 'reportPost',
   initialState: initialState,
   reducers: {
      getReportReasonRequest: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      getReportReasonSuccess: (state, action: PayloadAction<reportPostReducerTypes.IGetReportReasonSuccess>) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            reportReasons: {
               message: payload?.message,
               list: payload?.list,
               total_records: payload?.total_records,
               error: '',
            },
         };
      },
      getReportReasonFailed: (state, action: PayloadAction<reportPostReducerTypes.IReportFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            reportReasons: {
               error: action.payload?.error,
               list: [],
               total_records: 0,
               message: '',
            },
         };
      },

      addReportPostRequest: (state, action: PayloadAction<reportPostReducerTypes.IAddReportPost>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addReportPostSuccess: (state, action: PayloadAction<reportPostReducerTypes.IAddReportPostSuccess>) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            addReportPost: {
               message: payload?.message,
               error: '',
            },
         };
      },
      addReportPostFailed: (state, action: PayloadAction<reportPostReducerTypes.IReportFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            addReportPost: {
               error: action.payload?.error,
               message: '',
            },
         };
      },

      // add report user post
      addReportUserPostRequest: (state, action: PayloadAction<reportPostReducerTypes.IAddReportPost>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addReportUserPostSuccess: (state, action: PayloadAction<reportPostReducerTypes.IAddReportPostSuccess>) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            addReportUserPost: {
               message: payload?.message,
               error: '',
            },
         };
      },
      addReportUserPostFailed: (state, action: PayloadAction<reportPostReducerTypes.IReportFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            addReportUserPost: {
               error: action.payload?.error,
               message: '',
            },
         };
      },

      // add report post comment
      addReportPostCommentRequest: (state, action: PayloadAction<reportPostReducerTypes.IAddReportPost>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addReportPostCommentSuccess: (state, action: PayloadAction<reportPostReducerTypes.IAddReportPostSuccess>) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            addReportPostComment: {
               message: payload?.message,
               error: '',
            },
         };
      },
      addReportPostCommentFailed: (state, action: PayloadAction<reportPostReducerTypes.IReportFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            addReportPostComment: {
               error: action.payload?.error,
               message: '',
            },
         };
      },

      // add report post comment reply
      addReportPostCommentReplyRequest: (state, action: PayloadAction<reportPostReducerTypes.IAddReportPost>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addReportPostCommentReplySuccess: (
         state,
         action: PayloadAction<reportPostReducerTypes.IAddReportPostSuccess>
      ) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            addReportPostCommentReply: {
               message: payload?.message,
               error: '',
            },
         };
      },
      addReportPostCommentReplyFailed: (state, action: PayloadAction<reportPostReducerTypes.IReportFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            addReportPostCommentReply: {
               error: action.payload?.error,
               message: '',
            },
         };
      },
      reportPostReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
