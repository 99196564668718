import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_COMMENTS } from 'reduxToolKit/actions';
import { commonSlice, getCommentsListSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getCommentsListRequest, getCommentsListSuccess, getCommentsListFailed } = getCommentsListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getCommentsListSaga = function* (getCommentsListParams: PayloadAction<feedSagaTypes.IGetCommentsSagaRequest>) {
   yield put(getCommentsListRequest(getCommentsListParams.payload));
   try {
      const requestPayload = getCommentsListParams.payload;
      const params = {
         id: requestPayload.id,
         page: requestPayload.page,
         limit: requestPayload.limit,
         isAskADoc: requestPayload.isAskADoc,
         last_comment_id: requestPayload.last_comment_id,
      };
      const data = { last_displayed_comment_id: requestPayload.last_displayed_comment_id };

      let apiPath = API.getPostComments;
      if (requestPayload.isAskADoc) {
         apiPath = API.getAskADocFeedComments;
      }
      const checkUser = true;
      const goUrl = true;
      const response: AxiosResponse = yield apiCall({ goUrl, checkUser, params, data, ...apiPath });
      if (response.status === 200) {
         const responseData = response.data.data;
         const tagUsersList = {
            list: responseData.list,
            message: response.data?.message,
            requestData: requestPayload,
         };
         yield put(getCommentsListSuccess(tagUsersList));
      } else {
         yield put(getCommentsListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGetCommentsList = function* () {
   yield takeEvery(GET_COMMENTS, getCommentsListSaga);
};
