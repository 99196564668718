import { createAction } from '@reduxjs/toolkit';
import { deviceClinicNotesSagaTypes } from 'types';

export const GET_DEVICE_CLINIC_NOTE_COMMENTS = 'GET_DEVICE_CLINIC_NOTE_COMMENTS';
export const getClinicNoteComment = createAction<deviceClinicNotesSagaTypes.IDeviceClinicNoteGetComments>(
   GET_DEVICE_CLINIC_NOTE_COMMENTS
);

export const ADD_EDIT_DEVICE_CLINIC_NOTE_COMMENT = 'ADD_EDIT_DEVICE_CLINIC_NOTE_COMMENT';
export const addEditclinicNotecomment = createAction<deviceClinicNotesSagaTypes.IDeviceClinicNoteAddEditComment>(
   ADD_EDIT_DEVICE_CLINIC_NOTE_COMMENT
);

export const DEVICE_CLINIC_NOTE_COMMENTS_CLOSE = 'DEVICE_CLINIC_NOTE_COMMENTS_CLOSE';
export const clinicNoteCommentClose = createAction<deviceClinicNotesSagaTypes.IDeviceClinicNoteEndSession>(
   DEVICE_CLINIC_NOTE_COMMENTS_CLOSE
);

//Modal - DeviceAddClinicNote Actions
export const MODAL_DEVICE_ADD_CLINIC_NOTE_SAGA = 'MODAL_DEVICE_ADD_CLINIC_NOTE_SAGA';
export const modalDeviceAddClinicNoteAction = createAction<deviceClinicNotesSagaTypes.IDeviceClinicAddNoteSagaPayload>(
   MODAL_DEVICE_ADD_CLINIC_NOTE_SAGA
);

//Modal - DeviceEditClinicNote Actions
export const MODAL_DEVICE_EDIT_CLINIC_NOTE_SAGA = 'MODAL_DEVICE_EDIT_CLINIC_NOTE_SAGA';
export const modalDeviceEditClinicNoteAction = createAction<deviceClinicNotesSagaTypes.IDeviceClinicEditNoteSagaPayload>(
   MODAL_DEVICE_EDIT_CLINIC_NOTE_SAGA
);
