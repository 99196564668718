import Constant from 'config/Constant';
import { DummyUser, User } from 'types/modelHelpers';
import { IGetPatient, IGetPhysician } from 'types/teleMedicine/appointmentList/getAppointmentSagaTypes';
import * as MyHelper from 'utils/helpers/authHeader';

export const isPatient = (userData: User | IGetPhysician | IGetPatient | DummyUser | undefined | null) => {
   return userData ? userData.user_type === Constant.PATIENT : false;
};
export const isAdmin = (userData: User | IGetPhysician | IGetPatient | DummyUser | undefined | null) => {
   return userData ? userData.user_type === Constant.ADMIN : false;
};
export const isPhysician = (userData: User | IGetPhysician | IGetPatient | DummyUser | undefined | null) => {
   return userData ? userData.user_type === Constant.PHYSICIAN : false;
};

export const isNurse = (userData: User | IGetPhysician | IGetPatient | DummyUser | undefined | null) => {
   return userData ? userData.user_type === Constant.NURSE : false;
};

export const isPractiseAdmin = (userData: User | IGetPhysician | IGetPatient | DummyUser | undefined | null) => {
   return userData ? userData.user_type === Constant.PRACTISE_ADMIN : false;
};

export const isAuxiliaryStaff = (userData: User | IGetPhysician | IGetPatient | DummyUser | undefined | null) => {
   return userData ? userData.user_type === Constant.AUXILIARY_STAFF : false;
};

export const isRecruiter = (userData: User | IGetPhysician | IGetPatient | DummyUser | undefined | null) => {
   return userData ? userData.user_type === Constant.RECRUITER : false;
};

export const isCaregiver = (userData: User | IGetPhysician | IGetPatient | DummyUser | undefined | null) => {
   return userData ? userData.user_type === Constant.CAREGIVER : false;
};

export const isPatientAdvocate = (userData: User | IGetPhysician | IGetPatient | DummyUser | undefined | null) => {
   return userData ? userData.user_type === Constant.PATIENT_ADVOCATE : false;
};
export const isOrganization = (userData: User | IGetPhysician | IGetPatient | DummyUser | undefined | null) => {
   return userData ? userData.user_type === Constant.ORGANIZATION : false;
};
export const isPCAUser = (userData: any) => {
   if (isPatient(userData) || isCaregiver(userData) || isPatientAdvocate(userData)) {
      return true;
   }
   return false;
};

export const isPhysicianAdmin = (userData: User) => {
   if (isPhysician(userData)) {
      const clinicList = userData && userData.physician_clinic ? userData.physician_clinic : [];
      const index = clinicList.findIndex((x: any) => x.is_admin === Constant.IS_PHYSICIAN_ADMIN);
      if (index === -1) {
         return false;
      } else {
         return true;
      }
   } else {
      return false;
   }
};

export const isNurseAdmin = (userData: User | IGetPhysician | IGetPatient | DummyUser | undefined | null) => {
   if (isNurse(userData)) {
      return true;
   } else {
      return false;
   }
};

export const isNurseNursePractisener = (userData: User) => {
   if (isNurse(userData)) {
      const nurseType = userData && userData.nurse_type;
      if (nurseType === 2) {
         return true;
      } else {
         return false;
      }
   } else {
      return false;
   }
};

export const isClinicalPracticeAdmin = () => {
   const user = MyHelper.getUser();
   if (user) {
      if (isPractiseAdmin(user)) {
         if (user.practice_administrator_type === 1) {
            return true;
         } else if (user.practice_administrator_type === 0) {
            return false;
         }
      } else {
         return true;
      }
   }
};

export const isClinicalPracticeAdminOnly = () => {
   const user = MyHelper.getUser();
   if (user) {
      if (isPractiseAdmin(user)) {
         if (user.practice_administrator_type === 1) {
            return true;
         } else if (user.practice_administrator_type === 0) {
            return false;
         }
      } else {
         return false;
      }
   }
};

export const isClinicAuxiliaryStaff = () => {
   const user = MyHelper.getUser();
   if (user) {
      if (isAuxiliaryStaff(user)) {
         if (user.auxiliary_staff_type === 1) {
            return true;
         } else if (user.auxiliary_staff_type === 0) {
            return false;
         }
      } else {
         return true;
      }
   }
};

export const isClinicAuxiliaryStaffOnly = () => {
   const user = MyHelper.getUser();
   if (user) {
      if (isAuxiliaryStaff(user)) {
         if (user.auxiliary_staff_type === 1) {
            return true;
         } else if (user.auxiliary_staff_type === 0) {
            return false;
         }
      } else {
         return false;
      }
   }
};
