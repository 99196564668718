import { createSlice } from '@reduxjs/toolkit';
import { deviceReducesTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: deviceReducesTypes.IGetDeviceMinAndMax = {
   isLoading: false,
   error: '',
   message: '',
   getDeviceMinAndMax: {
      isLoading: false,
      message: '',
      error: '',
      data: null,
   },
   getDeviceWeightReadingDiff: {
      isLoading: false,
      message: '',
      error: '',
      data: null,
   },
   addDevices: {
      isLoading: false,
      message: '',
      error: '',
      data: null,
   },
};

export const deviceGetMinAndMaxSlice = createSlice({
   name: 'deviceGetMinAndMax',
   initialState: initialState,
   reducers: {
      deviceGetMinAndMaxLoading: (state) => {
         return {
            ...state,
            isLoading: true,
            getDeviceMinAndMax: {
               isLoading: true,
            },
         };
      },
      deviceGetMinAndMaxRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            getDeviceMinAndMax: {
               isLoading: true,
            },
         };
      },
      deviceGetMinAndMaxSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            getDeviceMinAndMax: {
               isLoading: false,
               data: payload.data,
            },
         };
      },
      deviceGetMinAndMaxFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            getDeviceMinAndMax: {
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },

      deviceGetMinAndMaxReset: (state) => {
         return {
            ...state,
            getDeviceMinAndMax: {
               ...initialState.getDeviceMinAndMax,
            },
         };
      },
      // device weight reading difference
      deviceGetWeightReadingDiffRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            getDeviceWeightReadingDiff: {
               isLoading: true,
            },
         };
      },
      deviceGetWeightReadingDiffSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            getDeviceWeightReadingDiff: {
               isLoading: false,
               data: payload.data,
            },
         };
      },
      deviceGetWeightReadingDiffFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            getDeviceWeightReadingDiff: {
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },

      deviceGetWeightReadingDiffReset: (state) => {
         return {
            ...state,
            getDeviceWeightReadingDiff: {
               ...initialState.getDeviceWeightReadingDiff,
            },
         };
      },
      // add temperature
      addDevicesRequest: (state, action) => {
         return {
            ...state,
            isLoading: false,
            addDevices: {
               ...state.addDevices,
               isLoading: true,
               ...action.payload,
            },
         };
      },
      addDevicesSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            addDevices: {
               isLoading: false,
               data: payload.data,
               message: payload.message || messages.success,
            },
         };
      },
      addDevicesFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            addDevices: {
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },

      addDevicesReset: (state) => {
         return {
            ...state,
            addDevices: {
               ...initialState.addDevices,
            },
         };
      },
   },
});
