import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_CONVERSATIONS_MESSAGES, ADD_CONVERSATIONS_MESSAGES } from 'reduxToolKit/actions';
import { conversationMessageAddGetSlice, commonSlice } from 'reduxToolKit/reducers';
import { conversationsSagatypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   conversationAddMesssageRequest,
   conversationAddMesssageSuccess,
   conversationAddMesssageFailed,
   conversationGetMesssageRequest,
   conversationGetMesssageSuccess,
   conversationGetMesssageFailed,
} = conversationMessageAddGetSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const conversationsAddMessageSaga = function* (
   payloadState: PayloadAction<conversationsSagatypes.IAskADocAddConversationMessage>
) {
   yield put(conversationAddMesssageRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = {
         conversation_id: payloadState.payload?.conversation_id || null,
         ask_a_doc_id: payloadState.payload?.ask_a_doc_id || null,
         listing_type: payloadState.payload.listing_type,
      };
      const data = {
         message: payloadState.payload.message,
         medias: payloadState.payload.medias,
      };
      const response: AxiosResponse = yield apiCall({
         checkUser,
         goUrl,
         params,
         data,
         ...API.askADocConversationAddMessage,
      });
      if (response.status === 200) {
         const resObj = {
            responseMessage: response.data.message || messages.success,
            data: response.data?.data,
         };
         yield put(conversationAddMesssageSuccess(resObj));
      } else {
         yield put(conversationAddMesssageFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const conversationGetMessagesSaga = function* (
   payloadState: PayloadAction<conversationsSagatypes.IaskADocGetConversationMessage>
) {
   yield put(conversationGetMesssageRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.askADocGetConversationMessage });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message || messages.success,
            data: response.data?.data || 0,
         };
         yield put(conversationGetMesssageSuccess(resObj));
      } else {
         yield put(conversationGetMesssageFailed(response.data || messages.error));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeConversationAddGetActions = function* () {
   yield takeEvery(ADD_CONVERSATIONS_MESSAGES, conversationsAddMessageSaga);
   yield takeEvery(GET_CONVERSATIONS_MESSAGES, conversationGetMessagesSaga);
};
