import { createSlice } from '@reduxjs/toolkit';
import { myProfileReducerTypes } from 'types';

const initialState: myProfileReducerTypes.IServiceOfferedReducer = {
   addServiceOffered: {
      isLoading: false,
      error: '',
      message: '',
   },
   getMasterServiceOffered: {
      isLoading: false,
      message: '',
      error: '',
      list: [],
   },
   getUserServiceOffered: {
      isLoading: false,
      message: '',
      error: '',
      list: [],
   },
};

export const serviceOfferedSlice = createSlice({
   name: 'serviceOffered',
   initialState: initialState,
   reducers: {
      // add service offered
      serviceOfferedAddRequest: (state, action) => {
         return {
            ...state,
            addServiceOffered: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      serviceOfferedAddSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            addServiceOffered: {
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      serviceOfferedAddFailed: (state, action: any) => {
         return {
            ...state,
            addServiceOffered: {
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      serviceOfferedAddReset: (state) => {
         return {
            ...state,
            addServiceOffered: initialState.addServiceOffered,
         };
      },

      // get master service offered
      getMasterServiceOfferedRequest: (state, action) => {
         return {
            ...state,
            getMasterServiceOffered: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getMasterServiceOfferedSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            getMasterServiceOffered: {
               list: payload.list,
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      getMasterServiceOfferedFailed: (state, action: any) => {
         return {
            ...state,
            getMasterServiceOffered: {
               list: state.getMasterServiceOffered.list,
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      getMasterServiceOfferedReset: (state) => {
         return {
            ...state,
            getMasterServiceOffered: initialState.getMasterServiceOffered,
         };
      },
      // get user service offered
      getUserServiceOfferedRequest: (state, action) => {
         return {
            ...state,
            getUserServiceOffered: {
               ...state.getUserServiceOffered,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getUserServiceOfferedSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            getUserServiceOffered: {
               ...state.getUserServiceOffered,
               list: payload.list,
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      getUserServiceOfferedFailed: (state, action: any) => {
         return {
            ...state,
            getUserServiceOffered: {
               ...state.getUserServiceOffered,
               list: state.getUserServiceOffered.list,
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      getUserServiceOfferedMessageReset: (state) => {
         return {
            ...state,
            getUserServiceOffered: {
               ...state.getUserServiceOffered,
               message: '',
               isLoading: false,
            },
         };
      },
      getUserServiceOfferedReset: (state) => {
         return {
            ...state,
            getUserServiceOffered: initialState.getUserServiceOffered,
         };
      },
   },
});
