import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { aboutMeReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: aboutMeReducerTypes.IModalCancersReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
   errors: [],
};

//Modal - getCancers Slice
export const modalCancersSlice = createSlice({
   name: 'modalCancers',
   initialState: initialState,
   reducers: {
      getCancersRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action?.payload?.page === 1 ? [] : state.list,
         };
      },
      getCancersSuccess: (state, action: PayloadAction<aboutMeReducerTypes.IModalCancersSuccessPayload>) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            isLoading: false,
            message: payload.message || messages.success,
         };
      },
      getCancersFailed: (state, action: PayloadAction<aboutMeReducerTypes.IModalCancersFailedPayload>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      getCancersMessageReset: (state) => {
         return {
            ...state,
            isLoading: false,
            message: '',
         };
      },

      getCancersReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
