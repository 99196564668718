import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { FEED_POLL_OPTION } from 'reduxToolKit/actions';
import { commonSlice, feedPollOptionSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { feedPollOptionRequest, feedPollOptionSuccess, feedPollOptionFailed } = feedPollOptionSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const feedPollOption = function* (feedPollOption: PayloadAction<feedSagaTypes.IPostPollOptionSagaPayload>) {
   yield delay(500);
   yield put(feedPollOptionRequest(feedPollOption.payload));
   try {
      const apiPath = {
         apiPath: `${API.feedPollOption.apiPath}${feedPollOption.payload.id}${`/vote`}`,
         action: API.feedPollOption.action,
      };
      const checkUser = true;

      const response: AxiosResponse = yield apiCall({ checkUser, ...apiPath });
      if (response.status === 200) {
         yield put(feedPollOptionSuccess(response.data));
      } else {
         yield put(feedPollOptionFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeFeedPollOptionRequest = function* () {
   yield takeLatest(FEED_POLL_OPTION, feedPollOption);
};
