import { createSlice } from '@reduxjs/toolkit';
import { joinVideoCallReducerTypes, joinVideoCallSagaTypes } from 'types';

const initialState: joinVideoCallReducerTypes.IVideoCallConnection = {
   videoConnection: null,
   isLoading: false,
   error: '',
   message: '',
};
const videoCallConnectionSlice = createSlice({
   name: 'videoConnection',
   initialState: initialState,
   reducers: {
      videoCallConnection: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      videoCallConnectionSuccess: (state, action: joinVideoCallSagaTypes.IGetVideoConnection) => {
         return {
            ...state,
            videoConnection: action.payload,
            isLoading: false,
         };
      },
      videoCallConnectionFailed: (state, action) => {
         return {
            ...state,
            videoConnection: null,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
   },
});
export { videoCallConnectionSlice };
