import environment from 'environment';

export default {
   siteURL: '',
   //baseURL: 'https://hitp7gl2gb.execute-api.us-east-1.amazonaws.com/dev',
   baseURL: environment.baseUrl,
   googleAPI: 'AIzaSyA9qviU5kSvtIXNfw6x5b7mkTEd3h3nP_M',
   imageBaseUrl: environment.imageBaseUrl,
   googlePlay: 'https://play.google.com/store/apps/details?id=com.oncopower',
   appStore: 'https://apps.apple.com/app/oncopower/id1466384848',
};
