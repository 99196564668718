import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { feedReducerTypes, feedSagaTypes } from 'types';

const initialState: feedReducerTypes.ICommentsReplyAddReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const commentsReplyAddSlice = createSlice({
   name: 'commentsReplyAdd',
   initialState: initialState,
   reducers: {
      commentsReplyAddRequest: (state, action: PayloadAction<feedSagaTypes.ICommentReplyAddSagaRequest>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      commentsReplyAddSuccess: (state, action: PayloadAction<feedReducerTypes.ICommentsReplyAddReducer>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      commentsReplyAddFailed: (state, action: PayloadAction<feedReducerTypes.ICommentsReplyAddReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error_message,
         };
      },
      commentsReplyAddReset: () => {
         return {
            ...initialState,
         };
      },
      commentsReplyAddResetMessage: (state) => {
         return {
            ...state,
            message: '',
         };
      },
   },
});
export { commentsReplyAddSlice };
