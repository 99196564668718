import React, { useCallback, useEffect, useState } from 'react';

import DateOfInitialDiagnosis from 'assets/fill-profile/date_of_initial_diagnosis.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import Constant from 'config/Constant';
import { useDispatch, useSelector } from 'react-redux';
import { addDiagnosisDateAction } from 'reduxToolKit/actions';
import { diagnosisSlice } from 'reduxToolKit/reducers';
import { DatePicker } from 'rsuite';
import { myProfileSagaTypes, storeTypes } from 'types';
import { DONE, NEXT, SUBMIT } from 'utils/constants/profileConstants';
import { convertDiagnosisToDateTime, isBefore100YearsFilterDate } from 'utils/helpers/dateHelpers';

const DiagnosisSection = (props: any) => {
   const dispatch = useDispatch();
   const [errorMessage, setErrorMessage] = useState<string>('');
   const [dateOfBirth, setDateOfBirth] = useState<any>(null);

   const { message: diagnosisMessage, error: diagnosisError } = useSelector(
      (state: storeTypes.IStore) => state.diagnosis
   );
   const { diagnosisReset } = diagnosisSlice.actions;

   useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
         document.body.style.overflow = 'scroll';
      };
   }, []);

   const hideAlert = (type: string) => {
      if (type === 'diagnosisSuccess') {
         if (props?.isFromDiagnosis) {
            props?.closeModelMyProfile();
            props.getUserData();
         }
         dispatch(diagnosisReset());
      } else if (type === 'diagnosisError') {
         dispatch(diagnosisReset());
         props?.setIsLoader(false);
      }
   };

   const changeDate = useCallback((date: Date) => {
      setErrorMessage('');
      setDateOfBirth(date);
   }, []);

   const clickNext = useCallback(() => {
      if (!dateOfBirth) {
         setErrorMessage('Date of initial diagnosis date cannot be blank');
         return;
      }
      props?.setIsLoader(true);
      const data: myProfileSagaTypes.IDiagnosisProps = {
         initial_diagnosis_date: convertDiagnosisToDateTime(dateOfBirth),
      };
      dispatch(addDiagnosisDateAction(data));
   }, [dateOfBirth]);

   const gotoNavigation = useCallback(() => {
      if (props?.isFromClinicalTrials) {
         props.navigation(props?.nextSectionClinicalTrails);
      } else {
         props.navigation(props.nexSection);
      }
   }, []);

   useEffect(() => {
      if (props.isDiagnosisDateValid) {
         gotoNavigation();
      }
      //edit
      setDateOfBirth(props?.userDetail?.initial_diagnosis_date || null);
   }, []);

   useEffect(() => {
      if (diagnosisMessage && props?.isFromDiagnosis) {
         const getAlert = () => (
            <InfoTimerAlert
               success
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={diagnosisMessage}
               onConfirm={() => hideAlert('diagnosisSuccess')}
            />
         );
         props?.setAlert(getAlert);
         props?.setIsLoader(false);
         props.currentModalClose();
      } else if (
         diagnosisMessage &&
         !props.isDisplay &&
         !props.isPrimary &&
         !props.isAddress &&
         !props.isDiagnosisDateValid &&
         !props?.isStagingValid &&
         !props?.isCancerStatusValid
      ) {
         props.setIsLoader(false);
         gotoNavigation();
      } else if (diagnosisMessage) {
         props.setIsLoader(false);
         gotoNavigation();
      }
   }, [diagnosisMessage]);

   return (
      <div>
         {diagnosisError ? (
            <InfoTimerAlert
               error
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={diagnosisError}
               onConfirm={() => hideAlert('diagnosisError')}
            />
         ) : null}
         <div className="col-12">
            <img className="common-race-icon diagnosis-icon mt--2" src={DateOfInitialDiagnosis} alt="icon" />
         </div>
         <div className="col-12">
            <p className="common-race-title-text diagnosis-title-text">Date of Initial Diagnosis</p>
         </div>
         <div className="col-12">
            <p className="common-race-paragraph-text diagnosis-paragraph-text  p-2">
               Please select your initial cancer diagnosis date
            </p>
         </div>
         <div className="col-12">
            <DatePicker
               preventOverflow={true}
               ranges={[]}
               disabledDate={(date) => isBefore100YearsFilterDate(date)}
               block={true}
               size="lg"
               placement={'top'}
               format={Constant.DATE_FORMAT}
               placeholder={Constant.DATE_FORMAT}
               value={dateOfBirth}
               limitEndYear={0}
               oneTap={true}
               onChange={changeDate}
            />
         </div>
         {errorMessage ? <div className="error-msg ml-4 mt-3 mr-4"> {errorMessage || ''}</div> : null}
         <div className="button">
            <button type="submit" className="button-start" id="diagnosisSectionSubmit" onClick={clickNext}>
               {props?.currentStateArray &&
               props?.currentStateArray[props?.currentStateArray?.length - 1] === props?.currentState
                  ? DONE
                  : props?.isFromDiagnosis
                  ? SUBMIT
                  : NEXT}
            </button>
         </div>
      </div>
   );
};

export default DiagnosisSection;
