import React from 'react';

export default function DotLoader() {
   return (
      <div className="feed-spinner">
         <div className="loadingname">
            <span className="bounce1" />
            <span className="bounce2" />
            <span className="bounce3" />
         </div>
      </div>
   );
}
