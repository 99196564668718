import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   ADD_GENETIC_TESTING,
   GET_MASTER_GENETIC_TESTING,
   GET_GENETIC_TESTING_LIST,
   DELETE_GENETIC_TESTING,
   GENETIC_TEST_CANCER_LIST,
} from 'reduxToolKit/actions';
import { commonSlice, geneticTestingSlice } from 'reduxToolKit/reducers';
import { myProfileReducerTypes, myProfileSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   geneticTestingAddRequest,
   geneticTestingAddSuccess,
   geneticTestingAddFailed,
   getMasterGeneticTestingRequest,
   getMasterGeneticTestingSuccess,
   getMasterGeneticTestingFailed,
   getGeneticTestingListRequest,
   getGeneticTestingListSuccess,
   getGeneticTestingListFailed,
   getGeneticTestingMasterListRequest,
   getGeneticTestingMasterListSuccess,
   getGeneticTestingMasterListFailed,
   geneticTestingDeleteRequest,
   geneticTestingDeleteSuccess,
   geneticTestingDeleteFailed,
} = geneticTestingSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const geneticTestingAddSaga = function* (geneticTestingAddPayload: any) {
   yield put(geneticTestingAddRequest(geneticTestingAddPayload.payload));
   try {
      const checkUser = false;
      const data = geneticTestingAddPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...API.geneticTestingAdd });
      if (response.status === 200) {
         const responseData = response.data;
         const geneticTestingAddData = {
            message: responseData?.message || '',
         };
         yield put(geneticTestingAddSuccess(geneticTestingAddData));
      } else {
         yield put(geneticTestingAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getMasterGeneticTestingSaga = function* (
   getMasterGeneticTestingPayload: PayloadAction<myProfileReducerTypes.IGeneticTestingReducer>
) {
   yield put(getMasterGeneticTestingRequest(getMasterGeneticTestingPayload.payload));
   try {
      const params = getMasterGeneticTestingPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         params,
         ...API.getMasterGeneticTesting,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const getMasterGeneticTestingList = {
            list: responseData?.data?.data || [],
            total_records: responseData?.data?.total || 0,
            message: response?.data?.message || '',
         };
         yield put(getMasterGeneticTestingSuccess(getMasterGeneticTestingList));
      } else {
         yield put(getMasterGeneticTestingFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getGeneticTestingListSaga = function* (
   getGeneticTestingListPayload: PayloadAction<myProfileReducerTypes.IGeneticTestingReducer>
) {
   yield put(getGeneticTestingListRequest(getGeneticTestingListPayload.payload));
   try {
      const params = getGeneticTestingListPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         params,
         ...API.getGeneticTestingList,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const getGeneticTestingList = {
            list: responseData?.data?.data || [],
            total_records: responseData?.data?.total || 0,
            message: response?.data?.message || '',
         };
         yield put(getGeneticTestingListSuccess(getGeneticTestingList));
      } else {
         yield put(getGeneticTestingListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getGeneticTestCancerList = function* (getGeneticTestCancerPayload: any) {
   yield put(getGeneticTestingMasterListRequest(getGeneticTestCancerPayload.payload));
   try {
      const params = getGeneticTestCancerPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         params,
         ...API.getGeneticTestCancerMasterList,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const geneticCancerList = {
            list: responseData?.data?.cancer_types?.data || [],
            total_records: responseData?.data?.cancer_types?.total || 0,
            message: response?.data?.message || '',
         };
         yield put(getGeneticTestingMasterListSuccess(geneticCancerList));
      } else {
         yield put(getGeneticTestingMasterListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const geneticTestingDeleteSaga = function* (
   geneticTestingDeletePayload: PayloadAction<myProfileSagaTypes.IDeleteGeneticTestingProps>
) {
   yield put(geneticTestingDeleteRequest(geneticTestingDeletePayload.payload));
   try {
      const checkUser = false;
      const apiPath = {
         apiPath: `${API.geneticTestingDelete.apiPath}${geneticTestingDeletePayload.payload.id}`,
         action: API.geneticTestingDelete.action,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, ...apiPath });
      if (response.status === 200) {
         const responseData = response.data;
         const geneticTestingDeleteData = {
            message: responseData?.message || '',
         };
         yield put(geneticTestingDeleteSuccess(geneticTestingDeleteData));
      } else {
         yield put(geneticTestingDeleteFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGeneticTesting = function* () {
   yield takeEvery(ADD_GENETIC_TESTING, geneticTestingAddSaga);
   yield takeEvery(GET_MASTER_GENETIC_TESTING, getMasterGeneticTestingSaga);
   yield takeEvery(GET_GENETIC_TESTING_LIST, getGeneticTestingListSaga);
   yield takeEvery(GENETIC_TEST_CANCER_LIST, getGeneticTestCancerList);
   yield takeEvery(DELETE_GENETIC_TESTING, geneticTestingDeleteSaga);
};
