import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { DAILY_CHECK_LIST } from 'reduxToolKit/actions';
import { dailyCheckListSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getAllDailyListReq, getAllDailyListSuccess, getAllDailyListFailed } = dailyCheckListSlice.actions;

const dailyCheckLogList = function* (getCheckPayload: any) {
   yield put(getAllDailyListReq());
   try {
      const params = getCheckPayload.payload;
      const response: AxiosResponse = yield apiCall({ params, ...API.dailyCheckLog });
      if (response.status === 200) {
         const responseData = response.data.data;
         const checkLogList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message,
         };
         yield put(getAllDailyListSuccess(checkLogList));
      } else {
         yield put(getAllDailyListFailed(response.data));
      }
   } catch (error) {}
};
export const takeDailyCheckLogListRequest = function* () {
   yield takeEvery(DAILY_CHECK_LIST, dailyCheckLogList);
};
