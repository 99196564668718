import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MEDICAL_ENQUIRES_GET_ONE_SAGA } from 'reduxToolKit/actions';
import { commonSlice, medicalEnqGetOneSlice } from 'reduxToolKit/reducers';
import { medicalEnqSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { medicalEnqGetOneView, medicalEnqGetOneViewSuccess, medicalEnqGetOneViewFailed } = medicalEnqGetOneSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const medicalEnqGetOne = function* (medicalEnqGetOne: medicalEnqSagaTypes.IMedicalEnqGetOnePayload) {
   yield put(medicalEnqGetOneView(medicalEnqGetOne.payload));
   try {
      const payload = medicalEnqGetOne.payload;
      const url = {
         apiPath: `${API.medicalEnqGetOne.apiPath}${payload.id}`,
         action: API.medicalEnqGetOne.action,
      };
      const response: AxiosResponse = yield apiCall({ ...url });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(medicalEnqGetOneViewSuccess(resObj));
      } else {
         yield put(medicalEnqGetOneViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeMedicalEnqGetOneRequest = function* () {
   yield takeEvery(MEDICAL_ENQUIRES_GET_ONE_SAGA, medicalEnqGetOne);
};
