import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { PROFILE_GET_SKILLS_SAGA, UPDATE_USER_SKILLS } from 'reduxToolKit/actions';
import { commonSlice, profileSkillsSlice } from 'reduxToolKit/reducers';
import { skillsSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getSkillsRequest,
   getSkillsSuccess,
   getSkillsFailed,
   updateSkillsRequest,
   updateSkillsSuccess,
   updateSkillsFailed,
} = profileSkillsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

//getSkills saga
const profileSkillsSaga = function* (skillsPayload: PayloadAction<skillsSagaTypes.IGetSkillsForm>) {
   yield put(getSkillsRequest(skillsPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = skillsPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getSkills });
      if (response.status === 200) {
         const responseData = response.data;
         const getSkillsList = {
            list: responseData?.data?.list || [],
            total_records: responseData?.data?.total_records || 0,
            message: response?.data?.message || '',
         };
         yield put(getSkillsSuccess(getSkillsList));
      } else {
         yield put(getSkillsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

// Update User Skills Saga
const updateProfileSkillsSaga = function* (skillsPayload: PayloadAction<skillsSagaTypes.IUpdateUserSkills>) {
   yield put(updateSkillsRequest(skillsPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = skillsPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.updateSkills });
      if (response.status === 200) {
         const updateSkills = {
            message: response?.data?.message || messages.success,
         };
         yield put(updateSkillsSuccess(updateSkills));
      } else {
         yield put(updateSkillsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGetSkillsRequest = function* () {
   yield takeEvery(PROFILE_GET_SKILLS_SAGA, profileSkillsSaga);
   yield takeEvery(UPDATE_USER_SKILLS, updateProfileSkillsSaga);
};
