import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { myProfileReducerTypes } from 'types';

const initialState: myProfileReducerTypes.IBlockUnblockUserReducer = {
   isLoading: false,
   blockUnblockUser: {
      isLoading: false,
      message: '',
      error: '',
      data: null,
   },
   blockedUsersList: {
      isLoading: false,
      message: '',
      error: '',
      list: [],
      pagination: {
         current_page: 1,
         last_page: 1,
         first_page_url: '',
         last_page_url: '',
         next_page_url: '',
         prev_page_url: '',
         total: 0,
      },
   },
};

// blockUnblockUser Slice
export const blockUnblockUserSlice = createSlice({
   name: 'blockUnblockUser',
   initialState: initialState,
   reducers: {
      getBlockUnblockUserRequest: (state, action) => {
         return {
            ...state,
            blockUnblockUser: {
               ...state.blockUnblockUser,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getBlockUnblockUserSuccess: (state, action: PayloadAction<myProfileReducerTypes.IGetBlockUnblockUserReducer>) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            blockUnblockUser: {
               ...state.blockUnblockUser,
               ...payload,
               message: payload?.message,
               error: '',
               isLoading: false,
            },
         };
      },
      getBlockUnblockUserFailed: (state, action: PayloadAction<myProfileReducerTypes.IGetBlockUnblockUserReducer>) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            blockUnblockUser: {
               ...state.blockUnblockUser,
               error: payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      getBlockUnblockUserReset: (state) => {
         return {
            ...initialState,
         };
      },
      // getBlockedUsersList
      getBlockedUsersListRequest: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            blockedUsersList: {
               ...state.blockedUsersList,
               ...payload,
               isLoading: true,
               list: payload.page && payload.page === 1 ? [] : state.blockedUsersList?.list || [],
            },
         };
      },
      getBlockedUsersListSuccess: (state, action: PayloadAction<any>) => {
         const { payload } = action;
         const stateList = state.blockedUsersList?.list || [];
         return {
            ...state,
            isLoading: false,
            blockedUsersList: {
               ...state.blockedUsersList,
               message: payload?.message,
               error: '',
               isLoading: false,
               pagination: payload.pagination,
               list: payload.pagination.current_page === 1 ? payload.list : [...stateList, ...payload.list],
            },
         };
      },
      getBlockedUsersListFailed: (state, action: PayloadAction<myProfileReducerTypes.IGetBlockUsersListReducer>) => {
         const { payload } = action;
         const stateList = state.blockedUsersList?.list || [];
         return {
            ...state,
            isLoading: false,
            blockedUsersList: {
               ...state.blockedUsersList,
               list: stateList,
               error: payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      getBlockedUsersListReset: (state) => {
         return {
            ...initialState,
         };
      },
   },
});
