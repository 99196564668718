import { createSlice } from '@reduxjs/toolkit';
import { myProfileReducerTypes } from 'types';

const initialState: myProfileReducerTypes.IOrgNameReducer = {
   addOrgName: {
      isLoading: false,
      error: '',
      message: '',
   },
   addOrgImage: {
      isLoading: false,
      error: '',
      message: '',
   },
};

export const orgNameSlice = createSlice({
   name: 'orgName',
   initialState: initialState,
   reducers: {
      // add org name
      addOrgNameRequest: (state, action) => {
         return {
            ...state,
            addOrgName: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      addOrgNameSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            addOrgName: {
               ...state.addOrgName,
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      addOrgNameFailed: (state, action: any) => {
         return {
            ...state,
            addOrgName: {
               ...state.addOrgName,
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      addOrgNameReset: (state) => {
         return {
            ...state,
            addOrgName: initialState.addOrgName,
         };
      },

      // add org image
      addOrgImageRequest: (state, action) => {
         return {
            ...state,
            addOrgImage: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      addOrgImageSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            addOrgImage: {
               ...state.addOrgImage,
               message: payload.message,
               error: '',
               isLoading: false,
            },
         };
      },
      addOrgImageFailed: (state, action: any) => {
         return {
            ...state,
            addOrgImage: {
               ...state.addOrgImage,
               error: action.payload.error,
               message: '',
               isLoading: false,
            },
         };
      },
      addOrgImageReset: (state) => {
         return {
            ...state,
            addOrgImage: initialState.addOrgImage,
         };
      },
   },
});
