import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   MEDICAL_ENQUIRES_DOCUMENT_LIST_SAGA,
   MEDICAL_ENQUIRES_DOCUMENT_UPDATE_SAGA,
   MEDICAL_ENQUIRES_DOCUMENT_VIEW_SAGA,
} from 'reduxToolKit/actions';
import {
   commonSlice,
   medicalEnqDocumentsListSlice,
   medicalEnqUpdateDocumentSlice,
   medicalEnqViewDocumentSlice,
} from 'reduxToolKit/reducers';
import { medicalEnqSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   medicalEnqDocumentsListViewRequest,
   medicalEnqDocumentsListViewSuccess,
   medicalEnqDocumentsListViewFailed,
} = medicalEnqDocumentsListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const medicalEnquiresDocumentsListSaga = function* (
   medicalEnqDocListPayload: medicalEnqSagaTypes.IMedicalEnqDocumentListPayload
) {
   yield put(medicalEnqDocumentsListViewRequest(medicalEnqDocListPayload.payload));
   try {
      const params = medicalEnqDocListPayload.payload;
      const url = API.medicalEnqDocumentsList;
      const response: AxiosResponse = yield apiCall({ params, ...url });
      if (response.status === 200) {
         const responseData = response.data.data;
         const appointmentMessagesList = {
            list: responseData.data,
            pagination: {
               current_page: responseData.current_page,
               last_page: responseData.last_page,
               first_page_url: responseData.first_page_url,
               last_page_url: responseData.last_page_url,
               next_page_url: responseData.next_page_url,
               prev_page_url: responseData.prev_page_url,
               total: responseData.total,
            },
            message: response.data?.message,
         };
         yield put(medicalEnqDocumentsListViewSuccess(appointmentMessagesList));
      } else {
         yield put(medicalEnqDocumentsListViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   medicalEnqViewDocumentView,
   medicalEnqViewDocumentViewSuccess,
   medicalEnqViewDocumentViewFailed,
} = medicalEnqViewDocumentSlice.actions;

const medicalEnqViewDocumentSaga = function* (payloadId: medicalEnqSagaTypes.IMedicalEnqDocumentViewPayload) {
   yield put(medicalEnqViewDocumentView(payloadId.payload));
   try {
      const apiPath = {
         apiPath: `${API.medicalEnqGetOneDocument.apiPath}${payloadId.payload}`,
         action: API.medicalEnqGetOneDocument.action,
      };
      const response: AxiosResponse = yield apiCall({ ...apiPath });
      if (response.status === 200) {
         yield put(medicalEnqViewDocumentViewSuccess(response.data.data));
      } else {
         yield put(medicalEnqViewDocumentViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const {
   medicalEnqUpdateDocumentView,
   medicalEnqUpdateDocumentSuccess,
   medicalEnqUpdateDocumentFailed,
} = medicalEnqUpdateDocumentSlice.actions;

const medicalEnqUpdateDocumentSaga = function* (
   medicalEnqUpdateePayload: medicalEnqSagaTypes.IMedicalEnqDocumentUpdatePayload
) {
   yield put(medicalEnqUpdateDocumentView(medicalEnqUpdateePayload));
   try {
      const data = {
         claim_form: medicalEnqUpdateePayload.payload.claim_form,
      };
      const apiPath = {
         apiPath: `${API.medicalEnqUpdateDocument.apiPath}${medicalEnqUpdateePayload.payload.id}`,
         action: API.medicalEnqUpdateDocument.action,
      };
      const response: AxiosResponse = yield apiCall({ ...apiPath, data });
      if (response.status === 200) {
         yield put(medicalEnqUpdateDocumentSuccess(response.data));
      } else {
         yield put(medicalEnqUpdateDocumentFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeMedicalEnqDocumentRequest = function* () {
   yield takeEvery(MEDICAL_ENQUIRES_DOCUMENT_LIST_SAGA, medicalEnquiresDocumentsListSaga);
   yield takeEvery(MEDICAL_ENQUIRES_DOCUMENT_VIEW_SAGA, medicalEnqViewDocumentSaga);
   yield takeEvery(MEDICAL_ENQUIRES_DOCUMENT_UPDATE_SAGA, medicalEnqUpdateDocumentSaga);
};
