import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MODAL_UPDATE_PATIENT_INSURANCE_SAGA } from 'reduxToolKit/actions';
import { commonSlice, modalUpdatePatientInsuranceSlice } from 'reduxToolKit/reducers';
import { modalPatientInsuranceSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   modalUpdatePatientInsuranceRequest,
   modalUpdatePatientInsuranceSuccess,
   modalUpdatePatientInsuranceFailed,
} = modalUpdatePatientInsuranceSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const modalUpdatePatientInsuranceSaga = function* (
   patientInsurancePayload: PayloadAction<modalPatientInsuranceSagaTypes.IModalPatientInsuranceUpdateSagaPayload>
) {
   yield put(modalUpdatePatientInsuranceRequest(patientInsurancePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = patientInsurancePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.updatePatientInsurance });
      if (response.status === 200) {
         const responseData = response.data;
         const InsuranceUpdateList = {
            message: responseData?.message || '',
         };
         yield put(modalUpdatePatientInsuranceSuccess(InsuranceUpdateList));
      } else {
         yield put(modalUpdatePatientInsuranceFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeUpdatePatientInsurance = function* () {
   yield takeEvery(MODAL_UPDATE_PATIENT_INSURANCE_SAGA, modalUpdatePatientInsuranceSaga);
};
