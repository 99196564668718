import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINIC_DELETE_AUXILIARY_SAGA } from 'reduxToolKit/actions';
import { commonSlice, clinicDeleteAuxiliarySlice } from 'reduxToolKit/reducers';
import { clinicAdminSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   clinicDeleteAuxiliaryRequest,
   clinicDeleteAuxiliarySuccess,
   clinicDeleteAuxiliaryFailed,
} = clinicDeleteAuxiliarySlice.actions;

const { exceptionHandlerRequest } = commonSlice.actions;

const clinicDeleteAuxiliary = function* (
   clinicDeleteAuxiliary: PayloadAction<clinicAdminSagaTypes.IClinicDeleteAuxiliarySaga>
) {
   yield put(clinicDeleteAuxiliaryRequest(clinicDeleteAuxiliary.payload));
   try {
      const data = clinicDeleteAuxiliary.payload;
      const goUrl = true;
      const checkUser = true;
      const response: AxiosResponse = yield apiCall({ data, goUrl, checkUser, ...API.clinicDeleteAuxiliary });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(clinicDeleteAuxiliarySuccess(resObj));
      } else {
         yield put(clinicDeleteAuxiliaryFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeClinicDeleteAuxiliaryRequest = function* () {
   yield takeEvery(CLINIC_DELETE_AUXILIARY_SAGA, clinicDeleteAuxiliary);
};
