import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_STATE_AND_TRAVEL_SAGA } from 'reduxToolKit/actions';
import { commonSlice, settingGetStateAndTravelListSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   settingGetStateAndTravelRequest,
   settingGetStateAndTravelSuccess,
   settingGetStateAndTravelFailed,
} = settingGetStateAndTravelListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const settingGetStateAndTravelListSaga = function* () {
   yield put(settingGetStateAndTravelRequest());
   try {
      const url = API.settingGetStateAndTravelList;
      const checkUser = false;
      const response: AxiosResponse = yield apiCall({ checkUser, ...url });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(settingGetStateAndTravelSuccess(resObj));
      } else {
         yield put(settingGetStateAndTravelFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeSettingGetStateAndTravelRequest = function* () {
   yield takeEvery(GET_STATE_AND_TRAVEL_SAGA, settingGetStateAndTravelListSaga);
};
