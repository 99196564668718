import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { peopleNearMeReducerTypes } from 'types';

const initialState: peopleNearMeReducerTypes.ICancerTypeListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
   drawerClose: false,
   is_load_more: false,
};

export const reportableSymptomSlice = createSlice({
   name: 'reportableSymptom',
   initialState: initialState,
   reducers: {
      reportableSymptomLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      reportableSymptomRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      reportableSymptomSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            total_records: payload.total_records,
            message: payload.message,
            is_load_more: payload.is_load_more,
            isLoading: false,
         };
      },
      reportableSymptomFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      reportableSymptomReset: () => {
         return {
            ...initialState,
         };
      },

      setDrawerClose: (state, action: PayloadAction<boolean>) => {
         return {
            ...state,
            drawerClose: action.payload,
         };
      },
   },
});
