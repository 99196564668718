import { useEffect, useRef } from 'react';

import { simpleEncode } from 'utils/helpers/encryptHelpers';

import { isAO } from './array.helpers';

export const isCompare = (value1: any, value2: any) => {
   return value1 === value2;
};

export const gotoDrugLookUp = (drugId: any) => {
   window.open(`/drug-lookup/detail/${simpleEncode(String(drugId))}`, '_blank');
};

export const listParseAndStringify = <T>(value: T): T => {
   try {
      return JSON.parse(JSON.stringify(value));
   } catch (e) {
      return value;
   }
};

export const formatBytes = (bytes: any, decimals = 2) => {
   if (bytes === 0) return '0 Bytes';

   const k = 1024;
   const dm = decimals < 0 ? 0 : decimals;
   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

   const i = Math.floor(Math.log(bytes) / Math.log(k));

   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export function useHorizontalScroll() {
   const elRef = useRef();
   useEffect(() => {
      const el: any = elRef.current;
      if (el) {
         const onWheel = (e: any) => {
            if (e.deltaY === 0) return;
            e.preventDefault();
            el.scrollTo({
               left: el.scrollLeft + e.deltaY,
               behavior: 'smooth',
            });
         };
         el.addEventListener('wheel', onWheel);
         return () => el.removeEventListener('wheel', onWheel);
      }
   }, []);
   return elRef;
}

export const simpleStringify = (value: string | boolean | any) => {
   try {
      if (isAO(value)) {
         return JSON.stringify(value);
      }
      return value;
   } catch (e) {
      return value;
   }
};
