import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   PROFILE_DELETE_PHYSICIAN_SAGA,
   PROFILE_GET_PHYSICIAN_SAGA,
   PROFILE_UPDATE_PHYSICIAN_SAGA,
} from 'reduxToolKit/actions';
import { commonSlice, deletePhysicianSlice, getPhysiciansSlice, updatePhysicianSlice } from 'reduxToolKit/reducers';
import { physicianDetailsTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getPhysiciansRequest, getPhysiciansSuccess, getPhysiciansFailed } = getPhysiciansSlice.actions;
const { updatePhysicianRequest, updatePhysicianSuccess, updatePhysicianFailed } = updatePhysicianSlice.actions;
const { deletePhysicianRequest, deletePhysicianSuccess, deletePhysicianFailed } = deletePhysicianSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

//MyProfile getPhysician saga
const getPhysiciansSaga = function* (physiciansPayload: PayloadAction<physicianDetailsTypes.IGetPhysicianForm>) {
   yield put(getPhysiciansRequest(physiciansPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = physiciansPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.userGetAllUsers });
      if (response.status === 200) {
         const responseData = response.data;
         const physiciansList = {
            list: responseData?.data?.list || [],
            total_records: responseData?.data?.total_records || 0,
            message: response?.data?.message || '',
         };
         yield put(getPhysiciansSuccess(physiciansList));
      } else {
         yield put(getPhysiciansFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

//MyProfile updatePhysician saga
const updatePhysicianSaga = function* (
   updatePhysicianPayload: PayloadAction<physicianDetailsTypes.IUpdatePhysicianForm>
) {
   yield put(updatePhysicianRequest(updatePhysicianPayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = updatePhysicianPayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.updatePhysician });
      if (response.status === 200) {
         const responseData = response.data;
         const updatePhysicianList = {
            message: responseData?.message || '',
         };
         yield put(updatePhysicianSuccess(updatePhysicianList));
      } else {
         yield put(updatePhysicianFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

//MyProfile deletePhysician saga
const deletePhysicianSaga = function* (payloadState: PayloadAction<physicianDetailsTypes.IDeletePhysicianForm>) {
   yield put(deletePhysicianRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.deletePhysician });
      if (response.status === 200) {
         const resObj = {
            message: response.data?.message || '',
         };
         yield put(deletePhysicianSuccess(resObj));
      } else {
         yield put(deletePhysicianFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePhysicianDetails = function* () {
   yield takeEvery(PROFILE_DELETE_PHYSICIAN_SAGA, deletePhysicianSaga);
   yield takeEvery(PROFILE_GET_PHYSICIAN_SAGA, getPhysiciansSaga);
   yield takeEvery(PROFILE_UPDATE_PHYSICIAN_SAGA, updatePhysicianSaga);
};
