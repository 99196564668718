import { createSlice } from '@reduxjs/toolkit';
import { oncDriveReducerTypes, oncDriveSagaTypes } from 'types';

const initialState: oncDriveReducerTypes.IOncDriveAddFolderReducer = {
   isLoading: false,
   error: '',
   message: '',
   errors: null,
   data: null,
};
const oncDriveAddFolderSlice = createSlice({
   name: 'oncDriveAddFolderSlice',
   initialState: initialState,
   reducers: {
      oncDriveAddFolderRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addOncDriveSuccess: (state, action: oncDriveSagaTypes.IAddToDriveSuccess) => {
         return {
            ...state,
            isLoading: false,
            ...action.payload,
         };
      },
      addOncDriveFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
            errors: action.payload.errors,
         };
      },
      oncDriveAddFolderReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
export { oncDriveAddFolderSlice };
