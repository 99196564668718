import { createAction } from '@reduxjs/toolkit';
import { medicationSavingSagaTypes } from 'types';

export const MEDICATION_SAVING_LIST = 'MEDICATION_SAVING_LIST';
export const getMedicationSavingList = createAction<medicationSavingSagaTypes.IMedicationSavingParams>(
   MEDICATION_SAVING_LIST
);

export const MEDICATION_SAVING_DETAIL = 'MEDICATION_SAVING_DETAIL';
export const getMedicationSavingDetail = createAction<medicationSavingSagaTypes.IMedicationSavingDetailParams>(
   MEDICATION_SAVING_DETAIL
);
