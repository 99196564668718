import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';
import { DELETE_POST_COMMENT, eventTrackAction } from 'reduxToolKit/actions';
import { commonSlice, deletePostCommentSlice } from 'reduxToolKit/reducers';
import { eventTrackTypes, feedSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { deletePostCommentRequest, deletePostCommentSuccess, deletePostCommentFailed } = deletePostCommentSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const deletePostComment = function* (deletePostComment: PayloadAction<feedSagaTypes.IDeletePostCommentActionProps>) {
   yield put(deletePostCommentRequest(deletePostComment.payload));
   try {
      const data = deletePostComment.payload;
      const checkUser = true;
      const goUrl = true;

      let response = null;
      if (deletePostComment.payload.isAskADoc) {
         response = yield apiCall({ goUrl, checkUser, data, ...API.askADocFeedCommentDelete });
      } else {
         response = yield apiCall({ checkUser, data, ...API.deletePostComment });
      }
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: null,
            requestData: data,
         };
         yield put(deletePostCommentSuccess(resObj));
         if (deletePostComment.payload.event) {
            const eventData: eventTrackTypes.IEventTrack = {
               event: {
                  name: deletePostComment.payload.event.name,
               },
            };
            yield put(eventTrackAction(eventData));
         }
      } else {
         yield put(deletePostCommentFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeDeletePostCommentRequest = function* () {
   yield takeEvery(DELETE_POST_COMMENT, deletePostComment);
};
