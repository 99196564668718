import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { VIEW_APPOINTMENT_INFO } from 'reduxToolKit/actions';
import { commonSlice, viewAppointmentSlice } from 'reduxToolKit/reducers';
import { viewAppointmentSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getAppointmentView, getAppointmentViewSuccess, getAppointmentViewFailed } = viewAppointmentSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const viewAppointment = function* (payloadId: viewAppointmentSagaTypes.IGetAppointmentSuccess) {
   yield put(getAppointmentView(payloadId.payload));
   try {
      const apiPath = {
         apiPath: `${API.viewAppointment.apiPath}${'/'}${payloadId.payload}`,
         action: API.viewAppointment.action,
      };
      const response: AxiosResponse = yield apiCall({ ...apiPath });
      if (response.status === 200) {
         yield put(getAppointmentViewSuccess(response.data.data));
      } else {
         yield put(getAppointmentViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeViewAppointmentRequest = function* () {
   yield takeEvery(VIEW_APPOINTMENT_INFO, viewAppointment);
};
