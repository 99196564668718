import { createAction } from '@reduxjs/toolkit';
import {
   aboutMeSagaTypes,
   addCaregiverSagaTypes,
   addFamilyMemberSagaTypes,
   associatedPhysicianSagaTypes,
   modalCertificateSagaTypes,
   modalClinicTrialSagaTypes,
   modalConnectionInviteSagaTypes,
   modalEducationTrainingSagaTypes,
   modalExperienceSagaTypes,
   modalMedicationSagaTypes,
   modalMyClinicsSagaTypes,
   modalPatientInsuranceSagaTypes,
   modalUpdateCoMorbiditiesSagaTypes,
   modalUpdateFamilyHistoriesSagaTypes,
   rateWriteSagaTypes,
   reportPostReducerTypes,
   symptomMonitoringCommentReducerTypes,
   userStateSagaTypes,
} from 'types';

//Modal - getAssociatedExceptTreatingPhysicians Action
export const MODAL_GET_ASSOCIATED_PHYSICIAN_SAGA = 'MODAL_GET_ASSOCIATED_PHYSICIAN_SAGA';
export const modalGetAssociatedPhysicianAction = createAction<associatedPhysicianSagaTypes.IModalAssociatedPhysicianSagaPaylod>(
   MODAL_GET_ASSOCIATED_PHYSICIAN_SAGA
);

//Modal - addAssociatedPhysicians Action
export const MODAL_ADD_ASSOCIATED_PHYSICIAN_SAGA = 'MODAL_ADD_ASSOCIATED_PHYSICIAN_SAGA';
export const modalAddAssociatedPhysicianAction = createAction<associatedPhysicianSagaTypes.IModalAddAssociatedPhysicianSagaPayload>(
   MODAL_ADD_ASSOCIATED_PHYSICIAN_SAGA
);

//Modal - getCancers Actions
export const MODAL_GET_CANCERS_SAGA = 'MODAL_GET_CANCERS_SAGA';
export const modalGetCancersAction = createAction<aboutMeSagaTypes.IModalCancersSagaPayload>(MODAL_GET_CANCERS_SAGA);

//AboutMe - patientDescriptionUpdate, physicianDescriptionUpdate, nurseDescriptionUpdate Actions
export const MODAL_DESCRIPTION_UPDATE_SAGA = 'MODAL_DESCRIPTION_UPDATE_SAGA';
export const modalDescriptionUpdateAction = createAction<aboutMeSagaTypes.IModalDescriptionUpdateSagaPayload>(
   MODAL_DESCRIPTION_UPDATE_SAGA
);

//Modal - AddFamilyMember Actions
export const MODAL_ADD_FAMILY_MEMBER_SAGA = 'MODAL_ADD_FAMILY_MEMBER_SAGA';
export const modalAddFamilyMemberAction = createAction<addFamilyMemberSagaTypes.IModalAddFamilyMemberSagaPayload>(
   MODAL_ADD_FAMILY_MEMBER_SAGA
);
//Modal - updateCertificate Actions
export const MODAL_UPDATE_CERTIFICATE_SAGA = 'MODAL_UPDATE_CERTIFICATE_SAGA';
export const modalUpdateCertificateAction = createAction<modalCertificateSagaTypes.IModalCertificateSagaPayload>(
   MODAL_UPDATE_CERTIFICATE_SAGA
);
//Modal - AddCaregiver Actions
export const MODAL_ADD_CAREGIVER_SAGA = 'MODAL_ADD_CAREGIVER_SAGA';
export const modalAddCaregiverAction = createAction<addCaregiverSagaTypes.IModalAddCaregiverSagaPayload>(
   MODAL_ADD_CAREGIVER_SAGA
);
//Modal - Symptom Monitoring Comment DialogActions
export const GET_SYMPTOM_MONITORING_COMMENT = 'GET_SYMPTOM_MONITORING_COMMENT';
export const getSymptomMonitoringComments = createAction<symptomMonitoringCommentReducerTypes.ISymptomMonitoringComment>(
   GET_SYMPTOM_MONITORING_COMMENT
);

export const SYMPTOM_MONITORING_COMMENTS_CLOSE = 'SYMPTOM_MONITORING_COMMENTs_CLOSE';
export const symptomMonitoringCommentsClose = createAction<symptomMonitoringCommentReducerTypes.ISymptomMonitoringCommentsClose>(
   SYMPTOM_MONITORING_COMMENTS_CLOSE
);

export const ADD_EDIT_SYMPTOM_MONITORING_COMMENTS = 'ADD_EDIT_SYMPTOM_MONITORING_COMMENTS';
export const addEditSymptomMonitoringComments = createAction<symptomMonitoringCommentReducerTypes.IAddEditSymptomMonitoringComments>(
   ADD_EDIT_SYMPTOM_MONITORING_COMMENTS
);
//Modal - UpdateClinicTrial Actions
export const MODAL_UPDATE_CLINIC_TRIAL_SAGA = 'MODAL_UPDATE_CLINIC_TRIAL_SAGA';
export const modalUpdateClinicTrialAction = createAction<modalClinicTrialSagaTypes.IModalClinicTrialSagaPayload>(
   MODAL_UPDATE_CLINIC_TRIAL_SAGA
);

//Modal - UpdateEducationTraining Actions
export const MODAL_UPDATE_EDUCATION_TRAINING_SAGA = 'MODAL_UPDATE_EDUCATION_TRAINING_SAGA';
export const modalUpdateEducationTrainingAction = createAction<modalEducationTrainingSagaTypes.IModalEducationTrainingSagaPayload>(
   MODAL_UPDATE_EDUCATION_TRAINING_SAGA
);
//Modal - UpdateCoMorbidities Actions
export const MODAL_UPDATE_COMORBIDITIES_SAGA = 'MODAL_UPDATE_COMORBIDITIES_SAGA';
export const modalUpdateCoMorbiditiesAction = createAction<modalUpdateCoMorbiditiesSagaTypes.IModalCoMorbiditiesUpdateSagaPayload>(
   MODAL_UPDATE_COMORBIDITIES_SAGA
);

// Modal - Get stateLists Actions
export const GET_USER_STATE_LISTS = 'GET_USER_STATE_LISTS';
export const getStateList = createAction<userStateSagaTypes.IGetStateLists>(GET_USER_STATE_LISTS);

// Modal - Update User State actions
export const UPDATE_USER_STATE = 'UPDATE_USER_STATE';
export const updateUserState = createAction<userStateSagaTypes.IUpdateUserState>(UPDATE_USER_STATE);

// Modal - Post Add Review Action
export const POST_ADD_REVIEW = 'POST_ADD_REVIEW';
export const addReview = createAction<rateWriteSagaTypes.IAddReview>(POST_ADD_REVIEW);
//Modal - updateCertificate Actions
export const MODAL_UPDATE_FAMILYHISTORIES_SAGA = 'MODAL_UPDATE_FAMILYHISTORIES_SAGA';
export const modalUpdateFamilyHistoriesAction = createAction<modalUpdateFamilyHistoriesSagaTypes.IModalUpdateFamilyHistoriesSagaPayload>(
   MODAL_UPDATE_FAMILYHISTORIES_SAGA
);
//Modal - updateExperience Actions
export const MODAL_UPDATE_EXPERIENCE_SAGA = 'MODAL_UPDATE_EXPERIENCE_SAGA';
export const modalUpdateExperienceAction = createAction<modalExperienceSagaTypes.IModalExperienceSagaPayload>(
   MODAL_UPDATE_EXPERIENCE_SAGA
);

//Modal - inviteViaMail Actions
export const MODAL_INVITE_VIA_MAIL_SAGA = 'MODAL_INVITE_VIA_MAIL_SAGA';
export const modalinviteViaMailAction = createAction<modalConnectionInviteSagaTypes.IModalInviteViaMailSagaPayload>(
   MODAL_INVITE_VIA_MAIL_SAGA
);

//Modal - getReportReason Actions
export const GET_REPORT_REASON = 'GET_REPORT_REASON';
export const getReportReason = createAction<reportPostReducerTypes.IGetReportPost>(GET_REPORT_REASON);

//Modal - addReportPost Actions
export const ADD_REPORT_POST = 'ADD_REPORT_POST';
export const addReportPost = createAction<reportPostReducerTypes.IAddReportPost>(ADD_REPORT_POST);

//Modal - getReportUserReason Actions
export const GET_REPORT_USER_REASON = 'GET_REPORT_USER_REASON';
export const getReportUserReason = createAction<modalMyClinicsSagaTypes.IModelReportUserReason>(GET_REPORT_USER_REASON);

//Modal - addReportUserPost Actions
export const ADD_REPORT_USER_POST = 'ADD_REPORT_USER_POST';
export const addReportUserPost = createAction<reportPostReducerTypes.IAddReportUserPost>(ADD_REPORT_USER_POST);

//Modal - addReportPostComment Actions
export const ADD_REPORT_POST_COMMENT = 'ADD_REPORT_POST_COMMENT';
export const addReportPostComment = createAction<reportPostReducerTypes.IAddReportPostComment>(ADD_REPORT_POST_COMMENT);

//Modal - addReportPostCommentReply Actions
export const ADD_REPORT_POST_COMMENT_REPLY = 'ADD_REPORT_POST_COMMENT_REPLY';
export const addReportPostCommentReply = createAction<reportPostReducerTypes.IAddReportPostCommentReply>(
   ADD_REPORT_POST_COMMENT_REPLY
);

//Modal - GetClinics Actions
export const MODAL_GET_CLINICS_SAGA = 'MODAL_GET_CLINICS_SAGA';
export const modalGetClinicsAction = createAction<modalMyClinicsSagaTypes.IModalGetMyClinicsSagaPayload>(
   MODAL_GET_CLINICS_SAGA
);

//Modal - joinClinicPAtient and joinClinicPhysician Actions
export const MODAL_JOIN_CLINIC_SAGA = 'MODAL_JOIN_CLINIC_SAGA';
export const modalJoinClinicAction = createAction<modalMyClinicsSagaTypes.IModalJoinClinicSagaPayload>(
   MODAL_JOIN_CLINIC_SAGA
);

//Modal - updatePatientInsurance Actions
export const MODAL_UPDATE_PATIENT_INSURANCE_SAGA = 'MODAL_UPDATE_PATIENT_INSURANCE_SAGA';
export const modalUpdatePatientInsuranceAction = createAction<modalPatientInsuranceSagaTypes.IModalPatientInsuranceUpdateSagaPayload>(
   MODAL_UPDATE_PATIENT_INSURANCE_SAGA
);

//Modal - UpdateMedication Actions
export const MODAL_UPDATE_MEDICATION_SAGA = 'MODAL_UPDATE_MEDICATION_SAGA';
export const modalUpdateMedicationAction = createAction<modalMedicationSagaTypes.IModalUpdateMedicationSagaPayload>(
   MODAL_UPDATE_MEDICATION_SAGA
);

//Nurse - getAssociatedTreatingPhysicians Action
export const NURSE_GET_ASSOCIATED_PHYSICIAN_SAGA = 'NURSE_GET_ASSOCIATED_PHYSICIAN_SAGA';
export const nurseGetAssociatedPhysicianAction = createAction<associatedPhysicianSagaTypes.INurseAssociatedPhysicianSagaPayload>(
   NURSE_GET_ASSOCIATED_PHYSICIAN_SAGA
);
