import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clinicalTrialsReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';

const initialState: clinicalTrialsReducerTypes.IClinicalTrialsReducer = {
   clinicalTrialsList: {
      isLoading: false,
      progressLoadMore: false,
      error: '',
      errors: null,
      message: '',
      list: [],
      limit: 0,
      page: 1,
      pagination: {
         current_page: null,
         last_page: null,
         first_page_url: '',
         last_page_url: '',
         next_page_url: '',
         prev_page_url: '',
         total: 0,
      },
   },
   clinicalTrialsListCount: {
      isLoading: false,
      error: '',
      message: '',
      data: null,
      limit: 0,
      page: 1,
   },
   clinicalTrialsInterestedList: {
      isLoading: false,
      progressLoadMore: false,
      error: '',
      errors: null,
      message: '',
      list: [],
      limit: 0,
      page: 1,
      pagination: {
         current_page: null,
         last_page: null,
         first_page_url: '',
         last_page_url: '',
         next_page_url: '',
         prev_page_url: '',
         total: 0,
      },
   },
   clinicalTrialsInterest: {
      isLoading: false,
      error: '',
      errorMessage: '',
      message: '',
      requestData: null,
      data: null,
   },
};

export const clinicalTrialsSlice = createSlice({
   name: 'clinicalTrials',
   initialState: initialState,
   reducers: {
      clinicalTrialsListLoading: (state) => {
         return {
            ...state,
            clinicalTrialsList: {
               ...state.clinicalTrialsList,
               isLoading: true,
               progressLoadMore: true,
            },
         };
      },
      clinicalTrialsListRequest: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            clinicalTrialsList: {
               ...state.clinicalTrialsList,
               ...action.payload,
               isLoading: true,
               progressLoadMore: true,
               list: action.payload.page && action.payload.page === 1 ? [] : state.clinicalTrialsList.list,
            },
         };
      },
      clinicalTrialsListSuccess: (state, action: PayloadAction<any>) => {
         const { payload } = action;
         const list = listParseAndStringify(payload.list);
         const convertList: any[] = list;
         /* list.forEach((ele: any) => {
            const element = listParseAndStringify(ele);
            if (element) {
               element.condition_load_more = false;
               element.intervention_load_more = false;
               convertList.push(element);
            }
         }); */
         return {
            ...state,
            clinicalTrialsList: {
               ...state.clinicalTrialsList,
               pagination: { ...payload.pagination },
               list:
                  payload.pagination.current_page === 1
                     ? convertList
                     : [...state.clinicalTrialsList.list, ...convertList],
               total_records: payload.total_records,
               isLoading: false,
               progressLoadMore: false,
               error: '',
               errors: null,
            },
         };
      },
      clinicalTrialsListUpdate: (state, action: PayloadAction<any>) => {
         const { payload } = action;
         return {
            ...state,
            clinicalTrialsList: {
               ...state.clinicalTrialsList,
               list: payload,
            },
         };
      },
      clinicalTrialsListFailed: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            clinicalTrialsList: {
               ...state.clinicalTrialsList,
               isLoading: false,
               progressLoadMore: false,
               message: '',
               error: action.payload.error,
               errors: action.payload.errors,
            },
         };
      },

      clinicalTrialsListReset: (state) => {
         return {
            ...state,
            clinicalTrialsList: {
               ...initialState.clinicalTrialsList,
            },
         };
      },
      //
      clinicalTrialsListCountLoading: (state) => {
         return {
            ...state,
            clinicalTrialsListCount: {
               ...state.clinicalTrialsListCount,
               isLoading: true,
            },
         };
      },
      clinicalTrialsListCountRequest: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            clinicalTrialsListCount: {
               ...state.clinicalTrialsListCount,
               isLoading: true,
            },
         };
      },
      clinicalTrialsListCountSuccess: (state, action: PayloadAction<any>) => {
         const { payload } = action;
         return {
            ...state,
            clinicalTrialsListCount: {
               ...state.clinicalTrialsListCount,
               data: payload.data,
               isLoading: false,
               error: '',
            },
         };
      },
      clinicalTrialsListCountFailed: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            clinicalTrialsListCount: {
               ...state.clinicalTrialsListCount,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },

      clinicalTrialsListCountReset: (state) => {
         return {
            ...state,
            clinicalTrialsListCount: {
               ...initialState.clinicalTrialsListCount,
            },
         };
      },
      // clinical trials interested list
      clinicalTrialsInterestedListLoading: (state) => {
         return {
            ...state,
            clinicalTrialsInterestedList: {
               ...state.clinicalTrialsInterestedList,
               isLoading: true,
               progressLoadMore: true,
            },
         };
      },
      clinicalTrialsInterestedListRequest: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            clinicalTrialsInterestedList: {
               ...state.clinicalTrialsInterestedList,
               ...action.payload,
               isLoading: true,
               progressLoadMore: true,
               list: action.payload.page && action.payload.page === 1 ? [] : state.clinicalTrialsInterestedList.list,
            },
         };
      },
      clinicalTrialsInterestedListSuccess: (state, action: PayloadAction<any>) => {
         const { payload } = action;
         const list = listParseAndStringify(payload.list);
         const convertList: any[] = list;
         /* list.forEach((ele: any) => {
            const element = listParseAndStringify(ele);
            if (element) {
               element.condition_load_more = false;
               element.intervention_load_more = false;
               convertList.push(element);
            }
         }); */
         return {
            ...state,
            clinicalTrialsInterestedList: {
               ...state.clinicalTrialsInterestedList,
               pagination: { ...payload.pagination },
               list:
                  payload.pagination.current_page === 1
                     ? convertList
                     : [...state.clinicalTrialsInterestedList.list, ...convertList],
               total_records: payload.total_records,
               isLoading: false,
               progressLoadMore: false,
               error: '',
               errors: null,
            },
         };
      },
      clinicalTrialsInterestedListUpdate: (state, action: PayloadAction<any>) => {
         const { payload } = action;
         return {
            ...state,
            clinicalTrialsInterestedList: {
               ...state.clinicalTrialsInterestedList,
               list: payload,
            },
         };
      },
      clinicalTrialsInterestedListFailed: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            clinicalTrialsInterestedList: {
               ...state.clinicalTrialsInterestedList,
               isLoading: false,
               progressLoadMore: false,
               message: '',
               error: action.payload.error,
               errors: action.payload.errors,
            },
         };
      },

      clinicalTrialsInterestedListReset: (state) => {
         return {
            ...state,
            clinicalTrialsInterestedList: {
               ...initialState.clinicalTrialsInterestedList,
            },
         };
      },
      // clinical trials interest
      clinicalTrialsInterestRequestLoading: (state) => {
         return {
            ...state,
            clinicalTrialsInterest: {
               ...state.clinicalTrialsInterest,
               isLoading: true,
               progressLoadMore: true,
            },
         };
      },
      clinicalTrialsInterestRequest: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            clinicalTrialsInterest: {
               ...state.clinicalTrialsInterest,
               requestData: action.payload,
               isLoading: true,
               progressLoadMore: true,
            },
         };
      },
      clinicalTrialsInterestSuccess: (state, action: PayloadAction<any>) => {
         const { payload } = action;
         return {
            ...state,
            clinicalTrialsInterest: {
               ...state.clinicalTrialsInterest,
               isLoading: false,
               progressLoadMore: false,
               data: payload.data,
               message: payload.message || messages.success,
            },
         };
      },
      clinicalTrialsInterestFailed: (state, action: PayloadAction<any>) => {
         return {
            ...state,
            clinicalTrialsInterest: {
               ...state.clinicalTrialsInterest,
               isLoading: false,
               progressLoadMore: false,
               message: '',
               error: action.payload.error || messages.failed,
            },
         };
      },

      clinicalTrialsInterestReset: (state) => {
         return {
            ...state,
            clinicalTrialsInterest: {
               ...initialState.clinicalTrialsInterest,
            },
         };
      },
   },
});
