import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addCaregiverReducerTypes } from 'types';

const initialState: addCaregiverReducerTypes.IModalAddCaregiverReducer = {
   error: '',
   message: '',
   isLoading: false,
};

export const modalAddCaregiverSlice = createSlice({
   name: 'modalAddCaregiver',
   initialState: initialState,
   reducers: {
      addCaregiverRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      addCaregiverSuccess: (
         state,
         action: PayloadAction<addCaregiverReducerTypes.IModalAddCaregiverSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      addCaregiverFailed: (state, action: PayloadAction<addCaregiverReducerTypes.IModalAddCaregiverFailedPayload>) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      addCaregiverReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
