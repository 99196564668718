import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { MODAL_GET_CANCERS_SAGA } from 'reduxToolKit/actions';
import { commonSlice, modalCancersSlice } from 'reduxToolKit/reducers';
import { aboutMeSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getCancersRequest, getCancersSuccess, getCancersFailed } = modalCancersSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

//Modal - getCancers Saga
const modalCancersSaga = function* (cancersTypePayload: PayloadAction<aboutMeSagaTypes.IModalCancersSagaPayload>) {
   yield put(getCancersRequest(cancersTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancersTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getCancers });
      if (response.status === 200) {
         const responseData = response.data;
         const cancersList = {
            list: responseData?.data?.list || [],
            total_records: responseData?.data?.total_records || 0,
            message: response?.data?.message || '',
         };
         yield put(getCancersSuccess(cancersList));
      } else {
         yield put(getCancersFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGetCancersRequest = function* () {
   yield takeEvery(MODAL_GET_CANCERS_SAGA, modalCancersSaga);
};
