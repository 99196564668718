import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { checkLogPageTypes, checkLogReducerTypes } from 'types';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';

const initialState: checkLogReducerTypes.IDailyCheckInsReducer = {
   dailyCheckInsMasterList: {
      isLoading: true,
      list: [],
      message: '',
      error: '',
   },
   dailyCheckInsList: {
      list: [],
      pagination: {
         current_page: null,
         last_page: null,
         next_page_url: '',
         prev_page_url: '',
         first_page_url: '',
         last_page_url: '',
         total: 0,
      },
      isLoading: false,
      error: '',
      message: '',
   },
   dailyCheckInsGetOne: {
      isLoading: false,
      data: null,
      message: '',
      error: '',
   },
   dailyCheckInsAdd: {
      isLoading: false,
      data: null,
      message: '',
      error: '',
      name: '',
   },
   dailyCheckInsAddPost: {
      isLoading: false,
      data: null,
      message: '',
      error: '',
      reqData: null,
   },
   dailyCheckInsUpdated: {
      isLoading: true,
      data: null,
      message: '',
      error: '',
   },
   dailyCheckInsContinuous: {
      isLoading: false,
      data: null,
      message: '',
      error: '',
   },
   dailyCheckInsStreakMasterList: {
      isLoading: false,
      list: [],
      message: '',
      error: '',
   },
   dailyCheckInsStreakUserList: {
      isLoading: false,
      list: [],
      message: '',
      error: '',
   },
   dailyCheckInsStreakFeed: {
      isLoading: false,
      data: null,
      message: '',
      error: '',
   },
};
const dailyCheckInsSlice = createSlice({
   name: 'dailyCheckIns',
   initialState: initialState,
   reducers: {
      // dailyCheckInsMasterList
      dailyCheckInsMasterListRequest: (state) => {
         return {
            ...state,
            dailyCheckInsMasterList: {
               ...state.dailyCheckInsMasterList,
               isLoading: true,
               list: [],
            },
         };
      },
      dailyCheckInsMasterListSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            dailyCheckInsMasterList: {
               ...state.dailyCheckInsMasterList,
               list: payload.list,
               isLoading: false,
            },
         };
      },
      dailyCheckInsMasterListFailed: (state, action) => {
         return {
            ...state,
            dailyCheckInsMasterList: {
               ...state.dailyCheckInsMasterList,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },
      dailyCheckInsMasterListReset: (state) => {
         return { ...state, dailyCheckInsMasterList: { ...initialState.dailyCheckInsMasterList } };
      },

      // daily check ins list
      dailyCheckInsListRequest: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            dailyCheckInsList: {
               ...state.dailyCheckInsList,
               isLoading: true,
               list: [],
               ...payload,
            },
         };
      },
      dailyCheckInsListSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            dailyCheckInsList: {
               ...state.dailyCheckInsList,
               pagination: { ...payload.pagination },
               list:
                  payload.pagination.current_page === 1
                     ? payload.list
                     : [...state.dailyCheckInsList.list, ...payload.list],
               isLoading: false,
               message: payload.message,
            },
         };
      },
      dailyCheckInsListFailed: (state, action) => {
         return {
            ...state,
            dailyCheckInsList: {
               ...state.dailyCheckInsList,
               isLoading: false,
               message: '',
               error: action.payload.error,
               list: state.dailyCheckInsList.list,
            },
         };
      },
      dailyCheckInsListReset: (state) => {
         return {
            ...state,
            dailyCheckInsList: {
               ...initialState.dailyCheckInsList,
            },
         };
      },

      // dailyCheckInsGetOne
      dailyCheckInsGetOneRequest: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            dailyCheckInsGetOne: {
               ...state.dailyCheckInsGetOne,
               isLoading: true,
               data: null,
               ...payload,
            },
         };
      },
      dailyCheckInsGetOneSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            dailyCheckInsGetOne: {
               ...state.dailyCheckInsGetOne,
               data: payload.data,
               isLoading: false,
            },
         };
      },
      dailyCheckInsGetOneFailed: (state, action) => {
         return {
            ...state,
            dailyCheckInsGetOne: {
               ...state.dailyCheckInsGetOne,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },
      dailyCheckInsGetOneReset: (state) => {
         return { ...state, dailyCheckInsGetOne: { ...initialState.dailyCheckInsGetOne } };
      },

      // dailyCheckInsAdd
      dailyCheckInsAddRequest: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            dailyCheckInsAdd: {
               ...state.dailyCheckInsAdd,
               isLoading: true,
               data: null,
               ...payload,
            },
         };
      },
      dailyCheckInsAddSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            dailyCheckInsAdd: {
               ...state.dailyCheckInsAdd,
               data: payload.data,
               isLoading: false,
               message: payload.message,
            },
         };
      },
      dailyCheckInsAddFailed: (state, action) => {
         return {
            ...state,
            dailyCheckInsAdd: {
               ...state.dailyCheckInsAdd,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },
      dailyCheckInsAddReset: (state) => {
         return { ...state, dailyCheckInsAdd: { ...initialState.dailyCheckInsAdd } };
      },

      // dailyCheckInsAddPost
      dailyCheckInsAddPostRequest: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            dailyCheckInsAddPost: {
               ...state.dailyCheckInsAddPost,
               isLoading: true,
               data: null,
               reqData: payload,
            },
         };
      },
      dailyCheckInsAddPostSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            dailyCheckInsAddPost: {
               ...state.dailyCheckInsAddPost,
               data: payload.data,
               isLoading: false,
               message: payload.message,
            },
         };
      },
      dailyCheckInsAddPostFailed: (state, action) => {
         return {
            ...state,
            dailyCheckInsAddPost: {
               ...state.dailyCheckInsAddPost,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },
      dailyCheckInsAddPostResetMessage: (state) => {
         return { ...state, dailyCheckInsAddPost: { ...state.dailyCheckInsAddPost, message: '' } };
      },
      dailyCheckInsAddPostReset: (state) => {
         return { ...state, dailyCheckInsAddPost: { ...initialState.dailyCheckInsAddPost } };
      },

      // dailyCheckInsUpdated
      dailyCheckInsUpdatedRequest: (state) => {
         return {
            ...state,
            dailyCheckInsUpdated: {
               ...state.dailyCheckInsUpdated,
               isLoading: true,
               data: null,
            },
         };
      },
      dailyCheckInsUpdatedSuccess: (state, action: any) => {
         const { payload } = action;
         const data = dailyCheckInsUpdatedDataUpdate(payload.data);
         return {
            ...state,
            dailyCheckInsUpdated: {
               ...state.dailyCheckInsUpdated,
               data: data,
               isLoading: false,
            },
         };
      },
      dailyCheckInsUpdatedFailed: (state, action) => {
         return {
            ...state,
            dailyCheckInsUpdated: {
               ...state.dailyCheckInsUpdated,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },
      dailyCheckInsUpdatedReset: (state) => {
         return { ...state, dailyCheckInsUpdated: { ...initialState.dailyCheckInsUpdated } };
      },
      // dailyCheckInsContinuous
      dailyCheckInsContinuousRequest: (state) => {
         return {
            ...state,
            dailyCheckInsContinuous: {
               ...state.dailyCheckInsContinuous,
               isLoading: true,
               data: null,
            },
         };
      },
      dailyCheckInsContinuousSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            dailyCheckInsContinuous: {
               ...state.dailyCheckInsContinuous,
               data: payload.data,
               message: payload.message,
               isLoading: false,
            },
         };
      },
      dailyCheckInsContinuousFailed: (state, action) => {
         return {
            ...state,
            dailyCheckInsContinuous: {
               ...state.dailyCheckInsContinuous,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },
      dailyCheckInsContinuousResetMessage: (state) => {
         return { ...state, dailyCheckInsContinuous: { ...state.dailyCheckInsContinuous, message: '' } };
      },
      dailyCheckInsContinuousReset: (state) => {
         return { ...state, dailyCheckInsContinuous: { ...initialState.dailyCheckInsContinuous } };
      },
      // daily check ins streaks master list
      dailyCheckInsStreakMasterListRequest: (
         state,
         action: PayloadAction<checkLogPageTypes.ICheckInsStreaksMasterList>
      ) => {
         return {
            ...state,
            dailyCheckInsStreakMasterList: {
               ...state.dailyCheckInsStreakMasterList,
               ...action.payload,
               isLoading: true,
               list: [],
            },
         };
      },

      dailyCheckInsStreakMasterListSuccess: (state, action: any) => {
         const { payload } = action;
         const list = getDailyCheckInsStreakMasterList(payload.list);
         return {
            ...state,
            dailyCheckInsStreakMasterList: {
               ...state.dailyCheckInsStreakMasterList,
               list: list,
               message: payload.message,
               isLoading: false,
            },
         };
      },
      dailyCheckInsStreakMasterListFailed: (state, action) => {
         return {
            ...state,
            dailyCheckInsStreakMasterList: {
               ...state.dailyCheckInsStreakMasterList,
               isLoading: false,
               message: '',
               error: action.payload.error,
               list: state.dailyCheckInsStreakMasterList.list,
            },
         };
      },
      dailyCheckInsStreakMasterListResetMessage: (state) => {
         return {
            ...state,
            dailyCheckInsStreakMasterList: { ...state.dailyCheckInsStreakMasterList, message: '' },
         };
      },
      dailyCheckInsStreakMasterListReset: (state) => {
         return { ...state, dailyCheckInsStreakMasterList: { ...initialState.dailyCheckInsStreakMasterList } };
      },

      dailyCheckInsStreakMasterListUpdate: (state, action: any) => {
         const { payload } = action;

         const list = getDailyCheckInsStreakMasterUpdateList(
            listParseAndStringify(state.dailyCheckInsStreakMasterList.list),
            listParseAndStringify(payload)
         );
         return {
            ...state,
            dailyCheckInsStreakMasterList: {
               ...state.dailyCheckInsStreakMasterList,
               list: list,
               message: payload.message,
               isLoading: false,
            },
         };
      },

      // daily check ins streaks user list
      dailyCheckInsStreakUserListRequest: (
         state,
         action: PayloadAction<checkLogPageTypes.ICheckInsStreaksMasterList>
      ) => {
         return {
            ...state,
            dailyCheckInsStreakUserList: {
               ...state.dailyCheckInsStreakUserList,
               ...action.payload,
               isLoading: true,
               list: [],
            },
         };
      },
      dailyCheckInsStreakUserListSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            dailyCheckInsStreakUserList: {
               ...state.dailyCheckInsStreakUserList,
               list: payload.list,
               message: payload.message,
               isLoading: false,
            },
         };
      },
      dailyCheckInsStreakUserListFailed: (state, action) => {
         return {
            ...state,
            dailyCheckInsStreakUserList: {
               ...state.dailyCheckInsStreakUserList,
               isLoading: false,
               message: '',
               error: action.payload.error,
               list: state.dailyCheckInsStreakUserList.list,
            },
         };
      },
      dailyCheckInsStreakUserListReset: (state) => {
         return { ...state, dailyCheckInsStreakUserList: { ...initialState.dailyCheckInsStreakUserList } };
      },

      // daily check ins streak feed
      dailyCheckInsStreakFeedRequest: (state, action: PayloadAction<checkLogPageTypes.ICheckInsStreakFeed>) => {
         return {
            ...state,
            dailyCheckInsStreakFeed: {
               ...state.dailyCheckInsStreakFeed,
               ...action.payload,
               isLoading: true,
               data: null,
            },
         };
      },
      dailyCheckInsStreakFeedSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            dailyCheckInsStreakFeed: {
               ...state.dailyCheckInsStreakFeed,
               data: payload.data,
               message: payload.message,
               isLoading: false,
            },
         };
      },
      dailyCheckInsStreakFeedFailed: (state, action) => {
         return {
            ...state,
            dailyCheckInsStreakFeed: {
               ...state.dailyCheckInsStreakFeed,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },
      dailyCheckInsStreakFeedReset: (state) => {
         return { ...state, dailyCheckInsStreakFeed: { ...initialState.dailyCheckInsStreakFeed } };
      },
   },
});
export { dailyCheckInsSlice };

const getDailyCheckInsStreakMasterList = (streakMasterList: any[]) => {
   return streakMasterList.map((streakData: any) => {
      streakData.isDisable = true;
      streakData.isWeekly = streakData.id === 5;
      streakData.weeklyCount = 0;
      return streakData;
   });
};

const getDailyCheckInsStreakMasterUpdateList = (streakMasterList: any[], userList: any[]) => {
   return streakMasterList.map((streakData: any) => {
      const index = userList.findIndex((x) => x.check_in_streak_id === streakData.id);
      const filterArray = userList.filter((x) => x.check_in_streak_id === 5);
      streakData.isDisable = index !== -1 ? false : true;
      streakData.isWeekly = streakData.id === 5;
      streakData.weeklyCount = filterArray?.length || 0;
      return streakData;
   });
};

const dailyCheckInsUpdatedDataUpdate = (updatedData: any) => {
   if (updatedData?.current_streak?.check_in_streak) {
      updatedData.current_streak.check_in_streak.isDisable = false;
      updatedData.current_streak.check_in_streak.isWeekly = updatedData.current_streak.check_in_streak_id === 5;
      updatedData.current_streak.check_in_streak.weeklyCount = 0;
   }
   return updatedData;
};
