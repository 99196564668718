import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_PATIENT_STATE } from 'reduxToolKit/actions';
import { commonSlice, getPatientStateSlice } from 'reduxToolKit/reducers';
import { askADocPatientReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getPatientStateRequest, getPatientStateSuccess, getPatientStateFailed } = getPatientStateSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getPatientStateSaga = function* (
   patientStatePayload: PayloadAction<askADocPatientReducerTypes.IAskADocPatientStateProps>
) {
   yield put(getPatientStateRequest(patientStatePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = patientStatePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getPatientState });
      if (response.status === 200) {
         const responseData = {
            data: response.data?.data,
            message: response.data?.message || messages.success,
         };
         yield put(getPatientStateSuccess(responseData));
      } else {
         yield put(getPatientStateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGetPatientStateSaga = function* () {
   yield takeEvery(GET_PATIENT_STATE, getPatientStateSaga);
};
