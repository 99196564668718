import { createSlice } from '@reduxjs/toolkit';
import { rpmDashBoardReducerTypes } from 'types';

const initialState: rpmDashBoardReducerTypes.IRpmDashBoardListReducer = {
   isLoading: false,
   progressLoadMore: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
   clinicRpmAllDevices: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
};

export const rpmDashboardSlice = createSlice({
   name: 'rpmDashboard',
   initialState: initialState,
   reducers: {
      rpmDashboardLoading: (state) => {
         return {
            ...state,
            isLoading: true,
            progressLoadMore: true,
         };
      },
      rpmDashboardRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            progressLoadMore: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      rpmDashboardSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.page === 1 ? payload.list : [...state.list, ...payload.list],
            total_records: payload.total_records,
            isLoading: false,
            progressLoadMore: false,
         };
      },
      rpmDashboardFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            progressLoadMore: false,
            message: '',
            error: action.payload.error,
         };
      },

      rpmDashboardReset: () => {
         return {
            ...initialState,
         };
      },

      clinicAllRpmDevicesRequest: (state, action) => {
         return {
            ...state,
            clinicRpmAllDevices: {
               ...state.clinicRpmAllDevices,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      clinicAllRpmDevicesSuccess: (state, action: any) => {
         return {
            ...state,
            clinicRpmAllDevices: {
               ...state.clinicRpmAllDevices,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      clinicAllRpmDevicesFailed: (state, action: any) => {
         return {
            ...state,
            clinicRpmAllDevices: {
               ...state.clinicRpmAllDevices,
               ...action.payload,
               isLoading: false,
               message: '',
               data: null,
            },
         };
      },

      clinicAllRpmDevicesReset: (state) => {
         return {
            ...state,
            clinicRpmAllDevices: {
               isLoading: false,
               message: '',
               data: null,
               error: '',
            },
         };
      },
   },
});
