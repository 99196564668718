import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINIC_ADMINS_INFO_SAGA, CLINIC_ADMINS_LIST_SAGA } from 'reduxToolKit/actions';
import { clinicAdminsSlice, commonSlice } from 'reduxToolKit/reducers';
import { clinicAdminSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   clinicAdminsRequest,
   clinicAdminsListSuccess,
   clinicAdminsListFailed,
   clinicAdminsInfoRequest,
   clinicAdminInfoSuccess,
   clinicAdminsInfoFailed,
} = clinicAdminsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const clinicAdminListSaga = function* (payloadData: PayloadAction<clinicAdminSagaTypes.IClinicAdminsListRequestSaga>) {
   yield put(clinicAdminsRequest(payloadData.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadData.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.clinicAdmins });
      if (response.status === 200) {
         const responseData = response?.data;
         const clinicPhysicianList = {
            list: responseData?.data?.list,
         };
         yield put(clinicAdminsListSuccess(clinicPhysicianList));
      } else {
         yield put(clinicAdminsListFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const clinicAdminInfoSaga = function* (payloadData: PayloadAction<clinicAdminSagaTypes.IClinicAdminsInfoRequestSaga>) {
   yield put(clinicAdminsInfoRequest(payloadData.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadData.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.clinicInfo });
      if (response.status === 200) {
         const responseData = response?.data;
         const clinicPhysicianList = {
            data: responseData?.data,
         };
         yield put(clinicAdminInfoSuccess(clinicPhysicianList));
      } else {
         yield put(clinicAdminsInfoFailed(response?.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeClinicAdmins = function* () {
   yield takeEvery(CLINIC_ADMINS_LIST_SAGA, clinicAdminListSaga);
   yield takeEvery(CLINIC_ADMINS_INFO_SAGA, clinicAdminInfoSaga);
};
