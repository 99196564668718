import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ASSIGN_CLINIC, ASSIGN_VIDEO, ASSIGNED_VIDEO } from 'reduxToolKit/actions';
import { assignClinicVideoSlice, assignedVideoSlice, assignVideoSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { assignVideoLoading, assignVideoRequest, assignVideoSuccess, assignVideoFailed } = assignVideoSlice.actions;
const {
   assignedVideoLoading,
   assignedVideoRequest,
   assignedVideoSuccess,
   assignedVideoFailed,
} = assignedVideoSlice.actions;

const {
   assignClinicVideoLoading,
   assignClinicVideoRequest,
   assignClinicVideoSuccess,
   assignClinicVideoFailed,
} = assignClinicVideoSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const assignVideoSaga = function* (assignVideoTypePayload: any) {
   yield put(assignVideoLoading());
   yield put(assignVideoRequest(assignVideoTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = assignVideoTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.clinicVideos });
      if (response.status === 200) {
         const responseData = response.data;
         const assignVideoList = {
            list: responseData.data?.list,
            total_records: responseData.data?.total_records,
            message: response?.data?.message,
         };
         yield put(assignVideoSuccess(assignVideoList));
      } else {
         yield put(assignVideoFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const assignedVideoSaga = function* (assignedVideoTypePayload: any) {
   yield put(assignedVideoLoading());
   yield put(assignedVideoRequest(assignedVideoTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = assignedVideoTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getPatientAssignedVideos });
      if (response.status === 200) {
         const responseData = response.data;
         const assignedVideoList = {
            list: responseData.data?.list,
            total_records: responseData.data?.total_records,
            message: response?.data?.message,
         };
         yield put(assignedVideoSuccess(assignedVideoList));
      } else {
         yield put(assignedVideoFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const assignClinicVideoSaga = function* (assignClinicVideoTypePayload: any) {
   yield put(assignClinicVideoLoading());
   yield put(assignClinicVideoRequest(assignClinicVideoTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = assignClinicVideoTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.assignClinicHistoryVideo });
      if (response.status === 200) {
         const responseData = response.data;
         yield put(assignClinicVideoSuccess(responseData));
      } else {
         yield put(assignClinicVideoFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAssignVideo = function* () {
   yield takeEvery(ASSIGN_VIDEO, assignVideoSaga);
};

export const takeAssignedVideo = function* () {
   yield takeEvery(ASSIGNED_VIDEO, assignedVideoSaga);
};

export const takeAssignClinicVideo = function* () {
   yield takeEvery(ASSIGN_CLINIC, assignClinicVideoSaga);
};
