import { createSlice } from '@reduxjs/toolkit';
import { announcementReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: announcementReducerTypes.IAnnouncementReducerTypes = {
   announcementList: {
      isLoading: false,
      error: '',
      errorMessage: '',
      message: '',
      data: null,
   },
   announcementResponse: {
      isLoading: false,
      error: '',
      errorMessage: '',
      message: '',
      data: null,
   },
};
export const announcementSlice = createSlice({
   name: 'announcementSlice',
   initialState: initialState,
   reducers: {
      announcementListRequest: (state) => {
         return {
            ...state,
            announcementList: {
               isLoading: true,
            },
         };
      },
      announcementListSuccess: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            announcementList: {
               ...payload,
               isLoading: false,
               data: payload.data,
               message: payload.message || messages.success,
            },
         };
      },
      announcementListFailed: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            announcementList: {
               ...payload,
               isLoading: false,
               message: '',
               data: null,
               error: payload.error || messages.failed,
               errorMessage: payload.error || messages.failed,
            },
         };
      },
      announcementListReset: (state) => {
         return {
            ...state,
            announcementList: {
               ...initialState.announcementList,
            },
         };
      },
      // announcement response
      announcementResponseRequest: (state, action) => {
         return {
            ...state,
            announcementResponse: {
               ...action.payload,
               isLoading: true,
            },
         };
      },
      announcementResponseSuccess: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            announcementResponse: {
               ...payload,
               isLoading: false,
               data: payload.data,
               message: payload.message || messages.success,
            },
         };
      },
      announcementResponseFailed: (state, action) => {
         const { payload } = action;
         return {
            ...state,
            announcementResponse: {
               ...payload,
               isLoading: false,
               message: '',
               data: null,
               error: payload.error || messages.failed,
               errorMessage: payload.error || messages.failed,
            },
         };
      },
      announcementResponseReset: (state) => {
         return {
            ...state,
            announcementResponse: {
               ...initialState.announcementResponse,
            },
         };
      },
   },
});
