import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { oncoMeditationPageTypes, oncoMeditationReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: oncoMeditationReducerTypes.IOncoMeditationReducers = {
   oncoMeditationList: {
      isLoading: false,
      message: '',
      error: '',
      list: [],
      pagination: {
         current_page: null,
         last_page: null,
         first_page_url: '',
         last_page_url: '',
         next_page_url: '',
         prev_page_url: '',
         total: 0,
      },
      lastCreatedDate: new Date(),
   },
   oncoMeditationAudioPlayer: {
      selectedIndex: -1,
      oncoItem: null,
      isAudioPlaying: false,
      isAudioPaused: false,
      onAudioPlayingTime: 0,
      isAudioPlayerInvoke: false,
      audioPlayingUrl: null,
   },
};

export const oncoMeditationSlice = createSlice({
   name: 'oncoMeditation',
   initialState: initialState,
   reducers: {
      // get treatment estimate enabled physicians
      oncoMeditationListRequest: (
         state,
         action: PayloadAction<oncoMeditationPageTypes.IOncoMeditationRequestProps>
      ) => {
         return {
            ...state,
            oncoMeditationList: {
               ...state.oncoMeditationList,
               ...action.payload,
               isLoading: true,
               list: action.payload.page && action.payload.page === 1 ? [] : state.oncoMeditationList.list,
            },
         };
      },
      oncoMeditationListSuccess: (
         state,
         action: PayloadAction<oncoMeditationReducerTypes.IOncoMeditationListReducers>
      ) => {
         const { payload } = action;
         return {
            ...state,
            oncoMeditationList: {
               pagination: payload.pagination,
               list:
                  payload.pagination.current_page === 1
                     ? payload.list
                     : [...state.oncoMeditationList.list, ...payload.list],
               isLoading: false,
               message: payload.message || messages.success,
               lastCreatedDate: payload.lastCreatedDate,
            },
         };
      },
      oncoMeditationListFailed: (
         state,
         action: PayloadAction<oncoMeditationReducerTypes.IOncoMeditationListReducers>
      ) => {
         return {
            ...state,
            oncoMeditationList: {
               ...state.oncoMeditationList,
               isLoading: false,
               error: action.payload.error,
               message: '',
            },
         };
      },
      oncoMeditationListListResetMessage: (state) => {
         return {
            ...state,
            oncoMeditationList: {
               ...state.oncoMeditationList,
               message: '',
               isLoading: false,
            },
         };
      },
      oncoMeditationListListReset: (state) => {
         return {
            ...state,
            oncoMeditationList: initialState.oncoMeditationList,
         };
      },

      oncoMeditationAudioPlayerStates: (
         state,
         action: PayloadAction<oncoMeditationReducerTypes.IOncoMeditationAudioPlayer>
      ) => {
         return {
            ...state,
            oncoMeditationAudioPlayer: {
               ...state.oncoMeditationAudioPlayer,
               selectedIndex:
                  action.payload?.selectedIndex || action.payload?.selectedIndex === 0
                     ? action.payload?.selectedIndex
                     : state.oncoMeditationAudioPlayer.selectedIndex,
               oncoItem: action.payload?.oncoItem ? action.payload?.oncoItem : state.oncoMeditationAudioPlayer.oncoItem,
               isAudioPlaying:
                  action.payload?.isAudioPlaying === undefined
                     ? state.oncoMeditationAudioPlayer.isAudioPlaying
                     : action.payload?.isAudioPlaying,
               isAudioPaused:
                  action.payload?.isAudioPaused === undefined
                     ? state.oncoMeditationAudioPlayer.isAudioPaused
                     : action.payload?.isAudioPaused,
               isAudioPlayerInvoke:
                  action.payload?.isAudioPlayerInvoke === undefined
                     ? state.oncoMeditationAudioPlayer.isAudioPlayerInvoke
                     : action.payload?.isAudioPlayerInvoke,
               onAudioPlayingTime:
                  action.payload?.onAudioPlayingTime || state.oncoMeditationAudioPlayer.onAudioPlayingTime,
               audioPlayingUrl: action.payload?.audioPlayingUrl
                  ? action.payload?.audioPlayingUrl
                  : state.oncoMeditationAudioPlayer.audioPlayingUrl,
            },
         };
      },

      oncoAudioPlayerInvoke: (state, action: PayloadAction<oncoMeditationPageTypes.IUpdateAudioPlayerInvoke>) => {
         return {
            ...state,
            oncoMeditationAudioPlayer: {
               ...state.oncoMeditationAudioPlayer,
               isAudioPlayerInvoke: action.payload?.isAudioPlayerInvoke,
               isAudioPaused: action.payload?.isAudioPaused,
            },
         };
      },

      oncoMeditationAudioPlayerStateReset: (state) => {
         return {
            ...state,
            oncoMeditationAudioPlayer: initialState.oncoMeditationAudioPlayer,
         };
      },

      oncoMeditationAudioPlayerDialogStateReset: (state) => {
         return {
            ...state,
            oncoMeditationAudioPlayer: {
               ...state.oncoMeditationAudioPlayer,
               selectedIndex: -1,
               oncoItem: null,
               isAudioPlaying: false,
               isAudioPaused: false,
               onAudioPlayingTime: 0,
               audioPlayingUrl: null,
            },
         };
      },
   },
});
