import { put, takeEvery } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ADD_HIPPA_COMPLIANCE, GET_HIPPA_COMPLIANCE } from 'reduxToolKit/actions';
import { commonSlice, hippaComplianceSlice } from 'reduxToolKit/reducers';
import { hippaComplianceReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   addHippaComplianceRequest,
   addHippaComplianceSuccess,
   addHippaComplianceFailed,
   getHippaComplianceRequest,
   getHippaComplianceSuccess,
   getHippaComplianceFailed,
} = hippaComplianceSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const addHippaComplianceSaga = function* (
   payloadState: PayloadAction<hippaComplianceReducerTypes.IAddHippaCompliance>
) {
   yield put(addHippaComplianceRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addHippaCompliance });
      if (response.status === 200) {
         const responseData = {
            data: response.data?.data,
            message: response.data?.message || messages.success,
         };
         yield put(addHippaComplianceSuccess(responseData));
      } else {
         yield put(addHippaComplianceFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getHippaComplianceSaga = function* (
   payloadState: PayloadAction<hippaComplianceReducerTypes.IGetHippaCompliance>
) {
   yield put(getHippaComplianceRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getHippaCompliance });
      if (response.status === 200) {
         const responseData = {
            data: response.data?.data,
            message: response.data?.message || messages.success,
         };
         yield put(getHippaComplianceSuccess(responseData));
      } else {
         yield put(getHippaComplianceFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAskADocHippaCompliance = function* () {
   yield takeEvery(ADD_HIPPA_COMPLIANCE, addHippaComplianceSaga);
   yield takeEvery(GET_HIPPA_COMPLIANCE, getHippaComplianceSaga);
};
