import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { PHYSICIAN_JOIN_REQUESTS } from 'reduxToolKit/actions';
import { clinicJoinRequestSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   clinicJoinRequestLoading,
   clinicJoinRequestRequest,
   clinicJoinRequestSuccess,
   clinicJoinRequestFailed,
} = clinicJoinRequestSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const adminClinicSaga = function* (cancerTypePayload: any) {
   yield put(clinicJoinRequestLoading());
   yield put(clinicJoinRequestRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, ...API.clinicJoinRequests });
      if (response.status === 200) {
         const responseData = response.data;

         const clinicPhySicianList = {
            data: responseData.data,
         };
         yield put(clinicJoinRequestSuccess(clinicPhySicianList));
      } else {
         yield put(clinicJoinRequestFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeJoinClinicRequest = function* () {
   yield takeEvery(PHYSICIAN_JOIN_REQUESTS, adminClinicSaga);
};
