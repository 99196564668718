import { createSlice } from '@reduxjs/toolkit';
import { peopleNearMeReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: peopleNearMeReducerTypes.ITopCancerState = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
};

export const getTopCancerSlice = createSlice({
   name: 'getTopCancer',
   initialState: initialState,
   reducers: {
      getTopCancerRequest: (state) => {
         return {
            ...state,
            isLoading: true,
            list: state.list,
         };
      },
      getTopCancerSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            isLoading: false,
            message: payload.message || messages.success,
         };
      },
      getTopCancerFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      getTopCancerReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
