import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { MY_PATIENTS_LIST, GET_CLINICAL_ALL_PATIENTS } from 'reduxToolKit/actions';
import { commonSlice, myPatientsListSlice } from 'reduxToolKit/reducers';
import { clinicAdminSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   myPatientsListLoading,
   myPatientsListViewRequest,
   myPatientsListViewSuccess,
   myPatientsListFailed,
   getClinicalAllPatientsRequest,
   getClinicalAllPatientsSuccess,
   getClinicalAllPatientsFailed,
} = myPatientsListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const myPatientsSaga = function* (payloadState: PayloadAction<clinicAdminSagaTypes.IClinicNursePatients>) {
   yield put(myPatientsListLoading());
   yield put(myPatientsListViewRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload.paramsData;
      const url = payloadState.payload.userType === 'nurse' ? API.oncNursePatientsList : API.oncMyPatientsList;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...url });
      if (response.status === 200) {
         const responseData = response?.data;
         const myPatientsList = {
            list: responseData?.data?.list,
            total_records: responseData?.data?.total_records,
         };
         yield put(myPatientsListViewSuccess(myPatientsList));
      } else {
         yield put(myPatientsListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getClinicalAllPatientsSaga = function* (
   payloadState: PayloadAction<clinicAdminSagaTypes.IGetClinicalAllPatients>
) {
   yield put(getClinicalAllPatientsRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getClinicAllPatients });
      if (response.status === 200) {
         const responseData = response?.data;
         const clinicalAllPatientsList = {
            list: responseData?.data?.list,
            total_records: responseData?.data?.total_records,
         };
         yield put(getClinicalAllPatientsSuccess(clinicalAllPatientsList));
      } else {
         yield put(getClinicalAllPatientsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeMyPatientsRequest = function* () {
   yield takeLatest(MY_PATIENTS_LIST, myPatientsSaga);
   yield takeLatest(GET_CLINICAL_ALL_PATIENTS, getClinicalAllPatientsSaga);
};
