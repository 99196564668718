import { createAction } from '@reduxjs/toolkit';
import { announcementPageTypes } from 'types';

export const ANNOUNCEMENT_LIST = 'ANNOUNCEMENT_LIST';
export const getAnnouncementListSagaAction = createAction(ANNOUNCEMENT_LIST);

export const ANNOUNCEMENT_RESPONSE = 'ANNOUNCEMENT_RESPONSE';
export const announcementResponseSagaAction = createAction<announcementPageTypes.IAnnouncementResponsePageTypes>(
   ANNOUNCEMENT_RESPONSE
);
