import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { feedReducerTypes } from 'types';

const initialState: feedReducerTypes.ITagUsersListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   requestData: null,
};

export const tagUsersListSlice = createSlice({
   name: 'tagUsersList',
   initialState: initialState,
   reducers: {
      tagUsersListRequest: (state) => {
         return {
            ...state,
            isLoading: true,
            list: [],
         };
      },
      tagUsersListSuccess: (state, action: PayloadAction<feedReducerTypes.ITagUsersListReducer>) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            message: 'success',
            isLoading: false,
            requestData: payload.requestData,
         };
      },
      tagUsersListFailed: (state, action: PayloadAction<feedReducerTypes.ITagUsersListReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: 'failed',
            error: action.payload.error,
         };
      },
      tagUsersListResetMessage: (state) => {
         return {
            ...state,
            message: '',
         };
      },
      tagUsersListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
