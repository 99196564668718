import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearch } from 'types/common/search';

const initialState: ISearch = {
   jobSearch: '',
   globalSearch: '',
   drugLookupSearch: '',
   secondOpinionSearch: '',
   educationLibrarySearch: '',
   supportGroupSearch: '',
};

const searchSlice = createSlice({
   name: 'search',
   initialState: initialState,
   reducers: {
      setJobSearch: (state, action: PayloadAction<string>) => {
         return {
            ...state,
            jobSearch: action.payload,
         };
      },
      resetJobSearch: (state) => {
         return {
            ...state,
            jobSearch: '',
         };
      },
      setGlobalSearch: (state, action: PayloadAction<string>) => {
         return {
            ...state,
            globalSearch: action.payload,
         };
      },
      resetGlobalSearch: (state) => {
         return {
            ...state,
            globalSearch: '',
         };
      },
      setDrugLookupSearch: (state, action: PayloadAction<string>) => {
         return {
            ...state,
            drugLookupSearch: action.payload,
         };
      },
      resetDrugLookupSearch: (state) => {
         return {
            ...state,
            drugLookupSearch: '',
         };
      },
      setSecondOpinionSearch: (state, action: PayloadAction<string>) => {
         return {
            ...state,
            secondOpinionSearch: action.payload,
         };
      },
      resetSecondOpinionSearch: (state) => {
         return {
            ...state,
            secondOpinionSearch: '',
         };
      },
      setEducationLibrarySearch: (state, action: PayloadAction<string>) => {
         return {
            ...state,
            educationLibrarySearch: action.payload,
         };
      },
      resetEducationLibrarySearch: (state) => {
         return {
            ...state,
            educationLibrarySearch: '',
         };
      },
      setSupportGroupSearch: (state, action: PayloadAction<string>) => {
         return {
            ...state,
            supportGroupSearch: action.payload,
         };
      },
      resetSupportGroupSearch: (state) => {
         return {
            ...state,
            supportGroupSearch: '',
         };
      },
      // reset all
      resetAllSearch: () => {
         return { ...initialState };
      },
   },
});

export { searchSlice };
