import { createAction } from '@reduxjs/toolkit';
import { patientAdvocateTypes } from 'types';

export const GET_ADVOCATES_SAGA = 'GET_ADVOCATES_SAGA';
export const getAdvocatesAction = createAction<patientAdvocateTypes.IPatientAdvocateForm>(GET_ADVOCATES_SAGA);

export const UPDATE_ADVOCATES_SAGA = 'UPDATE_ADVOCATES_SAGA';
export const updateAdvocateAction = createAction<patientAdvocateTypes.IUpdateAdvocateForm>(UPDATE_ADVOCATES_SAGA);

export const DELETE_PATIENT_ADVOCATE_SAGA = 'DELETE_PATIENT_ADVOCATE_SAGA';
export const deletePatientAdvocateAction = createAction<patientAdvocateTypes.IDeletePatientAdvocateForm>(
   DELETE_PATIENT_ADVOCATE_SAGA
);

export const ADD_ADVOCATE_SAGA = 'ADD_ADVOCATE_SAGA';
export const addAdvocateAction = createAction<patientAdvocateTypes.IAddAdvocateForm>(ADD_ADVOCATE_SAGA);
