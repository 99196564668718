import { createSlice } from '@reduxjs/toolkit';
import { appointmentScheduleSubmitReducerTypes, appointmentScheduleSubmitSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: appointmentScheduleSubmitReducerTypes.IAppointmentScheduleReducer = {
   appointmentSchedule: null,
   isLoading: false,
   error: '',
   message: '',
   addAppointment: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   getOneAppointment: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   updateAppointmentStatus: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
   myAppointmentsMeeting: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
};
const appointmentScheduleSlice = createSlice({
   name: 'appointmentScheduleSubmit',
   initialState: initialState,
   reducers: {
      PostAppointmentSchedule: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      PostAppointmentScheduleSuccess: (
         state,
         action: appointmentScheduleSubmitSagaTypes.IAppointmentScheduleSuccess
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      PostAppointmentScheduleFailed: (state, action) => {
         return {
            ...state,
            physicianSchedule: null,
            isLoading: false,
            message: '',
            error: action.payload.error_message,
         };
      },
      postAppointmentResetMsg: (state, action) => {
         return {
            ...state,
            message: '',
         };
      },
      postAppointmentResetErr: (state, action) => {
         return {
            ...state,
            message: '',
            error: '',
         };
      },
      // add appointment
      addAppointmentRequest: (state, action) => {
         return {
            ...state,
            addAppointment: {
               isLoading: true,
               ...action.payload,
            },
         };
      },
      addAppointmentSuccess: (state, action: any) => {
         return {
            ...state,
            addAppointment: {
               isLoading: false,
               data: action.payload.data,
               message: action.payload.message || messages.success,
            },
         };
      },
      addAppointmentFailed: (state, action: any) => {
         return {
            ...state,
            addAppointment: {
               isLoading: false,
               error: action.payload.error,
            },
         };
      },
      addAppointmentReset: (state) => {
         return {
            ...state,
            addAppointment: {
               ...initialState.addAppointment,
            },
         };
      },
      // get one appointment
      getOneAppointmentRequest: (state, action) => {
         return {
            ...state,
            getOneAppointment: {
               isLoading: true,
               ...action.payload,
            },
         };
      },
      getOneAppointmentSuccess: (state, action: any) => {
         return {
            ...state,
            getOneAppointment: {
               isLoading: false,
               data: action.payload.data,
               message: action.payload.message || messages.success,
            },
         };
      },
      getOneAppointmentFailed: (state, action: any) => {
         return {
            ...state,
            getOneAppointment: {
               isLoading: false,
               error: action.payload.error,
            },
         };
      },
      getOneAppointmentReset: (state) => {
         return {
            ...state,
            getOneAppointment: { ...initialState.getOneAppointment },
         };
      },
      // update appointment status
      updateAppointmentStatusRequest: (state, action) => {
         return {
            ...state,
            updateAppointmentStatus: {
               isLoading: true,
               ...action.payload,
            },
         };
      },
      updateAppointmentStatusSuccess: (state, action: any) => {
         return {
            ...state,
            updateAppointmentStatus: {
               isLoading: false,
               data: action.payload.data,
               message: action.payload.message || messages.success,
            },
         };
      },
      updateAppointmentStatusFailed: (state, action: any) => {
         return {
            ...state,
            updateAppointmentStatus: {
               isLoading: false,
               error: action.payload.error,
            },
         };
      },
      updateAppointmentStatusReset: (state) => {
         return {
            ...state,
            updateAppointmentStatus: initialState.updateAppointmentStatus,
         };
      },
      // my appointment meeting
      myAppointmentsMeetingRequest: (state, action) => {
         return {
            ...state,
            myAppointmentsMeeting: {
               isLoading: true,
               ...action.payload,
            },
         };
      },
      myAppointmentsMeetingSuccess: (state, action: any) => {
         return {
            ...state,
            myAppointmentsMeeting: {
               isLoading: false,
               data: action.payload.data,
               message: action.payload.message || messages.success,
            },
         };
      },
      myAppointmentsMeetingFailed: (state, action: any) => {
         return {
            ...state,
            myAppointmentsMeeting: {
               isLoading: false,
               error: action.payload.error,
            },
         };
      },
      myAppointmentsMeetingReset: (state) => {
         return {
            ...state,
            myAppointmentsMeeting: initialState.myAppointmentsMeeting,
         };
      },
   },
});
export { appointmentScheduleSlice };
