import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import CancerIcon from 'assets/fill-profile/cancerIcon.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import { useDispatch, useSelector } from 'react-redux';
import { cancerSecondaryTypeSagaAction, cancerTypeSagaAction, profileCompletionSagaAction } from 'reduxToolKit/actions';
import { cancerTypeListSlice, secondaryCancerTypeListSlice } from 'reduxToolKit/reducers';
import { Icon, SelectPicker } from 'rsuite';
import { storeTypes } from 'types';
import { CancerTypeProps } from 'types/modelHelpers';
import { CommonConstants } from 'utils/constants/common';
import { DONE, NEXT } from 'utils/constants/profileConstants';

const CancerTypeSection = (props: CancerTypeProps) => {
   const [primaryCancer, setPrimaryCancer] = useState<number | null>(null);
   const [specificCancer, setSpecificCancer] = useState<number | null>(null);
   const [validation, setValidation] = useState(false);
   const [isSearchCancerType, setIsSearchCancerType] = useState(false);
   const [isSearchCancer, setIsSearchCancer] = useState(false);
   const [addressError, setAddressError] = useState('');
   const [cancerTypes, setCancerTypes] = useState<any[]>([]);
   const [cancers, setCancers] = useState<any[]>([]);
   const dispatch = useDispatch();
   const { message, isLoading, error } = useSelector((state: storeTypes.IStore) => state.publicPostShare);
   const { list } = useSelector((state: storeTypes.IStore) => state.cancerType);
   const { list: SecondaryList } = useSelector((state: storeTypes.IStore) => state.secondaryCancerType);
   const { cancerListReset } = cancerTypeListSlice.actions;
   const { secondaryCancerListReset } = secondaryCancerTypeListSlice.actions;

   useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
         document.body.style.overflow = 'scroll';
      };
   }, []);

   const handleSubmit = () => {
      if (primaryCancer !== null) {
         setValidation(false);
         props.setIsLoader(true);
         const postAddressData = {
            input_type: 'cancer',
            primary_cancer_id: primaryCancer,
            secondary_cancer_id: specificCancer,
         };
         dispatch(profileCompletionSagaAction(postAddressData));

         setAddressError('');
      } else {
         props.setIsLoader(false);
         setValidation(true);
         return;
      }
   };
   const getCancerTypes = (searchWord: string) => {
      setIsSearchCancerType(true);
      setCancerTypes([]);
      const params = {
         page: 1,
         limit: CommonConstants.DropDownLimit,
         search_word: searchWord,
      };
      dispatch(cancerTypeSagaAction(params));
   };

   if (list.length > 0) {
      list.map((cancerList: any) => {
         const cancers = {
            label: cancerList?.name,
            value: cancerList?.id,
         };
         return setCancerTypes((cancerTypes) => [...cancerTypes, cancers]);
      });

      setIsSearchCancerType(false);
      dispatch(cancerListReset());
   }

   const getSpecificCancer = (cancerTypeId: number, searchWord: string) => {
      setIsSearchCancer(true);
      setCancers([]);
      const params = {
         cancer_type_id: cancerTypeId,
         search_word: searchWord,
         limit: CommonConstants.DropDownLimit,
      };
      dispatch(cancerSecondaryTypeSagaAction(params));
   };

   if (SecondaryList.length > 0) {
      SecondaryList.map((cancerList: any) => {
         const cancersObj = {
            label: cancerList?.name,
            value: cancerList?.id,
         };
         return setCancers((cancers) => [...cancers, cancersObj]);
      });
      setIsSearchCancer(false);
      dispatch(secondaryCancerListReset());
   }

   const changeCancerType = (data: number) => {
      setPrimaryCancer(data);
      if (data) {
         getSpecificCancer(data, '');
      }
   };
   const changeCancerSpecific = (data: number) => {
      setSpecificCancer(data);
   };

   useEffect(() => {
      if (props.isPrimary) {
         gotoNavigation();
      } else {
         getCancerTypes('');
      }
   }, []);

   const hideAlert = (type: string) => {
      if (type === 'userAlert') {
         props.skipModal();
         if (props.isFromNotification) {
         } else if (props.isFromClinicalTrials) {
         } else {
            window.location.href = '/feed';
         }
      }
   };

   const gotoNavigation = useCallback(() => {
      if (props?.isFromFeedInitial) {
         props.navigation(props?.nextSectionFillProfileInitial);
      } else if (props?.isFromClinicalTrials) {
         props.navigation(props?.nextSectionClinicalTrails);
      } else {
         props.navigation(props.nexSection);
      }
   }, []);

   useEffect(() => {
      if (
         (message &&
            props.displayStatus &&
            !props.isDisplay &&
            !props.isPrimary &&
            !props.isAddress &&
            !props.isDiagnosisDateValid &&
            !props?.isStagingValid &&
            !props?.isCancerStatusValid) ||
         (message &&
            props.displayStatus &&
            props.isDisplay &&
            !props.isPrimary &&
            !props.isAddress &&
            !props.isDiagnosisDateValid &&
            !props?.isStagingValid &&
            !props?.isCancerStatusValid)
      ) {
         props.setIsLoader(isLoading);
         gotoNavigation();
      } else if (
         message &&
         props.displayStatus &&
         props.isDisplay &&
         !props.isPrimary &&
         props.isAddress &&
         props.isDiagnosisDateValid &&
         props?.isStagingValid &&
         props?.isCancerStatusValid
      ) {
         const getAlert = () => (
            <InfoTimerAlert
               success
               confirmBtnText="Okay!"
               confirmBtnBsStyle="successBtn"
               title={message}
               onConfirm={() => hideAlert('userAlert')}
            />
         );

         props.setAlert(getAlert);
         props.setIsLoader(isLoading);
         props.currentModalClose();
      } else if (message) {
         props.setIsLoader(isLoading);
         gotoNavigation();
      }
      if (error) {
         setAddressError(error);
         props.setIsLoader(isLoading);
      }
   }, [message, error]);

   return (
      <>
         <div className="col-12">
            <img className="onco-icon" src={CancerIcon} alt="icon" />
         </div>

         <div>
            <p className="paragragh-text p-2">Select your cancer type to help us provide you the best resources</p>
         </div>
         <div>
            <label htmlFor="title" aria-hidden="true" className="dob-header">
               Cancer Type
            </label>
            <SelectPicker
               preventOverflow={true}
               cleanable={false}
               autoFocus={true}
               maxHeight={150}
               onChange={(data) => {
                  changeCancerType(data);
               }}
               value={primaryCancer}
               data={cancerTypes}
               renderMenu={(menu) => {
                  if (cancerTypes.length === 0 && isSearchCancerType) {
                     return (
                        <p
                           style={{
                              padding: 4,
                              color: '#999',
                              textAlign: 'center',
                           }}
                        >
                           <Icon icon="spinner" spin /> Loading
                        </p>
                     );
                  } else if (cancerTypes.length === 0 && !isSearchCancerType) {
                     return (
                        <p
                           style={{
                              padding: 4,
                              color: '#999',
                              textAlign: 'center',
                           }}
                        >
                           No Results Found
                        </p>
                     );
                  }
                  return menu;
               }}
            />
         </div>
         {primaryCancer === null && validation ? (
            <Box mt={1} className="error-msg">
               Cancer type is required
            </Box>
         ) : null}

         <div className="mt-2">
            <label htmlFor="title" aria-hidden="true" className="dob-header">
               Cancer Specific (optional)
            </label>
            <SelectPicker
               disabled={primaryCancer === null}
               autoFocus={true}
               cleanable={false}
               maxHeight={150}
               onChange={(data) => {
                  changeCancerSpecific(data);
               }}
               value={specificCancer}
               data={cancers}
               renderMenu={(menu) => {
                  if (cancers.length === 0 && isSearchCancer) {
                     return (
                        <p
                           style={{
                              padding: 4,
                              color: '#999',
                              textAlign: 'center',
                           }}
                        >
                           <Icon icon="spinner" spin /> Loading
                        </p>
                     );
                  } else if (cancers.length === 0 && !isSearchCancer) {
                     return (
                        <p
                           style={{
                              padding: 4,
                              color: '#999',
                              textAlign: 'center',
                           }}
                        >
                           No Results Found
                        </p>
                     );
                  }
                  return menu;
               }}
            />
         </div>
         {/* {specificCancer === null && validation ? (
            <div className="error-msg-address">Cancer specific is required</div>
         ) : (
            ''
         )} */}
         <div className="error-msg">{addressError}</div>
         <div className="button">
            <button type="button" className="button-start" id="cancerTypeSectionSubmit" onClick={handleSubmit}>
               {props?.currentStateArray &&
               props?.currentStateArray[props?.currentStateArray?.length - 1] === props?.currentState
                  ? DONE
                  : NEXT}
            </button>
         </div>
      </>
   );
};

export { CancerTypeSection };
