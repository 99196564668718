import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CUSTOM_NOTIFICATION_LIST } from 'reduxToolKit/actions';
import { commonSlice, customNotificationSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   customNotificationLoading,
   customNotificationRequest,
   customNotificationSuccess,
   customNotificationFailed,
} = customNotificationSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const customNotificationSaga = function* (notificationTypePayload: any) {
   yield put(customNotificationLoading());
   yield put(customNotificationRequest(notificationTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = notificationTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getCustomNotification });
      if (response.status === 200) {
         const responseData = response.data;
         const customNotificationList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            message: response.data?.message,
         };
         yield put(customNotificationSuccess(customNotificationList));
      } else {
         yield put(customNotificationFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeCustomNotificationRequest = function* () {
   yield takeEvery(CUSTOM_NOTIFICATION_LIST, customNotificationSaga);
};
