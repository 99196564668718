import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { HEALTH_RECORD_LIST } from 'reduxToolKit/actions';
import { commonSlice, healthRecordSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { healthRecordLoading, healthRecordRequest, healthRecordSuccess, healthRecordFailed } = healthRecordSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const healthRecordPhysicianSaga = function* (healthTypePayload: any) {
   yield put(healthRecordLoading());
   yield put(healthRecordRequest(healthTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = {
         page: healthTypePayload.payload.page,
         limit: healthTypePayload.payload.limit,
         clinic_id: healthTypePayload.payload.clinic_id,
         patient_id: healthTypePayload.payload.patient_id,
         search_word: healthTypePayload.payload.search_word,
      };
      const Api =
         healthTypePayload.payload.type === 'physician' ? API.getPhysicianHealthRecords : API.getPatientHealthRecords;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...Api });
      if (response.status === 200) {
         const responseData = response.data;
         const healthRecordsList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            message: response.data?.message,
         };
         yield put(healthRecordSuccess(healthRecordsList));
      } else {
         yield put(healthRecordFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeHealthRecordPhysicianRequest = function* () {
   yield takeEvery(HEALTH_RECORD_LIST, healthRecordPhysicianSaga);
};
