import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fillProfileReducerTypes } from 'types';

const initialState: fillProfileReducerTypes.IPhoneSectionReducerType = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
export const inviteClinicPhysiciansSlice = createSlice({
   name: 'inviteClinicPhysicians',
   initialState: initialState,
   reducers: {
      inviteClinicPhysiciansRequest: (
         state,
         action: PayloadAction<fillProfileReducerTypes.IPhoneSectionReducerType>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      inviteClinicPhysiciansSuccess: (
         state,
         action: PayloadAction<fillProfileReducerTypes.IPhoneSectionReducerType>
      ) => {
         return {
            ...state,
            ...action.payload,
         };
      },
      inviteClinicPhysiciansFailed: (
         state,
         action: PayloadAction<fillProfileReducerTypes.IPhoneSectionReducerType>
      ) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      inviteClinicPhysiciansReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
