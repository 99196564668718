import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { PILL_REMAINDER_GET_ONE_SAGA } from 'reduxToolKit/actions';
import { commonSlice, pillRemainderGetOneSlice } from 'reduxToolKit/reducers';
import { pillRemainderSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   pillRemainderGetOneView,
   pillRemainderGetOneViewSuccess,
   pillRemainderGetOneViewFailed,
} = pillRemainderGetOneSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const pillRemainderGetOne = function* (
   pillRemainderGetOne: PayloadAction<pillRemainderSagaTypes.IPillRemainderGetOneParams>
) {
   yield put(pillRemainderGetOneView(pillRemainderGetOne.payload));
   try {
      const payload = pillRemainderGetOne.payload;
      const url = {
         apiPath: `${API.pillRemainderGetOne.apiPath}${payload.id}`,
         action: API.pillRemainderGetOne.action,
      };
      const response: AxiosResponse = yield apiCall({ ...url });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(pillRemainderGetOneViewSuccess(resObj));
      } else {
         yield put(pillRemainderGetOneViewFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takePillRemainderGetOneRequest = function* () {
   yield takeEvery(PILL_REMAINDER_GET_ONE_SAGA, pillRemainderGetOne);
};
