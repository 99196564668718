import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { locumReducerTypes, locumSagaTypes } from 'types';

const initialState: locumReducerTypes.ILocumList = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};

export const practiseAdminLocumPhySearchSlice = createSlice({
   name: 'practiseAdminLocumPhySearch',
   initialState: initialState,
   reducers: {
      practiseAdminLocumPhySearchLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      practiseAdminLocumPhySearchRequest: (
         state,
         action: PayloadAction<locumSagaTypes.IBookPhysicianListSagaParams>
      ) => {
         return {
            ...state,
            isLoading: true,
            ...action.payload,
         };
      },
      practiseAdminLocumPhySearchSuccess: (state, action: PayloadAction<locumReducerTypes.ILocumList>) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      practiseAdminLocumPhySearchFailed: (state, action: PayloadAction<locumReducerTypes.ILocumList>) => {
         const { payload } = action;
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: payload.error,
         };
      },
      practiseAdminLocumPhySearchReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
