import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINIC_PHYSICIANS } from 'reduxToolKit/actions';
import { commonSlice, getPhysicianListSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getPhysicianListLoading,
   getPhysicianListViewRequest,
   getPhysicianListViewSuccess,
   getPhysicianListFailed,
} = getPhysicianListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const clinicPhysicianList = function* (cancerTypePayload: any) {
   yield put(getPhysicianListLoading());
   yield put(getPhysicianListViewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getClinicPhysician });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            data: responseData.data,
         };
         yield put(getPhysicianListViewSuccess(oncDriveList));
      } else {
         yield put(getPhysicianListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeClinicPhysicianList = function* () {
   yield takeEvery(CLINIC_PHYSICIANS, clinicPhysicianList);
};
