import { createSlice } from '@reduxjs/toolkit';
import { peopleNearMeReducerTypes } from 'types';

const initialState: peopleNearMeReducerTypes.IDrugSpecific = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
export const notificationReadAllSlice = createSlice({
   name: 'notificationReadAll',
   initialState: initialState,
   reducers: {
      notificationReadAllRequest: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      notificationReadAllSuccess: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            ...action.payload,
         };
      },
      notificationReadAllFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
      notificationReadAllReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
