import { createAction } from '@reduxjs/toolkit';
import { pillRemainderSagaTypes } from 'types';

export const PILL_REMAINDER_ADD_SAGA = 'PILL_REMAINDER_ADD_SAGA';
export const pillRemainderAddSagaAction = createAction<pillRemainderSagaTypes.IPillRemainderAddSagaParams>(
   PILL_REMAINDER_ADD_SAGA
);

export const PILL_REMAINDER_LIST_SAGA = 'PILL_REMAINDER_LIST_SAGA';
export const pillRemainderListSagaAction = createAction<pillRemainderSagaTypes.IPillRemainderListSagaParams>(
   PILL_REMAINDER_LIST_SAGA
);

export const PILL_REMAINDER_GET_ONE_SAGA = 'PILL_REMAINDER_GET_ONE_SAGA';
export const pillRemainderGetOneSagaAction = createAction<pillRemainderSagaTypes.IPillRemainderGetOneParams>(
   PILL_REMAINDER_GET_ONE_SAGA
);

export const PILL_REMAINDER_DELETE_SAGA = 'PILL_REMAINDER_DELETE_SAGA';
export const pillRemainderDeleteSagaAction = createAction<pillRemainderSagaTypes.IPillRemainderDeleteParams>(
   PILL_REMAINDER_DELETE_SAGA
);

export const PILL_REMAINDER_EDIT_SAGA = 'PILL_REMAINDER_EDIT_SAGA';
export const pillRemainderEditSagaAction = createAction<pillRemainderSagaTypes.IPillRemainderAddSagaParams>(
   PILL_REMAINDER_EDIT_SAGA
);

export const PILL_REMAINDER_NOTIFICATION_STATUS_ADD_SAGA = 'PILL_REMAINDER_NOTIFICATION_STATUS_ADD_SAGA';
export const pillRemainderNotificationStatusAddSagaAction = createAction<pillRemainderSagaTypes.IPillRemainderNotificationStatusAddParams>(
   PILL_REMAINDER_NOTIFICATION_STATUS_ADD_SAGA
);

export const PILL_REMAINDER_NOTIFICATION_STATUS_EDIT_SAGA = 'PILL_REMAINDER_NOTIFICATION_STATUS_EDIT_SAGA';
export const pillRemainderNotificationStatusEditSagaAction = createAction<pillRemainderSagaTypes.IPillRemainderNotificationStatusEditParams>(
   PILL_REMAINDER_NOTIFICATION_STATUS_EDIT_SAGA
);

export const MEDICATIONS_LIST_SAGA = 'MEDICATIONS_LIST_SAGA';
export const medicationListSagaAction = createAction<pillRemainderSagaTypes.IPillActiveAndInactiveMedicineList>(
   MEDICATIONS_LIST_SAGA
);

export const OTHER_MEDICATIONS_LIST_SAGA = 'OTHER_MEDICATIONS_LIST_SAGA';
export const otherMedicationListSagaAction = createAction<pillRemainderSagaTypes.IPillActiveAndInactiveMedicineList>(
   OTHER_MEDICATIONS_LIST_SAGA
);

export const PILL_REMAINDER_MEDICATION_STATUS_UPDATE_SAGA = 'PILL_REMAINDER_MEDICATION_STATUS_UPDATE_SAGA';
export const pillRemainderMedicationStatusUpdateSagaAction = createAction<pillRemainderSagaTypes.IPillRemainderMedicationStatusUpdateParams>(
   PILL_REMAINDER_MEDICATION_STATUS_UPDATE_SAGA
);

export const PILL_REMAINDER_NOTIFICATION_GET_ONE_SAGA = 'PILL_REMAINDER_NOTIFICATION_GET_ONE_SAGA';
export const pillRemainderNotificationGetOneSagaAction = createAction<pillRemainderSagaTypes.IPillRemainderGetOneParams>(
   PILL_REMAINDER_NOTIFICATION_GET_ONE_SAGA
);

export const PILL_REMAINDER_ALL_PILLS = 'PILL_REMAINDER_ALL_PILLS';
export const pillRemainderAllPillsSagaAction = createAction<pillRemainderSagaTypes.IPillRemainderAllPillsParams>(
   PILL_REMAINDER_ALL_PILLS
);

export const PILL_REMAINDER_NON_CANCER_DELETE_SAGA = 'PILL_REMAINDER_NON_CANCER_DELETE_SAGA';
export const pillRemainderNonCancerDeleteSagaAction = createAction<pillRemainderSagaTypes.IPillRemainderDeleteParams>(
   PILL_REMAINDER_NON_CANCER_DELETE_SAGA
);
