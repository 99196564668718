import React, { useCallback, useEffect, useRef, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
// import dayjs from "dayjs";
import { Box, Button, Card, Container, Divider, Grid, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DotLoader from 'component/loader/DotLoader';
import DisplayName from 'component/modal/DisplayName';
import VideoTutorial from 'component/modal/VideoTutorialsModal';
import { PillRemainderList } from 'component/pillRemainder/prList/PrList';
import { PillRemainderModal } from 'component/pillRemainder/shared/prModal/index';
import Loading from 'component/widget/Loading';
import Constant from 'config/Constant';
import UrlConfig from 'config/UrlConfig';
import FsLightbox from 'fslightbox-react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { medicationListSagaAction, pillRemainderListSagaAction, userDetails } from 'reduxToolKit/actions';
import { commonSlice, medicationListSlice, pillRemainderListSlice } from 'reduxToolKit/reducers';
import { pillRemainderPageTypes, pillRemainderSagaTypes, storeTypes } from 'types';
import { common } from 'utils/constants/common';
import { PILL_REMAINDER_EVENTS } from 'utils/constants/eventTrack/pillRemainderEventConstants';
import { USR_PRF_RENDER_VALID } from 'utils/constants/profileConstants';
import {
   addUserObject,
   getUser,
   getVideoBooleanPillReminder,
   setVideoBooleanPillReminder,
} from 'utils/helpers/authHeader';
import {
   changeNormalDateFormat,
   convertDateToTimezone,
   convertDateToTimezoneDate,
   convertStartEndDate,
   getCurrentDate,
   getDate,
   getDateOfNormalDate,
} from 'utils/helpers/dateHelpers';
import { setEventTrack } from 'utils/helpers/eventTrack';
import { isPCAUser, isPhysician } from 'utils/helpers/userHelpers/userTypeHelpers';
import { isDisplayName } from 'utils/helpers/validationHelpers';

import { PillMediListItem } from '../prMedications/PillMediListItem';
import { PillRemainderDrawer } from '../shared/prDrawer';

const PillRemainder = () => {
   const containerRef = useRef() as any;
   const [isVideoTutorialsModal, setIsVideoTutorialsModal] = useState(
      isPCAUser(getUser()) ? (getVideoBooleanPillReminder() === 'true' ? false : true) : false
   );
   const [slide, setSlide] = useState(1);
   const [toggler, setToggler] = useState(false);

   const [editDrawer, setEditDrawer] = useState(false);
   const [pillItemMedication, setPillItemMedication] = useState<pillRemainderPageTypes.IPillRemainderPillsItem | null>(
      null
   );
   const [isView, setIsView] = useState<boolean>(true);

   const history = useHistory();
   const dispatch = useDispatch();
   const [addRemainder, setAddRemainder] = useState(false);
   const [daysAndDate, setDaysAndDate] = useState<pillRemainderPageTypes.IPillRemainderDate[]>([]);
   const [changeDate, setChangeDate] = useState<Date | null>(getCurrentDate());
   const [selectedDate, setSelectedDate] = useState<pillRemainderPageTypes.IPillRemainderDate | null>(null);
   const userDetail = useSelector((state: storeTypes.IStore) => state.userDetail?.userDetail);
   const { pillRemainderListReset } = pillRemainderListSlice.actions;
   const { updateFslightBoxOpen } = commonSlice.actions;
   const [isLoading, setIsLoading] = useState(false);
   const [displayName, setDisplayName] = useState(false);

   const { data: medicationsList, isLoading: medicationListIsLoading } = useSelector(
      (state: storeTypes.IStore) => state.medicationsList
   );
   const { medicationsListReset } = medicationListSlice.actions;

   const handleDateChange = (date: any) => {
      const dates = changeNormalDateFormat(date);

      if (convertStartEndDate(getCurrentDate().toString()) === convertStartEndDate(date.toString())) {
         const newDate = getDateOfNormalDate(dates.getFullYear(), dates.getMonth(), dates.getDay() - 1);
         const obj = {
            day: newDate.toLocaleString('en-us', { weekday: 'short' }),
            dateFormat: dates,
            date: newDate.getDay(),
         };

         setSelectedDate(obj);
         if (containerRef?.current !== null) {
            handleXPosCenter();
         }
      }
      setChangeDate(dates);
      const daysIn = [];
      const days = getDate(date.getFullYear(), date.getMonth() + 1, 0);
      for (let i = 1; i <= days; i++) {
         const newDate = getDateOfNormalDate(dates.getFullYear(), dates.getMonth(), i);
         const dateObj = {
            day: newDate.toLocaleString('en-us', { weekday: 'short' }),
            dateFormat: newDate,
            date: newDate.getDay(),
         };
         daysIn.push(dateObj);
      }
      setDaysAndDate(daysIn);
   };

   const handleRemainder = () => {
      setAddRemainder(!addRemainder);
   };

   const handlePrevScroll = () => {
      if (containerRef !== null) {
         const dayWidth = handleDayItemWidth();
         containerRef.current.scrollLeft += -(dayWidth * 3);
      }
   };
   const handleXPosCenter = () => {
      setTimeout(() => {
         const todayDateElem = document.getElementById('today-date');
         if (todayDateElem) {
            const dayWidth = handleDayItemWidth();
            const scrollCenPos = dayWidth * 6;
            if (containerRef.current) {
               containerRef.current.scrollLeft = todayDateElem.offsetLeft - scrollCenPos;
            }
         }
      }, 1000);
   };

   const handleDayItemWidth = () => {
      const dynamicScroll: any = document.getElementsByClassName('dynamic-scroll-item');
      const dynamicScrollOffset: any = dynamicScroll && dynamicScroll[0]?.offsetWidth;
      return dynamicScrollOffset;
   };
   const handleNextScroll = () => {
      if (containerRef !== null) {
         const dayWidth = handleDayItemWidth();
         containerRef.current.scrollLeft += dayWidth * 3;
      }
   };

   const clickDate = (date: pillRemainderPageTypes.IPillRemainderDate, i: number) => {
      setSelectedDate(date);
      const convertDateTimezoneDate = convertDateToTimezoneDate(date.dateFormat);
      const convertDateTimezone = convertDateToTimezone(date.dateFormat);
      dispatch(pillRemainderListReset());
      pillRemainderListDispatch(convertDateTimezoneDate, convertDateTimezone);
   };

   const pillRemainderListDispatch = (date: string, convertDateTimezone: string) => {
      const pillRemainderRequest = {
         date,
         time: convertDateTimezone,
      };
      dispatch(pillRemainderListSagaAction(pillRemainderRequest));
   };
   const updateDisplayNameStatus = (status: boolean) => {
      if (status) {
         dispatch(userDetails(USR_PRF_RENDER_VALID));
      }
   };
   const showLoading = (status: string) => {
      const isLoadings = status === 'show' ? true : false;
      setIsLoading(isLoadings);
   };
   useEffect(() => {
      const checkUserBoolean = isPCAUser(getUser()) || isPhysician(getUser());
      if (!checkUserBoolean) {
         history.push('/login');
      }
      if (containerRef?.current !== null) {
         handleXPosCenter();
      }
      const dates = getCurrentDate();
      const newDate = getDateOfNormalDate(dates.getFullYear(), dates.getMonth(), dates.getDay() - 1);
      const obj = {
         day: newDate.toLocaleString('en-us', { weekday: 'short' }),
         dateFormat: dates,
         date: newDate.getDay(),
      };
      setSelectedDate(obj);
      handleDateChange(getCurrentDate());
      dispatch(medicationsListReset());
      const params: pillRemainderSagaTypes.IPillActiveAndInactiveMedicineList = {
         from_type: 'pill',
      };
      dispatch(medicationListSagaAction(params));
   }, []);

   useEffect(() => {
      if (isPCAUser(getUser())) {
         addUserObject(userDetail);
         setDisplayName(isDisplayName());
      }
   }, [userDetail]);

   const handleCloseVideoTutorial = () => {
      setIsVideoTutorialsModal(!isVideoTutorialsModal);
      setVideoBooleanPillReminder('true');
   };

   const openImageslider = (slide: number) => {
      setToggler(!toggler);
      setSlide(slide);
      if (isVideoTutorialsModal) {
         handleCloseVideoTutorial();
      }
      dispatch(updateFslightBoxOpen({ isFslightBoxOpen: !toggler }));
   };

   const clickActiveAndInActiveMedicine = (activeMedi: pillRemainderPageTypes.IPillRemainderPillsItem) => {
      setPillItemMedication(activeMedi);
      setIsView(true);
      handleCloseDrawer();
   };

   const clickEditActiveAndInActiveMedicine = (activeMedi: pillRemainderPageTypes.IPillRemainderPillsItem) => {
      setPillItemMedication(activeMedi);
      handleCloseDrawer();
   };

   const handleCloseDrawer = () => {
      setEditDrawer(!editDrawer);
   };

   const setMedicationEdit = (isEdit: boolean) => {
      setIsView(isEdit);
   };

   const clickSeeAllMedications = useCallback(() => {
      history.push(`/pill-reminder-medications`);
   }, []);

   return (
      <Box mt={15} className="pill-remainder">
         {!displayName && (
            <DisplayName
               isRedirectToAskADocHome={false}
               showLoading={showLoading}
               updateDisplayNameStatus={updateDisplayNameStatus}
               isBasicProfile={false}
            />
         )}
         {isLoading ? <Loading /> : null}
         {isVideoTutorialsModal && (
            <VideoTutorial
               handleClose={handleCloseVideoTutorial}
               open={isVideoTutorialsModal}
               isFromAskADoc={false}
               isFromAskADocPhy={false}
               isFromOncoklinic={false}
               isFromNurse={false}
               isFromOncDrive={false}
               isFromSecondOpinionPhy={false}
               isFromSecondOpinionPat={false}
               isFromPillReminder={true}
               openImageslider={openImageslider}
            />
         )}
         <FsLightbox
            id={'viewTutorialVideoId'}
            toggler={toggler}
            sources={[`${UrlConfig.imageBaseUrl}${Constant.PILL_REMINDER_PATIENT_VIEW_TUTORIAL_VIDEO}`]}
            thumbs={[`${UrlConfig.imageBaseUrl}${Constant.PILL_REMINDER_PATIENT_VIEW_TUTORIAL_VIDEO_THUMBNAIL}`]}
            types={['video']}
            maxYoutubeVideoDimensions={{ width: common.YOUTUBE_WIDTH, height: common.YOUTUBE_HEIGHT }}
            slide={slide}
            disableLocalStorage={true}
         />
         <Container className="container-maxWidth">
            <Card>
               <Grid container direction="column" spacing={3}>
                  <Grid item xs={12}>
                     <Container maxWidth={'lg'}>
                        <Box p={3}>
                           <Grid container direction="row" justify="space-between">
                              <Grid item>
                                 <Typography className="pill-title">Pill Reminder</Typography>
                              </Grid>
                              <Grid item>
                                 <Grid container spacing={3}>
                                    <Grid item className="askdoc__patient">
                                       {isPCAUser(getUser()) && (
                                          <label className="video__text_pill hand-i" onClick={() => openImageslider(1)}>
                                             {`View tutorial video`}
                                          </label>
                                       )}
                                    </Grid>
                                    <Grid item>
                                       <Button
                                          className="add-remainder-btn"
                                          onClick={() => {
                                             handleRemainder();
                                             setEventTrack(PILL_REMAINDER_EVENTS.ADD_PILL_REMINDER);
                                          }}
                                       >
                                          <AddIcon />
                                          Add Reminder
                                       </Button>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Box>
                     </Container>
                     <Divider />
                  </Grid>
                  <Grid item xs={12}>
                     <Box style={{ textAlign: 'end', marginRight: '40px' }}>
                        <Link to="/pill-reminder-medications">
                           <Button
                              onClick={() => {
                                 setEventTrack(PILL_REMAINDER_EVENTS.VIEW_MEDICATIONS);
                              }}
                              className="medications-btn"
                           >
                              Medications
                              <ArrowForwardIcon fontSize="small" />
                           </Button>
                        </Link>
                     </Box>
                     <Box>
                        <Box className="pill-medi-home-list-div style-7" px={5}>
                           <Grid container direction="row" spacing={3}>
                              {medicationsList && medicationsList?.active && medicationsList?.active?.length > 0 ? (
                                 medicationsList?.active.map((item: any, j: number) => {
                                    return (
                                       <>
                                          {j < 4 ? (
                                             <Grid item>
                                                <Box width="400px">
                                                   <PillMediListItem
                                                      activeInactiveMedi={item}
                                                      clickEditActiveAndInActiveMedicine={
                                                         clickEditActiveAndInActiveMedicine
                                                      }
                                                      clickActiveAndInActiveMedicine={clickActiveAndInActiveMedicine}
                                                      isFromHome={true}
                                                   />
                                                </Box>
                                             </Grid>
                                          ) : null}
                                          {j < 4 ? null : j + 1 === medicationsList?.active?.length ? (
                                             <Grid item className="pill-medi-see-all-grid-item">
                                                <Box
                                                   width="100%"
                                                   className="pill-medi-home-see-all-div"
                                                   onClick={clickSeeAllMedications}
                                                >
                                                   <Box className="see-all-div">
                                                      <Typography className="see-all-text">See all</Typography>
                                                      <ArrowForwardIcon fontSize="small" />
                                                   </Box>
                                                </Box>
                                             </Grid>
                                          ) : null}
                                       </>
                                    );
                                 })
                              ) : medicationListIsLoading ? (
                                 <Box width="100%" className="mt-5">
                                    <DotLoader />
                                 </Box>
                              ) : null}
                           </Grid>
                        </Box>
                     </Box>
                  </Grid>
                  <Grid item xs={12}>
                     <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around" className="time-pick ">
                           <DatePicker
                              className="hand-i"
                              showTodayButton={true}
                              variant="dialog"
                              openTo="month"
                              views={['month', 'year']}
                              value={changeDate}
                              onChange={handleDateChange}
                           />
                        </Grid>
                     </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12}>
                     <Grid container direction="row" justify="space-between" className="filter-container">
                        <Grid item xs={1}>
                           <IconButton onClick={handlePrevScroll}>
                              <ArrowBackIosIcon className="pl-1" />
                           </IconButton>
                        </Grid>
                        <Grid item xs={9}>
                           <Box className="filter-schedule" width={1}>
                              <Grid container direction="row" spacing={6} className="container-grid" ref={containerRef}>
                                 {daysAndDate.length > 0 &&
                                    daysAndDate.map((day: pillRemainderPageTypes.IPillRemainderDate, i: number) => {
                                       return (
                                          <Grid
                                             item
                                             key={i}
                                             xs={12}
                                             className="dynamic-scroll-item"
                                             id={
                                                convertStartEndDate(selectedDate?.dateFormat.toString()) ===
                                                convertStartEndDate(day?.dateFormat.toString())
                                                   ? 'today-date'
                                                   : `other-date${i}`
                                             }
                                          >
                                             <Box
                                                className={`date-day`}
                                                onClick={() => {
                                                   clickDate(day, i);
                                                }}
                                             >
                                                <Typography
                                                   className={`filter-day ${
                                                      convertStartEndDate(selectedDate?.dateFormat.toString()) ===
                                                      convertStartEndDate(day?.dateFormat.toString())
                                                         ? 'day-clicked'
                                                         : ''
                                                   }`}
                                                >
                                                   {day.day}
                                                </Typography>
                                                <Typography
                                                   align="center"
                                                   className={`filter-date ${
                                                      convertStartEndDate(selectedDate?.dateFormat.toString()) ===
                                                      convertStartEndDate(day?.dateFormat.toString())
                                                         ? 'date-day-clicked'
                                                         : ''
                                                   }`}
                                                >
                                                   {i + 1}
                                                </Typography>
                                             </Box>
                                          </Grid>
                                       );
                                    })}
                              </Grid>
                           </Box>
                        </Grid>
                        <Grid item xs={2}>
                           <IconButton onClick={handleNextScroll}>
                              <ArrowForwardIosIcon />
                           </IconButton>
                        </Grid>
                     </Grid>
                  </Grid>

                  <Grid item xs={12}>
                     <PillRemainderList selectedDate={selectedDate} />
                  </Grid>
               </Grid>
            </Card>
         </Container>
         {addRemainder && (
            <PillRemainderModal
               handleClose={handleRemainder}
               handleSubmit={handleRemainder}
               open={addRemainder}
               selectedDate={selectedDate}
            />
         )}
         {editDrawer && (
            <PillRemainderDrawer
               handleClose={handleCloseDrawer}
               pillItem={null}
               pillItemMedication={pillItemMedication}
               type={''}
               isView={isView}
               setMedicationEdit={setMedicationEdit}
               selectedDate={null}
               clearMedications={() => {}}
               isFromHome={true}
            />
         )}
      </Box>
   );
};

export default PillRemainder;
