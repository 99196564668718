import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINIC_PHY_INVITE_IS_EXIST_SAGA } from 'reduxToolKit/actions';
import { commonSlice, clinicPhyInviteIsExistSlice } from 'reduxToolKit/reducers';
import { clinicAdminSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   clinicPhyInviteIsExistRequest,
   clinicPhyInviteIsExistSuccess,
   clinicPhyInviteIsExistFailed,
} = clinicPhyInviteIsExistSlice.actions;

const { exceptionHandlerRequest } = commonSlice.actions;

const clinicPhyInviteIsExist = function* (
   clinicPhyInviteIsExist: PayloadAction<clinicAdminSagaTypes.IClinicPhyInviteIsExistSaga>
) {
   yield put(clinicPhyInviteIsExistRequest(clinicPhyInviteIsExist.payload));
   try {
      const data = clinicPhyInviteIsExist.payload;
      const goUrl = true;
      const checkUser = true;
      const response: AxiosResponse = yield apiCall({ data, goUrl, checkUser, ...API.clinicPhyInviteIsExist });
      if (response.status === 200) {
         const resObj = response.data;
         yield put(clinicPhyInviteIsExistSuccess(resObj));
      } else {
         yield put(clinicPhyInviteIsExistFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeClinicPhyInviteIsExistRequest = function* () {
   yield takeEvery(CLINIC_PHY_INVITE_IS_EXIST_SAGA, clinicPhyInviteIsExist);
};
