import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { COMMENT_ADD_UPDATE, eventTrackAction } from 'reduxToolKit/actions';
import { commentsAddUpdateSlice, commonSlice } from 'reduxToolKit/reducers';
import { eventTrackTypes, feedSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { commentsAddUpdateRequest, commentsAddUpdateSuccess, commentsAddUpdateFailed } = commentsAddUpdateSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const commentsAddUpdate = function* (commentsAddUpdate: PayloadAction<feedSagaTypes.ICommentAddUpdateSagaRequest>) {
   yield put(commentsAddUpdateRequest(commentsAddUpdate.payload));
   try {
      const data = commentsAddUpdate.payload;

      let url = null;
      if (data.isAskADoc) {
         if (data.clickEditComment) {
            url = API.askADocFeedCommentEdit;
         } else {
            url = API.askADocFeedComment;
         }
      } else {
         if (data.clickEditComment) {
            url = API.editComment;
         } else {
            url = API.addComment;
         }
      }

      const checkUser = true;
      const goUrl = true;

      const response: AxiosResponse = yield apiCall({ goUrl, checkUser, data, ...url });
      if (response.status === 200) {
         const resObj = { data: response.data, requestData: data };
         yield put(commentsAddUpdateSuccess(resObj));
         if (commentsAddUpdate.payload.event) {
            const eventData: eventTrackTypes.IEventTrack = {
               event: {
                  name: commentsAddUpdate.payload.event.name,
               },
            };
            yield put(eventTrackAction(eventData));
         }
      } else {
         yield put(commentsAddUpdateFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};
export const takeCommentAddUpdateRequest = function* () {
   yield takeEvery(COMMENT_ADD_UPDATE, commentsAddUpdate);
};
