import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINICAL_NOTES_LIST } from 'reduxToolKit/actions';
import { clinicalNotesListSlice, commonSlice } from 'reduxToolKit/reducers';
import { clinicalNotesSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   clinicalNotesListLoading,
   clinicalNotesListViewRequest,
   clinicalNotesListViewSuccess,
   clinicalNotesListFailed,
} = clinicalNotesListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const clinicalNotesListSaga = function* (
   clinicalTypePayload: PayloadAction<clinicalNotesSagaTypes.IClinicalNotesListForm>
) {
   yield put(clinicalNotesListLoading());
   yield put(clinicalNotesListViewRequest(clinicalTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = clinicalTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getClinicalNotesList });
      if (response.status === 200) {
         const responseData = response.data;
         const clinicNoteList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            page: responseData.data.page,
         };
         yield put(clinicalNotesListViewSuccess(clinicNoteList));
      } else {
         yield put(clinicalNotesListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeClinicalNotesListRequest = function* () {
   yield takeEvery(CLINICAL_NOTES_LIST, clinicalNotesListSaga);
};
