import { createSlice } from '@reduxjs/toolkit';
import { peopleNearMeReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: peopleNearMeReducerTypes.ICancerTypeListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
};

export const myTreatingPhysiciansSlice = createSlice({
   name: 'myTreatingPhysicians',
   initialState: initialState,
   reducers: {
      myTreatingPhysiciansLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      myTreatingPhysiciansRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: action.payload.page && action.payload.page === 1 ? [] : state.list,
         };
      },
      myTreatingPhysiciansSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            message: payload.message || messages.success,
            total_records: payload.total_records,
            isLoading: false,
         };
      },
      myTreatingPhysiciansFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      myTreatingPhysiciansReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
