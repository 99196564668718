import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { GET_SPECIALITIES } from 'reduxToolKit/actions';
import { getSpecialitiesSlice, commonSlice } from 'reduxToolKit/reducers';
import { specialitySagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getSpecialitiesRequest, getSpecialitiesSuccess, getSpecialitiesFailed } = getSpecialitiesSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getSpecilaities = function* (payloadState: PayloadAction<specialitySagaTypes.IGetSpecialitySagaPayload>) {
   yield put(getSpecialitiesRequest(payloadState.payload));
   try {
      const url = API.physicianSpecialities;
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...url });
      if (response.status === 200) {
         const resObj = {
            data: response?.data?.data?.list,
            message: response?.data?.message,
         };
         yield put(getSpecialitiesSuccess(resObj));
      } else {
         yield put(getSpecialitiesFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGetSpecialities = function* () {
   yield takeLatest(GET_SPECIALITIES, getSpecilaities);
};
