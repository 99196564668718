import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { peopleNearMeReducerTypes, postShareSagaTypes } from 'types';

const initialState: peopleNearMeReducerTypes.ICancerTypeListReducer = {
   isLoading: false,
   error: '',
   message: '',
   list: [],
   current_page: 0,
   limit: 0,
   total_records: 0,
};

export const askPhyListSlice = createSlice({
   name: 'askPhyList',
   initialState: initialState,
   reducers: {
      askPhyListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      askPhyListViewRequest: (state, action: PayloadAction<postShareSagaTypes.IAskPhyList>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: state.list,
         };
      },
      askPhyListViewSuccess: (state, action: PayloadAction<peopleNearMeReducerTypes.ICancerTypeListReducer>) => {
         const { payload } = action;
         return {
            ...state,
            list: payload.list,
            message: payload.message,
            total_records: payload.total_records,
            isLoading: false,
         };
      },
      askPhyListFailed: (state, action: PayloadAction<peopleNearMeReducerTypes.ICancerTypeListReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      askPhyListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
