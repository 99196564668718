import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { GET_MY_CONVERSATIONS } from 'reduxToolKit/actions';
import { commonSlice, convoListSlice } from 'reduxToolKit/reducers';
import { postShareSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { convoListLoading, convoListViewRequest, convoListViewSuccess, convoListFailed } = convoListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const convoListSaga = function* (cancerTypePayload: PayloadAction<postShareSagaTypes.IChat>) {
   const payload = cancerTypePayload.payload;
   yield put(convoListLoading());
   if (payload.search_word) {
      yield delay(1000);
   }
   yield put(convoListViewRequest(cancerTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = cancerTypePayload.payload;

      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.convoList });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            message: response.data?.message || messages.success,
         };
         yield put(convoListViewSuccess(oncDriveList));
      } else {
         yield put(convoListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeConvoListRequest = function* () {
   yield takeLatest(GET_MY_CONVERSATIONS, convoListSaga);
};
