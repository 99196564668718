import { createSlice } from '@reduxjs/toolkit';
import { scheduleReducerTypes } from 'types';

const initialState: scheduleReducerTypes.ICheckAppointment = {
   isLoading: false,
   error: '',
   message: '',
   status: false,
};

export const checkAppointmentAvailabilitySlice = createSlice({
   name: 'checkAppointmentAvailability',
   initialState: initialState,
   reducers: {
      checkAppointmentAvailabilityRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      checkAppointmentAvailabilitySuccess: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      checkAppointmentAvailabilityFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error_message,
         };
      },
      checkAppointmentAvailabilityResetMsg: (state, action) => {
         return {
            ...state,
            message: '',
         };
      },
      checkAppointmentAvailabilityResetErr: (state, action) => {
         return {
            ...state,
            message: '',
            error: '',
         };
      },
   },
});
