import axios from 'axios';
import { User } from 'types/modelHelpers';

import { simpleStringify } from './commonHelpers';

/*Set User token and details to local storage*/
export const setAuth = (userData: any) => {
   localStorage.setItem('authToken', userData.token);
   localStorage.setItem('tokenExp', userData.token_exp);
   localStorage.setItem('tokenIat', userData.token_iat);
   localStorage.setItem('userId', userData.user.id);
   localStorage.removeItem('clinicId');
   localStorage.setItem('refreshToken', userData.refresh_token);
   localStorage.setItem('refreshTokenExp', userData.refresh_token_exp);
   localStorage.setItem('refreshTokenIat', userData.refresh_token_iat);
};

/*Set User token and details to local storage*/
export const setAuthRefreshToken = (userData: any) => {
   localStorage.setItem('authToken', userData.token);
   localStorage.setItem('tokenExp', userData.token_exp);
   localStorage.setItem('tokenIat', userData.token_iat);
   localStorage.setItem('refreshToken', userData.refresh_token);
   localStorage.setItem('refreshTokenExp', userData.refresh_token_exp);
   localStorage.setItem('refreshTokenIat', userData.refresh_token_iat);
};

export const removeToken = () => {
   localStorage.removeItem('authToken');
   localStorage.removeItem('tokenExp');
   localStorage.removeItem('tokenIat');
   localStorage.removeItem('refreshToken');
   localStorage.removeItem('refreshTokenExp');
   localStorage.removeItem('refreshTokenIat');
};

/*Get User Details*/
export const getAuth = () => {
   const authToken = localStorage.getItem('authToken');
   const tokenExp = localStorage.getItem('tokenExp');
   const tokenIat = localStorage.getItem('tokenIat');
   const user = getUser();
   const userId = localStorage.getItem('userId');
   const refreshToken = localStorage.getItem('refreshToken');
   const refreshTokenExp = localStorage.getItem('refreshTokenExp');
   const refreshTokenIat = localStorage.getItem('refreshTokenIat');
   return {
      authToken: authToken,
      tokenExp: tokenExp,
      tokenIat: tokenIat,
      userDetails: user,
      userId: userId,
      refreshToken: refreshToken,
      refreshTokenExp: refreshTokenExp,
      refreshTokenIat: refreshTokenIat,
   };
};

const getUserProfile = async () => {
   axios
      .get('/user/my-profile')
      .then((response: any) => {
         if (response.status === 200) {
            addUserObject(response?.data?.data);
            // window.location.reload();
         }
      })
      .catch(() => {
         // window.location.reload();
      });
};

export const getUser = (): User => {
   const tempUser = String(localStorage.getItem('user'));
   if (!tempUser) {
      (async () => {
         getUserProfile();
      })();
   }
   return JSON.parse(tempUser);
};

export const getUserData = (): any => {
   return getUser();
};

export const getUserId = (): number | null => {
   const user = getUser();
   return user ? user.id : null;
};

export const currentUserType = (): any => {
   const user = getUser();
   return user.user_type;
};

/*To check user is valid or invalid*/
export const isValidUser = () => {
   const authToken = localStorage.getItem('authToken');
   const user = getUser();
   return !!(authToken && user);
};

//Verified User
export const isEmailVerified = () => {
   const user = getUser();
   return user ? user.email_verified_at : null;
};

export const isAdminVerified = () => {
   const user = getUser();
   return user ? user.is_verified : null;
};

/*Logout*/
export const logout = () => {
   localStorage.clear();
   sessionStorage.clear();
};

export const removeUserObject = () => {
   localStorage.removeItem('user');
};

export const addUserObject = (userData: any) => {
   if (userData) {
      localStorage.setItem('user', simpleStringify(userData.user));
   }
};

export const getClinicID = () => {
   const clinicId = localStorage.getItem('clinicId');
   return clinicId ? parseInt(clinicId) : null;
};

export const getClinicId = (clinicId: any) => {
   localStorage.setItem('clinicId', clinicId);
};

export const setClinicDetails = (clinic: string) => {
   localStorage.setItem('clinicDetails', clinic);
};

export const getClinicDetails = () => {
   const clinicDetailsJson = localStorage.getItem('clinicDetails');
   if (!clinicDetailsJson) {
      return null;
   }
   return JSON.parse(clinicDetailsJson);
};
export const setClinicId = () => {
   const clinicId = localStorage.getItem('clinicId');
   return {
      clinicId: clinicId,
   };
};
export const setPatientId = (patientId: any) => {
   localStorage.setItem('patientId', patientId);
};
export const getPatientId = () => {
   const patientId = localStorage.getItem('patientId');
   return {
      patientId: patientId,
   };
};
export const setPatientName = (patientName: any) => {
   localStorage.setItem('patientName', patientName);
};

export const setIdleTimeOut = (status: any) => {
   localStorage.setItem('idle_time_out', status);
};

export const getIdleTimeOut = () => {
   const timeOutStatus = localStorage.getItem('idle_time_out');
   return timeOutStatus ? parseInt(timeOutStatus) : 0;
};

export const removeIdleTimeOut = () => {
   localStorage.removeItem('idle_time_out');
};

export const getPatientName = () => {
   const patientName = localStorage.getItem('patientName');
   return patientName;
};
export const setPatientCreatedAt = (patientCreatedAt: any) => {
   localStorage.setItem('patientCreatedAt', patientCreatedAt);
};
export const getPatientCreatedAt = () => {
   const patientCreatedAt = localStorage.getItem('patientCreatedAt');
   return patientCreatedAt;
};
export const setDeviceToken = (deviceToken: any) => {
   localStorage.setItem('deviceToken', deviceToken);
};
export const getDeviceToken = () => {
   const deviceToken = localStorage.getItem('deviceToken');
   return deviceToken;
};

export const setRemainTime = (time: any) => {
   localStorage.setItem('remainTime', time);
};

export const getRemainTime = () => {
   const remainTime = localStorage.getItem('remainTime');
   return remainTime ? parseInt(remainTime) : 0;
};

export const setDeviceNotificationToken = (deviceNotificationToken: any) => {
   localStorage.setItem('deviceNotificationToken', deviceNotificationToken);
};
export const getDeviceNotificationToken = () => {
   const deviceNotificationToken = localStorage.getItem('deviceNotificationToken');
   return deviceNotificationToken;
};

export const setClinicHistory = (clinicHistory: any) => {
   localStorage.setItem('clinicHistory', simpleStringify(clinicHistory));
};
export const getClinicHistory = () => {
   const clinicHistory = JSON.parse(String(localStorage.getItem('clinicHistory')));
   return clinicHistory;
};
export const setRiskAssessmentTab = (riskAssessmentTab: any) => {
   localStorage.setItem('riskAssessmentTab', riskAssessmentTab);
};
export const getRiskAssessmentTab = () => {
   const riskAssessmentTab = localStorage.getItem('riskAssessmentTab');
   return riskAssessmentTab;
};
export const setInfectionTrackingTab = (infectionTrackingTab: any) => {
   localStorage.setItem('infectionTrackingTab', infectionTrackingTab);
};
export const getInfectionTrackingTab = () => {
   const infectionTrackingTab = localStorage.getItem('infectionTrackingTab');
   return infectionTrackingTab;
};

export const setRecentSymptom = (symptom: any) => {
   localStorage.setItem('recentSymptom', symptom);
};
export const getRecentSymptom = () => {
   const recentSymptomStatus = localStorage.getItem('recentSymptom');

   return {
      recentSymptomStatus: recentSymptomStatus === 'yes' ? recentSymptomStatus : 'no',
   };
};

export const setClinicDisabledFeature = (clinicDisabledFeature: any) => {
   localStorage.setItem('clinicDisabledFeature', simpleStringify(clinicDisabledFeature));
};
export const getClinicDisabledFeature = () => {
   const clinicDisabledFeature = JSON.parse(String(localStorage.getItem('clinicDisabledFeature')));
   return clinicDisabledFeature;
};

export const setDeviceId = (deviceId: any) => {
   localStorage.setItem('deviceId', deviceId);
};
export const getDeviceId = () => {
   const deviceId = localStorage.getItem('deviceId');
   return deviceId;
};

export const setIsExistActiveDevice = (isExistActiveDevice: any) => {
   localStorage.setItem('isExistActiveDevice', isExistActiveDevice);
};
export const getIsExistActiveDevice = () => {
   const isExistActiveDevice = localStorage.getItem('isExistActiveDevice');
   return isExistActiveDevice === 'true' ? true : false;
};

export const setAskADocId = (askADcoId: any) => {
   localStorage.setItem('askADcoId', askADcoId);
};
export const getAskADocId = () => {
   const askADcoId = localStorage.getItem('askADcoId');
   return askADcoId;
};

export const setVideoBooleanAskADoc = (videoBooleanAskADoc: string) => {
   localStorage.setItem('videoBooleanAskADoc', videoBooleanAskADoc);
};
export const getVideoBooleanAskADoc = () => {
   const videoBooleanAskADoc = localStorage.getItem('videoBooleanAskADoc');
   return videoBooleanAskADoc;
};

export const setVideoBooleanSecondOpinion = (videoBooleanSecondOpinion: string) => {
   localStorage.setItem('videoBooleanSecondOpinion', videoBooleanSecondOpinion);
};
export const getVideoBooleanSecondOpinion = () => {
   const videoBooleanSecondOpinion = localStorage.getItem('videoBooleanSecondOpinion');
   return videoBooleanSecondOpinion;
};

export const setVideoBooleanOncoklinic = (videoBooleanOncoklinic: string) => {
   localStorage.setItem('videoBooleanOncoklinic', videoBooleanOncoklinic);
};
export const getVideoBooleanOncoklinic = () => {
   const videoBooleanOncoklinic = localStorage.getItem('videoBooleanOncoklinic');
   return videoBooleanOncoklinic;
};

export const setVideoBooleanNurse = (videoBooleanNurse: string) => {
   localStorage.setItem('videoBooleanNurse', videoBooleanNurse);
};
export const getVideoBooleanNurse = () => {
   const videoBooleanNurse = localStorage.getItem('videoBooleanNurse');
   return videoBooleanNurse;
};

export const setVideoBooleanPatOncDrive = (videoBooleanPatOncDrive: string) => {
   localStorage.setItem('videoBooleanPatOncDrive', videoBooleanPatOncDrive);
};
export const getVideoBooleanPatOncDrive = () => {
   const videoBooleanPatOncDrive = localStorage.getItem('videoBooleanPatOncDrive');
   return videoBooleanPatOncDrive;
};

export const setVideoBooleanPillReminder = (videoBooleanPillReminder: string) => {
   localStorage.setItem('videoBooleanPillReminder', videoBooleanPillReminder);
};
export const getVideoBooleanPillReminder = () => {
   const videoBooleanPillReminder = localStorage.getItem('videoBooleanPillReminder');
   return videoBooleanPillReminder;
};

export const setFeedMeditation = (feedMeditation: string) => {
   localStorage.setItem('feedMeditation', feedMeditation);
};
export const getFeedMeditation = () => {
   const feedMeditation = localStorage.getItem('feedMeditation');
   return feedMeditation;
};

export const setFeedMeditationDoNotShowAgain = (feedMeditationDoNotAgain: string) => {
   localStorage.setItem('feedMeditationDoNotAgain', feedMeditationDoNotAgain);
};
export const getFeedMeditationDoNotShowAgain = () => {
   const feedMeditationDoNotAgain = localStorage.getItem('feedMeditationDoNotAgain');
   return feedMeditationDoNotAgain;
};

export const setWithExpiry = (key: string, text: any, ttl: number, type: string) => {
   const now = new Date();
   const item = {
      text,
      expiry: now.getTime() + ttl,
      type,
   };
   localStorage.setItem(key, simpleStringify(item));
};

export const getWithExpiry = (key: string) => {
   const itemStr = localStorage.getItem(key);
   if (!itemStr) {
      return null;
   }
   const item = JSON.parse(itemStr);
   const now = new Date();
   if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
   }
   return item;
};

export const setSubscriptionSessionId = (subscriptionSessionId: string) => {
   localStorage.setItem('subscriptionSessionId', subscriptionSessionId);
};
export const getSubscriptionSessionId = () => {
   const subscriptionSessionId = localStorage.getItem('subscriptionSessionId');
   return subscriptionSessionId;
};
export const removeSubscriptionSessionId = () => {
   localStorage.removeItem('subscriptionSessionId');
};

export const setSubscriptionWalletEnable = (subscriptionWalletEnable: string) => {
   localStorage.setItem('subscriptionWalletEnable', subscriptionWalletEnable);
};
export const getSubscriptionWalletEnable = () => {
   const subscriptionWalletEnable = localStorage.getItem('subscriptionWalletEnable');
   return subscriptionWalletEnable;
};
export const removeSubscriptionWalletEnable = () => {
   localStorage.removeItem('subscriptionWalletEnable');
};

export const setFeedShareLinkSecretKey = (feedShareLinkSecretKey: any) => {
   localStorage.setItem('feedShareLinkSecretKey', simpleStringify(feedShareLinkSecretKey));
};

export const getFeedShareLinkSecretKey = () => {
   const feedShareLinkSecretKey = localStorage.getItem('feedShareLinkSecretKey');
   if (feedShareLinkSecretKey) {
      const item = JSON.parse(feedShareLinkSecretKey);
      return item;
   } else {
      return null;
   }
};
export const removeFeedShareLinkSecretKey = () => {
   localStorage.removeItem('feedShareLinkSecretKey');
};

export const setFeedShareLinkRoute = (feedShareLinkRoute: any) => {
   localStorage.setItem('feedShareLinkRoute', feedShareLinkRoute);
};
export const getFeedShareLinkRoute = () => {
   const feedShareLinkRoute = localStorage.getItem('feedShareLinkRoute');
   return feedShareLinkRoute;
};
export const removeFeedShareLinkRoute = () => {
   localStorage.removeItem('feedShareLinkRoute');
};

export const setEducationLibraryLinkRoute = (educationLibraryLinkRoute: any) => {
   localStorage.setItem('educationLibraryLinkRoute', educationLibraryLinkRoute);
};
export const getEducationLibraryLinkRoute = () => {
   const educationLibraryLinkRoute = localStorage.getItem('educationLibraryLinkRoute');
   return educationLibraryLinkRoute;
};
export const removeEducationLibraryLinkRoute = () => {
   localStorage.removeItem('educationLibraryLinkRoute');
};

// announcement
export const setAnnouncementImageData = (announcementImageData: any) => {
   const imageData = simpleStringify(announcementImageData);
   localStorage.setItem('announcementImageData', imageData);
};

export const getAnnouncementImageData = () => {
   const imageData = localStorage.getItem('announcementImageData');
   return imageData ? JSON.parse(imageData) : null;
};

export const removeAnnouncementImageData = () => {
   localStorage.removeItem('announcementImageData');
};

export const setAnnouncementImageWithTextData = (announcementImageWithTextData: any) => {
   const imageData = simpleStringify(announcementImageWithTextData);
   localStorage.setItem('announcementImageWithTextData', imageData);
};

export const getAnnouncementImageWithTextData = () => {
   const imageData = localStorage.getItem('announcementImageWithTextData');
   return imageData ? JSON.parse(imageData) : null;
};

export const removeAnnouncementImageWithTextData = () => {
   localStorage.removeItem('announcementImageWithTextData');
};

export const setAnnouncementTextData = (announcementTextData: any) => {
   const imageData = simpleStringify(announcementTextData);
   localStorage.setItem('announcementTextData', imageData);
};

export const getAnnouncementTextData = () => {
   const imageData = localStorage.getItem('announcementTextData');
   return imageData ? JSON.parse(imageData) : null;
};

export const removeAnnouncementTextData = () => {
   localStorage.removeItem('announcementTextData');
};

// announcement session storage
export const setAnnouncementImageDataSession = (announcementImageDataSession: any) => {
   sessionStorage.setItem('announcementImageDataSession', announcementImageDataSession);
};

export const getAnnouncementImageDataSession = () => {
   const imageData = sessionStorage.getItem('announcementImageDataSession');
   return imageData;
};

export const removeAnnouncementImageDataSession = () => {
   sessionStorage.removeItem('announcementImageDataSession');
};

export const setAnnouncementImageWithTextDataSession = (announcementImageWithTextDataSession: any) => {
   sessionStorage.setItem('announcementImageWithTextDataSession', announcementImageWithTextDataSession);
};

export const getAnnouncementImageWithTextDataSession = () => {
   const imageData = sessionStorage.getItem('announcementImageWithTextDataSession');
   return imageData;
};

export const removeAnnouncementImageWithTextDataSession = () => {
   sessionStorage.removeItem('announcementImageWithTextDataSession');
};

export const setAnnouncementTextDataSession = (announcementTextDataSession: any) => {
   sessionStorage.setItem('announcementTextDataSession', announcementTextDataSession);
};

export const getAnnouncementTextDataSession = () => {
   const imageData = sessionStorage.getItem('announcementTextDataSession');
   return imageData;
};

export const removeAnnouncementTextDataSession = () => {
   sessionStorage.removeItem('announcementTextDataSession');
};

export const setIsAlreadyOpenAnnouncement = (isAlreadyOpenAnnouncementData: any) => {
   sessionStorage.setItem('isAlreadyOpenAnnouncement', isAlreadyOpenAnnouncementData);
};

export const getIsAlreadyOpenAnnouncement = () => {
   return sessionStorage.getItem('isAlreadyOpenAnnouncement');
};

export const removeIsAlreadyOpenAnnouncement = () => {
   sessionStorage.removeItem('isAlreadyOpenAnnouncement');
};

export const setFirstTimeTour = (firstTimeTourData: any) => {
   localStorage.setItem('firstTimeTour', firstTimeTourData);
};

export const getFirstTimeTour = () => {
   return localStorage.getItem('firstTimeTour');
};

export const removeFirstTimeTour = () => {
   localStorage.removeItem('firstTimeTour');
};

export const setIsFirstTimeFillNowAndAskADocShow = (isFirstTimeFillNowAndAskADocShow: any) => {
   localStorage.setItem('isFirstTimeFillNowAndAskADocShow', isFirstTimeFillNowAndAskADocShow);
};

export const getIsFirstTimeFillNowAndAskADocShow = () => {
   return localStorage.getItem('isFirstTimeFillNowAndAskADocShow');
};

export const setEduLibOrgObj = (eduLibOrgObj: any) => {
   localStorage.setItem('eduLibOrgObj', eduLibOrgObj);
};

export const getEduLibOrgObj = () => {
   return localStorage.getItem('eduLibOrgObj');
};

export const removeEduLibOrgObj = () => {
   localStorage.removeItem('eduLibOrgObj');
};
