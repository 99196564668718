import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { ADD_SERVICE_OFFERED, GET_MASTER_SERVICE_OFFERED, GET_USER_SERVICE_OFFERED } from 'reduxToolKit/actions';
import { commonSlice, serviceOfferedSlice } from 'reduxToolKit/reducers';
import { myProfileReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   serviceOfferedAddRequest,
   serviceOfferedAddSuccess,
   serviceOfferedAddFailed,
   getMasterServiceOfferedRequest,
   getMasterServiceOfferedSuccess,
   getMasterServiceOfferedFailed,
   getUserServiceOfferedRequest,
   getUserServiceOfferedSuccess,
   getUserServiceOfferedFailed,
} = serviceOfferedSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const serviceOfferedAddSaga = function* (serviceOfferedAddPayload: any) {
   yield put(serviceOfferedAddRequest(serviceOfferedAddPayload.payload));
   try {
      const data = serviceOfferedAddPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         data,
         ...API.serviceOfferedAdd,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const serviceOfferedAddData = {
            message: responseData?.message || '',
         };
         yield put(serviceOfferedAddSuccess(serviceOfferedAddData));
      } else {
         yield put(serviceOfferedAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getMasterServiceOfferedSaga = function* (
   getMasterServiceOfferedPayload: PayloadAction<myProfileReducerTypes.IRaceReducer>
) {
   yield put(getMasterServiceOfferedRequest(getMasterServiceOfferedPayload.payload));
   try {
      const params = getMasterServiceOfferedPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         params,
         ...API.getMasterServiceOffered,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const getMasterServiceOfferedList = {
            list: responseData?.list || [],
            total_records: responseData?.total_records || 0,
            message: response?.data?.message || messages.success,
         };
         yield put(getMasterServiceOfferedSuccess(getMasterServiceOfferedList));
      } else {
         yield put(getMasterServiceOfferedFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const getUserServiceOfferedSaga = function* (
   getUserServiceOfferedPayload: PayloadAction<myProfileReducerTypes.IRaceReducer>
) {
   yield put(getUserServiceOfferedRequest(getUserServiceOfferedPayload.payload));
   try {
      const params = getUserServiceOfferedPayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser: true,
         peopleMayYouKnow: true,
         params,
         ...API.getUserServiceOffered,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const getUserServiceOfferedList = {
            list: responseData?.data?.data || [],
            total_records: responseData?.data?.total || 0,
            message: response?.data?.message || messages.success,
         };
         yield put(getUserServiceOfferedSuccess(getUserServiceOfferedList));
      } else {
         yield put(getUserServiceOfferedFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeServiceOffered = function* () {
   yield takeEvery(ADD_SERVICE_OFFERED, serviceOfferedAddSaga);
   yield takeEvery(GET_MASTER_SERVICE_OFFERED, getMasterServiceOfferedSaga);
   yield takeEvery(GET_USER_SERVICE_OFFERED, getUserServiceOfferedSaga);
};
