import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { PATIENT_ACCEPT_REQUEST } from 'reduxToolKit/actions';
import { acceptClinicReqPatSlice, commonSlice } from 'reduxToolKit/reducers';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   acceptClinicReqPatRequest,
   acceptClinicReqPatSuccess,
   acceptClinicReqPatFailed,
} = acceptClinicReqPatSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const addendumSaga = function* (payloadState: any) {
   yield put(acceptClinicReqPatRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.clinicJoinRequestByPatient });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
         };
         yield put(acceptClinicReqPatSuccess(resObj));
      } else {
         yield put(acceptClinicReqPatFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAcceptJoinClinicPat = function* () {
   yield takeEvery(PATIENT_ACCEPT_REQUEST, addendumSaga);
};
