import { createSlice } from '@reduxjs/toolkit';
import { appointmentDocumentReducerTypes, appointmentDocumentSagaTypes } from 'types';

const initialState: appointmentDocumentReducerTypes.IAppointmentDocumentReducer = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};

const initialStateUpdate: appointmentDocumentReducerTypes.IAppointmentDocumentUpdate = {
   isLoading: false,
   error: '',
   message: '',
   id: '',
   claim_form: null,
   isAppointmentDocUpdate: null,
};

const viewAppointmentDocumentSlice = createSlice({
   name: 'viewAppointmentDocument',
   initialState: initialState,
   reducers: {
      viewAppointmentDocStart: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      viewAppointmentDocSuccess: (state, action: appointmentDocumentSagaTypes.IAppointmentDocumentDataSuccess) => {
         return {
            ...state,
            data: action.payload,
            isLoading: false,
         };
      },
      viewAppointmentDocFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },
   },
});

const updateAppointmentDocumentSlice = createSlice({
   name: 'updateAppointmentDocument',
   initialState: initialStateUpdate,
   reducers: {
      updateAppointmentDocStart: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            isAppointmentDocUpdate: null,
         };
      },

      updateAppointmentDocSuccess: (state, action: appointmentDocumentSagaTypes.IAppointmentDocumentUpdateSuccess) => {
         return {
            ...state,
            message: action.payload.message,
            isLoading: false,
            isAppointmentDocUpdate: true,
         };
      },
      updateAppointmentDocFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: 'Something went wrong',
            error: action.payload.error,
            isAppointmentDocUpdate: false,
         };
      },
      resetDocUpdate: (state) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            isAppointmentDocUpdate: null,
         };
      },
   },
});

export { updateAppointmentDocumentSlice, viewAppointmentDocumentSlice };
