import { createSlice } from '@reduxjs/toolkit';
import { DrugLookUpReducerTypes } from 'types';

const initialState: DrugLookUpReducerTypes.IOneDrugReducerTypes = {
   oneDrugList: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      drug_url: [],
      name: '',
   },
   oneDrugReview: {
      isLoading: false,
      message: '',
      data: null,
      error: '',
   },
};

export const getOneDrugsListSlice = createSlice({
   name: 'getOneDrugsList',
   initialState: initialState,
   reducers: {
      getOneDrugsListViewRequest: (state: any, action: any) => {
         return {
            ...state,
            oneDrugList: {
               ...state.oneDrugList,
               ...action.payload,
               isLoading: true,
               list: action.payload.page && action.payload.page === 1 ? [] : state.list,
            },
         };
      },
      getOneDrugsListViewSuccess: (state: any, action: any) => {
         const { payload } = action;
         return {
            ...state,
            oneDrugList: {
               ...state.oneDrugList,
               list: payload.list,
               isLoading: false,
               name: payload.name,
               drug_url: payload.drug_url,
            },
         };
      },
      getOneDrugsListFailed: (state: any, action: any) => {
         return {
            ...state,
            oneDrugList: {
               ...state.oneDrugList,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },
      getDrugsListReset: (state: any) => {
         return {
            ...state,
            oneDrugList: {
               ...state.oneDrugList,
               ...initialState.oneDrugList,
            },
         };
      },

      getOneDrugsReviewRequest: (state: any, action: any) => {
         return {
            ...state,
            oneDrugReview: {
               ...state.oneDrugReview,
               ...action.payload,
               isLoading: true,
            },
         };
      },
      getOneDrugsReviewSuccess: (state: any, action: any) => {
         return {
            ...state,
            oneDrugReview: {
               ...state.oneDrugReview,
               ...action.payload,
               isLoading: false,
            },
         };
      },
      getOneDrugsReviewFailed: (state: any, action: any) => {
         return {
            ...state,
            oneDrugReview: {
               ...state.oneDrugReview,
               isLoading: false,
               error: action.payload.error,
               message: '',
            },
         };
      },

      getOneDrugsReviewReset: (state: any) => {
         return {
            ...state,
            oneDrugReview: {
               ...state.oneDrugReview,
               ...initialState.oneDrugReview,
            },
         };
      },
   },
});
