import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { caseDetailsReducerTypes, storeTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: caseDetailsReducerTypes.ICaseDetailsReducer = {
   isLoading: false,
   error: '',
   errorMessage: '',
   message: '',
   data: null,
   requestData: null,
};

export const askADocEstimateSlice = createSlice({
   name: 'askADocEstimate',
   initialState: initialState,
   reducers: {
      askADocEstimateRequest: (state, action: PayloadAction<caseDetailsReducerTypes.ICaseDetailsAddEstimate>) => {
         return {
            ...state,
            isLoading: true,
            ...action.payload,
         };
      },
      askADocEstimateCancelRequest: (
         state,
         action: PayloadAction<caseDetailsReducerTypes.ICaseDetailsCancelEstimate>
      ) => {
         return {
            ...state,
            isLoading: true,
            ...action.payload,
         };
      },
      askADocEstimatePaymentRequest: (
         state,
         action: PayloadAction<caseDetailsReducerTypes.ICaseDetailsAddEstimatePayment>
      ) => {
         return {
            ...state,
            isLoading: true,
            ...action.payload,
         };
      },
      askADocEstimateEndChatRequest: (
         state,
         action: PayloadAction<caseDetailsReducerTypes.ICaseDetailsCancelEstimate>
      ) => {
         return {
            ...state,
            isLoading: true,
            ...action.payload,
         };
      },
      askADocEstimateSuccess: (state, action: PayloadAction<caseDetailsReducerTypes.ICaseDetailsSuccessPayload>) => {
         const { payload } = action;
         return {
            ...state,
            data: payload?.data || null,
            requestData: payload?.requestData || null,
            message: payload?.message || messages.success,
            error: '',
            isLoading: false,
         };
      },
      askADocEstimateFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: action.payload.error || '',
            errorMessage: action.payload.error_message || '',
         };
      },
      askADocEstimateResetMessage: (state) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: '',
            errorMessage: '',
         };
      },
      askADocEstimateReset: () => {
         return { ...initialState };
      },
   },
});
