import { createSlice } from '@reduxjs/toolkit';
import { fillProfileReducerTypes } from 'types';

const initialState: fillProfileReducerTypes.IPhoneSectionReducerType = {
   isLoading: false,
   error: '',
   errorMessage: '',
   message: '',
   data: null,
   errors: null,
};
export const resendOtpSectionPostSlice = createSlice({
   name: 'resendOtpSectionSlice',
   initialState: initialState,
   reducers: {
      resendOtpSectionRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      resendOtpSectionSuccess: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            ...action.payload,
         };
      },
      resendOtpSectionFailed: (state, action) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload?.error,
            errorMessage: action.payload?.error,
            errors: action.payload?.errors,
         };
      },
      resendOtpSectionReset: (state) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            errorMessage: '',
            error: '',
            errors: null,
         };
      },
   },
});
