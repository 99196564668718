import React, { useEffect, useState } from 'react';

import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { DialogTitle, DialogActions, DialogContent } from 'component/shared/MuiWithStyle';
import { changeOneDateFormatToAnother } from 'utils/helpers/dateHelpers';
import { letterSpace } from 'utils/helpers/stringHelpers';
import { showDisplayName } from 'utils/helpers/userHelpers';

const AddCTHippoCompliance = (props: any) => {
   const [modalIsOpen, setModalIsOpen] = useState<boolean>(true);
   const [errorMessage, setErrorMessage] = useState<string>('');
   const [userName, setUserName] = useState<string>('');
   const [signature, setSignature] = useState<string>('');
   const [termsAgree, setTermsAgree] = useState<boolean>(false);

   useEffect(() => {
      const name = showDisplayName(props.userData, true);

      setUserName(name);
   }, []);

   const handleClose = (status: boolean) => {
      setModalIsOpen(false);
      props.openHippoCompliance(status);
   };

   /* getIp = async () => {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      this.setState(
         {
            ipAddress: data.ip,
         },
         () => {
            this.onClickSubmit();
         }
      );
   }; */

   const onClickSubmit = () => {
      if (signature !== '') {
         const data: any = {
            user_name: userName,
            signature: signature,
         };
         props.addCTHippaCompliance(data);
         handleClose(true);
      } else {
         setErrorMessage('Please enter signature');
      }
   };

   const onClickCancel = (status: boolean) => {
      handleClose(status);
   };

   const handleChangeTermsAgree = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTermsAgree(e.target.checked);
   };

   const onChangeSignature = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSignature(e.target.value);
      setErrorMessage('');
   };

   const host = window.location.host;
   const protocol = window.location.protocol;
   const hostUrl = protocol + '//' + host;
   return (
      <div className="pull-right" id="list">
         <Dialog
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            className="feed ask-doc__hippa"
            onClose={() => handleClose(false)}
            aria-labelledby="customized-dialog-title"
            open={modalIsOpen}
            fullWidth={true}
            maxWidth={'sm'}
         >
            <DialogTitle id="customized-dialog-title" onClose={() => handleClose(false)}>
               HIPAA and Privacy Waiver
            </DialogTitle>
            <DialogContent dividers className="comments">
               <Paper className="paper">
                  {/* <HippaContent /> */}
                  <iframe
                     title="video info"
                     style={{ width: '100%', height: '400px' }}
                     src={`${hostUrl}/onco-power-ct-hippa-content.html`}
                  ></iframe>
               </Paper>
            </DialogContent>
            <DialogActions>
               <Grid container spacing={3} className="m-2">
                  <div className="col-12 stage">
                     <FormControlLabel
                        control={
                           <Checkbox
                              color="primary"
                              checked={termsAgree}
                              onChange={handleChangeTermsAgree}
                              name="terms_agree"
                           />
                        }
                        label="I have read the above terms and agree."
                     />
                  </div>
                  <div className="col-12 text-center">
                     <div className="ocp-form">
                        <form className="form">
                           <div className="form-row mb-0">
                              <div className="col-12 flex">
                                 <div className="col-6">
                                    <input
                                       type="text"
                                       name="name"
                                       value={signature}
                                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                          onChangeSignature(e);
                                       }}
                                       className="form-control input-filed"
                                       onKeyPress={letterSpace}
                                       placeholder="Type Signature"
                                       autoComplete="off"
                                    />
                                 </div>
                                 <div className="col-6">
                                    <input
                                       type="text"
                                       name="name"
                                       disabled
                                       value={userName}
                                       className="form-control input-filed"
                                       onKeyPress={letterSpace}
                                       placeholder="Type Name"
                                       autoComplete="off"
                                    />
                                 </div>
                              </div>
                              <p className="ct-hippa-date mt-4">
                                 <span className="date-text">Date: </span>
                                 {changeOneDateFormatToAnother(new Date())}
                              </p>
                              {errorMessage ? <div className="errorMsg ml-3">{errorMessage || ''}</div> : null}
                           </div>
                        </form>
                     </div>
                  </div>
                  <div className="col-12 mt-2 mb-4">
                     <div className="book__appointment text-right">
                        <Button onClick={() => onClickCancel(false)} className="btn-cancel mr-3">
                           Cancel
                        </Button>
                        {termsAgree ? (
                           <Button
                              variant="contained"
                              color="primary"
                              onClick={onClickSubmit}
                              className="m-save-btn m-btn-p"
                           >
                              Submit
                           </Button>
                        ) : (
                           <Button variant="contained" color="primary" className="m-dim-btn">
                              Submit
                           </Button>
                        )}
                     </div>
                  </div>
               </Grid>
            </DialogActions>
         </Dialog>
      </div>
   );
};

export default AddCTHippoCompliance;
