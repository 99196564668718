import { createAction } from '@reduxjs/toolkit';
import { askADocViewAnswersReducerTypes } from 'types';

export const GET_VIEW_ANSWERS = 'GET_VIEW_ANSWERS';
export const askADocViewAnswersSagaAction = createAction<askADocViewAnswersReducerTypes.IViewAnswerPhysicianID>(
   GET_VIEW_ANSWERS
);

export const CASE_DETAILS_ADD_ANSWERS = 'CASE_DETAILS_ADD_ANSWERS';
export const addAnswersOfQuestions = createAction<askADocViewAnswersReducerTypes.IAddAnswerPayload>(
   CASE_DETAILS_ADD_ANSWERS
);
