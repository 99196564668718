import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { eventTrackAction, SAVE_UNSAVE_POST } from 'reduxToolKit/actions';
import { commonSlice, savedPostSlice } from 'reduxToolKit/reducers';
import { eventTrackTypes, feedSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { saveUnsaveAPostRequest, saveUnsavePostSuccess, saveUnsavePostFailure } = savedPostSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const saveUnsavePostSaga = function* (payloadState: PayloadAction<feedSagaTypes.ISaveUnSavePostEventSagaPayload>) {
   yield put(saveUnsaveAPostRequest(payloadState.payload));
   try {
      const customUrl = API.saveUnsaveAPost;
      const apiPath = customUrl.apiPath + payloadState.payload.postId;
      const action = customUrl.action;
      const checkUser = true;
      const goUrl = false;
      const response: AxiosResponse = yield apiCall({ goUrl, checkUser, ...{ apiPath, action } });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
            data: response.data?.data || null,
         };
         yield put(saveUnsavePostSuccess(responseData));
         if (payloadState.payload.event) {
            const eventData: eventTrackTypes.IEventTrack = {
               event: {
                  name: payloadState.payload.event.name,
               },
            };
            yield put(eventTrackAction(eventData));
         }
      } else {
         yield put(saveUnsavePostFailure(response.data));
      }
   } catch (err) {
      yield put(exceptionHandlerRequest(err));
   }
};

export const takeSaveUnsaveAPostSaga = function* () {
   yield takeEvery(SAVE_UNSAVE_POST, saveUnsavePostSaga);
};
