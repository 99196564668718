import { createSlice } from '@reduxjs/toolkit';
import { caregiverDetailsReducerTypes } from 'types';

const initialState: caregiverDetailsReducerTypes.IDeleteCaregiverReducer = {
   isLoading: false,
   error: '',
   message: '',
};

export const caregiverDeleteSlice = createSlice({
   name: 'caregiverDelete',
   initialState: initialState,
   reducers: {
      caregiverDeleteRequest: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: true,
         };
      },
      caregiverDeleteSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      caregiverDeleteFailed: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            isLoading: false,
            message: '',
            error: payload.error_message,
         };
      },
      caregiverDeleteReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
