import { createSlice } from '@reduxjs/toolkit';
import { myProfileReducerTypes } from 'types';

const initialState: myProfileReducerTypes.IDeleteCertificateReducer = {
   error: '',
   message: '',
   isLoading: false,
};

export const deleteCertificateSlice = createSlice({
   name: 'deleteCertificate',
   initialState: initialState,
   reducers: {
      deleteCertificateRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      deleteCertificateSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      deleteCertificateFailed: (state, action: any) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      deleteCertificateReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
