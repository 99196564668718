import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import Add from 'assets/alert/pillAddIcon.svg';
import { InfoTimerAlert } from 'component/shared/InfoTimerAlert';
import { useDispatch, useSelector } from 'react-redux';
import Select, { InputActionMeta, ValueType } from 'react-select';
import { pillRemainderAllPillsSagaAction } from 'reduxToolKit/actions';
import { getDrugsListSlice } from 'reduxToolKit/reducers';
import { pillRemainderPageTypes, storeTypes } from 'types';
import { CommonConstants } from 'utils/constants/common';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';

const PillNameAdd = (props: any) => {
   const dispatch = useDispatch();

   const [errorMessage, setErrorMessage] = useState('');
   const [searchWord, setSearchWord] = useState('');
   const [addSearchWord, setAddSearchWord] = useState('');
   const [changedDoseInformation, setChangedDoseInformation] = useState('');
   const [drugListDropdown, setDrugListDropdown] = useState<pillRemainderPageTypes.dropDownPropsName[]>([]);
   const [drugValue, setDrugValue] = useState<pillRemainderPageTypes.dropDownPropsName | null>(null);

   const { list: drugList, error: drugsError, isLoading: isLoadingDrugList } = useSelector(
      (state: storeTypes.IStore) => state.pillRemainderAllPillsList
   );
   const { getDrugsListReset } = getDrugsListSlice.actions;

   useEffect(() => {
      const drugListTemp: pillRemainderPageTypes.dropDownPropsName[] = [];
      const array = listParseAndStringify(drugList);
      if (array) {
         for (let i = 0; i < array.length; i++) {
            const obj = array[i];
            drugListTemp.push({
               title: obj?.brand_name || obj?.generic_name || '',
               label: obj?.brand_name || obj?.generic_name || '',
               value: String(obj?.id) || '',
               dose_information: obj.dose_information || '',
               unit: obj.unit || '',
               reminder_days: obj.reminder_days || 0,
            });
         }
      }
      setDrugListDropdown(drugListTemp);
   }, [drugList]);

   const searchName = (value: string, actionMeta: InputActionMeta) => {
      if (actionMeta?.action === 'input-change') {
         setSearchWord(value);
         setDrugValue(null);
         setAddSearchWord('');
         setChangedDoseInformation('');
         pillRemainderAllPillsDispatch(1, value);
      }
   };

   const clickAdd = () => {
      if (searchWord) {
         setErrorMessage('');
         setAddSearchWord(searchWord);
      } else {
         setAddSearchWord('');
         setErrorMessage('');
      }
   };

   const clickNext = useCallback(() => {
      if (!drugValue || !addSearchWord) {
         if (!drugValue && !addSearchWord) {
            setErrorMessage('Medication is required');
            return;
         } else if (!drugValue) {
            if (!addSearchWord) {
               setErrorMessage('Please select drug');
               return;
            }
         } else if (!addSearchWord) {
            if (!drugValue) {
               setErrorMessage('Please enter drug name and add your drug name');
               return;
            }
         }
         const obj = {
            name: addSearchWord ? addSearchWord : drugValue?.title ? drugValue?.title : '',
            drug_id: drugValue?.value ? drugValue?.value : '',
            dose_information: changedDoseInformation ? changedDoseInformation : '',
            unit: drugValue?.unit ? drugValue?.unit : '',
         };
         props.pillRemainderName(obj);
      } else {
         setErrorMessage('Please select drug or Please enter drug name and add your drug name');
      }
   }, [drugValue, addSearchWord, changedDoseInformation]);

   const pillRemainderAllPillsDispatch = (page: number, searchWord: string) => {
      const drugs = {
         search_word: searchWord,
         page: page,
         limit: CommonConstants.DropDownLimit,
      };
      dispatch(pillRemainderAllPillsSagaAction(drugs));
   };

   const hideAlert = (type: string) => {
      if (type === 'errorAlert') {
         dispatch(getDrugsListReset());
      }
   };

   useEffect(() => {
      pillRemainderAllPillsDispatch(1, searchWord);
      if (props?.name) {
         setDrugValue({
            title: props?.name?.name || '',
            label: props?.name?.name || '',
            value: props?.name?.drug_id ? String(props?.name?.drug_id) : '',
            dose_information: props?.name?.dose_information || '',
            unit: props?.name?.unit || '',
            reminder_days: props?.reminder_days || 0,
         });
         setChangedDoseInformation(props?.name?.dose_information || '');
      }
      return () => {
         dispatch(getDrugsListReset());
      };
   }, []);

   return (
      <Box>
         {drugsError && (
            <InfoTimerAlert
               error
               confirmBtnText={`Okay!`}
               confirmBtnBsStyle={`errorBtn`}
               title={drugsError}
               onConfirm={() => hideAlert('errorAlert')}
            />
         )}
         <Typography align="center" className="pill-add-text-select">
            What med would you like to add?
         </Typography>
         <Box className="pill-input-box-select" ml={searchWord ? 5 : 0}>
            <Select
               placeholder=""
               inputValue={searchWord}
               options={drugListDropdown}
               // menuPlacement="top"
               isLoading={isLoadingDrugList}
               loadingMessage={(obj: { inputValue: string }) => 'Loading...'}
               value={drugValue}
               onChange={(value: ValueType<pillRemainderPageTypes.dropDownPropsName, false>) => {
                  setDrugValue({
                     title: value?.title || '',
                     label: value?.label || '',
                     value: value?.value ? String(value?.value) : '',
                     dose_information: value?.dose_information || '',
                     unit: value?.unit || '',
                     reminder_days: value?.reminder_days || 0,
                  });

                  setChangedDoseInformation(value?.dose_information || '');
                  setSearchWord('');
                  setAddSearchWord('');
                  setErrorMessage('');
                  props.getRemainderDays(value?.reminder_days || 0);
               }}
               onInputChange={(value: string, actionMeta: InputActionMeta) => {
                  searchName(value, actionMeta);
               }}
            />
            {searchWord && (
               <Button
                  className={'pill-add-btn'}
                  onClick={() => {
                     clickAdd();
                  }}
               >
                  <img src={Add} alt="img" />
                  &nbsp; ADD
               </Button>
            )}
         </Box>

         {addSearchWord && (
            <div className="row">
               <div className="mt-2 pill-name-search">
                  <div>
                     <label className="pl-2 pr-2 mb-1 mt-1 ">{addSearchWord}</label>
                  </div>
                  <div>
                     <i
                        className="fa fa-close iconRound ml-2 mr-2"
                        onClick={() => {
                           setSearchWord('');
                           setAddSearchWord('');
                           pillRemainderAllPillsDispatch(1, '');
                        }}
                     />
                  </div>
               </div>
            </div>
         )}

         {errorMessage && <Box className="errorMsg pill-error">{errorMessage}</Box>}

         <Typography className="pill-dose-text">Dose info :</Typography>
         <Box width="70%" ml={1} mt={1} mx="auto">
            <TextField
               id="outlined-basic"
               // className="pill-input-box"
               className="pill-input"
               variant="outlined"
               fullWidth
               multiline
               rows={4}
               defaultValue="Default Value"
               value={changedDoseInformation}
               onChange={(e: any) => {
                  setChangedDoseInformation(e.target.value);
               }}
            />
         </Box>

         <Box mb={4} mx="auto" mt={drugValue ? 2 : 15}>
            <Grid container direction="row" spacing={3} justify="center">
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="pill-add-back-button"
                     fullWidth
                     onClick={props?.confirmToClose}
                  >
                     Back
                  </Button>
               </Grid>
               <Grid item>
                  <Button
                     autoFocus
                     variant="contained"
                     className="telemedicine__modal--btn"
                     fullWidth
                     onClick={clickNext}
                  >
                     Next
                  </Button>
               </Grid>
            </Grid>
         </Box>
      </Box>
   );
};

export { PillNameAdd };
