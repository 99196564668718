import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { PROFILE_COMPLETION_SECTION } from 'reduxToolKit/actions';
import { commonSlice, postShareSlice } from 'reduxToolKit/reducers';
import { postShareSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { postShareRequest, postShareSuccess, postShareFailed } = postShareSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const profileCompletion = function* (payloadState: postShareSagaTypes.IProfileCompletionDataPayload) {
   yield put(postShareRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.profileCompletion });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: null,
         };
         yield put(postShareSuccess(resObj));
      } else {
         yield put(postShareFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeProfileCompletionRequest = function* () {
   yield takeEvery(PROFILE_COMPLETION_SECTION, profileCompletion);
};
