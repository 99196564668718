import { createAction } from '@reduxjs/toolkit';
import { myProfileSagaTypes } from 'types';

export const ADD_POPULATION_SERVED = 'ADD_POPULATION_SERVED';
export const addPopulationServedAction = createAction<myProfileSagaTypes.IAddPopulationServedProps>(
   ADD_POPULATION_SERVED
);

export const GET_USER_POPULATION_SERVED = 'GET_USER_POPULATION_SERVED';
export const getUserPopulationServedAction = createAction<myProfileSagaTypes.IUserPopulationServedProps>(
   GET_USER_POPULATION_SERVED
);
