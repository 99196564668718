import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { postShareReducerTypes, postShareSagaTypes } from 'types';

const initialState: postShareReducerTypes.IPublicPostSuccessPayload = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};
const publicPostShareSlice = createSlice({
   name: 'publicPostShareData',
   initialState: initialState,
   reducers: {
      publicPostShareRequest: (state, action: PayloadAction<postShareSagaTypes.IPublicPostPayload>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      publicPostShareSuccess: (state, action: PayloadAction<postShareReducerTypes.ISharePostSuccessPayload>) => {
         return {
            ...state,
            isLoading: false,
            message: action.payload?.message,
            data: action.payload?.data,
         };
      },
      publicPostShareFailed: (state, action: PayloadAction<postShareReducerTypes.ISharePostFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            error: action.payload?.error,
            message: '',
         };
      },

      publicPostShareReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
export { publicPostShareSlice };
