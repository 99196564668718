import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { locumReducerTypes, locumSagaTypes } from 'types';

const initialState: locumReducerTypes.IAddUpdateLocumServiceReducer = {
   isLoading: false,
   error: '',
   error_message: '',
   message: '',
   data: null,
};

export const locumServiceDeleteSlice = createSlice({
   name: 'locumServiceDelete',
   initialState: initialState,
   reducers: {
      locumServiceDeleteRequest: (state, action: PayloadAction<locumSagaTypes.IGetOneLocumServiceSagaParams>) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: true,
         };
      },
      locumServiceDeleteSuccess: (state, action: PayloadAction<locumReducerTypes.IAddUpdateLocumServiceReducer>) => {
         const { payload } = action;
         return {
            ...state,
            ...payload,
            isLoading: false,
         };
      },
      locumServiceDeleteFailed: (state, action: PayloadAction<locumReducerTypes.IAddUpdateLocumServiceReducer>) => {
         const { payload } = action;
         return {
            ...state,
            data: null,
            isLoading: false,
            message: '',
            error: payload.error_message,
         };
      },
      locumServiceDeleteReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
