import oncoPowerHome from 'assets/askDoc/OncoNewIcon.svg';
import kovidPowerHome from 'assets/image/kovid_new.svg';
import oncoPowerLogo from 'assets/image/oncopower-logo.png';
import kovidPowerLogo from 'assets/image/symptomExpress.svg';
import environment from 'environment';

const isKovidPower = environment.isKovidPower;
const siteName = isKovidPower ? 'SymptomXpress' : 'OncoPower';
const siteLogo = isKovidPower ? kovidPowerLogo : oncoPowerLogo;
const homeLogo = isKovidPower ? kovidPowerHome : oncoPowerHome;
const isKovid = isKovidPower ? true : false;
const menuVisible = isKovidPower ? false : true;

const symptomNormalizedSize = 250;

export default {
   DATE_FORMAT: 'MM/DD/YYYY',
   TIME_FORMAT: 'hh:mm A',
   CHAT_DATE_AND_TIME_FORMAT: 'MMM DD, hh:mm A',
   DOCUMENTATION__DATE_FORMAT: 'MMM DD, YYYY',
   PATIENT: 'patient',
   ADMIN: 'admin_user',
   PHYSICIAN: 'physician',
   NURSE: 'nurse',
   PRACTISE_ADMIN: 'practice_administrator',
   API_PRACTICE_ADMIN: 'practice-admin',
   AUXILIARY_STAFF: 'auxiliary_staff',
   RECRUITER: 'recruiter',
   CAREGIVER: 'patient_caregiver',
   PATIENT_ADVOCATE: 'patient_advocate',
   ORGANIZATION: 'organization',
   ORGANIZATION_API: 'organization-user',
   SOURCE_KOVIDPOWER: 'kovidpower',
   SOURCE_ONCOPOWER: 'oncopower',
   IS_PHYSICIAN_ADMIN: true,
   siteName: siteName,
   siteLogo: siteLogo,
   homeLogo: homeLogo,
   menuVisible: menuVisible,
   isKovid: isKovid,
   notVerifiedHeader: 'Your account is under verification,',
   notVerifiedContent: 'We will reach out to you soon with email notification',
   notVerifiedAdminHeader: 'We apologize.',
   notVerifiedAdminHeader2: ' Your account is under verification.',
   notVerifiedAdminContent:
      'OncoPower is a clinical care platform and we are required to verify the credentials of providers. We will send you an email notification shortly. Thank you for your patience',
   nonUSPhysicians:
      'Please enter NPI number. For non-US physicians, please email registration document to support@oncopower.org',
   notVerifiedAdminContentVerify: 'You can speed up the verification process by',
   webPushCertificates: 'BOdWyy_BU6f4FyjTzzzi-IORVZ3A2_9UwR4oKWbvuMguuUQM2cOpOPLvO4vSgyCKXEYLGIAQsxdQl-hFPHzW0ws',
   initializeAppFcm: {
      apiKey: 'AIzaSyCpZENbIYnGUkYvqdOdmEFBjH6Na5VrVAw',
      authDomain: 'oncopower-82244.firebaseapp.com',
      databaseURL: 'https://oncopower-82244.firebaseio.com',
      projectId: 'oncopower-82244',
      storageBucket: 'oncopower-82244.appspot.com',
      messagingSenderId: '703866654598',
      appId: '1:703866654598:web:72d3f3429145a17a29a607',
      measurementId: 'G-JH00N2M8R2',
   },
   fileType: 'File not supported',
   symptomNormalizedSize: symptomNormalizedSize,
   askADocPanelGroupId: '1',
   infectionAskADocPanelGroupId: '2',
   LINKEDIN_CLIENT_ID: '81g8uskh3uxani',
   REDIRECT_URI: window.location.origin + '/linkedin',
   doximityClientId: '409a6aca17339327bf23fa23a754d3285a26fa49bfb956a459a737a1e6f48d38',
   doximityRedirectUrl: window.location.origin + '/auth/doximity/callback',
   Apple_Client_ID: 'com.oncopower.oncopowerapp',
   Apple_Service_ID: 'com.oncopower.oncopowerapp.apple.login',
   apple_redirectURI: window.location.origin + '/auth/apple/callback',
   GOOGLE_CLIENT_ID: '703866654598-pj1nrossa1vbjarj2pcsjkihbpibundu.apps.googleusercontent.com',
   AskADoc: 'Ask-a-Doc',
   TreatmentEstimate: 'Treatment Request',
   OncoKlinic: 'oncklinic',
   OncKlinic: 'oncKlinic',
   BloodPressure: 1,
   Weight: 2,
   Oximeter: 3,
   Thermometer: 4,
   EKG: 5,
   FROM_FEED: 'feed-fill-profile',
   FROM_SECOND_OPINION: 'second-fill-profile',
   FROM_PHY_SECOND_OPINION: 'from-second-opinion',
   FROM_PHY_ASK_DOC: 'from-ask-a-doc',
   FROM_PHY_MY_SERVICE: 'from-my-service',
   FROM_MY_SERVICE: 'country-list',
   FROM_FEED_UPDATE_PROFILE: 'from-feed-update-profile',
   FROM_USER_UPDATE_PROFILE: 'from-user-update-profile',
   BUGSNAG_API_KEY: 'd783d80146ce8f7b2d5648ffe83cd8d1',
   BUGSNAG_ENABLED_DOMAINS: ['dev.oncopower.org', 'app.oncopower.org'],
   MIN_SYSTOLIC: 30,
   MAX_SYSTOLIC: 250,
   MIN_DIASTOLIC: 30,
   MAX_DIASTOLIC: 250,
   MIN_PULSE: 0,
   MAX_PULSE: 250,

   MIN_WEIGHT: 0,
   MAX_WEIGHT: 999,

   MIN_TEMPERATURE: 70,
   MAX_TEMPERATURE: 120,

   MIN_OXYZEN: 0,
   MAX_OXYZEN: 100,
   TIME_OUT_MIN: 5,
   IS_ASK_A_DOC_PRO_ENABLED: true,
   STRIPE_PUBLISHABLE_KEY:
      'pk_live_51H9u3eJRwSivGAPExWjvcDIHCWXZOuExz98jmSl9sy9dUGMWdfwvIwQxbtfKrOZ8mdWhbzNXsgKvWxW9m0JFdpNK00aK6kylGj',
   MY_CONVERSATION_ONE_TO_ONE: 'one_to_one',
   MY_CONVERSATION_ASK_A_DOC: 'ask_a_doc',
   MY_CONVERSATION_ASK_A_DOC_ESTIMATE: 'ask_a_doc_estimate',
   MY_CONVERSATION_ASK_A_DOC_SUBSCRIPTION: 'ask_a_doc_subscription',
   COMPLETE_PROFILE: 'Please complete your basic profile before starting the Second Opinion request.',
   CHOOSE_DISPLAY_NAME:
      "You can choose to add a Display name in case you don't want to show your actual name in the application.",
   webSocketUrl: 'wss://socket-prod.oncopower.org/?Auth=',
   urlSecondOpinion: 'second-opinion',
   SecondOpinion: 'Second Opinion',
   ONCDRIVE: 'OncDrive',
   patientSideConsultationReport: 'We will notify you once the consultation report is ready.',
   physicianSideConsultationReport: 'Need to add summary.',
   facebookPixelId: '856938211160769',
   VIEW_TUTORIAL_VIDEO: 'storage/e0e01c22-5f09-45d0-b67e-16e4ebd361d9.mp4',
   VIEW_TUTORIAL_VIDEO_THUMBNAIL: 'thumbnail/storage/e0e01c22-5f09-45d0-b67e-16e4ebd361d9.jpg',
   DEVICES_READING_REMAINDER: 'Please login into the mobile app to report the following device readings today.',
   ASK_A_DOC_VIEW_TUTORIAL_VIDEO: 'storage/27238c14-3403-4ffb-b30a-ea4ff1f50aa1.mp4',
   ASK_A_DOC_VIEW_TUTORIAL_VIDEO_THUMBNAIL: 'thumbnail/storage/27238c14-3403-4ffb-b30a-ea4ff1f50aa1.jpg',
   ASK_A_DOC_PHYSICIAN_VIEW_TUTORIAL_VIDEO: 'storage/ff5c91ad-947a-448e-9de6-d478b474545a.mp4',
   ASK_A_DOC_PHYSICIAN_VIEW_TUTORIAL_VIDEO_THUMBNAIL: 'thumbnail/storage/ff5c91ad-947a-448e-9de6-d478b474545a.jpg',
   SECOND_OPINION_VIEW_TUTORIAL_VIDEO: 'storage/4aec60b5-b2f9-40c5-8199-9804c6c45ab5.mp4',
   SECOND_OPINION_VIEW_TUTORIAL_VIDEO_THUMBNAIL: 'thumbnail/storage/4aec60b5-b2f9-40c5-8199-9804c6c45ab5.jpg',
   ASK_A_DOC_VIEW_TUTORIAL_VIDEO_TEXT: 'Watch the video to know how Ask-a-Doc works.',
   ASK_A_DOC_PHYSICIAN_VIEW_TUTORIAL_VIDEO_TEXT: 'Watch the video to know how Ask-a-Doc works.',
   SECOND_OPINION_VIEW_TUTORIAL_VIDEO_TEXT: 'Watch the video to know how OncoPower Expert Opinions works.',
   ONCOKLINIC_VIEW_TUTORIAL_VIDEO_TEXT: 'Watch the video to know how Remote Patient Monitoring(RPM) works.',
   VIEW_TUTORIAL_VIDEO_NURSE: 'https://www.youtube.com/watch?v=VOuFNiYo0ns',
   VIEW_TUTORIAL_VIDEO_NURSE_TEXT: 'Watch the video to know how Clinical Care works on OncoPower.',
   TELE_MEDICINE_PATIENTS_NOTES_NO_DATA: 'Your physician will add notes once the call is completed.',
   SECOND_OPINION_PATIENT_VIEW_TUTORIAL_VIDEO: 'storage/194313a2-045f-404d-9d77-dcaf9867c7c1.mp4',
   SECOND_OPINION_PATIENT_VIEW_TUTORIAL_VIDEO_THUMBNAIL: 'thumbnail/storage/194313a2-045f-404d-9d77-dcaf9867c7c1.jpg',
   CHOOSE_COUNTRY: 'Update your country to proceed',
   ONC_DRIVE_PATIENT_VIEW_TUTORIAL_VIDEO: 'storage/cb0cd2dd-4710-4312-9945-db3eb5e78e41.mp4',
   ONC_DRIVE_PATIENT_VIEW_TUTORIAL_VIDEO_THUMBNAIL: 'thumbnail/storage/cb0cd2dd-4710-4312-9945-db3eb5e78e41.jpg',
   ONC_DRIVE_VIEW_TUTORIAL_VIDEO_TEXT: 'Watch the video to know how to store your health records in OncDrive',
   SECOND_OPINION_PHYSICIAN_VIEW_TUTORIAL_VIDEO_TEXT: 'Watch the video to know how OncoPower Expert Opinions works.',
   PILL_REMINDER_PATIENT_VIEW_TUTORIAL_VIDEO: 'storage/eaa53f0d-24a4-4e14-b8c9-8004a791fc3b.mp4',
   PILL_REMINDER_PATIENT_VIEW_TUTORIAL_VIDEO_THUMBNAIL: 'thumbnail/storage/eaa53f0d-24a4-4e14-b8c9-8004a791fc3b.jpg',
   PILL_REMINDER_VIEW_TUTORIAL_VIDEO_TEXT:
      'Watch video to know how to set reminders to make sure you take your medicines on time',
   FEED_MEDITATION_AUDIO: 'storage/66ce6498-1c41-46ba-a568-ee3dd1154db2.mp3',
   SHOW_DISPLAY_NAME_INSIDE: 'original_name',
   SHOW_DISPLAY_NAME_OUTSIDE: 'display_name',
   SUCCESS: 'success',
   FAILURE: 'failure',
   // TODO: Development app id
   FACEBOOK_APP_ID: '570926150878399',
   //TODO : google analytics
   GOOGLE_ANALYTICS_SCRIPT: 'https://www.googletagmanager.com/gtag/js',
   GOOGLE_ANALYTICS_TRACKING_ID: 'G-6GSPR6KHRT',
   // TODO: Google Analytics with oncKlinic url filter
   GOOGLE_ANALYTICS_TRACKING_ID_PRIMARY: 'G-49LBG45N9J',
   GOOGLE_ANALYTICS_TRACKING_FILTER_ONCKLINIC: 'G-8MRCCN9YWZ',
   // Fingerprint
   FINGER_PRINT_API_KEY: 'knGpfPWCI68WqZfSi7FV',
   FINGER_PRINT_END_POINT: 'https://fp.oncopower.org',
   FINGER_PRINT_CACHE_LOCATION: 'sessionstorage',
};
