import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { delay, put, takeLatest } from 'redux-saga/effects';
import { COMMENTS_REACTIONS_LIST } from 'reduxToolKit/actions';
import { commentsReactionsListSlice, commonSlice } from 'reduxToolKit/reducers';
import { feedSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   commentsReactionsListLoading,
   commentsReactionsListViewRequest,
   commentsReactionsListViewSuccess,
   commentsReactionsListFailed,
} = commentsReactionsListSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const commentsReactionsListSaga = function* (cancerTypePayload: PayloadAction<feedSagaTypes.ICommentsReactionsList>) {
   yield put(commentsReactionsListLoading());
   yield delay(500);
   yield put(commentsReactionsListViewRequest(cancerTypePayload.payload));
   try {
      const peopleMayYouKnow = true;
      const params = cancerTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ peopleMayYouKnow, params, ...API.commentsReactionsList });
      if (response.status === 200) {
         const responseData = response.data;
         const oncDriveList = {
            list: responseData.data.list,
            total_records: responseData.data.total_records,
            message: response.data?.message,
         };
         yield put(commentsReactionsListViewSuccess(oncDriveList));
      } else {
         yield put(commentsReactionsListFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takecommentsReactionsListRequest = function* () {
   yield takeLatest(COMMENTS_REACTIONS_LIST, commentsReactionsListSaga);
};
