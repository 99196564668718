import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { S3_GET_SIGNED_URL, S3_DELETE_FILE } from 'reduxToolKit/actions';
import { commonSlice, s3FileUploadSlice } from 'reduxToolKit/reducers';
import { s3UploadReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getS3SignedURLRequest,
   getS3SignedURLSuccess,
   getS3SignedURLFailed,
   s3DeleteFileKeyRequest,
   s3DeleteFileKeySuccess,
   s3DeleteFileKeyFailed,
} = s3FileUploadSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getS3SignedURLSaga = function* (payloadState: PayloadAction<s3UploadReducerTypes.IGetS3SignedURL>) {
   yield put(getS3SignedURLRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getS3SignedURL });
      if (response.status === 201 || response.status === 200) {
         const responseData = {
            data: response.data?.data || null,
            message: response.data?.message || messages.success,
         };
         yield put(getS3SignedURLSuccess(responseData));
      } else {
         yield put(getS3SignedURLFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

const s3DeleteFile = function* (PayloadState: PayloadAction<s3UploadReducerTypes.IS3DeleteFileKey>) {
   yield put(s3DeleteFileKeyRequest(PayloadState.payload));
   try {
      const checkUser = true;
      const data = {
         media: PayloadState.payload?.media,
      };
      const response: AxiosResponse = yield apiCall({ checkUser, data, ...API.deleteS3FileKey });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(s3DeleteFileKeySuccess(responseData));
      } else {
         yield put(s3DeleteFileKeyFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeGetS3SignedURLSaga = function* () {
   yield takeEvery(S3_GET_SIGNED_URL, getS3SignedURLSaga);
   yield takeEvery(S3_DELETE_FILE, s3DeleteFile);
};
