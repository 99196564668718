import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Constant from 'config/Constant';
import UrlConfig from 'config/UrlConfig';
import environment from 'environment';
import { store } from 'reduxToolKit';
import { commonSlice } from 'reduxToolKit/reducers';
import { commonApiTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { getAuth, getUser, logout, removeToken, setAuthRefreshToken } from 'utils/helpers/authHeader';
import {
   isAuxiliaryStaff,
   isNurse,
   isOrganization,
   isPCAUser,
   isPhysician,
   isPractiseAdmin,
   isRecruiter,
} from 'utils/helpers/userHelpers/userTypeHelpers';

import { getUnixMilliSeconds } from './dateHelpers';

const { exceptionHandlerRequest } = commonSlice.actions;
// const dispatch = store.dispatch;
export const apiCall = async (apiConfig: commonApiTypes.ApiConfig, promisePayload?: any): Promise<any> => {
   try {
      // const baseURL = config.apiBaseURL;
      let baseURL = '';
      if (apiConfig.goUrl) {
         baseURL = environment.baseUrl;
      } else {
         baseURL = environment.baseUrlNew;
      }

      let userUrl = '';
      if (!apiConfig.checkUser) {
         if (isPCAUser(getUser())) {
            userUrl = `/api/v1/${Constant.PATIENT}`;
         } else if (isPhysician(getUser())) {
            userUrl = `/api/v1/${Constant.PHYSICIAN}`;
         } else if (isNurse(getUser())) {
            userUrl = `/api/v1/${Constant.NURSE}`;
         } else if (isPractiseAdmin(getUser())) {
            userUrl = `/api/v1/${Constant.API_PRACTICE_ADMIN}`;
         } else if (isAuxiliaryStaff(getUser())) {
            userUrl = `/api/v1/${Constant.AUXILIARY_STAFF}`;
         } else if (isRecruiter(getUser())) {
            userUrl = `/api/v1/${Constant.RECRUITER}`;
         } else if (isOrganization(getUser())) {
            userUrl = `/api/v1/${Constant.ORGANIZATION_API}`;
         }
      }
      // const url = apiConfig.checkUser ? `${baseURL}${apiConfig.apiPath}` : `${baseURL}${userUrl}${apiConfig.apiPath}`;
      let peopleUrl = '';
      if (apiConfig.peopleMayYouKnow) {
         peopleUrl = `/api`;
      }
      const url = apiConfig.checkUser
         ? apiConfig.peopleMayYouKnow
            ? `${baseURL}${peopleUrl}${apiConfig.apiPath}`
            : `${baseURL}${apiConfig.apiPath}`
         : apiConfig.peopleMayYouKnow
         ? `${baseURL}${peopleUrl}${apiConfig.apiPath}`
         : `${baseURL}${userUrl}${apiConfig.apiPath}`;

      const requestConfig: AxiosRequestConfig = {
         url,
         method: apiConfig.action,
         data: apiConfig.data,
         headers: apiConfig.headers ? apiConfig.headers : apiConfig.unAuthorization ? unAuthHeader() : defaultHeader(),
         params: apiConfig.params,
         timeout: 0,
         validateStatus(status: number) {
            //If user un authorized
            if (status === 401) {
               if (getUnixMilliSeconds() >= parseInt(getAuth()?.tokenExp || '0')) {
                  logOut();
               } else {
                  logout();
                  window.location.href = '/';
               }
            }
            return status >= 200 && status <= 500;
         },
      };
      if (apiConfig.cancelToken) {
         requestConfig.cancelToken = apiConfig.cancelToken;
      }
      const response = await axios(requestConfig)
         .then((res: AxiosResponse<any>) => {
            if (res.status === 500) {
               store.dispatch(exceptionHandlerRequest(messages.internal_error));
            } else if (res.status === 404) {
               res.data = {
                  error: messages.errorMessage,
               };
            }
            if (res.status !== 200 && res.status !== 201) {
               if (res.data?.error || res.data?.error_message || res.data?.errors) {
                  if (!res.data?.error) {
                     res.data.error = returnError(res);
                  }
               }
            }
            return {
               ...res,
               ...(promisePayload && { promisePayload }),
            };
         })
         .catch((error: AxiosError<any>) => {
            return error;
            //Todo API Errors handle
         });
      return response;
   } catch (ex) {
      // return false;
      logout();
      window.location.href = '/';
   }
};
export const logOut = () => {
   if (getAuth().authToken === null || getAuth().refreshToken === null) {
      logout();
      window.location.href = '/';
      return false;
   }
   const refreshToken = getAuth().refreshToken;
   return axios
      .post(
         UrlConfig.baseURL + '/refresh-token',
         {},
         {
            headers: {
               'Content-Type': 'application/json',
               Authorization: `Bearer ${refreshToken}`,
            },
         }
      )
      .then((res) => {
         if (res.status === 200) {
            removeToken();
            setAuthRefreshToken(res.data.data);
            return true;
         }
      })
      .then((res) => {
         if (res) {
            window.location.reload();
         }
      })
      .catch(() => {
         logout();
         window.location.href = '/';
      });
};
export const defaultHeader = () => {
   const headers: commonApiTypes.IDefaultHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuth().authToken,
      'device-type': 'web',
   };
   return headers;
};
export const unAuthHeader = () => {
   const headers: commonApiTypes.IUnAuthHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'device-type': 'web',
   };
   return headers;
};
const returnError = (response: any) => {
   return response.data?.error_message || response.data?.error || messages.error;
};
