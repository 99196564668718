import { createAction } from '@reduxjs/toolkit';
import { supportGroupsSagaTypes } from 'types';

export const SUPPORT_GROUPS_INFO = 'SUPPORT_GROUPS_INFO';
export const supportGroupsInfoAction = createAction<supportGroupsSagaTypes.IGroupInfoSagaTypes>(SUPPORT_GROUPS_INFO);

export const SUPPORT_GRP_LEAVE_GROUP = 'SUPPORT_GRP_LEAVE_GROUP';
export const leaveGroupAction = createAction<supportGroupsSagaTypes.IGroupInfoSagaTypes>(SUPPORT_GRP_LEAVE_GROUP);

export const SUPPORT_GRP_MEMBERS_LIST = 'SUPPORT_GRP_MEMBERS_LIST';
export const supportGrpMembersList = createAction<supportGroupsSagaTypes.IMembersList>(SUPPORT_GRP_MEMBERS_LIST);
