import { createSlice } from '@reduxjs/toolkit';
import { myProfileReducerTypes } from 'types';

const initialState: myProfileReducerTypes.IDiagnosisReducer = {
   error: '',
   message: '',
   isLoading: false,
};

export const latinoSlice = createSlice({
   name: 'latino',
   initialState: initialState,
   reducers: {
      latinoRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      latinoSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      latinoFailed: (state, action: any) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      latinoReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
