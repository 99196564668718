import { createSlice } from '@reduxjs/toolkit';
import { healthRecordsReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: healthRecordsReducerTypes.IClinicVideoTypeListReducer = {
   isLoading: false,
   list: [],
   error: '',
   message: '',
};
export const patientMedicationsSlice = createSlice({
   name: 'patientMedicationList',
   initialState: initialState,
   reducers: {
      patientMedicationLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      patientMedicationRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: state.list,
         };
      },
      patientMedicationSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            message: messages.success,
            list: payload,
            isLoading: false,
         };
      },
      patientMedicationFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            error: action.payload.error,
         };
      },
      patientMedicationReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
export const lineOfTherapySlice = createSlice({
   name: 'lineOfTherapyList',
   initialState: initialState,
   reducers: {
      lineOfTherapyLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      lineOfTherapyRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: state.list,
         };
      },
      lineOfTherapySuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            message: messages.success,
            list: payload,
            isLoading: false,
         };
      },
      lineOfTherapyFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            error: action.payload.error,
         };
      },
      lineOfTherapyReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
export const coMorbiditiesSlice = createSlice({
   name: 'coMorbiditiesList',
   initialState: initialState,
   reducers: {
      coMorbiditiesLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      coMorbiditiesRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: state.list,
         };
      },
      coMorbiditiesSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            list: payload,
            isLoading: false,
            message: payload.message || messages.success,
         };
      },
      coMorbiditiesFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            error: action.payload.error,
         };
      },
      coMorbiditiesResetMessage: (state) => {
         return {
            ...state,
            isLoading: false,
            message: '',
         };
      },
      coMorbiditiesReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
export const patientMeditationRpmDeviceSlice = createSlice({
   name: 'meditationRpmDeviceList',
   initialState: initialState,
   reducers: {
      meditationRpmDeviceLoading: (state) => {
         return {
            ...state,
            isLoading: false,
         };
      },
      meditationRpmDeviceRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
            list: state.list,
         };
      },
      meditationRpmDeviceSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            message: messages.success,
            list: payload,
            isLoading: false,
         };
      },
      meditationRpmDeviceFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            error: action.payload.error,
         };
      },
      meditationRpmDeviceReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
export const meditationSlice = createSlice({
   name: 'meditationList',
   initialState: initialState,
   reducers: {
      meditationListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      meditationListRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: state.list,
         };
      },
      meditationListSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message || messages.success,
            list: payload,
            isLoading: false,
         };
      },
      meditationListFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            error: action.payload.error,
         };
      },
      meditationListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
export const morbiditiesSlice = createSlice({
   name: 'morbiditiesList',
   initialState: initialState,
   reducers: {
      morbiditiesListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      morbiditiesListRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: state.list,
         };
      },
      morbiditiesListSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            message: messages.success,
            list: payload,
            isLoading: false,
         };
      },
      morbiditiesListFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            error: action.payload.error,
         };
      },
      morbiditiesListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
export const getDeviceSlice = createSlice({
   name: 'devicesList',
   initialState: initialState,
   reducers: {
      devicesListLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      devicesListRequest: (state, action) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
            list: state.list,
         };
      },
      devicesListSuccess: (state, action: any) => {
         const { payload } = action;
         return {
            ...state,
            message: messages.success,
            list: payload,
            isLoading: false,
         };
      },
      devicesListFailed: (state, action: any) => {
         return {
            ...state,
            isLoading: false,
            error: action.payload.error,
         };
      },
      devicesListReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
