import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Card, Container, Grid, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import SearchIcon from 'assets/askDoc/searchIconHeader.svg';
import ProfileLayoutModal from 'component/fillProfile/ProfileLayout';
import QueryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
   cancerTypeSagaAction,
   clinicalTrialsCancerSpecificSagaAction,
   clinicalTrialsInterestedListSagaAction,
   // clinicalTrialsListCountSagaAction, // TODO: temporarily hide this item
   clinicalTrialsListItemGetOneSagaAction,
   clinicalTrialsListSagaAction,
   clinicalTrialsStatesSagaAction,
   clinicalTrialsStudyPhaseSagaAction,
   clinicalTrialsStudyTypeSagaAction,
   fillProfileAction,
   userGetCountriesAction,
} from 'reduxToolKit/actions';
import {
   clinicalTrialsCancerSpecificSlice,
   clinicalTrialsSlice,
   clinicalTrialsStatesSlice,
   populationServedSlice,
   serviceOfferedSlice,
   userDetailsSlice,
} from 'reduxToolKit/reducers';
import { clinicalTrialsPageTypes, myProfileSagaTypes, storeTypes, userInviteEmailPageTypes } from 'types';
import { CommonConstants, SET_TIME_OUT } from 'utils/constants/common';
import { addUserObject, getUser, removeEducationLibraryLinkRoute } from 'utils/helpers/authHeader';
import {
   getClinicalTrailsDefaultStudyPhase,
   getClinicalTrialsAge,
   getClinicalTrialsCancerSpecific,
   getClinicalTrialsCancerType,
   getClinicalTrialsCancerTypeIds,
   getClinicalTrialsCountries,
   getClinicalTrialsCountriesIds,
   getClinicalTrialsGender,
   getClinicalTrialsState,
} from 'utils/helpers/clinicalTrialsHelpers';
import { listParseAndStringify } from 'utils/helpers/commonHelpers';
import { simpleDecode } from 'utils/helpers/encryptHelpers';
import { isOrganization, isPCAUser } from 'utils/helpers/userHelpers/userTypeHelpers';
import {
   isAddValidation,
   isCancerStatusValidation,
   isDiagnosisDateValidation,
   isDisplayName,
   isDobValidation,
   isLatinoValidation,
   isOrgNameValidation,
   isPhoneValidation,
   isPrimaryCancer,
   isRaceValidation,
   isStagingValidation,
} from 'utils/helpers/validationHelpers';

import ClinicalTrialsCard from './ClinicalTrialsCard';
import ClinicalTrialsInterestedCard from './ClinicalTrialsInterestedCard';
import ClinicFilter from './ClinicFilter';

const ClinicalTrials = memo(() => {
   const dispatch = useDispatch();
   const location = useLocation();

   const [finalData, setFinalData] = useState<clinicalTrialsPageTypes.IClinicalTrialsListData>({
      status: [],
      age: [],
      gender: [],
      cancer_type: [],
      cancer_specific: [],
      search_word: '',
      study_type: [],
      study_phase: [],
      countries: [],
      state: [],
   });

   const [isProfileLayoutModal, setIsProfileLayoutModal] = useState<boolean>(false);
   const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
   const [isDobValid, setIsDobValid] = useState<boolean>(false);
   const [isAddressValid, setIsAddressValid] = useState<boolean>(false);
   const [isDisplayNameValid, setIsDisplayNameValid] = useState<boolean>(false);
   const [isPrimaryCancerValid, setIsPrimaryCancerValid] = useState<boolean>(false);
   const [isRaceValid, setIsRaceValid] = useState<boolean>(false);
   const [isStagingValid, setIsStagingValid] = useState<boolean>(false);
   const [isDiagnosisDateValid, setIsDiagnosisDateValid] = useState<boolean>(false);
   const [isLatinoValid, setIsLatinoValid] = useState<boolean>(false);
   const [isCancerStatusValid, setIsCancerStatusValid] = useState<boolean>(false);
   const [isServiceOfferedValid, setIsServiceOfferedValid] = useState(false);
   const [isOrgNameValid, setIsOrgNameValid] = useState(false);
   const [isPopulationServedValid, setIsPopulationServedValid] = useState(false);
   const [activeTab, setActiveTab] = useState<string>('all');
   const [searchWord, setSearchWord] = useState<string>('');
   const [countriesIds, setCountriesIds] = useState<number[]>(getClinicalTrialsCountriesIds(getUser()) || []);
   const [cancerTypeIds, setCancerTypeIds] = useState<number[]>(getClinicalTrialsCancerTypeIds(getUser()) || []);

   const {
      clinicalTrialsListReset,
      clinicalTrialsListCountReset,
      clinicalTrialsInterestedListReset,
   } = clinicalTrialsSlice.actions;

   const { isFrom, userDetail }: { isFrom?: any; userDetail: any } = useSelector(
      (state: storeTypes.IStore) => state.userDetail
   );
   const { getUserReset } = userDetailsSlice.actions;

   const { pagination } = useSelector((state: storeTypes.IStore) => state.clinicalTrials.clinicalTrialsList);
   const { total } = pagination;

   const { data: clinicalTrialsListCountData } = useSelector(
      (state: storeTypes.IStore) => state.clinicalTrials.clinicalTrialsListCount
   );

   const { pagination: paginationInterested } = useSelector(
      (state: storeTypes.IStore) => state.clinicalTrials.clinicalTrialsInterestedList
   );
   const { total: totalInterested } = paginationInterested;

   const { clinicalTrialsCancerSpecificReset } = clinicalTrialsCancerSpecificSlice.actions;
   const { clinicalTrialsStatesReset } = clinicalTrialsStatesSlice.actions;

   const { list: listUserServiceOffered, message: messageUserServiceOffered } = useSelector(
      (state: storeTypes.IStore) => state.serviceOffered.getUserServiceOffered
   );
   const { data: getUserPopulationServedData, message: messagePopulationServed } = useSelector(
      (state: storeTypes.IStore) => state.populationServed.getUserPopulationServed
   );

   const { getUserServiceOfferedMessageReset } = serviceOfferedSlice.actions;
   const { getUserPopulationServedMessageReset } = populationServedSlice.actions;

   useEffect(() => {
      if (messageUserServiceOffered || messagePopulationServed) {
         if (listUserServiceOffered) {
            setIsServiceOfferedValid(listUserServiceOffered?.length > 0);
            dispatch(getUserServiceOfferedMessageReset());
         }

         if (getUserPopulationServedData) {
            const { primary_cancers } = getUserPopulationServedData;
            setIsPopulationServedValid(primary_cancers?.length > 0);
            dispatch(getUserPopulationServedMessageReset());
         }
      }
   }, [listUserServiceOffered, messageUserServiceOffered, messagePopulationServed, getUserPopulationServedData]);

   useEffect(() => {
      if (isOrgNameValid && isServiceOfferedValid && isPopulationServedValid) {
         setIsProfileLayoutModal(false);
      } else {
         if (isOrganization(getUser())) {
            setIsProfileLayoutModal(true);
         }
      }
   }, [isOrgNameValid, isServiceOfferedValid, isPopulationServedValid]);

   const pathParams: {
      id: string;
   } = useParams();
   const { id: clinicalTrialsIdString } = pathParams;

   const age = useMemo(() => getClinicalTrialsAge(userDetail?.user) || [], [userDetail]);
   const gender = useMemo(() => getClinicalTrialsGender(userDetail?.user) || [], [userDetail]);
   const cancerType = useMemo(() => getClinicalTrialsCancerType(userDetail?.user) || [], [userDetail]);
   const cancerSpecific = useMemo(() => getClinicalTrialsCancerSpecific(userDetail?.user) || [], [userDetail]);
   const countries = useMemo(() => getClinicalTrialsCountries(userDetail?.user) || [], [userDetail]);
   const state = useMemo(() => getClinicalTrialsState(userDetail?.user) || [], [userDetail]);
   const cancerTypeIdsMemo = useMemo(() => getClinicalTrialsCancerTypeIds(userDetail?.user) || [], [userDetail]);
   const countriesIdsMemo = useMemo(() => getClinicalTrialsCountriesIds(userDetail?.user) || [], [userDetail]);
   const studyPhase = useMemo(() => getClinicalTrailsDefaultStudyPhase(), []);

   const clinicalTrialsFinalObj = (obj: any) => {
      setFinalData(obj);
      dispatch(clinicalTrialsListReset());
      dispatch(clinicalTrialsListCountReset());
      getClinicalTrialsList(1, obj);
   };

   const clinicalTrialsCancerTypeIdChange = (cancerTypeIds: number[]) => {
      setCancerTypeIds(cancerTypeIds);
      dispatch(clinicalTrialsCancerSpecificReset());
      // cancer specific api
      const cancerSpecificParamsAndData: clinicalTrialsPageTypes.IClinicalTrailsCancerSpecificParamsAndPostData = {
         params: { page: 1, limit: CommonConstants.DropDownLimit, search_word: '' },
         data: {
            cancer_type_ids: cancerTypeIds || [],
         },
      };
      dispatch(clinicalTrialsCancerSpecificSagaAction(cancerSpecificParamsAndData));
   };

   const clinicalTrialsCountryIdChange = (countriesIds: number[]) => {
      setCountriesIds(countriesIds);
      dispatch(clinicalTrialsStatesReset());
      // state api
      const stateParamsAndData: clinicalTrialsPageTypes.IClinicalTrailsStatesParamsAndPostData = {
         params: { page: 1, limit: CommonConstants.DropDownLimit, search_word: '' },
         data: {
            country_ids: countriesIds || [],
         },
      };
      dispatch(clinicalTrialsStatesSagaAction(stateParamsAndData));
   };

   const changeClinicalTrials = (e: any) => {
      const value = e?.target?.value || '';
      const data = listParseAndStringify(finalData);
      data.search_word = value;
      setFinalData(data);
      dispatch(clinicalTrialsListReset());
      dispatch(clinicalTrialsListCountReset());
      getClinicalTrialsList(1, data);
   };

   const changeClinicalTrialsInterested = (e: any) => {
      const value = e?.target?.value || '';
      setSearchWord(value);
      dispatch(clinicalTrialsInterestedListReset());
      getClinicalTrialsInterestedList(1, value);
   };

   const clickClinicalTrialsTab = useCallback((type: string) => {
      setActiveTab(type);
   }, []);

   const getGender = (genderList: any[]) => {
      let gender: any[] = genderList?.length > 0 ? ['All'] : [];
      for (const value of genderList) {
         if (value === 'All') {
            gender = ['All', 'Male', 'Female'];
            break;
         } else {
            gender.push(value);
         }
      }
      return gender;
   };

   const getClinicalTrialsList = (page: number, obj: any) => {
      const apiData: any = {
         status: obj.status,
         age: obj.age,
         gender: getGender(obj.gender),
         cancer_type: obj.cancer_type,
         cancer_specific: obj.cancer_specific?.map((q: any) => {
            return q.cancer_specific_name;
         }),
         search_word: obj.search_word,
         study_type: obj.study_type,
         study_phase: obj.study_phase,
         countries: obj.countries,
         state: obj.state.map((q: any) => {
            return q.state_name;
         }),
      };
      const data: clinicalTrialsPageTypes.IClinicalTrialsListParamsAndData = {
         params: { page, limit: 15 },
         data: apiData,
      };
      dispatch(clinicalTrialsListSagaAction(data));

      // TODO: temporarily hide this item
      /* const countData: clinicalTrialsPageTypes.IClinicalTrialsListCountParams = {
         params: { page, limit: 15, is_count: 1 },
         data: apiData,
      };
      dispatch(clinicalTrialsListCountSagaAction(countData)); */
   };

   const getClinicalTrialsInterestedList = (page: number, searchWord: string) => {
      const data: clinicalTrialsPageTypes.IClinicalTrialsInterestedListParams = {
         params: { page, limit: 15, search_word: searchWord },
      };
      dispatch(clinicalTrialsInterestedListSagaAction(data));
   };

   const initialDataSet = (firstTime: boolean) => {
      const data: any = {
         status: ['Recruiting'],
         age: age,
         gender: gender,
         cancer_type: cancerType,
         cancer_specific: cancerSpecific,
         search_word: '',
         study_type: [],
         study_phase: studyPhase,
         countries: countries,
         state: state,
      };
      setFinalData(data);
      dispatch(clinicalTrialsListReset());
      dispatch(clinicalTrialsListCountReset());
      getClinicalTrialsList(1, data);
      // cancer specific api
      const cancerSpecificParamsAndData: clinicalTrialsPageTypes.IClinicalTrailsCancerSpecificParamsAndPostData = {
         params: { page: 1, limit: CommonConstants.DropDownLimit, search_word: '' },
         data: {
            cancer_type_ids: cancerTypeIdsMemo,
         },
      };
      dispatch(clinicalTrialsCancerSpecificSagaAction(cancerSpecificParamsAndData));
      setCancerTypeIds(getClinicalTrialsCancerTypeIds(userDetail?.user) || []);
      // state api
      const stateParamsAndData: clinicalTrialsPageTypes.IClinicalTrailsStatesParamsAndPostData = {
         params: { page: 1, limit: CommonConstants.DropDownLimit, search_word: '' },
         data: {
            country_ids: countriesIdsMemo,
         },
      };
      dispatch(clinicalTrialsStatesSagaAction(stateParamsAndData));
      setCountriesIds(countriesIds);
      if (firstTime) getClinicalTrialsInterestedList(1, searchWord);
   };

   const clearAllDataSet = (firstTime: boolean) => {
      const data: any = {
         status: [],
         age: [],
         gender: [],
         cancer_type: [],
         cancer_specific: [],
         search_word: '',
         study_type: [],
         study_phase: [],
         countries: [],
         state: [],
      };
      setFinalData(data);
      dispatch(clinicalTrialsListReset());
      dispatch(clinicalTrialsListCountReset());
      getClinicalTrialsList(1, data);
      dispatch(clinicalTrialsStatesReset());
      setCountriesIds([]);
      setCancerTypeIds([]);
      if (firstTime) getClinicalTrialsInterestedList(1, searchWord);
   };

   useEffect(() => {
      if (userDetail && isFrom === 'clinicalTrailsHome') {
         addUserObject(userDetail);
         if ((parseInt(simpleDecode(clinicalTrialsIdString)) || 0) === 0) {
            initialDataSet(true);
         }
         setTimeout(() => {
            updatedUser(userDetail?.user);
         }, SET_TIME_OUT.MIN);
      }
   }, [userDetail, isFrom, isOrgNameValid, isServiceOfferedValid, isPopulationServedValid]);

   const clickClearAll = useCallback(() => {
      clearAllDataSet(false);
   }, []);

   const fillProfileModal = () => {
      setIsProfileLayoutModal(false);
      fillProfileApi();
   };

   const updatedUser = (user: any) => {
      if (user) {
         setIsPrimaryCancerValid(isPrimaryCancer(user));
         setIsDisplayNameValid(isDisplayName(user));
         setIsAddressValid(isAddValidation(user));
         setIsDobValid(isDobValidation(user));
         setIsPhoneValid(isPhoneValidation(user));
         setIsRaceValid(isRaceValidation(user));
         setIsStagingValid(isStagingValidation(user));
         setIsDiagnosisDateValid(isDiagnosisDateValidation(user));
         setIsLatinoValid(isLatinoValidation(user));
         setIsCancerStatusValid(isCancerStatusValidation(user));
         setIsOrgNameValid(isOrgNameValidation(user));

         if (isPCAUser(user)) {
            if (!isDisplayName() || !isPrimaryCancer() || !isAddValidation() || !isDobValidation()) {
               setIsProfileLayoutModal(true);
            }
         }
         // TODO: checking purpose hided this
         /* else {
            if (isOrgNameValid && isServiceOfferedValid && isPopulationServedValid) {
               setIsProfileLayoutModal(false);
            } else {
               setIsProfileLayoutModal(true);
            }
         } */
      }
   };

   const fillProfileApi = () => {
      const userData: userInviteEmailPageTypes.IUserDetails = {
         isFrom: 'clinicalTrailsHome',
      };
      const params: myProfileSagaTypes.IFillProfileProps = {
         params: { page: 1, limit: CommonConstants.DropDownLimit },
         data: userData,
      };
      dispatch(fillProfileAction(params));
   };

   useEffect(() => {
      dispatch(getUserReset());
      removeEducationLibraryLinkRoute();
      return () => {
         dispatch(clinicalTrialsListReset());
         dispatch(clinicalTrialsInterestedListReset());
         dispatch(getUserReset());
      };
   }, []);

   useEffect(() => {
      const params: any = {
         page: 1,
         limit: CommonConstants.DropDownLimit,
         search_word: '',
      };
      // primary cancer
      dispatch(cancerTypeSagaAction(params));
      // study type
      dispatch(clinicalTrialsStudyTypeSagaAction(params));
      // study phase
      dispatch(clinicalTrialsStudyPhaseSagaAction(params));
      // countries
      dispatch(userGetCountriesAction(params));

      const queryString: QueryString.ParsedQuery<string> = QueryString.parse(location.search);
      const isInterestedString = queryString.isInterested ? simpleDecode(queryString.isInterested) : '';

      if (isInterestedString) {
         setActiveTab('interested');
      }
      if ((parseInt(simpleDecode(clinicalTrialsIdString)) || 0) > 0) {
         const params = {
            clinical_trails_id: simpleDecode(clinicalTrialsIdString),
         };
         dispatch(clinicalTrialsListItemGetOneSagaAction(params));
      }
      fillProfileApi();
   }, []);

   return (
      <Box mt={16}>
         <Container maxWidth="lg">
            {isProfileLayoutModal ? (
               isPCAUser(getUser()) || isOrganization(getUser()) ? (
                  <ProfileLayoutModal
                     closeModal={fillProfileModal}
                     isFirst={false}
                     phoneValid={isPhoneValid}
                     dobValid={isDobValid}
                     addressValid={isAddressValid}
                     displayNameValid={isDisplayNameValid}
                     primaryCancerValid={isPrimaryCancerValid}
                     isRaceValid={isRaceValid}
                     isStagingValid={isStagingValid}
                     isDiagnosisDateValid={isDiagnosisDateValid}
                     isLatinoValid={isLatinoValid}
                     isCancerStatusValid={isCancerStatusValid}
                     isServiceOfferedValid={isServiceOfferedValid}
                     isOrgNameValid={isOrgNameValid}
                     isPopulationServedValid={isPopulationServedValid}
                     userDetail={getUser()}
                     isFromClinicalTrials={true}
                     isFromClinicalTrialsHome={true}
                     isFromFeed={true}
                     isFromOrg={isOrganization(getUser())}
                  />
               ) : null
            ) : null}
            <Card className="trials_card">
               <Box>
                  <Typography className="trials_title">Clinical Trials</Typography>
                  <Grid container spacing={3}>
                     <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Box className="trials-side-menu">
                           {activeTab === 'all' ? (
                              <Box p={3}>
                                 <Box className="filter-div">
                                    <Grid container direction="row" alignItems="center">
                                       <Grid item xs={6}>
                                          <Typography className="filter-main-text">Filters</Typography>
                                       </Grid>
                                       <Grid item xs={6}>
                                          <Box className="filter-clear-all-div">
                                             <Typography
                                                component="span"
                                                className="filter-clear-all-text"
                                                onClick={clickClearAll}
                                             >
                                                Clear All
                                             </Typography>
                                          </Box>
                                       </Grid>
                                    </Grid>
                                 </Box>
                                 <ClinicFilter
                                    clinicalTrialsFinalObj={clinicalTrialsFinalObj}
                                    finalData={finalData}
                                    countriesIds={countriesIds}
                                    cancerTypeIds={cancerTypeIds}
                                    clinicalTrialsCountryIdChange={clinicalTrialsCountryIdChange}
                                    clinicalTrialsCancerTypeIdChange={clinicalTrialsCancerTypeIdChange}
                                 />
                              </Box>
                           ) : null}
                        </Box>
                     </Grid>
                     <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                        <Box className="trials-container">
                           <Paper square>
                              <Box className="global-search" py={2} px={2}>
                                 <Box className="clinical-trials-search pl-4 pr-4">
                                    <Grid container spacing={2} direction="row">
                                       <Grid item xs={6} className="grid-first">
                                          <Grid container spacing={2} direction="row">
                                             <Grid item>
                                                <Typography
                                                   component="span"
                                                   className={`tab-text ${activeTab === 'all' ? `tab-active` : ``}`}
                                                   onClick={() => {
                                                      clickClinicalTrialsTab('all');
                                                   }}
                                                >
                                                   {`Results (${clinicalTrialsListCountData?.total_count || total})`}
                                                </Typography>
                                             </Grid>
                                             <Grid item>
                                                <Typography
                                                   component="span"
                                                   className={`tab-text ${
                                                      activeTab === 'interested' ? `tab-active` : ``
                                                   }`}
                                                   onClick={() => {
                                                      clickClinicalTrialsTab('interested');
                                                   }}
                                                >
                                                   {`Interested (${totalInterested})`}
                                                </Typography>
                                             </Grid>
                                          </Grid>
                                       </Grid>
                                       <Grid item xs={6} className="grid-second">
                                          {activeTab === 'all' ? (
                                             <TextField
                                                autoComplete="off"
                                                id="outlined-basic"
                                                placeholder="Search study"
                                                fullWidth
                                                autoFocus
                                                variant="outlined"
                                                InputProps={{
                                                   startAdornment: (
                                                      <InputAdornment position="start">
                                                         <img src={SearchIcon} alt={'img'} />
                                                      </InputAdornment>
                                                   ),
                                                }}
                                                style={{ width: '298px' }}
                                                value={finalData.search_word}
                                                onChange={changeClinicalTrials}
                                             />
                                          ) : activeTab === 'interested' ? (
                                             <TextField
                                                autoComplete="off"
                                                id="outlined-basic"
                                                placeholder="Search study"
                                                fullWidth
                                                autoFocus
                                                variant="outlined"
                                                InputProps={{
                                                   startAdornment: (
                                                      <InputAdornment position="start">
                                                         <img src={SearchIcon} alt={'img'} />
                                                      </InputAdornment>
                                                   ),
                                                }}
                                                style={{ width: '298px' }}
                                                value={searchWord}
                                                onChange={changeClinicalTrialsInterested}
                                             />
                                          ) : null}
                                       </Grid>
                                    </Grid>
                                 </Box>
                              </Box>
                              <Box px={2}>
                                 {activeTab === 'all' ? (
                                    <ClinicalTrialsCard finalData={finalData} searchWord={searchWord} />
                                 ) : activeTab === 'interested' ? (
                                    <ClinicalTrialsInterestedCard searchWord={searchWord} />
                                 ) : null}
                              </Box>
                           </Paper>
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
            </Card>
         </Container>
      </Box>
   );
});

export default ClinicalTrials;
