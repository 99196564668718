import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { feedReducerTypes, feedSagaTypes } from 'types';

const initialState: feedReducerTypes.IDeletePostCommentReducer = {
   isLoading: false,
   error: '',
   error_message: '',
   message: '',
   data: null,
   requestData: null,
};
const deletePostCommentSlice = createSlice({
   name: 'deletePostComment',
   initialState: initialState,
   reducers: {
      deletePostCommentRequest: (state, action: PayloadAction<feedSagaTypes.IDeletePostCommentActionProps>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      deletePostCommentSuccess: (state, action: PayloadAction<feedReducerTypes.IDeletePostCommentReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: action.payload.message,
            requestData: action.payload.requestData,
         };
      },
      deletePostCommentFailed: (state, action: PayloadAction<feedReducerTypes.IDeletePostCommentReducer>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error_message,
         };
      },
      deletePostCommentReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
export { deletePostCommentSlice };
