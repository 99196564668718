import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { USR_PROFILE_UPDATE_SOCIAL_HISTORIES } from 'reduxToolKit/actions';
import { commonSlice, socialHistoriesSlice } from 'reduxToolKit/reducers';
import { socialHistoryReducerTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   updateSocialHistoriesRequest,
   updateSocialHistoriesSuccess,
   updateSocialHistoriesFailed,
} = socialHistoriesSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const updateSocialHistoriesSaga = function* (
   payloadState: PayloadAction<socialHistoryReducerTypes.IUpdateSocialHistoryData>
) {
   yield put(updateSocialHistoriesRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.updateSocialHistories });
      if (response.status === 200) {
         const responseData = {
            message: response.data?.message || messages.success,
         };
         yield put(updateSocialHistoriesSuccess(responseData));
      } else {
         yield put(updateSocialHistoriesFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeUpdateSocialHistories = function* () {
   yield takeEvery(USR_PROFILE_UPDATE_SOCIAL_HISTORIES, updateSocialHistoriesSaga);
};
