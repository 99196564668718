import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clinicAdminReducerTypes, clinicAdminSagaTypes, storeTypes } from 'types';

const initialState: clinicAdminReducerTypes.IClinicNursePatientsList = {
   myPatientsList: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      total_records: 0,
   },
   getClinicalAllPatients: {
      isLoading: false,
      error: '',
      message: '',
      list: [],
      total_records: 0,
   },
};

export const myPatientsListSlice = createSlice({
   name: 'myPatientsList',
   initialState: initialState,
   reducers: {
      myPatientsListLoading: (state) => {
         return {
            ...state,
            myPatientsList: {
               ...state.myPatientsList,
               isLoading: true,
            },
         };
      },
      myPatientsListViewRequest: (state, action: PayloadAction<clinicAdminSagaTypes.IClinicNursePatients>) => {
         return {
            ...state,
            myPatientsList: {
               ...state.myPatientsList,
               ...action.payload.paramsData,
               isLoading: true,
               list: state.myPatientsList.list,
            },
         };
      },
      myPatientsListViewSuccess: (
         state,
         action: PayloadAction<clinicAdminReducerTypes.IClinicNursePatientListSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            myPatientsList: {
               ...state.myPatientsList,
               isLoading: false,
               message: 'success',
               list: payload.list,
               total_records: payload.total_records,
            },
         };
      },
      myPatientsListFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            myPatientsList: {
               ...state.myPatientsList,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },

      myPatientsListReset: (state) => {
         return {
            ...state,
            myPatientsList: {
               ...initialState.myPatientsList,
            },
         };
      },

      getClinicalAllPatientsRequest: (state, action: PayloadAction<clinicAdminSagaTypes.IGetClinicalAllPatients>) => {
         return {
            ...state,
            getClinicalAllPatients: {
               ...state.getClinicalAllPatients,
               ...action.payload,
               isLoading: true,
               list: state.getClinicalAllPatients.list,
            },
         };
      },
      getClinicalAllPatientsSuccess: (
         state,
         action: PayloadAction<clinicAdminReducerTypes.IClinicNursePatientListSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            getClinicalAllPatients: {
               ...state.getClinicalAllPatients,
               isLoading: false,
               message: 'success',
               list: payload.list,
               total_records: payload.total_records,
            },
         };
      },
      getClinicalAllPatientsFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            getClinicalAllPatients: {
               ...state.getClinicalAllPatients,
               isLoading: false,
               message: '',
               error: action.payload.error,
            },
         };
      },

      getClinicalAllPatientsReset: (state) => {
         return {
            ...state,
            getClinicalAllPatients: {
               ...initialState.getClinicalAllPatients,
            },
         };
      },
   },
});
