import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { networkReducerTypes, networkSagaTypes } from 'types';

const initialState: networkReducerTypes.IMyConnectionsReducerPayload = {
   isLoading: false,
   error: '',
   message: '',
   data: null,
};

export const getAllConnectionsSlice = createSlice({
   name: 'getAllConnections',
   initialState: initialState,
   reducers: {
      getAllConnectionsLoading: (state) => {
         return {
            ...state,
            isLoading: true,
         };
      },
      getAllConnectionsRequest: (state, action: PayloadAction<networkSagaTypes.IGetMyConnectionsSagaPayload>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getAllConnectionsSuccess: (state, action: PayloadAction<networkReducerTypes.IMyConnectionsSuccessPayload>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      getAllConnectionsFailed: (state, action: PayloadAction<networkReducerTypes.IMyConnectionsFailurePayload>) => {
         return {
            ...state,
            isLoading: false,
            message: '',
            error: action.payload.error,
         };
      },

      getAllConnectionsReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
