import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clinicAdminReducerTypes, clinicAdminSagaTypes } from 'types';

const initialState: clinicAdminReducerTypes.IClinicWorkingHoursReducerPayload = {
   isLoading: false,
   data: null,
   message: '',
   error: '',
};

export const clinicWorkingHoursSlice = createSlice({
   name: 'clinicWorkingHours',
   initialState: initialState,
   reducers: {
      clinicWorkingHoursRequest: (state, action: PayloadAction<clinicAdminSagaTypes.IClinicWorkingHoursSaga>) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      clinicWorkingHoursSuccess: (
         state,
         action: PayloadAction<clinicAdminReducerTypes.IClinicWorkingHoursSuccessPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: false,
         };
      },
      clinicWorkingHoursFailed: (
         state,
         action: PayloadAction<clinicAdminReducerTypes.IClinicWorkingHoursFailurePayload>
      ) => {
         return {
            ...state,
            error: action.payload?.error,
            isLoading: false,
         };
      },

      clinicWorkingHoursReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
