import { createAction } from '@reduxjs/toolkit';
import { videoLibrarySagaTypes } from 'types/videoLibrary';

export const VIDEO_LIBRARY_LIST_SAGA = 'VIDEO_LIBRARY_LIST_SAGA';
export const videoLibraryListSagaAction = createAction<videoLibrarySagaTypes.IVideoLibraryListActionProps>(
   VIDEO_LIBRARY_LIST_SAGA
);

export const VIDEO_LIBRARY_LIST_SEARCH_SAGA = 'VIDEO_LIBRARY_LIST_SEARCH_SAGA';
export const videoLibraryListSearchSagaAction = createAction<videoLibrarySagaTypes.IVideoLibraryListActionProps>(
   VIDEO_LIBRARY_LIST_SEARCH_SAGA
);

export const VIDEO_LIBRARY_CATEGORY_LIST_SAGA = 'VIDEO_LIBRARY_CATEGORY_LIST_SAGA';
export const videoLibraryCategoryListSagaAction = createAction<videoLibrarySagaTypes.IVideoLibraryCategoryListActionProps>(
   VIDEO_LIBRARY_CATEGORY_LIST_SAGA
);

export const ORG_USER_EDU_MY_LIB_ADD_UPDATE = 'ORG_USER_EDU_MY_LIB_ADD_UPDATE';
export const orgUserEduMyLibAddUpdateSagaAction = createAction<videoLibrarySagaTypes.IOrgUserEduMyLibAddActionProps>(
   ORG_USER_EDU_MY_LIB_ADD_UPDATE
);

export const ORG_USER_EDU_MY_LIB_DELETE = 'ORG_USER_EDU_MY_LIB_DELETE';
export const orgUserEduMyLibDeleteSagaAction = createAction<videoLibrarySagaTypes.IEduLibMyLibDeleteProps>(
   ORG_USER_EDU_MY_LIB_DELETE
);

export const FEED_ORG_LIST = 'FEED_ORG_LIST';
export const feedOrgListAction = createAction<videoLibrarySagaTypes.IFeedOrgListProps>(FEED_ORG_LIST);
