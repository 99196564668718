import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { askADocPatientReducerTypes, storeTypes } from 'types';
import { messages } from 'utils/constants/messages';

const initialState: askADocPatientReducerTypes.IAskADocPhysicianStateReducerTypes = {
   isLoading: false,
   list: [],
   error: '',
   message: '',
};

export const getPhysicianStateSlice = createSlice({
   name: 'getPhysicianState',
   initialState: initialState,
   reducers: {
      getPhysicianStateRequest: (
         state,
         action: PayloadAction<askADocPatientReducerTypes.IAskADocPhysicianStateProps>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      getPhysicianStateSuccess: (
         state,
         action: PayloadAction<askADocPatientReducerTypes.IAskADocPhysicianStateReducerTypes>
      ) => {
         return {
            ...state,
            list: action.payload?.list,
            message: action.payload?.message || messages.success,
            error: '',
            isLoading: false,
         };
      },
      getPhysicianStateFailed: (state, action: PayloadAction<storeTypes.ICommonApiFailurePayload>) => {
         return {
            ...state,
            message: '',
            error: action.payload?.error,
            isLoading: false,
         };
      },
      getPhysicianStateReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
