export const customStyles = {
   content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: 0,
      marginTop: 0,
      width: '30%',
   },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps: any = {
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
         width: 250,
      },
   },
   variant: 'menu',
   getContentAnchorEl: null,
};

export const CustomStyleoption = {
   option: (base: any) => ({
      ...base,
      cursor: 'pointer',
   }),
   control: (base: any) => ({
      ...base,
      cursor: 'pointer',
      height: '50px',
   }),
};

export interface EditIconProps {
   onClick?: () => void;
   ariaLabel?: string;
   iconEdit?: any;
   id?: string;
}
