import { User } from 'types/modelHelpers';
import { getUser } from 'utils/helpers/authHeader';
import { isPCAUser, isPhysician } from 'utils/helpers/userHelpers/userTypeHelpers';

export default {
   MIN: 8,
   MAX: 15,
   EMPTY: 'Password field is required',
   REQUIRED: 'This field is required',
   OLD_PASSWORD: 'Old password is required',
   NEW_PASSWORD: 'New password is required',
   CONFORM_PASSWORD: 'Confirm new password is required',
   MIN_VALIDATION: 'Password has to be longer than 8 characters!',
   MAX_VALIDATION: 'Password has to be less than 15 characters!',
   PASSWORD_MATCH_VALIDATE: 'Password must match',
   ACCEPT_TERMS: 'The terms and conditions must be accepted.',
   PASSWORD_REG_EXP_MATCH: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
   PASSWORD_REGULAREXP_NOT_MATCH:
      'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character',
   PASSWORD_REG_EXP_NOT_MATCH:
      'Password length must be between 6 to 15 characters, one uppercase, one lowercase, one number and one special case character',
   FIRST_NAME: 'First name field is required',
   LAST_NAME: 'Last name field is required',
   VALID_MAIL: 'Please Enter an valid Email',
   EMAIL: 'Email field is required',
   STATE: 'State is required',
   DISPLAY_NAME: 'Display name field is required',
   ACCOUNT_TYPE: 'Account type field is required',
};

// Validation Section
export const isPhoneValidation = (userDetail?: User) => {
   const user = userDetail || getUser();
   if (user?.phone_verified_at !== null && user?.phone !== null && user?.country_code !== null) {
      return true;
   }
   return false;
};
export const isDobValidation = (userDetail?: User) => {
   const user = userDetail || getUser();
   if (user?.date_of_birth !== null && user?.gender !== null) {
      return true;
   }
   return false;
};
export const isAddValidation = (userDetail?: User) => {
   const user = userDetail || getUser();
   if (user?.address !== null && user?.country != null && user?.patient_state != null) {
      return true;
   } else {
      return false;
   }
};

export const isRaceValidation = (userDetail?: User) => {
   const user = userDetail || getUser();
   if (user?.race !== null) {
      return true;
   } else {
      return false;
   }
};

export const isStagingValidation = (userDetail?: User) => {
   const user = userDetail || getUser();
   if (user?.staging !== null && user?.staging !== '') {
      return true;
   } else {
      return false;
   }
};

export const isDiagnosisDateValidation = (userDetail?: User) => {
   const user = userDetail || getUser();
   if (user?.initial_diagnosis_date !== null && user?.initial_diagnosis_date !== '') {
      return true;
   } else {
      return false;
   }
};

export const isLatinoValidation = (userDetail?: User) => {
   const user = userDetail || getUser();
   if (user?.hispanic_or_latino !== null && user?.hispanic_or_latino !== '') {
      return true;
   } else {
      return false;
   }
};

export const isCancerStatusValidation = (userDetail?: User) => {
   const user = userDetail || getUser();
   if (user?.cancer_status !== null) {
      return true;
   } else {
      return false;
   }
};

export const isOrgNameValidation = (userDetail?: User) => {
   const user = userDetail || getUser();
   if (user?.organization?.name !== null) {
      return true;
   } else {
      return false;
   }
};

export const isBasicProfileValidInFeed = (userDetail?: User) => {
   if (
      isAddValidation(userDetail) &&
      isDobValidation(userDetail) &&
      /* isPhoneValidation() && */ isDisplayName() &&
      isPrimaryCancer(userDetail) &&
      isCancerStatusValidation(userDetail) &&
      isLatinoValidation(userDetail) &&
      isDiagnosisDateValidation(userDetail) &&
      isStagingValidation(userDetail) &&
      isRaceValidation(userDetail)
   ) {
      return true;
   }
   return false;
};
export const isBasicProfileValidMyservice = () => {
   const user = getUser();
   if (isPhysician(user)) {
      if (user?.country_id === 236 || user?.user_country?.length > 0) {
         return false;
      } else if (user?.user_country?.length === 0) {
         return true;
      }
      return true;
   }
};
export const isBasicProfileValid = (isSecondOpinion?: boolean) => {
   const user = getUser();
   if (isPhysician(user)) {
      if (
         user.first_name !== null &&
         user.last_name !== null &&
         user.address !== null &&
         user.latitude !== null &&
         user.longitude !== null
      ) {
         return true;
      } else {
         return false;
      }
   } else if (isPCAUser(user)) {
      const cancerTypeId = user.cancer_patient
         ? user.cancer_patient.cancer_type
            ? user.cancer_patient.cancer_type.id
            : null
         : null;
      const stateId = localStorage.getItem('stateId') ? localStorage.getItem('stateId') : null;
      if (isSecondOpinion) {
         if (
            user.first_name !== null &&
            user.last_name !== null &&
            cancerTypeId !== null &&
            user.date_of_birth !== null &&
            user.gender !== null
         ) {
            return true;
         } else {
            return false;
         }
      } else {
         if (
            user.first_name !== null &&
            user.last_name !== null &&
            cancerTypeId !== null &&
            user.date_of_birth !== null &&
            user.gender !== null &&
            user.address !== null &&
            user.latitude !== null &&
            user.longitude !== null &&
            stateId !== null
         ) {
            return true;
         } else {
            return false;
         }
      }
   }
};

export const isDisplayName = (userDetail?: User) => {
   const user = userDetail || getUser();
   if (user?.display_name) {
      return true;
   } else {
      return false;
   }
};
export const isPrimaryCancer = (userDetail?: User) => {
   const user = userDetail || getUser();
   const cancerTypeId = user?.cancer_patient?.cancer_type?.id > 0 ? user?.cancer_patient?.cancer_type?.id : null;
   if (cancerTypeId) {
      return true;
   } else {
      return false;
   }
};

export const isLocalOnly = () => {
   return window.location.origin === 'http://localhost:3000';
};

export const isLocalAndDevOnly = () => {
   const URL = window.location.origin;
   return URL === 'http://localhost:3000' || URL === 'https://dev.oncopower.org';
};
