import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { CLINICAL_NOTES_ADD } from 'reduxToolKit/actions';
import { clinicalNotesAddSlice, commonSlice } from 'reduxToolKit/reducers';
import { clinicalNotesSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   clinicalNotesAddLoading,
   clinicalNotesAddRequest,
   clinicalNotesAddSuccess,
   clinicalNotesAddFailed,
} = clinicalNotesAddSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const clinicalNotesAddSaga = function* (
   clinicalNotesTypePayload: PayloadAction<clinicalNotesSagaTypes.IClinicalNotesAddForm>
) {
   yield put(clinicalNotesAddLoading());
   yield put(clinicalNotesAddRequest(clinicalNotesTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = clinicalNotesTypePayload.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addClinicalNotes });
      if (response.status === 200) {
         const message = response.data.message;
         yield put(clinicalNotesAddSuccess({ message }));
      } else {
         yield put(clinicalNotesAddFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeClinicalNotesAddRequest = function* () {
   yield takeEvery(CLINICAL_NOTES_ADD, clinicalNotesAddSaga);
};
