import BrainyList from 'assets/alert/brainyList.svg';
import HugList from 'assets/alert/hugList.svg';
import LikeList from 'assets/alert/likeList.svg';
import PrayerList from 'assets/alert/prayerList.svg';
import BrainyImage from 'assets/askDoc/brainy.svg';
import HugsImage from 'assets/askDoc/hugs.svg';
import PrayerImage from 'assets/askDoc/prayerIcon.svg';
import commentLike from 'assets/bg/commentLike.svg';
import likesIcon from 'assets/icons/like_blue.svg';

import {
   ADDRESS_SECTION,
   CANCER_STATUS_SECTION,
   CANCER_TYPE_SECTION,
   DIAGNOSIS_SECTION,
   DISPLAY_NAME_SECTION,
   HOME_SECTION,
   STAGING_SECTION,
} from './auth';

export const reactionTypesList = [
   'like',
   'comments',
   'comment_replies',
   'ask_a_doc_feed_comments',
   'ask_a_doc_comment_replies',
];

export const reactionCommentTypesList = [
   'comments',
   'comment_replies',
   'ask_a_doc_feed_comments',
   'ask_a_doc_comment_replies',
];

export const reactionShareTypesList = ['share'];

export const reactionTypeClassName: any = {
   like: 'is_liked',
   folded_hands: 'prayer',
   brainy: 'brainy',
   hugs: 'hugs',
};

export const reactionTypeClassNamePost: any = {
   like: 'is-liked-post',
   folded_hands: 'prayer',
   brainy: 'brainy',
   hugs: 'hugs',
};

export const reactionTypeText: any = {
   like: 'Like',
   folded_hands: 'Prayer',
   brainy: 'Brainy',
   hugs: 'Hug',
};

export const reactionTypeIcon: any = {
   like: commentLike,
   folded_hands: PrayerImage,
   brainy: BrainyImage,
   hugs: HugsImage,
};

export const reactionListIcons: any = {
   like: LikeList,
   folded_hands: PrayerList,
   brainy: BrainyList,
   hugs: HugList,
};

export const reactionTypeIconPost: any = {
   like: likesIcon,
   folded_hands: PrayerImage,
   brainy: BrainyImage,
   hugs: HugsImage,
};

export const optionText = [
   'A',
   'B',
   'C',
   'D',
   'E',
   'F',
   'G',
   'H',
   'I',
   'J',
   'K',
   'L',
   'M',
   'N',
   'O',
   'P',
   'Q',
   'R',
   'S',
   'T',
   'U',
   'V',
   'W',
   'X',
   'Y',
   'Z',
];

export const QUESTION_MARK = '?';
export const CREATE_POST_CONFIRMATION_TITLE = 'Are you looking for any specific answer?';
export const CREATE_POST_CONFIRMATION_MESSAGE =
   'Try our Ask-a-Doc tool where we have our handpicked physicians to help you in all cancer needs';
export const CANCEL_POST_TITLE = 'Are you sure want to discard this post?';
export const CANCEL_DISCARD_POST = 'Discard post';
export const CANCEL_SAVE_AS_DRAFT = 'Save as draft';
export const CANCEL_CONTINUE_EDITING = 'Continue editing';
export const FEED_PHYSICIAN_SHOW_CASE = [
   {
      content:
         'Welcome! This home page has the main feed with user posts. You can introduce yourself or share ideas with fellow providers here!',
      placement: 'bottom',
      target: '.show-inputmenu-user',
      selector: '.show-inputmenu-user',
      title: '',
      disableBeacon: true,
   },
   {
      content: 'The button to your right allows you to turn on/off whether you see patient posts.',
      placement: 'top',
      target: '.show-patientfeed',
      selector: '.show-patientfeed',
      title: '',
   },
   {
      content:
         'Up here is quick access to many important tools. Ask-a-Doc is a place for more personalized care. The Drug Look-Up tool has updated clinical and prescribing info. OncKlinic allows you to provide symptom monitoring and remote care and shows up here once it is set up.',
      placement: 'top',
      target: '.show-topmenu-user',
      selector: '.show-topmenu-user',
      title: '',
   },
   {
      content:
         'You can click on your picture or name to access your profile. This left menu contains more tools and resources such as Meditation Suite to mitigate burn-out and the Education Library. Check them out!',
      placement: 'auto',
      target: '.show-leftsidemenu-user',
      selector: '.show-leftsidemenu-user',
      title: '',
   },
   {
      content:
         'And lastly, here is where you can go for all of the important setting and profile information, wallet and notifications set-up, and more.',
      placement: 'bottom',
      target: '.show-dropdownuser',
      selector: '.show-dropdownuser',
      title: '',
   },
];
export const FEED_PATIENT_SHOW_CASE = [
   {
      content:
         'Welcome! This home page has the main feed with user posts. You can introduce yourself or seek advice from fellow patients here!',
      placement: 'bottom',
      target: '.show-inputmenu-user',
      selector: '.show-inputmenu-user',
      title: '',
      disableBeacon: true,
   },
   {
      content:
         'Up here is quick access to many important tools. Ask-a-Doc is a place for more personalized care. The Education Library has lots of great video content to help you manage symptoms and side effects. Clinical Trials helps you find treatment options that may be right for you.',
      placement: 'top',
      target: '.show-topmenu-user',
      selector: '.show-topmenu-user',
      title: '',
   },
   {
      content:
         'You can click on your picture or name to access your profile. This left menu contains more tools and resources for you to help with your care journey such as Support Groups, Daily Check-In, Meditation Suite, Pill Reminder set-up, and Medical Records storage as OncDrive. Check them out!',
      placement: 'auto',
      target: '.show-leftsidemenu-user',
      selector: '.show-leftsidemenu-user',
      title: '',
   },
   {
      content:
         'And lastly, here is where you can go for all of the important setting and profile information, wallet and notifications set-up, and more.',
      placement: 'bottom',
      target: '.show-dropdownuser',
      selector: '.show-dropdownuser',
      title: '',
   },
];

export const FEED_ORGANIZATION_SHOW_CASE = [
   {
      content:
         'Welcome! This is the home feed with user posts. You can introduce yourself or seek advice from fellow users here!',
      placement: 'bottom',
      target: '.show-inputmenu-user',
      selector: '.show-inputmenu-user',
      title: '',
      disableBeacon: true,
   },
   {
      content:
         'Up here is quick access to many important tools. The Education Library has lots of great video content to help you create or manage multi-media files. The Drug Look-Up tool has updated clinical and prescribing info. Clinical Trials helps you find treatment options that may be right for you.',
      placement: 'top',
      target: '.show-topmenu-user',
      selector: '.show-topmenu-user',
      title: '',
   },
   {
      content:
         'You can click on your picture or name to access your profile. This left menu contains more tools and resources for you to help with your care journey such as Support Groups, Daily Check-In, Meditation Suite. Check them out!',
      placement: 'auto',
      target: '.show-leftsidemenu-user',
      selector: '.show-leftsidemenu-user',
      title: '',
   },
   {
      content:
         'And lastly, here is where you can go for all of the important setting and profile information, wallet and notifications set-up, and more.',
      placement: 'bottom',
      target: '.show-dropdownuser',
      selector: '.show-dropdownuser',
      title: '',
   },
];

export const FILL_PROFILE_STEP = 10;
export const FILL_PROFILE_STEP_INITIAL = 3;
export const FILL_PROFILE_CLINICAL_TRAILS = 4;
export const FILL_PROFILE_CLINICAL_TRAILS_REMAINING = 6;

export const FILL_PROFILE_SKIP_ARRAY = [
   HOME_SECTION,
   DISPLAY_NAME_SECTION,
   CANCER_TYPE_SECTION,
   DIAGNOSIS_SECTION,
   STAGING_SECTION,
   CANCER_STATUS_SECTION,
   ADDRESS_SECTION,
];

export const CREATE_POST_NO_SUPPORT_GROUP =
   'You are currently not a member of any support group. Kindly change the visibility type and post.';
export const SUPPORT_GROUP_CANNOT_BLANK = 'Support group cannot be blank';
export const VISIBILITY_CANNOT_BLANK = 'Visibility cannot be blank. Please select';
export const ENTER_DESCRIPTION = 'Enter description';
export const PLEASE_ENTER_ALL_FIELDS = 'Please enter all fields.';
