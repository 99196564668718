import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { PUBLIC_POST_SHARE } from 'reduxToolKit/actions';
import { commonSlice, publicPostShareSlice } from 'reduxToolKit/reducers';
import { postShareSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { publicPostShareRequest, publicPostShareSuccess, publicPostShareFailed } = publicPostShareSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const publicPostShareSaga = function* (payloadState: PayloadAction<postShareSagaTypes.IPublicPostPayload>) {
   yield put(publicPostShareRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      let data, customUrl;
      if (payloadState.payload?.shareType === 'public') {
         data = {
            id: payloadState.payload?.id,
            description: payloadState.payload?.description,
         };
         customUrl = API.publicPostShare;
      } else {
         data = {
            id: payloadState.payload.id,
            description: payloadState.payload.description,
            visibility: payloadState.payload.visibility,
         };
         customUrl = API.postShare;
      }
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...customUrl });
      if (response.status === 200) {
         const resObj = {
            message: response.data.message,
            data: response.data.data,
         };
         yield put(publicPostShareSuccess(resObj));
      } else {
         yield put(publicPostShareFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takePublicPostRequest = function* () {
   yield takeEvery(PUBLIC_POST_SHARE, publicPostShareSaga);
};
