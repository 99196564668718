import { combineReducers } from 'redux';
import { storeTypes } from 'types';

import {
   acceptClinicReqPatSlice,
   acceptClinicReqSlice,
   acceptConnectionPostSlice,
   addAuxiliaryStaffSlice,
   addClinicAdminSlice,
   addClinicStaffSlice,
   addClinicVideosSlice,
   addDailyCheckLogSlice,
   addEditAppointmentCommentSlice,
   addendumPostSlice,
   addendumUpdatePostSlice,
   addMyServicesSpecialtiesSlice,
   addPatientSlice,
   addressSectionPostSlice,
   fillProfileSubSpecialitySlice,
   addScheduleSlice,
   addSymptomSlice,
   appointmentScheduleSlice,
   askADocCreateRequest72HrsSlice,
   askADocDisclaimerSlice,
   askADocEstimateSlice,
   askADocFeedReactionSlice,
   askADocListSlice,
   askADocExampleQusListSlice,
   askADocViewAnswersSlice,
   askPhyCountSlice,
   askPhyListSlice,
   savedPostSlice,
   assignClinicVideoSlice,
   assignedVideoSlice,
   assignVideoSlice,
   bookServiceSlice,
   cancerTypeListSlice,
   caregiverDeleteSlice,
   checkAppointmentAvailabilitySlice,
   checkScheduleBlockSlice,
   clinicAdminSlice,
   clinicAdminsSlice,
   clinicalNotesAddSlice,
   clinicalNotesListSlice,
   clinicAuxiliarySlice,
   clinicInvitePatientReqSlice,
   clinicInvitePatientsSlice,
   clinicJoinRequestSlice,
   clinicPatientsSlice,
   clinicStaffSlice,
   clinicVideosSlice,
   clinicInfoEditSlice,
   clinicPhyInviteIsExistSlice,
   clinicDeleteAuxiliarySlice,
   clinicPatientBulkImportSlice,
   commentsAddUpdateSlice,
   commentsReactionSlice,
   commentsReactionsListSlice,
   commentsReplyAddSlice,
   commentsReplyDeleteSlice,
   commentsReplyUpdateSlice,
   commonSlice,
   coMorbiditiesSlice,
   connectionInviteDeleteSlice,
   connectionReqDeleteSlice,
   connectionRequestPostSlice,
   consentListSlice,
   convoListSlice,
   conversationSlice,
   conversationMessageAddGetSlice,
   customNotificationAddSlice,
   customNotificationSlice,
   dailyCheckListSlice,
   deleteCertificateSlice,
   deleteClinicAdminSlice,
   deleteClinicalTrialSlice,
   deleteClinicPhysiciansSlice,
   deleteClinicStaffSlice,
   deleteClinicVideosSlice,
   deleteEducationSlice,
   deleteExperienceSlice,
   deleteFamilyMemberSlice,
   deleteMyConnectionSlice,
   deletePhysicianSlice,
   deletePostCommentSlice,
   deviceClinicNotesCommentsSlice,
   dobSectionPostSlice,
   documentListSlice,
   drugSpecificListSlice,
   educationVideosSlice,
   feedConnectionStatisticsSlice,
   feedReactionsAddSlice,
   getAllConnectionsSlice,
   getAllUsersListSlice,
   getAppointmentCommentSlice,
   getScheduledPhysicianSlice,
   getAskADocCaseDetailsSlice,
   getAskADocCaseDetailsCheckRequiredFieldsSlice,
   getCaregiverDetailsSlice,
   getClinicAdminInvitesSlice,
   getClinicGroupsSlice,
   getClinicPhysicianSlice,
   getCommentsListSlice,
   getDeviceSlice,
   getDiseasesSlice,
   getDrugsListSlice,
   getFamilyMembersSlice,
   getFamilyMemberTypesSlice,
   getLineOfTherapyHistorySlice,
   getOneDrugsListSlice,
   getOnePrescriptionSlice,
   getPatientStateSlice,
   getPhysicianStateSlice,
   getPhysicianAdminSlice,
   getPhysicianInviteSlice,
   getPhysicianListSlice,
   getPhysiciansSlice,
   getPrimaryDiseaseSlice,
   getReplyCommentsListSlice,
   s3FileUploadSlice,
   getScheduleSlice,
   getTopCancerSlice,
   getTopStateSlice,
   getTreatingPhysicianSlice,
   getUserProfileSlice,
   healthRecordAddSlice,
   healthRecordSlice,
   hippaComplianceSlice,
   inviteClinicPhysiciansSlice,
   inviteRequestListSlice,
   joinCallAppointmentSlice,
   joinGroupSlice,
   oncoKlinicSlice,
   leaveClinicSlice,
   lineOfTherapySlice,
   locumGetOneServiceSlice,
   locumMonthWiseServiceListSlice,
   locumServiceAddUpdateSlice,
   locumServiceDeleteSlice,
   loginSlice,
   medicalEnqAddCommentSlice,
   medicalEnqAddSlice,
   medicalEnqCommentsListSlice,
   medicalEnqDocumentsListSlice,
   medicalEnqGetOneSlice,
   medicalEnqMyClinicPhysListSlice,
   medicalEnquiresListSlice,
   medicalEnqUpdateCommentSlice,
   medicalEnqUpdateDocumentSlice,
   medicalEnqUpdateSlice,
   medicalEnqViewDocumentSlice,
   medicationListSlice,
   medicationSlice,
   meditationSlice,
   modalAddAssociatedPhysicianSlice,
   modalAddCaregiverSlice,
   modalAddFamilyMemberSlice,
   modalCancersSlice,
   modalDescriptionUpdateSlice,
   modalGetAssociatedPhysicianSlice,
   modalGetClinicsSlice,
   modalInviteViaMailSlice,
   modalJoinClinicsSlice,
   modalUpdateCertificateSlice,
   modalUpdateClinicTrialSlice,
   modalUpdateCoMorbiditiesSlice,
   modalUpdateEducationTrainingSlice,
   modalUpdateExperienceSlice,
   modalUpdateFamilyHistoriesSlice,
   modalUpdateMedicationSlice,
   modalUpdatePatientInsuranceSlice,
   morbiditiesSlice,
   myClinicPatientsListSlice,
   myClinicPhyCancelReqSlice,
   myClinicPhyReqSlice,
   myClinicPhysiciansSlice,
   myPatientsListSlice,
   myPhysicianSlice,
   myServiceListSlice,
   myServicePhyListSlice,
   myServiceSpecialityListSlice,
   myServiceUpdateSlice,
   myTreatingPhysiciansSlice,
   newStateAddingSlice,
   notificationCountSlice,
   notificationListSlice,
   notificationReadAllSlice,
   notificationReadSlice,
   notificationSettingsListSlice,
   nurseGetAssociatedPhysicianSlice,
   oncDriveAddFolderSlice,
   oncDriveDeleteFolderAndFilesSlice,
   oncDriveListSlice,
   oncDriveMyClinicPatientListSlice,
   oncDriveSharedListSlice,
   oncDriveSharedUserListSlice,
   oncDriveShareSlice,
   oncDriveUnShareSlice,
   oncDriveViewFileSlice,
   otpProcessSlice,
   otpSectionPostSlice,
   panelRequestSlice,
   panelsListSlice,
   panelsPhyListSlice,
   patientAdvocateSlice,
   patientMedicationsSlice,
   patientMeditationRpmDeviceSlice,
   paypalAndZelleUpdateSlice,
   pendingRequestListSlice,
   peopleNearMeListSlice,
   phoneSectionPostSlice,
   physicianInfoSlice,
   physicianRewardSlice,
   physicianScheduleSlice,
   pillRemainderAddSlice,
   pillRemainderDeleteSlice,
   pillRemainderNonCancerDeleteSlice,
   pillRemainderEditSlice,
   pillRemainderGetOneSlice,
   pillRemainderListSlice,
   pillRemainderMediStatusUpdateSlice,
   pillRemainderNotificationGetOneSlice,
   pillRemainderNotifyStatusAddSlice,
   pillRemainderNotifyStatusEditSlice,
   postShareSlice,
   practiseAdminLocumListSlice,
   practiseAdminLocumPhySearchSlice,
   profilePublicationSlice,
   profileSkillsSlice,
   profileSocialHistoriesSlice,
   publicPostShareSlice,
   rateWriteSlice,
   reportableSymptomSlice,
   reportPostSlice,
   resendOtpSectionPostSlice,
   rpmDashboardSlice,
   rpmPendingPrescriptionSlice,
   rpmPrescriptionAddSlice,
   rpmPrescriptionEditSlice,
   rpmPrescriptionSlice,
   rpmPrescriptionUpdateSlice,
   secondaryCancerTypeListSlice,
   settingAddStateAndTravelSlice,
   settingGetStateAndTravelListSlice,
   settingLocumGetStateAndTravelListSlice,
   showPatientFeedSlice,
   singleAskADocFeedListSlice,
   singleFeedListSlice,
   socialHistoriesSlice,
   summaryPostSlice,
   getOneSummarySlice,
   summaryUpdatePostSlice,
   supportGroupsSlice,
   supportMyGroupsSlice,
   symptomConsentListSlice,
   symptomListSlice,
   symptomMonitoringCommentsSlice,
   tagUsersListSlice,
   rpmIntervalRemainderSlice,
   teleMedicineListSlice,
   toastControllerSlice,
   updateAppointmentDocumentSlice,
   updateAppointmentSlice,
   updateCaregiverSlice,
   updateClinicAdminInviteStatusSlice,
   updateFamilyMemberSlice,
   updatePhysicianSlice,
   userDetailsSlice,
   userInviteEmailSlice,
   userInviteEmailValidationSlice,
   userStateSlice,
   videoCallConnectionSlice,
   videoCallUpdateSlice,
   videoLibraryCategoryListSlice,
   videoLibraryListSlice,
   viewAppointmentDocumentSlice,
   viewAppointmentSlice,
   walletOffersSlice,
   walletPhysicianSlice,
   walletSlice,
   walletBalanceGetSlice,
   workingHoursSlice,
   recruiterAdminJobSlice,
   physicianJobsSlice,
   getSpecialitiesSlice,
   googleLoginSlice,
   linkedInLoginSlice,
   groupsInfoDetailsSlice,
   notificationAddDeviceTokenSlice,
   globalSearchListSlice,
   recentSearchSlice,
   adminNotificationSlice,
   profilePictureSlice,
   isExistingClinicInviteSlice,
   clinicWorkingHoursSlice,
   phyNpiNumberVerifySlice,
   changePasswordSlice,
   userRegisterSlice,
   searchNetworkAllUsersSlice,
   forgetPasswordSlice,
   setPasswordSlice,
   subscriptionDetailsSlice,
   askADocPhyPanelRequestSlice,
   askADocPhyPanelListSlice,
   emailVerificationSlice,
   postCreateAndEditSlice,
   vdocipherSlice,
   userGetCountriesSlice,
   deviceGetMinAndMaxSlice,
   userOncoklinicSlice,
   secondOpinionPhyListSlice,
   secOpinionShortPhyListSlice,
   secondOpinionNearbyPhyListSlice,
   physicianJobFavouriteSlice,
   searchSlice,
   feedPollOptionSlice,
   feedPollUsersListSlice,
   feedPostListSlice,
   oncKlinicMeetingSlice,
   oncKlinicSymptomClinicSlice,
   deviceBloodPressureSlice,
   deviceSmartWeightSlice,
   feedMeditationSlice,
   oncKlinicSymptomSlice,
   oncoMeditationSlice,
   eventTrackSlice,
   treatmentMethodSlice,
   askADocPrePopulateCreateRequestDataSlice,
   feedShareLinkSlice,
   feedShareLinkUserSlice,
   announcementSlice,
   clinicalTrialsSlice,
   clinicalTrialsStudyTypeSlice,
   clinicalTrialsStudyPhaseSlice,
   clinicalTrialsStatesSlice,
   clinicalTrialsCancerSpecificSlice,
   hashTagListSlice,
   dailyCheckInsSlice,
   hidePostSlice,
   blockUnblockUserSlice,
   communityGuidelinesSlice,
   medicationSavingSlice,
   pillRemainderAllPillsListSlice,
   supportGroupSlice,
   diagnosisSlice,
   latinoSlice,
   stagingSlice,
   raceSlice,
   cancerStatusSlice,
   ecogSlice,
   medicalHistorySlice,
   geneticTestingSlice,
   serviceOfferedSlice,
   orgNameSlice,
   populationServedSlice,
   reportableSymptomGoListSlice,
   orgUserEduLibSlice,
} from './reducers';
import { s3MediaUploadSlice } from './reducers/fileUpload/s3MediaUploadSlice';

const appReducer = combineReducers<storeTypes.IStore>({
   // Login
   login: loginSlice.reducer,
   forgetPassword: forgetPasswordSlice.reducer,
   setPassword: setPasswordSlice.reducer,
   emailVerification: emailVerificationSlice.reducer,

   // Common
   common: commonSlice.reducer,
   search: searchSlice.reducer,
   getSpecialities: getSpecialitiesSlice.reducer,
   hippaComplianceData: hippaComplianceSlice.reducer,
   // Toast
   toastController: toastControllerSlice.reducer,

   // File upload
   s3FileUploadData: s3FileUploadSlice.reducer,
   //feed
   postCreateAndEdit: postCreateAndEditSlice.reducer,
   feedPollOption: feedPollOptionSlice.reducer,
   feedPollUsersList: feedPollUsersListSlice.reducer,
   feedPostList: feedPostListSlice.reducer,
   feedMeditation: feedMeditationSlice.reducer,
   // onc Drive
   oncDriveList: oncDriveListSlice.reducer,
   oncDriveSharedList: oncDriveSharedListSlice.reducer,
   oncDriveSharedUsersList: oncDriveSharedUserListSlice.reducer,
   oncDriveAddFolder: oncDriveAddFolderSlice.reducer,
   oncDriveViewFile: oncDriveViewFileSlice.reducer,
   oncDriveDeleteFolderAndFiles: oncDriveDeleteFolderAndFilesSlice.reducer,
   // -- Drive Share
   oncDriveShare: oncDriveShareSlice.reducer,
   oncDriveUnShareUser: oncDriveUnShareSlice.reducer,
   oncDriveMyClinicPatientList: oncDriveMyClinicPatientListSlice.reducer,

   // Medical Enq
   medicalEnquiresList: medicalEnquiresListSlice.reducer,
   medicalEnqMyClinicPhysList: medicalEnqMyClinicPhysListSlice.reducer,
   medicalEnqAdd: medicalEnqAddSlice.reducer,
   medicalEnqUpdate: medicalEnqUpdateSlice.reducer,
   medicalEnqGetOne: medicalEnqGetOneSlice.reducer,
   medicalEnqAddComment: medicalEnqAddCommentSlice.reducer,
   medicalEnqUpdateComment: medicalEnqUpdateCommentSlice.reducer,
   medicalEnqCommentsList: medicalEnqCommentsListSlice.reducer,
   medicalEnqDocumentsList: medicalEnqDocumentsListSlice.reducer,
   medicalEnqViewDocument: medicalEnqViewDocumentSlice.reducer,
   medicalEnqUpdateDocument: medicalEnqUpdateDocumentSlice.reducer,

   // Invite
   userInviteEmail: userInviteEmailSlice.reducer,
   userDetail: userDetailsSlice.reducer,
   userInviteEmailValidation: userInviteEmailValidationSlice.reducer,

   // video Library
   videoLibraryList: videoLibraryListSlice.reducer,
   videoLibraryCategoryList: videoLibraryCategoryListSlice.reducer,
   newStateAdded: newStateAddingSlice.reducer,
   publicPostShare: postShareSlice.reducer,
   feedReactionsAdd: feedReactionsAddSlice.reducer,
   publicPostShareData: publicPostShareSlice.reducer,
   rateWriteData: rateWriteSlice.reducer,
   addDailyCheckLog: addDailyCheckLogSlice.reducer,
   peopleNearMe: peopleNearMeListSlice.reducer,
   showPatientFeed: showPatientFeedSlice.reducer,
   orgUserEduLib: orgUserEduLibSlice.reducer,

   // Profile
   profilePhoneSection: phoneSectionPostSlice.reducer,
   profileOtpSection: otpSectionPostSlice.reducer,
   profileResendOtpSection: resendOtpSectionPostSlice.reducer,
   profileDobSection: dobSectionPostSlice.reducer,
   profileAddressSection: addressSectionPostSlice.reducer,
   fillProfileSubSpeciality: fillProfileSubSpecialitySlice.reducer,
   changePassword: changePasswordSlice.reducer,
   userGetCountries: userGetCountriesSlice.reducer,
   blockUnblockUser: blockUnblockUserSlice.reducer,

   // Addendum
   addendum: addendumPostSlice.reducer,
   updateAddendum: addendumUpdatePostSlice.reducer,

   // Clinic
   oncoKlinic: oncoKlinicSlice.reducer,
   myClinicPhysician: myClinicPhysiciansSlice.reducer,
   myClinicPatients: myClinicPatientsListSlice.reducer,
   clinicalNotesList: clinicalNotesListSlice.reducer,
   clinicalNotesAdd: clinicalNotesAddSlice.reducer,
   clinicPhysicianAdmin: getPhysicianAdminSlice.reducer,
   clinicJoinRequests: clinicJoinRequestSlice.reducer,
   acceptJoinClinic: acceptClinicReqSlice.reducer,
   acceptJoinClinicPat: acceptClinicReqPatSlice.reducer,
   clinicWorkHours: workingHoursSlice.reducer,
   clinicAdmins: clinicAdminsSlice.reducer,
   modalUpdateClinicTrial: modalUpdateClinicTrialSlice.reducer,
   modalGetClinics: modalGetClinicsSlice.reducer,
   modalJoinClinics: modalJoinClinicsSlice.reducer,
   // -- Clinic Invite
   clinicGetPhy: getPhysicianListSlice.reducer,
   clinicGetPhyInvite: getPhysicianInviteSlice.reducer,
   clinicDeletePhysician: deleteClinicPhysiciansSlice.reducer,
   clinicInvitePhysician: inviteClinicPhysiciansSlice.reducer,
   clinicPatients: clinicPatientsSlice.reducer,
   clinicInvitePatients: clinicInvitePatientsSlice.reducer,
   clinicInvitePatientsReq: clinicInvitePatientReqSlice.reducer,
   getClinicGroups: getClinicGroupsSlice.reducer,
   getClinicAdminInvites: getClinicAdminInvitesSlice.reducer,
   updateClinicAdminInviteStatus: updateClinicAdminInviteStatusSlice.reducer,
   leaveClinic: leaveClinicSlice.reducer,
   deleteClinicalTrial: deleteClinicalTrialSlice.reducer,
   // -- Clinic Video
   addClinicVideos: addClinicVideosSlice.reducer,
   deleteClinicVideos: deleteClinicVideosSlice.reducer,
   clinicVideos: clinicVideosSlice.reducer,
   assignVideo: assignVideoSlice.reducer,
   assignedVideo: assignedVideoSlice.reducer,
   assignClinicVideo: assignClinicVideoSlice.reducer,
   // -- Request
   myClinicPhyReq: myClinicPhyReqSlice.reducer,
   myClinicPhyCancelReq: myClinicPhyCancelReqSlice.reducer,

   // Clinic Admin
   addClinicAdmin: addClinicAdminSlice.reducer,
   deleteClinicAdmin: deleteClinicAdminSlice.reducer,
   clinicAdmin: clinicAdminSlice.reducer,
   clinicAuxiliary: clinicAuxiliarySlice.reducer,
   clinicInfoEdit: clinicInfoEditSlice.reducer,
   clinicPhyInviteIsExist: clinicPhyInviteIsExistSlice.reducer,
   clinicDeleteAuxiliary: clinicDeleteAuxiliarySlice.reducer,
   clinicPatientBulkImport: clinicPatientBulkImportSlice.reducer,
   // Clinic Staff
   addClinicStaff: addClinicStaffSlice.reducer,
   deleteClinicStaff: deleteClinicStaffSlice.reducer,
   clinicStaff: clinicStaffSlice.reducer,
   addAuxiliaryStaffModel: addAuxiliaryStaffSlice.reducer,

   // Drug Lookup
   getDrugsList: getDrugsListSlice.reducer,
   getOneDrugsList: getOneDrugsListSlice.reducer,
   getCancerSpecific: drugSpecificListSlice.reducer,

   // OncoKlinic
   addMyPatient: addPatientSlice.reducer,
   summary: summaryPostSlice.reducer,
   updateSummary: summaryUpdatePostSlice.reducer,
   getOneSummary: getOneSummarySlice.reducer,
   cancerType: cancerTypeListSlice.reducer,
   secondaryCancerType: secondaryCancerTypeListSlice.reducer,
   patientMedicationList: patientMedicationsSlice.reducer,
   educationVideos: educationVideosSlice.reducer,
   documentList: documentListSlice.reducer,
   consentList: consentListSlice.reducer,
   myTreatingPhysician: myTreatingPhysiciansSlice.reducer,
   myPatients: myPatientsListSlice.reducer,
   lineOfTherapyList: lineOfTherapySlice.reducer,
   coMorbiditiesList: coMorbiditiesSlice.reducer,
   meditationRpmDeviceList: patientMeditationRpmDeviceSlice.reducer,
   meditationList: meditationSlice.reducer,
   morbiditiesList: morbiditiesSlice.reducer,
   isExistingClinicInvite: isExistingClinicInviteSlice.reducer,
   clinicWorkingHours: clinicWorkingHoursSlice.reducer,
   userOncoklinic: userOncoklinicSlice.reducer,
   oncKlinicMeeting: oncKlinicMeetingSlice.reducer,

   // -- OncoKlinic Devices
   devicesList: getDeviceSlice.reducer,
   deviceClinicNoteComments: deviceClinicNotesCommentsSlice.reducer,
   deviceGetMinAndMax: deviceGetMinAndMaxSlice.reducer,
   deviceBloodPressure: deviceBloodPressureSlice.reducer,
   deviceSmartWeight: deviceSmartWeightSlice.reducer,

   // -- Health Records
   healthRecords: healthRecordSlice.reducer,
   healthRecordAdd: healthRecordAddSlice.reducer,
   // -- Symptom
   addSymptom: addSymptomSlice.reducer,
   symptomList: symptomListSlice.reducer,
   reportableSymptomList: reportableSymptomSlice.reducer,
   reportableSymptomGoList: reportableSymptomGoListSlice.reducer,
   symptomConsent: symptomConsentListSlice.reducer,
   symptomMonitoringComments: symptomMonitoringCommentsSlice.reducer,
   oncKlinicSymptomClinic: oncKlinicSymptomClinicSlice.reducer,
   oncKlinicSymptom: oncKlinicSymptomSlice.reducer,

   // -- RPM Dashborad
   rpmDashboardList: rpmDashboardSlice.reducer,
   rpmIntervalRemainder: rpmIntervalRemainderSlice.reducer,
   // Tele Medicine
   teleMedicineList: teleMedicineListSlice.reducer,
   myPhysician: myPhysicianSlice.reducer,

   // Schedule
   scheduleList: getScheduleSlice.reducer,
   checkScheduleBlock: checkScheduleBlockSlice.reducer,
   addSchedule: addScheduleSlice.reducer,
   checkAppointmentAvailability: checkAppointmentAvailabilitySlice.reducer,
   // Appointment
   physicianSchedule: physicianScheduleSlice.reducer,
   appointmentSchedule: appointmentScheduleSlice.reducer,
   viewAppointment: viewAppointmentSlice.reducer,
   updateAppointment: updateAppointmentSlice.reducer,
   getAppointmentComment: getAppointmentCommentSlice.reducer,
   getScheduledPhysicianList: getScheduledPhysicianSlice.reducer,
   joinCallAppointment: joinCallAppointmentSlice.reducer,
   addEditAppointmentComment: addEditAppointmentCommentSlice.reducer,
   videoCallUpdate: videoCallUpdateSlice.reducer,
   viewAppointmentDocument: viewAppointmentDocumentSlice.reducer,
   updateAppointmentDocument: updateAppointmentDocumentSlice.reducer,
   videoConnection: videoCallConnectionSlice.reducer,

   // Chat
   convoList: convoListSlice.reducer,
   conversations: conversationSlice.reducer,
   conversationMessageAddGet: conversationMessageAddGetSlice.reducer,

   // Users List & connection
   allUsersList: getAllUsersListSlice.reducer,
   pendingRequests: pendingRequestListSlice.reducer,
   inviteRequests: inviteRequestListSlice.reducer,
   acceptConnection: acceptConnectionPostSlice.reducer,
   connectionRequest: connectionRequestPostSlice.reducer,
   getAllConnection: getAllConnectionsSlice.reducer,
   connectionInviteDelete: connectionInviteDeleteSlice.reducer,
   connectionRequestDelete: connectionReqDeleteSlice.reducer,
   deleteAllConnection: deleteMyConnectionSlice.reducer,

   // onco meditation
   oncoMeditation: oncoMeditationSlice.reducer,

   // Notifications
   notificationCount: notificationCountSlice.reducer,
   notificationList: notificationListSlice.reducer,
   notificationRead: notificationReadSlice.reducer,
   notificationAllRead: notificationReadAllSlice.reducer,
   notificationSettingsList: notificationSettingsListSlice.reducer,
   notificationAddDeviceToken: notificationAddDeviceTokenSlice.reducer,
   // Custom Notifications
   customNotification: customNotificationSlice.reducer,
   customNotificationAdd: customNotificationAddSlice.reducer,

   // Wallet
   wallet: walletSlice.reducer,
   physicianWallet: walletPhysicianSlice.reducer,
   walletOffers: walletOffersSlice.reducer,
   physicianReward: physicianRewardSlice.reducer,
   walletBalanceGet: walletBalanceGetSlice.reducer,

   // Pill Remainder
   pillRemainderNotifyStatusAdd: pillRemainderNotifyStatusAddSlice.reducer,
   pillRemainderNotifyStatusEdit: pillRemainderNotifyStatusEditSlice.reducer,
   pillRemainderMediStatusUpdate: pillRemainderMediStatusUpdateSlice.reducer,
   pillRemainderNotificationGetOne: pillRemainderNotificationGetOneSlice.reducer,
   pillRemainderGetOne: pillRemainderGetOneSlice.reducer,
   pillRemainderDelete: pillRemainderDeleteSlice.reducer,
   pillRemainderNonCancerDelete: pillRemainderNonCancerDeleteSlice.reducer,
   pillRemainderEdit: pillRemainderEditSlice.reducer,
   pillRemainderList: pillRemainderListSlice.reducer,
   pillRemainderAdd: pillRemainderAddSlice.reducer,
   medicationsList: medicationListSlice.reducer,
   pillRemainderAllPillsList: pillRemainderAllPillsListSlice.reducer,

   // Locum Services
   settingGetStateAndTravelList: settingGetStateAndTravelListSlice.reducer,
   settingAddStateAndTravel: settingAddStateAndTravelSlice.reducer,
   practiseAdminLocumList: practiseAdminLocumListSlice.reducer,
   practiseAdminPhySearch: practiseAdminLocumPhySearchSlice.reducer,
   settingLocumGetStateAndTravelList: settingLocumGetStateAndTravelListSlice.reducer,
   locumServiceAddUpdate: locumServiceAddUpdateSlice.reducer,
   bookServices: bookServiceSlice.reducer,
   locumMonthWiseServiceList: locumMonthWiseServiceListSlice.reducer,
   locumGetOneService: locumGetOneServiceSlice.reducer,
   locumServiceDelete: locumServiceDeleteSlice.reducer,

   // AskADoc
   askADocList: askADocListSlice.reducer,
   askADocExampleQusList: askADocExampleQusListSlice.reducer,
   askADocCaseDetails: getAskADocCaseDetailsSlice.reducer,
   askADocViewAnswers: askADocViewAnswersSlice.reducer,
   askADocCreateRequest72Hrs: askADocCreateRequest72HrsSlice.reducer,
   singleAskADocFeed: singleAskADocFeedListSlice.reducer,
   askADocDisclaimerData: askADocDisclaimerSlice.reducer,
   askADocUserProfileData: getUserProfileSlice.reducer,
   askADocPhyPanelRequest: askADocPhyPanelRequestSlice.reducer,
   askADocPhyPanelList: askADocPhyPanelListSlice.reducer,
   askADocPrePopulateCreateRequestData: askADocPrePopulateCreateRequestDataSlice.reducer,
   // -- Panels
   panelsList: panelsListSlice.reducer,
   panelsPhyList: panelsPhyListSlice.reducer,
   // -- Phy List
   askPhyList: askPhyListSlice.reducer,
   askPhyListCount: askPhyCountSlice.reducer,
   // -- Estimate
   askADocEstimate: askADocEstimateSlice.reducer,

   // AskADoc Feed
   feedConnectionStatistics: feedConnectionStatisticsSlice.reducer,
   singleFeed: singleFeedListSlice.reducer,
   askADocFeedReaction: askADocFeedReactionSlice.reducer,
   tagUsersList: tagUsersListSlice.reducer,
   getReplyComments: getReplyCommentsListSlice.reducer,
   commentsReaction: commentsReactionSlice.reducer,
   reportPosData: reportPostSlice.reducer,
   topState: getTopStateSlice.reducer,
   topCancer: getTopCancerSlice.reducer,
   askADocCheckRequiredFields: getAskADocCaseDetailsCheckRequiredFieldsSlice.reducer,

   // Comments
   commentReplyAdd: commentsReplyAddSlice.reducer,
   commentReplyUpdate: commentsReplyUpdateSlice.reducer,
   commentReplyDelete: commentsReplyDeleteSlice.reducer,
   getCommentsList: getCommentsListSlice.reducer,
   commentsAddUpdate: commentsAddUpdateSlice.reducer,
   deletePostComment: deletePostCommentSlice.reducer,
   commentsReactionsList: commentsReactionsListSlice.reducer,

   //second opinion
   secondOpinionPhyList: secondOpinionPhyListSlice.reducer,
   secOpinionShortPhyList: secOpinionShortPhyListSlice.reducer,
   secondOpinionNearbyPhyList: secondOpinionNearbyPhyListSlice.reducer,

   // Groups Related
   supportGroups: supportGroupsSlice.reducer,
   supportMyGroups: supportMyGroupsSlice.reducer,
   joinGroup: joinGroupSlice.reducer,

   // RPM Prescriptions
   medicationList: medicationSlice.reducer,
   editPrescription: rpmPrescriptionEditSlice.reducer,
   getTreatingPhysician: getTreatingPhysicianSlice.reducer,
   getOnePrescription: getOnePrescriptionSlice.reducer,
   getLinesOfTherapyHistory: getLineOfTherapyHistorySlice.reducer,
   getClinicPhysician: getClinicPhysicianSlice.reducer,
   rpmPrescriptionList: rpmPrescriptionSlice.reducer,
   rpmPendingPrescriptionList: rpmPendingPrescriptionSlice.reducer,
   rpmPrescriptionUpdate: rpmPrescriptionUpdateSlice.reducer,
   rpmPrescriptionAdd: rpmPrescriptionAddSlice.reducer,

   // My Profile
   deleteCertificate: deleteCertificateSlice.reducer,
   deleteExperience: deleteExperienceSlice.reducer,
   deleteEducation: deleteEducationSlice.reducer,
   patientAdvocate: patientAdvocateSlice.reducer,
   getPhysicians: getPhysiciansSlice.reducer,
   profileSocialHistories: profileSocialHistoriesSlice.reducer,
   socialHistories: socialHistoriesSlice.reducer,
   profileSkills: profileSkillsSlice.reducer,
   profilePublication: profilePublicationSlice.reducer,
   mobileVerifyOtpData: otpProcessSlice.reducer,
   updatePhysician: updatePhysicianSlice.reducer,
   deletePhysician: deletePhysicianSlice.reducer,
   paypalAndZelleUpdate: paypalAndZelleUpdateSlice.reducer,
   // -- Care Giver
   getCaregiverDetails: getCaregiverDetailsSlice.reducer,
   updateCaregiver: updateCaregiverSlice.reducer,
   deleteCaregiver: caregiverDeleteSlice.reducer,
   getPrimaryDiseases: getPrimaryDiseaseSlice.reducer,

   // Family Members
   getFamilyMembers: getFamilyMembersSlice.reducer,
   updateFamilyMember: updateFamilyMemberSlice.reducer,
   deleteFamilyMember: deleteFamilyMemberSlice.reducer,
   panelRequest: panelRequestSlice.reducer,
   getDiseases: getDiseasesSlice.reducer,
   getFamilyMemberTypes: getFamilyMemberTypesSlice.reducer,

   // State
   getPatientStateData: getPatientStateSlice.reducer,
   getPhysicianStateData: getPhysicianStateSlice.reducer,
   userStateData: userStateSlice.reducer,

   // My Service
   myServiceList: myServiceListSlice.reducer,
   myServiceSpeciality: myServiceSpecialityListSlice.reducer,
   myServicesUpdateData: myServiceUpdateSlice.reducer,
   addMyServicesSpecialtiesData: addMyServicesSpecialtiesSlice.reducer,
   myServicePhysician: myServicePhyListSlice.reducer,

   // Modal
   getCancers: modalCancersSlice.reducer,
   modalGetAssociatedPhysician: modalGetAssociatedPhysicianSlice.reducer,
   modalAddAssociatedPhysician: modalAddAssociatedPhysicianSlice.reducer,
   modalDescriptionUpdate: modalDescriptionUpdateSlice.reducer,
   modalAddFamilyMember: modalAddFamilyMemberSlice.reducer,
   modalUpdateCertificate: modalUpdateCertificateSlice.reducer,
   modalAddCaregiver: modalAddCaregiverSlice.reducer,
   modalupdateEducationTraining: modalUpdateEducationTrainingSlice.reducer,
   modalUpdateCoMorbidities: modalUpdateCoMorbiditiesSlice.reducer,
   modalUpdateFamilyHistories: modalUpdateFamilyHistoriesSlice.reducer,
   modalUpdateExperience: modalUpdateExperienceSlice.reducer,
   modalInviteViaMail: modalInviteViaMailSlice.reducer,
   modalUpdatePatientInsurance: modalUpdatePatientInsuranceSlice.reducer,
   modalUpdateMedication: modalUpdateMedicationSlice.reducer,
   nurseGetAssociatedPhysician: nurseGetAssociatedPhysicianSlice.reducer,
   physicianInfo: physicianInfoSlice.reducer,

   // Recruiter
   recruiterAdminJob: recruiterAdminJobSlice.reducer,
   // Recruiter - Physician
   physicianJobs: physicianJobsSlice.reducer,
   physicianJobFavourite: physicianJobFavouriteSlice.reducer,
   // Save Post
   savedPostObject: savedPostSlice.reducer,
   // google login
   googleLogin: googleLoginSlice.reducer,
   // linked in
   linkedInLogin: linkedInLoginSlice.reducer,
   //support group
   groupsInfoDetails: groupsInfoDetailsSlice.reducer,
   supportGroup: supportGroupSlice.reducer,
   // global search
   globalSearch: globalSearchListSlice.reducer,
   // recent search
   recentSearch: recentSearchSlice.reducer,
   //admin notifications
   adminNotification: adminNotificationSlice.reducer,
   //profile picture
   profilePicture: profilePictureSlice.reducer,
   //npi nmumber
   phyNpiNumberVerify: phyNpiNumberVerifySlice.reducer,
   // user register
   userRegister: userRegisterSlice.reducer,
   //network
   searchNetworkAllUsers: searchNetworkAllUsersSlice.reducer,

   // Subscription Details
   subscriptionDetails: subscriptionDetailsSlice.reducer,

   //vdocipher
   vdocipher: vdocipherSlice.reducer,
   // File Upload
   s3MediaUploadState: s3MediaUploadSlice.reducer,
   // event track
   eventTrack: eventTrackSlice.reducer,
   // treatment method
   treatmentMethod: treatmentMethodSlice.reducer,
   //feed share link
   feedShareLink: feedShareLinkSlice.reducer,
   feedShareLinkUser: feedShareLinkUserSlice.reducer,
   // announcement
   announcement: announcementSlice.reducer,
   // clinical trials
   clinicalTrials: clinicalTrialsSlice.reducer,
   clinicalTrialsStudyType: clinicalTrialsStudyTypeSlice.reducer,
   clinicalTrialsStudyPhase: clinicalTrialsStudyPhaseSlice.reducer,
   clinicalTrialsStates: clinicalTrialsStatesSlice.reducer,
   clinicalTrialsCancerSpecific: clinicalTrialsCancerSpecificSlice.reducer,
   // hash tag
   hashTagMasterList: hashTagListSlice.reducer,
   // daily checkins
   dailyCheckList: dailyCheckListSlice.reducer,
   dailyCheckIns: dailyCheckInsSlice.reducer,
   // hide this post
   hidePost: hidePostSlice.reducer,
   // community guidelines
   communityGuidelines: communityGuidelinesSlice.reducer,
   // medication saving
   medicationSaving: medicationSavingSlice.reducer,
   diagnosis: diagnosisSlice.reducer,
   latino: latinoSlice.reducer,
   staging: stagingSlice.reducer,
   race: raceSlice.reducer,
   cancerStatus: cancerStatusSlice.reducer,
   ecog: ecogSlice.reducer,
   medicalHistory: medicalHistorySlice.reducer,
   geneticTesting: geneticTestingSlice.reducer,
   serviceOffered: serviceOfferedSlice.reducer,
   orgName: orgNameSlice.reducer,
   populationServed: populationServedSlice.reducer,
});
//Clear set persist
const rootReducer = (state: any, action: any) => {
   if (action.type === 'login/logOutSuccess') {
      state = undefined;
   }
   return appReducer(state, action);
};

export { rootReducer };
