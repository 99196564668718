import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import {
   MODAL_ADD_ASSOCIATED_PHYSICIAN_SAGA,
   MODAL_GET_ASSOCIATED_PHYSICIAN_SAGA,
   NURSE_GET_ASSOCIATED_PHYSICIAN_SAGA,
} from 'reduxToolKit/actions';
import {
   commonSlice,
   modalAddAssociatedPhysicianSlice,
   modalGetAssociatedPhysicianSlice,
   nurseGetAssociatedPhysicianSlice,
} from 'reduxToolKit/reducers';
import { associatedPhysicianSagaTypes } from 'types';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const {
   getAssociatedPhysicianRequest,
   getAssociatedPhysicianSuccess,
   getAssociatedPhysicianFailed,
} = modalGetAssociatedPhysicianSlice.actions;
const {
   nurseGetAssociatedPhysicianRequest,
   nurseGetAssociatedPhysicianSuccess,
   nurseGetAssociatedPhysicianFailed,
} = nurseGetAssociatedPhysicianSlice.actions;
const {
   addAssociatedPhysicianRequest,
   addAssociatedPhysicianSuccess,
   addAssociatedPhysicianFailed,
} = modalAddAssociatedPhysicianSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

//Modal - getAssociatedExceptTreatingPhysicians Saga
const modalGetAssociatedPhysicianSaga = function* (
   physicianTypePayload: PayloadAction<associatedPhysicianSagaTypes.IModalAssociatedPhysicianSagaPaylod>
) {
   yield put(getAssociatedPhysicianRequest(physicianTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = physicianTypePayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         goUrl,
         params,
         ...API.getAssociatedExceptTreatingPhysicians,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const physicianList = {
            list: responseData?.data?.list || [],
            total_records: responseData?.data?.total_records || 0,
            message: responseData?.message || '',
         };
         yield put(getAssociatedPhysicianSuccess(physicianList));
      } else {
         yield put(getAssociatedPhysicianFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

//addAssociatedPhysician Saga
const modalAddAssociatedPhysicianSaga = function* (
   payloadState: PayloadAction<associatedPhysicianSagaTypes.IModalAddAssociatedPhysicianSagaPayload>
) {
   yield put(addAssociatedPhysicianRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const data = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, data, ...API.addAssociatedPhysicians });
      if (response.status === 200) {
         const resObj = {
            message: response.data?.message || '',
         };
         yield put(addAssociatedPhysicianSuccess(resObj));
      } else {
         yield put(addAssociatedPhysicianFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

//Nurse - getAssociatedPhysician Saga
const nurseGetAssociatedPhysicianSaga = function* (
   getphysicianTypePayload: PayloadAction<associatedPhysicianSagaTypes.INurseAssociatedPhysicianSagaPayload>
) {
   yield put(nurseGetAssociatedPhysicianRequest(getphysicianTypePayload.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = getphysicianTypePayload.payload;
      const response: AxiosResponse = yield apiCall({
         checkUser,
         goUrl,
         params,
         ...API.getAssociatedTreatingPhysicians,
      });
      if (response.status === 200) {
         const responseData = response.data;
         const getphysicianList = {
            list: responseData?.data?.list || [],
            total_records: responseData?.data?.total_records || 0,
            message: responseData?.message || '',
         };
         yield put(nurseGetAssociatedPhysicianSuccess(getphysicianList));
      } else {
         yield put(nurseGetAssociatedPhysicianFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takeAssociatedPhysicianRequest = function* () {
   yield takeEvery(MODAL_GET_ASSOCIATED_PHYSICIAN_SAGA, modalGetAssociatedPhysicianSaga);
   yield takeEvery(MODAL_ADD_ASSOCIATED_PHYSICIAN_SAGA, modalAddAssociatedPhysicianSaga);
   yield takeEvery(NURSE_GET_ASSOCIATED_PHYSICIAN_SAGA, nurseGetAssociatedPhysicianSaga);
};
