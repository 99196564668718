import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { put, takeEvery } from 'redux-saga/effects';
import { GET_ALL_CONNECTIONS } from 'reduxToolKit/actions';
import { commonSlice, getAllConnectionsSlice } from 'reduxToolKit/reducers';
import { networkSagaTypes } from 'types';
import { messages } from 'utils/constants/messages';
import { apiCall } from 'utils/helpers/apiCall';
import { apiRoutes as API } from 'utils/helpers/apiRoutes';

const { getAllConnectionsRequest, getAllConnectionsSuccess, getAllConnectionsFailed } = getAllConnectionsSlice.actions;
const { exceptionHandlerRequest } = commonSlice.actions;

const getAllConnections = function* (payloadState: PayloadAction<networkSagaTypes.IGetMyConnectionsSagaPayload>) {
   yield put(getAllConnectionsRequest(payloadState.payload));
   try {
      const checkUser = true;
      const goUrl = true;
      const params = payloadState.payload;
      const response: AxiosResponse = yield apiCall({ checkUser, goUrl, params, ...API.getMyConnections });
      if (response.status === 200) {
         const responseData = response.data;
         const connectionList = {
            data: responseData.data,
            message: response.data?.message || messages.success,
         };
         yield put(getAllConnectionsSuccess(connectionList));
      } else {
         yield put(getAllConnectionsFailed(response.data));
      }
   } catch (error) {
      yield put(exceptionHandlerRequest(error));
   }
};

export const takegetAllConnections = function* () {
   yield takeEvery(GET_ALL_CONNECTIONS, getAllConnections);
};
