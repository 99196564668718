import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { modalClinicTrialReducerTypes, modalClinicTrialSagaTypes } from 'types';

const initialState: modalClinicTrialReducerTypes.IModalClinicTrialUpdateReducer = {
   error: '',
   message: '',
   isLoading: false,
};

export const modalUpdateClinicTrialSlice = createSlice({
   name: 'modalUpdateClinicTrial',
   initialState: initialState,
   reducers: {
      modalUpdateClinicTrialRequest: (
         state,
         action: PayloadAction<modalClinicTrialSagaTypes.IModalClinicTrialSagaPayload>
      ) => {
         return {
            ...state,
            ...action.payload,
            isLoading: true,
         };
      },
      modalUpdateClinicTrialSuccess: (
         state,
         action: PayloadAction<modalClinicTrialReducerTypes.IModalClinicTrialUpdateSuccessPayload>
      ) => {
         const { payload } = action;
         return {
            ...state,
            message: payload.message,
            error: '',
            isLoading: false,
         };
      },
      modalUpdateClinicTrialFailed: (
         state,
         action: PayloadAction<modalClinicTrialReducerTypes.IModalClinicTrialUpdateFailedPayload>
      ) => {
         return {
            ...state,
            error: action.payload.error,
            message: '',
            isLoading: false,
         };
      },
      modalUpdateClinicTrialReset: () => {
         return {
            ...initialState,
         };
      },
   },
});
