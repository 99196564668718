import React, { memo, useState } from 'react';

import { Box, Button, Container, Divider, Grid, IconButton, Paper, Popover, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ClinicalShare from 'assets/icons/clinical-share.svg';
import ClinicalInterested from 'assets/icons/clinical_interested.svg';
import feedShareCopy from 'assets/icons/feedShareCopy.svg';
import feedShareFacebook from 'assets/icons/feedShareFacebook.svg';
import feedShareTwitter from 'assets/icons/feedShareTwitter.svg';
import feedShareWhatsapp from 'assets/icons/feedShareWhatsapp.svg';
import ViewMoreIcon from 'assets/icons/view-more.svg';
import NoData from 'component/widget/NoData';
import { useDispatch } from 'react-redux';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { toastControllerSlice } from 'reduxToolKit/reducers';
import { CommonConstants } from 'utils/constants/common';
import { getAgeGroup, getStatusClassName } from 'utils/helpers/clinicalTrialsHelpers';

const ClinicalTrialsListItem = memo((props: any) => {
   const dispatch = useDispatch();
   const item = props?.item;

   const [anchorElList, setAnchorElList] = React.useState(null);
   const [anchorElLocation, setAnchorElLocation] = React.useState(null);

   const [conditionsViewMore, setConditionsViewMore] = useState<boolean>(false);
   const [interventionsViewMore, setInterventionsViewMore] = useState<boolean>(false);
   const [conditionsAndInterventionsList, setConditionsAndInterventionsList] = useState<any[]>([]);
   const [locationsList, setLocationsList] = useState<any[]>([]);
   const [popoverOpenShare, setPopoverOpenShare] = useState<any>(null);
   const [shareLink, setShareLink] = useState<string>('');

   const { triggerToastAction } = toastControllerSlice.actions;

   const handleClickList = (event: any) => {
      setAnchorElList(event.currentTarget);
   };

   const handleCloseList = () => {
      setAnchorElList(null);
   };

   const handleClickLocation = (event: any) => {
      setAnchorElLocation(event.currentTarget);
   };

   const handleCloseLocation = () => {
      setAnchorElLocation(null);
   };

   const handleShare = (e: any, copyLinkShare: string) => {
      setShareLink(copyLinkShare);
      setPopoverOpenShare(e.currentTarget);
   };

   const handleClosePop = () => {
      setPopoverOpenShare(null);
   };

   const clickCopyLink = (dynamicLink: string) => {
      if (dynamicLink) {
         navigator?.clipboard?.writeText(dynamicLink);
      }
      const toastData = {
         messageType: CommonConstants.Toast.types.SUCCESS,
         message: 'Link copied to clipboard',
      };
      dispatch(triggerToastAction(toastData));

      handleClosePop();
   };

   return (
      <Grid item key={props?.i}>
         {/* Note: active card className --- active-card */}
         <Paper variant="outlined" className="card">
            <Box>
               <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={6}>
                     <Container maxWidth={item?.is_interested === undefined ? false : 'sm'}>
                        <Box py={2}>
                           <Typography className="card-title">{item?.title || ''}</Typography>
                           <Box mt={1}>
                              <Grid container spacing={1} alignItems="center">
                                 <Grid item>
                                    <Grid container alignItems="center">
                                       <Grid item>
                                          <Typography className="card-sts">Status : </Typography>
                                       </Grid>
                                       <Grid item>
                                          <Typography
                                             className={`card-sts-red-green ${getStatusClassName(item?.status || '')}`}
                                          >
                                             {item?.status || '-'}
                                          </Typography>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                                 <Grid item>
                                    <Grid container spacing={2} alignItems="center">
                                       <Grid item>
                                          <Box ml={2}>
                                             <Typography className="trails-title">
                                                Sex :{' '}
                                                <Typography component="span" className="trails-content">
                                                   {item?.gender || '-'}
                                                </Typography>
                                             </Typography>
                                          </Box>
                                       </Grid>
                                       <Grid item>
                                          <Box>
                                             <Typography className="trails-title">
                                                Age :{' '}
                                                {JSON.parse(item?.age || '[]')?.length > 0
                                                   ? JSON.parse(item?.age || '[]').map((ageItem: any, i: number) => {
                                                        const length = JSON.parse(item?.age || '[]')?.length || 0;
                                                        return (
                                                           <Typography
                                                              key={i}
                                                              component="span"
                                                              className="trails-content"
                                                           >
                                                              {getAgeGroup(ageItem)}
                                                              {i === length - 1 ? `` : `, `}
                                                           </Typography>
                                                        );
                                                     })
                                                   : '-'}
                                             </Typography>
                                          </Box>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Box>
                     </Container>
                  </Grid>
                  <Grid item xs={6}>
                     <Grid container direction="column" spacing={2} justify="center" alignItems="center">
                        <Grid item>
                           <Box px={2} style={{ display: 'flex' }}>
                              <Button
                                 className="im-interested-share mr-3"
                                 onClick={(e: any) => {
                                    handleShare(e, item?.dynamic_link || '');
                                 }}
                              >
                                 <div className="share-icon">
                                    <img src={ClinicalShare} alt="share" className="mr-2" />
                                    <span>Share</span>
                                 </div>
                              </Button>
                              {item?.is_interested === 1 ? (
                                 <Box className="im-interested-add">
                                    <img src={ClinicalInterested} alt={'img'} />
                                    Marked as Interested
                                 </Box>
                              ) : item?.is_interested === 0 ? (
                                 <Button
                                    className="im-interested"
                                    onClick={() => {
                                       props?.clickInterested(item?.id || 0);
                                    }}
                                 >
                                    I’m Interested
                                 </Button>
                              ) : null}
                           </Box>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Box>
            <Box p={2}>
               <Divider />
               <Box py={3} px={1}>
                  <Grid container direction="column" spacing={4}>
                     <Grid item>
                        <Grid container justify="space-between">
                           <Grid item xs={4}>
                              <Box style={{ marginRight: '5px' }}>
                                 <Typography className="trails-title">Phase</Typography>
                                 <Box mt={1}>
                                    <Grid container direction="column">
                                       {JSON.parse(item?.phase || '[]')?.length > 0 ? (
                                          JSON.parse(item?.phase || '[]').map((phaseItem: any, i: number) => {
                                             return (
                                                <Grid item key={i}>
                                                   <Typography className="trails-content">{phaseItem}</Typography>
                                                </Grid>
                                             );
                                          })
                                       ) : (
                                          <Grid item>
                                             <Typography className="trails-content">-</Typography>
                                          </Grid>
                                       )}
                                    </Grid>
                                 </Box>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box>
                                 <Typography className="trails-title">Study Type</Typography>
                                 <Box mt={1}>
                                    <Grid container direction="column">
                                       <Grid item>
                                          <Typography className="trails-content">{item?.study_type || '-'}</Typography>
                                       </Grid>
                                    </Grid>
                                 </Box>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box style={{ marginLeft: '5px' }}>
                                 <Typography className="trails-title">NCT Number</Typography>
                                 <Box mt={1}>
                                    <Grid container direction="column">
                                       <Grid item>
                                          <Typography className="trails-content">{item?.nct_id || '-'}</Typography>
                                       </Grid>
                                    </Grid>
                                 </Box>
                              </Box>
                           </Grid>
                        </Grid>
                     </Grid>
                     <Grid item>
                        <Grid container justify="space-between">
                           <Grid item xs={4}>
                              <Box style={{ marginRight: '5px' }}>
                                 <Typography className="trails-title">Intervention(s)</Typography>
                                 <Box mt={1}>
                                    <Grid container direction="column" spacing={1}>
                                       {JSON.parse(item?.intervention || '[]')?.length > 0 ? (
                                          JSON.parse(item?.intervention || '[]').map(
                                             (interventionItem: any, i: number) => {
                                                const length = JSON.parse(item?.intervention || '[]')?.length || 0;

                                                if (i <= 5) {
                                                   return (
                                                      <Grid item key={i}>
                                                         {i < 5 ? (
                                                            <Typography className="trails-content">
                                                               {interventionItem}
                                                               {i === length - 1 ? `` : `, `}
                                                            </Typography>
                                                         ) : null}
                                                         {i > 4 ? (
                                                            <Typography
                                                               className="trails-content-view-more view-more-margin hand-i"
                                                               onClick={(e: any) => {
                                                                  handleClickList(e);
                                                                  setInterventionsViewMore(true);
                                                                  setConditionsViewMore(false);
                                                                  setConditionsAndInterventionsList(
                                                                     JSON.parse(item?.intervention || '[]')
                                                                  );
                                                               }}
                                                            >
                                                               <Typography
                                                                  component={'span'}
                                                                  className="text-and-image"
                                                               >
                                                                  {`View more`}
                                                                  <img src={ViewMoreIcon} alt="view-less" />
                                                               </Typography>
                                                            </Typography>
                                                         ) : null}
                                                      </Grid>
                                                   );
                                                } else {
                                                   return null;
                                                }
                                             }
                                          )
                                       ) : (
                                          <Grid item>
                                             <Typography className="trails-content">-</Typography>
                                          </Grid>
                                       )}
                                    </Grid>
                                 </Box>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box>
                                 <Typography className="trails-title">Condition(s)</Typography>
                                 <Box mt={1}>
                                    <Grid container direction="column" spacing={1}>
                                       {(item?.clinical_trial_conditions || [])?.length > 0 ? (
                                          (item?.clinical_trial_conditions || []).map(
                                             (conditionsItem: any, i: number) => {
                                                const length = (item?.clinical_trial_conditions || [])?.length || 0;

                                                if (i <= 5) {
                                                   return (
                                                      <Grid item key={i}>
                                                         {i < 5 ? (
                                                            <Typography className="trails-content">
                                                               {conditionsItem?.name || ''}
                                                               {i === length - 1 ? `` : `, `}
                                                            </Typography>
                                                         ) : null}
                                                         {i > 4 ? (
                                                            <Typography
                                                               className="trails-content-view-more view-more-margin hand-i"
                                                               onClick={(e: any) => {
                                                                  handleClickList(e);
                                                                  setConditionsViewMore(true);
                                                                  setInterventionsViewMore(false);
                                                                  setConditionsAndInterventionsList(
                                                                     item?.clinical_trial_conditions || []
                                                                  );
                                                               }}
                                                            >
                                                               <Typography
                                                                  component={'span'}
                                                                  className="text-and-image"
                                                               >
                                                                  {`View more`}
                                                                  <img src={ViewMoreIcon} alt="view-more" />
                                                               </Typography>
                                                            </Typography>
                                                         ) : null}
                                                      </Grid>
                                                   );
                                                } else {
                                                   return null;
                                                }
                                             }
                                          )
                                       ) : (
                                          <Grid item>
                                             <Typography className="trails-content">-</Typography>
                                          </Grid>
                                       )}
                                    </Grid>
                                 </Box>
                              </Box>
                           </Grid>
                           <Grid item xs={4}>
                              <Box style={{ marginLeft: '5px' }}>
                                 <Typography className="trails-title">Study Design</Typography>
                                 <Box mt={1}>
                                    <Grid container direction="column" spacing={1}>
                                       <Grid item>
                                          <Typography className="trails-content">
                                             {`Allocation : ${item?.study_design_allocation || '-'}`}
                                          </Typography>
                                       </Grid>
                                       <Grid item>
                                          <Typography className="trails-content">
                                             {`Intervention Model : ${item?.study_design_intervention || '-'}`}
                                          </Typography>
                                       </Grid>
                                    </Grid>
                                 </Box>
                              </Box>
                           </Grid>
                        </Grid>
                     </Grid>
                     <Grid item>
                        <Grid container direction="column">
                           <Grid item>
                              <Box>
                                 <Typography className="trails-title">Outcome Measure(s)</Typography>
                                 <Box mt={1}>
                                    <Grid container direction="row" spacing={1}>
                                       {JSON.parse(item?.outcome_measure || '[]')?.length > 0 ? (
                                          JSON.parse(item?.outcome_measure || '[]').map(
                                             (outcomeMeasureItem: any, i: number) => {
                                                const length = JSON.parse(item?.outcome_measure || '[]')?.length || 0;
                                                return (
                                                   <Grid item key={i}>
                                                      <Typography className="trails-content">
                                                         {outcomeMeasureItem}
                                                         {i === length - 1 ? `` : `, `}
                                                      </Typography>
                                                   </Grid>
                                                );
                                             }
                                          )
                                       ) : (
                                          <Grid item>
                                             <Typography className="trails-content">-</Typography>
                                          </Grid>
                                       )}
                                    </Grid>
                                 </Box>
                              </Box>
                           </Grid>
                           <Grid item>
                              <Box className="mt-3">
                                 <Typography className="trails-title">Sponsor(s)</Typography>
                                 <Box mt={1}>
                                    <Typography className="trails-content">{item?.sponsor || ''}</Typography>
                                 </Box>
                              </Box>
                           </Grid>
                           <Grid item>
                              <Box className="mt-3">
                                 <Typography className="trails-title">Collaborator(s)</Typography>
                                 <Box mt={1}>
                                    <Grid container direction="row" spacing={1}>
                                       {JSON.parse(item?.collaborator || '[]')?.length > 0 ? (
                                          JSON.parse(item?.collaborator || '[]').map(
                                             (collaboratorsItem: any, i: number) => {
                                                const length = JSON.parse(item?.collaborator || '[]')?.length || 0;
                                                return (
                                                   <Grid item key={i}>
                                                      <Typography className="trails-content">
                                                         {collaboratorsItem}
                                                         {i === length - 1 ? `` : `, `}
                                                      </Typography>
                                                   </Grid>
                                                );
                                             }
                                          )
                                       ) : (
                                          <Grid item>
                                             <Typography className="trails-content">-</Typography>
                                          </Grid>
                                       )}
                                    </Grid>
                                 </Box>
                              </Box>
                           </Grid>
                           <Grid item>
                              <Box className="mt-4">
                                 <Grid container direction="row" alignItems="center">
                                    <Grid item>
                                       <Typography className="trails-title">Location(s)</Typography>
                                    </Grid>
                                    <Grid item>
                                       <Typography
                                          className="trails-content-view-more location-view-more-margin hand-i"
                                          onClick={(e: any) => {
                                             handleClickLocation(e);
                                             setLocationsList(item?.clinical_trial_locations || []);
                                          }}
                                       >
                                          <Typography component={'span'} className="text-and-image">
                                             {`View`}
                                             <img src={ViewMoreIcon} alt="view-more" />
                                          </Typography>
                                       </Typography>
                                    </Grid>
                                 </Grid>
                              </Box>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </Box>
            </Box>
         </Paper>
         <Box>
            {/* comments and Intervention(s) */}
            <Popover
               id={Boolean(anchorElList) ? 'simple-popover' : undefined}
               open={Boolean(anchorElList)}
               anchorEl={anchorElList}
               onClose={handleCloseList}
               anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
               }}
               transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
               }}
               className="view-more-list-popover"
               disableBackdropClick
               disableEscapeKeyDown
            >
               <Box className="more-box style-7" py={2}>
                  <Grid container direction="row" alignItems="center">
                     <Grid item xs={6}>
                        <Typography className="location-title">
                           {conditionsViewMore ? `Condition(s)` : interventionsViewMore ? `Intervention(s)` : ''}
                        </Typography>
                     </Grid>
                     <Grid item xs={6}>
                        <Box className="close-button" py={1}>
                           <IconButton onClick={handleCloseList}>
                              <CloseIcon />
                           </IconButton>
                        </Box>
                     </Grid>
                  </Grid>
                  <Box className="item-div style-7" px={1}>
                     <Grid container direction="column">
                        {conditionsAndInterventionsList?.length > 0 ? (
                           conditionsAndInterventionsList.map((item: any, i: number) => {
                              return (
                                 <Grid item key={i}>
                                    <Typography className="list-text-bold">{item?.name || item || ''}</Typography>
                                 </Grid>
                              );
                           })
                        ) : (
                           <NoData />
                        )}
                     </Grid>
                  </Box>
               </Box>
            </Popover>
            {/* locations */}
            <Popover
               id={Boolean(anchorElLocation) ? 'simple-popover' : undefined}
               open={Boolean(anchorElLocation)}
               anchorEl={anchorElLocation}
               onClose={handleCloseLocation}
               anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
               }}
               transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
               }}
               className="view-more-locations-popover"
               disableBackdropClick
               disableEscapeKeyDown
            >
               <Box className="more-box style-7" py={2}>
                  <Grid container direction="row" alignItems="center">
                     <Grid item xs={6}>
                        <Typography className="location-title">Location(s)</Typography>
                     </Grid>
                     <Grid item xs={6}>
                        <Box className="close-button" py={1}>
                           <Typography className="location-status">Status</Typography>
                           <IconButton onClick={handleCloseLocation}>
                              <CloseIcon />
                           </IconButton>
                        </Box>
                     </Grid>
                  </Grid>
                  <Box className="item-div style-7" px={1}>
                     <Box className="location-div">
                        {locationsList?.length > 0 ? (
                           locationsList.map((item: any, i: number) => {
                              return (
                                 <Box key={i} className="location-inner-div">
                                    <Box>
                                       <Grid container direction="row" xs={12}>
                                          <Grid item xs={10}>
                                             <Typography className="location-full-text">
                                                {item?.facility || ''}
                                             </Typography>
                                          </Grid>
                                          <Grid item xs={2}>
                                             <Typography className="location-full-text-bold">
                                                {item?.status || ''}
                                             </Typography>
                                          </Grid>
                                       </Grid>
                                    </Box>
                                    <Box className="location-inner-second-div">
                                       <Typography className="location-full-text">{`Location : ${item?.city || ''}${
                                          item?.city ? ', ' : ''
                                       } ${item?.state || ''}${item?.state ? ', ' : ''} ${
                                          item?.country || ''
                                       }`}</Typography>
                                       {/* <Grid container direction="row" xs={12} alignItems="center">
                                          <Grid item xs={1}>
                                             <Typography className="location-full-contact-text">
                                                {`Contact : `}{' '}
                                             </Typography>
                                          </Grid>
                                          {JSON.parse(item?.contacts || '[]')?.length > 0
                                             ? JSON.parse(item?.contacts || '[]').map((contactItem: any, i: number) => {
                                                  return (
                                                     <Grid item xs={11}>
                                                        <Typography component="span" className="location-full-text">
                                                           {`${contactItem?.name || ''}    ${contactItem?.phone || ''}`}
                                                        </Typography>
                                                        <Typography
                                                           component="span"
                                                           className="location-full-sub-contact-text"
                                                        >
                                                           {contactItem?.email || ''}
                                                        </Typography>
                                                     </Grid>
                                                  );
                                               })
                                             : '-'}
                                       </Grid> */}
                                    </Box>
                                 </Box>
                              );
                           })
                        ) : (
                           <NoData />
                        )}
                     </Box>
                     {/* <Grid container direction="column">
                        {[1, 2, 3]?.length > 0 ? (
                           [1, 2, 3].map((item: any, i: number) => {
                              return (
                                 <Grid item key={i}>
                                    <Typography className="location-text-bold">test</Typography>
                                    <Box className="location-div">
                                       {[1, 2, 3]?.length > 0 ? (
                                          [1, 2, 3].map((item: any, i: number) => {
                                             return (
                                                <Box key={i} className="location-inner-div">
                                                   <Box>
                                                      <Grid container direction="row" xs={12}>
                                                         <Grid item xs={6}>
                                                            <Typography className="location-full-text">
                                                               test sub hospital name
                                                            </Typography>
                                                         </Grid>
                                                         <Grid item xs={6}>
                                                            <Typography className="location-full-text-bold">
                                                               Recurting
                                                            </Typography>
                                                         </Grid>
                                                      </Grid>
                                                   </Box>
                                                   <Box className="location-inner-second-div">
                                                      <Typography className="location-full-text">
                                                         test sub address
                                                      </Typography>
                                                      <Grid container direction="row" xs={12}>
                                                         <Grid item>
                                                            <Typography className="location-full-text">
                                                               test sub contact{' '}
                                                            </Typography>
                                                         </Grid>
                                                         <Grid item>
                                                            <Typography className="location-full-sub-contact-text">
                                                               mathankumar@mailinator.com
                                                            </Typography>
                                                         </Grid>
                                                      </Grid>
                                                      <Typography className="location-full-text">
                                                         test sub user
                                                      </Typography>
                                                   </Box>
                                                </Box>
                                             );
                                          })
                                       ) : (
                                          <NoData />
                                       )}
                                    </Box>
                                 </Grid>
                              );
                           })
                        ) : (
                           <NoData />
                        )} 
                     </Grid> */}
                  </Box>
               </Box>
            </Popover>
            {/* share */}
            <Popover
               id={Boolean(popoverOpenShare) ? 'simple-popover' : undefined}
               open={Boolean(popoverOpenShare) ? true : false}
               anchorEl={popoverOpenShare}
               onClose={handleClosePop}
               disableScrollLock={true}
               disableRestoreFocus={true}
               anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
               }}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
               }}
            >
               <Grid container direction="column" className="post-pop-over-share">
                  <Grid item className="feed-share">
                     <WhatsappShareButton
                        url={shareLink || 'https://oncopower.org/join'}
                        title={
                           'Join OncoPower for free today to connect and communicate with cancer experts at any time from any device!'
                        }
                        separator={'\n'}
                        className="Demo__some-network__share-button feed-share-button"
                     >
                        <Box mt={1} mb={1}>
                           <Box
                              className="choose-btn"
                              onClick={() => {
                                 handleClosePop();
                              }}
                           >
                              <span className="b-like-txt">
                                 <img src={feedShareWhatsapp} alt="comment" /> &nbsp;&nbsp;&nbsp;WhatsApp
                              </span>
                           </Box>
                        </Box>
                     </WhatsappShareButton>
                  </Grid>
                  <Grid item className="feed-share">
                     <TwitterShareButton
                        url={shareLink || 'https://oncopower.org/join'}
                        title={
                           'Join OncoPower for free today to connect and communicate with cancer experts at any time from any device!\n'
                        }
                        hashtags={['oncopower']}
                        className="Demo__some-network__share-button feed-share-button"
                     >
                        <Box mt={1} mb={1}>
                           <Box
                              className="choose-btn"
                              onClick={() => {
                                 handleClosePop();
                              }}
                           >
                              <span className="b-like-txt">
                                 <img src={feedShareTwitter} alt="comment" /> &nbsp;&nbsp;&nbsp;Twitter
                              </span>
                           </Box>
                        </Box>
                     </TwitterShareButton>
                  </Grid>
                  <Grid item className="feed-share">
                     <FacebookShareButton
                        url={shareLink || 'https://oncopower.org/join'}
                        quote={
                           'Join OncoPower for free today to connect and communicate with cancer experts at any time from any device!'
                        }
                        hashtag={'#oncopower'}
                        className="Demo__some-network__share-button feed-share-button"
                     >
                        <Box mt={1} mb={1}>
                           <Box
                              className="choose-btn"
                              onClick={() => {
                                 handleClosePop();
                              }}
                           >
                              <span className="b-like-txt">
                                 <img src={feedShareFacebook} alt="comment" /> &nbsp;&nbsp;&nbsp;Facebook
                              </span>
                           </Box>
                        </Box>
                     </FacebookShareButton>
                  </Grid>
                  <Grid
                     item
                     className="feed-share"
                     onClick={() => {
                        clickCopyLink(shareLink);
                     }}
                  >
                     <Box mt={1} mb={1}>
                        <Box className="choose-btn">
                           <img src={feedShareCopy} alt="comment" />
                           <span className="b-like-txt">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Copy link</span>
                        </Box>
                     </Box>
                  </Grid>
               </Grid>
            </Popover>
         </Box>
      </Grid>
   );
});
export default ClinicalTrialsListItem;
